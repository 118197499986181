// eslint-disable-next-line camelcase
import { action_types } from './openedgift.actionTypes'

function gift (state = { loading: false, data: null }, action = {}) {
  switch (action.type) {
    case action_types.REQUEST_GIFT:
      return {
        ...state,
        loading: true
      }

    case action_types.SUCCESS_GIFT:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      }
    case action_types.ERROR_GIFT:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    default:
      return state
  }
}

export { gift }
