import { Buffer } from 'buffer'

import React, { useState, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'

import PaymentWrapper from '@vfgroup-oneplatform/framework/Payment/PaymentWrapper'
import { PaymentTypes, PaymentScreenTypes } from '@vfgroup-oneplatform/framework/Payment/Utils/PaymentTypes.js'
import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import { Dimensions, NativeModules, Platform } from 'react-native'

import crypto from 'isomorphic-webcrypto'
import { Base64 } from 'js-base64'
import { TextEncoder } from 'text-encoding'
import { VFScreen } from '@vfgroup-oneplatform/foundation/Components'


import { WebView } from 'react-native-webview'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { CyberSource, VFPayments } from '../../Services'

import NavigationService from '../../Navigation/NavigationService'

import ReduxCache from '../../Utils/ReduxCache'

import { formatPhoneNumber, validatePhoneNumber } from '../../Utils'

import PaymentsNumber from './PaymentsNumber'
import { Images } from '../../Themes'
import { Styles as styles } from './Payments.Styles'




const tokenTypes = {
  DEFAULT: 'Default',
  TOKENID: 'TokenId',
  CARDID: 'CardId'
}

const headerTitleIfIsOtherTopUp = {
  false: 'dashboard_top_up_card_title',
  true: 'top_up_someone_else_quick_action_title'
}

const wheelValues = [
  {
    value: '100',
    gift: false
  },
  {
    value: '200',
    gift: false
  },
  {
    value: '300',
    gift: false
  },
  {
    value: '400',
    gift: false
  },
  {
    value: '500',
    gift: false
  },
  {
    value: '600',
    gift: false
  },
  {
    value: '700',
    gift: false
  },
  {
    value: '800',
    gift: true
  },
  {
    value: '900',
    gift: true
  },
  {
    value: '1000',
    gift: true
  },
  {
    value: '1100',
    gift: true
  },
  {
    value: '1200',
    gift: true
  },
  {
    value: '1300',
    gift: true
  },
  {
    value: '1400',
    gift: true
  },
  {
    value: '1500',
    gift: true
  },
  {
    value: '1600',
    gift: true
  },
  {
    value: '1700',
    gift: true
  },
  {
    value: '1800',
    gift: true
  },
  {
    value: '1900',
    gift: true
  },
  {
    value: '2000',
    gift: true
  }
]

const defaultWheelValue = [wheelValues[2]]

function Payments(props) {
  const defaultError = 'top_up_quick_action_error'
  const [errorMessage, setErrorMessage] = useState(defaultError)
  const [loading, setLoading] = useState(false)

  const routeName = Platform.OS === 'web' && Dimensions.get('window').width > 700 ? props?.route?.name || '' : ''
  const isThirdParty = Platform.OS === 'web' && Dimensions.get('window').width > 700 ? routeName === 'TopUPAnother' ? true : false : props.isThirdParty

  const { isVisible, navigation, type } = props
  const [tokenizedCard, setTokenizedCard] = useState(false)
  const [payerAuthenticationDetails, setPayerAuthenticationDetails] = useState({})
  const theme = useTheme()


  // eslint-disable-next-line no-unused-vars
  const [isContentLoading, setContentLoading] = useState(true)

  useLayoutEffect(() => {
    getTranslations()
  }, [])

  useEffect(() => {
    if (tokenizedCard) {
      getPayerAuthenticationToken()
    }
  }, [tokenizedCard])

  const getTranslations = async () => {
    let translations = {}
    /*
     * @author Edison
     * Added because after selecting third party number the title is changed to my msisdn topup title which is wrong
    */
    const start_value = 500
    const extra_translations_third_party = {
      'al': {
        payment_quick_action_start_value_gift: start_value,
        top_up_quick_action_offer_subtitle: 'Rimbush një numër tjetër dhe përfito 1GB bonus',
        top_up_quick_action_offer_subtitle_third_party: 'Rimbush një numër tjetër dhe përfito 1GB bonus',
      },
      'en': {
        payment_quick_action_start_value_gift: start_value,
        top_up_quick_action_offer_subtitle: 'Top up another number and get 1GB bonus',
        top_up_quick_action_offer_subtitle_third_party: 'Top up another number and get 1GB bonus',
      }
    }

    const extra_translations = {
      'al': {
        payment_quick_action_start_value_gift: start_value,
        top_up_quick_action_offer_subtitle: 'Rimbush mbi 500 Lekë dhe përfito 3GB dhuratë',
      },
      'en': {
        payment_quick_action_start_value_gift: start_value,
        top_up_quick_action_offer_subtitle: 'Top up by 500ALL or more and get 3GB bonus',
      }
    }

    if (isThirdParty) {
      translations = { ...extra_translations_third_party }
    } else {
      translations = { ...extra_translations }
    }

    ContentManager.configureI18nTranslations({ en: translations.en, al: translations.al })

    const startingValue = start_value || 500

    wheelValues.map(item => {
      if (parseInt(item.value) >= parseInt(startingValue)) {
        item.gift = true
      }
      return item
    })

    setContentLoading(false)
  }

  const onPressAddData = () => {
    return true
  }

  const onPressRejectData = () => {
    return true
  }

  const toggleModal = () => {
    props.setShowPayments(!isVisible)

    if (paymentOptions.screen == PaymentScreenTypes.PAYMENTSUCCESSFULLY && !isThirdParty) {
      // @todo refresh our balance
      // NativeModules.RNReward.refreshBalance()
    }

  }

  const getCustomerId = () => {
    setLoading(true)
    VFPayments.getCustomerId(msisdn, { 'vf-operator': 'payments' })
      .then(data => {
        const externalId = data.details.externalId.value
        setClientId(externalId)
      })
      .catch(error => {
        setErrorMessage(defaultError)
        if (error?.data?.reasonCode == 400) {
          createCustomerCyberSource()
        } else {
          setPaymentOptions({
            screen: PaymentScreenTypes.ERROR
          })

          setOnErrorPress(() => () => getCustomerId())
        }
      })
  }

  const createCustomerCyberSource = async () => {

    const hashedMsisdn = await sha256(msisdn)

    const body = {
      'buyerInformation': {
        'merchantCustomerID': hashedMsisdn
      },
      'clientReferenceInformation': {
        'code': hashedMsisdn
      }
    }

    try {
      const data = await VFPayments.generateToken(msisdn, body, 'POST', { 'vf-operator': 'Customer.create' })
      var tokenized = data.id[0].value

      const cyberSourceCustomer = await CyberSource.createCustomer(tokenized, body)
      setClientId(cyberSourceCustomer.id)

      saveCustomerId(cyberSourceCustomer.id)

    } catch (error) {
      setErrorMessage(defaultError)
      setPaymentOptions({
        screen: PaymentScreenTypes.ERROR
      })

      setOnErrorPress(() => () => createCustomerCyberSource())
    }

  }

  const saveCustomerId = async (id) => {
    try {
      await VFPayments.saveCustomerIdToVF(msisdn, id, { 'vf-operator': 'payments' })

    } catch (error) {
      setErrorMessage(defaultError)
      setPaymentOptions({
        screen: PaymentScreenTypes.ERROR
      })

      setOnErrorPress(() => () => saveCustomerId(id))
    }
  }


  const importKey = async (jsonWebKey) => {

    return crypto.subtle.importKey(
      'jwk',
      jsonWebKey,
      {
        name: 'RSA-OAEP',
        hash: { name: 'SHA-1' }
      },
      true,
      ['encrypt'],
    )
  }


  const tokenizeCard = async (cardNumber, jwk) => {
    const cardNumberBuffer = Buffer.from(cardNumber)


    // await crypto.ensureSecure()

    const publicKey = await importKey(jwk)


    try {
      const encryptedCardNumberBuffer = await crypto.subtle.encrypt(
        {
          name: 'RSA-OAEP',
          hash: { name: 'SHA-1' }
        },
        publicKey,
        cardNumberBuffer
      )

      return Base64.btoa(String.fromCharCode.apply(null, new Uint8Array(encryptedCardNumberBuffer)))
    } catch (error) {
      console.error(error)
    }

    throw new Error()
  }

  async function sha256(message) {
    const msgUint8 = new TextEncoder().encode(message)
    const hashBuffer = await crypto.subtle.digest({ name: 'SHA-256' }, msgUint8)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
    return hashHex.substring(0, 50)
  }

  const createCustomerPaymentInstrument = async (card, instrumentIdentifier) => {

    const body = {
      'card': {
        'expirationMonth': card.expirationMonth,
        'expirationYear': card.expirationYear,
        'type': card.type
      },
      'instrumentIdentifier': {
        'id': instrumentIdentifier
      },
      'billTo': {
        'firstName': card.cardName
      }
    }

    try {
      const data = await VFPayments.generateToken(msisdn, body, 'POST', { 'vf-operator': 'CustomerPaymentInstrument.create' })
      var tokenized = data.id[0].value

      const customerPaymentInstrument = await CyberSource.createCustomerPaymentInstrument(tokenized, clientId, body)
      return customerPaymentInstrument

    } catch (error) {
      console.error(error)
      return false
    }
  }

  const getBrandById = (id) => {
    const types = {
      '001': 'Visa',
      '002': 'Mastercard',
      '003': 'American Express'
    }

    return types[id]
  }

  const getIdByBrand = (brand) => {
    const types = {
      'Visa': '001',
      'Mastercard': '002',
      'American Express': '003'
    }

    return types[brand]
  }

  const getImageByCardId = (id) => {
    const types = {
      '001': Images.visa_icon,
      '002': Images.mastercard_icon,
      '003': Images.americanExpress_icon
    }

    return types[id]
  }

  const getCardType = (number) => {
    // visa
    var re = new RegExp('^4')
    if (number.match(re) != null) { return 'Visa' }

    // Mastercard
    if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) {
      return 'Mastercard'
    }


    re = new RegExp('^3[47]')
    if (number.match(re) != null) {
      return 'American Express'
    }


    // Visa Electron
    re = new RegExp('^(4026|417500|4508|4844|491(3|7))')
    if (number.match(re) != null) {
      return 'Visa'
    }

    return ''
  }

  const [cards, setCards] = useState([])
  const [defaultPayment, setDefaultPayment] = useState({})
  // const [clientId, setClientId] = useState('B1A89F2C5BA1B42DE05341588E0AD177')
  // const [msisdn, setMsisdn] = useState('355698933127')
  const [clientId, setClientId] = useState()
  const msisdn = ReduxCache.getMsisdn()
  const [thirdPartyMsisdn, setThirdPartyMsisdn] = useState()

  const getPaymentInstruments = () => {
    VFPayments.generateToken(msisdn, null, 'GET', { 'vf-operator': 'CustomerPaymentInstrument.getList' })
      .then(data => {
        var tokenized = data.id[0].value

        CyberSource.getPaymentInstruments(tokenized, clientId)
          .then(res => {
            const allCards = []

            if (res.count > 0) {
              res._embedded.paymentInstruments.map(card => {
                const cardDetail = {
                  id: card.id,
                  instrumentIdentifier: card.instrumentIdentifier.id,
                  token: card.id,
                  cardName: card.billTo?.firstName || 'credit_card',
                  nameOnCard: 'credit_card',
                  lastFourDigits: card._embedded.instrumentIdentifier.card.number.slice(-4),
                  brand: getBrandById(card.card.type),
                  expiry: card.card.expirationMonth + '/' + card.card.expirationYear,
                  icon: getImageByCardId(card.card.type),
                  tokenType: tokenTypes.CARDID
                }

                allCards.push(cardDetail)

                if (card.default && !defaultPayment.id) {
                  cardDetail.tokenType = tokenTypes.DEFAULT
                  setDefaultPayment(cardDetail)
                }
              })

              if (allCards.length > 0) {
                getPayerAuthenticationToken()
                setPaymentOptions({
                  ...paymentOptions,
                  isCardListOpend: false
                })
              }

              setCards(allCards)
            }

            setLoading(false)

          })
          .catch(error => {
            setErrorMessage(defaultError)
            setLoading(false)
            setOnErrorPress(() => () => getPaymentInstruments())
          })



      })
      .catch(error => {
        setErrorMessage(defaultError)
        setLoading(false)
        setOnErrorPress(() => () => getPaymentInstruments())
      })
  }

  const getPayerAuthenticationToken = async () => {
    const hashedMsisdn = await sha256(msisdn)
    const body = {
      'clientReferenceInformation': {
        'code': hashedMsisdn
      },
      'paymentInformation': {
        'customer': {
          'customerId': tokenizedCard ? tokenizedCard : clientId
        },
        'card': {
          'expirationMonth': defaultPayment.expiry.split('/')[0],
          'expirationYear': defaultPayment.expiry.split('/')[1]
        }
      }
    }

    VFPayments.generateToken(msisdn, body, 'POST', { 'vf-operator': 'PA.DeviceData' })
      .then(data => {
        const payerDetails = {}

        data.parts.specification.characteristicsValue.map(item => {
          payerDetails[item.characteristicName] = item.value
        })

        setPayerAuthenticationDetails(payerDetails)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleSelectPaymentCard = (card) => {
    setDefaultPayment(card)
  }

  const onPressConfirmSelection = async (selectedItem) => {
    const amount = selectedItem.selectedValue?.value || defaultWheelValue[0].value

    setPaymentOptions({
      screen: PaymentScreenTypes.PAYMENTREQUEST
    })

    const phoneNumber = formatPhoneNumber(selectedItem.topupPhoneNumber)

    const paymentDetails = {
      instrumentIdentifier: defaultPayment.instrumentIdentifier,
      token: defaultPayment.token || '',
      authorizedAmount: amount,
      currency: currency,
      customer: clientId,
      tokenType: defaultPayment.tokenType || tokenTypes.DEFAULT,
      isThirdParty: isThirdParty,
      thirdPartyMsisdn: phoneNumber,
      expiry: defaultPayment.expiry
    }


    try {
      await VFPayments.createTopUpPayment(msisdn, paymentDetails, { 'vf-operator': 'payments', 'vf-source': 'myvodafone' })
      setPaymentOptions({
        screen: PaymentScreenTypes.PAYMENTSUCCESSFULLY
      })

      setErrorMessage(defaultError)

      return {
        balance: amount
      }
    } catch (error) {
      setErrorMessage(error?.data?.message || defaultError)

      setOnErrorPress(() => async () => {
        setPaymentOptions({ screen: PaymentScreenTypes.PAYMENTREQUEST })
        await onPressConfirmSelection(selectedItem)
      })
      throw new Error(error?.message || error?.data?.message)

    }

  }



  const onPressSaveCard = async (card) => {

    try {

      setPaymentOptions({ screen: PaymentScreenTypes.ADD_PAYMENT_CARD_LOADING })

      const tokenBody = {
        'encryptionType': 'RsaOaep',
        'targetOrigin': 'https://www.vodafone.al'
      }

      const data = await VFPayments.generateToken(msisdn, tokenBody, 'POST', { 'vf-operator': 'Flex.GenerateKey' })

      const specifications = data?.parts?.specification?.characteristicsValue

      const specificationsData = {}
      specifications.forEach(item => {
        specificationsData[item.characteristicName] = item.value
      })

      const jwt = {
        'kid': specificationsData.jwkKeyId,
        'n': specificationsData.jwkMod,
        'e': specificationsData.jwkExp,
        'use': 'enc',
        'kty': 'RSA'
      }

      const tokenized = await tokenizeCard(card.cardNo, jwt)

      const cardBrand = getCardType(card.cardNo)
      const cardTypeID = getIdByBrand(cardBrand)

      const body = {
        'cardInfo': {
          'cardNumber': tokenized,
          'expirationMonth': card.expirationData.split('/')[0],
          'expirationYear': '20' + card.expirationData.split('/')[1],
          'cardType': cardTypeID
        },
        'keyId': specificationsData.jwkKeyId
      }

      const instrument = await CyberSource.createTokenizesCard(body)


      const instrumentIdentifier = instrument?._embedded?.icsReply?.instrumentIdentifier
      const instrumentId = instrumentIdentifier.id

      const cardDetail = {
        id: instrumentId,
        instrumentIdentifier: instrumentId,
        token: instrumentId,
        cardName: card.cardName,
        nameOnCard: '',
        lastFourDigits: instrument.maskedPan.split('XXXXXX')[1],
        brand: cardBrand,
        expiry: body.cardInfo.expirationMonth + '/' + body.cardInfo.expirationYear,
        icon: getImageByCardId(cardTypeID)
      }




      let cyberSourceCardObject = {}
      if (card.saveForLater && instrumentId) {
        cyberSourceCardObject = {
          number: card.cardNo,
          expirationMonth: card.expirationData.split('/')[0],
          expirationYear: '20' + card.expirationData.split('/')[1],
          securityCode: card.ccvNo,
          nameOnCard: card.nameOnCard,
          cardName: card.cardName,
          type: cardTypeID
        }
        cardDetail.tokenType = tokenTypes.CARDID
      } else {
        cardDetail.tokenType = tokenTypes.TOKENID
      }

      if (card.saveForLater && instrumentId) {
        const customerPaymentInstrument = await createCustomerPaymentInstrument(cyberSourceCardObject, instrumentId, clientId)
        cardDetail.id = customerPaymentInstrument.id
        cardDetail.token = customerPaymentInstrument.id
        getPaymentInstruments()
      } else {
        setTokenizedCard(instrumentId)
        setPaymentOptions({ screen: PaymentScreenTypes.DEFAULT })
      }

      setDefaultPayment(cardDetail)
    } catch (error) {
      setPaymentOptions({
        screen: PaymentScreenTypes.ERROR
      })
      setErrorMessage(defaultError)

      setOnErrorPress(() => () => setPaymentOptions({ screen: PaymentScreenTypes.ADD_PAYMENT_CARD }))
    }

  }

  useEffect(() => {
    if (!msisdn) {
      return
    }
    getCustomerId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msisdn])

  useEffect(() => {
    if (!clientId) {
      return
    }
    getPaymentInstruments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  const [paymentOptions, setPaymentOptions] = useState({ isCardListOpend: true })
  const [onErrorPress, setOnErrorPress] = useState()

  const currency = 'ALL'


  const retry = () => {
    setPaymentOptions({
      screen: PaymentScreenTypes.ADD_PAYMENT_CARD_LOADING
    })
    onErrorPress()
  }


  const paymentThirdParty = () => {
    return (
      <PaymentsNumber
        setThirdPartyMsisdn={setThirdPartyMsisdn}
        onPress={thirdParty => setThirdPartyMsisdn(thirdParty)}
        msisdn={thirdPartyMsisdn}
      />
    )
  }

  const onPressManageCard = () => {
    if (Platform.OS == 'ios') {
      NativeModules.RNReward.makeVisible()
    }
    NavigationService.navigate('PaymentMethods')
  }

  const getTopUpData = () => {
    return wheelValues
  }

  const onPhoneNumberChange = (number) => {
    const isValid = validatePhoneNumber(number)
    if (isValid) {
      setThirdPartyMsisdn(formatPhoneNumber(number))
    }

    return isValid
  }


  const iframeContent = `
      <form id="collectionForm" name="devicedata" method="POST" action="${payerAuthenticationDetails.deviceDataCollectionUrl}">
            <input type="text" name="JWT" value="${payerAuthenticationDetails.accessToken}"/>
        </form>
        <script>
        window.onload = function() {
          document.getElementById("collectionForm").submit();
        };
      </script>
  `

  const javascript = `
    window.addEventListener("message", (event) => {
      window.ReactNativeWebView.postMessage(JSON.stringify(event));
      if(event.origin === 'https://centinelapistag.cardinalcommerce.com'){
        let data = JSON.parse(event.data);
      }

      if(data !== undefined && data.Status){
        console.log('Songbird ran DF successfully');
      }
    }, false);

    true;
  `



  return (
    <VFScreen
      headerStyle={styles.headerStyle(theme)}
      title={headerTitleIfIsOtherTopUp[isThirdParty]}
      onClose={() => { navigation.pop(1) }}
    >
      <PaymentWrapper
        isLoading={loading}
        onPressManageCard={onPressManageCard}
        defaultPayment={defaultPayment}
        paymentCards={cards}
        toggleModal={toggleModal}
        withTopUpSomeOneElseTitle={isThirdParty}
        userType={isThirdParty ? 'payM' : 'payG'}
        // paymentOptions={paymentOptions}
        closeModal={toggleModal}
        isVisible={true}
        onPressAddData={onPressAddData}
        onPressRejectData={onPressRejectData}
        onSelectDefaultCard={handleSelectPaymentCard}
        wheelValues={wheelValues}
        offerValues={false}
        // defaultWheelValue={defaultWheelValue}
        //onClose={toggleModal}
        type={(Platform.OS === 'web' && Dimensions.get('window').width > 700) ? routeName === 'TopUPAnother' ? PaymentTypes.TOP_UP_SOMEONE_ELSE : PaymentTypes.BALANCE_TOP_UP : isThirdParty ? PaymentTypes.TOP_UP_SOMEONE_ELSE : PaymentTypes.BALANCE_TOP_UP}
        onPressConfirmSelection={onPressConfirmSelection}
        // QATitle={'Title'}
        //   QASubtitle={QASubtitle}
        //   billPaymentSuccessPlaceholders={billPaymentSuccessPlaceholders}
        currency={ContentManager.translate(currency)}
        onPressSaveCard={onPressSaveCard}
        phoneNumberValidation={onPhoneNumberChange}
        // billInfoText={'Testttt'}
        onErrorPress={retry}
        paymentButtonError="full_error_button_text"
        billButtonError="full_error_button_text"
        topUpTitleError={errorMessage}
        topUpErrorMessage={errorMessage != 'top_up_quick_action_error' ? ' ' : 'top_up_quick_action_error_message'}
        paymentTitleError="payment_quick_action_error"
        paymentErrorMessage="payment_quick_action_error_message"
        PaymentsNumber={paymentThirdParty}
        isBtnSuccessDisabled={(isThirdParty && (thirdPartyMsisdn == null || thirdPartyMsisdn.length != 12)) || !defaultPayment.id}
        hiddenFields={['nameOnCard']}
        getTopUpData={getTopUpData}
        customAmountMaximum={2000}
      />
      {payerAuthenticationDetails.accessToken &&
        <WebView
          style={styles.iframeDataCollection}
          javaScriptEnabled={true}
          onMessage={(event) => {
            console.log('event.nativeEvent payerAuthentication', event.nativeEvent)
            // setPayerAuthenticationResponse(JSON.parse(event.nativeEvent.data))
          }}
          injectedJavaScript={javascript}
          // injectedJavaScriptBeforeContentLoaded={runBeforeFirst}
          source={{ html: iframeContent }} />
      }
    </VFScreen>
  )
}

Payments.propTypes = {
  isVisible: PropTypes.bool,
  setShowPayments: PropTypes.func,
  isThirdParty: PropTypes.bool,
  route: PropTypes.object,
  navigation: PropTypes.object

}


export default Payments
