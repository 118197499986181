import {
  GetVdayOffers,
} from '@vfal-lab/rn-vfg-vday/Services'

import ReduxCache from '../../Utils/ReduxCache'

import {actionTypes} from './VDayOffers.ActionTypes'

const errorMapper = (error) => {
  const payload = error?.response?.data?.message || error?.message
  return payload
}

const getOffersResponseMapper = (res) => {
  const recommendationItem = (res?.data || {})
  const validityPeriod = recommendationItem?.[0]?.validityPeriod?.toDate?.date
  const items = []
  recommendationItem.map(el => {
    const id = el.id[0]?.value
    const url = el.parts?.specification?.characteristicsValue[0]?.value
    const imageUrl = el.parts?.specification?.characteristicsValue[0]?.type
    const {
      name,
      desc,
    } = el

    items.push({ id, name, desc, imageUrl, url })
  })
  return { items, validityPeriod }
}

const customHeaders = {
  'X-Source-CountryCode': 'al',
  'X-Source-Operator': 'redday',
  'X-Source-System': 'selfcare',
  'Accept-Language': 'al',
  'Content-Type': 'application/json',
}

const getVdayOffers = (utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_OFFERS,
    })

    const msisdn = ReduxCache.getMsisdn()

    GetVdayOffers(msisdn, customHeaders, {
      ...utilities,
      responseMapper: getOffersResponseMapper,
      errorMapper: errorMapper,
    })
      .then(response => dispatch({
        type: actionTypes.SUCCESS_OFFERS,
        payload: response,
      }))
      .catch(error => dispatch({
        type: actionTypes.ERROR_OFFERS,
        payload: error,
      }))
  }
}

export {
  actionTypes,
  getVdayOffers,
}
