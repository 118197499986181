import {Placeholder, PlaceholderLine, Fade} from 'rn-placeholder'
import React from 'react'
import {View} from 'native-base'

const CardPlaceholder = () => {
  return (
    <View
      style={{
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 250,
      }}>
      <Placeholder
        Animation={props => (
          <Fade {...props} style={{backgroundColor: '#ccc'}} />
        )}>
        <View
          style={{
            width: '100%',
            flex: 1,
            height: 235,
          }}>
          <View
            style={{
              height: 225,
              alignSelf: 'center',
              width: '100%',
              marginBottom: 10,
              backgroundColor: '#f7f7f7',
            }}>
            <PlaceholderLine
              height={20}
              style={{
                width: 33,
                height: 33,
                backgroundColor: '#dfdfdf',
                alignSelf: 'flex-end',
                margin: 15,
              }}
            />
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 10,
              position: 'absolute',
              bottom: 0,
              width: '100%',
            }}>
            <PlaceholderLine
              height={56}
              hasRadius
              style={{width: 110, height: 29, backgroundColor: '#dfdfdf'}}
            />
            <PlaceholderLine
              height={56}
              hasRadius
              style={{width: 95, height: 29, backgroundColor: '#dfdfdf'}}
            />
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: 'space-between',
              flexDirection: 'row',
              padding: 15,
              backgroundColor: '#ededed',
              position: 'absolute',
              bottom: 0,
              width: '100%',
              height: 38,
              paddingTop: 10,
              marginBottom: 15,
              borderBottomLeftRadius: 12,
              borderBottomRightRadius: 12,
            }}>
            <PlaceholderLine
              style={{width: 60, height: 19, backgroundColor: '#dfdfdf'}}
            />
            <PlaceholderLine
              style={{width: 30, height: 19, backgroundColor: '#dfdfdf'}}
            />
            <PlaceholderLine
              style={{width: 80, height: 19, backgroundColor: '#dfdfdf'}}
            />
          </View>
        </View>
      </Placeholder>
    </View>
  )
}

export default CardPlaceholder
