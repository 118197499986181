import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { GigaBonus as GigaBonusScreen } from '@vfal-lab/rn-vfg-giga-family/Screens'

import {actions} from './GigaBonus.Actions'


function GigaBonus({navigation, route}) {
  const dispatch = useDispatch()

  const [name, setName] = useState()
  const [city, setCity] = useState()
  const [coordinates, setCoordinates] = useState()

  const orderBonus = async (utilities) => {
    return dispatch(actions.orderBonus(name, coordinates, city, utilities))
  }

  return (
    <GigaBonusScreen
      navigation={navigation}
      orderBonus={orderBonus}
      name={name}
      nameRegex={/^\w{2,}(\s+\w{2,})+/}
      setName={setName}
      city={city}
      setCity={setCity}
      coordinates={coordinates}
      setCoordinates={setCoordinates}
      route={route}
    />
  )
}

GigaBonus.defaultProps = {}

GigaBonus.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object
}

export default GigaBonus
