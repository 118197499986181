import React from 'react'
import { VFText, Icon, VFTouchableInScroll, VFButton } from '@vfgroup-oneplatform/foundation/Components'
import { withTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { Dimensions, Platform, View } from 'react-native'

import PropTypes from 'prop-types'

import styles from '../SecondaryComponent.Styles'

const TopUp = ({ theme, image, onPress, value, currency, paymentInfo }) => {
  if (Dimensions.get('window').width > 700 && Platform.OS === 'web') {
    return (
      <VFTouchableInScroll onPress={onPress}>
        <View style={styles.topUpOuterContainer}>
          <View style={styles.topUpContainer}>
            <Icon source={image} style={styles.icon} size={32} />
            <VFText i18nKey={'dashboard_top_up_card_title'} style={styles.title} />
          </View>
          <VFText
            i18nKey={'dashboard_top_up_card_subtitle'}
            style={styles.subtitle(theme)}
          />
          <View style={styles.topUpPriceOuterContainer}>
            <View style={styles.priceContnair}>
              <VFText i18nKey={value} style={styles.price} />
              <VFText i18nKey={' ' + currency} style={styles.topUpCurrency} />
            </View>
            {paymentInfo && <VFText
              i18nKey={'top_up_last_recharge'}
              placeHolders={[paymentInfo]}
              style={styles.updatedMessage(theme)}
            />}
            <View style={{ flex: 1, flexGrow: 1, flexDirection: 'row' }}>
              <VFButton title='Top Up' type='secondary' onPress={onPress} style={{ alignSelf: 'flex-end', marginHorizontal: 14, flexGrow: 1 }} />
            </View>
          </View>
        </View>
      </VFTouchableInScroll>
    )
  }
  return (
    <VFTouchableInScroll onPress={onPress}>
      <View style={styles.topUpOuterContainer}>
        <View style={styles.topUpContainer}>
          <Icon source={image} style={styles.icon} size={24} />
          <VFText i18nKey={'dashboard_top_up_card_title'} style={styles.title} />
        </View>
        <VFText
          i18nKey={'dashboard_top_up_card_subtitle'}
          style={styles.subtitle(theme)}
        />
        <View style={styles.topUpPriceOuterContainer}>
          <View style={styles.priceContnair}>
            <VFText i18nKey={value} style={styles.price} />
            <VFText i18nKey={currency} style={styles.topUpCurrency} />
          </View>
          {paymentInfo && <VFText
            i18nKey={'top_up_last_recharge'}
            placeHolders={[paymentInfo]}
            style={styles.updatedMessage(theme)}
          />}
        </View>
      </View>
    </VFTouchableInScroll>
  )
}

TopUp.propTypes = {
  theme: PropTypes.objectOf({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  }),
  image: PropTypes.object,
  onPress: PropTypes.func
}

export default withTheme(TopUp)
