import AsyncStorage from '@react-native-async-storage/async-storage'


const setLocalKey = async (key, value) => {
    await AsyncStorage.setItem(key, value)
}

const getLocalKey = async (key) => {
    return await AsyncStorage.getItem(key)
}

const removeLocalKey = async (key) => {
    await AsyncStorage.removeItem(key)
}

export {setLocalKey, removeLocalKey, getLocalKey}
