import { actionTypes as loginActionTypes } from '../Login/Login.ActionsTypes'

import { actionTypes } from './Dashboard.ActionTypes'

function balance(state = { loading: false, data: null, error: null }, action = {}) {
  switch (action.type) {
    case actionTypes.BALANCE_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.BALANCE_SUCCESS:
      return {
        loading: false,
        error: null,
        data: action.data,
      }
    case actionTypes.BALANCE_ERROR:
      return {
        loading: false,
        error: action.data,
        data: null
      }

    default:
      return state
  }
}

function pega(state = { loading: false, data: null, error: null }, action = {}) {
  switch (action.type) {
    case actionTypes.PEGA_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.PEGA_SUCCESS:
      return {
        loading: false,
        error: null,
        data: action.data,
      }
    case actionTypes.PEGA_ERROR:
      return {
        loading: false,
        error: action.data,
        data: null
      }
    case loginActionTypes.LOGOUT:
      return {
        loading: false,
        error: null,
        data: null
      }
    default:
      return state
  }
}

function userSideBar(state = [], action = {}) {
  switch (action.type) {
    case actionTypes.UPDATE_SIDEBAR:
      return [...action.data]
    case actionTypes.CLEAR_SIDEBAR:
      return []
    default:
      return state
  }
}

export { balance, pega, userSideBar }
