import React from 'react'
import PropTypes from 'prop-types'

import { getThemeImages, useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { PlanUsers } from '@vfgroup-oneplatform/soho-framework/BusinessOverview'

import NavigationService from '../../../Navigation/NavigationService'


const PlanUsersScreen = ({ navigation, route }) => {

  const { plan } = route.params || {}

  const theme = useTheme()
  const images = getThemeImages(theme.name)

  const {
    name,
    price,
    currency,
    isCurrencyFirst = false,
    withPriceVat = true,
    users,
    unassignedUsers
  } = plan


  const onClose = () => {
    NavigationService.popToTop()
  }
  const onBack = () => {
    navigation.goBack()
  }

  return (
    <PlanUsers
      vfScreenProps={{
        onClose: onClose,
        onBack: onBack,
        showBack: true,
      }}
      withTray
      images={images}
      name={name}
      priceInfo={{
        price,
        currency,
        isCurrencyFirst,
        withPriceVat
      }}
      users={users}
      unassignedUsers={unassignedUsers}
    />
  )
}
PlanUsersScreen.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
}

export default PlanUsersScreen
