import { LoginStatuses as Status } from '@vfgroup-oneplatform/login'
import { RegisterBase } from '@vfgroup-oneplatform/login/BaseClasses'

// import { LoginStatuses as Status } from 'rn-vfg-login'
// import { RegisterBase } from 'rn-vfg-login/BaseClasses'

import { errorObjectConstructor } from '../../Services/Auth/utils'

import { Actions as LoginActions } from '../Login/Login.Actions'

import { Actions } from './Register.Actions'


class Register extends RegisterBase {
  constructor(
    authConfig = {},
    onLoadingStart = () => { },
    onLoadingEnd = () => { }
  ) {
    super(authConfig, onLoadingStart, onLoadingEnd)
    this.authStatus = {
      success: {
        status: Status.Success,
        response: {}
      },
      registerFailure: {
        status: Status.Failed,
        errorMessage: 'Error',
        subErrorMessage: 'Register failed. Please try again.'
      },
      verifyCodeFailure: {
        status: Status.Failed,
        errorMessage: 'An error occurred',
        subErrorMessage: 'Please try again.'
      },
      registerFailureAlreadyExists: {
        status: Status.Blocked
      }
    }

    this.password = null
  }

  validatePhone = () => {
    return true
  }
  validatePassword = () => {
    return true
  }
  onRegister = (phoneNumber, password) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.password = password

        resolve(this.authStatus.success)
        // reject(this.authStatus.registerFailureAlreadyExists)
      } catch (error) {
        const errorInfo = errorObjectConstructor(
          error,
          this.authStatus.registerFailure
        )
        reject(errorInfo)
      }
    })
  }

  onVerifyCodeSuccess = (response = {}) => {
    this.__onLoadingEnd()
  }

  onCodeSubmit = (phoneNumber, verificationCode, verifyCodeLength) => {
    return new Promise(async (resolve, reject) => {
      try {
        await Actions.register(phoneNumber, this.password, verificationCode)
        await LoginActions.loginWithDXL(phoneNumber, this.password)

        resolve(this.authStatus.success)
      } catch (error) {

        const errorInfo = errorObjectConstructor(
          error,
          this.authStatus.verifyCodeFailure
        )

        reject(errorInfo)
      }
    })
  }
}
export default Register
