import React, { useEffect, useRef, useState } from 'react'
import { Dimensions, Platform, View } from 'react-native'
import PropTypes from 'prop-types'

import AppSettings from '@vfgroup-oneplatform/framework/Settings/AppSettings'
import { VFText, Icon, VFButton, QuickActionMenu } from '@vfgroup-oneplatform/foundation/Components'

import Modal from 'react-native-modal'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { getThemeImages } from '../../Group/Themes'
import NavigationService from '../../Navigation/NavigationService'

import { getMsisdnData, setMsisdnData } from '../../Utils'

import CardContent from './CardContent'

import Images from '../../Themes/Images'
import { styles } from './EditProfile.Styles'



const SuccessModal = ({
	visible,
	close,
}) => {

	const theme = useTheme()
	const images = getThemeImages(theme.name)

	if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
		return (
			<Modal
				isVisible={visible}
				backdropOpacity={0.5}
				onSwipeComplete={() => close()}
				onBackdropPress={() => close()}
				propagateSwipe
				hideModalContentWhileAnimating
				hardwareAccelerated
				backdropTransitionOutTiming={0}
				style={{
					justifyContent: 'flex-end',
					margin: '0 !important',
					minWidth: 1920,
					maxWidth: 1920,
				}}
			>
				<View
					style={{
						minWidth: '600px',
						maxWidth: '600px',
						height: 'auto',
						backgroundColor: theme.colors.quickActionGradientColors.secondary,
						margin: 'auto',
						padding: '20px',
						borderRadius: '7px',
					}}
					onStartShouldSetResponder={() => true}>
					<View style={styles.successModalContainer}>
						<Icon
							name={Images.tick_success}
							size={100}
							resizeMode="contain"
							type="image"
						/>
						<VFText
							i18nKey="profile_settings_save_name_success_title"
							style={styles.successText}
						/>
						<VFButton
							title="Okay"
							style={styles.successButton}
							onPress={close}
						/>
					</View>
				</View>
			</Modal>
		)
	}

	return (
		<QuickActionMenu
			isVisible={visible}
			toggleModal={close}
			type="secondary"
			title=""
		>
			<View style={styles.successModalContainer}>
				<Icon
					name={Images.tick_success}
					size={100}
					resizeMode="contain"
					type="image"
				/>
				<VFText
					i18nKey="profile_settings_save_name_success_title"
					style={styles.successText}
				/>
				<VFButton
					title="Okay"
					style={styles.successButton}
					onPress={close}
				/>
			</View>
		</QuickActionMenu>
	)
}

SuccessModal.propTypes = {
	visible: PropTypes.bool,
	close: PropTypes.func,
}

function EditProfile({ ...props }) {
	const images = getThemeImages('light')
	const inputRef = useRef()
	const [username, setUsername] = useState('')
	const [succesModalVisible, setSuccessModalVisible] = useState(false)

	const saveUsername = async () => {
		await setMsisdnData('username', username)
		setSuccessModalVisible(true)
	}

	const closeModal = () => {
		setSuccessModalVisible(false)
		//NavigationService.pop()
	}

	useEffect(() => {
		getMsisdnData()
			.then((res) => {
				const storedUsername = res?.username
				setUsername(storedUsername)
				inputRef.current?.setValue(storedUsername)
			})
			.catch(() => { })
	}, [])

	const profileSettings = {
		title: 'profile_settings_edit_name_title',
		description: 'profile_settings_edit_name_subtitle',
		withCard: true,
		items: [
			{
				component: CardContent,
				props: {
					icon: 'ic_Users_Members_Customers',
					title: 'profile_settings_edit_title',
					description: 'profile_settings_edit_subtitle',
					username,
					setUsername,
					onPress: () => saveUsername(),
					inputRef,
				}
			},
		]
	}

	const onClose = () => NavigationService.pop()

	return (
		<>
			<AppSettings
				title="profile_settings_edit_title"
				settings={[profileSettings]}
				onClose={onClose}
				images={images}
				withTray
				{...props}
			/>
			<SuccessModal
				visible={succesModalVisible}
				close={closeModal}
				images={images}
			/>
		</>
	)
}
EditProfile.propTypes = {}

export default EditProfile
