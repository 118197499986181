import { StyleSheet } from 'react-native'

export default StyleSheet.create({
    container: {
        width: 36,
        height: 36,
        marginLeft: 8,
    },
})

