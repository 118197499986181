import { Platform, ToastAndroid } from 'react-native'
// import { Toast } from 'native-base'

export const toastr = {
  showToast: (message, duration = 2500) => {
    if (Platform.OS == 'android') {
      ToastAndroid.showWithGravityAndOffset(message, ToastAndroid.LONG, ToastAndroid.BOTTOM, 25, 50)
    } else {
      // Toast.show({
      //   text: message,
      //   duration,
      //   position: 'bottom',
      //   textStyle: { textAlign: 'center' },
      //   buttonText: 'Ok'
      // })
    }
  }
}

export default toastr
