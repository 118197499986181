import { Dimensions, Platform, StyleSheet } from 'react-native'
import { Fonts, Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  title: {
    marginVertical: 32,
    fontSize: 24,
    ...Fonts.type.bold,
  },
  //Card
  cardWrapper: {
    padding: 16,
    flex: 1,
    height: '100%',
    width: '100%'
  },
  card: {
    flex: 1,
  },
  cardHeader: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardTextContainer: {
    flex: 1,
  },
  mobileIcon: (theme) => ({
    tintColor: theme.colors.textColor,
    marginRight: 10,
  }),
  contactContainer: {
    flexGrow: 1,
  },
  cardDescText: {
    marginTop: 16,
    fontSize: 16,
    ...Fonts.type.normal
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 28,
  },
  subTitle: theme => ({
    color: theme.colors.textColorTwo,
    fontSize: 14,
    marginTop: 4,
  }),
  button: {
    marginTop: 15
  },
  separator: theme => ({
    height: 1,
    backgroundColor: theme.colors.borderColorThree,
    marginTop: 16
  }),
  listStyle: {

  },
  listContainerStyle: {
    paddingHorizontal: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? '10%' : 16,
    paddingBottom: '10%',
  },
  //
  textsWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemSeparator: {
    height: 16
  },
  //Loaders
  cardContentLoader: theme => ({
    marginHorizontal: 16,
    padding: 16,
    borderRadius: 6,
    marginVertical: 8,
    backgroundColor: theme.colors.cardBackgroundColor
  }),
  textContentLoader: {
    marginTop: 16,
    borderRadius: 6,
    marginHorizontal: 16,

  },
  errorContainer: {
    paddingVertical: 30,
    paddingHorizontal: 10,
  },
  noContractsText: {
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Fonts.size.h6,
    marginTop: 40,
    textAlign: 'center',
  },
})
