import { StyleSheet } from 'react-native'
import { Fonts, Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

const styles = StyleSheet.create({
  titleStyle: {
    marginLeft: -16,
  },
  closeStyle: {
    marginRight: -16,
  },
  title: {
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Fonts.size.h4,
    marginVertical: 20,
  },
  desc: {
    fontFamily: Fonts.type.VFFont,
    fontSize: Fonts.size.h6,
    lineHeight: 20,
    marginBottom: 10,
  },
  button: {
    marginVertical: 10,
  },
  buttonContainer: {
    marginTop: 20,
    flex: 1,
    justifyContent: 'flex-end'
  },
  inputText: {
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Fonts.size.h6,
  },
  niptInput: {
    width: '85%',
    marginHorizontal: 0,
  },
  niptInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // marginTop: 20,
  },
  icon: {
    marginTop: 8,
  },
  input: {
    marginHorizontal: 0,
    marginVertical: 5,
  },
  numberText: {
    fontFamily: Fonts.type.bold.fontFamily,
    fontWeight: Fonts.type.bold.fontWeight,
    fontSize: Fonts.size.h5,
    marginBottom: 30,
  },
  resendTextDisabled: theme => ({
    fontFamily: Fonts.type.VFFont,
    fontSize: Fonts.size.h6,
    color: theme.colors.textColorTwo,
    textAlign: 'center',
    marginBottom: 20,
  }),
  resendText: {
    fontFamily: Fonts.type.VFFont,
    fontSize: Fonts.size.h6,
    color: Colors.vfRed,
    textAlign: 'center',
    marginBottom: 20,
  },
  errorContainer: {
    marginHorizontal: 0,
    marginBottom: 30,
    width: 300,
    marginTop: -5,
  },
  errorIcon: {
    paddingLeft: 10,
    paddingRight: 12,
  },
  boldText: {
    ...Fonts.type.bold,
    fontSize: Fonts.size.h5,
    textAlign: 'center',
  },
  fixedLineContainer: {
    flexWrap: 'wrap',
    minWidth: 100,
    maxWidth: 200,
    paddingHorizontal: 25,
    paddingVertical: 10,
    borderRadius: 8,
    borderWidth: 2,
    borderColor: Colors.ocean,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10
  },
  modalContentWraper: {
    flexGrow: 1,
    paddingHorizontal: 16,
    paddingVertical: 32,
    marginTop: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  lottie: {
    width: 150,
    height: 150,
  },
  listContainer: {
    marginTop: 30,
  },
  dropdown: {
    marginHorizontal: 0,
    height: 55,
  },
  verifyCodeCell: theme => ({
    color: theme.colors.textColor,
    borderColor: theme.colors.textInput.default.baseColor,
  }),
  disabledStyle: theme => ({
    backgroundColor: theme.colors.buttons.disabledThree,
  }),
  dropdownContainer: {
    marginBottom: 15,
  }
})

export default styles
