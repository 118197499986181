/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import {
  View,
  Image,
  SafeAreaView,
  TouchableOpacity,
  ImageBackground,
  LayoutAnimation,
  Platform,
  NativeModules,
  Dimensions
} from 'react-native'
import moment from 'moment'
import { connect } from 'react-redux'
import { VFScreen } from '@vfgroup-oneplatform/foundation/Components'

import { actions as heartCollectionActions } from '../VfHeartsCollection/VfHeartsCollection.actions'
import Splash from '../Splash'
import Loader from '../../Components/Loader'
import VfText from '../../Components/VfText'

import { __translate } from '../../Utils'

import Images from '../../Themes/Images'
import colors from '../../Themes/Colors'
import styles from './VfHearts.styles'
import { actions } from './VfHearts.actions'

class VfLoveBoxComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      timer: null,
      confirmActivation: false,
    }
  }

  infoScroll = React.createRef();

  componentDidMount() {
    this.props.activeComponent()
    this.props.getServiceBalance();

    this._blurSubscription = this.props.navigation.addListener(
      'blur',
      this._handleFocusOut,
    )
    this._focusSubscription = this.props.navigation.addListener(
      'focus',
      this._handleFocusIn,
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const { serviceBalanceState } = this.props

    if (this._countdown) {
      return
    }

    if (
      !serviceBalanceState.error &&
      serviceBalanceState.data &&
      !serviceBalanceState.data.isEligible
    ) {
      this.startCountDown()
    }
  }

  navigateToInfo = () => {
    this.props.activateInfo(true)
    this.props.navigation.navigate('VfHeartsCollection')
  };

  startCountDown = () => {
    const { serviceBalanceState } = this.props

    if (!serviceBalanceState.data.date) {
      return
    }

    const date = moment(
      serviceBalanceState.data.date,
      serviceBalanceState.data.dateFormat,
    )
    let diff, duration, hours, minutes, seconds
    this._countdown = setInterval(() => {
      diff = date.diff(moment(), 'miliseconds')

      if (diff < 0) {
        this._handleFocusIn()
        clearInterval(this._countdown)
        this._countdown = null
        return
      }

      duration = moment.duration(diff)
      hours = duration.hours() < 10 ? '0' + duration.hours() : duration.hours()
      minutes =
        duration.minutes() < 10 ? '0' + duration.minutes() : duration.minutes()
      seconds =
        duration.seconds() < 10 ? '0' + duration.seconds() : duration.seconds()
      this.setState({
        timer: hours + ':' + minutes + ':' + seconds,
      })
    }, 1000)
  };

  _handleFocusOut = () => {
    clearInterval(this._countdown)
    this._countdown = null
  };

  _handleFocusIn = () => {
    this.props.activeComponent()
    this.props.getServiceBalance()
  };

  componentWillUnmount() {
    clearInterval(this._countdown)
    this._blurSubscription()
    this._focusSubscription()
  }

  getcontent = () => {
    const { serviceBalanceState, reddemLoveBoxState, redeemLbItem } = this.props

    if (redeemLbItem.isSuccess && redeemLbItem.data) {
      return (
        <>
          <View style={styles.infoContainer}>
            <TouchableOpacity onPress={() => this.navigateToInfo()}>
              <Image
                source={Images.info}
                style={styles.info}
                resizeMode="contain"
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.props.resetRedeem()}>
              <Image
                source={Images.lb_close}
                style={styles.close}
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
          <View style={styles.contentContainer}>
            <VfText size="h2" weight="bold" style={{ marginBottom: 32 }}>
              {__translate('lovebox_success_msg')}
            </VfText>
            <ImageBackground
              source={Images.fl_success_hearts}
              style={[styles.success_error_image, styles.success_bg_image]}
              resizeMode="contain">
              <VfText
                size="h2"
                weight="bold"
                align="center"
                style={styles.heartText}>
                {redeemLbItem.data.name}
              </VfText>
            </ImageBackground>
            <VfText
              size="h5"
              align="center"
              style={{ marginTop: 32, marginBottom: 0, marginHorizontal: 64 }}>
              {__translate('lovebox_success_2') + ' '}
              <VfText size="h5" weight="bold" align="center">
                {redeemLbItem.data.name}
              </VfText>
            </VfText>
          </View>
        </>
      )
    } else if (serviceBalanceState.loading || reddemLoveBoxState.loading) {
      return (
        <>
          <View style={styles.infoContainer}>
            <TouchableOpacity onPress={() => this.navigateToInfo()}>
              <Image
                source={Images.info}
                style={styles.info}
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
          <View style={[styles.contentContainer, styles.loadingCenter]}>
            <Loader />
          </View>
        </>
      )
    } else if (redeemLbItem.error) {
      return (
        <>
          <View style={styles.infoContainer}>
            <TouchableOpacity onPress={() => this.navigateToInfo()}>
              <Image
                source={Images.info}
                style={styles.info}
                resizeMode="contain"
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.props.resetRedeem()}>
              <Image
                source={Images.lb_close}
                style={styles.close}
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
          <View style={styles.contentContainer}>
            <VfText size="h2" weight="bold" style={{ marginBottom: 32 }}>
              {__translate('love_box_error_msg_2')}
            </VfText>
            <Image
              source={Images.fl_error_hearts}
              style={[styles.success_error_image, styles.error_image]}
              resizeMode="contain"
            />
            <VfText
              size="h5"
              align="center"
              style={{ marginTop: 32, marginBottom: 0, marginHorizontal: 64 }}>
              {redeemLbItem.error}
            </VfText>
          </View>
        </>
      )
    } else if (serviceBalanceState.error || !serviceBalanceState.data) {
      return (
        <>
          <View style={styles.infoContainer}>
            <TouchableOpacity onPress={() => this.navigateToInfo()}>
              <Image
                source={Images.info}
                style={styles.info}
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
          <View style={styles.contentContainer}>
            <VfText size="h2" weight="bold" style={{ marginBottom: 32 }}>
              {__translate('love_box_error_msg_2')}
            </VfText>
            <Image
              source={Images.fl_error_hearts}
              style={[styles.success_error_image, styles.error_image]}
              resizeMode="contain"
            />
            <VfText style={{ margin: 32 }}>{serviceBalanceState.error}</VfText>
            <TouchableOpacity
              onPress={() => this.props.getServiceBalance()}
              style={styles.button}>
              <VfText style={styles.buttonText}>
                {__translate('lovebox_error_3')}
              </VfText>
            </TouchableOpacity>
          </View>
        </>
      )
    } else if (serviceBalanceState.data.category === 'recharge') {
      return (
        <>
          <View style={styles.infoContainer}>
            <TouchableOpacity onPress={() => this.navigateToInfo()}>
              <Image
                source={Images.info}
                style={styles.info}
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
          <View style={styles.contentContainer}>
            <VfText size="h2" weight="bold" style={{ marginBottom: 32 }}>
              {__translate('love_box_error_msg_2')}
            </VfText>
            <Image
              source={Images.fl_error_hearts}
              style={[styles.success_error_image, styles.error_image]}
              resizeMode="contain"
            />
            <VfText align="center" style={{ margin: 32 }}>
              {serviceBalanceState.data.desc}
            </VfText>
          </View>
        </>
      )
    } else if (reddemLoveBoxState.error) {
      return (
        <>
          <View style={styles.infoContainer}>
            <TouchableOpacity onPress={() => this.navigateToInfo()}>
              <Image
                source={Images.info}
                style={styles.info}
                resizeMode="contain"
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.props.resetActivateLoveBox()}>
              <Image
                source={Images.lb_close}
                style={styles.close}
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
          <View style={styles.contentContainer}>
            <VfText size="h2" weight="bold" style={{ marginBottom: 32 }}>
              {__translate('love_box_error_msg_2')}
            </VfText>
            <Image
              source={Images.fl_error_hearts}
              style={[styles.success_error_image, styles.error_image]}
              resizeMode="contain"
            />
            <VfText
              size="h5"
              align="center"
              style={{ marginTop: 32, marginBottom: 0 }}>
              {reddemLoveBoxState.error}
            </VfText>
          </View>
        </>
      )
    } else if (reddemLoveBoxState.isSuccess) {
      return (
        <>
          <View style={styles.infoContainer}>
            <TouchableOpacity onPress={() => this.navigateToInfo()}>
              <Image
                source={Images.info}
                style={styles.info}
                resizeMode="contain"
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => this.props.resetActivateLoveBox(true)}>
              <Image
                source={Images.lb_close}
                style={styles.close}
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
          <View style={styles.contentContainer}>
            <VfText size="h2" weight="bold" style={{ marginBottom: 32 }}>
              {__translate('lovebox_success_msg')}
            </VfText>
            <Image
              source={Images.fl_success_hearts}
              style={[styles.success_error_image, styles.success_image]}
              resizeMode="contain"
            />
            <VfText
              size="h5"
              align="center"
              style={{ marginTop: 32, marginBottom: 0 }}>
              {__translate('lovebox_success') + ' '}
            </VfText>
            <VfText size="h5" weight="bold" align="center">
              {reddemLoveBoxState.data.name + ' '}
              <VfText size="h5" style={{ marginBottom: 32, marginTop: 0 }}>
                {__translate('heart_collection_msg_2')}
              </VfText>
              {' ' + reddemLoveBoxState.data.desc}
            </VfText>
          </View>
        </>
      )
    } else if (serviceBalanceState.data.isEligible) {
      return (
        <>
          <View style={styles.infoContainer}>
            <TouchableOpacity onPress={() => this.navigateToInfo()}>
              <Image
                source={Images.info}
                style={styles.info}
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
          <View style={styles.contentContainer}>
            <VfText
              size="h2"
              align="center"
              weight="bold"
              style={{ marginBottom: 0 }}>
              {__translate('lovebox_title')}
            </VfText>
            <VfText
              size="h2"
              weight="bold"
              align="center"
              style={{ color: colors.red, marginTop: 0, marginBottom: 0 }}>
              {__translate('lovebox_title_2')}
            </VfText>
            <VfText
              size="h2"
              align="center"
              weight="bold"
              style={{ marginTop: 0 }}>
              {__translate('lovebox_title_3')}
            </VfText>
            <VfText
              size="h5"
              align="center"
              style={{ marginVertical: 32, marginHorizontal: 64 }}>
              {serviceBalanceState.data.desc}
            </VfText>
            <VfText size="h5" align="center" style={{ marginHorizontal: 64 }}>
              {__translate('lovebox_activate_now')}
              <VfText
                size="h5"
                weight="bold"
                style={{ marginBottom: 32, marginTop: 0 }}>
                {` ${serviceBalanceState.data.amount} ${serviceBalanceState.data.currency
                  }`}
              </VfText>
            </VfText>

            <View style={{ flex: 1, justifyContent: 'flex-end', width: '100%', padding: 20 }}>
              <TouchableOpacity
                onPress={() => {
                  LayoutAnimation.configureNext(
                    LayoutAnimation.Presets.easeInEaseOut,
                  )
                  this.setState({ confirmActivation: true })
                }}
                style={styles.buttonBottom}>
                <VfText style={styles.buttonText}>
                  {__translate('lovebox_yes_button')}
                </VfText>
              </TouchableOpacity>
            </View>
          </View>
        </>
      )
    } else {
      return (
        <>
          <View style={styles.infoContainer}>
            <TouchableOpacity onPress={() => this.navigateToInfo()}>
              <Image
                source={Images.info}
                style={styles.info}
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
          <View style={styles.contentContainer}>
            <VfText
              size="h2"
              align="center"
              weight="bold"
              style={{ marginBottom: 0 }}>
              {__translate('lovebox_title')}
            </VfText>
            <VfText
              size="h2"
              weight="bold"
              align="center"
              style={{ color: '#e60000', marginTop: 0, marginBottom: 0 }}>
              {__translate('lovebox_title_2')}
            </VfText>
            <VfText
              size="h2"
              align="center"
              weight="bold"
              style={{ marginTop: 0 }}>
              {__translate('lovebox_title_3')}
            </VfText>
            <VfText
              size="h5"
              align="center"
              style={{ marginVertical: 32, marginHorizontal: 64 }}>
              {serviceBalanceState.data.desc}
              <VfText size="h5" weight="bold">
                {__translate('lovebox_subtitle_2')}
              </VfText>
            </VfText>
            <VfText
              key={'countdown'}
              size="h2"
              weight="bold"
              style={{ color: '#e60000' }}>
              {this.state.timer}
            </VfText>
          </View>
        </>
      )
    }
  };

  getBgImage = () => {
    const { serviceBalanceState, reddemLoveBoxState, redeemLbItem } = this.props

    if (
      reddemLoveBoxState.error ||
      serviceBalanceState.error ||
      redeemLbItem.error
    ) {
      return Images.fl_error
    }

    if (serviceBalanceState.loading || reddemLoveBoxState.loading) {
      return Images.fl_main_hearts_bg
    }

    return Images.fl_main_hearts_bg
  };

  onClose = async () => {
    if (this.props.route?.params?.isDeepLink) {
      this.props.navigation.navigate('Dashboard')
    } else {
      this.props.navigation.pop()
    }
  }

  render() {
    const { splash } = this.props
    if (splash.isCache || !splash.data) {
      return <Splash component={'LoveBox'} />
    }

    if (
      splash.isCache ||
      !splash.data ||
      !splash?.data[splash.data.locale] ||
      !splash?.data[splash.data.locale].LoveBox
    ) {
      return <Splash component={'LoveBox'} />
    }


    const { serviceBalanceState } = this.props

    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
      return (
        <VFScreen
          title={'Flower Box'}
          onClose={this.onClose}
          contentContainerStyle={{
            backgroundColor: colors.aluminium,
          }}
        >
          <View style={styles.safeArea}>
            {this.state.confirmActivation ? (
              <View style={[styles.container, styles.mainContainer]}>
                <View style={styles.innerContainer}>
                  <ImageBackground
                    imageStyle={{}}
                    source={Images.fl_redeem_flowers_bg}
                    style={styles.imgBg}>
                    <View style={[styles.contentContainer, styles.spaceBetween]}>
                      <View style={styles.confirmActivationHeader}>
                        <TouchableOpacity
                          onPress={() => {
                            LayoutAnimation.configureNext(
                              LayoutAnimation.Presets.easeInEaseOut,
                            )
                            this.setState({ confirmActivation: false })
                          }}>
                          <Image
                            source={Images.lb_close}
                            style={styles.close}
                            resizeMode="contain"
                          />
                        </TouchableOpacity>
                      </View>
                      <View>
                        <View
                          style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 16,
                          }}>
                          <ImageBackground
                            source={Images.fl_activate_flower}
                            resizeMode="contain"
                            imageStyle={{
                              width: '200%',
                              height: '200%',
                              left: '-50%',
                              top: '-45%',
                            }}
                            style={{
                              width: 100,
                              height: 100,
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          />
                        </View>
                        <View style={{ marginTop: 90 }}>
                          <VfText
                            size="h4"
                            align="center"
                            style={{
                              marginBottom: 32,
                              marginTop: 0,
                              marginHorizontal: 64,
                            }}>
                            {__translate('lovebox_confirmation_msg_1')}
                            <VfText
                              size="h4"
                              align="center"
                              weight="bold"
                              style={{}}>
                              {__translate('lovebox_confirmation_msg_2')}
                            </VfText>
                            {__translate('lovebox_confirmation_msg_3')}
                            <VfText
                              size="h4"
                              align="center"
                              weight="bold"
                              style={{}}>
                              {serviceBalanceState.data?.amount +
                                ' ' +
                                serviceBalanceState.data?.currency +
                                '?'}
                            </VfText>
                          </VfText>
                        </View>
                      </View>
                      <View style={{ flex: 1, justifyContent: 'flex-end', width: '100%', padding: 20 }} >
                        <TouchableOpacity
                          onPress={() => {
                            this.props.activateLoveBox(serviceBalanceState.data)
                            LayoutAnimation.configureNext(
                              LayoutAnimation.Presets.easeInEaseOut,
                            )
                            this.setState({ confirmActivation: false })
                          }}
                          style={styles.primaryButtonCopy}>
                          <VfText style={styles.primaryButtonText}>
                            {__translate('lovebox_confirmation_positive')}
                          </VfText>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => {
                            LayoutAnimation.configureNext(
                              LayoutAnimation.Presets.easeInEaseOut,
                            )
                            this.setState({ confirmActivation: false })
                          }}
                          style={styles.secondaryButtonCopy}>
                          <VfText style={styles.secondaryButtonText}>
                            {__translate('lovebox_confirmation_negative')}
                          </VfText>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </ImageBackground>
                </View>
              </View>
            ) : (
              <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'scroll', paddingBottom: '7%' }}>
                <View style={[styles.container, styles.mainContainer, { minHeight: 350, marginBottom: 20 }]}>
                  <View style={styles.innerContainer}>
                    <ImageBackground
                      imageStyle={{}}
                      source={this.getBgImage()}
                      style={styles.imgBg}>
                      {this.getcontent()}
                    </ImageBackground>
                  </View>
                </View>
                <View style={[styles.container, styles.bottomContainer]}>
                  <TouchableOpacity
                    style={styles.innerContainer}
                    onPress={() => {
                      this.props.getUserProfile()
                      this.props.getAllItems()
                      this.props.navigation.navigate('VfHeartsCollection')
                    }}
                    activeOpacity={0.5}>
                    <ImageBackground
                      source={Images.fl_bottom_hearts_bg}
                      resizeMode="cover"
                      style={[styles.imgBg, styles.rowCenter]}>
                      <View
                        style={{
                          flex: 4,
                          flexDirection: 'row',
                          alignItems: 'baseline',
                        }}>
                        {Platform.OS === 'android' || Platform.OS === 'web' && (
                          <VfText
                            adjustsFontSizeToFit
                            numberOfLines={2}
                            size="h2"
                            style={{
                              color: 'white',
                              includeFontPadding: false,
                              paddingHorizontal: 8,
                              textShadowColor: 'rgba(0, 0, 0, 0.32)',
                              textShadowOffset: { width: 0, height: 2 },
                              textShadowRadius: 8,
                            }}>
                            {__translate('heart_collection_button')}
                            <Image
                              source={Images.chevron_right_white}
                              resizeMode="contain"
                              style={{
                                height: 18,
                                width: 18,
                                marginLeft: 25,
                                overflow: 'visible',
                                shadowColor: colors.black,
                                shadowOffset: { width: 0, height: 2 },
                                shadowOpacity: 0.32,
                                shadowRadius: 8,
                              }}
                            />
                          </VfText>
                        )}

                        {Platform.OS === 'ios' && (
                          <>
                            <VfText
                              adjustsFontSizeToFit
                              numberOfLines={2}
                              size="h2"
                              style={{
                                color: 'white',
                                includeFontPadding: false,
                                paddingHorizontal: 8,
                                textShadowColor: 'rgba(0, 0, 0, 0.32)',
                                textShadowOffset: { width: 0, height: 2 },
                                textShadowRadius: 8,
                              }}>
                              {__translate('heart_collection_button')}
                            </VfText>
                            <Image
                              source={Images.chevron_right_white}
                              resizeMode="contain"
                              style={{
                                height: 18,
                                width: 18,
                                marginLeft: '-15%',
                                overflow: 'visible',
                                shadowColor: colors.black,
                                shadowOffset: { width: 0, height: 2 },
                                shadowOpacity: 0.32,
                                shadowRadius: 8,
                              }}
                            />
                          </>
                        )}
                      </View>
                      <View
                        style={{
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%'
                        }}>
                        <Image
                          source={Images.fl_hearts}
                          resizeMode="contain"
                          style={{
                            flex: 1,
                            position: 'initial'
                          }}
                        />
                      </View>
                    </ImageBackground>
                  </TouchableOpacity>
                </View>
              </div>
            )}
          </View>
        </VFScreen>
      )
    }

    return (
      <VFScreen
        headerStyle={{ backgroundColor: '#fff' }}
        title={__translate('flower_landing_title')}
        onClose={this.onClose}
        contentContainerStyle={{
          height: 'auto'
        }}
      >
        <SafeAreaView style={styles.safeArea}>
          {this.state.confirmActivation ? (
            <View style={[styles.container, styles.mainContainer]}>
              <View style={styles.innerContainer}>
                <ImageBackground
                  imageStyle={{}}
                  source={Images.fl_redeem_flowers_bg}
                  style={styles.imgBg}>
                  <View style={[styles.contentContainer, styles.spaceBetween]}>
                    <View style={styles.confirmActivationHeader}>
                      <TouchableOpacity
                        onPress={() => {
                          LayoutAnimation.configureNext(
                            LayoutAnimation.Presets.easeInEaseOut,
                          )
                          this.setState({ confirmActivation: false })
                        }}>
                        <Image
                          source={Images.lb_close}
                          style={styles.close}
                          resizeMode="contain"
                        />
                      </TouchableOpacity>
                    </View>
                    <View>
                      <View>
                        <VfText
                          size="h4"
                          align="center"
                          style={{
                            marginBottom: 32,
                            marginTop: 0,
                            marginHorizontal: 64,
                          }}>
                          {__translate('lovebox_confirmation_msg_1')}
                          <VfText
                            size="h4"
                            align="center"
                            weight="bold"
                            style={{}}>
                            {__translate('lovebox_confirmation_msg_2')}
                          </VfText>
                          {__translate('lovebox_confirmation_msg_3')}
                          <VfText
                            size="h4"
                            align="center"
                            weight="bold"
                            style={{}}>
                            {serviceBalanceState.data?.amount +
                              ' ' +
                              serviceBalanceState.data?.currency +
                              '?'}
                          </VfText>
                        </VfText>
                      </View>
                      <View
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: 16,
                        }}>
                        <ImageBackground
                          source={Images.fl_activate_flower}
                          resizeMode="contain"
                          imageStyle={{
                            width: '200%',
                            height: '200%',
                            left: '-50%',
                            top: '-45%',
                          }}
                          style={{
                            width: 100,
                            height: 100,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        />
                      </View>
                    </View>
                    <View>
                      <TouchableOpacity
                        onPress={() => {
                          this.props.activateLoveBox(serviceBalanceState.data)
                          LayoutAnimation.configureNext(
                            LayoutAnimation.Presets.easeInEaseOut,
                          )
                          this.setState({ confirmActivation: false })
                        }}
                        style={styles.primaryButton}>
                        <VfText style={styles.primaryButtonText}>
                          {__translate('lovebox_confirmation_positive')}
                        </VfText>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          LayoutAnimation.configureNext(
                            LayoutAnimation.Presets.easeInEaseOut,
                          )
                          this.setState({ confirmActivation: false })
                        }}
                        style={styles.secondaryButton}>
                        <VfText style={styles.secondaryButtonText}>
                          {__translate('lovebox_confirmation_negative')}
                        </VfText>
                      </TouchableOpacity>
                    </View>
                  </View>
                </ImageBackground>
              </View>
            </View>
          ) : (
            <>
              <View style={[styles.container, styles.mainContainer]}>
                <View style={styles.innerContainer}>
                  <ImageBackground
                    imageStyle={{}}
                    source={this.getBgImage()}
                    style={styles.imgBg}>
                    {this.getcontent()}
                  </ImageBackground>
                </View>
              </View>
              <View style={[styles.container, styles.bottomContainer]}>
                <TouchableOpacity
                  style={styles.innerContainer}
                  onPress={() => {
                    this.props.getUserProfile()
                    this.props.getAllItems()
                    this.props.navigation.navigate('VfHeartsCollection')
                  }}
                  activeOpacity={0.5}>
                  <ImageBackground
                    source={Images.fl_bottom_hearts_bg}
                    resizeMode="cover"
                    style={[styles.imgBg, styles.rowCenter]}>
                    <View
                      style={{
                        flex: 4,
                        flexDirection: 'row',
                        alignItems: 'baseline',
                      }}>
                      {Platform.OS === 'android' || Platform.OS === 'web' && (
                        <VfText
                          adjustsFontSizeToFit
                          numberOfLines={2}
                          size="h2"
                          style={{
                            color: 'white',
                            includeFontPadding: false,
                            paddingHorizontal: 8,
                            textShadowColor: 'rgba(0, 0, 0, 0.32)',
                            textShadowOffset: { width: 0, height: 2 },
                            textShadowRadius: 8,
                          }}>
                          {__translate('heart_collection_button')}
                          <Image
                            source={Images.chevron_right_white}
                            resizeMode="contain"
                            style={{
                              height: 18,
                              width: 18,
                              marginLeft: 25,
                              overflow: 'visible',
                              shadowColor: colors.black,
                              shadowOffset: { width: 0, height: 2 },
                              shadowOpacity: 0.32,
                              shadowRadius: 8,
                            }}
                          />
                        </VfText>
                      )}

                      {Platform.OS === 'ios' && (
                        <>
                          <VfText
                            adjustsFontSizeToFit
                            numberOfLines={2}
                            size="h2"
                            style={{
                              color: 'white',
                              includeFontPadding: false,
                              paddingHorizontal: 8,
                              textShadowColor: 'rgba(0, 0, 0, 0.32)',
                              textShadowOffset: { width: 0, height: 2 },
                              textShadowRadius: 8,
                            }}>
                            {__translate('heart_collection_button')}
                          </VfText>
                          <Image
                            source={Images.chevron_right_white}
                            resizeMode="contain"
                            style={{
                              height: 18,
                              width: 18,
                              marginLeft: '-15%',
                              overflow: 'visible',
                              shadowColor: colors.black,
                              shadowOffset: { width: 0, height: 2 },
                              shadowOpacity: 0.32,
                              shadowRadius: 8,
                            }}
                          />
                        </>
                      )}
                    </View>
                    <View
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                      }}>
                      <Image
                        source={Images.fl_hearts}
                        resizeMode="contain"
                        style={{
                          flex: 1,
                          position: 'initial'
                        }}
                      />
                    </View>
                  </ImageBackground>
                </TouchableOpacity>
              </View>
            </>
          )}
        </SafeAreaView>
      </VFScreen>
    )
  }
}

VfLoveBoxComponent.defaultProps = {}

function mapDispatchToProps(dispatch) {
  return {
    getServiceBalance: isBackground =>
      dispatch(actions.getServiceBalance(isBackground)),
    activateLoveBox: serviceBalanceDetails =>
      dispatch(actions.activateLoveBox(serviceBalanceDetails)),
    resetServiceBalance: () => dispatch(actions.resetServiceBalance()),
    resetActivateLoveBox: refresh =>
      dispatch(actions.resetActivateLoveBox(refresh)),
    activateInfo: goBack =>
      dispatch(heartCollectionActions.activateInfo(goBack)),
    resetRedeem: () => dispatch(heartCollectionActions.resetRedeem()),
    getUserProfile: () => dispatch(heartCollectionActions.getUserProfile()),
    getAllItems: () => dispatch(heartCollectionActions.getAllItems()),
    activeComponent: () => dispatch({
      type: 'ACTIVE_COMPONENT',
      payload: 'LoveBox'
    })
  }
}

const mapStateToProps = state => {
  const { serviceBalanceState, reddemLoveBoxState, redeemLbItem, splash } = state
  return { serviceBalanceState, reddemLoveBoxState, redeemLbItem, splash }
}

const VfHearts = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VfLoveBoxComponent)

export default VfHearts
