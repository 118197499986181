export const citiesList = [
  {
    'label': 'Belsh',
    'value': 'Belsh'
  },
  {
    'label': 'Berat',
    'value': 'Berat'
  },
  {
    'label': 'Bulqizë',
    'value': 'Bulqizë'
  },
  {
    'label': 'Cërrik',
    'value': 'Cërrik'
  },
  {
    'label': 'Delvinë',
    'value': 'Delvinë'
  },
  {
    'label': 'Devoll',
    'value': 'Devoll'
  },
  {
    'label': 'Dibër',
    'value': 'Dibër'
  },
  {
    'label': 'Divjakë',
    'value': 'Divjakë'
  },
  {
    'label': 'Dropull',
    'value': 'Dropull'
  },
  {
    'label': 'Durrës',
    'value': 'Durrës'
  },
  {
    'label': 'Elbasan',
    'value': 'Elbasan'
  },
  {
    'label': 'Fier',
    'value': 'Fier'
  },
  {
    'label': 'Finiq',
    'value': 'Finiq'
  },
  {
    'label': 'Fushë-Arrëz',
    'value': 'Fushë-Arrëz'
  },
  {
    'label': 'Gjirokastër',
    'value': 'Gjirokastër'
  },
  {
    'label': 'Gramsh',
    'value': 'Gramsh'
  },
  {
    'label': 'Has',
    'value': 'Has'
  },
  {
    'label': 'Himarë',
    'value': 'Himarë'
  },
  {
    'label': 'Kamëz',
    'value': 'Kamëz'
  },
  {
    'label': 'Kavajë',
    'value': 'Kavajë'
  },
  {
    'label': 'Klos',
    'value': 'Klos'
  },
  {
    'label': 'Kolonjë',
    'value': 'Kolonjë'
  },
  {
    'label': 'Konispol',
    'value': 'Konispol'
  },
  {
    'label': 'Korçë',
    'value': 'Korçë'
  },
  {
    'label': 'Krujë',
    'value': 'Krujë'
  },
  {
    'label': 'Kukës',
    'value': 'Kukës'
  },
  {
    'label': 'Kurbin',
    'value': 'Kurbin'
  },
  {
    'label': 'Kuçovë',
    'value': 'Kuçovë'
  },
  {
    'label': 'Këlcyrë',
    'value': 'Këlcyrë'
  },
  {
    'label': 'Lezhë',
    'value': 'Lezhë'
  },
  {
    'label': 'Libohovë',
    'value': 'Libohovë'
  },
  {
    'label': 'Librazhd',
    'value': 'Librazhd'
  },
  {
    'label': 'Lushnje',
    'value': 'Lushnje'
  },
  {
    'label': 'Maliq',
    'value': 'Maliq'
  },
  {
    'label': 'Mallakastër',
    'value': 'Mallakastër'
  },
  {
    'label': 'Malësi e Madhe',
    'value': 'Malësi e Madhe'
  },
  {
    'label': 'Mat',
    'value': 'Mat'
  },
  {
    'label': 'Memaliaj',
    'value': 'Memaliaj'
  },
  {
    'label': 'Mirditë',
    'value': 'Mirditë'
  },
  {
    'label': 'Patos',
    'value': 'Patos'
  },
  {
    'label': 'Peqin',
    'value': 'Peqin'
  },
  {
    'label': 'Pogradec',
    'value': 'Pogradec'
  },
  {
    'label': 'Poliçan',
    'value': 'Poliçan'
  },
  {
    'label': 'Prrenjas',
    'value': 'Prrenjas'
  },
  {
    'label': 'Pukë',
    'value': 'Pukë'
  },
  {
    'label': 'Pustec',
    'value': 'Pustec'
  },
  {
    'label': 'Përmet',
    'value': 'Përmet'
  },
  {
    'label': 'Roskovec',
    'value': 'Roskovec'
  },
  {
    'label': 'Rrogozhinë',
    'value': 'Rrogozhinë'
  },
  {
    'label': 'Sarandë',
    'value': 'Sarandë'
  },
  {
    'label': 'Selenicë',
    'value': 'Selenicë'
  },
  {
    'label': 'Shijak',
    'value': 'Shijak'
  },
  {
    'label': 'Shkodër',
    'value': 'Shkodër'
  },
  {
    'label': 'Skrapar',
    'value': 'Skrapar'
  },
  {
    'label': 'Tepelenë',
    'value': 'Tepelenë'
  },
  {
    'label': 'Tiranë',
    'value': 'Tiranë'
  },
  {
    'label': 'Tropojë',
    'value': 'Tropojë'
  },
  {
    'label': 'Ura Vajgurore',
    'value': 'Ura Vajgurore'
  },
  {
    'label': 'Vau i Dejës',
    'value': 'Vau i Dejës'
  },
  {
    'label': 'Vlorë',
    'value': 'Vlorë'
  },
  {
    'label': 'Vorë',
    'value': 'Vorë'
  }
]
