import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'
import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import ReduxCache from '../Utils/ReduxCache'

import { API_URLS } from './Config'


const defaultResponseMapper = (response) => {
  const categories = []
  const characteristics = {}

  try {
    response.map(item => {
      if (item.recommendationType === 'pega_offer') {
        item.category.map(category => {
          if (category.id === 'RecommendationIssue' || category.id === 'RecommendationGroup') {
            categories.push(category.name)
          }
        })

        item.recommendationItem.map(recommendation => {

          recommendation.product?.productCharacteristic.map(characteristic => {
            if (!characteristics[item.id]) {
              characteristics[item.id] = {
                name: item.name,
                characteristics: {},
                agreementIds: []
              }
            }

            if (!characteristics[item.id].characteristics[characteristic.name]) {
              characteristics[item.id].characteristics[characteristic.name] = []
            }
            characteristics[item.id].characteristics[characteristic.name].push(characteristic.value)
          })

          if (recommendation?.product?.agreement?.[0]?.id) {
            characteristics[item.id].agreementIds.push(recommendation.product.agreement[0].id)
          }
        })
      }
    })
  } catch (error) {
    console.error(error)
  }


  return {
    categories: categories,
    characteristics: characteristics
  }
}

const GetPEGA = async (type, customHeaders = {}, utilities = { responseMapper: defaultResponseMapper }) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

  onStart()
  const msisdn = ReduxCache.getMsisdn()
  const params = {
    'relatedParty.id': msisdn,
    type: type,
  }

  try {
    const response = await axios.get(API_URLS.pega, { headers: customHeaders, params: params })

    const _response = responseMapper(response.data)

    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

export { GetPEGA }
