/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

import { isArray } from 'lodash'

import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'
import moment from 'moment'

import {
  getInternetOffers as _getInternetOffers,
  getRenewalOffers,
  getTvOffers,
} from '../../Services'

const actionTypes = {
  REQUEST_OFFERS: 'REQUEST_OFFERS',
  SUCCESS_OFFERS: 'SUCCESS_OFFERS',
  ERROR_OFFERS: 'ERROR_OFFERS',

  SELECT_BUNDLE: 'SELECT_BUNDLE',
  SELECT_TV_OFFER: 'SELECT_TV_OFFER',
  SELECT_TV_ADD_ON: 'SELECT_TV_ADD_ON',
  CLEAR_SELECTED_BUNDLE: 'CLEAR_SELECTED_BUNDLE',

  REQUEST_INTERNET_OFFERS: 'REQUEST_INTERNET_OFFERS',
  SUCCESS_INTERNET_OFFERS: 'SUCCESS_INTERNET_OFFERS',
  ERROR_INTERNET_OFFERS: 'ERROR_INTERNET_OFFERS',
  CLEAR_INTERNET_OFFERS: 'CLEAR_INTERNET_OFFERS',


  REQUEST_TV_OFFERS: 'REQUEST_TV_OFFERS',
  SUCCESS_TV_OFFERS: 'SUCCESS_TV_OFFERS',
  ERROR_TV_OFFERS: 'ERROR_TV_OFFERS',
  CLEAR_TV_OFFERS: 'CLEAR_TV_OFFERS',

}

const headers = {
  'vf-source': 'selfcare',
  'Content-Type': 'application/json',
  'vf-operator': 'myvodafone',
  'vf-country-code': 'al',
  'Accept-Language': 'locale',
}


const selectBundle = (bundle) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SELECT_BUNDLE,
      payload: bundle,
    })
  }
}
const selectTVOffer = (bundle) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SELECT_TV_OFFER,
      payload: bundle,
    })
  }
}
const selectTVAddOn = (addOn) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SELECT_TV_ADD_ON,
      payload: addOn,
    })
  }
}
const clearBundle = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.CLEAR_SELECTED_BUNDLE,
    })
  }
}

const clearInternetOfferData = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.CLEAR_INTERNET_OFFERS,
    })
  }
}

const clearTVOfferData = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.CLEAR_TV_OFFERS,
    })
  }
}

const errorMapper = (error) => {
  const payload = error?.response?.data?.message || error?.message
  return payload
}

const getPriceObj = (obj) => {
  if (obj) {
    const priceObj = obj[0]
    const { unit = '', value = '' } = priceObj?.price?.taxIncludedAmount || {}
    const localizedUnit = ContentManager.translate(unit)
    return {
      unit: localizedUnit,
      value
    }
  }
  return {
    unit: '',
    value: ''
  }
}

const getDurationObj = (obj) => {
  if (obj) {
    const { duration: { amount = '', units = '' } = {} } = obj.find(o => o['@type'] === 'commitmentTerm') || {}
    return {
      amount,
      units
    }
  }
  return {
    amount: '',
    units: ''
  }
}


const responseMapper = (res) => {
  const characteristicsTypes = [
    'downloadspeed',
    'uploadspeed',
    'fup',
  ]
  if (isArray(res.data)) {
    const mappedData = []
    res?.data.map((item, _i) => {
      const remItem = item.recommendationItem[0]
      const { product = {} } = remItem
      const { unit, value } = getPriceObj(product.productPrice)
      const { amount, units } = getDurationObj(product.productTerm)

      const { startDate } = product

      //Product Characteristic
      let productCharacteristicContent = []
      if (isArray(product?.productCharacteristic)) {
        if (product.productCharacteristic.find(el => el?.name === 'SpecSubType')?.value === 'Internet') {
          productCharacteristicContent = product.productCharacteristic.filter(_item => (
            characteristicsTypes.includes(_item?.name?.toLowerCase())
          )).map(item1 => ({
            title: item1?.value,
            type: item1?.name?.toLowerCase()
          }))
        } else if (product.productCharacteristic.find(el => el?.name === 'SpecSubType')?.value === 'CableTV') {
          productCharacteristicContent = item?.description.split('\n')?.filter(el => el !== '')
        }
      }

      const modemData = []
      let installationFee = {}

      //get modem data
      if (isArray(product?.productRelationship)) {
        product.productRelationship?.filter(el => el?.relationshipType === 'addon')?.map(item => {
          const { product } = item

          if (isArray(product?.productCharacteristic)) {
            if (product.productCharacteristic?.find(el => el?.name === 'SpecSubType')?.value === 'Modem') {
              const price = getPriceObj(product?.productPrice)
              modemData.push({
                id: product?.id,
                title: product?.name,
                price,
                modemData: item,
              })
            }

            if (product.productCharacteristic?.find(el => el?.name === 'SpecSubType')?.value === 'Fee') {
              const price = getPriceObj(product?.productPrice)
              installationFee = {
                id: product?.id,
                title: product?.name,
                price: price?.value,
                unit: price?.unit,
                installationData: item,
              }
            }
          }

        })
      }
      //Tv plan for renewal offer
      const tvPlan = {}
      if (isArray(item.recommendationItem)) {
        const tvIndex = item.recommendationItem.findIndex((_item, idx) => {
          const specSubTypeObj = _item.product.productCharacteristic.filter((characteristic) => characteristic?.name === 'SpecSubType')
          return specSubTypeObj[0].value === 'CableTV' || false
        })
        if (tvIndex > -1) {
          let tvProductCharacteristicContent = []
          const { product: tvProduct = {}, id = '' } = item.recommendationItem[tvIndex]
          const { unit: tvUnit, value: tvValue } = getPriceObj(tvProduct.productPrice)
          const { units: tvUnits } = getDurationObj(tvProduct.productTerm)

          //Product Characteristic
          if (isArray(product.productCharacteristic)) {
            tvProductCharacteristicContent = tvProduct.productCharacteristic.filter(_item => (
              characteristicsTypes.includes(_item?.name?.toLowerCase())
            )).map(item1 => ({
              title: item1?.value,
              type: item1?.name?.toLowerCase()
            }))
          }

          Object.assign(tvPlan,
            {
              id: id,
              title: tvProduct?.name || '',
              desc: tvValue + ' ' + tvUnit,
              period: `${tvUnits}`,
              content: tvProductCharacteristicContent,
              tvData: item,
            })
        }
      }

      //get tv addons
      const addOns = []
      //get tv resources
      const tvResources = []

      if (isArray(product.productRelationship)) {
        product.productRelationship?.filter(el => el?.relationshipType === 'addon').forEach((addOnItem, index) => {

          const { product = {} } = addOnItem

          if (isArray(product?.productCharacteristic)) {

            const addOnType = product?.productCharacteristic.find(el => el?.name === 'SpecType')?.value

            const price = getPriceObj(product?.productPrice)

            const { unit: addOnUnit, value: addOnValue } = price

            const { units: addOnUnits } = getDurationObj(product.productTerm)

            if (addOnType === 'Service') {
              //addons characteristics
              const addonsCharacteristicsContent = product?.description?.split('\n')?.filter(el => el !== '')

              addOns.push({
                id: product.id,
                title: product?.name || '',
                type: 'addon',
                data: addOnItem,
                addOnType,
                desc: addOnValue + ' ' + addOnUnit,
                period: `${addOnUnits}`,
                content: addonsCharacteristicsContent || [],
              })
            }

            if (addOnType === 'Resource') {
              tvResources.push({
                id: product.id,
                title: product?.name || '',
                type: 'addon',
                data: addOnItem,
                addOnType,
                price
              })
            }
          }

        })
      }

      mappedData.push({
        id: item.id,
        title: item?.recommendationType === 'pega_offer' ? product?.name : item?.name,
        recomandation_type: item?.recommendationType,
        desc: value + ' ' + unit,
        period: `${units}`,
        periodValue: `${amount}`,
        content: productCharacteristicContent,
        tvPlan: tvPlan,
        contractPeriod: startDate ? moment(startDate).format('DD/MM/YYYY') : '',
        addOns,
        location: product?.place?.[0]?.name,
        //
        description: item?.description,
        offerData: item,
        //for installation step
        modemData: modemData,
        tvResources,
        installationFee: installationFee
      })
    })
    return mappedData
  }

}

const getOffers = (utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_OFFERS,
    })


    getRenewalOffers({ ...headers, 'vf-project': 'pega_dnext' }, {
      ...utilities,
      responseMapper,
      errorMapper,
    })
      .then(response => dispatch({
        type: actionTypes.SUCCESS_OFFERS,
        payload: response,
      }))
      .catch(error => dispatch({
        type: actionTypes.ERROR_OFFERS,
        payload: error,
      }))
  }
}


const getInternetOffers = (id, utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_INTERNET_OFFERS,
    })

    if (id == 'Tirana') {
      id = 'Tirane'
    } else {
      id = id.replace('ë', 'e')
    }

    _getInternetOffers(id, { ...headers, 'vf-project': 'productcatalogue_dnext' }, {
      ...utilities,
      responseMapper,
      errorMapper,
    })
      .then(response => dispatch({
        type: actionTypes.SUCCESS_INTERNET_OFFERS,
        payload: response,
      }))
      .catch(error => {
        dispatch({
          type: actionTypes.ERROR_INTERNET_OFFERS,
          payload: error,
        })
      })
  }
}
const getTVOffers = (id, utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_TV_OFFERS,
    })

    if (id == 'Tirana') {
      id = 'Tirane'
    } else {
      id = id.replace('ë', 'e')
    }

    getTvOffers(id, { ...headers, 'vf-project': 'productcatalogue_dnext' }, {
      ...utilities,
      responseMapper,
      errorMapper,
    })
      .then(response => dispatch({
        type: actionTypes.SUCCESS_TV_OFFERS,
        payload: response,
      }))
      .catch(error => dispatch({
        type: actionTypes.ERROR_TV_OFFERS,
        payload: error,
      }))
  }
}

export {
  actionTypes,
  selectBundle,
  selectTVOffer,
  selectTVAddOn,
  getOffers,
  getInternetOffers,
  getTVOffers,
  clearBundle,
  clearInternetOfferData,
  clearTVOfferData
}
