import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import ReduxCache from '../../Utils/ReduxCache'

import { API_URLS } from '../Config'

const mapError = (error) => {
  const payload = error?.response?.data?.message || error?.message
  return payload
}

async function removeAddons(
  addOnData,
  customHeaders = {},
  utilities = {},
) {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
  })

  const {
    errorMapper,
    onError,
    onStart,
    onSuccess,
  } = _utilities

  onStart()

  const data = {
    'category': 'offers',
    'channel': [
      {
        'id': 'selfcare'
      }
    ],
    'productOrderItem': [
      {
        'id': addOnData.id,
        'quantity': 1,
        'action': 'delete',
        'product': {
          'productCharacteristic': [
            {
              'name': 'type',
              'value': 'standard'
            }
          ],
          'productPrice': [
            {
              'price': {
                'taxIncludedAmount': {
                  'unit': addOnData.unit,
                  'value': addOnData.price,
                }
              }
            }
          ]
        }
      }
    ],
    'quote': null,
    'relatedParty': [
      {
        'id': ReduxCache.getMsisdn(),
        'role': 'Customer',
        '@referredType': 'individual'
      }
    ]
  }

  try {
    const response = await axios.post(API_URLS.productsAndServices.removeAddons, data, {
      headers: customHeaders,
    })
    const _response = response?.data
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

export { removeAddons }
