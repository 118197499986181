import React, { Component } from 'react'
import {
  View,
  Image,
  FlatList,
  Platform,
  TextInput,
  ScrollView,
  SafeAreaView,
  TouchableOpacity,
  KeyboardAvoidingView,
  StyleSheet,
  Dimensions
} from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  check,
  request,
  openSettings,
  PERMISSIONS,
  RESULTS,
} from 'react-native-permissions'
import { selectContactPhone } from 'react-native-select-contact'
import ModalDropdown from 'react-native-modal-dropdown'
import { connect } from 'react-redux'

import Splash from '../Splash'
import Loader from '../../Components/Loader'
import PopUp from '../../Components/PopUp'
import VfText from '../../Components/VfText'
import HorizontalLine from '../../Components/HorizontalLine'

import Error from '../../Components/Error'

import { __translate } from '../../Utils'

import Images from '../../Themes/Images'
import colors from '../../Themes/Colors'
import styles from './vfCoinsTransfer.styles'


import { actions } from './vfCoinsTransfer.actions'
import { VFScreen } from '@vfgroup-oneplatform/foundation/Components'

const RECENT_TRANSACTION_QUEUE = 'recentTransactionQueue'
const ACTIVE_STATUS = 'active'

class VfAmountOption extends Component {

  render() {
    return (
      <TouchableOpacity
        onPress={this.props.onPress}
        disabled={this.props.disabled}
        style={[
          styles.isDisabled_false,
          this.props.disabled && styles.isDisabled_true
        ]
        }>
        <View
          style={[
            styles.amountOptContainer,
            this.props.isSelected && styles.isSelected_true,
            this.props.style,
          ]}>
          <VfText
            style={[
              styles.amountText,
              this.props.isSelected && styles.isSelectedText_true
            ]}>
            {this.props.value}
          </VfText>
          <VfText
            style={[
              styles.currencyText,
              this.props.isSelected && styles.isSelectedText_true
            ]}>
            {this.props.currency}
          </VfText>
        </View>
      </TouchableOpacity>
    )
  }
}

class CoinsTransfer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      phoneNumber: '',
      isAttention: false,
      popUp: {
        display: false,
        message: 'Something went wrong',
      },
      recentTransactionQueue: [],
    }
  }
  dropDownRef = React.createRef();

  componentDidMount() {
    this.getRecentTransactions()
    this.props.getList()
  }
  push = (arr, newNumber) => {
    if (!arr) {
      arr = []
    }
    const index = arr.findIndex(obj => obj === newNumber)
    if (index === -1) {
      return [newNumber, ...arr].slice(0, 5)
    }
    return [arr[index], ...arr.slice(0, index), ...arr.slice(index + 1, 5)]
  };
  updateRecentTransactions = async function (key, value) {
    await AsyncStorage.setItem(key, value)
  };

  getRecentTransactions = async function () {
    try {
      const response = await AsyncStorage.getItem(RECENT_TRANSACTION_QUEUE)
      if (response !== null) {
        const recentTransactions = JSON.parse(response)
        this.setState({ recentTransactionQueue: recentTransactions })
      } else {
        this.setState({ recentTransactionQueue: null })
      }
    } catch (error) {
      return
    }
  };

  getPhoneNumber = () => {
    check(
      Platform.OS === 'android'
        ? PERMISSIONS.ANDROID.READ_CONTACTS
        : PERMISSIONS.IOS.CONTACTS,
    )
      .then(result => {
        switch (result) {
          case RESULTS.UNAVAILABLE:
            break
          case RESULTS.DENIED:
            request(
              Platform.select({
                android: PERMISSIONS.ANDROID.READ_CONTACTS,
                ios: PERMISSIONS.IOS.CONTACTS,
              }),
            )
            break
          case RESULTS.GRANTED:
            return selectContactPhone().then(selection => {
              if (!selection) {
                return null
              }
              const { selectedPhone } = selection
              let number = selectedPhone.number
              number = number.replace(/[^6]*/i, '355')
              number = number.replace(/[\s-]/gi, '')
              this.changeInputText(number)
              return selectedPhone.number
            })
          case RESULTS.BLOCKED:
            openSettings().catch(() => null)
            break
        }
      })
      .catch(() => null)
  };
  changeInputText = number => {
    this.dropDownRef.current.select(-1)
    this.changeText(number)
  };
  changeText = number => {
    this.setState({ phoneNumber: number })
  };
  setSelected = item => {
    this.props.setSelectedAmount(item)
  };

  getContent = () => {
    const { transferVCoinsList } = this.props

    if (transferVCoinsList.loading) {
      return (
        <SafeAreaView style={styles.safearea}>
          <Loader />
        </SafeAreaView>
      )
    } else if (
      transferVCoinsList.data === null ||
      transferVCoinsList.error !== null ||
      typeof transferVCoinsList.data.items[0] === 'undefined'
    ) {
      return (
        <SafeAreaView style={styles.safearea}>
          <Error
            message={transferVCoinsList.error}
            retry={() => this.retry()}
          />
        </SafeAreaView>
      )
    } else {
      return (
        <ScrollView
          style={styles.cardContainer}
          contentContainerStyle={styles.cardContentContainer}>
          <View style={styles.card}>
            <View style={styles.seperatorView}>
              <View style={styles.seperatorView}>
                <VfText style={[styles.sectionText, styles.numberTitle]}>
                  {__translate('reward_share_number')}
                </VfText>
              </View>
              <View style={styles.inputContainer}>
                <TextInput
                  textContentType="telephoneNumber"
                  autoCompleteType="cc-number"
                  dataDetectorTypes="phoneNumber"
                  keyboardType="decimal-pad"
                  placeholder={__translate('reward_share_number_desc')}
                  placeholderTextColor={colors.mid_grey}
                  onChangeText={number => this.changeInputText(number)}
                  value={this.state.phoneNumber}
                  style={styles.input}
                />
                <ModalDropdown
                  ref={this.dropDownRef}
                  options={
                    this.state.recentTransactionQueue || [
                      __translate('reward_share_no_recent_trans'),
                    ]
                  }
                  onSelect={(index, value) => this.changeText(value)}
                  showsVerticalScrollIndicator={false}
                  renderSeparator={() => <></>}
                  dropdownStyle={[
                    styles.dropdownContainer,
                    {
                      height: this.state.recentTransactionQueue
                        ? this.state.recentTransactionQueue.length * 42
                        : 42,
                      maxHeight: 210,
                    },
                    {
                      ...Platform.select({
                        web: {
                          maxWidth: 600,
                          width: '100%',
                          alignSelf: 'center'
                        }
                      })
                    }
                  ]}
                  dropdownTextStyle={styles.dropdownTextStyle}>
                  <View style={styles.dropdownIcon}>
                    <Image
                      source={Images.chevron_down_red}
                      style={styles.inputIcon}
                    />
                  </View>
                </ModalDropdown>
              </View>
            </View>
            <View style={styles.seperatorView}>
              <TouchableOpacity onPress={() => this.getPhoneNumber()}>
                <View style={styles.getContactContainer}>
                  <VfText style={styles.addContactText}>
                    {__translate('reward_share_contactlist')}
                  </VfText>
                  <View style={styles.dropdownIcon}>
                    <Image source={Images.contacts} style={styles.inputIcon} />
                  </View>
                </View>
              </TouchableOpacity>
            </View>
            <View style={styles.seperatorView}>
              <HorizontalLine style={styles.seperator} />
            </View>
            <View style={styles.seperatorView}>
              <VfText style={[styles.sectionText, styles.amountTitle]}>
                {__translate('reward_share_amount')}
              </VfText>
            </View>
            <View style={styles.seperatorView}>
              <FlatList
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                data={transferVCoinsList.data.items || []}
                keyExtractor={item => item.id}
                extraData={transferVCoinsList.selected}
                renderItem={({ item }) => (
                  <VfAmountOption
                    onPress={() => this.setSelected(item)}
                    key={item.id}
                    id={item.id}
                    value={item.amount}
                    currency={item.currency}
                    isSelected={item.id === transferVCoinsList.selected.id}
                    disabled={item.status !== ACTIVE_STATUS}
                  />
                )}
                contentContainerStyle={styles.listContentContainer}
                initialNumToRender={4}
              />
            </View>

            <View style={styles.seperatorView}>
              <HorizontalLine style={styles.seperator} />
            </View>
            <View style={styles.seperatorView}>
              <View style={styles.detailsTextContainer}>
                <VfText size="h5" style={styles.detailsTexFee}>
                  {__translate('reward_share_fee')}
                </VfText>
                <VfText size="h5" weight="bold" style={styles.detailsTexFee}>
                  {transferVCoinsList.selected.transaction_fee || '--'}
                </VfText>
              </View>
            </View>

            <View style={styles.seperatorView}>
              <HorizontalLine style={styles.seperator} />
            </View>
            <View style={styles.seperatorView}>
              <View style={styles.detailsContainer}>
                <View style={styles.detailsTextContainer}>
                  <VfText style={styles.detailsText}>
                    {__translate('reward_share_limit')}
                  </VfText>
                  <VfText style={styles.detailsText}>
                    {transferVCoinsList.selected.transfering_actual_value ||
                      transferVCoinsList.data.items[0]
                        .transfering_actual_value}{' '}
                    /{' '}
                    {transferVCoinsList.selected.transfering_max_value ||
                      transferVCoinsList.data.items[0].transfering_max_value}
                  </VfText>
                </View>
                <View style={styles.detailsTextContainer}>
                  <VfText style={styles.detailsText}>
                    {__translate('reward_receive_limit')}
                  </VfText>
                  <VfText style={styles.detailsText}>
                    {transferVCoinsList.selected.receiving_actual_value ||
                      transferVCoinsList.data.items[0]
                        .receiving_actual_value}{' '}
                    /{' '}
                    {transferVCoinsList.selected.receiving_max_value ||
                      transferVCoinsList.data.items[0].receiving_max_value}
                  </VfText>
                </View>
                <View style={styles.detailsTextContainer}>
                  <VfText style={styles.detailsText}>
                    {__translate('reward_share_number_limit')}
                  </VfText>
                  <VfText style={styles.detailsText}>
                    {transferVCoinsList.selected.number_of_transfer_value ||
                      transferVCoinsList.data.items[0]
                        .number_of_transfer_value}{' '}
                    /{' '}
                    {transferVCoinsList.selected.number_of_transfer_limit ||
                      transferVCoinsList.data.items[0].number_of_transfer_limit}
                  </VfText>
                </View>
              </View>
            </View>
            <View style={styles.seperatorView}>
              <TouchableOpacity
                onPress={() => this.showPopUp(this.selected)}
                style={styles.button}>
                <VfText style={styles.buttonText}>
                  {__translate('reward_share_bt_txt')}
                </VfText>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      )
    }
  };
  getKeyboardAwareContainer = () => {
    if (Platform.OS === 'android') {
      return (
        <>
          {this.getContent()}
          <KeyboardAvoidingView />
        </>
      )
    } else {
      return (
        <>
          <KeyboardAvoidingView
            behavior="padding"
            style={styles.keboardAwareContainer}>
            {this.getContent()}
          </KeyboardAvoidingView>
        </>
      )
    }
  };

  closePopUp = () => {
    this.setState({
      popUp: {
        display: false,
      },
    })
    if (this.props.transferVCoinsState.isSuccess) {
      this.props.resetGetList()
      this.props.resetTransferVCoins()
      this.resetControllers()
      this.setSelected({})
      this.setState({
        phoneNumber: '',
      })
      this.props.getList()
    }
  };

  showPopUp = () => {
    this.props.resetTransferVCoins()
    this.resetControllers()
    const regexPhone = /^3556[7-9][0-9]{7}$/i
    if (!regexPhone.test(this.state.phoneNumber)) {
      this.setState({
        isAttention: true,
        popUp: {
          display: true,
          message: __translate('reward_share_invalid_number'),
        },
      })
    } else if (this.props.transferVCoinsList.selected.id === undefined) {
      this.setState({
        isAttention: true,
        popUp: {
          display: true,
          message: __translate('reward_share_not_selected_amount'),
        },
      })
    } else {
      this.setState({
        popUp: {
          display: true,
        },
      })
    }
  };
  retry = () => {
    this.props.resetGetList()
    this.props.resetTransferVCoins()
    this.resetControllers()
    this.setSelected({})
    this.setState({
      phoneNumber: '',
    })
    this.props.getList()
  };
  resetControllers = () => {
    this.setState({
      isError: false,
      isAttention: false,
      isSuccess: false,
    })
  };
  handleTransferOperation = () => {
    const phoneNumber = this.state.phoneNumber
    const selectedItem = this.props.transferVCoinsList.selected
    this.setState({
      recentTransactionQueue: this.push(
        this.state.recentTransactionQueue,
        phoneNumber,
      ),
    }, () => {
      this.updateRecentTransactions(
        RECENT_TRANSACTION_QUEUE,
        JSON.stringify(this.state.recentTransactionQueue),
      )
    })
    this.props.transferVCoins(phoneNumber, selectedItem)
  };

  getPopUpContent = () => {
    const { transferVCoinsList, transferVCoinsState } = this.props
    if (transferVCoinsState.loading) {
      return (
        <View style={styles.sucessInfoContainer}>
          <Loader />
        </View>
      )
    } else if (transferVCoinsState.isError) {
      return (
        <View style={[styles.sucessInfoContainer, { height: 200 }]}>
          <Error
            message={this.props.transferVCoinsState.error}
            retry={() => this.closePopUp()}
          />
        </View>
      )
    } else if (this.state.isAttention) {
      return (
        <View style={styles.sucessInfoContainer}>
          <VfText style={styles.sucessInfo}>{this.state.popUp.message}</VfText>
          <TouchableOpacity
            onPress={() => this.closePopUp()}
            style={[styles.popUpButton, styles.popUpButton_cancel]}>
            <VfText style={styles.buttonText}>
              {__translate('reward_loyalty_close_bt')}
            </VfText>
          </TouchableOpacity>
        </View>
      )
    } else if (transferVCoinsState.isSuccess) {
      return (
        <View style={styles.sucessInfoContainer}>
          <VfText size="h4" weight="bold" style={styles.sucessInfo}>
            {__translate('reward_share_success_header')}
          </VfText>

          <VfText size="h5" weight="light" style={styles.sucessInfo}>
            {__translate('reward_share_success_desc')}{' '}
            {transferVCoinsList.selected.amount}{' '}
            {__translate('reward_share_success_amount')}{' '}
            {__translate('reward_share_success_to')} {this.state.phoneNumber}{' '}
            {__translate('reward_share_success_fee')}{' '}
            {transferVCoinsList.selected.transaction_fee || '--'}
          </VfText>

          <VfText style={[styles.sucessInfo, styles.sharing]}>
            {__translate('reward_share_success_bottom_txt')}
          </VfText>
        </View>
      )
    } else {
      return (
        <View style={styles.popUpContentContainer}>
          <VfText
            weight="bold"
            size="h4"
            style={[styles.detailsTextPopUp, styles.fullWidth]}>
            {__translate('reward_share_confirmation_desc')}
          </VfText>
          <View style={styles.popUpDetailsContainer}>
            <View style={styles.detailsTextPopUpContainer}>
              <VfText size="h5" style={[styles.detailsTextPopUp, { fontSize: 19 }]}>
                {__translate('reward_share_confirmation_vcoins')}
              </VfText>
              <VfText size="h5" weight="bold" style={styles.detailsTextPopUp}>
                {' '}
                {transferVCoinsList.selected.amount}
              </VfText>
            </View>
            <View style={styles.detailsTextPopUpContainer}>
              <VfText size="h5" style={[styles.detailsTextPopUp, { fontSize: 19 }]}>
                {__translate('reward_share_confirmation_to')}
              </VfText>
              <VfText size="h5" weight="bold" style={styles.detailsTextPopUp}>
                {' '}
                {this.state.phoneNumber}
              </VfText>
            </View>
            <View style={styles.detailsTextPopUpContainer}>
              <VfText size="h5" style={[styles.detailsTextPopUp, { fontSize: 19 }]}>
                {__translate('reward_share_confirmation_fee')}
              </VfText>
              <VfText size="h5" weight="bold" style={styles.detailsTextPopUp}>
                {' '}
                {transferVCoinsList.selected.transaction_fee || '--'}
              </VfText>
            </View>
          </View>
          <View style={styles.popUpDetailsContainer}>
            <TouchableOpacity
              onPress={() => this.handleTransferOperation()}
              style={[styles.popUpButton, styles.popUpButton_confirm]}>
              <VfText style={styles.buttonText}>
                {__translate('reward_share_conf_ptv_bt_txt')}
              </VfText>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => this.closePopUp()}
              style={[styles.popUpButton, styles.popUpButton_cancel]}>
              <VfText style={styles.buttonText}>
                {__translate('reward_share_conf_ngt_bt_txt')}
              </VfText>
            </TouchableOpacity>
          </View>
        </View>
      )
    }
  };

  getImage = () => {
    const { transferVCoinsState } = this.props
    if (transferVCoinsState.isError) {
      return Images.warning
    } else if (this.state.isAttention) {
      return Images.warning
    } else if (transferVCoinsState.isSuccess) {
      return Images.ok_sign
    } else {
      return Images.share_vcoins
    }
  };

  render() {
    const { splash } = this.props
    if (splash.isCache || !splash.data) {
      return <Splash />
    }

    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
      return (
        <>
          <VFScreen
            headerStyle={{ backgroundColor: '#fff' }}
            containerStyle={{ backgroundColor: '#fff' }}
            contentContainerStyle={{ height: '94%' }}
            title={'Vodafone More'}
            onClose={() => { }}
            onBack={() => this.props.navigation.goBack()}
            showBack
            showClose={false}
          >
            <SafeAreaView style={styles.safearea}>
              <View style={styles.header}>
                <View style={styles.headerPartContainer}>
                  {/* <TouchableOpacity onPress={() => this.props.navigation.goBack()} style={styles.touchableGroup}>
                    <Image
                      source={Images.chevron_left_red}
                      style={styles.smallIcon}
                    />
                    <Image source={Images.share_vcoins} style={styles.bigIcon} />
                  </TouchableOpacity> */}
                </View>
                <View style={styles.headerPartContainer}>
                  <Image source={Images.vcoins} style={styles.bigIcon} />
                  <VfText size="h4" weight="bold">
                    {this.props.user.data.coins}
                  </VfText>
                </View>
              </View>
              {/* {this.getKeyboardAwareContainer()} */}
              <View style={{ paddingHorizontal: '10%' }}>
                {this.getContent()}
              </View>
            </SafeAreaView>
            <PopUp
              visibility={this.state.popUp.display}
              image={this.getImage()}
              closePopUp={() => this.closePopUp(false)}>
              {this.getPopUpContent()}
            </PopUp>
          </VFScreen>
        </>
      )
    }

    return (
      <>
        <SafeAreaView style={styles.safearea}>
          <View style={styles.header}>
            <View style={styles.headerPartContainer}>
              <TouchableOpacity onPress={() => this.props.navigation.goBack()} style={styles.touchableGroup}>
                <Image
                  source={Images.chevron_left_red}
                  style={styles.smallIcon}
                />
                <Image source={Images.share_vcoins} style={styles.bigIcon} />
              </TouchableOpacity>
            </View>
            <View style={styles.headerPartContainer}>
              <Image source={Images.vcoins} style={styles.bigIcon} />
              <VfText size="h4" weight="bold">
                {this.props.user.data.coins}
              </VfText>
            </View>
          </View>
          {this.getKeyboardAwareContainer()}
        </SafeAreaView>
        <PopUp
          visibility={this.state.popUp.display}
          image={this.getImage()}
          closePopUp={() => this.closePopUp(false)}>
          {this.getPopUpContent()}
        </PopUp>
      </>
    )
  }
}

CoinsTransfer.defaultProps = {}

function mapDispatchToProps(dispatch) {
  return {
    getList: isBackground => dispatch(actions.getList(isBackground)),
    transferVCoins: (reciever, selectedItem) =>
      dispatch(actions.transferVCoins(reciever, selectedItem)),
    resetGetList: () => dispatch(actions.resetGetList()),
    resetTransferVCoins: () => dispatch(actions.resetTransferVCoins()),
    setSelectedAmount: item => dispatch(actions.setSelectedAmount(item)),
  }
}

const mapStateToProps = state => {
  const { transferVCoinsList, transferVCoinsState, splash, user } = state
  return { transferVCoinsList, transferVCoinsState, splash, user }
}

const VfCoinsTransfer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoinsTransfer)

export default VfCoinsTransfer
