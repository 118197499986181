import axios from 'axios'

import CONFIG from '../../Config'
import { getErrorMessage } from '../../Utils'
import ReduxCache from '../../Utils/ReduxCache'

import { action_types } from './survey.actionTypes'

const getPreferences = () => {
  return async dispatch => {
    dispatch({
      type: action_types.REQUEST_PREFERENCES
    })

    const msisdn = ReduxCache.getMsisdn()

    const data = {
      'queryOptions': {
        'filter': '',
        'pagination': {
          'count': 0,
          'limit': 10
        },
        'sorting': ''
      },
      'queries': [
        {
          'query': '$.redeemerId=' + msisdn
        },
        {
          'operator': 'AND'
        },
        {
          'query': '$.type=' + CONFIG.queryConfig.type
        },
        {
          'operator': 'AND'
        },
        {
          'query': '$.channelId=' + CONFIG.queryConfig.channelId
        },
        {
          'operator': 'AND'
        },
        {
          'query': '$.category=preferences'
        }
      ]
    }

    const url = await CONFIG.getUrl()
    const headers = await CONFIG.getHeaders()
    axios.post(url.preferencesEndpoint, data, {
      headers: {
        ...headers
      }
    })
      .then(response => {
        const data = response.data[0]

        dispatch({
          type: action_types.SUCCESS_PREFERENCES,
          payload: data
        })
      })
      .catch(error => {
        const message = getErrorMessage(error)
        dispatch({
          type: action_types.ERROR_PREFERENCES,
          payload: message
        })
      })
  }
}

const sendPreferences = (items) => {
  items = JSON.stringify(items)
  items = items.replace(/ë/g, 'e')
  items = items.replace(/ç/g, 'c')
  items = items.replace(/Ë/g, 'c')
  items = items.replace(/Ç/g, 'c')

  items = JSON.parse(items)

  return async dispatch => {
    dispatch({
      type: action_types.REQUEST_SUBMIT_PREFERENCES
    })

    const url = await CONFIG.getUrl()
    const headers = await CONFIG.getHeaders()
    axios.patch(url.sendPreferencesEndpoint, items, {
      headers: {
        ...headers
      }
    })
      .then(response => {
        dispatch({
          type: action_types.SUCCESS_SUBMIT_PREFERENCES
        })
      })
      .catch(error => {
        const message = getErrorMessage(error)

        dispatch({
          type: action_types.ERROR_SUBMIT_PREFERENCES,
          payload: message
        })
      })
  }
}

export const actions = {
  getPreferences,
  sendPreferences
}
