import axios from 'axios'

import CONFIG from '../../Config'
import {getErrorMessage} from '../../Utils'
import ReduxCache from '../../Utils/ReduxCache'
import {actions as profileActions} from '../Demo/demo.actions'

import {actionTypes} from './vfCoinsTransfer.actionTypes'

const config = {
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'al',
    'vf-country-code': 'al',
    'vf-source': 'selfcare',
    'vf-operator': 'reward',
  },
  data: null,
  params: null,
}

function getList(isBackground = false) {
  return async dispatch => {
    if (!isBackground) {
      dispatch({
        type: actionTypes.REQUEST_GET_LIST,
      })
    }
    const msisdn = ReduxCache.getMsisdn()
    config.data = null
    config.params = {
      relatedPartyId: msisdn,
    }
    const headers = await CONFIG.getTransferVCoinsHeaders()
    config.headers = headers
    const url = await CONFIG.getUrl()
    axios
      .get(url.transferVCoins, config)
      .then(result => {
        const items = result.data
        const transferVcoinsItems = []
        for (const i in items) {
          const item = items[i]
          const transferVCoinsObj = {
            key: i,
            id: item.id[0].value,
            name: item.name,
            amount: item.name.split(' ')[0],
            currency: item.name.split(' ')[1],
            desc: item.desc,
            type: item.type,
            category: item.category[0].value,
            status: item.status,
          }

          const characteristics =
            item.parts.adjustmentSpecification.characteristicsValue
          for (const j in characteristics) {
            const characteristic = characteristics[j]
            transferVCoinsObj[characteristic.characteristicName] =
              characteristic.value
          }
          transferVcoinsItems.push(transferVCoinsObj)
        }
        const data = {
          items: transferVcoinsItems,
        }

        dispatch({
          type: actionTypes.SUCCESS_GET_LIST,
          payload: data,
        })
      })
      .catch(event => {
        const errorMessage = getErrorMessage(event)
        dispatch({
          type: actionTypes.ERROR_GET_LIST,
          payload: errorMessage,
        })
      })
  }
}
function transferVCoins(reciever, selectedItem) {
  return async dispatch => {
    dispatch({
      type: actionTypes.REQUEST_TRANSFER_VCOINS,
    })
    const msisdn = ReduxCache.getMsisdn()
    config.params = {
      relatedPartyId: msisdn,
    }
    config.data = {
      id: [
        {
          value: selectedItem.id,
        },
      ],
      name: selectedItem.name,
      desc: selectedItem.desc,
      type: selectedItem.type,
      category: [
        {
          value: selectedItem.category,
        },
      ],
      roles: {
        sender: {
          id: [
            {
              value: msisdn,
              schemeName: 'Sender ID',
              schemeAgencyName: 'Vodafone',
            },
          ],
        },
        receiver: {
          id: [
            {
              value: reciever,
              schemeName: 'Reciever ID',
              schemeAgencyName: 'Vodafone',
            },
          ],
        },
      },
      status: selectedItem.status,
      parts: {
        triggeredBy: {
          id: [
            {
              value: 'selfcare',
            },
          ],
        },
        adjustmentSpecification: {
          characteristicsValue: [
            {
              characteristicName: 'receiving_actual_value',
              value: selectedItem.receiving_actual_value,
              type: 'receiving_monthly_limit',
            },
            {
              characteristicName: 'receiving_max_value',
              value: selectedItem.receiving_max_value,
              type: 'receiving_monthly_limit',
            },
            {
              characteristicName: 'transfering_actual_value',
              value: selectedItem.transfering_actual_value,
              type: 'transfering_monthly_limit',
            },
            {
              characteristicName: 'transfering_max_value',
              value: selectedItem.transfering_max_value,
              type: 'transfering_monthly_limit',
            },
            {
              characteristicName: 'number_of_transfer_value',
              value: selectedItem.number_of_transfer_value,
              type: 'number_of_transfer',
            },
            {
              characteristicName: 'number_of_transfer_limit',
              value: selectedItem.number_of_transfer_limit,
              type: 'number_of_transfer',
            },
            {
              characteristicName: 'transaction_fee',
              value: selectedItem.transaction_fee,
            },
          ],
        },
      },
      relatedParty: [
        {
          id: [
            {
              value: msisdn,
            },
          ],
        },
      ],
    }

    const headers = await CONFIG.getTransferVCoinsHeaders()
    config.headers = headers

    const url = await CONFIG.getUrl()
    axios
      .post(url.transferVCoins, config.data, {
        headers: config.headers,
        params: config.params,
      })
      .then(result => {
        const data = result.data
        dispatch({
          type: actionTypes.SUCCESS_TRANSFER_VCOINS,
          payload: data,
        })

        try {
          dispatch(profileActions.getUserProfile())
        } catch (e){

        }

      })
      .catch(event => {
        const errorMessage = getErrorMessage(event)
        dispatch({
          type: actionTypes.ERROR_TRANSFER_VCOINS,
          payload: errorMessage,
        })
      })
  }
}

function resetGetList() {
  return dispatch => {
    dispatch({
      type: actionTypes.RESET_GET_LIST,
      payload: null,
    })
  }
}

function resetTransferVCoins() {
  return dispatch => {
    dispatch({
      type: actionTypes.RESET_TRANSFER_VCOINS,
      payload: null,
    })
  }
}

function setSelectedAmount(item) {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_SELECTED_AMOUNT,
      payload: item,
    })
  }
}

export const actions = {
  getList,
  transferVCoins,
  resetGetList,
  resetTransferVCoins,
  setSelectedAmount,
}
