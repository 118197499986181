import { StyleSheet } from 'react-native'

import colors from '../../Themes/Colors'

export default StyleSheet.create({
  header: {
    borderRadius: 55,
    // overflow: 'hidden',
    justifyContent: 'center',
    backgroundColor: '#fff',
    marginBottom: -50,
    padding: 5,
    width: 110,
    height: 110,
    zIndex: 1,
    marginTop: 50,
    elevation: 5,
    alignSelf: 'center',
    shadowColor: colors.black,
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.16,
    shadowRadius: 8,
  },
  headerImage: {
    height: 60,
    width: 60,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  body: {
    backgroundColor: '#fff',
    width: '70%',
    marginLeft: '15%',
    padding: 16,
    zIndex: 0,
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
    paddingTop: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 10
  },
  closeImage: {
    width: 25,
    height: 25
  }
})
