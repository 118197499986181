import React, { useCallback, useEffect, useState } from 'react'
import { Platform, StatusBar } from 'react-native'
import PropTypes from 'prop-types'
import axios from 'axios'
// import { AppearanceProvider, Appearance } from 'react-native-appearance'
import { AppearanceProvider } from 'react-native-appearance'
import {
    ThemeProvider,
    AllowFontScalingContext
} from '@vfgroup-oneplatform/foundation/Components/Themes'

import { OptionsEnum, withDisplayOptions } from '@vfgroup-oneplatform/framework/Settings/DisplayOptions'
import { colorSchemeInstance as ColorSchemeInstance } from '@vfgroup-oneplatform/foundation/Utils/ColorManager'
import { Provider } from 'react-redux'
import { Root } from 'native-base'
// import TealiumHelper, { EnvironmentEnum } from '@vfgroup-oneplatform/foundation/TealiumHelper'


import { PersistGate } from 'redux-persist/lib/integration/react'
// import { createInstance } from 'react-native-netperform-sdk'

import { ContentManager } from '@vfgroup-oneplatform/foundation'
import SecLib, { EnvironmentTypes } from '@vf-org/react-native-smapi'

import AppNavigation from '../Navigation/AppNavigation'


import { persistor, store } from '../Redux'

import LanguageContext from '../Utils/LanguageContext'


import ConfigureLanguage from '../Config/I18n'
import ReduxCache from '../Utils/ReduxCache'

import { API_URLS } from '../Services/Config'

import { getThemeColors } from '../Themes'

import '../Network/Interceptors'

import { Actions } from './LandingScreen/LandingScreen.Actions'

import { useNavigation } from '@react-navigation/native'

import DynamicCouponProvider from '../Utils/DynamicCoupon'

import AsyncStorage from '@react-native-async-storage/async-storage'


import qs from 'qs'

// import { actionTypes } from '../Login/Login.Actions'
// import { setLocalKey } from '../Services/Storage'
import NavigationService from '../Navigation/NavigationService'

ConfigureLanguage()

// SecLib.init({
//     environment: EnvironmentTypes.GCP_TEST,
//     isVerbose: true
// })

const MyApp = ({ displayOption }) => {
    // const [colorScheme, setColorScheme] = useState(Appearance.getColorScheme() || 'light')
    const [colorScheme, setColorScheme] = useState('light')
    const [language, setLanguage] = useState()
    const [langLoading, setLangLoading] = useState(false)
    const [isConnected, setIsConnected] = useState(false)
    const [isLoading, setIsLoading] = useState(true)


    const clearShakeAnimationStorage = () => {
        AsyncStorage.removeItem('shake_animation')
    }

    useEffect(() => {
        ColorSchemeInstance.subscribe(handleColorSchemeUpdate)
        if (Platform.OS === 'ios') {
            // createInstance('preProduction')
            // createInstance('production')
        }

        // TealiumHelper.configure('vodafone', 'al-myvfapp-rn', EnvironmentEnum.TEST)
        clearShakeAnimationStorage()
    }, [])
    useEffect(() => {
        if (!language) {
            return
        }

        getRemoteTranslation()
    }, [language])


    const onBeforeLift = () => {
        const lang = ReduxCache.getLanguage()
        ContentManager.setLocale(lang)
        setLanguage(lang)
    }

    const getRemoteTranslation = async () => {
        let languageURL = API_URLS.translations_en
        if (language == 'al') {
            languageURL = API_URLS.translations_al
        }

        const response = await axios.get(languageURL)
        if (response?.data) {
            ContentManager.configureI18nTranslations({ [language]: response.data })
        }

    }
    /* handle color scheme update by the system */
    const handleColorSchemeUpdate = (callback) => {
        // setColorScheme(callback.colorScheme)
        // setColorScheme('light')
        setColorScheme(callback.colorScheme)
    }

    const handelDisplayOptions = () => {
        if (displayOption === OptionsEnum.auto) {
            return getThemeColors(colorScheme)
        } else {
            return getThemeColors(displayOption)
        }
    }

    const handleLanguageChange = async (lang) => {
        setLangLoading(true)
        store.dispatch({ type: 'UPDATE_LANGUAGE', data: lang })
        // await Actions.getLP()
        ContentManager.setLocale(lang)
        setLanguage(lang)
        await Actions.getLP()
        setLangLoading(false)
    }

    const getAuthToken = async (code, state) => {
        const url = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://my.vodafone.al'
        var data = qs.stringify({
            'grant_type': 'urn:ietf:params:oauth:grant-type:token-exchange',
            'requested_token_type': 'urn:ietf:params:oauth:token-type:access_token',
            'subject_token': code,
            'subject_token_type': 'urn:vodafone:params:oauth:token-type:authorization_code',
            'state': state,


        })
        var config = {
            method: 'post',
            url: 'https://api-mobile-ci-vfal.vodafone.com/OAuth2/v2/token',
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'Origin': url,
            },
            data: data
        }

        axios(config)
            .then(function (response) {
                let userType, hashedMsisdn, hashedMsisdnParking = ''
                response?.data?.user_info?.userAssets?.map((asset) => {
                    if (asset?.assetType === 'customerType') {
                        userType = asset?.id
                    }
                    if (asset?.assetType === 'hashedMsisdn') {
                        hashedMsisdn = asset?.id
                    }
                    if (asset?.assetType === 'hashedMsisdnParking') {
                        hashedMsisdnParking = asset?.id
                    }
                })
                store.dispatch({ type: 'LOGOUT' })
                ReduxCache.clear()
                window.localStorage.setItem('isLoggedIn', 'false')
                window.history.replaceState({}, document.title, '/')
                ReduxCache.set('auth.userType', response.data?.user_info?.userAssets[0]?.id)
                window.localStorage.setItem('isLoggedInUAT', 'true')
                store.dispatch({
                    type: 'LOGIN_SUCCESS', data: {
                        ...response.data,
                        msisdn: response?.data?.user_info?.phone_number,
                        userType: userType,
                        hashedMsisdn: hashedMsisdn,
                        hashedMsisdnParking: hashedMsisdnParking,
                        ms_access_token: response.data?.access_token,
                        ms_refresh_token: response.data?.refresh_token
                    }
                })
                setIsLoading(false)
                NavigationService.navigate('Home', { fromUAT: true })
            })
            .catch(function (error) {
                window.history.replaceState({}, document.title, '/')
                setIsLoading(false)
                ReduxCache.clear()
                store.dispatch({ type: 'LOGOUT' })
                window.localStorage.setItem('isLoggedIn', 'false')
                window.localStorage.setItem('isLoggedInUAT', 'false')
                NavigationService.navigateWithResetAction('Home', { invalidToken: true })
            })
    }

    useEffect(() => {
        const params = window.location.href.split('?')[1]
        const code = new URLSearchParams(params).get('code')
        const state = new URLSearchParams(params).get('state')
        if (code && state) {
            getAuthToken(code, state)
        } else {
            setIsLoading(false)
        }
    }, [])




    return (
        <Provider store={store}>
            <PersistGate onBeforeLift={onBeforeLift} persistor={persistor}>
                <Root>
                    <AllowFontScalingContext.Provider value={false}>
                        <AppearanceProvider>
                            <ThemeProvider theme={handelDisplayOptions()}>
                                <StatusBar barStyle="light-content" />
                                <LanguageContext.Provider
                                    value={{
                                        language: language,
                                        changeLanguage: handleLanguageChange,
                                        langLoading,
                                        setLangLoading,
                                    }}>
                                    <DynamicCouponProvider>
                                        <AppNavigation isLoading={isLoading} isConnected={isConnected} />
                                    </DynamicCouponProvider>
                                </LanguageContext.Provider>
                            </ThemeProvider>
                        </AppearanceProvider>
                    </AllowFontScalingContext.Provider>
                </Root>
            </PersistGate>
        </Provider>
    )
}

MyApp.propTypes = {
    displayOption: PropTypes.string
}

const App = withDisplayOptions(MyApp)

export default App
