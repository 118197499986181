import React from 'react'
import { Platform, Dimensions } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'


import { QuizGame } from '@vfal-lab/rn-vfg-quiz/Screens'
import { VFScreen } from '@vfgroup-oneplatform/foundation/Components'


import NavigationService from '../../Navigation/NavigationService'

import { getQuizOptions, putQuizOption, getQuizRewards } from './QuizGame.Actions'
import styles from './QuizGame.Styles'

function QuizGameScreen({ route: { params: { quizLevel, sessionId, optionsNr } } }) {

  const dispatch = useDispatch()

  const getLevelOptions = async (questionNr, utilities) => dispatch(getQuizOptions(sessionId, questionNr, utilities))

  const updateQuizOption = async (data, utilities) => dispatch(putQuizOption(sessionId, data, utilities))

  const getReward = async (utilities) => dispatch(getQuizRewards(sessionId, utilities))

  const levelOptions = useSelector(state => state?.levelOptions?.data || {})



  const onClose = () => {
    NavigationService.pop()
  }

  const goBack = (numberOfPops) => NavigationService.pop(numberOfPops)
  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
    return (
      <VFScreen
        headerStyle={styles.headerStyle}
        title={'UN Quiz'}
        hideMainHeader
      >
        <QuizGame
          onClose={onClose}
          quizLevel={quizLevel}
          getLevelOptions={getLevelOptions}
          optionsNr={optionsNr}
          putQuizOption={updateQuizOption}
          getReward={getReward}
          goBack={goBack}
          levelOptions={levelOptions}
        />
      </VFScreen>
    )
  } else {
    return <QuizGame
      onClose={onClose}
      quizLevel={quizLevel}
      getLevelOptions={getLevelOptions}
      optionsNr={optionsNr}
      putQuizOption={updateQuizOption}
      getReward={getReward}
      goBack={goBack}
      levelOptions={levelOptions}
    />
  }
}
QuizGameScreen.propTypes = {
  route: PropTypes.object,
}

export default QuizGameScreen
