import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { LocaleConfig } from 'react-native-calendars'

import AppointmentsScreens from '@vfal-lab/rn-vfg-appointments'

import ReduxCache from '../../Utils/ReduxCache'

import { isAdminView } from '../../Utils'

import NavigationService from '../../Navigation/NavigationService'

import {
  getServices,
  getStoresData,
  postAppointment,
  getStoresOpenHours,
  getAvailableTimeSlots,
  getAppointments
} from '../../Services/Appointments'

import { citiesList } from './utils'


const BookAnAppointment = ({ navigation, route }) => {

  const theme = useTheme()

  const isAdmin = isAdminView()

  const language = ReduxCache.getLanguage()

  const [isServicesLoading, setIsServicesLoading] = useState(false)
  const [serviceData, setServiceData] = useState([])

  const [isStoresLoading, setIsStoresLoading] = useState(false)
  const [storesData, setStoresData] = useState([])
  const [storesError, setStoresError] = useState(null)

  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [addressInfo, setAddressInfo] = useState({
    street: '',
    city: '',
    extraData: ''
  })

  const [defaultAppointment, setDefaultAppointment] = useState({})

  const close = () => {
    if (route?.params?.isDeeplink) {
      NavigationService.navigateWithResetAction('Dashboard')
    } else {
      navigation.pop()
    }
  }

  const getServicesData = () => {
    setIsServicesLoading(true)
    getServices({
      onSuccess: res => {
        setIsServicesLoading(false)
        setServiceData(res)
      },
      onError: err => {
        setError(true)
        setErrorMessage(err)
        setIsServicesLoading(false)
      }
    })
  }

  const getStoresLocationData = () => {
    setIsStoresLoading(true)
    setStoresError(null)
    getStoresData({
      onSuccess: (res) => {
        setIsStoresLoading(false)
        if (isAdmin) {
          setDefaultAppointment({
            ...res?.[0],
            id: 'vfoffice',
          })
        } else {
          setStoresData(res)
        }
      },
      onError: (err) => {
        setIsStoresLoading(false)
        setStoresError(err)
      }
    })
  }


  useEffect(() => {
    if (language) {
      LocaleConfig.defaultLocale = language
    }
    if (language === 'en') {
      moment.locale('en')
    } else {
      moment.locale('sq')
    }
  }, [language])

  useEffect(() => {
    getServicesData()
  }, [])


  const onActiveStepIndexChange = (activeStepIndex) => {
    if (activeStepIndex === 1) {
      getStoresLocationData()
    }
  }

  const onBookAppointmentPress = appointment => {
    setIsLoading(true)
    postAppointment(appointment, {
      onSuccess: () => {
        setIsLoading(false)
        setSuccess(true)
      },
      onError: err => {
        setIsLoading(false)
        setErrorMessage(err)
        setError(true)
      }
    })
  }

  const onAppointmentsPress = () => {
    setSuccess(false)
    setError(false)
    setErrorMessage('')
    setTimeout(() => {
      NavigationService.navigate('Appointments', { reset: true })
    }, 1)
  }

  const onErrorModalClosePress = () => {
    setError(false)
    setErrorMessage('')
    setTimeout(() => {
      close()
    }, 1)
  }

  const onClose = () => {
    setSuccess(false)
    setError(false)
    setErrorMessage('')
    setTimeout(() => {
      close()
    }, 1)
  }

  const onBack = () => {
    if (route.params?.isDeeplink) {
      NavigationService.navigateWithResetAction('Dashboard')
    } else {
      navigation.goBack()
    }
  }


  return (
    <AppointmentsScreens.BookAnAppointment
      onClose={onClose}
      onBack={onBack}

      servicesData={serviceData}
      isServicesLoading={isServicesLoading}

      storesData={storesData}
      isStoresLoading={isStoresLoading}
      storesError={storesError}
      getStoresData={getStoresLocationData}
      getStoresOpenHours={getStoresOpenHours}

      getAvailableTimeSlots={getAvailableTimeSlots}
      getAppointments={getAppointments}

      onActiveStepIndexChange={onActiveStepIndexChange}
      theme={theme}
      isAdmin={isAdmin}

      addressInfo={addressInfo}
      setAddressInfo={setAddressInfo}
      defaultAppointmentInfo={defaultAppointment}
      citiesOptions={citiesList}

      onBookAppointmentPress={onBookAppointmentPress}
      isBookingAppointmentInProgress={isLoading}
      bookingAppointmentError={error}
      errorMessage={errorMessage}
      isBookingSuccessModalVisible={success}
      onSuccessModalClosePress={onAppointmentsPress}
      onAppointmentsPress={onAppointmentsPress}
      onErrorModalClosePress={onErrorModalClosePress}
    />
  )

}
BookAnAppointment.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object
}

export default BookAnAppointment
