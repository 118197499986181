import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { View, ScrollView, Dimensions } from 'react-native'
import Modal from 'react-native-modal'
import ContentLoader, { Rect } from 'react-content-loader/native'
import moment from 'moment'

import { VFScreen, VFButton, VFText, Card, Icon, ErrorScreen } from '@vfgroup-oneplatform/foundation/Components'
import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import ManualRefresh from '@vfgroup-oneplatform/foundation/Components/ManualRefresh'
import Timeline from '@vfgroup-oneplatform/foundation/Components/Timeline'
import { useTheme, getThemeImages } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { getMsisdnData } from '../../Utils'
import ReduxCache from '../../Utils/ReduxCache'

import { patchOrder, getOrder } from '../../Services/Orders'

import { SuccessModal, Scan, ActiveSim, Error } from './Components'


import { Images } from '../../Themes'

import styles from './OrderProgress.Styles'


const { width } = Dimensions.get('screen')


const TextLoader = () => {

  const theme = useTheme()

  return (
    <ContentLoader
      speed={2}
      width={width - 32}
      height={100}
      backgroundColor={theme.colors.shimmerCenterColor}
      foregroundColor={theme.colors.shimmerEdgesColor}
      style={styles.loader}
    >
      <Rect x="4" y="4" rx="5" ry="5" width={150} height={20} />
      <Rect x="4" y="40" rx="5" ry="5" width={100} height={20} />
    </ContentLoader>
  )
}

const TimelineLoader = () => {

  const theme = useTheme()

  return (
    <ContentLoader
      speed={2}
      width={width - 32}
      height={200}
      backgroundColor={theme.colors.shimmerCenterColor}
      foregroundColor={theme.colors.shimmerEdgesColor}
      style={styles.loader}
    >
      <Rect x="4" y="4" rx="5" ry="5" width={20} height={20} />
      <Rect x="40" y="4" rx="5" ry="5" width={100} height={20} />
      <Rect x="12" y="24" rx="2" ry="2" width={5} height={50} />

      <Rect x="4" y="74" rx="5" ry="5" width={20} height={20} />
      <Rect x="40" y="74" rx="5" ry="5" width={100} height={20} />
      <Rect x="12" y="94" rx="2" ry="2" width={5} height={50} />

      <Rect x="4" y="144" rx="5" ry="5" width={20} height={20} />
      <Rect x="40" y="144" rx="5" ry="5" width={100} height={20} />
    </ContentLoader>
  )
}



const OrderProgressScreen = ({ navigation, route }) => {

  const { orderId } = route.params

  const theme = useTheme()

  const themedImages = getThemeImages(theme.name)

  const [username, setUsername] = useState()
  const msisdn = ReduxCache.getMsisdn()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState({})
  const [activeStep, setActiveStep] = useState(2)

  const [orderedSim, setOrderedSim] = useState('')

  const [modalState, setModalState] = useState({
    isVisible: false,
    type: ''
  })

  const [scanLoading, setScanLoading] = useState(false)
  const [scanError, setScanError] = useState('')

  const [image, setImage] = useState()
  const [barCode, setBarCode] = useState('')


  const getData = () => {
    setLoading(true)
    setError('')
    getOrder(orderId, {
      onSuccess: (res) => {
        setOrder({
          timeline: [
            { title: 'order_progress_order_confirmed', type: 'confirmed', status: 'COMPLETED' },
            { title: 'order_progress_sim_delivery', type: 'delivery', status: 'COMPLETED' },
            {
              title: 'order_progress_order_completed',
              type: 'completed',
              status: 'COMPLETED',
              cardTitle: 'order_progress_activate_service',
              updateTime: moment().format('DD/MM/YYYY, HH:mm'),
              action: 'scan'
            },
          ],
          ...res
        })
        if (res?.status === 'completed') {
          setActiveStep(3)
        } else {
          setActiveStep(2)
        }
        setOrderedSim(res?.msisdn)
        setLoading(false)
      },
      onError: (err) => {
        setError(err)
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    getMsisdnData()
      .then((res) => {
        const storedUsername = res?.username
        setUsername(storedUsername)
      })
      .catch(() => { })
    getData()
  }, [])

  const onClose = () => navigation?.goBack()

  const onCardActionPress = (actionType) => {
    if (actionType === 'scan') {
      setModalState(prev => ({
        ...prev,
        isVisible: true,
        type: 'simReady'
      }))
    }
  }

  const closeModal = () => {
    setModalState(prev => ({
      ...prev,
      isVisible: false,
      type: ''
    }))
  }

  const activateSim = () => {
    const data = {
      orderId: orderId,
      sim: barCode
    }
    setScanLoading(true)
    patchOrder(data, {
      onSuccess: () => {
        setModalState(prev => ({
          ...prev,
          type: 'activated'
        }))
        setScanLoading(false)
      },
      onError: (err) => {
        setScanLoading(false)
        setScanError(err)
        setModalState(prev => ({
          ...prev,
          type: 'error'
        }))
      }
    })
  }


  return (
    <VFScreen
      title="order_progress_title"
      titlePlaceholders={[orderId]}
      onClose={onClose}
      showBack
      onBack={onClose}
      withSubHeader
      withHeaderSpace
      subHeaderTitleStyle={styles.subHeaderTitleStyle}
      subHeaderTitle="order_progress_status"
      subHeaderTitlePlaceholders={[order?.status]}
      // subHeaderSubTitle={ContentManager.translate('order_progress_delivery', [order?.estDelivery])}
      subHeaderSubTitleStyle={styles.subHeaderSubTitleStyle}
      subHeaderDescription={ContentManager.translate('order_progress_account', [username || msisdn])}
      renderDescriptionInfo={() => <ManualRefresh images={themedImages} containerStyle={styles.manualRefresh} onRefresh={getData} />}
    >
      <ScrollView contentContainerStyle={styles.container} >
        {error ? <>
          <ErrorScreen
            text={error}
            onPress={getData}
          />
        </> : <>
          {loading ? <TextLoader /> : <>
            <VFText
              i18nKey="order_progress_timeline_title"
              style={styles.title}
            />
            <VFText
              i18nKey="order_progress_timeline_subtitle"
              style={styles.subtitle(theme)}
              placeHolders={[order?.date]}
            />
          </>}

          {loading ? <TimelineLoader /> : <Timeline
            steps={order?.timeline}
            initialActiveSections={[activeStep]}
            renderStepBody={(data) => {
              return (
                <Card
                  type="main"
                  style={styles.cardContainer(theme)}
                >
                  <View style={styles.cardHeaderStyle(theme)}>
                    <Icon
                      name={Images.sim_card}
                      size={80}
                      resizeMode="contain"
                      style={styles.imageBackground(theme)}
                    />
                    <View style={styles.textContainer}>
                      <VFText
                        i18nKey={data?.cardTitle}
                        style={styles.cardTitle}
                      />
                      <VFText
                        i18nKey="order_progress_timeline_step_update_time"
                        style={styles.cardSubTitle}
                      />
                      <VFText
                        i18nKey={data?.updateTime}
                        style={styles.boldText}
                      />
                    </View>
                  </View>
                  <VFButton
                    title={`order_progress_${data?.action}_button`}
                    type="secondary"
                    onPress={() => onCardActionPress(data?.action)}
                  />
                </Card>
              )
            }}
          />}

        </>}
      </ScrollView>
      <Modal
        isVisible={modalState?.isVisible}
        close={closeModal}
        style={styles.modalContainer(theme)}
      >
        <>
          {modalState.type === 'simReady' && <SuccessModal
            title="scan_sim_title"
            subTitle="scan_sim_subtitle"
            buttonTitle="scan_sim_scan_button"
            onClose={closeModal}
            onButtonPress={() => {
              setModalState(prev => ({
                ...prev,
                type: 'scan'
              }))
            }}
          />}
          {modalState.type === 'scan' && <Scan
            onClose={closeModal}
            onButtonPress={activateSim}
            image={image}
            setImage={setImage}
            barCode={barCode}
            setBarCode={setBarCode}
            loading={scanLoading}
          />}

          {modalState.type === 'activated' && <ActiveSim
            onClose={() => {
              closeModal()
              navigation.navigate('Dashboard')
            }}
            simNumber={orderedSim}
          />}

          {modalState.type === 'error' && <Error
            onClose={closeModal}
            error={scanError}
          />}
        </>
      </Modal>
    </VFScreen>
  )
}

OrderProgressScreen.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default OrderProgressScreen
