import React from 'react'
import PropTypes from 'prop-types'

// import { AddPaymentScreen } from '@vfgroup-oneplatform/framework/Payment'
import { AddPaymentScreen } from '@vfgroup-oneplatform/framework/Payment'
import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'


// import crypto from 'isomorphic-webcrypto'
// import {TextEncoder} from 'text-encoding'

import { CyberSource, VFPayments } from '../../Services'
import NavigationService from '../../Navigation/NavigationService'


const AddPayment = ({ route }) => {

  const { card, clientId, msisdn, onEditPaymentSuccess } = route.params

  // async function sha256(message) {
  //   const msgUint8 = new TextEncoder().encode(message)
  //   const hashBuffer = await crypto.subtle.digest({name: 'SHA-256'}, msgUint8)
  //   const hashArray = Array.from(new Uint8Array(hashBuffer))
  //   const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
  //   return hashHex
  // }

  const getCardData = () => {
    return {
      ...card,
      expirationData: card.expiry.replace('/20', '/'),
      cardNo: '************' + card.lastFourDigits,
      cardName: ContentManager.translate(card.cardName),
      nameOnCard: ContentManager.translate(card.nameOnCard)
    }
  }

  const onPressSaveCard = async cardData => {
  }

  const handleDeleteCard = async () => {
    try {
      await deleteCustomerPaymentInstrument()
    } catch (error) {
      const errObj = {
        actionTxt: 'top_up_quick_action_close_button_text',
        title: 'payment_delete_card_error',
        description: 'payment_delete_card_error_message'
      }
      throw errObj
    }
  }

  const deleteCustomerPaymentInstrument = async () => {

    const body = ''

    try {
      const data = await VFPayments.generateToken(msisdn, body, 'POST', { 'vf-operator': 'CustomerPaymentInstrument.delete' })
      var tokenized = data.id[0].value

      const customerPaymentInstrument = await CyberSource.deleteCustomerCard(tokenized, clientId, card.id, body)
      return customerPaymentInstrument

    } catch (error) {
      throw new Error(error)
    }
  }


  const onClose = () => {
    NavigationService.goBack()
  }

  return (
    <AddPaymentScreen
      title={card?.cardName}
      onClose={onClose}
      onBack={onClose}
      onPressSaveCard={onPressSaveCard}
      getCardData={getCardData}
      onEditPaymentSuccess={onEditPaymentSuccess}
      onPressDeleteCard={handleDeleteCard}
      hiddenFields={['nameOnCard']}
      validations={{ cardNo: () => true }}
      hideScanCard={true}
    />
  )
}

AddPayment.propTypes = {
  route: PropTypes.object
}


export default AddPayment
