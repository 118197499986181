import { StyleSheet, Dimensions } from 'react-native'
import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

const { width } = Dimensions.get('screen')


const getTitleFontSize = () => {
  if (width > 400) {
    return Fonts.size.h2
  } else if (width > 250) {
    return Fonts.size.h3
  } else {
    return Fonts.size.h4
  }
}

const styles = StyleSheet.create({
  container: theme => ({
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: theme.colors.cardBackgroundColor,
    borderRadius: 6,
    paddingHorizontal: 12,
    paddingTop: 15.5,
    paddingBottom: 12,
    overflow: 'hidden',
  }),
  title: {
    ...Fonts.type.bold,
    fontSize: Fonts.size.regular,
    marginHorizontal: 8,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentContainer: theme => ({
    backgroundColor: theme.colors.cardBackgroundColor,
    height: '100%',
    borderRadius: 6,
  }),
  planTitle: {
    fontSize: getTitleFontSize(),
    fontFamily: Fonts.type.VodafoneLt
  },
  usersText: {
    fontSize: Fonts.size.h6,
  },
  footerText: theme => ({
    fontSize: Fonts.size.regularSmall,
    color: theme.colors.textColorTwo,
    paddingTop: 10,
  }),
  textView: {
    flex: 1,
    justifyContent: 'center'
  }
})


export default styles
