import { StyleSheet } from 'react-native'

import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  subHeaderTitleStyle: theme => ({
    fontSize: Fonts.size.h5,
    color: theme.colors.textColor,
  }),
  subHeaderSubTitleStyle: theme => ({
    ...Fonts.type.bold,
    color: theme.colors.textColorTwo,
    fontSize: Fonts.size.h5,
    marginBottom: -2
  }),
  subHeaderBeforeSubTitleStyle: theme => ({
    fontSize: Fonts.size.h6,
    color: theme.colors.textColorTwo,
    marginRight: 5
  }),
  containerStyle: {
    paddingHorizontal: 16,
    paddingTop: 15
  },
  subHeaderFullContainerStyle: {
    marginBottom: 10,
  },
  emptyContainer: {
    alignItems: 'center',
    marginTop: 40
  },
  emptyTitle: {
    ...Fonts.type.bold,
    fontSize: Fonts.size.h5
  }
})

