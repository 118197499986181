import React from 'react'
import { View, Text } from 'react-native'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'

import { VFText, Icon, VFButton } from '@vfgroup-oneplatform/foundation/Components'
import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'
import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import { isAdminView } from '../../../Utils'

import { Images } from '../../Themes'

import styles from './BusinessOverviewButton.Styles'


const BusinessOverviewButton = ({ onPress, businessName }) => {

  const balanceReduxData = useSelector(store => store.balance)

  const theme = useTheme()

  let totalUsers = 0

  Object.keys(balanceReduxData.data).filter(key => {
    if (key.includes('businessplan')) {
      totalUsers = totalUsers + balanceReduxData.data[key]?.details?.amount?.$
    }
  })


  if (!isAdminView()) {
    return null
  }


  return (
    <View style={styles.container} >
      <Icon
        name={Images.business_image}
        size={80}
        resizeMode="contain"
      />
      <View style={styles.contentContainer}>
        <View style={styles.textContainer}>
          <Text style={styles.title(theme)}>{businessName ? businessName : ContentManager.translate('subtray_business_section_title')}
            {totalUsers !== 0 && <VFText
              i18nKey={totalUsers > 1 ? 'subtray_business_section_users' : 'subtray_business_section_user'}
              placeHolders={[totalUsers.toString()]}
              style={styles.subtitle}
            />}
          </Text>
        </View>
        <VFButton
          onPress={onPress}
          title="subtray_business_section_button_title"
          type="secondary"
          style={styles.buttonStyle}
        />
      </View>
    </View>
  )
}
BusinessOverviewButton.defaultProps = {
  onPress: () => { }
}
BusinessOverviewButton.propTypes = {
  businessName: PropTypes.string,
  onPress: PropTypes.func,
}

export default BusinessOverviewButton
