export default [
    {
      componentName: 'Dashboard',
      title: 'dashboard_title',
      dashboardItems: [
        {
          name: 'MainCardComponent',
          componentId: 'MainCardComponent',
          extraInfo: { isLoading: false, error: null, bucket: [] },
        },
        {
          name: 'SecondaryCardComponent',
          componentId: 'SecondaryCardComponent',
          extraInfo: { isLoading: false, error: null, bucket: [] },
        },
        {
          name: 'ThirdCardComponent',
          componentId: 'ThirdCardComponent',
          extraInfo: { isLoading: false, error: null, bucket: [] },
        },
        {
          name: 'FourthCardComponent',
          componentId: 'FourthCardComponent',
          extraInfo: { isLoading: false, error: null, bucket: [] },
        },
      ],
    },
    {
      componentName: 'DashboardDiscovery',
      title: 'dashboard_discovery_label',
      discoveryItems: [
        {
          type: 'discover_fixed_component',
          name: 'dashboard_upgrade_component_name',
          componentId: 'DiscoverFixedCard',
          extraInfo: {
            title: 'dashboard_upgrade_component_title',
          },
        },
        {
          type: 'group',
          name: 'dashboard_basic_group_component_name',
          moreText: 'dashboard_group_component_show_more',
          lessText: 'dashboard_group_component_show_less',
          subItems: [
            {
              name: 'dashboard_demo_component_name',
              componentId: 'demo-component',
              extraInfo: {
                title: 'dashboard_top_up_item_title',
                icon: 'ic_top_up',
              },
            },
          ],
        },
      ],
    },
]
