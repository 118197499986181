import { StyleSheet, Platform, Dimensions } from 'react-native'
import { Fonts, Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'hidden',
    borderRadius: 6,
    height: '100%'
  },
  imageStyle: {
    ...Platform.select({
      web: {
        height: "100%",
        width: '100%'
      },
      default: {
        width: undefined,
        height: undefined,
      }
    })
  },
  textContainer: {
    padding: 10,
    justifyContent: 'space-between',
    height: '100%',
    flex: 1
  },
  title: {
    fontFamily: Fonts.type.VodafoneLt,
    color: Colors.white,
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 26 : 20,
    lineHeight: 22,
    marginBottom: 16,
    fontWeight: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 700 : null
  },
  titlePromo: {
    fontFamily: Fonts.type.VodafoneLt,
    color: Colors.black,
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 22 : 17,
    lineHeight: 12,
    marginBottom: 9
  },
  subTitle: {
    color: Colors.white,
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 17 : 12,
    lineHeight: 12,
    letterSpacing: 0
  },
  subTitlePromo: {
    color: Colors.white,
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 17 : 12,
    lineHeight: 12,
    letterSpacing: 0
  },
  textIconContainer: {
    flexDirection: 'row'
  },
  text: {
    fontFamily: Fonts.type.VFFont,
    color: Colors.white,
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 17 : 12,
    lineHeight: 22.9,
    marginTop: 1
  },
  textPromo: {
    fontFamily: Fonts.type.VFFont,
    color: Colors.white,
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 17 : 12,
    lineHeight: 22.9,
    marginTop: 1
  },
  btn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  imageBorderRadius: {
    borderRadius: 6
  },
})
