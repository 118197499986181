export const actionTypes = {
  REQUEST_GET_LIST: 'REQUEST_GET_LIST',
  SUCCESS_GET_LIST: 'SUCCESS_GET_LIST',
  ERROR_GET_LIST: 'ERROR_GET_LIST',
  RESET_GET_LIST: 'RESET_GET_LIST',
  SET_SELECTED_AMOUNT: 'SET_SELECTED_AMOUNT',
  REQUEST_TRANSFER_VCOINS: 'REQUEST_TRANSFER_VCOINS',
  SUCCESS_TRANSFER_VCOINS: 'SUCCESS_TRANSFER_VCOINS',
  ATTENTION_TRANSFER_VCOINS: 'ATTENTION_TRANSFER_VCOINS',
  ERROR_TRANSFER_VCOINS: 'ERROR_TRANSFER_VCOINS',
  RESET_TRANSFER_VCOINS: 'RESET_TRANSFER_VCOINS',
}