const TABS_LABELS = {
    MY_PLAN: 'MY_PLAN',
    DEVICES: 'DEVICES',
    ADDONS: 'ADDONS',
    BALANCE: 'BALANCE',
  }

  const PAY_M_TABS = [
    {
      key: TABS_LABELS.MY_PLAN,
      tab_key: 'my_plan_screen_title'
    },
    {
        key: TABS_LABELS.BALANCE,
        tab_key: 'balance_screen_title'
    },
    {
      key: TABS_LABELS.ADDONS,
      tab_key: 'addons_screen_title'
    }
  ]

  const PAY_G_TABS = [
    {
        key: TABS_LABELS.MY_PLAN,
        tab_key: 'my_plan_screen_title'
    },
    {
      key: TABS_LABELS.BALANCE,
      tab_key: 'balance_screen_title'
    },
    {
      key: TABS_LABELS.ADDONS,
      tab_key: 'addons_screen_title'
    }
  ]

  const FIX_TABS = [
    {
      key: TABS_LABELS.MY_PLAN,
      tab_key: 'my_plan_screen_title'
    }
  ]

  export { TABS_LABELS, PAY_G_TABS, FIX_TABS, PAY_M_TABS }
