import React, { useEffect, useRef, useState } from 'react'
import {
  View,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  ViewPropTypes
} from 'react-native'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { VFText } from '@vfgroup-oneplatform/foundation/Components'
import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { getMenu } from '../../../../Utils'

import { Images } from '@vfgroup-oneplatform/framework/Themes'

import ReduxCache from '../../../../Utils/ReduxCache'

import styles from './TobiInteractive.Styles'

const SCREEN_WIDTH = Dimensions.get('screen').width
const INITIAL_CONTAINER_SIZE = SCREEN_WIDTH / 5.294
const FINAL_CONTAINER_SIZE = SCREEN_WIDTH - SCREEN_WIDTH / 10
const MAX_TRANSLATEX = SCREEN_WIDTH - SCREEN_WIDTH / 1.8

function TobiInteractive({
  trayIcon,
  customTrayIconStyle,
  showModal,
  allowFontScaling,
  style,
  dropTray,
  upTray,
  trayType,
  finishedAnimation,
  navigationRef,
  showExpandedTobi,
}) {

  const userType = ReduxCache.getUserType()

  const configurationsRedux = useSelector(store => store.configurations)
  const menu = getMenu(configurationsRedux) || []

  const screens = menu.filter(el => el.key.includes('itemsSupport'))
  const supportScreens = []

  screens.map(item => {
    if (item?.screen !== 'Dashboard') {
      supportScreens.push(item?.screen)
    }
  })

  const [showSupport, setShowSupport] = useState(false)

  const [disabled, setDisabled] = useState(false)
  const trayBreakTime = 3000

  const trayAnimation = useRef(new Animated.Value(INITIAL_CONTAINER_SIZE)).current
  const tobiTranslateX = useRef(new Animated.ValueXY({ x: 0, y: 0 })).current
  const timeoutRef = useRef(null)
  const isTobiRight = useRef(false)

  useEffect(() => {
    const unsubscribe = navigationRef.addListener('state', (state) => {
      setShowSupport(supportScreens.includes(navigationRef.getCurrentRoute()?.name))
      onNavigationChange()
    })
    return unsubscribe
  }, [navigationRef, configurationsRedux, userType])

  useEffect(() => {
    if (dropTray) {
      handelExpandAnimation()
    }
  }, [dropTray])

  useEffect(() => {
    if (upTray) {
      trayAnimation.stopAnimation()
      setTimeout(() => {
        handleCenterAnimation()
      }, 0)
    }
  }, [upTray])

  const handelMinimizeAnimation = (time = 0) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      Animated.parallel([
        Animated.timing(trayAnimation, {
          toValue: SCREEN_WIDTH / 3,//
          duration: 400,
          useNativeDriver: false
        }),
        Animated.timing(tobiTranslateX, {
          toValue: { x: MAX_TRANSLATEX, y: 0 },//
          duration: 400,
          useNativeDriver: false
        })
      ]).start(({ finished }) => {
        isTobiRight.current = true
      })
    }, time)
  }

  const handelExpandAnimation = () => {
    setTimeout(() => {
      Animated.timing(trayAnimation, {
        toValue: FINAL_CONTAINER_SIZE,
        duration: 400,
        delay: 500,
        useNativeDriver: false
      }).start(() => {
        handelMinimizeAnimation(trayBreakTime)
      })
    }, 0)
  }

  const handleCenterAnimation = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    handelOpenModal()
  }

  const onNavigationChange = () => {
    setDisabled(true)
    const callBack = () => {
      handleCenterAnimation()
      handelExpandAnimation()
    }
    handelOpenModal(callBack)

    setTimeout(() => {
      setDisabled(false)
    }, 5000)
  }

  useEffect(() => {
    if (!showExpandedTobi) {
      setTimeout(() => {
        Animated.parallel([
          Animated.timing(trayAnimation, {
            toValue: SCREEN_WIDTH / 3,//
            duration: 400,
            useNativeDriver: false
          }),
          Animated.timing(tobiTranslateX, {
            toValue: { x: MAX_TRANSLATEX, y: 0 },//
            duration: 400,
            useNativeDriver: false
          })
        ]).start()
      }, 1000)
    }
  }, [showExpandedTobi])

  const onTobiPress = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    const callBack = () => {
      handelMinimizeAnimation()
      handleCenterAnimation()
      finishedAnimation && finishedAnimation()
    }
    handelOpenModal(callBack)
  }

  const handelOpenModal = callBack => {
    Animated.parallel([
      Animated.timing(tobiTranslateX, {
        toValue: { x: 0, y: 0 },
        duration: 300,
        useNativeDriver: false
      }),
      Animated.timing(trayAnimation, {
        toValue: INITIAL_CONTAINER_SIZE,
        duration: 200,
        useNativeDriver: false
      })
    ]).start(({ finished }) => {
      if (finished) {
        callBack && callBack()
      }
    })
  }

  const theme = useTheme()

  const opacity = trayAnimation.interpolate({
    inputRange: [
      INITIAL_CONTAINER_SIZE,
      FINAL_CONTAINER_SIZE * 0.99,
      FINAL_CONTAINER_SIZE
    ],
    outputRange: [0, 0, 1]
  })

  const paddingLeft = trayAnimation.interpolate({
    inputRange: [INITIAL_CONTAINER_SIZE, FINAL_CONTAINER_SIZE],
    outputRange: [0, 0]
  })

  const tobiTranslateXInter = tobiTranslateX.x.interpolate({
    inputRange: [0, SCREEN_WIDTH - 20],
    outputRange: [0, SCREEN_WIDTH - 20],
    extrapolate: 'clamp'
  })


  if (!showSupport) {
    return null
  }

  return (
    <View style={styles.wrapper} >
      <View style={[styles.outerContainer, style]} >
        <Animated.View
          style={[
            styles.container(theme),
            {
              width: trayAnimation,
              paddingLeft,
              transform: [
                { translateX: tobiTranslateXInter },
                { translateY: tobiTranslateX.y }
              ]
            }
          ]}
        >
          <TouchableOpacity
            onPress={onTobiPress}
            style={
              trayType === 'main' ? styles.tobiContainerStyle : null
            }
            disabled={disabled}
          >
            {trayIcon ? (
              <View
                style={[styles.customTrayIcon, customTrayIconStyle]}
                accessibilityLabel={'trayCustomIcon'}
                testID={'trayCustomIcon'}
              >
                {trayIcon}
              </View>
            ) : (
              <Image
                source={Images.toBi}
                style={styles.tobiStyle}
                accessibilityLabel={'trayTobiIcon'}
                testID={'trayTobiIcon'}
              />
            )}
          </TouchableOpacity>

          <Animated.View style={[styles.textView, { opacity }]}>
            <VFText
              i18nKey='tobi_interactive_help_message'
              testID="tobiMessage"
              style={styles.text}
              allowFontScaling={allowFontScaling}
              numberOfLines={2}
            />
          </Animated.View>
        </Animated.View>
      </View>
    </View>
  )
}

TobiInteractive.defaultProps = {
  willUnmount: () => { }
}

TobiInteractive.propTypes = {
  welcomeMessage: PropTypes.string,
  notifications: PropTypes.array,
  trayIcon: PropTypes.element,
  willUnmount: PropTypes.func,
  welcomeMessageBreakTime: PropTypes.number,
  notificationsBreakTime: PropTypes.number,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  allowFontScaling: PropTypes.bool,
  style: ViewPropTypes.style,
  dropTray: PropTypes.bool,
  upTray: PropTypes.bool,
  trayType: PropTypes.string,
  customTrayIconStyle: ViewPropTypes.style,
  finishedAnimation: PropTypes.func,
  navigationRef: PropTypes.object
}

export default TobiInteractive
