import moment from 'moment'

import simplify from 'simplify-js'

import ReduxCache from '../../Utils/ReduxCache'

const mapPolygonCoords = (polygons = []) => {
  if (polygons.length === 0) {
    return polygons
  }
  const simplified = simplify(polygons ?? [], 0.0003, true)
  return simplified.map(polygon => {
    const { x, y } = polygon ?? {}
    return {
      latitude: y < 90 && y > -90 ? y : 0,
      longitude: x < 180 && y > -180 ? x : 0
    }
  })
}

const checkForGracePeriod = date => {
  const now = moment()
  const grace = moment(date)
  return now.isSameOrBefore(grace)
}

export const customerCheckMapper = res => {
  const fixId = res?.relatedParty?.find(el => el.role === 'customer')?.id
  return {
    fixId,
    exists: !!fixId
  }
}

export const errorMapper = error => {
  const payload = error?.response?.data?.message ?? error?.message
  return payload
}

export const locationResponseMapper = res => {
  const { data } = res
  const coveragePolygons = []
  if (!Array.isArray(data) || data.length === 0) {
    return []
  }
  data?.map(item => {
    const tempArray = []
    item?.geographicLocation?.geometry?.map(_item => {
      tempArray.push({
        x: _item?.x,
        y: _item?.y
      })
    })
    if (tempArray.length !== 0) { coveragePolygons.push(tempArray) }
  })
  return coveragePolygons
  // return data
  //   .map((item, _i) => {
  //     return {
  //       id: _i,
  //       city: item?.city || '',
  //       country: item?.country || '',
  //       polygonCoords: mapPolygonCoords(item?.geographicLocation?.geometry)
  //     }
  //   })
  //   .filter(el => el.polygonCoords?.length !== 0)
}

export const customerCheckByIDMapper = res => {
  if (Array.isArray(res)) {
    return res[0]?.id ?? null
  } else {
    return null
  }
}

export const createGroupMapper = res => {
  return res?.status
}

export const validateLocationResponseMapper = res => {
  return res?.data[0] ?? {}
}

export const locationHeaders = {
  'vf-country-code': 'al'
}

export const bundleResponseMapper = res => {
  const output = {
    offers: []
  }
  if (Array.isArray(res)) {
    for (const entry of res) {
      if (Array.isArray(entry?.recommendationItem) === false) {
        continue
      }
      for (const item of entry.recommendationItem) {
        const characteristic = item.product?.productCharacteristic
        output.offers.push({
          // General
          id: item.product?.productOffering?.name,
          name: item.product?.productOffering?.name,
          recommended: entry.recommendationType === 'converged',
          // Price & Discount
          discount: characteristic?.find(el => el.name === 'discount'),
          price: item.product?.productPrice[0]?.price?.taxIncludedAmount,
          // Bundle Configuration
          tv: characteristic?.find(el => el.name === 'TV'),
          slots: characteristic?.find(el => el.name === 'members'),
          internet: characteristic?.find(el => el.name === 'Internet'),
          tariffPlan: characteristic?.find(el => el.name === 'tariffPlan'),
          download: characteristic?.find(el => el.name === 'downloadSpeed'),
          // Terms
          productTerms: item.product?.productTerm?.find(
            el => el['@type'] === 'commitmentTerm'
          )?.duration
        })
      }
    }
  }
  return output
}

export const customMobilePlansMapper = res => {
  const output = {
    plans: []
  }
  if (Array.isArray(res)) {
    for (const entry of res) {
      if (Array.isArray(entry?.recommendationItem) === false) {
        continue
      }
      for (const item of entry.recommendationItem) {
        output.plans.push({
          details: {
            internet:
              item.product?.productCharacteristic.find(
                el => el.name === 'internet'
              )?.value ?? 0,
            sms:
              item.product?.productCharacteristic.find(el => el.name === 'sms')
                ?.value ?? 0,
            minutes:
              item.product?.productCharacteristic.find(
                el => el.name === 'minutes'
              )?.value ?? 0
          },
          recommended: false,
          id: item.product?.productOffering?.name,
          name: item.product?.productOffering?.name,
          productTerms: item.product?.productTerm[0]?.duration,
          price: item.product?.productPrice[0]?.price?.taxIncludedAmount
        })
      }
    }
  }
  return output
}

export const customSlotsMapper = res => {
  const output = {
    slots: []
  }
  if (Array.isArray(res)) {
    output.slots = res[0].prodSpecCharValueUse?.map(item => {
      return {
        id: item.name,
        slots: item.productSpecCharacteristicValue[0]?.value
      }
    })
  }
  return output
}

export const customTVandFixMapper = res => {
  const INTERNET = 'Internet_PS'
  const DOWNLOAD_SPEED = 'DownloadSpeed'
  const TV = 'CableTV_PS'
  const output = {
    tvs: [],
    speeds: []
  }
  if (Array.isArray(res)) {
    for (const element of res) {
      if (element?.productSpecification?.name === INTERNET) {
        const { value, unitOfMeasure } =
          element?.prodSpecCharValueUse?.find(
            entry => entry.name === DOWNLOAD_SPEED
          )?.productSpecCharacteristicValue[0] ?? {}
        const speed = `${value ?? ''} ${unitOfMeasure ?? ''}`.trim()
        output.speeds.push({
          title: speed,
          value: value,
          id: element.id,
          packageName: element.name,
          name: speed.replace(/\s/g, '')
        })
      } else if (element?.productSpecification?.name === TV) {
        const tv = {
          id: element.id,
          name: element.name
        }
        output.tvs.push(tv)
      }
    }
  }
  output.speeds.sort((a, b) => a.value - b.value)
  return output
}

export const getCommonHeaders = () => {
  // const locale = ReduxCache.getLanguage() ?? 'al'
  return {
    Accept: 'application/json',
    'vf-country-code': 'al',
    'vf-project': 'unlimited',
    'Accept-Language': 'en/al',
    'Content-Type': 'application/json'
  }
}

export const customBundlePriceMapper = data => {
  const output = {
    price: data.productOrderItem?.find(el => el.id === 'discountedPrice')
      ?.itemPrice[0]?.price?.taxIncludedAmount,
    discount: data.productOrderItem?.find(el => el.id === 'discountPrice')
      ?.itemPrice[0]?.price?.taxIncludedAmount
  }
  return output
}

export const validateNumberMapper = data => {
  return {
    isValid: data.status === 'initialized'
  }
}

// TODO: Actual mapping
export const customerResponseMapper = data => {
  return data
}

export const memberResponseMapper = res => {
  const output = {
    admin: '',
    members: [],
    isAdmin: false
  }
  if (Array.isArray(res)) {
    output.members = res.map(member => {
      const data = {
        number: member.id,
        status: member.status,
        role: member.relatedParty[0]?.role?.toLowerCase(),
        isGracePeriod: checkForGracePeriod(member.validFor?.endDateTime),
        gracePeriodEndDate: moment(member.validFor?.endDateTime).format(
          'DD/MM/YYYY'
        ),
        acceptedDate: moment(member.validFor?.startDateTime).format(
          'DD/MM/YYYY'
        )
      }
      if (data.role === 'admin') {
        output.admin = data.number
      }
      return data
    })
  }
  output.members.sort(a => {
    return a.role === 'admin' ? -1 : 1
  })
  output.isAdmin = output.admin === ReduxCache.getMsisdn()
  return output
}

export const contractResponseMapper = data => {
  const output = {
    offer: {
      recommended: false
    },
    name: data.description ?? ''
  }
  if (data) {
    const slots = data.productOrderItem?.find(
      el => el?.product?.description?.toLowerCase() === 'members'
    )?.product?.name
    const download = data.productOrderItem?.find(
      el => el?.product?.description?.toLowerCase() === 'internet'
    )?.product?.productCharacteristic[0]?.value
    const internet = data.productOrderItem?.find(
      el => el?.product?.description?.toLowerCase() === 'internet'
    )?.product?.name
    const plan = data.productOrderItem?.find(
      el => el?.product?.description?.toLowerCase() === 'tariffplan'
    )?.product?.name
    output.offer = {
      ...output.offer,
      id: data.id,
      name: data.agreement[0]?.name ?? '',
      //
      discount: { unit: '', value: 0 },
      price: data.orderTotalPrice[0]?.price?.taxIncludedAmount,
      //
      tv: data.productOrderItem?.find(
        el => el?.product?.description?.toLowerCase() === 'tv'
      )?.product?.name,
      slots: { id: slots, name: 'members', value: slots },
      internet: { id: '', name: 'Internet', value: internet },
      tariffPlan: { id: plan, name: 'tariffPlan', value: plan },
      download: {
        id: 'string',
        value: download,
        name: 'downloadSpeed'
      },
      productTerms: { amount: 24, units: 'Month' }
    }
  }
  console.log(output)
  return output
}

export const addMemberResponseMapper = res => {
  return res
}

export const removeMemberResponseMapper = res => {
  return res
}

export const isGroupMemberMapper = res => {
  if (Array.isArray(res)) {
    return res[0].status === 'groupFound'
  } else {
    return false
  }
}
