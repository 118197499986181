import { Buffer } from 'buffer'

import PropTypes from 'prop-types'

import React, { useState, useEffect } from 'react'
import { NativeModules, Platform, View } from 'react-native'


import PaymentWrapper from '@vfgroup-oneplatform/framework/Payment/PaymentWrapper'
import { PaymentTypes, PaymentScreenTypes } from '@vfgroup-oneplatform/framework/Payment/Utils/PaymentTypes.js'
import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'


import crypto from 'isomorphic-webcrypto'
import { Base64 } from 'js-base64'
import { TextEncoder } from 'text-encoding'

import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'


import Modal from 'react-native-modal'


import { CyberSource, VFPayments } from '../../Services'

import ReduxCache from '../../Utils/ReduxCache'

import { Images as PaymentImages } from '../../Themes'




const tokenTypes = {
  DEFAULT: 'Default',
  TOKENID: 'TokenId',
  CARDID: 'CardId'
}

function PaymentsProvider(props) {
  console.log('🚀 ~ file: PaymentsProvider.js:40 ~ PaymentsProvider ~ props:', props)
  const { paymentObj, isOfferActivating } = props
  console.log('🚀 ~ file: PaymentsProvider.js:41 ~ PaymentsProvider ~ isOfferActivating:', isOfferActivating)

  const showPayment = props.isVisible

  const [cards, setCards] = useState([])
  const [defaultPayment, setDefaultPayment] = useState({})
  const [clientId, setClientId] = useState()

  const [paymentOptions, setPaymentOptions] = useState({ isCardListOpend: true })
  const [onErrorPress, setOnErrorPress] = useState()

  const defaultError = 'payment_quick_action_error'
  const [errorMessage, setErrorMessage] = useState(defaultError)

  const [loading, setLoading] = useState(false)

  const language = ReduxCache.getLanguage()
  const msisdn = ReduxCache.getMsisdn()
  const currency = 'ALL'
  const isThirdParty = false

  const theme = useTheme()

  useEffect(() => {
    setPaymentOptions({
      screen: PaymentScreenTypes.ADD_PAYMENT_CARD_LOADING
    })

  }, [])


  useEffect(() => {
    if (!clientId) {
      return
    }
    getPaymentInstruments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])


  useEffect(() => {
    if (!msisdn) {
      return
    }

    getCustomerId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msisdn])


  const toggleModal = () => {
    props.setShowPayment(false)
  }


  const getCustomerId = () => {
    setLoading(true)

    VFPayments.getCustomerId(msisdn, { 'vf-operator': 'payments' })
      .then(data => {
        const externalId = data.details.externalId.value
        setClientId(externalId)
        if (clientId) {
          setPaymentOptions({
            screen: PaymentScreenTypes.DEFAULT
          })
        }
      })
      .catch(error => {

        setErrorMessage(defaultError)
        if (error?.data?.reasonCode == 400) {
          createCustomerCyberSource()
        } else {
          setPaymentOptions({
            screen: PaymentScreenTypes.ERROR
          })
          setOnErrorPress(() => () => getCustomerId())
        }
      })
  }

  const createCustomerCyberSource = async () => {
    const hashedMsisdn = await sha256(msisdn)
    const body = {
      'buyerInformation': {
        'merchantCustomerID': hashedMsisdn
      },
      'clientReferenceInformation': {
        'code': hashedMsisdn
      }
    }

    try {
      const data = await VFPayments.generateToken(msisdn, body, 'POST', { 'vf-operator': 'Customer.create' })
      var tokenized = data.id[0].value

      const cyberSourceCustomer = await CyberSource.createCustomer(tokenized, body)
      setClientId(cyberSourceCustomer.id)

      saveCustomerId(cyberSourceCustomer.id)

    } catch (error) {
      setPaymentOptions({
        screen: PaymentScreenTypes.ERROR
      })

      setErrorMessage(defaultError)

      setOnErrorPress(() => () => createCustomerCyberSource())
    }

  }

  const saveCustomerId = async (id) => {
    try {
      await VFPayments.saveCustomerIdToVF(msisdn, id, { 'vf-operator': 'payments' })

    } catch (error) {
      setPaymentOptions({
        screen: PaymentScreenTypes.ERROR
      })

      setErrorMessage(defaultError)
      setOnErrorPress(() => () => saveCustomerId(id))
    }
  }

  const importKey = async (jsonWebKey) => {

    return crypto.subtle.importKey(
      'jwk',
      jsonWebKey,
      {
        name: 'RSA-OAEP',
        hash: { name: 'SHA-1' }
      },
      true,
      ['encrypt'],
    )
  }

  async function sha256(message) {
    const msgUint8 = new TextEncoder().encode(message)
    const hashBuffer = await crypto.subtle.digest({ name: 'SHA-256' }, msgUint8)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
    return hashHex.substring(0, 50)
  }

  const tokenizeCard = async (cardNumber, jwk) => {
    const cardNumberBuffer = Buffer.from(cardNumber)

    await crypto.ensureSecure()

    const publicKey = await importKey(jwk)


    try {
      const encryptedCardNumberBuffer = await crypto.subtle.encrypt(
        {
          name: 'RSA-OAEP',
          hash: { name: 'SHA-1' }
        },
        publicKey,
        cardNumberBuffer
      )


      return Base64.btoa(String.fromCharCode.apply(null, new Uint8Array(encryptedCardNumberBuffer)))
    } catch (error) {
      console.error(error)
    }

    throw new Error()
  }

  const createCustomerPaymentInstrument = async (card, instrumentIdentifier) => {

    const body = {
      'card': {
        'expirationMonth': card.expirationMonth,
        'expirationYear': card.expirationYear,
        'type': card.type
      },
      'instrumentIdentifier': {
        'id': instrumentIdentifier
      },
      'billTo': {
        'firstName': card.cardName
      }
    }

    try {
      const data = await VFPayments.generateToken(msisdn, body, 'POST', { 'vf-operator': 'CustomerPaymentInstrument.create' })
      var tokenized = data.id[0].value

      const customerPaymentInstrument = await CyberSource.createCustomerPaymentInstrument(tokenized, clientId, body)
      return customerPaymentInstrument

    } catch (error) {
      return false
    }
  }

  const getBrandById = (id) => {
    const types = {
      '001': 'Visa',
      '002': 'Mastercard',
      '003': 'American Express'
    }

    return types[id]
  }

  const getIdByBrand = (brand) => {
    const types = {
      'Visa': '001',
      'Mastercard': '002',
      'American Express': '003'
    }

    return types[brand]
  }

  const getImageByCardId = (id) => {
    const types = {
      '001': PaymentImages.visa_icon,
      '002': PaymentImages.mastercard_icon,
      '003': PaymentImages.americanExpress_icon
    }

    return types[id]
  }

  const getCardType = (number) => {
    // visa
    var re = new RegExp('^4')
    if (number.match(re) != null) { return 'Visa' }

    // Mastercard
    if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) {
      return 'Mastercard'
    }


    re = new RegExp('^3[47]')
    if (number.match(re) != null) {
      return 'American Express'
    }


    // Visa Electron
    re = new RegExp('^(4026|417500|4508|4844|491(3|7))')
    if (number.match(re) != null) {
      return 'Visa'
    }

    return ''
  }

  // param here when in billing is returned from manage it should update cards and set the default one
  const getPaymentInstruments = (shouldSetDefault = false) => {
    setPaymentOptions({
      screen: PaymentScreenTypes.PAYMENTREQUEST
    })
    VFPayments.generateToken(msisdn, null, 'GET', { 'vf-operator': 'CustomerPaymentInstrument.getList' })
      .then(data => {
        var tokenized = data.id[0].value
        CyberSource.getPaymentInstruments(tokenized, clientId)
          .then(res => {
            const allCards = []
            if (res.count > 0) {
              res._embedded.paymentInstruments.map(card => {
                const cardDetail = {
                  id: card.id,
                  instrumentIdentifier: card.instrumentIdentifier.id,
                  token: card.id,
                  cardName: card.billTo?.firstName || 'credit_card',
                  nameOnCard: 'credit_card',
                  lastFourDigits: card._embedded.instrumentIdentifier.card.number.slice(-4),
                  brand: getBrandById(card.card.type),
                  expiry: card.card.expirationMonth + '/' + card.card.expirationYear,
                  icon: getImageByCardId(card.card.type),
                  tokenType: tokenTypes.CARDID
                }

                allCards.push(cardDetail)

                if (card.default && (!defaultPayment.id || shouldSetDefault)) {
                  cardDetail.tokenType = tokenTypes.DEFAULT
                  setDefaultPayment(cardDetail)
                }
              })

            }

            setCards(allCards)

            if (allCards.length > 0) {
              setPaymentOptions({
                ...paymentOptions,
                isCardListOpend: false
              })
            } else {
              setDefaultPayment({})
            }

            setLoading(false)


          })
          .catch(error => {
            setPaymentOptions({
              screen: PaymentScreenTypes.ERROR
            })
            setErrorMessage(defaultError)
            setOnErrorPress(() => () => getPaymentInstruments())
          })



      })
      .catch(error => {
        setLoading(false)
        setErrorMessage(defaultError)
        setOnErrorPress(() => () => getPaymentInstruments())
      })
  }

  const handleSelectPaymentCard = (card) => {
    setDefaultPayment(card)
  }

  const onPressConfirmSelection = async (selectedItem) => {
    const amount = selectedItem.selectedValue.value

    setPaymentOptions({
      screen: PaymentScreenTypes.PAYMENTREQUEST
    })

    const paymentDetails = {
      instrumentIdentifier: defaultPayment.instrumentIdentifier,
      token: defaultPayment.token || '',
      authorizedAmount: amount,
      currency: currency,
      customer: clientId,
      tokenType: defaultPayment.tokenType || tokenTypes.DEFAULT,
      isThirdParty: isThirdParty,
      expiry: defaultPayment.expiry,
      paymentObj: paymentObj
    }

    try {
      const headers = {
        'x-source-system': 'selfcare',
        'x-source-operator': 'myoffers',
        'vf-country-code': 'al',
        'Accept-Language': language,
        'vf-source': null
      }
      await VFPayments.createOfferPayment(msisdn, paymentDetails, headers)

      setErrorMessage(defaultError)

      return {
        balance: amount
      }
    } catch (error) {
      setOnErrorPress(() => async () => {
        await onPressConfirmSelection(selectedItem)
      })

      throw new Error(error?.message || error?.data?.message)
    }

  }

  const onPressSaveCard = async (card) => {

    try {

      setPaymentOptions({ screen: PaymentScreenTypes.ADD_PAYMENT_CARD_LOADING })

      const tokenBody = {
        'encryptionType': 'RsaOaep',
        'targetOrigin': 'https://www.vodafone.al'
      }

      const data = await VFPayments.generateToken(msisdn, tokenBody, 'POST', { 'vf-operator': 'Flex.GenerateKey' })

      const specifications = data?.parts?.specification?.characteristicsValue

      const specificationsData = {}
      specifications.forEach(item => {
        specificationsData[item.characteristicName] = item.value
      })

      const jwt = {
        'kid': specificationsData.jwkKeyId,
        'n': specificationsData.jwkMod,
        'e': specificationsData.jwkExp,
        'use': 'enc',
        'kty': 'RSA'
      }


      const tokenized = await tokenizeCard(card.cardNo, jwt)
      const cardBrand = getCardType(card.cardNo)
      const cardTypeID = getIdByBrand(cardBrand)

      const body = {
        'cardInfo': {
          'cardNumber': tokenized,
          'expirationMonth': card.expirationData.split('/')[0],
          'expirationYear': '20' + card.expirationData.split('/')[1],
          'cardType': cardTypeID
        },
        'keyId': specificationsData.jwkKeyId
      }

      const instrument = await CyberSource.createTokenizesCard(body)


      const instrumentIdentifier = instrument?._embedded?.icsReply?.instrumentIdentifier
      const instrumentId = instrumentIdentifier.id

      const cardDetail = {
        id: instrumentId,
        instrumentIdentifier: instrumentId,
        token: instrumentId,
        cardName: card.cardName,
        nameOnCard: '',
        lastFourDigits: instrument.maskedPan.split('XXXXXX')[1],
        brand: cardBrand,
        expiry: body.cardInfo.expirationMonth + '/' + body.cardInfo.expirationYear,
        icon: getImageByCardId(cardTypeID)
      }

      let cyberSourceCardObject = {}
      if (card.saveForLater && instrumentId) {
        cyberSourceCardObject = {
          number: card.cardNo,
          expirationMonth: card.expirationData.split('/')[0],
          expirationYear: '20' + card.expirationData.split('/')[1],
          securityCode: card.ccvNo,
          nameOnCard: card.nameOnCard,
          cardName: card.cardName,
          type: cardTypeID
        }

        cardDetail.tokenType = tokenTypes.CARDID
      } else {
        cardDetail.tokenType = tokenTypes.TOKENID
      }


      if (card.saveForLater && instrumentId) {
        const customerPaymentInstrument = await createCustomerPaymentInstrument(cyberSourceCardObject, instrumentId, clientId)
        cardDetail.id = customerPaymentInstrument.id
        cardDetail.token = customerPaymentInstrument.id
        getPaymentInstruments()
      } else {
        setPaymentOptions({ screen: PaymentScreenTypes.DEFAULT })
      }

      setDefaultPayment(cardDetail)
      setCards([...cards, cardDetail])

    } catch (error) {
      setPaymentOptions({
        screen: PaymentScreenTypes.ERROR
      })

      setErrorMessage(defaultError)

      setOnErrorPress(() => () => setPaymentOptions({ screen: PaymentScreenTypes.ADD_PAYMENT_CARD }))
    }

  }




  const retry = () => {
    setPaymentOptions({
      screen: PaymentScreenTypes.ADD_PAYMENT_CARD_LOADING
    })
    onErrorPress()
  }

  const onPressManageCard = () => {
    if (Platform.OS == 'ios') {
      NativeModules.RNReward.makeVisible()
    }

    props.setShowPayment(false)

    props.navigation.navigate('PaymentMethods')
  }

  const title = ContentManager.translate('payment_quick_action_description') + ' ' + paymentObj.validity?.amount + ' ' + paymentObj.validity?.unit
  const subTitle = ContentManager.translate('payment_quick_action_subtitle') + ' ' + paymentObj.name

  if (isOfferActivating) {

    return (
      <Modal
        isVisible={showPayment}
        backdropOpacity={0.5}
        onSwipeComplete={() => toggleModal()}
        onBackdropPress={() => toggleModal()}
        propagateSwipe
        hideModalContentWhileAnimating
        hardwareAccelerated
        backdropTransitionOutTiming={0}
        style={{
          justifyContent: 'flex-end',
          margin: '0 !important',
          minWidth: 1920,
          maxWidth: 1920,
        }}
      >
        <View
          style={{
            height: 'auto',
            minWidth: '600px',
            maxWidth: '600px',
            backgroundColor: theme.colors.quickActionGradientColors.secondary,
            margin: 'auto',
            padding: '20px',
            borderRadius: '7px',
          }}
          onStartShouldSetResponder={() => true}>
          <PaymentWrapper
            isLoading={loading}
            defaultPayment={defaultPayment}
            paymentCards={cards}
            toggleModal={toggleModal}
            closeModal={toggleModal}
            isVisible={showPayment}
            // paymentOptions={paymentOptions}
            onPressManageCard={onPressManageCard}
            // onPressAddData={onPressAddData}
            // onPressRejectData={onPressRejectData}
            singleValue
            value={{ value: paymentObj.price.value, currency: paymentObj.price.unit, paidValue: 0, subTextValue: ContentManager.translate(paymentObj.validity.label) + `: ${paymentObj.validity.amount} ${paymentObj.validity.unit}` }}
            onSelectDefaultCard={handleSelectPaymentCard}
            // wheelValues={wheelValues}
            offerValues={{ giftValue: 'Test 123' }}
            // defaultWheelValue={defaultWheelValue}
            onClose={toggleModal}
            type={PaymentTypes.BILLING_PAYMENT}
            onPressConfirmSelection={onPressConfirmSelection}
            QATitle={paymentObj.title}
            QASubtitle={subTitle}
            billPaymentSuccessPlaceholders={[paymentObj.name]}
            onErrorPress={retry}
            paymentButtonError="full_error_button_text"
            billButtonError="full_error_button_text"
            topUpTitleError="top_up_quick_action_error"
            topUpErrorMessage="top_up_quick_action_error_message"
            paymentTitleError={errorMessage}
            paymentErrorMessage={errorMessage != 'payment_quick_action_error' ? ' ' : 'payment_quick_action_error_message'}
            currency={paymentObj.validity?.unit}
            onPressSaveCard={onPressSaveCard}
            billInfoText={title}
            hiddenFields={['nameOnCard']}
            isBtnSuccessDisabled={!defaultPayment.id}
            theme={{
              name: theme.name,
              isDark: true,
              colors: theme.colors
            }}
          />
        </View>
      </Modal>
    )
  }



  return (
    <PaymentWrapper
      isLoading={loading}
      defaultPayment={defaultPayment}
      paymentCards={cards}
      toggleModal={toggleModal}
      closeModal={toggleModal}
      isVisible={showPayment}
      // paymentOptions={paymentOptions}
      onPressManageCard={onPressManageCard}
      // onPressAddData={onPressAddData}
      // onPressRejectData={onPressRejectData}
      singleValue
      value={{ value: paymentObj.price.value, currency: paymentObj.price.unit, paidValue: 0, subTextValue: ContentManager.translate(paymentObj.validity.label) + `: ${paymentObj.validity.amount} ${paymentObj.validity.unit}` }}
      onSelectDefaultCard={handleSelectPaymentCard}
      // wheelValues={wheelValues}
      offerValues={{ giftValue: 'Test 123' }}
      // defaultWheelValue={defaultWheelValue}
      onClose={toggleModal}
      type={PaymentTypes.BILLING_PAYMENT}
      onPressConfirmSelection={onPressConfirmSelection}
      QATitle={paymentObj.title}
      QASubtitle={subTitle}
      billPaymentSuccessPlaceholders={[paymentObj.name]}
      onErrorPress={retry}
      paymentButtonError="full_error_button_text"
      billButtonError="full_error_button_text"
      topUpTitleError="top_up_quick_action_error"
      topUpErrorMessage="top_up_quick_action_error_message"
      paymentTitleError={errorMessage}
      paymentErrorMessage={errorMessage != 'payment_quick_action_error' ? ' ' : 'payment_quick_action_error_message'}
      currency={paymentObj.validity?.unit}
      onPressSaveCard={onPressSaveCard}
      billInfoText={title}
      hiddenFields={['nameOnCard']}
      isBtnSuccessDisabled={!defaultPayment.id}
    />
  )
}

PaymentsProvider.propTypes = {
  paymentObj: PropTypes.object,
  isVisible: PropTypes.bool,
  navigation: PropTypes.object,
  setShowPayment: PropTypes.func,
}

export default PaymentsProvider
