import React, { PureComponent } from 'react'
import { View, ViewPropTypes } from 'react-native'
import PropTypes from 'prop-types'
import moment from 'moment'
import { VFText } from '@vfgroup-oneplatform/foundation/Components'
import {
    withTheme,
    LightThemeColors
} from '@vfgroup-oneplatform/foundation/Components/Themes'

import { getThemeColors } from '@vfgroup-oneplatform/framework/Themes'

import styles from './MyPlanCard.Styles'

class MyPlanCard extends PureComponent {
    constructor(props) {
        super(props)
    }

    renderRenewalDateText() {
        const {
            renewalDate,
            renewalDateStyle,
            theme = LightThemeColors
        } = this.props

        if (moment(renewalDate).isValid()) {
            return (
                <VFText
                    style={[styles.renewalDateStyle(theme), renewalDateStyle]}
                    i18nKey="my_plan_primary_card_header_renewal_date"
                    placeHolders={[moment(renewalDate).locale(this.props.locale == 'al' ? 'sq' : this.props.locale).format('DD MMM')]}
                />
            )
        }
    }

    render() {
        const {
            status,
            statusStyle,
            statusAccessibilityLabel,
            title,
            titleStyle,
            titleAccessibilityLabel,
            style,
            children,
            theme = LightThemeColors
        } = this.props
        const frameworkColors = getThemeColors(theme.name)
        return (
            <View style={[styles.cardContainerStyle(theme), style]}>
                <View style={styles.headerTitleViewStyle}>
                    <VFText
                        style={[styles.statusStyle(frameworkColors), statusStyle]}
                        i18nKey={status}
                        accessibilityLabel={statusAccessibilityLabel}
                    />
                    {this.renderRenewalDateText()}
                </View>
                <View style={styles.titleViewStyle}>
                    <VFText
                        style={[styles.titleStyle(theme), titleStyle]}
                        i18nKey={title}
                        accessibilityLabel={titleAccessibilityLabel}
                    />
                </View>
                <View style={styles.divider(theme)} />
                {children}
            </View>
        )
    }
}

MyPlanCard.propTypes = {
    status: PropTypes.string,
    statusStyle: ViewPropTypes.style,
    statusAccessibilityLabel: ViewPropTypes.accessibilityLabel,
    title: PropTypes.string,
    titleStyle: ViewPropTypes.style,
    renewalDate: PropTypes.string,
    renewalDateStyle: ViewPropTypes.style,
    locale: PropTypes.string,
    titleAccessibilityLabel: ViewPropTypes.accessibilityLabel,
    style: ViewPropTypes.style,
    children: PropTypes.node,
    theme: PropTypes.shape({
        name: PropTypes.string,
        isDark: PropTypes.bool,
        colors: PropTypes.object
    })
}

export default withTheme(MyPlanCard)
