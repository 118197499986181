import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'
import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import { API_URLS } from '../Config'

const GetCatalogue = async (
  recommendationType,
  relatedPartyId,
  relatedPartyRole,
  customHeaders = {},
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

  const data = {
    data: null,
    params: {
      recommendationType,
      'relatedParty.id': relatedPartyId,
      'relatedParty.role': relatedPartyRole,
    },
    headers: customHeaders
  }

  onStart()

  try {
    const response = await axios.get(API_URLS.getCatalogue, data)

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const ActivateOffer = async (
  channel,
  productOrderItem, // Array of items to add/delete
  id, // MSISDN
  customHeaders = {},
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

  const data = {
    category: 'offers',
    channel,
    productOrderItem,
    relatedParty: [
      {
        id,
        role: 'Customer',
        '@referredType': 'individual',
      },
    ],
  }

  onStart()

  try {
    const response = await axios.post(API_URLS.activateOffer, data, { headers: customHeaders })

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

export { GetCatalogue, ActivateOffer }
