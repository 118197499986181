import React, { forwardRef, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import { View, ScrollView } from 'react-native'
import Swiper from 'react-native-web-swiper';

const PagerView = forwardRef(({
    children,
    initialPage,
    style,
    orientation,
    onPageSelected,
    onPageScroll,
    scrollEnabled,
    swipeEnable,
    controlsEnabled,
    ...props }, ref) => {

    const filteredChildren = children?.filter(element => {
        if (!React.isValidElement(element)) {
            return
        }
        if (initialPage === parseInt(element?.key, 10)) {
            return element
        }
    })

    if (swipeEnable) {
        const elements = React.Children.toArray(children)
        return (
            <Swiper
                from={initialPage}
                controlsEnabled={controlsEnabled}
                onIndexChanged={(index) => onPageSelected(index)}
                vertical={orientation === 'vertical'}
                controlsProps={{
                    PrevComponent: () => <></>,
                    NextComponent: () => <></>,
                    dotProps: {
                        badgeStyle: {
                            backgroundColor: props.dotColor ?? '#999'
                        }
                    },
                    dotActiveStyle: {
                        backgroundColor: props.activeDotColor ?? '#fff'
                    }
                }}
            >
                {elements.map((element, index) =>
                    <View key={index} style={{ width: '100%', height: '100%', scrollSnapAlign: "start" }} >
                        {element}
                    </View>
                )}
            </Swiper>
        )
    }

    return <ScrollView ref={ref} showsVerticalScrollIndicator={false} style={{ flex: 1 }}>
        {filteredChildren}
    </ScrollView>
})
PagerView.propTypes = {
    children: PropTypes.any,
    initialPage: PropTypes.number
}

PagerView.defaultProps = {
    scrollEnabled: true,
    swipeEnable: true,
    initialPage: 0,
    controlsEnabled: false,
    onPageSelected: () => { }
}

export default PagerView
