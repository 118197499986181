import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Dimensions, View } from 'react-native'

import PreOnboardingTutorial from '@vfgroup-oneplatform/framework/PreOnboardingTutorial'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { VFText } from '@vfgroup-oneplatform/foundation/Components'


import ReduxCache from '../../Utils/ReduxCache'

import { setMsisdnData } from '../../Utils/MsisdnLocalData'

import NavigationService from '../../Navigation/NavigationService'


import { isBusinessChooser } from '../../Utils'

import styles from './Tutorial.Styles'



const Tutorial = ({ route }) => {
  const { tutorialKey } = route?.params || { tutorialKey: 'itemsTutorialDashboard' }


  const [activeStep, setActiveStep] = useState(0)

  const userType = ReduxCache.getUserType()

  const configurationsRedux = useSelector(store => store?.configurations)
  const menu = isBusinessChooser() ? configurationsRedux.menu_business_chooser : configurationsRedux?.[`menu_${userType}`]

  const theme = useTheme()

  const tutorialItems = useMemo(() => menu?.find(el => el.key === tutorialKey)?.subItems || [], [menu, tutorialKey])


  tutorialItems.sort((a, b) => parseInt(a.order, 10) > parseInt(b.order, 10) ? 1 : -1)

  const tutorialImages = []
  const tutorialSteps = []

  let nextScreenKey = ''

  tutorialItems?.map(tutorialItem => {

    const image = tutorialItem?.text.split('|')?.[tutorialItem?.text.split('|').length - 1]
    const title = tutorialItem?.text.split('|')?.[0]
    const description = tutorialItem?.text.split('|')?.[1]

    tutorialImages.push({ 'uri': image })

    tutorialSteps.push({ title, description })

    nextScreenKey = tutorialItem.screen

    // Image.prefetch(image)
  })

  const onPrimaryButtonPress = () => {
    if (tutorialSteps?.length - 1 > activeStep) {
      setActiveStep(prev => prev + 1)
    } else {
      NavigationService.navigateWithResetAction('Dashboard', { screen_key: nextScreenKey })
      if (isBusinessChooser()) {
        setMsisdnData('businessTutorialsShown', JSON.stringify(true))
      } else {
        setMsisdnData('fixTutorialsShown', JSON.stringify(true))
      }
      setActiveStep(0)
    }
  }

  const calculateHeight = () => {
    return (`${Dimensions.get('window').height - 240}px`)
  }

  useEffect(() => {
    if (!tutorialItems || tutorialItems?.length === 0) {
      NavigationService.navigateWithResetAction('Dashboard')
      setActiveStep(0)
    }
  }, [tutorialItems])

  if (!tutorialItems || tutorialItems?.length === 0) {
    return null
  }

  return (
    <PreOnboardingTutorial
      initialActiveStep={0}
      activeStepIndex={activeStep}
      primaryButtonText={tutorialSteps.length - 1 === activeStep ? 'tutorial_last_step' : 'tutorial_next_step'}
      withSecondaryButton={false}
      isSwipeDisabled={false}
      steps={tutorialSteps}
      images={tutorialImages}
      imageProps={{ style: { width: '100%', height: calculateHeight() }, resizeMode: 'contain' }}
      onPrimaryButtonPress={onPrimaryButtonPress}
      onCardPositionChange={setActiveStep}
      theme={theme}
      renderStep={() => {
        return (
          <View style={{ width: (Dimensions.get('window').width * 0.8) - 32 }}>
            <VFText
              i18nKey={tutorialSteps[activeStep]?.title}
              style={styles.stepTitleText}
            />
            <VFText
              i18nKey={tutorialSteps[activeStep]?.description}
              style={styles.stepDescriptionText}
            />
          </View>
        )
      }}
    />
  )
}

Tutorial.propTypes = {
  route: PropTypes.object,
}

export default Tutorial
