import { StyleSheet, Dimensions, Platform } from 'react-native'

import colors from '../../Themes/Colors'

const { width } = Dimensions.get('window')

export default StyleSheet.create({
  safeArea: {
    backgroundColor: colors.aluminium,
    flex: 1,
    marginTop: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? null : 22,
    paddingBottom: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 20 : null,
    paddingHorizontal: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? '10%' : null
  },
  container: {
    backgroundColor: colors.white,
    borderRadius: 6,
    margin: 16,
  },
  mainContainer: {
    flex: 1,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 8,
    elevation: 8,
  },
  bottomContainer: {
    height: 100,
    marginTop: 0,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 8,
    elevation: 8,
  },

  infoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 16,
  },
  confirmActivationHeader: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
    padding: 16,
  },
  info: {
    width: 30,
    height: 30,
    tintColor: colors.flower_blue,
  },
  close: {
    width: 30,
    height: 30,
  },
  contentContainer: {
    flex: 6,
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  button: {
    backgroundColor: colors.vfRed,
    margin: 16,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    width: width - 64,
    left: 0
  },
  buttonBottom: {
    backgroundColor: colors.vfRed,

    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  buttonText: {
    color: colors.white,
    fontSize: 22,
    margin: 10,
  },
  primaryButton: {
    backgroundColor: colors.vfRed,
    margin: 16,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    width: width - 64,
  },
  primaryButtonCopy: {
    backgroundColor: colors.vfRed,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20
  },
  primaryButtonText: {
    color: colors.white,
    fontSize: 22,
    margin: 10,
  },
  secondaryButton: {
    backgroundColor: colors.white,
    margin: 16,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: colors.vfRed,
    justifyContent: 'center',
    alignItems: 'center',
    width: width - 62,
  },
  secondaryButtonCopy: {
    backgroundColor: colors.white,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: colors.vfRed,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  secondaryButtonText: {
    color: colors.vfRed,
    fontSize: 22,
    margin: 10,
  },
  success_error_image: {
    height: 130,
  },
  success_image: {
    shadowColor: colors.vfRed,
  },
  success_bg_image: {
    width: '100%',
    justifyContent: 'center',
    alignContent: 'center',
    shadowColor: colors.vfRed,
  },
  error_image: {
    shadowColor: colors.black,
  },

  innerContainer: {
    flex: 1,
    borderRadius: 6,
    overflow: 'hidden',
  },
  imgBg: {
    flex: 1,
  },
  rowCenter: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollView: {
    flexGrow: 1,
    marginHorizontal: 16,
    paddingRight: 8,
    marginBottom: 100,
  },
  infoContentContainer: {
    alignItems: 'flex-start',
  },
  loadingCenter: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 300,
  },
  heartText: {
    marginTop: 32,
    color: colors.white,
  },
})
