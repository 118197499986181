import { StyleSheet } from 'react-native'
import {
  Fonts,
  Colors
} from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  imageStyle: {
    width: undefined,
    height: undefined
  },
  scrollViewContentContainer: {
    marginHorizontal: 16.6,
    // paddingTop: 16,
    paddingBottom: 120
  },
  screenTitle: { fontSize: 24, fontWeight: '700' },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'center',
    gap: 24,
    paddingBottom: 60
  },
  imageBorderRadius: { borderRadius: 6, height: 220 },
  textContainer: {
    padding: 10,
    justifyContent: 'space-between',
    height: 220,
    display: 'flex',
  },
  card: { width: undefined, height: 220, paddingTop: 20 },
  title: {
    fontFamily: Fonts.type.VodafoneLt,
    color: Colors.white,
    fontSize: 25,
    lineHeight: 22,
    marginBottom: 9
  },
  titlePromo: {
    fontFamily: Fonts.type.VodafoneLt,
    color: Colors.white,
    fontSize: 25,
    lineHeight: 22,
    marginBottom: 9
  },
  subTitle: {
    color: Colors.white,
    fontSize: 15,
    lineHeight: 15,
    letterSpacing: 0
  },
  subTitlePromo: {
    color: Colors.white,
    fontSize: 15,
    lineHeight: 15,
    letterSpacing: 0
  },
  text: {
    fontFamily: Fonts.type.VFFont,
    color: Colors.snow,
    fontSize: 15,
    lineHeight: 15,
    marginTop: 1
  },
  textPromo: {
    fontFamily: Fonts.type.VFFont,
    color: Colors.white,
    fontSize: 15,
    lineHeight: 15,
    marginTop: 1
  },
  btn: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-end'
  }
})
