import { StyleSheet } from 'react-native'
import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  container: theme => ({
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    overflow: 'hidden',
    backgroundColor: theme.colors.tintColor,
    flex: 1
  }),
  imageStyle: {
    width: '100%',
    flex: 1
  },
  textContainer: {
    flex: 1,
    paddingHorizontal: 15,
    paddingVertical: 15
  },
  header: theme => ({
    color: theme.colors.cardHeaderTitleColor,
    fontFamily: Fonts.type.VFFont,
    fontSize: 16.6,
    lineHeight: 22.9
  }),
  text: theme => ({
    color: theme.colors.headerColor,
    fontFamily: Fonts.type.bold.fontFamily,
    fontWeight: Fonts.type.bold.fontWeight,
    fontSize: 25,
    lineHeight: 29.1
  }),
  scrollStyle: {
    height: '70%',
    backgroundColor: 'red'
  }
})
