import { ActionTypes } from './TermsAndConditions.ActionTypes'
import { ActionTypes as actions } from './OnBoarding.ActionTypes'

const INITIAL_STATE = {
  accepted: false
}
function termsAndConditions(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ActionTypes.ACCEPT_TERMS_AND_CONDITIONS:
      return accept(state)
    case actions.CLEAR_ONBOARDING_CACHE:
      return INITIAL_STATE
    default:
      return state
  }
}
function accept(state) {
  return { ...state, accepted: true }
}
export default termsAndConditions
