import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'

import moment from 'moment'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import ReduxCache from '../../Utils/ReduxCache'

import { API_URLS } from '../Config'

import { isAdminView } from '../../Utils'

const mapError = (error) => {
  const payload = error?.response?.data?.message || error?.message
  return payload
}


const getFormattedDate = str => {
  if (!str) {
    return
  }
  const date = new Date(str)
  if (!date instanceof Date || isNaN(date.getTime())) {
    return
  }
  return moment(date).format('DD/MM/YYYY')
}

const addStartDate = (date, arr) => {
  const entry = { name: 'first_day', value: date, '@type': 'show' }
  let inserted = false
  const result = arr.map(el => {
    if (el.name === 'last_day') {
      inserted = true
      return [entry, el]
    } else {
      return el
    }
  })
  if (inserted === false) {
    result.push(entry)
  }
  return result.flat()
}

const contractResponseMapper = (response) => {
  const data = response?.data || []

  if (data.length === 0) {
    return data
  }

  const contractsArray = []

  data.map(item => {
    const { agreementType, name, agreementItem, characteristic, agreementPeriod } = item

    let icon = 'mobile'

    let shownCharacteristic = characteristic || []

    let id = item?.id || ''

    const title = agreementType === 'mobile' ? item?.description : name


    if (agreementType === 'fixed') {
      shownCharacteristic =
        characteristic?.filter(
          el => el?.['@type'] === 'show' && el?.name !== 'address'
        ) || []
      icon = characteristic.find(el => el?.['@type'] === 'nonshow')?.value || ''
      id = characteristic.find(el => el?.name === 'address')?.value || ''
      // Adding start of contract date
      if (Array.isArray(agreementItem) && agreementItem.length) {
        const product = agreementItem[0].product
        if (Array.isArray(product) && product.length) {
          const date = getFormattedDate(product[0].startDate)
          const characteristics = addStartDate(date, shownCharacteristic)
          shownCharacteristic = characteristics
        }
      }
    } else if (agreementType === 'mobile') {
      const date = getFormattedDate(agreementPeriod.startDateTime)
      const characteristics = addStartDate(date, shownCharacteristic)
      shownCharacteristic = characteristics
    }

    contractsArray.push({ id, agreementType, name: title, shownCharacteristic, icon })
  })

  const order = ['mobile', 'fixed']

  contractsArray.sort((a, b) => {
    if (order.indexOf(a.agreementType) < order.indexOf(b.agreementType)) {
      return -1
    }
    return 1
  })

  return contractsArray
}

async function getContracts(
  contractType,
  utilities = {},
) {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
  })

  const {
    errorMapper,
    onError,
    onStart,
    onSuccess,
  } = _utilities

  onStart()

  let params = {
    'engagedParty.id': ReduxCache.getMsisdn(),
    'engagedParty.role': isAdminView() ? 'organization' : 'customer'
  }

  if (contractType === 'Fixed') {
    params = {
      'engagedParty.id': ReduxCache.getMsisdn(),
      'engagedParty.role': isAdminView() ? 'organization' : 'customer',
      'agreementType': 'Fixed'
    }
  }


  try {
    const response = await axios.get(API_URLS.fix.getContract, {
      params,
    })
    const _response = contractResponseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const addContractMapper = (response) => {
  const { data } = response

  return data
}

const headers = {
  'Content-Type': 'application/json-patch+json',
  'Accept': 'application/json'
}

async function addManualAgreement(
  pin,
  id,
  type,
  phoneNumber,
  utilities = {},
) {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
  })

  const {
    errorMapper,
    onError,
    onStart,
    onSuccess,
  } = _utilities

  onStart()

  const data = {
    'characteristic': [
      {
        'name': 'pin',
        'value': pin,
        '@type': 'securitytoken'
      },
      {
        'name': 'id',
        'value': id,
        '@type': type
      }
    ],
    'engagedParty':
    {
      'id': ReduxCache.getMsisdn(),
      '@type': 'Customer',
      '@referredType': 'Customer'
    },
    'relatedParty': [
      {
        'id': phoneNumber,
        '@type': 'Customer',
        '@referredType': 'Customer'
      }
    ]
  }

  try {
    const response = await axios.patch(`${API_URLS.fix.addManualContract}/${ReduxCache.getMsisdn()}`, data, {
      headers,
    })
    const _response = addContractMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    console.log(error?.response?.data)
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

export { getContracts, addManualAgreement }
