import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'

import { VFInput } from '@vfgroup-oneplatform/foundation/Components'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { getThemeImages } from '../../Themes'

import styles from './SearchInput.Styles'


const SearchInput = ({
  inputRef,
  value,
  onChangeText,
  label,
  onFocus,
  onRightButtonPress,
  rightButtonIcon,
  rightButtonDisabled,
  formatMsisdn,
  ...props
}) => {

  const theme = useTheme()

  const themedImages = getThemeImages(theme.name)

  const _onChangeText = (text) => {
    let formattedText = text

    if (parseInt(formattedText, 10) && formatMsisdn) {
      if (formattedText.startsWith('06')) {
        formattedText = formattedText?.replace('06', '3556')
      } else if (formattedText.startsWith('6')) {
        formattedText = formattedText?.replace('6', '3556')
      } else if (formattedText.startsWith('003556')) {
        formattedText = formattedText?.replace('003556', '3556')
      } else if (formattedText.startsWith('+3556')) {
        formattedText = formattedText?.replace('+3556', '3556')
      } else if (formattedText.startsWith('+355')) {
        formattedText = formattedText?.replace('+355', '355')
      }
    }
    onChangeText(formattedText)
  }

  const rightIconPress = () => {
    onRightButtonPress && onRightButtonPress()
  }

  const formatSearchNumber = phoneNumber => {
    const alRegex = /^(3556|003556|\+3556|06|6)/
    if (!phoneNumber) {
      return
    }
    return phoneNumber.replace(/\s/g, '').replace(alRegex, '6')
  }

  return (
    <View
      style={styles.searchContentContainer(theme)}
    >
      <VFInput
        fieldRef={inputRef}
        value={value}
        onChangeText={_onChangeText}
        label={label}
        baseColor={theme.colors.textInput.default.baseColor}
        containerStyle={styles.inputContainerStyle}
        textInputContainerStyle={styles.textInputContainerStyle(theme)}
        tintColor={theme.colors.textInput.default.baseColor}
        textColor={theme.colors.textColorTwo}
        onFocus={onFocus}
        rightIconName={themedImages.icCallsContacts}
        rightIconSize={30}
        showRightIcon
        rightIconPress={rightIconPress}
        rightIconStyle={styles.rightIconStyle}
        {...props}
      />
      {/* <View style={styles.searchButtonContainer(rightButtonDisabled, theme)} activeOpacity={1}>
        <TouchableOpacity onPress={onRightButtonPress} disabled={rightButtonDisabled}  >
          <Icon
            name={Images[rightButtonIcon]}
            size={40}
            resizeMode="contain"
          />
        </TouchableOpacity>
      </View> */}
    </View>
  )
}
SearchInput.propTypes = {
  inputRef: PropTypes.object,
  value: PropTypes.string,
  onChangeText: PropTypes.func,
  label: PropTypes.string,
  onFocus: PropTypes.func,
  onRightButtonPress: PropTypes.func,
  rightButtonIcon: PropTypes.string,
  rightButtonDisabled: PropTypes.bool,
  formatMsisdn: PropTypes.bool
}

export default SearchInput
