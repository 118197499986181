// import axios from 'axios'
import axios from 'axios'
import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import { isFunction } from 'lodash'

import ReduxCache from '../../Utils/ReduxCache'

import { API_URLS } from '../Config'

import {
  errorMapper,
  customSlotsMapper,
  customTVandFixMapper,
  bundleResponseMapper,
  customBundlePriceMapper,
  customMobilePlansMapper
} from './Mappers'

const removeEmptyObjectKeys = obj =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))

const getATLBundles = async utilities => {
  const _utilities = retrieveUtilities({
    ...utilities,
    errorMapper,
    responseMapper: bundleResponseMapper
  })
  _utilities.onStart()
  try {
    const response = await axios.get(API_URLS.pega, {
      headers: {
        'vf-project': 'unlimited'
      },
      params: {
        recommendationType: 'bundleInternal',
        'relatedParty.id': ReduxCache.getMsisdn()
      }
    })
    const _response = _utilities.responseMapper(response?.data ?? [])
    _utilities.onSuccess(_response)
    return _response
  } catch (error) {
    const _error = _utilities.errorMapper(error)
    if (isFunction(_utilities.onError)) {
      _utilities.onError(_error)
    } else {
      throw _error
    }
  }
}

const getPegaBundles = async utilities => {
  const _utilities = retrieveUtilities({
    ...utilities,
    errorMapper,
    responseMapper: bundleResponseMapper
  })
  _utilities.onStart()
  try {
    const response = await axios.get(API_URLS.pega, {
      headers: {
        'vf-project': 'unlimited'
      },
      params: {
        recommendationType: 'converged',
        'relatedParty.id': ReduxCache.getMsisdn()
      }
    })
    const _response = _utilities.responseMapper(response?.data ?? [])
    _utilities.onSuccess(_response)
    return _response
  } catch (error) {
    const _error = _utilities.errorMapper(error)
    if (isFunction(_utilities.onError)) {
      _utilities.onError(_error)
    } else {
      throw _error
    }
  }
}

const getTariffPlans = async utilities => {
  const _utilities = retrieveUtilities({
    ...utilities,
    errorMapper,
    responseMapper: customMobilePlansMapper
  })
  _utilities.onStart()
  try {
    const response = await axios.get(API_URLS.pega, {
      headers: {
        'vf-project': 'unlimited'
      },
      params: {
        recommendationType: 'tariffPlan'
      }
    })
    const _response = _utilities.responseMapper(response?.data ?? [])
    _utilities.onSuccess(_response)
    return _response
  } catch (error) {
    const _error = _utilities.errorMapper(error)
    if (isFunction(_utilities.onError)) {
      _utilities.onError(_error)
    } else {
      throw _error
    }
  }
}

const getMemberSlots = async utilities => {
  const _utilities = retrieveUtilities({
    ...utilities,
    errorMapper,
    responseMapper: customSlotsMapper
  })
  _utilities.onStart()
  try {
    const response = await axios.get(API_URLS.unlimited.productCatalog, {
      headers: {
        'vf-project': 'unlimited'
      },
      params: {
        catalogType: 'memberChoice'
      }
    })
    const _response = _utilities.responseMapper(response?.data ?? [])
    _utilities.onSuccess(_response)
    return _response
  } catch (error) {
    const _error = _utilities.errorMapper(error)
    if (isFunction(_utilities.onError)) {
      _utilities.onError(_error)
    } else {
      throw _error
    }
  }
}

const getFixAndTVs = async utilities => {
  const _utilities = retrieveUtilities({
    ...utilities,
    errorMapper,
    responseMapper: customTVandFixMapper
  })
  _utilities.onStart()
  try {
    const response = await axios.get(API_URLS.unlimited.productOffering, {
      headers: {
        'vf-project': 'unlimited'
      },
      params: {
        catalogType: 'Converge',
        'relatedParty.id': ReduxCache.getMsisdn()
      }
    })
    const _response = _utilities.responseMapper(response?.data ?? [])
    _utilities.onSuccess(_response)
    return _response
  } catch (error) {
    const _error = _utilities.errorMapper(error)
    if (isFunction(_utilities.onError)) {
      _utilities.onError(_error)
    } else {
      throw _error
    }
  }
}

const getCustomBundlePrice = async ({ body, ...utilities }) => {
  const _utilities = retrieveUtilities({
    ...utilities,
    errorMapper,
    responseMapper: customBundlePriceMapper
  })
  _utilities.onStart()
  const data = removeEmptyObjectKeys(body)
  const reqBody = {
    category: 'calculatePrice',
    description: 'calculate the price of the shopping cart',
    productOrderItem: Object.keys(data).map((key, index) => {
      const obj = {
        action: 'add',
        id: index + 1,
        product: { name: data[key] }
      }
      if (key === 'slots') {
        obj.quantity = parseInt((data[key] ?? ' ')?.split(' ')[0], 10)
      }
      return obj
    })
  }
  try {
    const response = await axios.post(API_URLS.offerPayment, reqBody, {
      headers: {
        'vf-project': 'unlimited'
      }
    })
    const _response = _utilities.responseMapper(response?.data ?? [])
    _utilities.onSuccess(_response)
    return _response
  } catch (error) {
    const _error = _utilities.errorMapper(error)
    if (isFunction(_utilities.onError)) {
      _utilities.onError(_error)
    } else {
      throw _error
    }
  }
}

export {
  getFixAndTVs,
  getATLBundles,
  getPegaBundles,
  getTariffPlans,
  getMemberSlots,
  getCustomBundlePrice
}
