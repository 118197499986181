import { Dimensions, Platform, StyleSheet } from 'react-native'

import colors from '../../Themes/Colors'

export default StyleSheet.create({
  safearea: {
    backgroundColor: colors.aluminium,
    flex: 1,
  },
  header: {
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 8,
    elevation: 8,
    paddingHorizontal: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? '10%' : null
  },
  headerPartContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },
  touchableGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  smallIcon: {
    width: 16,
    height: 16,
    margin: 4,
    resizeMode: 'contain',
  },
  bigIcon: {
    width: 36,
    height: 36,
    margin: 4,
    resizeMode: 'contain',
  },
  keboardAwareContainer: {
    flex: 1,
  },
  cardContainer: {
    flexGrow: 1,
  },
  cardContentContainer: {

    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    flexGrow: 1,
    alignSelf: 'stretch',
    marginVertical: 22,
    marginHorizontal: 16,
    paddingVertical: 4,
    borderRadius: 6,
    backgroundColor: colors.white,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 8,
    elevation: 8,
  },
  sectionText: {
    marginHorizontal: 16,
  },
  numberTitle: {
    marginTop: 8,
    marginBottom: 4,
  },
  inputContainer: {
    marginTop: 8,
    marginBottom: 4,
    marginHorizontal: 16,
    paddingLeft: 8,
    borderWidth: 1,
    borderColor: colors.mid_grey,
    borderRadius: 6,
    borderStyle: 'solid',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    paddingHorizontal: 8,
    paddingVertical: 10,
    fontSize: 18,
    fontFamily: 'Vodafone Rg',
    color: colors.dark_grey,
    flexGrow: 1,
  },
  inputIcon: {
    height: 50,
    width: 18,
    resizeMode: 'contain',
  },
  dropdownIcon: {
    paddingLeft: 8,
    paddingRight: 16,
  },
  dropdownContainer: {
    position: 'relative',
    left: 0,
    right: 0,
    marginHorizontal: 32,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: colors.mid_grey,
    overflow: 'hidden',
    flexShrink: 1,
  },
  dropdownTextStyle: {
    borderRadius: 6,
    fontFamily: 'Vodafone Rg',
    fontSize: 18,
    paddingHorizontal: 16,
  },
  getContactContainer: {
    marginVertical: 8,
    marginHorizontal: 16,
    paddingLeft: 8,
    borderRadius: 6,
    backgroundColor: colors.anthracite,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addContactText: {
    color: colors.white,
    fontSize: 18,
    paddingVertical: 4,
  },
  seperator: {
    marginVertical: 8,
    marginHorizontal: 16,
  },
  seperatorView: {
    flexGrow: 1,
    justifyContent: 'center',
    // alignItems: 'center',
  },
  amountTitle: {
    marginBottom: 8,
    marginTop: 4,
  },

  //VfAmountOption Styles
  listContentContainer: {
    paddingHorizontal: 8,
    flexGrow: 1,
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  amountOptContainer: {
    width: 75,
    height: 75,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 40,
    margin: 8,
    backgroundColor: colors.silver,
  },
  isDisabled_true: {
    opacity: 0.5,
  },
  isDisabled_false: {
    opacity: 1,
  },
  isSelected_true: {
    backgroundColor: colors.dark_grey,
  },
  isSelected_false: {
    backgroundColor: colors.silver,
  },
  isSelectedText_true: {
    color: colors.white,
  },
  amountText: {
    fontSize: 20,
    marginBottom: 0,
  },
  currencyText: {
    fontSize: 11,
    marginTop: 0,
  },

  detailsContainer: {
    marginVertical: 4,
  },
  detailsTextContainer: {
    paddingHorizontal: 16,
    paddingVertical: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  detailsTexFee: {
    marginHorizontal: 0,
    marginVertical: 4,
  },
  detailsText: {
    margin: 0,
  },
  button: {
    backgroundColor: colors.vfRed,
    margin: 16,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    color: colors.white,
    fontSize: 22,
    margin: 10,
  },

  //PopUp
  popUpContentContainer: {
    alignSelf: 'stretch',
  },
  popUpDetailsContainer: {
    marginVertical: 16,
  },
  detailsTextPopUpContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  detailsTextPopUp: {
    margin: 0,
  },
  popUpButton: {
    marginVertical: 8,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  popUpButton_confirm: {
    backgroundColor: colors.vfRed,
  },
  popUpButton_cancel: {
    backgroundColor: colors.dark_grey,
  },
  sucessInfoContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  sucessInfo: {
    textAlign: 'center',
    alignSelf: 'stretch',
    marginVertical: 16,
  },
  sharing: {
    color: colors.vfRed,
    fontSize: 18,
  },
})
