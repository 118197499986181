import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import ReduxCache from '../../Utils/ReduxCache'
import { API_URLS } from '../Config'

const mapError = (error) => {
    const payload = error?.response?.data?.message || error?.message
    return payload
}

async function terminateAccount(
    reason,
    customHeaders = {},
    utilities = {},
) {
    const _utilities = retrieveUtilities(utilities, {
        errorMapper: mapError,
    })

    const {
        errorMapper,
        onError,
        onStart,
        onSuccess,
    } = _utilities

    onStart()

    const data = {
        description: 'Terminate account',
        name: 'Terminate account',
        priority: '1',
        severity: 'Minor',
        ticketType: 'deleteaccount',
        channel: {
            id: 'selfcare'
        },
        note: [
            {
                text: `${reason}`
            }
        ],
        relatedParty: [
            {
                id: ReduxCache.getMsisdn(),
                role: 'subscriber',
                '@referredType': ''
            }
        ],
    }

    try {
        const response = await axios.post(API_URLS.terminateAccount, data, {
            headers: customHeaders,
        })
        const _response = response?.data
        onSuccess(_response)
        return _response
    } catch (error) {
        const _error = errorMapper(error)
        if (_isFunction(onError)) {
            onError(_error)
        } else {
            throw _error
        }
    }
}

export { terminateAccount }
