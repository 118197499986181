import { actionTypes } from './Migration.ActionTypes'

function pinGeneration(
  state = { loading: false, data: null, error: null },
  action =  {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_GENERATE_PIN:
      return {
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_GENERATE_PIN:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }

    case actionTypes.ERROR_GENERATE_PIN:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}

function pinValidation(
  state = { loading: false, data: null, error: null },
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_VALIDATE_PIN:
      return {
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_VALIDATE_PIN:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }

    case actionTypes.ERROR_VALIDATE_PIN:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}

function offerDetails(
  state = { loading: false, data: null, error: null },
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_OFFER_DETAILS:
      return {
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_OFFER_DETAILS:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }

    case actionTypes.ERROR_OFFER_DETAILS:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}

function preferences(
  state = { loading: false, data: null, error: null },
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_PREFERENCES:
      return {
        loading: true,
        data: null,
        error: null,
      }
    case actionTypes.UPDATE_PREFERENCE:
    case actionTypes.UPDATE_ALL_PREFERENCES:
    case actionTypes.SUCCESS_PREFERENCES:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }

    case actionTypes.ERROR_PREFERENCES:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}

function preferencePayload(
  state = { payload: null },
  action = {},
) {
  if (action.type == actionTypes.SUCCESS_PREFERENCES_PAYLOAD){
    return {
      loading: false,
      data: action.payload,
      error: null,
    }
  }

  return state
}

function preferenceUpdate(
  state = { loading: false, data: null, error: null },
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_UPDATE_PREFERENCES:
      return {
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_UPDATE_PREFERENCES:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }

    case actionTypes.ERROR_UPDATE_PREFERENCES:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}

function accountSearch(
  state = { loading: false, data: null, error: null },
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_SEARCH_ACCOUNT:
      return {
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_SEARCH_ACCOUNT:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }

    case actionTypes.ERROR_SEARCH_ACCOUNT:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}

function notifyState(
  state = { loading: false, data: null, error: null },
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_NOTIFY:
      return {
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_NOTIFY:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }

    case actionTypes.ERROR_NOTIFY:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}

function orderCreation(
  state = { loading: false, data: null, error: null },
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_CREATE_ORDER:
      return {
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_CREATE_ORDER:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }

    case actionTypes.ERROR_CREATE_ORDER:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}

export {
  pinGeneration,
  pinValidation,
  offerDetails,
  preferences,
  preferencePayload,
  preferenceUpdate,
  accountSearch,
  notifyState,
  orderCreation,
}
