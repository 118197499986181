import React from 'react'
import PropTypes from 'prop-types'
import {
  ScrollView,
  ViewPropTypes,
  View,
} from 'react-native'

import { VFText, ErrorScreen, VFButton } from '@vfgroup-oneplatform/foundation/Components'
import {
  withTheme,
  LightThemeColors,
} from '@vfgroup-oneplatform/foundation/Components/Themes'

import MyPlanCardLoading from '@vfgroup-oneplatform/framework/MyPlan/Components/MyPlanCardLoading'
import styles from '@vfgroup-oneplatform/framework/MyPlan/Screens/MyPlan/MyPlan.Styles'

import FixPlanCard from './FixPlanCard'

const MyFixPlan = ({
  withTray,
  title,
  subtitle,
  planData,
  containerStyle,
  images,
  theme,
  isMyPlanLoading,
  onPressUpgradePlan,
  isError,
  onTryAgainPress,
  errorText,
  errorImages,
  errorIconName,
  tryAgainIconName,
  productsWithOffer,
  ...props
}) => {

  const renderUpgradePlanCTA = planItem => {
    if (!productsWithOffer.includes(planItem?.id)) {
      return null
    }
    return (
      <View style={styles.buttonContainer}>
        <VFButton
          title="my_plan_primary_card_upgrade_plan"
          type="primary"
          onPress={onPressUpgradePlan}
        />
      </View>
    )
  }

  return (
    <ScrollView
      scrollEnabled={!isMyPlanLoading}
      style={[styles.container, containerStyle]}
      contentContainerStyle={styles.scrollViewContainerStyle(withTray)}
    >
      <VFText i18nKey={title} style={styles.headerTitle(theme)} />
      {!isError ? (
        <VFText i18nKey={subtitle} style={styles.headerSubTitle(theme)} />
      ) : null}
      {isMyPlanLoading ? (
        <MyPlanCardLoading />
      ) : isError ? (
        <ErrorScreen
          iconStyle={styles.errorIconStyle}
          text={errorText}
          images={errorImages}
          iconName={errorIconName}
          iconButtonName={tryAgainIconName}
          onPress={onTryAgainPress}
          textButton="my_plan_screen_try_again"
        />
      ) : (
        <>
          {planData.map(item => {
            return (
              <FixPlanCard
                item={item}
                key={item.id}
                upgradePlanBtn={() => renderUpgradePlanCTA(item)}
              />
            )
          })}
        </>
      )}
    </ScrollView>
  )
}

MyFixPlan.defaultProps = {
  theme: LightThemeColors,
}

MyFixPlan.propTypes = {
  withTray: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  containerStyle: ViewPropTypes.style,
  images: PropTypes.object,
  planData: PropTypes.arrayOf({
    id: PropTypes.string,
    status: PropTypes.string,
    title: PropTypes.string,
    address: PropTypes.string,
    price: PropTypes.string,
    priceUnit: PropTypes.string,
    characteristics: PropTypes.array,
    startDate: PropTypes.string,
    terminationDate: PropTypes.string,
  }),
  theme: PropTypes.objectOf({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  }),
  isMyPlanLoading: PropTypes.bool,
  onPressUpgradePlan: PropTypes.func,
  isError: PropTypes.bool,
  onTryAgainPress: PropTypes.func,
  errorText: PropTypes.string,
  errorImages: PropTypes.array,
  errorIconName: PropTypes.string,
  tryAgainIconName: PropTypes.string,
  productsWithOffer: PropTypes.array,
}

export default withTheme(MyFixPlan)
