import React from 'react'
import { VFText, Icon } from '@vfgroup-oneplatform/foundation/Components'
import { withTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { View } from 'react-native'
import PropTypes from 'prop-types'

import styles from '../FourthComponent.Styles'

const Messages = ({ theme, image, messagesPlaceHolders, subTitle }) => {
  return (
    <View style={styles.container}>
      <View style={styles.messagesContainer}>
        <View style={styles.iconAndTitleContainer}>
          <Icon source={image} style={styles.icon} size={26} />
          <VFText i18nKey={'My messages'} style={styles.title} />
        </View>
        <View style={styles.subtitleConatier}>
          <VFText
            i18nKey={subTitle}
            placeHolders={messagesPlaceHolders}
            style={styles.messagesSubtitle(theme)}
          />
        </View>
      </View>
    </View>
  )
}

Messages.propTypes = {
  theme: PropTypes.objectOf({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  }),
  image: PropTypes.object,
  messagesPlaceHolders: PropTypes.arrayOf(PropTypes.string),
  subTitle: PropTypes.string
}

export default withTheme(Messages)
