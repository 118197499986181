import { StyleSheet } from 'react-native'
import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    marginBottom: 25,
  },
  title: {
    marginTop: 30,
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Fonts.size.h3
  },
  subTitle: {
    marginTop: 5,
    fontSize: Fonts.size.h4,
    fontFamily: Fonts.type.VodafoneLt,
  },
  btn: {
    width: '45%',
    marginTop: 20,
    marginBottom: 50
  },
  listHeader: {
    ...Fonts.type.bold,
    fontSize: Fonts.size.h4,
    marginBottom: 10,
  },
  //card
  cardTitle: {
    fontSize: Fonts.size.h5,
    fontFamily: Fonts.type.VFFont
  },
  cardContainer: {
    height: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 20,
    paddingHorizontal: 16,
    marginVertical: 10,
  },
  text: {
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Fonts.size.regular,
    lineHeight: 20
  },
  iconStyle: {
    marginVertical: 20
  }
})

export default styles
