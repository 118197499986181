import axios from 'axios'

import AsyncStorage from '@react-native-async-storage/async-storage'

import CONFIG from '../../Config'
import {getErrorMessage} from '../../Utils'
import ReduxCache from '../../Utils/ReduxCache'

import {action_types} from './splash.actionTypes'

const getSplash = (locale, component) => {
  return async dispatch => {
    dispatch({
      type: action_types.REQUEST_SPLASH,
    })

    const msisdn = ReduxCache.getMsisdn()
    const url = await CONFIG.getUrl()
    const headers = await CONFIG.getHeaders()

    let system = 'reward'

    let splashUrl = url.splashEndpoint

    if (component === 'Refill') {
      system = 'refill'
    } else if (component === 'LoveBox') {
      system = 'lovebox'
      splashUrl = splashUrl.replace('4.3.0', '5.0.0')
    } else {
      splashUrl = splashUrl.replace('4.3.0', '4.0.0')
    }

    axios
      .get(splashUrl + '&msisdn=' + msisdn + '&hash=12312321755', {
        headers: {
          ...headers,
          'Accept-Language': locale,
          'X-Source-System': system,
        },
      })
      .then(response => {
        const data = response.data

        const translations = {}
        const images = {}
        for (const i in data.pages) {
          const page = data.pages[i]

          for (const key in page.items) {
            const item = page.items[key]
            translations[item.key] = item.value

            if (item.name) {
              images[item.key] = item.name
            }
          }
        }

        for (const i in data.menu) {
          const page = data.menu[i]

          translations[page.key] = page.value
          if (page.name) {
            images[page.key] = page.name
          }
        }

        dispatch({
          type: action_types.SUCCESS_SPLASH,
          payload: {
            language: locale,
            component: component,
            translations: {
              [component]: translations,
            },
          },
        })

        dispatch({
          type: action_types.SUCCESS_SPLASH_IMAGES,
          payload: {
            component: component,
            language: locale,
            images: {
              [component]: images,
            },
          },
        })

        const localSplash = {
          language: locale,
          [locale]: {
            translations,
          },
        }

        const localImages = {
          language: locale,
          [locale]: {
            images,
          },
        }

        AsyncStorage.setItem(
          'reward_splash_content',
          JSON.stringify(localSplash),
        )
        AsyncStorage.setItem(
          'reward_splash_images',
          JSON.stringify(localImages),
        )
      })
      .catch(error => {
        const message = getErrorMessage(error)
        dispatch({
          type: action_types.ERROR_SPLASH,
          payload: message,
        })
      })
  }
}

export const actions = {
  getSplash,
}
