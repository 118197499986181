import moment from 'moment'

import {
    getLevelOptions,
    getRewards,
    putQuestion,
} from '@vfal-lab/rn-vfg-quiz/Services'

import ReduxCache from '../../Utils/ReduxCache'

const actionTypes = {
    GET_LEVEL_OPTIONS: 'GET_LEVEL_OPTIONS',
    SUCCESS_LEVEL_OPTIONS: 'SUCCESS_LEVEL_OPTIONS',
    ERROR_LEVEL_OPTIONS: 'ERROR_LEVEL_OPTIONS',

    PUT_QUIZ_OPTION: 'PUT_QUIZ_OPTION',
    SUCCESS_QUIZ_OPTION: 'SUCCESS_QUIZ_OPTION',
    ERROR_QUIZ_OPTION: 'ERROR_QUIZ_OPTION',

    GET_QUIZ_REWARDS: 'GET_QUIZ_REWARDS',
    SUCCESS_QUIZ_REWARDS: 'SUCCESS_QUIZ_REWARDS',
    ERROR_QUIZ_REWARDS: 'ERROR_QUIZ_REWARDS',
}

const errorMapper = (error) => {
    const payload = error?.response?.data?.message || error?.message
    return payload
}

const quizOptionsMapper = (res) => {

    const options = res?.data?.relatedProductOffering.map((item => ({
        label: item?.desc,
        id: item?.id[0]?.value,
    })))

    const startDate = res?.data?.validityPeriod?.fromDate?.date
    const endDate = res?.data?.validityPeriod?.toDate?.date
    const dateDiff = moment(endDate).diff(moment(startDate), 'seconds')
    return {
        questionNumber: res?.data?.category[0].listHierarchyId,
        quizQuestion: res?.data?.desc,
        options: options,
        responseObject: res?.data,
        dateDiff: dateDiff,
    }
}

const customHeaders = {
    'X-Source-Operator': 'quiz',
    'X-Source-System': 'selfcare',
    'Content-Type': 'application/json;charset=UTF-8',
}

const getQuizOptions = (sessionId, questionNr, utilities = {}) => {
    return async dispatch => {

        dispatch({
            type: actionTypes.GET_LEVEL_OPTIONS,
        })

        const msisdn = ReduxCache.getMsisdn()

        getLevelOptions(sessionId, msisdn, questionNr, customHeaders, {
            ...utilities,
            responseMapper: quizOptionsMapper,
            errorMapper: errorMapper,
        })
            .then(response => dispatch({
                type: actionTypes.SUCCESS_LEVEL_OPTIONS,
                payload: response,
            }))
            .catch(error => dispatch({
                type: actionTypes.ERROR_LEVEL_OPTIONS,
                payload: error,
            }))
    }
}
const putQuizMapper = (res) => {
    const mappedRes = {
        state: res?.data?.status,
        // description: res?.data?.desc,
        title: res?.data?.desc,
        correctAnswers: res?.data?.parts?.specification?.characteristicsValue[0]?.value,
    }
    return mappedRes
}

const putQuizOption = (sessionId, data, utilities = {}) => {
    return async dispatch => {

        dispatch({
            type: actionTypes.PUT_QUIZ_OPTION,
        })

        putQuestion(sessionId, data, customHeaders, {
            ...utilities,
            responseMapper: putQuizMapper,
            errorMapper: errorMapper,
        })
            .then(response => dispatch({
                type: actionTypes.SUCCESS_QUIZ_OPTION,
                payload: response,
            }))
            .catch(error => dispatch({
                type: actionTypes.ERROR_QUIZ_OPTION,
                payload: error,
            }))
    }
}

const getRewardsMapper = (res) => {
    const mappedRes = {
        description: res?.data?.desc,
        title: res?.data?.name,
        state: res?.data?.status,
    }
    return mappedRes
}

const getQuizRewards = (sessionId, utilities = {}) => {
    return async dispatch => {

        dispatch({
            type: actionTypes.GET_QUIZ_REWARDS,
        })

        const msisdn = ReduxCache.getMsisdn()

        getRewards(sessionId, msisdn, customHeaders, {
            ...utilities,
            responseMapper: getRewardsMapper,
            errorMapper: errorMapper,
        })
            .then(response => dispatch({
                type: actionTypes.SUCCESS_QUIZ_REWARDS,
                payload: response,
            }))
            .catch(error => dispatch({
                type: actionTypes.ERROR_QUIZ_REWARDS,
                payload: error,
            }))
    }
}

export {
    actionTypes,
    getQuizOptions,
    putQuizOption,
    getQuizRewards,
}
