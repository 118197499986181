import React, { useEffect, useState } from 'react'
import { Dimensions, Platform, ScrollView, View } from 'react-native'
import PropTypes from 'prop-types'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { VFButton, VFScreen } from '@vfgroup-oneplatform/foundation/Components'
import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'
import SpinnerOverlay from '@vfgroup-oneplatform/foundation/Components/SpinnerOverlay'

import {
    SectionEnums,
} from '@vfgroup-oneplatform/framework/CommonUI/PrivacyPermissionsOverlay'
import {
    PrivacyPermissionsSection,
} from '@vfgroup-oneplatform/framework/CommonUI/PrivacyPermissionsOverlay/Components'

import VFLoader from '@vfal-lab/rn-vfg-catalogue/Components/VFLoader'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import NavigationService from '../../Navigation/NavigationService'

import { getPreferences, updatePreferences } from '../../Services/Settings'

import ReduxCache from '../../Utils/ReduxCache'


import styles from './Preferences.Styles'

const Preferences = ({ ...rest }) => {
    const [saving, setSaving] = useState(false)

    const [data, setData] = useState({})
    const [items, setItems] = useState({})

    const [loading, setLoading] = useState(true)

    const language = ReduxCache.getLanguage()
    const theme = useTheme()


    const preferences = {
        privacySettingsSections: [
            {
                title: ContentManager.translate('preferences_subtitle'),
                content: {
                    paragraphs: [
                        ContentManager.translate('preferences_first_description'),
                        ContentManager.translate('preferences_second_description')
                    ]
                },
                actions: {
                    items: []
                }
            }
        ]
    }


    const askForNotifications = (res) => {
        if (res['push notification']) {
            // UrbanAirship.setUserNotificationsEnabled(true)
        }
    }

    useEffect(() => {
        getPreferences({
            'Accept-Language': language,
        }, {
            onSuccess: ({ response, items: newItems }) => {
                AsyncStorage.setItem('shouldRenderPreferences', `${response?.status !== 'Active'}`)
                if (response?.status === 'Active') {
                    // askForNotifications(newItems)
                }
                setItems(newItems)
                setData(response)
                setLoading(false)
            },
            onError: () => { }
        })
    }, [])

    const onDismiss = () => {
        NavigationService.pop()
        setSaving(false)
    }

    const onOkButton = () => {
        setSaving(true)

        updatePreferences(data, items, {}, {
            onSuccess: (res) => {
                onDismiss()
                askForNotifications(res)
            },
            onError: () => onDismiss()
        })
    }

    const onToggle = key => {
        setItems(oldItems => {
            return {
                ...oldItems,
                [key]: !oldItems[key]
            }
        })
    }

    const sections = preferences.privacySettingsSections.map((sec, i) => {
        const parts = data?.parts
        const preferencesArr = parts?.partyPreference?.partyPreference

        const mappedPreferences = preferencesArr?.map((preference) => ({
            title: preference?.name,
            description: preference?.desc,
            key: preference?.id?.[0]?.value,
            initialValue: items?.[preference?.id?.[0]?.value],
            onToggle: onToggle
        })) || []

        return {
            ...sec,
            actions: {
                type: SectionEnums.PERMISSION,
                items: mappedPreferences
            }
        }
    })

    const onClose = () => NavigationService.pop()

    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
        return (
            <>
                <VFScreen
                    title={'preferences_title'}
                    onClose={onClose}
                    closeButtonTestID={'appSettingsCloseBtn'}
                    onBack={onClose}
                    showBack={true}
                    showClose={false}
                    {...rest}
                >
                    {loading ?
                        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <VFLoader style={{
                                width: 150,
                                height: 150,
                            }}

                                containerStyle={{
                                    flex: 1,
                                    marginBottom: 64,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}

                            />
                        </View>
                        :
                        <ScrollView
                            showsVerticalScrollIndicator={false}
                            contentContainerStyle={[
                                styles.scrollViewContentContainer,
                                { paddingHorizontal: '10%', paddingBottom: 100 }
                            ]}>
                            {sections.map((section, index) => (
                                <PrivacyPermissionsSection index={index} key={index} section={section} />
                            ))}
                            <VFButton
                                onPress={onOkButton}
                                style={styles.button}
                                title="profile_settings_save_button"
                            />
                        </ScrollView>}
                </VFScreen>
                {saving && <SpinnerOverlay isVisible autoPlay lottieStyle={styles.lottieStyle} />}
            </>
        )
    }

    return (
        <>
            <VFScreen
                title={'preferences_title'}
                onClose={onClose}
                closeButtonTestID={'appSettingsCloseBtn'}
                withHeaderSpace
            >
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={[
                        styles.scrollViewContentContainer(theme),
                    ]}>
                    {sections.map((section, index) => (
                        <PrivacyPermissionsSection index={index} key={index} section={section} />
                    ))}
                </ScrollView>
                <VFButton
                    onPress={onOkButton}
                    style={styles.button}
                    title="profile_settings_save_button"
                />
            </VFScreen>
            {saving && <SpinnerOverlay isVisible autoPlay lottieStyle={styles.lottieStyle} />}
        </>
    )
}
Preferences.propTypes = {
    setIsVisible: PropTypes.func,
    isVisible: PropTypes.bool,
    onFinish: PropTypes.func
}
Preferences.defaultProps = {
    setIsVisible: () => { },
    isVisible: false,
    onFinish: () => { }
}
export default Preferences
