import { Dimensions, Image, Platform } from 'react-native'
import { Images as foundationImages } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { Images as frameworkImages } from '@vfgroup-oneplatform/framework/Themes'

const images = {
  ...frameworkImages,
  ...foundationImages,
  icChevronRightWhite: Image.resolveAssetSource(
    require('./../Assets/Images/icChevronRightWhite@3x.png')
  ),
  ic_data_sharing: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/dataSharing@3x.png')
  ),
  ic_sms_text: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/smsText@3x.png')
  ),
  ic_call_log: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/callLog@3x.png')
  ),
  tobi_less_icon: Image.resolveAssetSource(
    require('./../Assets/Images/tobi/tobi-less@3x.png')
  ),
  tobi_less_icon_help: Image.resolveAssetSource(
    require('./../Assets/Images/tobi/tobi-less-help@3x.png')
  ),
  // onBoarding
  protection: Image.resolveAssetSource(
    require('./../Assets/Images/onBoarding/protection@3x.png')
  ),
  location: Image.resolveAssetSource(
    require('./../Assets/Images/onBoarding/location@3x.png')
  ),
  recognition: Image.resolveAssetSource(
    require('./../Assets/Images/onBoarding/recognition@3x.png')
  ),
  payment: Image.resolveAssetSource(
    require('./../Assets/Images/Tray/HiFiIconsPayment.png')
  ),
  padlock: Image.resolveAssetSource(
    require('./../Assets/Images/onBoarding/padlock@3x.png')
  ),
  prepare: Image.resolveAssetSource(
    require('./../Assets/Images/onBoarding/prepare@3x.png')
  ),
  clapping: Image.resolveAssetSource(
    require('./../Assets/Images/onBoarding/clapping@3x.png')
  ),
  // Billing
  ic_parental_control: Image.resolveAssetSource(
    require('./../Assets/Images/Billing/icParentalControl@3x.png')
  ),
  ic_router: Image.resolveAssetSource(
    require('./../Assets/Images/Billing/icRouter@3x.png')
  ),
  // Addons
  videoPass: Image.resolveAssetSource(
    require('./../Assets/Images/Addons/videoPass@3x.png')
  ),
  socialPass: Image.resolveAssetSource(
    require('./../Assets/Images/Addons/socialPass@3x.png')
  ),
  musicPass: Image.resolveAssetSource(
    require('./../Assets/Images/Addons/musicPass@3x.png')
  ),
  maps: Image.resolveAssetSource(require('./../Assets/Images/Addons/maps@3x.png')),
  roaming: Image.resolveAssetSource(
    require('./../Assets/Images/Addons/roaming@3x.png')
  ),
  gamingPass: Image.resolveAssetSource(
    require('./../Assets/Images/Addons/gamingPass@3x.png')
  ),
  addOnHeader: Image.resolveAssetSource(
    require('./../Assets/Images/Addons/header@3x.png')
  ),
  visa: Image.resolveAssetSource(require('./../Assets/Images/visa@3x.png')),
  icTopUpRed: Image.resolveAssetSource(
    require('./../Assets/Images/TopUp/icTopUpRed@3x.png')
  ),
  roamingPassIcon: Image.resolveAssetSource(
    require('./../Assets/Images/Addons/roamingPassIcon@3x.png')
  ),
  roamingPassImage: Image.resolveAssetSource(
    require('./../Assets/Images/Addons/roamingPassImage@3x.png')
  ),
  icBillReport: Image.resolveAssetSource(
    require('@vfgroup-oneplatform/foundation/Components/Assets/Images/Icons/icBillReportGenericTariffPlan@3x.png')
  ),
  icClock: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/icClockMid@3x.png')
  ),
  ic_mastercard: Image.resolveAssetSource(
    require('./../Assets/Images/TopUp/mastercardLight@3x.png')
  ),
  ic_visa: Image.resolveAssetSource(
    require('./../Assets/Images/TopUp/visaCard@3x.png')
  ),
  ic_outgoing_call: Image.resolveAssetSource(
    require('./../../Group/Assets/Images/Balance/icOutgoingCall@3x.png')
  ),
  ic_social_pass: Image.resolveAssetSource(
    require('./../Assets/Images/Balance/icSocialPass@3x.png')
  ),
  icMail: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/icMail@3x.png')
  ),
  icTopUp: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/icTopUp@3x.png')
  ),
  icLogo: Image.resolveAssetSource(require('./../Assets/Images/Logo/red@3x.png')),
  icRoaming: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/iconsIndicatorsSystemIconsRoaming333333@3x.png')
  ),
  ic_bundle_white: Image.resolveAssetSource(
    require('./../Assets/Images/Addons/bundle_white.png')
  ),

  ic_offers: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/ic_offers@3x.png')),
  ic_shake: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/shake@3x.png')),
  ic_rewards: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/reward@3x.png')),
  ic_red_box: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/flower@3x.png')),
  ic_recharge: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/top_up@3x.png')),
  ic_recharge_other: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/top-up-another@3x.png')),
  ic_parking: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/parking@3x.png')),
  ic_sharing: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/sharing@2x.png')),
  ic_sos_credit: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/share-your-mins@3x.png')),
  ic_call_me_back: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/call-me-back@3x.png')),
  ic_store: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/store@3x.png')),
  ic_payFix: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/payFix.png')),

  background_waves_red: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/background_waves_red.jpg')
  ),
  background_soho_waves_red: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/soho_background.png')
  ),
  busniess_usage: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/busniess_usage.png')),
  busniess_usage_white: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/busniess_usage_white.png')),
  business_image: Image.resolveAssetSource(require('./../Assets/Images/Tray/business_image.png')),
  ic_marketplace: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/ic_marketplace.png')
  ),

  background_red: Image.resolveAssetSource(
    require('../../Assets/Images/PreLogin/GetStarted.png')
  ),
  'ic_user_request ': Image.resolveAssetSource(
    require('./../Assets/Images/Tray/user_requests.png')
  ),
  requests_icon: Image.resolveAssetSource(
    require('./../Assets/Images/Tray/user_requests.png')
  ),
  ic_my_users_and_group: Image.resolveAssetSource(require('./../Assets/Images/Tray/icMyUsers.png')),


  ebiznes: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/ebiznes.png')
  ),
  vodafoneSmartFleet: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/vodafoneSmartFleet.png')
  ),
  tagAndTrack: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/tagAndTrack.png')
  ),
  temperatureTag: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/temperatureTag.png')
  ),

  ic_search: Image.resolveAssetSource(
    require('./../Assets/Images/ic_search.png')
  ),

  icCallsContactsWhite: Image.resolveAssetSource(
    require('./../Assets/Images/Soho/icCallsContactsWhite.png')
  ),

  ic_warning_red: Image.resolveAssetSource(
    require('../../Assets/Images/icons/attention.png')
  ),
  ic_warning_white: Image.resolveAssetSource(
    require('../../Assets/Images/vf_icons/HIFI/warning.png')
  ),


  cloud_white: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/cloud_white.png')
  ),
  cloud: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/cloud.png')
  ),
  digital_white: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/digital_white.png')
  ),
  digital: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/digital.png')
  ),
  fixed_white: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/fixed_white.png')
  ),
  fixed: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/fixed.png')
  ),
  security_white: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/security_white.png')
  ),
  security: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/security.png')
  ),
  vhub_white: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/security_white.png')
  ),
  vhub: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/security.png')
  ),
  soho_usage_tile_bg: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/soho_usage_tile_bg.png')
  ),
  soho_usage_tile_bg_dark: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/soho_usage_tile_bg_dark.png')
  ),
  usage_tile_bg: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/usage_tile_bg.jpg')
  ),
  usage_tile_bg_dark: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/usage_tile_bg_dark.jpg')
  ),
  ic_unlimited_home_tray: Image.resolveAssetSource(
    require('../Images/Dashboard/unlimited_home.png')
  ),


  ic_number_tray: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/ic_number_tray.png')),
  ic_settings_tray: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? Image.resolveAssetSource(require('./../Assets/Images/Dashboard/ic_settings_tray_web.png')) : Image.resolveAssetSource(require('./../Assets/Images/Dashboard/ic_settings_tray.png')),
  ic_tidal_tray: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/ic_tidal_tray.png')),
  ic_red_gift: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/ic_red_gift@3x.png')),
  ic_secure: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/ic_secure.png')),
  ic_giga_family_tray: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/ic_giga_family_tray@3x.png')),
  ic_tile_background: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/ic_tile_background@3x.png')),
  ic_albsig: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/albsig.png')),
  ic_promo_discover_counter: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/promoDiscover.png')),
  ic_ticket: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/ic_ticket.png')),
  icShoppingTrolley: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/icShoppingTrolley@3x.png')),
  ic_acceleration: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/icAcceleration@3x.png')),
  logout_icon: Image.resolveAssetSource(require('./../Assets/Images/Tray/logout.png')),
  message_icon: Image.resolveAssetSource(require('./../Assets/Images/Tray/mail.png')),

  ic_loan: Image.resolveAssetSource(
    require('./../../Assets/Images/Addons/loan.png')
  ),
  ic_loan_payback: Image.resolveAssetSource(
    require('./../../Assets/Images/Addons/loan-payback.png')
  ),
  ic_bundle: Image.resolveAssetSource(
    require('./../Assets/Images/Addons/bundle@3x.png')
  ),

  ic_recommend: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/ic_recommend.png')),
  ic_fixed_line: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/fixLine.png')),
  ic_stars: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/ic_stars.png')),
  ic_gift: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/ic_red_gift@3x.png')),
  ic_shopping: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/icShoppingTrolley@3x.png')),
  ic_shopping_white: Image.resolveAssetSource(require('./../Assets/Images/Dashboard/icShoppingTrolleyWhite@3x.png')),
  contracts_icon: Image.resolveAssetSource(require('./../Assets/Images/Tray/ic_contracts.png')),
  ic_giga_fibra_tray: Image.resolveAssetSource(require('./../Assets/Images/Tray/giga_fibra.png')),
  ic_vodafone_tv: Image.resolveAssetSource(require('./../Assets/Images/Tray/vodafone_tv.png')),
  orders_icon: Image.resolveAssetSource(require('./../Assets/Images/Tray/ic_orders.png')),
  ic_modem: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/broadband.png')
  ),
  ic_broadband: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/wifi_medium.png')
  ),
  ic_tv_icon: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/tv.png')
  ),
  ic_tv: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/television.png')
  ),
  speed: Image.resolveAssetSource(
    require('./../Assets/Images/speed.png')
  ),
  speed_white: Image.resolveAssetSource(
    require('./../Assets/Images/speed_white.png')
  ),
  icBusinessUser: Image.resolveAssetSource(
    require('./../Assets/Images/Soho/BusinessOverview/Plans/icBusinessUser@3x.png')
  ),
  call_log: Image.resolveAssetSource(
    require('./../Assets/Images/Balance/icOutgoingCall@3x.png')
  ),
  juth: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/juth.png')
  ),
  bright_sky: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/bright_sky.png')
  ),
  tirana_ime: Image.resolveAssetSource(
    require('./../Assets/Images/Dashboard/tirana_ime.png')
  ),
  ic_indicators_home: Image.resolveAssetSource(
    require('../Assets/Images/SwitchAccount/iconsIndicatorsSystemIconsHome333333@3x.png')
  ),
  'bookcalendar-month': Image.resolveAssetSource(
    require('./../Assets/Images/calendar-month.png')
  ),
  'bookcalendar-month-dark': Image.resolveAssetSource(
    require('./../Assets/Images/calendar-month-dark.png')
  ),
  bundles: Image.resolveAssetSource(
    require('./../Assets/Images/bundles.png')
  ),
  bundles_dark: Image.resolveAssetSource(
    require('./../Assets/Images/bundles_dark.png')
  ),
  'bookcalendar-month': Image.resolveAssetSource(
    require('./../Assets/Images/calendar-month.png')
  ),
  'bookcalendar-month-dark': Image.resolveAssetSource(
    require('./../Assets/Images/calendar-month-dark.png')
  ),
}

export default images
