import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { Icon, QuickActionMenu, VFButton, VFScreen, VFText } from '@vfgroup-oneplatform/foundation/Components'
import { Dimensions, Image, ImageBackground, TouchableOpacity, View } from 'react-native'
import { RNCamera } from 'react-native-infy-camera'
import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import Modal from '@vfal-lab/rn-vfg-renewals-fix/Components/Modal'
import { withTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { RNHoleView } from 'react-native-hole-view'

import WebView from 'react-native-webview'

import Loader from '../../Components/Loader'

import styles from './CocaCola.Styles'
import { ErrorComponent, LoadingComponent, NoProductFound, SuccessComponent } from './Status'

import { useCocaCola } from './useCocaCola'
import { getThemeImages } from '../../Themes'

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('screen')


const CocaCola = ({ route, theme }) => {
  const cameraRef = useRef()

  const isDeeplink = route?.params?.isDeeplink


  const { error,
    infoModal,
    notFound,
    toggleFlash,
    flashState,
    isCameraOpen,
    toggleCameraModal,
    toggleInfoModal,
    onClose,
    removeError,
    onQRScan,
    loading,
    successData,
  } = useCocaCola({ isDeeplink })

  const width = SCREEN_WIDTH - 160
  const cameraHeight = SCREEN_WIDTH * 16 / 9

  // eslint-disable-next-line react/prop-types
  const images = getThemeImages(theme.name)

  const imageHeight = width

  const topVerticalOffset = (cameraHeight - 120 - imageHeight) / 2

  return (
    <VFScreen
      title="cocacola_header_title"
      onClose={onClose}
      showBack
      onBack={toggleInfoModal}
      leftIcon={images.info_circle_black}
      containerStyle={styles.screenContainer(theme)}
      headerStyle={styles.header}
    >

      <View style={styles.flexContainer(theme)}>
        <Image style={styles.mainImage} source={images.coca_cola_can} />
      </View>
      <View style={styles.flexContainer(theme)}>
        <ImageBackground
          resizeMode="stretch"
          style={styles.imageBackground}
          source={images.coca_cola_card}>
          <View style={styles.backgroundContainer}>
            <View style={styles.qrHeader}>
              <View style={styles.qrWrapper}>
                <Image style={styles.scanIcon(theme)} source={images.coca_cola_scan} />
                <View>
                  <VFText style={styles.scanSubtitle(theme)} i18nKey="cocacola_reward" />
                  <VFText style={styles.scanTitle(theme)} i18nKey="cocacola_qrcode" />
                </View>
              </View>
            </View>

            <VFText style={styles.scanDesc(theme)} i18nKey="cocacola_card_description" />

            <VFButton onPress={toggleCameraModal} style={styles.scanButton} title="cocacola_scan_qr" />

          </View>
        </ImageBackground>
      </View>


      <Modal
        visible={isCameraOpen}
        close={toggleCameraModal}
        type="full"
        contentContainerStyle={styles.modal(theme, { bottom: 0 })}
        theme={theme}
      >
        {error && <ErrorComponent onRetry={removeError} onClose={toggleCameraModal} error={error} />}
        {notFound && <NoProductFound onClose={toggleCameraModal} />}

        {loading && <LoadingComponent />}
        {successData && <SuccessComponent onClose={toggleCameraModal} value={successData.value} date={successData.validity} />}

        {
          !error && !notFound && !loading && !successData &&
          <>
            <RNCamera
              ref={cameraRef}
              type={RNCamera.Constants.Type.back}
              flashMode={flashState}
              focusable
              useNativeZoom={false}
              zoom={0}
              barCodeTypes={[RNCamera.Constants.BarCodeType.qr]}
              onBarCodeRead={onQRScan}
              style={{ height: SCREEN_HEIGHT, width: SCREEN_WIDTH }}
            >
              <View style={styles.cameraContainer}>

                <RNHoleView
                  style={styles.overlayMask}
                  holes={[
                    {
                      x: 80,
                      y: topVerticalOffset,
                      width: width,
                      height: imageHeight,
                      borderRadius: 8,
                    },
                  ]}
                />
                <View style={styles.borderRadius(width, imageHeight, topVerticalOffset)} />


                <View style={styles.headerContainer}>

                  <TouchableOpacity onPress={toggleFlash}>
                    <Icon
                      name={RNCamera.Constants.FlashMode.torch === flashState ? images.flash_filled : images.flash}
                      size={25}
                      resizeMode="contain"
                      type="image"
                    />
                  </TouchableOpacity>

                  <TouchableOpacity onPress={toggleCameraModal}>
                    <Icon
                      name={images.close_white}
                      size={25}
                      resizeMode="contain"
                      type="image"
                    />
                  </TouchableOpacity>

                </View>


                <View style={styles.textContainer}>
                  <VFText
                    i18nKey="cocacola_camera_description"
                    style={styles.text}
                  />
                </View>
              </View>
            </RNCamera>
          </>
        }
      </Modal>

      <QuickActionMenu
        isVisible={infoModal}
        toggleModal={toggleInfoModal}
        title="Info"
        type="secondary"
        closeBtnStyle={styles.modalCloseStyle}
        titleStyle={styles.modalTitleStyle}
      >
        <View style={styles.webviewContainer}>
          <WebView
            source={{ uri: ContentManager.translate('cocacola_info_url') }}
            startInLoadingState={true}
            renderLoading={() => <Loader size={50} view="full" />}
          />
        </View>
      </QuickActionMenu>
    </VFScreen>
  )
}

CocaCola.propTypes = {
  route: PropTypes.object,
  theme: PropTypes.object,
}


export default withTheme(CocaCola)
