import React from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { Icon, VFText } from '@vfgroup-oneplatform/foundation/Components'

import { getThemeImages } from '../../../../Themes'

import styles from '../SecondaryComponent.Styles'

const CardHeader = ({
    icon,
    title,
    ...props
}) => {

    const theme = useTheme()

    const themedImages = getThemeImages(theme.name)

    return (
        <View style={styles.iconAndTitleContainer}>
            <Icon
                source={themedImages?.[icon]}
                style={styles.icon}
                size={24}
            />
            <VFText
                i18nKey={title}
                style={styles.title}
                {...props}
            />
        </View>
    )
}
CardHeader.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
}

export default CardHeader