import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  View,
  FlatList,
  Dimensions,
  Platform,
} from 'react-native'
import ContentLoader, { Rect } from 'react-content-loader/native'
import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import {
  Card,

  VFText,
  Icon,
  ErrorScreen,
  VFScreen
} from '@vfgroup-oneplatform/foundation/Components'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { getContracts } from '../../Services/Fix'

import { getThemeImages } from '../../Themes'

import styles from './FixContracts.Styles'

const { width } = Dimensions.get('screen')


const CardLoader = () => {

  const theme = useTheme()

  let _width

  if (Dimensions.get('window').width > 700) {
    _width = (width - 300) * 0.8
  } else {
    _width = width - 32
  }


  return (
    <ContentLoader
      speed={2}
      width={_width}
      height={190}
      backgroundColor={theme.colors.shimmerCenterColor}
      foregroundColor={theme.colors.shimmerEdgesColor}
      style={styles.cardContentLoader(theme)}

    >
      <Rect x="16" y="16" rx="10" ry="10" width={32} height={32} />

      <Rect x="60" y="16" rx="5" ry="5" width={80} height={18} />
      <Rect x="60" y="40" rx="5" ry="5" width={100} height={16} />

      <Rect x="16" y="70" rx="5" ry="5" width={width - 60} height={2} />

      <Rect x="16" y="90" rx="5" ry="5" width={width / 3} height={15} />
      <Rect x={width - 150} y="90" rx="5" ry="5" width={100} height={15} />

      <Rect x="16" y="120" rx="5" ry="5" width={width / 3} height={15} />
      <Rect x={width - 150} y="120" rx="5" ry="5" width={100} height={15} />

      <Rect x="16" y="150" rx="5" ry="5" width={width / 3} height={15} />
      <Rect x={width - 150} y="150" rx="5" ry="5" width={100} height={15} />

    </ContentLoader>
  )

}
const TextLoader = () => {

  const theme = useTheme()

  return (
    <ContentLoader
      speed={2}
      width={width - 32}
      height={30}
      backgroundColor={theme.colors.shimmerCenterColor}
      foregroundColor={theme.colors.shimmerEdgesColor}
      style={styles.textContentLoader}
    >
      <Rect x="4" y="4" rx="5" ry="5" width={100} height={16} />
    </ContentLoader>
  )

}

const renderLoaders = () => {
  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
    return (
      <View style={{ marginHorizontal: '10%', overflow: 'hidden' }}>
        <TextLoader />
        <CardLoader />
        <CardLoader />
      </View>
    )
  }
  return (
    <>
      <TextLoader />
      <CardLoader />
      <CardLoader />
    </>
  )
}
function CardContent({
  id,
  name,
  shownCharacteristic,
  icon,
  theme,
}) {

  const themeImages = getThemeImages(theme.name)

  const getIcon = () => {
    switch (icon) {
      case 'tv':
        return themeImages.ic_tv_and_internet
      case 'internet':
        return themeImages.ic_wifi_broadband
      case 'mobile':
        return themeImages.ic_mobile
      default:
        return ''
    }
  }

  return (
    <>
      <View style={[styles.card, { marginBottom: 20 }]} key={id}>
        <View style={styles.cardHeader}>
          <Icon
            name={getIcon()}
            size={30}
            resizeMode="contain"
            type="image"
            style={styles.mobileIcon(theme)}
          />
          <View style={styles.cardTextContainer}>
            <VFText
              i18nKey={name}
              style={styles.header}
            />
            <VFText
              i18nKey={id}
              style={styles.subTitle(theme)}
            />
          </View>
        </View>
        <View style={styles.separator(theme)} />

        <View style={styles.contactContainer}  >

          {shownCharacteristic.map(item => {
            return (
              <View style={styles.textsWrapper} >
                <VFText
                  i18nKey={`contracts_${item?.name}`}
                  style={styles.cardDescText}
                />
                {item?.name === 'contract_duration' ? <VFText
                  i18nKey={item?.value?.trim() != '-' ? 'contracts_months' : item?.value}
                  style={styles.cardDescText}
                  placeHolders={[item?.value]}
                /> :
                  <VFText
                    i18nKey={item?.name === 'price' ? item?.value.toString() + ' ' + ContentManager.translate('ALL') : item.value}
                    style={styles.cardDescText}
                  />}
              </View>
            )
          })}
        </View>
      </View>
      {/* <View style={styles.separator} />
      <VFButton
        type="secondary"
        title="contracts_download_button_label"
        onPress={onPress}
        style={styles.button}
      /> */}
    </>

  )
}

CardContent.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  shownCharacteristic: PropTypes.arrayOf({
    name: PropTypes.string,
    value: PropTypes.oneOf(PropTypes.string, PropTypes.number)
  }),
  icon: PropTypes.string,
  theme: PropTypes.object,
}


const Contracts = ({ navigation }) => {

  const [isLoading, setIsLoading] = useState(false)
  const [contracts, setContracts] = useState([])
  const [error, setError] = useState('')
  const contractType = 'all'

  const theme = useTheme()

  const getData = () => {
    setIsLoading(true)
    setError(null)
    getContracts(contractType, {
      onSuccess: (response) => {
        setIsLoading(false)
        setContracts(response)
      },
      onError: (err) => {
        setIsLoading(false)
        setError(err || 'general_error')
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])


  const onClose = () => navigation.navigate('Dashboard')

  const firstFixedEl = contracts.find(el => el.agreementType === 'fixed')
  const firstMobileEl = contracts.find(el => el.agreementType === 'mobile')
  const firstFixedIndex = contracts.indexOf(firstFixedEl)
  const firstMobileIndex = contracts.indexOf(firstMobileEl)

  const renderItem = ({ index, item }) => {
    return (
      <View style={[{ marginBottom: 20 }, index == contracts.length - 1 ? { marginBottom: '10%' } : {}]}>
        {(index === firstMobileIndex || index === firstFixedIndex) && <VFText
          i18nKey={index === firstFixedIndex ? 'contracts_fixed_title' : 'contracts_mobile_title'}
          style={styles.title}
        />}
        <Card style={styles.cardWrapper} type="main" hasShadow>
          <CardContent
            {...item}
            theme={theme}
          />
        </Card>
      </View>

    )
  }


  return (
    <VFScreen
      title="contracts_header_title"
      onClose={onClose}
      onBack={onClose}
    >
      {isLoading ?
        renderLoaders()
        :
        <View>
          {error ?
            <ErrorScreen
              text={error}
              onPress={getData}
              textButton="contracts_try_again"
            /> :
            <FlatList
              keyExtractor={(item, index) => index.toString()}
              data={contracts}
              renderItem={renderItem}
              showsVerticalScrollIndicator={false}
              style={styles.listStyle}
              contentContainerStyle={styles.listContainerStyle}
              // ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
              ListEmptyComponent={() => <VFText
                i18nKey="contracts_fixed_no_contracts"
                style={styles.noContractsText}
              />}
            />
          }
        </View>
      }
    </VFScreen>
  )
}

Contracts.propTypes = {
  navigation: PropTypes.object,
}

export default Contracts
