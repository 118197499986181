import React from 'react'
import FastImage from 'react-native-fast-image'

const CustomImage = (props) => {
  return (
    <FastImage
      style={props.style}
      source={props.source}
    />
  )
}

export default CustomImage
