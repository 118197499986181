import React, { useState, useRef, useEffect } from 'react'
import { FlatList, Dimensions, Platform, View } from 'react-native'
import PropTypes from 'prop-types'
import ContentLoader, { Rect } from 'react-content-loader/native'

import { VFScreen, VFText, ErrorScreen, VFButton, QuickActionMenu } from '@vfgroup-oneplatform/foundation/Components'
import ManualRefresh from '@vfgroup-oneplatform/foundation/Components/ManualRefresh'
import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { getThemeImages } from '@vfgroup-oneplatform/framework/Themes'


import { Html5QrcodeScanType, Html5QrcodeScanner } from 'html5-qrcode'

import _Modal from 'react-native-modal'

import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import { getOrders } from '../../Services/Fix'

import ReduxCache from '../../Utils/ReduxCache'

import { __translate, getMsisdnData } from '../../Utils'

import { InProgressOrdersCard, PastOrdersCard } from './Components'


import styles from './ManageOrder.Styles'


const { width, height } = Dimensions.get('screen')

const _height = height * 0.8 - 32



const ManageOrders = ({ navigation, route }) => {

  const [isScanning, setIsScanning] = useState(false)
  const [id, setId] = useState(null)
  const cameraRef = useRef(null)

  const [username, setUsername] = useState()
  const msisdn = ReduxCache.getMsisdn()

  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const [lastUpdateTime, setLastUpdatedTime] = useState(new Date())

  const getData = async () => {
    setLoading(true)
    getOrders({
      onSuccess: (response) => {
        setOrders(response)
        setLoading(false)
        setError(false)
        setLastUpdatedTime(new Date())
      },
      onError: (err) => {
        setLoading(false)
        setLastUpdatedTime(new Date())
        setError(err)
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    getMsisdnData()
      .then((res) => {
        const storedUsername = res?.username
        setUsername(storedUsername)
      })
      .catch(() => { })
  }, [])

  const headerRef = useRef(null)
  const [activeTab, setActiveTab] = useState(0)

  const theme = useTheme()
  const images = getThemeImages(theme.name)

  const configureHeaderTabs = {
    activeTab: activeTab,
    setActiveTab: index => setActiveTab(index),
    tabs: [
      { index: 0, tab_key: 'my_orders_inprogress' },
      { index: 1, tab_key: 'my_orders_pastorders' }
    ]
  }

  const vfScreenProps = {
    contentContainerStyle: styles.contentContainer,
    withSubHeader: true,
    subHeaderTitle: 'my_orders_manage_your_orders',
    subHeaderTitleStyle: styles.subHeaderTitle,
    subHeaderSubTitle: orders.filter(order => order.state !== 'completed').length.toString(),
    subHeaderAfterSubTitle: orders.filter(order => order.state !== 'completed').length === 1 ? 'my_orders_number_of_order_inprogress' : 'my_orders_number_of_orders_inprogress',
    subHeaderSubTitleStyle: styles.subHeaderSubTitle,
    subHeaderDescription: username || msisdn,
    renderDescriptionInfo: renderDescriptionInfo
  }

  function renderDescriptionInfo() {
    return (
      <ManualRefresh
        containerStyle={styles.manualRefresh}
        onRefresh={getData}
        images={images}
        lastUpdateTime={lastUpdateTime}
      />
    )
  }

  function renderInprogressTab(isActive) {

    return (
      isActive && (
        <FlatList
          data={orders.filter(order => order.state !== 'completed')}
          style={{ paddingHorizontal: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? '10%' : 0 }}
          contentContainerStyle={{ paddingHorizontal: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? '10%' : 0 }}
          keyExtractor={item => item.id}
          renderItem={({ item }) => <InProgressOrdersCard order={item} />}
          ListEmptyComponent={() => (
            <>
              <VFText i18nKey="my_orders_no_inprogess_orders" style={styles.emptyListTitle(theme)} />
            </>
          )}
          ListHeaderComponent={() => (
            <VFButton
              title="my_orders_scan_button"
              onPress={() => {
                setIsScanning(true)
                // navigation.navigate('OrderProgress', { orderId: 'fb3cd2d' })
              }}
              style={styles.scanBtn}
            />
          )}
        />
      )
    )
  }

  function renderPastOrdersTab(isActive) {
    return (
      isActive && (
        <FlatList
          contentContainerStyle={{ paddingHorizontal: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? '10%' : 0 }}
          style={{ paddingHorizontal: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? '10%' : 0 }}
          data={orders.filter(order => order.state === 'completed')}
          keyExtractor={item => item.id}
          renderItem={({ item }) => <PastOrdersCard order={item} />}
          ListEmptyComponent={() => (
            <>
              <VFText i18nKey="my_orders_no_past_orders" style={styles.emptyListTitle(theme)} />
            </>
          )}
          ListHeaderComponent={() => (
            <VFButton
              title="my_orders_scan_button"
              onPress={() => {
                setIsScanning(true)
                // navigation.navigate('OrderProgress', { orderId: 'fb3cd2d' })
              }}
              style={styles.scanBtn}
            />
          )}
        />
      )
    )
  }

  const CardLoader = () => {
    return (
      <ContentLoader
        speed={2}
        width={width - 32}
        height={210}
        backgroundColor={theme.colors.shimmerCenterColor}
        foregroundColor={theme.colors.shimmerEdgesColor}
        style={styles.cardContentLoader(theme)}

      >
        <Rect x="16" y="16" rx="5" ry="5" width={100} height={16} />

        <Rect x="16" y="48" rx="5" ry="5" width={width - 60} height={2} />

        <Rect x="16" y="66" rx="5" ry="5" width={100} height={90} />

        <Rect x={width - 200} y="86" rx="5" ry="5" width={120} height={16} />

        <Rect x={width - 200} y="118" rx="5" ry="5" width={120} height={16} />

        <Rect x="16" y="178" rx="5" ry="5" width={150} height={16} />

      </ContentLoader>
    )

  }

  const CardLoaderCompleted = () => {
    return (
      <ContentLoader
        speed={2}
        width={width - 32}
        height={120}
        backgroundColor={theme.colors.shimmerCenterColor}
        foregroundColor={theme.colors.shimmerEdgesColor}
        style={styles.cardContentLoader(theme)}

      >
        <Rect x="16" y="16" rx="5" ry="5" width={100} height={90} />

        <Rect x={width - 200} y="50" rx="5" ry="5" width={120} height={16} />

      </ContentLoader>
    )

  }

  const TitleLoader = () => {
    return (
      <ContentLoader
        speed={2}
        width={width - 32}
        height={60}
        backgroundColor={theme.colors.shimmerCenterColor}
        foregroundColor={theme.colors.shimmerEdgesColor}
        style={styles.textContentLoader}
      >
        <Rect x="4" y="4" rx="5" ry="5" width={120} height={16} />
        <Rect x={width - 140} y="4" rx="5" ry="5" width={100} height={16} />
        <Rect x="4" y="30" rx="5" ry="5" width={100} height={16} />
      </ContentLoader>
    )

  }

  const renderLoaders = () => {
    return (
      <View style={{ marginHorizontal: '10%', overflow: 'hidden' }}>
        <TitleLoader />
        <CardLoader />
        <CardLoader />
      </View>
    )
  }

  const renderLoadersCompleted = () => {
    return (
      <>
        <TitleLoader />
        <CardLoaderCompleted />
        <CardLoaderCompleted />
        <CardLoaderCompleted />
      </>
    )
  }

  const renderError = () => {
    return (
      <ErrorScreen
        text={error}
        onPress={getData}
        textButton={'my_orders_try_again'}
      />
    )
  }

  const onClose = () => {
    if (route?.params?.isDeepLink) {
      navigation.navigate('Dashboard')
    } else {
      navigation.pop()
    }
  }





  useEffect(() => {
    if (isScanning) {
      function onScanSuccess(decodedText, decodedResult) {
        setId(decodedText)
      }
      var html5QrcodeScanner = new Html5QrcodeScanner(
        'qr-reader',
        { fps: 10, qrbox: 250, supportedScanTypes: Dimensions.get('window').width > 700 ? [Html5QrcodeScanType.SCAN_TYPE_FILE] : [Html5QrcodeScanType.SCAN_TYPE_CAMERA] },
        false,
        {
          button: VFButton,
          text: VFText
        },
        __translate
      )
      html5QrcodeScanner.render(onScanSuccess)

    }
  }, [isScanning])

  return (
    <VFScreen
      ref={headerRef}
      title="my_orders_title"
      onClose={onClose}
      configureHeaderTabs={configureHeaderTabs}
      {...vfScreenProps}
    >
      {loading ? <>
        {activeTab === 0 && renderLoaders()}
        {activeTab === 1 && renderLoadersCompleted()}
      </> : <>
        {
          error ? <>
            {renderError()}
          </> : <>
            {renderInprogressTab(activeTab === 0)}
            {renderPastOrdersTab(activeTab === 1)}
          </>
        }
      </>}

      {Dimensions.get('window').width > 700 &&
        <_Modal
          isVisible={isScanning}
          backdropOpacity={0.5}
          onSwipeComplete={() => {
            setIsScanning(false)
            setId(null)
          }}
          onBackdropPress={() => {
            setIsScanning(false)
            setId(null)
          }}
          propagateSwipe
          hideModalContentWhileAnimating
          hardwareAccelerated
          backdropTransitionOutTiming={0}
          style={{
            justifyContent: 'flex-end',
            margin: '0 !important',
            minWidth: 1920,
            maxWidth: 1920,
          }}
        >
          <View
            style={{
              minWidth: '600px',
              maxWidth: '600px',
              height: 'auto',
              backgroundColor: theme.colors.quickActionGradientColors.secondary,
              margin: 'auto',
              padding: '20px',
              paddingTop: null,
              borderRadius: '7px',
            }}
            onStartShouldSetResponder={() => true}>
            <div id="qr-reader" style={{ width: '100%', marginBottom: 12 }} />
            <VFButton
              title={`${ContentManager.translate('tutorial_last_step')} ${id !== null ? id : ''}`}
              onPress={() => {
                setIsScanning(false)
                navigation.navigate('OrderProgress', { orderId: id })
                setId(null)
              }}
              disabled={id === null}
              type="primary"
            />
          </View>
        </_Modal>}

      {Dimensions.get('window').width <= 700 &&
        <QuickActionMenu
          isVisible={isScanning}
          toggleModal={() => setIsScanning(false)}
          type="secondary"
          title="my_orders_scan_button"
          closeBtnStyle={styles.modalCloseStyle}
          titleStyle={styles.modalTitleStyle}
        >
          <View style={{ height: _height - (_height / 3) }}>
            <div id="qr-reader" style={{ width: '100%' }} />
            <VFButton
              title={`${ContentManager.translate('tutorial_last_step')} ${id !== null ? id : ''}`}
              onPress={() => {
                setIsScanning(false)
                navigation.navigate('OrderProgress', { orderId: id })
                setId(null)
              }}
              disabled={id === null}
              type="primary"
            />
          </View>
        </QuickActionMenu>}

    </VFScreen>

  )
}

ManageOrders.propTypes = {
  navigation: PropTypes.object,
}

export default ManageOrders
