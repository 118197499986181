import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { View, Platform, TouchableWithoutFeedback } from 'react-native'
import { VFText } from '@vfgroup-oneplatform/foundation/Components'
import Video from '@vfgroup-oneplatform/framework/CommonUI/Video'
import {
  withTheme,
  LightThemeColors
} from '@vfgroup-oneplatform/foundation/Components/Themes'

import styles from './VideoPlayer.Styles'
import ReactPlayer from 'react-player/lazy'

class VideoPlayer extends Component {
  state = {
    play: false,
    initialized: false,
    playing: false
  }

  init = () => {
    this.setState({ play: true, initialized: true })
  }

  dispose = () => {
    this.setState({ play: false })
  }

  //Function's for web video player
  handlePlay = () => {
    this.setState({ playing: true })
  }
  handlePause = () => {
    this.setState({ playing: false })
  }
  handlePlayPause = () => {
    this.setState((prev) => {
      return {
        ...prev,
        playing: !prev.playing
      }
    })
  }
  render() {
    const { video, forceLandsacpe, poster, theme, header, text } = this.props
    return (
      <View style={styles.containerStyle(theme)}>
        <View style={styles.videoTextContainer}>
          {Platform.OS === 'web' ?
            <TouchableWithoutFeedback onPress={this.handlePlayPause}>
              <ReactPlayer
                className='react-player'
                width={'100%'}
                height={'100%'}
                url={video}
                playbackRate={1}
                playing={this.state.playing}
                controls={true}
                loop={true}
                light={poster}
                onPlay={this.handlePlay}
                onPause={this.handlePause}
              />
            </TouchableWithoutFeedback>
            :
            <Video
              url={video}
              play={!this.state.play}
              forceLandsacpe={forceLandsacpe}
              poster={poster}
            />
          }

        </View>
        <View style={styles.textContainer}>
          <VFText
            style={styles.header(theme)}
            i18nKey={header}
          />
          <VFText style={styles.text(theme)} i18nKey={text} />
        </View>
      </View>
    )
  }
}

VideoPlayer.propTypes = {
  video: PropTypes.oneOf(
    PropTypes.string,
    PropTypes.shape({ uri: PropTypes.string.isRequired })
  ).isRequired,
  play: PropTypes.bool,
  forceLandsacpe: PropTypes.bool,
  poster: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  theme: PropTypes.objectOf({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  })
}

VideoPlayer.defaultProps = {
  play: true,
  forceLandsacpe: false,
  theme: LightThemeColors
}
export default withTheme(VideoPlayer)
