import React, { useState, useEffect } from 'react'
import {
  View,
  TouchableOpacity,
  Dimensions,
  FlatList,
  Platform,
} from 'react-native'
import PropTypes from 'prop-types'
import WebView from 'react-native-webview'
// import Share from 'react-native-share'

import {
  VFScreen,
  VFImageHeader,
  VFText,
  VFButton,
  QuickActionMenu,
  Shimmer,
  Icon,
} from '@vfgroup-oneplatform/foundation/Components'
import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'
import { setLoadingConfigObject } from '@vfgroup-oneplatform/foundation/Components/Shimmer/Shimmer.utils'

import { Images as images, useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import _Modal from 'react-native-modal'

import ReduxCache from '../../Utils/ReduxCache'

import Loader from '../../Components/Loader'
import Card from '../../Components/Card'

import { getOfferData, getHistory } from '../../Services/Recommend'

import SuccessfulReferral from './SuccessfulReferral'

import { Images } from '../../Themes'

import styles from './Recommend.Styles'


const { height: SCREEN_HEIGHT } = Dimensions.get('window')

const height = SCREEN_HEIGHT * 0.8 - 32

const shimmerConfig1 = [
  {
    main: setLoadingConfigObject(
      'line',
      1,
      null,
      { marginVertical: 10 },
    ),
  },
]

const shimmerConfig = [
  {
    main: setLoadingConfigObject(
      'line',
      2,
      100,
      { marginVertical: 3, height: 10 },
    ),
    right: setLoadingConfigObject(
      'line',
      1,
      30,
      { height: 10 },
    ),
  },
]


const CardLoading = ({ theme }) => {
  return (
    <>
      <View style={styles.referalItemContainer(true, theme)}>
        <Shimmer config={shimmerConfig} />
      </View>
      <View style={styles.referalItemContainer(true, theme)}>
        <Shimmer config={shimmerConfig} />
      </View>
      <View style={styles.referalItemContainer(false, theme)}>
        <Shimmer config={shimmerConfig} />
      </View>
    </>
  )
}


const TermsModal = ({
  isVisible,
  toggleModal,
}) => {
  const theme = useTheme()

  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
    return (
      <_Modal
        isVisible={isVisible}
        backdropOpacity={0.5}
        onSwipeComplete={toggleModal}
        onBackdropPress={toggleModal}
        propagateSwipe
        hideModalContentWhileAnimating
        hardwareAccelerated
        backdropTransitionOutTiming={0}
        style={{
          justifyContent: 'flex-end',
          margin: '0 !important',
          minWidth: 1920,
          maxWidth: 1920,
        }}
      >
        <View
          style={{
            minWidth: '600px',
            maxWidth: '600px',
            height: 'auto',
            backgroundColor: theme.colors.quickActionGradientColors.secondary,
            margin: 'auto',
            padding: '20px',
            paddingTop: null,
            borderRadius: '7px',
          }}
          onStartShouldSetResponder={() => true}>
          <WebView
            source={{ uri: ContentManager.translate('reccomend_terms_url') }}
            startInLoadingState={true}
            renderLoading={() => <Loader size={50} view="full" />}
          />
        </View>
      </_Modal>
    )
  }

  return (
    <QuickActionMenu
      isVisible={isVisible}
      toggleModal={() => toggleModal()}
      title="recommend_terms_modal_title"
      type="secondary"
      closeBtnStyle={styles.modalCloseStyle}
      titleStyle={styles.modalTitleStyle}
    >
      <View style={{ height: height - (height / 3) }}>
        <WebView
          source={{ uri: ContentManager.translate('reccomend_terms_url') }}
          startInLoadingState={true}
          renderLoading={() => <Loader size={50} view="full" />}
        />
      </View>
    </QuickActionMenu>
  )
}
TermsModal.propTypes = {
  isVisible: PropTypes.bool,
  toggleModal: PropTypes.func,
}


const Info = ({ isVisible, toggleModal }) => {
  const theme = useTheme()

  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
    return (
      <_Modal
        isVisible={isVisible}
        backdropOpacity={0.5}
        onSwipeComplete={toggleModal}
        onBackdropPress={toggleModal}
        propagateSwipe
        hideModalContentWhileAnimating
        hardwareAccelerated
        backdropTransitionOutTiming={0}
        style={{
          justifyContent: 'flex-end',
          margin: '0 !important',
          minWidth: 1920,
          maxWidth: 1920,
        }}
      >
        <View
          style={{
            minWidth: '600px',
            maxWidth: '600px',
            height: 'auto',
            backgroundColor: theme.colors.quickActionGradientColors.secondary,
            margin: 'auto',
            padding: '20px',
            paddingTop: null,
            borderRadius: '7px',
          }}
          onStartShouldSetResponder={() => true}>
          <WebView
            source={{ uri: ContentManager.translate('recommend_more_info_url') }}
            startInLoadingState={true}
            renderLoading={() => <Loader size={50} view="full" />}
          />
        </View>
      </_Modal>
    )
  }
  return (
    <QuickActionMenu
      isVisible={isVisible}
      toggleModal={toggleModal}
      type="secondary"
      title="recommend_info"
      closeBtnStyle={styles.modalCloseStyle}
      titleStyle={styles.modalTitleStyle}
    >
      <View style={{ height: height - (height / 3) }}>
        <WebView
          source={{ uri: ContentManager.translate('recommend_more_info_url') }}
          startInLoadingState={true}
          renderLoading={() => <Loader size={50} view="full" />}
        />
      </View>
    </QuickActionMenu>
  )
}
Info.propTypes = {
  isVisible: PropTypes.bool,
  toggleModal: PropTypes.func,
}


const Error = ({ error, getData, theme }) => {
  return (
    <View style={styles.errorContainer}>
      <VFText
        i18nKey={error}
        style={styles.errorText(theme)}
      />
      <TouchableOpacity
        style={styles.tryAgainContainer}
        onPress={getData}
      >
        <VFText
          i18nKey="recommend_try_again"
          style={styles.tryAgainText}
        />
        <Icon
          name={images.ic_RefreshRed}
          size={30}
          resizeMode="contain"
        />
      </TouchableOpacity>
    </View>
  )
}

Error.propTypes = {
  error: PropTypes.string,
  getData: PropTypes.func,
}


const Recommend = ({ navigation }) => {

  const [showSuccessfulReferral, setShowSuccessfulReferral] = useState(false)

  const [showInfo, setShowInfo] = useState(false)
  const [showTermsModal, setShowTermsModal] = useState(false)

  const [dataLoading, setDataLoading] = useState(false)
  const [historyLoading, setHistoryLoading] = useState(false)
  const [referrals, setReferrals] = useState([])
  const [totalReferals, setTotalReferals] = useState()

  const [error, setError] = useState('')

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [code, setCode] = useState('')

  const lang = ReduxCache.getLanguage()

  const theme = useTheme()

  const getContentData = () => {
    setDataLoading(true)
    setHistoryLoading(true)
    setError()
    getOfferData({}, {
      onSuccess: (response) => {
        setTitle(response.title)
        setDescription(response.description)
        setCode(response.code)
        setDataLoading(false)
      },
      onError: (er) => {
        setError(er)
        setDataLoading(false)
        setHistoryLoading(false)
      }
    })
    getHistory({
      'vf-operator': 'affiliate'
    }, {
      onSuccess: (response) => {
        setHistoryLoading(false)
        setTotalReferals(response.totalReferals)
        setReferrals(response.historyData || [])
      },
      onError: (er) => {
        setError(er)
        setHistoryLoading(false)
      }
    })
  }

  useEffect(() => {
    getContentData()
  }, [])

  const onClose = () => {
    navigation.pop()
  }

  const toggleInfoModal = () => {
    setShowInfo(prev => !prev)
  }

  const toggleTermsModal = () => {
    setShowTermsModal(!showTermsModal)
  }

  const onShare = async () => {
    if (Platform.OS === 'web') {
      navigator.clipboard.writeText(code)
    }
    //   const shareOptions = {
    //     message: ContentManager.translate(`my_code_${lang}`, [code]),
    //     url: 'https://onelink.to/myvodafone',
    //   }
    //   await Share.open(shareOptions)
    // }

    // if (showSuccessfulReferral) {
    //   return (
    //     <SuccessfulReferral
    //       onClose={() => setShowSuccessfulReferral(false)}
    //       navigateToDashboard={onClose}
    //     />
    //   )
  }

  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
    return (
      <VFScreen withHeader={false}>
        <VFImageHeader
          image={Images.recommend_header}
          title="recommend_title"
          onClose={onClose}
          showBack
          leftIcon={Images.recommend_info}
          rightIcon={Images.close_white}
          onBack={toggleInfoModal}
          titleStyle={styles.headerTitle}
        />
        {error ? <Error theme={theme} error={error} getData={getContentData} /> :
          <View style={[styles.container, { overflow: 'scroll' }]}>
            <View style={[styles.container, { paddingHorizontal: '20%' }]}>
              <View style={styles.referContainer(theme)}>
                {dataLoading ? <Shimmer config={shimmerConfig1} /> : <VFText
                  i18nKey={title}
                  style={styles.subtitle}
                />}

                {dataLoading ? <Shimmer config={shimmerConfig1} /> : <VFText
                  i18nKey={description}
                  style={styles.description}
                />}


                <View style={styles.codeContainer(theme)}>
                  {dataLoading ? <Shimmer config={shimmerConfig1} /> : <VFText
                    i18nKey={code}
                    style={styles.codeText}
                  />}
                </View>

                <VFButton
                  title="recommend_share_code"
                  style={styles.shareButton}
                  onPress={onShare}
                />

                <TouchableOpacity onPress={toggleTermsModal}>
                  <VFText
                    i18nKey="recommend_read_terms"
                    style={styles.readTermsText}
                  />
                </TouchableOpacity>

              </View>

              <View style={styles.referalsContainer}>
                {historyLoading ? <Shimmer config={shimmerConfig1} /> : <View style={styles.referalsHeader(theme)}>
                  <VFText
                    i18nKey="recommend_successful_referrals"
                    placeHolders={[referrals.length === 0 ? '0' : totalReferals]}
                    style={styles.successfulReferalsText}
                  />
                </View>}

                {referrals.length === 0 && <View>
                  <VFText
                    i18nKey="recommend_no_referrals"
                    style={styles.description}
                  />
                </View>}

              </View>
              <Card style={styles.cardContainer(theme)}>
                {historyLoading ? <CardLoading theme={theme} /> : <FlatList
                  data={referrals ? referrals : []}
                  keyExtractor={item => item.number}
                  renderItem={({ item, index }) => {
                    return (
                      <View style={styles.referalItemContainer(index !== (referrals.length - 1), theme)}>
                        <View style={styles.referalTopContainer}>
                          <VFText
                            i18nKey={item?.number}
                            style={styles.referalItemNumber(theme)}
                          />
                          {/* <VFText
                          i18nKey="3GB"
                          style={styles.referalItemGift(theme)}
                        /> */}
                        </View>
                        <VFText
                          i18nKey="recommend_referral_item_joined_date"
                          placeHolders={[item?.date]}
                          style={styles.referalItemJoinedDate(theme)}
                        />
                      </View>
                    )
                  }}
                />}
              </Card>

            </View>

          </View>}
        <TermsModal
          isVisible={showTermsModal}
          toggleModal={toggleTermsModal}
        />
        <Info
          isVisible={showInfo}
          toggleModal={toggleInfoModal}
        />
      </VFScreen>
    )
  }

  return (
    <VFScreen withHeader={false}>
      <VFImageHeader
        image={Images.recommend_header}
        title="recommend_title"
        onClose={onClose}
        showBack
        leftIcon={Images.recommend_info}
        rightIcon={Images.close_white}
        onBack={toggleInfoModal}
        titleStyle={styles.headerTitle}
      />
      {error ? <Error theme={theme} error={error} getData={getContentData} /> : <View style={styles.container}>
        <View style={styles.container}>
          <View style={styles.referContainer(theme)}>
            {dataLoading ? <Shimmer config={shimmerConfig1} /> : <VFText
              i18nKey={title}
              style={styles.subtitle}
            />}

            {dataLoading ? <Shimmer config={shimmerConfig1} /> : <VFText
              i18nKey={description}
              style={styles.description}
            />}


            <View style={styles.codeContainer(theme)}>
              {dataLoading ? <Shimmer config={shimmerConfig1} /> : <VFText
                i18nKey={code}
                style={styles.codeText}
              />}
            </View>

            <VFButton
              title="recommend_share_code"
              style={styles.shareButton}
              onPress={onShare}
            />

            <TouchableOpacity onPress={toggleTermsModal}>
              <VFText
                i18nKey="recommend_read_terms"
                style={styles.readTermsText}
              />
            </TouchableOpacity>

          </View>

          <View style={styles.referalsContainer}>
            {historyLoading ? <Shimmer config={shimmerConfig1} /> : <View style={styles.referalsHeader(theme)}>
              <VFText
                i18nKey="recommend_successful_referrals"
                placeHolders={[referrals.length === 0 ? '0' : totalReferals]}
                style={styles.successfulReferalsText}
              />
            </View>}

            {referrals.length === 0 && <View>
              <VFText
                i18nKey="recommend_no_referrals"
                style={styles.description}
              />
            </View>}

          </View>
          <Card style={styles.cardContainer(theme)}>
            {historyLoading ? <CardLoading theme={theme} /> : <FlatList
              data={referrals ? referrals : []}
              keyExtractor={item => item.number}
              renderItem={({ item, index }) => {
                return (
                  <View style={styles.referalItemContainer(index !== (referrals.length - 1), theme)}>
                    <View style={styles.referalTopContainer}>
                      <VFText
                        i18nKey={item?.number}
                        style={styles.referalItemNumber(theme)}
                      />
                      {/* <VFText
                        i18nKey="3GB"
                        style={styles.referalItemGift(theme)}
                      /> */}
                    </View>
                    <VFText
                      i18nKey="recommend_referral_item_joined_date"
                      placeHolders={[item?.date]}
                      style={styles.referalItemJoinedDate(theme)}
                    />
                  </View>
                )
              }}
            />}
          </Card>

        </View>

      </View>}
      <TermsModal
        isVisible={showTermsModal}
        toggleModal={toggleTermsModal}
      />
      <Info
        isVisible={showInfo}
        toggleModal={toggleInfoModal}
      />
    </VFScreen>
  )
}

Recommend.propTypes = {
  navigation: PropTypes.object,
}

CardLoading.propTypes = {
  theme: PropTypes.object
}

Error.propTypes = {
  theme: PropTypes.object
}

export default Recommend
