import {
  GetDailyCatalogue,
} from '../../Services/Promo'


const actionTypes = {
  REQUEST_PRIZES_CATALOGUE: 'REQUEST_PRIZES_CATALOGUE',
  SUCCESS_PRIZES_CATALOGUE: 'SUCCESS_PRIZES_CATALOGUE',
  ERROR_PRIZES_CATALOGUE: 'ERROR_PRIZES_CATALOGUE',
}

const errorMapperMessage = (error) => {
  const payload = error?.response?.data || {
    message: 'Diçka shkoi gabim',
  }
  return payload
}

const getProductsResponseMapper = (response) => {
  const { recommendationItem } = (response.data?.[0] || {})
  const product = recommendationItem?.[0]?.product
  const items = []
  const { product: elements, description, id: totalOfTickets } = product || {}
  elements.map(el => {
    let price = {}
    const {
      id,
      href,
      name,
      productPrice,
      status,
    } = el
    price = productPrice?.[0]?.price?.dutyFreeAmount
    items.push({ id, href, name, price, status })
  })

  const cardData = (response.data?.[1] || {})
  const cardElements = cardData?.recommendationItem?.[0]?.product?.product

  const cardItems = []

  cardElements.map(item => {
    const years = item.productCharacteristic.find(el => el.id === 'years').name
    const multiplication = item.productCharacteristic.find(el => el.id === 'multiplication').name

    cardItems.push({ years, multiplication })
  })


  return { items, description, totalOfTickets, cardItems }
}

const getProducts = (type, utilities = {}) => {
  return async (dispatch, getState) => {

    dispatch({
      type: actionTypes.REQUEST_PRIZES_CATALOGUE,
    })

    const { configurations } = getState()

    const showPromoHeader = configurations?.config?.promo_project_header

    let headers = {
      'vf-operator': 'promo',
      'vf-source': 'selfcare',
    }

    if (showPromoHeader === 'true') {
      headers = {
        'vf-operator': 'promo',
        'vf-source': 'selfcare',
        'vf-project': 'promo',
      }
    }

    GetDailyCatalogue(type, headers, {
      ...utilities,
      responseMapper: getProductsResponseMapper,
      errorMapper: errorMapperMessage,
    }).then(response => dispatch({
      type: actionTypes.SUCCESS_PRIZES_CATALOGUE,
      payload: response,
    })
    ).catch(error => dispatch({
      type: actionTypes.ERROR_PRIZES_CATALOGUE,
      payload: error,
    })
    )
  }
}

const actions = {
  getProducts,
}

export {
  actionTypes,
  actions,
}

