import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TouchableOpacity, View, Dimensions } from 'react-native'

import { Icon, VFText } from '@vfgroup-oneplatform/foundation/Components'

import { useTheme, getThemeImages } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { SearchInputContact } from '../../../Group/Components/SearchInput'

import SubscriptionItem from './SubscriptionItem'

import styles from './SubscriptionList.Styles'

const { height: SCREEN_HEIGHT } = Dimensions.get('screen')


const NO_OF_PAGES = 10

const SubscriptionList = ({
  data,
  isLoading,
  scrollRef,
  offset,
  unfilteredData,
  isAdminView,
  selectedCategories,
}) => {

  const theme = useTheme()
  const themeImages = getThemeImages(theme.name)

  const [searchedText, setSearchedText] = useState('')

  const [filteredData, setFilteredData] = useState(data)
  const [openIndex, setOpenIndex] = useState(undefined)

  const [page, setPage] = useState(0)

  const inputRef = useRef()

  const scrollTo = () => {
    scrollRef?.current?.scrollTo({ x: 0, y: offset, animated: true })
  }

  useEffect(() => {
    if (!searchedText) {
      setFilteredData(data)
    } else {
      setFilteredData(data?.filter(el => {
        if (el?.headlineProps.title?.includes(searchedText) || el?.headlineProps?.id?.includes(searchedText)) {
          return el
        } else {
          return null
        }
      }))
    }
  }, [searchedText, data])


  const renderPages = () => {
    if (filteredData?.length <= NO_OF_PAGES) {
      return null
    }
    return (
      <View style={styles.pageContainer}>
        {page !== 0 && <TouchableOpacity
          onPress={() => {
            setPage(prev => prev - 1)
            scrollRef?.current?.scrollTo({ x: 0, y: offset, animated: true })
          }}
          style={styles.pageButton}
        >
          <Icon name={themeImages.ic_chevron_right_red} style={{ transform: [{ rotate: '180deg' }] }} size={22} />
          <VFText i18nKey="soho_usage_users_prev" style={styles.pageText(theme)} />
        </TouchableOpacity>}
        {(page + 1) * NO_OF_PAGES < filteredData?.length && <TouchableOpacity
          onPress={() => {
            setPage(prev => prev + 1)
            scrollRef?.current?.scrollTo({ x: 0, y: offset, animated: true })
          }}
          style={styles.pageButton}
        >
          <VFText i18nKey="soho_usage_users_next" style={styles.pageText(theme)} />
          <Icon name={themeImages.ic_chevron_right_red} size={22} />
        </TouchableOpacity >}
      </View >
    )
  }

  return (
    <View style={{ minHeight: SCREEN_HEIGHT - offset + 100 }}>
      {!isLoading && data?.length > 1 && isAdminView && selectedCategories === 'employee' && <SearchInputContact
        inputRef={inputRef}
        value={searchedText}
        onChangeText={setSearchedText}
        label="soho_monthly_billling_search_placeholder"
        onFocus={scrollTo}
        onSelectContact={setSearchedText}
      />}
      {filteredData?.length === 0 ? (<>
        {isAdminView && <VFText
          i18nKey="soho_monthly_billing_no_users"
          style={styles.emptyText}
        />}
      </>) :
        filteredData?.slice(page * NO_OF_PAGES, (page + 1) * NO_OF_PAGES)
          .map(subscriptionItem => <SubscriptionItem
            key={subscriptionItem?.id}
            {...subscriptionItem}
            isLoading={isLoading}
            openIndex={openIndex}
            setOpenIndex={setOpenIndex}
            unfilteredData={unfilteredData}
            isAdminView={isAdminView}
          />)
      }
      {!isLoading && renderPages()}
    </View>
  )
}
SubscriptionList.defaultProps = {
  offset: 400
}
SubscriptionList.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  scrollRef: PropTypes.object,
  offset: PropTypes.number,
  unfilteredData: PropTypes.array,
  isAdminView: PropTypes.bool,
  selectedCategories: PropTypes.string
}

export default SubscriptionList
