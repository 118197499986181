import { useState, useEffect, useContext, useRef } from 'react'
import { Dimensions, Platform } from 'react-native'

import { setLoadingConfigObject } from '@vfgroup-oneplatform/foundation/Components/Shimmer/Shimmer.utils'

import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager/ContentManager'
import moment from 'moment'

import { useNavigation, useRoute } from '@react-navigation/native'

import { cloneDeep as _cloneDeep, isUndefined } from 'lodash'

import { useDispatch, useSelector } from 'react-redux'


import MainComponent from '../../../Group/Components/Dashboard/MainComponent'
import SecondaryComponent from '../../../Group/Components/Dashboard/SecondaryComponent/SecondaryComponent'
import ThirdComponent from '../../../Group/Components/Dashboard/ThirdComponent/ThirdComponent'
import FourthComponent from '../../../Group/Components/Dashboard/FourthComponent/FourthComponent'

import DiscoverCarousel from '../../../Group/Components/DiscoverCarousel'
import VodafoneTV from '../../../Group/Components/VideoPlayer/VideoPlayer'
import EditorialCard from '../../../Group/Components/EditorialCard'

import { isShownToday, setShownToday, getMenu, isAdminView, isBusinessChooser } from '../../../Utils'

import TobiSupportContext from '../../../Utils/TobiSupportContext'

import { Images as groupImages } from '../../../Group/Themes'


import { Actions } from '../Dashboard.Actions'
import ReduxCache from '../../../Utils/ReduxCache'


const { width: SCREEN_WIDTH } = Dimensions.get('screen')

const components = {
  MainCardComponent: MainComponent,
  SecondaryCardComponent: SecondaryComponent,
  ThirdCardComponent: ThirdComponent,
  FourthCardComponent: FourthComponent,
  DiscoverCarousel: DiscoverCarousel,
  VodafoneTV: VodafoneTV,
  EditorialCard: EditorialCard,
}

const dashboardLoadingConfig = {
  mainCardsLoadingConfig: [
    {
      left: setLoadingConfigObject('circle', 1, 10, { marginTop: 10 }),
      main: setLoadingConfigObject('line', 1, 10, { marginTop: 10 }),
      right: setLoadingConfigObject('line', 1, 10, { marginTop: 10 }),
      style: { marginTop: 10 }
    }
  ],
  secCardsLoadingConfig: [
    {
      left: setLoadingConfigObject('circle', 1, 10, { marginTop: 10 }),
      main: null,
      right: setLoadingConfigObject('line', 1, 10, { marginTop: 10 }),
      style: { marginTop: 10 }
    }
  ],
  adCardsLoadingConfig: [
    {
      left: setLoadingConfigObject('circle', 1, 10, { marginTop: 10 }),
      main: null,
      right: setLoadingConfigObject('line', 1, 10, { marginTop: 10 }),
      style: { marginTop: 10 }
    }
  ]
}

const discoverCardLoadingConfig = [
  {
    left: setLoadingConfigObject('square', 1, null, { width: '95%', height: SCREEN_WIDTH / 2, borderRadius: 12 }),
    right: setLoadingConfigObject('square', 1, null, { width: '100%', height: SCREEN_WIDTH / 2, borderRadius: 12 }),
  },
  {
    left: setLoadingConfigObject('square', 1, null, { width: '95%', height: SCREEN_WIDTH / 2, borderRadius: 12 }),
    right: setLoadingConfigObject('square', 1, null, { width: '100%', height: SCREEN_WIDTH / 2, borderRadius: 12 }),
    style: { marginTop: 20 }
  }
]

const lastUpdatedLoadingConfig = [{
  main: null,
  left: setLoadingConfigObject('line', 1, 50, { marginTop: 10 }),
  right: null
}]

const menuMapping = {
  'myoffers': 'Catalogue',
  'shake': 'Shake',
  'redBoxWebview': 'VfHearts',
  //  'netPerformSpeedtest': 'SpeedChecker',
  'reward': 'Reward',
  'billing': 'Billing',
  'recharge_my': 'Payments',
  'recharge_other': 'Payments',
  'GigaFamily': 'GigaFamily',
  'payment': 'PaymentMethods',
  'migration': 'Migration',
  'refill': 'Refill',
  'promo_live': 'Promo',
  'promo': 'Promo',
  'fix_renewal': 'FixBundles',
  'callMeBack': 'CallMeBack',
  // 'netPerformSpeedtest': 'SpeedChecker',
  'myWeekend': 'VDayOffers',
  'fix_bill': 'Billing',
  'fixProductsAndServices': 'FixProductsAndServices',
  'affiliate': 'Recommend',
  'digital_acquisition': 'DigitalAcquisition',
  'marketplace': 'Marketplace',
  'appointment': 'Appointments',
  'settings': 'Settings',
  'bundle': 'Catalogue',
  'recommendedoffers': 'RecommendedBundles'
}

const monthTranslations = {
  al: {
    'January': 'Janar',
    'February': 'Shkurt',
    'March': 'Mars',
    'April': 'Prill',
    'May': 'Maj',
    'June': 'Qershor',
    'July': 'Korrik',
    'August': 'Gusht',
    'September': 'Shtator',
    'October': 'Tetor',
    'November': 'Nëntor',
    'December': 'Dhjetor',
    'Jan': 'Jan',
    'Feb': 'Shku',
    'Mar': 'Mar',
    'Apr': 'Pri',
    'Jun': 'Qer',
    'Jul': 'Korr',
    'Aug': 'Gush',
    'Sep': 'Shta',
    'Oct': 'Tet',
    'Nov': 'Nën',
    'Dec': 'Dhje',
    'Janar': 'Janarit',
    'Shkurt': 'Shkurtit',
    'Mars': 'Marsit',
    'Prill': 'Prillit',
    'Maj': 'Majit',
    'Qershor': 'Qershorit',
    'Korrik': 'Korrikut',
    'Gusht': 'Gushtit',
    'Shtator': 'Shtatorit',
    'Tetor': 'Tetorit',
    'Nëntor': 'Nëntorit',
    'Dhjetor': 'Dhjetorit'
  },
  en: {
    'January': 'January',
    'February': 'February',
    'March': 'March',
    'April': 'April',
    'May': 'May',
    'June': 'June',
    'July': 'July',
    'August': 'August',
    'September': 'September',
    'October': 'October',
    'November': 'November',
    'December': 'December',
    'Jan': 'Jan',
    'Feb': 'Feb',
    'Mar': 'Mar',
    'Apr': 'Apr',
    'Jun': 'Jun',
    'Jul': 'Jul',
    'Aug': 'Aug',
    'Sep': 'Sep',
    'Oct': 'Oct',
    'Nov': 'Nov',
    'Dec': 'Dec'
  }
}

const guestAllowScreens = {
  'netPerformSpeedtest': 'SpeedChecker',
  'dashboardGuest': 'PreLogin'
}

function useDashboardMenu({
  userName,
  setShowPayments,
  setShowRefill,
  setShowDataShare,
  setIsThirdParty,
  userBalance,
  setUserBalance,
  setIsGuestModa
}) {


  const [isLoading, setIsLoading] = useState(true)
  const [showLogoutConfirm, setShowLogoutModal] = useState(false)

  const [error, setError] = useState(false)
  const [showDiscoverModal, setShowDiscoverModal] = useState(false)
  const [discoverModalData, setDiscoverModalData] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [tobiURL, setTobiURL] = useState(false)
  const [tobiIcon, setTobiIcon] = useState('')
  const [activeDiscovers, setActiveDiscovers] = useState({})
  const [isEligibleRefill, setIsEligibleRefill] = useState(false)

  const [shouldShowFix, showFixedLine] = useState(false)
  const [showFixManualProduct, setShowFixManualProduct] = useState(false)


  const balanceReduxData = useSelector(store => store.balance)
  const configurationsRedux = useSelector(store => store.configurations)
  const authRedux = useSelector(store => store.auth)
  const pegaRedux = useSelector(store => store.pega)

  const userTarifPlan = authRedux.tariffPlan
  const userType = ReduxCache.getUserType()
  const msisdn = ReduxCache.getMsisdn()
  const language = useSelector(state => state.language)

  const [gigaFibraCount, setGigaFibraCount] = useState(0)
  const [vodafoneTvText, setVodafoneTvText] = useState(false)

  const { navigate } = useNavigation()
  const route = useRoute()
  const dispatch = useDispatch()

  const screen_key = route?.params?.screen_key || ''

  const [storiesProps, setStoriesProps] = useState({})


  const [accountSwitchVisible, setAccountSwitchVisible] = useState(false)

  const toggleAccountSwitch = () => setAccountSwitchVisible(prev => !prev)

  const businessAccount = ReduxCache.getBusinessAccount()

  // For Non Vodafone Users
  const [nonVfSecondTile, setNonVfSecondTile] = useState()
  const [guestSecondTile, setGuestSecondTile] = useState()

  const [myAccountMenu, setMyAccountMenu] = useState({
    'title': '',
    'icon': ' ',
    'key': 'my_account',
    'subTray': {
      'subTrayTitle': 'Llogaria ime',
      'subTrayID': 'account',
      'subTrayDesc': 'sub_tray_select_one',
      'subTrayItems': []
    },
    'trayAction': 'myAccountTrayAction.class'
  })

  const [myProductsMenu, setMyProductsMenu] = useState({
    'title': '',
    'key': 'my_products',
    'icon': ' ',
    'subTray': {
      'subTrayTitle': 'Produktet e mia',
      'subTrayID': 'products',
      'subTrayDesc': 'sub_tray_select_one',
      'subTrayItems': []
    },
    'trayAction': 'productsTrayAction'
  })

  const [balance, setBalance] = useState({
    'title': 'dashboard_title',
    'dashboardItems': []
  })

  const [secondaryCards, setSecondaryCards] = useState([])

  const [discover, setDiscover] = useState({
    'title': '',
    'componentName': 'DashboardDiscovery',
    'discoveryItems': []
  })

  const [trayOptionalItems, setTrayOptionalItems] = useState([])

  const sideBar = useRef([
    {
      key: 'Dashboard',
      name: 'dashboard_title',
      screens: ['Dashboard']
    },
    {
      key: 'ProductsAndServices',
      name: 'sub_tray_my_products_title',
      screens: ['ProductsAndServices']
    },
    {
      key: 'Billing',
      name: 'dashboard_billing_card_title',
      screens: ['Billing', 'MonthlyBilling', 'FixBilling']
    },
    {
      key: 'Catalogue',
      name: 'catalogue_title_screen',
      screens: ['Catalogue', 'CatalogueDetails']
    },
  ])

  //fix components
  const toggleFix = () => showFixedLine(prev => !prev)
  const toggleManualLine = () => setShowFixManualProduct(prev => !prev)

  const toggleDicoveryModal = () => setShowDiscoverModal(prev => !prev)

  const tobiContext = useContext(TobiSupportContext)

  const openTobiUrl = (dashboardRef) => {
    const menu = getMenu(configurationsRedux)
    const showSupportModal = configurationsRedux?.[menu]?.find(el => el.key.includes('itemsSupportDashboard'))
    if (showSupportModal) {
      dashboardRef?.current?.scrollToOffset(0, true)
      tobiContext?.openTobiModal()
    } else if (tobiURL) {
      navigate('WebView', { url: tobiURL, isReact: true, incognito: true })
    }
  }


  const openTobiModal = () => tobiContext?.openTobiModal()

  const toggleRefill = () => setShowRefill(prev => !prev)


  useEffect(() => {
    if (screen_key && !isLoading && !balanceReduxData.loading) {
      navigateToNative(screen_key)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, isLoading, balanceReduxData.loading])


  useEffect(() => {
    moment.locale('en')
    if (!msisdn) {
      return
    }
    if (!balanceReduxData.loading && !balanceReduxData.data && !balanceReduxData.error) {
      dispatch(Actions.getUserBalance(isAdminView()))
    }

    if (!balanceReduxData.loading && balanceReduxData.data) {
      onBalanceReceived(balanceReduxData.data)
    }

    if (!balanceReduxData.loading && balanceReduxData?.error) {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceReduxData, msisdn, businessAccount?.showBusinessView])

  useEffect(() => {
    if (!pegaRedux.loading && pegaRedux.data && pegaRedux.data.categories) {
      const categories = pegaRedux.data.categories

      if (categories.includes('DM')) {
        setIsEligibleRefill(true)
      }

      if (categories.includes('Renewal')) {

        setActiveDiscovers(prevState => ({
          ...prevState,
          'migration_renewal': true
        }))

        onMigrationPopUpReceived('migration_renewal')
      }

      if (categories.includes('P2H')) {

        setActiveDiscovers(prevState => ({
          ...prevState,
          'migration_p2h': true
        }))

        onMigrationPopUpReceived('migration_p2h')
      }

      if (categories.includes('Fix')) {

        setActiveDiscovers(prevState => ({
          ...prevState,
          'fix_renewal': true
        }))
      }

      if (categories.includes('Affiliate')) {
        setActiveDiscovers(prevState => ({
          ...prevState,
          'affiliate': true
        }))
      }

    }
  }, [pegaRedux])

  useEffect(() => {
    const menu = getMenu(configurationsRedux)
    if (configurationsRedux && balanceReduxData.data && menu) {
      onMenuReceived(menu)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurationsRedux, balanceReduxData, userType, businessAccount, getMenu])

  const onMyWeekendReceived = async () => {
    setActiveDiscovers({
      ...activeDiscovers,
      'myWeekend': true
    })

    const isVdayShown = await isShownToday('vdayShown')
    if (!isVdayShown) {
      onMyWeekendPopUpReceived()
    }
  }

  const onMyWeekendPopUpReceived = () => {
    setTimeout(() => {
      setDiscover(oldDiscover => {
        oldDiscover.discoveryItems[0]?.bucket?.map(discoverItem => {
          const key = discoverItem.extraInfo.key
          if (key === 'myWeekend') {
            setShowDiscoverModal(true)
            setDiscoverModalData(discoverItem.extraInfo)
            setShownToday('vdayShown')
          }
        })

        return oldDiscover
      })

    }, 2000)
  }

  const onMigrationPopUpReceived = async (type = '') => {

    const isMigrationShownToday = await isShownToday('migration')

    if (isMigrationShownToday) {
      return
    }

    setTimeout(() => {
      setDiscover(oldDiscover => {
        oldDiscover.discoveryItems[0]?.bucket?.map(discoverItem => {
          const key = discoverItem.extraInfo.key
          if (key === type) {
            setShowDiscoverModal(true)
            setDiscoverModalData(discoverItem.extraInfo)
            setShownToday('migration')
          }
        })

        return oldDiscover
      })

    }, 2000)
  }


  const mapBalanceData = (data) => {
    if (!data || handleError(data)) {
      return false
    }

    setGigaFibraCount(0)

    const order = ['business', 'mobile', 'data', 'voice', 'sms', 'money', 'modem', 'internet', 'cabletv', 'smartcard', 'fix_item_product']

    const mobileProductsOrder = ['data', 'voice', 'sms']

    let hasActiveBundle = true

    if (data.activatebundle) {
      hasActiveBundle = false
    }

    const items = []
    const mobileData = []
    try {
      for (const key in data) {
        const item = data[key]
        const type = item.type.$.toLowerCase()

        if ((['data', 'sms', 'voice', 'promo', 'nonvod_item_product', 'fix_item_product', 'balance', 'businessplan'].indexOf(type) === -1) && !type.includes('fix_item_usage') && !type.includes('nonvod_item_usage')) {
          continue
        }

        const value = parseFloat(item.details.allowance['available-allowance'].$)
        const units = item.details.allowance['available-allowance']['@currency-id']
        const valueName = value + ' ' + units

        let bucket = {}

        if (type === 'promo' && !isAdminView()) {
          const promoStatus = item.status.$ === 'active' ? 1 : 0

          const endDate = item['validity-period']['to-date'].date
          const dateFormat = item['validity-period']['to-date']['@format']?.toUpperCase()

          bucket = {
            'isShared': false,
            'hasActiveBundle': true,
            'onPressCard': () => navigateToNative('promo_live'),
            'usageType': 'promo',
            'itemTitle': item.name, //custom only for promo
            'isActive': promoStatus, //custom only for promo (used to decide discover card and tile)
            'remainingTime': {
              'date': endDate,
              'format': dateFormat
            },
            'remainingValue': {
              'amount': value,
              'units': 'dashboard_tickets'
            },
            'value': {
              'amount': parseFloat(item.details.amount.$)
            },
            'valueName': '',
            'footerText': 'dashboard_get_your_ticket'
            // 'isRoaming': true
          }
        } else if (type === 'fix_item_usage' || type.includes('nonvod_item_usage')) {
          bucket = {
            'isShared': false,
            'hasActiveBundle': true,
            'onPressCard': () => { navigateToNative('FixProductsAndServices') },
            'usageType': item?.categories?.[0]?.['@list-name'].toLowerCase(),
            'fixOfferName': item?.name,
            'type': 'fix',
            'status': item?.status?.$,
            'address': item?.desc,
          }
        } else if (type === 'fix_item_product' || type === 'nonvod_item_product') {
          // For My products tiles
          if (item.categories[0].$ === 'Internet') {
            setGigaFibraCount(prev => prev + 1)
          } else if (item.categories[0].$ === 'Vodafone TV') {
            const vodafoneTv = item?.name
            setVodafoneTvText(vodafoneTv)
          }
          continue
        } else if ((userType === 'hybrid' || userType === 'hybrid_fix') && type === 'balance' && !isAdminView()) {
          // for hybrid userType there is a tile with balance amount

          bucket = {
            'isShared': false,
            'hasActiveBundle': true,
            'usageType': 'money',
            'remainingValue': {
              'amount': value,
              'units': units
            },
            'value': {
              'amount': ''
            },
            'valueName': '',
            // 'isRoaming': true,
            onPressCard: () => navigate('ProductsAndServices'),
          }
        }
        else if ((['data', 'voice', 'sms'].indexOf(type) > -1) && !isAdminView()) {
          if (hasActiveBundle) {

            const mobileItem = {
              'usageType': item.name,
              'remainingValue': {
                'amount': value === -1 ? 'unlimited_bar_' + item.name : value,
                'units': value === -1 ? '' : units
              },
              'value': {
                'amount': parseFloat(item.details.amount.$)
              },
              'valueName': value === -1 ? 'unlimited_bar_' + item.name : valueName,
              'hasActiveBundle': hasActiveBundle
            }

            try {
              if (item.name === 'data' && value !== -1 && item.desc?.length > 0) {
                mobileItem.showGetMoreData = true
                mobileItem.onGetMoreDataPress = () => navigateToNative('myoffers')
              }
            } catch (err) {
              console.log('error for 80%', err)
            }

            mobileData.push(mobileItem)
          } else {
            bucket = {
              'isShared': false,
              'hasActiveBundle': false,
              'onPressCard': () => navigate('ProductsAndServices'),
              'usageType': item.name,
            }
          }
        }
        else if (type === 'businessplan' && isAdminView()) {
          bucket = {
            'isShared': false,
            'hasActiveBundle': true,
            'onPress': () => navigate('BusinessOverview'),
            'usageType': 'business',
            'planName': item?.desc || '',
            'planDetails': {
              'users': item?.details?.amount?.$,
              'validityPeriod': item?.['validity-period'],
            }
          }
        }


        if (type === 'promo') {
          bucket.footerText = 'dashboard_get_your_ticket'
        }

        if (Object.keys(bucket).length !== 0) {
          items.push(bucket)
        }

      }
      if (mobileData.length > 0) {
        mobileData.sort((a, b) => {
          if (mobileProductsOrder.indexOf(a.usageType) < mobileProductsOrder.indexOf(b.usageType)) {
            return -1
          }
          return 1
        })

        if (mobileData.length !== 0) {
          items.push({
            'onPressCard': () => navigate('ProductsAndServices'),
            'usageType': 'mobile',
            'hasActiveBundle': true,
            'extraData': mobileData
          })
        }
      }

    } catch (err) {
      console.log(err)
    }
    if (items.length > 0) {
      items.sort((a, b) => {
        if (order.indexOf(a.usageType) < order.indexOf(b.usageType)) {
          return -1
        }
        return 1
      })

      return items
    }
  }

  const handleDiscoverCards = (data) => {

    const menus = {
      'type': 'discover_fixed_component',
      'name': 'dashboard_upgrade_component_name',
      'componentId': 'DiscoverCarousel',
      'bucket': []
    }

    data.subItems?.map(discoverItem => {


      // If the dicovery item is BigBrother skip
      if (discoverItem.key === 'entertainment') {
        return
      }
      let desc = ''
      let button = ''
      let cardBackground = ''
      let modalIcon = ''
      let type = ''
      let secondCardBackground = ''
      try {
        button = discoverItem.text.split('|')[0]
        desc = discoverItem.text.split('|')[1]
        cardBackground = discoverItem.text.split('|')[2] || ''
        secondCardBackground = discoverItem.text.split('|')?.[3] || ''
        type = discoverItem.type

        modalIcon = discoverItem.text.split('|')[3] || ''
      } catch (err) {
        console.log('error discoverItem', err)
      }

      menus.bucket.push({
        'extraInfo': {
          'title': discoverItem.value,
          'key': discoverItem.key,
          'subTitle': desc,
          'description': desc,
          'buttonText': button,
          'onPress': (item) => {
            if (type?.includes('webview')) {
              navigate('WebView', { url: item.key, title: item.description, isReact: false })
            } else {
              navigateToNative(item.key)
            }
          },
          'icon': cardBackground || 'https://api-web-ci-vfal.vodafone.com/file/mva/fatura_banner_big.png',
          secondIcon:
            secondCardBackground ||
            'https://api-web-ci-vfal.vodafone.com/file/mva/fatura_banner_big.png',
          'modalIcon': modalIcon,
        }
      })
    })


    return menus
  }

  const generateMenuData = (data) => {
    const offersAndRewards = {
      key: 'Offers & Rewards',
      name: 'dashboard_offers_rewards_group_component_name',
      items: []
    }

    const basicAndSos = {
      key: 'Basic Services',
      name: 'basic_section_title',
      items: []
    }

    const assistance = {
      key: 'Assistance',
      name: 'Assistance',
      items: []
    }

    const current = {
      'title': 'dashboard_discovery_label',
      'componentName': 'DashboardDiscovery',
      'discoveryItems': []
    }

    data = data.sort((a, b) => {
      if (!a.order) {
        return -1
      }

      if (parseInt(a.order, 10) < parseInt(b.order, 10)) {
        return -1
      }
      return 1
    })

    data.map(item => {
      return item.subItems?.sort((a, b) => {
        if (!a.order) {
          return -1
        }

        if (parseInt(a.order, 10) < parseInt(b.order, 10)) {
          return -1
        }
        return 1
      })
    })

    let videoCard = null
    const menus = []
    const CURRENT_OS = Platform.OS === 'ios' ? 'iOS' : 'Android'
    const OTHER_OS = Platform.OS === 'ios' ? 'Android' : 'iOS'

    data.map(item => {

      // For Non Vodafone Users
      if (item.key === 'carouselItems') {

        const elements = []
        item.subItems.map(element => {

          const text = element.text.split('|')
          let onPress = null

          if (element.type === 'react') {
            onPress = () => navigateToNative(element.screen)
          } else if (element.type === 'webview') {
            onPress = () => navigate('WebView', { url: element.screen, title: text[0], isReact: true })
          }

          const bucket = {
            'usageType': 'non_vf',
            'title': text[0],
            'subTitle': text[1],
            'background': text[2],
            'img': text[3],
            'onPressCard': onPress,
            'hasActiveBundle': true
          }

          elements.push(bucket)
        })


        setBalance(prev => ({
          ...prev,
          dashboardItems: {
            ...prev.dashboardItems,
            0: {
              ...prev.dashboardItems[0],
              'name': 'MainCardComponent',
              'componentId': 'MainCardComponent',
              extraInfo: {
                ...prev.dashboardItems[0]?.extraInfo,
                bucket: [...prev.dashboardItems[0]?.extraInfo?.bucket || [], ...elements]
              }
            },
          }
        }))

        return
      }

      // For Non Vodafone and Guest Users
      if (item.key === 'secondTileItem') {

        const text = item.text.split('|')

        setNonVfSecondTile({
          title: item.value,
          subTitle: text[0],
          icon: text[1],
          type: item.type,
          background: text[2],
          onPress: () => navigate('WebView', { url: item.screen, title: item.value, isReact: true })
        })

        setGuestSecondTile({
          title: item.value,
          subTitle: text[0],
          icon: text[1],
          type: item.type,
          background: text[2],
          onPress: () => navigate('WebView', { url: item.screen, title: item.value, isReact: true })
        })

        return
      }

      if (item.key === 'itemsDiscover') {
        handleSecondaryCards(item)
        return
      }

      if (item.key === 'itemsAccount' || item.key === 'itemsProducts') {
        handleTrayMenu(item, item.key)
        return
      }

      if (item.key === 'itemsTrayOptionalItems') {
        handleTrayOptionalItems(item)
        return
      }

      if (item.key === 'itemsDiscoverVideo') {
        videoCard = item.subItems[0]

        menus.push({
          'type': 'generic',
          'name': 'Vodafone TV',
          'componentId': 'VodafoneTV',
          'video': videoCard.text?.split('|')[0],
          'poster': videoCard.text?.split('|')[1],
          'header': videoCard.screen,
          'text': videoCard.value,
        })

        return
      }

      if (item.key === 'itemsTobi') {
        if (item.text.split('|')[1]) {
          setTobiURL(item.text.split('|')[0])
          setTobiIcon(item.text.split('|')[1])
        }
        else {
          setTobiURL(item.text)
        }
        return
      }

      if (item.key === 'itemsDiscoverCard') {
        const discoverData = handleDiscoverCards(item)
        menus.unshift(discoverData)
        return
      }

      if (item.key === 'itemsSecondaryDiscoverCard') {
        const discoverData = handleDiscoverCards(item)
        menus.push(discoverData)
        return
      }

      //other application

      if (item.key === 'otherApps') {

        const otherApps = {
          'type': 'apps',
          'name': item.value,
          'subItems': []
        }

        item?.subItems.map(subItem => {
          const name = subItem?.value || ''
          const iosAppId = subItem?.screen.split('|')?.[1]
          const androidAppId = subItem?.screen.split('|')?.[2]
          const appSchema = subItem?.screen.split('|')?.[0]
          const icon = subItem?.text

          // groupImages[subItem?.key] = { 'uri': icon }
          const extraInfo = {
            title: subItem?.value || '',
            icon: subItem?.key,
            appSchema: appSchema,
            appId: iosAppId,
            packageInfo: androidAppId,
          }
          otherApps.subItems.push({ name, extraInfo })
        })

        menus.push(otherApps)

      }

      if (!item.subItems || item.subItems.length === 0 || item.key.includes('itemsTutorial') || item.key.includes('itemsSupport') || item.key === 'carouselItems' || item.key === 'otherApps' || item.key.includes('itemsMainCard') ||
        item.key.includes('itemsSummerPromo')) {
        return
      }

      const group = {
        'type': 'group',
        'name': item.value,
        'key': item.key,
        'moreText': 'dashboard_group_component_show_more',
        'lessText': 'dashboard_group_component_show_less',
        'subItems': []
      }

      item?.subItems?.map(subItem => {

        // IF the item is BigBrother we skip it
        if (subItem.key === 'entertainment') {
          return
        }

        if (subItem.key.endsWith(`_${OTHER_OS}`)) {
          return
        } else if (subItem.key.endsWith(`_${CURRENT_OS}`)) {
          subItem.key = subItem.key.replace(`_${CURRENT_OS}`, '')
        }

        const menu_data = {
          'name': subItem.key,
          'componentId': 'demo-component',
          'key': subItem?.key,
          'extraInfo': {
            'title': subItem.value || subItem.key,
            'icon': subItem.screen,
            'subTitle': subItem.text,
            'url': subItem.text,
            'type': subItem.type
          }
        }


        if (subItem.key === 'netPerformSpeedtest') {
          menu_data.extraInfo.type = 'webview'
          menu_data.extraInfo.url = 'https://speedtest.vodafone.al'
          menu_data.extraInfo.subTitle = 'https://speedtest.vodafone.al'
        }

        if (subItem?.key === 'Roaming' && ReduxCache?.getBusinessAccount()?.showBusinessView) {
          menu_data.extraInfo.type = 'native'
        }


        group.subItems.push({ ...menu_data })

        // OFFFERS AND REWARD

        if (subItem.key === 'reward') {
          offersAndRewards.items.push({
            key: 'Reward',
            name: 'Vodafone More',
            screens: ['Reward', 'OpenedGift', 'Catalog', 'Profile', 'Info', 'Details', 'FavouritesDetails', 'VfCoinsTransfer', '']
          })
        }
        if (subItem.key === 'redBoxWebview') {
          offersAndRewards.items.push({
            key: 'VfHearts',
            name: 'Flower box',
            screens: ['VfHearts', 'VfHeartsCollection']
          })
        }
        if (subItem.key === 'ungame') {
          offersAndRewards.items.push({
            key: 'Quiz',
            name: 'UN Quiz',
            screens: ['Quiz', 'QuizGame']
          })
        }

        //BASIC AND SOS

        if (subItem.key === 'recharge_other') {
          basicAndSos.items.push({
            key: 'TopUPAnother',
            name: 'top_up_someone_else_quick_action_title',
            screens: ['TopUPAnother']
          })
        }

        if (subItem.key === 'recharge_my') {
          basicAndSos.items.push({
            key: 'TopUP',
            name: 'my_recharge_section_title',
            screens: ['TopUP']
          })
        }

        if (subItem.key === 'mParking') {
          basicAndSos.items.push({
            key: 'Parking',
            name: 'Tirana Parking',
            isWebView: true,
            params: { 'url': `https://mytrp.altirana.com/?MSISDN=${ReduxCache.get('auth.hashedMsisdnParking')}&lang=${language}`, 'title': 'Tirana Parking', 'isReact': true }
          })
        }
        if (subItem.key === 'Roaming') {
          assistance.items.push({
            key: 'Roaming',
            name: 'Roaming',
            screens: ['Roaming']
          })
        }

        if (subItem.key === 'VodafoneSharing') {
          basicAndSos.items.push({
            key: 'DataSharing',
            name: 'Vodafone Share',
            screens: ['DataSharing']
          })
        }

        if (subItem.key === 'callMeBack' || subItem.key === 'call_me_back') {
          assistance.items.push({
            key: 'CallMeBack',
            name: 'call_me_back_title',
            screens: ['CallMeBack', 'CallMeBackHistory']
          })
        }

        if (subItem.key === 'netPerformSpeedtest_Android' || subItem?.key === 'netPerformSpeedtest') {
          assistance.items.push({
            key: 'Speed Test',
            name: 'Speed Test',
            isWebView: true,
            params: { 'url': 'https://speedtest.vodafone.al', 'title': 'Speed Test', 'isReact': true }
          })
        }


      })
      menus.push(group)

    })

    current.discoveryItems = menus
    sideBar.current = [...sideBar.current, offersAndRewards, basicAndSos, assistance]
    return current
  }

  const handleError = (data) => {
    if (!data) {
      return true
    } else if (data.status && parseInt(data.status, 10) >= 400) {
      return true
    } else if (data.failures) {
      return true
    }

    return false
  }

  const handleSecondaryCards = (item) => {
    const cards = mapSecondaryCards(item)
    setSecondaryCards(cards)
  }

  const getBalanceValue = (data) => {
    if (!data) {
      return false
    }

    const hasError = handleError(data)

    if (hasError) {
      setError(true)
      return
    } else {
      setError(false)
    }


    let balanceValue = {}
    const last_bill = []
    for (const i in data) {
      const item = data[i]
      if (item.name === 'balance') {
        let date = null
        try {
          date = moment(item['last-activity-date'], 'DD/MM/YYYY')
        } catch (err) {
          date = null
        }
        //removed amount and currency since they were the same as balance_amount and balance_currency
        balanceValue = {
          ...balanceValue,
          'last_charge_date': date?.date(),
          'last_charge_month': date?.format('MMM'),
          'balance_amount': item.details.allowance['available-allowance'].$,
          'balance_currency': item.details.allowance['available-allowance']['@currency-id'],
        }
      }

      if (item.name === 'paymentDueDate') {
        let date = null

        try {
          if (item['validity-period']['to-date'].date) {
            date = moment(item['validity-period']['to-date'].date, item['validity-period']['to-date']['@format'].toUpperCase())
          }
        } catch (err) {
          date = null
        }

        balanceValue = {
          ...balanceValue,
          'billing_date': date?.date(),
          'billing_month': date?.format('MMM'),
        }
      }

      if (item.name === 'last_bill') {
        last_bill.push(item)
      }
    }

    if (last_bill && last_bill?.length > 0) {
      const formattedLastBill = []

      last_bill.map(lastBillItem => {

        let date = null

        try {
          if (lastBillItem['validity-period']['to-date'].date) {
            date = moment(lastBillItem['validity-period']['to-date'].date, lastBillItem['validity-period']['to-date']['@format'].toUpperCase())
          }
        } catch (err) {
          date = null
        }

        formattedLastBill.push({
          'bill_amount': lastBillItem.details.allowance['available-allowance'].$,
          'bill_currency': lastBillItem.details.allowance['available-allowance']['@currency-id'],
          'billing_date': date?.date(),
          'billing_month': date?.format('MMM'),
          'category': lastBillItem?.categories?.[0]?.$ || '',
          'status': lastBillItem?.status?.$ || '',
          'billing_full_date': date,
          'category': lastBillItem?.categories?.[0]?.$,
          'billing_month': date?.format('MMMM'),
        })
      })

      balanceValue = {
        ...balanceValue,
        lastBill: formattedLastBill,
        has_last_bill: true
      }
    }

    return balanceValue
  }


  const handleTrayMenu = (item, type) => {
    const subTrayItems = []
    item.subItems?.map(subItem => {
      const text = subItem.text?.split('|') || []
      if (subItem?.key === 'inbox') { return }

      subTrayItems.push({
        'key': subItem.key,
        'type': subItem.type,
        'itemTitle': subItem.value,
        'itemSubTitle': (subItem.key === 'logout' || subItem.key === 'myNumber' || subItem.key === 'next') ? '' : text[0],
        'itemUrl': text[1] || '',
        'itemSubtitleWithBadge': text[0],
        'itemImage': subItem.screen,
        'itemExtraData': {},
        'itemTitleStyle': {
          paddingHorizontal: 10,
          lineHeight: Platform.OS === 'ios' ? 13 : 15,
        },
        containerViewStyle: {
          width: 150
        }
      })
    })

    if (type === 'itemsAccount') {
      setMyAccountMenu({
        ...myAccountMenu,
        icon: 'ic_admin',
        title: item.value,
        subTray: {
          ...myAccountMenu.subTray,
          subTrayTitle: item.value,
          subTrayItems: subTrayItems
        }
      })
    } else if (type === 'itemsProducts') {
      setMyProductsMenu({
        ...myProductsMenu,
        icon: 'ic_connected_devices',
        title: item.value,
        subTray: {
          ...myProductsMenu.subTray,
          subTrayTitle: item.value,
          subTrayItems: subTrayItems
        }
      })
    }

  }

  const handleTrayOptionalItems = (item) => {
    //we can only add to optional items to tray
    if (item?.subItems?.length === 2) {
      const formattedItems = []
      item.subItems.map(subItem => {
        formattedItems.push({
          title: subItem.value,
          icon: subItem.screen,
          key: subItem.key,
          subTray: {
            subTrayTitle: subItem.value,
            subTrayDesc: '',
            subTrayItems: []
          },
          trayAction: '',
        })
      })
      setTrayOptionalItems(formattedItems)
    } else {
      setTrayOptionalItems([])
    }
  }


  const onMenuReceived = (dataJson) => {
    const hasError = handleError(dataJson)

    if (hasError) {
      setError(true)
      setIsLoading(false)
      return
    } else {
      setError(false)
    }

    const menus_formated = generateMenuData(dataJson)

    // alert('menus_formated' + JSON.stringify(menus_formated))
    setDiscover(menus_formated)
    setIsLoading(false)
  }

  const mapSecondaryCards = (data) => {

    if (data?.subItems?.length === 0) {
      // alert('no secondary cards found')
    }

    return data.subItems || []
  }


  const onBalanceReceived = async (dataJson) => {
    const result = mapBalanceData(dataJson)

    if (dataJson?.vodafoneday) {
      onMyWeekendReceived()
    }
    const userBalanceData = getBalanceValue(dataJson)
    setUserBalance(userBalanceData)

    if (result) {
      try {
        const formatedData = {
          ...balance,
          dashboardItems: {
            ...balance.dashboardItems,
            0: {
              ...balance.dashboardItems[0],
              'name': 'MainCardComponent',
              'componentId': 'MainCardComponent',
              extraInfo: {
                ...balance.dashboardItems[0]?.extraInfo,
                bucket: result
              }
            },
          }
        }

        setBalance(formatedData)

      } catch (err) {
        console.log(err)
      }
    }

    setIsLoading(false)
  }

  const isGuestAllowedInComponent = (callback) => {
    if (userType === 'guest') {
      navigate('TransparentPopup')
    } else {
      callback()
    }
  }


  const navigateToNative = async (item) => {

    const key = typeof item === 'string' ? item : item.name

    if (userType === 'guest') {
      if (Object.keys(guestAllowScreens).includes(key)) {
        navigate(guestAllowScreens[key])
        return
      }
      else if (item.extraInfo && item.extraInfo.type === 'webview') {
        navigate('WebView', { url: item.extraInfo.url, title: item.extraInfo.title, isReact: true })
        return
      }
      navigate('TransparentPopup')
      return
    }

    switch (key) {
      case 'VodafoneSharing':
        if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
          navigate('DataSharing')
        } else {
          setShowDataShare(true)
        }
        return
      case 'promo_teasing':
        return
      case 'redBoxWebview':
        dispatch({
          type: 'ACTIVE_COMPONENT',
          payload: 'LoveBox'
        })
        break
      case 'Roaming':
        navigate('Roaming')
        break
      case 'reward':
        dispatch({
          type: 'ACTIVE_COMPONENT',
          payload: 'More'
        })
        break
      case 'migration_p2h':
        navigate('Migration', { type: 'p2h' })
        break
      case 'migration_renewal':
        navigate('Migration', { type: 'renewal' })
        break
      case 'maunal_line':
        toggleManualLine()
        break
      case 'ungame':
        navigate('Quiz')
        break
      case 'FixProductsAndServices':
        navigate('FixProductsAndServices')
        break
      case 'purchase_fix_discover':
        toggleFix()
        break
      // case 'netPerformSpeedtest':
      //   navigate('SpeedChecker')
      //   break
      case 'fixPurchaseJourney':
        toggleFix()
        break
      case 'unlimited_home':
        navigate('UnlimitedHomeJourney')
        break
      case 'recommendedoffers':
        navigate('Catalogue')
        break
      case 'CocaCola':
        navigate('CocaCola')
        break
      // case 'entertainment':
      //   navigate('BBVotingSystem')
      //   break
    }

    if (menuMapping[key]) {
      if (key === 'recharge_my') {
        setIsThirdParty(false)
        if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
          navigate('TopUP')
        } else {
          setShowPayments(true)
        }
      } else if (key === 'recharge_other') {
        setIsThirdParty(true)
        if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
          navigate('TopUPAnother')
        } else {
          setShowPayments(true)
        }
      } else {
        navigate(menuMapping[key])
      }
    } else if (item.extraInfo?.type === 'webview') {
      if (key === 'mParking') {
        const encryptedMsisdn = ReduxCache.get('auth.hashedMsisdnParking')
        navigate('WebView', { url: item.extraInfo.url, title: item.extraInfo.title, postData: 'MSISDN=' + encryptedMsisdn, isReact: true })
      } else {
        navigate('WebView', { url: item.extraInfo.url, title: item.extraInfo.title, isReact: true })
      }
    }
    else if (item.extraInfo?.type === 'call') {
      window.open(`tel:${item.extraInfo?.subTitle}`)
      return
    }
  }

  const handleBottomMenuNavigation = (item, method) => {
    if (item.key === 'my_products' || item.key === 'my_account') {
      method()
    } else {
      navigateToNative(item.key)
    }
  }


  const handlePressSubTrayItems = async (subTrayItem) => {
    switch (subTrayItem.key) {
      case 'inbox':
        navigate('MessageCenter')
        break
      case 'payment':
        isGuestAllowedInComponent(() => navigate('PaymentMethods', { screen: 'Dashboard' }))
        break
      case 'settings':
        navigate('Settings')
        break
      case 'logout':
        isGuestAllowedInComponent(() => setShowLogoutModal(true))
        break
      case 'myNumber':
        navigate('ProductsAndServices')
        break
      case 'GetFixLine':
        toggleFix()
        break
      case 'my_orders':
        isGuestAllowedInComponent(() => navigate('ManageOrders'))
        break
      case 'my_contracts':
        navigate('FixContracts')
        break
      case 'GigaFibra':
        navigate('FixProductsAndServices')
        break
      case 'vodafoneTV':
        navigate('FixProductsAndServices')
        break
      case 'purchase_fix_discover':
        toggleFix()
        break
      case 'dashboardGuest':
        navigate('PreLogin')
        break
      case 'myUsersAndGroup':
        navigate('UsersAndGroup')
        break
      case 'userRequest':
        navigate('Requests')
        break
      case 'user_requests':
        navigate('Requests')
        break
      case 'unlimitedHome':
        navigate('GroupManagement')
        break
      default:
        if (subTrayItem.type === 'react') {
          navigate(subTrayItem.key)
        } else if (subTrayItem.type === 'webview') {
          navigate('WebView', { url: subTrayItem.itemUrl, title: subTrayItem.itemTitle, isReact: true })
        }
    }
  }

  const getSecondaryCards = () => {
    if (secondaryCards.length === 0 || !userBalance) {
      return {}
    }

    const thirdCardData = secondaryCards[1]
    const fourthCardData = secondaryCards[2]

    const secondaryCard = {
      'name': 'SecondaryCardComponent',
      'componentId': 'SecondaryCardComponent',
      'userType': userType,
      'userTypeInitial': userType,
      //if those keys are not in userBalance object then keys for topUp and billing will be empty so it will not throw an error
      'extraInfo': {
        'topUp': {
          //value for topUp will be balance_amount which we get in balance object in details.allowance['available-allowance'].$
          //if balance_amount does not exist thaan it will be 0
          'value': userBalance?.balance_amount?.toString() || '0',
          'currency': userBalance?.balance_currency || '',
          //since we will show a top card only for prepaid & prepaid_fix then for other userType we will not have a value
          'paymentInfo': userType?.includes('prepaid') && (userBalance?.last_charge_date + ' ' + monthTranslations[language][userBalance?.last_charge_month]),
          'onPress': () => navigateToNative('recharge_my')
        },
        'billing': {
          // if user has last bill then the value for billing will be bill_amount wich we get it in last_bill object in details.allowance['available-allowance'].$
          // if userType is hybrid and does not have last bill than the value for billing will be 0
          // for userTypes that are not hybrid and do nat have last bill, then billing value will be balance_amount which we get in balance object in details.allowance['available-allowance'].$
          'value': userBalance.has_last_bill ? (userBalance?.bill_amount?.toString() || '') : (!userType.includes('postpaid') ? '--' : userBalance?.balance_amount?.toString() || ''),
          //currency has the same logic as value
          'currency': userBalance.has_last_bill ? userBalance.bill_currency || '' : (userType === 'hybrid' ? ' ' : userBalance?.balance_currency || ''),
          //if we don't have billing date than we asign paymentInfo to null
          //billing date and month we get it in paymentDueDate object and if there is a last_bill object than billing_date/month is overwritten
          //so we use billing_date/month from last_bill object which if it does not exist billing_date/month is from paymentDueDate object and if this does not exist then its null
          'paymentInfo': userBalance?.billing_date ? (userBalance?.billing_date + ' ' + monthTranslations[language][userBalance?.billing_month]) : null,
          'onPress': () => { navigateToNative('billing') },
          'lastBill': userBalance.has_last_bill ? userBalance.lastBill : null
        },
      },
      'nonVf': nonVfSecondTile,
      'guest': guestSecondTile,
    }

    let thirdNavigationItem = {
      extraInfo: {
        url: thirdCardData?.text,
        title: thirdCardData?.key,
        type: 'webview'
      }
    }
    let thirdTitle = ''
    let thirdSubTitle = ''
    if (thirdCardData.type === 'webview') {
      thirdTitle = thirdCardData?.key || ''
      thirdSubTitle = thirdCardData?.value || ''
    } else {
      thirdNavigationItem = thirdCardData?.key
      thirdTitle = thirdCardData?.value || ''
      thirdSubTitle = thirdCardData?.text || ''
      if (sideBar.current.findIndex((item) => { item?.key === 'Shake' }) === -1) {
        sideBar.current.push({
          key: 'Shake',
          name: 'Shake',
          screens: ['Shake']
        })
      }
    }

    const onPressThirdComponent = (key) => {
      if (key === 'maunal_line') {
        return () => navigate('TransparentPopup')
      } else {
        return () => navigateToNative(thirdNavigationItem)
      }
    }

    const thirdCard = {
      'name': 'ThirdCardComponent',
      'componentId': 'ThirdComponent',
      'extraInfo': {

        'onPress': onPressThirdComponent(thirdCardData?.key),
        'icon': thirdCardData?.screen,
        'title': thirdTitle,
        'subTitle': thirdSubTitle,
      }
    }


    const onPressFourthComp = (key) => {
      if (key === 'netPerformSpeedtest') {
        return () => {
          navigate('WebView', { url: 'https://speedtest.vodafone.al', title: fourthCardData.title, isReact: true })
        }
      } else {
        return () => navigateToNative(fourthCardData?.key)
      }
    }

    const fourthCard = {
      'name': 'FourthCardComponent',
      'componentId': 'FourthCardComponent',
      'type': fourthCardData?.key === 'recharge' ? 'recharge' : 'other',
      'extraInfo': {
        'onPress': () => {
          if (fourthCardData?.key === 'recharge') {
            navigateToNative('recharge_my')
          } else if (fourthCardData?.type === 'webview') {
            navigateToNative({
              extraInfo: {
                url: fourthCardData?.text,
                title: fourthCardData?.key,
                type: 'webview'
              }
            })
          }
          else {
            navigateToNative(fourthCardData.key)
          }
        },
        'icon': fourthCardData?.screen,
        'title': fourthCardData?.type === 'webview' ? fourthCardData?.key : fourthCardData?.value,
        'subTitle': fourthCardData?.key === 'recharge' ? userName : (fourthCardData?.type === 'webview' ? fourthCardData?.value : fourthCardData?.text),
        'value': fourthCardData?.key === 'recharge' ? userBalance.amount?.toString() : null,
        'currency': fourthCardData?.key === 'recharge' ? userBalance.currency : null,
      }
    }

    return {
      1: secondaryCard,
      2: thirdCard,
      3: fourthCard
    }

  }


  const getDashboardData = () => {

    const balanceCloned = _cloneDeep(balance)
    if (!isUndefined(balanceCloned.dashboardItems[0]?.extraInfo)) {
      try {


        const mobileTileIndex = balanceCloned?.dashboardItems[0]?.extraInfo?.bucket.findIndex(el => el.usageType === 'mobile')
        if (mobileTileIndex !== -1) {
          balanceCloned.dashboardItems[0].extraInfo.bucket[mobileTileIndex].showRefill = isEligibleRefill
          balanceCloned.dashboardItems[0].extraInfo.bucket[mobileTileIndex].onRefillPress = () => setShowRefill(true)
        }

      } catch (err) {
        console.log('error dashboard refill', err)
      }
    }

    let promoBucket = {}

    balanceCloned.dashboardItems[0]?.extraInfo?.bucket?.map((bucket, index) => {
      bucket.tarif_plan = userTarifPlan

      if (bucket.usageType === 'promo') {
        promoBucket = bucket

        if (!bucket.isActive) {
          balanceCloned.dashboardItems[0].extraInfo.bucket.splice(index, 1)
        }
      }

      return bucket
    })



    const secondaryCardsData = getSecondaryCards()
    const mergedBalance = {
      ...balanceCloned,
      dashboardItems: {
        0: {
          ...balanceCloned.dashboardItems[0]
        },
        ...secondaryCardsData
      }
    }

    const discoverDataNew = _cloneDeep(discover)

    if (discoverDataNew?.discoveryItems[0]?.bucket) {
      const filtered = discoverDataNew.discoveryItems[0]?.bucket?.filter((discoverItem, index) => {
        const key = discoverItem.extraInfo.key
        if (key === 'myWeekend' && !activeDiscovers[key]) {
          return false
        }

        if (key === 'migration_renewal' && !activeDiscovers[key]) {
          return false
        }

        if (key === 'migration_p2h' && !activeDiscovers[key]) {
          return false
        }

        if (key === 'refill' && !activeDiscovers[key]) {
          return false
        }

        if (key === 'fix_renewal' && !activeDiscovers[key]) {
          return false
        }
        if (key === 'affiliate' && !activeDiscovers[key]) {
          return false
        }

        // if promo object is not found don't show anything for promo
        if (key === 'promo_teasing' && Object.keys(promoBucket).length === 0) {
          return false
        }

        // if promo is active hide teasing
        if (key === 'promo_teasing' && promoBucket.isActive) {
          return false
        }

        // if promo is not active hide live
        if (key === 'promo_live' && !promoBucket.isActive) {
          return false
        }

        if (key === 'promo_live' && promoBucket.isActive) {
          discoverDataNew.discoveryItems[0].bucket[index].extraInfo.isActive = promoBucket.isActive
          discoverDataNew.discoveryItems[0].bucket[index].extraInfo.remainingTime = promoBucket.remainingTime
        }
        if (key === 'fix_bill') {
          //hide billing discovery if all bills are payed
          const billData = []
          if (balanceReduxData?.data) {
            Object.keys(balanceReduxData?.data)?.forEach(objectKey => {
              if (objectKey.includes('last_bill')) {
                billData.push(balanceReduxData?.data[objectKey])
              }
            })
          }

          if (billData.length === 0) {
            return true
          }

          let areAllPaid = true

          billData.forEach(lastBillItem => {
            if (lastBillItem?.status?.$ === 'settled') {
              areAllPaid = areAllPaid && true
            } else {
              areAllPaid = areAllPaid && false
            }

          })

          return !areAllPaid
        }

        return true
      })

      discoverDataNew.discoveryItems[0].bucket = filtered
    }

    const itemsMainCard = configurationsRedux?.menu_prepaid?.filter(item => item.key === 'itemsMainCard') || []
    const image = itemsMainCard?.[0]?.subItems[0]?.text?.split('|')?.[2] || ''

    if (!pegaRedux.loading) {
      const pegaP2H = Object.keys(pegaRedux?.data?.characteristics || {})?.filter(
        key => key?.toLowerCase()?.startsWith('p2h')
      )
      if (pegaP2H?.length > 0 && itemsMainCard?.length > 0) {
        mergedBalance?.dashboardItems[0]?.extraInfo?.bucket.push({
          usageType: 'pega_recommendation',
          hasActiveBundle: true,
          uri: image
        })
      }
    }

    const mergedBalanceDataNew = _cloneDeep(mergedBalance)

    return [mergedBalanceDataNew, discoverDataNew]
  }

  const getProductsTray = () => {
    const changed = false
    const sidebarItem = {
      key: 'My Products',
      name: 'product_section_title',
      items: [
      ]
    }
    const myProductsMenuCloned = _cloneDeep(myProductsMenu)

    myProductsMenuCloned?.subTray?.subTrayItems?.map(subItem => {
      if (subItem.key === 'myNumber' && msisdn) {
        subItem.itemSubTitle = msisdn
      }

      return subItem
    })

    if (gigaFibraCount >= 1) {
      let text = 'dashboard_fix_line'

      if (gigaFibraCount > 1) {
        text = ContentManager.translate('dashboard_fix_lines', gigaFibraCount.toString())
      }

      const foundItem = myProductsMenuCloned?.subTray?.subTrayItems?.find(item => item.key === 'GigaFibra')
      if (foundItem) {
        foundItem.itemSubTitle = text
        foundItem.show = true
      }

    }

    if (vodafoneTvText) {
      const foundItem = myProductsMenuCloned?.subTray?.subTrayItems?.find(item => item.key === 'vodafoneTV')
      if (foundItem) {
        foundItem.itemSubTitle = vodafoneTvText ? vodafoneTvText : 'dashboard_details'
        foundItem.show = true
      }
    }

    const filtered = myProductsMenuCloned?.subTray?.subTrayItems?.filter(item => {
      if (item.key === 'GigaFibra' || item.key === 'vodafoneTV') {
        if (!item.show) {
          return false
        }
      }

      return true
    })

    myProductsMenuCloned.subTray.subTrayItems = filtered

    if (sideBar.current.filter(item => item.key === 'My Products').length === 0 && !changed) {
      sideBar.current = [...sideBar.current, sidebarItem]
    } else {
      const indexOfSidebarItem = sideBar.current.findIndex(item => item.key === 'My Products')
      if (indexOfSidebarItem != -1) {
        sideBar.current.splice(indexOfSidebarItem, 1, sidebarItem)
      }
    }

    return myProductsMenuCloned
  }

  const getMyAccountTray = () => {
    const sidebarItem = {
      key: 'My Account',
      name: 'accounts_section_title',
      items: [
        {
          key: 'PaymentMethods',
          name: 'payment_method_screen_title',
          screens: ['PaymentMethods', 'EditPayment', 'AddPayment']
        },
        {
          key: 'FixContracts',
          name: 'my_contracts',
          screens: ['FixContracts']
        },
        {
          key: 'ManageOrders',
          name: 'my_orders',
          screens: ['ManageOrders']
        },
        {
          key: 'Settings',
          name: 'subtray_app_settings_title',
          screens: ['Settings', 'ManageProfile', 'EditProfile', 'ChangeMyPassword', 'DeactivateAccount', 'Recommendations', 'Preferences', 'DataManagment', 'TermsAndConditions', 'PrivacyPolicy']
        },
      ]
    }
    const myAccountMenuCloned = _cloneDeep(myAccountMenu)

    myAccountMenuCloned?.subTray?.subTrayItems?.map(subItem => {
      if (subItem.key === 'logout' && msisdn) {
        subItem.itemSubTitle = msisdn
        subItem.itemSubtitleWithBadge = 'tray_messages_new_unread'
      }

      return subItem
    })

    if (sideBar.current.filter(item => item.key === 'My Account').length === 0) {
      sideBar.current = [...sideBar.current, sidebarItem]
    }

    return myAccountMenuCloned
  }

  const getNotificationCount = (notificationsCount) => {

    const messageTitle = myAccountMenu?.subTray?.subTrayItems?.find(el => el?.key === 'inbox')?.itemTitle

    const notificationsCountObject = {
      [myAccountMenu?.title]: {
        [messageTitle]: notificationsCount
      }
    }

    return notificationsCountObject
  }

  return {
    components,
    dashboardLoadingConfig,
    discoverCardLoadingConfig,
    lastUpdatedLoadingConfig,
    getMyAccountTray,
    getProductsTray,
    getDashboardData,
    handlePressSubTrayItems,
    handleBottomMenuNavigation,
    error,
    showLogoutConfirm,
    showDiscoverModal,
    discoverModalData,
    shouldShowFix,
    showFixManualProduct,
    navigateToNative,
    isLoading,
    setShowLogoutModal,
    toggleDicoveryModal,
    toggleFix,
    toggleManualLine,
    openTobiUrl,
    tobiIcon,
    openTobiModal,
    toggleRefill,
    groupImages,
    tobiURL,
    sideBar,
    storiesProps,
    setStoriesProps,
    accountSwitchVisible,
    toggleAccountSwitch,
    businessAccount,
    trayOptionalItems,
    getNotificationCount,
  }
}

export default useDashboardMenu
