import React, {Component} from 'react'
import { View, TouchableOpacity, ScrollView , ImageBackground} from 'react-native'

import FastImage from 'react-native-fast-image'

import axios from 'axios'

import WebView from '../../Components/WebView'

import { __translate, __image } from '../../Utils'

import { Images } from '../../Themes'
import styles from './details.styles'


class Details extends Component {
  state = {
    loading: false,
    data: null,
    error: null
  }

  componentDidMount(){
    this.setState({loading: true})
    axios.get(__translate('rewards_tc'))
    .then(response => {
      const content = response.data

      this.setState({data: content, loading: false})
    })
    .catch(error => {
      this.setState({loading: false, error: 'Something went wrong'})
    })
  }

  render () {
    return (
      <ScrollView>
        <ImageBackground
          style={styles.details}
          source={{
            uri: __image('reward_loyalty_tc_bg'),
            // priority: FastImage.priority.normal,
          }}>

          <TouchableOpacity onPress={() => this.props.navigation.navigate('Info')}>
            <FastImage
              style={styles.go_white}
              source={Images.go_white} />
          </TouchableOpacity>
        </ImageBackground>

        <View style={styles.content} >
          <View>
            { this.state.data && <WebView content={this.state.data} /> }
          </View>
          <TouchableOpacity onPress={() => this.props.navigation.navigate('Info')}>
            <FastImage
              style={styles.go}
              source={Images.go} />
          </TouchableOpacity>
        </View>
      </ScrollView>
    )
  }
}


export default Details
