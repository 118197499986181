import { StyleSheet, Dimensions } from 'react-native'

import colors from '../../Themes/Colors'

const screenWidth = Dimensions.get('screen').width

export default StyleSheet.create({
  safeArea: {
    backgroundColor: colors.aluminium,
    flex: 1,
    paddingHorizontal: '10%'
  },
  container: {
    flex: 1,
    backgroundColor: colors.white,
    borderRadius: 6,
    margin: 16,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 8,
    elevation: 8,
    marginTop: 35,
  },
  innerContainer: {
    flex: 1,
    borderRadius: 6,
    overflow: 'hidden',
  },
  imgBg: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    flexGrow: 1,
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  infoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 16,
  },
  info: {
    width: 30,
    height: 30,
    tintColor: colors.flower_blue,
  },
  close: {
    width: 30,
    height: 30,
  },
  centerContent: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollView: {
    flexGrow: 1,
    marginHorizontal: 16,
    paddingRight: 8,
    marginBottom: 130,
  },
  rowContainer: {
    flexGrow: 1,
    paddingVertical: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rowContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heartContainer: {
    padding: 8,
    margin: 8,
    width: 50,
    height: 50,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    shadowColor: '#390D0F',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 8,
    elevation: 8,
  },
  heartImg: {
    width: 35,
    height: 35,
    resizeMode: 'contain',
    overflow: 'visible',
    shadowColor: colors.red,
    shadowOffset: { width: 0, height: 6 },
    shadowOpacity: 0.16,
    shadowRadius: 8,
  },
  heartImgAmount: {
    width: 70,
    height: 70,
    justifyContent: 'center',
    alignContent: 'center',
    shadowColor: colors.red,
    shadowOffset: { width: 0, height: 6 },
    shadowOpacity: 0.16,
    shadowRadius: 8,
  },

  buttonsContainer: {
    alignSelf: 'flex-end',
    marginHorizontal: 8,
    marginBottom: 32,
  },
  cancelButton: {
    backgroundColor: colors.white,
    borderWidth: 2,
    borderColor: colors.red,
    margin: 8,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    width: screenWidth - 64,
  },
  cancelButtonText: {
    color: colors.red,
    fontSize: 22,
    margin: 10,
  },
  button: {
    backgroundColor: colors.red,
    margin: 8,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    width: screenWidth - 64,
  },
  buttonText: {
    color: colors.white,
    fontSize: 22,
    margin: 10,
  },
  success_error_image: {
    height: 100,
    overflow: 'visible',
    shadowOffset: { width: 0, height: 16 },
    shadowOpacity: 0.32,
    shadowRadius: 8,
  },
  success_image: {
    shadowColor: colors.red,
  },
  error_image: {
    shadowColor: colors.black,
  },

  bottomContainer: {
    flex: 1.2,
    marginTop: 0,
  },

  rowCenter: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heartText: {
    color: colors.red,
  },
})
