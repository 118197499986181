import React from 'react'
import { View, TouchableOpacity, Platform, Dimensions } from 'react-native'
import { VFText, VFButton, Icon } from '@vfgroup-oneplatform/foundation/Components'

import { useTheme, getThemeImages } from '@vfgroup-oneplatform/foundation/Components/Themes'

import launchLoginScreen, { loginManager } from '../../Utils/LaunchLogin'

import NavigationService from '../../Navigation/NavigationService'

import styles from './TransparentPopup.Styles'

const TransparentPopup = ({ navigation, route }) => {

  const theme = useTheme()

  const themeImages = getThemeImages(theme.name)

  const { onDismiss } = route?.params || {}

  const onClose = () => {
    if (onDismiss) {
      onDismiss?.()
    } else {
      navigation.pop()
    }
  }

  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {

    return (
      <TouchableOpacity
        style={styles.container(theme)}
        onPress={onClose}
        activeOpacity={1}
      >
        <View style={[styles.insideContainer, { width: '50%' }]}>
          <TouchableOpacity
            style={styles.whiteArea(theme)}
            onPress={() => {
              // do nothing
            }}
            activeOpacity={1}
          >
            <VFText i18nKey={'guest_pop_up_title'} style={styles.title(theme)} />
            <Icon name={themeImages.ic_admin} size={100} style={styles.icon} />
            <VFText
              i18nKey={'guest_pop_up_description'}
              style={styles.description(theme)}
            />
            <VFButton
              title="guest_pop_up_login"
              type={'primary'}
              onPress={() => {
                NavigationService.pop()
                launchLoginScreen(false)
              }}
            />
            <VFButton
              title="guest_pop_up_signup"
              type={'secondary'}
              style={styles.signUp}
              textStyle={styles.signUpText(theme)}
              onPress={() => {
                NavigationService.pop()
                loginManager.Register()
              }}
            />
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    )
  }

  return (
    <TouchableOpacity
      style={styles.container(theme)}
      onPress={onClose}
      activeOpacity={1}
    >
      <View style={styles.insideContainer}>
        <TouchableOpacity
          style={styles.whiteArea(theme)}
          onPress={() => {
            // do nothing
          }}
          activeOpacity={1}
        >
          <VFText i18nKey={'guest_pop_up_title'} style={styles.title(theme)} />
          <Icon name={themeImages.ic_admin} size={100} style={styles.icon} />
          <VFText
            i18nKey={'guest_pop_up_description'}
            style={styles.description(theme)}
          />
          <VFButton
            title="guest_pop_up_login"
            type={'primary'}
            onPress={() => {
              NavigationService.pop()
              launchLoginScreen(false)
            }}
          />
          <VFButton
            title="guest_pop_up_signup"
            type={'secondary'}
            style={styles.signUp}
            textStyle={styles.signUpText(theme)}
            onPress={() => {
              NavigationService.pop()
              loginManager.Register()
            }}
          />
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  )
}


export default TransparentPopup
