export const actionTypes = {
  REQUEST_BALANCE_OPTIONS: 'REQUEST_BALANCE_OPTIONS',
  SUCCESS_BALANCE_OPTIONS: 'SUCCESS_BALANCE_OPTIONS',
  ERROR_BALANCE_OPTIONS: 'ERROR_BALANCE_OPTIONS',

  REQUEST_DATA_OPTIONS: 'REQUEST_DATA_OPTIONS',
  SUCCESS_DATA_OPTIONS: 'SUCCESS_DATA_OPTIONS',
  ERROR_DATA_OPTIONS: 'ERROR_DATA_OPTIONS',

  REQUEST_VCOINS_OPTIONS: 'REQUEST_VCOINS_OPTIONS',
  SUCCESS_VCOINS_OPTIONS: 'SUCCESS_VCOINS_OPTIONS',
  ERROR_VCOINS_OPTIONS: 'ERROR_VCOINS_OPTIONS',

  REQUEST_BALANCE_TRANSFER: 'REQUEST_BALANCE_TRANSFER',
  SUCCESS_BALANCE_TRANSFER: 'SUCCESS_BALANCE_TRANSFER',
  ERROR_BALANCE_TRANSFER: 'ERROR_BALANCE_TRANSFER',

  REQUEST_DATA_TRANSFER: 'REQUEST_DATA_TRANSFER',
  SUCCESS_DATA_TRANSFER: 'SUCCESS_DATA_TRANSFER',
  ERROR_DATA_TRANSFER: 'ERROR_DATA_TRANSFER',

  REQUEST_VCOINS_TRANSFER: 'REQUEST_VCOINS_TRANSFER',
  SUCCESS_VCOINS_TRANSFER: 'SUCCESS_VCOINS_TRANSFER',
  ERROR_VCOINS_TRANSFER: 'ERROR_VCOINS_TRANSFER',
}