import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import ReduxCache from '../../Utils/ReduxCache'

import { API_URLS, COMMON_HEADERS } from '../Config'


const mapError = (error) => {
  const payload = error?.response?.data?.message || error?.message
  return payload
}


const getCostumer = async (data, utilities) => {

  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
  })

  const { onStart, onSuccess, onError, errorMapper } = _utilities

  onStart()

  const params = {
    'type': 'acquisition',
    'individualIdentification.identificationId': data?.idNumber,
    'individualIdentification.identificationType': 'Identification%20Card',
    'birthDate': `${data?.year}${data.month}${data.day}`
  }

  const headers = {
    ...COMMON_HEADERS,
  }

  try {
    const response = await axios.get(API_URLS.acquisition.individualParty, {
      params,
      headers,
      // paramsSerializer: (param) => {
      //   let result = ''
      //   Object.keys(param).map((el, index) => {
      //     result = result + (el + '=' + param[el]) + (index !== Object.keys(param).length - 1 ? '&' : '')
      //   })
      //   return result
      // }
    })
    const _response = response?.data
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const postCostumer = async (data, utilities) => {

  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
  })

  const { onStart, onSuccess, onError, errorMapper } = _utilities

  onStart()

  const {
    idNumber,
    address,
    birthplace,
    city,
    country,
    day,
    month,
    year,
    documentType,
    expirationYear,
    expirationMonth,
    expirationDate,
    gender,
    name,
    surname,
    mobileNo,
  } = data

  const requestData = {
    'birthDate': `${year}-${month}-${day}T09:26:29.970Z`,
    'countryOfBirth': country,
    'familyName': surname,
    'givenName': name,
    'gender': gender,
    'location': country !== 'Albania' ? 'Other Foreign' : birthplace,
    'placeOfBirth': country !== 'Albania' ? 'Other Foreign' : birthplace,
    'nationality': country,
    'contactMedium': [
      {
        'mediumType': 'billing_address',
        'characteristic': {
          'city': city?.replace('ë', 'e'),
          'contactType': 'Home',
          'country': 'Albania',
          'emailAddress': '',
          'phoneNumber': mobileNo,
          'postCode': '1001',
          'street1': address,
        },
      },
      {
        'mediumType': 'home_address',
        'characteristic': {
          'city': 'string',
          'contactType': 'string',
          'country': 'string',
          'emailAddress': 'string',
          'phoneNumber': 'string',
          'postCode': 'string',
          'street1': 'string'
        }
      },
    ],
    'individualIdentification': [
      {
        'identificationId': idNumber,
        'identificationType': documentType,
        'issuingDate': `${expirationYear}-${expirationMonth}-${expirationDate}T09:26:29.970Z`,
      },
    ],
    'partyCharacteristic': [
      {
        'name': 'type',
        'value': 'Consumer',
      },
      {
        'name': 'segment',
        'value': 'Individual',
      },
    ],
    'relatedParty': [
      {
        'id': '',
        'name': '',
        '@referredType': '',
      },
    ],
    'status': 'initialized',
  }

  const params = {
    'type': 'acquisition',
  }

  const headers = {
    ...COMMON_HEADERS,
  }

  try {
    const response = await axios.post(API_URLS.acquisition.individualParty, requestData, {
      params,
      headers,
    })
    const _response = response?.data
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const productOrder = async (data, utilities) => {

  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
  })

  const { onStart, onSuccess, onError, errorMapper } = _utilities

  onStart()

  const { selectedOffer, selectedSim, address, accountNumber, contactNo } = data

  const selectedOfferData = selectedOffer?.data

  const msisdn = ReduxCache.getMsisdn()

  const prodPrice = []
  selectedOfferData.recommendationItem[0]?.product.productTerm.map(item => {
    prodPrice.push({
      ...item,
      'validFor': {
        'endDateTime': '', //should always be emty
        'startDateTime': ''//should always be emty
      },
    })
  })

  const prodCharacteristics = []

  if (selectedOfferData.recommendationItem[0]?.product?.productCharacteristic.length === 0) {
    prodCharacteristics.push({
      'name': 'affiliateCode',
      'valueType': 'string',
      'value': '',
      '@baseType': 'string',
      '@schemaLocation': 'string',
      '@type': 'string'
    })
  } else {
    selectedOfferData.recommendationItem[0]?.product?.productCharacteristic.map(item => {
      prodCharacteristics.push({
        ...item,
        value: item?.value?.length > 10 ? '' : item?.value //  either empty or 10 digits(max field value in CRM)
      })
    })
  }


  const requestData = {
    'category': 'acquisition',
    'description': 'New Connection',
    'externalId': accountNumber,
    'channel':
      [
        {
          'id': 'MVAAPP',
        },
      ],
    'productOrderItem':
      [
        {
          'id': selectedOfferData?.name,
          'quantity': 1,
          'action': '',
          'product':
          {
            'id': selectedOfferData?.name,
            'name': selectedOfferData?.name,
            'productCharacteristic': prodCharacteristics,
            'productPrice': selectedOfferData.recommendationItem[0]?.product.productPrice,
            'place':
              [
                {
                  'id': 'a4b4628c-a30d-4f6b-a8dd-f9a2bbca50c5',
                  'href': '/api/geographicAddressManagement/v4/geographicAddress/a4b4628c-a30d-4f6b-a8dd-f9a2bbca50c5',
                  'name': address + ' - ' + contactNo,
                  'role': 'CART_ITEM_SERVICE_ADDRESS',
                  '@referredType': 'RelatedPlaceRef'
                },
              ],
            'realizingResource': [
              {
                'id': 'account',
                'name': 'Account Value',
                'value': accountNumber,
                '@baseType': 'string',
                '@schemaLocation': 'string',
                '@type': 'string',
                '@referredType': 'account'
              },
              {
                'id': 'sim',
                'name': 'Sim Value',
                'value': 'sim Value',
                '@baseType': 'string',
                '@schemaLocation': 'string',
                '@type': 'string',
                '@referredType': 'sim'
              },
              {
                'id': selectedSim,
                'name': 'MSISDN',
                'value': accountNumber,
                '@baseType': 'string',
                '@schemaLocation': 'string',
                '@type': 'string',
                '@referredType': 'msisdn'
              }
            ],
            'productTerm': prodPrice,
            'productRelationship': selectedOfferData?.recommendationItem[0]?.product.productRelationship,
          },
        },
      ],
    'relatedParty':
      [
        {
          'id': msisdn,
          'role': 'Customer',
          '@referredType': 'individual',
        },
      ],
    'payment':
      [
        {
          'id': 'creditcard/cash/bill',
          'href': 'string',
          'name': 'Pay on Delivery/Credit Card/Bill',
          '@baseType': 'string',
          '@schemaLocation': 'string',
          '@type': 'string',
          '@referredType': 'string',
        },
      ],
    'note':
      [
        {
          'id': 'address',
          'author': 'string',
          'date': '',
          'text': address,
          '@baseType': 'string',
          '@schemaLocation': 'string',
          '@type': 'string',
        },
      ],
    'orderTotalPrice': [
      {
        'price': {
          'priceType': 'total',
          'unitOfMeasure': 'ALL',
          'price': {
            ...selectedOffer?.data?.recommendationItem[0]?.product?.productPrice?.price,
            '@baseType': 'Price',
            '@type': 'Price',
          },
        },
      },
    ],
  }

  const params = {
    'type': 'acquisition',
  }

  const headers = {
    ...COMMON_HEADERS,
    'vf-project': 'dnext',
  }

  try {
    const response = await axios.post(API_URLS.acquisition.order, requestData, {
      params,
      headers,
    })
    const _response = response?.data
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const getOffers = async (msisdn, customHeaders, utilities) => {

  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
  })

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

  onStart()

  const params = {
    'relatedParty.id': msisdn,
    'type': 'acquisition',
  }

  const headers = {
    ...COMMON_HEADERS,
    ...customHeaders,
  }

  try {
    const response = await axios.get(API_URLS.acquisition.getOffers, {
      params,
      headers,
    })

    const _response = responseMapper(response?.data)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const getResources = async (data, customHeaders, utilities) => {

  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
  })

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

  onStart()

  const headers = {
    ...COMMON_HEADERS,
    ...customHeaders,
  }

  const params = {
    'id': data?.searchedNumber,
    'resourceCharacteristic.name': 'orderId',
    'resourceCharacteristic.value': data?.accountNumber,
  }

  try {
    const response = await axios.get(API_URLS.acquisition.getResources, {
      headers,
      params,
    })

    const _response = responseMapper(response?.data)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}


export {
  getCostumer,
  postCostumer,
  productOrder,
  getOffers,
  getResources,
}
