import React, { useContext } from 'react'
import { View, FlatList, Dimensions, ScrollView } from 'react-native'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { VFText, VFButton, Card, Icon } from '@vfgroup-oneplatform/foundation/Components'
import { Images } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { getMenu } from '../../../../Utils'

import NavigationService from '../../../../Navigation/NavigationService'

import TobiSupportContext from '../../../../Utils/TobiSupportContext'

import ReduxCache from '../../../../Utils/ReduxCache'

import styles from './TobiModalContent.Styles'


const TobiModalContent = ({ closeModal, navigationRef }) => {

  const width = Dimensions.get('screen')?.width - 32

  const tobiContext = useContext(TobiSupportContext)

  const userType = ReduxCache.getUserType()


  const configurationsRedux = useSelector(store => store.configurations)
  const menu = getMenu(configurationsRedux) || []

  const tobiUrl = menu?.find(el => el?.key === 'itemsTobi')?.text

  const mapScreen = (screenName) => {
    if (screenName === 'ProductsAndServices') {
      return 'P&S'
    }
    if (screenName === 'FixProductsAndServices') {
      return 'FixP&S'
    }
    else {
      return screenName
    }
  }

  const openTobiUrl = () => {
    closeModal()
    navigationRef.navigate('WebView', { url: tobiUrl, isReact: true, incognito: true })
  }

  const supportItems = menu.find(el => el.key === `itemsSupport${mapScreen(navigationRef.getCurrentRoute()?.name)}`)?.subItems || []

  if (navigationRef.getCurrentRoute()?.name === 'Dashboard') {
    return (
      <View style={styles.container}>
        <VFText i18nKey="support_dashboard_modal_title" style={styles.title} />
        <VFText i18nKey="support_dashboard_modal_subtitle" style={styles.subTitle} />
        <VFButton
          title="support_dashboard_help_button"
          type="tertiary"
          style={styles.btn}
          onPress={openTobiUrl}
        />
        <VFText i18nKey="support_dashboard_modal_desc" style={styles.listHeader} />
        <FlatList
          data={supportItems}
          showsVerticalScrollIndicator={false}
          renderItem={({ item, index }) => {
            return (
              <Card
                hasShadow
                type="main"
                style={styles.cardContainer}
                onPressCard={() => {
                  closeModal()
                  if (item.screen !== 'guides') {
                    NavigationService.navigate('Tutorial', { tutorialKey: item?.screen })
                  } else {
                    setTimeout(() => {
                      tobiContext.toolTipsModalRef?.current?.toggleModal()
                    }, 1000)
                  }
                }}
              >
                <VFText
                  i18nKey={item?.value}
                  style={styles.cardTitle}
                />
                <Icon
                  name={Images.ic_chevron_right_red}
                  size={30}
                  resizeMode="contain"
                  style={styles.iconStyle}
                />
              </Card>
            )
          }}
        />
      </View>
    )
  }

  return (
    <ScrollView style={styles.container}>
      {
        supportItems.map(item => {
          return (
            <View>
              <VFText i18nKey={item?.screen} style={styles.title} />
              <Icon
                source={{ uri: item?.text }}
                size={width}
                style={styles.iconStyle}
              />
              <VFText
                i18nKey={item?.value}
                style={styles.text}
              />
            </View>
          )
        })
      }
    </ScrollView>
  )

}
TobiModalContent.defaultProps = {
  closeModal: () => { },
}

TobiModalContent.propTypes = {
  closeModal: PropTypes.func,
  navigationRef: PropTypes.object,
}


export default TobiModalContent
