import React, { useState } from 'react'
import { View, TouchableOpacity, useWindowDimensions } from 'react-native'
import { Icon, VFText } from '@vfgroup-oneplatform/foundation/Components'

import SpinnerOverlay from '@vfgroup-oneplatform/foundation/Components/SpinnerOverlay'

import ReduxCache from '../../Utils/ReduxCache'

import { store } from '../../Redux'

import { Actions as LandingScreenActions } from '../../Containers/LandingScreen/LandingScreen.Actions'

import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import Images from '../../Themes/Images'
import styles from './CustomLanguageChanger.Styles'
import { setStoriesInStore } from '../../Containers/Story/Story.Actions'

const CustomLanguageComponent = ({ containerStyle, languageChanger }) => {

  const [langugageChangeLoader, setLanguageChangeLoader] = useState(false)

  const changeLanguage = async (selectedLanguage) => {

    setLanguageChangeLoader(true)
    const res = await ReduxCache.getLanguage()

    if (res !== selectedLanguage) {
      store.dispatch({ type: 'UPDATE_LANGUAGE', data: selectedLanguage })
      ContentManager.setLocale(selectedLanguage)
      await setStoriesInStore()
      await LandingScreenActions.getLP()
      languageChanger(selectedLanguage)
    }
    setLanguageChangeLoader(false)
    return
  }

  const { width } = useWindowDimensions()

  return (
    <View style={[styles.flagsContainer, containerStyle]}>
      <TouchableOpacity style={styles.circleFlag} onPress={() => {
        changeLanguage('al')
      }}>
        <Icon name={Images.icAlbanianFlag} size={20} resizeMode={'cover'} />
      </TouchableOpacity>

      <VFText i18nKey=" | " style={styles.flagSeparator} />

      <TouchableOpacity style={styles.circleFlag} onPress={() => {
        changeLanguage('en')
      }}>
        <Icon name={Images.icBritishFlag} size={20} resizeMode={'cover'} />
      </TouchableOpacity>

      {langugageChangeLoader &&
        <SpinnerOverlay
          type="spinner"
          autoPlay
          loop
          isVisible
          lottieStyle={styles.lottie(width)} />
      }
    </View>
  )
}

export default CustomLanguageComponent