import React from 'react'
import { View, ImageBackground } from 'react-native'
import PropTypes from 'prop-types'

import { getThemeImages } from '../../../Themes'
import GeneralComponent from '../GeneralComponent'

import styles from './ThirdComponent.Styles'

import { useSelector } from 'react-redux'

import {
  withTheme,
  LightThemeColors,
  useTheme
} from '@vfgroup-oneplatform/foundation/Components/Themes'

const ThirdComponent = ({
  extraInfo,
  placeHolders
}) => {
  const theme = useTheme()
  const Images = getThemeImages(theme.name)

  const pegaOffers = useSelector(state => state.pega.data?.characteristics) || {}

  const shouldShowOffersAnimation = Object.keys(pegaOffers)?.filter(key => key !== 'Dummy|Services|Affiliate') || []

  return (
    <View style={styles.container}>
      <ImageBackground source={Images.ic_tile_background} style={styles.image}>
        <GeneralComponent
          hasBackground
          image={Images[extraInfo.icon]}
          subTitle={extraInfo.subTitle}
          title={extraInfo.title}
          placeHolders={placeHolders}
          onPress={extraInfo.onPress}
          shouldShowOffersAnimation={(shouldShowOffersAnimation?.length > 0) && extraInfo.key === 'recommendedoffers'}
        />
      </ImageBackground>
    </View>
  )
}

ThirdComponent.defaultProps = {
  theme: LightThemeColors
}
ThirdComponent.propTypes = {
  theme: PropTypes.objectOf({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  })
}

export default withTheme(ThirdComponent)
