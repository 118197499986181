/* eslint-disable react-native/no-inline-styles */
import React, { useRef, useState, useEffect } from 'react'
import {
  View,
  TouchableOpacity,
  KeyboardAvoidingView,
  ScrollView,
  Keyboard,
  Platform,
  Dimensions,
} from 'react-native'
import PropTypes from 'prop-types'

import {
  VFText,
  VFInput,
  VFButton,
  LeftIconErrorMessage,
  VFDropdown,
  QuickActionMenu,
  Icon,
} from '@vfgroup-oneplatform/foundation/Components'
// import VFCodeInput from '@vfgroup-oneplatform/login/Screens/VerifyCode/VFCodeInput/VFCodeInput'
import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

// import VFCodeInput from 'rn-vfg-login/Screens/VerifyCode/VFCodeInput/VFCodeInput'
import VFCodeInput from '@vfgroup-oneplatform/login/Screens/VerifyCode/VFCodeInput/VFCodeInput'

import Modal from 'react-native-modal'

import MigrationLottie from '../../Components/MigrationLottie/MigrationLottie'

import { validatePhoneNumber, formatPhoneNumber, validatePersonalNumber, validateNipt } from '../../Utils/Regex'

import { getContracts, addManualAgreement } from '../../Services/Fix'

import { Actions } from '../VerifyCode/VerifyCode.Actions'

import { Actions as LoginActions } from '../Login/Login.Actions'

import { isFixUser } from '../../Utils/Helpers'

import styles from './FixManualProduct.Styles'


const IdTypes = [
  {
    id: 0,
    value: 'individual',
    label: 'fix_manual_personal_id'
  },
  {
    id: 1,
    value: 'organization',
    label: 'fix_manual_nipt'
  },
]

const FirstStep = ({
  changeActiveStep,
  toggleModal,
}) => {
  return (
    <View style={{ flex: 1 }}>
      <VFText
        i18nKey="fix_manual_product_subtitle"
        style={styles.title}
      />
      <VFText
        i18nKey="fix_manual_product_first_step_desc"
        style={styles.desc}
      />
      <View style={styles.buttonContainer}>
        <VFButton
          title="fix_manual_product_add_contract"
          style={styles.button}
          onPress={() => changeActiveStep(steps.addData)}
        />
        <VFButton
          title="fix_manual_product_cancel"
          style={styles.button}
          type="secondary"
          onPress={toggleModal}
        />
      </View>
    </View>
  )
}
FirstStep.propTypes = {
  changeActiveStep: PropTypes.func,
  toggleModal: PropTypes.func,
}

const SecondStep = ({
  changeActiveStep,
  toggleModal,
}) => {
  return (
    <View style={{ flex: 1 }}>
      <VFText
        i18nKey="fix_manual_product_subtitle"
        style={styles.title}
      />
      <VFText
        i18nKey="fix_manual_product_second_step_desc"
        style={styles.desc}
      />
      <View style={[styles.buttonContainer, { flex: 1 }]}>
        <View style={{ flex: 1 }} />
        <VFButton
          title="fix_manual_product_add_contract"
          style={styles.button}
          onPress={() => changeActiveStep(steps.addData)}
        />
        <VFButton
          title="fix_manual_product_cancel"
          style={styles.button}
          type="secondary"
          onPress={toggleModal}
        />
      </View>
    </View>
  )
}
SecondStep.propTypes = {
  changeActiveStep: PropTypes.func,
  toggleModal: PropTypes.func,
}

const AddData = ({
  toggleModal,
  identification,
  setIdentification,
  phoneNumber,
  setPhoneNumber,
  getVerifyPin,
  setIdType,
  idType,
  theme,
}) => {

  const identificationRef = useRef()
  const msisdnRef = useRef()

  const [numberError, setNumberError] = useState(false)
  const [identificationError, setIdentificationError] = useState(false)

  const checkPhoneNumber = () => setNumberError(!validatePhoneNumber(phoneNumber))

  const checkNipOrId = () => {
    if (!validatePersonalNumber(identification) && !validateNipt(identification)) {
      setIdentificationError(true)
    } else {
      setIdentificationError(false)
    }
  }

  return (
    <View
      style={{ flex: 1 }}
    >
      <ScrollView contentContainerStyle={{ flex: 1 }}>
        <VFText
          i18nKey="fix_manual_product_subtitle"
          style={styles.title}
        />
        <VFText
          i18nKey="fix_manual_product_add_data_step_desc"
          style={styles.desc}
        />
        <VFDropdown
          items={IdTypes}
          placeholder={{ label: IdTypes.find(el => el.value === idType)?.label || 'fix_manual_id_type' }}
          isArrowShown={true}
          style={styles.dropdown}
          onValueChange={(type) => setIdType(type)}
          labelStyle={styles.inputText}
          containerStyle={styles.dropdownContainer}
          optionsStyle={{ top: 0 }}
        />
        <VFInput
          fieldRef={ref => {
            identificationRef.current = ref
          }}
          label={idType === 'individual' ? 'fix_manual_product_id' : (idType === 'organization' ? 'fix_manual_product_nipt' : 'fix_manual_product_nipt_or_id')}
          value={identification}
          onChangeText={setIdentification}
          containerStyle={styles.input}
          labelTextStyle={styles.inputText}
          baseColor={identificationError ? theme.colors.textInput.error.tintColor : theme.colors.textInput.default.baseColor}
          textColor={theme.colors.textColor}
          tintColor={theme.colors.textInput.default.tintColor}
          onBlur={checkNipOrId}
          showHelperText={identificationError}
          helperText="fix_manual_product_wrong_nipt_or_id_format"
          autoCapitalize="characters"
          helperTextColor={theme.colors.textInput.error.tintColor}
        />
        <VFInput
          fieldRef={ref => {
            msisdnRef.current = ref
          }}
          label="fix_manual_product_msisdn"
          value={phoneNumber}
          onChangeText={setPhoneNumber}
          containerStyle={styles.input}
          labelTextStyle={styles.inputText}
          baseColor={numberError ? theme.colors.textInput.error.tintColor : theme.colors.textInput.default.baseColor}
          textColor={theme.colors.textColor}
          tintColor={theme.colors.textInput.default.tintColor}
          onBlur={phoneNumber && checkPhoneNumber}
          showHelperText={numberError}
          helperText="fix_manual_product_wrong_number_format"
          keyboardType="phone-pad"
          helperTextColor={theme.colors.textInput.error.tintColor}
        />
      </ScrollView>

      <View style={[styles.buttonContainer, { flex: 1, justifyContent: 'flex-end' }]}>
        <VFButton
          title="fix_manual_product_add_contract"
          style={styles.button}
          onPress={getVerifyPin}
          disabled={numberError || !identification || !phoneNumber || identificationError || !idType}
          disabledStyle={styles.disabledStyle(theme)}
        />
        <VFButton
          title="fix_manual_product_cancel"
          style={styles.button}
          type="secondary"
          onPress={toggleModal}
        />
      </View>
    </View>
  )
}
AddData.propTypes = {
  toggleModal: PropTypes.func,
  identification: PropTypes.string,
  setIdentification: PropTypes.func,
  phoneNumber: PropTypes.string,
  setPhoneNumber: PropTypes.func,
  getVerifyPin: PropTypes.func,
  setIdType: PropTypes.func,
  idType: PropTypes.string,
  theme: PropTypes.object,
}

const ErrorStep = ({
  changeActiveStep,
  toggleModal,
  error,
}) => {
  return (
    <View style={{ flex: 1 }}>
      <VFText
        i18nKey="fix_manual_product_subtitle"
        style={styles.title}
      />
      <VFText
        i18nKey={error || 'fix_manual_product_default_error_message'}
        style={styles.desc}
      />
      <View style={[styles.buttonContainer, { flex: 1 }]}>
        <View style={{ flex: 1 }}>
          {Platform.OS === 'web' && Dimensions.get('window').width > 700 &&
            <Icon
              type="image"
              size={54}
              name="error"
            />
          }
        </View>
        <VFButton
          title="fix_manual_product_try_again"
          style={styles.button}
          onPress={() => changeActiveStep(steps.addData)}
        />
        <VFButton
          title="fix_manual_product_cancel"
          style={styles.button}
          type="secondary"
          onPress={toggleModal}
        />
      </View>
    </View>
  )
}
ErrorStep.propTypes = {
  changeActiveStep: PropTypes.func,
  toggleModal: PropTypes.func,
  error: PropTypes.string,
}

const Verify = ({
  phoneNumber,
  resendPinLoading,
  resendPin,
  formattedCount,
  pinError,
  changeActiveStep,
  pin,
  setPin,
  pinLoading,
  setIsLoading,
  setPinError,
  addNewContract,
  theme,
}) => {

  const cancelStep = () => {
    changeActiveStep(steps.addData)
    setIsLoading(false)
    setPinError(false)
  }

  return (
    <KeyboardAvoidingView
      behavior={'padding'}
      keyboardVerticalOffset={180}
      style={{ flex: 1 }}
    >
      <ScrollView contentContainerStyle={{ flex: 1 }}>
        <View style={{ flex: 1 }} >
          <VFText
            i18nKey="fix_manual_product_verify_step_title"
            style={styles.title}
          />
          <VFText
            i18nKey="fix_manual_product_verify_step_subtitle"
            style={styles.desc}
          />
          <VFText
            i18nKey={phoneNumber}
            style={styles.numberText}
          />
          {pinError && <LeftIconErrorMessage
            errorMessage="fix_manual_product_verify_code_error"
            style={styles.errorContainer}
            iconContainerStyle={styles.errorIcon}
          />}
          <VFText
            i18nKey="fix_manual_product_verify_step_desc"
            style={styles.desc}
          />

          <VFCodeInput
            codeLength={5}
            keyboardType="default"
            onFulfill={(pinText) => {
              setPin(pinText)
              Keyboard.dismiss()
            }}
            colors={{
              textInput: {
                tintColor: theme.colors.textInput.default.tintColor,
              }
            }}
            codeInputTextStyle={styles.verifyCodeCell(theme)}
          />
          <TouchableOpacity onPress={resendPin}>
            {
              resendPinLoading ? <VFText
                i18nKey="fix_manual_product_verify_code_resend_code_disabled"
                placeHolders={[formattedCount]}
                style={styles.resendTextDisabled(theme)}
              /> : <VFText
                i18nKey="fix_manual_product_verify_code_resend_code"
                style={styles.resendText}
              />
            }
          </TouchableOpacity>
          <View style={{ flex: 1 }} />
          <VFButton
            title="fix_manual_product_add_contract"
            onPress={addNewContract}
            disabled={!pin || pinError || pinLoading}
            style={styles.button}
            disabledStyle={styles.disabledStyle(theme)}
          />
          <VFButton
            title="fix_manual_product_cancel"
            type="secondary"
            style={styles.button}
            onPress={cancelStep}
          />
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  )
}
Verify.propTypes = {
  phoneNumber: PropTypes.string,
  resendPinLoading: PropTypes.func,
  resendPin: PropTypes.bool,
  confirmPin: PropTypes.func,
  formattedCount: PropTypes.string,
  pinError: PropTypes.bool,
  changeActiveStep: PropTypes.func,
  pin: PropTypes.string,
  setPin: PropTypes.func,
  pinLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  setPinError: PropTypes.func,
  addNewContract: PropTypes.func,
  theme: PropTypes.object,
}

const FinalStep = ({
  changeActiveStep,
  toggleModal,
  contracts,
  theme,
}) => {


  return (
    <View style={{ flex: 1 }} >
      <VFText
        i18nKey="fix_manual_product_final_step_subtitle"
        style={styles.title}
      />
      <VFText
        i18nKey="fix_manual_product_final_step_desc"
        style={styles.desc}
      />

      <ScrollView style={styles.listContainer} horizontal showsHorizontalScrollIndicator={false} >
        {contracts?.map((item) => {
          return (
            <View style={[styles.fixedLineContainer, { backgroundColor: theme.name === 'dark' ? theme.colors.cardBackgroundColorTwo : 'transparent' }]}>
              <VFText
                i18nKey={item?.name}
                style={styles.boldText}
              />
            </View>
          )
        })}
      </ScrollView>

      <View style={[styles.buttonContainer, { flex: 1 }]}>
        <View style={{ flex: 1 }} />
        <VFButton
          title="fix_manual_product_add_contract"
          style={styles.button}
          onPress={() => changeActiveStep(steps.addData)}
        />
        <VFButton
          title="fix_manual_product_close"
          style={styles.button}
          type="secondary"
          onPress={toggleModal}
        />
      </View>
    </View >
  )
}
FinalStep.propTypes = {
  changeActiveStep: PropTypes.func,
  toggleModal: PropTypes.func,
  contracts: PropTypes.array,
  theme: PropTypes.object,
}

const Loading = () => {
  return (
    <View onStartShouldSetResponder={() => true}>
      <View style={styles.modalContentWraper}>
        <MigrationLottie
          style={styles.lottie}
        />
      </View>
    </View>
  )
}
Loading.propTypes = {
  setActiveScreen: PropTypes.func,
}

const steps = {
  first: 'first',
  second: 'second',
  addData: 'addData',
  error: 'error',
  verify: 'verify',
  final: 'final',
  loading: 'loading',
}

const Steps = {
  first: FirstStep,
  second: SecondStep,
  addData: AddData,
  error: ErrorStep,
  verify: Verify,
  final: FinalStep,
  loading: Loading,
}

const FixManualProduct = ({
  isVisible,
  toggleModal,
  refreshDashboard
}) => {

  const [activeStep, setActiveStep] = useState(steps.addData)

  const Step = Steps[activeStep]

  const theme = useTheme()

  const [identification, setIdentification] = useState()
  const [phoneNumber, setPhoneNumber] = useState()
  const [idType, setIdType] = useState()

  const [error, setError] = useState()

  const [pinLoading, setIsLoading] = useState(false)
  const [contracts, setContracts] = useState([])

  const [pin, setPin] = useState(null)
  const [resendPinLoading, setResendPinLoading] = useState(false)
  const [pinError, setPinError] = useState(false)

  const formatCount = (sec) => {
    if (sec.toString().length === 1) {
      return '0:0' + sec
    }
    return '0:' + sec
  }

  const [count, setCount] = useState(30)
  const [formattedCount, setFormattedCount] = useState(formatCount(count))

  const contractType = 'Fixed'

  const resetData = () => {
    setIdentification('')
    setPhoneNumber('')
    setIdType('')
    setError('')
    setPin(null)
  }

  const getData = () => {
    getContracts(contractType, {
      onSuccess: (response) => {
        setIsLoading(false)
        setContracts(response)
        setActiveStep(steps.final)
      },
      onError: (err) => {
        setError(err)
        // setActiveStep(steps.error)
      }
    }
    )
  }

  const getVerifyPin = () => {
    setPinError(false)
    setActiveStep(steps.loading)
    Actions.generatePin(formatPhoneNumber(phoneNumber))
      .then(() => {
        setActiveStep(steps.verify)
      })
      .catch((er) => {
        setError(er?.data?.message || '')
        setActiveStep(steps.error)
      })
  }

  const resendPin = () => {
    setPin('')
    setResendPinLoading(true)
    getVerifyPin()
  }

  const addNewContract = () => {
    setIsLoading(true)
    setActiveStep(steps.loading)

    addManualAgreement(pin, identification, idType, formatPhoneNumber(phoneNumber), {
      onSuccess: async () => {
        resetData()
        getData()

        if (!isFixUser()) {
          await LoginActions.refreshToken()

          setTimeout(() => {
            refreshDashboard()
          }, 300)
        }

      },
      onError: (er) => {
        setIsLoading(false)
        setActiveStep(steps.error)
        setError(er)
      }
    })
  }

  useEffect(() => {
    if (resendPinLoading) {
      const interval = setInterval(() => {
        setCount(count - 1)
        setFormattedCount(formatCount(count - 1))
      }, 1000)

      if (count === 0) {
        setResendPinLoading(false)
      }
      return () => {
        clearInterval(interval)
      }
    }
  }, [count, resendPinLoading])

  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
    return (
      <Modal
        isVisible={isVisible}
        backdropOpacity={0.5}
        // onSwipeComplete={toggleModal}
        // onBackdropPress={toggleModal}
        propagateSwipe
        hideModalContentWhileAnimating
        hardwareAccelerated
        backdropTransitionOutTiming={0}
        style={{
          justifyContent: 'flex-end',
          margin: '0 !important',
          minWidth: 1920,
          maxWidth: 1920,
        }}
      >
        <View style={{
          minWidth: 600,
          minHeight: 600,
          backgroundColor: theme.colors.quickActionGradientColors.secondary,
          margin: 'auto',
          padding: '20px',
          borderRadius: '7px',
        }}>
          <Step
            changeActiveStep={setActiveStep}
            toggleModal={toggleModal}
            identification={identification}
            setIdentification={setIdentification}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            getVerifyPin={getVerifyPin}
            resendPinLoading={resendPinLoading}
            resendPin={resendPin}
            formattedCount={formattedCount}
            pinError={pinError}
            pin={pin}
            setPin={setPin}
            error={error}
            contracts={contracts}
            idType={idType}
            setIdType={setIdType}
            pinLoading={pinLoading}
            setIsLoading={setIsLoading}
            addNewContract={addNewContract}
            setPinError={setPinError}
            theme={theme}
          />
        </View>
      </Modal>
    )
  }

  return (
    <QuickActionMenu
      title="fix_manual_product_title"
      isVisible={isVisible}
      toggleModal={toggleModal}
      type="secondary"
      titleStyle={styles.titleStyle}
      closeBtnStyle={styles.closeStyle}
    >
      <Step
        changeActiveStep={setActiveStep}
        toggleModal={toggleModal}
        identification={identification}
        setIdentification={setIdentification}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        getVerifyPin={getVerifyPin}
        resendPinLoading={resendPinLoading}
        resendPin={resendPin}
        formattedCount={formattedCount}
        pinError={pinError}
        pin={pin}
        setPin={setPin}
        error={error}
        contracts={contracts}
        idType={idType}
        setIdType={setIdType}
        pinLoading={pinLoading}
        setIsLoading={setIsLoading}
        addNewContract={addNewContract}
        setPinError={setPinError}
        theme={theme}
      />
    </QuickActionMenu>
  )
}

FixManualProduct.propTypes = {
  isVisible: PropTypes.bool,
  toggleModal: PropTypes.func,
  refreshDashboard: PropTypes.func
}

export default FixManualProduct
