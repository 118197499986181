import React from 'react'
import { VFText, Icon, VFTouchableInScroll } from '@vfgroup-oneplatform/foundation/Components'
import { withTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { View } from 'react-native'

import PropTypes from 'prop-types'

import styles from '../FourthComponent.Styles'

const TopUp = ({ theme, image, onPress, title, value, userName, currency }) => {
  return (
    <VFTouchableInScroll onPress={onPress}>
      <View style={styles.topUpContiner}>
        <View style={styles.iconAndTitleContainer}>
          <Icon source={image} style={styles.icon} size={26} />
          <VFText i18nKey={title} style={styles.title} />
        </View>
        <View style={[styles.subtitleConatier, styles.topUpSubtitleContainer]}>
          <VFText i18nKey={value} style={styles.subtitle} />
          <VFText i18nKey={currency} style={styles.subtitle} />
          <VFText
            i18nKey={'dashboard_card_topup_user_phone'}
            placeHolders={[userName]}
            style={styles.rightSubtitle(theme)}
          />
        </View>
      </View>
    </VFTouchableInScroll>
  )
}

TopUp.propTypes = {
  theme: PropTypes.objectOf({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  }),
  image: PropTypes.object,
  onPress: PropTypes.func
}

export default withTheme(TopUp)
