import React from 'react'
import { View, Pressable, Platform, Dimensions } from 'react-native'
import PropTypes from 'prop-types'
import { VFText, Icon } from '@vfgroup-oneplatform/foundation/Components'
import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'
import { Fonts, useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { Images } from '../../../../Themes'

import { getStatusTitle } from '../utils'

import styles from './InProgressOrdersSubCard.styles'

const InProgressOrdersSubCard = ({ item }) => {

  const theme = useTheme()

  let image

  if (item.orderIcon === 'Internet' || item.orderIcon === 'Modem') {
    image = Images.modem
  } else if (item.orderIcon === 'CableTv' || item.orderIcon === 'SmartCard') {
    image = Images.tv
  } else {
    image = Images.tv_and_modem
  }

  const status = getStatusTitle(item.status)

  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
    return (
      <View style={styles.card(theme)}>
        <VFText style={styles.itemNameText(theme)} i18nKey={item.title} />
        <View style={{ width: '100%', height: 1, backgroundColor: '#EBEBEB', marginVertical: 20 }} />


        <View style={styles.itemDetailsContainer}>
          <View style={{ flex: 0.5, justifyContent: 'flex-start' }}>
            <Icon
              size={80}
              type="image"
              style={styles.image}
              name={image}
            />
          </View>
          <View style={styles.itemDetailsSubContainer}>
            <View style={styles.itemDetailsSubContainerChild}>
              <VFText style={styles.statusText} i18nKey="my_orders_status" />
              <VFText
                style={[styles.statusText, Fonts.type.bold]}
                i18nKey={ContentManager.translate(status)}
              />
            </View>
            <View style={styles.itemDetailsSubContainerChild}>
              <VFText
                style={styles.statusText}
                i18nKey="my_orders_price"
              />
              <View style={styles.statusMultilineContainer}>
                <VFText
                  style={[styles.statusText, Fonts.type.bold]}
                  i18nKey={item.price}
                />
              </View>
            </View>
          </View>
        </View>


        <VFText
          style={styles.itemUpdatedAt(theme)}
          i18nKey="my_orders_start_date"
          placeHolders={[item.startDate]}
        />

      </View>
    )
  }

  return (
    <View style={styles.card(theme)}>
      <View style={styles.itemNameContainer(theme)}>
        <VFText style={styles.itemNameText(theme)} i18nKey={item.title} />
      </View>

      <Pressable>
        <View style={styles.itemDetailsContainer}>
          <Icon
            size={80}
            type="image"
            style={styles.image}
            name={image}
          />
          <View style={styles.itemDetailsSubContainer}>
            <View style={styles.itemDetailsSubContainerChild}>
              <VFText style={styles.statusText} i18nKey="my_orders_status" />
              <VFText
                style={[styles.statusText, Fonts.type.bold]}
                i18nKey={ContentManager.translate(status)}
              />
            </View>
            <View style={styles.itemDetailsSubContainerChild}>
              <VFText
                style={styles.statusText}
                i18nKey="my_orders_price"
              />
              <View style={styles.statusMultilineContainer}>
                <VFText
                  style={[styles.statusText, Fonts.type.bold]}
                  i18nKey={item.price}
                />
              </View>
            </View>
          </View>
        </View>

        <View style={styles.itemUpdatedAtContainer}>
          <VFText
            style={styles.itemUpdatedAt(theme)}
            i18nKey="my_orders_start_date"
            placeHolders={[item.startDate]}
          />
        </View>
      </Pressable>
    </View>
  )
}


InProgressOrdersSubCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    title: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    startDate: PropTypes.string,
    orderIcon: PropTypes.string,
  }),
  onItemPress: PropTypes.func,
  orderId: PropTypes.string,
}

export default InProgressOrdersSubCard
