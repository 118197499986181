import { store } from '../Redux/CreateStore'

import { formatNumber } from './Regex'

const ReduxCache = {
    cacheObj: { language: 'al' },
    get(path, cache = false) {
        if (cache && this.cacheObj[path]) {
            return this.cacheObj[path]
        }

        // const keys = path.split('.')
        // const data = store.getState()

        // let value = data
        // keys.map(key => {
        //     value = value[key]
        // })

        // this.cacheObj[path] = value

        // return this.cacheObj[path]
        try {
            const keys = path.split('.')
            const data = store.getState()
            let value = data
            keys.map(key => {
                value = value[key]
            })
            this.cacheObj[path] = value

            return this.cacheObj[path]
        } catch (error) {
            return ''
        }
    },
    set(key, value) {
        this.cacheObj[key] = value
    },
    remove(key) {
        delete this.cacheObj[key]
        console.log(this.get('auth.userType'))
    },
    clear() {
        this.cacheObj = {}
    },
    getMsisdn() {
        return this.get('auth.msisdn')
    },
    getDXLToken() {
        return this.get('auth.access_token')
    },
    getToken() {
        return this.get('auth.ms_access_token')
    },
    getLanguage() {
        return this.get('language')
    },
    getUserType() {
        return this.get('auth.userType')
    },
    getBalance(full = true) {
        const balance = this.get('balance.data.balance.details.amount')

        if (full) {
            return formatNumber(balance.$) + ' ' + balance['@currency-id']
        }
        return {
            amount: formatNumber(balance.$),
            unit: ' ' + balance['@currency-id']
        }
    },
    getBusinessAccount() {
        return this.get('auth.businessAccount')
    },
    getMinVersion() {
        return this.get('configurations.config.min_app_version')
    }
}

export default ReduxCache
