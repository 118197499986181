import React from 'react'
import { ScrollView, View } from 'react-native'

import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'
import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { VFText, Badge } from '@vfgroup-oneplatform/foundation/Components'
import PropTypes from 'prop-types'

import OrderSummaryData from '../OrderSummaryData'

import { mapStatusToTag, getStatusTitle } from '../utils'

import styles from './OrderSummaryDataModal.Styles'

const OrderSummaryDataModal = props => {

  const theme = useTheme()
  const { order } = props

  const getOrderItemSummaryData = orderData => {
    return [
      {
        key: 'my_orders_summary_total_without_tax',
        value: `${orderData.pricePlan.dutyFreePrice} ${order.pricePlan.unit}`
      },
      {
        key: 'my_orders_summary_total_tax_rate',
        value: `${orderData.pricePlan.taxRate} %`
      },
      {
        key: 'my_orders_summary_total_device_cost',
        value: `${orderData.pricePlan.totalAmount} ${order.pricePlan.unit}`,
        keyStyle: styles.deviceTotalCost
      }
    ]
  }

  return (
    <>
      <View style={styles.container}>
        <VFText
          type="primary"
          style={styles.sectionTitle}
          i18nKey="my_orders_order_number"
        />
        <View style={styles.orderNumContainer}>
          <VFText
            type="primary"
            style={styles.orderNumber}
            i18nKey={order.id.toString()}
          />
          <Badge
            text={ContentManager.translate(getStatusTitle(order.state))}
            type={mapStatusToTag(order.state)}
            style={styles.badgeStyle}
            textStyle={styles.badgeTextStyle}
          />
        </View>
      </View>

      <ScrollView style={styles.scrollView}>
        {order.items.map(orderData => (
          <OrderSummaryData
            icon={orderData.image}
            title={orderData.category}
            subTitle={orderData.title}
            details={getOrderItemSummaryData(orderData)}
          />
        ))}
      </ScrollView>

      <VFText
        style={styles.totalPayText}
        i18nKey="my_orders_summary_total_to_pay"
      />
      <View style={styles.totalPayContent}>
        <View style={styles.separator(theme)} />
        <View style={styles.totalPayContentRow}>
          <VFText
            style={styles.contentText}
            i18nKey="my_orders_summary_total_without_tax"
          />
          <VFText
            style={styles.contentText}
            i18nKey={`${order.pricePlan.dutyFreePrice} ${order.pricePlan.unit}`}
          />
        </View>
        <View style={[styles.totalPayContentRow]}>
          <VFText
            style={styles.contentText}
            i18nKey={ContentManager.translate(
              'my_orders_summary_total',
              [order.pricePlan.perMonth]
            )}
          />
          <VFText
            style={styles.contentText}
            i18nKey={`${order.pricePlan.total} ${order.pricePlan.unit}`}
          />
        </View>
      </View>
    </>
  )
}
OrderSummaryDataModal.propTypes = {
  order: PropTypes.object
}
export default OrderSummaryDataModal
