import React from 'react'
import PropTypes from 'prop-types'

import { View, Text } from 'react-native'

import { VFText, Icon, VFButton } from '@vfgroup-oneplatform/foundation/Components'
import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import styles from './InfoOverlay.Styles'
import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

const InfoOverlay = ({ title, icon, description, steps, onPress, containerStyle, titleStyle, iconSize, withButton = false, buttonStyle, withSecondayButton = false, secondarytButtonStyle, secondaryOnPress }) => {

  const theme = useTheme()

  return (
    <View style={[styles.container, containerStyle]}>
      {title && <VFText i18nKey={ContentManager.translate(title)} style={[styles.title, titleStyle]} />}
      <View style={styles.iconContainer}>
        <Icon name={icon} size={iconSize || 100} style={styles.iconStyle} />
      </View>
      <VFText i18nKey={description} style={styles.description} />

      <View style={styles.stepsContainer}>
        {steps.map((step, index) => {
          return (
            <View style={styles.stepContainer}>
              <View style={styles.numberBorder(theme)}>
                <Text style={styles.number(theme)}>{index + 1}</Text>
              </View>
              <VFText i18nKey={step} style={styles.stepText} />
            </View>
          )
        })}
      </View>
      {withSecondayButton && <VFButton type='primary' title="roaming_go_to_settings" style={[styles.secondaryButton, secondarytButtonStyle]} onPress={secondaryOnPress} />}
      {withButton && <VFButton type='secondary' title="roaming_close" style={[styles.button, buttonStyle]} onPress={onPress} />}
    </View>
  )
}

InfoOverlay.defaultProps = {}

InfoOverlay.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.string,
  steps: PropTypes.array,
  onPress: PropTypes.func,
  containerStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  iconSize: PropTypes.number,
  withButton: PropTypes.bool,
  buttonStyle: PropTypes.object
}


export default InfoOverlay;