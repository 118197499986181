import React from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'

import { Icon, VFButton, VFText } from '@vfgroup-oneplatform/foundation/Components'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { getThemeImages } from '../../Group/Themes'

import styles from './DeactivateAccount.Styles'


function Card({ icon, title, description, onPress }) {
    const theme = useTheme()

    const Images = getThemeImages(theme.name)

    return (
        <View>
            <View style={styles.content} >
                <View style={styles.titleContainer}>
                    <Icon
                        type="image"
                        source={Images[icon]}
                        size={34}
                        style={styles.icon}
                    />
                    <VFText type="primary" i18nKey={title} style={styles.title} />

                </View>

                {description ? (
                    <View style={styles.subTitleContainer}>
                        <VFText
                            type="primary"
                            i18nKey={description}
                            style={styles.subTitle}
                        />
                    </View>
                ) : null}
            </View>
            <View style={styles.separator(0)} />
            <VFButton title="delete_account_delete_button" onPress={onPress} style={styles.deleteButton} />
        </View>

    )
}

Card.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    onPress: PropTypes.func.isRequired,
}

export default Card
