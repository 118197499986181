import { StyleSheet } from 'react-native'
import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  containerStyle: theme => ({
    height: 300,
    backgroundColor: theme.colors.tintColor,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10
  }),
  videoTextContainer: {
    flex: 1
  },
  textContainer: {
    paddingHorizontal: 15,
    paddingVertical: 15
  },
  header: theme => ({
    color: theme.colors.cardHeaderTitleColor,
    fontFamily: Fonts.type.VFFont,
    fontSize: 16.6,
    lineHeight: 22.9
  }),
  text: theme => ({
    color: theme.colors.headerColor,
    fontFamily: Fonts.type.bold.fontFamily,
    fontWeight: Fonts.type.bold.fontWeight,
    fontSize: 25,
    lineHeight: 29.1
  })
})
