/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux'

import { auth } from '../Containers/Login/Login.Reducers'
import { configurations } from '../Containers/LandingScreen/LandingScreen.Reducers'
import { balance, pega, userSideBar } from '../Containers/Dashboard/Dashboard.Reducers'

// // Shake
import { productDetails, orderDetails } from '../Containers/Shake/Shake.Reducers'

// // Giga Family
import { customerPlan, products } from '../Containers/GigaFamily/GigaFamily.Reducers'
import gigaBonus from '../Containers/GigaBonus/GigaBonus.Reducers'

// // My offers
import { catalogueDetails } from '../Containers/Catalogue/Catalogue.Reducers'
import { catalogueOfferActivation } from '../Containers/CatalogueDetails/CatalogueDetails.Reducers'

// // Refill
import { offerDetails as refillOfferDetails, offerActivation } from '../Containers/Refill/Refill.Reducers'

// // VDay
import { vdayOffers } from '../Containers/VDayOffers/VDayOffers.Reducers'

// //Data sharing
import {
  balanceOptions,
  dataOptions,
  vcoinsOptions,
  balanceTransfer,
  dataTransfer,
  vcoinsTransfer
} from '../Containers/DataSharing/DataSharing.Reducers'

// Migration
import {
  pinGeneration,
  pinValidation,
  offerDetails,
  preferences as migrationPreferences,
  preferencePayload,
  preferenceUpdate,
  accountSearch,
  notifyState,
  orderCreation
} from '../Containers/Migration/Migration.Reducers'

// // CallMeBack
import { callMeBackHistory, callMeBack } from '../Containers/CallMeBack/CallMeBack.Reducers'

// //OnBoarding
import onBoarding from '../Containers/OnBoarding/OnBoarding.Reducers'
import termsAndConditions from '../Containers/OnBoarding/TermsAndConditions.Reducers'


//Reward
import {
  dynamicCoupons,
  activeCoupon,
  cancelledCoupon,
  categories,
  favorites,
  plainProducts
} from '../Containers/Catalog/catalog.reducers'
import { gift } from '../Containers/OpenedGift/openedgift.reducers'
import { user } from '../Containers/Demo/demo.reducers'
import { splash, images } from '../Containers/Splash/splash.reducers'
import { preferences } from '../Containers/Survey/survey.reducers'
import { history } from '../Containers/Profile/profile.reducers'
import { filters } from '../Containers/Filters/filters.reducers'
import { redeem, popUp } from '../Containers/Redeem/redeem.reducers'

import {
  transferVCoinsList,
  transferVCoinsState
} from '../Containers/VfCoinsTransfer/vfCoinsTransfer.reducers'

// //Love Box
import {
  serviceBalanceState,
  reddemLoveBoxState
} from '../Containers/VfHearts/VfHearts.reducers'

import {
  userState,
  allLoveBoxItems,
  plainLoveBoxProducts,
  redeemLbItem,
  infoState
} from '../Containers/VfHeartsCollection/VfHeartsCollection.reducers'

import {
  language
} from '../Containers/Settings/Settings.Reducers'

// Promo
import {
  dailyCatalogue,
  rewardDetails,
} from '../Containers/Promo/Promo.Reducers'
import { promoPrizes } from '../Containers/PromoPrizes/PromoPrizes.Reducers'


import { renewalsOffers, selectedOffer, internetOffers, tvOffers } from '../Containers/FixBundles/FixBundles.Reducers'
import { geographicAddress, geographicAddressValidation } from '../Containers/FixPurchaseCoverage/FixPurchaseCoverage.Reducers'

import {
  quizGame,
  quizLevels,
  selectedQuizLevel,
} from '../Containers/Quiz/Quiz.Reducers'

import {
  levelOptions,
} from '../Containers/QuizGame/QuizGame.Reducers'

import {
  acquisitionOffers,
  acquisitionSelectedOffer,
  acquisitionResources,
} from '../Containers/DigitalAcquisition/DigitalAcquisition.Reducers'

import {
  stories,
} from '../Containers/Story/Story.Reducers'

export default combineReducers({
  language: language,
  auth: auth,
  configurations: configurations,
  balance: balance,
  pega: pega,
  sidebar: userSideBar,
  // // Shake
  productDetails,
  orderDetails,

  // // Giga Family
  customerPlan,
  products,
  gigaBonus,

  // // My offers
  catalogueDetails,
  catalogueOfferActivation,

  // // Refill
  refillOfferDetails,
  offerActivation,

  // // Migration
  pinGeneration,
  pinValidation,
  offerDetails,
  migrationPreferences,
  preferencePayload,
  preferenceUpdate,
  accountSearch,
  notifyState,
  orderCreation,

  // //Call me back
  callMeBackHistory,
  callMeBack,

  // //VDay
  vdayOffers,

  // //Data sharing
  balanceOptions,
  dataOptions,
  vcoinsOptions,
  balanceTransfer,
  dataTransfer,
  vcoinsTransfer,

  // //OnBoarding
  onBoarding,
  termsAndConditions,

  // // Reward
  user,
  categories,
  favorites,
  plainProducts,
  gift,
  preferences,
  popUp,
  history,
  filters,
  redeem,
  splash,
  images,

  // //Transfer VCoins
  transferVCoinsList,
  transferVCoinsState,

  // //Love Box
  serviceBalanceState,
  reddemLoveBoxState,
  userState,
  allLoveBoxItems,
  plainLoveBoxProducts,
  redeemLbItem,
  infoState,

  //Promo
  dailyCatalogue,
  rewardDetails,
  promoPrizes,


  //Renewals and purchase fix
  renewalsOffers,
  internetOffers,
  selectedOffer,
  tvOffers,

  //Quiz
  quizGame,
  quizLevels,
  selectedQuizLevel,
  levelOptions,

  //PurchaseCoverage
  geographicAddress,
  geographicAddressValidation,
  cancelledCoupon,
  dynamicCoupons,
  activeCoupon,

  //Digital Acquisition
  acquisitionOffers,
  acquisitionSelectedOffer,
  acquisitionResources,

  // Stories
  stories,
})
