import { Dimensions, Platform, StyleSheet } from 'react-native'

import colors from '../../Themes/Colors'

const shadowStyles = {
  shadowColor: colors.black,
  shadowOffset: { width: 0, height: 2 },
  shadowOpacity: 0.16,
  shadowRadius: 8,
  elevation: 1,
}

const styles = StyleSheet.create({
  card: theme => ({
    backgroundColor: theme ? theme.colors.cardBackgroundColorFour : 'white',    margin: 16,
    padding: 16,
    marginTop: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 20 : 32,
    borderRadius: 6,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 8,
    elevation: 1,
  }),

  text: {
    fontFamily: 'Vodafone Rg',
    fontSize: 32,
    color: colors.black,
  },
})

export { styles, shadowStyles }
