import LightFrameworkImages from '@vfgroup-oneplatform/framework/Themes/Modes/Light/Images'

import { Images as foundationImages } from '@vfgroup-oneplatform/foundation/Components/Themes'

import Images from '../../Images'

const images = {
  ...LightFrameworkImages,
  ...Images,

  ic_tv: Images.ic_tv,
  ic_tv_and_internet: Images.ic_tv_and_internet,
  ic_mobile: foundationImages.ic_mobile,
  info: Images.info_light,
  notification_error: Images.notification_error_light,
  need_help: Images.need_help_light,
  international: Images.international_light,
  connectivity: Images.connectivity_light,
  clock: Images.clock_black,
  data: Images.data_black
}

export default images
