import { StyleSheet, Dimensions, Platform } from 'react-native'
import { Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

var deviceWidth = Dimensions.get('window').width

export default StyleSheet.create({
  headerStyle: {
    backgroundColor: Colors.white,
    borderBottomWidth: 0
  },
  containerStyle: {
    flex: 1
  },
  welcomeMessage: {
    fontFamily: Platform.OS === 'ios' ? 'Vodafone Rg' : 'VodafoneRg',
    color: '#fff',
    fontSize: 23,
    textAlign: 'center',
    marginTop: 50
  },
  reward: {
    fontFamily: Platform.OS === 'ios' ? 'Vodafone Rg' : 'VodafoneRg',
    color: '#fff',
    fontSize: 23,
    textAlign: 'center'
  },
  coins: {
    fontFamily: Platform.OS === 'ios' ? 'Vodafone Rg' : 'VodafoneRg',
    color: '#fff',
    fontSize: 20,
    marginTop: 40,
    textAlign: 'center'
  },
  gift: {
    width: deviceWidth * 74 / 100,
    paddingTop: 15,
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '13%',
    borderRadius: 500,
    height: deviceWidth * 74 / 100,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 95
  },
  circle: {
    width: (deviceWidth * 74 / 100 + 4),
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '13%',
    height: deviceWidth * 74 / 100 + 8,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 95
  },
  unbox: {
    fontFamily: Platform.OS === 'ios' ? 'Vodafone Rg' : 'VodafoneRg',
    color: '#fff',
    fontSize: 29,
    textAlign: 'center',
    marginTop: 0,
    padding: 10
  },
  gift_closed: {
    width: '100%',
    height: '100%'
  },
  gift_coin: {
    width: 100,
    height: 100,
    marginTop: 20
  }
})
