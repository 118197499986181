import React, { Component } from 'react'
import { FlatList, ScrollView, View, Text } from 'react-native'
import FastImage from 'react-native-fast-image'

import Product from '../Product'
import Loader from '../Loader'
import Error from '../Error'
import CardPlaceholder from '../Placeholder'
import { __translate, __image } from '../../Utils'

class List extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (
      typeof nextProps.category.products === 'undefined' ||
      typeof nextProps.category.products.data === 'undefined'
    ) {
      return false
    }

    if (this.props.refreshing != nextProps.refreshing) {
      return true
    }

    return nextProps.category.products != this.props.category.products
  }

  render() {
    const { category } = this.props

    if (
      category.products &&
      category.products.error &&
      !category.products.data
    ) {
      return (
        <Error
          message={category.error}
          retry={() => this.props._handleLoadMore(category)}
        />
      )
    }

    if (
      typeof category.products === 'undefined' ||
      typeof category.products.data === 'undefined'
    ) {
      return (
        <ScrollView>
          <CardPlaceholder />
          <CardPlaceholder />
          <CardPlaceholder />
          <CardPlaceholder />
        </ScrollView>
      )
    }

    if (category.products.data.length == 0 && !category.products.loading) {
      return (
        <>
          <View
            style={{
              borderRadius: 55,
              overflow: 'hidden',
              justifyContent: 'center',
              backgroundColor: '#fff',
              marginBottom: -50,
              padding: 5,
              width: 74,
              height: 74,
              zIndex: 1,
              marginTop: 50,
              elevation: 5,
              alignSelf: 'center',
            }}>
            <FastImage
              source={{ uri: __image('reward_loyalty_no_coupons') }}
              style={{ height: 64, width: 64 }}
            />
          </View>
          <View
            style={{
              backgroundColor: '#fff',
              width: '70%',
              marginLeft: '15%',
              padding: 40,
              zIndex: 0,
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
              paddingTop: 70,
            }}>
            <Text style={{ textAlign: 'center', color: '#333' }}>
              {' '}
              {__translate('reward_loyalty_no_coupons')}{' '}
            </Text>
          </View>
        </>
      )
    }

    return (
      <FlatList
        ref={ref => {
          this.props.setListRef(ref)
        }}
        data={category.products.data || []}
        keyExtractor={item => item.id.toString()}
        renderItem={({ item }) => {
          const isDynamic = item?.characteristics?.dynamic === 'yes' ? true : false
          return (<Product
            favorite={this.props.favorite}
            handleClick={() => this.props.showPopUp(item, isDynamic, category?.id)}
            data={item}
          />
          )
        }}
        getItemLayout={(data, index) => {
          return { length: 245, offset: 245 * index, index }
        }}
        initialNumToRender={3}
        onEndReached={() => this.props._handleLoadMore(category)}
        onEndReachedThreshold={0.5}
        onRefresh={this.props.onRefresh()}
        refreshing={this.props.refreshing}
        contentContainerStyle={{ paddingBottom: 60 }}
        ListHeaderComponent={this.props.listHeaderComponent}
        ListFooterComponent={
          category.products.error ? (
            <Error
              message={category.products.error}
              retry={() => this.props._handleLoadMore(category)}
            />
          ) : category.products &&
            category.products.loading &&
            category.products.data &&
            category.products.data.length ? (
            <Loader size={45} />
          ) : null
        }
      />
    )
  }
}

export default List
