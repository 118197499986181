import { REHYDRATE } from 'redux-persist'

import { action_types } from './filters.actionTypes'

const filters = (state = { loading: false, data: null, error: null }, action = {}) => {
  switch (action.type) {
    case REHYDRATE:
      if (typeof action.payload === 'undefined' || typeof action.payload.filters === 'undefined' || action.payload.filters.error) {
        return state
      }
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.filters.data
      }

    case action_types.REQUEST_FILTERS:
      return {
        ...state,
        loading: true
      }
    case action_types.SUCCESS_FILTERS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      }
    case action_types.ERROR_FILTERS:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export { filters }
