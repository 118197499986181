import React, { useState } from 'react'
import { View, Dimensions } from 'react-native'
import PropTypes from 'prop-types'
import ContentLoader, { Rect } from 'react-content-loader/native'

import { Card, Icon, PercentageIndicator, VFText } from '@vfgroup-oneplatform/foundation/Components'
import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'
import CollapsibleItem from '@vfgroup-oneplatform/foundation/Components/Collapsible/CollapsibleItem/CollapsibleItem'

import { getThemeImages } from '../../../../Group/Themes'

import { BusinessOverviewServices } from '../../../../Services/Business'

import { getUsageAmount } from './utils'

import styles from './Usage.Styles'


const { width: SCREEN_WIDTH } = Dimensions.get('screen')

const LOADING_USAGE = [{}, {}, {}]


const UsageUsersCard = ({
  isLoading,
  user,
  isOpened,
  setIsOpened,
}) => {

  const theme = useTheme()
  const themedImages = getThemeImages(theme.name)

  const [usageData, setUsageData] = useState([])
  const [loading, setLoading] = useState(false)

  const getUsageData = () => {
    if (isOpened) {
      setIsOpened('')
    } else {
      setIsOpened(user.id)
      if (usageData.length === 0) {
        setLoading(true)
        BusinessOverviewServices.getUsageByUsers(user.id, {
          onSuccess: res => {
            setLoading(false)
            setUsageData(res)
          },
          onError: () => setLoading(false)
        })
      }
    }

  }

  return (
    <>
      <Card type="main" style={styles.cardStyle}>
        {isLoading ? <View>
          <ContentLoader
            speed={2}
            width={'100%'}
            height={60}
            backgroundColor={theme.colors.shimmerCenterColor}
            foregroundColor={theme.colors.shimmerEdgesColor}
          >
            <Rect x="16" y="15" rx="4" ry="4" width={30} height={30} />
            <Rect x="62" y="20" rx="4" ry="4" width={120} height={15} />
          </ContentLoader>
        </View> : <>
          <View style={styles.line(theme)} />
          <CollapsibleItem
            title={user?.name}
            type="primary"
            isOpend={isOpened}
            onPress={getUsageData}
            icon={themedImages.ic_indicators_business}
            isLastItem={false}
            content={<>
              {loading ? <>
                {LOADING_USAGE.map(() => {
                  return (
                    <ContentLoader
                      speed={2}
                      width={SCREEN_WIDTH - 16}
                      height={40}
                      backgroundColor={theme.colors.shimmerCenterColor}
                      foregroundColor={theme.colors.shimmerEdgesColor}
                    >
                      <Rect x="0" y="0" rx="4" ry="4" width={30} height={30} />
                      <Rect x="40" y="0" rx="4" ry="4" width={60} height={10} />
                      <Rect x={SCREEN_WIDTH - 124} y="0" rx="4" ry="4" width={60} height={10} />
                      <Rect x="40" y="15" rx="4" ry="4" width={SCREEN_WIDTH - 104} height={10} />
                    </ContentLoader>
                  )
                })}
              </> : <>
                {usageData?.map((item, index) => {
                  return (
                    <View style={styles.usageContainer} key={index}>
                      <View style={styles.usageIconContainer(theme)}>
                        <Icon name={themedImages[item?.icon]} size={30} />
                      </View>
                      <View style={styles.usagePercentageContainer}>
                        <View style={styles.usageTextContainer}>
                          <VFText
                            i18nKey={item?.name}
                            style={styles.usageText}
                          />
                          <VFText
                            i18nKey={getUsageAmount(item)}
                            style={styles.usageTextValue}
                          />
                        </View>
                        <PercentageIndicator
                          type="horizontal"
                          value={item?.isUnlimited ? 100 : item?.valuePercentage}
                          animate={true}
                          color={theme.colors.secondryColor}
                          animationType="scaleUp"
                          fillColor={theme.colors.fillColor}
                        />
                      </View>
                    </View>
                  )
                })}
              </>}
            </>}
          />
        </>}
      </Card>
    </>
  )
}
UsageUsersCard.propTypes = {
  isLoading: PropTypes.bool,
  user: PropTypes.object,
  isOpened: PropTypes.bool,
  setIsOpened: PropTypes.func
}

export default UsageUsersCard
