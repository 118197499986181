import React, { useState } from 'react'
import { Dimensions, Platform, Pressable, ScrollView, TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'

import { VFScreen, VFText, Icon } from '@vfgroup-oneplatform/foundation/Components'
import { useTheme, getThemeImages } from '@vfgroup-oneplatform/foundation/Components/Themes'

import Card from '../../Components/Card'
import WebView from '../../Components/WebView/WebView'

import NavigationService from '../../Navigation/NavigationService'

import { styles } from './TermsAndConditions.Styles'


const TermsAndConditions = ({ route, ...rest }) => {
    const { params } = route
    const onClose = () => NavigationService.pop()
    const theme = useTheme()

    const Images = getThemeImages(theme.name)

    const [activeScreen, setActiveScreen] = useState('home')
    const [selectedItem, setSelectedItem] = useState()

    let termsData = useSelector(state => state.configurations.pages.find(el => el.page === 'settingsotherpage').items)

    const termsItems = useSelector(state => state.configurations.pages.find(el => el.page === 'settings').items)

    const termsAndConditions = []

    if (Platform.OS === 'ios') {
        termsData = termsData.filter(el => {
            if (el.key !== 'settings_netperform_privacy_html_android') {
                return el
            }
        })
    } else {
        termsData = termsData.filter(el => {
            if (el.key !== 'settings_netperform_privacy_html_ios') {
                return el
            }
        })
    }

    termsData.map(item => {
        const content = item.value
        let title = ''
        if (item.key === 'settings_netperform_privacy_html_android' || item.key === 'settings_netperform_privacy_html_ios') {
            title = termsItems.find(el => el.key === 'settings_terms_and_conditions_netperform_privacy').value
        }
        if (item.key === 'settings_tems_html') {
            title = termsItems.find(el => el.key === 'settings_terms_and_conditions_general_title').value
        }
        if (item.key === 'settings_netperform_terms_and_conditions_html') {
            title = termsItems.find(el => el.key === 'settings_terms_and_conditions_netperform_terms_and_conditions').value
        }
        if (item.key === 'settings_terms_and_conditions_topup_html') {
            title = termsItems.find(el => el.key === 'settings_terms_and_conditions_topup_title').value
        }
        if (item.key === 'app_privacy_regulation_supplement_html') {
            title = termsItems.find(el => el.key === 'settings_terms_and_conditions_privacy_app').value
        }
        termsAndConditions.push({ content: content, title: title })
    })

    if (route?.params?.content) {
        return (
            <VFScreen
                title={route.params.title}
                onClose={() => {
                    setActiveScreen('home')
                    setSelectedItem(null)
                }}
                closeButtonTestID={'appSettingsCloseBtn'}
                containerStyle={styles.wrapper}
                onBack={
                    NavigationService.goBack
                }
                showBack={true}
                showClose={false}
                {...rest}
            >
                <ScrollView contentContainerStyle={[styles.container, { paddingHorizontal: '10%' }]} >
                    <Card>
                        <WebView content={route.params.content} />
                    </Card>
                </ScrollView>
            </VFScreen>
        )
    }

    if (activeScreen === 'item') {
        if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
            return (
                <VFScreen
                    title={selectedItem.title}
                    onClose={() => {
                        setActiveScreen('home')
                        setSelectedItem(null)
                    }}
                    closeButtonTestID={'appSettingsCloseBtn'}
                    containerStyle={styles.wrapper}
                    onBack={
                        () => {
                            setActiveScreen('home')
                            setSelectedItem(null)
                        }
                    }
                    showBack={true}
                    showClose={false}
                    {...rest}
                >
                    <ScrollView contentContainerStyle={[styles.container, { paddingHorizontal: '10%' }]} >
                        <Card>
                            <WebView content={selectedItem.content} />
                        </Card>
                    </ScrollView>
                </VFScreen>
            )
        }
        return (
            <VFScreen
                title={selectedItem.title}
                onClose={() => {
                    setActiveScreen('home')
                    setSelectedItem(null)
                }}
                closeButtonTestID={'appSettingsCloseBtn'}
                containerStyle={styles.wrapper}
            >
                <ScrollView contentContainerStyle={styles.container} >
                    <Card>
                        <WebView content={selectedItem.content} />
                    </Card>
                </ScrollView>
            </VFScreen>
        )
    }

    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
        return (
            <VFScreen
                title="terms_and_conditions_title"
                onClose={onClose}
                closeButtonTestID={'appSettingsCloseBtn'}
                containerStyle={styles.wrapper}
                onBack={onClose}
                showBack={true}
                showClose={false}
                {...rest}
            >
                <ScrollView contentContainerStyle={[styles.container, { paddingHorizontal: '10%' }]} >
                    {termsAndConditions.map(item => (
                        <Pressable
                            onPress={() => {
                                setActiveScreen('item')
                                setSelectedItem(item)
                            }}
                        >
                            <Card style={styles.card(theme)}>
                                <VFText
                                    type="primary"
                                    i18nKey={item.title}
                                    style={styles.title}
                                />
                                <Icon
                                    name={Images.ic_chevron_right_red}
                                    type="image"
                                />
                            </Card>
                        </Pressable>
                    ))}
                </ScrollView>
            </VFScreen>
        )
    }


    return (
        <VFScreen
            title="terms_and_conditions_title"
            onClose={onClose}
            closeButtonTestID={'appSettingsCloseBtn'}
            containerStyle={styles.wrapper}
        >
            <ScrollView contentContainerStyle={styles.container} >
                {termsAndConditions.map(item => (
                    <TouchableOpacity
                        onPress={() => {
                            setActiveScreen('item')
                            setSelectedItem(item)
                        }}
                    >
                        <Card style={styles.card(theme)}>
                            <VFText
                                type="primary"
                                i18nKey={item.title}
                                style={styles.title}
                            />
                            <Icon
                                name={Images.ic_chevron_right_red}
                                type="image"
                            />
                        </Card>
                    </TouchableOpacity>
                ))}
            </ScrollView>
        </VFScreen>
    )
}

export default TermsAndConditions
