import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { Platform } from 'react-native'
import { selectContactPhone } from 'react-native-select-contact'
import {
  check,
  request,
  openSettings,
  PERMISSIONS,
  RESULTS
} from 'react-native-permissions'

import { VFInput } from '@vfgroup-oneplatform/foundation/Components'

import { Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

import Images from '../../Themes/Images'

function PaymentsNumber({ onPress, msisdn }) {

  const [thirdPartyMsisdn, setThirdPartyMsisdn] = useState(msisdn)
  const [inputRef, setInputRef] = useState()

  const getPhoneNumber = () => {
    check(
      Platform.OS === 'android'
        ? PERMISSIONS.ANDROID.READ_CONTACTS
        : PERMISSIONS.IOS.CONTACTS,
    )
      .then(result => {
        switch (result) {
          case RESULTS.UNAVAILABLE:
            break
          case RESULTS.DENIED:
            request(
              Platform.select({
                android: PERMISSIONS.ANDROID.READ_CONTACTS,
                ios: PERMISSIONS.IOS.CONTACTS
              }),
            )
            break
          case RESULTS.GRANTED:
            return selectContactPhone().then(selection => {
              if (!selection) {
                return null
              }
              const { selectedPhone } = selection
              let number = selectedPhone.number
              number = number.replace(/[^6]*/i, '355')
              number = number.replace(/[\s-]/gi, '')
              number = number.replace(/[(, )]/gi, '')
              setThirdPartyMsisdn(number)
              inputRef.setValue(number)
              onPress(number)
              return selectedPhone.number
            })
          case RESULTS.BLOCKED:
            openSettings().catch(() => null)
            break
        }
      })
      .catch(() => null)
  }

  const styles = {
    inputIcon: {
      resizeMode: 'contain',
      tintColor: Colors.dark_grey
    }
  }

  const [isIdError, setIdError] = useState(false)

  const checkIdNumber = () => {
    if (!thirdPartyMsisdn || thirdPartyMsisdn.length != 12) {
      setIdError(true)
    } else {
      onPress(thirdPartyMsisdn)
    }
  }

  useEffect(() => {
    if (msisdn && msisdn.length != 12) {
      setIdError(true)
    } else {
      setIdError(false)
    }
  }, [msisdn])

  return (
    <VFInput
      label="topup_add_mobile_number"
      fieldRef={ref => setInputRef(ref)}
      showRightIcon
      rightIconName={Images.contacts}
      rightIconType="image"
      rightIconPress={getPhoneNumber}
      rightIconColor={Colors.dark_grey}
      rightIconStyle={styles.inputIcon}
      tintColor={Colors.ocean}
      value={thirdPartyMsisdn}
      onChangeText={number => setThirdPartyMsisdn(number)}
      onBlur={checkIdNumber}
      onContentSizeChange={() => { }}
      showHelperText={isIdError}
      helperTextColor={Colors.red}
      onFocus={() => setIdError(false)}
      helperText="topup_wrong_format"
      keyboardType={'phone-pad'}
    />
  )
}

PaymentsNumber.propTypes = {
  onPress: PropTypes.func,
  msisdn: PropTypes.number
}

export default PaymentsNumber
