import { StyleSheet, Platform } from 'react-native'

export default {
  profileContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 15,
    paddingBottom: 5
  },
  profileItems: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  walletImage: {
    width: 35,
    height: 35
  },
  points: {
    marginLeft: 5,
    marginRight: 5,
    fontSize: 14,
    fontWeight: 'bold'
  },
  goIcon: {
    width: 15,
    height: 15
  },
  infoIcon: {
    width: 30,
    height: 30
  },
  tabUnderline: {
    backgroundColor: 'transparent',
    height: 2,
    marginBottom: -1
  },
  activeTab: {
    backgroundColor: '#fff'
  },
  activeText: {
    color: '#000',
    fontSize: 12,
    fontWeight: 'normal'
  },
  tab: {
    backgroundColor: '#fff',
    borderBottomWidth: 2
  },
  tabText: {
    color: '#000',
    fontSize: 12,
    fontWeight: 'normal'
  },
  tabContainer: {
    backgroundColor: '#ddddde',
    ...Platform.OS === 'web' && {
      overflow: 'auto'
    }
  },
  dynamicHeader: {
    backgroundColor: 'white',
    marginHorizontal: 15,
    marginVertical: 10,
    borderRadius: 6,
    padding: 10,
  },
  dynamicHeaderText: {
    fontSize: 14,
    fontFamily: 'Vodafone Rg',
    fontWeight: 'bold',
  },
}
