import React, { useState, useEffect } from 'react'
import { View, ScrollView, TouchableOpacity, Platform, Dimensions } from 'react-native'
import PropTypes from 'prop-types'
import { VFButton, Icon, VFText, VFItem } from '@vfgroup-oneplatform/foundation/Components'

import VFLottieView from '@vfgroup-oneplatform/foundation/Components/VFLottieView'
import { useTheme, getThemeImages } from '@vfgroup-oneplatform/foundation/Components/Themes'

import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import Modal, { ReactNativeModal } from 'react-native-modal'

import ReduxCache from '../../../Utils/ReduxCache'

import { isBusinessChooser, isAdminView } from '../../../Utils'

import { store } from '../../../Redux'
import { Actions } from '../../../Containers/Dashboard/Dashboard.Actions'
import { setStoriesInStore } from '../../../Containers/Story/Story.Actions'

import Images from '../../Themes/Images'

import styles from './AccountSwitch.Styles'

const AccountSwitch = ({
  isVisible,
  onClose,
}) => {

  const theme = useTheme()
  const images = getThemeImages(theme.name)

  const [currentAccount, setCurrentAccount] = useState({})
  const [accounts, setAccounts] = useState([])
  const [isSwitchingAccountInProgress, setIsSwitchingAccountInProgress] = useState(false)
  const [switchingToAccountName, setSwitchingToAccountName] = useState('')
  const [error, setError] = useState(false)


  useEffect(() => {
    getCurrentUser()
    getOtherAccounts()
  }, [])

  const getCurrentUser = () => {
    if (isBusinessChooser()) {
      setCurrentAccount({
        id: isAdminView() ? 'business' : 'personal',
        name: ContentManager.translate(isAdminView() ? 'account_switch_business_account' : 'account_switch_personal_account'),
        loadingName: ContentManager.translate(isAdminView() ? 'account_switch_business_account_loading' : 'account_switch_personal_account_loading'),
        icon: 'ic_indicators_admin',
      })
    }
  }

  const getOtherAccounts = () => {
    const accountArray = []

    if (isBusinessChooser()) {
      accountArray.push({
        id: isAdminView() ? 'personal' : 'business',
        name: ContentManager.translate(isAdminView() ? 'account_switch_personal_account' : 'account_switch_business_account'),
        loadingName: ContentManager.translate(isAdminView() ? 'account_switch_personal_account_loading' : 'account_switch_business_account_loading'),
        icon: 'ic_indicators_admin',
      })
    }
    setAccounts(accountArray)
  }

  const onErrorModalClose = () => {
    setError(false)
    setSwitchingToAccountName('')
  }

  const onErrorModalCancel = () => {
    onErrorModalClose()
    onClose()
  }


  const businessAccount = ReduxCache.getBusinessAccount()


  const onAccountPress = async (account) => {
    setSwitchingToAccountName(account.loadingName)
    setIsSwitchingAccountInProgress(true)

    try {
      if (account.id === 'business' || account.id === 'personal') {
        // switch view for business customer

        const showBusinessView = businessAccount?.showBusinessView

        const res = await store.dispatch(Actions.getUserBalance(!showBusinessView))

        if (Object.keys(res)?.length > 0) {
          await store.dispatch({ type: 'SWITCH_VIEW', data: !showBusinessView })
          setStoriesInStore()
          setIsSwitchingAccountInProgress(false)
          onClose()

        } else {

          setError(true)
          setIsSwitchingAccountInProgress(false)
        }
      } else {
        //switch to another msisdn
      }
    } catch (err) {
      this.setState({ isSwitchingAccountInProgress: false, errorModal: true })
    }

  }

  if (!isBusinessChooser()) {
    return null
  }

  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
    return (
      <ReactNativeModal
        isVisible={isVisible}
        backdropOpacity={0.5}
        onSwipeComplete={onClose}
        onBackdropPress={onClose}
        propagateSwipe
        hideModalContentWhileAnimating
        hardwareAccelerated
        backdropTransitionOutTiming={0}
        style={{
          justifyContent: 'flex-end',
          margin: '0 !important',
          minWidth: 1920,
          maxWidth: 1920,
        }}
      >
        <View
          style={{
            minWidth: '600px',
            maxWidth: '600px',
            height: 'auto',
            backgroundColor: theme.colors.quickActionGradientColors.secondary,
            margin: 'auto',
            padding: '20px',
            paddingTop: null,
            borderRadius: "7px",
          }}
          onStartShouldSetResponder={() => true}>
          {isSwitchingAccountInProgress ? <View style={styles.loadingContainer(theme)}>
            <VFLottieView
              type="spinner"
              autoPlay
              loop
              style={styles.loadingView}
            />
            <VFText
              i18nKey={'account_switch_loading_screen_title'}
              style={styles.loadingTitle(theme)}
            />
            <VFText
              i18nKey={'account_switch_loading_screen_desc'}
              style={styles.loadingSubtitle(theme)}
              placeHolders={[switchingToAccountName]}
            />
          </View> : <>
            {error ? <View style={styles.errorModal(theme)}>
              <View style={styles.contentContainer}>
                <Icon
                  size={150}
                  name={Images.warning}
                  resizeMode="contain"
                />
                <VFText
                  i18nKey="account_switch_default_error_title"
                  style={styles.title}
                />
                <VFText
                  i18nKey="account_switch_default_error_desc"
                  style={styles.subtitle}
                  placeHolders={[switchingToAccountName || ' ']}
                />
              </View>

              <View style={styles.buttonsContainer} >
                <VFButton
                  title="account_switch_cancel"
                  onPress={onErrorModalCancel}
                />
                <VFButton
                  title="account_switch_secondary_button"
                  type="secondary"
                  style={styles.button}
                  onPress={onErrorModalClose}
                />
              </View>
            </View> :
              <>
                <View style={styles.headerContainer} >
                  <VFText
                    i18nKey="account_switch_title"
                    style={styles.headerTitle}
                  />

                  <TouchableOpacity onPress={onClose}>
                    <Icon
                      name={images.ic_close}
                      resizeMode="contain"
                    />
                  </TouchableOpacity>
                </View>

                <ScrollView>
                  <VFText
                    i18nKey={'account_switch_current_account'}
                    style={styles.currentAccountTitleStyle(theme)}
                  />

                  <VFItem
                    containerStyle={styles.accountItemContainerStyle(theme)}
                    {...currentAccount}
                    disabled
                    onPress={onAccountPress}
                    rightIcon={Images.ic_tick_solution}
                    leftIcon={images[currentAccount?.icon]}
                  />

                  <VFText
                    i18nKey={'account_switch_other_accounts'}
                    style={styles.otherAccountsTitleStyle(theme)}
                  />

                  {accounts?.map((account, index) => {
                    return (
                      <VFItem
                        key={account.id}
                        containerStyle={index === 0 ? styles.accountItemContainerStyle(theme) : undefined}
                        {...account}
                        onPress={onAccountPress}
                        rightIcon={images.ic_chevron_right_red}
                        leftIcon={images[account.icon]}
                      />
                    )
                  })}
                </ScrollView>

                <VFButton
                  title="switch_account_quick_action_cancel_button_title"
                  onPress={onClose}
                  type="secondary"
                  style={styles.cancelButtonStyle(theme)}
                />
              </>
            }
          </>}
        </View>
      </ReactNativeModal>
    )
  }

  return (
    <>
      <Modal
        isVisible={isVisible}
        style={styles.modal}
        statusBarTranslucent={true}
      >
        {isSwitchingAccountInProgress ? <View style={styles.loadingContainer(theme)}>
          <VFLottieView
            type="spinner"
            autoPlay
            loop
            style={styles.loadingView}
          />
          <VFText
            i18nKey={'account_switch_loading_screen_title'}
            style={styles.loadingTitle(theme)}
          />
          <VFText
            i18nKey={'account_switch_loading_screen_desc'}
            style={styles.loadingSubtitle(theme)}
            placeHolders={[switchingToAccountName]}
          />
        </View> : <>
          {error ? <View style={styles.errorModal(theme)}>
            <View style={styles.contentContainer}>
              <Icon
                size={150}
                name={Images.warning}
                resizeMode="contain"
              />
              <VFText
                i18nKey="account_switch_default_error_title"
                style={styles.title}
              />
              <VFText
                i18nKey="account_switch_default_error_desc"
                style={styles.subtitle}
                placeHolders={[switchingToAccountName || ' ']}
              />
            </View>

            <View style={styles.buttonsContainer} >
              <VFButton
                title="account_switch_cancel"
                onPress={onErrorModalCancel}
              />
              <VFButton
                title="account_switch_secondary_button"
                type="secondary"
                style={styles.button}
                onPress={onErrorModalClose}
              />
            </View>
          </View> : <View style={styles.switchAccountContainer(theme)} >
            <View style={styles.headerContainer} >
              <VFText
                i18nKey="account_switch_title"
                style={styles.headerTitle}
              />

              <TouchableOpacity onPress={onClose}>
                <Icon
                  name={images.ic_close}
                  resizeMode="contain"
                />
              </TouchableOpacity>
            </View>

            <ScrollView>
              <VFText
                i18nKey={'account_switch_current_account'}
                style={styles.currentAccountTitleStyle(theme)}
              />

              <VFItem
                containerStyle={styles.accountItemContainerStyle(theme)}
                {...currentAccount}
                disabled
                onPress={onAccountPress}
                rightIcon={Images.ic_tick_solution}
                leftIcon={images[currentAccount?.icon]}
              />

              <VFText
                i18nKey={'account_switch_other_accounts'}
                style={styles.otherAccountsTitleStyle(theme)}
              />

              {accounts?.map((account, index) => {
                return (
                  <VFItem
                    key={account.id}
                    containerStyle={index === 0 ? styles.accountItemContainerStyle(theme) : undefined}
                    {...account}
                    onPress={onAccountPress}
                    rightIcon={images.ic_chevron_right_red}
                    leftIcon={images[account.icon]}
                  />
                )
              })}
            </ScrollView>

            <VFButton
              title="switch_account_quick_action_cancel_button_title"
              onPress={onClose}
              type="secondary"
              style={styles.cancelButtonStyle(theme)}
            />
          </View>}
        </>}
      </Modal>
    </>
  )
}
AccountSwitch.defaultProps = {
  isVisible: false,
  onClose: () => { }
}
AccountSwitch.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
}

export default AccountSwitch
