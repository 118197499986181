import React from 'react'
import { Linking } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useDispatch } from 'react-redux'

import ReduxCache from '../../../Utils/ReduxCache'


function useDeepLink({toggleRefill, setIsThirdParty, setShowPayments, setDataShareActiveTab, setShowDataShare}) {

  const { navigate } = useNavigation()
  const dispatch = useDispatch()

  // Get deep link URL
  React.useEffect(() => {
    const linkingEventHandler = Linking.addEventListener('url', handleUrlOpenedApp)

    Linking.getInitialURL().then((url) => {
      if (url) {
        handleDeepLink(url)
      }
    }).catch(err => console.error('[Dashboard.js] An error occurred', err))

    return () => { linkingEventHandler.remove() }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUrlOpenedApp = ({ url }) => {
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        handleDeepLink(url)
      }
    })
  }

  const handleDeepLink = (url) => {
    if (url.includes('vfal://') || url.includes('https://my.vodafone.al')) {
      let urlRight = url.replace('vfal://', '')
      urlRight = urlRight.replace('https://my.vodafone.al', '')

      let splitedUrl = ''

      if (urlRight.includes('refill')) {
        setTimeout(() => {
          toggleRefill(true)
        }, 4000)
      } else if (urlRight.includes('recharge')) {
        splitedUrl = urlRight.replace('recharge/', '')
        setTimeout(() => {
          if (splitedUrl === 'thirdparty') {
            setIsThirdParty(true)
            setShowPayments(true)
          } else {
            setIsThirdParty(false)
            setShowPayments(true)
          }
        }, 4000)
      } else if (urlRight.includes('vodafoneshare')) {
        splitedUrl = urlRight.replace('vodafoneshare/', '')
        setTimeout(() => {
          if (splitedUrl !== "") {
            setDataShareActiveTab(splitedUrl)
          }
          setShowDataShare(true)
        }, 4000)
      } else if (urlRight.includes('more')) {
        splitedUrl = urlRight.replace('more/', '')
        dispatch({
          type: 'ACTIVE_COMPONENT',
          payload: 'More'
        })
        navigate('Demo', { deeplinkCat: splitedUrl })
      } else if (urlRight.includes('myweekend')) {
        // todo 
      } else if (urlRight.includes('tiranaparking')) {
        const encryptedMsisdn = ReduxCache.get('auth.hashedMsisdnParking')
        const webViewURL = 'https://mytrp.altirana.com/?MSISDN=&lang='
        const title = 'Tirana Parking'
        navigate('WebView', { url: webViewURL, title: title, postData: 'MSISDN=' + encryptedMsisdn, isReact: true })
      } else if (urlRight.includes('tidal')) {
        const webViewURL = 'https://offers.vodafone.com/al'
        const title = 'Tidal'
        navigate('WebView', { url: webViewURL, title: title, isReact: true })
      } else if (urlRight.includes('securenet')) {
        const webViewURL = 'https://securenet.vodafone.al'
        const title = 'Secure Net'
        navigate('WebView', { url: webViewURL, title: title, isReact: true })
      }
    }
  }

  return {}
}

export default useDeepLink
