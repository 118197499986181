import { StyleSheet } from 'react-native'
import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

const styles = StyleSheet.create({
  subHeaderTitleStyle: {
    marginTop: 10,
  },
  subHeaderSubTitleStyle: {
    fontFamily: Fonts.type.VFFont,
    fontSize: Fonts.size.h5,
    fontWeight: 'normal',
  },
  manualRefresh: {
    marginBottom: 0,
  },
  container: {
    paddingHorizontal: 16,
    paddingTop: 20,
  },
  title: {
    ...Fonts.type.bold,
    fontSize: Fonts.size.h4,
    marginTop: 30,
    marginBottom: 10,
  },
  subtitle: theme => ({
    fontSize: Fonts.size.h6,
    color: theme.colors.textColorTwo,
    marginBottom: 30,
  }),
  cardTitle: {
    fontSize: Fonts.size.h6,
  },
  cardSubTitle: {
    fontSize: Fonts.size.regular,
  },
  boldText: {
    fontSize: Fonts.size.regular,
    ...Fonts.type.bold,
  },
  cardHeaderStyle: {
    flexDirection: 'row',
  },
  horizontalLine: theme => ({
    borderBottomColor: theme.colors.borderColorThree,
    borderBottomWidth: 1,
    marginVertical: 15,
  }),
  textContainer: {
    justifyContent: 'space-evenly',
    marginLeft: 20,

  },
  cardContainer: theme => ({
    width: '100%',
    margin: 0,
    padding: 16,
    backgroundColor: theme.colors.cardBackgroundColor,
    height: 'auto',
  }),
  modalContainer: theme => ({
    margin: 0,
    justifyContent: 'flex-end'
  }),
  loader: {
    marginTop: 16,
    borderRadius: 6,
  },
  lottieContainerStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  lottieViewStyle: {
    width: 200,
    height: 200
  }
})

export default styles
