import { StyleSheet, Dimensions } from 'react-native'
import { Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

const { height } = Dimensions.get('screen')

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  containerStyle: {
    paddingHorizontal: 0,
  },
  headerStyle: {
    paddingHorizontal: 16,
  },
  modal: {
  },
  title: {
    minWidth: 200,
  },
  modalHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 32,
    marginBottom: 16,
    justifyContent: 'space-between',
  },
  subHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 32,
    justifyContent: 'space-between',
    marginTop: 16,
  },
  moreInfoContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  options: {
    marginBottom: 32,
  },
  confirmButton: {
    marginBottom: 16,
    marginTop: 8,
  },
  modalContentWraper: {
    flexGrow: 1,
  },
  errorWrapper: {
    // flexGrow: 1,
    alignItems: 'center',
  },
  loaderWraper: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 24,
  },
  verticalSpacing: {
    marginVertical: 16,
  },
  medVerticalSpacing: {
    marginVertical: 32,
  },
  secondaryButton: {
    marginTop: 16,
  },
  closeIcon: {
    tintColor: Colors.thinDark,
  },
  lottie: {
    width: 150,
    height: 150,
    marginLeft: 0,
  },
  infoLabel: {
    marginTop: 2,
    minWidth: 64,
  },
  infoIcon: {
    tintColor: Colors.vfRed,
    marginLeft: 8,
  },
  separator: {
    marginBottom: 32,
  },
  smallSpace: {
    marginBottom: 8,
  },
  mediumSpace: {
    marginBottom: 16,
  },
  airtimeContainer: {
    flexDirection: 'row',
    marginBottom: 16,
  },
  currency: {
    minWidth: 150,
  },
  largeSpace: {
    marginBottom: 24,
  },
  xlargeSpace: {
    marginBottom: 32,
  },
  buttonSpace: {
    marginTop: 8,
  },
  infoScrollContainer: {
    flexGrow: 1,
    paddingBottom: 16,
  },
  infoScroll: {
    marginVertical: 16,
  },
  webView: {
    flex: 1,
    minHeight: height * 0.5,
  },
  sectionTitle: {
    minWidth: '50%',
    marginVertical: 8,
  },
  codeWrapper: {
    alignSelf: 'center',
    paddingHorizontal: 10,
    borderStyle: 'dashed',
    borderRadius: 1,
    borderWidth: 2,
    borderColor: '#979797',
    marginBottom: 24,
  },
  qrWrapper: {
    alignSelf: 'center',
    paddingVertical: 24,
  },
})

export default styles
