import { StyleSheet, Platform } from 'react-native'
import { Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
    titleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        marginRight: 11
    },
    title: {
        fontSize: 18.7,
        lineHeight: 25,
        paddingTop: Platform.OS === 'ios' ? 5 : null
    },
    subTitle: {
        marginLeft: 42,
        fontSize: 14.6,
        marginTop: 12.6,
        lineHeight: 18.7
    },
    subTitleContainer: {
        paddingRight: 16
    },
    description: {
        fontSize: 16,
        lineHeight: 20,
        ...Platform.select({
            web: {
                overflow: 'hidden'
            },
            default: {}
        })
    },
    content: {
        marginBottom: 25
    },
    separator: (theme) => ({
        height: 1,
        backgroundColor: theme?.colors?.borderColorThree,
        marginVertical: 10,
    }),
    titleStyle: {
        marginLeft: -16,
    },
    closeBtnStyle: {
        marginRight: -16,
    },
    footer: {
        flex: 1
    },
    footerText: {
        textAlign: 'center',
        color: Colors.red,
        fontSize: 16
    },
    deleteButton: {
        marginTop: 15
    },
    deleteAccText: {
        marginBottom: 20,
        marginTop: 5,
        textAlign: 'left',
        fontFamily: 'Vodafone Rg',
        fontSize: 18,
        color: Colors.black,
    },
    deleteAccCardHeader: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        paddingVertical: 20
    },
    deleteAccMobileIcon: {
        marginHorizontal: 15,
    },
    modalTitle: (bottom) => ({
        fontSize: 20,
        lineHeight: 28,
        marginTop: 24,
        marginBottom: bottom,
    }),
    modalSubtitle: {
        marginBottom: 24,
        fontSize: 16,
        lineHeight: 22,
    },
    confirmButton: {
        marginBottom: 16,
        marginTop: 10,
    },
    requestResultContainer: {
        flexGrow: 1,
    },
    image: {
        alignSelf: 'center',
        marginVertical: 20,
    },
    textContainer: {
        alignItems: 'center',
    },
    errorDescription: {
        textAlign: 'center',
        marginVertical: 20,
        fontSize: 18,
        lineHeight: 24,
    },
    stepButton: {
        marginHorizontal: 15,
    },
    errorTitle: {
        fontWeight: 'bold',
        fontSize: 28,
        lineHeight: 36,
    },
    modalContainer: {
        flex: 1,
    },
})
