import { actionTypes } from './Catalogue.ActionsTypes'

function catalogueDetails(
  state = { loading: false },
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_GET_CATALOGUE:
      return { loading: true }

    case actionTypes.SUCCESS_GET_CATALOGUE:
      return {
        loading: false,
        data: action.payload,
      }

    case actionTypes.ERROR_GET_CATALOGUE:
      return {
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}


export {
  catalogueDetails,
}
