
export const countriesList = [
  {
    'id': 0,
    'value': 'Afghanistan',
    'label': 'Afghanistan'
  },
  {
    'id': 1,
    'value': 'Albania',
    'label': 'Albania'
  },
  {
    'id': 2,
    'value': 'Algeria',
    'label': 'Algeria'
  },
  {
    'id': 3,
    'value': 'American Samoa',
    'label': 'American Samoa'
  },
  {
    'id': 4,
    'value': 'Andorra',
    'label': 'Andorra'
  },
  {
    'id': 5,
    'value': 'Angola',
    'label': 'Angola'
  },
  {
    'id': 6,
    'value': 'Anguilla',
    'label': 'Anguilla'
  },
  {
    'id': 7,
    'value': 'Antarctica',
    'label': 'Antarctica'
  },
  {
    'id': 8,
    'value': 'Antigua and Barbuda',
    'label': 'Antigua and Barbuda'
  },
  {
    'id': 9,
    'value': 'Argentina',
    'label': 'Argentina'
  },
  {
    'id': 10,
    'value': 'Armenia',
    'label': 'Armenia'
  },
  {
    'id': 11,
    'value': 'Aruba',
    'label': 'Aruba'
  },
  {
    'id': 12,
    'value': 'Australia',
    'label': 'Australia'
  },
  {
    'id': 13,
    'value': 'Austria',
    'label': 'Austria'
  },
  {
    'id': 14,
    'value': 'Azerbaijan',
    'label': 'Azerbaijan'
  },
  {
    'id': 15,
    'value': 'Bahamas (the)',
    'label': 'Bahamas (the)'
  },
  {
    'id': 16,
    'value': 'Bahrain',
    'label': 'Bahrain'
  },
  {
    'id': 17,
    'value': 'Bangladesh',
    'label': 'Bangladesh'
  },
  {
    'id': 18,
    'value': 'Barbados',
    'label': 'Barbados'
  },
  {
    'id': 19,
    'value': 'Belarus',
    'label': 'Belarus'
  },
  {
    'id': 20,
    'value': 'Belgium',
    'label': 'Belgium'
  },
  {
    'id': 21,
    'value': 'Belize',
    'label': 'Belize'
  },
  {
    'id': 22,
    'value': 'Benin',
    'label': 'Benin'
  },
  {
    'id': 23,
    'value': 'Bermuda',
    'label': 'Bermuda'
  },
  {
    'id': 24,
    'value': 'Bhutan',
    'label': 'Bhutan'
  },
  {
    'id': 25,
    'value': 'Bolivia (Plurinational State of)',
    'label': 'Bolivia (Plurinational State of)'
  },
  {
    'id': 26,
    'value': 'Bonaire, Sint Eustatius and Saba',
    'label': 'Bonaire, Sint Eustatius and Saba'
  },
  {
    'id': 27,
    'value': 'Bosnia and Herzegovina',
    'label': 'Bosnia and Herzegovina'
  },
  {
    'id': 28,
    'value': 'Botswana',
    'label': 'Botswana'
  },
  {
    'id': 29,
    'value': 'Bouvet Island',
    'label': 'Bouvet Island'
  },
  {
    'id': 30,
    'value': 'Brazil',
    'label': 'Brazil'
  },
  {
    'id': 31,
    'value': 'British Indian Ocean Territory (the)',
    'label': 'British Indian Ocean Territory (the)'
  },
  {
    'id': 32,
    'value': 'Brunei Darussalam',
    'label': 'Brunei Darussalam'
  },
  {
    'id': 33,
    'value': 'Bulgaria',
    'label': 'Bulgaria'
  },
  {
    'id': 34,
    'value': 'Burkina Faso',
    'label': 'Burkina Faso'
  },
  {
    'id': 35,
    'value': 'Burundi',
    'label': 'Burundi'
  },
  {
    'id': 36,
    'value': 'Cabo Verde',
    'label': 'Cabo Verde'
  },
  {
    'id': 37,
    'value': 'Cambodia',
    'label': 'Cambodia'
  },
  {
    'id': 38,
    'value': 'Cameroon',
    'label': 'Cameroon'
  },
  {
    'id': 39,
    'value': 'Canada',
    'label': 'Canada'
  },
  {
    'id': 40,
    'value': 'Cayman Islands (the)',
    'label': 'Cayman Islands (the)'
  },
  {
    'id': 41,
    'value': 'Central African Republic (the)',
    'label': 'Central African Republic (the)'
  },
  {
    'id': 42,
    'value': 'Chad',
    'label': 'Chad'
  },
  {
    'id': 43,
    'value': 'Chile',
    'label': 'Chile'
  },
  {
    'id': 44,
    'value': 'China',
    'label': 'China'
  },
  {
    'id': 45,
    'value': 'Christmas Island',
    'label': 'Christmas Island'
  },
  {
    'id': 46,
    'value': 'Cocos (Keeling) Islands (the)',
    'label': 'Cocos (Keeling) Islands (the)'
  },
  {
    'id': 47,
    'value': 'Colombia',
    'label': 'Colombia'
  },
  {
    'id': 48,
    'value': 'Comoros (the)',
    'label': 'Comoros (the)'
  },
  {
    'id': 49,
    'value': 'Congo (the Democratic Republic of the)',
    'label': 'Congo (the Democratic Republic of the)'
  },
  {
    'id': 50,
    'value': 'Congo (the)',
    'label': 'Congo (the)'
  },
  {
    'id': 51,
    'value': 'Cook Islands (the)',
    'label': 'Cook Islands (the)'
  },
  {
    'id': 52,
    'value': 'Costa Rica',
    'label': 'Costa Rica'
  },
  {
    'id': 53,
    'value': 'Croatia',
    'label': 'Croatia'
  },
  {
    'id': 54,
    'value': 'Cuba',
    'label': 'Cuba'
  },
  {
    'id': 55,
    'value': 'Curaçao',
    'label': 'Curaçao'
  },
  {
    'id': 56,
    'value': 'Cyprus',
    'label': 'Cyprus'
  },
  {
    'id': 57,
    'value': 'Czechia',
    'label': 'Czechia'
  },
  {
    'id': 58,
    'value': "Côte d'Ivoire",
    'label': "Côte d'Ivoire"
  },
  {
    'id': 59,
    'value': 'Denmark',
    'label': 'Denmark'
  },
  {
    'id': 60,
    'value': 'Djibouti',
    'label': 'Djibouti'
  },
  {
    'id': 61,
    'value': 'Dominica',
    'label': 'Dominica'
  },
  {
    'id': 62,
    'value': 'Dominican Republic (the)',
    'label': 'Dominican Republic (the)'
  },
  {
    'id': 63,
    'value': 'Ecuador',
    'label': 'Ecuador'
  },
  {
    'id': 64,
    'value': 'Egypt',
    'label': 'Egypt'
  },
  {
    'id': 65,
    'value': 'El Salvador',
    'label': 'El Salvador'
  },
  {
    'id': 66,
    'value': 'Equatorial Guinea',
    'label': 'Equatorial Guinea'
  },
  {
    'id': 67,
    'value': 'Eritrea',
    'label': 'Eritrea'
  },
  {
    'id': 68,
    'value': 'Estonia',
    'label': 'Estonia'
  },
  {
    'id': 69,
    'value': 'Eswatini',
    'label': 'Eswatini'
  },
  {
    'id': 70,
    'value': 'Ethiopia',
    'label': 'Ethiopia'
  },
  {
    'id': 71,
    'value': 'Falkland Islands (the) [Malvinas]',
    'label': 'Falkland Islands (the) [Malvinas]'
  },
  {
    'id': 72,
    'value': 'Faroe Islands (the)',
    'label': 'Faroe Islands (the)'
  },
  {
    'id': 73,
    'value': 'Fiji',
    'label': 'Fiji'
  },
  {
    'id': 74,
    'value': 'Finland',
    'label': 'Finland'
  },
  {
    'id': 75,
    'value': 'France',
    'label': 'France'
  },
  {
    'id': 76,
    'value': 'French Guiana',
    'label': 'French Guiana'
  },
  {
    'id': 77,
    'value': 'French Polynesia',
    'label': 'French Polynesia'
  },
  {
    'id': 78,
    'value': 'French Southern Territories (the)',
    'label': 'French Southern Territories (the)'
  },
  {
    'id': 79,
    'value': 'Gabon',
    'label': 'Gabon'
  },
  {
    'id': 80,
    'value': 'Gambia (the)',
    'label': 'Gambia (the)'
  },
  {
    'id': 81,
    'value': 'Georgia',
    'label': 'Georgia'
  },
  {
    'id': 82,
    'value': 'Germany',
    'label': 'Germany'
  },
  {
    'id': 83,
    'value': 'Ghana',
    'label': 'Ghana'
  },
  {
    'id': 84,
    'value': 'Gibraltar',
    'label': 'Gibraltar'
  },
  {
    'id': 85,
    'value': 'Greece',
    'label': 'Greece'
  },
  {
    'id': 86,
    'value': 'Greenland',
    'label': 'Greenland'
  },
  {
    'id': 87,
    'value': 'Grenada',
    'label': 'Grenada'
  },
  {
    'id': 88,
    'value': 'Guadeloupe',
    'label': 'Guadeloupe'
  },
  {
    'id': 89,
    'value': 'Guam',
    'label': 'Guam'
  },
  {
    'id': 90,
    'value': 'Guatemala',
    'label': 'Guatemala'
  },
  {
    'id': 91,
    'value': 'Guernsey',
    'label': 'Guernsey'
  },
  {
    'id': 92,
    'value': 'Guinea',
    'label': 'Guinea'
  },
  {
    'id': 93,
    'value': 'Guinea-Bissau',
    'label': 'Guinea-Bissau'
  },
  {
    'id': 94,
    'value': 'Guyana',
    'label': 'Guyana'
  },
  {
    'id': 95,
    'value': 'Haiti',
    'label': 'Haiti'
  },
  {
    'id': 96,
    'value': 'Heard Island and McDonald Islands',
    'label': 'Heard Island and McDonald Islands'
  },
  {
    'id': 97,
    'value': 'Holy See (the)',
    'label': 'Holy See (the)'
  },
  {
    'id': 98,
    'value': 'Honduras',
    'label': 'Honduras'
  },
  {
    'id': 99,
    'value': 'Hong Kong',
    'label': 'Hong Kong'
  },
  {
    'id': 0,
    'value': 'Hungary',
    'label': 'Hungary'
  },
  {
    'id': 1,
    'value': 'Iceland',
    'label': 'Iceland'
  },
  {
    'id': 2,
    'value': 'India',
    'label': 'India'
  },
  {
    'id': 3,
    'value': 'Indonesia',
    'label': 'Indonesia'
  },
  {
    'id': 4,
    'value': 'Iran (Islamic Republic of)',
    'label': 'Iran (Islamic Republic of)'
  },
  {
    'id': 5,
    'value': 'Iraq',
    'label': 'Iraq'
  },
  {
    'id': 6,
    'value': 'Ireland',
    'label': 'Ireland'
  },
  {
    'id': 7,
    'value': 'Isle of Man',
    'label': 'Isle of Man'
  },
  {
    'id': 8,
    'value': 'Israel',
    'label': 'Israel'
  },
  {
    'id': 9,
    'value': 'Italy',
    'label': 'Italy'
  },
  {
    'id': 10,
    'value': 'Jamaica',
    'label': 'Jamaica'
  },
  {
    'id': 11,
    'value': 'Japan',
    'label': 'Japan'
  },
  {
    'id': 12,
    'value': 'Jersey',
    'label': 'Jersey'
  },
  {
    'id': 13,
    'value': 'Jordan',
    'label': 'Jordan'
  },
  {
    'id': 14,
    'value': 'Kazakhstan',
    'label': 'Kazakhstan'
  },
  {
    'id': 15,
    'value': 'Kenya',
    'label': 'Kenya'
  },
  {
    'id': 16,
    'value': 'Kiribati',
    'label': 'Kiribati'
  },
  {
    'id': 17,
    'value': "Korea (the Democratic People's Republic of)",
    'label': "Korea (the Democratic People's Republic of)"
  },
  {
    'id': 18,
    'value': 'Korea (the Republic of)',
    'label': 'Korea (the Republic of)'
  },
  {
    'id': 19,
    'value': 'Kuwait',
    'label': 'Kuwait'
  },
  {
    'id': 20,
    'value': 'Kyrgyzstan',
    'label': 'Kyrgyzstan'
  },
  {
    'id': 21,
    'value': "Lao People's Democratic Republic (the)",
    'label': "Lao People's Democratic Republic (the)"
  },
  {
    'id': 22,
    'value': 'Latvia',
    'label': 'Latvia'
  },
  {
    'id': 23,
    'value': 'Lebanon',
    'label': 'Lebanon'
  },
  {
    'id': 24,
    'value': 'Lesotho',
    'label': 'Lesotho'
  },
  {
    'id': 25,
    'value': 'Liberia',
    'label': 'Liberia'
  },
  {
    'id': 26,
    'value': 'Libya',
    'label': 'Libya'
  },
  {
    'id': 27,
    'value': 'Liechtenstein',
    'label': 'Liechtenstein'
  },
  {
    'id': 28,
    'value': 'Lithuania',
    'label': 'Lithuania'
  },
  {
    'id': 29,
    'value': 'Luxembourg',
    'label': 'Luxembourg'
  },
  {
    'id': 30,
    'value': 'Macao',
    'label': 'Macao'
  },
  {
    'id': 31,
    'value': 'Madagascar',
    'label': 'Madagascar'
  },
  {
    'id': 32,
    'value': 'Malawi',
    'label': 'Malawi'
  },
  {
    'id': 33,
    'value': 'Malaysia',
    'label': 'Malaysia'
  },
  {
    'id': 34,
    'value': 'Maldives',
    'label': 'Maldives'
  },
  {
    'id': 35,
    'value': 'Mali',
    'label': 'Mali'
  },
  {
    'id': 36,
    'value': 'Malta',
    'label': 'Malta'
  },
  {
    'id': 37,
    'value': 'Marshall Islands (the)',
    'label': 'Marshall Islands (the)'
  },
  {
    'id': 38,
    'value': 'Martinique',
    'label': 'Martinique'
  },
  {
    'id': 39,
    'value': 'Mauritania',
    'label': 'Mauritania'
  },
  {
    'id': 40,
    'value': 'Mauritius',
    'label': 'Mauritius'
  },
  {
    'id': 41,
    'value': 'Mayotte',
    'label': 'Mayotte'
  },
  {
    'id': 42,
    'value': 'Mexico',
    'label': 'Mexico'
  },
  {
    'id': 43,
    'value': 'Micronesia (Federated States of)',
    'label': 'Micronesia (Federated States of)'
  },
  {
    'id': 44,
    'value': 'Moldova (the Republic of)',
    'label': 'Moldova (the Republic of)'
  },
  {
    'id': 45,
    'value': 'Monaco',
    'label': 'Monaco'
  },
  {
    'id': 46,
    'value': 'Mongolia',
    'label': 'Mongolia'
  },
  {
    'id': 47,
    'value': 'Montenegro',
    'label': 'Montenegro'
  },
  {
    'id': 48,
    'value': 'Montserrat',
    'label': 'Montserrat'
  },
  {
    'id': 49,
    'value': 'Morocco',
    'label': 'Morocco'
  },
  {
    'id': 50,
    'value': 'Mozambique',
    'label': 'Mozambique'
  },
  {
    'id': 51,
    'value': 'Myanmar',
    'label': 'Myanmar'
  },
  {
    'id': 52,
    'value': 'Namibia',
    'label': 'Namibia'
  },
  {
    'id': 53,
    'value': 'Nauru',
    'label': 'Nauru'
  },
  {
    'id': 54,
    'value': 'Nepal',
    'label': 'Nepal'
  },
  {
    'id': 55,
    'value': 'Netherlands (the)',
    'label': 'Netherlands (the)'
  },
  {
    'id': 56,
    'value': 'New Caledonia',
    'label': 'New Caledonia'
  },
  {
    'id': 57,
    'value': 'New Zealand',
    'label': 'New Zealand'
  },
  {
    'id': 58,
    'value': 'Nicaragua',
    'label': 'Nicaragua'
  },
  {
    'id': 59,
    'value': 'Niger (the)',
    'label': 'Niger (the)'
  },
  {
    'id': 60,
    'value': 'Nigeria',
    'label': 'Nigeria'
  },
  {
    'id': 61,
    'value': 'Niue',
    'label': 'Niue'
  },
  {
    'id': 62,
    'value': 'Norfolk Island',
    'label': 'Norfolk Island'
  },
  {
    'id': 63,
    'value': 'Northern Mariana Islands (the)',
    'label': 'Northern Mariana Islands (the)'
  },
  {
    'id': 64,
    'value': 'Norway',
    'label': 'Norway'
  },
  {
    'id': 65,
    'value': 'Oman',
    'label': 'Oman'
  },
  {
    'id': 66,
    'value': 'Pakistan',
    'label': 'Pakistan'
  },
  {
    'id': 67,
    'value': 'Palau',
    'label': 'Palau'
  },
  {
    'id': 68,
    'value': 'Palestine, State of',
    'label': 'Palestine, State of'
  },
  {
    'id': 69,
    'value': 'Panama',
    'label': 'Panama'
  },
  {
    'id': 70,
    'value': 'Papua New Guinea',
    'label': 'Papua New Guinea'
  },
  {
    'id': 71,
    'value': 'Paraguay',
    'label': 'Paraguay'
  },
  {
    'id': 72,
    'value': 'Peru',
    'label': 'Peru'
  },
  {
    'id': 73,
    'value': 'Philippines (the)',
    'label': 'Philippines (the)'
  },
  {
    'id': 74,
    'value': 'Pitcairn',
    'label': 'Pitcairn'
  },
  {
    'id': 75,
    'value': 'Poland',
    'label': 'Poland'
  },
  {
    'id': 76,
    'value': 'Portugal',
    'label': 'Portugal'
  },
  {
    'id': 77,
    'value': 'Puerto Rico',
    'label': 'Puerto Rico'
  },
  {
    'id': 78,
    'value': 'Qatar',
    'label': 'Qatar'
  },
  {
    'id': 79,
    'value': 'Republic of North Macedonia',
    'label': 'Republic of North Macedonia'
  },
  {
    'id': 80,
    'value': 'Romania',
    'label': 'Romania'
  },
  {
    'id': 81,
    'value': 'Russian Federation (the)',
    'label': 'Russian Federation (the)'
  },
  {
    'id': 82,
    'value': 'Rwanda',
    'label': 'Rwanda'
  },
  {
    'id': 83,
    'value': 'Réunion',
    'label': 'Réunion'
  },
  {
    'id': 84,
    'value': 'Saint Barthélemy',
    'label': 'Saint Barthélemy'
  },
  {
    'id': 85,
    'value': 'Saint Helena, Ascension and Tristan da Cunha',
    'label': 'Saint Helena, Ascension and Tristan da Cunha'
  },
  {
    'id': 86,
    'value': 'Saint Kitts and Nevis',
    'label': 'Saint Kitts and Nevis'
  },
  {
    'id': 87,
    'value': 'Saint Lucia',
    'label': 'Saint Lucia'
  },
  {
    'id': 88,
    'value': 'Saint Martin (French part)',
    'label': 'Saint Martin (French part)'
  },
  {
    'id': 89,
    'value': 'Saint Pierre and Miquelon',
    'label': 'Saint Pierre and Miquelon'
  },
  {
    'id': 90,
    'value': 'Saint Vincent and the Grenadines',
    'label': 'Saint Vincent and the Grenadines'
  },
  {
    'id': 91,
    'value': 'Samoa',
    'label': 'Samoa'
  },
  {
    'id': 92,
    'value': 'San Marino',
    'label': 'San Marino'
  },
  {
    'id': 93,
    'value': 'Sao Tome and Principe',
    'label': 'Sao Tome and Principe'
  },
  {
    'id': 94,
    'value': 'Saudi Arabia',
    'label': 'Saudi Arabia'
  },
  {
    'id': 95,
    'value': 'Senegal',
    'label': 'Senegal'
  },
  {
    'id': 96,
    'value': 'Serbia',
    'label': 'Serbia'
  },
  {
    'id': 97,
    'value': 'Seychelles',
    'label': 'Seychelles'
  },
  {
    'id': 98,
    'value': 'Sierra Leone',
    'label': 'Sierra Leone'
  },
  {
    'id': 99,
    'value': 'Singapore',
    'label': 'Singapore'
  },
  {
    'id': 0,
    'value': 'Sint Maarten (Dutch part)',
    'label': 'Sint Maarten (Dutch part)'
  },
  {
    'id': 1,
    'value': 'Slovakia',
    'label': 'Slovakia'
  },
  {
    'id': 2,
    'value': 'Slovenia',
    'label': 'Slovenia'
  },
  {
    'id': 3,
    'value': 'Solomon Islands',
    'label': 'Solomon Islands'
  },
  {
    'id': 4,
    'value': 'Somalia',
    'label': 'Somalia'
  },
  {
    'id': 5,
    'value': 'South Africa',
    'label': 'South Africa'
  },
  {
    'id': 6,
    'value': 'South Georgia and the South Sandwich Islands',
    'label': 'South Georgia and the South Sandwich Islands'
  },
  {
    'id': 7,
    'value': 'South Sudan',
    'label': 'South Sudan'
  },
  {
    'id': 8,
    'value': 'Spain',
    'label': 'Spain'
  },
  {
    'id': 9,
    'value': 'Sri Lanka',
    'label': 'Sri Lanka'
  },
  {
    'id': 10,
    'value': 'Sudan (the)',
    'label': 'Sudan (the)'
  },
  {
    'id': 11,
    'value': 'Suriname',
    'label': 'Suriname'
  },
  {
    'id': 12,
    'value': 'Svalbard and Jan Mayen',
    'label': 'Svalbard and Jan Mayen'
  },
  {
    'id': 13,
    'value': 'Sweden',
    'label': 'Sweden'
  },
  {
    'id': 14,
    'value': 'Switzerland',
    'label': 'Switzerland'
  },
  {
    'id': 15,
    'value': 'Syrian Arab Republic',
    'label': 'Syrian Arab Republic'
  },
  {
    'id': 16,
    'value': 'Taiwan',
    'label': 'Taiwan'
  },
  {
    'id': 17,
    'value': 'Tajikistan',
    'label': 'Tajikistan'
  },
  {
    'id': 18,
    'value': 'Tanzania, United Republic of',
    'label': 'Tanzania, United Republic of'
  },
  {
    'id': 19,
    'value': 'Thailand',
    'label': 'Thailand'
  },
  {
    'id': 20,
    'value': 'Timor-Leste',
    'label': 'Timor-Leste'
  },
  {
    'id': 21,
    'value': 'Togo',
    'label': 'Togo'
  },
  {
    'id': 22,
    'value': 'Tokelau',
    'label': 'Tokelau'
  },
  {
    'id': 23,
    'value': 'Tonga',
    'label': 'Tonga'
  },
  {
    'id': 24,
    'value': 'Trinidad and Tobago',
    'label': 'Trinidad and Tobago'
  },
  {
    'id': 25,
    'value': 'Tunisia',
    'label': 'Tunisia'
  },
  {
    'id': 26,
    'value': 'Turkey',
    'label': 'Turkey'
  },
  {
    'id': 27,
    'value': 'Turkmenistan',
    'label': 'Turkmenistan'
  },
  {
    'id': 28,
    'value': 'Turks and Caicos Islands (the)',
    'label': 'Turks and Caicos Islands (the)'
  }
]
export const gendersList = [
  { label: 'Female', value: 'F' },
  { label: 'Male', value: 'M' },
]

export const idTypes = [
  { label: 'Passport', value: 'Passport' },
  { label: 'ID Card', value: 'Identification Card' },
]

export const channelList = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
]

export const citiesList = [
  { label: 'B.Curri', value: 'B.Curri' },
  { label: 'Ballsh', value: 'Ballsh' },
  { label: 'Berat', value: 'Berat' },
  { label: 'Bilisht', value: 'Bilisht' },
  { label: 'Bulqize', value: 'Bulqize' },
  { label: 'Burrel', value: 'Burrel' },
  { label: 'Cerrik', value: 'Cerrik' },
  { label: 'Corovode', value: 'Corovode' },
  { label: 'Delvine', value: 'Delvine' },
  { label: 'Devoll', value: 'Devoll' },
  { label: 'Divjake', value: 'Divjake' },
  { label: 'Durres', value: 'Durres' },
  { label: 'Elbasan', value: 'Elbasan' },
  { label: 'Erseke', value: 'Erseke' },
  { label: 'F.Kruje', value: 'F.Kruje' },
  { label: 'Fier', value: 'Fier' },
  { label: 'Fierze', value: 'Fierze' },
  { label: 'Fushe Arrez', value: 'Fushe Arrez' },
  { label: 'Fushe Kruje', value: 'Fushe Kruje' },
  { label: 'Gjirokaster', value: 'Gjirokaster' },
  { label: 'Gramsh', value: 'Gramsh' },
  { label: 'Has', value: 'Has' },
  { label: 'Himare', value: 'Himare' },
  { label: 'Kashar', value: 'Kashar' },
  { label: 'Kamez', value: 'Kamez' },
  { label: 'Kavaje', value: 'Kavaje' },
  { label: 'Kelcyre', value: 'Kelcyre' },
  { label: 'Klos', value: 'Klos' },
  { label: 'Kolonje', value: 'Kolonje' },
  { label: 'Konispoli', value: 'Konispoli' },
  { label: 'Koplik', value: 'Koplik' },
  { label: 'Korce', value: 'Korce' },
  { label: 'Krast', value: 'Krast' },
  { label: 'Kruje', value: 'Kruje' },
  { label: 'Krume', value: 'Krume' },
  { label: 'Kucove', value: 'Kucove' },
  { label: 'Kukel', value: 'Kukel' },
  { label: 'Kukes', value: 'Kukes' },
  { label: 'Kurbin', value: 'Kurbin' },
  { label: 'Kurbnesh', value: 'Kurbnesh' },
  { label: 'Lac', value: 'Lac' },
  { label: 'Leskovik', value: 'Leskovik' },
  { label: 'Lezhe', value: 'Lezhe' },
  { label: 'Libohova', value: 'Libohova' },
  { label: 'Librazhd', value: 'Librazhd' },
  { label: 'Lushnje', value: 'Lushnje' },
  { label: 'Malesi e Madhe', value: 'Malesi e Madhe' },
  { label: 'Maliq', value: 'Maliq' },
  { label: 'Mallakaster', value: 'Mallakaster' },
  { label: 'Mamurras', value: 'Mamurras' },
  { label: 'Maqellare', value: 'Maqellare' },
  { label: 'Mat', value: 'Mat' },
  { label: 'Memaliaj', value: 'Memaliaj' },
  { label: 'Milot', value: 'Milot' },
  { label: 'Mirdite', value: 'Mirdite' },
  { label: 'Orikum', value: 'Orikum' },
  { label: 'Patos', value: 'Patos' },
  { label: 'Peqin', value: 'Peqin' },
  { label: 'Permet', value: 'Permet' },
  { label: 'Peshkopi', value: 'Peshkopi' },
  { label: 'Perrenjas', value: 'Perrenjas' },
  { label: 'Podgoran', value: 'Podgoran' },
  { label: 'Pogradec', value: 'Pogradec' },
  { label: 'Polican', value: 'Polican' },
  { label: 'Porocan', value: 'Porocan' },
  { label: 'Povelce', value: 'Povelce' },
  { label: 'Puke', value: 'Puke' },
  { label: 'Rinas', value: 'Rinas' },
  { label: 'Roskovec', value: 'Roskovec' },
  { label: 'Rraboshte', value: 'Rraboshte' },
  { label: 'Rreshen', value: 'Rreshen' },
  { label: 'Rrogozhine', value: 'Rrogozhine' },
  { label: 'Rubik', value: 'Rubik' },
  { label: 'Sarande', value: 'Sarande' },
  { label: 'Selenica', value: 'Selenica' },
  { label: 'Shengjin', value: 'Shengjin' },
  { label: 'Shijak', value: 'Shijak' },
  { label: 'Shkoder', value: 'Shkoder' },
  { label: 'Shtermen', value: 'Shtermen' },
  { label: 'Skrapar', value: 'Skrapar' },
  { label: 'Sukth', value: 'Sukth' },
  { label: 'Tepelene', value: 'Tepelene' },
  { label: 'Tirane', value: 'Tirane' },
  { label: 'Tropoje', value: 'Tropoje' },
  { label: 'Ulez', value: 'Ulez' },
  { label: 'Ura Vajgurore', value: 'Ura Vajgurore' },
  { label: 'Vlore', value: 'Vlore' },
  { label: 'Vore', value: 'Vore' },
  { label: 'Other', value: 'Other' },
]
