import { StyleSheet } from 'react-native'
import { Colors, Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

const styles = StyleSheet.create({
    card: {
        flex: 1,
    },
    cardHeader: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    mobileIcon: {
        marginRight: 10,
    },
    contactContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    inputContainer: {
        flex: 1,
        minHeight: 50,
        marginHorizontal: 0,
    },
    inputText: (theme) => ({
        color: theme.colors.textColorTwo,
        fontFamily: Fonts.type.VFFont,
    }),
    header: {
        fontSize: 20,
        fontWeight: 'bold',
        color: Colors.black,
        lineHeight: 28,
    },
    subTitle: theme => ({
        color: theme.colors.textColorTwo,
        fontSize: 16,
        lineHeight: 22,
    }),
    button: {
        marginTop: 15
    },
    separator: theme => ({
        height: 1,
        backgroundColor: theme.colors.borderColorThree,
        marginVertical: 10
    }),
    successModalContainer: {
        alignItems: 'center',
        marginVertical: 30,
    },
    successText: {
        fontFamily: Fonts.type.VFFont,
        fontSize: Fonts.size.h4,
        marginVertical: 30,
    },
    successButton: {
        width: '100%',
    }
})

export { styles }
