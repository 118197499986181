import { actionTypes } from './Refill.ActionsTypes'

function offerDetails(
  state = { loading: false, data: null, error: null },
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_GET_OFFER:
      return {
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_GET_OFFER:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }

    case actionTypes.ERROR_GET_OFFER:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}

function offerActivation(
  state = { loading: false, data: null, error: null },
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_ACTIVATE_OFFER:
      return {
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_ACTIVATE_OFFER:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }

    case actionTypes.ERROR_ACTIVATE_OFFER:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}


export {
  offerDetails,
  offerActivation,
}
