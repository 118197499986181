import { actionTypes } from './QuizGame.Actions'

function levelOptions(
  state = { loading: false, data: null, error: null },
  action = {},
) {
  switch (action.type) {
    case actionTypes.GET_LEVEL_OPTIONS:
      return {
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_LEVEL_OPTIONS:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }

    case actionTypes.ERROR_LEVEL_OPTIONS:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}

export {
  levelOptions,
}
