import { StyleSheet } from 'react-native'

import { Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  primaryButtonStyle: theme => ({
    alignItems: 'center',
    backgroundColor: theme.colors.buttons.primary.default,
    borderRadius: 6,
    height: 44,
    justifyContent: 'center'
  }),
  primaryTextStyle: theme => ({
    color: theme.colors.textButtons.primary.default,
    fontSize: 18,
    textAlign: 'center'
  }),
  secondaryButtonStyle: theme => ({
    alignItems: 'center',
    backgroundColor: theme.colors.buttons.secondary.default,
    borderColor: Colors.brownGrey,
    borderWidth: 1,
    borderRadius: 6,
    height: 44,
    justifyContent: 'center'
  }),
  secondaryTextStyle: theme => ({
    color: theme.colors.textButtons.secondary.default,
    fontSize: 18,
    textAlign: 'center'
  }),
  tertiaryButtonStyle: theme => ({
    alignItems: 'center',
    backgroundColor: theme.colors.buttons.tertiary.default,
    borderRadius: 6,
    borderColor: Colors.light_grey,
    borderWidth: 1,
    height: 44,
    justifyContent: 'center'
  }),
  tertiaryTextStyle: theme => ({
    color: theme.colors.textButtons.tertiary.default,
    fontSize: 18,
    textAlign: 'center'
  }),
  quaternaryButtonStyle: theme => ({
    alignItems: 'center',
    backgroundColor: theme.colors.buttons.quaternary.default,
    borderColor: theme.colors.buttons.quaternary.borderColor,
    borderWidth: 1,
    borderRadius: 6,
    height: 44,
    justifyContent: 'center'
  }),
  quaternaryTextStyle: theme => ({
    color: theme.colors.textButtons.quaternary.default,
    fontSize: 18,
    textAlign: 'center'
  }),
  disabledButtonStyle: {
    backgroundColor: Colors.veryLightPink,
    borderWidth: 0
  },
  loadingContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }
})
export const underlayColor = {
  primary: Colors.activeRed,
  secondary: Colors.activeGrey,
  tertiary: Colors.dark_grey
}
