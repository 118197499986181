import React, { Component } from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'

import Error from '../../Components/Error'
import Loader from '../../Components/Loader'

import ReduxCache from '../../Utils/ReduxCache'

import { actions } from './splash.actions'

class Splash extends Component {
  componentDidMount() {
    this._retrieveData()
  }

  _retrieveData = async () => {
    const language = ReduxCache.getLanguage()
    let component = 'More'

    try {
      if (this.props.component) {
        component = this.props.component
      }
    } catch (e) {
      console.log('Something went wrong when reading local storage' + e)
    }

    this.component = component
    this.props.getSplash(language, component)
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.component != this.props.component) {
      this._retrieveData()
    }
  }

  _retry() {
    this._retrieveData()
  }

  render() {
    const { splash, images } = this.props

    if (!splash?.data?.[this.component] && splash.error && !splash.loading) {
      return (
        <View style={{ flex: 1 }}>
          <Error message={splash.error} retry={() => this._retry()} />
        </View>
      )
    }

    if (
      (splash.loading && !splash.error) ||
      !images.data ||
      !images.data?.[this.component]
    ) {
      return (
        <View>
          <Loader size={65} view={'full'} />
        </View>
      )
    }

    return null
  }
}

const mapStateToProps = state => {
  const { splash, images } = state
  return { splash, images }
}

const mapDispatchToProps = dispatch => {
  return {
    getSplash: (locale, component) => {
      dispatch(actions.getSplash(locale, component))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Splash)