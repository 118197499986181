import React, { Component } from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import {
  withTheme,
  LightThemeColors
} from '@vfgroup-oneplatform/foundation/Components/Themes'

// import TopUpScreen from '../../../../App/Containers/TopUpScreen/TopUpScreen'
import { getThemeImages } from '../../../Themes'

import GeneralComponent from '../GeneralComponent'

import styles from './FourthComponent.Styles'
import { TopUp } from './Components'



class FourthComponent extends Component {
  constructor(props) {
    super(props)
    this.state = { isVisible: false }
  }
  toggleModal = () => {
    this.setState({ isVisible: !this.state.isVisible })
  }

  closeTopUpModalQuickAction = () => {
    this.setState({
      isVisible: false
    })
  }
  render() {
    const { theme, type, extraInfo, placeHolders } = this.props
    const Images = getThemeImages(theme.name)

    return (
      <View style={styles.container} testID={'DBsecCard3'}>
        {type === 'other' ?
          <GeneralComponent
            image={Images[extraInfo.icon]}
            subTitle={extraInfo.subTitle}
            title={extraInfo.title}
            placeHolders={placeHolders}
            onPress={extraInfo.onPress}
          />
          :
          <View>
            <TopUp
              image={Images.icTopUp}
              onPress={extraInfo.onPress}
              userName={extraInfo.subTitle}
              title={extraInfo.title}
              value={extraInfo.value}
              currency={extraInfo.currency}
            />
            {/* <TopUpScreen
              toggleModal={this.toggleModal}
              closeModal={this.closeTopUpModalQuickAction}
              isVisible={this.state.isVisible}
              type={PaymentTypes.GIFT_AFTER_PAY}
            /> */}
          </View>
        }
      </View>
    )
  }
}

FourthComponent.defaultProps = {
  theme: LightThemeColors,
}
FourthComponent.propTypes = {
  theme: PropTypes.objectOf({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  }),
  extraInfo: PropTypes.objectOf({
    unreadMessagesCount: PropTypes.number
  }),
  messagesPlaceHolders: PropTypes.arrayOf(PropTypes.string)
}
export default withTheme(FourthComponent)
