import { RNCamera } from 'react-native-infy-camera'
import { useState } from 'react'

import NavigationService from '../../Navigation/NavigationService'
import { ActivateOffer } from '../../Services/CocaCola'

const useCocaCola = ({ isDeeplink }) => {
    const [flashState, setFlashState] = useState(RNCamera.Constants.FlashMode.off)
    const [isCameraOpen, setIsCameraOpen] = useState(false)
    const [error, setError] = useState(false)
    const [successData, setSuccessData] = useState(false)
    const [infoModal, setInfoModal] = useState(false)
    const [notFound] = useState(false)
    const [loading, setLoading] = useState(false)

    const toggleFlash = () => {
        if (flashState === RNCamera.Constants.FlashMode.off) {
            setFlashState(RNCamera.Constants.FlashMode.torch)
        } else {
            setFlashState(RNCamera.Constants.FlashMode.off)
        }
    }

    const toggleCameraModal = () => {
        setError(false)
        setLoading(false)
        setSuccessData(false)
        setIsCameraOpen(!isCameraOpen)
    }

    const toggleInfoModal = () => {
        setInfoModal(!infoModal)
    }

    const onClose = () => {
        if (isDeeplink) {
            NavigationService.navigateWithResetAction('Dashboard')
        } else {
            NavigationService.goBack()
        }
    }

    const removeError = () => {
        setError(false)
    }

    const onQRScan = async (event) => {
        setLoading(true)
        //const url = 'https://efiskalizimi-app.tatime.gov.al/invoice-check/#/verify?iic=BAAEE431C0DB9C261FD408E78AF6C089&tin=L41617022M&crtd=2023-06-14T14:16:20%2002:00&ord=44918&bu=li226eq846&cr=ak152gu347&sw=cc302yz654&prc=1399.00'
        const url = event.data

        try {
            const response = await ActivateOffer(url)
            const productCharacteristic = response.productOrderItem[0]?.product?.productCharacteristic[0]
            const productTerm = response.productOrderItem[0]?.product?.productTerm[0]
            const data = {
                value: productCharacteristic?.value + ' ' + productCharacteristic?.valueType,
                validity: productTerm?.duration?.amount + ' ' + productTerm?.duration?.units
            }

            setSuccessData(data)
        } catch (e) {
            setError(e.data?.message ? e.data.message : 'cocacola_error_description')
        }

        setLoading(false)
    }

    return {
        error,
        infoModal,
        notFound,
        toggleFlash,
        toggleCameraModal,
        toggleInfoModal,
        onClose,
        removeError,
        onQRScan,
        flashState,
        isCameraOpen,
        loading,
        successData,
    }
}

export { useCocaCola }
