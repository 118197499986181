/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'


import Quiz from '@vfal-lab/rn-vfg-quiz/Screens'
import { VFScreen } from '@vfgroup-oneplatform/foundation/Components'


import { openQuizEvent, listQuizLevels, openQuizLevel } from './Quiz.Actions'
import styles from './Quiz.Styles'

function QuizScreen({ navigation }) {

  const dispatch = useDispatch()

  const openQuizGame = async (utilities) => dispatch(openQuizEvent(utilities))

  const getQuizLevels = async (utilities) => dispatch(listQuizLevels(utilities))

  const startQuizLevel = async (quizLevelId, utilities) => dispatch(openQuizLevel(quizLevelId, utilities))

  const quizLevels = useSelector(state => state?.quizLevels?.data || [])

  useLayoutEffect(() => {
    (async () => {
      try {
        await openQuizGame(
          {
            onStart: () => { },
            onError: () => { },
            onSuccess: () => { },
          })
      } catch (e) { }
    })()
  }, [])


  const onClose = () => {
    navigation.pop()
  }

  const navigateToQuizGame = (level, sessionId, optionsNr) => navigation.navigate('QuizGame', { quizLevel: level, sessionId, optionsNr })


  const openMoreInfo = (url, title) => {
    navigation.navigate('WebView', {
      url,
      title,
      isReact: true
    })
  }


  return (
    <VFScreen
      headerStyle={styles.headerStyle}
      title={'UN Quiz'}
      hideMainHeader
    >
      <Quiz
        onClose={onClose}
        navigateToQuizGame={navigateToQuizGame}
        openQuizGame={openQuizGame}
        getQuizLevels={getQuizLevels}
        startQuizLevel={startQuizLevel}
        quizLevels={quizLevels}
        openMoreInfo={openMoreInfo}
      />
    </VFScreen>
  )
}

QuizScreen.propTypes = {
  navigation: PropTypes.object,
}

export default QuizScreen
