import axios from 'axios'

import CONFIG from '../../Config'
import {getErrorMessage} from '../../Utils'
import NavigationService from '../../Navigation/NavigationService'
import ReduxCache from '../../Utils/ReduxCache'

import {actionTypes} from './VfHeartsCollection.actionTypes'

const getUserProfile = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.REQUEST_PROFILE,
    })

    const msisdn = ReduxCache.getMsisdn()

    const url = await CONFIG.getUrl()
    const headers = await CONFIG.getUserProfileHeaders()
    axios
      .get(url.profileEndpoint + msisdn, {
        headers: {
          ...headers,
        },
        data: null,
      })
      .then(response => {
        const data = response.data

        const characteristics = {}

        for (const i in data.parts.specification.characteristicsValue) {
          const characteristic = data.parts.specification.characteristicsValue[i]
          characteristics[characteristic.characteristicName] =
            characteristic.value
        }

        const coins = data.details.loyaltyAccountBalance.value || 0

        const user = {
          id: data.id[0].value,
          name: data.name,
          status: data.status,
          created: data.created.value,
          coins: coins,
          characteristics: characteristics,
        }

        dispatch({
          type: actionTypes.SUCCESS_PROFILE,
          payload: user,
        })
      })
      .catch(error => {
        const message = getErrorMessage(error)
        dispatch({
          type: actionTypes.ERROR_PROFILE,
          payload: message,
        })
      })
  }
}

const setInitialUser = user => {
  return dispatch => {
    dispatch({
      type: actionTypes.INITIAL_PROFILE,
      payload: user,
    })
  }
}

const getAllItems = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.REQUEST_ALL_ITEMS,
    })

    const id = '8983BB8912273302E053020011AC0BA8'
    const sort = ''
    const category = 'coupon'
    const page = 0
    const limit = 100
    const msisdn = ReduxCache.getMsisdn()
    const channelId = 'love_box'

    const data = {
      queryOptions: {
        filter: '',
        pagination: {
          count: page,
          limit: limit,
        },
        sorting: sort,
      },
      queries: [
        {
          query: '$.redeemerId=' + msisdn,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.type=' + CONFIG.queryConfig.type,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.channelId=' + channelId,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.category=' + category,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.id=' + id,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.version=2',
        },
      ],
    }

    const url = await CONFIG.getUrl()
    const headers = await CONFIG.getAllItemsHeaders()
    axios
      .post(url.apiEndpoint, data, {
        headers: {
          ...headers,
        },
      })
      .then(response => {
        const plainProducts = {}
        const allProducts = []

        for (const i in response.data) {
          const product = response.data[i]
          const characteristics = {}

          for (const j in product.parts.specification.characteristicsValue) {
            const characteristic =
              product.parts.specification.characteristicsValue[j]
            characteristics[characteristic.characteristicName] =
              characteristic.value
          }

          const productDetails = {
            id: product.parts.productOffering[0].id[0].value,
            partner: product.name,
            name: product.parts.productOffering[0].name,
            type: product.parts.productOffering[0].type,
            desc: product.parts.productOffering[0].desc,
            favorite: product.status == 'favourite',
            validityPeriod: product.parts.productOffering[0].toDate.date,
            characteristics: characteristics,
            price: product.parts.productOffering[0].priceFinalPrice.value,
            currency:
              product.parts.productOffering[0].priceFinalPrice.currencyID,
            oldPrice: product.parts.productOffering[0].priceOriginalPrice.value,
          }

          plainProducts[product.parts.productOffering[0].id[0].value] =
            response.data[i]
          allProducts.push(productDetails)
        }

        dispatch({
          type: actionTypes.SUCCESS_ALL_ITEMS,
          payload: {
            products: allProducts,
          },
        })

        dispatch({
          type: actionTypes.SUCCESS_PLAIN_PRODUCTS,
          payload: plainProducts,
        })
      })
      .catch(error => {
        const message = getErrorMessage(error)
        dispatch({
          type: actionTypes.ERROR_ALL_ITEMS,
          payload: {
            error: message,
          },
        })
      })
  }
}

const redeemProduct = (product, shouldReset = false) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.REQUEST_LB_REDEEM_HANDLE,
    })

    product = JSON.stringify(product)
    product = product.replace(/ë/g, 'e')
    product = product.replace(/ç/g, 'c')
    product = product.replace(/Ë/g, 'e')
    product = product.replace(/Ç/g, 'c')

    product = JSON.parse(product)
    // add static price because it's mandatory
    product.parts.productOffering[0].priceFinalPrice.value = 0
    product.parts.productOffering[0].priceOriginalPrice.value = 0

    const url = await CONFIG.getUrl()
    const headers = await CONFIG.getLbRedeemHeaders()

    axios
      .post(url.redeemEndpoint, product, {
        headers: {
          ...headers,
        },
      })
      .then(response => {
        const name = product.parts.productOffering[0].name
        const productData = {
          name: name,
        }

        NavigationService.goBack()

        dispatch({
          type: actionTypes.SUCCESS_LB_REDEEM_HANDLE,
          payload: productData,
        })

        setTimeout(() => {
          if (shouldReset) {
            dispatch(resetRedeem())
          }
        }, 100)
      })
      .catch(error => {
        const message = getErrorMessage(error)
        dispatch({
          type: actionTypes.ERROR_LB_REDEEM_HANDLE,
          payload: message,
        })
        NavigationService.goBack()
      })
  }
}

const resetRedeem = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.RESET_LB_REDEEM,
    })
  }
}

const activateInfo = (goBack = false) => {
  return dispatch => {
    dispatch({
      type: actionTypes.ACTIVATE_INFO,
      payload: goBack,
    })
  }
}
const deactivateInfo = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.DEACTIVATE_INFO,
      payload: false,
    })
  }
}

export const actions = {
  getUserProfile,
  setInitialUser,
  getAllItems,
  redeemProduct,
  resetRedeem,
  activateInfo,
  deactivateInfo,
}
