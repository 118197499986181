import {StyleSheet} from 'react-native'

import colors from '../../Themes/Colors'

const styles = StyleSheet.create({
  modal: {
    justifyContent: 'flex-end',
    margin: 0,
    backgroundColor: 'rgba(0,0,0,0.01)',
  },
  main: {
    backgroundColor: colors.white,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  header: {
    flexDirection: 'row',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    overflow: 'hidden',
    padding: 16,
    paddingTop: 32,
  },
  indicatorContainer: {
    padding: 30,
    paddingBottom: 0,
    backgroundColor: 'transparent',
  },
  topIndicator: {
    width: 45,
    height: 6,
    backgroundColor: 'rgba(242, 242, 242, 0.52)',
    alignSelf: 'center',
    marginBottom: 6,
    borderRadius: 3,
  },
  close: {
    flex: 1,
    position: 'absolute',
    right: 16,
    top: 34,
    width: 26,
    height: 26,
  },
  diagonals: {
    position: 'absolute',
    left: 13,
    height: 27,
    width: 1,
    backgroundColor: colors.dark_grey,
  },
  primaryDiagonal: {
    transform: [{rotate: '45deg'}],
  },
  secondaryDiagonal: {
    transform: [{rotate: '315deg'}],
  },
  content: {
    paddingHorizontal: 16,
    paddingBottom: 32,
  },
})

export {styles}
