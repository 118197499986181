import React, { useEffect, useMemo } from 'react'
import {
  View,
  ScrollView,
  useWindowDimensions,
  TouchableOpacity,
  Platform,
  Dimensions,
} from 'react-native'
import { Icon, VFText } from '@vfgroup-oneplatform/foundation/Components'
import Shimmer from '@vfgroup-oneplatform/foundation/Components/Shimmer'
import PropTypes from 'prop-types'
import { shimmerPlaceholderConfig, setLoadingConfigObject } from '@vfgroup-oneplatform/foundation/Components/Shimmer/Shimmer.utils'
import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { useSelector } from 'react-redux'

import { isEmpty } from 'lodash'

import NavigationService from '../../Navigation/NavigationService'

import MigrationLottie from '../../Components/MigrationLottie'

import { setStoriesInStore } from './Story.Actions'

import Images from '../../Themes/Images'

import styles from './Stories.Styles.js'

const Stories = ({
  textColor,
  containerStyle,
  bubbleSize,
  navigationLocation,
  onPress,
  openStories
}) => {
  const [reloading, setReloading] = React.useState(false)

  const { width } = useWindowDimensions()

  const theme = useTheme()
  const stories = useSelector(state => state?.stories?.stories || null)

  useEffect(() => {
    if ((stories?.length === 0 || stories === null) && !reloading) {
      reloadStories()
    }
  }, [stories])

  const reloadStories = async () => {
    setReloading(true)
    try {
      await setStoriesInStore()
      setReloading(false)
    } catch (error) {
    }
    setReloading(false)
  }

  const orderedStories = useMemo(() => {
    if (Array.isArray(stories) && stories) {
      return stories.sort((a, b) => {
        return a.seen === b.seen ? 0 : a ? -1 : 1
      })
    }

    return false
  }, [stories])

  const openStory = (bubble, index) => {
    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
      openStories(true)
      onPress(
        {
          storyId: '',
          bubbleId: bubble.id,
          navigationLocation,
          internalNavigation: true,
          slide: 'slide_from_bottom',
        }
      )
    } else {
      NavigationService.navigate('Story', {
        storyId: '',
        bubbleId: bubble.id,
        navigationLocation,
        internalNavigation: true,
        slide: 'slide_from_bottom',
      })
    }
  }

  if (reloading) {
    return <View style={styles.reloadContainer}>
      <MigrationLottie style={styles.lottieReloading} />
    </View>
  }



  if (orderedStories) {
    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
      return (
        <View
          style={[styles.storyContainer]}
        >
          {/* Map all Story bubbles on the screen */}
          {orderedStories?.map((story, index) => {
            return (
              <View style={styles.singleStory}>
                {/* Navigate to the story component and pass to this component the required data */}
                <TouchableOpacity
                  style={styles.storyButton(story.seen)}
                  onPress={() => openStory(story, index)}
                  activeOpacity={0.8}
                >
                  <Icon
                    name={{
                      uri: Images[story.image]
                        ? Images[story.image].uri
                        : story.image
                    }}
                    size={bubbleSize || 80}
                    style={styles.radius}
                  />
                </TouchableOpacity>
                {/* Show countdown for each bubble if there is any */}
                {story.showCountdown ? (
                  <View style={styles.offerCountdown}>
                    <Icon
                      name={Images.clock_orange}
                      size={12}
                      style={styles.offerCountdownIcon}
                    />
                    <VFText
                      i18nKey={`${story.timeLeft} hr`}
                      style={styles.offerCountdownTime}
                    />
                  </View>
                ) : (
                  <View style={styles.empty} />
                )}
                {story.description && <VFText
                  i18nKey={story.description}
                  style={styles.offerCountdownDescription(width, textColor, theme)}
                />}
              </View>
            )
          })}
        </View>)
    }
    else {
      return (
        <ScrollView
          style={[navigationLocation === 'Dashboard' && styles.storyContainer]}
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={containerStyle || {}}
        >
          {/* Map all Story bubbles on the screen */}
          {orderedStories?.map((story, index) => {
            return (
              <View style={styles.singleStory}>
                {/* Navigate to the story component and pass to this component the required data */}
                <TouchableOpacity
                  style={styles.storyButton(story.seen)}
                  onPress={() => openStory(story, index)}
                  activeOpacity={0.8}
                >
                  <Icon
                    name={{
                      uri: Images[story.image]
                        ? Images[story.image].uri
                        : story.image
                    }}
                    size={bubbleSize || 80}
                    style={styles.radius}
                  />
                </TouchableOpacity>
                {/* Show countdown for each bubble if there is any */}
                {story.showCountdown ? (
                  <View style={styles.offerCountdown}>
                    <Icon
                      name={Images.clock_orange}
                      size={12}
                      style={styles.offerCountdownIcon}
                    />
                    <VFText
                      i18nKey={`${story.timeLeft} hr`}
                      style={styles.offerCountdownTime}
                    />
                  </View>
                ) : (
                  <View style={styles.empty} />
                )}
                {story.description && <VFText
                  i18nKey={story.description}
                  style={styles.offerCountdownDescription(width, textColor, theme)}
                />}
              </View>
            )
          })}
        </ScrollView>
      )
    }
  } else {
    return navigationLocation === 'Dashboard' ? <></> : (
      <TouchableOpacity
        style={styles.reloadButton}
        onPress={reloadStories}
      >
        <VFText
          i18nKey={'Reload Stories'}
          style={[styles.txtButton]}
        />
        <Icon
          name={Images.icRefreshWhite}
          type="image"
          size={26}
          style={styles.iconButton}
        />
      </TouchableOpacity>
    )
  }
}


Stories.propTypes = {
  textColor: PropTypes.string,
  containerStyle: PropTypes.object,
  bubbleSize: PropTypes.number,
  navigationLocation: PropTypes.string,
  onPress: PropTypes.func
}

Stories.defaultValues = {
  textColor: 'white'
}

export default Stories
