import { actionTypes } from './PromoPrizes.Actions'

function promoPrizes(
  state = { loading: false, data: null, error: null },
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_PRIZES_CATALOGUE:
      return {
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_PRIZES_CATALOGUE:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }

    case actionTypes.ERROR_PRIZES_CATALOGUE:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}

export {
  promoPrizes,
}
