import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { StatusBar, Platform } from 'react-native'

import { GigaFamily as GigaFamilyScreen } from '@vfal-lab/rn-vfg-giga-family/Screens'
import { VFScreen } from '@vfgroup-oneplatform/foundation/Components'

import { actions } from './GigaFamily.Actions'
import styles from './GigaFamily.Styles.js'

function GigaFamily({ navigation, route }) {
  const dispatch = useDispatch()

  /** Get customer plan */
  const getCustomerPlan = async (utilities) => {
    return dispatch(actions.getCustomerPlan(utilities))
  }
  const plan = useSelector(state => state.customerPlan.data)

  /** Get Products */
  const getProducts = async (utilities) => {
    return dispatch(actions.getProducts(utilities))
  }
  const products = useSelector(state => state.products)

  const resetStatusBar = () => {
    if (Platform.OS === 'android') {
      StatusBar.setBarStyle('light-content', true)
      StatusBar.setBackgroundColor(Colors.thinDark, true)
      StatusBar.setTranslucent(false)
    } else if (Platform.OS === 'ios') {
      StatusBar.setBarStyle('dark-content', true)
    }
  }

  const onClose = async () => {
    if (route?.params?.isDeepLink) {
      navigation.navigate('Dashboard')
    } else {
      navigation.pop()
    }
    resetStatusBar()
  }

  const navigateToAccount = () => {
    navigation.navigate('ProductsAndServices')
  }

  const openEbil = () => {
    navigation.navigate('Billing')
  }

  return (
    <VFScreen
      headerStyle={styles.headerStyle}
      title={'Giga Family'}
      hideMainHeader
    >
      <GigaFamilyScreen
        isContentLoading={false}
        navigation={navigation}
        getCustomerPlan={getCustomerPlan}
        plan={plan}
        getProducts={getProducts}
        title={products.data?.title}
        products={products.data?.products}
        isGigaFamily={products.data?.isGigaFamily}
        productsLoading={products.loading}
        productsError={products.error}
        onClose={onClose}
        navigateToAccount={navigateToAccount}
        openEbil={openEbil}
      />
    </VFScreen>
  )
}

GigaFamily.defaultProps = {}

GigaFamily.propTypes = {
  navigation: PropTypes.object,
}

export default GigaFamily
