import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  ScrollView,
  View,
  TouchableWithoutFeedback,
  LayoutAnimation,
  UIManager,
  ViewPropTypes,
  Platform,
  Dimensions
} from 'react-native'
import {
  map as _map,
  get as _get,
  isFunction as _isFunction,
  isEmpty as _isEmpty
} from 'lodash'

import { VFText, VFButton, ErrorScreen } from '@vfgroup-oneplatform/foundation/Components'
import {
  withTheme,
  LightThemeColors,
  Types,
  getThemeImages
} from '@vfgroup-oneplatform/foundation/Components/Themes'

import { usePrevious } from '@vfgroup-oneplatform/foundation/Utils/Hooks'


import { PaymentScreenTypes } from '@vfgroup-oneplatform/framework/Payment/Utils/PaymentTypes'
import MyPlanUsageItem from '@vfgroup-oneplatform/framework/MyPlan/Components/MyPlanUsageItem'
import MyPlanInfoItem from '@vfgroup-oneplatform/framework/MyPlan/Components/MyPlanInfoItem'
import MyPlanCardLoading from '@vfgroup-oneplatform/framework/MyPlan/Components/MyPlanCardLoading'
import MyPlanExtrasModal from '@vfgroup-oneplatform/framework/MyPlan/Screens/MyPlanExtrasModal'
import Config from '@vfgroup-oneplatform/framework/MyPlan/Screens/MyPlanExtrasModal/Config.json'

import ReduxCache from '../../../../../Utils/ReduxCache'

import MyPlanCard from './MyPlanCard'


import styles from './MyPlan.Styles'


const CustomLayoutAnimation = {
  duration: 150,
  create: {
    type: LayoutAnimation.Types.linear,
    property: LayoutAnimation.Properties.opacity
  },
  update: {
    type: LayoutAnimation.Types.easeInEaseOut
  }
}

const MyPlan = ({
  withTray,
  title,
  subtitle,
  planData,
  containerStyle,
  images,
  theme,
  addExtraPlanData,
  onPressAddConfirmation,
  forceOpenModal,
  withUsagePercentage,
  percentageIndicatorFillColor,
  percentageIndicatorSecondaryColor,
  percentageIndicatorContainerStyle,
  percentageIndicatorShouldAnimate,
  paymentCards,
  defaultPayment,
  onSelectDefaultCard,
  onPressSaveCard,
  onAddUsagePress,
  onClose,
  isMyPlanLoading,
  onPressUpgradePlan,
  isError,
  onTryAgainPress,
  errorText,
  errorImages,
  errorIconName,
  tryAgainIconName
}) => {
  const [extraModalJson, setExtraModalJson] = useState({
    ..._get(addExtraPlanData, '["data"]', {}),
    ..._get(Config, '["data"]')
  })
  const [isShowMoreOpen, toggleShowMore] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [saveCardIsLoading, setSaveCardIsLoading] = useState(false)
  const [isResetData, setIsResetData] = useState(false)
  const [
    doneLoadingSubtitlePlaceholders,
    setDoneLoadingSubtitlePlaceholders
  ] = useState(_get(extraModalJson, 'resultScreen.messagePlaceholders', []))
  const [isLoadingSuccessful, setIsLoadingSuccessful] = useState(true)
  const [loadingFailureError, setLoadingFailureError] = useState({})
  const [paymentOptions, setPaymentOptions] = useState({
    isCardListOpend: false,
    title: undefined
  })
  const prevVisable = usePrevious(isVisible)
  const [sortedPlanData, setSortedPlanData] = useState([])

  useEffect(() => {
    const amountOfItems = {}
    let sortedArray = []
    const sequence = []
    planData?.forEach((item) => {
      if (!amountOfItems[item?.usageData.length]) {
        amountOfItems[item?.usageData.length] = []
      }
      amountOfItems[item?.usageData.length].push(item)
    })
    Object.keys(amountOfItems).forEach(function (key) {
      if (!sequence[0]) {
        sequence.push(key)
      } else {
        sequence.forEach((item, index) => {
          if (key > item) {
            if (index === 0) {
              sequence.unshift(key)
            } else {
              sequence.splice(index - 1, 0, key)
            }
          }
        })
      }
    })
    sequence.forEach((item) => {
      sortedArray = [...sortedArray, ...amountOfItems[item]]
    })

    setSortedPlanData([...sortedArray])
  }, [planData])



  useEffect(() => {
    forceOpenModal &&
      forceOpenModal.force &&
      toggleAddUsageModal(forceOpenModal.key)
  }, [forceOpenModal && forceOpenModal.force])
  useEffect(() => {
    !isVisible && setPaymentOptions({})
    if (prevVisable && !isVisible) {
      onClose && onClose()
    }
  }, [isVisible, prevVisable])
  useEffect(() => {
    if (
      forceOpenModal &&
      forceOpenModal.force &&
      _isFunction(forceOpenModal.onModalVisibilityChange)
    ) {
      forceOpenModal.onModalVisibilityChange(isVisible)
    }
  }, [isVisible])

  const handlePressShowMore = () => {
    UIManager.setLayoutAnimationEnabledExperimental &&
      UIManager.setLayoutAnimationEnabledExperimental(true)
    LayoutAnimation.configureNext(CustomLayoutAnimation)
    toggleShowMore(!isShowMoreOpen)
  }

  let loadingPromise = useRef().current

  const resetModalStatus = () => {
    loadingPromise && loadingPromise.cancel()
    setIsLoading(false)
    setDoneLoadingSubtitlePlaceholders(
      _get(extraModalJson, 'resultScreen.messagePlaceholders', [])
    )
  }

  const toggleModal = () => {
    resetModalStatus()
    setIsVisible(!isVisible)
    setTimeout(() => {
      setIsResetData(!!isVisible)
      setPaymentOptions({
        isCardListOpend: false,
        title: undefined
      })
    }, 200)
  }

  const closeModal = () => {
    resetModalStatus()
    setIsVisible(false)
    setTimeout(() => {
      setIsResetData(true)
      setPaymentOptions({
        isCardListOpend: false,
        title: undefined
      })
    }, 200)
  }

  const handleConfirmButtonPress = (
    paymentTypeOptionSelected,
    paymentAmountOptionSelected
  ) => {
    setIsLoading(true)
    loadingPromise = Promise.resolve(
      onPressAddConfirmation(
        paymentTypeOptionSelected,
        paymentAmountOptionSelected
      )
    )
      .then(placeHolder => {
        setDoneLoadingSubtitlePlaceholders([
          placeHolder || paymentAmountOptionSelected.title
        ])
        setIsLoading(false)
        setIsLoadingSuccessful(true)
      })
      .catch(error => {
        setLoadingFailureError(error)
        setIsLoading(false)
        setIsLoadingSuccessful(false)
      })
  }

  const handleTryAgainPress = () => {
    setIsLoadingSuccessful(true)
  }

  const handlePaymentFooterIconPress = () => { }

  const handleAddUsageButtonPress = item => {
    if (_isFunction(onAddUsagePress)) {
      onAddUsagePress(item, toggleAddUsageModal)
    } else {
      toggleAddUsageModal(item.key)
    }
  }

  const toggleAddUsageModal = key => {
    // spreading config of data first to fall back on data config if config of item was not provided
    setExtraModalJson({
      ..._get(Config, '["data"]', {}),
      ..._get(addExtraPlanData, `[${key}]`, {}),
      ..._get(Config, `[${key}]`, {})
    })
    toggleModal()
  }

  const onPressOpenCardList = () => {
    setPaymentOptions({
      ...paymentOptions,
      isCardListOpend: !paymentOptions.isCardListOpend
    })
  }

  const handleSelectDefaultCard = card => {
    onSelectDefaultCard(card)
    setPaymentOptions({
      ...paymentOptions,
      isCardListOpend: false
    })
  }

  const onPressAddCard = () => {
    setPaymentOptions({
      ...paymentOptions,
      screen: PaymentScreenTypes.ADD_PAYMENT_CARD,
      title: 'quick_action_add_new_payment_card_title',
      isBackButton: true
    })
  }

  const onBackButtonPress = () => {
    setPaymentOptions({
      ...paymentOptions,
      isBackButton: false,
      title: undefined,
      screen: undefined
    })
  }

  const handlePressSaveNewCard = cardData => {
    setSaveCardIsLoading(true)
    setPaymentOptions({
      isBackButton: false,
      title: 'quick_action_add_new_payment_card_title'
    })
    onPressSaveCard(cardData)
      .then(res => {
        setSaveCardIsLoading(false)
        setPaymentOptions({
          ...paymentOptions,
          screen: undefined,
          isCardListOpend: false,
          title: undefined,
          isBackButton: false
        })
      })
      .catch(err => {
        setPaymentOptions({
          ...paymentOptions,
          screen: undefined,
          isCardListOpend: false,
          title: undefined,
          isBackButton: false
        })
        setLoadingFailureError(err)
        setSaveCardIsLoading(false)
      })
  }
  const renderDivider = () => <View style={styles.divider(theme)} />

  const Images = getThemeImages(theme.name)

  const renderUsage = _usageData => {
    if (_isEmpty(_usageData)) {
      return null
    }
    return (
      <View style={styles.usageValuesView}>
        {_map(_usageData, (item, index) => {
          const {
            usageItemLabel,
            usageValuesText,
            usageValuesPlaceholders,
            isUnlimitedUsage,
            addUsageButtonTitle,
            icon,
            shouldShowAddUsageButton,
            usagePercentageValue
          } = item
          return (
            <MyPlanUsageItem
              usageItemLabel={usageItemLabel}
              usageValuesText={usageValuesText}
              usageValuesPlaceholders={usageValuesPlaceholders}
              isUnlimitedUsage={isUnlimitedUsage}
              unlimitedUsageText={'my_plan_primary_card_unlimited_consumption'}
              addUsageButtonTitle={addUsageButtonTitle}
              withUsagePercentage={withUsagePercentage}
              icon={icon}
              shouldShowAddUsageButton={shouldShowAddUsageButton}
              usagePercentageValue={usagePercentageValue}
              containerStyle={styles.usageItemsContainerStyle}
              images={images}
              addUsageButtonHandler={() => handleAddUsageButtonPress(item)}
              percentageIndicatorFillColor={percentageIndicatorFillColor}
              percentageIndicatorSecondaryColor={
                percentageIndicatorSecondaryColor
              }
              percentageIndicatorContainerStyle={
                percentageIndicatorContainerStyle
              }
              percentageIndicatorShouldAnimate={
                percentageIndicatorShouldAnimate
              }
              // eslint-disable-next-line react-native/no-inline-styles
              usageItemLabelTextStyle={{ minWidth: 110 }}
            />
          )
        })}
      </View>
    )
  }

  const renderPrice = (_price, priceUnit) => {
    if (!_price) {
      return null
    }
    return (
      <View style={styles.priceDetailsViewStyle}>
        <VFText
          i18nKey={'my_plan_primary_card_price_label'}
          style={styles.priceLabelStyle}
        />
        <View style={styles.priceValueViewStyle}>
          <VFText i18nKey={_price} style={styles.priceValueAmountStyle} />
          <VFText i18nKey={priceUnit} style={styles.priceValueUnitStyle} />
        </View>
      </View>
    )
  }

  const renderContractData = _contractData => {
    if (_isEmpty(_contractData)) {
      return null
    }
    return _map(_contractData, (item, index) => {
      const { key, value } = item
      if (index < 2) {
        return <MyPlanInfoItem label={key} value={value} />
      }
    })
  }

  const renderUpgradePlanCTA = _withUpgradePlanCTA => {
    if (!_withUpgradePlanCTA) {
      return null
    }
    return (
      <VFButton
        title="my_plan_primary_card_upgrade_plan"
        type="primary"
        style={styles.upgradeButtonStyle}
        onPress={onPressUpgradePlan}
      />
    )
  }

  const renderShowMore = _contractData => {
    if (_contractData && _contractData.length > 2) {
      return (
        <TouchableWithoutFeedback onPress={handlePressShowMore}>
          <View style={styles.showMoreContainer}>
            <VFText
              i18nKey={
                isShowMoreOpen
                  ? 'my_plan_primary_card_show_less'
                  : 'my_plan_primary_card_show_more'
              }
              style={styles.showMoreText(theme)}
            />
          </View>
        </TouchableWithoutFeedback>
      )
    }
  }

  const renderMoreContractData = (_isShowMoreOpen, _contractData) => {
    if (!_isShowMoreOpen) {
      return null
    }
    return (
      <View>
        {_isShowMoreOpen &&
          _map(_contractData, (item, index) => {
            const { key, value } = item
            if (index > 1) {
              return <MyPlanInfoItem label={key} value={value} />
            }
          })}
      </View>
    )
  }


  const GetPlanCardBody = planItem => {
    const {
      usageData,
      contractData,
      price,
      priceUnit,
      withUpgradePlanCTA
    } = planItem

    const planCardBodyComponents = [
      renderUsage(usageData),
      renderPrice(price, priceUnit),
      renderContractData(contractData),
      renderUpgradePlanCTA(withUpgradePlanCTA),
      renderMoreContractData(isShowMoreOpen, contractData),
      renderShowMore(contractData)
    ].filter(item => item)

    return planCardBodyComponents.map((item, index) => {
      return (
        <>
          {index % 2 !== 0 && renderDivider()}
          {item}
          {index % 2 !== 0 &&
            index !== planCardBodyComponents.length - 1 &&
            renderDivider()}
        </>
      )
    })
  }

  if (Dimensions.get('window').width > 700 && Platform.OS === 'web') {
    return (
      <View
        style={[styles.container, containerStyle]}
      >
        <MyPlanExtrasModal
          toggleModal={toggleModal}
          isVisible={isVisible}
          closeModal={closeModal}
          type={Types.SECONDARY}
          isLoading={isLoading}
          saveCardIsLoading={saveCardIsLoading}
          title={_get(extraModalJson, 'title', '')}
          paymentTypeTitle={_get(extraModalJson, 'paymentTypeTitle', '')}
          paymentTypeTitleStyle={{}}
          paymentTypeOptions={_get(extraModalJson, 'options', [])}
          paymentAmountTitle={_get(extraModalJson, 'paymentAmountTitle', '')}
          paymentAmountFirstTypeOptions={_get(
            extraModalJson,
            'options[0].items',
            []
          )}
          paymentAmountSecondTypeOptions={_get(
            extraModalJson,
            'options[1].items',
            []
          )}
          paymentMethodTitle={_get(extraModalJson, 'paymentMethod.title', '')}
          paymentMethodTitleStyle={{}}
          paymentFooterTitle={_get(extraModalJson, 'paymentMethod.subTitle', '')}
          paymentFooterIcon={Images.edit_pen}
          handlePaymentFooterIconPress={handlePaymentFooterIconPress}
          paymentFooterFirstTypeDescription={_get(
            extraModalJson,
            'options[0].description',
            ''
          )}
          paymentFooterSecondTypeDescription={_get(
            extraModalJson,
            'options[1].description',
            ''
          )}
          confirmButtonType={Types.PRIMARY}
          confirmButtonTitle={_get(
            extraModalJson,
            'paymentMethod.firstButtonText',
            ''
          )}
          handleConfirmButtonPress={handleConfirmButtonPress}
          cancelButtonType={Types.SECONDARY}
          cancelButtonTitle={_get(
            extraModalJson,
            'paymentMethod.secondButtonText',
            ''
          )}
          handleCancelButtonPress={closeModal}
          loadingMessage={_get(
            extraModalJson,
            'loadingScreen.loadingMessage',
            ''
          )}
          isLoadingSuccessful={isLoadingSuccessful}
          doneLoadingFailureIcon={Images.ic_WarningHiLight_Theme}
          doneLoadingMainTitle={
            isLoadingSuccessful
              ? _get(extraModalJson, 'resultScreen.title', '')
              : _get(loadingFailureError, 'message', '')
          }
          doneLoadingSubTitle={
            isLoadingSuccessful
              ? _get(extraModalJson, 'resultScreen.message', '')
              : _get(loadingFailureError, 'description', '')
          }
          doneLoadingSubtitlePlaceholders={doneLoadingSubtitlePlaceholders}
          doneLoadingReturnButtonType={Types.PRIMARY}
          doneLoadingReturnButtonText={
            isLoadingSuccessful
              ? _get(extraModalJson, 'resultScreen.buttonText', '')
              : _get(extraModalJson, 'resultScreen.failureButtonText', '')
          }
          doneLoadingReturnButtonAction={
            isLoadingSuccessful ? closeModal : handleTryAgainPress
          }
          isResetData={isResetData}
          paymentCards={paymentCards}
          defaultPayment={defaultPayment}
          onSelectDefaultCard={handleSelectDefaultCard}
          onPressOpenCardList={onPressOpenCardList}
          paymentOptions={paymentOptions}
          onPressAddCard={onPressAddCard}
          onPressSaveCard={handlePressSaveNewCard}
          onBackButtonPress={onBackButtonPress}
        />
        <VFText i18nKey={title} style={styles.headerTitle(theme)} />
        {!isError ? (
          <VFText i18nKey={subtitle} style={styles.headerSubTitle(theme)} />
        ) : null}
        {isMyPlanLoading ? (
          <MyPlanCardLoading />
        ) : isError ? (
          <ErrorScreen
            iconStyle={styles.errorIconStyle}
            text={errorText}
            images={errorImages}
            iconName={errorIconName}
            iconButtonName={tryAgainIconName}
            onPress={onTryAgainPress}
            textButton={'my_plan_screen_try_again'}
          />
        ) : (
          <View style={styles.cardWrapper(Platform.OS === 'web' && Dimensions.get('window').width > 700)}>
            {sortedPlanData.map(item => {
              return (
                <MyPlanCard status={item.status} title={item.title} renewalDate={item.datePlaceholders[0]} locale={ReduxCache.getLanguage()}>
                  {GetPlanCardBody(item)}
                </MyPlanCard>
              )
            })}
          </View>
        )}
      </View>
    )
  }


  return (
    <ScrollView
      scrollEnabled={!isMyPlanLoading}
      style={[styles.container, containerStyle]}
      contentContainerStyle={styles.scrollViewContainerStyle(withTray)}
    >
      <MyPlanExtrasModal
        toggleModal={toggleModal}
        isVisible={isVisible}
        closeModal={closeModal}
        type={Types.SECONDARY}
        isLoading={isLoading}
        saveCardIsLoading={saveCardIsLoading}
        title={_get(extraModalJson, 'title', '')}
        paymentTypeTitle={_get(extraModalJson, 'paymentTypeTitle', '')}
        paymentTypeTitleStyle={{}}
        paymentTypeOptions={_get(extraModalJson, 'options', [])}
        paymentAmountTitle={_get(extraModalJson, 'paymentAmountTitle', '')}
        paymentAmountFirstTypeOptions={_get(
          extraModalJson,
          'options[0].items',
          []
        )}
        paymentAmountSecondTypeOptions={_get(
          extraModalJson,
          'options[1].items',
          []
        )}
        paymentMethodTitle={_get(extraModalJson, 'paymentMethod.title', '')}
        paymentMethodTitleStyle={{}}
        paymentFooterTitle={_get(extraModalJson, 'paymentMethod.subTitle', '')}
        paymentFooterIcon={Images.edit_pen}
        handlePaymentFooterIconPress={handlePaymentFooterIconPress}
        paymentFooterFirstTypeDescription={_get(
          extraModalJson,
          'options[0].description',
          ''
        )}
        paymentFooterSecondTypeDescription={_get(
          extraModalJson,
          'options[1].description',
          ''
        )}
        confirmButtonType={Types.PRIMARY}
        confirmButtonTitle={_get(
          extraModalJson,
          'paymentMethod.firstButtonText',
          ''
        )}
        handleConfirmButtonPress={handleConfirmButtonPress}
        cancelButtonType={Types.SECONDARY}
        cancelButtonTitle={_get(
          extraModalJson,
          'paymentMethod.secondButtonText',
          ''
        )}
        handleCancelButtonPress={closeModal}
        loadingMessage={_get(
          extraModalJson,
          'loadingScreen.loadingMessage',
          ''
        )}
        isLoadingSuccessful={isLoadingSuccessful}
        doneLoadingFailureIcon={Images.ic_WarningHiLight_Theme}
        doneLoadingMainTitle={
          isLoadingSuccessful
            ? _get(extraModalJson, 'resultScreen.title', '')
            : _get(loadingFailureError, 'message', '')
        }
        doneLoadingSubTitle={
          isLoadingSuccessful
            ? _get(extraModalJson, 'resultScreen.message', '')
            : _get(loadingFailureError, 'description', '')
        }
        doneLoadingSubtitlePlaceholders={doneLoadingSubtitlePlaceholders}
        doneLoadingReturnButtonType={Types.PRIMARY}
        doneLoadingReturnButtonText={
          isLoadingSuccessful
            ? _get(extraModalJson, 'resultScreen.buttonText', '')
            : _get(extraModalJson, 'resultScreen.failureButtonText', '')
        }
        doneLoadingReturnButtonAction={
          isLoadingSuccessful ? closeModal : handleTryAgainPress
        }
        isResetData={isResetData}
        paymentCards={paymentCards}
        defaultPayment={defaultPayment}
        onSelectDefaultCard={handleSelectDefaultCard}
        onPressOpenCardList={onPressOpenCardList}
        paymentOptions={paymentOptions}
        onPressAddCard={onPressAddCard}
        onPressSaveCard={handlePressSaveNewCard}
        onBackButtonPress={onBackButtonPress}
      />
      <VFText i18nKey={title} style={styles.headerTitle(theme)} />
      {!isError ? (
        <VFText i18nKey={subtitle} style={styles.headerSubTitle(theme)} />
      ) : null}
      {isMyPlanLoading ? (
        <MyPlanCardLoading />
      ) : isError ? (
        <ErrorScreen
          iconStyle={styles.errorIconStyle}
          text={errorText}
          images={errorImages}
          iconName={errorIconName}
          iconButtonName={tryAgainIconName}
          onPress={onTryAgainPress}
          textButton={'my_plan_screen_try_again'}
        />
      ) : (
        <View style={styles.cardWrapper(Platform.OS === 'web' && Dimensions.get('window').width > 700)}>
          {planData.map(item => {
            return (
              <MyPlanCard status={item.status} title={item.title} renewalDate={item.datePlaceholders[0]} locale={ReduxCache.getLanguage()}>
                {GetPlanCardBody(item)}
              </MyPlanCard>
            )
          })}
        </View>
      )}
    </ScrollView>
  )
}

MyPlan.defaultProps = {
  theme: LightThemeColors,
  withUpgradePlanCTA: true,
  withUsagePercentage: true,
  percentageIndicatorShouldAnimate: false
}

MyPlan.propTypes = {
  onClose: PropTypes.func,
  defaultPayment: PropTypes.object,
  onSelectDefaultCard: PropTypes.func,
  onPressSaveCard: PropTypes.func,
  withTray: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  containerStyle: ViewPropTypes.style,
  images: PropTypes.object,
  planData: PropTypes.objectOf({
    status: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.string,
    priceUnit: PropTypes.string,
    usageData: PropTypes.objectOf({
      key: PropTypes.string,
      usageItemLabel: PropTypes.string,
      usageValuesText: PropTypes.string,
      usageValuesPlaceholders: PropTypes.arrayOf(PropTypes.string),
      isUnlimitedUsage: PropTypes.bool,
      icon: PropTypes.string,
      shouldShowAddUsageButton: PropTypes.bool,
      usagePercentageValue: PropTypes.number,
      addUsageButtonTitle: PropTypes.string
    }),
    contractData: PropTypes.objectOf({
      key: PropTypes.string,
      value: PropTypes.string
    })
  }),
  theme: PropTypes.objectOf({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  }),
  addExtraPlanData: PropTypes.object,
  onPressAddConfirmation: PropTypes.func,
  forceOpenModal: PropTypes.objectOf({
    force: PropTypes.bool,
    key: PropTypes.string,
    onModalVisibilityChange: PropTypes.func
  }),
  withUpgradePlanCTA: PropTypes.bool,
  withUsagePercentage: PropTypes.bool,
  percentageIndicatorFillColor: PropTypes.string,
  percentageIndicatorSecondaryColor: PropTypes.string,
  percentageIndicatorContainerStyle: ViewPropTypes.style,
  percentageIndicatorShouldAnimate: PropTypes.bool,
  paymentCards: PropTypes.arrayOf({
    brand: PropTypes.string,
    lastFourDigits: PropTypes.string,
    cardName: PropTypes.string,
    expiry: PropTypes.string,
    icon: PropTypes.string
  }),
  onAddUsagePress: PropTypes.func,
  isMyPlanLoading: PropTypes.bool,
  onPressUpgradePlan: PropTypes.func,
  isError: PropTypes.bool,
  onTryAgainPress: PropTypes.func,
  errorText: PropTypes.string,
  errorImages: PropTypes.array,
  errorIconName: PropTypes.string,
  tryAgainIconName: PropTypes.string
}
export { MyPlan }
export default withTheme(MyPlan)
