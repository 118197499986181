import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'
import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import { API_URLS } from '../Config'

const GeographicAddress = async (
    city,
    customHeaders = {},
    utilities = {},
) => {
    const _utilities = retrieveUtilities(utilities)

    const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

    onStart()

    try {
        const response = await axios.get(API_URLS.fix.geographic.geographicAddress, {
            params: {
                'city': city,
            },
            headers: { ...customHeaders },
            data: null,
        })

        const _response = responseMapper(response)
        onSuccess(_response)
        return _response
    } catch (error) {
        const _error = errorMapper(error)
        if (_isFunction(onError)) {
            onError(_error)
        } else {
            throw _error
        }
    }
}

const GeographicAddressValidation = async (
    coords,
    customHeaders = {},
    utilities = {},
) => {
    const _utilities = retrieveUtilities(utilities)

    const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

    onStart()

    try {
        const response = await axios.get(API_URLS.fix.geographic.geographicAddressValidation, {
            params: {
                'submittedGeographicAddress.geographicLocation.geometry.x': coords.longitude,
                'submittedGeographicAddress.geographicLocation.geometry.y': coords.latitude,

            },
            headers: { ...customHeaders },
            data: null,
        })

        const _response = responseMapper(response)
        onSuccess(_response)
        return _response
    } catch (error) {
        const _error = errorMapper(error)
        if (_isFunction(onError)) {
            onError(_error)
        } else {
            throw _error
        }
    }
}

export { GeographicAddress, GeographicAddressValidation }
