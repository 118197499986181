import React, { useEffect, useRef, useState } from 'react'
import { VFText, Icon, VFTouchableInScroll } from '@vfgroup-oneplatform/foundation/Components'
import { withTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { Dimensions, View, Animated } from 'react-native'
import PropTypes from 'prop-types'

import AsyncStorage from '@react-native-async-storage/async-storage'
import VFLottieView from '@vfgroup-oneplatform/foundation/Components/VFLottieView'


import styles from './GeneralComponent.Styles'
const reward = require('./MA-008_Unlock-rewards_HiFi_Dark_@1x_Lottie.json')

const GeneralComponent = ({ theme, title, image, placeHolders, subTitle, onPress, hasBackground, shouldShowOffersAnimation }) => {

  const shakeAnimation = useRef(new Animated.Value(0)).current;
  const shakeIconAnimation = useRef(new Animated.Value(0)).current;
  const [runLoop, setRunLoop] = useState(true)

  useEffect(() => {
    if (title == 'Shake') {
      runAnimation()
    }

  }, [shakeAnimation])

  const runAnimation = async () => {
    const keyExist = await AsyncStorage.getItem('shake_animation')

    if (keyExist) {
      return
    }

    Animated.sequence([
      Animated.timing(shakeAnimation, { toValue: 5, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeAnimation, { toValue: -5, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeAnimation, { toValue: 5, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeAnimation, { toValue: 0, duration: 100, useNativeDriver: true }),

      Animated.timing(shakeAnimation, { toValue: 0, duration: 200, useNativeDriver: true }),

      Animated.timing(shakeAnimation, { toValue: 5, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeAnimation, { toValue: -5, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeAnimation, { toValue: 5, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeAnimation, { toValue: 0, duration: 100, useNativeDriver: true }),

      Animated.timing(shakeAnimation, { toValue: 0, duration: 200, useNativeDriver: true }),

      Animated.timing(shakeAnimation, { toValue: 5, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeAnimation, { toValue: -5, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeAnimation, { toValue: 5, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeAnimation, { toValue: 0, duration: 100, useNativeDriver: true })
    ]).start();


    Animated.sequence([
      Animated.timing(shakeIconAnimation, { toValue: 12, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeIconAnimation, { toValue: -12, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeIconAnimation, { toValue: 12, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeIconAnimation, { toValue: 0, duration: 100, useNativeDriver: true }),

      Animated.timing(shakeIconAnimation, { toValue: 0, duration: 200, useNativeDriver: true }),

      Animated.timing(shakeIconAnimation, { toValue: 12, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeIconAnimation, { toValue: -12, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeIconAnimation, { toValue: 12, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeIconAnimation, { toValue: 0, duration: 100, useNativeDriver: true }),

      Animated.timing(shakeIconAnimation, { toValue: 0, duration: 200, useNativeDriver: true }),

      Animated.timing(shakeIconAnimation, { toValue: 12, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeIconAnimation, { toValue: -12, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeIconAnimation, { toValue: 12, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeIconAnimation, { toValue: 0, duration: 100, useNativeDriver: true }),
    ]).start();

    AsyncStorage.setItem('shake_animation', '1')

  }

  const spin = shakeIconAnimation.interpolate({
    inputRange: [0, 100],
    outputRange: ['0deg', '100deg']
  })

  useEffect(() => {
    if (shouldShowOffersAnimation) {
      setTimeout(() => {
        setRunLoop(false)
      }, 4000)
    }
  }, [shouldShowOffersAnimation])

  return (
    <VFTouchableInScroll onPress={onPress}>
      <Animated.View style={[styles.container, { transform: [{ translateX: shakeAnimation }] }]}>
        <View style={styles.messagesContainer}>
          <View style={styles.iconAndTitleContainer}>
            {shouldShowOffersAnimation ? <VFLottieView
              loop={runLoop}
              autoPlay
              source={reward}
              type="custom"
              lottieViewStyle={styles.lottieStyle}
            /> : <Animated.View style={{ transform: [{ rotate: spin }] }}>
              <Icon source={image} style={[styles.icon, { tintColor: hasBackground ? '#fff' : 'default' }]} size={Dimensions.get('window').width > 700 ? 32 : 25} />
            </Animated.View>}
            <VFText numberOfLines={2} type={hasBackground ? 'secondary' : 'primary'} i18nKey={title} style={styles.title} />
          </View>
          <View style={styles.subtitleConatier}>
            <VFText
              i18nKey={subTitle}
              placeHolders={placeHolders}
              style={styles.messagesSubtitle(theme)}
              type={hasBackground ? 'secondary' : 'primary'}
            />
          </View>
        </View>
      </Animated.View>
    </VFTouchableInScroll>
  )
}

GeneralComponent.propTypes = {
  theme: PropTypes.objectOf({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  }),
  title: PropTypes.string,
  image: PropTypes.object,
  placeHolders: PropTypes.arrayOf(PropTypes.string),
  subTitle: PropTypes.string,
  shouldShowOffersAnimation: PropTypes.bool
}

export default withTheme(GeneralComponent)
