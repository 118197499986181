import { StyleSheet } from 'react-native'

import Fonts from './Fonts'
import Metrics from './Metrics'
import Colors from './Colors'

const getApplicationStyles = (_fonts, _metrics, _colors) => ({
  screen: {
    mainContainer: {
      flex: 1,
      backgroundColor: _colors.transparent
    },
    backgroundImage: {
      ...StyleSheet.absoluteFill
    },
    container: {
      flex: 1,
      // paddingTop: Metrics.baseMargin,
      backgroundColor: _colors.transparent
    },
    section: {
      margin: _metrics.section,
      padding: _metrics.baseMargin
    },
    sectionText: {
      ..._fonts.style.normal,
      paddingVertical: _metrics.doubleBaseMargin,
      color: _colors.snow,
      marginVertical: _metrics.smallMargin,
      textAlign: 'center'
    },
    subtitle: {
      color: _colors.snow,
      padding: _metrics.smallMargin,
      marginBottom: _metrics.smallMargin,
      marginHorizontal: _metrics.smallMargin
    },
    titleText: {
      ..._fonts.style.h2,
      fontSize: 14,
      color: _colors.text
    },
    sectionTitle: {
      ..._fonts.style.h4,
      color: _colors.coal,
      backgroundColor: _colors.ricePaper,
      padding: _metrics.smallMargin,
      marginTop: _metrics.smallMargin,
      marginHorizontal: _metrics.baseMargin,
      borderWidth: 1,
      borderColor: _colors.ember,
      alignItems: 'center',
      textAlign: 'center'
    }
  }
})

const ApplicationStyles = {
  ...getApplicationStyles(Fonts, Metrics, Colors)
}

export default ApplicationStyles
export { getApplicationStyles }
