import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'
import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import ReduxCache from '../../Utils/ReduxCache'

import { convertCity } from '../../Utils/Helpers'

import { API_URLS } from '../Config'

const defaultResponseMapper = response => {
  const { data } = response
  const fixItems = data?.relatedParty?.filter(
    item =>
      item?.['@referredType'] === 'fix' &&
      item?.role.toLowerCase() === 'customer'
  )
  if (fixItems?.length && fixItems?.length > 0) {
    return true
  }
  return false
}

const mapError = error => {
  const payload = error?.response?.data?.message
    ? {
        message: error?.response?.data?.message,
        code: error?.response?.data?.code
      }
    : {
        message: error?.message
      }
  return payload
}

const getInternetOffers = async (id, customHeaders = {}, utilities = {}) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } =
    _utilities

  onStart()

  try {
    const response = await axios.get(API_URLS.pega, {
      params: {
        'relatedParty.id': ReduxCache.getMsisdn(),
        'relatedParty.role': 'customer',
        'channel.id': 'selfcare',
        'recommendationItem.place.id': id, //enter searched address here
        'recommendationItem.productCharacteristic.name': 'SpecSubType',
        'recommendationItem.productCharacteristic.value': 'Internet',
        type: 'purchase'
      },
      headers: { ...customHeaders },
      data: null
    })

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const getTvOffers = async (id, customHeaders = {}, utilities = {}) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } =
    _utilities

  onStart()

  try {
    const response = await axios.get(API_URLS.pega, {
      params: {
        'relatedParty.id': ReduxCache.getMsisdn(),
        'relatedParty.role': 'customer',
        'channel.id': 'selfcare',
        'recommendationItem.place.id': id, //enter searched address here
        'recommendationItem.productCharacteristic.name': 'SpecSubType',
        'recommendationItem.productCharacteristic.value': 'CableTV',
        type: 'purchase'
      },
      headers: { ...customHeaders },
      data: null
    })

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const validateFixUser = async (customHeaders = {}, utilities = {}) => {
  const _utilities = retrieveUtilities(utilities, { errorMapper: mapError })

  const { onStart, onSuccess, onError, errorMapper } = _utilities

  onStart()

  try {
    const response = await axios.get(
      `${API_URLS.fix.customerManagement}/${ReduxCache.getMsisdn()}`,
      {
        headers: { ...customHeaders },
        data: null
      }
    )
    const _response = defaultResponseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const getCustomer = async (id, customHeaders = {}, utilities = {}) => {
  const _utilities = retrieveUtilities(utilities, { errorMapper: mapError })

  const { onStart, onSuccess, onError, responseMapper, errorMapper } =
    _utilities

  onStart()

  const params = {
    'individualIdentification.identificationId': id,
    'individualIdentification.identificationType': 'ID_CARD'
  }

  try {
    const response = await axios.get(`${API_URLS.fix.partyApi}/individual`, {
      headers: { ...customHeaders },
      data: null,
      params
    })

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const postIndividualData = async (
  _data,
  customHeaders = {},
  utilities = {}
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper } = _utilities

  onStart()

  const {
    givenName,
    familyName,
    identificationId,
    issuingAuthority,
    endDateTime,
    frontIdPhotoBase64,
    backIdPhotoBase64,
    selfieBase64,
    street,
    city,
    country
  } = _data || {}
  const cityName = convertCity(city)
  const regex = /data:.*base64,/
  const data = {
    status: 'prospect',
    givenName: givenName,
    familyName: familyName,
    contactMedium: [
      {
        mediumType: '',
        preferred: true,
        characteristic: {
          city: cityName.toUpperCase(),
          contactType: '',
          country: country.toUpperCase(),
          emailAddress: '',
          faxNumber: '',
          phoneNumber: '',
          postCode: '1001',
          socialNetworkId: '',
          stateOrProvince: '',
          street1: street.toUpperCase(),
          street2: '',
          '@baseType': '',
          '@schemaLocation': '',
          '@type': ''
        },
        validFor: {
          endDateTime: '2022-08-18T12:59:19.430Z',
          startDateTime: '2022-08-18T12:59:19.430Z'
        },
        '@baseType': '',
        '@schemaLocation': '',
        '@type': ''
      }
    ],
    individualIdentification: [
      {
        identificationId: identificationId,
        identificationType: 'idCard',
        issuingAuthority: issuingAuthority,
        attachment: {
          attachmentType: 'front_image',
          content: frontIdPhotoBase64.replace(regex, ''),
          mimeType: 'png',
          size: {
            amount: 1,
            units: ''
          }
        },
        validFor: {
          endDateTime: endDateTime
        }
      },
      {
        identificationId: identificationId,
        identificationType: 'idCard',
        issuingAuthority: issuingAuthority,
        attachment: {
          attachmentType: 'back_image',
          content: backIdPhotoBase64.replace(regex, ''),
          mimeType: 'png',
          size: {
            amount: 1,
            units: ''
          }
        },
        validFor: {
          endDateTime: endDateTime
        }
      },
      {
        identificationId: identificationId,
        identificationType: 'idCard',
        issuingAuthority: issuingAuthority,
        attachment: {
          attachmentType: 'selfie_image',
          content: selfieBase64.replace(regex, ''),
          mimeType: 'png',
          size: {
            amount: 1,
            units: ''
          }
        },
        validFor: {
          endDateTime: endDateTime
        }
      }
    ],
    relatedParty: [
      {
        id: ReduxCache.getMsisdn(),
        '@type': 'Customer',
        '@referredType': 'Customer'
      }
    ]
  }

  try {
    const response = await axios.post(API_URLS.fix.partyIndividualApi, data, {
      headers: { ...customHeaders }
    })

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = mapError(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

export {
  getInternetOffers,
  getTvOffers,
  validateFixUser,
  getCustomer,
  postIndividualData
}
