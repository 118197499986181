import React, {useContext, useState, useMemo} from 'react'
import {Animated, Easing, View, ColorPropType, Platform} from 'react-native'
import PropTypes from 'prop-types'

function CheckBox(props) {
  const {
    value: checked,
    onValueChange,
    tintColors,
    disabled: isDisabled,
    disabledTintColors,
    style
  } = props
  const [anim] = useState(new Animated.Value(0))

  if (checked) {
    Animated.timing(anim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
      easing: Easing.linear
    }).start()
  } else {
    Animated.timing(anim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
      easing: Easing.linear
    }).start()
  }

  return <input
      type="checkbox"
      checked={checked}
      disabled={isDisabled}
      onChange={() => onValueChange(!checked)}
      style={{...style, width: 20, height: 20}}
    />
}

export default CheckBox
