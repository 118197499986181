import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'
import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import { API_URLS } from '../Config'
import ReduxCache from '../../Utils/ReduxCache'

const ActivateOffer = async (
  url,
  customHeaders = {
    'vf-operator': 'promoVoucher',
    'X-Source-Operator': 'promoVoucher'
  },
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

  const msisdn = ReduxCache.getMsisdn()

  const data = {
    'category': 'CocaColaBonus',
    'productOrderItem': [
      {
        'id': 'CocaColaBonus',
        'action': 'add',
        'product': {
          'id': 'CocaColaBonus',
          'productCharacteristic': [
            {
              'name': 'reward',
              'value': url,
              '@baseType': 'reward',
              '@type': 'string'
            }
          ]
        }
      }
    ],
    'relatedParty': [
      {
        'id': msisdn,
        'role': 'subscriber',
        '@referredType': ''
      }
    ],
    '@type': 'payments_bonus'
  }

  onStart()

  try {
    const response = await axios.post(API_URLS.cocaCola.activate, data, { headers: customHeaders })

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

export { ActivateOffer }
