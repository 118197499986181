import { actionTypes } from './Login.Actions'

function auth(state = {}, action = {}) {
  if (action.type === actionTypes.LOGIN_SUCCESS) {
    return {
      ...state,
      ...action.data
    }
  } else if (action.type == actionTypes.LOGOUT) {
    return {}
  } else if (action.type == actionTypes.RESET_TOKEN) {
    return {
      ...state,
      token: null,
      access_token: null,
      ms_access_token: null,
    }
  } else if (action.type === actionTypes.SWITCH_VIEW) {
    return {
      ...state,
      businessAccount: {
        ...state?.businessAccount,
        showBusinessView: action.data
      }
    }
  } else {
    return state
  }
}

export { auth }
