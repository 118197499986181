export const BluetoothManager = {}
export const BluetoothEscposPrinter = {}
export const PERMISSIONS = {}
export const RESULTS = {}
export const check = ()=>{}
export const checkNotifications = ()=>{}
export const request = ()=>{}
export const openSettings  = ()=>{}
export const PROVIDER_GOOGLE = {}
export const Marker = {}
export const AnimatedRegion = {}
export const launchCamera = {}
export const launchImageLibrary = {}
export const requestNotifications = {}
export const selectContactPhone = ()=>{}
export const getGenericPassword = ()=>{}
export const getInternetCredentials = ()=>{}
export const hasInternetCredentials = ()=>{}
export const setGenericPassword = ()=>{}
export const setInternetCredentials = ()=>{}
export const resetGenericPassword = ()=>{}
export const resetInternetCredentials = ()=>{}
export const KeyboardAwareScrollView = ()=> null
export const CodeField = ()=> null
export const Cursor = ()=> null
export const MaskSymbol = ()=> null
export const isLastFilledCell = ()=>{}
export const useBlurOnFulfill = ()=>{}
export const useClearByFocusCell = ()=>{}

export default {types: {}, fetch: async ()=> { return {isConnected: true}}, addEventListener: ()=> true,  clearInterval: ()=> true,  setInterval: ()=> true}
