import React, { createContext, useState, useContext } from 'react'
import PropTypes from 'prop-types'

import BottomSheetSelector from '../Components/DynamicCoupon'

const DynamicCoupon = createContext()

function DynamicCouponProvider({ children }) {
  const [activeScreen, setActiveScreen] = useState('input')
  const [couponValue, setCouponValue] = useState(null)
  const [isGet, setIsGet] = useState(true)

  const [modal, setModal] = useState({
    isVisible: false,
    onGenerateCoupon: () => { },
    onCancelCoupon: () => { },
    error: {},
  })

  const closeModal = () => {
    setModal(prev => ({ ...prev, isVisible: false, error: {} }))
    setActiveScreen('input')
    setCouponValue(null)
  }

  const openCoupon = (
    onOpen,
    onGenerateCoupon,
    onCancelCoupon
  ) => {
    onOpen()
    setActiveScreen('loading')
    setModal({
      isVisible: true,
      onGenerateCoupon,
      onCancelCoupon,
    })
  }

  return (
    <DynamicCoupon.Provider value={{
      openCoupon,
      activeScreen,
      couponValue,
      setActiveScreen,
      setCouponValue,
      closeModal,
      isGet,
      setIsGet,
      setModal,
    }}>
      {children}
      <BottomSheetSelector
        {...modal}
        activeScreen={activeScreen}
        setActiveScreen={setActiveScreen}
        value={couponValue}
        setValue={setCouponValue}
        closeModal={closeModal}
        isGet={isGet}
      />
    </DynamicCoupon.Provider>
  )
}
DynamicCouponProvider.propTypes = {
  children: PropTypes.any,
}

function useDynamicCoupon() {
  const context = useContext(DynamicCoupon)
  if (context === undefined) {
    throw new Error('useDynamicCoupon must be used within a DynamicCouponProvider')
  }
  return context
}

export { useDynamicCoupon, DynamicCoupon }
export default DynamicCouponProvider
