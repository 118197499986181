import React from 'react'
import { TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import { VFText, RadioButton as Radio } from '@vfgroup-oneplatform/foundation/Components'

import { styles } from './RadioButton.Styles'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

function RadioButton({
    id,
    label,
    isActive,
    optionStyle,
    activeOptionStyle,
    labelStyle,
    activeLabelStyle,
    setSelected,
    disabled,
    isLast
}) {

    const theme = useTheme()

    return (
        <TouchableOpacity
            disabled={disabled || !setSelected}
            onPress={() => setSelected(id)}
            style={[
                styles.option(theme),
                styles[`isLast_${isLast}`],
                optionStyle,
                isActive && activeOptionStyle
            ]}
            activeOpacity={0.5}
        >
            <VFText
                i18nKey={label || ''}
                style={[
                    styles.label,
                    labelStyle,
                    isActive && activeLabelStyle
                ]}
            />
            <Radio selected={isActive} onPress={() => setSelected(id)} />
        </TouchableOpacity>
    )
}

RadioButton.defaultProps = {
    label: '',
    isActive: false,
    optionStyle: {},
    activeOptionStyle: {},
    labelStyle: {},
    activeLabelStyle: {},
    setSelected: () => { },
    disabled: false
}

RadioButton.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    label: PropTypes.string,
    isActive: PropTypes.bool,
    optionStyle: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    activeOptionStyle: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    labelStyle: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    activeLabelStyle: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    setSelected: PropTypes.func,
    disabled: PropTypes.bool,
    isLast: PropTypes.bool,
}

export default RadioButton
