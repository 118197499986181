import { StyleSheet, Platform } from 'react-native'
import { Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  subHeaderDescriptionStyle: {
    marginTop: Platform.OS === 'android' ? 3 : 7,
    color: Colors.vfRed,
    textDecorationLine: 'underline',
  },
  descriptionStyle: {
    width: 300,
    fontSize: 16.6,
    lineHeight: 22.9,
  },
  subHeaderDescriptionContainerStyle: {
    alignItems: 'baseline',
  },
  buttonStyle: {
    marginBottom: 33.7,
  },
  refreshViewStyle: {
    marginTop: 0,
  },
  titleStyle: {
    fontSize: 16
  }

})
