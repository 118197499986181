// import { LoginStatuses as Status } from 'rn-vfg-login'
import { LoginStatuses as Status } from '@vfgroup-oneplatform/login'

const errorObjectConstructor = (error, defaultError) => {
  const errorResponse = error?.data
  return errorResponse
    ? {
      status: Status.Failed,
      errorMessage: errorResponse.message,
      subErrorMessage: errorResponse.description
    }
    : defaultError
}
export { errorObjectConstructor }
