import React from 'react'
import { Dimensions, Platform } from 'react-native'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { CallMeBackHistory as CallMeBackHistoryScreen } from '@vfal-lab/rn-vfg-call-me-back/Screens'
import { VFScreen } from '@vfgroup-oneplatform/foundation/Components'


function CallMeBackHistory({ navigation }) {

  const { historyData, validityObject } = useSelector(state => state.callMeBackHistory.data || {})


  const goBack = () => navigation.pop()

  const onClose = () => {
    navigation.pop(2)
  }
  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
    return (
      <VFScreen
        headerStyle={{
          backgroundColor: Colors.white,
          borderBottomWidth: 0
        }}
        title={'call_me_back_title'}
        hideMainHeader
      >
        <CallMeBackHistoryScreen
          goBack={goBack}
          onClose
          historyData={historyData}
          validityObject={validityObject}
        />
      </VFScreen>
    )
  }

  return (<CallMeBackHistoryScreen
    onClose={onClose}
    goBack={goBack}
    historyData={historyData}
    validityObject={validityObject}
  />
  )
}
CallMeBackHistory.propTypes = {
  navigation: PropTypes.object,
}

export default CallMeBackHistory
