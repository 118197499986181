import { actionTypes } from './LandingScreen.ActionsTypes'

function configurations(state = {}, action = {}) {
  if (action.type == actionTypes.CONFIGURATIONS_SUCCESS) {
    return {
      ...action.data
    }
  } else {
    return state
  }
}

export { configurations }
