import { actionTypes } from './Quiz.Actions'

function quizGame(
  state = { loading: false, data: null, error: null },
  action = {},
) {
  switch (action.type) {
    case actionTypes.OPEN_QUIZ_EVENT:
      return {
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_QUIZ_EVENT:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }

    case actionTypes.ERROR_QUIZ_EVENT:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}

function quizLevels(
  state = { loading: false, data: null, error: null },
  action = {},
) {
  switch (action.type) {
    case actionTypes.GET_QUIZ_LEVELS:
      return {
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_QUIZ_LEVELS:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }

    case actionTypes.ERROR_QUIZ_LEVELS:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}

function selectedQuizLevel(
  state = { loading: false, data: null, error: null },
  action = {},
) {
  switch (action.type) {
    case actionTypes.GET_QUIZ_LEVELS:
      return {
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_QUIZ_LEVELS:
      return {
        loading: false,
        data: null,
        error: null,
      }

    case actionTypes.ERROR_QUIZ_LEVELS:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}

export {
  quizGame,
  quizLevels,
  selectedQuizLevel,
}
