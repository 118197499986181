import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'
import moment from 'moment'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import ReduxCache from '../../Utils/ReduxCache'

import { API_URLS, COMMON_HEADERS } from '../Config'


const mapError = (error) => {
  const payload = error?.response?.data?.message || error?.message
  return payload
}

const orderResponseMapper = (res) => {
  const data = res?.[0]

  const { id, orderDate, relatedParty, state } = data

  const date = moment(orderDate).format('DD/MM/YYYY')

  const msisdn = relatedParty?.find(el => el?.name === 'msisdn')?.id

  return {
    id,
    date,
    msisdn,
    status: state
  }

}


const patchOrder = async (data, utilities) => {

  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
  })

  const { onStart, onSuccess, onError, errorMapper } = _utilities

  onStart()

  const { orderId, sim } = data


  const msisdn = ReduxCache.getMsisdn()

  const requestData = {
    'category': 'acquisition',
    'description': 'New Connection',
    'externalId': orderId,
    'state': 'acknowledged',
    'channel': [
      {
        'id': 'MVAAPP'
      }
    ],
    'productOrderItem': [
      {
        'id': '',
        'quantity': 1,
        'action': 'modify',
        'product': {
          'id': '',
          'name': ''
        }
      }
    ],
    'relatedParty': [
      {
        'id': msisdn,
        'role': 'Customer',
        '@referredType': 'individual'
      },
      {
        'id': sim,
        'role': 'sim',
        '@referredType': 'individual'
      }
    ]
  }

  const headers = {
    ...COMMON_HEADERS,
    'vf-project': 'dnext',
  }

  try {
    const response = await axios.patch(`${API_URLS.orders.productOrder}/${orderId}`, requestData, {
      headers: headers,
    })
    const _response = response?.data
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}


const getOrder = async (orderId, utilities) => {

  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
  })

  const { onStart, onSuccess, onError, errorMapper } = _utilities

  onStart()

  const headers = {
    ...COMMON_HEADERS,
    'vf-project': 'dnext',
  }

  const params = {
    id: orderId,
    category: 'mobile'
  }

  try {
    const response = await axios.get(API_URLS.orders.productOrder, {
      headers: headers,
      params: params,
    })
    const _response = orderResponseMapper(response?.data)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}


export {
  patchOrder,
  getOrder
}
