import {store} from '../Redux/CreateStore'

const images = key => {
  try {
    const state = store.getState()
    const locale = state.splash.data.locale
    const active = state.splash.data.active
    const allImages = state.images.data[locale]
    return allImages[active][key] || ''
  } catch (error) {
    return ''
  }
}

export default images
