// import { LoginStatuses as Status } from 'rn-vfg-login'
// import { SoftLoginBase } from 'rn-vfg-login/BaseClasses'

import { LoginStatuses as Status } from '@vfgroup-oneplatform/login'
import { SoftLoginBase } from '@vfgroup-oneplatform/login/BaseClasses'

import NavigationService from '../../Navigation/NavigationService'
import { errorObjectConstructor } from '../../Services/Auth/utils'

import { Actions as LoginActions } from '../Login/Login.Actions'

import { Actions } from './ForgotPassword.Actions'

class ForgotPassword extends SoftLoginBase {
  constructor(
    authConfig = {},
    onLoadingStart = () => { },
    onLoadingEnd = () => { }
  ) {
    super(authConfig, onLoadingStart, onLoadingEnd)

    this.maxLength = 14

    this.authStatus = {
      success: {
        status: Status.Success,
        response: {}
      },
      verificationCodeFailure: {
        status: Status.Failed,
        errorMessage: 'Your code is not correct',
        subErrorMessage: 'Please try again.'
      },
      phoneNumberFailure: {
        status: Status.Failed,
        errorMessage: 'An error occurred',
        subErrorMessage: 'Please try again.'
      },
      NotExist: {
        status: Status.Blocked
      }
    }
  }

  getAuthConfig = () => {
    return this.__authConfig
  }

  validatePhone = phoneNumber => {
    return true
  }

  onFinish = async (response) => {
  }

  onConfirmNewPassword = async (password) => {
    try {
      this.onLoginStart()
      await Actions.resetPassword(this.phoneNumber, password, this.verificationCode)

      await LoginActions.loginWithDXL(this.phoneNumber, password)
      this.onVerifyCodeSuccess()
      NavigationService.navigateWithResetAction('Dashboard')
    } catch (error) {
      const errorInfo = errorObjectConstructor(
        error,
        this.authStatus.registerFailure
      )

      throw errorInfo
    }
  }

  onCodeSubmit = async (phoneNumber, verificationCode, verifyCodeLength) => {
    this.verificationCode = verificationCode
    this.phoneNumber = phoneNumber

    NavigationService.navigate('ConfirmNewPasswordScreen', { onNewPasswordConfirm: this.onConfirmNewPassword, onNewPasswordConfirmSuccess: this.onFinish })
  }

  onLoginStart = () => {
    this.__onLoadingStart()
  }

  onVerifyCodeSuccess = async (response = {}) => {
    this.__onLoadingEnd()
  }
}

export default ForgotPassword
