import axios from 'axios'

import { isFunction } from 'lodash'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import { API_URLS } from '../Config'

import {
  errorMapper,
  locationResponseMapper,
  validateLocationResponseMapper
} from './Mappers'

const validateLocation = async ({ body, ...utilities }) => {
  const geometry = 'submittedGeographicAddress.geographicLocation.geometry'
  const _utilities = retrieveUtilities({
    ...utilities,
    errorMapper,
    responseMapper: validateLocationResponseMapper
  })
  _utilities.onStart()
  try {
    const response = await axios.get(
      API_URLS.fix.geographic.geographicAddressValidation,
      {
        params: {
          [`${geometry}.x`]: body.longitude,
          [`${geometry}.y`]: body.latitude
        }
      }
    )
    const _response = _utilities.responseMapper(response)
    _utilities.onSuccess(_response)
    return _response
  } catch (error) {
    const _error = _utilities.errorMapper(error)
    if (isFunction(_utilities.onError)) {
      _utilities.onError(_error)
    } else {
      throw _error
    }
    console.log(error)
  }
}

const getCoveredAreas = async ({ body, ...utilities }) => {
  const _utilities = retrieveUtilities({
    ...utilities,
    errorMapper,
    responseMapper: locationResponseMapper
  })
  _utilities.onStart()
  try {
    const response = await axios.get(
      API_URLS.fix.geographic.geographicAddress,
      {
        params: {
          city: body.city
        }
      }
    )
    const _response = _utilities.responseMapper(response)
    _utilities.onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (isFunction(_utilities.onError)) {
      _utilities.onError(_error)
    } else {
      throw _error
    }
  }
}

export { validateLocation, getCoveredAreas }
