import DarkFrameworkImages from '@vfgroup-oneplatform/framework/Themes/Modes/Dark/Images'
import { Image } from 'react-native'

import Images from '../../Images'

const images = {
  ...DarkFrameworkImages,
  ...Images,

  ic_tv: Images.ic_tv_white,
  ic_tv_and_internet: Images.ic_tv_and_internet_white,
  clock: Images.clock_white,
  data: Images.data_white,
  coca_cola_card: Image.resolveAssetSource(
    require('../../../Assets/Images/coca-cola/card-dark.png')
  ),
  info_circle_black: Image.resolveAssetSource(
    require('../../../Assets/Images/icons/info-white.png')
  ),
  coca_cola_can: Image.resolveAssetSource(
    require('../../../Assets/Images/coca-cola/can-dark.jpg')
  ),
  coca_cola_scan: Image.resolveAssetSource(
    require('../../../Assets/Images/coca-cola/scan.png')
  ),
  coca_cola_success: Image.resolveAssetSource(
    require('../../../Assets/Images/coca-cola/success.png')
  ),
  coca_cola_error: Image.resolveAssetSource(
    require('../../../Assets/Images/coca-cola/error.png')
  ),
  coca_cola_not_found: Image.resolveAssetSource(
    require('../../../Assets/Images/coca-cola/basket.png')
  ),
  info: Images.info_dark,
  notification_error: Images.notification_error_dark,
  need_help: Images.need_help_dark,
  international: Images.international_dark,
  connectivity: Images.connectivity_dark
}

export default images
