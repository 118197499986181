import { store } from '../../Redux'

import { getStories } from '../../Services/Stories/Stories.Services'
import { isAdminView } from '../../Utils'
import ReduxCache from '../../Utils/ReduxCache'

const actionTypes = {
  SET_STORIES: 'SET_STORIES',
  STORIES_LOADING: 'STORIES_LOADING',
  STORIES_ERROR: 'STORIES_ERROR',
  LIKE_STORY: 'LIKE_STORY',
  OPEN_STORY: 'OPEN_STORY'
}

const setStoriesInStore = async (onSuccess) => {
  store.dispatch({ type: actionTypes.STORIES_LOADING, payload: true })
  try {
    const userLoggedIn = ReduxCache.getMsisdn()
    if (userLoggedIn) {

      let name = null
      const userType = ReduxCache.getUserType()
      if (isAdminView()) {
        name = 'business'
      } else if (userType?.includes('guest')) {
        name = 'guest'
      } else {
        name = 'selfcare'
      }

      const stories = await getStories({ name })
      const pegaState = store.getState().pega

      if (name === 'guest' && !pegaState.loading && !pegaState.data) {
        store.dispatch({
          type: actionTypes.SET_STORIES,
          payload: stories // storyt pa ato te p2h
        })
      } else {
        // Check if stories are reccomended by pega
        if (!pegaState.loading && pegaState.data && pegaState?.data?.characteristics) {
          const pegaP2H = Object.keys(pegaState?.data?.characteristics)?.filter(
            key => key?.toLowerCase()?.startsWith('p2h')
          )
          const storiesFilter = stories?.stories?.filter(
            story => story?.isPegaReccomended === false
          )
          const filteredStories = {
            stories: storiesFilter
          }
          store.dispatch({
            type: actionTypes.SET_STORIES,
            payload: pegaP2H?.length > 0 ? stories : filteredStories
          })
        } else {
          store.dispatch({ type: actionTypes.SET_STORIES, payload: stories })
        }
      }
      onSuccess()
    } else {
      throw new Error()
    }
  } catch (error) {
    store.dispatch({ type: actionTypes.STORIES_ERROR, payload: true })
    return error
  }
}

export { setStoriesInStore, actionTypes }
