import {
  GetCustomerPlan,
  GetProducts,
} from '@vfal-lab/rn-vfg-giga-family/Services'

import ReduxCache from '../../Utils/ReduxCache'

import { actionTypes } from './GigaFamily.ActionTypes'

const customerPlanResponseMapper = (response) => {
  const payload = response.data
  return payload?.categories?.[0]?.$ || ''
}

const hasGigaFamilyKey = ({gigafamily}) => gigafamily === 'true'

const productsResponseMapper = (response) => {
  const payload = response.data || []
  const res = {
    title: '',
    products: [],
  }
  if (payload.length > 0) {
    res.title = payload[0].category?.[0]?.value
  }
  const products = []
  payload.map(product => {
    const obj = {
      name: product.name,
      desc: product.shortDescription,
      description: product.desc,
    }

    const characteristics = product.parts?.specification?.characteristicsValue ?? []
    const tags = []
    characteristics.map(item => {
      if (item.characteristicName === 'tag') {
        Boolean(item.value) && tags.push(item.value)
      } else {
        obj[item.characteristicName] = item.value || ''
      }
    })
    obj.tags = tags
    products.push(obj)
  })
  res.isGigaFamily = products.includes(hasGigaFamilyKey)
  res.products = products
  return res
}

const errorMapper = (error) => {
  const payload = error?.response?.data?.message
  return payload
}

const defaultHeaders = {
  'vf-source': 'selfcare',
  'vf-operator': 'gigafamily',
  'vf-country-code': 'al',
}

const getCustomerPlan = (utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_CUSTOMER_PLAN,
    })

    const msisdn = ReduxCache.getMsisdn()

    GetCustomerPlan(msisdn, {}, {
      ...utilities,
      responseMapper: customerPlanResponseMapper,
      errorMapper: errorMapper,
    }).then(response => dispatch({
      type: actionTypes.SUCCESS_CUSTOMER_PLAN,
      payload: response,
    })).catch(error => {
      dispatch({
        type: actionTypes.ERROR_CUSTOMER_PLAN,
        payload: error,
      })
    })
  }
}

const getProducts = (utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_GET_PRODUCTS,
    })

    const msisdn = ReduxCache.getMsisdn()

    GetProducts(msisdn, defaultHeaders, {
      ...utilities,
      responseMapper: productsResponseMapper,
      errorMapper: errorMapper,
    }).then(response => dispatch({
      type: actionTypes.SUCCESS_GET_PRODUCTS,
      payload: response,
    })).catch(error => {
      dispatch({
        type: actionTypes.ERROR_GET_PRODUCTS,
        payload: error,
      })
    })
  }
}

const actions = {
  getCustomerPlan,
  getProducts,
}

export {
  actionTypes,
  actions,
}
