// import * as Keychain from 'react-native-keychain'

import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'
// import { UrbanAirship } from 'urbanairship-react-native'

import { API_URLS, CLIENT_ID, CLIENT_SECRET, GUEST_CLIENT_ID, GUEST_CLIENT_SECRET } from '../Config'

const Login = async (
    msisdn,
    password,
    useGuestClientCredentials = false,
    customHeaders = {},
    utilities = {},
) => {
    const _utilities = retrieveUtilities(utilities)

    const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

    // const channelId = await UrbanAirship.getChannelId()

    onStart()

    const data = {
        'name': 'Check Authentication Credential',
        'desc': 'Check Authentication Credential',
        'type': 'password',
        'category': [
            {
                'listHierarchyId': '1',
                'value': 'password'
            }
        ],
        'details': {
            'passwordText': password,
            'passwordHintText': password,
            'usernameText': msisdn,
            'pin': [
                {
                    'typeCode': 'token',
                    'status': 'Active',
                    'value': '1234'
                }
            ],
            'credentialType': 'password',
            'oldPasswordText': ''
        },
        'parts': {
            'specification': {
                'id': [
                    {
                        'schemeAgencyName': 'Vodafone',
                        'schemeName': 'Specification ID',
                        'value': '123'
                    }
                ],
                'name': 'Consumer Change Authentication Credential',
                'desc': 'Consumer Change Authentication Credential',
                'type': 'token',
                'category': [
                    {
                        'listHierarchyId': '1',
                        'value': 'token'
                    }
                ],
                'characteristicsValue': [
                    {
                        'characteristicName': 'client_id',
                        'value': useGuestClientCredentials ? GUEST_CLIENT_ID : CLIENT_ID,
                        'creationTime': {
                            'value': '2015-04-01T00:00:00'
                        },
                        'validityPeriod': {
                            'fromDate': {
                                'date': '2015-04-01'
                            },
                            'toDate': {
                                'date': '2015-04-01'
                            }
                        }
                    },
                    {
                        'characteristicName': 'client_secret',
                        'value': useGuestClientCredentials ? GUEST_CLIENT_SECRET : CLIENT_SECRET,
                        'creationTime': {
                            'value': '2015-04-01T00:00:00'
                        },
                        'validityPeriod': {
                            'fromDate': {
                                'date': '2015-04-01'
                            },
                            'toDate': {
                                'date': '2015-04-01'
                            }
                        }
                    },
                    {
                        'characteristicName': 'channelID',
                        'creationTime': {
                            'value': ''
                        },
                        'description': '',
                        'type': '',
                        'validityPeriod': {
                            'fromDate': {
                                'date': ''
                            },
                            'toDate': {
                                'date': ''
                            }
                        },
                    }
                ]
            }
        }
    }



    try {
        const response = await axios.post(API_URLS.loginDXL, data, { headers: customHeaders })
        const _response = responseMapper(response)

        onSuccess(_response)
        return _response
    } catch (error) {
        const _error = errorMapper(error)

        if (_isFunction(onError)) {
            onError(_error)
        } else {
            throw _error
        }
    }
}

const RefreshToken = async (
    msisdn,
    customHeaders = {},
    utilities = {},
) => {

    const _utilities = retrieveUtilities(utilities)

    const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities


    // const credentials = await Keychain.getGenericPassword({ service: msisdn })


    // if (!credentials) {
    //     throw new Error()
    // }

    onStart()

    const data = {
        'type': 'password',
        'details': {
            'usernameText': msisdn,
            'credentialType': 'refresh_token'
        },
        'parts': {
            'specification': {
                'characteristicsValue': [
                    {
                        'characteristicName': 'client_id',
                        'value': CLIENT_ID,
                    },
                    {
                        'characteristicName': 'client_secret',
                        'value': CLIENT_SECRET,
                    },
                    {
                        'characteristicName': 'refresh_token',
                        'value': 'credentials.password',
                        'type': ''
                    }
                ]
            }
        }
    }

    try {
        const response = await axios.post(API_URLS.loginDXL, data, { headers: customHeaders })
        const _response = responseMapper(response)

        onSuccess(_response)
        return _response
    } catch (error) {
        const _error = errorMapper(error)

        if (_isFunction(onError)) {
            onError(_error)
        } else {
            throw _error
        }
    }
}

const ResetPassword = async (
    msisdn,
    password,
    pin,
    customHeaders = {},
    utilities = {},
) => {
    const _utilities = retrieveUtilities(utilities)

    const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

    onStart()

    const data = {
        'name': 'Reset Authentication Credential',
        'desc': 'Reset Authentication Credential',
        'type': 'password',
        'category': [
            {
                'listHierarchyId': '1',
                'value': 'password'
            }
        ],
        'details': {
            'passwordText': password,
            'passwordHintText': 's.rogers',
            'usernameText': msisdn,
            'pin': [
                {
                    'typeCode': 'token',
                    'status': 'Active',
                    'value': pin
                }
            ],
            'credentialType': 'password',
            'oldPasswordText': ''
        },
        'parts': {
            'specification': {
                'id': [
                    {
                        'schemeAgencyName': 'Vodafone',
                        'schemeName': 'Specification ID',
                        'value': '123'
                    }
                ],
                'name': 'Consumer Change Authentication Credential',
                'desc': 'Consumer Change Authentication Credential',
                'type': 'token',
                'category': [
                    {
                        'listHierarchyId': '1',
                        'value': 'token'
                    }
                ],
                'characteristicsValue': [
                    {
                        'characteristicName': 'client_id',
                        'value': CLIENT_ID,
                        'creationTime': {
                            'value': '2015-04-01T00:00:00'
                        },
                        'validityPeriod': {
                            'fromDate': {
                                'date': '2015-04-01'
                            },
                            'toDate': {
                                'date': '2015-04-01'
                            }
                        }
                    },
                    {
                        'characteristicName': 'client_secret',
                        'value': CLIENT_SECRET,
                        'creationTime': {
                            'value': '2015-04-01T00:00:00'
                        },
                        'validityPeriod': {
                            'fromDate': {
                                'date': '2015-04-01'
                            },
                            'toDate': {
                                'date': '2015-04-01'
                            }
                        }
                    }
                ]
            }
        }
    }

    try {
        const response = await axios.post(API_URLS.resetPassword + msisdn, data, { headers: customHeaders })

        const _response = responseMapper(response)

        onSuccess(_response)
        return _response
    } catch (error) {
        const _error = errorMapper(error)

        if (_isFunction(onError)) {
            onError(_error)
        } else {
            throw _error
        }
    }
}

const Register = async (
    msisdn,
    password,
    pin,
    customHeaders = {},
    utilities = {},
) => {
    const _utilities = retrieveUtilities(utilities)

    const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

    onStart()

    const data = {
        'name': 'Customer System User Account One',
        'desc': 'The  Customer System User Account for the party',
        'type': 'individual',
        'category': [
            {
                'listHierarchyId': '1',
                'value': 'token'
            }
        ],
        'parts': {
            'credential': [
                {
                    'passwordText': password,
                    'passwordHintText': '',
                    'usernameText': msisdn,
                    'pin': [
                        {
                            'typeCode': 'string',
                            'value': pin,
                            'status': 'Active',
                            'creationTime': {
                                'value': '2015-04-01T00:00:00',
                                'format': 'ISO8601'
                            },
                            'validityPeriod': {
                                'fromDate': {
                                    'date': '2015-05-15-06:00'
                                },
                                'toDate': {
                                    'date': '2015-05-17-06:00'
                                }
                            }
                        }
                    ]
                }
            ],
            'specification': {
                'characteristic': {
                    'characteristicsValue': [
                        {
                            'characteristicName': 'client_id',
                            'value': CLIENT_ID,
                            'creationTime': {
                                'value': ''
                            },
                            'validityPeriod': {
                                'fromDate': {
                                    'date': ''
                                },
                                'toDate': {
                                    'date': ''
                                }
                            }
                        },
                        {
                            'characteristicName': 'client_secret',
                            'value': CLIENT_SECRET,
                            'creationTime': {
                                'value': ''
                            },
                            'validityPeriod': {
                                'fromDate': {
                                    'date': ''
                                },
                                'toDate': {
                                    'date': ''
                                }
                            }
                        }
                    ]
                }
            }
        }
    }


    try {
        const response = await axios.post(API_URLS.register, data, { headers: customHeaders })

        const _response = responseMapper(response)

        onSuccess(_response)
        return _response
    } catch (error) {
        const _error = errorMapper(error)

        if (_isFunction(onError)) {
            onError(_error)
        } else {
            throw _error
        }
    }
}

const SendPin = async (
    msisdn,
    customHeaders = {},
    utilities = {},
) => {
    const _utilities = retrieveUtilities(utilities)

    const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

    onStart()

    const data = {
        'name': 'Generate Pin',
        'desc': 'Generate Pin',
        'type': 'pin',
        'category': [{
            'value': 'pin',
            'listHierarchyId': 'Vodafone'
        }
        ],
        'details': {
            'lifespan': {
                'value': '',
                'unitCode': ''
            },
            'requestType': 'pin'
        },
        'roles': {
            'requestor': {
                'id': [{
                    'value': msisdn,
                    'schemeName': 'Requestor ID',
                    'schemeAgencyName': 'Vodafone'
                }
                ],
                'name': 'User',
                'desc': '',
                'type': '',
                'category': [{
                    'value': '',
                    'listHierarchyId': 'Vodafone'
                }
                ],
                'status': ''
            }
        },
        'parts': {
            'specification': {
                'id': [
                    {
                        'schemeAgencyName': 'Vodafone',
                        'schemeName': 'Specification ID',
                        'value': '123'
                    }
                ],
                'name': 'Consumer Change Authentication Credential',
                'desc': 'Consumer Change Authentication Credential',
                'type': 'token',
                'category': [
                    {
                        'listHierarchyId': '1',
                        'value': 'token'
                    }
                ],
                'characteristicsValue': [
                    {
                        'characteristicName': 'client_id',
                        'value': CLIENT_ID,
                        'creationTime': {
                            'value': ''
                        },
                        'validityPeriod': {
                            'fromDate': {
                                'date': ''
                            },
                            'toDate': {
                                'date': ''
                            }
                        }
                    },
                    {
                        'characteristicName': 'client_secret',
                        'value': CLIENT_SECRET,
                        'creationTime': {
                            'value': ''
                        },
                        'validityPeriod': {
                            'fromDate': {
                                'date': ''
                            },
                            'toDate': {
                                'date': ''
                            }
                        }
                    }
                ]
            }
        }
    }


    try {
        const response = await axios.post(API_URLS.getPin, data, { headers: customHeaders })

        const _response = responseMapper(response)

        onSuccess(_response)
        return _response
    } catch (error) {
        const _error = errorMapper(error)

        if (_isFunction(onError)) {
            onError(_error)
        } else {
            throw _error
        }
    }
}

const ValidatePin = async (
    msisdn,
    pin,
    customHeaders = {},
    utilities = {},
) => {
    const _utilities = retrieveUtilities(utilities)

    const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

    onStart()

    const data = {
        'id': [
            {
                'value': 'GR2SV',
                'schemeAgencyName': 'Vodafone',
                'schemeName': 'Security Token ID'
            }
        ],
        'name': 'Validate Pin',
        'desc': 'Validate Pin',
        'type': 'pin',
        'category': [
            {
                'value': 'pin',
                'listHierarchyId': 'Vodafone'
            }
        ],
        'details': {
            'lifespan': {
                'value': pin,
                'unitCode': ''
            },
            'requestType': 'pin'
        },
        'roles': {
            'requestor': {
                'id': [
                    {
                        'value': msisdn,
                        'schemeName': 'Requestor ID',
                        'schemeAgencyName': 'Vodafone'
                    }
                ],
                'name': 'User',
                'desc': '',
                'type': '',
                'category': [
                    {
                        'value': '',
                        'listHierarchyId': 'Vodafone'
                    }
                ],
                'status': ''
            }
        },
        'parts': {
            'specification': {
                'id': [
                    {
                        'schemeAgencyName': 'Vodafone',
                        'schemeName': 'Specification ID',
                        'value': '123'
                    }
                ],
                'name': 'Consumer Change Authentication Credential',
                'desc': 'Consumer Change Authentication Credential',
                'type': 'token',
                'category': [
                    {
                        'listHierarchyId': '1',
                        'value': 'token'
                    }
                ],
                'characteristicsValue': [
                    {
                        'characteristicName': 'client_id',
                        'value': CLIENT_ID,
                        'creationTime': {
                            'value': ''
                        },
                        'validityPeriod': {
                            'fromDate': {
                                'date': ''
                            },
                            'toDate': {
                                'date': ''
                            }
                        }
                    },
                    {
                        'characteristicName': 'client_secret',
                        'value': CLIENT_SECRET,
                        'creationTime': {
                            'value': ''
                        },
                        'validityPeriod': {
                            'fromDate': {
                                'date': ''
                            },
                            'toDate': {
                                'date': ''
                            }
                        }
                    }
                ]
            }
        }
    }


    try {
        const response = await axios.post(API_URLS.getPin, data, { headers: customHeaders })

        const _response = responseMapper(response)

        onSuccess(_response)
        return _response
    } catch (error) {
        const _error = errorMapper(error)

        if (_isFunction(onError)) {
            onError(_error)
        } else {
            throw _error
        }
    }
}

export { Login, Register, SendPin, ResetPassword, ValidatePin, RefreshToken }
