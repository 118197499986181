import React from 'react'
import { View, SafeAreaView, TouchableOpacity, Platform, Alert } from 'react-native'
import PropTypes from 'prop-types'
import Permissions, { RESULTS } from 'react-native-permissions'

import { VFText, VFButton, Icon } from '@vfgroup-oneplatform/foundation/Components'
import { getThemeImages, useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { Images } from '../../../../Themes'

import styles from './Success.Styles'

const Success = ({
  onClose,
  title,
  subTitle,
  onButtonPress,
  buttonTitle,
}) => {

  const theme = useTheme()

  const themedImages = getThemeImages(theme.name)

  const cameraPermissions = Platform.OS === 'ios' ? Permissions.PERMISSIONS.IOS.CAMERA : Permissions.PERMISSIONS.ANDROID.CAMERA
  //ios checks microphone permissions
  const microphonePermission = Permissions.PERMISSIONS.IOS.MICROPHONE


  const checkPermissionsIOS = () => {
    Permissions.checkMultiple([cameraPermissions, microphonePermission]).then(statuses => {
      const cameraStatus = statuses[cameraPermissions]
      const microphoneStatus = statuses[microphonePermission]
      if (cameraStatus === RESULTS.DENIED || microphoneStatus === RESULTS.DENIED) {
        if (cameraStatus === RESULTS.DENIED) {
          Permissions.request(cameraPermissions).then(() => checkPermissionsIOS())
        } else {
          Permissions.request(microphonePermission).then(() => checkPermissionsIOS())
        }
      } else if (cameraStatus === RESULTS.GRANTED && microphoneStatus === RESULTS.GRANTED) {
        onButtonPress()
        return
      } else {
        Alert.alert("Can't open camera!", 'Go to settings to grant permissions', [
          { text: 'Go to Settings', onPress: () => Permissions.openSettings() },
          { text: 'Cancel' },
        ])
        return
      }
    })
  }

  const checkPermissionsAndroid = () => {
    Permissions.check(cameraPermissions).then(status => {
      switch (status) {
        case RESULTS.GRANTED:
          onButtonPress()
          break
        case RESULTS.DENIED:
          Permissions.request(cameraPermissions).then(result => {
            if (result === RESULTS.GRANTED) {
              Alert.alert("Can't open camera!", 'Go to settings to grant permissions', [
                { text: 'Go to Settings', onPress: () => Permissions.openSettings() },
                { text: 'Cancel' },
              ])
            }
          })
          break
        default:
          Alert.alert("Can't open camera!", 'Go to settings to grant permissions', [
            { text: 'Go to Settings', onPress: () => Permissions.openSettings() },
            { text: 'Cancel' },
          ])
          break
      }
    })
  }

  const checkPermissions = () => {
    if (Platform.OS === 'ios') {
      checkPermissionsIOS()
    } else {
      checkPermissionsAndroid()
    }
  }

  const onPress = () => {
    checkPermissions()
  }


  return (
    <SafeAreaView style={styles.container}>
      <TouchableOpacity
        style={styles.closeButton}
        onPress={onClose}
        activeOpacity={1}
      >
        <Icon
          name={themedImages.ic_close}
          size={25}
          type="image"
        />
      </TouchableOpacity>
      <View style={styles.orderScreenContainer}>
        <Icon
          name={Images.tick_success}
          type="image"
          size={120}
          resizeMode="contain"
        />
        <VFText
          i18nKey={title}
          style={styles.title}
        />
        <VFText
          i18nKey={subTitle}
          style={styles.subtitle(theme)}
        />
      </View>
      <VFButton
        type="primary"
        title={buttonTitle}
        style={styles.btnStyle}
        onPress={onPress}
      />
    </SafeAreaView>
  )
}

Success.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  onButtonPress: PropTypes.func,
  buttonTitle: PropTypes.string,
}

export default Success
