import { Dimensions, Platform, StyleSheet } from 'react-native'

import Colors from '../../Themes/Colors'

export default StyleSheet.create({
  storyContentStyle: {
    justifyContent: 'space-between'
  },
  singleStory: {
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 8,
    alignSelf: 'flex-start',
    flex: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 1 : null
  },
  storyButton: seen => ({
    borderRadius: 100,
    borderWidth: seen ? 3 : 3,
    borderColor: seen ? '#A6A6A6' : '#0096AD',
    overflow: 'hidden',
    backgroundColor: '#000',
    padding: 1
  }),
  offerCountdown: {
    backgroundColor: 'white',
    borderRadius: 100,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 5,
    paddingVertical: 5,
    alignItems: 'center',
    width: 70,
    marginTop: -20,
    marginBottom: 10
  },
  offerCountdownIcon: {
    marginRight: 5,
    resizeMode: 'contain'
  },
  offerCountdownDescription: (width, textColor, theme) => ({
    color: textColor ? textColor : theme.colors.text.primary,
    // color: theme.isDark ? '#fff' : '#7d9',
    fontSize: 14,
    lineHeight: 16.07,
    width: width / 4,
    textAlign: 'center'
  }),
  offerCountdownTime: {
    fontSize: 12
  },
  radius: {
    borderRadius: 100
  },
  empty: {
    height: 13
  },
  reloadButton: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  reloadButtonText: {
    fontSize: 18,
    color: 'white',
    paddingRight: 8
  },
  storyContainer: {
    marginTop: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 0 : -35,
    height: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 120 : null,
    flexDirection: 'row',
    paddingTop: 16,
    width: '100%'
  },
  txtButton: {
    color: Colors.aluminium,
    fontSize: Platform.OS === 'ios' ? 16.6 : 16,
  },
  iconButton: {
    marginLeft: 5,
    marginTop: -4,
    alignItems: 'center',
  },
  reloadContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  lottieReloading: {
    alignSelf: 'center',
    height: 50
  },
})
