import { StyleSheet } from 'react-native'


import { Colors, Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 20,
  },
  title: {
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Fonts.size.h4,
  },
  borderedRowContainer: theme => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.borderColorThree,
  }),
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20,
  },
  rowText: {
    fontFamily: Fonts.type.VFFont,
    fontSize: Fonts.size.h6,
  },
  hybridContainer: theme => ({
    marginTop: 30,
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.borderColorThree,
  }),
  hybridText: {
    marginBottom: 20,
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Fonts.size.h4,
  },
  prepaidConatiner: {
    marginBottom: 20,
  },
  infoText: {
    fontFamily: Fonts.type.VFFont,
    fontSize: Fonts.size.h6,
    marginVertical: 20,
    lineHeight: 25,
  },
  pukContainer: theme => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderWidth: 1,
    borderColor: theme.colors.borderColorThree,
    padding: 20,
    borderRadius: 7,
    alignItems: 'center',
    marginTop: 10,
  }),
  pukContent: {
    flexDirection: 'row',
  },
  pukTextContainer: {
    justifyContent: 'space-between',
    marginLeft: 15,
  },
  pukIcon: {
    alignSelf: 'center',
  },
  pukText: {
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Fonts.size.h5,
  },
  pukValueText: {
    fontSize: Fonts.size.h5,
    fontWeight: Fonts.type.bold.fontWeight,
  },
  button: {
    width: 100,
    fontWeight: Fonts.type.bold.fontWeight,
  },
  errorContainer: {
    paddingVertical: 30,
    paddingHorizontal: 10,
  },
  errorText: theme => ({
    fontFamily: Fonts.type.VFFont,
    fontSize: Fonts.size.h6,
    color: theme.colors.textColorTwo,
    marginVertical: 20,
    textAlign: 'center',
  }),
  tryAgainContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tryAgainText: {
    fontFamily: Fonts.type.VFFont,
    fontSize: Fonts.size.h6,
    color: Colors.vfRed,
    marginRight: 5,
  },
  copyText: theme => ({
    fontFamily: Fonts.VFFont,
    color: theme.colors.tintColor,
  }),
  copyContainer: theme => ({
    backgroundColor: theme.colors.headerColor,
    position: 'absolute',
    right: 0,
    top: -15,
    borderRadius: 4,
    height: 30,
    width: 60,
    justifyContent: 'center',
    alignItems: 'center',
  }),
  arrow: theme => ({
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 5,
    borderRightWidth: 5,
    borderBottomWidth: 10,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: theme.colors.headerColor,
    transform: [{ rotate: '180deg' }],
    position: 'absolute',
    right: 16,
    top: 15,
  }),
  closeButtonStyle: {
    marginRight: -16,
  },
  titleStyle: {
    marginLeft: -16,
  },
})
