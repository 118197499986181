import { StyleSheet, Dimensions } from 'react-native'

import { Colors, Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window')

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  cameraContainer: {
    flex: 1,
    justifyContent: 'space-between'
  },
  overlayMask: {
    position: 'absolute',
    backgroundColor: Colors.blackRGBA(0.75),
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  borderRadius: (width, imageHeight, topVerticalOffset) => ({
    backgroundColor: 'transparent',
    width: width + 4,
    height: imageHeight + 4,
    position: 'absolute',
    top: topVerticalOffset - 2,
    borderWidth: 2,
    borderRadius: 10,
    borderColor: Colors.ocean,
    left: 30,
    overflow: 'hidden',
  }),
  headerContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 40,
    paddingHorizontal: 16,
    height: 60,
  },
  textContainer: {
    position: 'absolute',
    bottom: 120,
    width: '100%',
    alignItems: 'center',
  },
  text: {
    color: Colors.white,
    textAlign: 'center',
    fontFamily: Fonts.type.VFFont,
    fontSize: Fonts.size.h6,
    marginTop: 10,
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.dark_grey,
    position: 'absolute',
    width: '100%',
    bottom: 0,
    height: SCREEN_HEIGHT - (SCREEN_WIDTH * 16) / 9,
    minHeight: 100,
  },
  cameraButtonOutter: {
    width: 70,
    height: 70,
    backgroundColor: Colors.whiteRGBA(0.7),
    borderRadius: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cameraButtonInner: {
    width: 55,
    height: 55,
    backgroundColor: Colors.white,
    borderRadius: 55,
  },
  showImageContainer: {
    flex: 1,
    backgroundColor: Colors.black,
    justifyContent: 'space-between',
  },
  emptyIcon: {
    width: 25,
    height: 25,
  },
  image: (width) => ({
    width: width,
    height: '100%',
  }),
  buttonsContainer: {
    paddingHorizontal: 16,
    marginBottom: 40,
  },
  button: {
    marginTop: 15,
  },
  marginTop: {
    marginTop: 100,
  },
  loadingContainer: theme => ({
    flex: 1,
    backgroundColor: theme.colors.backgroundColor,
    alignItems: 'center',
    justifyContent: 'center',
  }),
  loader: {
    width: 180,
    height: 180,
    marginLeft: 0,
  }
})

export default styles
