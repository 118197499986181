import { StyleSheet, Dimensions } from 'react-native'
import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

const { width } = Dimensions.get('screen')


const styles = StyleSheet.create({
  title: theme => ({
    ...Fonts.type.bold,
    fontSize: Fonts.size.regularSmall,
    color: theme.colors.textColor
  }),
  subtitle: {
    fontSize: Fonts.size.regularSmall,
    marginLeft: 5,
    fontWeight: '100'
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
    width: width / 2,
    marginVertical: 10
  },
  contentContainer: {
    justifyContent: 'space-around',
    marginLeft: 10,
    width: width / 2,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
  },
  buttonStyle: {
    height: 40,
  }
})


export default styles
