import React, { Component } from 'react'
import { View, Text, TouchableOpacity, ImageBackground, Platform, Dimensions } from 'react-native'
import { default as FastImage } from 'react-native-fast-image'

import { __translate, __image } from '../../Utils'

import { Images } from '../../Themes'



class FavouritesDetails extends Component {
  render() {
    return (
      <View style={[{ flex: 1 }, Platform.OS === 'web' && Dimensions.get('window').width > 700 && { padding: '10%', paddingTop: 20 }]}>
        <ImageBackground
          style={{ width: '100%', height: '100%' }}
          source={Images.info_full_bg}>

          <TouchableOpacity onPress={() => this.props.navigation.navigate('Catalog')}>
            <FastImage
              style={{ width: 25, height: 25, alignSelf: 'flex-end', marginTop: 20, marginRight: 20, transform: [{ rotate: '180deg' }] }}
              source={Images.close_white} />
          </TouchableOpacity>

          <View style={{ backgroundColor: '#fff', borderRadius: 100, padding: 20, alignItems: 'center', alignSelf: 'center', marginTop: 40 }}>
            <FastImage
              style={{ width: 150, height: 150 }}
              source={{ uri: __image('reward_loyalty_tutorial_desc') }}
              source={{ uri: __image('reward_loyalty_tutorial_desc') }}
            />
          </View>

          <View style={{ paddingVertical: 20, paddingHorizontal: 30 }} >
            <Text style={{ color: '#fff', fontSize: 25, fontWeight: 'bold', textAlign: 'center', marginTop: 50 }}> {__translate('reward_loyalty_postpay_tutorial_header')} </Text>
            <Text style={{ marginTop: 20, color: '#fff', fontSize: 18, textAlign: 'center' }}> {__translate('reward_loyalty_tutorial_desc')} </Text>
          </View>
        </ImageBackground>
      </View>
    )
  }
}


export default FavouritesDetails
