import { StyleSheet, Dimensions } from 'react-native'
import { Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

const Styles = StyleSheet.create({
  headerStyle: (theme) => ({
    // backgroundColor: theme.colors.background.primary[0],
    borderBottomWidth: 0
  }),
  iframePopup: {
    flex: 1,
    width: '100%',
    height: Dimensions.get('window').height,
    zIndex: 1000,
  },
  iframeDataCollection: {
    width: 1,
    height: 1,
  }
})

export { Styles }
