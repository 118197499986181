const actionTypes = {
  SET_STORIES: 'SET_STORIES',
  STORIES_LOADING: 'STORIES_LOADING',
  LIKE_STORY: 'LIKE_STORY',
  OPEN_STORY: 'OPEN_STORY'
}


const stories = (state = {}, action = {}) => {

  const { type, payload } = action

  switch (type) {
    case actionTypes.SET_STORIES:
      return {
        ...state,
        ...payload
      }
    case actionTypes.STORIES_LOADING:
      return {
        stories: [],
        error: false,
        loading: false
      }
    case actionTypes.OPEN_STORY:
      return {
        ...state,
        stories: state.stories.map(
          bubble => {
            if (bubble.id === payload.bubbleId) {
              const newStories = bubble.stories.map(story => {
                return story.id === payload.id ? { ...story, seen: true } : story
              })
              return { ...bubble, stories: newStories }
            }
            return bubble
          }
        )
      }
    case actionTypes.LIKE_STORY:
      return {
        ...state,
        stories: state.stories.map(
          bubble => {
            if (bubble.id === payload.bubbleId) {
              const newStories = bubble.stories.map(story => {
                return story.id === payload.id ? { ...story, liked: !story.liked } : story
              })
              return { ...bubble, stories: newStories }
            }
            return bubble
          }
        )
      }
    default:
      return state
  }
}


export { stories }
