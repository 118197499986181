import { Buffer } from 'buffer'

import crypto from 'isomorphic-webcrypto'
import { Base64 } from 'js-base64'
import { TextEncoder } from 'text-encoding'

import { Images as PaymentImages } from '../../Themes'



export const PaymentScreenTypes = {
  ADD_PAYMENT_CARD_LOADING: 'ADD_PAYMENT_CARD_LOADING',
  DEFAULT: 'DEFAULT',
  PAYMENTREQUEST: 'PAYMENTREQUEST',
  ERROR: 'ERROR',
  PAYMENTSUCCESSFULLY: 'PAYMENTSUCCESSFULLY',
  ADD_PAYMENT_CARD: 'ADD_PAYMENT_CARD',
}

export const tokenTypes = {
  DEFAULT: 'Default',
  TOKENID: 'TokenId',
  CARDID: 'CardId'
}


export const importKey = async (jsonWebKey) => {

  return crypto.subtle.importKey(
    'jwk',
    jsonWebKey,
    {
      name: 'RSA-OAEP',
      hash: { name: 'SHA-1' }
    },
    true,
    ['encrypt'],
  )
}

export async function sha256(message) {
  const msgUint8 = new TextEncoder().encode(message)
  const hashBuffer = await crypto.subtle.digest({ name: 'SHA-256' }, msgUint8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
  return hashHex.substring(0, 50)
}

export const tokenizeCard = async (cardNumber, jwk) => {
  const cardNumberBuffer = Buffer.from(cardNumber)

  await crypto.ensureSecure()

  const publicKey = await importKey(jwk)


  try {
    const encryptedCardNumberBuffer = await crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
        hash: { name: 'SHA-1' }
      },
      publicKey,
      cardNumberBuffer
    )


    return Base64.btoa(String.fromCharCode.apply(null, new Uint8Array(encryptedCardNumberBuffer)))
  } catch (error) {
    console.log('errrr', error)
  }

  throw new Error()
}


export const getBrandById = (id) => {
  const types = {
    '001': 'Visa',
    '002': 'Mastercard',
    '003': 'American Express'
  }

  return types[id]
}

export const getIdByBrand = (brand) => {
  const types = {
    'Visa': '001',
    'Mastercard': '002',
    'American Express': '003'
  }

  return types[brand]
}

export const getImageByCardId = (id) => {
  const types = {
    '001': PaymentImages.visa_icon,
    '002': PaymentImages.mastercard_icon,
    '003': PaymentImages.americanExpress_icon
  }

  return types[id]
}

export const getCardType = (number) => {
  // visa
  var re = new RegExp('^4')
  if (number.match(re) != null) { return 'Visa' }

  // Mastercard
  if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) {
    return 'Mastercard'
  }


  re = new RegExp('^3[47]')
  if (number.match(re) != null) {
    return 'American Express'
  }


  // Visa Electron
  re = new RegExp('^(4026|417500|4508|4844|491(3|7))')
  if (number.match(re) != null) {
    return 'Visa'
  }

  return ''
}
