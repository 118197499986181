import React, { useContext } from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'

import {
  LightThemeColors,
  useTheme,
  withTheme
} from '@vfgroup-oneplatform/foundation/Components/Themes'
import { SettingsRadio } from '@vfgroup-oneplatform/framework/Settings/components'
import SpinnerOverlay from '@vfgroup-oneplatform/foundation/Components/SpinnerOverlay'

import LanguageContext from '../../../Utils/LanguageContext'

import ReduxCache from '../../../Utils/ReduxCache'

import styles from './SelectLanguage.style'


const languages = [
  {
    'icon': 'icBritishFlag',
    'title': 'app_languages_english',
    'identifier': 'en'
  },
  {
    'icon': 'icAlbanianFlag',
    'title': 'app_languages_albanian',
    'identifier': 'al'
  }
]

function SelectLanguage(props) {
  const { changeLanguage, langLoading } = useContext(LanguageContext)
  const language = ReduxCache.getLanguage()
  const theme = useTheme()
  const onLanguagePress = (index) => {
    try {
      const lang = languages[index].identifier
      changeLanguage(lang)
      //ContentManager.configureI18nTranslations({ en: LANGUAGES[lang] })
      // ContentManager.setLocale(lang)
      //store.dispatch({ type: 'UPDATE_LANGUAGE', data: lang })
      //ReduxCache.set('language' ,lang)
    } catch (error) {
      //ContentManager.configureI18nTranslations({ en: { ...en } })
    }
  }

  return (
    <View style={{ backgroundColor: theme.colors.cardBackgroundColor, padding: 20, borderRadius: 7 }}>
      {languages.map((item, index) => (
        <View key={item?.identifier}>
          <SettingsRadio
            {...item}
            isSelected={item.identifier === language}
            onPress={() => onLanguagePress(index)}
            images={props.images}
          />
          {index !== languages.length - 1 && (
            <View style={styles.divider(props.theme)} />
          )}
        </View>))}
      {langLoading && <SpinnerOverlay isVisible autoPlay lottieStyle={styles.lottieStyle} />}
    </View>
  )
}

SelectLanguage.defaultProps = {
  theme: LightThemeColors
}

SelectLanguage.propTypes = {
  theme: PropTypes.shape({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  }),
  images: PropTypes.object
}
export default withTheme(SelectLanguage)
