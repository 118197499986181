import { actionTypes } from './DigitalAcquisition.Actions'

const acquisitionOffers = (state = { loading: false, data: null }, action = {}) => {
  switch (action.type) {
    case actionTypes.REQUEST_OFFERS:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SUCCESS_OFFERS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      }
    case actionTypes.ERROR_OFFERS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

const acquisitionSelectedOffer = (state = { data: null }, action = {}) => {
  switch (action.type) {
    case actionTypes.CLEAR_SELECTED_OFFER:
      return {
        data: {},
      }
    case actionTypes.SELECT_OFFER:
      return {
        data: action.payload,
      }
    default:
      return state
  }
}

const acquisitionResources = (state = { loading: false, data: null }, action = {}) => {
  switch (action.type) {
    case actionTypes.REQUEST_RESOURCES:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SUCCESS_RESOURCES:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      }
    case actionTypes.ERROR_RESOURCES:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}



export { acquisitionOffers, acquisitionSelectedOffer, acquisitionResources }
