import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 25,
    paddingBottom: 50,
    marginTop: -30,
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 65,
  },
  iconContainer: {
    alignItems: 'center'
  },
  iconStyle: {
    textAlign: 'center'
  },
  description: {
    fontSize: 30,
    textAlign: 'center',
    paddingHorizontal: 20,
    marginTop: 30,
    marginBottom: 40,
  },
  stepsContainer: {
    marginBottom: 30,
  },
  stepContainer: {
    flexDirection: 'row',
    marginBottom: 40,
    alignItems: 'center'
  },
  numberBorder: theme => ({
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: theme ? theme.colors.textColor : 'black',
    marginRight: 20,
    marginBottom: 2
  }),
  number: theme => ({
    fontSize: 20,
    color: theme ? theme.colors.textColor : 'black',
  }),
  stepText: {
    fontSize: 24
  },
  button: {
    marginTop: 60 
  },
  secondaryButton: {
    marginVertical: -30,
  }
})

export default styles;