import React, { useEffect, useState } from 'react'
import { Dimensions, Platform, ScrollView, View } from 'react-native'
import PropTypes from 'prop-types'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { VFScreen, VFButton } from '@vfgroup-oneplatform/foundation/Components'
import SpinnerOverlay from '@vfgroup-oneplatform/foundation/Components/SpinnerOverlay'

import {
    SectionEnums,
} from '@vfgroup-oneplatform/framework/CommonUI/PrivacyPermissionsOverlay'
import {
    PrivacyPermissionsSection,
} from '@vfgroup-oneplatform/framework/CommonUI/PrivacyPermissionsOverlay/Components'

import VFLoader from '@vfal-lab/rn-vfg-catalogue/Components/VFLoader'

import NavigationService from '../../Navigation/NavigationService'

import { getPreferences, updatePreferences } from '../../Services/Settings'

import ReduxCache from '../../Utils/ReduxCache'

import preferences from './permissions.json'
import styles from './DataManagment.Styles'

const DataManagment = ({ ...rest }) => {
    const [saving, setSaving] = useState(false)

    const [data, setData] = useState({})
    const [items, setItems] = useState({})

    const [isLoading, setIsLoading] = useState(true)

    const askForNotifications = (res) => {
        if (res['push notification']) {
            // UrbanAirship.setUserNotificationsEnabled(true)
        }
    }

    const language = ReduxCache.getLanguage()

    useEffect(() => {
        getPreferences({
            'Accept-Language': language,
        }, {
            onSuccess: ({ response, items: newItems }) => {
                AsyncStorage.setItem('shouldRenderPreferences', `${response?.status !== 'Active'}`)
                if (response?.status === 'Active') {
                    askForNotifications(newItems)
                }

                setItems(newItems)
                setData(response)
                setIsLoading(false)
            },
            onError: () => { }
        })
    }, [])

    const onDismiss = () => {
        NavigationService.pop()
        setSaving(false)
    }

    const onOkButton = () => {
        setSaving(true)

        updatePreferences(data, items, {}, {
            onSuccess: (res) => {
                onDismiss()
                askForNotifications(res)
            },
            onError: () => onDismiss()
        })
    }

    const onToggle = key => {
        setItems(oldItems => {
            return {
                ...oldItems,
                [key]: !oldItems[key]
            }
        })
    }

    const sections = preferences.privacySettingsSections.map((sec, i) => {
        const parts = data?.parts
        const permissions = parts?.partyPermission?.permission?.partyPermission

        const mappedPermissions = permissions?.map((permission) => ({
            title: permission?.name,
            description: permission?.desc,
            key: permission?.id?.[0]?.value,
            initialValue: items?.[permission?.id?.[0]?.value],
            onToggle: onToggle
        })) || []

        return {
            ...sec,
            actions: {
                type: SectionEnums.PERMISSION,
                items: mappedPermissions
            }
        }
    })

    const onClose = () => NavigationService.pop()

    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
        return (
            <>
                <VFScreen
                    title={'data_management_title'}
                    onClose={onClose}
                    closeButtonTestID={'appSettingsCloseBtn'}
                    onBack={onClose}
                    showBack={true}
                    showClose={false}
                    {...rest}
                >
                    {isLoading ?
                        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <VFLoader style={{
                                width: 150,
                                height: 150,
                            }}

                                containerStyle={{
                                    flex: 1,
                                    marginBottom: 64,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}

                            />
                        </View>
                        :
                        <>
                            <View
                                style={[
                                    styles.scrollViewContentContainer,
                                    { paddingHorizontal: '10%' }
                                ]}>
                                {sections.map((section, index) => (
                                    <PrivacyPermissionsSection index={index} key={index} section={section} />
                                ))}
                            </View>
                            <VFButton
                                onPress={onOkButton}
                                style={[styles.button, { marginHorizontal: '10%' }]}
                                title="profile_settings_save_button"
                            /></>}

                </VFScreen>
                {saving && <SpinnerOverlay isVisible autoPlay lottieStyle={styles.lottieStyle} />}
            </>
        )
    }

    return (
        <>
            <VFScreen
                title={'data_management_title'}
                onClose={onClose}
                closeButtonTestID={'appSettingsCloseBtn'}
            >
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={[
                        styles.scrollViewContentContainer,
                    ]}>
                    {sections.map((section, index) => (
                        <PrivacyPermissionsSection index={index} key={index} section={section} />
                    ))}
                </ScrollView>
                <VFButton
                    onPress={onOkButton}
                    style={styles.button}
                    title="profile_settings_save_button"
                />
            </VFScreen>
            {saving && <SpinnerOverlay isVisible autoPlay lottieStyle={styles.lottieStyle} />}
        </>
    )
}
DataManagment.propTypes = {
    setIsVisible: PropTypes.func,
    isVisible: PropTypes.bool,
    onFinish: PropTypes.func
}
DataManagment.defaultProps = {
    setIsVisible: () => { },
    isVisible: false,
    onFinish: () => { }
}
export default DataManagment
