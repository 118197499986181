import { action_types } from './redeem.actionTypes'

function popUp (state = { loading: false, data: null }, action = {}) {
  switch (action.type) {
    case action_types.REQUEST_QRCODE:
      return {
        ...state,
        loading: true
      }
    case action_types.SUCCESS_QRCODE:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      }
    case action_types.ERROR_QRCODE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case action_types.RESET_REDEEM:
      return {
        loading: false,
        data: null
      }
    default:
      return state
  }
}

function redeem (state = { loading: false, data: null }, action = {}) {
  switch (action.type) {
    case action_types.REQUEST_REDEEM_HANDLE:
      return {
        ...state,
        loading: true
      }
    case action_types.SUCCESS_REDEEM_HANDLE:
      return {
        ...state,
        loading: false,
        error: null,
        data: {
          success: true,
          product: action.payload
        }
      }
    case action_types.ERROR_REDEEM_HANDLE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case action_types.RESET_REDEEM:
      return {
        loading: false,
        data: null
      }
    default:
      return state
  }
}

export { popUp }
export { redeem }
