const validatePhoneNumber = (number) => {
    const format = /^(3556|003556|\+3556|06|6)([6-9]{1})([2-9]{1})([0-9]){6}$/
    return format.test(number)
}

const alRegex = /^(3556|003556|\+3556|06)/

const validatePersonalNumber = (personalNumber) => {
    // const format = /^[A-Za-z]{1}\d{8}[A-Za-z]{1}$/
    const format = /^.{4,}$/
    return format.test(personalNumber)
}

const validateEmail = email => {
    const format = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    return format.test(email)
}

const validateNipt = (nipt) => {
    const format = /^[A-Za-z]{1}\d{8}[A-Za-z]{1}$/
    return format.test(nipt)
}

const personalNumberRegex = /^.{4,}$/
// const personalNumberRegex = /^[A-Za-z]{1}\d{8}[A-Za-z]{1}$/

const nameFormat = /^[a-zA-Z]{3,}$/

const validateName = nipt => {
    const format = /^[a-zA-Z]{3,}$/
    return format.test(nipt)
}

const validateGroupName = name => {
    const format = /^[a-zA-Z0-9' ]{1,15}$/
    return format.test(name)
}

const formatNumber = (value) => parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

const formatNumberNoDecimal = (value) => parseFloat(value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

const formatPhoneNumber = phoneNumber => {
    if (!phoneNumber) {
        return
    }
    return phoneNumber.replace(/\s/g, '').replace(alRegex, '3556')
}


export {
    validatePhoneNumber,
    formatPhoneNumber,
    validatePersonalNumber,
    validateNipt,
    personalNumberRegex,
    nameFormat,
    validateName,
    validateGroupName,
    formatNumber,
    formatNumberNoDecimal,
    validateEmail
}
