import { actionTypes } from './Shake.ActionTypes'

function productDetails(
  state = { loading: false, data: null, error: null },
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_OFFER_DETAILS:
      return {
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_OFFER_DETAILS:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }

    case actionTypes.ERROR_OFFER_DETAILS:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}

function orderDetails(
  state = { loading: false, data: null, error: null },
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_ORDER_PRODUCT:
      return {
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_ORDER_PRODUCT:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }

    case actionTypes.ERROR_ORDER_PRODUCT:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}

export {
  productDetails,
  orderDetails,
}
