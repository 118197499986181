import { v1 as uuidv1 } from 'uuid'

import {
  GetProducts,
  OrderProduct
} from '@vfal-lab/rn-vfg-shake/Services'

// import InAppReview from 'react-native-in-app-review'

import ReduxCache from '../../Utils/ReduxCache'
// import { shouldShowReview, updateLastReviewDate } from '../../Utils/MsisdnLocalData'

const actionTypes = {
  REQUEST_OFFER_DETAILS: 'REQUEST_OFFER_DETAILS',
  SUCCESS_OFFER_DETAILS: 'SUCCESS_OFFER_DETAILS',
  ERROR_OFFER_DETAILS: 'ERROR_OFFER_DETAILS',

  REQUEST_ORDER_PRODUCT: 'REQUEST_ORDER_PRODUCT',
  SUCCESS_ORDER_PRODUCT: 'SUCCESS_ORDER_PRODUCT',
  ERROR_ORDER_PRODUCT: 'ERROR_ORDER_PRODUCT'
}

const errorMapperMessage = (error) => {
  const payload = error?.response?.data || {
    message: 'shake_button_error_general'
  }
  return payload
}

const orderHeaders = {
  'X-Source-CountryCode': 'al',
  'X-Source-Operator': 'shake',
  'X-Source-System': 'selfcare'
}

const labels = {
  quote: 'shake_label_quote',
  coupon: 'shake_label_coupon',
  vcoins: 'shake_label_vcoins'
}

const productMapper = product => {
  const {
    id,
    name,
    description: desc,
    productPrice = [],
    productCharacteristic = [],
    productRelationship = []
  } = product

  const amount = productPrice?.[0]?.price?.dutyFreeAmount || {}
  let type = product['@type']?.toLowerCase()
  if (type === 'free') {
    type = productCharacteristic.find(element => element.name === 'type')?.['@type']
  }
  const productValidity = productCharacteristic.find(element => element.name === 'productValidity')?.['@type']

  let recomendedProduct = productRelationship?.[0]?.product
  if (recomendedProduct?.id) {
    recomendedProduct = productMapper(recomendedProduct)
  }

  return {
    id,
    type,
    name,
    title: labels[type] || name,
    desc,
    price: amount.value,
    unit: amount.unit,
    productValidity,
    recomendedProduct
  }
}

const getProductsResponseMapper = (response) => {
  const { id, recommendationItem = [] } = (response.data?.[0] || {})
  const cards = []
  const options = []

  recommendationItem.map(({ product = {} }) => {
    const item = productMapper(product)
    const {
      type,
      name,
      desc
    } = item
    cards.push({ type, name, desc })
    options.push({ ...item })
  })

  return { id, cards, options }
}

const orderResponseMapper = (response) => {
  const data = response.data || {}
  const { name } = data.productOrderItem?.[0]?.productOffering || {}
  const { description: desc } = data
  const payload = { name, desc }
  return payload
}

const getProducts = (type, utilities = {}) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.REQUEST_OFFER_DETAILS
    })

    const msisdn = ReduxCache.getMsisdn()

    GetProducts(type, msisdn, {}, {
      ...utilities,
      responseMapper: getProductsResponseMapper,
      errorMapper: errorMapperMessage
    }).then(async response => {

      // const showReview = await shouldShowReview()
      // if (showReview) {
      //   InAppReview.RequestInAppReview()
      //     .then((hasFlowFinishedSuccessfully) => {
      //       if (hasFlowFinishedSuccessfully) {
      //         updateLastReviewDate()
      //       }
      //     })
      //     .catch(() => {

      //     })
      // }

      dispatch({
        type: actionTypes.SUCCESS_OFFER_DETAILS,
        payload: response
      })
    }).catch(error => dispatch({
      type: actionTypes.ERROR_OFFER_DETAILS,
      payload: error
    }))
  }
}

const orderProduct = (orderId, productId, type, utilities = {}) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.REQUEST_ORDER_PRODUCT
    })

    const msisdn = ReduxCache.getMsisdn()

    const headers = Object.assign(
      {},
      orderHeaders,
      {
        'vf-trace-transaction-id': uuidv1(),
        'vf_ext_bp_id': uuidv1()
      }
    )

    const requestBody = {
      'channel': [
        {
          'id': 'MVA10'
        }
      ],
      'productOrderItem': [
        {
          'id': orderId,
          'quantity': 1,
          'action': 'add',
          'productOffering': {
            'id': productId
          }
        }
      ],
      'relatedParty': [
        {
          'id': msisdn,
          'role': 'Customer',
          '@referredType': 'individual'
        }
      ],
      '@type': type
    }

    OrderProduct(requestBody, headers, {
      ...utilities,
      responseMapper: orderResponseMapper,
      errorMapper: errorMapperMessage
    }).then(response => dispatch({
      type: actionTypes.SUCCESS_ORDER_PRODUCT,
      payload: response
    })).catch(error => dispatch({
      type: actionTypes.ERROR_ORDER_PRODUCT,
      payload: error
    }))
  }
}

const actions = {
  getProducts,
  orderProduct
}

export {
  actionTypes,
  actions
}
