import React, {Component} from 'react'
import { TouchableOpacity, View } from 'react-native'
import Modal from 'react-native-modal'

import FastImage from 'react-native-fast-image'

import { __image } from '../../Utils'

import styles from './popup.styles'


class PopUp extends Component {
  render() {
    const { image, showHeader = true } = this.props
    return (
      <Modal
        style={{ margin: 0 }}
        backgropColor={'#040406'}
        backdropOpacity={0.85}
        isVisible={this.props.visibility}
        animationIn="fadeIn"
        animationOut="fadeOut"
        backdropTransitionInTiming={600}
        backdropTransitionOutTiming={600}
        animationOutTiming={200}
        useNativeDriver={true}
        hideModalContentWhileAnimating={true}
        onBackdropPress={() => this.props.closePopUp()}
        onBackButtonPress={() => this.props.closePopUp()}
        >
       {showHeader &&  <View style={styles.header}>
          <FastImage
            source={image}
            style={styles.headerImage}
            resizeMode="contain"
          />
        </View>}
        <View style={styles.body}>
          <TouchableOpacity style={styles.button} onPress={() => {
              this.props.closePopUp()
            }}>
            <FastImage
              source={{ uri: __image('reward_loyalty_close_bt') }}
              style={styles.closeImage}
            />
          </TouchableOpacity>
          <View style={[showHeader && { paddingTop: 60 }]}>
            {this.props.children}
          </View>
        </View>
      </Modal>
    )
  }
}

export default PopUp
