import React, { Component } from 'react'
import { ImageBackground, View, Platform, Dimensions, TouchableOpacity } from 'react-native'
import { VFText, Icon } from '@vfgroup-oneplatform/foundation/Components'
import PropTypes from 'prop-types'

import CountDown from './CountDown'

import { Images } from '../../Themes'

import styles from './DiscoverFixedCard.Styles'



class DiscoverFixedCard extends Component {
  constructor(props) {
    super(props)
    this.state = { isVisible: false, remainingTime: false }
  }

  toggleModal = () => {
    this.setState({
      isVisible: !this.state.isVisible
    })
  }
  closeTopUpModalQuickAction = () => {
    this.setState({
      isVisible: false
    })
  }
  renderBackground = () => (
    <ImageBackground
      source={{ uri: this.props.extraInfo.icon }}
      style={styles.imageStyle}
      resizeMode="cover"
      {...this.props}
      accessible
    />
  )
  renderTopUpContent = () => (
    <View style={styles.textContainer}>
      <View>
        <VFText
          style={styles.title(this.props.theme)}
          i18nKey={'dashboard_upgrade_component_title_topup'}
          placeHolders={['User', 20, '€', 5, 'GB', 5, '€']}
        />
        <VFText
          style={styles.subTitle}
          i18nKey={'dashboard_upgrade_component_subtitle_topup'}
          placeHolders={[10, '€', 'Thursday']}
        />
      </View>
      <View style={styles.textIconContainer}>
        <View style={styles.btn}>
          <VFText
            style={styles.text}
            i18nKey={'dashboard_upgrade_component_button_topup'}
            accessibilityLabel="DBtopupNow"
          />
          <Icon name={Images.icChevronRightWhite} type="image" />
        </View>
      </View>
    </View>
  )

  renderContent = () => {
    const { title, buttonText, key } = this.props.extraInfo

    if (key == 'promo_live') {
      return this.renderLivePromoContent()
    } else if (key == 'promo_teasing') {
      return this.renderPromoContent()
    }

    return (
      <View style={styles.textContainer}>
        <VFText style={styles.title} i18nKey={title} />
        <View style={styles.btn}>
          <VFText
            style={styles.text}
            i18nKey={buttonText}
            accessibilityLabel="DBtopupNow"
          />
          <Icon name={Images.icChevronRightWhite} size={14} type="image" />
        </View>
        {/* <View style={styles.textIconContainer}>
          <View style={styles.btn}>
            <VFText
              style={styles.text}
              i18nKey={buttonText}
              accessibilityLabel="DBtopupNow"
            />
            <Icon name={Images.icChevronRightWhite} type="image" />
          </View>
        </View> */}
      </View>
    )
  }

  renderPromoContent = () => {
    const { title, subTitle, buttonText, isActive } = this.props.extraInfo

    return (
      <View style={styles.textContainer}>
        <View>
          <VFText
            style={styles.titlePromo}
            i18nKey={title}
          />
          <VFText
            style={styles.subTitlePromo}
            i18nKey={subTitle}
          />
        </View>
      </View>
    )
  }

  renderLivePromoContent = () => {
    const { title, subTitle, buttonText, remainingTime } = this.props.extraInfo

    return (
      <View style={styles.textContainer}>
        <View>
          <VFText
            style={styles.titlePromo}
            i18nKey={title}
          />
          <VFText
            style={styles.subTitlePromo}
            i18nKey={subTitle}
          />
        </View>

        <CountDown to={remainingTime.date} format={remainingTime.format} />

        <View style={styles.textIconContainer}>
          <View style={styles.btn}>
            <VFText
              style={styles.textPromo}
              i18nKey={buttonText}
              accessibilityLabel="DBtopupNow"
            />
            <Icon name={Images.icChevronRightWhite} type="image" />
          </View>
        </View>
      </View>
    )
  }

  render() {
    if (Dimensions.get('window').width > 700) {
      return (
        <View onClick={() => this.props.extraInfo?.onPress(this.props.extraInfo)} style={styles.container}>
          <ImageBackground
            source={{ uri: this.props.extraInfo.icon }}
            style={styles.imageStyle}
            resizeMode={Platform.OS === 'web' ? "stretch" : 'cover'}
            {...this.props}
            accessible
          >
            {this.renderContent()}
          </ImageBackground>
        </View>
      )
    }
  }

  render() {
    return (
      <View onClick={() => this.props.extraInfo?.onPress(this.props.extraInfo)} style={styles.container}>
        <ImageBackground
          source={{ uri: this.props.extraInfo.icon }}
          style={styles.imageStyle}
          resizeMode={Platform.OS === 'web' ? "stretch" : 'cover'}
          {...this.props}
          accessible
        >
          {this.renderContent()}
        </ImageBackground>
      </View>
    )
  }
}
DiscoverFixedCard.propTypes = {
  setParallaxRef: PropTypes.func
}

export default DiscoverFixedCard
