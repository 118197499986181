import {actionTypes} from './vfCoinsTransfer.actionTypes'

function transferVCoinsList(
  state = {loading: false, data: null, selected: {}, error: null},
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_GET_LIST:
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_GET_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      }
    case actionTypes.ERROR_GET_LIST:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      }
    case actionTypes.RESET_GET_LIST:
      return {
        ...state,
        loading: false,
        data: null,
        selected: {},
        error: null,
      }
    case actionTypes.SET_SELECTED_AMOUNT:
      return {
        ...state,
        selected: action.payload,
      }
    default:
      return state
  }
}

function transferVCoinsState(
  state = {
    loading: false,
    isSuccess: false,
    isAttention: false,
    isError: false,
    data: null,
    error: null,
  },
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_TRANSFER_VCOINS:
      return {
        ...state,
        loading: true,
        isSuccess: false,
        isAttention: false,
        isError: false,
      }

    case actionTypes.SUCCESS_TRANSFER_VCOINS:
      return {
        ...state,
        loading: false,
        isSuccess: true,
        isAttention: false,
        isError: false,
        data: action.payload,
        error: null,
      }
    case actionTypes.ATTENTION_TRANSFER_VCOINS:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        isAttention: true,
        isError: false,
        data: action.payload,
        error: null,
      }
    case actionTypes.ERROR_TRANSFER_VCOINS:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        isAttention: false,
        isError: true,
        data: null,
        error: action.payload,
      }
    case actionTypes.RESET_TRANSFER_VCOINS:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        isAttention: false,
        isError: false,
        data: null,
        error: null,
      }
    default:
      return state
  }
}

export {transferVCoinsList, transferVCoinsState}
