import {actionTypes} from './VDayOffers.ActionTypes'

function vdayOffers(
    state = { loading: false, data: null },
    action = {},
) {
    switch (action.type) {
        case actionTypes.REQUEST_OFFERS:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.SUCCESS_OFFERS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload,
            }
        case actionTypes.ERROR_OFFERS:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}

export { vdayOffers }
