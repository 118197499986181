import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import AppointmentsScreens from '@vfal-lab/rn-vfg-appointments'

import ReduxCache from '../../Utils/ReduxCache'

import { getAppointments, getStoresData, getServices } from '../../Services/Appointments'

import { isAdminView } from '../../Utils'
import NavigationService from '../../Navigation/NavigationService'

const Appointments = ({ navigation, route }) => {


  const [isLoading, setIsLoading] = useState(false)
  const [appointments, setAppointments] = useState([])
  const [services, setServices] = useState([])

  const isAdmin = isAdminView()
  const language = ReduxCache.getLanguage()


  const handleClose = () => {
    if (route?.params?.isDeepLink) {
      NavigationService.navigateWithResetAction('Dashboard')
    } else {
      navigation.navigate('Dashboard')
    }
  }

  const onBookAppointmentsButtonPress = () => {
    navigation.navigate('BookAnAppointment')
  }


  const getData = useCallback(async () => {
    const place = isAdmin ? 'vfoffice' : 'vfshop'
    setIsLoading(true)

    await getServices({
      onSuccess: res => {
        setServices(res)
      }
    })

    const params = {
      'place.id': place,
      'relatedParty.id': ReduxCache.getMsisdn(),
    }
    await getAppointments(params, {
      onSuccess: res => {
        setIsLoading(false)
        let filtredData = []
        if (isAdmin) {
          filtredData = res?.filter(el => !el?.place?.id?.includes('vfshop'))
        } else {
          filtredData = res?.filter(el => el?.place?.id?.includes('vfshop'))
        }
        setAppointments(filtredData)
      },
      onError: () => {
        setIsLoading(false)
      }
    })
  }, [isAdmin])


  useEffect(() => {
    if (route?.params?.reset) {
      getData()
    }
  }, [route, getData])

  useEffect(() => {
    getData()
  }, [getData])


  useEffect(() => {
    if (language === 'en') {
      moment.locale('en')
    } else {
      moment.locale('sq')
    }
  }, [language])


  return (
    <AppointmentsScreens.YourAppointments
      screenProps={{
        onClose: handleClose
      }}
      withTray
      appointments={appointments}
      services={services}
      getLocationData={getStoresData}
      isLoading={isLoading}
      onBookAppointmentsButtonPress={onBookAppointmentsButtonPress}
      withCancelBookingButton={false}
      withChangeBookingPress={false}
      isAdmin={isAdmin}
    />
  )
}
Appointments.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object
}

export default Appointments
