import React from 'react'
import { StatusBar, Platform } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

import ShakeScreen from '@vfal-lab/rn-vfg-shake/Screens'
import { VFScreen } from '@vfgroup-oneplatform/foundation/Components'



import { actions } from './Shake.Actions'
import styles from './Shake.Styles'


function Shake({ navigation, route }) {

  const dispatch = useDispatch()


  const GetProducts = async (type, utilities) => {
    return dispatch(actions.getProducts(type, utilities))
  }
  const productDetails = useSelector(state => state.productDetails.data || {})

  const OrderProduct = async (orderId, productId, type, utilities) => {
    return dispatch(actions.orderProduct(orderId, productId, type, utilities))
  }
  const orderDetails = useSelector(state => state.orderDetails.data || {})

  const resetStatusBar = () => {
    if (Platform.OS === 'android') {
      StatusBar.setBarStyle('light-content', true)
      StatusBar.setBackgroundColor(Colors.thinDark, true)
      StatusBar.setTranslucent(false)
    } else if (Platform.OS === 'ios') {
      StatusBar.setBarStyle('dark-content', true)
    }
  }

  const navigateToBundles = () => {
    resetStatusBar()
    navigation.navigate('Catalogue')
  }

  const onClose = async () => {
    resetStatusBar()
    if (route?.params?.isDeepLink) {
      navigation.navigate('Dashboard')
    } else {
      navigation.pop()
    }
  }

  return (
    <VFScreen
      headerStyle={styles.headerStyle}
      title={'Shake'}
      hideMainHeader
      isFullHeight
    >
      <ShakeScreen
        onClose={onClose}
        navigateToBundles={navigateToBundles}
        isContentLoading={false}
        getProducts={GetProducts}
        productDetails={productDetails}
        orderProduct={OrderProduct}
        orderDetails={orderDetails}
      />
    </VFScreen>
  )

}

Shake.propTypes = {
  navigation: PropTypes.object
}

export default Shake
