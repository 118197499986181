import React, {Component} from 'react'
import {ActivityIndicator, Platform} from 'react-native'

import styles from './loader.styles'
import {Colors} from '../../Themes'

class Loader extends Component {
  shouldComponentUpdate() {
    return false
  }

  render() {
    const {view} = this.props
    let size = this.props.size
    if (!size) {
      size = 45
    }

    if (typeof view !== 'undefined' && view == 'full') {
      return (
        <ActivityIndicator
          style={[styles.loader, {height: '100%'}]}
          size={Platform.OS === 'ios' ? 1 : size}
          color={Colors.red}
        />
      )
    }

    return (
      <ActivityIndicator
        style={styles.loader}
        size={Platform.OS === 'ios' ? 1 : size}
        color={Colors.red}
      />
    )
  }
}

export default Loader
