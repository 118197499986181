import axios from 'axios'

import { isFunction as _isFunction } from 'lodash'
import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import { API_URLS } from '../Config'


const getCurrentBills = async (msisdn, customHeaders = {}, utilities = {}) => {
  const _utilities = retrieveUtilities(utilities)

  const {
    onStart,
    onSuccess,
    onError,
    responseMapper,
    errorMapper
  } = _utilities

  const params = {
    'relatedParty.id': msisdn,
    'relatedParty.role': 'Payer',
    'id': 'current_bill',
  }

  onStart()

  try {
    const response = await axios.get(API_URLS.fix.getFixBills, {
      params: params,
      headers: customHeaders,
    })

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const getPreviousBills = async (msisdn, customHeaders = {}, utilities = {}) => {
  const _utilities = retrieveUtilities(utilities)

  const {
    onStart,
    onSuccess,
    onError,
    responseMapper,
    errorMapper
  } = _utilities

  const params = {
    'relatedParty.id': msisdn,
    'relatedParty.role': 'Payer',
  }

  onStart()

  try {
    const response = await axios.get(API_URLS.fix.getFixBills, {
      params: params,
      headers: customHeaders,
    })

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

export { getCurrentBills, getPreviousBills }
