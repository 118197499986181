import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: theme => ({
    height: '100%',
    width: '100%',
    backgroundColor: '#0000007f',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  insideContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  whiteArea: theme => ({
    height: 'auto',
    width: '90%',
    backgroundColor: theme.colors.backgroundColor,
    padding: 20,
    borderRadius: 10
  }),
  title: (theme) => ({
    color: theme.colors.textColor,
    fontSize: 20,
    fontWeight: '700',
    marginVertical: 16,
  }),
  icon: { alignSelf: 'center' },
  description: (theme) => ({
    color: theme.colors.textColor,
    fontSize: 18,
    fontWeight: '500',
    marginVertical: 16,
    lineHeight: 18
  }),
  signUp: {
    marginTop: 12
  },
  signUpText: (theme) => ({
    color: theme.colors.textColor,
  })
})

export default styles
