import { Platform, StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    marginVertical: 20,
    flexDirection: 'row',
    alignItems: 'center'
  },
  text: {
    color: 'red',
    fontSize: Platform.OS === 'ios' ? 14.6 : 14
  },
  icon: {
    marginLeft: 5,
    marginTop: -4,
    alignItems: 'center'
  }
})