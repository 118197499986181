import React, { useEffect, useState } from 'react'
import { Linking, Platform } from 'react-native'
import { NavigationContainer, getStateFromPath } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import PropTypes from 'prop-types'
import { Colors, useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { withSplash } from '@vfgroup-oneplatform/foundation/Splash'
import { LoginScreens } from '@vfgroup-oneplatform/login'
// import { handelOnNavigationReady, handleNavigationStateChange } from '@vf-org/react-native-smapi'

// import { Purchase as ApplicationPDPPurchase } from '@vfgroup-oneplatform/soho-framework/ApplicationPDP/Screens'
// import TobiModal from '@vfgroup-oneplatform/framework/Dashboard/Tray/Components/TobiModal'
// import {
//   handleNavigationStateChange,
//   handleOnNavigationReady
// } from '@vf-org/react-native-smapi'


import TobiModal from '@vfgroup-oneplatform/framework/Dashboard/Tray/Components/TobiModal'



// PreLogin
import PreLogin from '../Containers/PreLogin'
import Story from '../Containers/Story/Story'

import DigitalAcquisition from '../Containers/DigitalAcquisition'


import TransparentPopup from '../Containers/TransparentPopup/TransparentPopup'



import LandingScreen from '../Containers/LandingScreen'
import Dashboard from '../Containers/Dashboard'
// import SpeedChecker from '../Containers/SpeedChecker'
import WebView from '../Containers/WebView'
import Billing from '../Containers/Billing'
import MonthlyBilling from '../Containers/MonthlyBilling'
import Shake from '../Containers/Shake'
import GigaFamily from '../Containers/GigaFamily'
import GigaBonus from '../Containers/GigaBonus'
import GigaProductInfo from '../Containers/GigaProductInfo'
import Catalogue from '../Containers/Catalogue'
import CatalogueDetails from '../Containers/CatalogueDetails'
// import AppPermissions from '../Containers/AppPermissions'
import ProductsAndServices from '../Containers/ProductsAndServices'
import Migration from '../Containers/Migration'
import CallMeBack from '../Containers/CallMeBack'
import CallMeBackHistory from '../Containers/CallMeBackHistory'
// import RemoveAddOn from '../Containers/RemoveAddOn'
// import MessageCenter from '../Containers/MessageCenter'
// import OnBoarding from '../Containers/OnBoarding'
import VDayOffers from '../Containers/VDayOffers'
import DataSharing from '../Containers/DataSharing'
import PaymentMethods from '../Containers/PaymentMethods'
import EditPayment from '../Containers/EditPayment'
import AddPayment from '../Containers/AddPayment'

// import MessageOfferScreen from '../Containers/MessageOfferScreen'
// import GroupManagement from '../Containers/GroupManagement'
import UnlimitedHomeJourney from '../Containers/UnlimitedHomeJourney'

import { YourAppointments, BookAnAppointment } from '../Containers/Appointments'
//Business Components
import BusinessOverview from '../Containers/Business/BusinessOverview'
import Plan from '../Containers/Business/Plan'
import PlanUsers from '../Containers/Business/PlanUsers'
import ProfileManagement from '../Containers/Business/ProfileManagement'
// import Marketplace from '../Containers/Business/Marketplace'
// import ApplicationsList from '../Containers/Business/ApplicationsList'
// import LicenceDetails from '../Containers/Business/LicenceDetails'
import UsersAndGroup from '../Containers/Business/UsersAndGroup'
// import Requests from '../Containers/Business/Requests'
// import ManageRequests from '../Containers/Business/ManageRequests'
// import AddOnRequestDetails from '../Containers/Business/AddOnRequestDetails'
//components
import TobiInterActive from '../Group/Components/Tobi/TobiInteractive'
import TobiModalContent from '../Group/Components/Tobi/TobiModalContent'
import OrderProgress from '../Containers/OrderProgress'

import TobiSupportContext from '../Utils/TobiSupportContext'

// //Settings
import Settings from '../Containers/Settings'
import ManageProfile from '../Containers/ManageProfile'
import EditProfile from '../Containers/EditProfile'
import ChangeMyPassword from '../Containers/ChangeMyPassword'
import DeactivateAccount from '../Containers/DeactivateAccount'
import Recommendations from '../Containers/Recommendations'
import Preferences from '../Containers/Preferences'
import DataManagment from '../Containers/DataManagment'
import TermsAndConditions from '../Containers/TermsAndConditions'
import PrivacyPolicy from '../Containers/PrivacyPolicy'

import Payments from '../Containers/Payments'
import Recommend from '../Containers/Recommend'

// import NetPerfom from '../Containers/NetPerform'

// // Reward Component
import Reward from '../Containers/Demo'
import OpenedGift from '../Containers/OpenedGift'
import Catalog from '../Containers/Catalog'
// import Survey from '../Containers/Survey'
import Profile from '../Containers/Profile'
import Info from '../Containers/Info'
import Details from '../Containers/Details'
import FavouritesDetails from '../Containers/FavouritesDetails'
import VfCoinsTransfer from '../Containers/VfCoinsTransfer'

// // Flower Box Component
import VfHearts from '../Containers/VfHearts'
import VfHeartsCollection from '../Containers/VfHeartsCollection'



import Quiz from '../Containers/Quiz'
import QuizGame from '../Containers/QuizGame'


import FixBundles from '../Containers/FixBundles'
import FixPurchaseCoverage from '../Containers/FixPurchaseCoverage'
import FixRenewals from '../Containers/FixRenewals'
import FixProductsAndServices from '../Containers/FixProductsAndServices'
import FixContracts from '../Containers/FixContracts'
import FixBilling from '../Containers/FixBilling'

import MyOrders from '../Containers/ManageOrders'

import Tutorial from '../Containers/Tutorial'

import Roaming from '../Containers/Roaming'

import RoamingSettings from '../Containers/RoamingSettings'
import DiscoverScreenSeeMore from '../Containers/DiscoverScreenSeeMore'
// import RecommendedBundles from '../Containers/RecommendedBundles'
// import SummerPromo from '../Containers/SummerPromo'
import CocaCola from '../Containers/CocaCola'

import { navigationRef } from './NavigationService'


const splashConfig = {
  logoSize: 32,
  defaultTime: 4000,
  // mode: SplashModes.FADING,
  color: Colors.vfRed
}

const navigationProps = Platform.OS !== 'web' ? {
  // onReady: () => handelOnNavigationReady(navigationRef),
  // onStateChange: () => handleNavigationStateChange(navigationRef)
} : {
}

const Stack = createNativeStackNavigator()

const createParamsFromPath = (_path) => {
  if (_path.includes('ebill')) {
    let newPath = '/ebill?'
    const fieldTypes = ['id', 'section']
    const paramsArray = _path.slice(1, _path.length).split('/')
    paramsArray.shift()
    paramsArray.map((item, index) => {
      newPath += `${fieldTypes[index]}=${item}&`
    })
    return newPath
  }
  let path
  // if (_path.includes('myoffers')) {
  const a = _path.split('/')
  path = '/' + a[1] + '?'
  a.splice(0, 2)
  a.map((item) => {
    if (item?.split('=').length === 1) {
      path += `type=${item}&`
    } else {
      let newItem = item
      if (newItem.startsWith('?')) {
        newItem = newItem.replace('?', '')
      }
      path += newItem
    }
  })
  return path
  // } else {
  //   return _path
  // }

}


function Navigation(otherprops) {
  const linking = {
    prefixes: ['vfal://', 'http://localhost:3000'],
    config: {
      screens: {
        Dashboard: 'dashboard',
        Billing: 'ebill',
        Catalogue: 'myoffers',
        Settings: 'settings',
        ProductsAndServices: 'productsandservices',
        Shake: 'shake',
        SpeedChecker: 'speedtest',
        PaymentMethods: 'mycards',
        VfHearts: 'flowerbox',
        CallMeBack: 'callmeback',
        GigaFamily: 'gigafamily',
        Story: 'story/:storyId?',
        ManageOrders: 'manageOrders',
        RoamingSettings: 'roamingSettings',
        Appointments: 'takimet',
        BookAnAppointment: 'rezervotakim',
        Roaming: 'roaming',
        UnlimitedHomeJourney: 'shtepiajone',
        SummerPromo: 'summerpromo',
        CocaCola: 'cocacola',
        VDayOffers: 'myweekend',
        FixProductsAndServices: 'fixproductsandservices',
        LoginByEmailScreen: {
          path: 'login/',
          // parse: {
          //   renderLoginIconView: (renderLoginIconView) => renderLoginIconView.replace(/^@/, ' ')
          // },
          // stringify: {
          //   renderLoginIconView: (renderLoginIconView) => '',
          // },
        },
        MonthlyBilling: {
          path: 'monthlybilling/:bill',
          parse: {
            bill: (bill) => bill.replace(/^@/, ' ')
          },
          stringify: {
            bill: (bill) => (''),
          },
        },
        WebView: {
          path: 'webview/:url/:title/:isReact',
          parse: {
            url: (url) => url.replace(/^@/, ''),
            title: (title) => title.replace(/^@/, ''),
            isReact: (isReact) => isReact.replace(/^@/, ''),
          },
          stringify: {
            url: (url) => '',
            title: (title) => '',
            isReact: (isReact) => '',
          },
        },
        CatalogueDetails: {
          path: 'cataloguedetails/:item',
          parse: {
            item: (item) => item.replace(/^@/, '')
          },
          stringify: {
            item: (item) => '',
          },
        }
      }
    },
    getStateFromPath: (path, config) => {

      let newPath
      const screensToBeFiltered = ['myoffers', 'productsandservices', 'ebill']


      if ((path.split('/').length - 1) > 1 && screensToBeFiltered.includes(path.split('/')[1])) {
        newPath = createParamsFromPath(path)
        window.location.replace(`http://localhost:3000${newPath}`)
      } else {
        newPath = path
      }
      //const newPath = path === 'recharge' ? 'dashboard' : path
      const state = getStateFromPath(newPath, config)

      //check if path is speed test
      if (path === '/speedtest') {

        const newState = {
          ...state,
          routes: state.routes.map(route => {
            return {
              ...route,
              name: 'Home',
              params: {
                ...route.params,
                isDeepLink: true,
                route: {
                  params: {
                    url: 'https://speedtest.vodafone.al',
                  },
                  name: 'WebView',
                  path: '/webview',
                }
              }
            }
          })
        }
        return newState
      }

      if (state) {
        const newState = {
          ...state,
          routes: state.routes.map(route => {
            return {
              ...route,
              name: 'Home',
              params: {
                ...route.params,
                isDeepLink: true,
                route: route
              }
            }
          })
        }
        return newState
      } else {
        return {
          routes: [{
            name: 'Home',
            path: '/dashboard',
            params: {
              isDeepLink: false,
              route: 'Dashboard'
            }
          }]
        }
      }
    }
  }
  const [activeTab, setActiveTab] = useState(0)

  const [showExpandedTobi, setShowExpandedTobi] = React.useState(false)

  let tobiModalRef = React.createRef(null)
  const toolTipsModalRef = React.createRef(null)

  const openTobiModal = () => tobiModalRef?.showModal(true)
  const theme = useTheme()

  return (
    <TobiSupportContext.Provider value={{ openTobiModal, toolTipsModalRef }}>
      <NavigationContainer
        ref={navigationRef}
        // onReady={() => handelOnNavigationReady(navigationRef)}
        // linking={linking}
        {...navigationProps}
      >
        <Stack.Navigator screenOptions={{
          headerShown: false,
          cardStyle: { backgroundColor: 'blue' },
        }}
        >
          <Stack.Screen name="Home">{(props) => <LandingScreen {...props} other={otherprops} />}</Stack.Screen>
          <Stack.Screen name="VerifyCodeScreen" component={LoginScreens.VerifyCodeScreen.screen} />
          <Stack.Screen name="LoginByEmailScreen" component={LoginScreens.LoginByEmailScreen.screen} />
          <Stack.Screen name="RegisterScreen" component={LoginScreens.RegisterScreen.screen} />
          <Stack.Screen name="ConfirmNewPasswordScreen" component={LoginScreens.ConfirmNewPasswordScreen.screen} />
          <Stack.Screen name="PhoneLoginScreen" component={LoginScreens.PhoneLoginScreen.screen} />
          <Stack.Screen name="Dashboard" component={Dashboard} />
          {/* <Stack.Screen name="SpeedChecker" component={SpeedChecker} /> */}
          <Stack.Screen name="WebView" component={WebView} />
          <Stack.Screen name="Billing" component={Billing} />
          <Stack.Screen name="MonthlyBilling" component={MonthlyBilling} />
          <Stack.Screen name="Shake" component={Shake} />
          <Stack.Screen name="GigaFamily" component={GigaFamily} />
          <Stack.Screen name="GigaProductInfo" component={GigaProductInfo} />
          <Stack.Screen name="GigaBonus" component={GigaBonus} />
          <Stack.Screen name="Catalogue" component={Catalogue} />
          <Stack.Screen name="CatalogueDetails" component={CatalogueDetails} />
          {/* <Stack.Screen name="AppPermissions" component={AppPermissions} /> */}
          <Stack.Screen name="ProductsAndServices" component={ProductsAndServices} />
          {/* <Stack.Screen name="RemoveAddOn" component={RemoveAddOn} />*/}
          <Stack.Screen name="Migration" component={Migration} />
          <Stack.Screen name="CallMeBack" component={CallMeBack} />
          <Stack.Screen name="CallMeBackHistory" component={CallMeBackHistory} />
          <Stack.Screen name="VDayOffers" component={VDayOffers} />
          {/* <Stack.Screen name="OnBoarding" component={OnBoarding} /> */}
          <Stack.Screen name="DataSharing" component={DataSharing} />
          <Stack.Screen name="PaymentMethods" component={PaymentMethods} />
          <Stack.Screen name="EditPayment" component={EditPayment} />
          <Stack.Screen name="AddPayment" component={AddPayment} />
          <Stack.Screen name="PreLogin" options={({ route }) => ({ animaitonTypeForReplace: 'push', animation: 'fade_from_bottom', headerShown: false })}>{(props) => <PreLogin {...props} other={otherprops} />}</Stack.Screen>
          <Stack.Screen name="Recommend" component={Recommend} />
          <Stack.Screen name="TransparentPopup" component={TransparentPopup} options={{ presentation: 'transparentModal', animaitonTypeForReplace: 'push', animation: 'fade' }} />


          {/* SETTINGS WRAPPED */}
          <Stack.Screen name="Settings" >{(props) => (<Settings activeTab={activeTab} setActiveTab={setActiveTab} {...props} />)}</Stack.Screen>
          <Stack.Screen name="ManageProfile" >{(props) => (<ManageProfile activeTab={activeTab} setActiveTab={setActiveTab} {...props} />)}</Stack.Screen>
          <Stack.Screen name="EditProfile" >{(props) => (<EditProfile activeTab={activeTab} setActiveTab={setActiveTab} {...props} />)}</Stack.Screen>
          <Stack.Screen name="ChangeMyPassword" >{(props) => (<ChangeMyPassword activeTab={activeTab} setActiveTab={setActiveTab} {...props} />)}</Stack.Screen>
          <Stack.Screen name="DeactivateAccount" >{(props) => (<DeactivateAccount activeTab={activeTab} setActiveTab={setActiveTab} {...props} />)}</Stack.Screen>
          <Stack.Screen name="Recommendations" >{(props) => (<Recommendations activeTab={activeTab} setActiveTab={setActiveTab} {...props} />)}</Stack.Screen>
          <Stack.Screen name="Preferences" >{(props) => (<Preferences activeTab={activeTab} setActiveTab={setActiveTab} {...props} />)}</Stack.Screen>
          <Stack.Screen name="DataManagment" >{(props) => (<DataManagment activeTab={activeTab} setActiveTab={setActiveTab} {...props} />)}</Stack.Screen>
          <Stack.Screen name="TermsAndConditions" >{(props) => (<TermsAndConditions activeTab={activeTab} setActiveTab={setActiveTab} {...props} />)}</Stack.Screen>
          <Stack.Screen name="PrivacyPolicy" >{(props) => (<PrivacyPolicy activeTab={activeTab} setActiveTab={setActiveTab} {...props} />)}</Stack.Screen>



          {/* <Stack.Screen name="ManageProfile" component={ManageProfile} />
         <Stack.Screen name="EditProfile" component={EditProfile} />
        <Stack.Screen name="ChangeMyPassword" component={ChangeMyPassword} />
        <Stack.Screen name="DeactivateAccount" component={DeactivateAccount} />
        <Stack.Screen name="Recommendations" component={Recommendations} />
        <Stack.Screen name="Preferences" component={Preferences} />
        <Stack.Screen name="DataManagment" component={DataManagment} />
        <Stack.Screen name="TermsAndConditions" component={TermsAndConditions} />
        <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy} />
        <Stack.Screen name="MessageCenter" component={MessageCenter} />
      <Stack.Screen name="NetPerfom" component={NetPerfom} /> */}
          <Stack.Screen name="TopUPAnother" component={Payments} />
          <Stack.Screen name="TopUP" component={Payments} />
          <Stack.Screen name="Reward" component={Reward} options={{ route: { theme: theme } }} />
          <Stack.Screen name="OpenedGift" component={OpenedGift} />
          {/* <Stack.Screen name="Survey" component={Survey} /> */}
          <Stack.Screen name="Catalog" component={Catalog} options={{ route: { theme: theme } }} />

          <Stack.Screen name="Profile" component={Profile} />
          <Stack.Screen name="Info" component={Info} />
          <Stack.Screen name="Details" component={Details} />
          <Stack.Screen name="FavouritesDetails" component={FavouritesDetails} />
          <Stack.Screen name="VfCoinsTransfer" component={VfCoinsTransfer} />
          <Stack.Screen name="VfHearts" component={VfHearts} />
          <Stack.Screen name="VfHeartsCollection" component={VfHeartsCollection} />
          <Stack.Screen name="Tutorial" component={Tutorial} />
          <Stack.Screen name="Story" component={Story} />
          <Stack.Screen name="Roaming" component={Roaming} />
          <Stack.Screen name="RoamingSettings" component={RoamingSettings} />
          <Stack.Screen name="DiscoverScreenSeeMore" component={DiscoverScreenSeeMore} />



          {/* <Stack.Screen name="Promo" component={Promo} />
        <Stack.Screen name="PromoPrizes" component={PromoPrizes} /> */}

          <Stack.Screen name="Quiz" component={Quiz} />
          <Stack.Screen name="QuizGame" component={QuizGame} />
          <Stack.Screen name="ManageOrders" component={MyOrders} />
          <Stack.Screen name="FixContracts" component={FixContracts} />
          <Stack.Screen name="FixProductsAndServices" component={FixProductsAndServices} />
          <Stack.Screen name="FixBilling" component={FixBilling} />
          <Stack.Screen name="FixPurchaseCoverage" component={FixPurchaseCoverage} />
          <Stack.Screen name="FixRenewals" component={FixRenewals} />
          <Stack.Screen name="FixBundles" component={FixBundles} />
          <Stack.Screen name="DigitalAcquisition" component={DigitalAcquisition} />

          {/* <Stack.Screen name="GroupManagement" component={GroupManagement} /> */}
          <Stack.Screen name="UnlimitedHomeJourney" component={UnlimitedHomeJourney} />
          <Stack.Screen name="Appointments" component={YourAppointments} />
          <Stack.Screen name="BookAnAppointment" component={BookAnAppointment} />
          <Stack.Screen name="BusinessOverview" component={BusinessOverview} />
          <Stack.Screen name="Plan" component={Plan} />
          <Stack.Screen name="PlanUsers" component={PlanUsers} />
          <Stack.Screen name="ProfileManagement" component={ProfileManagement} />
          <Stack.Screen name="UsersAndGroup" component={UsersAndGroup} />
          <Stack.Screen name="OrderProgress" component={OrderProgress} />
          {/* Soho components */}
          {/*
          <Stack.Screen name="Marketplace" component={Marketplace} />
          <Stack.Screen name="ApplicationsList" component={ApplicationsList} />
          <Stack.Screen name="LicenceDetails" component={LicenceDetails} />
          <Stack.Screen name="ApplicationPDPPurchase" component={ApplicationPDPPurchase} />
          <Stack.Screen name="Requests" component={Requests} />
          <Stack.Screen name="ManageRequests" component={ManageRequests} />
          <Stack.Screen name="AddOnRequestDetails" component={AddOnRequestDetails} />
        <Stack.Screen name="LicenceRequestDetails" component={SohoRequestsScreens.LicenceRequestDetails.screen} /> */}

          {/*



         */}
        <Stack.Screen name="CocaCola" component={CocaCola} />

        </Stack.Navigator>
      </NavigationContainer>
      <TobiModal
        onHideModal={() => {
          tobiModalRef?.hideModal()
          setShowExpandedTobi(false)
        }}
        trayType="main"
        ref={ref => {
          tobiModalRef = ref
        }}
      >
        <TobiModalContent
          closeModal={() => tobiModalRef?.hideModal()}
          navigationRef={navigationRef}
        />
      </TobiModal>

      <TobiInterActive
        dropTray={true}
        finishedAnimation={() => {
          tobiModalRef?.showModal(true)
          setShowExpandedTobi(true)
        }}
        navigationRef={navigationRef}
        showExpandedTobi={showExpandedTobi}
      />
    </TobiSupportContext.Provider >
  )
}

Navigation.propTypes = {
  startSplashEndingAnimation: PropTypes.func,
  dismissSplash: PropTypes.func,
  setSplashMode: PropTypes.func,
  setSplashColor: PropTypes.func,
  endingDuration: PropTypes.number,
  isVisible: PropTypes.bool,
  setSplashLogoPosition: PropTypes.func
}

export default withSplash(React.forwardRef(Navigation), splashConfig)
// export default React.forwardRef(Navigation)
