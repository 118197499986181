import axios from 'axios'

import CONFIG from '../../Config'
import { getErrorMessage } from '../../Utils'
import ReduxCache from '../../Utils/ReduxCache'

import { actions as redeemActions } from '../Redeem/redeem.actions'

import { action_types } from './openedgift.actionTypes'

const getGift = () => {
  return async dispatch => {
    dispatch({
      type: action_types.REQUEST_GIFT
    })

    const msisdn = ReduxCache.getMsisdn()

    const data = {
      'queryOptions': {
        'filter': '',
        'pagination': {
          'count': 10,
          'limit': 0
        },
        'sorting': ''
      },
      'queries': [
        {
          'query': '$.redeemerId=' + msisdn
        },
        {
          'operator': 'AND'
        },
        {
          'query': '$.type=' + CONFIG.queryConfig.type
        },
        {
          'operator': 'AND'
        },
        {
          'query': '$.channelId=' + CONFIG.queryConfig.channelId
        },
        {
          'operator': 'AND'
        },
        {
          'query': '$.category=gift'
        }
      ]
    }

    const url = await CONFIG.getUrl()
    const headers = await CONFIG.getHeaders()
    axios.post(url.apiEndpoint, data, {
      headers: {
        ...headers
      }
    })
      .then(response => {
        const data = response.data[0]

        if (!data) {
          const gift = {
            name: '',
            desc: '',
            validityPeriod: ''
          }

          dispatch({
            type: action_types.SUCCESS_GIFT,
            payload: gift
          })

          return
        } else {
          dispatch(redeemActions.redeemProduct(data, true))
        }

        const gift = {
          name: data.parts.productOffering[0].name,
          desc: data.parts.productOffering[0].desc,
          validityPeriod: data.parts.productOffering[0].toDate.date
        }

        dispatch({
          type: action_types.SUCCESS_GIFT,
          payload: gift
        })
      })
      .catch(error => {
        const message = getErrorMessage(error)

        dispatch({
          type: action_types.ERROR_GIFT,
          payload: message
        })
      })
  }
}

export const actions = {
  getGift
}
