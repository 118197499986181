import React from 'react'
import PropTypes from 'prop-types'

import { Platform, TouchableOpacity } from 'react-native'
import { VFText, Icon } from '@vfgroup-oneplatform/foundation/Components'

import { Images } from '../../Themes'
import { styles } from './ErrorHandler.Styles'

const ErrorHandlerComponent = ({ textButton, onRetry }) => {
  return (
    <TouchableOpacity
      style={styles.container}
      onPress={onRetry}
      accessible={false}
    >
      <VFText
        i18nKey={textButton}
        style={styles.text}
      />
      <Icon
        name={Images.ic_refreshRed}
        type="image"
        size={26}
        style={styles.icon}
      />
    </TouchableOpacity>
  )
}

ErrorHandlerComponent.defaultProps = {
  textButton: 'Retry Again',
  onRetry: () => { console.log('Error handler clicked') }
}

ErrorHandlerComponent.propTypes = {
  textButton: PropTypes.string,
  onRetry: PropTypes.func
}

export default ErrorHandlerComponent