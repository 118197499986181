import React, { Component } from 'react'
import {
  View,
  TouchableOpacity,
  Text,
  FlatList,
  TextInput,
  KeyboardAvoidingView,
  Platform,
  Dimensions,
} from 'react-native'
import { Footer, FooterTab, Button, Header, Item } from 'native-base'
import { connect } from 'react-redux'
import { SafeAreaView } from 'react-native-safe-area-context'
import FastImage from 'react-native-fast-image'

import Modal from 'react-native-modal'

import Error from '../../Components/Error'

import { __translate, __image } from '../../Utils'

import { Images } from '../../Themes'
import styles from './filters.styles'

import { actions } from './filters.actions'

import { getThemeColors } from '@vfgroup-oneplatform/foundation/Components/Themes'

class Filters extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modalVisible: false,
      items: [],
      partner: null,
      location: null,
      sort: null,
      search: '',
      listLength: 0,
      placeholders: {
        location: __translate('reward_loyalty_location_bottom_menu'),
        partner: __translate('reward_loyalty_partner_bottom_menu'),
        sort: '',
      },
    }
  }

  setModalVisible(visible, type = null) {
    if (
      !this.props.filters ||
      !this.props.filters.data ||
      !this.props.filters.data[type]
    ) {
      return
    }

    if (type != null) {
      this.setState({
        modalVisible: visible,
        type: type,
        items: (this.props.filters && this.props.filters.data[type].data) || [],
        listLength: this.props.filters.data[type].data.length,
      })
    } else {
      this.setState({
        modalVisible: visible,
        type: type,
        listLength: 0,
        search: '',
      })
    }
  }

  componentDidMount() {
    this.props.getFilters()
  }

  _retry() {
    this.props.getFilters()
  }

  filterItems(text) {
    const { filters } = this.props
    if (typeof text === 'undefined') {
      this.setState({
        items: filters.data[this.state.type].data,
      })
    }

    const items = filters.data[this.state.type].data.filter((item, index) => {
      return item.title.toLowerCase().indexOf(text.toLowerCase()) >= 0
    })

    this.setState({
      items: items,
      search: text,
    })
  }

  handleClear() {
    this.setState(
      {
        [this.state.type]: null,
      },
      () => this.handleSearch(),
    )
  }

  handleItemSelect(value) {
    this.setState(
      {
        [this.state.type]: value,
        search: '',
        items: this.props.filters.data[this.state.type].data,
      },
      () => this.handleSearch(),
    )
  }

  handleSearch = () => {
    this.hideModal()
    this.props.changePage(1)

    setTimeout(() => {
      const filters = {}
      if (this.state.partner) {
        filters.partner = this.state.partner
      }

      if (this.state.location) {
        filters.location = this.state.location
      }

      if (this.state.sort) {
        filters.sort = this.state.sort
      }

      if (Object.keys(filters).length > 0) {
        this.props.getAllItems(filters)
      } else {
        this.props.getAllItems(null)
      }
    }, 500)
  };

  hideModal() {
    this.setState({ modalVisible: false })
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState != this.state || nextProps.filters != this.props.filters
  }

  getAnroid() {
    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
      const theme = localStorage.getItem('@display_option')
      const themeColors = getThemeColors(theme === 'dark' ? 'dark' : 'light')
      return (
        <View style={{
          minWidth: '600px',
          maxWidth: '600px',
          minHeight: '500px',
          maxHeight: '500px',
          backgroundColor: themeColors.colors.quickActionGradientColors.secondary,
          margin: 'auto',
          padding: '20px',
          borderRadius: "7px",
        }}>
          <View style={[styles.modalContent, { height: 460, width: 560, marginTop: '20px', justifyContent: 'center', marginBottom: '20px' }]}>
            <View>
              <Header noShadow searchBar rounded transparent style={[styles.modalHeader, { alignItems: 'center' }]}>
                {this.state.listLength > 7 ? (
                  <Item>
                    <FastImage style={styles.search} source={Images.search} />
                    <TextInput
                      value={this.state.search}
                      onChangeText={text => this.filterItems(text)}
                      style={styles.input}
                      placeholder={this.state.placeholders[this.state.type]}
                    />
                  </Item>
                ) : null}
                {this.state.type == 'sort' ? (
                  <Item>
                    <Text style={styles.sort}>
                      {__translate('reward_loyalty_sorting_bottom_menu')}
                    </Text>
                  </Item>
                ) : null}
                <TouchableOpacity
                  onPress={() => {
                    this.hideModal()
                  }}>
                  <FastImage
                    style={styles.arrow_down}
                    source={Images.close_red}
                  />
                </TouchableOpacity>
              </Header>
            </View>
            {this.state[this.state.type] && (
              <Text onPress={() => this.handleClear()} style={styles.clear}>
                {__translate('reward_loyalty_clear_filter')}
              </Text>
            )}

            <FlatList
              data={this.state.items}
              extraData={[
                this.state.partner,
                this.state.sort,
                this.state.location,
              ]}
              renderItem={({ item, separators }) => (
                <TouchableOpacity
                  onPress={() => this.handleItemSelect(item.key)}
                  onShowUnderlay={separators.highlight}
                  onHideUnderlay={separators.unhighlight}>
                  <View style={styles.listView}>
                    {this.state[this.state.type] === item.key ? (
                      <Text style={styles.selectedItem}>{item.title}</Text>
                    ) : (
                      <Text style={styles.item}>{item.title}</Text>
                    )}
                  </View>
                </TouchableOpacity>
              )}
            />
          </View>
        </View>
      )
    }

    return (
      <SafeAreaView
        forceInset={{ top: 'always' }}
        style={{
          flex: 1, backgroundColor: 'transparent'
        }}>
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <View>
              <Header noShadow searchBar rounded transparent style={styles.modalHeader}>
                {this.state.listLength > 7 ? (
                  <Item>
                    <FastImage style={styles.search} source={Images.search} />
                    <TextInput
                      value={this.state.search}
                      onChangeText={text => this.filterItems(text)}
                      style={styles.input}
                      placeholder={this.state.placeholders[this.state.type]}
                    />
                  </Item>
                ) : null}
                {this.state.type == 'sort' ? (
                  <Item>
                    <Text style={styles.sort}>
                      {__translate('reward_loyalty_sorting_bottom_menu')}
                    </Text>
                  </Item>
                ) : null}
                <TouchableOpacity
                  onPress={() => {
                    this.hideModal()
                  }}>
                  <FastImage
                    style={styles.arrow_down}
                    source={Images.arrow_down}
                  />
                </TouchableOpacity>
              </Header>
            </View>
            {this.state[this.state.type] && (
              <Text onPress={() => this.handleClear()} style={styles.clear}>
                {__translate('reward_loyalty_clear_filter')}
              </Text>
            )}

            <FlatList
              data={this.state.items}
              extraData={[
                this.state.partner,
                this.state.sort,
                this.state.location,
              ]}
              renderItem={({ item, separators }) => (
                <TouchableOpacity
                  onPress={() => this.handleItemSelect(item.key)}
                  onShowUnderlay={separators.highlight}
                  onHideUnderlay={separators.unhighlight}>
                  <View style={styles.listView}>
                    {this.state[this.state.type] === item.key ? (
                      <Text style={styles.selectedItem}>{item.title}</Text>
                    ) : (
                      <Text style={styles.item}>{item.title}</Text>
                    )}
                  </View>
                </TouchableOpacity>
              )}
            />
          </View>
        </View>
      </SafeAreaView>
    )
  }

  getIOS() {
    return (
      <KeyboardAvoidingView
        style={{ flex: 1, backgroundColor: 'transparent' }}
        behavior="padding"
        enabled>
        <TouchableOpacity
          style={{ flex: 1 }}
          onPress={() => {
            this.hideModal()
          }}>
          <SafeAreaView
            forceInset={{ top: 'always' }}
            style={{ flex: 1, backgroundColor: 'transparent' }}>
            <View style={styles.modalContent}>
              <View>
                <Header noShadow searchBar rounded style={styles.modalHeader}>
                  {this.state.listLength > 7 ? (
                    <Item>
                      <FastImage style={styles.search} source={Images.search} />
                      <TextInput
                        value={this.state.search}
                        onChangeText={text => this.filterItems(text)}
                        style={styles.input}
                        placeholder={this.state.placeholders[this.state.type]}
                      />
                    </Item>
                  ) : null}
                  {this.state.type == 'sort' ? (
                    <Item>
                      <Text style={styles.sort}>
                        {__translate('reward_loyalty_sorting_bottom_menu')}
                      </Text>
                    </Item>
                  ) : null}
                  <TouchableOpacity
                    onPress={() => {
                      this.hideModal()
                    }}>
                    <FastImage
                      style={styles.arrow_down}
                      source={Images.arrow_down}
                    />
                  </TouchableOpacity>
                </Header>
              </View>
              {this.state[this.state.type] && (
                <Text onPress={() => this.handleClear()} style={styles.clear}>
                  {__translate('reward_loyalty_clear_filter')}
                </Text>
              )}

              <FlatList
                data={this.state.items}
                extraData={[
                  this.state.partner,
                  this.state.sort,
                  this.state.location,
                ]}
                renderItem={({ item, separators }) => (
                  <TouchableOpacity
                    onPress={() => this.handleItemSelect(item.key)}
                    onShowUnderlay={separators.highlight}
                    onHideUnderlay={separators.unhighlight}>
                    <View style={styles.listView}>
                      {this.state[this.state.type] === item.key ? (
                        <Text style={styles.selectedItem}>{item.title}</Text>
                      ) : (
                        <Text style={styles.item}>{item.title}</Text>
                      )}
                    </View>
                  </TouchableOpacity>
                )}
              />
            </View>
          </SafeAreaView>
        </TouchableOpacity>
      </KeyboardAvoidingView>
    )
  }

  render() {
    const { filters } = this.props

    if (filters.loading) {
      return null
    }

    if (filters.error) {
      return <Error message={filters.error} retry={() => this._retry()} />
    }

    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
      return (
        <View style={{ marginTop: 0, height: 142 }}>
          <Modal
            style={{ margin: 0, }}
            backgropColor={'#040406'}
            backdropOpacity={0.85}
            isVisible={this.state.modalVisible}
            animationIn="fadeIn"
            animationOut="fadeOut"
            backdropTransitionInTiming={600}
            backdropTransitionOutTiming={600}
            animationOutTiming={200}
            useNativeDriver={true}
            hideModalContentWhileAnimating={true}
            onBackdropPress={() => this.hideModal()}
            onBackButtonPress={() => this.hideModal()}>

            {Platform.OS == 'ios' ? this.getIOS() : this.getAnroid()}

            {/* <Footer style={{ marginLeft: 300 }}>
              <FooterTab style={styles.background}>
                <Button
                  style={this.state.partner ? styles.hasFilter : styles.noFilter}
                  onPress={() => {
                    this.setModalVisible(true, 'partner')
                  }}>
                  <FastImage
                    style={styles.icon}
                    source={{ uri: __image('reward_loyalty_partner_bottom_menu') }}
                  />
                </Button>
                <Button
                  style={this.state.location ? styles.hasFilter : styles.noFilter}
                  onPress={() => {
                    this.setModalVisible(true, 'location')
                  }}>
                  <FastImage
                    style={styles.icon}
                    source={{ uri: __image('reward_loyalty_location_bottom_menu') }}
                  />
                </Button>
                <Button
                  style={this.state.sort ? styles.hasFilter : styles.noFilter}
                  onPress={() => {
                    this.setModalVisible(true, 'sort')
                  }}>
                  <FastImage
                    style={styles.icon}
                    source={{ uri: __image('reward_loyalty_sorting_bottom_menu') }}
                  />
                </Button>
              </FooterTab>
            </Footer> */}
          </Modal>

          <Footer>
            <FooterTab style={styles.background}>
              <Button
                style={this.state.partner ? styles.hasFilter : styles.noFilter}
                onPress={() => {
                  this.setModalVisible(true, 'partner')
                }}>
                <FastImage
                  style={styles.icon}
                  source={{ uri: __image('reward_loyalty_partner_bottom_menu') }}
                />
              </Button>
              <Button
                style={this.state.location ? styles.hasFilter : styles.noFilter}
                onPress={() => {
                  this.setModalVisible(true, 'location')
                }}>
                <FastImage
                  style={styles.icon}
                  source={{ uri: __image('reward_loyalty_location_bottom_menu') }}
                />
              </Button>
              <Button
                style={this.state.sort ? styles.hasFilter : styles.noFilter}
                onPress={() => {
                  this.setModalVisible(true, 'sort')
                }}>
                <FastImage
                  style={styles.icon}
                  source={{ uri: __image('reward_loyalty_sorting_bottom_menu') }}
                />
              </Button>
            </FooterTab>
          </Footer>
        </View>
      )
    }

    return (
      <View style={{ marginTop: 0 }}>
        <Modal
          style={{ margin: 0 }}
          backgropColor={'#040406'}
          backdropOpacity={0.85}
          isVisible={this.state.modalVisible}
          animationIn="fadeIn"
          animationOut="fadeOut"
          backdropTransitionInTiming={600}
          backdropTransitionOutTiming={600}
          animationOutTiming={200}
          useNativeDriver={true}
          hideModalContentWhileAnimating={true}
          onBackdropPress={() => this.hideModal()}
          onBackButtonPress={() => this.hideModal()}>
          {Platform.OS == 'ios' ? this.getIOS() : this.getAnroid()}

          <Footer>
            <FooterTab style={styles.background}>
              <Button
                style={this.state.partner ? styles.hasFilter : styles.noFilter}
                onPress={() => {
                  this.setModalVisible(true, 'partner')
                }}>
                <FastImage
                  style={styles.icon}
                  source={{ uri: __image('reward_loyalty_partner_bottom_menu') }}
                />
              </Button>
              <Button
                style={this.state.location ? styles.hasFilter : styles.noFilter}
                onPress={() => {
                  this.setModalVisible(true, 'location')
                }}>
                <FastImage
                  style={styles.icon}
                  source={{ uri: __image('reward_loyalty_location_bottom_menu') }}
                />
              </Button>
              <Button
                style={this.state.sort ? styles.hasFilter : styles.noFilter}
                onPress={() => {
                  this.setModalVisible(true, 'sort')
                }}>
                <FastImage
                  style={styles.icon}
                  source={{ uri: __image('reward_loyalty_sorting_bottom_menu') }}
                />
              </Button>
            </FooterTab>
          </Footer>
        </Modal>

        <Footer>
          <FooterTab style={styles.background}>
            <Button
              style={this.state.partner ? styles.hasFilter : styles.noFilter}
              onPress={() => {
                this.setModalVisible(true, 'partner')
              }}>
              <FastImage
                style={styles.icon}
                source={{ uri: __image('reward_loyalty_partner_bottom_menu') }}
              />
            </Button>
            <Button
              style={this.state.location ? styles.hasFilter : styles.noFilter}
              onPress={() => {
                this.setModalVisible(true, 'location')
              }}>
              <FastImage
                style={styles.icon}
                source={{ uri: __image('reward_loyalty_location_bottom_menu') }}
              />
            </Button>
            <Button
              style={this.state.sort ? styles.hasFilter : styles.noFilter}
              onPress={() => {
                this.setModalVisible(true, 'sort')
              }}>
              <FastImage
                style={styles.icon}
                source={{ uri: __image('reward_loyalty_sorting_bottom_menu') }}
              />
            </Button>
          </FooterTab>
        </Footer>
      </View>
    )
  }
}

const mapStateToProps = state => {
  const { filters, splash } = state
  return { filters, splash }
}

const mapDispatchToProps = dispatch => {
  return {
    getFilters: () => {
      dispatch(actions.getFilters())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Filters)
