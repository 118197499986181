import { LoginStatuses as Status } from '@vfgroup-oneplatform/login'
import { VerifyCodeBase } from '@vfgroup-oneplatform/login/BaseClasses'

// import { LoginStatuses as Status } from 'rn-vfg-login'
// import { VerifyCodeBase } from 'rn-vfg-login/BaseClasses'

import { errorObjectConstructor } from '../../Services/Auth/utils'

import { Actions } from './VerifyCode.Actions'

class VerifyCode extends VerifyCodeBase {
  constructor() {
    super()
    this.GET_SMS_CODE_SUCCESS = {
      status: Status.Success,
      response: {}
    }
    this.GET_SMS_CODE_FAILED = {
      status: Status.Failed,
      errorMessage: 'An error occurred',
      subErrorMessage: 'Please try again.'
    }
    this.verifyCodeLength = 5
  }

  keyboardType = 'default'

  getResendCodeTimeout = () => {
    return 120000
  }

  withCountdown = true

  getSMSCode = phoneNumber => {
    return new Promise(async (resolve, reject) => {
      try {
        await Actions.generatePin(phoneNumber)
        resolve(this.GET_SMS_CODE_SUCCESS)
      } catch (error) {
        const errorInfo = errorObjectConstructor(
          error,
          this.GET_SMS_CODE_FAILED
        )
        reject(errorInfo)
      }
    })
  }
}
export default VerifyCode
