import { REHYDRATE } from 'redux-persist'

import { action_types } from './catalog.actionTypes'

function user(state = { loading: false, data: null }, action = {}) {
  switch (action.type) {
    case action_types.SUCCESS_PROFILE:
      return {
        ...state,
        loading: false,
        error: null,
        data: {
          ...state.data,
          ...action.payload
        }
      }

    case action_types.ERROR_PROFILE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case action_types.REQUEST_PROFILE:
      return {
        ...state,
        loading: true
      }

    default:
      return state
  }
}

function categories(state = { loading: false, data: null }, action = {}) {
  switch (action.type) {
    case action_types.REQUEST_CATEGORIES:
      return {
        ...state,
        loading: true
      }

    case action_types.SUCCESS_CATEGORIES:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      }

    case REHYDRATE:
      if (typeof action.payload === 'undefined' || typeof action.payload.categories === 'undefined' || action.payload.categories.error) {
        return {
          ...state,
          data: null,
          error: null
        }
      }

      const categories = { ...action.payload.categories.data }
      try {
        for (const i in categories) {
          if (categories[i] && categories[i].products) {
            delete categories[i].products
          }
        }
      } catch (error) {
        console.error('something went wrong with rehydrate!')
      }

      return {
        ...state,
        loading: false,
        error: null,
        data: categories
      }

    case action_types.ERROR_CATEGORIES:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case action_types.SUCCESS_CATEGORY_ITEMS:
      const oldData = state.data[action.payload.id].products || []
      let newData = []
      if (typeof oldData !== 'undefined') {
        newData = oldData.data
      }

      if (action.payload.isFilter || action.payload.isFirstPage) {
        newData = []
      }

      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            products: {
              loading: false,
              loadingMore: false,
              error: null,
              data: (newData || []).concat(action.payload.products)
            }
          }
        }
      }

    case action_types.REQUEST_CATEGORY_ITEMS:
      if (!state.data) {
        return {
          ...state,
          data: {
            [action.payload.id]: {
              loading: true,
              loadingMore: action.payload.loadingMore
            }
          }
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            products: {
              ...state.data[action.payload.id].products,
              loading: true,
              loadingMore: action.payload.loadingMore
            }
          }
        }
      }
    case action_types.ERROR_CATEGORY_ITEMS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            products: {
              ...state.data[action.payload.id].products,
              loading: false,
              error: action.payload.error
            }
          }
        }
      }
    case action_types.SUCCESS_FAVORITE_HANDLE:

      if (typeof state.data.all.products === 'undefined' || !state.data.all.products.data) {
        return state
      }

      return {
        ...state,
        data: {
          ...state.data,
          all: {
            ...state.data.all,
            products: {
              ...state.data.all.products,
              data: state.data.all.products.data.map((product) => {
                if (product.id == action.payload.id) {
                  return { ...product, favorite: action.payload.favorite }
                } else {
                  return product
                }
              })
            }
          },
          [action.payload.category]: {
            ...state.data[action.payload.category],
            products: {
              ...state.data[action.payload.category].products,
              data: state.data[action.payload.category].products.data.map((product) => {
                if (product.id == action.payload.id) {
                  return { ...product, favorite: action.payload.favorite }
                } else {
                  return product
                }
              })
            }
          }
        }
      }
    default:
      return state
  }
}

function favorites(state = { loading: false, data: null }, action = {}) {
  switch (action.type) {
    case action_types.REQUEST_FAVORITES:
      return {
        ...state,
        loading: true
      }

    case action_types.SUCCESS_FAVORITES:
      const oldData = state.data || []

      if (action.payload.page == 0) {
        return {
          ...state,
          loading: false,
          error: null,
          data: action.payload.products
        }
      }

      return {
        ...state,
        loading: false,
        error: null,
        data: (oldData).concat(action.payload.products)
      }

    case action_types.ERROR_FAVORITES:
    case action_types.ERROR_FAVORITE_HANDLE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case action_types.SUCCESS_FAVORITE_HANDLE:
      if (action.payload.favorite == false) {
        // we need to remove from array
        return {
          ...state,
          error: null,
          data: state.data.filter(product => {
            if (product.id == action.payload.id) {
              return false
            }
            return true
          })
        }
      } else {
        // we need to add to array
        return {
          ...state,
          error: null,
          data: [
            ...state.data,
            action.payload.product
          ]
        }
      }

    default:
      return state
  }
}

function plainProducts(state = {}, action = {}) {
  switch (action.type) {
    case action_types.SUCCESS_PLAN_PRODUCTS:
      return {
        ...state,
        ...action.payload
      }
    case action_types.SUCCESS_FAVORITE_HANDLE:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          status: action.payload.status
        }
      }
    default:
      return state
  }
}

function dynamicCoupons(state = { loading: false, data: null }, action) {
  switch (action.type) {
    case action_types.GENERATE_COUPON:
      return {
        ...state,
        loading: true,
      }

    case action_types.SUCCESS_COUPON:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      }

    case action_types.ERROR_COUPON:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

function activeCoupon(state = { loading: false, data: null }, action) {
  switch (action.type) {
    case action_types.REQUEST_ACTIVE_COUPON:
      return {
        ...state,
        loading: true,
      }

    case action_types.SUCCESS_ACTIVE_COUPON:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      }

    case action_types.ERROR_ACTIVE_COUPON:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

function cancelledCoupon(state = { loading: false, data: null }, action) {
  switch (action.type) {
    case action_types.REQUEST_CANCEL_COUPON:
      return {
        ...state,
        loading: true,
      }

    case action_types.SUCCESS_CANCEL_COUPON:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      }

    case action_types.ERROR_CANCEL_COUPON:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export { user, dynamicCoupons, activeCoupon, cancelledCoupon, categories, favorites, plainProducts }
