import ReduxCache from '../Utils/ReduxCache'

const headersConfig = {
  sourceSystem: 'selfcare',
  sourceOperator: 'reward',
  sourceCountryCode: 'al',
  acceptLanguage: 'al',
  system: 'facebook',
  operator: 'facebook_app'
}

const getLbRedeemHeaders = async () => {
  let locale = ReduxCache.getLanguage()
  if (!locale) {
    locale = 'al'
  }

  return {
    'vf-source': 'love_box',
    'vf-operator': 'reward',
    'Content-Type': 'application/json',
    'vf-country-code': locale,
    'Accept-Language': locale
  }
}

const getUserProfileHeaders = async () => {
  let locale = ReduxCache.getLanguage()
  if (!locale) {
    locale = 'al'
  }

  return {
    'Content-Type': 'application/json',
    'Accept-Language': locale,
    'vf-source': 'love_box',
    'vf-operator': 'reward'
  }
}

const getAllItemsHeaders = async () => {
  let locale = ReduxCache.getLanguage()
  if (!locale) {
    locale = 'al'
  }

  return {
    'Content-Type': 'application/json',
    'Accept-Language': locale,
    'X-Source-CountryCode': locale,
    'X-Source-System': 'love_box',
    'X-Source-Operator': 'reward'
  }
}

const getLoveBoxRedeemHeaders = async () => {
  let locale = ReduxCache.getLanguage()
  if (!locale) {
    locale = 'al'
  }

  return {
    'Content-Type': 'application/json',
    'Accept-Language': locale,
    'vf-country-code': locale,
    'vf-source': 'selfcare',
    'vf-operator': 'love_box'
  }
}

const getServiceBalanceHeaders = async () => {
  let locale = ReduxCache.getLanguage()
  if (!locale) {
    locale = 'al'
  }

  return {
    'Content-Type': 'application/json',
    'Accept-Language': locale,
    'X-Source-CountryCode': locale,
    'X-Source-System': 'selfcare',
    'X-Source-Operator': 'myvodafone'
  }
}

const getTransferVCoinsHeaders = async () => {
  let locale = ReduxCache.getLanguage()
  if (!locale) {
    locale = 'al'
  }

  return {
    'Content-Type': 'application/json',
    'Accept-Language': locale,
    'vf-country-code': locale,
    'vf-source': 'selfcare',
    'vf-operator': 'reward'
  }
}


const getRefillHeaders = async () => {
  let locale = ReduxCache.getLanguage()
  if (!locale) {
    locale = 'al'
  }

  return {
    'Content-Type': 'application/json',
    'Accept-Language': locale,
    'X-Source-CountryCode': locale,
    'X-Source-System': 'selfcare',
    'X-Source-Operator': 'refill'
  }
}

const getHeaders = async () => {
  let locale = ReduxCache.getLanguage()
  if (!locale) {
    locale = 'al'
  }

  return {
    'X-Source-System': headersConfig.sourceSystem,
    'Content-Type': 'application/json',
    'X-Source-Operator': headersConfig.sourceOperator,
    'X-Source-CountryCode': locale,
    'Accept-Language': locale
  }
}

const getRedeemHeaders = async () => {
  let locale = ReduxCache.getLanguage()
  if (!locale) {
    locale = 'al'
  }

  return {
    'vf-source': 'selfcare',
    'Content-Type': 'application/json',
    'vf-operator': headersConfig.sourceOperator,
    'vf-country-code': locale,
    'Accept-Language': locale
  }
}

const getProfileHeaders = async () => {
  const locale = ReduxCache.getLanguage()

  return {
    'vf-source': 'selfcare',
    'Content-Type': 'application/json',
    'vf-operator': headersConfig.sourceOperator,
    'vf-country-code': locale,
    'Accept-Language': locale,
    'Content-Length': 0
  }
}

const getUrl = async () => {
  const endpoint = 'https://api-mobile-ci-vfal.vodafone.com/auth/'

  return {
    endpoint: endpoint,
    qrEndpoint: endpoint + 'documentAPI/v2/document',
    apiEndpoint: endpoint + 'paymentVoucherAPI/paymentVoucher/search',
    favoritesEndpoint: endpoint + 'paymentVoucherAPI/paymentVoucher/',
    redeemEndpoint:
      endpoint + 'processPaymentVoucherAPI/v2/processPaymentVoucher/redeem',
    preferencesEndpoint:
      endpoint + 'customerPreference/v2/customerPreference/search',
    sendPreferencesEndpoint:
      endpoint + 'customerPreference/v2/customerPreference/PREFERENCES_KEY',
    historyEndpoint:
      endpoint + 'loyaltyAccountAdjustment/v2/loyaltyAccountAdjustment',
    profileEndpoint: endpoint + 'loyaltyAccountAPI/v2/loyaltyAccount/',
    splashEndpoint:
      'https://api-mobile-ci-vfal.vodafone.com/enterprise-resources/public/technical/configurations?version=4.3.0',
    refillProducts: endpoint + 'productOfferingAPI/v2/productOffering',
    refillHistory: endpoint + 'salesOrderAPI/v2/salesOrder/search',
    refillActivate: endpoint + 'salesOrderAPI/v2/salesOrder',
    transferVCoins: endpoint + 'serviceAccountAdjustmentAPI/v2/serviceAccountAdjustment',
    loveBoxServiceBalance: 'https://api-mobile-ci-vfal.vodafone.com/enterprise-resources/public/service/service-balances/',
    lbRedeem: 'https://api-mobile-ci-vfal.vodafone.com/auth/processPaymentVoucherAPI/v2/processPaymentVoucher/redeem',
    redeemMagicEndpoint:
      endpoint +
      'processPaymentVoucherAPI/v2/processPaymentVoucher/redeem'
  }
}

const queryConfig = {
  type: 'reward',
  channelId: 'selfcare'
}

const headers = {
  'X-Source-System': headersConfig.sourceSystem,
  'X-Source-Operator': headersConfig.sourceOperator,
  'X-Source-CountryCode': headersConfig.sourceCountryCode,
  'Accept-Language': headersConfig.acceptLanguage,
  system: headersConfig.system,
  operator: headersConfig.operator
}



export const APP_VERSION = '7.0.5'

export const MAPS_API_KEY = 'AIzaSyDgZm5OUSqiyK7fEstVVKhQCtQvkEvXjZo'


export default {
  getUrl,
  headersConfig,
  headers,
  queryConfig,
  getRedeemHeaders,
  getHeaders,
  getProfileHeaders,
  getRefillHeaders,
  getTransferVCoinsHeaders,
  getServiceBalanceHeaders,
  getLoveBoxRedeemHeaders,
  getUserProfileHeaders,
  getAllItemsHeaders,
  getLbRedeemHeaders,
  APP_VERSION,
  MAPS_API_KEY,
}
