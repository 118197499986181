/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react'
import { Platform, LayoutAnimation } from 'react-native'
import PropTypes from 'prop-types'
import { Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { PurchaseCoverage } from '@vfal-lab/rn-vfg-renewals-fix/Screens'

import { useDispatch, useSelector } from 'react-redux'

import axios from 'axios'

import { getAddress } from '@vfal-lab/rn-vfg-unlimited-home/Services'

import NavigationService from '../../Navigation/NavigationService'

import { MAPS_API_KEY } from '../../Config'

import { getCoveredAreas } from '../../Services/Unlimited'

import { locationResponseMapper } from '../../Services/Unlimited/Mappers'

import { actions } from './FixPurchaseCoverage.Actions'


const ADDRESS_TYPES = ['locality', 'administrative_area_level_1']
const STREET_TYPES = ['route']
const COUNTRY_TYPES = ['country']

function PurchaseCoverageScreen({ isVisible, toggleModal }) {

  const dispatch = useDispatch()

  const inputRef = React.useRef()


  const geographicAddress = useSelector(state => state.geographicAddress.data || [])
  const geographicAddressLoading = useSelector(state => state.geographicAddress.loading || false)
  const validationAddressLoading = useSelector(state => state.geographicAddressValidation.loading || false)

  const [streetAddress, setStreetAddress] = useState('')

  const [city, setCity] = useState()
  const [coordinates, setCoordinates] = useState()
  const [currentCoordinates, setCurrentCoordinates] = useState(null)

  const [successSearch, setSuccessSearch] = useState(false)
  const [isErrorSearch, setIsErrorSearch] = useState(false)

  const [serviceAreas, setServiceAreas] = useState([])
  const [areItemsFetching, setAreItemsFetching] = useState(false)

  const [streetName, setStreetName] = useState()

  const GetGeographicAddress = (city, utilities) => {
    return dispatch(actions.getGeographicAddress(city, utilities))
  }
  const ValidateGeographicAddress = (coords, utilities) => {
    return dispatch(actions.geographicAddressValidation(coords, utilities))
  }

  // const resetStatusBar = () => {
  //   if (Platform.OS === 'android') {
  //     StatusBar.setBarStyle('light-content', true)
  //     StatusBar.setBackgroundColor(Colors.thinDark, true)
  //     StatusBar.setTranslucent(false)
  //   } else if (Platform.OS === 'ios') {
  //     StatusBar.setBarStyle('dark-content', true)
  //   }
  // }

  const [isContentLoading, setContentLoading] = useState(false)

  // useLayoutEffect(() => {
  //   resetStatusBar()
  // }, [])

  const onSearch = async (coords, locationAddress = false) => {
    setAreItemsFetching(true)
    setSuccessSearch(false)
    setIsErrorSearch(false)
    ValidateGeographicAddress({
      longitude: coords.latLng?.lng(),
      latitude: coords.latLng?.lat()
    }, {
      onSuccess: (res) => {
        LayoutAnimation.configureNext({
          duration: 300,
          create:
          {
            type: LayoutAnimation.Types.easeInEaseOut,
            property: LayoutAnimation.Properties.opacity,
          },
          update:
          {
            type: LayoutAnimation.Types.easeInEaseOut,
          }
        })
        if (res?.validationResult !== 'success') {
          setAreItemsFetching(false)
          setSuccessSearch(false)
          setIsErrorSearch(true)
        } else {
          // setServiceAreas(res?.submittedGeographicAddress?.geographicLocation?.geometry)
          setSuccessSearch(true)
          setIsErrorSearch(false)
          setAreItemsFetching(false)
        }

      },
      onError: (e) => {
        LayoutAnimation.configureNext({
          duration: 300,
          create:
          {
            type: LayoutAnimation.Types.easeInEaseOut,
            property: LayoutAnimation.Properties.opacity,
          },
          update:
          {
            type: LayoutAnimation.Types.easeInEaseOut,
          }
        })
        setSuccessSearch(false)
        setIsErrorSearch(true)
        setAreItemsFetching(false)
      },
    })
  }

  const updateNames = (type = '') => {
    setContentLoading(true)
    setStreetAddress('')
    const coords = type === 'current' ? currentCoordinates : coordinates
    console.log('🚀 ~ file: FixPurchaseCoverage.js:134 ~ updateNames ~ coords:', coords)
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords}&key=+${MAPS_API_KEY}`,
      )
      .then(res => {
        const result = res.data.results[0].address_components || []
        const streetName = result
          .find(element => STREET_TYPES.includes(element.types[0]))
          ?.long_name?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .normalize('NFC')

        let cityName = result
          .find(element => ADDRESS_TYPES.includes(element.types[0]))
          ?.long_name?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .normalize('NFC')

        const countryName = result
          .find(element => COUNTRY_TYPES.includes(element.types[0]))
          ?.long_name?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .normalize('NFC')

        setStreetAddress(streetName || cityName)

        const street = streetName ? streetName + ',' : ''

        if (cityName === 'Tirane') {
          cityName = 'Tirana'
        }

        if (city !== cityName) {
          setCity(cityName)
        }

        if (type !== 'current') {
          inputRef?.current?.setValue(street + cityName + ',' + countryName)
          inputRef?.current?.blur()
        }

        dispatch(actions.geographicAddressUpdate(streetName || cityName))

        setContentLoading(false)
      })
      .catch(() => {
        setContentLoading(false)
      })
  }

  const goToSteps = () => {
    // resetStatusBar()
    toggleModal()
    NavigationService.navigate('FixRenewals', { isPurchase: true })
  }

  useEffect(() => {
    if (city) {
      GetGeographicAddress(city, {
        onSuccess: (res) => {
        },
        onError: (e) => {
        },
      })
    }
  }, [city])

  const _handleLocationChange = (data, coords) => {
    // if (data.city !== locationData.city) {
    GetGeographicAddress(data.city, {
      onSuccess: (res) => {
        setServiceAreas(res)
      },
      responseMapper: locationResponseMapper
    })
    ValidateGeographicAddress(coords, {
      onSuccess: (res) => {
        if (res?.validationResult !== 'success') {
          setAreItemsFetching(false)
          setSuccessSearch(false)
          setIsErrorSearch(true)
        } else {
          setSuccessSearch(true)
          setIsErrorSearch(false)
          setAreItemsFetching(false)
        }

      },
      onError: (e) => {
        setSuccessSearch(false)
        setIsErrorSearch(true)
        setAreItemsFetching(false)
      },
    })
    // }
  }

  const handleLocationChange = (data, apiKey = 'AIzaSyDgZm5OUSqiyK7fEstVVKhQCtQvkEvXjZo') => {
    getAddress({ coordinate: data, apiKey })
      .then(result => {
        _handleLocationChange(result.data, data)
        // onLocationChange({ ...data, ...result.data })
      })
      .catch(() => {
        // onLocationChange(data)
      })
  }


  useEffect(() => {
    if (coordinates) {
      updateNames()
    } else if (currentCoordinates) {
      updateNames('current')
    }
  }, [coordinates, currentCoordinates])

  return (
    <PurchaseCoverage
      geographicAddress={geographicAddress}
      geographicAddressLoading={geographicAddressLoading}
      isVisible={isVisible}
      toggleModal={toggleModal}
      isContentLoading={isContentLoading}
      validationAddressLoading={validationAddressLoading}
      // resetStatusBar={resetStatusBar}
      goToSteps={goToSteps}
      onSearch={handleLocationChange}
      locationState={{
        city: city,
        setCity: setCity,
        coordinates: coordinates,
        setCoordinates: setCoordinates,
        currentCoordinates: currentCoordinates,
        setCurrentCoordinates: setCurrentCoordinates,
        successSearch: successSearch,
        setSuccessSearch: setSuccessSearch,
        isErrorSearch: isErrorSearch,
        setIsErrorSearch: setIsErrorSearch
      }}
      API_KEY={MAPS_API_KEY}
      inputRef={inputRef}
      serviceAreas={serviceAreas}
      setServiceAreas={setServiceAreas}
      areItemsFetching={areItemsFetching}
      updateNames={updateNames}
      setAreItemsFetching={setAreItemsFetching}
      validateLocation={onSearch}
      streetName={streetName}
      setStreetName={setStreetName}
    />
  )
}
PurchaseCoverageScreen.propTypes = {
  isVisible: PropTypes.bool,
  toggleModal: PropTypes.func,
}

export default PurchaseCoverageScreen
