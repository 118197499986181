/* eslint-disable import/exports-last */

import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

/* eslint-disable prefer-const */
export const issuingCountryOptions = [{ id: 0, value: 'afghanistan', label: 'Afghanistan' },
{ id: 1, value: 'albania', label: 'Albania' },
{ id: 2, value: 'algeria', label: 'Algeria' },
{ id: 3, value: 'american samoa', label: 'American Samoa' },
{ id: 4, value: 'andorra', label: 'Andorra' },
{ id: 5, value: 'angola', label: 'Angola' },
{ id: 6, value: 'anguilla', label: 'Anguilla' },
{ id: 7, value: 'antarctica', label: 'Antarctica' },
{
  id: 8,
  value: 'antigua and barbuda',
  label: 'Antigua and Barbuda',
},
{ id: 9, value: 'argentina', label: 'Argentina' },
{ id: 10, value: 'armenia', label: 'Armenia' },
{ id: 11, value: 'aruba', label: 'Aruba' },
{ id: 12, value: 'australia', label: 'Australia' },
{ id: 13, value: 'austria', label: 'Austria' },
{ id: 14, value: 'azerbaijan', label: 'Azerbaijan' },
{ id: 15, value: 'bahamas (the)', label: 'Bahamas (the)' },
{ id: 16, value: 'bahrain', label: 'Bahrain' },
{ id: 17, value: 'bangladesh', label: 'Bangladesh' },
{ id: 18, value: 'barbados', label: 'Barbados' },
{ id: 19, value: 'belarus', label: 'Belarus' },
{ id: 20, value: 'belgium', label: 'Belgium' },
{ id: 21, value: 'belize', label: 'Belize' },
{ id: 22, value: 'benin', label: 'Benin' },
{ id: 23, value: 'bermuda', label: 'Bermuda' },
{ id: 24, value: 'bhutan', label: 'Bhutan' },
{
  id: 25,
  value: 'bolivia (plurinational state of)',
  label: 'Bolivia (Plurinational State of)',
},
{
  id: 26,
  value: 'bonaire, sint eustatius and saba',
  label: 'Bonaire, Sint Eustatius and Saba',
},
{
  id: 27,
  value: 'bosnia and herzegovina',
  label: 'Bosnia and Herzegovina',
},
{ id: 28, value: 'botswana', label: 'Botswana' },
{ id: 29, value: 'bouvet island', label: 'Bouvet Island' },
{ id: 30, value: 'brazil', label: 'Brazil' },
{
  id: 31,
  value: 'british indian ocean territory (the)',
  label: 'British Indian Ocean Territory (the)',
},
{
  id: 32,
  value: 'brunei darussalam',
  label: 'Brunei Darussalam',
},
{ id: 33, value: 'bulgaria', label: 'Bulgaria' },
{ id: 34, value: 'burkina faso', label: 'Burkina Faso' },
{ id: 35, value: 'burundi', label: 'Burundi' },
{ id: 36, value: 'cabo verde', label: 'Cabo Verde' },
{ id: 37, value: 'cambodia', label: 'Cambodia' },
{ id: 38, value: 'cameroon', label: 'Cameroon' },
{ id: 39, value: 'canada', label: 'Canada' },
{
  id: 40,
  value: 'cayman islands (the)',
  label: 'Cayman Islands (the)',
},
{
  id: 41,
  value: 'central african republic (the)',
  label: 'Central African Republic (the)',
},
{ id: 42, value: 'chad', label: 'Chad' },
{ id: 43, value: 'chile', label: 'Chile' },
{ id: 44, value: 'china', label: 'China' },
{ id: 45, value: 'christmas island', label: 'Christmas Island' },
{
  id: 46,
  value: 'cocos (keeling) islands (the)',
  label: 'Cocos (Keeling) Islands (the)',
},
{ id: 47, value: 'colombia', label: 'Colombia' },
{ id: 48, value: 'comoros (the)', label: 'Comoros (the)' },
{
  id: 49,
  value: 'congo (the democratic republic of the)',
  label: 'Congo (the Democratic Republic of the)',
},
{ id: 50, value: 'congo (the)', label: 'Congo (the)' },
{
  id: 51,
  value: 'cook islands (the)',
  label: 'Cook Islands (the)',
},
{ id: 52, value: 'costa rica', label: 'Costa Rica' },
{ id: 53, value: 'croatia', label: 'Croatia' },
{ id: 54, value: 'cuba', label: 'Cuba' },
{ id: 55, value: 'curaçao', label: 'Curaçao' },
{ id: 56, value: 'cyprus', label: 'Cyprus' },
{ id: 57, value: 'czechia', label: 'Czechia' },
{ id: 58, value: 'côte d\'ivoire', label: 'Côte d\'Ivoire' },
{ id: 59, value: 'denmark', label: 'Denmark' },
{ id: 60, value: 'djibouti', label: 'Djibouti' },
{ id: 61, value: 'dominica', label: 'Dominica' },
{
  id: 62,
  value: 'dominican republic (the)',
  label: 'Dominican Republic (the)',
},
{ id: 63, value: 'ecuador', label: 'Ecuador' },
{ id: 64, value: 'egypt', label: 'Egypt' },
{ id: 65, value: 'el salvador', label: 'El Salvador' },
{
  id: 66,
  value: 'equatorial guinea',
  label: 'Equatorial Guinea',
},
{ id: 67, value: 'eritrea', label: 'Eritrea' },
{ id: 68, value: 'estonia', label: 'Estonia' },
{ id: 69, value: 'eswatini', label: 'Eswatini' },
{ id: 70, value: 'ethiopia', label: 'Ethiopia' },
{
  id: 71,
  value: 'falkland islands (the) [malvinas]',
  label: 'Falkland Islands (the) [Malvinas]',
},
{
  id: 72,
  value: 'faroe islands (the)',
  label: 'Faroe Islands (the)',
},
{ id: 73, value: 'fiji', label: 'Fiji' },
{ id: 74, value: 'finland', label: 'Finland' },
{ id: 75, value: 'france', label: 'France' },
{ id: 76, value: 'french guiana', label: 'French Guiana' },
{ id: 77, value: 'french polynesia', label: 'French Polynesia' },
{
  id: 78,
  value: 'french southern territories (the)',
  label: 'French Southern Territories (the)',
},
{ id: 79, value: 'gabon', label: 'Gabon' },
{ id: 80, value: 'gambia (the)', label: 'Gambia (the)' },
{ id: 81, value: 'georgia', label: 'Georgia' },
{ id: 82, value: 'germany', label: 'Germany' },
{ id: 83, value: 'ghana', label: 'Ghana' },
{ id: 84, value: 'gibraltar', label: 'Gibraltar' },
{ id: 85, value: 'greece', label: 'Greece' },
{ id: 86, value: 'greenland', label: 'Greenland' },
{ id: 87, value: 'grenada', label: 'Grenada' },
{ id: 88, value: 'guadeloupe', label: 'Guadeloupe' },
{ id: 89, value: 'guam', label: 'Guam' },
{ id: 90, value: 'guatemala', label: 'Guatemala' },
{ id: 91, value: 'guernsey', label: 'Guernsey' },
{ id: 92, value: 'guinea', label: 'Guinea' },
{ id: 93, value: 'guinea-bissau', label: 'Guinea-Bissau' },
{ id: 94, value: 'guyana', label: 'Guyana' },
{ id: 95, value: 'haiti', label: 'Haiti' },
{
  id: 96,
  value: 'heard island and mcdonald islands',
  label: 'Heard Island and McDonald Islands',
},
{ id: 97, value: 'holy see (the)', label: 'Holy See (the)' },
{ id: 98, value: 'honduras', label: 'Honduras' },
{ id: 99, value: 'hong kong', label: 'Hong Kong' },
{ id: 0, value: 'hungary', label: 'Hungary' },
{ id: 1, value: 'iceland', label: 'Iceland' },
{ id: 2, value: 'india', label: 'India' },
{ id: 3, value: 'indonesia', label: 'Indonesia' },
{
  id: 4,
  value: 'iran (islamic republic of)',
  label: 'Iran (Islamic Republic of)',
},
{ id: 5, value: 'iraq', label: 'Iraq' },
{ id: 6, value: 'ireland', label: 'Ireland' },
{ id: 7, value: 'isle of man', label: 'Isle of Man' },
{ id: 8, value: 'israel', label: 'Israel' },
{ id: 9, value: 'italy', label: 'Italy' },
{ id: 10, value: 'jamaica', label: 'Jamaica' },
{ id: 11, value: 'japan', label: 'Japan' },
{ id: 12, value: 'jersey', label: 'Jersey' },
{ id: 13, value: 'jordan', label: 'Jordan' },
{ id: 14, value: 'kazakhstan', label: 'Kazakhstan' },
{ id: 15, value: 'kenya', label: 'Kenya' },
{ id: 16, value: 'kiribati', label: 'Kiribati' },
{
  id: 17,
  value: 'korea (the democratic people\'s republic of)',
  label: 'Korea (the Democratic People\'s Republic of)',
},
{
  id: 18,
  value: 'korea (the republic of)',
  label: 'Korea (the Republic of)',
},
{ id: 19, value: 'kuwait', label: 'Kuwait' },
{ id: 20, value: 'kyrgyzstan', label: 'Kyrgyzstan' },
{
  id: 21,
  value: 'lao people\'s democratic republic (the)',
  label: 'Lao People\'s Democratic Republic (the)',
},
{ id: 22, value: 'latvia', label: 'Latvia' },
{ id: 23, value: 'lebanon', label: 'Lebanon' },
{ id: 24, value: 'lesotho', label: 'Lesotho' },
{ id: 25, value: 'liberia', label: 'Liberia' },
{ id: 26, value: 'libya', label: 'Libya' },
{ id: 27, value: 'liechtenstein', label: 'Liechtenstein' },
{ id: 28, value: 'lithuania', label: 'Lithuania' },
{ id: 29, value: 'luxembourg', label: 'Luxembourg' },
{ id: 30, value: 'macao', label: 'Macao' },
{ id: 31, value: 'madagascar', label: 'Madagascar' },
{ id: 32, value: 'malawi', label: 'Malawi' },
{ id: 33, value: 'malaysia', label: 'Malaysia' },
{ id: 34, value: 'maldives', label: 'Maldives' },
{ id: 35, value: 'mali', label: 'Mali' },
{ id: 36, value: 'malta', label: 'Malta' },
{
  id: 37,
  value: 'marshall islands (the)',
  label: 'Marshall Islands (the)',
},
{ id: 38, value: 'martinique', label: 'Martinique' },
{ id: 39, value: 'mauritania', label: 'Mauritania' },
{ id: 40, value: 'mauritius', label: 'Mauritius' },
{ id: 41, value: 'mayotte', label: 'Mayotte' },
{ id: 42, value: 'mexico', label: 'Mexico' },
{
  id: 43,
  value: 'micronesia (federated states of)',
  label: 'Micronesia (Federated States of)',
},
{
  id: 44,
  value: 'moldova (the republic of)',
  label: 'Moldova (the Republic of)',
},
{ id: 45, value: 'monaco', label: 'Monaco' },
{ id: 46, value: 'mongolia', label: 'Mongolia' },
{ id: 47, value: 'montenegro', label: 'Montenegro' },
{ id: 48, value: 'montserrat', label: 'Montserrat' },
{ id: 49, value: 'morocco', label: 'Morocco' },
{ id: 50, value: 'mozambique', label: 'Mozambique' },
{ id: 51, value: 'myanmar', label: 'Myanmar' },
{ id: 52, value: 'namibia', label: 'Namibia' },
{ id: 53, value: 'nauru', label: 'Nauru' },
{ id: 54, value: 'nepal', label: 'Nepal' },
{
  id: 55,
  value: 'netherlands (the)',
  label: 'Netherlands (the)',
},
{ id: 56, value: 'new caledonia', label: 'New Caledonia' },
{ id: 57, value: 'new zealand', label: 'New Zealand' },
{ id: 58, value: 'nicaragua', label: 'Nicaragua' },
{ id: 59, value: 'niger (the)', label: 'Niger (the)' },
{ id: 60, value: 'nigeria', label: 'Nigeria' },
{ id: 61, value: 'niue', label: 'Niue' },
{ id: 62, value: 'norfolk island', label: 'Norfolk Island' },
{
  id: 63,
  value: 'northern mariana islands (the)',
  label: 'Northern Mariana Islands (the)',
},
{ id: 64, value: 'norway', label: 'Norway' },
{ id: 65, value: 'oman', label: 'Oman' },
{ id: 66, value: 'pakistan', label: 'Pakistan' },
{ id: 67, value: 'palau', label: 'Palau' },
{
  id: 68,
  value: 'palestine, state of',
  label: 'Palestine, State of',
},
{ id: 69, value: 'panama', label: 'Panama' },
{ id: 70, value: 'papua new guinea', label: 'Papua New Guinea' },
{ id: 71, value: 'paraguay', label: 'Paraguay' },
{ id: 72, value: 'peru', label: 'Peru' },
{
  id: 73,
  value: 'philippines (the)',
  label: 'Philippines (the)',
},
{ id: 74, value: 'pitcairn', label: 'Pitcairn' },
{ id: 75, value: 'poland', label: 'Poland' },
{ id: 76, value: 'portugal', label: 'Portugal' },
{ id: 77, value: 'puerto rico', label: 'Puerto Rico' },
{ id: 78, value: 'qatar', label: 'Qatar' },
{
  id: 79,
  value: 'republic of north macedonia',
  label: 'Republic of North Macedonia',
},
{ id: 80, value: 'romania', label: 'Romania' },
{
  id: 81,
  value: 'russian federation (the)',
  label: 'Russian Federation (the)',
},
{ id: 82, value: 'rwanda', label: 'Rwanda' },
{ id: 83, value: 'réunion', label: 'Réunion' },
{ id: 84, value: 'saint barthélemy', label: 'Saint Barthélemy' },
{
  id: 85,
  value: 'saint helena, ascension and tristan da cunha',
  label: 'Saint Helena, Ascension and Tristan da Cunha',
},
{
  id: 86,
  value: 'saint kitts and nevis',
  label: 'Saint Kitts and Nevis',
},
{ id: 87, value: 'saint lucia', label: 'Saint Lucia' },
{
  id: 88,
  value: 'saint martin (french part)',
  label: 'Saint Martin (French part)',
},
{
  id: 89,
  value: 'saint pierre and miquelon',
  label: 'Saint Pierre and Miquelon',
},
{
  id: 90,
  value: 'saint vincent and the grenadines',
  label: 'Saint Vincent and the Grenadines',
},
{ id: 91, value: 'samoa', label: 'Samoa' },
{ id: 92, value: 'san marino', label: 'San Marino' },
{
  id: 93,
  value: 'sao tome and principe',
  label: 'Sao Tome and Principe',
},
{ id: 94, value: 'saudi arabia', label: 'Saudi Arabia' },
{ id: 95, value: 'senegal', label: 'Senegal' },
{ id: 96, value: 'serbia', label: 'Serbia' },
{ id: 97, value: 'seychelles', label: 'Seychelles' },
{ id: 98, value: 'sierra leone', label: 'Sierra Leone' },
{ id: 99, value: 'singapore', label: 'Singapore' },
{
  id: 0,
  value: 'sint maarten (dutch part)',
  label: 'Sint Maarten (Dutch part)',
},
{ id: 1, value: 'slovakia', label: 'Slovakia' },
{ id: 2, value: 'slovenia', label: 'Slovenia' },
{ id: 3, value: 'solomon islands', label: 'Solomon Islands' },
{ id: 4, value: 'somalia', label: 'Somalia' },
{ id: 5, value: 'south africa', label: 'South Africa' },
{
  id: 6,
  value: 'south georgia and the south sandwich islands',
  label: 'South Georgia and the South Sandwich Islands',
},
{ id: 7, value: 'south sudan', label: 'South Sudan' },
{ id: 8, value: 'spain', label: 'Spain' },
{ id: 9, value: 'sri lanka', label: 'Sri Lanka' },
{ id: 10, value: 'sudan (the)', label: 'Sudan (the)' },
{ id: 11, value: 'suriname', label: 'Suriname' },
{
  id: 12,
  value: 'svalbard and jan mayen',
  label: 'Svalbard and Jan Mayen',
},
{ id: 13, value: 'sweden', label: 'Sweden' },
{ id: 14, value: 'switzerland', label: 'Switzerland' },
{
  id: 15,
  value: 'syrian arab republic',
  label: 'Syrian Arab Republic',
},
{ id: 16, value: 'taiwan', label: 'Taiwan' },
{ id: 17, value: 'tajikistan', label: 'Tajikistan' },
{
  id: 18,
  value: 'tanzania, united republic of',
  label: 'Tanzania, United Republic of',
},
{ id: 19, value: 'thailand', label: 'Thailand' },
{ id: 20, value: 'timor-leste', label: 'Timor-Leste' },
{ id: 21, value: 'togo', label: 'Togo' },
{ id: 22, value: 'tokelau', label: 'Tokelau' },
{ id: 23, value: 'tonga', label: 'Tonga' },
{
  id: 24,
  value: 'trinidad and tobago',
  label: 'Trinidad and Tobago',
},
{ id: 25, value: 'tunisia', label: 'Tunisia' },
{ id: 26, value: 'turkey', label: 'Turkey' },
{ id: 27, value: 'turkmenistan', label: 'Turkmenistan' },
{
  id: 28,
  value: 'turks and caicos islands (the)',
  label: 'Turks and Caicos Islands (the)',
}]


const getYearOptions = () => {
  let currentYear = new Date().getFullYear()
  let tenYearsFromNow = new Date().getFullYear() + 10
  const years = []
  while (currentYear <= tenYearsFromNow) {
    years.push(currentYear++)
  }
  const mappedYears = []

  years.map((i, _i) => {
    mappedYears.push({
      id: _i,
      value: i.toString(),
      label: i.toString(),
    })
  })
  return mappedYears.reverse()
}


export const yearsOptions = getYearOptions()

export const monthOptions = [{ id: 0, value: '01', label: ContentManager.translate('january') },
{ id: 1, value: '02', label: ContentManager.translate('february') },
{ id: 2, value: '03', label: ContentManager.translate('march') },
{ id: 3, value: '04', label: ContentManager.translate('april') },
{ id: 4, value: '05', label: ContentManager.translate('may') },
{ id: 5, value: '06', label: ContentManager.translate('june') },
{ id: 6, value: '07', label: ContentManager.translate('july') },
{ id: 7, value: '08', label: ContentManager.translate('august') },
{ id: 8, value: '09', label: ContentManager.translate('september') },
{ id: 9, value: '10', label: ContentManager.translate('october') },
{ id: 10, value: '11', label: ContentManager.translate('november') },
{ id: 11, value: '12', label: ContentManager.translate('december') }]


