/* eslint-disable react/prop-types */
/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react'
import {
  View,
  Image,
  ScrollView,
  SafeAreaView,
  TouchableOpacity,
  ImageBackground,
  Platform,
  Dimensions,
} from 'react-native'
import { connect } from 'react-redux'
import { VFScreen } from '@vfgroup-oneplatform/foundation/Components'

import Loader from '../../Components/Loader'
import VfText from '../../Components/VfText'
import VfHeartsCollRowItem from '../../Components/VfHeartsCollRowItem'
import { __translate } from '../../Utils'

import ReduxCache from '../../Utils/ReduxCache'

import { actions } from './VfHeartsCollection.actions'
import Images from '../../Themes/Images'
import styles from './VfHeartsCollection.styles'


const LANGUAGE_AL = 'al'

class VfHeartsCollectionComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isActivate: false,
      activeItem: '',
      language: 'al',
    }
  }
  componentDidMount = () => {
    // this.props.getUserProfile();
    // this.props.getAllItems();
    this.getlanguage()

    this._sub = this.props.navigation.addListener(
      'blur',
      this._handleFocusOut,
    )
  };

  componentWillUnmount = () => {
    this._sub()
  }

  getlanguage = async function () {
    const language = ReduxCache.getLanguage()
    if (language) {
      this.setState({
        language: language,
      })
    }
  };

  getActiveItem = () => {
    const { allLoveBoxItems } = this.props
    if (allLoveBoxItems.data) {
      return allLoveBoxItems.data.find(obj => obj.id === this.state.activeItem)
    }
  };

  getContent = () => {
    const { activateInfo, deactivateInfo } = this.props
    const { userState, allLoveBoxItems, redeemLbItem, infoState } = this.props
    const activeItem = this.getActiveItem()
    if (infoState.isActive) {
      return (
        <>
          <View style={[styles.contentContainer, styles.spaceBetween]}>
            <TouchableOpacity onPress={() => deactivateInfo()} style={{ marginRight: 20, marginTop: 20 }}>
              <Image
                source={Images.close_white}
                style={[styles.info, { alignSelf: 'flex-end' }]}
                resizeMode="contain"
              />
            </TouchableOpacity>
            <View style={styles.infoContainer}>
              <View style={styles.rowCenter}>
                <VfText size="h2" weight="bold" style={{ margin: 0 }}>
                  {__translate('heart_collection_info_tittle')}
                </VfText>
              </View>
              {Platform.OS === 'web' && Dimensions.get('window').width > 700 ? null : <TouchableOpacity onPress={() => this.closeInfo(infoState)}>
                <Image
                  source={Images.lb_close}
                  style={styles.close}
                  resizeMode="contain"
                />
              </TouchableOpacity>}
            </View>
            <VfText size="h3" style={{ marginHorizontal: 16, lineHeight: 32 }}>
              {__translate('heart_collection_info_subtittle')}
            </VfText>
            <ScrollView
              ref={this.infoScroll}
              onLayout={() => this.infoScroll.current.flashScrollIndicators()}
              style={styles.scrollView}
              indicatorStyle="black">
              <VfText
                size="h5"
                weight="light"
                style={{ marginHorizontal: 0, lineHeight: 30 }}>
                {__translate('heart_collection_info')}
              </VfText>
            </ScrollView>
          </View>
        </>
      )
    } else if (redeemLbItem.loading || userState.loading || allLoveBoxItems.loading) {
      return (
        <>
          <View style={styles.infoContainer}>
            <TouchableOpacity onPress={() => activateInfo()}>
              <Image
                source={Images.info}
                style={styles.info}
                resizeMode="contain"
              />
            </TouchableOpacity>
            {Platform.OS === 'web' && Dimensions.get('window').width > 700 ? null : <TouchableOpacity onPress={() => this.props.navigation.goBack()}>
              <Image
                source={Images.lb_close}
                style={styles.close}
                resizeMode="contain"
              />
            </TouchableOpacity>}
          </View>
          <View style={[styles.contentContainer, styles.centerContent]}>
            <Loader />
          </View>
        </>
      )
    } else if (
      userState.error ||
      !userState.data ||
      allLoveBoxItems.error ||
      !allLoveBoxItems.data
    ) {
      return (
        <>
          <View style={styles.infoContainer}>
            <TouchableOpacity onPress={() => activateInfo()}>
              <Image
                source={Images.info}
                style={styles.info}
                resizeMode="contain"
              />
            </TouchableOpacity>
            {Platform.OS === 'web' && Dimensions.get('window').width > 700 ? null : <TouchableOpacity onPress={() => this.props.navigation.goBack()}>
              <Image
                source={Images.lb_close}
                style={styles.close}
                resizeMode="contain"
              />
            </TouchableOpacity>}
          </View>
          <View style={styles.contentContainer}>
            <VfText
              size="h2"
              weight="bold"
              style={{ margin: 32, textAlign: 'center' }}>
              {__translate('love_box_error_msg_2')}
            </VfText>
            <Image
              source={Images.fl_error_hearts}
              style={[
                styles.success_error_image,
                styles.error_image,
                { alignSelf: 'center' },
              ]}
              resizeMode="contain"
            />
            <VfText
              size="h5"
              align="center"
              style={{ marginTop: 32, marginBottom: 0, paddingHorizontal: 25 }}>
              {__translate('lovebox_error2')}
            </VfText>
          </View>
        </>
      )
    }
    // else if (this.state.isSuccess) {
    //   return (
    //     <>
    //       <View style={styles.infoContainer}>
    //         <TouchableOpacity onPress={() => activateInfo()}>
    //           <Image
    //             source={Images.info}
    //             style={styles.info}
    //             resizeMode="contain"
    //           />
    //         </TouchableOpacity>
    //       </View>
    //       <View style={styles.contentContainer}>
    //         <VfText size="h2" weight="bold" style={{margin: 32}}>
    //           WOW!
    //         </VfText>
    //         <Image
    //           source={Images.lb_success_hearts}
    //           style={[styles.success_error_image, styles.success_image]}
    //           resizeMode="contain"
    //         />
    //         <VfText
    //           size="h5"
    //           align="center"
    //           style={{marginTop: 32, marginBottom: 0}}>
    //           You won a Heart and
    //         </VfText>
    //         <VfText size="h5" weight="bold" align="center">
    //           XXX
    //           <VfText size="h5" style={{marginBottom: 32, marginTop: 0}}>
    //             {' '}
    //             for{' '}
    //           </VfText>
    //           XXX!
    //         </VfText>
    //       </View>
    //     </>
    //   );
    // }
    else if (this.state.isActivate) {
      return (
        <>
          <View style={[styles.contentContainer, styles.spaceBetween]}>
            <View style={styles.infoContainer}>
              <VfText style={{ margin: 0 }} />
              {Platform.OS === 'web' && Dimensions.get('window').width > 700 ? null : <TouchableOpacity
                onPress={() => this.setState({ isActivate: false })}>
                <Image
                  source={Images.lb_close}
                  style={styles.close}
                  resizeMode="contain"
                />
              </TouchableOpacity>}
            </View>
            <View>
              <View>
                {this.state.language === LANGUAGE_AL ? (
                  <VfText
                    size="h4"
                    align="center"
                    style={{
                      marginBottom: 32,
                      marginTop: 0,
                      marginHorizontal: 64,
                    }}>
                    {__translate('heart_collection_msg_1')}
                    <VfText size="h4" align="center" weight="bold" style={{}}>
                      {activeItem.price +
                        ' ' +
                        __translate('heart_collection_msg_3') +
                        ' '}
                    </VfText>
                    {__translate('heart_collection_msg_2') + ' '}
                    <VfText size="h4" align="center" weight="bold" style={{}}>
                      {activeItem.name}
                    </VfText>
                    ?
                  </VfText>
                ) : (
                  <VfText
                    size="h4"
                    align="center"
                    style={{
                      marginBottom: 32,
                      marginTop: 0,
                      marginHorizontal: 64,
                    }}>
                    {__translate('heart_collection_msg_1') + ' '}
                    <VfText size="h4" align="center" weight="bold" style={{}}>
                      {activeItem.name + ' '}
                    </VfText>
                    {__translate('heart_collection_msg_2') + ' '}
                    <VfText size="h4" align="center" weight="bold" style={{}}>
                      {activeItem.price +
                        ' ' +
                        __translate('heart_collection_msg_3')}
                    </VfText>
                    ?
                  </VfText>
                )}
              </View>
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 16,
                }}>
                <ImageBackground
                  source={Images.fl_activate_flower}
                  resizeMode="contain"
                  imageStyle={{
                    width: '200%',
                    height: '200%',
                    left: '-50%',
                    top: '-45%',
                  }}
                  style={{
                    width: 100,
                    height: 100,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <VfText
                    size="h2"
                    weight="bold"
                    align="center"
                    style={styles.heartText}>
                    {activeItem.name}
                  </VfText>
                </ImageBackground>
              </View>
            </View>
            <View style={styles.buttonsContainer}>
              <TouchableOpacity
                onPress={() => this.redeemProduct()}
                style={styles.button}>
                <VfText style={styles.buttonText}>
                  {__translate('lovebox_yes_button')}
                </VfText>
              </TouchableOpacity>
            </View>
          </View>
        </>
      )
    } else {
      let price = ''

      try {
        price = allLoveBoxItems.data[allLoveBoxItems.data.length - 1].price
      } catch (e) {
        price = ''
      }

      return (
        <>
          <View style={styles.infoContainer}>
            <View style={styles.rowCenter}>
              <TouchableOpacity onPress={() => activateInfo()}>
                <Image
                  source={Images.info}
                  style={styles.info}
                  resizeMode="contain"
                />
              </TouchableOpacity>
              <VfText style={{ margin: 0, marginLeft: 8 }}>
                {__translate('heart_collection') + ' '}
                <VfText weight="bold">
                  {userState.data.coins + ' '}
                  {__translate('heart_collection_msg_3') + ' '}
                </VfText>
                {__translate('heart_collection_2') + ''}
                <VfText weight="bold">{price}</VfText>
              </VfText>
            </View>
            {Platform.OS === 'web' && Dimensions.get('window').width > 700 ? null : <TouchableOpacity onPress={() => this.props.navigation.goBack()}>
              <Image
                source={Images.lb_close}
                style={styles.close}
                resizeMode="contain"
              />
            </TouchableOpacity>}
          </View>
          <View style={styles.contentContainer}>
            <VfHeartsCollRowItem
              hearts={userState.data.coins}
              items={allLoveBoxItems.data}
              onPress={id => this.setState({ isActivate: true, activeItem: id })}
            />
          </View>
        </>
      )
    }
  };

  getBgImage = () => {
    const { infoState, allLoveBoxItems } = this.props
    if (infoState.isActive || this.state.isActivate) {
      return Images.fl_redeem_flowers_bg
    } else if (allLoveBoxItems.error) {
      return Images.fl_error
    }
    return Images.fl_hearts_collection_bg
  };

  infoScroll = React.createRef();

  _handleFocusOut = () => {
    setTimeout(() => {
      this.props.deactivateInfo()
    }, 200)
  };

  closeInfo = infoState => {
    if (infoState.goBack) {
      this.props.navigation.goBack()

      setTimeout(() => {
        this.props.deactivateInfo()
      }, 200)
    } else {
      this.props.deactivateInfo()
    }
  };

  redeemProduct() {
    const { plainLoveBoxProducts } = this.props
    this.props.redeemProduct(plainLoveBoxProducts[this.state.activeItem])
  }

  onClose = () => {
    this.props.navigation.pop(2)
  }

  onBack = () => {
    this.props.navigation.pop()
  }

  render() {
    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
      return (
        <VFScreen
          title={'flower_landing_title'}
          onClose={this.onClose}
          onBack={this.onBack}
          showBack={true}
          showClose={false}
          contentContainerStyle={{
            height: 'auto'
          }}
        >
          <View style={styles.safeArea}>
            <View style={styles.container}>
              <View style={styles.innerContainer}>
                <ImageBackground
                  source={this.getBgImage()}
                  resizeMode="cover"
                  style={styles.imgBg}>
                  {this.getContent()}
                </ImageBackground>
              </View>
            </View>
          </View>
        </VFScreen>
      )
    }
    return (
      <VFScreen
        headerStyle={{ backgroundColor: '#fff' }}
        title={__translate('flower_landing_title')}
        onClose={this.onClose}
        onBack={this.onBack}
        showBack={true}
        contentContainerStyle={{
          height: 'auto'
        }}
      >
        <SafeAreaView style={styles.safeArea}>
          <View style={styles.container}>
            <View style={styles.innerContainer}>
              <ImageBackground
                source={this.getBgImage()}
                resizeMode="cover"
                style={styles.imgBg}>
                {this.getContent()}
              </ImageBackground>
            </View>
          </View>
        </SafeAreaView>
      </VFScreen>
    )
  }
}

VfHeartsCollectionComponent.defaultProps = {}

function mapDispatchToProps(dispatch) {
  return {
    getUserProfile: () => dispatch(actions.getUserProfile()),
    setInitialUser: user => dispatch(actions.setInitialUser(user)),
    getAllItems: () => dispatch(actions.getAllItems()),
    redeemProduct: (product, shouldReset) =>
      dispatch(actions.redeemProduct(product, shouldReset)),
    resetRedeem: () => dispatch(actions.resetRedeem()),
    activateInfo: () => dispatch(actions.activateInfo()),
    deactivateInfo: () => dispatch(actions.deactivateInfo()),
  }
}

const mapStateToProps = state => {
  const {
    userState,
    allLoveBoxItems,
    plainLoveBoxProducts,
    redeemLbItem,
    infoState,
  } = state
  return {
    userState,
    allLoveBoxItems,
    plainLoveBoxProducts,
    redeemLbItem,
    infoState,
  }
}

const VfHeartsCollection = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VfHeartsCollectionComponent)

export default VfHeartsCollection
