
import React, { useState, useEffect } from 'react'
import { View, Dimensions } from 'react-native'
import PropTypes from 'prop-types'
import moment from 'moment'

import { VFText } from '@vfgroup-oneplatform/foundation/Components'
import styles from './CountDown.Styles'

const DEFAULT_COUNTDOWN = {
  days: '00',
  hours: '00',
  minutes: '00',
}


const getTime = (to, format) => {
  if (!to || !format) {
    return DEFAULT_COUNTDOWN
  }

  const momentTime = moment(to, format).unix()
  const currentTime = moment().unix()

  const differenceTime = momentTime - currentTime
  const duration = moment.duration(differenceTime * 1000, 'milliseconds')

  return {
    days: duration.days() <= 0 ? '00' : (Math.floor(duration.asDays()) + '').padStart(2, '0'),
    hours: duration.hours() <= 0 ? '00' : (duration.hours() + '').padStart(2, '0'),
    minutes: duration.minutes() <= 0 ? '00' : (duration.minutes() + '').padStart(2, '0'),
  }
}

function Countdown({ to, format }) {
  const [count, setCount] = useState(() => getTime(to, format))


  useEffect(() => {
    const interval = setInterval(() => {
      setCount(() => getTime(to, format))
    }, 60000)
    return () => {
      clearInterval(interval)
    }
  }, [format, to])

  return (
    <View style={styles.container}>
      <View style={styles.sectionCentered}>
        <VFText
          i18nKey={count.days}
          style={styles.label}
        />
        <VFText
          i18nKey="dashboard_promo_label_days"
          style={styles.smallLabel}
        />
      </View>

      <VFText
        i18nKey={":"}
        style={styles.colon}
      />

      <View style={styles.sectionCentered}>
        <VFText
          i18nKey={count.hours}
          style={styles.label}
        />
        <VFText
          i18nKey="dashboard_promo_label_hours"
          style={styles.smallLabel}
        />
      </View>

      <VFText
        i18nKey={":"}
        style={styles.colon}
      />

      <View style={styles.sectionCentered}>
        <VFText
          i18nKey={count.minutes}
          style={styles.label}
        />
        <VFText
          i18nKey="dashboard_promo_label_min"
          style={styles.smallLabel}
        />
      </View>
    </View>
  )
}

Countdown.defaultProps = {

}

Countdown.propTypes = {
  to: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
}

export default Countdown
