import { NativeModules } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'

const getLocalToken = async () => {
  try {
    let user = await AsyncStorage.getItem('user')
    user = JSON.parse(user)
    const token = user.token
    return token
  } catch (e) {
    console.error('Something went wrong when reading local storage' + e)
  }
}

const getLocal = async key => {
  try {
    let user = await AsyncStorage.getItem('user')
    user = JSON.parse(user)

    const item = user[key]
    return item
  } catch (e) {
    console.error('Something went wrong when reading local storage' + e)
  }
}


const getLocalKeys = async () => {
  try {
    let user = await AsyncStorage.getItem('user')
    user = JSON.parse(user)
    return user
  } catch (e) {
    console.error('Something went wrong when reading local storage' + e)
  }
}

const setLocal = async (key, value) => {
  try {
    let user = await AsyncStorage.getItem('user')
    user = JSON.parse(user)
    user[key] = value

    await AsyncStorage.setItem('user', JSON.stringify(user))
    return
  } catch (e) {
    console.error('Something went wrong when reading local storage' + e)
  }
}

const setLocalToken = async (type, screen = null) => {
  try {
    let user = await AsyncStorage.getItem('user')
    user = JSON.parse(user)

    if (type == 'DXL') {
      user.token = user.DXLToken
      user.isDXL = 1
    } else {
      user.token = user.VFToken
      user.isDXL = 0
    }

    if (screen) {
      user.screen = screen
    }

    await AsyncStorage.setItem('user', JSON.stringify(user))
    return
  } catch (e) {
    console.error('Something went wrong when reading local storage' + e)
  }
}

const setLocalTokens = async (token1, token2) => {
  try {
    let user = await AsyncStorage.getItem('user')
    user = JSON.parse(user)

    user.DXLToken = token1
    user.VFToken = token2


    await AsyncStorage.setItem('user', JSON.stringify(user))
    return
  } catch (e) {
    console.error('Something went wrong when reading local storage' + e)
  }
}

const setLocalKeys = async (obj) => {
  if (Object.keys(obj).length < 1) {
    return
  }
  try {
    let user = await AsyncStorage.getItem('user')
    user = JSON.parse(user)

    for (var i in obj) {
      user[i] = obj[i]
    }

    await AsyncStorage.setItem('user', JSON.stringify(user))
    return
  } catch (e) {
    console.error('Something went wrong when reading local storage' + e)
  }
}

const setResult = () => {
  return new Promise((resolve, reject) => {
    NativeModules.RNReward.setResult('false')
      .then(() => {
        resolve()
      })
      .catch(error => {
        console.error('Error when calling setResult: ', JSON.stringify(error))
      })
  })
}

export { getLocal, setResult, setLocal, getLocalKeys, setLocalToken, setLocalTokens, setLocalKeys, getLocalToken }
