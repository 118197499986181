import { StyleSheet, Platform } from 'react-native'

export default StyleSheet.create({
  headerstyle: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 150,
    borderWidth: 0,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.5,
    elevation: 3,
    position: 'relative'
  },

  titleText: {
    fontFamily: Platform.OS === 'ios' ? 'Vodafone Rg' : 'VodafoneRg',
    fontSize: 24,
    justifyContent: 'center',
    alignItems: 'center',
    color: '#000',
    paddingBottom: 12,
    paddingLeft: 10,
    paddingRight: 10
  },
  subtitleText: {
    fontFamily: Platform.OS === 'ios' ? 'Vodafone Rg' : 'VodafoneRg',
    fontSize: 17,
    color: '#000',
    justifyContent: 'center',
    textAlign: 'center',
    paddingLeft: 5,
    paddingRight: 5
  }
})
