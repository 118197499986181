import React, { Component } from 'react'
import { View, Animated, TouchableOpacity, Platform } from 'react-native'
import PropTypes from 'prop-types'
import { isNumber as _isNumber, isEqual as _isEqual } from 'lodash'
import PagerView from 'react-native-pager-view'

import { Pagination } from '@vfgroup-oneplatform/foundation/Components'

import {
  withTheme,
  LightThemeColors,
  Colors,
  Fonts
} from '@vfgroup-oneplatform/foundation/Components/Themes'

import UsageInfoCard from '../../UsageInfoCard'
import UsageInfoCardWithButton from '../../UsageInfoCard/UsageInfoCardWithButton'
import BusinessUsage from '../../BusinessUsage'

import { getThemeImages } from '../../../Themes'

import styles from './MainComponent.Styles'

import GuestInfoCard from '../../GuestInfoCard'
import MobileUsage from '../../MobileUsage'
import NoBundleCard from '../../NoBundleCard'
import ReduxCache from '../../../../Utils/ReduxCache'

import PegaRecommendationOffer from '../../PegaRecommendationOffer'

const PersonalPageView = Animated.createAnimatedComponent(PagerView)
const BusinessPageView = Animated.createAnimatedComponent(PagerView)

class MainComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeDotCardIndex: 0
    }

    this.activeCardPosition = new Animated.Value(0)

    const { theme } = props
    this.Images = getThemeImages(theme.name)
    this.ResponsivePageView = React.createRef(null)
  }

  componentDidMount() {
    this.ResponsivePageView.current = Animated.createAnimatedComponent(PagerView)
  }

  componentDidUpdate(prevProps) {
    const { theme } = this.props
    if (!_isEqual(prevProps, this.props)) {
      this.ResponsivePageView.current = Animated.createAnimatedComponent(PagerView)
    }
    if (!_isEqual(prevProps.theme, this.props.theme)) {
      this.Images = getThemeImages(theme.name)
    }
  }

  onPageScroll = (e) => {
    if (Platform.OS === 'web') {
      this.setState({ activeDotCardIndex: e })
    } else {
      this.setState({ activeDotCardIndex: e.nativeEvent.position })
    }

  }

  renderBillingCard = ({ item, index, isActiveCard, opacity }) => {
    const {
      usageType,
      remainingValue = {},
      valueName,
      value = {},
      resetTextValue,
      isRoaming,
      footerText,
      itemTitle,
      type,
      fixOfferName,
      status,
      address,
      ...props
    } = item

    const getIconAndTitle = () => {
      if (type === 'fix' && usageType !== 'cabletv' && usageType !== 'internet') {
        return {
          icon: 'ic_broadband',
          title: ''
        }
      }

      switch (usageType) {
        case 'data':
          return {
            icon: 'ic_data_sharing',
            title: 'My Data'
          }
        case 'sms':
          return {
            icon: 'ic_sms_text',
            title: 'SMS'
          }
        case 'voice':
          return {
            icon: 'ic_call_log',
            title: 'Local Calls'
          }
        case 'unlimited':
          return {
            icon: 'ic_data_sharing',
            title: 'User\'s phone'
          }
        case 'money':
          return {
            icon: 'ic_credit',
            title: 'Credit'
          }
        case 'promo':
          return {
            icon: 'ic_ticket',
            title: itemTitle
          }
        case 'internet':
          return {
            icon: 'ic_broadband',
            title: 'Internet'
          }
        case 'cabletv':
          return {
            icon: 'ic_tv_icon',
            title: 'TV'
          }
        default:
          return {
            icon: null,
            title: ''
          }
      }
    }

    const icon = getIconAndTitle().icon
    const title = getIconAndTitle().title

    const footerRightIcon = isRoaming ? this.Images.icRoaming : null
    const fixImage = usageType === 'cabletv' ? 'ic_tv' : 'ic_modem'

    let valueRemainingPercentage = remainingValue.amount

    if (!_isNumber(remainingValue.amount) || usageType == 'promo' || usageType == 'money') {
      valueRemainingPercentage = 100
    } else if (_isNumber(value.amount)) {
      valueRemainingPercentage = (remainingValue.amount / value.amount) * 100
    }

    const usage_type_translations = usageType == 'messages' ? 'sms' : usageType
    const isUnlimited = remainingValue.amount == 'unlimited_bar_data' || remainingValue.amount == 'unlimited_bar_sms' || remainingValue.amount == 'unlimited_bar_voice' || usageType == 'promo'


    if (usageType === 'non_vf') {
      return (
        <TouchableOpacity activeOpacity={0.8} onPress={item.onPressCard} style={{ flex: 1 }}>
          <GuestInfoCard
            background={item.background}
            image={item.img}
            title={item.title}
            subTitle={item.subTitle}
          // icon={this.Images[icon]}
          // footerRightIcon={footerRightIcon}
          // footerText={footerText}
          // remaining={`${remainingValue.amount}`}
          // remainingUnit={remainingValue.units}
          // remainingText={usageType == 'promo' ? 'dashboard_collected' : !isUnlimited && usageType != 'money' ? 'dashboard_bundle_amount_left' : ''}
          // remainingTextPlaceholder={valueName ? [valueName] : []}
          // resetTextValue={resetTextValue}
          // value={valueRemainingPercentage || 0}
          // animate={isActiveCard}
          // delay={index === 0 ? 1500 : 10}
          // style={styles.usageInfoCard}
          // titleContainerStyle={{ opacity: opacity }}
          // showRefill={false}
          // onRefillPress={item.onRefillPress}
          // showGetMoreData={false}
          // onGetMoreDataPress={item.onGetMoreDataPress}
          // onPress={item.onPressCard}
          />
        </TouchableOpacity>
      )
    }
    if (usageType === 'business') {
      return <BusinessUsage {...item} />
    }

    if (!item.hasActiveBundle) {
      return (
        <TouchableOpacity style={{ height: '100%' }} activeOpacity={0.4} onPress={this.props.onPressCard}>
          <NoBundleCard
            title={title}
            icon={icon}
            usageType={usageType}
            item={item}
          />
        </TouchableOpacity>

      )
    }

    if (usageType == 'promo') {
      return (
        <TouchableOpacity activeOpacity={0.4} onPress={type === 'fix' ? props.onPressCard : this.props.onPressCard}>
          <UsageInfoCardWithButton
            icon={this.Images[icon]}
            footerRightIcon={footerRightIcon}
            footerText={footerText}
            title={title}
            remaining={`${remainingValue.amount}`}
            remainingUnit={remainingValue.units}
            remainingText={usageType == 'promo' ? 'dashboard_collected' : !isUnlimited && usageType != 'money' ? 'dashboard_bundle_amount_left' : ''}
            remainingTextPlaceholder={valueName ? [valueName] : []}
            resetTextValue={resetTextValue}
            value={valueRemainingPercentage || 0}
            animate={isActiveCard}
            delay={index === 0 ? 1500 : 10}
            style={styles.usageInfoCard}
            titleContainerStyle={{ opacity: opacity, alignItems: 'center' }}
            showRefill={false}
            onRefillPress={item.onPressCard}
            showGetMoreData={false}
            onGetMoreDataPress={item.onGetMoreDataPress}
            onPress={item.onPressCard}
          />
        </TouchableOpacity>
      )
    }

    if (usageType === 'mobile') {
      return (
        <TouchableOpacity style={{ flex: 1 }} activeOpacity={0.4} onPress={props.onPressCard}>
          <MobileUsage
            data={item}
            animate={isActiveCard}
            showRefill={item.showRefill}
            onRefillPress={item.onRefillPress}
            showGetMoreData={item.showGetMoreData}
            onGetMoreDataPress={item?.extraData?.[0]?.onGetMoreDataPress}
          />
        </TouchableOpacity>
      )
    }

    if (usageType === 'pega_recommendation') {
      return (
        <PegaRecommendationOffer data={item} />
      )
    }

    return (
      <TouchableOpacity style={{ height: '100%' }} activeOpacity={0.4} onPress={type === 'fix' ? props.onPressCard : this.props.onPressCard}>
        <UsageInfoCard
          icon={this.Images[icon]}
          footerRightIcon={footerRightIcon}
          footerText={footerText}
          title={title}
          remaining={`${remainingValue.amount}`}
          remainingUnit={remainingValue.units}
          remainingText={'dashboard_collected'}
          remainingTextPlaceholder={valueName ? [valueName] : []}
          resetTextValue={resetTextValue}
          value={valueRemainingPercentage || 0}
          animate={isActiveCard}
          delay={index === 0 ? 1500 : 10}
          style={styles.usageInfoCard}
          titleContainerStyle={{ opacity, alignItems: 'center' }}
          showRefill={item.showRefill}
          onRefillPress={item.onRefillPress}
          showGetMoreData={item.showGetMoreData}
          onGetMoreDataPress={item.onGetMoreDataPress}
          //for fix user types
          type={type}
          offerName={fixOfferName}
          fixImage={this.Images[fixImage]}
          status={status}
          address={address}
          usageType={usageType}
        />
      </TouchableOpacity>
    )
  }

  render() {
    const {
      extraInfo: { bucket = [] },
      theme
    } = this.props


    return (
      <View style={[styles.container(ReduxCache.getUserType()), bucket.length === 0 && { height: 300 }]} testID="MainCard">
        {bucket.length > 0 && this.ResponsivePageView.current &&
          <this.ResponsivePageView.current
            style={styles.container}
            initialPage={0}
            onPageSelected={this.onPageScroll}
            orientation="horizontal"
            showPageIndicator={false}
            onPageScroll={Animated.event(
              [
                {
                  nativeEvent: {
                    offset: this.activeCardPosition,
                  },
                },
              ],
              {
                useNativeDriver: true,
              }
            )}
          >
            {bucket.map((page, index) => {
              const isActiveCard = index === this.state.activeDotCardIndex
              const opacity = this.activeCardPosition.interpolate({
                inputRange: [
                  0,
                  0.02,
                  0.1,
                  0.3,
                  0.4,
                  0.5,
                  0.6,
                  1
                ],
                outputRange: [1, 0, 0, 0, 0, 0, 0, 1]
              })
              return this.renderBillingCard({ item: page, index, isActiveCard, opacity })
            })}
          </this.ResponsivePageView.current>}
        {bucket.length > 1 && <Pagination
          activeColor={theme.colors.secondryColor}
          inactiveColor={theme.colors.fillColor}
          inactiveOpacity={1}
          numberOfDots={bucket.length}
          activeDotIndex={this.state.activeDotCardIndex}
          containerStyle={styles.paginationStyle}
        />}
      </View>
    )
  }
}

MainComponent.defaultProps = {
  theme: LightThemeColors,
  extraInfo: {}
}

MainComponent.propTypes = {
  extraInfo: PropTypes.objectOf({
    bucket: PropTypes.array.isRequired
  }).isRequired,
  theme: PropTypes.objectOf({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  })
}

export default withTheme(MainComponent)
