import AsyncStorage from '@react-native-async-storage/async-storage'
import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import { UpfrontLoginBase } from '@vfgroup-oneplatform/login/BaseClasses'
// import { UpfrontLoginBase } from 'rn-vfg-login/BaseClasses'

import { Platform } from 'react-native'

import { LoginManager, LoginStatuses as Status } from '@vfgroup-oneplatform/login'
// import { LoginManager, LoginStatuses as Status } from 'rn-vfg-login'

import { Images, Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { errorObjectConstructor } from '../../Services/Auth/utils'
import NavigationService from '../../Navigation/NavigationService'

import { Actions as DashboardActions } from '../Dashboard/Dashboard.Actions'
import { store } from '../../Redux'
import SoftLoginImplementation from '../ForgotPassword/ForgotPassword'
import RegisterImplementation from '../Register'
import VerifyCodeImplementation from '../VerifyCode'

import ReduxCache from '../../Utils/ReduxCache'

import LanguageContext from '../../Utils/LanguageContext'

import { Actions as LandingScreenActions } from '../LandingScreen/LandingScreen.Actions'

// import CustomLanguageComponent from '../../Components/CustomLanguageChanger'

import { Actions } from './Login.Actions'



class Login extends UpfrontLoginBase {
  constructor(authConfig, onLoadingStart, onLoadingEnd, mode) {
    super(authConfig, onLoadingStart, onLoadingEnd)
    this.mode = mode
    this.authStatus = {
      success: {
        status: Status.Success,
        response: {}
      },
      loginFailure: {
        status: Status.Failed,
        errorMessage: 'upfront_login_error_message'
      },
      loginFailureLastAttempt: {
        status: Status.Failed,
        errorMessage: 'upfront_login_error_message'
      },
      blocked: {
        status: Status.Blocked
      },
      NewPasswordConfirmFailure: {
        status: Status.Failed,
        errorMessage: 'Error'
      }
    }
    ContentManager.setLocale(ReduxCache.getLanguage())
  }

  languageContext = LanguageContext
  showRememberMe = true
  withBiometricsLogin = true

  withThirdLabel = false
  getIsBiometricsLoginEnabled = async () => {
    let isCheckedBiometricLogin
    try {
      isCheckedBiometricLogin = await AsyncStorage.getItem(
        'isCheckedBiometricLogin'
      )
      isCheckedBiometricLogin = !!JSON.parse(isCheckedBiometricLogin)
    } catch (error) {
      isCheckedBiometricLogin = false
    }
    return isCheckedBiometricLogin
  }
  onThirdLabelPress = () => {

  }

  biometricsConfig = {
    ios: {
      description: 'Unlock MVA10 React with fingerprint',
      fallbackEnabled: false
    },
    android: {
      title: 'Unlock with Biometrics',
      cancelButton: 'Use Password'
    }
  }

  onBiometricsSuccess = () => {
    this.onSuccess()
  }

  showSecondaryButton = false

  secondaryButtonProps = {
    title: 'Continue with Facebook',
    onPress: () => { },
    style: {
      backgroundColor: Colors.frenchBlue,
      height: 46
    },
    underlayColor: Colors.lightFrenchBlue,
    textStyle: { fontSize: 18.7, marginTop: Platform.OS == 'ios' ? 5 : 0 },
    leftIcon: {
      type: 'image',
      name: Images.ic_facebook,
      iconStyle: { marginRight: 11.4 },
      size: 26
    }
  }

  withChangeLanguage = true
  languageProps = {
    languages: [
      {
        'icon': 'icAlbanianFlag',
        'title': 'app_languages_albanian',
        'identifier': 'al',
      },
      {
        'icon': 'icBritishFlag1',
        'title': 'app_languages_english',
        'identifier': 'en'
      },
    ],
    onChangeLanguage: (selectedLanguage) => {

      store.dispatch({ type: 'UPDATE_LANGUAGE', data: selectedLanguage })
      ContentManager.setLocale(selectedLanguage)
      // moment.locale([selectedLanguage == 'al' ? 'sq' : selectedLanguage, 'en'])
      LandingScreenActions.getLP()
    },
  }
  getAuthConfig = () => {
    return this.__authConfig
  }

  getAdditionalTrackingInfo = () => {
    return {
      page_channel: 'Vodafone.AL',
      page_country: 'AL',
      page_locale: 'en_AL'
    }
  }

  validateEmail = email => {
    const reg = /^3556([6-9]{1})([2-9]{1})([0-9]){6}$/
    return (
      reg.test(email)
    )
  }

  onLoginStart = () => {
    this.__onLoadingStart()
  }


  onLogin = (phoneNumber, password, rememberMe) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Actions.loginWithDXL(phoneNumber, password, null, store)

        // dispatch directly from store since we cannot connect Login class to redux
        store.dispatch(DashboardActions.getUserBalance(response?.businessAccount?.showBusinessView && response?.businessAccount?.sohoUserType === 'business_chooser' ? true : false))

        resolve({
          status: Status.Success,
          response
        })
      } catch (error) {
        const errorInfo = errorObjectConstructor(
          error,
          this.authStatus.loginFailure
        )
        reject(errorInfo)
      }
    })
  }

  onRegister = () => {

  }
  onForgetPassword = async () => {

    const authConfig = {}
    const config = {
      renderLoginIconView: null,
      renderVerifyLoginIconView: null
    }
    const onLoadingStart = () => { }

    const onLoadingEndRegister = () => {
      NavigationService.navigate('LoginByEmailScreen')
    }

    config.soft = new SoftLoginImplementation(authConfig, onLoadingStart, onLoadingStart)
    config.register = new RegisterImplementation({}, () => { }, onLoadingEndRegister)
    config.verifyCode = new VerifyCodeImplementation()

    const loginManager = new LoginManager(config, NavigationService)

    const onNext = await loginManager.init()

    onNext()
  }

  onResetAccount = () => {

  }

  onSuccess = () => {
    this.__onLoadingEnd()
  }

  validatePhone = () => { return true }
  validatePassword = () => { return true }

  keyboardType = 'phone-pad'

  // Custom language component
  // LanguageCustomComponent = CustomLanguageComponent

  onNewPasswordConfirm = (phoneNumberOrEmail, newPassword) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (newPassword === 'error') {
          reject(this.authStatus.NewPasswordConfirmFailure)
        }
        resolve(this.authStatus.success)
      }, 400)
    })
  }

  resetPasswordConfig = {
    maxLength: 14,
    onCodeSubmit: () => { },
    onNewPasswordConfirm: this.onNewPasswordConfirm,
    onNewPasswordConfirmSuccess: this.onSuccess
  }
}

export default Login
