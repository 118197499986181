import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import {
  View,
  Image,
  Pressable,
  TouchableOpacity,
  useWindowDimensions,
  StatusBar,
  Platform,
  Dimensions
} from 'react-native'
import { useIsFocused } from '@react-navigation/native'

import { useSelector, useDispatch } from 'react-redux'

import LinearGradient from 'react-native-linear-gradient'
import PropTypes from 'prop-types'
// import Share from 'react-native-share'
import VideoPlayer from 'react-native-video'
import GestureRecognizer from 'react-native-swipe-gestures'
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context'
import { VFButton, VFScreen, Icon, VFText } from '@vfgroup-oneplatform/foundation/Components'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import NavigationService from '../../Navigation/NavigationService'

import { postStoryEvents } from '../../Services/Stories/Stories.Services'

import { getLocalKey } from '../../Services/Storage'

import ReduxCache from '../../Utils/ReduxCache'

import MigrationLottie from '../../Components/MigrationLottie'

import ProgressBar from './ProgressBar'

import { actionTypes, setStoriesInStore } from './Story.Actions'


import styles from './Story.Styles'
import Images from '../../Themes/Images'


// Increment or decrement story
const OPERATION_TYPES = {
  INCREMENT: 'INCREMENT',
  DECREMENT: 'DECREMENT'
}
// story duration in seconds
const STORY_DURATION = 10

// Config for swipe gesture
const SWIPE_CONFIG = {
  velocityThreshold: 0.05,
  directionalOffsetThreshold: 30
}

const findBubbleOfStory = (bubbles, id) => {
  for (const bubble of bubbles) {
    if (Array.isArray(bubble.stories)) {
      for (const story of bubble.stories) {
        if (story.id === id) {
          return bubble
        }
      }
    }
  }
  return null
}

const findBubble = (bubbles, bubbleId) => {
  return bubbles.find(bubble => bubble.id === bubbleId)
}

const Story = (props) => {
  // id - The id of the bubble
  let storyId, bubbleId, internalNavigation, location

  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
    storyId = props.storyId
    bubbleId = props.bubbleId
    internalNavigation = props.internalNavigation
    location = props.navigationLocation
  } else {
    storyId = props.route.params.storyId
    bubbleId = props.route.params.bubbleId
    internalNavigation = props.route.params.internalNavigation
    location = props.route.params.navigationLocation
  }

  const { width } = useWindowDimensions()
  const theme = useTheme()


  const storeBubbles = useSelector(state => {
    return state.stories?.stories
  })
  const dispatch = useDispatch()


  const [currentBubble, setCurrentBubble] = useState(() => {
    return Array.isArray(storeBubbles)
      ? bubbleId
        ? findBubble(storeBubbles, bubbleId)
        : findBubbleOfStory(storeBubbles, storyId)
      : null
  })
  const [storyIndex, setStoryIndex] = useState(() => {
    if (!Array.isArray(storeBubbles)) {
      return 0
    }
    if (!currentBubble || !Array.isArray(currentBubble.stories)) {
      return 0
    }
    const idx = currentBubble.stories.findIndex(story => story.id === storyId)
    return idx >= 0 ? idx : 0
  })
  const [pause, setPause] = useState(false)
  const [assetLoading, setAssetLoading] = useState(false)
  const [seconds, setSeconds] = useState(STORY_DURATION)

  // Manage hold event with this
  const [pressInTime, setPressInTime] = useState(0)

  // Make sure to update current bubble when the redux state is finished fetching
  useEffect(() => {
    if (!storeBubbles) {
      setStoriesInStore()
      return
    }
    if (currentBubble) {
      return
    }
    const bubble = Array.isArray(storeBubbles)
      ? findBubbleOfStory(storeBubbles, storyId)
      : null
    setCurrentBubble(bubble)

  }, [storyId, storeBubbles, currentBubble])

  // This useEffect runs whenever we move on to the other story and when dhe duration changes (video) so that the story is skipped every <duration> seconds
  useLayoutEffect(() => {
    const interval = setInterval(() => {
      if (pause) {
        return
      }
      // On time finish
      if (seconds <= 1) {
        navigateStories(OPERATION_TYPES.INCREMENT)
        setSeconds(STORY_DURATION)
      }
      // Decrement time by 1s
      else {
        setSeconds(prev => prev - 1)
      }
    }, 1000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyIndex, seconds, pause])


  React.useLayoutEffect(() => {
    if (storeBubbles) {
      onOpenStoryEvent()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyIndex])



  const navigateBubbles = action => {
    if (!internalNavigation) {
      onClose()
      return
    }

    const currentBubbleIdx = storeBubbles
      ? storeBubbles.findIndex(bubble => bubble.id === currentBubble?.id)
      : -1
    const newIndex =
      action === OPERATION_TYPES.INCREMENT
        ? currentBubbleIdx + 1
        : currentBubbleIdx - 1

    const hasNextStory =
      Array.isArray(storeBubbles) &&
      storeBubbles[newIndex] &&
      Array.isArray(storeBubbles[newIndex].stories)

    const nextStoryIndex = hasNextStory
      ? action === OPERATION_TYPES.INCREMENT
        ? 0
        : storeBubbles[newIndex].stories.length - 1
      : null

    const nextStory = hasNextStory
      ? storeBubbles[newIndex].stories[nextStoryIndex]
      : null

    if (!nextStory) {
      setPause(false)
      setStoryIndex(0)
      onClose()
      return
    }

    setStoryIndex(nextStoryIndex)
    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
      setCurrentBubble(() => {
        return Array.isArray(storeBubbles)
          ? bubbleId
            ? findBubble(storeBubbles, storeBubbles[newIndex].id)
            : findBubbleOfStory(storeBubbles, storyId)
          : null
      })
    } else {
      // The navigation gets some parameters
      NavigationService.navigateWithResetAction('Story', {
        // Story Id
        storyId: nextStory.id,
        internalNavigation: true,
        bubbleId: storeBubbles[newIndex].id,
        // Slide from
        slide:
          action === OPERATION_TYPES.INCREMENT
            ? 'slide_from_right'
            : 'slide_from_left',
        navigationLocation: location
      })
    }
    clearStuff()
  }

  const navigateStories = operation => {
    if (!currentBubble || !Array.isArray(currentBubble.stories)) {
      return
    }
    if (operation === OPERATION_TYPES.INCREMENT) {
      // Going to next bubble
      if (storyIndex + 1 === currentBubble.stories.length) {
        // Go to first story of next bubble
        setStoryIndex(0)
        navigateBubbles(OPERATION_TYPES.INCREMENT)
      }
      // Going to next story of bubble
      else {
        setStoryIndex(prev => ++prev)
      }
    } else if (operation === OPERATION_TYPES.DECREMENT) {
      // Going to previous bubble
      if (storyIndex - 1 < 0) {
        navigateBubbles(OPERATION_TYPES.DECREMENT)
        //  TODO: Go the last story of the previous bubble
      }
      // Going to previous story of bubble
      else {
        setStoryIndex(prev => --prev)
      }
    }

    // TODO: Dispatch an event that marks the previous story as seen

    clearStuff()
  }


  const onOpenStoryEvent = () => {
    dispatch({ type: actionTypes.OPEN_STORY, payload: { bubbleId: currentBubble.id, id: currentBubble.stories[storyIndex].id } })
    postStoryEvents({ storyId: currentBubble?.stories[storyIndex].id, event: 'openEvent' })
  }


  const onClose = async () => {
    StatusBar.setHidden(false)
    if (location) {
      NavigationService.navigateWithResetAction(location, { withoutGuestLogin: true })
    } else {
      try {
        // If user is logged in and is not guest send to dashboard, if not send to prelogin
        const isLogin = await getLocalKey('isLoggedIn')
        const isGuest = ReduxCache.getUserType() === 'guest'

        if (!isLogin || isGuest) {
          NavigationService.navigateWithResetAction('PreLogin', { withoutGuestLogin: true })
        }
        else {
          NavigationService.navigateWithResetAction('Dashboard')
        }
      } catch (error) {
        NavigationService.navigateWithResetAction('PreLogin', { withoutGuestLogin: true })
      }
    }
    clearStuff()
    postStoryEvents({ storyId: currentBubble.stories[storyIndex].id, event: 'closeEvent' })
  }

  const onShareEvent = async () => {
    const shareId =
      currentBubble && Array.isArray(currentBubble.stories)
        ? currentBubble.stories[storyIndex].id
        : ''
    if (!shareId) {
      return
    }
    const shareOptions = {
      message: 'Check out this story from Vodafone Albania',
      url: `https://my.vodafone.al/story/${shareId}`
    }
    if (Platform.OS === 'web') {
      navigator.clipboard.writeText(shareOptions.url)
    }
    // await Share.open(shareOptions)
    postStoryEvents({ storyId: currentBubble.stories[storyIndex].id, event: 'shareEvent' })
  }


  const onLikeEvent = () => {
    dispatch({ type: actionTypes.LIKE_STORY, payload: { bubbleId: currentBubble.id, id: currentBubble.stories[storyIndex].id } })
    postStoryEvents({ storyId: currentBubble.stories[storyIndex].id, event: 'likeEvent' })

    const currentBubbleChanged = currentBubble
    currentBubbleChanged.stories[storyIndex].liked = !currentBubbleChanged.stories[storyIndex].liked

    setCurrentBubble(currentBubbleChanged)
  }

  const CTAButtonHandler = (type, url) => {
    postStoryEvents({ storyId: currentBubble.stories[storyIndex].id, event: 'CTAEvent' })

    if (type?.includes('webview')) {
      setPause(true)
      NavigationService.navigate('WebView', { url, title: 'Explore Story', isReact: true })
    } else {
      setPause(true)
      NavigationService.navigate(url)
    }
    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
      props.close()
    }
  }

  const onPressOutEventHandler = (pressOutTime) => {
    const diff = pressOutTime - pressInTime
    postStoryEvents({ storyId: currentBubble.stories[storyIndex].id, event: 'holdEvent', diff })
  }

  const skippedStoryEventHandler = () => {
    const skippedTime = seconds
    if (seconds > 0) {
      postStoryEvents({ storyId: currentBubble.stories[storyIndex].id, event: 'skippedEvent', skippedTime })
    }
  }

  const clearStuff = () => {
    setPause(false)
    setSeconds(STORY_DURATION)
  }

  const renderPreviousStory = () => {
    const currentBubbleIdx = storeBubbles
      ? storeBubbles.findIndex(bubble => bubble.id === currentBubble?.id)
      : -1
    const currentStoryIdx = storeBubbles ? storeBubbles[currentBubbleIdx].stories.findIndex(story => story.id === storeBubbles[currentBubbleIdx].stories[storyIndex].id) : -1
    const imageUri = () => {
      if (currentBubbleIdx === 0 && currentStoryIdx === 0) {
        return null
      } else if (currentStoryIdx === 0) {
        return storeBubbles[currentBubbleIdx - 1].stories[storeBubbles[currentBubbleIdx - 1].stories.length - 1].image
      }
      else {
        return storeBubbles[currentBubbleIdx].stories[currentStoryIdx - 1].image
      }
    }

    return (
      <Image
        className={'testAnimation'}
        style={{
          height: '40%',
          width: '40%',
          left: -40,
          alignSelf: 'center',
          position: 'absolute'
        }}
        source={{
          uri: imageUri()
        }}
        resizeMode="contain"
      />
    )
  }

  const renderNextStory = () => {
    const currentBubbleIdx = storeBubbles
      ? storeBubbles.findIndex(bubble => bubble.id === currentBubble?.id)
      : -1
    const currentStoryIdx = storeBubbles ? storeBubbles[currentBubbleIdx].stories.findIndex(story => story.id === storeBubbles[currentBubbleIdx].stories[storyIndex].id) : -1
    const maxBubbleLength = storeBubbles.length - 1
    const maxStoryIndex = storeBubbles[currentBubbleIdx].stories.length - 1

    const imageUri = () => {
      if (currentBubbleIdx === maxBubbleLength && currentStoryIdx === maxStoryIndex) {
        return null
      } else if (currentStoryIdx === maxStoryIndex) {
        return storeBubbles[currentBubbleIdx + 1].stories[0].image
      }
      else {
        return storeBubbles[currentBubbleIdx].stories[currentStoryIdx + 1].image
      }
    }

    return (
      <Image
        style={{ height: '40%', width: '40%', right: -40, alignSelf: 'center', position: 'absolute' }}
        source={{
          uri: imageUri()
        }}
        resizeMode="contain"
      />
    )
  }

  const { bottom } = useSafeAreaInsets()
  const storyWidth = useRef(0)

  // if (!currentBubble) {
  //   if (!internalNavigation) {
  //     onClose()
  //   }
  //   return <VFLottieLoadingScreen title={'Loading...'} />
  // }

  const isFocused = useIsFocused()

  useEffect(() => {
    if (isFocused) {
      StatusBar.setHidden(true)
      setPause(false)
    } else {
      StatusBar.setHidden(false)
    }
  }, [isFocused])

  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
    return (
      <Pressable
        onLayout={e => {
          storyWidth.current = e.nativeEvent.layout.width
        }}
        style={styles.pressable}
        activeOpacity={1}
        onPressIn={(event) => {
          setPressInTime(event.nativeEvent.timestamp)
          setPause(true)
        }}
        onPressOut={(event) => {
          onPressOutEventHandler(event.nativeEvent.timestamp)
          setPause(false)
        }}
      >
        <View style={styles.progresses}>
          {currentBubble?.stories?.map((story, ind) => {
            return (
              <View>
                <ProgressBar
                  key={storyIndex + ind * (ind + 2)}
                  index={ind + storyIndex}
                  seen={story.storyIndex < storyIndex}
                  color={'white'}
                  backgroundColor={'gray'}
                  style={styles.singleProgress}
                  height={2}
                  animDuration={STORY_DURATION}
                  seconds={seconds}
                  pause={pause}
                  // Width of each story should be the phone's width minus the margins minus the stories'lengths multiplied with the space width between the 2 stories and all this is divided by stories number
                  width={
                    currentBubble.stories.length === 1 ?
                      storyWidth.current
                      :
                      (storyWidth.current - 32 - currentBubble.stories.length * 0.8) /
                      currentBubble.stories.length
                  }
                  startAnimation={storyIndex === ind ? true : false}
                />
              </View>
            )
          })}
        </View>

        {currentBubble?.stories[storyIndex].type === 'video' ? (
          <>
            {/* While video is loading show and animated activity indicator */}
            {assetLoading && (
              // <ActivityIndicator
              //   size={50}
              //   style={styles.loader}
              //   hidesWhenStopped
              //   animating={assetLoading}
              // />
              <View style={styles.loaderContainer}>
                <MigrationLottie style={styles.loader} />
              </View>

            )}
            <VideoPlayer
              source={{ uri: currentBubble.stories[storyIndex].image }}
              onLoadStart={() => {
                setAssetLoading(true)
                setPause(true)
              }}
              paused={pause}
              resizeMode="cover"
              repeat={false}
              style={styles.videoPlayer}
              onLoad={item => {
                setPause(false)
                setSeconds(item.duration - (STORY_DURATION - seconds))
                setAssetLoading(false)
              }}
            // style={styles.videoStyle}
            // ref={this.setupMinimizedVideoRef}
            // onProgress={this.minimizedPlayerProgress}
            />
          </>
        ) : (
          <View style={{ display: 'flex', flexDirection: 'row', flex: 1, position: 'relative' }}>
            {renderPreviousStory()}
            <Image
              style={styles.storyImage}
              source={{
                uri: currentBubble?.stories[storyIndex].image
              }}
              resizeMode="contain"
            />
            {renderNextStory()}
          </View>
        )}

        {/* Header buttons */}
        <View style={styles.storyDataContainer}>
          <View style={styles.infoLeft}>
            {/* Expires in * button */}
            {currentBubble?.stories[storyIndex].icon ?
              <View style={styles.customIconContainer}>
                <Icon name={{ uri: currentBubble.stories[storyIndex].icon }} resizeMode="cover" width={Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 48 : 38} height={Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 48 : 38} style={styles.customIcon} />
              </View> :
              <View style={styles.customIconContainer}>
                <Icon name={Images.blackClock} resizeMode="contain" style={Dimensions.get('window').width > 700 && { width: 30, height: 30 }} />
              </View>
            }
            <View style={styles.titleContainer}>
              {currentBubble?.stories[storyIndex].name && <VFText
                i18nKey={currentBubble.stories[storyIndex].name}
                style={styles.titleText}
              />}
              {currentBubble?.stories[storyIndex].description && <VFText
                i18nKey={currentBubble.stories[storyIndex].description}
                style={styles.subTitleText}
              />}
            </View>
          </View>
        </View>

        {/* Buttons on both sides to skip to next or prev story */}
        <View style={styles.navigationContainer}>
          {/* Left button */}
          <TouchableOpacity
            style={styles.leftBtnNav}
            onPress={() => {
              navigateStories(OPERATION_TYPES.DECREMENT)
            }}
          >
            <LinearGradient
              colors={['#1112', 'transparent']}
              start={{ x: 0, y: 1 }}
              end={{ x: 1, y: 1 }}
              style={styles.navGradient}
            />
          </TouchableOpacity>

          {/* Right Button */}
          <TouchableOpacity
            style={styles.rightBtnNav}
            onPress={() => {
              skippedStoryEventHandler()
              navigateStories(OPERATION_TYPES.INCREMENT)
            }}
          >
            <LinearGradient
              colors={['#1112', 'transparent']}
              end={{ x: 0, y: 1 }}
              start={{ x: 1, y: 1 }}
              style={styles.navGradient}
            />
          </TouchableOpacity>
        </View>

        {/* Bottom Buttons */}
        <View style={styles.bottomBtnContainer(bottom)}>
          {currentBubble?.stories[storyIndex]?.CTAURL ?
            <VFButton
              title={currentBubble.stories[storyIndex].CTAText}
              textStyle={styles.buttonTitle(theme)}
              type={'secondary'}
              onPress={
                () => CTAButtonHandler(
                  currentBubble.stories[storyIndex].CTAType,
                  currentBubble.stories[storyIndex].CTAURL
                )
              }
              allowFontScaling={true}
              style={styles.storyBtn(theme)}
            /> : <View style={styles.emptyStoryButton} />}
          <View style={styles.bottomButtonContainer}>
            <TouchableOpacity onPress={() => onLikeEvent()}>
              {currentBubble?.stories[storyIndex].liked ? (
                <Icon name={Images.like_button_coloured} size={30} />
              ) : (
                <Icon name={Images.like_button} size={30} />
              )}
            </TouchableOpacity>
            {/*
            <TouchableOpacity onPress={() => onShareEvent()}>
              <Icon name={Images.share_button} size={30} />
            </TouchableOpacity> */}
          </View>
        </View>

      </Pressable>
    )
  }

  return (
    <SafeAreaView style={styles.safeArea}>
      <StatusBar
        animated={true}
        hidden={true} />
      <GestureRecognizer
        onSwipeUp={e => {
          onClose()
        }}
        onSwipeDown={() => onClose()}
        config={SWIPE_CONFIG}
      >
        <VFScreen
          withStatusBar={false}
          withHeader={false}
          containerStyle={styles.storyContainer}
        >
          <Pressable
            style={styles.pressable}
            activeOpacity={1}
            onPressIn={(event) => {
              setPressInTime(event.nativeEvent.timestamp)
              setPause(true)
            }}
            onPressOut={(event) => {
              onPressOutEventHandler(event.nativeEvent.timestamp)
              setPause(false)
            }}
          >
            <View style={styles.progresses}>
              {currentBubble?.stories.map((story, ind) => {
                return (
                  <View>
                    <ProgressBar
                      key={storyIndex + ind * (ind + 2)}
                      index={ind + storyIndex}
                      seen={story.storyIndex < storyIndex}
                      color={'white'}
                      backgroundColor={'gray'}
                      style={styles.singleProgress}
                      height={2}
                      animDuration={STORY_DURATION}
                      seconds={seconds}
                      pause={pause}
                      // Width of each story should be the phone's width minus the margins minus the stories'lengths multiplied with the space width between the 2 stories and all this is divided by stories number
                      width={
                        currentBubble?.stories.length === 1 ?
                          width - 20
                          :
                          (width - 32 - currentBubble.stories.length * 0.8) /
                          currentBubble.stories.length
                      }
                      startAnimation={storyIndex === ind ? true : false}
                    />
                  </View>
                )
              })}
            </View>

            {currentBubble?.stories[storyIndex].type === 'video' ? (
              <>
                {/* While video is loading show and animated activity indicator */}
                {/* {assetLoading && (
                  // <ActivityIndicator
                  //   size={50}
                  //   style={styles.loader}
                  //   hidesWhenStopped
                  //   animating={assetLoading}
                  // />
                  <View style={styles.loaderContainer}>
                    <MigrationLottie style={styles.loader} />
                  </View>

                )} */}
                <VideoPlayer
                  source={{ uri: currentBubble.stories[storyIndex].image }}
                  onLoadStart={() => {
                    setAssetLoading(true)
                    setPause(true)
                  }}
                  paused={pause}
                  resizeMode="cover"
                  repeat={false}
                  style={styles.videoPlayer}
                  onLoad={item => {
                    setPause(false)
                    setSeconds(item.duration - (STORY_DURATION - seconds))
                    setAssetLoading(false)
                  }}
                // style={styles.videoStyle}
                // ref={this.setupMinimizedVideoRef}
                // onProgress={this.minimizedPlayerProgress}
                />
              </>
            ) : (
              <>
                {/* {assetLoading && (
                  <View style={styles.loaderContainer}>
                    <MigrationLottie style={styles.loader} />
                  </View>
                )} */}
                <Image
                  style={styles.storyImage}
                  source={{
                    uri: currentBubble?.stories[storyIndex].image
                  }}
                  resizeMode="cover"
                  onLoadStart={() => {
                    setAssetLoading(true)
                    setPause(true)
                  }}
                  onLoad={item => {
                    setPause(false)
                    setAssetLoading(false)
                  }}
                />
              </>
            )}

            {/* Header buttons */}
            <View style={styles.storyDataContainer}>
              <View style={styles.infoLeft}>
                {/* Expires in * button */}
                {currentBubble?.stories[storyIndex].icon ?
                  <View style={styles.customIconContainer}>
                    <Icon name={{ uri: currentBubble.stories[storyIndex].icon }} resizeMode="cover" width={38} height={38} style={styles.customIcon} />
                  </View> :
                  <View style={styles.iconContainer}>
                    <Icon name={Images.blackClock} resizeMode="contain" />
                  </View>
                }
                <View style={styles.titleContainer}>
                  {currentBubble?.stories[storyIndex].name && <VFText
                    i18nKey={currentBubble.stories[storyIndex].name}
                    style={styles.titleText}
                  />}
                  {currentBubble?.stories[storyIndex].description && <VFText
                    i18nKey={currentBubble?.stories[storyIndex].description}
                    style={styles.subTitleText}
                  />}
                </View>
              </View>
              <TouchableOpacity
                style={{ marginRight: 20 }}
                onPress={() => {
                  onClose()
                }}
                hitSlop={{ top: 10, bottom: 10, left: 10, right: 20 }}
              >
                <Icon name={Images.close_white} size={18} />
              </TouchableOpacity>
            </View>

            {/* Buttons on both sides to skip to next or prev story */}
            <View style={styles.navigationContainer}>
              {/* Left button */}
              <TouchableOpacity
                style={styles.leftBtnNav}
                onPress={() => {
                  navigateStories(OPERATION_TYPES.DECREMENT)
                }}
              >
                <LinearGradient
                  colors={['#1112', 'transparent']}
                  start={{ x: 0, y: 1 }}
                  end={{ x: 1, y: 1 }}
                  style={styles.navGradient}
                />
              </TouchableOpacity>

              {/* Right Button */}
              <TouchableOpacity
                style={styles.rightBtnNav}
                onPress={() => {
                  skippedStoryEventHandler()
                  navigateStories(OPERATION_TYPES.INCREMENT)
                }}
              >
                <LinearGradient
                  colors={['#1112', 'transparent']}
                  end={{ x: 0, y: 1 }}
                  start={{ x: 1, y: 1 }}
                  style={styles.navGradient}
                />
              </TouchableOpacity>
            </View>

            {/* Bottom Buttons */}
            <View style={styles.bottomBtnContainer(bottom)}>
              {currentBubble?.stories[storyIndex]?.CTAURL ?
                <VFButton
                  title={currentBubble.stories[storyIndex].CTAText}
                  textStyle={styles.buttonTitle(theme)}
                  type={'secondary'}
                  onPress={
                    () => CTAButtonHandler(
                      currentBubble.stories[storyIndex].CTAType,
                      currentBubble.stories[storyIndex].CTAURL
                    )
                  }
                  allowFontScaling={true}
                  style={styles.storyBtn(theme)}
                /> : <View style={styles.emptyStoryButton} />}
              <View style={styles.bottomButtonContainer}>
                <TouchableOpacity onPress={() => onLikeEvent()}>
                  {currentBubble?.stories[storyIndex].liked ? (
                    <Icon name={Images.like_button_coloured} size={30} />
                  ) : (
                    <Icon name={Images.like_button} size={30} />
                  )}
                </TouchableOpacity>
                {/*
                <TouchableOpacity onPress={() => onShareEvent()}>
                  <Icon name={Images.share_button} size={30} />
                </TouchableOpacity> */}
              </View>
            </View>
          </Pressable>
        </VFScreen>
      </GestureRecognizer>
    </SafeAreaView>
  )
}

Story.propTypes = {
  props: PropTypes.object
}

export default Story
