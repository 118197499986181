import axios from 'axios'

import CONFIG from '../../Config'
import { getErrorMessage } from '../../Utils'
import ReduxCache from '../../Utils/ReduxCache'

import { action_types } from './filters.actionTypes'

const getFilters = () => {
  return async dispatch => {
    dispatch({
      type: action_types.REQUEST_FILTERS
    })

    const msisdn = ReduxCache.getMsisdn()

    const data = {
      'queryOptions': {
        'filter': '',
        'pagination': {
          'count': 10,
          'limit': 0
        },
        'sorting': ''
      },
      'queries': [
        {
          'query': '$.redeemerId=' + msisdn
        },
        {
          'operator': 'AND'
        },
        {
          'query': '$.type=' + CONFIG.queryConfig.type
        },
        {
          'operator': 'AND'
        },
        {
          'query': '$.channelId=' + CONFIG.queryConfig.channelId
        },
        {
          'operator': 'AND'
        },
        {
          'query': '$.category=filters'
        }
      ]
    }

    const url = await CONFIG.getUrl()
    const headers = await CONFIG.getHeaders()
    axios.post(url.apiEndpoint, data, {
      headers: {
        ...headers
      }
    })
      .then(response => {
        const filters = {}

        for (var i in response.data) {
          const filter = response.data[i]

          var characteristics = []

          for (var j in filter.parts.specification.characteristicsValue) {
            var characteristic = filter.parts.specification.characteristicsValue[j]
            characteristics.push({
              key: characteristic.characteristicName,
              title: characteristic.value
            })
          }

          filters[filter.name.toLowerCase()] = {
            name: filter.name,
            data: characteristics
          }
        }

        dispatch({
          type: action_types.SUCCESS_FILTERS,
          payload: filters
        })
      })
      .catch(error => {
        const message = getErrorMessage(error)
        dispatch({
          type: action_types.ERROR_FILTERS,
          payload: message
        })
      })
  }
}

export const actions = {
  getFilters
}
