import { Dimensions, Platform, StyleSheet } from 'react-native'
import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

const style = StyleSheet.create({
  container: theme => ({
    backgroundColor: theme.colors.backgroundColorThree,
    padding: 16,
    flex: 2,
    marginBottom: -1
  }),
  iconStyle: {
    resizeMode: 'contain'
  },
  detailsContainer: {
    flexDirection: 'row'
  },
  detailsWrapper: {
    marginTop: 10,
    flex: 1,
    paddingLeft: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 0 : 52
  },
  detailItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 4.2
  },
  detailKey: {
    fontSize: 16.7,
    lineHeight: 23
  },
  detailValue: {
    fontSize: 16.7,
    marginRight: 22
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  titleSubTitleContainer: {
    marginLeft: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 0 : 20
  },
  title: {
    marginBottom: 1,
    fontSize: 14.6,
    lineHeight: 18.8
  },
  subTitle: {
    fontSize: 18.8,
    lineHeight: 25.1
  }
})
export default style
