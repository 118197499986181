import React from 'react'
import {
  View,
  ScrollView,
  Image,
  ImageBackground,
  TouchableOpacity,
} from 'react-native'

import VfText from '../VfText'

import {__translate} from '../../Utils'

import {styles} from './VfHeartsCollRowItem.styles'
import Images from '../../Themes/Images'

class VfHeartsCollRowItem extends React.Component {
  getItems() {
    const {items} = this.props
    const len = items.length
    return items.map((item, i) => {
      let rowImage = {},
        itemStyles = {},
        disabled = true,
        desription = __translate('heart_collection_locked'),
        noOfHearts = parseInt(this.props.hearts),
        heartsLimit = parseInt(item.price),
        finalNoOfHearts = noOfHearts
      if (noOfHearts >= heartsLimit) {
        rowImage.heart = Images.fl_active_heart
        rowImage.heartAmount = Images.fl_active_heart_amount
        rowImage.line = Images.lb_active_dash
        itemStyles.descStyle = styles.activeDesc
        itemStyles.balanceStyle = styles.activeBalance
        disabled = false
        finalNoOfHearts = heartsLimit
        desription = __translate('heart_collection_claim')
      } else {
        rowImage.heart = Images.fl_inactive_heart
        rowImage.heartAmount = Images.fl_inactive_heart_amount
        rowImage.line = Images.lb_inactive_dash
        itemStyles.descStyle = styles.inactiveDesc
        itemStyles.balanceStyle = styles.inactiveBalance
        itemStyles.heartStyle = styles.inactiveFlower
      }
      return (
        <View style={styles.rowContainer} key={i}>
          <View style={styles.rowContent}>
            <ImageBackground
              source={rowImage.line}
              resizeMode="cover"
              imageStyle={styles[`line_disabled_${disabled}`]}
              style={i !== len - 1 ? styles.line : styles.noLine}
            />
            <View
              style={[
                styles.heartContainer,
                styles[`heart_container_disabled_${disabled}`],
              ]}>
              <Image
                source={rowImage.heart}
                style={[styles.heartImg, itemStyles.heartStyle]}
              />
            </View>
            <View>
              <VfText size="h3" weight="bold" style={itemStyles.balanceStyle}>
                {finalNoOfHearts}/{heartsLimit}
              </VfText>
              <VfText style={styles.balanceDesc}>
                {__translate('heart_collection_1')}
              </VfText>
            </View>
          </View>
          <View style={styles.rowContent}>
            <VfText weight="bold" style={itemStyles.descStyle}>
              {desription}
            </VfText>
            <TouchableOpacity
              onPress={() => this.props.onPress(item.id)}
              disabled={disabled}
              style={styles.touchable}>
              <ImageBackground
                source={rowImage.heartAmount}
                style={styles.heartImgAmount}
                imageStyle={styles.heartImageStyles}
                resizeMode="contain">
                <VfText
                  size="h5"
                  weight="bold"
                  align="center"
                  style={styles.heartText}>
                  {item.name}
                </VfText>
              </ImageBackground>
            </TouchableOpacity>
          </View>
        </View>
      )
    })
  }

  render() {
    return (
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.scrollView}>
        {this.getItems()}
      </ScrollView>
    )
  }
}
VfHeartsCollRowItem.defaultProps = {
  onPress: () => null,
}

export default VfHeartsCollRowItem
