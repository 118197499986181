// import moment from 'moment'
// import 'moment/locale/sq'

import { ContentManager } from '@vfgroup-oneplatform/foundation'

import FrameworkEn from '@vfgroup-oneplatform/framework/I18n/languages/en'
// import MessagesEn from '@vfgroup-oneplatform/message-center/I18n/languages/en'
import OnBoardingEn from '@vfgroup-oneplatform/onboarding/I18n/languages/en'
// import SpeedCheckerEn from '@vfgroup-oneplatform/netperform/I18n/languages/en'
import LoginEn from '@vfgroup-oneplatform/login/I18n/languages/en'
import SohoFrameworkEn from '@vfgroup-oneplatform/soho-framework/I18n/languages/en'

import BillingEn from '@vfal-lab/rn-vfg-fix-billing/I18n/languages/en'

import CallMeBackEn from '@vfal-lab/rn-vfg-call-me-back/I18n/languages/en'
import CallMeBackAl from '@vfal-lab/rn-vfg-call-me-back/I18n/languages/al'

import CatalogueEn from '@vfal-lab/rn-vfg-catalogue/I18n/languages/en'
import CatalogueAl from '@vfal-lab/rn-vfg-catalogue/I18n/languages/al'

import DataSharingEn from '@vfal-lab/rn-vfg-data-sharing/I18n/languages/en'
import DataSharingAl from '@vfal-lab/rn-vfg-data-sharing/I18n/languages/al'

import GigaBonusEn from '@vfal-lab/rn-vfg-giga-family/I18n/languages/en'
import GigaBonusAl from '@vfal-lab/rn-vfg-giga-family/I18n/languages/al'

import MigrationEn from '@vfal-lab/rn-vfg-migration/I18n/languages/en'
import MigrationAl from '@vfal-lab/rn-vfg-migration/I18n/languages/al'

import RefillEn from '@vfal-lab/rn-vfg-refill/I18n/languages/en'
import RefillAl from '@vfal-lab/rn-vfg-refill/I18n/languages/al'

import ShakeEn from '@vfal-lab/rn-vfg-shake/I18n/languages/en'
import ShakeAl from '@vfal-lab/rn-vfg-shake/I18n/languages/al'

import VdayEn from '@vfal-lab/rn-vfg-vday/I18n/languages/en'
import VdayAl from '@vfal-lab/rn-vfg-vday/I18n/languages/al'

import PromoEn from '@vfal-lab/rn-vfg-promo/I18n/languages/en'
import PromoAl from '@vfal-lab/rn-vfg-promo/I18n/languages/al'

import FixRenewalEn from '@vfal-lab/rn-vfg-renewals-fix/I18n/languages/en'
import FixRenewalAl from '@vfal-lab/rn-vfg-renewals-fix/I18n/languages/al'

import QuizEn from '@vfal-lab/rn-vfg-quiz/I18n/languages/en'
import QuizAl from '@vfal-lab/rn-vfg-quiz/I18n/languages/al'

import UnlimitedAl from '@vfal-lab/rn-vfg-unlimited-home/I18n/languages/al'
import UnlimitedEn from '@vfal-lab/rn-vfg-unlimited-home/I18n/languages/en'

import AcquisitionEn from '@vfal-lab/rn-vfg-digital-acquisition/I18n/languages/en'
import AcquisitionAl from '@vfal-lab/rn-vfg-digital-acquisition/I18n/languages/al'

import RoamingEn from '@vfal-lab/rn-vfg-roaming/I18n/languages/en'
import RoamingAl from '@vfal-lab/rn-vfg-roaming/I18n/languages/al'

import AppointmentsEn from '@vfal-lab/rn-vfg-appointments/I18n/languages/en'
import AppointmentsAl from '@vfal-lab/rn-vfg-appointments/I18n/languages/al'

import appEn from '../../I18n/languages/en'
import appAl from '../../I18n/languages/al'

import ReduxCache from '../../Utils/ReduxCache'


export default () => {
    const activeLocale = ReduxCache.getLanguage()
    ContentManager.setLocale(activeLocale)

    // moment.locale([activeLocale == 'al' ? 'sq' : activeLocale, 'en'])

    ContentManager.configureI18nTranslations({
        en: {
            ...FrameworkEn,
            ...BillingEn,
            ...CallMeBackEn,
            ...CatalogueEn,
            ...DataSharingEn,
            ...GigaBonusEn,
            ...MigrationEn,
            ...RefillEn,
            ...ShakeEn,
            ...VdayEn,
            ...LoginEn,
            ...PromoEn,
            ...FixRenewalEn,
            ...QuizEn,
            ...AcquisitionEn,
            ...SohoFrameworkEn,
            ...OnBoardingEn,
            ...RoamingEn,
            ...UnlimitedEn,
            ...AppointmentsEn,
            ...appEn,
        },
        al: {
            ...CallMeBackAl,
            ...CatalogueAl,
            ...DataSharingAl,
            ...GigaBonusAl,
            ...MigrationAl,
            ...RefillAl,
            ...ShakeAl,
            ...VdayAl,
            ...PromoAl,
            ...QuizAl,
            ...FixRenewalAl,
            ...AcquisitionAl,
            ...RoamingAl,
            ...UnlimitedAl,
            ...AppointmentsAl,
            ...appAl,
        },
    })
}
