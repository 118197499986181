import {action_types} from './demo.actionTypes'

function user(state = {loading: false, data: null}, action = {}) {
  switch (action.type) {
    case action_types.SUCCESS_PROFILE:
      return {
        ...state,
        loading: false,
        error: null,
        data: {
          ...state.data,
          ...action.payload,
        },
      }

    case action_types.ERROR_PROFILE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case action_types.REQUEST_PROFILE:
      return {
        ...state,
        loading: true,
      }

    case action_types.INITIAL_PROFILE:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          coins: action.payload.coins,
          status: action.payload.status,
          created: '',
        },
      }

    default:
      return state
  }
}

export {user}
