import { StyleSheet } from 'react-native'
import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  cardContainerStyle: theme => ({
    marginTop: 16,
    marginBottom: 20,
    paddingVertical: 20,
    marginHorizontal: 2,
    backgroundColor: theme.colors.cardBackgroundColor,
    borderRadius: 6,
    elevation: 2,
    shadowColor: 'rgba(0, 0, 0, 0.16)',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowRadius: 4,
    shadowOpacity: 1
  }),
  cardHeaderStyle: {
    paddingVertical: 20,
    paddingHorizontal: 20,
  },
  characteristicsContainer: theme => ({
    paddingHorizontal: 20,
    paddingVertical: 20,
    backgroundColor: theme.colors.backgroundColorTwo,
  }),
  row: {
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    marginRight: 20,
    marginLeft: 6
  },
  text: theme => ({
    fontSize: 16.7,
    lineHeight: 23,
    color: theme.colors.textColorThree
  }),
  dataRow: theme => ({
    paddingVertical: 16,
    marginHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.borderColor,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }),
  headerTitleViewStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
    flex: 1
  },
  statusStyle: color => ({
    fontWeight: 'bold',
    fontFamily: Fonts.type.VFFont,
    fontSize: 16.6,
    lineHeight: 23,
    color: color,
    marginTop: -2
  }),
  renewalDateStyle: theme => ({
    fontSize: 16.7,
    lineHeight: 23,
    color: theme.colors.textColorThree
  }),
  addressStyle: theme => ({
    fontSize: 14,
    lineHeight: 23,
    color: theme.colors.textColorTwo
  }),
  titleStyle: theme => ({
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: 25,
    lineHeight: 31.3,
    color: theme.colors.headerTitleColor,
    marginTop: 16.7
  }),
  priceText: theme => ({
    fontSize: 28,
    color: theme.colors.textColorThree
  }),
  priceUnit: theme => ({
    fontSize: 22,
    color: theme.colors.textColorThree
  }),
  priceContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
    paddingTop: 5
  },
  noAddonsContainer: {
    alignItems: 'center',
  },
})
