import ReduxCache from './ReduxCache'

const mapUserMenu = (configurationsRedux) => {

    const userType = ReduxCache.getUserType()

    let menu
    if (configurationsRedux['menu_' + userType] && configurationsRedux['menu_' + userType].length > 0) {
        menu = configurationsRedux['menu_' + userType]
    } else {
        menu = configurationsRedux.menu
    }

    const menu_business_user = configurationsRedux?.menu_business_user || []


    let mergedMenu = []

    if (menu && menu.length > 0) {

        menu?.forEach(menuItem => {
            const key = menuItem.key

            let mergedMenuItem

            if (key === 'usage' || key === 'itemsTobi') {
                mergedMenuItem = menuItem
            }


            const userMenuItem = menu_business_user?.find(el => el.key === key)


            if (!userMenuItem || !userMenuItem?.subItems || userMenuItem?.subItems.length === 0) {
                mergedMenuItem = menuItem
            } else {

                const item = {
                    ...menuItem,
                    subItems: []
                }

                const formattedUserMenuSubitems = userMenuItem.subItems.map(el => ({ ...el, priority: true }))

                const fomattedMenu = []
                menuItem?.subItems?.forEach(el => {
                    if (!formattedUserMenuSubitems.find(e => e.key === el.key)) {
                        fomattedMenu.push(el)
                    }
                })

                let subItems = [...formattedUserMenuSubitems, ...fomattedMenu]

                subItems = subItems.sort((a, b) => {
                    if (!a.order) {
                        return -1
                    }

                    if (parseInt(a.order, 10) < parseInt(b.order, 10)) {
                        return -1
                    }
                    if (parseInt(a.order, 10) === parseInt(b.order, 10)) {
                        if (a.priority) {
                            return -1
                        }
                    }
                    return 1
                })

                subItems = subItems.filter(el => (key !== 'itemsDiscoverDashboard' && el?.key !== 'Roaming')).map((el, index) => {
                    delete el.priority
                    return {
                        ...el,
                        order: (index + 1).toString(),
                    }
                })

                item.subItems = subItems
                mergedMenuItem = item

            }

            mergedMenu.push(mergedMenuItem)
        })

        menu_business_user.forEach(menuItem => {
            const key = menuItem.key

            const extraMenu = mergedMenu?.find(el => el.key === key)

            let extraItem

            if (!extraMenu || !extraMenu?.subItems || extraMenu?.subItems.length === 0) {
                extraItem = { ...menuItem, priority: true }
                mergedMenu.push(extraItem)
            }
        })

        mergedMenu = mergedMenu.sort((a, b) => {
            if (!a.order) {
                return -1
            }

            if (parseInt(a.order, 10) < parseInt(b.order, 10)) {
                return -1
            }
            if (parseInt(a.order, 10) === parseInt(b.order, 10)) {
                if (a.priority) {
                    return -1
                }
            }
            return 1
        })

        mergedMenu = mergedMenu.map((el, index) => {
            delete el.priority
            return {
                ...el,
                order: (index + 1).toString(),
            }
        })

    }

    return mergedMenu
}

export const getMenu = (configurationsRedux) => {

    const businessAccount = ReduxCache.getBusinessAccount()

    const userType = ReduxCache.getUserType()

    if (configurationsRedux) {
        let menu
        if (businessAccount && businessAccount?.showBusinessView && businessAccount?.sohoUserType === 'business_chooser') {
            menu = configurationsRedux.menu_business_chooser
        } else if (businessAccount && businessAccount?.sohoUserType === 'business_user') {
            menu = mapUserMenu(configurationsRedux)
        } else if (configurationsRedux['menu_' + userType] && configurationsRedux['menu_' + userType].length > 0) {
            menu = configurationsRedux['menu_' + userType]
        }

        if (!menu || menu.length === 0) {
            menu = configurationsRedux.menu
        }

        return menu

    } else {
        return null
    }

}
