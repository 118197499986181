import { StyleSheet } from 'react-native'

import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingTop: 20
  },
  scrollViewContainerStyle: withTray => ({
    paddingVertical: 16,
    paddingBottom: withTray ? 110 : 30
  }),
  headerTitle: theme => ({
    fontWeight: 'bold',
    fontSize: 25,
    lineHeight: 31,
    color: theme.colors.headerColor
  }),
  headerSubTitle: theme => ({
    fontSize: 16,
    color: theme.colors.subtitle,
    marginRight: 8,
    marginVertical: 16
  }),
  divider: theme => ({
    borderBottomColor: theme.colors.borderColorThree,
    borderBottomWidth: 1
  }),
  showMoreContainer: {
    flexGrow: 1,
    justifyContent: 'center',
    marginHorizontal: 15,
    marginTop: 12.5,
    marginBottom: 12.5
  },
  showMoreText: theme => ({
    color: theme.colors.showMoreColor,
    fontFamily: Fonts.type.VFFont,
    fontSize: 16.7,
    textAlign: 'center'
  }),
  usageValuesView: {
    marginTop: 16
  },
  usageItemsContainerStyle: {
    marginBottom: 20
  },
  upgradeButtonStyle: {
    marginVertical: 16
  },
  priceDetailsViewStyle: {
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 16
  },
  priceLabelStyle: {
    fontSize: 16.6,
    fontWeight: 'bold',
    flex: 1
  },
  priceValueViewStyle: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flex: 1
  },
  priceValueAmountStyle: {
    fontSize: 43.7,
    fontFamily: Fonts.type.VodafoneLt
  },
  priceValueUnitStyle: {
    fontSize: 29.1,
    fontFamily: Fonts.type.VodafoneLt,
    paddingBottom: 3
  },
  errorIconStyle: { marginTop: 55.8 },
  cardWrapper: (isWeb) => (isWeb ? {
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    paddingBottom: 50
  } : {})

})
