function removeNullFromObject(obj) {
    Object.keys(obj).forEach(k => {
      if (obj[k] === null)
        {delete obj[k]}
      else if (Array.isArray(obj[k]))
        {obj[k].map((_, index) => {
          removeNullFromObject(obj[k][index])
        })}
      else if (typeof obj[k] === 'object')
        {removeNullFromObject(obj[k])}
    })
}

function removeNullValues(obj) {
    if (obj)
        {if (Array.isArray(obj))
        {obj.map((_, index) => { return removeNullFromObject(obj[index]) })}
        else if (typeof obj === 'object')
        {removeNullFromObject(obj)}}
    return obj
}

const { isArray } = Array
const isNonEmptyArray = (value) => isArray(value) && value.length > 0
const isEmptyArray = (value) => !isArray(value) || value.length < 1

export {
    removeNullValues,
    isArray,
    isNonEmptyArray,
    isEmptyArray,
}
