import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { View, ScrollView, TouchableOpacity, Dimensions, KeyboardAvoidingView } from 'react-native'
import ContentLoader, { Rect } from 'react-content-loader/native'

import { ErrorScreen, VFText, Icon } from '@vfgroup-oneplatform/foundation/Components'
import { useTheme, getThemeImages } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { SearchInputContact } from '../../../../Group/Components/SearchInput'

import UsageUsersCard from './UsageUserCard'

import { sortByName } from './utils'

import styles from './Usage.Styles'


const { width: SCREEN_WIDTH } = Dimensions.get('screen')

const NO_OF_PAGES = 20

const LOADING_ARRAY = [{}, {}, {}, {}]


const Usage = ({
  usageUsers,
  usageUsersError,
  usageUsersLoading,
  tryAgain,
  scrollRef
}) => {

  const theme = useTheme()

  const Images = getThemeImages(theme.name)

  const inputRef = useRef()
  const [searchedText, setSearchedText] = useState('')

  const [filteredUsageArray, setFilteredUsageArray] = useState(usageUsers)

  const [page, setPage] = useState(0)

  const [isOpened, setIsOpened] = useState('')

  useEffect(() => {
    if (searchedText) {
      setFilteredUsageArray(usageUsers.filter(el => el?.name?.includes(searchedText)))
      if (filteredUsageArray > 0) {
        setPage(0)
      }
    } else {
      setFilteredUsageArray(usageUsers)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedText, usageUsers])


  const renderPages = () => {
    if (filteredUsageArray.length <= NO_OF_PAGES) {
      return null
    }
    return (
      <View style={styles.pageContainer}>
        {page !== 0 && <TouchableOpacity
          onPress={() => {
            setPage(prev => prev - 1)
            scrollRef?.current?._listRef?._scrollRef?.scrollTo({ x: 0, y: 0, animated: true })
          }}
          style={styles.pageButton}
        >
          <Icon name={Images.ic_chevron_right_red} style={{ transform: [{ rotate: '180deg' }] }} size={22} />
          <VFText i18nKey="soho_usage_users_prev" style={styles.pageText(theme)} />
        </TouchableOpacity>}
        {
          (page + 1) * NO_OF_PAGES < filteredUsageArray.length && <TouchableOpacity
            onPress={() => {
              setPage(prev => prev + 1)
              scrollRef?.current?._listRef?._scrollRef?.scrollTo({ x: 0, y: 0, animated: true })
            }}
            style={styles.pageButton}
          >
            <VFText i18nKey="soho_usage_users_next" style={styles.pageText(theme)} />
            <Icon name={Images.ic_chevron_right_red} size={22} />
          </TouchableOpacity >
        }
      </View >
    )
  }

  const renderLoading = () => {
    return (
      <>
        <View style={styles.searchContentContainer(theme)}>
          <ContentLoader
            speed={2}
            width={SCREEN_WIDTH - 32}
            height={120}
            backgroundColor={theme.colors.shimmerCenterColor}
            foregroundColor={theme.colors.shimmerEdgesColor}
          >
            <Rect x="0" y="10" rx="4" ry="4" width={120} height={20} />
            <Rect x="0" y="50" rx="4" ry="4" width={SCREEN_WIDTH - 32} height={55} />
          </ContentLoader>
        </View>
        {LOADING_ARRAY.map(() => <UsageUsersCard isLoading={usageUsersLoading} />)}
      </>
    )
  }

  const renderContent = () => {
    return (
      <KeyboardAvoidingView style={styles.contentContainer}>
        <VFText i18nKey="soho_usage_users_title" style={styles.title} />

        <SearchInputContact
          inputRef={inputRef}
          value={searchedText}
          onChangeText={setSearchedText}
          label="soho_usage_users_search_placeholder"

        // onSelectContact={setSearchedText}
        />
        <ScrollView>
          {sortByName(filteredUsageArray).slice(page * NO_OF_PAGES, (page + 1) * NO_OF_PAGES).map(user => <UsageUsersCard user={user} isOpened={user.id === isOpened} setIsOpened={setIsOpened} />)}
          {renderPages()}
        </ScrollView>

      </KeyboardAvoidingView>
    )
  }

  const renderError = () => {
    return (
      <ErrorScreen
        text={usageUsersError?.message}
        images={Images}
        icon="ic_warning_mid"
        onPress={tryAgain}
        textButton="soho_usage_users_error_try_again"
      />
    )
  }

  return (
    <View style={styles.container}>
      {usageUsersError ? renderError() : (usageUsersLoading ? renderLoading() : renderContent())}
    </View>
  )
}
Usage.propTypes = {
  usageUsers: PropTypes.array,
  usageUsersError: PropTypes.object,
  usageUsersLoading: PropTypes.bool,
  tryAgain: PropTypes.func,
  scrollRef: PropTypes.object
}

export default Usage
