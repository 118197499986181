import React from 'react'
import { ImageBackground, TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import styles from './PegaRecommendationOffer.Styles'

const PegaRecommendationOffer = data => {
  const { navigate } = useNavigation()
  const navigateTo = () => {
    navigate('Migration', { type: 'p2h' })
  }
  return (
    <TouchableOpacity
      activeOpacity={0.4}
      style={styles.containerStyle}
      onPress={navigateTo}
    >
      <ImageBackground
        source={{ uri: data.data.uri }}
        resizeMode="cover"
        style={styles.flex1}
      >
        {/* To be deleted or added later */}
        {/* <View style={styles.footerContainer}>
            <>
              <VFText i18nKey={'soho_dashboard_applications_see_more'} style={styles.resetTextStyle} />
              <Icon
                source={themeImages.chevron_right_white}
                size={16}
                resizeMode="contain"
              />
            </>
          </View> */}
      </ImageBackground>
    </TouchableOpacity>
  )
}

export default PegaRecommendationOffer
