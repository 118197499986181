import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'

import DigitalAcquisition from '@vfal-lab/rn-vfg-digital-acquisition/Screens'

import { Dimensions, Platform } from 'react-native'

import { getCostumer, postCostumer, productOrder } from '../../Services/Acquisition'

import ReduxCache from '../../Utils/ReduxCache'
import { MAPS_API_KEY } from '../../Config'

import { validatePersonalNumber, validatePhoneNumber } from '../../Utils/Regex'

import { GetOffers, SelectOffer, ClearOffer, GetResources } from './DigitalAcquisition.Actions'

import { countriesList, gendersList, idTypes, channelList, citiesList } from './utils'


const DigitalAcquisitionScreen = ({ navigation, route }) => {

  const offers = useSelector(state => state.acquisitionOffers?.data || [])
  const offersLoading = useSelector(state => state.acquisitionOffers?.loading)
  const [offerError, setOfferError] = useState('')

  const selectedOffer = useSelector(state => state.acquisitionSelectedOffer?.data || {})

  const resources = useSelector(state => state.acquisitionResources?.data || [])
  const [resourcesError, setResourcesError] = useState('')
  const resourcesLoading = useSelector(state => state.acquisitionResources?.loading)

  const [isCostumer, setIsCostumer] = useState(true)
  const [selectedSimNumber, setSelectedSimNumber] = useState()

  const [accountNumber, setAccountNumber] = useState()

  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [confirmLoading, setConfirmLoading] = useState(false)

  const dispatch = useDispatch()

  const msisdn = ReduxCache.getMsisdn()

  const [personalInfo, setPersonalInfo] = useState({
    name: '',
    surname: '',
    idNumber: '',
    day: '',
    month: '',
    year: '',
  })

  const [addressInfo, setAddressInfo] = useState({
    city: '',
    address: '',
    mobileNo: '',
  })

  const onClose = () => {
    if (route?.params?.isDeepLink) {
      navigation.navigate('Dashboard')
    } else {
      navigation.goBack()
    }
    dispatch(ClearOffer())
    setSelectedSimNumber()
    navigation.navigate('Dashboard')
  }

  const steps = [
    { i18nKey: 'acquisition_offer', id: 'offer' },
    { i18nKey: 'acquisition_id', id: 'id' },
    { i18nKey: 'acquisition_sim', id: 'sim' },
    { i18nKey: 'acquisition_summary', id: 'summary' },
  ]

  const onOrderConfirm = () => {

    setConfirmLoading(true)

    const data = {
      selectedOffer: selectedOffer,
      selectedSim: selectedSimNumber,
      address: addressInfo?.address,
      accountNumber: accountNumber,
      contactNo: addressInfo?.mobileNo
    }

    productOrder(data, {
      onSuccess: (res) => {
        setError(false)
        setConfirmLoading(false)
      },
      onError: err => {
        setErrorMessage(err)
        setError(true)
        setConfirmLoading(false)
      },
    })
  }

  const checkOrders = () => navigation.navigate('ManageOrders')


  const getOffers = () => {
    dispatch(GetOffers({
      onError: (err) => setOfferError(err),
    }))
  }


  const verifyCostumer = async (callback) => {
    const data = {
      ...personalInfo,
      ...addressInfo
    }
    await getCostumer(data, {
      onSuccess: (res) => {
        getAvaliableResources({
          'searchedNumber': '35569',
          'accountNumber': res?.externalReference?.name,
        })
        setAccountNumber(res?.externalReference?.name)
        setIsCostumer(true)
        callback && callback('success')
      },
      onError: (err) => {
        setPersonalInfo(prev => ({
          ...prev,
          birthplace: '',
          country: '',
          citizenship: '',
          gender: '',
          documentType: '',
          expirationDate: '',
          expirationMonth: '',
          expirationYear: '',
        }))
        setAddressInfo(prev => ({
          ...prev,
          mobileNo: '',
          countryAddress: '',
        }))
        callback && callback('error', err)
      },
    })
  }

  const onSelectOffer = async (data) => {
    dispatch(SelectOffer(data))
  }

  const getAvaliableResources = (accountNo = '') => {
    setResourcesError('')
    dispatch(GetResources(accountNo, {
      onError: err => {
        setResourcesError(err)
      },
    }))
  }

  const postForm = async (callback) => {
    const data = { ...addressInfo, ...personalInfo }

    await postCostumer(data, {
      onSuccess: (res) => {
        getAvaliableResources({
          'searchedNumber': '35569',
          'accountNumber': res?.externalReference?.name
        })
        setAccountNumber(res?.id)
        callback && callback('success')
      },
      onError: (err) => {
        callback && callback('error', err)
      },
    })
  }

  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
    return <DigitalAcquisition
      screenTitle="acquisition_screen_title"
      onClose={onClose}
      steps={steps}
      MNP={false}
      isLoading={false}
      onStartPress={getOffers}
      //offers step
      offers={offers}
      selectedOffer={selectedOffer}
      setSelectedOffer={onSelectOffer}
      offersLoading={offersLoading}
      offerError={offerError}
      //id step
      personalInfo={personalInfo}
      setPersonalInfo={setPersonalInfo}
      addressInfo={addressInfo}
      setAddressInfo={setAddressInfo}
      validatePersonalNumber={validatePersonalNumber}
      citiesList={citiesList}
      showExtendedCards={!isCostumer}
      countriesList={countriesList}
      gendersList={gendersList}
      documentTypeList={idTypes}
      validatePhoneNumber={validatePhoneNumber}
      channelList={channelList}
      postForm={postForm}
      verifyCostumer={verifyCostumer}
      setIsCostumer={setIsCostumer}
      //sim card step
      accountNumber={accountNumber}
      availableSims={resources}
      selectedSimNumber={selectedSimNumber}
      setSelectedSimNumber={setSelectedSimNumber}
      resourcesError={resourcesError}
      resourcesLoading={resourcesLoading}
      getAvaliableResources={getAvaliableResources}
      //final step
      onOrderConfirm={onOrderConfirm}
      confirmLoading={confirmLoading}
      errorMessage={errorMessage}
      error={error}
      checkOrders={checkOrders}
      //for mnp journey
      msisdn={msisdn}
      sendVerifyCode={() => { }}
      onCodeConfirm={() => { }}
      API_KEY={MAPS_API_KEY}
    />
  }


  return <DigitalAcquisition
    screenTitle="acquisition_screen_title"
    onClose={onClose}
    steps={steps}
    MNP={false}
    isLoading={false}
    onStartPress={getOffers}
    //offers step
    offers={offers}
    selectedOffer={selectedOffer}
    setSelectedOffer={onSelectOffer}
    offersLoading={offersLoading}
    offerError={offerError}
    //id step
    personalInfo={personalInfo}
    setPersonalInfo={setPersonalInfo}
    addressInfo={addressInfo}
    setAddressInfo={setAddressInfo}
    validatePersonalNumber={validatePersonalNumber}
    citiesList={citiesList}
    showExtendedCards={!isCostumer}
    countriesList={countriesList}
    gendersList={gendersList}
    documentTypeList={idTypes}
    validatePhoneNumber={validatePhoneNumber}
    channelList={channelList}
    postForm={postForm}
    verifyCostumer={verifyCostumer}
    setIsCostumer={setIsCostumer}
    //sim card step
    accountNumber={accountNumber}
    availableSims={resources}
    selectedSimNumber={selectedSimNumber}
    setSelectedSimNumber={setSelectedSimNumber}
    resourcesError={resourcesError}
    resourcesLoading={resourcesLoading}
    getAvaliableResources={getAvaliableResources}
    //final step
    onOrderConfirm={onOrderConfirm}
    confirmLoading={confirmLoading}
    errorMessage={errorMessage}
    error={error}
    checkOrders={checkOrders}
    //for mnp journey
    msisdn={msisdn}
    sendVerifyCode={() => { }}
    onCodeConfirm={() => { }}
    API_KEY={MAPS_API_KEY}
  />
}

DigitalAcquisitionScreen.propTypes = {
  navigation: PropTypes.object,
}

export default DigitalAcquisitionScreen
