import React, {Component} from 'react'
import { View, Text, ImageBackground } from 'react-native'
import {connect} from 'react-redux'
import Swiper from 'react-native-pager-view'
// import LottieView from 'lottie-react-native';

import Error from '../../Components/Error'

import Survey from '../Survey'

import { __translate, __image } from '../../Utils'

import { actions } from './openedgift.actions'

import { Images } from '../../Themes'
import styles from './openedGift.styles'

class OpenedGift extends Component {
  componentDidMount() {
    // this.props.getGift();
  }

  _retry(){
    this.props.getGift()
  }

  render () {
    const { gift } = this.props

    if (gift.loading){
      return (
        <View>
          <ImageBackground source={Images.circle_animation} style={styles.animation} />
        </View>
      )
    }

    if (!gift.loading && gift.data){
      return (
        <Swiper loop={false} paginationStyle={styles.pagination} loadMinimal={true} dotStyle={styles.dot} activeDotStyle={styles.activeDot} dotColor={'#999'} activeDotColor={'#fff'} style={styles.wrapper}>
          <View style={styles.slide1}>
            <ImageBackground
              source={{ uri: __image('reward_loyalty_unbox_page_bg') }}
              style={styles.gift_opened}
            >
              <Text style={styles.congratulation}> { __translate('reward_loyalty_unbox_message') } </Text>

              <ImageBackground source={Images.circle} style={styles.circle} />
              <View style={styles.gift}>

                <Text style={styles.normalText}> { __translate('reward_loyalty_gift') } </Text>
                <Text style={styles.giftName}> { gift.data.name } </Text>
                <Text style={styles.normalText}> { __translate('reward_loyalty_gift_validity') } </Text>
                <Text style={styles.normalBoldText}> { gift.data.validityPeriod } </Text>

              </View>
            </ImageBackground>
          </View>
          <View style={styles.slide2}>
            <Survey navigation={this.props.navigation} />
          </View>
        </Swiper>
      )
    }

    return (
      <Error message={gift.error} retry={() => this._retry()} />
    )
  }
}

const mapStateToProps = (state) => {
  const { gift, splash } = state
  return { gift, splash }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getGift: () => {
      dispatch(actions.getGift())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OpenedGift)
