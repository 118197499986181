import React, { Component } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  ImageBackground,
  Platform,
  Dimensions
} from 'react-native'
import { connect } from 'react-redux'

import Swiper from 'react-native-pager-view'
import FastImage from 'react-native-fast-image'

import { __translate, __image } from '../../Utils'
import FavouritesDetails from '../FavouritesDetails'

import styles from './info.styles'
import { Images } from '../../Themes'
import { VFText, VFScreen } from '@vfgroup-oneplatform/foundation/Components'


class Info extends Component {
  constructor(props) {
    super(props)

    let type = 'Prepaid'

    if (props.user.data && props.user.data.characteristics.COSPType) {
      type = props.user.data.characteristics.COSPType
    }

    let years = 0

    if (props.user.data && props.user.data.characteristics.firstCall) {
      const firstCall = props.user.data.characteristics.firstCall

      var dateParts = firstCall.split('-')
      var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])

      years = new Date(new Date() - dateObject).getFullYear() - 1970
    }

    this.state = {
      Prepaid: {
        row1: 'reward_loyalty_prepay_schema_info_row1',
        row2: 'reward_loyalty_prepay_schema_info_row2',
        row3: 'reward_loyalty_prepay_schema_info_row3',
        header: 'reward_loyalty_prepay_schema_header',
        desc: 'reward_loyalty_schema_info_desc_prepay',
      },
      Hybrid: {
        row1: 'reward_loyalty_hybrid_schema_info_row1',
        row2: 'reward_loyalty_hybrid_schema_info_row2',
        row3: 'reward_loyalty_hybrid_schema_info_row3',
        header: 'reward_loyalty_hybrid_schema_header',
        desc: 'reward_loyalty_schema_info_desc_hybrid',
      },
      Postpaid: {
        row1: 'reward_loyalty_postpay_schema_info_row1',
        row2: 'reward_loyalty_postpay_schema_info_row2',
        row3: 'reward_loyalty_postpay_schema_info_row3',
        header: 'reward_loyalty_postpay_schema_header',
        desc: 'reward_loyalty_schema_info_desc_postpay',
      },
      type: type,
      years: years,
    }
  }

  getContent(column, text) {
    const items = text.split('|')
    return items[column]
  }

  getHeader() {
    const text = __translate(this.state[this.state.type].header)
    const items = text.split('|')
    return items.map(item => {
      return <Text style={styles.tableColumn}>{item}</Text>
    })
  }

  render() {
    const words = this.state[this.state.type]

    if (!this.props.user.data.coins) {
      return (
        <>
          <FavouritesDetails navigation={this.props.navigation} />
          <View
            style={{
              flex: 1,
              position: 'absolute',
              bottom: 20,
              alignSelf: 'center',
            }}>
            <TouchableOpacity
              onPress={() => this.props.navigation.navigate('Details')}>
              <View
                style={{
                  ...styles.contentButton,
                  borderTopWidth: 0,
                  padding: 10,
                }}>
                <Text style={styles.contentText}>
                  {' '}
                  {__translate('reward_loyalty_schema_more_info')}{' '}
                </Text>
                <FastImage style={styles.go} source={Images.go} />
              </View>
            </TouchableOpacity>
          </View>
        </>
      )
    }

    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
      return (
        <VFScreen
          title={__translate('reward_landing_title')}
          onClose={() => { }}
          showBack={true}
          onBack={() => this.props.navigation.pop(1)}
          showClose={false}
        >
          <Swiper
            loop={true}
            paginationStyle={styles.pagination}
            loadMinimal={true}
            dotStyle={styles.dot}
            activeDotStyle={styles.activeDot}
            dotColor={'#999'}
            activeDotColor={'#fff'}
            controlsEnabled={true}
          // style={{ paddingHorizontal: '10%' }}

          >
            <View style={{ flex: 1, padding: '10%', paddingTop: 20 }}>
              <ImageBackground
                style={styles.info_bg}
                source={{
                  uri: __image('reward_loyalty_tc_bg'),
                  // priority: FastImage.priority.normal,
                }}>
                <View style={styles.topView}>
                  <View style={styles.headerText}>
                    <Text style={styles.topLabel}>
                      {' '}
                      {__translate('reward_loyalty_schema_info_header')}{' '}
                    </Text>
                  </View>

                  <View style={styles.closeIcon}>
                    <TouchableOpacity
                      style={{ padding: 10, width: 45, height: 45 }}
                      onPress={() => this.props.navigation.goBack()}>
                      <FastImage
                        style={styles.closeIcon}
                        source={Images.close_white}
                      />
                    </TouchableOpacity>
                  </View>
                </View>

                <ScrollView style={styles.scrollView}>
                  <View style={styles.tableContainer}>{this.getHeader()}</View>

                  <View style={styles.tableBody}>
                    <View style={styles.tableRow}>
                      <View
                        style={
                          this.state.years < 3 ? styles.circleBig : styles.circle
                        }>
                        <View
                          style={
                            this.state.years < 3 ? styles.years_red : styles.years
                          }>
                          <Text
                            style={
                              this.state.years < 3
                                ? styles.red_circle
                                : styles.white_circle
                            }>
                            {' '}
                            {this.getContent(
                              0,
                              __translate('reward_loyalty_prepay_schema_info_row1'),
                            )}{' '}
                          </Text>
                        </View>
                      </View>

                      <Text style={styles.text}>
                        {this.getContent(1, __translate(words.row1))}
                      </Text>
                      <Text style={styles.text}>
                        {this.getContent(2, __translate(words.row1))}
                      </Text>
                      {this.getContent(3, __translate(words.row1)) && (
                        <Text style={styles.text}>
                          {this.getContent(3, __translate(words.row1))}
                        </Text>
                      )}
                    </View>

                    <View style={styles.lineStyle} />

                    <View style={styles.tableRow}>
                      <View
                        style={
                          this.state.years >= 3 && this.state.years <= 7
                            ? styles.circleBig
                            : styles.circle
                        }>
                        <View
                          style={
                            this.state.years >= 3 && this.state.years <= 7
                              ? styles.years_red
                              : styles.years
                          }>
                          <Text
                            style={
                              this.state.years >= 3 && this.state.years <= 7
                                ? styles.red_circle
                                : styles.white_circle
                            }>
                            {this.getContent(
                              0,
                              __translate('reward_loyalty_prepay_schema_info_row2'),
                            )}
                          </Text>
                        </View>
                      </View>
                      <Text style={styles.text}>
                        {this.getContent(1, __translate(words.row2))}
                      </Text>
                      <Text style={styles.text}>
                        {this.getContent(2, __translate(words.row2))}
                      </Text>
                      {this.getContent(3, __translate(words.row2)) && (
                        <Text style={styles.text}>
                          {this.getContent(3, __translate(words.row2))}
                        </Text>
                      )}
                    </View>

                    <View style={styles.lineStyle} />

                    <View style={styles.tableRow}>
                      <View
                        style={
                          this.state.years > 7 ? styles.circleBig : styles.circle
                        }>
                        <View
                          style={
                            this.state.years > 7 ? styles.years_red : styles.years
                          }>
                          <Text
                            style={
                              this.state.years > 7
                                ? styles.red_circle
                                : styles.white_circle
                            }>
                            {this.getContent(
                              0,
                              __translate('reward_loyalty_prepay_schema_info_row3'),
                            )}
                          </Text>
                        </View>
                      </View>

                      <Text style={styles.text}>
                        {this.getContent(1, __translate(words.row3))}
                      </Text>
                      <Text style={styles.text}>
                        {this.getContent(2, __translate(words.row3))}
                      </Text>
                      {this.getContent(3, __translate(words.row3)) && (
                        <Text style={styles.text}>
                          {this.getContent(3, __translate(words.row3))}
                        </Text>
                      )}
                    </View>
                  </View>
                  <View style={styles.content}>
                    <Text style={styles.contentText}>
                      {' '}
                      {__translate(this.state[this.state.type].desc)}{' '}
                    </Text>
                    {/* <TouchableOpacity
                      onPress={() => this.props.navigation.navigate('Details')}>
                      <View style={styles.contentButton}>
                        <Text style={styles.contentText}>
                          {' '}
                          {__translate('reward_loyalty_schema_more_info')}{' '}
                        </Text>
                        <FastImage style={styles.go} source={Images.go} />
                      </View>
                    </TouchableOpacity> */}
                  </View>
                </ScrollView>
              </ImageBackground>
            </View>
            <FavouritesDetails navigation={this.props.navigation} />
          </Swiper>
        </VFScreen>
      )
    }

    return (
      <Swiper
        loop={false}
        paginationStyle={styles.pagination}
        loadMinimal={true}
        dotStyle={styles.dot}
        activeDotStyle={styles.activeDot}
        dotColor={'#999'}
        activeDotColor={'#fff'}
        controlsEnabled={true}
      >
        <View style={{ flex: 1 }}>
          <ImageBackground
            style={styles.info_bg}
            source={{
              uri: __image('reward_loyalty_tc_bg'),
              // priority: FastImage.priority.normal,
            }}>
            <View style={styles.topView}>
              <View style={styles.headerText}>
                <Text style={styles.topLabel}>
                  {' '}
                  {__translate('reward_loyalty_schema_info_header')}{' '}
                </Text>
              </View>

              <View style={styles.closeIcon}>
                <TouchableOpacity
                  style={{ padding: 10, width: 45, height: 45 }}
                  onPress={() => this.props.navigation.goBack()}>
                  <FastImage
                    style={styles.closeIcon}
                    source={Images.close_white}
                  />
                </TouchableOpacity>
              </View>
            </View>

            <ScrollView style={styles.scrollView}>
              <View style={styles.tableContainer}>{this.getHeader()}</View>

              <View style={styles.tableBody}>
                <View style={styles.tableRow}>
                  <View
                    style={
                      this.state.years < 3 ? styles.circleBig : styles.circle
                    }>
                    <View
                      style={
                        this.state.years < 3 ? styles.years_red : styles.years
                      }>
                      <Text
                        style={
                          this.state.years < 3
                            ? styles.red_circle
                            : styles.white_circle
                        }>
                        {' '}
                        {this.getContent(
                          0,
                          __translate('reward_loyalty_prepay_schema_info_row1'),
                        )}{' '}
                      </Text>
                    </View>
                  </View>

                  <Text style={styles.text}>
                    {this.getContent(1, __translate(words.row1))}
                  </Text>
                  <Text style={styles.text}>
                    {this.getContent(2, __translate(words.row1))}
                  </Text>
                  {this.getContent(3, __translate(words.row1)) && (
                    <Text style={styles.text}>
                      {this.getContent(3, __translate(words.row1))}
                    </Text>
                  )}
                </View>

                <View style={styles.lineStyle} />

                <View style={styles.tableRow}>
                  <View
                    style={
                      this.state.years >= 3 && this.state.years <= 7
                        ? styles.circleBig
                        : styles.circle
                    }>
                    <View
                      style={
                        this.state.years >= 3 && this.state.years <= 7
                          ? styles.years_red
                          : styles.years
                      }>
                      <Text
                        style={
                          this.state.years >= 3 && this.state.years <= 7
                            ? styles.red_circle
                            : styles.white_circle
                        }>
                        {this.getContent(
                          0,
                          __translate('reward_loyalty_prepay_schema_info_row2'),
                        )}
                      </Text>
                    </View>
                  </View>
                  <Text style={styles.text}>
                    {this.getContent(1, __translate(words.row2))}
                  </Text>
                  <Text style={styles.text}>
                    {this.getContent(2, __translate(words.row2))}
                  </Text>
                  {this.getContent(3, __translate(words.row2)) && (
                    <Text style={styles.text}>
                      {this.getContent(3, __translate(words.row2))}
                    </Text>
                  )}
                </View>

                <View style={styles.lineStyle} />

                <View style={styles.tableRow}>
                  <View
                    style={
                      this.state.years > 7 ? styles.circleBig : styles.circle
                    }>
                    <View
                      style={
                        this.state.years > 7 ? styles.years_red : styles.years
                      }>
                      <Text
                        style={
                          this.state.years > 7
                            ? styles.red_circle
                            : styles.white_circle
                        }>
                        {this.getContent(
                          0,
                          __translate('reward_loyalty_prepay_schema_info_row3'),
                        )}
                      </Text>
                    </View>
                  </View>

                  <Text style={styles.text}>
                    {this.getContent(1, __translate(words.row3))}
                  </Text>
                  <Text style={styles.text}>
                    {this.getContent(2, __translate(words.row3))}
                  </Text>
                  {this.getContent(3, __translate(words.row3)) && (
                    <Text style={styles.text}>
                      {this.getContent(3, __translate(words.row3))}
                    </Text>
                  )}
                </View>
              </View>
              <View style={styles.content}>
                <Text style={styles.contentText}>
                  {' '}
                  {__translate(this.state[this.state.type].desc)}{' '}
                </Text>
                <TouchableOpacity
                  onPress={() => this.props.navigation.navigate('Details')}>
                  <View style={styles.contentButton}>
                    <Text style={styles.contentText}>
                      {' '}
                      {__translate('reward_loyalty_schema_more_info')}{' '}
                    </Text>
                    <FastImage style={styles.go} source={Images.go} />
                  </View>
                </TouchableOpacity>
              </View>
            </ScrollView>
          </ImageBackground>
        </View>
        <FavouritesDetails navigation={this.props.navigation} />
      </Swiper>
    )
  }
}

const mapStateToProps = state => {
  const { user, splash } = state
  return { user, splash }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Info)
