import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { getThemeImages, useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { Plan } from '@vfgroup-oneplatform/soho-framework/BusinessOverview'

import NavigationService from '../../../Navigation/NavigationService'

import { BusinessOverviewServices } from '../../../Services/Business'

const PlanScreen = ({ navigation, route }) => {

  const theme = useTheme()
  const images = getThemeImages(theme.name)

  const [isRemovingUsersInProgress, setIsRemovingUsersInProgress] = useState(false)
  const [removeUserError, setRemoveUserError] = useState()

  const [isPlansExtrasLoading, setIsPlansExtrasLoading] = useState(false)
  const [addExtraPlanData, setAddExtraPlanData] = useState([])
  const [plansExtrasError, setPlansExtrasError] = useState()

  const { plan } = route?.params || {}

  const onRemoveUserPress = (user) => {
    setRemoveUserError()
    setIsRemovingUsersInProgress(true)
    BusinessOverviewServices.removeUserFromPlan(plan.id, user.id, {
      onSuccess: () => {
        setIsRemovingUsersInProgress(false)
      },
      onError: (err) => {
        setRemoveUserError(err)
        setIsRemovingUsersInProgress(false)
      }
    })
  }

  const onViewProfilePress = (user) => {
    navigation.navigate('ProfileManagement', { user })
  }

  const onAddUsagePress = (usageItem, toggleAddUsageModal, planId) => {
    setIsPlansExtrasLoading(true)
    setPlansExtrasError(null)
    toggleAddUsageModal(usageItem.usageType?.toLowerCase())
    BusinessOverviewServices.getPlansExtrasData(planId, {
      onSuccess: res => {
        setAddExtraPlanData(res)
        setIsPlansExtrasLoading(false)
      },
      onError: (err) => {
        setIsPlansExtrasLoading(false)
        setPlansExtrasError(err)
      }
    })
  }

  const onReturnToDashboardPress = () => {
    setTimeout(() => {
      NavigationService.popToTop()
    }, 50)
  }

  const onAddUserPress = (planObj) => {
    navigation.navigate('PlanUsers', { plan: planObj })
  }

  const onClose = () => {
    NavigationService.popToTop()
  }

  const onBack = () => {
    navigation.goBack()
  }

  return (
    <Plan
      vfScreenProps={{
        onClose: onClose,
        onBack: onBack,
        showBack: true,
      }}
      images={images}
      plan={plan}
      withTray={true}
      onViewProfilePress={onViewProfilePress}
      onReturnToDashboardPress={onReturnToDashboardPress}
      onReturnToPlanPress={() => { }}
      withViewPlanButton={false}
      withUpgradePlanButton={false}
      //add users props
      onAddUserPress={onAddUserPress}
      withAddUser={false}
      //add usage props
      onAddUsagePress={onAddUsagePress}
      shouldShowAddUsageButton={false}
      onAddUsageConfirmationPress={() => { }}
      isPlansExtrasLoading={isPlansExtrasLoading}
      addExtraPlanData={addExtraPlanData}
      plansExtrasError={plansExtrasError}
      //remove users props
      onRemovePress={onRemoveUserPress}
      onRemoveTryAgainPress={onRemoveUserPress}
      isRemovingUsersInProgress={isRemovingUsersInProgress}
      removeUserError={removeUserError}
      withRemoveUser={false}
    />
  )
}
PlanScreen.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
}

export default PlanScreen
