
import { GeographicAddress, GeographicAddressValidation } from '../../Services/Fix/GeographicAddress'

const actionTypes = {
    REQUEST_GEOGRAPHIC_ADDRESS: 'REQUEST_GEOGRAPHIC_ADDRESS',
    SUCCESS_GEOGRAPHIC_ADDRESS: 'SUCCESS_GEOGRAPHIC_ADDRESS',
    ERROR_GEOGRAPHIC_ADDRESS: 'ERROR_GEOGRAPHIC_ADDRESS',
    REQUEST_GEOGRAPHIC_ADDRESS_VALIDATION: 'REQUEST_GEOGRAPHIC_ADDRESS_VALIDATION',
    SUCCESS_GEOGRAPHIC_ADDRESS_VALIDATION: 'SUCCESS_GEOGRAPHIC_ADDRESS_VALIDATION',
    ERROR_GEOGRAPHIC_ADDRESS_VALIDATION: 'ERROR_GEOGRAPHIC_ADDRESS_VALIDATION',
}



const errorMapper = (error) => {
    const payload = error?.response?.data?.message || error?.message
    return payload
}


const mapPolygonCoords = (polygons = []) => {
    if (polygons.length !== 0) {
        const newPolygonCoords = polygons.map((_item, i) => {
            return {
                latitude: (_item?.y < 90 && _item?.y > -90) ? _item.y : 0,
                longitude: (_item?.x < 180 && _item.y > -180) ? _item.x : 0,
            }
        })
        return newPolygonCoords
    } else {
        return []
    }
}

const responseMapper = (res) => {
    const mappedData = []

    const { data } = res

    if (data.length !== 0) {
        data.map((item, _i) => {
            mappedData.push({
                id: _i,
                city: item?.city || '',
                country: item?.country || '',
                polygonCoords: mapPolygonCoords(item?.geographicLocation?.geometry)
            })
        })
        return mappedData
    } else {
        return []
    }
}

const customHeaders = {
    'vf-country-code': 'al',
}

const getGeographicAddress = (city, utilities = {}) => {
    return async dispatch => {

        dispatch({
            type: actionTypes.REQUEST_GEOGRAPHIC_ADDRESS,
        })

        GeographicAddress(city, customHeaders, {
            responseMapper,
            errorMapper,
            ...utilities,
        })
            .then(response => dispatch({
                type: actionTypes.SUCCESS_GEOGRAPHIC_ADDRESS,
                payload: response,
            }))
            .catch(error => dispatch({
                type: actionTypes.ERROR_GEOGRAPHIC_ADDRESS,
                payload: error,
            }))
    }
}

const validationMapper = (res) => {
    return res?.data[0]
}
const geographicAddressValidation = (coords, utilities = {}) => {
    return async dispatch => {

        dispatch({
            type: actionTypes.REQUEST_GEOGRAPHIC_ADDRESS_VALIDATION,
        })

        GeographicAddressValidation(coords, customHeaders, {
            ...utilities,
            responseMapper: validationMapper,
            errorMapper,
        })
            .then(response => dispatch({
                type: actionTypes.SUCCESS_GEOGRAPHIC_ADDRESS_VALIDATION,
                payload: { ...response },
            }))
            .catch(error => dispatch({
                type: actionTypes.ERROR_GEOGRAPHIC_ADDRESS_VALIDATION,
                payload: error,
            }))
    }
}

const geographicAddressUpdate = (street) => {
    return async dispatch => {
        dispatch({
            type: actionTypes.SUCCESS_GEOGRAPHIC_ADDRESS_VALIDATION,
            payload: { street: street },
        })
    }
}

const actions = {
    getGeographicAddress,
    geographicAddressValidation,
    geographicAddressUpdate
}
export {
    actionTypes,
    actions,
}
