import { actionTypes } from './FixPurchaseCoverage.Actions'

function geographicAddress(state = { loading: false, data: null }, action = {}) {
    switch (action.type) {
        case actionTypes.REQUEST_GEOGRAPHIC_ADDRESS:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.SUCCESS_GEOGRAPHIC_ADDRESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload,
            }
        case actionTypes.ERROR_GEOGRAPHIC_ADDRESS:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}

function geographicAddressValidation(state = { loading: false, data: null }, action = {}) {
    switch (action.type) {
        case actionTypes.REQUEST_GEOGRAPHIC_ADDRESS_VALIDATION:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.SUCCESS_GEOGRAPHIC_ADDRESS_VALIDATION:
            return {
                ...state,
                loading: false,
                error: null,
                data: {
                    ...state.data,
                    ...action.payload
                },
            }
        case actionTypes.ERROR_GEOGRAPHIC_ADDRESS_VALIDATION:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}



export { geographicAddress, geographicAddressValidation }
