import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { ProfileManagement } from '@vfgroup-oneplatform/soho-framework/ProfileManagement'
import { TABS_LABELS } from '@vfgroup-oneplatform/soho-framework/BusinessOverview/Utils/Enums'
import { Plans } from '@vfgroup-oneplatform/soho-framework/BusinessOverview'
import { VFText } from '@vfgroup-oneplatform/foundation/Components'

import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { BusinessOverviewServices } from '../../../Services/Business'

import ReduxCache from '../../../Utils/ReduxCache'

import { getThemeImages } from '../../../Group/Themes'

import styles from './ProfileManagement.Styles'


const ProfileManagementScreen = ({ navigation, route }) => {

  const { user } = route?.params || {}

  const theme = useTheme()
  const images = getThemeImages(theme.name)

  const businessName = ReduxCache.getBusinessAccount()?.businessName || ''

  const insets = useSafeAreaInsets()

  const [isLoadingPlans, setIsLoadingPlans] = useState(false)
  const [plans, setPlans] = useState([])
  const [plansError, setPlansError] = useState()


  const getPlansData = useCallback(() => {
    setIsLoadingPlans(true)
    setPlansError()
    BusinessOverviewServices.getPlans(user.id, 'employee', {
      onSuccess: (res) => {
        setPlans(res)
        setIsLoadingPlans(false)
      },
      onError: (err) => {
        setPlansError(err)
        setIsLoadingPlans(false)
      }
    })
  }, [user])


  useEffect(() => {
    getPlansData()
  }, [getPlansData])


  const onClose = () => {
    navigation.pop(2)
  }

  const onBack = () => navigation.goBack()

  return <ProfileManagement
    accountNo={user.id || ''}
    user={user || {}}
    onClose={onClose}
    onBack={onBack}
    onActiveTabChange={() => { }}
    images={images}
    userName={businessName}
    showBack
    tabsToExclude={[TABS_LABELS.DEVICES, TABS_LABELS.LICENCES, TABS_LABELS.USAGE, TABS_LABELS.ADDONS, TABS_LABELS.PLANS]}
    subHeaderFullContainerStyle={styles.subHeaderFullContainerStyle}
    licencesProps={{
      applications: []
    }}
    subHeaderTitleStyle={styles.subHeaderTitleStyle(theme)}
    subHeaderBeforeSubTitle="soho_profile_management_account_number"
    subHeaderBeforeSubTitleStyle={styles.subHeaderBeforeSubTitleStyle(theme)}
    subHeaderSubTitleStyle={styles.subHeaderSubTitleStyle(theme)}
    subHeaderMaxHeight={70}
    containerStyle={{ paddingBottom: insets.bottom }}
    customTabs={[
      {
        index: 0,
        key: 'Plans',
        tab_key: 'soho_business_overview_plans_tab_title',
        renderTabContent: () => {
          return <>
            {(plans.length !== 0 || isLoadingPlans || plansError) && <Plans
              withPageBanner={false}
              isLoading={isLoadingPlans}
              plans={plans}
              withPriceVat={false}
              withViewPlanButton={false}
              error={plansError}
              onTryAgainPress={getPlansData}
              withUpgradePlanButton={false}
              shouldShowAddUsageButton={false}
              usageValuesTextStyle={{ color: theme.colors.textColor }}
              images={images}
              withUsers={false}
            />}
            {(plans.length === 0 && !isLoadingPlans && !plansError) && <View style={styles.emptyContainer} >
              <VFText i18nKey="soho_plans_empty_title" style={styles.emptyTitle} />
            </View>}
          </>
        }
      }
    ]}
  />
}
ProfileManagementScreen.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object
}

export default ProfileManagementScreen
