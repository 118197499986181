import React from 'react'
import PropTypes from 'prop-types'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import RoamingScreen from '@vfal-lab/rn-vfg-roaming'

import ReduxCache from '../../Utils/ReduxCache'
import { getRoamingRecommandation, getRoamingOffers, getCountryFromCoordinates } from '../../Services/Roaming'


const Roaming = ({ navigation }) => {

  const onClose = () => navigation.pop()

  const MSISDN = ReduxCache.getMsisdn()
  const userType = ReduxCache.getUserType()
  const language = ReduxCache.getLanguage()

  const theme = useTheme()

  return (
    <RoamingScreen
      navigate={navigation.navigate}
      onClose={onClose}
      MSISDN={MSISDN}
      userType={userType}
      getRoamingRecommendationOffer={getRoamingRecommandation}
      getRoamingOfferBundles={getRoamingOffers}
      getCountryFromCoordinates={getCountryFromCoordinates}
      theme={theme}
      dataUrl={language === 'en' ? 'https://api-web-ci-vfal.vodafone.com/file/roaming/en.json' : 'https://api-web-ci-vfal.vodafone.com/file/roaming/al.json'}
    />
  )
}

Roaming.propTypes = {
  navigation: PropTypes.object,
}

export default Roaming
