import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
    container: {
        paddingTop: 25,
    },
    line: {
        marginVertical: 10,
    },
    wrapper: {
        paddingBottom: 24
    }
})

export { styles }
