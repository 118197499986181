import React from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'

import { VFText, Icon, VFInput, VFButton } from '@vfgroup-oneplatform/foundation/Components'
import { Colors, useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { getThemeImages } from '../../Group/Themes'

import { styles } from './EditProfile.Styles'

function CardContent({ username, setUsername, onPress, inputRef }) {
    const theme = useTheme()
    const Images = getThemeImages(theme.name)

    return (
        <>
            <View style={styles.card}>
                <View style={styles.cardHeader}>
                    <Icon
                        name={Images.ic_mobile}
                        size={30}
                        resizeMode="contain"
                        type="image"
                        style={styles.mobileIcon}
                    />
                    <View>
                        <VFText
                            i18nKey="profile_settings_name_label"
                            style={styles.header}
                            color={theme.colors.textColor}
                        />
                        <VFText
                            i18nKey="profile_settings_name_helper"
                            style={styles.subTitle(theme)}
                            color={theme.colors.textColorTwo}
                        />
                    </View>
                </View>
                <View style={styles.contactContainer}>
                    <VFInput
                        ref={ref => {
                            inputRef.current = ref
                        }}
                        label="profile_settings_name_input_label"
                        value={username}
                        onChangeText={setUsername}
                        containerStyle={styles.inputContainer}
                        labelTextStyle={styles.inputText}
                        style={styles.inputText(theme)}
                        baseColor={theme.colors.textInput.default.baseColor}
                        textColor={theme.colors.textColor}
                        tintColor={theme.colors.textInput.default.tintColor}
                        showHelperText={username?.length > 12}
                        helperTextColor={Colors.vfRed}
                        helperText="profile_settings_name_helper_error"
                        showRightIcon
                        rightIconName={Images.ic_admin}
                        disableRightIconPress={true}
                    />
                </View>
            </View>
            <View style={styles.separator(theme)} />
            <VFButton
                title="profile_settings_save_button"
                onPress={onPress}
                style={styles.button}
                disabled={!username || username?.length > 12}
            />
        </>

    )
}
CardContent.propTypes = {
    username: PropTypes.string,
    setUsername: PropTypes.func,
    onPress: PropTypes.func,
    inputRef: PropTypes.object
}

export default CardContent
