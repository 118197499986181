import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import { USAGE_TYPES } from '@vfgroup-oneplatform/soho-framework/BusinessOverview/Utils/Enums'

import { API_URLS } from '../../Config'

import { convertMB, convertMbTo } from '../../../Utils/Helpers'

const mapError = (error) => {
  const payload = error?.response?.data?.message || error?.message
  return {
    message: payload
  }
}

const iconMapper = (usageType) => {
  if (usageType.includes(USAGE_TYPES.DATA)) {
    return 'ic_data_sharing'
  } else if (usageType.includes(USAGE_TYPES.CALLS)) {
    return 'ic_call_log'
  } else if (usageType.includes(USAGE_TYPES.SMS)) {
    return 'ic_sms_text'
  } else {
    return ''
  }
}

const usersUsageResponseMapper = (res) => {

  const mappedResponse = []

  const { bucket } = res?.[0]

  bucket.map(bucketItem => {
    const { usageType, bucketCounter, name } = bucketItem

    const totalUsageObj = bucketCounter?.find(el => el.counterType === 'global') || {}
    const usedUsageObj = bucketCounter?.find(el => el.counterType === 'used') || {} //is actually remainingg amount

    const isUnlimited = totalUsageObj?.valueName === 'Unlimited'


    const { amount: totalAmount, units: totalUnits } = convertMB(totalUsageObj?.value?.units, totalUsageObj?.value?.amount)

    const { amount: usedAmount, units: usedUnits } = convertMbTo(totalUnits, usedUsageObj?.value?.amount, usedUsageObj?.value?.units)

    const leftAmount = usedAmount
    const valuePercentage = parseInt(leftAmount / totalAmount * 100, 10)

    mappedResponse.push({
      usageType,
      icon: iconMapper(usageType),
      totalAmount: parseFloat(Math.round(totalAmount * 100) / 100, 10)?.toString(),
      usedAmount: parseFloat(Math.round(leftAmount * 100) / 100, 10)?.toString(),
      usedUnits,
      totalUnits,
      isUnlimited,
      valuePercentage,
      name
    })
  })


  return mappedResponse
}


const getUsageByUsers = async (userId, utilities) => {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
    responseMapper: usersUsageResponseMapper,
  })

  const { onStart, onSuccess, onError, errorMapper, responseMapper } = _utilities


  onStart()

  const headers = {
    'vf-project': 'soho',
  }

  const params = {
    "relatedParty[role=='employee'].id": userId,
    "usageOverview.usage[status=='rated'].usageType": 'Data,SMS,Calls'
  }

  try {
    const response = await axios.get(API_URLS.business.busninessOverview.usageUsers, {
      headers: headers,
      params: params
    })
    const _response = responseMapper(response?.data)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

export {
  getUsageByUsers,
}
