/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
// eslint-disable-next-line no-unused-vars
import { Animated, Easing } from 'react-native'
import PropTypes from 'prop-types'
import { VFText } from '@vfgroup-oneplatform/foundation/Components'

import styles from './DeactivateAccount.Styles'

function DescriptionCard({ description }) {
    const startingHeight = 130
    const animatedHeight = useRef(new Animated.Value(startingHeight)).current

    useEffect(() => {
        Animated.timing(animatedHeight, {
            duration: 300,
            toValue: startingHeight,
            easing: Easing.inOut(Easing.cubic),
        }).start()
    }, [])

    return (
        <>
            <Animated.View style={{ height: animatedHeight }} >
                <VFText type="primary" i18nKey={description} style={styles.description} />
            </Animated.View>
            {/* <TouchableOpacity onPress={onShowMorePress} style={styles.footer}>
                <View style={styles.separator(15)} />
                <VFText i18nKey={expanded ? 'delete_account_less_button' : 'delete_account_more_button'} style={styles.footerText} />
            </TouchableOpacity> */}
        </>
    )
}

DescriptionCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
}

export default DescriptionCard
