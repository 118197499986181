import React from 'react'
import PropTypes from 'prop-types'
import { Icon, VFButton, VFText } from '@vfgroup-oneplatform/foundation/Components'
import { View } from 'react-native'
import WebView from 'react-native-webview'

import Images from '../../Themes/Images'
import styles from './CocaCola.Styles'

import LoadingHTML from './LoadingHTML'

const ErrorComponent = ({onClose, onRetry, error}) => {
  return (
    <View style={styles.errorContainer}>
      <View style={styles.errorContentWraper}>
          <Icon
            name={Images.coca_cola_error}
            size={95}
            type="image"
            resizeMode="contain"
          />
          <VFText
            i18nKey="cocacola_error_title"
            style={styles.errorTitle}
          />
          <VFText
            style={styles.errorDescription}
            i18nKey={error}
          />
        </View>
        <VFButton
          type="primary"
          title="cocacola_error_try_again"
          onPress={onRetry}
        />
        <VFButton
          type="secondary"
          title="cocacola_error_close"
          style={styles.tryButton}
          onPress={onClose}
        />
    </View>
  )
}

const SuccessComponent = ({onClose, value, date}) => {
  return (
    <View style={styles.errorContainer}>
      <View style={styles.errorContentWraper}>
          <Icon
            name={Images.coca_cola_success}
            size={95}
            type="image"
            resizeMode="contain"
          />
          <VFText
            i18nKey="cocacola_success_title"
            style={styles.errorTitle}
          />
          <VFText
            style={styles.errorDescription}
            i18nKey={'cocacola_success_description'}
            placeHolders={[value, date]}
          />
        </View>
        <VFButton
          type="primary"
          title="cocacola_error_close"
          style={styles.tryButton}
          onPress={onClose}
        />
    </View>
  )
}

const NoProductFound = ({onClose}) => {
    return (
      <View style={styles.errorContainer}>
        <View style={styles.errorContentWraper}>
            <Icon
              name={Images.coca_cola_not_found}
              size={95}
              type="image"
              resizeMode="contain"
            />
            <VFText
              i18nKey="cocacola_notfound_title"
              style={styles.errorTitle}
            />
            <VFText
              style={styles.errorDescription}
              i18nKey={'cocacola_notfound_description'}
            />
          </View>

          <VFButton
            type="secondary"
            title="cocacola_notfound_close"
            style={styles.tryButton}
            onPress={onClose}
          />
      </View>
    )
}

const LoadingComponent = () => {
  return (
    <WebView style={styles.webviewLoader} source={{html: LoadingHTML}} />
  )
}

ErrorComponent.propTypes = {
  onClose: PropTypes.func,
  onRetry: PropTypes.func,
  error: PropTypes.string,
}

SuccessComponent.propTypes = {
  onClose: PropTypes.func,
  value: PropTypes.string,
  date: PropTypes.string,
}

NoProductFound.propTypes = {
  onClose: PropTypes.func
}

export { ErrorComponent, NoProductFound, LoadingComponent, SuccessComponent }
