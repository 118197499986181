import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { VFButton } from '@vfgroup-oneplatform/foundation/Components'

import SoHoBusinessOverview, { Plan, Plans } from '@vfgroup-oneplatform/soho-framework/BusinessOverview'
import { TABS_LABELS } from '@vfgroup-oneplatform/soho-framework/BusinessOverview/Utils/Enums'

import { getThemeImages } from '../../../Group/Themes'

import { BusinessOverviewServices, UsersAndGroupServices } from '../../../Services/Business'

import ReduxCache from '../../../Utils/ReduxCache'

import Usage from './CustomUsageTab/Usage'


const BusinessOverview = ({ navigation }) => {

  const theme = useTheme()
  const images = getThemeImages(theme.name)

  const scrollRef = useRef()

  const [activeTab, setActiveTab] = useState(0)

  const organizationId = ReduxCache.getBusinessAccount()?.businessID

  //users props
  const [users, setUsers] = useState('')

  //billingProps
  const [totalValue, setTotalValue] = useState(null)
  const [totalUnit, setTotalUnit] = useState(null)
  const [billInfo, setBillInfo] = useState(false)

  //plans props
  const [isLoadingPlans, setIsLoadingPlans] = useState(true)
  const [plans, setPlans] = useState([])
  const [plansError, setPlansError] = useState()

  const [usageUsers, setUsageUsers] = useState([])
  const [usageUsersLoading, setUsageUsersLoading] = useState(false)
  const [usageUsersError, setUsageUsersError] = useState()

  const getPlansData = useCallback(() => {
    setIsLoadingPlans(true)
    setPlansError()
    BusinessOverviewServices.getPlans(organizationId, 'organization', {
      onSuccess: (res) => {
        setPlans(res)
        setIsLoadingPlans(false)
        const usersArray = []
        let total = 0
        let units = ''
        let withBillingInfo = false

        res.forEach(plan => {
          plan.users.forEach(user => {
            if (!usersArray.includes(user.id)) {
              usersArray.push(user.id)
            }
          })
          if (plan.withPrice) {
            total = total + (parseFloat(plan?.price, 10) * plan?.users?.length)
            units = plan?.currency
          }
          withBillingInfo = withBillingInfo || plan.withPrice
        })

        setTotalValue(total.toFixed(2)?.toString())
        setTotalUnit(units)
        setBillInfo(withBillingInfo)

        setUsers(usersArray?.length?.toString())
      },
      onError: (err) => {
        setPlansError(err)
        setIsLoadingPlans(false)
      }
    })
  }, [organizationId])

  const getUsageUsersData = useCallback(() => {
    setUsageUsersError()
    setUsageUsersLoading(true)
    UsersAndGroupServices.getUsers(organizationId, {
      onSuccess: res => {
        setUsageUsersLoading(false)
        setUsageUsers(res)
      },
      onError: err => {
        setUsageUsersError(err)
        setUsageUsersLoading(false)
      }
    })
  }, [organizationId])


  useEffect(() => {
    if (activeTab === 0 && plans.length === 0) {
      getPlansData()
    }
    if (activeTab === 1 && usageUsers.length === 0) {
      getUsageUsersData()
    }
  }, [activeTab, getUsageUsersData, getPlansData, plans, usageUsers])


  const onViewPlanPress = (plan) => {
    navigation.navigate('Plan', { plan })
  }

  const onBillingPress = () => {
    navigation.navigate('Billing')
  }

  const onViewProfilePress = (user) => {
    navigation.navigate('ProfileManagement', { user })
  }

  const onClose = () => {
    navigation.pop()
  }

  return <SoHoBusinessOverview
    onClose={onClose}
    onActiveTabChange={(index) => setActiveTab(index)}
    images={images}
    tabsToExclude={[TABS_LABELS.DEVICES, TABS_LABELS.LICENCES, TABS_LABELS.USAGE, TABS_LABELS.ADDONS, TABS_LABELS.PLANS, TABS_LABELS.BUDGET, TABS_LABELS.SETTINGS]}
    usersProps={{
      numberOfUsers: users,
      isLoading: isLoadingPlans,
    }}
    billingProps={{
      currentBillValue: totalValue ? (totalValue + ' ' + totalUnit) : ' ',
      withBillingButton: false,
      withCurrentBillInfo: billInfo ? true : false,
      onBillingPress: onBillingPress,
      isLoading: isLoadingPlans,
    }}
    vfScreenProps={{
      getScrollViewRef: scrollRef,
      subHeaderRightComponentStyle: { paddingBottom: 0 },
      subHeaderMaxHeight: 55
    }}
    customTabs={[
      {
        index: 1,
        key: 'Usage',
        tab_key: 'soho_business_overview_usage_tab_title',
        renderTabContent: () => <Usage
          usageUsers={usageUsers}
          usageUsersError={usageUsersError}
          usageUsersLoading={usageUsersLoading}
          tryAgain={getUsageUsersData}
          scrollRef={scrollRef}
        />
      },
      {
        index: 0,
        key: 'Plans',
        tab_key: 'soho_business_overview_plans_tab_title',
        renderTabContent: () => {
          return <>
            {(isLoadingPlans || plansError || plans.length !== 1) && <Plans
              withPageBanner={false}
              isLoading={isLoadingPlans}
              plans={plans}
              withPriceVat={false}
              onViewPlanPress={onViewPlanPress}
              error={plansError}
              onTryAgainPress={getPlansData}
            />}
            {(!isLoadingPlans && !plansError && plans.length === 1) && <Plan
              plan={plans[0]}
              withPriceVat={false}
              withViewPlanButton={false}
              withUpgradePlanButton={false}
              shouldShowAddUsageButton={false}
              withAddUser={false}
              images={images}
              withRemoveUser={false}
              onViewProfilePress={onViewProfilePress}
              vfScreenProps={{
                withHeader: false
              }}
            />}
          </>
        }
      }
    ]}
    renderSubHeaderRightComponent={() => !isLoadingPlans && <VFButton
      title="soho_business_overview_billing_button_text"
      onPress={onBillingPress}
      type="primary"
      // eslint-disable-next-line react-native/no-inline-styles
      style={{ height: 32, width: 82 }}
    />}
  />

}
BusinessOverview.propTypes = {
  navigation: PropTypes.object,
}

export default BusinessOverview
