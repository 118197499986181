import { isFunction as _isFunction } from 'lodash'
import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import axios from 'axios'

import { API_URLS, CYBERSOURCE_COMMON_HEADERS } from '../Config'


const createCustomer = async (
  token,
  data,
  customHeaders = {},
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities


  onStart()

  const headers = {
    ...CYBERSOURCE_COMMON_HEADERS,
    'Authorization': 'Bearer ' + token
  }

  try {
    const response = await axios.post(`${API_URLS.baseCyberSourceUrl}/tms/v2/customers`,
      data,
      {
        headers: headers
      },
      false,
    )

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const deleteCustomer = async (
  token,
  customerId,
  customHeaders = {},
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, errorMapper } = _utilities


  onStart()

  const headers = {
    ...CYBERSOURCE_COMMON_HEADERS,
    'Authorization': 'Bearer ' + token
  }

  try {
    const response = await axios.delete(`${API_URLS.baseCyberSourceUrl}/tms/v2/customers/${customerId}`,
      {
        headers: headers
      },
      false,
    )

    onSuccess(response)
    return response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const createCard = async (
  token,
  data,
  customHeaders = {},
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities


  onStart()

  const headers = {
    ...CYBERSOURCE_COMMON_HEADERS,
    'Authorization': 'Bearer ' + token
  }

  try {
    const response = await axios.post(`${API_URLS.baseCyberSourceUrl}/tms/v1/instrumentidentifiers`,
      data,
      {
        headers: headers
      },
      false,
    )

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const createTokenizesCard = async (
  data,
  customHeaders = {},
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities


  onStart()

  const headers = {
    'Bypas-Token': true
  }

  try {
    const response = await axios.post(API_URLS.flex,
      data,
      {
        headers: headers
      },
      false,
    )

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const createCustomerPaymentInstrument = async (
  token,
  customerTokenId,
  data,
  customHeaders = {},
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities


  onStart()

  const headers = {
    ...CYBERSOURCE_COMMON_HEADERS,
    'Authorization': 'Bearer ' + token
  }

  try {
    const response = await axios.post(`${API_URLS.baseCyberSourceUrl}/tms/v2/customers/${customerTokenId}/payment-instruments`,
      data,
      {
        headers: headers
      },
      false,
    )

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const setDefaultCustomerCard = async (
  token,
  customerTokenId,
  data,
  customHeaders = {},
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities


  onStart()

  const headers = {
    ...CYBERSOURCE_COMMON_HEADERS,
    'Authorization': 'Bearer ' + token
  }

  try {
    const response = await axios.patch(`${API_URLS.baseCyberSourceUrl}/tms/v2/customers/${customerTokenId}`,
      data,
      {
        headers: headers
      },
      false,
    )

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const deleteCustomerCard = async (
  token,
  customerTokenId,
  cardId,
  data,
  customHeaders = {},
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities


  onStart()

  const headers = {
    ...CYBERSOURCE_COMMON_HEADERS,
    'Authorization': 'Bearer ' + token
  }

  try {
    const response = await axios.delete(`${API_URLS.baseCyberSourceUrl}/tms/v2/customers/${customerTokenId}/payment-instruments/${cardId}`,
      {
        headers: headers
      },
      data,
      false,
    )

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const processPayment = async (
  token,
  data,
  customHeaders = {},
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities


  onStart()

  const headers = {
    ...CYBERSOURCE_COMMON_HEADERS,
    'Authorization': 'Bearer ' + token
  }

  try {
    const response = await axios.post(`${API_URLS.baseCyberSourceUrl}/pts/v2/payments`,
      data,
      {
        headers: headers
      },
      false,
    )

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const getPaymentInstruments = async (
  token,
  customerTokenId,
  customHeaders = {},
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities


  onStart()

  const headers = {
    ...CYBERSOURCE_COMMON_HEADERS,
    'Authorization': 'Bearer ' + token
  }

  try {
    const response = await axios.get(`${API_URLS.baseCyberSourceUrl}/tms/v2/customers/${customerTokenId}/payment-instruments`,
      {
        headers: headers
      }
    )

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

export {
  createCustomer,
  createCard,
  createCustomerPaymentInstrument,
  setDefaultCustomerCard,
  processPayment,
  getPaymentInstruments,
  createTokenizesCard,
  deleteCustomerCard,
  deleteCustomer
}
