/* eslint-disable import/exports-last */
import { LoginManager } from '@vfgroup-oneplatform/login'

import Login from '../Containers/Login'
import VerifyCodeImplementation from '../Containers/VerifyCode'
import RegisterImplementation from '../Containers/Register'

import NavigationService from '../Navigation/NavigationService'

import { actionTypes } from '../Containers/Login/Login.Actions'

import { store } from '../Redux'

import ReduxCache from './ReduxCache'

import { getMsisdnData } from './MsisdnLocalData'



const authConfig = {}
const config = {
    renderLoginIconView: null,
    renderVerifyLoginIconView: null,
    resetNavigation: false,
}
const onLoadingStart = () => { }

const onLoadingEnd = async (e) => {
    const userType = ReduxCache.getUserType()
    const sohoUserType = ReduxCache.getBusinessAccount() || ''

    let fixTutorialsShown
    let businessTutorialsShown

    getMsisdnData().then(res => {
        fixTutorialsShown = res?.fixTutorialsShown
        businessTutorialsShown = res?.businessTutorialsShown

        if (fixTutorialsShown || !userType?.includes('fix')) {
            // Only if user is fix and tutorial show Tutorial component, else show dashboard
            NavigationService.navigateWithResetAction('Dashboard')
            return
        }
        if (businessTutorialsShown && sohoUserType?.sohoUserType === 'business_chooser') {
            NavigationService.navigateWithResetAction('Dashboard')
        } else {
            NavigationService.navigateWithResetAction('Tutorial')
        }
    })
}

// config.soft = new SoftLoginImplementation(authConfig, onLoadingStart, onLoadingEnd)

config.upfront = new Login(authConfig, onLoadingStart, onLoadingEnd)
config.register = new RegisterImplementation({}, () => { }, onLoadingEnd)
config.verifyCode = new VerifyCodeImplementation()

export const loginManager = new LoginManager(config, NavigationService)


const LaunchLogin = async (logout = true) => {

    const onNext = await loginManager.init()


    onNext()

    // needs to be added a check to not remove the msisdn of the fingerprint or faceid
    // const msisdn = ReduxCache.getMsisdn()
    // Keychain.resetGenericPassword({service: msisdn})

    if (logout) {
        ReduxCache.clear()
        // added inside timeout because transition of the screen requires some time and getbalance in dashboard was being trigered
        setTimeout(() => {
            store.dispatch({ type: actionTypes.LOGOUT })
        }, 1000)
    }
}

export default LaunchLogin
