import { Dimensions, StyleSheet } from 'react-native'

const setFlexBasis = (width, length, index) => {
  if (length === 3 && index === 2) {
    return width - 32
  } else if (length === 2) {
    return width - 32
  }
  return (width - 45) / 2
}

export default StyleSheet.create({
  discoverContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'space-between',
    marginHorizontal: 'auto',
    marginTop: Dimensions.get('window').width > 700 && 20
  },
  discoverCard: (width, length, index) => ({
    borderRadius: 50,
    flexBasis: setFlexBasis(width, length, index),
    height: (width - 45) / 2,
    marginBottom: 12
  }),
  seeMore: theme => ({
    fontSize: 16.6,
    letterSpacing: 0,
    color: theme.colors.showMoreColor,
    textAlign: 'center',
  }),
  container: {
    flex: 1,
  },
  paginationStyle: {
    zIndex: 3,
    position: 'absolute',
    bottom: -45,
    alignSelf: 'center',
    marginVertical: 25,
  },
  cardStyle: {
    flex: 1,
    justifyContent: 'center',
    height: '100%',
  },
  cardCarouselStyle: {
    flex: 1,
    minWidth: Dimensions.get('window').width > 700 ? 435 : null,
    height: '100%'
  },
  seeMoreButton: {
    width: '100%',
    alignItems: 'center',
    marginTop: 20
  },
  detailsButton: {
    position: 'absolute',
    right: 0,
    top: -30
  }
})
