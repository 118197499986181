import { store } from '../../Redux'
import { GetLP as GetLPService } from '../../Services'
import ReduxCache from '../../Utils/ReduxCache'

import { APP_VERSION } from '../../Config'

import { actionTypes } from './LandingScreen.ActionsTypes'

const getLP = async () => {
    const headers = {
        'X-Source-System': 'selfcare',
        'Accept-Language': ReduxCache.getLanguage()
    }
    const response = await GetLPService(APP_VERSION, headers)

    store.dispatch({ type: actionTypes.CONFIGURATIONS_SUCCESS, data: response })

    return response
}

const Actions = { getLP }

export { Actions, actionTypes }
