import { Dimensions, StyleSheet } from 'react-native'

import { Fonts, Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'


const styles = StyleSheet.create({
  container: theme => ({
    flex: 1,
    height: '100%',
    backgroundColor: theme.colors.cardBackgroundColor
  }),
  closeButton: {
    position: 'absolute',
    top: 52,
    right: 16,
    zIndex: 100,
  },
  title: {
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Fonts.size.h3,
    color: Colors.white,
    marginVertical: 15,
  },
  subtitle: {
    fontFamily: Fonts.type.VFFont,
    fontSize: Dimensions.get('window').height < 700 ? Fonts.size.h6 : Fonts.size.h4,
    color: Colors.white,
    marginVertical: 5,
  },
  desc: {
    ...Fonts.type.bold,
    fontSize: Fonts.size.h5,
    color: Colors.white
  },
  phoneContainer: {
    backgroundColor: Colors.dark_grey,
    width: '70%',
    height: '100%',
    borderRadius: 40,
    padding: 18,
    alignSelf: 'center'
  },
  screenContainer: {
    backgroundColor: Colors.white,
    width: '100%',
    height: '100%',
    borderRadius: 25,
    alignItems: 'center',
    paddingHorizontal: 8,
  },
  bold: {
    ...Fonts.type.bold,
    fontSize: Fonts.size.h4,
  },
  text: {
    fontSize: Fonts.size.h5,
  },
  rowItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 8,
  },
  textContainer: {
    paddingVertical: 30,
    paddingHorizontal: 16,
  },
  icon: {
    marginRight: 15,
  },
  bottomContainer: {
    flex: 1,
    justifyContent: 'space-evenly',
    paddingHorizontal: 16,
  },
  topContainer: insets => ({
    flex: 1,
    paddingTop: insets.top,
  }),
  circle: {
    width: 17,
    height: 17,
    borderRadius: 15,
    backgroundColor: Colors.dark_grey,
    marginTop: 15,
    marginBottom: 20,
  },
  cardContainer: {
    backgroundColor: Colors.offWhite,
    width: '100%',
    marginTop: 30,
    borderRadius: 4,
    flexDirection: 'row',
    paddingVertical: 16,
  },
  simNumber: {
    ...Fonts.type.bold,
    fontSize: Fonts.size.regular,
    color: Colors.black,
  },
  cardTitle: {
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Fonts.size.h6,
    color: Colors.black,
  },
  badgeStyle: {
    position: 'absolute',
    right: 5,
    top: 5,
  },
  cardTextContainer: {
    justifyContent: 'space-around',
    paddingTop: 10,
  },
  label: {
    color: Colors.black,
  }
})

export default styles
