import { useState, useEffect, useCallback } from 'react'
import { BackHandler } from 'react-native'
import { useFocusEffect, useIsFocused } from '@react-navigation/native'

// import { UrbanAirship } from 'urbanairship-react-native'



import { useDispatch, useSelector } from 'react-redux'


import { getMsisdnData, setMsisdnData, isAdminView } from '../../../Utils'


import { Actions } from '../Dashboard.Actions'
import { Actions as LandingScreenActions } from '../../LandingScreen/LandingScreen.Actions'
import ReduxCache from '../../../Utils/ReduxCache'
import { setStoriesInStore } from '../../Story/Story.Actions'
import { APP_VERSION } from '../../../Config'



const launchScreenAnimationTimeout = 5000

function useDashboard(theme) {
  const [isLoading, setIsLoading] = useState(true)
  const [showTrayModal, setShowTrayModal] = useState(false)
  const [userName, setUserName] = useState('')
  const [lastUpdateTime, setLastUpdateTime] = useState(new Date())
  const [userBalance, setUserBalance] = useState({})

  const [showPayments, setShowPayments] = useState(false)
  const [showRefill, setShowRefill] = useState(false)
  const [showDataShare, setShowDataShare] = useState(false)
  const [isThirdParty, setIsThirdParty] = useState(false)

  const [unreadMessage, setUnreadMessages] = useState(0)
  const [isStoryVisible, setIsStoryVisible] = useState(false)


  const [showPreferences, setShowPreferences] = useState(false)
  const [shouldRenderPreferences, setShouldRenderPreferences] = useState(false)


  const balanceReduxData = useSelector(store => store.balance)
  const configurationsRedux = useSelector(store => store.configurations)


  const userType = ReduxCache.getUserType()
  const language = useSelector(state => state.language)
  const minAppVersion = ReduxCache.getMinVersion()
  const showUpdateModal = APP_VERSION < minAppVersion

  const isFocused = useIsFocused()

  // Data Share Active Tab
  // Can be change in case of deep linking
  const [dataShareActiveTab, setDataShareActiveTab] = useState('internet')

  const dispatch = useDispatch()

  const getStatusBarColor = () => {
    return 'light-content'
  }

  const getStatusBarBackground = () => {
    return 'transparent'
  }

  const [statusBarColor, setStatusBarColor] = useState(getStatusBarColor())
  const [statusBarBackground, setStatusBarBackground] = useState(getStatusBarBackground())

  const [headerHeight, setHeaderHeight] = useState(150)

  useEffect(() => {
    setStatusBarColor(getStatusBarColor())
    setStatusBarBackground(getStatusBarBackground())
  }, [theme])

  // useEffect(() => {
  //   const inboxUpdatedListener = UrbanAirship.addListener('inboxUpdated', response => {
  //     setUnreadMessages(response.messageUnreadCount)
  //   })

  //   UrbanAirship.refreshInbox()

  //   return () => {
  //     inboxUpdatedListener?.removeListener && inboxUpdatedListener.removeListener('inboxUpdated')
  //   }
  // }, [])

  useEffect(() => {
    if (!userType) {
      return
    }
    dispatch(Actions.getPegaData())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType])

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        if (showTrayModal) {
          return true
        }
        return false
      }

      BackHandler.addEventListener('hardwareBackPress', onBackPress)

      return () => BackHandler.removeEventListener('hardwareBackPress', onBackPress)
    }, [showTrayModal])
  )

  useEffect(() => {
    setTimeout(() => {
      checkPermissions()
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRenderPreferences])

  useFocusEffect(
    useCallback(() => {
      setUsernameFromStorage()
    }, [])
  )

  const showPrivacyPermissionsOverlay = () => {
    setTimeout(() => {
      setShowPreferences(true)
    }, launchScreenAnimationTimeout)
  }

  const showPrivacyPermissionsOverlayOnFirstLogin = async () => {
    const privacyData = await getMsisdnData()

    switch (privacyData?.showedPrivacyPermissions) {
      case 'true':
        return

      case 'false':
        break

      default:
        setMsisdnData('showedPrivacyPermissions', 'true')
        showPrivacyPermissionsOverlay()
        break
    }
  }

  const checkPermissions = async () => {
    const shouldRender = await getMsisdnData()

    if (userType === 'guest') {
      return
    }
    switch (shouldRender?.shouldRenderPreferences) {
      case 'true':
        setShouldRenderPreferences(true)
        showPrivacyPermissionsOverlayOnFirstLogin()
        return

      case 'false':
        setShouldRenderPreferences(false)
        break

      default:
        setShouldRenderPreferences(true)
        break
    }
  }

  const onFinishPreferences = async () => {
    setMsisdnData('shouldRenderPreferences', 'false')
    setShouldRenderPreferences(false)
  }

  const toggleDataShare = () => setShowDataShare(prev => !prev)


  const setUsernameFromStorage = () => {
    getMsisdnData()
      .then((res) => {
        const storedUsername = res?.username || ''
        setUserName(storedUsername)
      })
      .catch(() => { })
  }

  const refreshDashboard = () => {
    setIsLoading(true)
    setLastUpdateTime(new Date())

    if (!configurationsRedux || Object.keys(configurationsRedux) == 0) {
      LandingScreenActions.getLP()
    }
    setStoriesInStore()

    dispatch(Actions.getUserBalance(isAdminView()))
    getMsisdnData()
      .then((res) => {
        const storedUsername = res?.username
        setUserName(storedUsername)
      })
      .catch(() => { })
  }

  return {
    refreshDashboard,
    toggleDataShare,
    onFinishPreferences,
    showPayments,
    showRefill,
    showDataShare,
    isThirdParty,
    showPreferences,
    dataShareActiveTab,
    isLoading,
    userName,
    userBalance,
    setUserBalance,
    shouldRenderPreferences,
    balanceReduxData,
    language,
    setShowPayments,
    setShowRefill,
    setShowDataShare,
    setIsThirdParty,
    setShowPreferences,
    unreadMessage,
    setDataShareActiveTab,
    setShowTrayModal,
    showTrayModal,
    isStoryVisible,
    setIsStoryVisible,
    statusBarColor,
    setStatusBarColor,
    statusBarBackground,
    setStatusBarBackground,
    getStatusBarColor,
    getStatusBarBackground,
    lastUpdateTime,
    setHeaderHeight,
    headerHeight,
    showUpdateModal
  }
}

export default useDashboard
