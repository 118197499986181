let BASE_DXL_URL = 'https://eu2.api.vodafone.com/'
const BASE_URL = 'https://api-mobile-ci-vfal.vodafone.com/'

let CLIENT_ID = 'NYSBo6G2vRztXgmM664sNO3fSvrKqEM8'
let CLIENT_SECRET = 'CFcWmmJwMDX7gv5Z'

let GUEST_CLIENT_ID = 'gENjaT1QlB30Op0FiTRdpf4tjJOwbgV8'
let GUEST_CLIENT_SECRET = 'O2NDIUlNnp6RkeH4'

let FLEX_URL = 'https://flex.cybersource.com/cybersource/flex/v1/tokens'
let CYBERSOURCE_BASE_URL = 'https://api.cybersource.com'

const TEST_BASE_URL = 'https://api-sandbox-vfal.vodafone.com/'

const staging = false

if (staging) {
  BASE_DXL_URL = 'https://eu2-stagingref.api.vodafone.com/'

  FLEX_URL = 'https://testflex.cybersource.com/cybersource/flex/v1/tokens'
  CYBERSOURCE_BASE_URL = 'https://apitest.cybersource.com'

  CLIENT_ID = 'FuiOuIBt5CjbphMjoghh1uNZEfT1rjCp'
  CLIENT_SECRET = 'PzIMQOW5KLnCtce8'

  GUEST_CLIENT_ID = 'WHvGhGJUv8gAa0hScn39psvIIaJ6UOpt'
  GUEST_CLIENT_SECRET = '4pnm4A1nLOOnhzhm'
}

const GOOGLE_GEOCODING_KEY = 'AIzaSyBu4K6ecmabzZU5HUu0yvvTXpFek0sMtsM'


const API_URLS = {
  baseUrl: 'https://eu2.api.vodafone.com',
  baseCyberSourceUrl: CYBERSOURCE_BASE_URL,
  generateSecurityToken: BASE_DXL_URL + 'csm-api/securityTokenAPI/v2/generateSecurityToken',
  securityToken: BASE_DXL_URL + 'csm-api/securityTokenAPI/v2/securityToken/',
  flex: FLEX_URL,
  payment: BASE_DXL_URL + 'payments/v1/payment',
  bill: BASE_DXL_URL + 'billAnalysis/v1/bill',
  billHistory: BASE_DXL_URL + 'billAnalysis/v1/history',
  getBillPDF: BASE_DXL_URL + 'tmf-api/document/v4/document',
  translations_al: 'https://api-web-ci-vfal.vodafone.com/file/mva/mva_content_al.json',
  billBreakdown: BASE_DXL_URL + 'billAnalysis/v1/billBreakdown',
  translations_en: 'https://api-web-ci-vfal.vodafone.com/file/mva/mva_content_en.json',
  offerPayment: BASE_DXL_URL + 'tmf-api/productOrderingManagement/v4/productOrder',
  loginDXL: BASE_DXL_URL + 'csm-api/changeAuthenticationCredentialAPI2/v2/authenticationCredential/check',
  getPin: BASE_DXL_URL + 'csm-api/securityTokenAPI/v2/generateSecurityToken',
  register: BASE_DXL_URL + 'csm-api/customerSystemUserAccountAPI/v2/customerSystemUserAccount',
  resetPassword: BASE_DXL_URL + 'csm-api/changeAuthenticationCredentialAPI2/v2/authenticationCredential/reset/',
  LP: BASE_URL + 'enterprise-resources/public/technical/configurations',
  balance: BASE_URL + 'enterprise-resources/public/service/service-balances/',
  pega: BASE_DXL_URL + 'tmf-api/recommendation/v4/queryProductRecommendation',
  productsAndServices: {
    myPlan: BASE_DXL_URL + 'productsAndServices/v1/activeProductsStatus',
    topics: BASE_DXL_URL + 'tmf-api/eventManagement/v4/topic',
    removeAddons: BASE_DXL_URL + 'tmf-api/productOrderingManagement/v4/productOrder',
    getPuk: BASE_DXL_URL + 'tmf-api/resourceInventoryManagement/v4/physicalResource',
    accountManagement: BASE_DXL_URL + 'tmf-api/accountManagement/v4/partyAccount',
  },
  unlimited: {
    geographic: {
      get: BASE_DXL_URL + 'tmf-api/geographicAddressManagement/v4/geographicAddress',
      validate: BASE_DXL_URL + 'tmf-api/geographicAddressManagement/v4/geographicAddressValidation',
    },
    checkout: BASE_DXL_URL + 'tmf-api/productOrderingManagement/v4/productOrder',
    getMembers: BASE_DXL_URL + 'tmf-api/customerManagement/v4/customer',
    validateNumber: BASE_DXL_URL + 'tmf-api/party/v4/individual',
    createCustomer: BASE_DXL_URL + 'tmf-api/party/v4/individual',
    validateCustomer: BASE_DXL_URL + 'tmf-api/party/v4/individual',
    productCatalog: BASE_DXL_URL + 'tmf-api/productCatalogManagement/v4/catalog',
    getContract: BASE_DXL_URL + 'tmf-api/productOrderingManagement/v4/productOrder',
    productOffering: BASE_DXL_URL + 'tmf-api/productCatalogManagement/v4/productOffering',
  },
  fix: {
    customerManagement: BASE_DXL_URL + 'tmf-api/customerManagement/v4/customer',
    partyApi: BASE_DXL_URL + 'tmf-api/partyManagement/v4',
    geographic: {
      geographicAddress: BASE_DXL_URL + 'tmf-api/geographicAddressManagement/v4/geographicAddress',
      geographicAddressValidation: BASE_DXL_URL + 'tmf-api/geographicAddressManagement/v4/geographicAddressValidation',
    },
    getOrders: BASE_DXL_URL + 'tmf-api/productOrderingManagement/v4/productOrder',
    sendOrder: BASE_DXL_URL + 'tmf-api/productOrderingManagement/v4/productOrder',
    getContract: BASE_DXL_URL + 'tmf-api/agreementManagement/v4/agreement',
    addManualContract: BASE_DXL_URL + 'tmf-api/customerManagement/v4/customer',
    fixPlan: BASE_DXL_URL + 'tmf-api/agreementManagement/v4/agreement',
    getFixBills: BASE_DXL_URL + 'billAnalysis/v1/history',
    partyIndividualApi: BASE_DXL_URL + 'tmf-api/partyManagement/v4/individual',
    shoppingCart: BASE_DXL_URL + 'tmf-api/shoppingCart/v4/shoppingCart',
  },
  customerPrivacyProfile: BASE_URL + 'auth/customerPrivacyProfileAPI/v2/customerPrivacyProfile',
  terminateAccount: BASE_DXL_URL + 'tmf-api/troubleTicket/v4/troubleTicket',
  changePassword: BASE_DXL_URL + 'csm-api/changeAuthenticationCredentialAPI2/v2/authenticationCredential/change',
  affiliate: {
    getHistory: BASE_DXL_URL + 'tmf-api/eventManagement/v4/topic/affiliate/event',
  },
  roaming: {
    getRecommendations: BASE_DXL_URL + '/tmf-api/recommendation/v4/queryProductRecommendation',
    getGeographicAddress: BASE_DXL_URL + 'tmf-api/geographicAddressManagement/v4/geographicAddress',
    roamingRecommandation: BASE_DXL_URL + 'tmf-api/recommendation/v4/queryProductRecommendation',
    roamingOffers: BASE_DXL_URL + 'tmf-api/productCatalogManagement/v4/category',
    servicesAndDataCap: BASE_DXL_URL + 'tmf-api/ServiceActivationAndConfiguration/v4/service/roaming',
    googleGeocoding: 'https://maps.googleapis.com/maps/api/geocode/json?'
  },
  stories: {
    storiesRecommandation: BASE_DXL_URL + 'tmf-api/recommendation/v4/queryProductRecommendation',
    storiesEvents: BASE_DXL_URL + 'tmf-api/eventManagement/v4/topic/story/event',
  },
  acquisition: {
    individualParty: BASE_DXL_URL + 'tmf-api/party/v4/individual',
    order: BASE_DXL_URL + 'tmf-api/productOrderingManagement/v4/productOrder',
    getOffers: BASE_DXL_URL + 'tmf-api/recommendation/v4/queryProductRecommendation',
    getResources: BASE_DXL_URL + 'tmf-api/resourceInventoryManagement/v4/resource',
  },
  promo: {
    getDailyCatalogue: BASE_DXL_URL + 'tmf-api/recommendation/v4/queryProductRecommendation',
    activateReward: BASE_DXL_URL + 'tmf-api/productOrderingManagement/v4/productOrder',
  },
  orders: {
    productOrder: BASE_DXL_URL + 'tmf-api/productOrderingManagement/v4/productOrder',
  },
  bigbrother: {
    getCandidates: BASE_DXL_URL + 'tmf-api/recommendation/v4/queryProductRecommendation',
  },
  business: {
    busninessOverview: {
      plan: BASE_DXL_URL + 'productsAndServices/v1/activeProductsStatus',
      product: BASE_DXL_URL + 'tmf-api/productInventory/v4/product',
      addons: BASE_DXL_URL + 'productsAndServices/v1/productAndOfferingRecommendation',
      usage: BASE_DXL_URL + 'usage/v1/usage',
      usageUsers: BASE_DXL_URL + 'usage/v1/usageByUser',
      ProductOffering: BASE_DXL_URL + 'tmf-api/productCatalogManagement/v4/productOffering',
    },
    usersAndGroup: {
      users: BASE_DXL_URL + 'usersAndGroups/v1/user',
    },
    requests: {
      productOrder: BASE_DXL_URL + 'orderHandling/v1/productOrder',
      cancelOrder: BASE_DXL_URL + 'orderHandling/v1/bulkCancelProductOrder',
      updateOrder: BASE_DXL_URL + 'orderHandling/v1/bulkUpdateProductOrder'
    }
  },
  //my offers
  getCatalogue: BASE_DXL_URL + 'digitalProductRecommendation/v1/digitalProductRecommendation',
  activateOffer: BASE_DXL_URL + 'tmf-api/productOrderingManagement/v4/productOrder',
  appointments: {
    appointment: BASE_DXL_URL + 'tmf-api/appointment/v4/appointment',
    openHours: BASE_DXL_URL + 'tmf-api/geographicSiteManagement/v4/geographicSite',
    services: BASE_DXL_URL + 'digitalProductRecommendation/v1/digitalProductRecommendation',
    geographicAddress: BASE_DXL_URL + 'tmf-api/geographicAddressManagement/v4/geographicAddress',
    timeSlots: BASE_DXL_URL + 'tmf-api/appointment/v4/searchTimeSlot'
  },
  cocaCola: {
    activate: BASE_DXL_URL + 'tmf-api/productOrderingManagement/v4/productOrder'
  },
  summerPromo: {
    getPromo: BASE_URL + 'auth/productOfferingAPI/v2/productOffering',
    activatePromo: BASE_URL + 'auth/salesOrderAPI/v2.1/salesOrder'
  }
}

const COMMON_HEADERS = {
  'Content-Type': 'application/json',
  'Accept-Language': 'al',
  'vf-source': 'selfcare',
  'vf-country-code': 'al',
  'vf-operator': 'myvodafone',
}


const CYBERSOURCE_COMMON_HEADERS = {
  'Content-Type': 'application/json',
  'Host': 'api.cybersource.com',
  'cybersourse': true,
}


export { staging, API_URLS, COMMON_HEADERS, GUEST_CLIENT_SECRET, GUEST_CLIENT_ID, CYBERSOURCE_COMMON_HEADERS, CLIENT_ID, CLIENT_SECRET, BASE_DXL_URL, BASE_URL, TEST_BASE_URL, GOOGLE_GEOCODING_KEY }
