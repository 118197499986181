import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { UrbanAirship } from 'urbanairship-react-native'
import { useNavigation } from '@react-navigation/native'


import PrivacyPermissionsOverlayComponent, {
  SectionEnums
} from '@vfgroup-oneplatform/framework/CommonUI/PrivacyPermissionsOverlay'

import { setMsisdnData } from '../../Utils'
import { getPreferences, updatePreferences } from '../../Services/Settings'

import privacyPermissions from './permissions'


const PrivacyPermissionsOverlay = ({ isVisible, setIsVisible, onFinish }) => {
  const navigation = useNavigation()


  const [isLoading, setIsLoading] = useState(false)

  const [data, setData] = useState({})
  const [items, setItems] = useState({})

  const askForNotifications = (res) => {
    if (res['push notification']) {
      UrbanAirship.setUserNotificationsEnabled(true)
    }
  }

  const updateMsisdnState = async (state) => setMsisdnData('shouldRenderPreferences', state)

  useEffect(() => {
    getPreferences({}, {
      onSuccess: ({ response, items: newItems }) => {
        updateMsisdnState(`${response?.status !== 'Active'}`)
        if (response?.status === 'Active') {
          askForNotifications(newItems)
        }
        setItems(newItems)
        setData(response)
      },
      onError: () => { }
    })
  }, [])

  const onDismiss = () => {
    setIsVisible(false)
    onFinish()
  }

  const onOkButton = () => {
    setIsLoading(true)

    updatePreferences(data, items, {}, {
      onSuccess: (res) => {
        onDismiss()
        askForNotifications(res)
      },
      onError: () => onDismiss()
    })
  }

  const onToggle = key => {
    setItems(oldItems => {
      return {
        ...oldItems,
        [key]: !oldItems[key]
      }
    })
  }

  const getIntroSections = () => privacyPermissions().introSections.map(sec => ({
    ...sec,
    actions: {
      type: SectionEnums.CHECK,
      ...sec.actions
    }
  }))

  const getPrivacySettingsSections = () => privacyPermissions().privacySettingsSections.map((sec, i) => {
    const parts = data?.parts
    const preferences = parts?.partyPreference?.partyPreference
    const permissions = parts?.partyPermission?.permission?.partyPermission

    const mappedPreferences = preferences?.map((preference) => ({
      title: preference?.name,
      description: preference?.desc,
      key: preference?.id?.[0]?.value,
      initialValue: items?.[preference?.id?.[0]?.value],
      onToggle: onToggle
    })) || []

    const mappedPermissions = permissions?.map((permission) => ({
      title: permission?.name,
      description: permission?.desc,
      key: permission?.id?.[0]?.value,
      initialValue: items?.[permission?.id?.[0]?.value],
      onToggle: onToggle
    })) || []

    if (i === 0) {
      return sec
    }

    if (i === 1) {
      return {
        ...sec,
        actions: {
          type: SectionEnums.PERMISSION,
          items: mappedPermissions
        }
      }
    }

    if (i === 2) {
      return {
        ...sec,
        actions: {
          type: SectionEnums.PERMISSION,
          items: mappedPreferences
        }
      }
    }
  })

  const onRejectAll = () => {
    const newObject = {}
    Object.keys(items).map(key => {
      newObject[key] = false
    })
    setItems(newObject)

    updatePreferences(newObject, newObject, {}, {
      onSuccess: (res) => {
        askForNotifications(res)
      },
      onError: () => { }
    })
  }

  const managePrivacy = () => {
    onDismiss()
    setTimeout(() => {
      navigation.navigate('Preferences')
    }, 1)

  }

  const onAcceptAll = async () => {
    const newObject = {}
    Object.keys(items).map(key => {
      newObject[key] = true
    })
    setItems(newObject)

    try {
      await updatePreferences(data, newObject, {}, {})
      return true
    } catch (error) {
      return false
    }
  }

  return (
    <PrivacyPermissionsOverlayComponent
      testID="PPO-Overlay"
      isVisible={isVisible}
      isLoading={isLoading}
      onDismiss={onDismiss}
      onOkButtonPress={onOkButton}
      introSections={getIntroSections()}
      privacySettingsSections={getPrivacySettingsSections()}
      privacyPolicySections={[]}
      onRejectAll={onRejectAll}
      onRejectAllManagePrivacy={managePrivacy}
      onAcceptAll={onAcceptAll}
      onAcceptAllManagePrivacy={managePrivacy}
    />
  )
}
PrivacyPermissionsOverlay.propTypes = {
  setIsVisible: PropTypes.func,
  isVisible: PropTypes.bool,
  onFinish: PropTypes.func
}
PrivacyPermissionsOverlay.defaultProps = {
  setIsVisible: () => { },
  isVisible: false,
  onFinish: () => { }
}
export default PrivacyPermissionsOverlay
