import { getOffers, getResources } from '../../Services/Acquisition'

import ReduxCache from '../../Utils/ReduxCache'

const actionTypes = {
  REQUEST_OFFERS: 'REQUEST_OFFERS',
  SUCCESS_OFFERS: 'SUCCESS_OFFERS',
  ERROR_OFFERS: 'ERROR_OFFERS',

  SELECT_OFFER: 'SELECT_OFFER',
  CLEAR_SELECTED_OFFER: 'CLEAR_SELECTED_OFFER',

  REQUEST_RESOURCES: 'REQUEST_RESOURCES',
  SUCCESS_RESOURCES: 'SUCCESS_RESOURCES',
  ERROR_RESOURCES: 'ERROR_RESOURCES',
}

const getPrice = (priceObj = []) => {

  const obj = priceObj?.[0].price

  return obj?.taxIncludedAmount

}

const characteristicsTypes = ['INTERNATIONAL_SMS', 'SMS', 'INTERNATIONAL_MIN', 'MIN', 'MOBILE_GB']

const offersResponseMapper = (res = []) => {

  const data = []

  res?.map(item => {
    const { id, description = '', recommendationType = '', recommendationItem = [] } = item

    const { product = {} } = recommendationItem?.[0] || {}

    const { productPrice = [], productRelationship, productTerm } = product

    const addonObject = productRelationship.find(el => el?.relationshipType === 'addon')

    const addon = !addonObject ? null : {
      name: addonObject?.product?.name,
      id: addonObject?.product?.id,
    }

    const isMonthly = productPrice[0]?.priceType === 'Recurring charge' ? true : false

    const productArray = product?.product?.[0].productCharacteristic || []

    let productTermObj = {}

    if (productTerm && productTerm?.length !== 0) {
      productTermObj = productTerm?.find(el => el?.name?.includes('MonthCommitment'))?.duration || {}
    }

    const content = []

    productArray?.map(el => {
      if (characteristicsTypes.includes(el?.name)) {
        content.push({
          type: el?.name,
          value: el?.value
        })
      }
    })

    data.push({
      id,
      title: product?.name,
      description,
      recomandation_type: recommendationType === 'recommended_offer' ? 1 : 0,
      price: getPrice(productPrice)?.value + ' ' + getPrice(productPrice)?.unit,
      isMonthly,
      content,
      addon,
      contractDuration: productTermObj,
      data: item,
    })
  })

  return data
}

const resourcesResponseMapper = (res = []) => {
  const data = []
  res?.map(item => {
    const { id, resourceCharacteristic } = item
    const simSerial = resourceCharacteristic?.find(el => el?.name === 'simSerial')?.value || ''
    const orderId = resourceCharacteristic?.find(el => el?.name === 'OrderId')?.value || ''
    data.push({
      value: id,
      label: id,
      icon: 'ic_sim',
      simSerial,
      orderId,
    })
  })

  return data
}

const GetOffers = (utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_OFFERS,
    })

    const msisdn = ReduxCache.getMsisdn()

    getOffers(msisdn, { 'vf-project': 'pega_dnext' }, {
      ...utilities,
      responseMapper: offersResponseMapper,
    })
      .then(response => dispatch({
        type: actionTypes.SUCCESS_OFFERS,
        payload: response,
      }))
      .catch(error => dispatch({
        type: actionTypes.ERROR_OFFERS,
        payload: error,
      }))
  }
}

const SelectOffer = (data) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SELECT_OFFER,
      payload: data,
    })
  }
}

const ClearOffer = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.CLEAR_SELECTED_OFFER,
    })
  }
}

const GetResources = (data, utilities) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_RESOURCES,
    })

    getResources(data, { 'vf-project': 'acquisition' }, {
      ...utilities,
      responseMapper: resourcesResponseMapper,
    })
      .then(response => dispatch({
        type: actionTypes.SUCCESS_RESOURCES,
        payload: response,
      }))
      .catch(error => dispatch({
        type: actionTypes.ERROR_RESOURCES,
        payload: error,
      }))
  }
}

export {
  actionTypes,
  GetOffers,
  SelectOffer,
  ClearOffer,
  GetResources,
}
