import axios from 'axios'
import moment from 'moment'
import { isFunction as _isFunction } from 'lodash'
import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import ReduxCache from '../../Utils/ReduxCache'

import { API_URLS } from '../Config'

const mapTopicData = (response, events) => {
  const custom = []

  if (!response) {
    return custom
  }

  response.map(item => {
    custom.push({
      id: item.id,
      name: item.name,
      items: events[item.id] || []
    })
  })
  return custom
}

const mapEventsData = (response) => {
  const items = {}

  if (!response) {
    return items
  }

  response.map(item => {

    if (!items[item.eventType]) {
      items[item.eventType] = []
    }

    const iconsMapper = {
      'recharge': 'icTopUp',
      'parking': 'ic_parking',
      'loan': 'ic_loan',
      'bundle': 'ic_bundle',
      'sos': 'ic_bundle',
    }

    const price = item.analyticCharacteristic[0] || {}
    items[item.eventType].push({
      id: item.eventId,
      icon: iconsMapper[item.eventType],
      title: item.description,
      date: moment(item.eventTime, 'DD/MM/YY hh:mm'),
      duration: '',//item.title,
      price: price.value + ' ' + price.name,
      type: 'International calls'
    })
  })
  return items
}

async function getTopics(
  customHeaders = {},
  utilities = {}
) {
  const _utilities = retrieveUtilities(utilities, {
    responseMapper: (response, events) => mapTopicData(response, events)
  })

  const {
    errorMapper,
    onError,
    onStart,
    onSuccess,
    responseMapper
  } = _utilities


  onStart()

  try {
    const params = {
      'relatedParty.id': ReduxCache.getMsisdn(),
      'offset': 100,
      'limit': 0,
    }

    const events = await getAllEvents()

    const response = await axios.get(API_URLS.productsAndServices.topics, { headers: customHeaders, params: params })
    const _response = responseMapper(response.data, events)


    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

async function getAllEvents(
  customHeaders = {},
  utilities = {}
) {
  const _utilities = retrieveUtilities(utilities, {
    responseMapper: response => mapEventsData(response)
  })

  const {
    errorMapper,
    onError,
    onStart,
    onSuccess,
    responseMapper
  } = _utilities

  const msisdn = ReduxCache.getMsisdn()

  const data = {
    headers: customHeaders,
    params: {
      offset: 50,
      limit: 0,
      'relatedParty.id': msisdn
    }
  }

  onStart()

  try {
    const response = await axios.get(API_URLS.productsAndServices.topics + '/all/event', data)
    const _response = responseMapper(response.data)

    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

export { getTopics, getAllEvents }
