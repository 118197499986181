import { StyleSheet } from 'react-native'

import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  emptyText: {
    ...Fonts.type.bold,
    fontSize: Fonts.size.h5,
    textAlign: 'center'
  },
  pageContainer: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    marginBottom: 25,
    marginTop: 15
  },
  pageText: theme => ({
    fontSize: 18,
    color: theme.colors.seeBillColor,
  }),
  pageButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 10,
  },
})
