import axios from 'axios'

import CONFIG from '../../Config'
import { store } from '../../Redux/CreateStore'
import { __translate, getErrorMessage, toastr } from '../../Utils'
import ReduxCache from '../../Utils/ReduxCache'

// import { action_types } from './catalog.actionTypes'

export const action_types = {
  REQUEST_CATEGORIES: 'REQUEST_CATEGORIES',
  SUCCESS_CATEGORIES: 'SUCCESS_CATEGORIES',
  ERROR_CATEGORIES: 'ERROR_CATEGORIES',
  REQUEST_CATEGORY_ITEMS: 'REQUEST_CATEGORY_ITEMS',
  SUCCESS_CATEGORY_ITEMS: 'SUCCESS_CATEGORY_ITEMS',
  ERROR_CATEGORY_ITEMS: 'ERROR_CATEGORY_ITEMS',
  REQUEST_FAVORITES: 'REQUEST_FAVORITES',
  SUCCESS_FAVORITES: 'SUCCESS_FAVORITES',
  ERROR_FAVORITES: 'ERROR_FAVORITES',
  REQUEST_FAVORITE_HANDLE: 'REQUEST_FAVORITE_HANDLE',
  SUCCESS_FAVORITE_HANDLE: 'SUCCESS_FAVORITE_HANDLE',
  ERROR_FAVORITE_HANDLE: 'ERROR_FAVORITE_HANDLE',
  SUCCESS_PLAN_PRODUCTS: 'SUCCESS_PLAN_PRODUCTS',

  GENERATE_COUPON: 'GENERATE_COUPON',
  SUCCESS_COUPON: 'SUCCESS_COUPON',
  ERROR_COUPON: 'ERROR_COUPON',

  REQUEST_ACTIVE_COUPON: 'REQUEST_ACTIVE_COUPON',
  SUCCESS_ACTIVE_COUPON: 'SUCCESS_ACTIVE_COUPON',
  ERROR_ACTIVE_COUPON: 'ERROR_ACTIVE_COUPON',

  REQUEST_CANCEL_COUPON: 'REQUEST_CANCEL_COUPON',
  SUCCESS_CANCEL_COUPON: 'SUCCESS_CANCEL_COUPON',
  ERROR_CANCEL_COUPON: 'ERROR_CANCEL_COUPON',
}


const getCategories = shouldGetAll => {
  return async dispatch => {
    dispatch({
      type: action_types.REQUEST_CATEGORIES,
    })

    if (typeof shouldGetAll === 'undefined') {
      shouldGetAll = false
    }

    const msisdn = ReduxCache.getMsisdn()

    const data = {
      queryOptions: {
        filter: '',
        pagination: {
          count: 0,
          limit: 5,
        },
        sorting: '',
      },
      queries: [
        {
          query: '$.redeemerId=' + msisdn,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.type=' + CONFIG.queryConfig.type,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.channelId=' + CONFIG.queryConfig.channelId,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.category=category',
        },
      ],
    }

    const url = await CONFIG.getUrl()
    const headers = await CONFIG.getHeaders()
    axios
      .post(url.apiEndpoint, data, {
        headers: {
          ...headers,
        },
      })
      .then(response => {
        const categories = {}
        // manually add all as a category

        const allName = __translate('reward_loyalty_product_catalog_all') !== '' ? __translate('reward_loyalty_product_catalog_all') : 'Te gjitha'
        categories.all = {
          id: 'all',
          name: allName,
          order: 0,
          characteristics: {
            imageURL: null,
            color: '#A85751',
          },
        }

        for (var i in response.data) {
          const category = response.data[i]

          var characteristics = {}

          for (var j in category.parts.specification.characteristicsValue) {
            var characteristic =
              category.parts.specification.characteristicsValue[j]
            characteristics[characteristic.characteristicName] =
              characteristic.value
          }

          categories[category.id[0].value] = {
            id: category.id[0].value,
            name: category.name,
            order: category.category[0].listHierarchyId,
            characteristics: characteristics,
          }
        }

        dispatch({
          type: action_types.SUCCESS_CATEGORIES,
          payload: categories,
        })

        if (shouldGetAll) {
          dispatch(getAllItems())
        }
      })
      .catch(error => {
        const message = getErrorMessage(error)
        dispatch({
          type: action_types.ERROR_CATEGORIES,
          payload: message,
        })
      })
  }
}

//isFirstPage to refresh data in case of dynamic coupons
const getCategoryItems = (categoryId, filters = null, isFirstPage = false) => {
  return async dispatch => {
    let page = 0

    if (filters && filters.page) {
      page = filters.page

      if (Object.keys(filters).length == 1) {
        filters = null
      }
    }

    dispatch({
      type: action_types.REQUEST_CATEGORY_ITEMS,
      payload: {
        id: categoryId,
        loadingMore: page !== 0,
      },
    })

    let sort = ''
    let limit = 5

    if (filters) {
      limit = 5
    }

    if (filters && filters.sort) {
      sort = filters.sort
    }

    let category = 'coupon'
    let filterCategory = categoryId
    if (filters) {
      category = 'filter_products'
      filterCategory = 'filter_products'
    }

    const msisdn = ReduxCache.getMsisdn()
    const data = {
      queryOptions: {
        filter: '',
        pagination: {
          count: page,
          limit: limit,
        },
        sorting: sort,
      },
      queries: [
        {
          query: '$.redeemerId=' + msisdn,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.type=' + CONFIG.queryConfig.type,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.channelId=' + CONFIG.queryConfig.channelId,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.category=' + category,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.id=' + filterCategory,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.version=2',
        },
      ],
    }

    const url = await CONFIG.getUrl()
    const headers = await CONFIG.getHeaders()
    axios
      .post(url.apiEndpoint, data, {
        headers: {
          ...headers,
        },
      })
      .then(response => {
        const products = []
        const plainProducts = {}

        for (var i in response.data) {
          const product = response.data[i]
          var characteristics = {}

          for (var j in product.parts.specification.characteristicsValue) {
            var characteristic =
              product.parts.specification.characteristicsValue[j]
            characteristics[characteristic.characteristicName] =
              characteristic.value
          }

          let type = product.parts.productOffering[0].type

          if (
            type !== 'telco' &&
            product.parts.specification.desc &&
            product.parts.specification.desc === 'promotion'
          ) {
            type = 'promotion'
          }

          products.push({
            id: product.parts.productOffering[0].id[0].value,
            partner: product.name,
            name: product.parts.productOffering[0].name,
            type: type,
            desc: product.parts.productOffering[0].desc,
            favorite: product.status == 'favourite',
            validityPeriod: product.parts.productOffering[0].toDate.date,
            characteristics: characteristics,
            price: product.parts.productOffering[0].priceFinalPrice.value,
            currency:
              product.parts.productOffering[0].priceFinalPrice.currencyID,
            oldPrice: product.parts.productOffering[0].priceOriginalPrice.value,
          })

          plainProducts[product.parts.productOffering[0].id[0].value] = response.data[i]
        }

        // we also need to dispatch to plan object that we need to use when calling api
        dispatch({
          type: action_types.SUCCESS_PLAN_PRODUCTS,
          payload: plainProducts,
        })

        dispatch({
          type: action_types.SUCCESS_CATEGORY_ITEMS,
          payload: {
            id: categoryId,
            products: products,
            isFirstPage
          },
        })
      })
      .catch(error => {
        const message = getErrorMessage(error)
        dispatch({
          type: action_types.ERROR_CATEGORY_ITEMS,
          payload: {
            id: categoryId,
            error: message,
          },
        })
      })
  }
}

const getAllItems = (filters = null) => {
  return async dispatch => {
    dispatch({
      type: action_types.REQUEST_CATEGORY_ITEMS,
      payload: {
        id: 'all',
      },
    })

    let page = 0

    if (filters && filters.page) {
      page = filters.page
      filters = null
    }

    let id = 'all'
    if (filters) {
      id = 'filter_products'
    }

    let sort = ''
    let limit = 5

    if (filters) {
      limit = 20
    }

    if (filters && filters.sort) {
      sort = filters.sort
    }

    let category = 'coupon'
    if (filters) {
      category = 'filter_products'
    }

    const msisdn = ReduxCache.getMsisdn()

    const data = {
      queryOptions: {
        filter: '',
        pagination: {
          count: page,
          limit: limit,
        },
        sorting: sort,
      },
      queries: [
        {
          query: '$.redeemerId=' + msisdn,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.type=' + CONFIG.queryConfig.type,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.channelId=' + CONFIG.queryConfig.channelId,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.category=' + category,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.id=' + id,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.version=2',
        },
        {
          operator: 'AND',
        },
        {
          query: '$.version=2',
        },
      ],
    }

    if (filters && filters.location) {
      const location = filters.location

      data.queries.push(
        {
          operator: 'AND',
        },
        {
          query: '$.Location=' + location,
        },
      )
    }

    if (filters && filters.partner) {
      const partner = filters.partner

      data.queries.push(
        {
          operator: 'AND',
        },
        {
          query: '$.Partner=' + partner,
        },
      )
    }

    const url = await CONFIG.getUrl()
    const headers = await CONFIG.getHeaders()
    axios
      .post(url.apiEndpoint, data, {
        headers: {
          ...headers,
        },
      })
      .then(response => {
        const categories = {}
        const plainProducts = {}
        const allProducts = []

        for (const i in response.data) {
          const product = response.data[i]
          const characteristics = {}

          for (const j in product.parts.specification.characteristicsValue) {
            const characteristic =
              product.parts.specification.characteristicsValue[j]
            characteristics[characteristic.characteristicName] =
              characteristic.value
          }

          if (typeof categories[product.category[0].value] === 'undefined') {
            categories[product.category[0].value] = {
              data: [],
            }
          }

          let type = product.parts.productOffering[0].type

          if (
            type !== 'telco' &&
            product.parts.specification.desc &&
            product.parts.specification.desc == 'promotion'
          ) {
            type = 'promotion'
          }

          const categoryProduct = {
            id: product.parts.productOffering[0].id[0].value,
            partner: product.name,
            name: product.parts.productOffering[0].name,
            type: type,
            desc: product.parts.productOffering[0].desc,
            favorite: product.status == 'favourite',
            validityPeriod: product.parts.productOffering[0].toDate.date,
            characteristics: characteristics,
            price: product.parts.productOffering[0].priceFinalPrice.value,
            currency:
              product.parts.productOffering[0].priceFinalPrice.currencyID,
            oldPrice: product.parts.productOffering[0].priceOriginalPrice.value,
          }

          categories[product.category[0].value].data.push(categoryProduct)

          plainProducts[product.parts.productOffering[0].id[0].value] =
            response.data[i]
          allProducts.push(categoryProduct)
        }

        let isFilter = false
        if (filters) {
          isFilter = true
        }

        const resultCategories = ['all']

        for (const i in categories) {
          const products = categories[i].data
          resultCategories.push(i)

          dispatch({
            type: action_types.SUCCESS_CATEGORY_ITEMS,
            payload: {
              id: i,
              products: products,
              isFilter: isFilter,
              isFirstPage: true,
            },
          })
        }

        // if user is filtering we need to check which categories doesn't have items and empty them
        if (isFilter) {
          const state = store.getState()
          const allCategories = Object.keys(state.categories.data)

          // we need to get all categories that were not returned from api to make them empty in redux
          const emptyCategories = allCategories.filter(item => {
            return !resultCategories.includes(item)
          })

          for (var i in emptyCategories) {
            dispatch({
              type: action_types.SUCCESS_CATEGORY_ITEMS,
              payload: {
                id: allCategories[i],
                products: [],
                isFilter: isFilter,
                isFirstPage: true,
              },
            })
          }
        }

        dispatch({
          type: action_types.SUCCESS_CATEGORY_ITEMS,
          payload: {
            id: 'all',
            products: allProducts,
            isFilter: isFilter,
            isFirstPage: true,
          },
        })

        // we also need to dispatch to plan object that we need to use when calling api
        dispatch({
          type: action_types.SUCCESS_PLAN_PRODUCTS,
          payload: plainProducts,
        })
      })
      .catch(error => {

        const message = getErrorMessage(error)
        dispatch({
          type: action_types.ERROR_CATEGORY_ITEMS,
          payload: {
            id: 'all',
            error: message,
          },
        })
      })
  }
}

const getFavorites = page => {
  return async dispatch => {
    dispatch({
      type: action_types.REQUEST_FAVORITES,
    })

    if (typeof page === 'undefined') {
      page = 0
    }

    const msisdn = ReduxCache.getMsisdn()
    const data = {
      queryOptions: {
        filter: '',
        pagination: {
          count: page,
          limit: 5,
        },
        sorting: '',
      },
      queries: [
        {
          query: '$.redeemerId=' + msisdn,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.type=' + CONFIG.queryConfig.type,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.channelId=' + CONFIG.queryConfig.channelId,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.category=favourites',
        },
        {
          operator: 'AND',
        },
        {
          query: '$.version=2',
        },
      ],
    }

    const url = await CONFIG.getUrl()
    const headers = await CONFIG.getHeaders()
    axios
      .post(url.apiEndpoint, data, {
        headers: {
          ...headers,
        },
      })
      .then(response => {
        const products = []
        const plainProducts = {}

        for (var i in response.data) {
          const product = response.data[i]

          var characteristics = {}

          for (var j in product.parts.specification.characteristicsValue) {
            var characteristic =
              product.parts.specification.characteristicsValue[j]
            characteristics[characteristic.characteristicName] =
              characteristic.value
          }

          let type = product.parts.productOffering[0].type

          if (
            type !== 'telco' &&
            product.parts.specification.desc &&
            product.parts.specification.desc == 'promotion'
          ) {
            type = 'promotion'
          }

          products.push({
            id: product.parts.productOffering[0].id[0].value,
            partner: product.name,
            name: product.parts.productOffering[0].name,
            type: type,
            desc: product.parts.productOffering[0].desc,
            favorite: product.status == 'favourite',
            validityPeriod: product.parts.productOffering[0].toDate.date,
            characteristics: characteristics,
            price: product.parts.productOffering[0].priceFinalPrice.value,
            currency:
              product.parts.productOffering[0].priceFinalPrice.currencyID,
            oldPrice: product.parts.productOffering[0].priceOriginalPrice.value,
          })
          plainProducts[product.parts.productOffering[0].id[0].value] =
            response.data[i]
        }

        // we also need to dispatch to plan object that we need to use when calling api
        dispatch({
          type: action_types.SUCCESS_PLAN_PRODUCTS,
          payload: plainProducts,
        })

        dispatch({
          type: action_types.SUCCESS_FAVORITES,
          payload: {
            products: products,
            page: page,
          },
        })
      })
      .catch(error => {
        const message = getErrorMessage(error)
        dispatch({
          type: action_types.ERROR_FAVORITES,
          payload: message,
        })
      })
  }
}

const favorite = product => {
  return async dispatch => {
    dispatch({
      type: action_types.REQUEST_FAVORITE_HANDLE,
    })

    product = JSON.stringify(product)
    product = product.replace(/ë/g, 'e')
    product = product.replace(/ç/g, 'c')
    product = product.replace(/Ë/g, 'c')
    product = product.replace(/Ç/g, 'c')

    product = JSON.parse(product)

    const newProduct = { ...product }

    const id = newProduct.parts.productOffering[0].id[0].value

    newProduct.status == 'favourite'
      ? (newProduct.status = 'unfavourite')
      : (newProduct.status = 'favourite')

    // add static price because it's mandatory

    try {
      if (
        !newProduct.parts.productOffering[0].priceFinalPrice.value ||
        newProduct.parts.productOffering[0].priceFinalPrice.value == ''
      ) {
        newProduct.parts.productOffering[0].priceFinalPrice.value = 0
        newProduct.parts.productOffering[0].priceOriginalPrice.value = 0
      }
    } catch (error) { }

    const url = await CONFIG.getUrl()
    const headers = await CONFIG.getHeaders()
    axios
      .patch(url.favoritesEndpoint + id, newProduct, {
        headers: {
          ...headers,
        },
      })
      .then(response => {
        const product = response.data
        var characteristics = {}

        for (var j in product.parts.specification.characteristicsValue) {
          var characteristic =
            product.parts.specification.characteristicsValue[j]
          characteristics[characteristic.characteristicName] =
            characteristic.value
        }

        let type = product.parts.productOffering[0].type

        if (
          type !== 'telco' &&
          product.parts.specification.desc &&
          product.parts.specification.desc == 'promotion'
        ) {
          type = 'promotion'
        }

        const favProduct = {
          id: product.parts.productOffering[0].id[0].value,
          partner: product.name,
          name: product.parts.productOffering[0].name,
          type: type,
          desc: product.parts.productOffering[0].desc,
          favorite: product.status == 'favourite',
          validityPeriod: product.parts.productOffering[0].toDate.date,
          characteristics: characteristics,
          price: product.parts.productOffering[0].priceFinalPrice.value,
          currency: product.parts.productOffering[0].priceFinalPrice.currencyID,
          oldPrice: product.parts.productOffering[0].priceOriginalPrice.value,
        }

        dispatch({
          type: action_types.SUCCESS_FAVORITE_HANDLE,
          payload: {
            id: product.parts.productOffering[0].id[0].value,
            category: product.category[0].value,
            favorite: product.status == 'favourite',
            status: product.status,
            product: favProduct,
          },
        })

        dispatch(getFavorites())
      })
      .catch(error => {
        const message = getErrorMessage(error)
        toastr.showToast(message)
        dispatch({
          type: action_types.ERROR_FAVORITE_HANDLE,
          payload: message,
        })
      })
  }
}

const generateCoupon = (product, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: action_types.GENERATE_COUPON,
    })

    product = JSON.stringify(product)
    product = product.replace(/ë/g, 'e')
    product = product.replace(/ç/g, 'c')
    product = product.replace(/Ë/g, 'e')
    product = product.replace(/Ç/g, 'c')

    product = JSON.parse(product)

    const newProduct = { ...product }

    const msisdn = ReduxCache.getMsisdn()

    const data = {
      queryOptions: {
        filter: '',
        pagination: {
          count: 0,
          limit: 5,
        },
        sorting: '',
      },
      queries: [
        {
          query: '$.redeemerId=' + msisdn,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.type=' + CONFIG.queryConfig.type,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.channelId=' + CONFIG.queryConfig.channelId,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.category=qr',
        },
        {
          operator: 'AND',
        },
        {
          query: `$.id=${newProduct.id}`,
        },
        {
          operator: 'AND',
        },
        {
          query: `$.parts.productOffering[0].priceFinalPrice.value=${newProduct.value}`,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.parts.productOffering[0].priceFinalPrice.currencyID=leke',
        },
      ],
    }

    const url = await CONFIG.getUrl()
    const headers = await CONFIG.getHeaders()
    axios
      .post(url.apiEndpoint, data, {
        headers: {
          ...headers,
        },
      })
      .then(response => {
        const result = response?.data?.[0]
        dispatch({
          type: action_types.SUCCESS_COUPON,
          payload: {
            ...result,
          },
        })
        onSuccess()
      })
      .catch(error => {
        const message = getErrorMessage(error)
        dispatch({
          type: action_types.ERROR_COUPON,
          payload: message,
        })
        onError()
      })
  }
}

const getActiveCoupon = (id, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: action_types.REQUEST_ACTIVE_COUPON,
    })

    const msisdn = ReduxCache.getMsisdn()

    const data = {
      'queryOptions': {
        'filter': '',
        'pagination': {
          'count': 0,
          'limit': 10,
        },
        'sorting': '',
      },
      'queries': [
        {
          'query': `$.redeemerId=${msisdn}`,
        },
        {
          'operator': 'AND',
        },
        {
          'query': `$.id=${id}`,
        },
        {
          'operator': 'AND',
        },
        {
          'query': `$.type=${CONFIG.queryConfig.type}`,
        },
        {
          'operator': 'AND',
        },
        {
          'query': `$.channelId=${CONFIG.queryConfig.channelId}`,
        },
        {
          'operator': 'AND',
        },
        {
          'query': '$.category=dynamic_coupon_active',
        },
      ],
    }

    const url = await CONFIG.getUrl()
    const headers = await CONFIG.getHeaders()
    axios
      .post(url.apiEndpoint, data, {
        headers: {
          ...headers,
        },
      })
      .then(response => {
        const result = response?.data?.[0]
        onSuccess()
        dispatch({
          type: action_types.SUCCESS_ACTIVE_COUPON,
          payload: {
            ...result,
          },
        })
      })
      .catch(error => {
        const message = getErrorMessage(error)
        onError()
        dispatch({
          type: action_types.ERROR_ACTIVE_COUPON,
          payload: message,
        })
      })
  }
}

const cancelCoupon = (couponId, data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: action_types.REQUEST_CANCEL_COUPON,
    })

    const url = await CONFIG.getUrl()
    const headers = await CONFIG.getHeaders()

    let product = data.parts?.productOffering?.[0]

    product = JSON.stringify(product)
    product = product.replace(/ë/g, 'e')
    product = product.replace(/ç/g, 'c')
    product = product.replace(/Ë/g, 'e')
    product = product.replace(/Ç/g, 'c')

    product = JSON.parse(product)

    const newProduct = { ...product }

    axios
      .patch(
        url.favoritesEndpoint + couponId,
        {
          ...data,
          status: 'canceled',
          parts: {
            ...data.parts,
            productOffering: [newProduct],
          },
        },
        {
          headers: {
            ...headers,
          },
        })
      .then(response => {
        onSuccess()
        dispatch({
          type: action_types.SUCCESS_CANCEL_COUPON,
          payload: {},
        })
      })
      .catch(error => {
        onError()
        const message = getErrorMessage(error)
        dispatch({
          type: action_types.ERROR_CANCEL_COUPON,
          payload: message,
        })
      })
  }
}

export const actions = {
  getCategories,
  getCategoryItems,
  getFavorites,
  getAllItems,
  favorite,
  generateCoupon,
  getActiveCoupon,
  cancelCoupon,
}
