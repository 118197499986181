import {actionTypes} from './VfHearts.actionsTypes'

function serviceBalanceState(
  state = {loading: false, data: null, error: null},
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_GET_SERVICE_BALANCE:
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_GET_SERVICE_BALANCE:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      }
    case actionTypes.ERROR_GET_SERVICE_BALANCE:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      }
    case actionTypes.RESET_GET_SERVICE_BALANCE:
      return {
        ...state,
        loading: false,
        data: null,
        error: null,
      }
    default:
      return state
  }
}

function reddemLoveBoxState(
  state = {
    loading: false,
    isSuccess: false,
    isAttention: false,
    isError: false,
    data: null,
    error: null,
  },
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_LOVE_BOX_REDEEM:
      return {
        ...state,
        loading: true,
        isSuccess: false,
        isAttention: false,
        isError: false,
      }

    case actionTypes.SUCCESS_LOVE_BOX_REDEEM:
      return {
        ...state,
        loading: false,
        isSuccess: true,
        isAttention: false,
        isError: false,
        data: action.payload,
        error: null,
      }
    case actionTypes.ERROR_LOVE_BOX_REDEEM:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        isAttention: false,
        isError: true,
        data: null,
        error: action.payload,
      }
    case actionTypes.RESET_LOVE_BOX_REDEEM:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        isAttention: false,
        isError: false,
        data: null,
        error: null,
      }
    default:
      return state
  }
}

function infoState(
  state = {
    active: false,
  },
  action = {},
) {
  switch (action.type) {
    case actionTypes.ACTIVATE_INFO:
      return {
        ...state,
        active: true,
      }

    case actionTypes.DEACTIVATE_INFO:
      return {
        ...state,
        active: false,
      }
    default:
      return state
  }
}

export {serviceBalanceState, reddemLoveBoxState, infoState}
