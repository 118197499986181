import {createStore, compose, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {persistStore, persistReducer} from 'redux-persist'
import AsyncStorage from '@react-native-async-storage/async-storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

import rootReducer from './Reducers'

const enhancerList = []
const devToolsExtension = window && window.__REDUX_DEVTOOLS_EXTENSION__

if (typeof devToolsExtension === 'function') {
  enhancerList.push(devToolsExtension())
}

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['categories', 'filters', 'splash', 'images', 'auth', 'language'],
  timeout: 0,
  stateReconciler: autoMergeLevel2,
}

const composedEnhancer = compose(
  applyMiddleware(thunk),
  ...enhancerList,
)

const pReducer = persistReducer(persistConfig, rootReducer)
export const store = createStore(pReducer, {}, composedEnhancer)
export const persistor = persistStore(store)
