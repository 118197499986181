import React, { Component } from 'react'
import { View, TouchableOpacity } from 'react-native'
import { Card, CardItem, Badge, Text, Left, Body, Right } from 'native-base'

import { VFText } from '@vfgroup-oneplatform/foundation/Components'

import WebView from '../WebView'

import { __translate, __image } from '../../Utils'
import Image from '../Image'

import styles from './product.styles'

export default class CustomCard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false,
      data: this.props.data,
      isDynamic: this.props.isDynamic,
    }
  }

  _expand() {
    this.setState({
      expanded: !this.state.expanded,
    })
  }

  handleClick() {
    this.props.favorite(this.state.data.id)

    this.setState({
      data: {
        ...this.state.data,
        favorite: !this.state.data.favorite,
      },
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if ((nextProps.data.favorite !== prevState.data.favorite) || (nextProps.data.price !== prevState.data.price)) {
      return { data: nextProps.data }
    } else {
      return prevState
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.expanded !== this.state.expanded ||
      this.state.data.favorite !== nextState.data.favorite ||
      this.state.data.price !== nextProps.data.price
    )
  }


  render() {
    const { data } = this.state
    let newDataDesc
    const indexOfTag = data?.desc?.indexOf('<a ') + 3
    if (indexOfTag !== -1) {
      newDataDesc = data?.desc.slice(0, indexOfTag) + ` target="_blank" ` + data?.desc.slice(indexOfTag);
    } else {
      newDataDesc = data?.desc
    }

    return (
      <View style={styles.content}>
        <Card style={styles.card}>
          <TouchableOpacity
            style={styles.favHeart}
            onPress={() => this.handleClick()}>
            {data.favorite ? (
              <Image
                style={styles.favoritesIcon}
                source={{ uri: __image('reward_loyalty_selected_fav_bt') }}
              />
            ) : (
              <Image
                style={styles.favoritesIcon}
                source={{ uri: __image('reward_loyalty_fav_bt') }}
              />
            )}
          </TouchableOpacity>

          <CardItem
            onPress={() => this.props.handleClick(true)}
            button
            cardBody
            style={styles.main}>
            <Image
              style={{ height: 200, width: '100%', flex: 1 }}
              source={{ uri: data.characteristics.imageURL }}
            />
          </CardItem>

          <View
            style={[
              styles.partner,
              { backgroundColor: data.characteristics.color }
            ]}>
            <Text style={styles.partnerText}> {data.partner} </Text>
          </View>

          <Badge style={styles.date}>
            <Text style={styles.dateText}>
              {data.type !== 'promotion'
                ? data.validityPeriod
                : `${data.characteristics.promotion} Available`}
            </Text>
          </Badge>

          <CardItem style={styles.cardItem} footer>
            <Left style={styles.left}>
              <Text style={styles.offerValue}> {data.name} </Text>
            </Left>
            <Body style={styles.body}>
              <TouchableOpacity
                style={styles.moreButton}
                onPress={() => this._expand()}
              >
                <VFText
                  i18nKey={this.state.expanded ? 'reward_loyalty_show_less' : 'reward_loyalty_show_more'}
                  style={styles.moreText}
                />
                <Image
                  style={
                    this.state.expanded
                      ? styles.moreIconOpened
                      : styles.moreIcon
                  }
                  source={{ uri: __image('reward_loyalty_long_desc_bt') }}
                />
              </TouchableOpacity>
            </Body>
            <Right style={styles.right}>
              {(parseInt(data.price, 10) > 0) && <Text style={styles.priceValue}>
                {data.price}{' '}
                <Text style={styles.priceText}>{data.currency}</Text>
              </Text>}
            </Right>
          </CardItem>

          {this.state.expanded && (
            <View style={{ padding: 10, paddingTop: 0 }}>
              <WebView style={styles.contentWebView(80)} content={newDataDesc} />
            </View>
          )}
        </Card>
      </View>
    )
  }
}
