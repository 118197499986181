import {Register} from '../../Services'
import { formatPhoneNumber } from '../../Utils/Regex'

const register = async (phoneNumber, password, pin) => {
    try {
        phoneNumber = formatPhoneNumber(phoneNumber)

        const response = await Register(phoneNumber, password, pin, {bypass_token: true}, {})

        return response
    } catch (error) {
        throw error
    }
}

const Actions = {register}

export {Actions}
