export const action_types = {
  REQUEST_CATEGORIES: 'REQUEST_CATEGORIES',
  SUCCESS_CATEGORIES: 'SUCCESS_CATEGORIES',
  ERROR_CATEGORIES: 'ERROR_CATEGORIES',
  REQUEST_CATEGORY_ITEMS: 'REQUEST_CATEGORY_ITEMS',
  SUCCESS_CATEGORY_ITEMS: 'SUCCESS_CATEGORY_ITEMS',
  ERROR_CATEGORY_ITEMS: 'ERROR_CATEGORY_ITEMS',
  REQUEST_FAVORITES: 'REQUEST_FAVORITES',
  SUCCESS_FAVORITES: 'SUCCESS_FAVORITES',
  ERROR_FAVORITES: 'ERROR_FAVORITES',
  REQUEST_FAVORITE_HANDLE: 'REQUEST_FAVORITE_HANDLE',
  SUCCESS_FAVORITE_HANDLE: 'SUCCESS_FAVORITE_HANDLE',
  ERROR_FAVORITE_HANDLE: 'ERROR_FAVORITE_HANDLE',
  SUCCESS_PLAN_PRODUCTS: 'SUCCESS_PLAN_PRODUCTS',
}