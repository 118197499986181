export default {
    app_name: 'My Vodafone',

    general_error: 'Something went wrong!',
    top_up_quick_action_manage_payment_method_item_text: 'Manage',
    ALL: 'Lekë',
    ALL: 'ALL',
    Lekë: 'ALL',
    Lek: 'ALL',
    top_up_someone_else_quick_action_title: 'Top up another number',
    top_up_someone_else_new_recipient_title: 'Top up another number and get 1GB bonus',
    top_up_someone_else_next_button_title: 'Next',
    no_cards: 'You have no saved cards!',
    ': ': '',

    // Dashboard
    everything_is_ok_greeting_text: 'Hello %1$s',
    everything_is_ok_description_text: 'Just checking if everything is OK',
    everything_is_ok_text: 'Hello',
    everything_is_ok_error_text: 'We\'ve found an issue…',
    everything_is_ok_details_screen_success_text: 'Everything is OK, %1$s. We\'ll let you know if anything changes.',
    everything_is_ok_details_screen_in_progress_text: 'We\'re checking your services. This won\'t take long.',
    everything_is_ok_details_screen_error_text: 'We\'ve found something that you need to have a look at…',
    everything_is_ok_in_progress_text: 'Checking your services…',
    everything_is_ok_billing_title: 'Billing',
    everything_is_ok_last_bill_paid_title: 'Last bill paid',
    everything_is_ok_last_bill_paid_description: 'Okay',
    everything_is_ok_additional_charges_title: 'Additional charges',
    everything_is_ok_additional_charges_description: 'You have %1$s out of plan spend on your current bill. Please review these charges.',
    everything_is_ok_additional_charges_action_title: 'Review',
    everything_is_ok_data_remaining_title: 'Data Remaining',
    everything_is_ok_account_title: 'Account',
    everything_is_ok_address_up_to_date_title: 'Address up to date',
    everything_is_ok_payment_details_title: 'Payment Details',
    everything_is_ok_payment_details_description: 'Your payment details are out of date.',
    everything_is_ok_payment_details_action_title: 'Update',
    everything_is_ok_my_phone_title: 'My phone',
    everything_is_ok_my_fix_title: 'Fix Services',
    everything_is_ok_broadband_title: 'Broadband',
    everything_is_ok_speed_checker_action: 'Speed Checker',
    everything_is_ok_phone_title: '%1$s\'s phone',
    dashboard_title: 'Dashboard',
    dashboard_help_and_support_item_title: 'Help and support',
    dashboard_app_get_action_label: 'Get',
    dashboard_app_open_action_label: 'Open',
    dashboard_upgrade_component_name: 'upgrade component',
    dashboard_upgrade_component_title: 'This is upgrade component',
    dashboard_upgrade_component_title_topup: '%1$s, top up by %2$s%3$s or more and get %4$s%5$s data for %6$s%7$s',
    dashboard_upgrade_component_subtitle_topup: 'Normal price %1$s%2$s.\nOffer ends on %3$s.',
    dashboard_basic_group_component_name: 'Basics',
    dashboard_group_component_show_more: 'Show more',
    dashboard_group_component_show_less: 'Show less',
    dashboard_demo_component_name: 'Demo Component',
    dashboard_top_up_item_title: 'Top-up',
    dashboard_change_pin_item_title: 'Change PIN',
    dashboard_your_messages_item_title: 'Your messages',
    dashboard_purchase_add_on_item_title: 'Purchase add on',
    dashboard_assistance_group_component_name: 'Assistance',
    dashboard_travelling_abroad_item_title: 'Travelling abroad',
    dashboard_in_store_appointment_item_title: 'In-store appointments',
    dashboard_store_locator_item_title: 'Store locator',
    dashboard_wifi_finder_item_title: 'WiFi finder',
    dashboard_demo_component_title: 'This is our demo component',
    dashboard_developer_settings_item_title: 'Developer settings',
    dashboard_offers_rewards_group_component_name: 'Offers and rewards',
    dashboard_network_health_group_component_name: 'Network Health',
    dashboard_speed_checker_title: 'Speed Checker',
    dashboard_broadband_finder_item_title: 'Broadband finder',
    dashboard_apps_component_title: 'Other Vodafone apps',
    dashboard_facebook_item_title: 'Facebook',
    dashboard_twitter_item_title: 'Twitter',
    dashboard_slack_item_title: 'Slack',
    dashboard_loading_error_message: 'An error has occurred and we\'re having some trouble loading your content.',
    dashboard_loading_error_try_again_button: 'Try again',
    dashboard_item_error_normal_message: 'An error has occurred',
    dashboard_item_error_normal_try_again_button: 'Try again',
    dashboard_item_error_small_message: 'Error occurred',
    dashboard_item_error_small_try_again_button: 'Try again',
    dashboard_top_up_card_title: 'Top-up',
    dashboard_top_up_card_subtitle: 'Your balance is:',
    dashboard_top_up_card_subtitle: 'Your balance is: %1$s %1$s',
    dashboard_billing_card_title: 'eBill',
    dashboard_billing_card_subtitle: 'Last bill:',
    dashboard_billing_card_subtitle: 'Last bill: %1$s %1$s',
    dashboard_billing_card_pay_date: 'Pay %1$s%2$s %3$s',
    dashboard_top_up_card_username: '%1$s\'s phone',
    dashboard_top_up_card_last_updated: 'Updated %1$@ ago',
    dashboard_top_up_card_date_paid_title: 'Load amount',
    dashboard_top_up_card_badge_unpaid_title: 'Load amount',
    dashboard_top_up_card_description_unpaid_title: 'Couldn\'t renew tariff',
    dashboard_top_up_card_due_in_days: 'Due in %1$s days',
    dashboard_my_messages_card_title: 'My messages',
    dashboard_view_all_spendings_button: 'View all spendings',
    dashboard_offline_text: 'Hello %1$s you are offline',
    app_error_title: 'An error has occurred',
    app_error_description: 'Your connection was interrupted or your network has changed, your device may be trying to connect to too many networks at once.',
    app_error_button: 'Retry',
    dashboard_card_topup_user_phone: '%1$s\'s phone',
    lastupdatedlabel_minute: 'Refreshed %1$s minute ago',
    lastupdatedlabel_minutes: 'Refreshed %1$s minutes ago',
    lastupdatedlabel_timestamp: 'Refreshed at %1$s',
    lastupdatedlabel_updating: 'Refreshing...',
    lastupdatedlabel_justupdated: 'Refresh',
    billing_dashboard_next_available_date: '',
    billing_no_bill_subtitle: '',
    'My Data': 'My Data',
    'Local Calls': 'Local calls',
    dashboard_bundle_amount_left: 'left of %1$s',
    unlimited_bar_data: 'Unlimited',
    unlimited_bar_sms: 'Unlimited',
    unlimited_bar_voice: 'Unlimited',
    activate_bundle: 'Activate bundle',
    'Credit': 'Credit',
    dashboard_collected: 'collected',
    dashboard_promo_label_hours: 'hours',
    dashboard_promo_label_days: 'days',
    dashboard_promo_label_min: 'min',
    dashboard_get_your_ticket: 'Enter now',
    dashboard_tickets: 'Tickets',
    dashboard_details: 'Details',
    dashboard_fix_lines: '%1$s Fix Lines',
    dashboard_fix_line: '1 Fix Line',
    unlimited_sms: 'Unlimited communications',
    unlimited_voice: 'Unlimited communications',
    no_active_bundle: 'You don\'t have an active bundle:',
    dashboard_get_more_data: 'Get more Data',
    dashboard_billing_postpay_card_subtitle: 'Out of plan charges',

    //for fix user types
    dashboard_card_created_status: 'In process',
    dashboard_card_active_status: 'Active',
    dashboard_card_inactive_status: 'Inactive',
    dashboard_card_status: 'Off',
    dashboard_card_cancelled_status: 'Terminated',
    dashboard_card_terminated_status: 'Terminated',
    dashboard_my_mobile: "My mobile",
    contracts_first_day: 'First day of contract',
    order_progress_title: 'Order no. %1$s',
    order_progress_account: "%1$s's account",
    order_progress_status: 'Status: Order %1$s',
    order_progress_delivery: 'Estimated delivery: %1$s',

    order_progress_scan_button: 'Scan',
    order_progress_active_sim_title: "You've activated",
    order_progress_active_sim_subtitle: 'Vodafone SIM',
    order_progress_active_sim_dashboard: 'Go to dashboard',
    order_progress_error: 'Oops!',
    order_progress_error_message: 'Something went wrong',
    order_progress_close: 'Close',
    order_progress_order_confirmed: 'Order confimed',
    order_progress_sim_delivery: 'Sim Delivery',
    order_progress_order_completed: 'Order completed',
    order_progress_activate_service: 'Activate service',
    active_sim_title: 'Enjoy the best of Vodafone',
    active_sim_first_item_title: 'Best internet grid in Albania',
    active_sim_second_item_title: 'Best digital experience in Albania',
    active_sim_third_item_title: 'Best Customer Support',
    active_sim_card_title: 'New Vodafone SIM',
    active_sim_card_subtitle: 'Phone number',
    active_sim_card_active: 'Active',
    scan_sim_scan_button: 'Scan my SIM',
    scan_sim_title: 'Your SIM is ready.',
    scan_sim_subtitle: 'Scan your card and enjoy Vodafone products and services',
    prelogin_welcome: 'Welcome to My Vodafone',
    prelogin_quick_links: 'Discover Vodafone products',
    prelogin_getStarted: 'Get Started',
    prelogin_continue_as_guest: 'Continue as Guest',

    guest_pop_up_title: 'To check Vodafone products, services and offers you have to create an account',
    guest_pop_up_description: 'Dont worry you can create an account even if you are not a Vodafone customer',
    guest_pop_up_login: "Login here",
    guest_pop_up_signup: "Register here",

    story_offerExpires: 'Offer validity: %1$s',
    reward_loyalty_show_more: 'Show more',
    reward_loyalty_show_less: 'Show less',
    //tutorial & support
    support_dashboard_modal_title: "Hello I'm TOBi",
    support_dashboard_modal_subtitle: "How can I help you?",
    support_dashboard_help_button: 'Ask me a question',
    support_dashboard_modal_desc: "Quick help",
    tobi_interactive_help_message: 'Let me know if you need any help',


    tooltips_first_card_title_hybrid_fix: 'My products & account',
    tooltips_second_card_title_hybrid_fix: 'My phone',
    tooltips_third_card_title_hybrid_fix: 'My bills',
    tooltips_fourth_card_title_hybrid_fix: 'My offers',
    tooltips_first_card_subtitle_hybrid_fix: 'test',
    tooltips_second_card_subtitle_hybrid_fix: 'test',
    tooltips_third_card_subtitle_hybrid_fix: 'test',
    tooltips_fourth_card_subtitle_hybrid_fix: 'test',

    tooltips_first_card_title_hybrid: 'My products & account',
    tooltips_second_card_title_hybrid: 'My phone',
    tooltips_third_card_title_hybrid: 'My bills',
    tooltips_fourth_card_title_hybrid: 'My offers',
    tooltips_first_card_subtitle_hybrid: 'test',
    tooltips_second_card_subtitle_hybrid: 'test',
    tooltips_third_card_subtitle_hybrid: 'test',
    tooltips_fourth_card_subtitle_hybrid: 'test',


    tooltips_first_card_title_prepaid_fix: 'My products & account',
    tooltips_second_card_title_prepaid_fix: 'My phone',
    tooltips_third_card_title_prepaid_fix: 'My bills',
    tooltips_fourth_card_title_prepaid_fix: 'My offers',
    tooltips_first_card_subtitle_prepaid_fix: 'test',
    tooltips_second_card_subtitle_prepaid_fix: 'test',
    tooltips_third_card_subtitle_prepaid_fix: 'test',
    tooltips_fourth_card_subtitle_prepaid_fix: 'test',

    tooltips_first_card_title_prepaid: 'My products & account',
    tooltips_second_card_title_prepaid: 'My phone',
    tooltips_third_card_title_prepaid: 'My bills',
    tooltips_fourth_card_title_prepaid: 'My offers',
    tooltips_first_card_subtitle_prepaid: 'test',
    tooltips_second_card_subtitle_prepaid: 'test',
    tooltips_third_card_subtitle_prepaid: 'test',
    tooltips_fourth_card_subtitle_prepaid: 'test',


    tooltips_first_card_title_postpaid_fix: 'My products & account',
    tooltips_second_card_title_postpaid_fix: 'My phone',
    tooltips_third_card_title_postpaid_fix: 'My bills',
    tooltips_fourth_card_title_postpaid_fix: 'My offers',
    tooltips_first_card_subtitle_postpaid_fix: 'test',
    tooltips_second_card_subtitle_postpaid_fix: 'test',
    tooltips_third_card_subtitle_postpaid_fix: 'test',
    tooltips_fourth_card_subtitle_postpaid_fix: 'test',

    tooltips_first_card_title_postpaid: 'My products & account',
    tooltips_second_card_title_postpaid: 'My phone',
    tooltips_third_card_title_postpaid: 'My bills',
    tooltips_fourth_card_title_postpaid: 'My offers',
    tooltips_first_card_subtitle_postpaid: 'test',
    tooltips_second_card_subtitle_postpaid: 'test',
    tooltips_third_card_subtitle_postpaid: 'test',
    tooltips_fourth_card_subtitle_postpaid: 'test',

    tooltips_first_card_title_non_vf: 'My products & account',
    tooltips_second_card_title_non_vf: 'My phone',
    tooltips_third_card_title_non_vf: 'My bills',
    tooltips_fourth_card_title_non_vf: 'My offers',
    tooltips_first_card_subtitle_non_vf: 'test',
    tooltips_second_card_subtitle_non_vf: 'test',
    tooltips_third_card_subtitle_non_vf: 'test',
    tooltips_fourth_card_subtitle_non_vf: 'test',

    tooltips_first_card_title_guest: 'My products & account',
    tooltips_second_card_title_guest: 'My phone',
    tooltips_third_card_title_guest: 'My bills',
    tooltips_fourth_card_title_guest: 'My offers',
    tooltips_first_card_subtitle_guest: 'test',
    tooltips_second_card_subtitle_guest: 'test',
    tooltips_third_card_subtitle_guest: 'test',
    tooltips_fourth_card_subtitle_guest: 'test',


    tooltips_first_card_title_fix: 'My products & account',
    tooltips_second_card_title_fix: 'My phone',
    tooltips_third_card_title_fix: 'My bills',
    tooltips_fourth_card_title_fix: 'My offers',
    tooltips_first_card_subtitle_fix: 'test',
    tooltips_second_card_subtitle_fix: 'test',
    tooltips_third_card_subtitle_fix: 'test',
    tooltips_fourth_card_subtitle_fix: 'test',
    //recommended bundles
    recommended_bundles_screen_title: 'My offers',
    recommended_bundles_title: 'Only for you!',
    recommended_bundles_subtitle: 'Choose your favorite bundle and enjoy the endless communication.',
    recommended_bundles_button: 'View standard bundles',
    recommended_bundles_view_more: 'View More',
    recommended_bundles_activate: 'Activate now',


    // BBV
    bigbrother_bbv_tittle: 'Big Brother VIP 2',
    bigbrother_no_balance: 'You do not have enough balance to vote.',
    bigbrother_refill_now: 'Recharge now to vote for your favorite competitor.',
    bigbrother_voting_closed: 'Voting is closed',
    bigbrother_confirm: 'Confirm',
    bigbrother_confirmation: 'Confirmation',
    bigbrother_details: 'Details',

    bbadminpanel_bbv_administration: 'BBV Administration',
    bbadminpanel_voting_status_label: 'Open / Close voting',
    bbadminpanel_voting_status_open: 'Opened',
    bbadminpanel_voting_status_closed: 'Closed',
    bbadminpanel_voting_type_label: 'Save / Eliminate',
    bbadminpanel_voting_type_save: 'Save',
    bbadminpanel_voting_type_eliminate: 'Eliminate',
    bbadminpanel_set_voting: 'Set up the voting',
    bbadminpanel_add_title: 'Add main text/ title',
    bbadminpanel_select_housemates: 'Choose the housemates',
    bbadminpanel_post_voting: 'Post',
    bbadminpanel_text_title: 'Title text',


    SMS: 'SMS',
    MIN: 'MIN',

    tray_accounts_section_title: 'My account',
    tray_messages_section_title: 'Messages',
    tray_messages_no_new_messages: 'No new messages',

    sub_tray_payment_title: 'Payment',
    sub_tray_payment_subtitle: '%1$s methods available',
    sub_tray_payment_subtitle_one_method: '1 method available',
    sub_tray_payment_subtitle_zero_method: '0 payment methods',

    subtray_app_settings_title: 'Settings',
    subtray_app_settings_sub_title: 'Change your settings',
    logout_title: 'Log out',
    logout_subtitle: '%1$s\'s account',
    logout_description: 'Are you sure you want to log out of My Vodafone?',
    logout_button: 'Log out',
    logout_cancel_button: 'Cancel',
    sub_tray_my_products_title: 'Products & Services',
    my_account: 'My account',
    sub_tray_select_one: 'Select one',

    netperform_speed_checker_history_no_tests_description: '',
    netperform_speed_checker_more_info_title: 'More Info',
    netperform_speed_checker_more_info_url: 'https://api-web-ci-vfal.vodafone.com/file/speedtest/more_info/MoreInfoSpeedTestEN.html',

    //Settings
    settings_screen_title: 'Settings',
    settings_general_settings_title: 'General settings',
    settings_general_settings_subtitle: 'Manage your app configurations',
    settings_permissions_title: 'Permissions',
    settings_permissions_subtitle: 'Manage permissions',
    settings_device_permissions_title: 'Device permission',
    settings_device_permissions_subtitle: 'Customize which permissions you give access to certain apps.',
    settings_profile_manage_title: 'Manage your profile',
    settings_profile_manage_subtitle: 'Customise and edit your profile',
    improving_our_network_title: 'Network',
    improving_our_network_description: 'Network description',
    display_options_display_mode_section_title: 'Display mode',
    display_options_display_mode_section_description: '',
    display_options_mode_auto: 'Auto',
    display_options_mode_light: 'Light',
    display_options_mode_dark: 'Dark',
    settings_language_title: 'Language',
    settings_language_subtitle: 'Choose your language',
    app_languages_english: 'English',
    app_languages_albanian: 'Albanian',
    biometric_toggle_subtitle: 'Use Biometrics to easily access My Vodafone with your Phone.',
    biometric_toggle_title: 'Touch ID',
    permissions_app_personalised_description: 'Dependent on the level of access you have given us, we may make recommendations and tailor our service based on your location, quality of your connectivity service & installed apps data consumption.',
    permissions_app_personalised_title: 'Personalised services and recommendations',
    permissions_contact_description: 'Sync photos and names from your address book directly to numbers and devices to your account',
    permissions_contact_title: 'Contact Book',
    permissions_contacts_card_button_title: 'Give permission',
    permissions_contacts_card_description: 'To display your contacts here, My Vodafone needs permission to access your contacts.',
    permissions_contacts_card_title: 'Your contact list',
    permissions_footer_text: 'To manage the level of access Vodafone App has on your device tap',
    permissions_location_description: 'To show you messages and important information based on your location.',
    permissions_location_title: 'Location',
    permissions_network_description: 'Use the data collected to help improve our network which won\'t be linked back to you in any way',
    permissions_network_title: 'Network',
    permissions_personalised_description: 'Use data which identifies you to provide you with personalised services and recommendations',
    permissions_personalised_title: 'Personalised services and recommendations',
    permissions_push_notifications_description: 'Let us keep you up to date with important notifications about your account',
    permissions_push_notifications_title: 'Push Notifications',
    permissions_request_hypertext: 'Tap here to turn on',
    permissions_settings_hypertext: 'Settings',
    picker_select_done_text: 'Done',
    privacy_permissions_happy_with_this: 'I am happy with this',
    privacy_permissions_learn_more: 'Learn more',
    privacy_permissions_set_settings: 'Set privacy settings',
    privacy_permissions_show_less: 'Show less',
    phone_calls_permission_title: 'Phone Calls',
    phone_calls_permission_description: 'To understand the phone identity and use the network speed checker in the app.',
    call_logs_permission_title: 'Call Logs',
    call_logs_permission_description: 'Lorem ipsum dolor sit amet, consecteture. Lorem ipsum dolor sit amet, consectetur.',
    personalised_permission_title: 'Personalised services and recommendations',
    personalised_permission_description: 'Use data which identifies you to provide you with personalised services and recommendations.',
    permissions_usage_title: 'Usage',
    permissions_usage_description: 'To understand your network performance we use data from your phone',
    profile_settings_save_name_success_title: 'Username saved successfully!',

    profile_manage_title: 'Manage profile',
    profile_settings_title: 'Profile settings',
    profile_settings_subtitle: 'Manage your profile details',
    profile_settings_edit_title: 'Edit profile',
    profile_settings_edit_subtitle: 'Edit yur profile',
    profile_settings_edit_password_title: 'Edit password',
    profile_settings_edit_password_subtitle: 'Edit your password',
    profile_settings_deactivate_title: 'Deactivate account',
    profile_settings_deactivate_subtitle: 'Delete your account and all your data in My Vodafone',
    profile_settings_name_label: 'Your name',
    profile_settings_name_helper: 'You have 12 characters in your disposal',
    profile_settings_name_helper_error: 'You have typed more than 12 characters',
    profile_settings_name_input_label: 'Add name',
    profile_settings_edit_name_title: 'Edit your name',
    profile_settings_edit_name_subtitle: 'How shall we address to you? Add how you want to be called so it is easier for us to communicate.',
    profile_settings_save_button: 'Save',

    delete_account_screen_title: 'Delete account',
    delete_account_long_description: 'By deleting the profile you will be permanently erase all data you currently have on your My Vodafone app.\n\n Do not worry as you will continue to be a Vodafone customer and you can reopen a new account in the application at any time.',
    delete_account_less_button: 'Show less',
    delete_account_more_button: 'Show more',
    delete_account_delete_button: 'Delete account',
    delete_account_note_title: 'Deleting your profile',
    delete_account_profile_note_title: 'Your profile',
    delete_account_profile_note_description: 'Your profile holds data that are useful to you through the experience with Vodafone',
    delete_account_button: 'Delete',
    delete_account_cancel_button: 'Cancel',
    delete_account_confirm_title: 'Are you sure you want to delete your profile?',
    delete_account_confirm_text: 'You will lose all your data in the app',
    delete_account_feedback_text: 'Can you give us some comments on why you decided to delete your profile',
    delete_account_other_reason: 'Other',
    delete_account_title: 'Delete profile',

    delete_account_first_reason: 'I did not like the app',
    delete_account_second_reason: 'It is a very slow app',
    delete_account_third_reason: 'I don\'t trust Vodafone',
    delete_account_error_title: 'Something went wrong',
    delete_account_default_error: 'Please try your request again',
    delete_account_back_button: 'Go back',

    //onboard
    onboarding_greeting_text: 'Welcome to My Vodafone',
    onboarding_welcome_text: 'Let\'s start setting up your account',
    onboarding_start_action_label: 'Let\'s go!',
    terms_and_conditions_quick_action_title: 'Terms & conditions and privacy policy',
    terms_and_conditions_hyperlink_text: 'Terms and conditions',
    privacy_policy_hyperlink_text: 'privacy policy',
    terms_and_conditions_agree_title: 'I agree',
    terms_and_conditions_continue_button: 'Continue',
    terms_and_conditions_description_text: 'By using the app you agree to our %1$s and confirm you have read and understood our %2$s.',
    onboarding_address_step_description: 'test',
    onboarding_address_step_boldDescription: 'test',
    onboarding_address_step_yes_label: 'Yes',
    onboarding_address_step_no_label: 'No',
    onboarding_address_step_title: 'Address',
    onboarding_id_verification_step_description: 'test1',
    onboarding_skip_action_label: 'Skip',
    onboarding_id_verification_step_title: 'ID verification',
    onboarding_payment_method_step_description: 'test2',
    onboarding_step_yes_action_label: 'Yes',
    onboarding_step_forward_action_label: 'Forward',
    onboarding_payment_method_step_title: 'Payment method for better billing',
    permissions_header_title: 'Set permissions here. Don\'t worry - you can change these from your settings screen anytime you want.',
    permissions_button_title: 'I am happy with this',
    permissions_step_title: 'Permissions',
    onboarding_second_permission_step_action_two_text2: 'Skip',
    onboarding_congratulations_title: 'Congratulations!',
    onboarding_congratulations_sub_title: 'It\'s all set and ready to go.',
    onboarding_continue_label: 'Get started',
    onboarding_step_not_now: 'Not now',

    //terms&conditions
    terms_and_conditions_title: 'Terms & conditions',
    terms_and_conditions_show_more: 'Show more',
    terms_and_conditions_show_less: 'Show less',

    //Privacy policy 
    privacy_policy_title: 'Privacy supplement',
    privacy_policy_subtitle: 'All you need to know about privacy regulation',
    privacy_policy_show_more: 'Show more',
    privacy_policy_show_less: 'Show less',

    //Data Management
    data_management_title: 'Data managment',
    data_management_subtitle: 'Edit management of your data',

    //Setting privacy settings 
    settings_privacy_settings_title: 'Privacy settings',
    settings_privacy_settings_subtittle: 'Control how Vodafone uses your information to create offers tailored for you and manage your contract preferences.',
    settings_recommandation_description: 'Inform and manage access to your data.',
    settings_preferences_description: 'Manage the ways in which Vodafone can contact you.',
    settings_terms_conditions_description: 'Find out about the general terms of the application and services.',

    //Recommendations
    recommendations_title: 'Personalized recommendation',

    //Preferences
    preferences_title: 'Contact preferences',
    preferences_subtitle: 'Contact preferences',
    preferences_first_description: 'From time to time we would like to contact you about our latest offers and promotions.',
    preferences_second_description: 'Please select the preferred way for us to contact your:',

    //netperform
    settings_netperform_title: 'NetPerform',
    settings_netperform_subtitle: 'Edit NetPerform configurations',
    setting_netperform_policy: 'Privacy Policy Net Perform',

    //remove addons
    remove_addons_error_title: 'An error has occurred',
    remove_addons_back_button: 'Go back',
    Shop_Addons_See_All_Categories: 'All',
    remove_addon_success_title: 'Success!',
    remove_addon_default_success_message: 'This addon was deactivated successfully.',
    Confirm: 'Confirm',
    Cancel: 'Cancel',

    delete_account_success_title: 'Account deleted successfully',

    messages_all_inbox_no_messages: 'You don’t have any messages.',
    messages_all_inbox_no_messages_description: 'Your conversation history with Vodafone agents can be found in TOBi',
    messages_dashboard_access_secure_content_subtitle: 'Tap to access this secure area',
    messages_inbox_all: 'All',
    messages_inbox_loading: 'loading your messages…',
    messages_inbox_my_messages_header_title: 'Account',
    messages_inbox_my_messages_title: 'My messages',
    messages_inbox_see_more: 'See more',
    messages_inbox_show_less: 'Show less',
    messages_inbox_show_more: 'Show more',
    messages_inbox_this_month: 'This month',
    messages_inbox_unread_tab: 'Unread %1$s',
    messages_inbox_unread_text: 'unread',
    messages_item_subtitle_new_unread: '%1$s unread messages',
    messages_item_subtitle_no_messages: 'No new messages',
    messages_item_title: 'My messages',
    messages_list_unread_header: 'Unread messages',
    messages_unread_inbox_no_messages: 'You don’t have any unread messages.',
    messages_unread_inbox_no_messages_description: 'Your conversation history with Vodafone agents can be found in TOBi',

    product_and_services_sub_header_title_prepaid: 'Your balance',
    product_and_services_sub_header_title_postpaid: 'Cost per month',
    product_and_services_after_sub_header_title_prepaid: ' of credit left',
    product_and_services_after_sub_header_title_postpaid: ' added to your bill',
    product_and_services_top_up_button: 'Top-up',
    product_and_services_after_sub_header_title_fix: ' a month',
    unlimited_access_to_internet: 'You have unlimited access to internet',

    netperform_speed_test_history_tab_title: 'Speed test history',
    top_up_last_recharge: 'Last recharge %1$s',
    manage_addon_duration: '',

    //privacy permission onboarding
    privacy_permissions_intro_title: 'Some important updates on your privacy settings',
    privacy_permissions_intro_content_p1: 'At Vodafone, we place great emphasis on the correct use of your personal data.',
    privacy_permissions_intro_content_p2: 'To improve your customer experience, we may capture information on how you use the services on our network.',
    privacy_permissions_intro_more_content_p1: 'Occasionally we may also inform you about offers and services from one of our partners if we think they many be of interest to you.',
    privacy_permissions_intro_more_content_p2: 'Data such as the user and quality of your connectivity services, network traffic data, and location helps us personalise our services and tailor recommendations to you.',
    privacy_permissions_intro_more_content_p3: 'We make sure that we comply with current legislation, both nationally and European level.',
    privacy_permissions_intro_more_content_p4: 'You have the ability to exercise, at any time, all of the rights provided by law to process your personal data by forwarding your request to the following address data.privacy.gr@vodafone.com',
    privacy_permissions_intro_action_item1: 'Your communications will not be accessed',
    privacy_permissions_intro_action_item2: '"Get the latest offers on our products and services tailored to you',
    privacy_permissions_intro_action_item3: 'Keep up-to-date with any information relating to your Account',
    privacy_permissions_section_item1_title: 'Set privacy settings',
    privacy_permissions_section_item1_content1: 'Control how Vodafone uses your information to create offers tailored for you and manage your contact preferences.',
    privacy_permissions_section_item1_content2: 'You can change these from your privacy settings.',
    privacy_permissions_section_item2_title: 'Offers tailored to you',
    privacy_permissions_section_item2_content1: 'To improve your customer experience, we may capture information on how you use the services on our network.',
    privacy_permissions_section_item2_content2: 'Data such as the user and quality of your connectivity services, network traffic data, and location helps us personalise our services and tailor recommendations to you.',
    privacy_permissions_section_item2_more_content_para1: 'Occasionally we may also inform you about offers and services from one of our partners if we think they many be of interest to you.',
    privacy_permissions_section_item2_more_content_para2: 'You can control the information we collect and user in three ways and can update it at any time:',
    privacy_permissions_section_item2_more_content_bullet1: 'Using the privacy setting options below to control what data you want  to use from our network',
    privacy_permissions_section_item2_more_content_bullet2: 'Each Vodafone app has it owns settings that you can control within the app.',
    privacy_permissions_section_item2_more_content_bullet3: 'By controlling how cookies are used within your browser',
    privacy_permissions_section_item2_more_content_info_what: 'What: ',
    privacy_permissions_section_item2_more_content_info_what_desc: 'Information about how you use our products and services and where you use our network',
    privacy_permissions_section_item2_more_content_info_why: 'Why: ',
    privacy_permissions_section_item2_more_content_info_why_desc: 'We can provide you with the latest offers on our products and services tailored to you, with personalised customer service, and ensure the mobile network is available where you use it the most.',
    privacy_permissions_section_item3_title: 'Contact preferences',
    privacy_permissions_section_item3_content: 'From time to time we would like to contact you about our latest offers and promotions.',
    privacy_permissions_section_item3_more_content_info_what: 'What: ',
    privacy_permissions_section_item3_more_content_info_what_desc: 'Information about how you would like us to contact you',
    privacy_permissions_section_item3_more_content_info_why: 'Why: ',
    privacy_permissions_section_item3_more_content_info_why_desc: 'So we can keep you informed and up-to-date with any information relating to your account',

    my_plan_primary_card_header_renewal_date: 'Expiration date %1$s',
    my_plan_primary_card_upgrade_plan: 'Upgrade plan',
    my_plan_bucket_status_active: 'Active',
    my_plan_bucket_status_suspended: 'Inactive',
    verification_enter_code_text: 'Please enter the 5 digit code:',
    verification_description_receive_sms: 'You should receive an SMS message with a 5 digit code to the number provided within the next 3 minutes',
    upfront_email_or_phone_number_hint: 'Phone number',
    product_section_title: "My products",
    accounts_section_title: "My account",
    support_section_title: "Support",
    basic_section_title: "Basics",
    my_recharge_section_title: "Top-up my number",
    flower_landing_title: 'Flower collection',
    //Contracts
    contracts_header_title: 'My contracts',
    contracts_mobile_title: 'Mobile',
    contracts_fixed_title: 'Fixed',
    contracts_download_button_label: 'Download PDF',
    contracts_try_again: 'Try again',
    contracts_fixed_no_contracts: 'No contracts yet',
    contracts_price: 'Price',
    contracts_contract_duration: 'Contract duration',
    contracts_last_day: 'Last day of contract',
    contracts_months: '%1$s Months',
    // Summer Promo
    summer_promo_title: 'Summer Bonus',
    summer_promo_activate_offer: 'Activate Offer',
    summer_promo_close: 'Close',
    summer_promo_try_again: 'Try again',
    summer_promo_error_title: 'An error occured!',
    summer_promo_congratulations: 'Congratulations!',
    summer_promo_details_unit: 'Days',
    summer_promo_details_label: 'Validity',
    summer_promo_active_offer: 'Summer Bonus has been already activated once.',
    summer_promo_went_wrong: 'Something went wrong!',
    summer_promo_try_again: 'Try again',



    //Recommend Vodafone to a friend screen
    recommend_title: 'Refer a Friend',
    recommend_share_code: 'Share code',
    recommend_read_terms: 'Read terms and conditions',
    recommend_terms_modal_title: 'Terms and conditions',
    recommend_agree_terms: 'I agree to all terms and conditions',
    reccomend_terms_info: 'By tapping ‘Confirm’, you accept our terms and conditions. See terms and conditions above.',
    recommend_continue: 'Continue',
    recommend_info: 'Info',
    recommend_successful_referrals: 'Successful referrals: %1$s',
    recommend_referral_item_joined_date: 'Joined on %1$s',
    recommend_no_referrals: 'Here will be shown your friends once the code has been validated.',
    recommend_try_again: 'Try again',
    recommend_share_message: 'Share this code: %1$s with a friend',
    recommend_more_info_url: 'https://api-web-ci-vfal.vodafone.com/file/affiliate/more_info/MoreInfoAffiliateEN.html',
    reccomend_terms_url: 'https://api-web-ci-vfal.vodafone.com/file/affiliate/terms_and_cond/TermsAndCondEN.html',

    recommend_successful_referral: 'Successful Referral',
    recommend_successful_referral_title: 'Great news! %1$s joined Vodafone',
    recommend_successful_referral_subtitle: 'Your referral was successful! We have added 15GB to your account for the next 3 months. Enjoy!',
    recommend_successful_referral_dashboard: 'Return to Dashboard',
    recommend_successful_referral_close: 'Refer more friends',

    offline_header_is_offline: 'You are offine',
    offline_header_is_online: 'You are online again',

    //hyperlink

    hyperlink_screen_title: 'Number status',
    hyperlink_postpaid_title: 'Bill validity',
    hyperlink_puk: 'PUK',
    hyperlink_copy_puk_button: 'Copy',
    hyperlink_starting_from: 'Starting from',
    hyperlink_until: 'Until',
    hyperlink_duration: 'Duration',
    hyperlink_last_recharge: 'Last Recharge',
    hyperlink_recharge_date: 'Recharge Date',
    hyperlink_balance_duration: 'Balance duration',
    hyperlink_balance_validity: 'Balance validity',
    hyperlink_deactive: 'Inactive from',
    hyperlink_info_text: 'Check and verify updated data regarding your mobile number. Copy your PUK code in case of any emergency.',
    hyperlink_more_info_url: 'https://api-web-ci-vfal.vodafone.com/file/hyperlink/more_info/MoreInfoHyperlinkEN.html',
    hyperlink_copy_puk: 'Copied',
    hyperlink_months: ' months',
    general_screen_title: 'General',
    language_screen_title: 'Language',
    privacy_screen_title: 'Privacy',
    //add manual fixed product
    fix_manual_product_title: "Add fixed products",
    fix_manual_product_subtitle: "My fixed products",
    fix_manual_product_first_step_desc: "We\'re not able to find any Fixed contract with your personal details. If you have a line with us you can add it manually.",
    fix_manual_product_add_contract: "Add another contract",
    fix_manual_product_cancel: "Cancel",
    fix_manual_product_second_step_desc: "One of your personal details (Personal ID or MSISDN) did not match on our system.Please update your personal details  or add manually with your fixed details.",
    fix_manual_product_add_data_step_desc: "Add the fixed line in My Vodafone to have access to all of our digital services.",
    fix_manual_product_nipt_or_id: "Personal number or NIPT",
    fix_manual_product_id: "Personal number",
    fix_manual_product_nipt: "NIPT",
    fix_manual_product_msisdn: "MSISDN",
    fix_manual_product_wrong_number_format: "Wrong number format",
    fix_manual_product_wrong_nipt_or_id_format: "Wrong ID or NIPT format",
    fix_manual_product_verify_step_title: "Verification code",
    fix_manual_product_verify_step_subtitle: "We have sent the verification code to:",
    fix_manual_product_verify_step_desc: "Please enter the 4 digit code:",
    fix_manual_product_verify_code_resend_code_disabled: "Re-send code (%1$s)",
    fix_manual_product_verify_code_resend_code: "Re-send code",
    fix_manual_product_verify_code_error: "The code you entered is invalid",
    fix_manual_product_default_error_message: "One of your personal details ( Personal ID or MSISDN) did not match on our system.Please update your personal details  or add manually with your fixed details.",
    fix_manual_product_try_again: 'Try again',
    fix_manual_product_close: 'Close',
    fix_manual_product_final_step_subtitle: 'Select one',
    fix_manual_product_final_step_desc: 'The new fixed line was just added on your profile.',
    fix_manual_id_type: 'Choose identification type',
    fix_manual_personal_id: 'Personal number',
    fix_manual_nipt: 'NIPT',

    billing_card_tag_overdue: 'Overdue',
    billing_details_pay_bill_now: 'Pay now %1$s',
    billing_current_spend: '',
    billing_details_total_spend: '',
    billing_details_bill_paid: 'Paid on %1$s',
    billing_download_bill: 'Download bill',
    billing_storage_permission_denied: 'You should give permissions in storage',

    pns_fix_price_month: 'Price per month',
    pns_fix_min_terms_ends: 'Contact ends on',
    pns_fix_start_date: 'Start date',
    pns_fix_upgrade_plan: 'Upgrade plan',
    pns_fix_card_active_status: 'Active',
    pns_fix_card_inactive_status: 'Inctive',
    pns_fix_card_in_process_status: 'Pending',
    pns_fix_no_tv_addons: "You don't have any TV addons",


    //my orders
    my_orders_action_order_summary: 'Order summary',
    my_orders_title: 'My orders',
    my_orders_manage_your_orders: 'Manage your orders',
    my_orders_number_of_orders_inprogress: ' orders in progress',
    my_orders_number_of_order_inprogress: ' order in progress',
    my_orders_inprogress: 'In progress',
    my_orders_pastorders: 'Past orders',
    my_orders_order_number: 'Order number:',
    my_orders_price: 'Price: ',
    my_orders_start_date: 'Order date: %1$s',
    my_orders_status: 'Status:',
    my_orders_summary_total_without_tax: 'Total without tax',
    my_orders_summary_total_tax_rate: 'Tax',
    my_orders_summary_total_device_cost: 'Total:',
    my_orders_summary_total_to_pay: 'Total to pay',
    my_orders_summary_total: 'Total payment',
    my_orders_no_inprogess_orders: 'You don\'t have any orders in progress',
    my_orders_no_past_orders: 'You don\'t have any completed order',
    my_orders_try_again: 'Try again',
    //orders statues
    my_orders_acknowledged_status: 'Acknowledged',
    my_orders_device_returned_status: 'Device returned',
    my_orders_pending_status: 'Pending',
    my_orders_held_status: 'Held',
    my_orders_inprogress_status: 'In progress',
    my_orders_completed_status: 'Completed',
    my_orders_canceled_status: 'Cancelled',

    order_progress_alert_button: 'Get progress alerts',
    order_progress_collection_status: 'Ready for collection',
    order_progress_confirmed_status: 'Confirmed',
    order_progress_delivered_status: 'Delivered',
    order_progress_dispatched_status: 'Dispatched',
    order_progress_estimated_delivery: '',
    order_progress_installation_status: 'Installation',
    order_progress_screen_title: 'Order no. %1$s',
    order_progress_status: 'Status: %1$s',
    order_progress_timeline_action: 'View order details',
    order_progress_timeline_collection_title: 'Ready for collection',
    order_progress_timeline_confirmed_title: 'Order confirmed',
    order_progress_timeline_delivered_title: 'Order delivered',
    order_progress_timeline_dispatched_title: 'Order dispatched',
    order_progress_timeline_installation_title: 'Installation',
    order_progress_timeline_step_update_time: 'Update time:',
    order_progress_timeline_subtitle: 'Order placed: %1$s',
    order_progress_timeline_title: 'Order updates',
    order_progress_timeline_transit_title: 'Order in transit',
    order_progress_transit_status: 'In transit',
    select_amount: 'Select Amount',
    tutorial_next_step: 'Next step',
    tutorial_last_step: 'Continue',
    tranfer_balance: 'Transfer Balance',
    call_me_back: 'Call me back',
    balance_screen_title: 'Activity',
    balance_history_title: 'Activity history',
    balance_screen_no_history_title: 'You have no activity history',
    balance_screen_no_history_subtitle: 'Once you use your phone you\'ll be able to view your activity history',

    reward_loyalty_non_vf_message: 'Cannot use this feature',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    morning: 'Morning',
    midday: 'Midday',
    afterlunch: 'Afternoon',
    my_products: 'My Products',
    my_contracts: 'My Contracts',
    my_orders: 'My Orders',

    roaming_settings_screen_title: 'Roaming Settings',
    roaming_settings_location_permission_title: 'Location Permission',
    roaming_settings_location_permission_description: 'Click here to find out more about location',
    roaming_settings_learn_more: 'Learn More',
    roaming_settings_screen_description: 'In order to use roaming you need to enable it in your device settings and chek My Vodafone app if your roaming service is active',
    roaming_settings_device_settings_title: 'Device Settings',
    roaming_settings_device_settings_description: 'Before traveling, make sure "Data Roaming" is on in your Device Settings',
    roaming_settings_device_settings_how_to_enable_roaming_title: 'How to enable Roaming on your device:',
    roaming_settings_network_settings_title: 'Network Settings',
    roaming_settings_network_settings_description: 'Turn On Vodafone network roaming in order to use your SIM abroad',
    roaming_settings_network_settings_toggle_title: 'Roaming Services',
    roaming_settings_network_settings_popup_title: 'You are removing Roaming service from your number.',
    roaming_settings_network_settings_popup_description: 'Are you sure you want to proceed ?',
    roaming_settings_network_settings_toggle_calls: 'Calls',
    roaming_settings_network_settings_toggle_SMS: 'SMS',
    roaming_settings_network_settings_toggle_data: 'Data',
    roaming_settings_network_settings_administrator_contact: "!!! Contact your administrator to be able to change this filter!",
    roaming_settings_dataCap_title: 'Data CAP',
    roaming_settings_dataCap_description: 'Be in control of your roaming charges while in roaming through "Internet Control" service. While this service is active, you get notifications when you start using Internet in standard roaming rates and when you have reached the limit of the chosen schema:',
    roaming_settings_locationPermission_steps_title: "Location settings",
    roaming_settings_locationPermission_steps_description: "How to enable Location permission on your device:",
    roaming_close: "Close",
    roaming_settings_data_cap: 'Data Cap',
    roaming_settings_data_cap_default_error: 'Something went wrong! Try again.',

    roaming_settings_location_step_one_android: "SGo to settings",
    roaming_settings_location_step_two_android: "Location",
    roaming_settings_location_step_three_android: "Switch on Location Services",
    roaming_settings_roaming_step_one_android: "Go to Settings",
    roaming_settings_roaming_step_two_android: "Connections",
    roaming_settings_roaming_step_three_android: "Mobile Data",
    roaming_settings_roaming_step_four_android: "Switch On Data Roaming",

    roaming_settings_location_step_one_ios: "Go to Settings",
    roaming_settings_location_step_two_ios: "Privacy",
    roaming_settings_location_step_three_ios: "Location Services",
    roaming_settings_location_step_four_ios: "Switch on Location Services",
    roaming_settings_roaming_step_one_ios: "Go to settings",
    roaming_settings_roaming_step_two_ios: "Mobile data",
    roaming_settings_roaming_step_three_ios: "Mobile Data Options",
    roaming_settings_roaming_step_four_ios: "Turn Data Roaming on",

    dashboard_business_usage: "Your business plan",
    dashboard_business_usage_users: '%1$s users',
    dashboard_business_usage_reset_days: 'Resets in %1$s days',
    dashboard_business_usage_reset_day: 'Resets in 1 day',
    dashboard_business_usage_reset_next_day: 'Resets the next day',

    //Discovery Screen
    dashboard_discovery_see_more: 'Show more',
    dashboard_discovery_see_less: 'Show less',
    dashboard_discovery_promotion: 'Promotions',
    dashboard_discovery_see_details: 'See details',
    dashboard_discovery_screen_title: "What's new",

    subtray_business_section_title: 'My business',
    subtray_business_section_users: '- %1$s users',
    subtray_business_section_user: '- 1 user',
    subtray_business_section_button_title: 'Business Overview',
    billing_user_payment_modal_title: 'Bill payment',
    billing_user_payment_modal_subtitle: 'Cannot pay this bill',
    billing_user_payment_modal_desc: 'Only the administrator can make the payment for your number and other members of the group. You can only see the invoice details.',
    billing_user_payment_modal_button_title: 'Close',
    billing_user_payment_modal_error_title: 'Oops',
    billing_user_payment_modal_error_desc: 'Something went wrong! Could not switch to admin account',
    billing_user_payment_modal_error_button: 'Cancel',
    billing_chooser_payment_modal_button_title: 'Switch to Business view',
    billing_chooser_payment_modal_subtitle: 'Information',
    billing_chooser_payment_modal_desc: 'To continue with invoice payment you must have administrator rights. Switch to your business account by selecting Switch Account.',
    billing_current_bills_title: 'Current bills',
    billing_bills_type_fix: 'Fixed Services',
    billing_bills_type_mobile: 'Mobile Services',
    billing_breakdown_filter_role_company: 'Company',
    billing_breakdown_filter_role_employee: 'Employee',
    billing_breakdown_filter_role_service: 'Service',
    billing_breakdown_filter_role_fix: 'Fix Service',
    billing_current_bill_products: 'Produkts',
    account_switch_tray_subtitle: "%1$s's Account",
    account_switch_tray_button: 'Switch accounts',
    account_switch_loading_screen_title: 'Switching to...',
    account_switch_loading_screen_desc: '%1$s',
    account_switch_default_error_title: 'Oops!',
    account_switch_default_error_desc: 'Something went wrong',
    account_switch_cancel: 'Cancel',
    account_switch_secondary_button: 'Back to switch accounts',
    account_switch_current_account: 'Current Account',
    account_switch_other_accounts: 'Other Accounts',
    switch_account_quick_action_cancel_button_title: 'Cancel',
    account_switch_title: 'Switch Account',
    account_switch_personal_account: 'Personal account',
    account_switch_business_account: 'Business account',
    account_switch_personal_account_loading: 'Personal account',
    account_switch_business_account_loading: 'Business account',

    soho_usage_users_search_placeholder: "Let's refine your search",
    soho_usage_users_prev: 'Previous',
    soho_usage_users_next: 'Next',

    soho_requests_pending_empty_request_title: "You don't have any pending requests at the moment",
    soho_requests_past_empty_request_title: "You don't have any past requests",
    soho_requests_close: 'Close',
    soho_plans_empty_plans: "You don't have any active plans yet",

    textarea_message_remaining: '%1$s characters remaining',
    soho_business_overview_billing_button_text: 'eBill',

    soho_monthly_billling_search_placeholder: 'Search',
    soho_monthly_billing_no_users: 'No users found',
    soho_my_users_and_groups_empty_search: 'There were no results for "%1$s". Try a new search',
    soho_plans_plan_card_usage_details_value: '%1$s / %2$s',
    soho_requests_pending_requests_count_one: '%1$s pending request',
    soho_user_requests_number_of_requested_user: 'Requests from %1$s user',
    soho_business_overview_plans_tab_title: 'Plans',
    soho_plans_plan_card_usage_details_value: "%1$s/%2$s left",
    soho_plans_section_title: "Mobile plans",
    soho_plans_plan_card_title: "Mobile plan",
    soho_my_users_and_groups_users_search_placeholder: 'Search...',
    soho_my_users_and_groups_subtitle: 'Search user profile',


    select_contact_select_phone: 'Choose a contact',
    select_contact_cancel: 'Cancel',

    book_appointment_meeting_step_title: 'Meeting',
    book_appointment_meeting_step_subtile: 'Select a place for your appointment',
    book_appointment_view_location: 'View Location',
    book_appointment_timeslots_available: 'Time slots available',
    book_appointment_add_location: 'Add location',
    book_appointment_continue: 'Continue',
    book_appointment_or: 'or',

    last_bill_mobile_service: 'Mobile Services',
    last_bill_fix_service: 'Fixed Services',
    dashboard_last_bill_title: 'eBill',

    soho_requests_past_requests_status_completed: "Completed",
    soho_requests_past_requests_status_cancelled: "Cancelled",
    soho_requests_past_requests_status_rejected: "Rejected",
    billing_credit_card_paid_date: 'To be paid until the %1$s%2$s %3$s',

    soho_plans_empty_title: 'No active services',
    dashboard_billing_last_bills_pay_date: 'Pay until %1$s%2$s %3$s',
    billing_details_header_title: "%1$s Bill",
    product_and_services_ebill_button: 'Bills',
    soho_plans_empty_title: 'No active services',

    update_app_version_title: 'Get new version of the app',
    update_app_version_subtitle: 'Get new version of the app',
    update_app_version_button: 'Update App',
    billing_dashboard_status_paid: "Paid",
    billing_dashboard_status_unpaid: "Pay until %1$s%2$s %3$s",
    billing_dashboard_status_overdue: "Pay now",

    cocacola_header_title: 'Scan and Earn',
    cocacola_reward: 'Get your reward',
    cocacola_qrcode: 'Scan QR Code',
    cocacola_scan_qr: 'Scan QR code',
    cocacola_card_description: 'How to win internet for FREE: \n \n 1. Order one Coca-Cola Zero at Spar Food Court. \n 2. Scan the QR code here. \n 3. Instantly win internet for FREE.',
    cocacola_loading: 'Checking code…',
    cocacola_success_title: 'Congratulation!',
    cocacola_success_description: 'You have won %1$s for free to spend until %2$s.',
    cocacola_return: 'Return to homepage',


    cocacola_error_header_title: 'Scan & Earn',
    cocacola_error_title: 'Error!',
    cocacola_error_description: 'Could not verify the QR code you are scanning. \n Please scan again.',
    cocacola_error_try_again: 'Try again',
    cocacola_error_close: 'Close',
    cocacola_notfound_title: 'No product was found.',
    cocacola_notfound_description: 'Make sure you buy one Coca-Cola menu to win GB internet for free.',
    cocacola_notfound_close: 'Close',
    cocacola_camera_description: 'Place the QR code on the rectangle shape to proceed with scanning.',
    cocacola_info_url: 'https://api-web-ci-vfal.vodafone.com/file/cocacola/info_en.html',
}