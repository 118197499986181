import React from 'react'
import { Image, View } from 'react-native'
import PropTypes from 'prop-types'
import { VFText } from '@vfgroup-oneplatform/foundation/Components'
import {
  withTheme,
  LightThemeColors
} from '@vfgroup-oneplatform/foundation/Components/Themes'

import ParallaxScroll from '@vfgroup-oneplatform/framework/CommonUI/ParallaxScroll'

import Images from '../../Themes/Images'
import styles from './EditorialCard.Styles'

function EditorialCard({ theme, setParallaxRef }) {
  return (
    <View style={styles.container(theme)}>
      <ParallaxScroll
        style={styles.scrollStyle}
        ref={setParallaxRef}
        background={
          <Image style={styles.imageStyle} source={Images.editorial2} />
        }
      />
      <View style={styles.textContainer}>
        <VFText
          style={styles.header(theme)}
          i18nKey={'dashboard_editorial_card_header'}
        />
        <VFText
          style={styles.text(theme)}
          i18nKey={'dashboard_editorial_card_text'}
        />
      </View>
    </View>
  )
}

EditorialCard.defaultProps = {
  theme: LightThemeColors
}
EditorialCard.propTypes = {
  theme: PropTypes.objectOf({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  }),
  setParallaxRef: PropTypes.func
}

export default withTheme(EditorialCard)
