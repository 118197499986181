import axios from 'axios'

import CONFIG from '../../Config'
import { getErrorMessage } from '../../Utils'
import ReduxCache from '../../Utils/ReduxCache'

export const action_types = {
  REQUEST_QRCODE: 'REQUEST_QRCODE',
  SUCCESS_QRCODE: 'SUCCESS_QRCODE',
  ERROR_QRCODE: 'ERROR_QRCODE',
  REQUEST_REDEEM_HANDLE: 'REQUEST_REDEEM_HANDLE',
  SUCCESS_REDEEM_HANDLE: 'SUCCESS_REDEEM_HANDLE',
  ERROR_REDEEM_HANDLE: 'ERROR_REDEEM_HANDLE',
  ACTIVE_PROMOTION_COUPON: 'ACTIVE_PROMOTION_COUPON',
  RESET_REDEEM: 'RESET_REDEEM',
}

const getQrCode = item => {
  return async dispatch => {
    dispatch({
      type: action_types.REQUEST_QRCODE,
    })

    const msisdn = ReduxCache.getMsisdn()

    var data = {
      queryOptions: {
        filter: '',
        pagination: {
          count: 0,
          limit: 10,
        },
        sorting: '',
      },
      queries: [
        {
          query: '$.redeemerId=' + msisdn,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.id=' + item.id,
        },
        {
          operator: 'AND',
        },
        {
          query: '$.type=reward',
        },
        {
          operator: 'AND',
        },
        {
          query: '$.channelId=selfcare',
        },
        {
          operator: 'AND',
        },
        {
          query: '$.category=qr',
        },
        {
          operator: 'AND',
        },
        {
          query: '$.version=2',
        },
      ],
    }
    const url = await CONFIG.getUrl()
    const headers = await CONFIG.getHeaders()

    axios
      .post(url.apiEndpoint, data, {
        headers: {
          ...headers,
        },
      })
      .then(response => {
        const product = response.data[0]
        const qrCode = product.parts.productOffering[0].id[0].value

        if (item.type == 'promotion') {
          const status = product.parts.productOffering[0].status

          if (status == 'redeem') {
            dispatch({
              type: action_types.SUCCESS_QRCODE,
              payload: {
                code: qrCode,
                isRedeem: true,
                product: product,
              },
            })
            return
          }
        }

        dispatch({
          type: action_types.SUCCESS_QRCODE,
          payload: { code: qrCode, product: product },
        })
      })
      .catch(error => {
        const message = getErrorMessage(error)

        dispatch({
          type: action_types.ERROR_QRCODE,
          payload: message,
        })
      })
  }
}

const redeemProduct = (product, shouldReset = false) => {
  return async dispatch => {
    dispatch({
      type: action_types.REQUEST_REDEEM_HANDLE,
    })

    product = JSON.stringify(product)
    product = product.replace(/ë/g, 'e')
    product = product.replace(/ç/g, 'c')
    product = product.replace(/Ë/g, 'c')
    product = product.replace(/Ç/g, 'c')

    product = JSON.parse(product)
    // add static price because it's mandatory
    product.parts.productOffering[0].priceFinalPrice.value = 0
    product.parts.productOffering[0].priceOriginalPrice.value = 0

    const url = await CONFIG.getUrl()
    const headers = await CONFIG.getRedeemHeaders()
    axios
      .post(url.redeemEndpoint, product, {
        headers: {
          ...headers,
        },
      })
      .then(response => {
        const product = response.data

        let validity = ''
        if (product.validityPeriod && product.validityPeriod.toDate) {
          validity = product.validityPeriod.toDate.date
        }

        const productData = {
          validityPeriod: validity,
        }

        dispatch({
          type: action_types.SUCCESS_REDEEM_HANDLE,
          payload: productData,
        })

        if (shouldReset) {
          dispatch(resetRedeem())
        }
      })
      .catch(error => {
        const message = getErrorMessage(error)
        dispatch({
          type: action_types.ERROR_REDEEM_HANDLE,
          payload: message,
        })
      })
  }
}

const resetRedeem = () => {
  return dispatch => {
    dispatch({
      type: action_types.RESET_REDEEM,
    })
  }
}

export const actions = {
  getQrCode,
  redeemProduct,
  resetRedeem,
}
