import { isFunction as _isFunction } from 'lodash'
import axios from 'axios'
import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'
import { Base64 } from 'js-base64'

import { isAdminView } from '../../Utils'

import { API_URLS } from '../Config'

const generateToken = async (
  msisdn,
  body,
  type,
  customHeaders = {},
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

  let encodedBody = ''

  if (body !== '') {
    encodedBody = Base64.encode(JSON.stringify(body))
  }

  const data = {
    'details': {
      'requestType': 'generate'
    },
    'type': 'payments',
    'roles': {
      'requestor': {
        'id': [
          {
            'value': msisdn
          }
        ]
      }
    },
    'parts': {
      'specification': {
        'characteristicsValue': [
          {
            'characteristicName': 'body',
            'value': encodedBody
          },
          {
            'characteristicName': 'method',
            'value': type
          }
        ]
      }
    }
  }

  onStart()

  try {
    const response = await axios.post(API_URLS.generateSecurityToken, data, { headers: customHeaders })

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}


const getCustomerId = async (
  msisdn,
  customHeaders = {},
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

  const data = {
    data: null,
    headers: customHeaders
  }

  onStart()

  try {
    const response = await axios.get(API_URLS.generateSecurityToken + '/' + msisdn, data)

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const saveCustomerIdToVF = async (
  msisdn,
  id,
  customHeaders = {},
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities


  const data = {
    'id': [
      {
        'value': msisdn
      }
    ],
    'name': 'PSP Customer Id',
    'type': 'payments',
    'details': {
      'externalId': {
        'value': id,
        'schemeID': 'PSPId'
      }
    },
    'roles': {
      'requestor': {
        'id': [
          {
            'value': msisdn
          }
        ]
      }
    }
  }

  onStart()

  try {
    const response = await axios.post(API_URLS.securityToken + msisdn, data, { headers: customHeaders })

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const createTopUpPayment = async (
  msisdn,
  paymentDetails,
  customHeaders = {},
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities


  const data = {
    'name': 'TopUp Payment',
    'description': 'TopUp Payment',
    'authorizationCode': '12321',
    'correlatorId': '12321',
    'account': {
      'id': msisdn,
      'role': 'subscriber',
      '@referredType': ''
    },
    'channel': {
      'id': 'myvodafone',
      'href': 'My Vodafone App'
    },
    'payer': {
      'id': msisdn,
      'role': 'subscriber',
      '@referredType': ''
    },
    'totalAmount': {
      'unit': paymentDetails.currency,
      'value': paymentDetails.authorizedAmount
    },
    'paymentItem': [
      {
        'amount': {
          'unit': paymentDetails.currency,
          'value': paymentDetails.authorizedAmount
        },
        'item': {
          'id': '',
          '@referredType': 'BalanceTopup'
        },
      }
    ],
    'paymentMethod': {
      'token': paymentDetails.token,
      'tokenType': paymentDetails.tokenType,
      'relatedParty': {
        'id': paymentDetails.isThirdParty ? paymentDetails.thirdPartyMsisdn : msisdn,
        'role': paymentDetails.isThirdParty ? 'beneficiary' : 'subscriber',
        '@referredType': ''
      },
      'validFor': {
        'endDateTime': paymentDetails.expiry?.split('/')[1] + '-' + paymentDetails.expiry?.split('/')[0] + '-01'
      },
      'paymentSessionIdentifier': paymentDetails.sessionIdentifier
    }
  }

  if (paymentDetails.id) {
    data.paymentItem[0].id = paymentDetails.id
  }

  onStart()
  try {
    const response = await axios.post(API_URLS.payment, data, { headers: customHeaders })

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const createBillingPayment = async (
  msisdn,
  accountId,
  paymentDetails,
  customHeaders = {},
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

  const data = {
    'name': 'Bill Payment',
    'description': 'Bill Payment',
    'authorizationCode': '12321',
    'correlatorId': '12321',
    'account': {
      'id': accountId,
      'role': 'subscriber',
      '@referredType': ''
    },
    'channel': {
      'id': 'myvodafone',
      'href': 'My Vodafone App'
    },
    'payer': {
      'id': msisdn,
      'role': 'subscriber',
      '@referredType': isAdminView() ? 'organization' : ''
    },
    'totalAmount': {
      'unit': paymentDetails.currency,
      'value': paymentDetails.authorizedAmount
    },
    'paymentItem': [
      {
        'amount': {
          'unit': paymentDetails.currency,
          'value': paymentDetails.authorizedAmount
        },
        'item': {
          'id': paymentDetails.billId,
          '@referredType': 'CustomerBill'
        }
      }
    ],
    'paymentMethod': {
      'token': paymentDetails.token,
      'tokenType': paymentDetails.tokenType,
      'relatedParty': {
        'id': msisdn,
        'role': 'subscriber',
        '@referredType': ''
      },
      'validFor': {
        'endDateTime': paymentDetails.expiry?.split('/')[1] + '-' + paymentDetails.expiry?.split('/')[0] + '-01'
      },
      'paymentSessionIdentifier': paymentDetails.sessionIdentifier
    }
  }

  if (paymentDetails.id) {
    data.paymentItem[0].id = paymentDetails.id
  }

  onStart()
  try {
    const response = await axios.post(API_URLS.payment, data, { headers: customHeaders })

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}


const createOfferPayment = async (
  msisdn,
  paymentDetails,
  customHeaders = {},
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

  const { paymentObj } = paymentDetails


  let tokenFound = false

  paymentObj.characteristics.map(item => {
    if (item.name == 'paymentToken') {
      tokenFound = true
    }
  })

  if (!tokenFound) {
    paymentObj.characteristics.push({
      'name': 'paymentToken',
      'value': paymentDetails.token
    })

    paymentObj.characteristics.push({
      'name': 'paymentTokenType',
      'value': paymentDetails.tokenType
    })
  }

  const orderItems = [
    {
      id: paymentObj.id,
      quantity: 1,
      action: 'add',
      product: {
        name: paymentObj.name,
        productCharacteristic: paymentObj.characteristics,
        productPrice: [
          {
            price: {
              taxIncludedAmount: { ...paymentObj.price },
            },
          },
        ],
      },
    },
  ]

  const offerData = {
    'category': 'offers',
    'channel': paymentObj.channel,
    'productOrderItem': orderItems,
    'relatedParty': [
      {
        'id': msisdn,
        'role': 'Customer',
        '@referredType': 'individual'
      }
    ]
  }

  onStart()
  try {
    const response = await axios.post(API_URLS.offerPayment, offerData, { headers: customHeaders })

    const _response = responseMapper(response)

    if (_response.state == 'pending') {
      throw new Error(_response.description)
    }

    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

export {
  generateToken,
  getCustomerId,
  saveCustomerIdToVF,
  createTopUpPayment,
  createBillingPayment,
  createOfferPayment,
}
