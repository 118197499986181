import { GetBalance, GetPEGA } from '../../Services'
import ReduxCache from '../../Utils/ReduxCache'

import { actionTypes } from './Dashboard.ActionTypes'

// const BusinesItems = {
//     aplications: {
//         key: 'Aplication',
//         name: 'A',
//         isWebView: true,
//         params: { 'url': `https://mytrp.altirana.com/?MSISDN=${ReduxCache.get('auth.hashedMsisdnParking')}&lang=${language}`, 'title': 'Tirana Parking', 'isReact': true }
//     },
//     payment: {
//         key: 'PaymentMethods',
//         name: 'payment_method_screen_title',
//         screens: ['PaymentMethods', 'EditPayment', 'AddPayment']
//     }
// }

const getUserBalance = (isSohoUser) => {
    return async dispatch => {
        const userType = ReduxCache.getUserType()

        if (userType === 'guest') {
            dispatch({ type: actionTypes.BALANCE_SUCCESS, data: [] })
            return []
        }
        try {
            dispatch({ type: actionTypes.BALANCE_LOADING })
            const response = await GetBalance(isSohoUser)

            dispatch({ type: actionTypes.BALANCE_SUCCESS, data: response })

            return response
        } catch (error) {
            dispatch({ type: actionTypes.BALANCE_ERROR, data: error })
        }
    }
}

Promise.allSettled = Promise.allSettled || ((promises) => Promise.all(
    promises.map(p => p
        .then(value => ({
            status: 'fulfilled',
            value
        }))
        .catch(reason => ({
            status: 'rejected',
            reason
        }))
    )
))

const getPegaData = () => {
    const userType = ReduxCache.getUserType()

    return async dispatch => {
        if (userType === 'guest') {
            dispatch({ type: actionTypes.PEGA_SUCCESS, data: [] })

        }
        try {
            dispatch({ type: actionTypes.PEGA_LOADING })

            const response = await GetPEGA('dashboard')

            dispatch({ type: actionTypes.PEGA_SUCCESS, data: response })

            return response
        } catch (error) {
            dispatch({ type: actionTypes.PEGA_ERROR, data: error })
        }
    }
}

const updateUserSideBar = (items) => {
    const tempItems = []
    items?.map((initialItem, initialIndex) => {
        let item
        items?.map((secondItem, secondIndex) => {
            if (initialItem?.key === secondItem?.key && initialIndex !== secondIndex) {
                if ((secondItem?.items?.length || 1) >= (initialItem?.items?.length || 1)) {
                    item = secondItem
                } else {
                    item = initialItem
                }
            } else {
                item = initialItem
            }
        })
        if (tempItems.findIndex(_item => _item?.key === item?.key) !== -1) {
            tempItems.splice(tempItems.findIndex(_item => _item?.key === item?.key), 1, item)
        } else {
            tempItems.push(item)
        }
    })
    const BusinessRemove = ['Catalogue', 'ManageOrders', 'VfHearts', 'Quiz', 'TopUPAnother', 'DataSharing', 'PaymentMethods', 'Shake', 'FixContracts']
    if (ReduxCache?.getBusinessAccount()?.showBusinessView) {
        tempItems?.map((item, itemIndex) => {
            if (!item?.items) {
                item?.screens?.map((screenItem, screenIndex) => {
                    if (BusinessRemove.includes(screenItem)) {
                        tempItems.splice(itemIndex, 1)
                    }
                })
            }
            if (item?.items) {
                item?.items?.map((subItem, subItemIndex) => {
                    if (BusinessRemove.includes(subItem?.key)) {
                        tempItems[itemIndex].items.splice(subItemIndex, 1)
                    }
                })
            }
            if (item?.items?.length === 0) {
                tempItems.splice(itemIndex, 1)
            }
        })
        tempItems.splice(1, 0, {
            key: 'BusinessOverview',
            name: 'soho_subtray_business_overview_section_title',
            items: [
                {
                    key: 'BusinessOverview',
                    name: 'soho_business_overview_title',
                    screens: ['BusinessOverview', 'Plan', 'ProfileManagement']
                },
                {
                    key: 'UsersAndGroup',
                    name: 'soho_my_users_and_groups_screen_title',
                    screens: ['UsersAndGroup']
                },
            ]
        })

    }
    return async dispatch => { dispatch({ type: actionTypes.UPDATE_SIDEBAR, data: tempItems }) }
}

const clearSidebar = () => {
    return dispatch => { dispatch({ type: actionTypes.CLEAR_SIDEBAR }) }
}



const Actions = { getUserBalance, getPegaData, updateUserSideBar, clearSidebar }

export { Actions, actionTypes }
