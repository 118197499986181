import {StyleSheet} from 'react-native'

import colors from '../../Themes/Colors'

const styles = StyleSheet.create({
  horizontalLine: {
    borderBottomColor: colors.light_grey,
    borderBottomWidth: 1,
    display: 'flex',
    alignSelf: 'stretch',
  },
})

export {styles}
