import { StyleSheet } from 'react-native'

import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'


const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%'
  },
  orderScreenContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 30,
  },
  btnStyle: {
    marginHorizontal: 16,
  },
  title: {
    ...Fonts.type.bold,
    fontSize: Fonts.size.h4,
    marginVertical: 20,
  },
  subtitle: theme => ({
    color: theme.colors.textColorTwo,
    fontFamily: Fonts.type.VFFont,
    fontSize: Fonts.size.h6,
    marginVertical: 10,
    paddingHorizontal: 20,
    textAlign: 'center',
  }),
  closeButton: {
    position: 'absolute',
    top: 52,
    right: 16,
    zIndex: 100,
  },
})

export default styles
