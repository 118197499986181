import React from 'react'
import { View, TouchableOpacity, Animated, ImageBackground } from 'react-native'
import PropTypes from 'prop-types'

import { VFText, Icon } from '@vfgroup-oneplatform/foundation/Components'
import { withTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { getThemeImages } from '../../Themes'
import styles from './NoBundleCard.Styles'


const NoBundleCard = ({
  item,
  icon,
  title,
  usageType,
  theme,
}) => {

  const Images = getThemeImages(theme.name)

  return (
    <Animated.View style={styles.flex}>
      <ImageBackground
        source={Images.usage_tile_bg}
        resizeMode="cover"
        style={styles.container}
      >
        <View style={styles.header}>
          <Icon
            name={Images[icon]}
            type="image"
            size={30}
          />
          <VFText
            i18nKey={title}
            style={styles.title}
          />
        </View>

        <View style={styles.textContainer}>
          <VFText
            style={styles.text}
            i18nKey={'no_active_bundle'}
          />

          <View style={styles.textsView}>
            <VFText
              style={styles.text}
              i18nKey={`dashboard_${usageType}_no_bundle_text1`}
            />
            <VFText
              style={styles.text}
              i18nKey={`dashboard_${usageType}_no_bundle_text2`}
            />
          </View>

        </View>

        <TouchableOpacity onPress={item.onBundlePress} style={styles.button}>
          <VFText
            style={styles.title}
            i18nKey="activate_bundle"
            type="secondary"
          />
        </TouchableOpacity>
      </ImageBackground>
    </Animated.View>
  )

}
NoBundleCard.propTypes = {
  item: PropTypes.object,
  icon: PropTypes.string,
  title: PropTypes.string,
  usageType: PropTypes.string,
  theme: PropTypes.shape({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  })
}

export default withTheme(NoBundleCard)