import React, { useState } from 'react'
import { StatusBar, Platform } from 'react-native'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { Colors, Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

import Refill from '@vfal-lab/rn-vfg-refill/Screens'


import ReduxCache from '../../Utils/ReduxCache'

import { actions } from './Refill.Actions'


function RefillScreen({ navigation, ...props }) {
  const language = ReduxCache.getLanguage()
  const msisdn = ReduxCache.getMsisdn()
  const isVisible = props.isVisible



  const dispatch = useDispatch()

  /** Get offer details */
  const getOfferDetails = async (utilities) => {
    return dispatch(actions.getOfferDetails(utilities))
  }
  const offerDetails = useSelector(state => state.refillOfferDetails)
  const offerActivation = useSelector(state => state.offerActivation)

  const [selected, setSelected] = useState(-1)
  const data = offerDetails?.data || {}


  const activateOffer = async (action = 'ADD', utilities = {}) => {
    const overwriteItems = offerActivation.data?.overwriteItems || []

    return dispatch(actions.activateOffer(
      data.id,
      data.title,
      data.type,
      data.category,
      data.status,
      overwriteItems,
      action,
      utilities
    ))
  }

  const resetStatusBar = () => {
    if (Platform.OS === 'android') {
      StatusBar.setBarStyle('light-content', true)
      StatusBar.setBackgroundColor(Colors.thinDark, true)
      StatusBar.setTranslucent(false)
    } else if (Platform.OS === 'ios') {
      StatusBar.setBarStyle('dark-content', true)
    }
  }

  const toggleModal = async () => {
    if (isVisible) {
      resetStatusBar()
    }
    props.setShowRefill(false)
  }

  const goToRecharge = () => {
    resetStatusBar()
    props.showPayments()
  }

  return (
    <Refill
      title={data.title}
      options={data.lineItem}
      fromDate={data.fromDate}
      toDate={data.toDate}
      dateFormat="DD/MM/YYYY"
      visibleDateFormat="MMMM - DD"
      endDateFormat="DD.MM.YYYY"
      balance={props.balance}
      selected={selected}
      isLoading={offerDetails.loading}
      setSelected={index => setSelected(index)}
      getOfferDetails={getOfferDetails}
      activateOffer={activateOffer}
      activationStatus={offerActivation.data?.status}
      activationLoading={offerActivation.loading}
      moreInfoUrl={data.detailsLink}
      isContentLoading={false}
      isVisible={isVisible}
      toggleModal={toggleModal}
      language={language}
      recharge={goToRecharge}
      msisdn={msisdn}
    />
  )
}

RefillScreen.propTypes = {
  navigation: PropTypes.object,
  setShowRefill: PropTypes.func,
  showPayments: PropTypes.func,
  balance: PropTypes.string || PropTypes.number,
  isVisible: PropTypes.bool,
}

export default RefillScreen
