import axios from 'axios'
import {
  isFunction as _isFunction
} from 'lodash'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'
import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import moment from 'moment'

import { isAdminView } from '../../Utils'


import ReduxCache from '../../Utils/ReduxCache'

import { API_URLS } from '../Config'

const mapError = (error) => {
  const payload = error?.response?.data?.message || error?.message
  return payload
}


const planResonseMapper = (data) => {
  const planData = []

  if (data.length === 0 || data === null) {
    return planData
  }

  let customerBill = 0
  let unit = ''

  data.map(contract => {

    const { agreementItem = [], status = '' } = contract

    const { product } = agreementItem?.[0] || []

    product.map(item => {

      const { id = ' ', name = ' ', startDate, terminationDate, place, productCharacteristic = [], productPrice } = item

      const price = productPrice?.[0]?.price?.taxIncludedAmount?.value
      const priceUnit = productPrice?.[0]?.price?.taxIncludedAmount?.unit || 'ALL'

      const address = place?.[0]?.name

      customerBill = customerBill + parseInt(price, 10)
      unit = ContentManager.translate(priceUnit)

      let formattedStartDate = ''
      let formattedEndDate = ''

      if (moment(startDate).isValid()) {
        formattedStartDate = moment(startDate).format('DD/MM/YYYY')
      }

      if (moment(terminationDate).isValid()) {
        formattedEndDate = moment(terminationDate).format('DD/MM/YYYY')
      }

      planData.push({
        status: status?.toLowerCase(),
        title: name,
        address,
        price,
        priceUnit: unit,
        id,
        startDate: formattedStartDate,
        terminationDate: formattedEndDate,
        characteristics: productCharacteristic,
      })

    })

  })

  return {
    planData,
    customerBill,
    unit,
  }

}


async function getFixPlansData(
  customHeaders = {},
  utilities = {}
) {

  const _utilities = retrieveUtilities(utilities)

  const {
    onError,
    onStart,
    onSuccess
  } = _utilities

  const msisdn = ReduxCache.getMsisdn()

  onStart()

  const params = {
    'engagedParty.id': msisdn,
    'engagedParty.role': isAdminView() ? 'organization' : 'customer',
    'agreementType': 'Fixed',
    'isCustomerVisible': true
  }

  try {
    const response = await axios.get(API_URLS.fix.fixPlan, {
      headers: customHeaders,
      params: params
    })

    const _response = planResonseMapper(response.data)

    onSuccess(_response)

    return _response
  } catch (error) {
    const _error = mapError(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

export { getFixPlansData }
