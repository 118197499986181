import axios from 'axios'
import {
  get as _get,
  isFunction as _isFunction
} from 'lodash'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import ReduxCache from '../../Utils/ReduxCache'

import { API_URLS } from '../Config'

const getUsagePercentage = (bucket) => {
  const remainingAmount = bucket.bucketBalance[0].remainingValue.amount
  const totalAmount = bucket.bucketCounter[0].value.amount

  const usageValue = remainingAmount / totalAmount || 0

  return parseInt((usageValue * 100).toFixed(0))
}

const mapMyPlanResponse = (response, iconsMapper) => {

  const flatenResponse = {
    product: response.product[0],
    usageConsumptionReport: response.usageConsumptionReport[0],
    customerBill: response.customerBill[0],
  }

  // const { product, usageConsumptionReport } = flatenResponse
  const { product, customerBill } = flatenResponse

  // here starts the loop
  const data = []

  if (!product) {
    return {}
  }

  product.product = product?.product.sort((item1, item2) => {
    if (item1.productSerialNumber > item2.productSerialNumber) {
      return 1
    }

    if (item1.productSerialNumber < item2.productSerialNumber) {
      return -1
    }

    return 0
  })

  product?.product?.map(productProduct => {
    const mappedResponse = {
      usageData: [],
      contractData: []
    }

    mappedResponse.id = productProduct.id
    mappedResponse.status = 'my_plan_bucket_status_' + productProduct.status
    mappedResponse.title = productProduct.name
    mappedResponse.datePlaceholders = [productProduct.terminationDate]
    mappedResponse.priceObj = productProduct.productPrice[0]?.price?.taxIncludedAmount
    mappedResponse.date = 'my_plan_primary_card_renewal_date'

    const characteristics = {}
    productProduct.productCharacteristic.map(characteristic => {
      characteristics[characteristic.name] = characteristic.value
    })

    mappedResponse.characteristics = characteristics

    const buckets = {}
    productProduct.bucket.map(bucket => {
      buckets[bucket.usageType + '-' + bucket.id] = bucket
    })

    productProduct.bucket.map(bucket => {
      const item = {}
      item.key = bucket.usageType

      if (characteristics[bucket.id + 'CTALink']) {
        item.shouldShowAddUsageButton = true
      }

      const iconsMapp = {
        'smskombnder': 'ic_sms_text',
        'internet': 'ic_data_sharing',
        'minnderkomb': 'ic_outgoing_call',
        'minkomb': 'ic_outgoing_call',
        'smskomb': 'ic_sms_text',
        'unlimited_smskomb': 'ic_sms_text',
        'unlimited_internet': 'ic_data_sharing',
        'unlimited_minkomb': 'ic_outgoing_call',
        'fix_min': 'ic_outgoing_call',
      }

      item.icon = iconsMapp[bucket.usageType]

      const objKey = bucket.usageType + '-' + bucket.id

      const totalValue = buckets[objKey].bucketCounter[0].value.amount
      const totalUnit = buckets[objKey].bucketCounter[0].value.units

      const usedValue = buckets[objKey].bucketBalance[0].remainingValue.amount
      const usedUnit = buckets[objKey].bucketBalance[0].remainingValue.units

      item.usageValuesPlaceholders = [
        `${usedValue}${usedUnit}`,
        `${totalValue}${totalUnit}`
      ]

      item.usagePercentageValue = getUsagePercentage(bucket)
      item.usageValuesText = 'my_plan_primary_card_consumption'
      item.usageItemLabel = bucket.name
      item.addUsageButtonTitle = characteristics[bucket.id + 'CTAText']
      item.isUnlimitedUsage = totalValue == -1 ? true : false

      mappedResponse.usageData.push(item)
    })

    data.push(mappedResponse)
  })

  return {
    customerBill: customerBill,
    data: data
  }
}

async function getMyPlanData(
  customHeaders = {},
  utilities = {}
) {

  const _utilities = retrieveUtilities(utilities, {
    responseMapper: response =>
      mapMyPlanResponse(response, _get(utilities, 'iconsMapper', () => { }))
  })

  const {
    errorMapper,
    onError,
    onStart,
    onSuccess,
    responseMapper
  } = _utilities


  onStart()
  const params = {
    'product.relatedParty.id': ReduxCache.getMsisdn(),
    'product.relatedParty.role': 'subscriber',
    'product.id': 'myplan',
  }


  try {
    const response = await axios.get(API_URLS.productsAndServices.myPlan, { headers: customHeaders, params: params })
    const _response = responseMapper(response.data)

    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

export { mapMyPlanResponse, getMyPlanData }
