import axios from 'axios'

import { isFunction } from 'lodash'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import { API_URLS } from '../Config'

import ReduxCache from '../../Utils/ReduxCache'

import {
  errorMapper,
  isGroupMemberMapper,
  customerCheckMapper,
  customerCheckByIDMapper
} from './Mappers'

const getAttachment = (type, data) => {
  switch (type) {
    case 'front_image':
      return data.frontIdB64
    case 'back_image':
      return data.backIdB64
    case 'selfie_image':
      return data.selfieB64
    default:
      return ''
  }
}

const checkIfGroupMember = async utilities => {
  const _utilities = retrieveUtilities({
    ...utilities,
    errorMapper,
    responseMapper: isGroupMemberMapper
  })
  try {
    _utilities.onStart()
    const response = await axios.get(API_URLS.unlimited.getMembers, {
      headers: {
        'vf-project': 'unlimited'
      },
      params: {
        id: ReduxCache.getMsisdn()
      }
    })
    const _response = _utilities.responseMapper(response?.data ?? {})
    _utilities.onSuccess(_response)
  } catch (error) {
    const _error = _utilities.errorMapper(error)
    if (isFunction(_utilities.onError)) {
      _utilities.onError(_error)
    } else {
      throw _error
    }
  }
}

const createCustomer = async ({ body, ...utilities }) => {
  const _utilities = retrieveUtilities({ ...utilities, errorMapper })
  _utilities.onStart()
  try {
    const attachments = ['front_image', 'back_image', 'selfie_image']
    const endDateTime = new Date(
      parseInt(body.documentYear, 10),
      parseInt(body.documentMonth, 10) - 1,
      1,
      12,
      34,
      54
    ).toISOString()
    const regex = /data:.*base64,/

    const reqBody = {
      status: 'validated',
      gender: body.gender,
      givenName: body.name,
      nationality: 'ALBANIAN',
      placeOfBirth: body.city,
      familyName: body.surname,
      birthDate: body.birthday,
      countryOfBirth: 'Albania',
      contactMedium: [
        {
          mediumType: 'string',
          preferred: true,
          characteristic: {
            city: body?.locationData?.city
              ? body?.locationData?.city
              : 'TIRANE',
            contactType: 'string',
            country: body?.locationData?.country
              ? body?.locationData?.country?.toUpperCase()
              : 'ALBANIA',
            emailAddress: body?.email,
            faxNumber: 'string',
            phoneNumber: 'string',
            postCode: '1001',
            socialNetworkId: 'string',
            stateOrProvince: 'string',
            street1: body?.locationData?.street
              ? body?.locationData?.street
              : 'Rruga Myslym Shyri',
            street2: 'string',
            '@baseType': 'string',
            '@schemaLocation': 'string',
            '@type': 'string'
          },
          validFor: {
            endDateTime: '2022-08-18T12:59:19.430Z',
            startDateTime: '2022-08-18T12:59:19.430Z'
          },
          '@baseType': 'string',
          '@schemaLocation': 'string',
          '@type': 'string'
        }
      ],
      individualIdentification: attachments.map(attachment => {
        return {
          identificationType: 'idCard',
          identificationId: body.documentId,
          issuingAuthority: 'Albania',
          attachment: {
            mimeType: 'png',
            attachmentType: attachment,
            size: { amount: 1, units: '' },
            content: getAttachment(attachment, body).replace(regex, '')
          },
          validFor: { endDateTime }
        }
      }),
      partyCharacteristic: [
        { name: 'fixed', value: 'true' },
        { name: 'personalNumber', value: body.documentId }
      ],
      relatedParty: [
        {
          '@type': 'Customer',
          id: ReduxCache.getMsisdn(),
          '@referredType': 'Customer'
        }
      ]
    }
    const response = await axios.post(
      API_URLS.unlimited.createCustomer,
      reqBody,
      {
        headers: {
          'vf-project': 'unlimited'
        }
      }
    )
    const _response = _utilities.responseMapper(response?.data ?? {})
    _utilities.onSuccess(_response)
  } catch (error) {
    const _error = _utilities.errorMapper(error)
    if (isFunction(_utilities.onError)) {
      _utilities.onError(_error)
    } else {
      throw _error
    }
  }
}

const checkCustomerByMsisdn = async utilities => {
  const _utilities = retrieveUtilities({
    ...utilities,
    errorMapper,
    responseMapper: customerCheckMapper
  })
  try {
    _utilities.onStart()
    const response = await axios.get(
      `${API_URLS.fix.customerManagement}/${ReduxCache.getMsisdn()}`
    )
    const _response = _utilities.responseMapper(response?.data ?? {})
    _utilities.onSuccess(_response)
  } catch (error) {
    const _error = _utilities.errorMapper(error)
    if (isFunction(_utilities.onError)) {
      _utilities.onError(_error)
    } else {
      throw _error
    }
  }
}

const checkCustomerByID = async ({ body, ...utilities }) => {
  const _utilities = retrieveUtilities({
    ...utilities,
    errorMapper,
    responseMapper: customerCheckByIDMapper
  })
  try {
    _utilities.onStart()
    const response = await axios.get(API_URLS.unlimited.validateCustomer, {
      params: {
        id: body.fixId,
        'individualIdentification.identificationType': 'ID_CARD',
        'individualIdentification.identificationId': body.documentId
      }
    })
    const _response = _utilities.responseMapper(response?.data ?? {})
    _utilities.onSuccess(_response)
  } catch (error) {
    const _error = _utilities.errorMapper(error)
    if (isFunction(_utilities.onError)) {
      _utilities.onError(_error)
    } else {
      throw _error
    }
  }
}

export {
  createCustomer,
  checkCustomerByID,
  checkIfGroupMember,
  checkCustomerByMsisdn
}
