import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { VFScreen } from '@vfgroup-oneplatform/foundation/Components'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { UsersAndGroupServices } from '../../../Services/Business'

import ReduxCache from '../../../Utils/ReduxCache'

import Users from './Users'

import styles from './UsersAndGroup.Styles'


const UsersAndGroup = ({ navigation }) => {

  const organizationId = ReduxCache.getBusinessAccount()?.businessID
  const scrollViewRef = useRef()
  const theme = useTheme()

  const insets = useSafeAreaInsets()

  const [users, setUsers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)


  const onClose = () => navigation.pop()

  const onUserPress = (user) => navigation.navigate('ProfileManagement', { user })

  const getUserData = useCallback(() => {
    setIsLoading(true)
    setError(null)
    UsersAndGroupServices.getUsers(organizationId, {
      onSuccess: res => {
        setIsLoading(false)
        setUsers(res)
      },
      onError: err => {
        setError(err)
        setIsLoading(false)
      }
    })
  }, [organizationId])

  useEffect(() => {
    getUserData()
  }, [getUserData])


  return (
    <VFScreen
      title="soho_my_users_and_groups_screen_title"
      subHeaderTitle="soho_my_users_and_groups_title"
      subHeaderSubTitle="soho_my_users_and_groups_subtitle"
      withSubHeader
      subHeaderSubTitleStyle={styles.subHeaderSubTitleStyle(theme)}
      subHeaderTitleStyle={styles.subHeaderTitleStyle(theme)}
      getScrollViewRef={scrollViewRef}
      onClose={onClose}
      containerStyle={[styles.containerStyle, { paddingBottom: insets.bottom }]}
      subHeaderMaxHeight={80}
    >
      <Users
        users={users}
        error={error}
        isLoading={isLoading}
        onUserPress={onUserPress}
        tryAgain={getUserData}
        scrollRef={scrollViewRef}
      />
    </VFScreen>
  )
}
UsersAndGroup.propTypes = {
  navigation: PropTypes.object,
}

export default UsersAndGroup
