import __translate from './i18n'
import __image from './images'
import { getLocal, setLocal, getLocalKeys, setLocalToken, setLocalTokens, setLocalKeys } from './Auth'
import __locale from './locale'
import { toastr } from './Toast'
import {
  removeNullValues,
  isArray,
  isNonEmptyArray,
  isEmptyArray,
} from './JSON'
import { getMenu } from './Menu'


import { validatePhoneNumber, formatPhoneNumber, validatePersonalNumber, validateName, validateGroupName, formatNumber, formatNumberNoDecimal, validateEmail } from './Regex'

import {
  toLowerCase,
  toUpperCase,
  isURL,
  normalizeString,
  normalizeObject,
  capitalizeFirstLetter,
  isFixUser,
  isBusinessChooser
} from './Helpers'

import {
  getMsisdnData,
  setMsisdnData,
  isShownToday,
  setShownToday,
} from './MsisdnLocalData'
import ReduxCache from './ReduxCache'

const getErrorMessage = err => {
  let message = ''
  try {
    message = err.response.data.message
  } catch (error) {
    // if something goes wrong mostly by getting undefined properties then the message will just be the default one
    message = 'Dicka shkoi gabim!'
  }

  if (!message || typeof message !== 'string') {
    message = 'Dicka shkoi gabim!'
  }

  return message
}

const isAdminView = () => {
  const businessAccount = ReduxCache.getBusinessAccount()

  if (businessAccount?.sohoUserType === 'business_chooser' && businessAccount?.showBusinessView) {
    return true
  } else {
    return false
  }
}

export {
  __translate,
  getErrorMessage,
  __image,
  getLocal,
  setLocal,
  getLocalKeys,
  __locale,
  toastr,
  setLocalToken,
  setLocalTokens,
  removeNullValues,
  isArray,
  isNonEmptyArray,
  isEmptyArray,
  toLowerCase,
  toUpperCase,
  isURL,
  normalizeString,
  normalizeObject,
  setLocalKeys,
  capitalizeFirstLetter,
  getMsisdnData,
  setMsisdnData,
  validatePhoneNumber,
  formatPhoneNumber,
  isShownToday,
  setShownToday,
  isFixUser,
  isAdminView,
  isBusinessChooser,
  validatePersonalNumber,
  validateName,
  validateGroupName,
  formatNumber,
  formatNumberNoDecimal,
  getMenu,
  validateEmail
}
