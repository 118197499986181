import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  divider: theme => ({
    width: '100%',
    height: 1,
    backgroundColor: theme.colors.horizontalLineColor,
    marginVertical: 16
  }),
  lottieStyle: {
    width: 200,
    height: 200
  }
})
