import React from 'react'
import { View } from 'react-native'
import LottieView from 'lottie-react-native'

import PropTypes from 'prop-types'

import styles from './MigrationLottie.Styles'

const vfLoaderSource = require('./spinner.json')

const MigrationLottie = ({ source, style, accessibilityLabel }) => {
  return (
    <View>
      <LottieView
        style={[styles.container, style]}
        autoPlay
        loop={true}
        source={source}
      />
    </View>
  )
}

MigrationLottie.defaultProps = {
  accessibilityLabel: 'TPmainIcon',
  source: vfLoaderSource,
}

MigrationLottie.propTypes = {
  source: PropTypes.object,
  accessibilityLabel: PropTypes.string,
  style: PropTypes.object,
}

export default MigrationLottie
