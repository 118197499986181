// import axios from 'axios'
import axios from 'axios'
import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import { isFunction } from 'lodash'

import ReduxCache from '../../Utils/ReduxCache'

import { API_URLS } from '../Config'

import { errorMapper } from './Mappers'

const getShoppingCartCategory = bundleType => {
  switch (bundleType) {
    case 'pega':
      return 'shoppingCartPega'
    case 'custom':
      return 'shoppingCartCustom'
    case 'standard':
      return 'shoppingCartStandart'
  }
}

const getCheckoutBody = ({
  tv,
  plan,
  price,
  admin,
  address,
  members,
  internet,
  groupName,
  bundleType,
  installDate
}) => {
  const INSTALL_ADDRESS =
    address.replace(/\s/g, '') === ','
      ? 'Rruga Muhamet Gjollesha, Tirane, Shqiperi'
      : address

  const NOW = new Date().toISOString()
  const data = {
    requestedStartDate: NOW,
    category: getShoppingCartCategory(bundleType),
    channel: [{ id: 'MVA', name: 'shopId' }],
    note: [
      {
        id: 'address',
        author: 'string',
        date: installDate,
        '@type': 'string',
        '@baseType': 'string',
        '@schemaLocation': 'string',
        text: INSTALL_ADDRESS
      },
      {
        id: 'groupName',
        text: groupName
      }
    ],
    orderTotalPrice: [
      { description: 'string', price: { taxIncludedAmount: price } }
    ],
    productOrderItem: [
      // Tariff Plan
      {
        id: plan.id,
        action: 'add',
        product: {
          id: plan.id,
          startDate: NOW,
          name: plan.value,
          description: 'tariffPlan',
          productOffering: { id: plan.id, name: plan.value },
          productCharacteristic: [{ name: 'string', value: 'string' }],
          productTerm: [
            {
              name: 'Kusht',
              '@type': 'commitmentTerm',
              duration: plan.duration
            },
            {
              name: 'Kusht',
              '@type': 'usageTerm',
              duration: plan.duration
            }
          ],
          place: [
            {
              role: '',
              id: 'Tirane',
              '@referredType': '',
              name: INSTALL_ADDRESS
            }
          ],
          agreement: [
            {
              id: 'string',
              href: 'string',
              name: 'string',
              '@type': 'string',
              '@baseType': 'string',
              '@referredType': 'string',
              agreementItemId: 'string',
              '@schemaLocation': 'string'
            }
          ]
        }
      },
      // Internet
      {
        action: 'add',
        id: internet.id,
        product: {
          startDate: NOW,
          id: internet.id,
          name: internet.name,
          description: 'Internet',
          productRelationship: [],
          productOffering: { id: internet.id, name: internet.name },
          place: [
            {
              role: '',
              id: 'Tirane',
              '@referredType': '',
              name: INSTALL_ADDRESS
            }
          ],
          productCharacteristic: [
            { name: 'fixedId', value: internet.fixId },
            { name: 'DownloadSpeed', value: internet.speed }
          ],
          agreement: [
            {
              id: 'string',
              href: 'string',
              name: 'string',
              '@type': 'string',
              '@baseType': 'string',
              '@referredType': 'string',
              '@schemaLocation': 'string',
              agreementItemId: 'string'
            }
          ],
          productTerm: [
            {
              description: '',
              '@type': 'commitmentTerm',
              name: '18MonthCommitment',
              validFor: { startDateTime: NOW },
              duration: { amount: 18, units: 'Month' }
            }
          ]
        }
      }
    ],
    relatedParty: [
      { id: admin, role: 'admin', '@referredType': 'string' },
      ...members.entries.map(member => {
        return { role: 'member', id: member, '@referredType': 'string' }
      })
    ],
    requestStartDate: NOW
  }

  if (tv && tv.id) {
    data.productOrderItem.push(
      // TV
      {
        id: tv.id,
        action: 'add',
        product: {
          id: 'string',
          name: tv.name,
          startDate: NOW,
          description: 'tv',
          productOffering: { id: tv.id, name: tv.name },
          productCharacteristic: [{ name: 'Converged', value: 'True' }]
        }
      }
    )
  }

  if (members.id && members.name) {
    data.productOrderItem.push(
      // Members
      {
        action: 'add',
        id: members.id,
        product: {
          startDate: NOW,
          id: members.id,
          name: members.name,
          description: 'members',
          productOffering: { id: members.id, name: members.name },
          productCharacteristic: [{ name: 'members', value: members.name }],
          agreement: [
            {
              id: 'string',
              href: 'string',
              name: 'string',
              '@type': 'string',
              '@baseType': 'string',
              agreementItemId: 'string',
              '@referredType': 'string',
              '@schemaLocation': 'string'
            }
          ],
          place: [
            {
              role: '',
              id: 'Tirane',
              '@referredType': '',
              name: INSTALL_ADDRESS
            }
          ],
          productTerm: [
            {
              name: 'Kusht',
              '@type': 'commitmentTerm',
              duration: plan.duration
            },
            {
              name: 'Kusht',
              '@type': 'usageTerm',
              duration: plan.duration
            }
          ]
        }
      }
    )
  }

  return data
}

const unlimitedCheckout = async ({ body, ...utilities }) => {
  const _utilities = retrieveUtilities({
    ...utilities,
    errorMapper: errorMapper
  })
  // TODO: Pass data
  const reqBody = getCheckoutBody({
    ...body,
    admin: ReduxCache.getMsisdn()
  })
  try {
    _utilities.onStart()
    const response = await axios.post(API_URLS.unlimited.checkout, reqBody, {
      headers: {
        'vf-project': 'unlimited'
      }
    })
    const _response = _utilities.responseMapper(response?.data ?? [])
    _utilities.onSuccess(_response)
  } catch (error) {
    const _error = _utilities.errorMapper(error)
    if (isFunction(_utilities.onError)) {
      _utilities.onError(_error)
    } else {
      throw _error
    }
  }
}

export { unlimitedCheckout }
