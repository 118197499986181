import React, { useState, useEffect } from 'react'
import { View, Dimensions } from 'react-native'
import PropTypes from 'prop-types'
import ContentLoader, { Rect } from 'react-content-loader/native'

import { Card, VFText, VFButton, Icon } from '@vfgroup-oneplatform/foundation/Components'
import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { getThemeImages } from '../../../../Group/Themes'

import { Billing as BillingService } from '../../../../Services'

import styles from './SubscriptionItem.Styles'

const { width: SCREEN_WIDTH } = Dimensions.get('screen')

const SubscriptionItem = ({
  id,
  headlineProps,
  total,
  isLoading,
  detailsItems,
  role,
  openIndex,
  setOpenIndex,
  unfilteredData,
  isAdminView
}) => {

  const theme = useTheme()
  const themedImages = getThemeImages(theme.name)

  const [showData, setShowData] = useState(false)

  const [dataLoading, setDataLoading] = useState(false)
  const [details, setDetails] = useState([])


  const shouldGetEmployeesCalls = (role === 'employee' || role === 'individual' || role === 'fix') && isAdminView


  useEffect(() => {
    if (unfilteredData && unfilteredData?.findIndex(el => el.id === id) !== openIndex) {
      setShowData(false)
    } else if (openIndex === undefined) {
      setShowData(false)
    }
  }, [openIndex, id, unfilteredData])


  const onShowMorePress = () => {
    setShowData(true)
    setOpenIndex(unfilteredData.findIndex(el => el.id === id))
    if (shouldGetEmployeesCalls) {
      setDataLoading(true)
      BillingService.getBillBreakdown(id, false, headlineProps.id, {
        onSuccess: res => {
          setDataLoading(false)
          setDetails(res?.subscription?.[0]?.detailsItems)
        },
        onError: () => {
          setDataLoading(false)
        }
      })
    }
  }

  const onShowLessPress = () => {
    setShowData(false)
    setOpenIndex(undefined)
  }


  const renderHeaderLoading = () => {
    return (
      <ContentLoader
        speed={2}
        width={SCREEN_WIDTH - 32}
        height={33}
        backgroundColor={theme.colors.shimmerCenterColor}
        foregroundColor={theme.colors.shimmerEdgesColor}
      >
        <Rect x="0" y="0" rx="4" ry="4" width={35} height={35} />
        <Rect x="50" y="0" rx="4" ry="4" width={120} height={10} />
        <Rect x="50" y="25" rx="4" ry="4" width={120} height={8} />
      </ContentLoader>
    )
  }

  const renderItem = (item) => {
    return (
      <View style={styles.itemContainer(theme)}>
        {item?.map((el, index) => <View style={styles.row} key={index} >
          <VFText i18nKey={el.title} style={styles.itemTitle} />
          <VFText i18nKey={el.price} style={styles.itemText} />
        </View>)}
      </View>
    )
  }

  const renderTotal = () => {
    return (
      <View>
        {total && <View style={styles.itemContainer(theme)}>
          <View style={styles.row}>
            <VFText i18nKey="billing_payment_breakdown_total_before_vat_title" style={styles.itemText} />
            <VFText i18nKey={total?.noVatValue} style={styles.itemText} />
          </View>
          <View style={styles.row}>
            <VFText i18nKey="billing_payment_breakdown_vat_title" style={styles.itemText} />
            <VFText i18nKey={total?.vatValue} style={styles.itemText} />
          </View>
          <View style={styles.row}>
            <VFText i18nKey="billing_payment_breakdown_total_pay_title" style={[styles.itemText, styles.bold]} />
            <VFText i18nKey={total?.totalValue} style={[styles.itemText, styles.bold]} />
          </View>
        </View>}
      </View>
    )

  }

  const renderLoadingItem = () => {
    return (
      <ContentLoader
        speed={2}
        width={SCREEN_WIDTH - 82}
        height={60}
        backgroundColor={theme.colors.shimmerCenterColor}
        foregroundColor={theme.colors.shimmerEdgesColor}
      >
        <Rect x="0" y="20" rx="4" ry="4" width={100} height={10} />
        <Rect x={SCREEN_WIDTH - 214} y="20" rx="4" ry="4" width={100} height={10} />
        <Rect x="0" y="35" rx="4" ry="4" width={100} height={10} />
        <Rect x={SCREEN_WIDTH - 214} y="35" rx="4" ry="4" width={100} height={10} />
        <Rect x="0" y="50" rx="4" ry="4" width={100} height={10} />
        <Rect x={SCREEN_WIDTH - 214} y="50" rx="4" ry="4" width={100} height={10} />
      </ContentLoader>
    )
  }

  const renderTotalLoading = () => {
    return (
      <>
        <View style={styles.line(theme)} />
        <ContentLoader
          speed={2}
          width={SCREEN_WIDTH - 32}
          height={60}
          backgroundColor={theme.colors.shimmerCenterColor}
          foregroundColor={theme.colors.shimmerEdgesColor}
        >
          <Rect x="0" y="20" rx="4" ry="4" width={100} height={10} />
          <Rect x={SCREEN_WIDTH - 164} y="20" rx="4" ry="4" width={100} height={10} />
          <Rect x="0" y="35" rx="4" ry="4" width={100} height={10} />
          <Rect x={SCREEN_WIDTH - 164} y="35" rx="4" ry="4" width={100} height={10} />
          <Rect x="0" y="50" rx="4" ry="4" width={100} height={10} />
          <Rect x={SCREEN_WIDTH - 164} y="50" rx="4" ry="4" width={100} height={10} />
        </ContentLoader>
      </>
    )
  }


  return (
    <Card type="main" style={styles.cardStyle}>
      <View style={styles.cardHeader(theme)} >
        {isLoading ? renderHeaderLoading() : <>
          {headlineProps?.logo && <Icon size={35} name={themedImages[headlineProps?.logo]} />}
          <View>
            <VFText
              i18nKey={headlineProps?.title}
              style={styles.headerTitle}
            />
          </View>
        </>}
      </View>

      {showData && <>
        {!shouldGetEmployeesCalls ? <>
          {Object.keys(detailsItems)?.length > 0 && <View style={styles.dataContainer}>
            {Object.keys(detailsItems).map(detailsItem => renderItem(detailsItems[detailsItem]))}
          </View>}
          {/* total */}
          {renderTotal()}
        </> : <>
          {dataLoading ? <>
            <View style={styles.dataContainer}>
              {renderLoadingItem()}
            </View>
            {renderTotalLoading()}
          </> : <>
            {Object.keys(details)?.length > 0 && <View style={styles.dataContainer}>
              {Object.keys(details).map(detailsItem => renderItem(details[detailsItem]))}
            </View>}
            {/* total */}
            {renderTotal()}
          </>}
        </>}
      </>}
      <VFButton
        title={showData ? 'billing_payment_breakdown_btn_less' : 'billing_payment_breakdown_btn_more'}
        type="secondary"
        style={styles.button}
        textStyle={styles.buttonText(theme)}
        onPress={showData ? onShowLessPress : onShowMorePress}
        disabled={isLoading}
      />
    </Card>
  )
}
SubscriptionItem.defaultProps = {
  headlineProps: {},
  total: {}
}
SubscriptionItem.propTypes = {
  isLoading: PropTypes.bool,
  headlineProps: PropTypes.object,
  total: PropTypes.object,
  detailsItems: PropTypes.object,
  id: PropTypes.string.isRequired,
  role: PropTypes.string,
  unfilteredData: PropTypes.array,
  setOpenIndex: PropTypes.func,
  openIndex: PropTypes.number,
  isAdminView: PropTypes.bool
}

export default SubscriptionItem
