import React from 'react'
import {
  TouchableHighlight,
  Text,
  ViewPropTypes,
  StyleSheet,
  View
} from 'react-native'
import PropTypes from 'prop-types'
import { get as _get, isNil as _isNil } from 'lodash'
import memoize from 'memoize-one'
import { TypesList, Types } from '@vfgroup-oneplatform/foundation/Components/Themes/Types'
import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'
import VFText from '@vfgroup-oneplatform/foundation/Components/VFText'

import MigrationLottie from '../MigrationLottie'

import styles, { underlayColor } from './VFButton.Styles'

const getProperStyle = (
  type,
  disabled,
  style,
  textStyle,
  disabledStyle,
  disabledTextStyle,
  theme,
  forceTheme
) => {
  const isThemeForced =
    _get(forceTheme, 'force', false) && !_isNil(forceTheme.theme)
  const buttonStyles = [
    styles[`${type}ButtonStyle`](theme),
    isThemeForced ? styles[`${type}ButtonStyle`](forceTheme.theme) : undefined,
    style
  ]
  const textStyles = [
    styles[`${type}TextStyle`](theme),
    isThemeForced ? styles[`${type}TextStyle`](forceTheme.theme) : undefined,
    textStyle
  ]
  const underlayColorValue = underlayColor[type]

  if (disabled) {
    buttonStyles.push(styles.disabledButtonStyle)
    buttonStyles.push(disabledStyle)
    textStyles.push(disabledTextStyle)
  }

  return {
    buttonStyle: StyleSheet.flatten(buttonStyles),
    textStyle: StyleSheet.flatten(textStyles),
    underlayColor: underlayColorValue
  }
}

const getProperStyleMemoized = memoize(getProperStyle)

const VFButton = ({
  title,
  onPress,
  disabled,
  style,
  textStyle,
  disabledStyle,
  disabledTextStyle,
  textAccessibilityLabel,
  type,
  placeHolders,
  forceTheme,
  isLoading,
  loadingTitle,
  ...props
}) => {
  const theme = useTheme()

  const properStyles = getProperStyleMemoized(
    type,
    disabled || isLoading,
    style,
    textStyle,
    disabledStyle,
    disabledTextStyle,
    theme,
    forceTheme
  )

  return (
    <TouchableHighlight
      underlayColor={properStyles.underlayColor}
      onPress={onPress}
      disabled={disabled || isLoading}
      {...props}
      style={properStyles.buttonStyle}
      accessible
    >
      {isLoading
        ? <View style={styles.loadingContainer}>
          <VFText
            i18nKey={loadingTitle}
            style={properStyles.textStyle}
            accessibilityLabel={textAccessibilityLabel}
          />
          <MigrationLottie />

        </View>
        : <VFText
          i18nKey={title}
          style={properStyles.textStyle}
          accessibilityLabel={textAccessibilityLabel}
          placeHolders={placeHolders}
        />
      }

    </TouchableHighlight>
  )
}

VFButton.defaultProps = {
  type: Types.PRIMARY,
  style: {},
  textStyle: {},
  disabledStyle: {},
  disabledTextStyle: {},
  forceTheme: {
    force: false,
    theme: undefined
  },
  isLoading: false,
  loadingTitle: ''
}

VFButton.propTypes = {
  type: PropTypes.oneOf(TypesList),
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  style: ViewPropTypes.style,
  textStyle: Text.propTypes.style,
  disabledStyle: ViewPropTypes.style,
  disabledTextStyle: Text.propTypes.style,
  textAccessibilityLabel: PropTypes.string,
  placeHolders: PropTypes.arrayOf(PropTypes.string.isRequired),
  forceTheme: PropTypes.objectOf({
    force: PropTypes.bool.isRequired,
    theme: PropTypes.objectOf({
      name: PropTypes.string,
      isDark: PropTypes.bool,
      colors: PropTypes.object
    })
  }),
  isLoading: PropTypes.bool,
  loadingTitle: PropTypes.string
}

export default VFButton
