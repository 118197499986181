import {
  GeneratePin,
  ValidatePin,
  GetOfferDetails,
  GetPreferences,
  UpdatePreferences,
  SearchAccount,
  Notify,
  CreateOrder
} from '@vfal-lab/rn-vfg-migration/Services'

import { CLIENT_ID, CLIENT_SECRET } from '../../Services/Config'

import ReduxCache from '../../Utils/ReduxCache'


import { actionTypes } from './Migration.ActionTypes'

const generatePinResponseMapper = (response) => {
  const payload = response.data
  return payload
}

const validatePinResponseMapper = (response) => {
  const payload = response.data
  return payload
}

const errorMapperMessage = (error) => {
  const payload = error?.response?.data?.message
  return payload
}

const getOfferDetailsResponseMapper = (response) => (
  (response.data || []).map(data => {
    const {
      id,
      name: title,
      desc,
      type,
      status,
      validityPeriod: {
        toDate: {
          date: expirationDate
        } = {}
      } = {},
      details,
      category,
      parts: {
        campaign,
        price: priceObj,
        lineItem: lineItemObj,
        activationPeriod: activationPeriodPayload = [],
        specification = {}
      } = {}
    } = (data || {})

    const price = {}
    priceObj?.map(item => {
      price[item.type] = {}
      price[item.type].title = item.name
      price[item.type].priceDetail = item.priceDetail
    })

    const activationPeriod = []
    activationPeriodPayload.map(item => {
      activationPeriod.push({
        title: item.name,
        desc: item.desc
      })
    })

    const extraBenefits = []
    const characteristics = {}
    specification.characteristics?.map(item => {
      if (item.type === 'extra_benefits') {
        extraBenefits.push({
          title: item.name,
          type: item.type
        })
      } else {
        characteristics[item.type] = item.value
      }
    })

    const lineItem = []
    lineItemObj?.map(item => {
      let description = ''
      item.specification?.characteristicsValue?.map((element, i) => {
        if (i === 0) {
          description = element.value
        } else {
          description = `${description}\n${element.value}`
        }
      })

      lineItem.push({
        type: item.type,
        title: item.name,
        desc: description
      })
    })

    return {
      id,
      title,
      desc,
      type,
      status,
      details,
      category,
      campaign,
      price,
      activationPeriodPayload,
      activationPeriod,
      extraBenefits,
      lineItem,
      expirationDate,
      ...characteristics
    }
  })
)

const errorMapperObject = error => {
  const payload = error?.response?.data
  return payload
}

const getPreferencesResponseMapper = (response) => {
  const data = response.data?.[0]
  const payload = []

  data.parts?.partyPermission?.permission?.partyPermission?.map((item, index) => {
    const permission = {}
    const necessity = item.id?.[0]?.schemeName

    permission.id = item.id?.[0]?.value
    permission.necessity = necessity
    permission.withCheckBox = true
    permission.value = necessity === 'required' ? 'ON' : item.status
    permission.desc = item.desc
    permission.index = index
    permission.label = 'migration_terms_agree'

    payload.push(permission)
  })

  return {
    data: data,
    formatedData: payload
  }
}

const searchAccountResponseMapper = (response) => {
  const data = response.data?.[0]

  const id = data?.parts?.subscription?.[0]?.id?.[0]?.value

  return id

}

const customHeaders = {
  'vf-source': 'selfcare',
  'vf-operator': 'inappmigration',
  'vf-country-code': 'al'
}

const pinHeaders = {
  'vf-source': 'selfcare',
  'vf-operator': 'myvodafone',
  'vf-country-code': 'al',
  // 'vf-target-environment': 'al',
  Accept: '*/*'
}

const preferencesHeaders = {
  'x-source-system': 'selfcare',
  'x-source-operator': 'myvodafone',
  'X-Source-CountryCode': 'al'
}

const generatePin = (utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_GENERATE_PIN
    })

    const msisdn = ReduxCache.getMsisdn()

    const headers = Object.assign(
      {},
      pinHeaders,
    )

    GeneratePin(msisdn, CLIENT_ID, CLIENT_SECRET, headers, {
      ...utilities,
      responseMapper: generatePinResponseMapper,
      errorMapper: errorMapperMessage
    }).then(response => dispatch({
      type: actionTypes.SUCCESS_GENERATE_PIN,
      payload: response
    })).catch(error => {
      dispatch({
        type: actionTypes.ERROR_GENERATE_PIN,
        payload: error
      })
    })
  }
}

const validatePin = (pinCode, utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_VALIDATE_PIN
    })

    const msisdn = ReduxCache.getMsisdn()

    const headers = Object.assign(
      {},
      pinHeaders,
    )

    ValidatePin(pinCode, msisdn, CLIENT_ID, CLIENT_SECRET, headers, {
      ...utilities,
      responseMapper: validatePinResponseMapper,
      errorMapper: errorMapperMessage
    }).then(response => dispatch({
      type: actionTypes.SUCCESS_VALIDATE_PIN,
      payload: response
    })).catch(error => dispatch({
      type: actionTypes.ERROR_VALIDATE_PIN,
      payload: error
    }))
  }
}

const getOfferDetails = (type, utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_OFFER_DETAILS
    })

    const msisdn = ReduxCache.getMsisdn()

    const headers = Object.assign(
      {},
      customHeaders,
    )

    const STATUS = ''
    const CHANNEL_ID = 'selfcare'

    GetOfferDetails(type, STATUS, CHANNEL_ID, msisdn, headers, {
      ...utilities,
      responseMapper: getOfferDetailsResponseMapper,
      errorMapper: errorMapperMessage
    }).then(response => dispatch({
      type: actionTypes.SUCCESS_OFFER_DETAILS,
      payload: response
    })).catch(error => dispatch({
      type: actionTypes.ERROR_OFFER_DETAILS,
      payload: error
    }))
  }
}

const getPreferences = (utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_PREFERENCES
    })

    const msisdn = ReduxCache.getMsisdn()

    const headers = Object.assign(
      {},
      preferencesHeaders,
    )

    const CHANNEL_ID = 'inappmigration'
    const OFFSET = 0
    const COUNT = 10

    GetPreferences(msisdn, CHANNEL_ID, OFFSET, COUNT, headers, {
      ...utilities,
      responseMapper: getPreferencesResponseMapper,
      errorMapper: errorMapperMessage
    }).then(response => {
      dispatch({
        type: actionTypes.SUCCESS_PREFERENCES_PAYLOAD,
        payload: response.data
      })
      dispatch({
        type: actionTypes.SUCCESS_PREFERENCES,
        payload: response.formatedData
      })
    }).catch(error => dispatch({
      type: actionTypes.ERROR_PREFERENCES,
      payload: error
    }))
  }
}

const updatePreference = (id, value, data) => {
  return async dispatch => {
    const index = data?.findIndex(item => item.id === id)
    if (index !== -1) {
      data[index].value = value
    }
    dispatch({
      type: actionTypes.UPDATE_PREFERENCE,
      payload: data
    })
  }
}

const updateAllPreferences = (value, data) => {
  return async dispatch => {
    data = data?.map((item) => {
      return {
        ...item,
        value: value
      }
    })
    dispatch({
      type: actionTypes.UPDATE_ALL_PREFERENCES,
      payload: data
    })
  }
}

const updatePreferences = (requestBody, updatedTerms, utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_UPDATE_PREFERENCES
    })

    const headers = Object.assign(
      {},
      preferencesHeaders,
    )

    const id = requestBody?.id?.[0]?.value

    const request = requestBody
    const partyPreference = request?.parts?.partyPreference?.partyPreference?.[0]
    if (!partyPreference) {
      request.parts.partyPreference = {
        partyPreference: [{}]
      }
    }

    const preferences = request?.parts?.partyPermission?.permission?.partyPermission
    updatedTerms.map(item => {
      if (preferences?.[item.index]?.id?.[0]?.value) { preferences[item.index].id[0].value = item.id }
      if (preferences?.[item.index]?.status) { preferences[item.index].status = item.value }
    })

    if (request?.parts?.partyPermission?.permission?.partyPermission) { request.parts.partyPermission.permission.partyPermission = preferences }


    UpdatePreferences(id, request, headers, {
      ...utilities,
      errorMapper: errorMapperMessage
    }).then(response => dispatch({
      type: actionTypes.SUCCESS_UPDATE_PREFERENCES,
      payload: response
    })).catch(error => dispatch({
      type: actionTypes.ERROR_UPDATE_PREFERENCES,
      payload: error
    }))
  }
}


const searchAccount = (id, type, email, utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_SEARCH_ACCOUNT
    })

    const msisdn = ReduxCache.getMsisdn()

    const headers = Object.assign(
      {},
      customHeaders,
    )

    const COUNT = 0
    const LIMIT = 10

    SearchAccount(msisdn, id, type, email, COUNT, LIMIT, headers, {
      ...utilities,
      responseMapper: searchAccountResponseMapper,
      errorMapper: errorMapperObject
    }).then(response => dispatch({
      type: actionTypes.SUCCESS_SEARCH_ACCOUNT,
      payload: response
    })).catch(error => dispatch({
      type: actionTypes.ERROR_SEARCH_ACCOUNT,
      payload: error
    }))
  }
}

const notify = (
  id,
  name,
  desc,
  category,
  status,
  details,
  campaign,
  activationPeriod,
  screen,
  utilities = {}
) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_NOTIFY
    })

    const msisdn = ReduxCache.getMsisdn()

    const headers = Object.assign(
      {},
      customHeaders,
    )


    Notify(
      id,
      name,
      desc,
      category,
      status,
      details,
      campaign,
      activationPeriod,
      msisdn,
      screen,
      headers,
      {
        ...utilities,
        errorMapper: errorMapperObject
      }
    ).then(response => dispatch({
      type: actionTypes.SUCCESS_NOTIFY,
      payload: response
    })).catch(error => dispatch({
      type: actionTypes.ERROR_NOTIFY,
      payload: error
    }))
  }
}


const createOrder = (
  id,
  type,
  category,
  status,
  activationPeriod,
  personalInfo,
  lineItem,
  pinCode,
  lastRecharges,
  frequentlyCalled,
  image,
  utilities = {}
) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_CREATE_ORDER
    })

    const msisdn = ReduxCache.getMsisdn()
    const headers = Object.assign(
      {},
      customHeaders,
    )

    CreateOrder(
      id,
      type,
      category,
      status,
      activationPeriod,
      msisdn,
      personalInfo,
      lineItem,
      pinCode,
      lastRecharges,
      frequentlyCalled,
      image,
      headers,
      {
        ...utilities,
        errorMapper: errorMapperMessage
      }
    ).then(response => dispatch({
      type: actionTypes.SUCCESS_CREATE_ORDER,
      payload: response
    })).catch(error => dispatch({
      type: actionTypes.ERROR_CREATE_ORDER,
      payload: error
    }))
  }
}

const actions = {
  generatePin,
  validatePin,
  getOfferDetails,
  getPreferences,
  updatePreference,
  updateAllPreferences,
  updatePreferences,
  searchAccount,
  notify,
  createOrder
}

export {
  actions
}
