import { OrderBonus } from '@vfal-lab/rn-vfg-giga-family/Services'

import ReduxCache from '../../Utils/ReduxCache'

import { actionTypes } from './GigaBonus.ActionTypes'

const orderResponseMapper = (response) => {
  return response.data
}

const errorMapper = (error) => {
  const payload = error?.response?.data || {}
  return payload
}

const defaultHeaders = {
  'vf-source': 'selfcare',
  'vf-operator': 'gigafamily',
  'vf-country-code': 'al'
}

const orderBonus = (
  name,
  coordinates,
  city,
  utilities = {}
) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_ORDER_BONUS
    })
    const msisdn = ReduxCache.getMsisdn()

    OrderBonus(
      msisdn,
      name,
      coordinates,
      city,
      defaultHeaders,
      {
      ...utilities,
      responseMapper: orderResponseMapper,
      errorMapper: errorMapper
    }
    ).then(response => dispatch({
      type: actionTypes.SUCCESS_ORDER_BONUS,
      payload: response
    })).catch(error => {
      dispatch({
        type: actionTypes.ERROR_ORDER_BONUS,
        payload: error
      })
    })
  }
}

const actions = {
  orderBonus
}

export {
  actionTypes,
  actions
}
