import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import LinearGradient from 'react-native-linear-gradient'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { VFText, VFButton, Icon, Badge } from '@vfgroup-oneplatform/foundation/Components'

import { Colors, Images as groupImages, useTheme, getThemeImages } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { Images } from '../../../../Themes'

import styles from './ActiveSim.Styles'

const ActiveSim = ({
  onClose,
  simNumber
}) => {

  const insets = useSafeAreaInsets()

  const theme = useTheme()

  const themedImages = getThemeImages(theme.name)

  return (
    <View style={styles.container}>
      <LinearGradient
        style={styles.topContainer(insets)}
        colors={[Colors.darkRedTwo, Colors.vfRed]}
        useAngle={true}
        angleCenter={{ x: 0.5, y: 0.5 }}
        angle={30}
      >
        <TouchableOpacity
          style={styles.closeButton}
          onPress={onClose}
          activeOpacity={1}
        >
          <Icon
            name={groupImages.ic_close_white}
            size={25}
            type="image"
          />
        </TouchableOpacity>

        <View style={styles.textContainer}>
          <VFText
            i18nKey="onboarding_congratulations_title"
            style={styles.title}
          />
          <VFText
            i18nKey="order_progress_active_sim_title"
            style={styles.subtitle}
          />
          <VFText
            i18nKey="order_progress_active_sim_subtitle"
            style={styles.desc}
          />
        </View>
        <View>
          <View style={styles.phoneContainer}>
            <View style={styles.screenContainer}>
              <View style={styles.circle} />
              <Icon
                name={Images.vf_logo_gray}
                size={50}
                resizeMode="contain"
                type="image"
              />
              <View style={styles.cardContainer}>
                <Icon
                  name={Images.sim_card}
                  size={80}
                  resizeMode="contain"
                  type="image"
                />
                <Badge
                  type="positive"
                  text="active_sim_card_active"
                  style={styles.badgeStyle}
                />
                <View style={styles.cardTextContainer}>
                  <VFText
                    i18nKey="active_sim_card_title"
                    style={styles.cardTitle}
                  />
                  <View>
                    <VFText
                      i18nKey="active_sim_card_subtitle"
                      style={styles.label}
                    />
                    <VFText
                      i18nKey={simNumber}
                      style={styles.simNumber}
                    />
                  </View>
                </View>
              </View>

            </View>
          </View>
        </View>

      </LinearGradient>
      <View style={styles.bottomContainer}>
        <VFText
          i18nKey="active_sim_title"
          style={styles.bold}
        />
        <View>
          <View style={styles.rowItem}>
            <Icon
              name={themedImages.icNetworkMap}
              resizeMode="contain"
              type="image"
              size={35}
              style={styles.icon}
            />
            <VFText
              i18nKey="active_sim_first_item_title"
              style={styles.text}
            />
          </View>
          <View style={styles.rowItem}>
            <Icon
              name={themedImages.ic_mobile}
              resizeMode="contain"
              type="image"
              size={35}
              style={styles.icon}
            />
            <VFText
              i18nKey="active_sim_second_item_title"
              style={styles.text}
            />
          </View>
          <View style={styles.rowItem}>
            <Icon
              name={themedImages.ic_Users_Members_Customers}
              resizeMode="contain"
              type="image"
              size={35}
              style={styles.icon}
            />
            <VFText
              i18nKey="active_sim_third_item_title"
              style={styles.text}
            />
          </View>
        </View>
        <VFButton
          type="primary"
          title="order_progress_active_sim_dashboard"
          style={styles.btnStyle}
          onPress={onClose}
        />
      </View>
    </View>
  )
}

ActiveSim.propTypes = {
  onClose: PropTypes.func,
  simNumber: PropTypes.string,
}

export default ActiveSim
