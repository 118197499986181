import { Platform } from 'react-native'
import * as Keychain from 'react-native-keychain'

import { Login, RefreshToken } from '../../Services'
import { setLocalKey } from '../../Services/Storage'

import ReduxCache from '../../Utils/ReduxCache'
import { formatPhoneNumber } from '../../Utils/Regex'

import { APP_VERSION } from '../../Config/index'

const actionTypes = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGOUT: 'LOGOUT',
    BALANCE_LOADING: 'BALANCE_LOADING',
    BALANCE_SUCCESS: 'BALANCE_SUCCESS',
    BALANCE_ERROR: 'BALANCE_ERROR',
    RESET_TOKEN: 'RESET_TOKEN',
    SWITCH_VIEW: 'SWITCH_VIEW',
}



const loginResponseMapper = (response) => {
    const items = {}

    try {
        const data = response.data?.parts?.specification?.characteristicsValue

        data.map(item => {
            items[item.characteristicName] = item.value
        })

        items.userType = response.data?.parts?.generalUserAccount[0]?.type
        items.tariffPlan = response.data?.parts?.generalUserAccount[0]?.category[0]?.value
        //business costumers
        if (response.data?.parts?.generalUserAccount[1] && response.data?.parts?.generalUserAccount[1]?.category?.[0]?.value === 'business') {
            items.businessAccount = {
                showBusinessView: response.data?.parts?.generalUserAccount[1]?.type === 'business_chooser' ? false : true, //to show business view of dashboard after login
                businessID: response.data?.parts?.generalUserAccount[1]?.id?.[0]?.value, //id used in requests
                businessName: response.data?.parts?.generalUserAccount[1]?.name, //name of business shown in tray
                sohoUserType: response.data?.parts?.generalUserAccount[1]?.type //chooser or user
            }
        }
    } catch (error) {

    }

    return items
}

const loginWithDXL = async (phoneNumber, password, useGuestClientCredentials = false, store) => {

    try {
        // Do not format for guest
        if (!useGuestClientCredentials) {
            phoneNumber = formatPhoneNumber(phoneNumber)
        }

        phoneNumber = formatPhoneNumber(phoneNumber)
        const headers = {
            bypass_token: true,
            'X-Application-Version': APP_VERSION,
            'X-Application-OS': Platform.OS == 'ios' ? 'IOS' : 'Android',
        }

        const response = await Login(phoneNumber, password, useGuestClientCredentials, headers, { responseMapper: loginResponseMapper })
        response.msisdn = phoneNumber
        Keychain.setGenericPassword(phoneNumber, response.refresh_token)
        delete response.refresh_tokenc

        store.dispatch({ type: actionTypes.LOGIN_SUCCESS, data: response })
        setLocalKey('isLoggedIn', JSON.stringify(true))
        window.localStorage.setItem('isLoggedInUAT', false)
        return response
    } catch (error) {
        throw error
    }
}

const refreshToken = async (msisdn = ReduxCache.getMsisdn(), userData = false, store) => {
    try {
        const headers = {
            bypass_token: true,
            'X-Application-Version': APP_VERSION,
            'X-Application-OS': Platform.OS == 'ios' ? 'IOS' : 'Android',
        }

        let response = await RefreshToken(msisdn, headers, { responseMapper: loginResponseMapper })
        response.msisdn = msisdn

        if (userData && Object.keys(userData).length > 0) {
            response = {
                ...response,
                ...userData
            }
        }

        setLocalKey('isLoggedIn', JSON.stringify(true))
        store.dispatch({ type: actionTypes.LOGIN_SUCCESS, data: response })
        // await Keychain.setGenericPassword(msisdn, response.refresh_token, { service: msisdn })

        delete response.refresh_token
        return response
    } catch (error) {
        throw error
    }
}

const Actions = { loginWithDXL, refreshToken }

export { Actions, actionTypes }
