/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'

import { useSelector, useDispatch } from 'react-redux'

import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'


import { VDayOffers } from '@vfal-lab/rn-vfg-vday/Screens'

import en from '@vfal-lab/rn-vfg-vday/I18n/languages/en'
import al from '@vfal-lab/rn-vfg-vday/I18n/languages/al'

import NavigationService from '../../Navigation/NavigationService'

import ReduxCache from '../../Utils/ReduxCache'

import { getVdayOffers } from './VDayOffers.Actions'

const LANGUAGES = {
  al: al,
  en: en,
}

function VDayOffersScreen({ navigation, route }) {
  const language = ReduxCache.getLanguage()
  const [isContentLoading, setContentLoading] = useState(true)
  const dispatch = useDispatch()
  const isDeeplink = route?.params?.isDeeplink

  const { navigate } = navigation

  const vdayProducts = useSelector(state => state.vdayOffers.data || {})
  const vdayProductsLoading = useSelector(state => state.vdayOffers.loading || false)

  const getOffers = async (utilities) => {
    return dispatch(getVdayOffers(utilities))
  }



  useLayoutEffect(() => {
    try {
      ContentManager.configureI18nTranslations({ en: LANGUAGES?.[language] })
    } catch (error) {
      ContentManager.configureI18nTranslations({ en: { ...en } })
    }
    getOffers({
      onSuccess: () => setContentLoading(false),
      onError: () => { },
    })
  }, [])

  const onClose = () => {
    navigation.goBack()
  }

  return (
    <VDayOffers
      onClose={onClose}
      isContentLoading={isContentLoading || vdayProductsLoading}
      vdayProducts={vdayProducts}
      getVdayOffers={getOffers}
      navigate={url =>
        navigate('WebView', { url, isReact: true })
      }
    />
  )
}
VDayOffersScreen.propTypes = {
  navigation: PropTypes.object,
}

export default VDayOffersScreen
