import React, { Component } from 'react'
import { View, FlatList, RefreshControl, BackHandler, Platform, Dimensions } from 'react-native'
import { Text, TabHeading, Tab, Tabs, ScrollableTab } from 'native-base'
import { connect } from 'react-redux'

import { VFScreen } from '@vfgroup-oneplatform/foundation/Components'

import { SafeAreaProvider } from 'react-native-safe-area-context'

import FastImage from 'react-native-fast-image'

import Product from '../../Components/Product'
import Filters from '../Filters'

import Header from '../../Components/Header'
import Loader from '../../Components/Loader'
import Redeem from '../Redeem'
import List from '../../Components/List'
import Error from '../../Components/Error'
import CardPlaceholder from '../../Components/Placeholder'

import { actions as profileActions } from '../Demo/demo.actions'
import { __translate, __image } from '../../Utils'

import ReduxCache from '../../Utils/ReduxCache'

import { actions } from './catalog.actions'
import styles from './catalog.styles'

import { Images } from '../../Themes'

import { DynamicCoupon } from '../../Utils/DynamicCoupon'
import { getThemeColors } from '@vfgroup-oneplatform/foundation/Components/Themes'

const DYNAMIC = {
  'yes': true,
  'no': false,
}

class Catalog extends Component {
  static contextType = DynamicCoupon
  constructor(props) {
    super(props)
    this.onEndReachedCalledDuringMomentum = true

    this.state = {
      popUp: {
        display: false,
        lastFetched: new Date().getTime(),
      },
      refreshing: false,
      active: 'favourite',
      favoritePage: 0,
      allPage: 0,
      amount: null,
      userType: ReduxCache.getUserType(),
    }
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.backAction)
  }

  componentDidMount() {
    if (!this.props.categories.loading && !this.props.categories.data) {
      this.props.getCategories(true)
    }

    this.props.getFavorites()

    let initialPage = 1
    if (this.props.preferences.submited) {
      initialPage = 0
      this.props.getAllItems()
    }

    setTimeout(() => {
      this.changePage(initialPage)
    }, 500)

    BackHandler.addEventListener('hardwareBackPress', this.backAction)
  }



  backAction = () => {
    this.props.navigation.pop(2)
    return true
  }

  changePage(page) {
    // there are some times when this.tabs is not initializet yet
    if (this.tabs) {
      this.tabs.goToPage(page)
      return
    }

    if (this.props.categories.error) {
      return
    }

    if (this.props.favorites.loading) {
      setTimeout(() => {
        this.changePage(page)
      }, 500)
    } else if (this.props.favorites.error) {
      this.changePage(1)
    }
  }
  _retryUser = () => {
    this.props.getUserProfile()
  };

  _retryCategories = () => {
    this.props.getCategories(true)
  };

  _retryFavorites = () => {
    this.props.getFavorites()
  };

  _onRefresh = () => {
    this.setState({ refreshing: true })
    this.props.getUserProfile()
    this.setState({ refreshing: false })
  };

  handleTabChange(item) {
    // if it's favourite tab we don't do anything

    if (typeof item.ref === 'undefined') {
      this.setState({ active: 'favourite' })
      return
    }

    if (item.ref.props.favourite) {
      this.setState({ active: 'favourite' })
      return
    }
    if (item.ref.props.partners) {
      this.setState({ active: 'partners' })
      return
    }
    const categoryId = item.ref.props.categoryId
    this.setState({ active: categoryId })
    if (
      typeof this.props.categories.data[categoryId] === 'undefined' ||
      typeof this.props.categories.data[categoryId].products === 'undefined'
    ) {
      if (categoryId === 'all') {
        this.props.getAllItems()
      } else {
        this.props.getCategoryItems(categoryId)
      }
    }
  }

  closePopUp(reload) {
    this.setState({
      popUp: {
        display: false,
      },
    })
    if (typeof reload === 'undefined') {
      reload = true
    }

    if (reload) {
      this.props.getUserProfile()
    }
  }

  showPopUp = (item, isDynamic, categoryId) => {
    if (isDynamic) {
      this.context.openCoupon(
        () => {
          //getActiveCoupon
          this.context.setActiveScreen('loading')
          this.context.setIsGet(true)
          this.props.getActiveCoupon(
            item?.id,
            () => this.context.setActiveScreen('qrCode'),
            () => {
              this.context.setActiveScreen('input')
            },
          )
        },
        () => {
          //onGenerateCoupon
          this.context.setActiveScreen('loading')
          this.context.setIsGet(false)
          this.props.generateCoupon(
            { ...item, value: this.context.couponValue },
            () => {
              //onSuccess
              this.context.setActiveScreen('qrCode')
              this.props.getUserProfile()
              this.props.getCategoryItems(categoryId, null, true)
            },
            () => {
              //onError
              this.context.setModal(prev => ({
                ...prev,
                error: {
                  error: this.props.dynamicCoupons.error,
                  type: 'generate',
                },
              }))
              this.context.setActiveScreen('error')
            },
          )
        },
        () => {
          //onCancelCoupon
          this.context.setActiveScreen('loading')
          this.props.cancelCoupon(
            item?.id,
            this.context.isGet ? this.props.activeCoupon.data : this.props.dynamicCoupons.data,
            () => {
              //onSuccess
              this.context.closeModal()
              this.props.getUserProfile()
              this.props.getCategoryItems(categoryId, null, 2)
            },
            () => {
              //onError
              this.context.setModal(prev => ({
                ...prev,
                error: {
                  error: this.props.cancelledCoupon.error,
                  type: 'cancel',
                },
              }))
              this.context.setActiveScreen('error')
            }
          )
        }
      )
    } else {
      this.setState({
        popUp: {
          display: true,
          item: item,
        },
      })
    }
  };

  _handleLoadMoreAll = category => {
    const time = new Date().getTime() - this.state.lastFetchedAll

    if (time < 500 || !category.products) {
      return
    }

    this.setState({ lastFetchedAll: new Date().getTime() })

    const items = category.products.data || []

    const categoryId = category.id

    const page = this.state[categoryId] || 0

    const prevTotal = this.state.allPrevTotal || 0
    const total = items.length

    if (total < 20) {
      return
    }

    if (total == prevTotal) {
      return
    }

    if (!category.products.error) {
      this.setState({ allPrevTotal: total, [categoryId]: page + 1 })
    }

    let filters = this.state.filters

    if (!filters) {
      filters = {}
    }

    filters.page = page + 1

    this.props.getCategoryItems(categoryId, filters)
  };

  _handleLoadMore = category => {
    const time = new Date().getTime() - this.state.lastFetched
    const categoryId = category.id

    if (categoryId == 'all') {
      this._handleLoadMoreAll(category)
      return
    }

    if (time < 500 || !category.products) {
      return
    }

    this.setState({ lastFetched: new Date().getTime() })

    const items = category.products.data || []

    let page = Math.floor(items.length / 5)
    if (items < 5) {
      page = 1
    }

    // if we have less than 5 items in favorites it means that we cant take more    
    if (!items || items.length < 5) {
      return
    }

    const prevPage = this.state[categoryId] || 0

    if (!category.products.error) {
      this.setState({ [categoryId]: page })
    }

    if (page <= prevPage) {
      return
    }

    const filters = {
      page: page,
    }

    this.props.getCategoryItems(categoryId, filters)
  };

  _handleLoadMoreFavorites = items => {
    // if we have less than 5 items in favorites it means that we cant take more
    if (!items || items.length < 5) {
      return
    }

    const page = Math.floor(items.length / 5)
    const prevPage = this.state.favoritePage

    if (page <= prevPage) {
      return
    }

    this.setState({ favoritePage: page })
    this.props.getFavorites(page)
  };

  favorite = id => {
    const product = this.props.plainProducts[id]
    this.props.favorite(product)
  };

  setListRef = ref => {
    this.flatListRef = ref
  };

  getAllItems = (filters = null) => {
    if (this.flatListRef) {
      this.flatListRef?.scrollToOffset({ animated: true, offset: 0 })
    }
    this.props.getAllItems(filters)
    this.setState({ filters: filters })
  };

  getTabs = () => {
    const { user, categories } = this.props

    if (categories.data) {
      // we need to order them
      var ordered_items = Object.entries(categories.data).sort((a, b) => {
        return a[1].order - b[1].order
      })

      for (const i in ordered_items) {
        ordered_items[i] = ordered_items[i][1]
      }

      return ordered_items.map(category => {
        const isTabDynamic = DYNAMIC[category?.characteristics?.dynamic?.toLowerCase()]
        return (
          <Tab
            activeTabStyle={{
              ...styles.activeTab,
              borderBottomColor: category.characteristics.color,
            }}
            activeTextStyle={styles.activeText}
            textStyle={styles.tabText}
            tabStyle={styles.tab}
            tabContainerStyle={{ backgroundColor: 'red' }}
            style={styles.tabContainer}
            categoryId={category.id}
            key={category.id}
            heading={
              <TabHeading
                style={{
                  ...styles.tab,
                  borderBottomColor: category.characteristics.color,
                }}>
                <Text style={styles.tabText}>{category.name}</Text>
                {this.state.active === category.id ? (
                  <View
                    style={{
                      width: 12,
                      height: 12,
                      flex: 1,
                      position: 'absolute',
                      transform: [{ rotate: '45deg' }],
                      bottom: -8,
                      backgroundColor: category.characteristics.color,
                    }}
                  />
                ) : null}
              </TabHeading>
            }>
            <View style={{ paddingTop: 7, flex: 1 }}>
              <View style={{ flex: 1 }}>
                {category.products &&
                  category.products.loading &&
                  !category.products.loadingMore &&
                  category.products.data ? (
                  <Loader size={45} />
                ) : null}

                <View>
                  <List
                    setListRef={this.setListRef}
                    refreshing={user.loading}
                    category={category}
                    onRefresh={() => this._onRefresh}
                    favorite={this.favorite}
                    getCategoryItems={this.props.getCategoryItems}
                    _handleLoadMore={this._handleLoadMore}
                    showPopUp={this.showPopUp}
                    listHeaderComponent={isTabDynamic && <View style={styles.dynamicHeader} >
                      <Text style={styles.dynamicHeaderText}>
                        {
                          __translate('reward_loyalty_choose_partner')
                        }
                      </Text>
                    </View>}
                  />
                </View>
              </View>
            </View>
          </Tab>
        )
      })
    }
  };

  onClose = async () => {
    this.props.navigation.navigate('Dashboard')
  }



  render() {
    const { user, categories, favorites, popUp, dynamicCoupons, activeCoupon, cancelledCoupon } = this.props
    const theme = localStorage.getItem('@display_option')
    const getFavoriteProducts = () => {
      if (
        (favorites.loading && !favorites.data) ||
        !categories.data ||
        (!categories.data.all || !categories.data.all.products || categories.data.all.products.loading)
      ) {
        return (
          <>
            <CardPlaceholder />
            <CardPlaceholder />
            <CardPlaceholder />
            <CardPlaceholder />
          </>
        )
      }

      if (favorites.error) {
        return (
          <Error
            message={favorites.error}
            retry={() => this._retryFavorites()}
          />
        )
      }

      if (!favorites.loading && favorites.data && favorites.data.length == 0) {
        return (
          <>
            <View
              style={{
                borderRadius: 55,
                overflow: 'hidden',
                justifyContent: 'center',
                backgroundColor: theme === 'dark' ? '#25282B' : '#FAFAFA',
                marginBottom: -50,
                padding: 5,
                width: 110,
                height: 110,
                zIndex: 1,
                marginTop: 50,
                elevation: 5,
                alignSelf: 'center',
              }}>
              <FastImage
                source={{ uri: __image('reward_loyalty_no_fav_coupons') }}
                style={{ height: 100, width: 100 }}
              />
            </View>
            <View
              style={{
                backgroundColor: '#fff',
                width: '70%',
                marginLeft: '15%',
                padding: 40,
                zIndex: 0,
                borderTopLeftRadius: 7,
                borderTopRightRadius: 7,
                paddingTop: 70,
              }}>
              <Text style={{ textAlign: 'center', color: '#333' }}>
                {' '}
                {__translate('reward_loyalty_no_fav_coupons')}{' '}
              </Text>
            </View>
          </>
        )
      }

      if (favorites.data) {
        return (
          <FlatList
            data={favorites.data || []}
            keyExtractor={item => item.id.toString()}
            renderItem={({ item }) => {
              const isDynamic = DYNAMIC[item?.characteristics?.dynamic?.toLowerCase()]
              return (
                <Product
                  favorite={this.favorite}
                  handleClick={() => this.showPopUp(item, isDynamic)}
                  data={item}
                />
              )
            }}
            bounces={false}
            onEndReached={() => this._handleLoadMoreFavorites(favorites.data)}
            initialNumToRender={3}
            onEndReachedThreshold={0.5}
            ListFooterComponent={
              favorites.data && favorites.loading ? <Loader size={45} /> : null
            }
          />
        )
      }
    }

    const getFavorites = () => {
      return (
        <Tab
          activeTabStyle={styles.activeTab}
          activeTextStyle={styles.activeText}
          textStyle={styles.tabText}
          tabStyle={styles.tab}
          style={styles.tabContainer}
          favourite
          partners={false}
          heading={
            <TabHeading style={styles.tab}>
              <Text style={styles.tabText}>
                {__translate('reward_loyalty_product_catalog_fav')}
              </Text>
              {this.state.active === 'favourite' ? (
                <View
                  style={{
                    width: 12,
                    height: 12,
                    flex: 1,
                    position: 'absolute',
                    transform: [{ rotate: '45deg' }],
                    bottom: -8,
                    backgroundColor: '#333',
                  }}
                />
              ) : null}
            </TabHeading>
          }>
          <View
            style={{ flex: 1, minHeight: '100%' }}
            refreshControl={
              <RefreshControl
                refreshing={user.loading}
                onRefresh={this._onRefresh}
              />
            }>
            {getFavoriteProducts()}
          </View>
        </Tab>
      )
    }

    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
      return (
        <SafeAreaProvider style={{ flex: 1 }}>
          <VFScreen
            headerStyle={{ backgroundColor: theme === 'dark' ? '#25282B' : '#FAFAFA', }}
            containerStyle={{ backgroundColor: theme === 'dark' ? '#25282B' : '#FAFAFA', }}
            contentContainerStyle={{ height: '94%' }}
            title={__translate('reward_landing_title')}
            onClose={this.onClose}
            // titleStyle={{ color: '#333' }}
            rightIcon={Images.close}
            withStatusBar={false}
          >
            <View style={{ flex: 1, paddingHorizontal: '10%' }}>
              <View>
                <Header
                  retry={() => this._retryUser()}
                  user={user}
                  navigation={this.props.navigation}
                />
              </View>
              {categories.loading && !categories.data ? (
                <Loader size={40} />
              ) : categories.error ? (
                <Error
                  message={categories.error}
                  retry={() => this._retryCategories()}
                />
              ) : (
                <>
                  <Tabs
                    prerenderingSiblingsNumber={1}
                    ref={c => {
                      this.tabs = c
                      return
                    }}
                    style={styles.tabContainer}
                    onChangeTab={item => this.handleTabChange(item)}
                    renderTabBar={() => (
                      <ScrollableTab
                        style={{ backgroundColor: '#fff' }}
                        underlineStyle={styles.tabUnderline}
                      />
                    )}
                    contentProps={{
                      style: {
                        "overflow-x": 'hidden'
                      }
                    }}
                  >
                    {getFavorites()}
                    {this.getTabs()}
                  </Tabs>
                  {this.state.popUp.display && (
                    <Redeem
                      closePopUp={reload => this.closePopUp(reload)}
                      popUpModal={this.state.popUp}
                      getUserProfile={() => this.props.getUserProfile()}
                    />
                  )}
                </>
              )}
              {categories.data && (
                <Filters
                  changePage={page => this.changePage(page)}
                  getAllItems={filters => this.getAllItems(filters)}
                />
              )}
            </View>
          </VFScreen>
        </SafeAreaProvider>
      )
    }

    return (
      <SafeAreaProvider style={{ flex: 1 }}>
        <VFScreen
          headerStyle={{ backgroundColor: theme === 'dark' ? '#25282B' : '#FAFAFA', }}
          containerStyle={{ backgroundColor: theme === 'dark' ? '#25282B' : '#FAFAFA', }}
          contentContainerStyle={{ height: '94%' }}
          title={__translate('reward_landing_title')}
          onClose={this.onClose}
          rightIcon={Images.close}
          withStatusBar={false}
        >
          <View style={{ flex: 1 }}>
            <View>
              <Header
                retry={() => this._retryUser()}
                user={user}
                navigation={this.props.navigation}
              />
            </View>
            {categories.loading && !categories.data ? (
              <Loader size={40} />
            ) : categories.error ? (
              <Error
                message={categories.error}
                retry={() => this._retryCategories()}
              />
            ) : (
              <>
                <Tabs
                  prerenderingSiblingsNumber={1}
                  ref={c => {
                    this.tabs = c
                    return
                  }}
                  style={styles.tabContainer}
                  onChangeTab={item => this.handleTabChange(item)}
                  renderTabBar={() => (
                    <ScrollableTab
                      style={{ backgroundColor: '#fff' }}
                      underlineStyle={styles.tabUnderline}
                    />
                  )}
                  contentProps={{
                    style: {
                      "overflow-x": 'hidden'
                    }
                  }}
                >
                  {getFavorites()}
                  {this.getTabs()}
                </Tabs>
                {this.state.popUp.display && (
                  <Redeem
                    closePopUp={reload => this.closePopUp(reload)}
                    popUpModal={this.state.popUp}
                    getUserProfile={() => this.props.getUserProfile()}
                    userType={this.state.userType}
                  />
                )}
              </>
            )}
            {categories.data && (
              <Filters
                changePage={page => this.changePage(page)}
                getAllItems={filters => this.getAllItems(filters)}
                theme={getThemeColors(theme === 'dark' ? 'dark' : 'light')}
              />
            )}
          </View>
        </VFScreen>
      </SafeAreaProvider>
    )
  }
}

const mapStateToProps = state => {
  const {
    user,
    categories,
    favorites,
    popUp,
    plainProducts,
    redeem,
    preferences,
    dynamicCoupons,
    activeCoupon,
    cancelledCoupon,
  } = state
  return {
    user,
    categories,
    favorites,
    popUp,
    plainProducts,
    redeem,
    preferences,
    dynamicCoupons,
    activeCoupon,
    cancelledCoupon,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCategories: getAllItems => {
      dispatch(actions.getCategories(getAllItems))
    },
    getCategoryItems: (categoryId, page, isFirstPage = false) => {
      dispatch(actions.getCategoryItems(categoryId, page, isFirstPage))
    },
    getFavorites: page => {
      dispatch(actions.getFavorites(page))
    },
    getAllItems: filters => {
      dispatch(actions.getAllItems(filters))
    },
    favorite: product => {
      dispatch(actions.favorite(product))
    },
    cancelCoupon: (couponId, data, onSuccess, onError) => {
      dispatch(actions.cancelCoupon(couponId, data, onSuccess, onError))
    },
    generateCoupon: (product, onSuccess, onError) => {
      dispatch(actions.generateCoupon(product, onSuccess, onError))
    },
    getActiveCoupon: (id, onSuccess, onError) => {
      dispatch(actions.getActiveCoupon(id, onSuccess, onError))
    },
    getUserProfile: () => {
      dispatch(profileActions.getUserProfile())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Catalog)
