import { Dimensions, Platform, StyleSheet } from 'react-native'
import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  container: { marginBottom: 20 },
  titleStyle: theme => {
    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
      return ({
        fontSize: 28,
        lineHeight: 32,
        fontWeight: 'bold',
        fontFamily: Fonts.type.VFFont,
        color: '#333333',
        marginBottom: 16
      })
    } else {
      return ({
        fontSize: 16.7,
        lineHeight: 23,
        fontWeight: 'normal',
        fontFamily: Fonts.type.VFFont,
        color: theme.colors.headerColor
      })
    }
  },
  subtitle: theme => {
    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
      return ({
        fontSize: 18,
        lineHeight: 20.66,
        fontWeight: 400,
        marginBottom: 32,
        color: '#666666'
      })
    } else {
      return ({
        fontSize: 25.1,
        lineHeight: 31.4,
        fontWeight: 'bold',
        marginTop: 8,
        color: theme.colors.headerColor
      })
    }
  },
  rightText: theme => ({
    paddingTop: 0,
    lineHeight: 16,
    color: theme.colors.seeBillColor
  }),
  orderDate: theme => ({
    marginBottom: 16.7,
    fontSize: 16.7,
    letterSpacing: 0,
    color: theme.colors.headerColor
  }),
  QAMtitle: {
    fontSize: 16.7,
    letterSpacing: -0.29,
    alignSelf: 'center'
  },
  QAMContainerStyle: { paddingHorizontal: 0 },
})
