import { StyleSheet, Platform, Dimensions } from 'react-native'

import { Fonts, Colors, Metrics } from '@vfgroup-oneplatform/foundation/Components/Themes'

const { width: screenWidth } = Dimensions.get('window')


const heightToWidthRatio = 0.479

const mainCardWidth = screenWidth - Metrics.mainContentPadding * 2

export default StyleSheet.create({
  container: {
    padding: 10,
    flex: 1,
    overflow: 'hidden',
  },
  twoCardContainer: theme => ({
    backgroundColor: 'transparent',
    flex: 1,
    // margin: -8,
    // padding: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    gap: 20
  }),
  halfContainer: theme => ({
    width: mainCardWidth * heightToWidthRatio,
    height: mainCardWidth * heightToWidthRatio * 0.225,
    backgroundColor: theme.colors.cardBackgroundColor,
    elevation: 5,
    shadowColor: 'rgba(0, 0, 0, 0.16)',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowRadius: 4,
    shadowOpacity: 1,
    borderRadius: 6,
    padding: 12,
    justifyContent: 'space-between',
    flexDirection: 'column',
    flex: 1,
  }),
  iconAndTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    marginRight: 5,
    marginLeft: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 0 : Platform.OS === 'ios' ? 0 : 5,
    width: 25,
    height: 25,
  },
  title: {
    ...Fonts.type.bold,
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 20 : Platform.OS === 'ios' ? 16.6 : 16
  },
  titleNonVf: {
    ...Fonts.type.bold,
    color: "#FFF",
    fontSize: Platform.OS === 'ios' ? 16.6 : 16
  },
  subtitle: theme => ({
    marginLeft: 14,
    fontSize: screenWidth * 0.04 - 1,
    color: theme.colors.textColorThree
  }),
  noBillimage: {
    alignSelf: 'center',
    height: 50,
    width: 50,
    marginTop: -3
  },
  subtitle: theme => ({
    marginLeft: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 0 : 14,
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 32 : screenWidth * 0.04 - 1,
    marginTop: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 24 : null,
    color: theme.colors.textColorThree
  }),
  fixSubtitle: theme => ({
    marginLeft: 0,
    fontSize: screenWidth * 0.04 - 1,
    color: theme.colors.textColorThree
  }),
  noBillsSubtitle: theme => ({
    fontSize: screenWidth * 0.04 - 1,
    lineHeight: Platform.OS === 'ios' ? 18.7 : 18,
    color: theme.colors.textColorTwo
  }),
  notAvailableText: theme => ({
    fontSize: 16,
    lineHeight: 22,
    color: theme.colors.errorMessageColor
  }),
  nextBillContainer: theme => ({
    flex: 1,
    paddingTop: 4,
    backgroundColor: theme.colors.quickActionGradientColors.secondary
  }),
  nonVfSubTitle: {
    fontSize: screenWidth * 0.04 - 1,
    marginTop: 6,
    fontFamily: Fonts.type.VFFont,
    color: '#FFF'
  },
  billSubTitle: {
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 18 : screenWidth * 0.04,
    marginTop: 6,
    fontFamily: Fonts.type.VFFont,
  },
  billFixSubTitle: {
    fontSize: Dimensions.get('window').width > 700 ? 48 : screenWidth * 0.04 - 1,
    marginTop: 2,
    fontFamily: Fonts.type.VFFont,
  },
  billFixValue: {
    fontSize: Dimensions.get('window').width > 700 ? 48 : screenWidth * 0.04 - 1,
    marginTop: 0,
    fontFamily: Fonts.type.VFFont,
  },
  priceAndCurrencyContainer: {
    justifyContent: 'flex-end',
    marginBottom: 5,
    marginTop: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? '20px' : null,
    flex: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? null : 1,
    paddingBottom: screenWidth * 0.02
  },
  priceAndCurrencyTextContainer: {
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  billPrice: {
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 64 : screenWidth * 0.1,
    alignSelf: 'baseline'
  },
  billCurrency: {
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 64 : screenWidth * 0.07,
    paddingBottom: Platform.OS === 'android' ? 4 : 0
  },
  billDueDate: theme => ({
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 18 : screenWidth * 0.04 - 2,
    color: theme.colors.textColorTwo,
    marginBottom: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 10 : null,
  }),
  topUpContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 0 : 12,
    marginLeft: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 0 : 16,
  },
  priceContnair: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginTop: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 8 : null
  },
  fixTopUpContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5
  },
  price: {
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 52 : screenWidth * 0.09,
    marginLeft: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 0 : 14
  },
  topUpCurrency: {
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 52 : screenWidth * 0.07,
    marginBottom: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? null : 4,
    marginLeft: 2
  },
  updatedMessage: theme => ({
    color: theme.colors.textColorTwo,
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 18 : 14,
    lineHeight: 22,
    marginLeft: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 0 : 14,
    marginTop: Platform.OS === 'ios' ? 8 : 4
  }),
  noBillsContainer: {
    height: '75%',
    marginBottom: 8,
    marginTop: 4,
    justifyContent: 'space-between'
  },
  topUpPriceOuterContainer: {
    height: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? null : '60%',
    flex: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 1 : null,
    paddingBottom: 10,
    justifyContent: 'flex-end'
  },
  fixTopUpPriceOuterContainer: {
    justifyContent: 'flex-end'
  },
  topUpOuterContainer: {
    height: '100%',
    padding: Dimensions.get('window').width > 700 ? 20 : 0
  },
  section: {
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 18 : screenWidth * 0.04,
    marginTop: 6,
  },
  smallMarginTop: {
    margiTop: 10
  },
  expensesDetails: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  smallMarginBottom: {
    marginBottom: 10
  },
  flexRow: {
    flexDirection: 'row'
  },
  horizontalLine: {
    height: 1,
    width: '100%',
    marginBottom: '20px',
    backgroundColor: '#F4F4F4'
  },
  priceContainer: {
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  nonVfContainer: {
    padding: 10,
    flex: 1,
    justifyContent: 'space-between'
  }
})