import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  profileContainer: {
    padding: 15,
    paddingBottom: 5,
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  headerItems: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 1,
    alignItems: 'center',
  },
  profileItems: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  walletImage: {
    width: 35,
    height: 35,
  },
  points: {
    marginLeft: 5,
    marginRight: 5,
    fontSize: 14,
    fontWeight: 'bold',
  },
  goIcon: {
    width: 15,
    height: 15,
  },
  infoIcon: {
    width: 30,
    height: 30,
  },
})
