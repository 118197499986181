import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'
import moment from 'moment'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import ReduxCache from '../../Utils/ReduxCache'


import { API_URLS } from '../Config'

const mapError = (error) => {
  const payload = error?.response?.data?.message || error?.message
  return payload
}

const dataResponseMapper = (response) => {
  const data = response?.data

  const profileData = data.find(el => el?.recommendationType === 'customer_pega_profile')

  //get affiliate code
  const code = profileData?.recommendationItem[0].product?.productCharacteristic?.find(el => {
    return el?.name === 'affiliate_code'
  })?.value

  const offerData = data?.find(el => el.recommendationType === 'pega_offer')

  //get title and description
  const title = offerData?.name
  const description = offerData?.description


  return { title, description, code }
}

const historyResponseMapper = (response) => {

  const data = response?.data

  if (data.length === 0) {
    return data
  }

  //get total number of referals
  const totalReferals = data[0]?.analyticCharacteristic?.find(el => {
    return el?.id === 'total_trans'
  })?.value

  //get history data
  const historyData = []

  data.map(item => {
    const number = item?.relatedParty?.find(el => {
      return el?.role === 'beneficiary'
    })?.id

    const date = moment(item?.eventTime).format('DD/MM/YYYY')

    historyData.push({ number, date })
  })


  return { totalReferals, historyData }
}

async function getOfferData(
  customHeaders = {},
  utilities = {},
) {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
  })

  const {
    errorMapper,
    onError,
    onStart,
    onSuccess,
  } = _utilities

  onStart()

  const params = {
    'relatedParty.id': ReduxCache.getMsisdn(),
    'type': 'affiliate',
  }

  try {
    const response = await axios.get(API_URLS.pega, {
      headers: customHeaders,
      params,
    })
    const _response = dataResponseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

async function getHistory(
  customHeaders = {},
  utilities = {},
) {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
  })

  const {
    errorMapper,
    onError,
    onStart,
    onSuccess,
  } = _utilities

  onStart()

  const params = {
    'relatedParty.id': ReduxCache.getMsisdn(),
    'offset': 100,
    'limit': 0,
  }

  try {
    const response = await axios.get(API_URLS.affiliate.getHistory, {
      headers: customHeaders,
      params,
    })
    const _response = historyResponseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}



export { getOfferData, getHistory }
