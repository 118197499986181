import React, { useState } from 'react'
import {
  View,
  ScrollView,
  TouchableOpacity,
  ImageBackground,
  SafeAreaView,
  Dimensions
} from 'react-native'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'
import {
  VFText,
  VFScreen,
  Icon
} from '@vfgroup-oneplatform/foundation/Components'
import PropTypes from 'prop-types'

import { Images } from '../../Group/Themes'

import styles from './DiscoverScreenSeeMore.Styles'

const { width: SCREEN_WIDTH } = Dimensions.get('screen')

const DiscoverScreenSeeMore = ({ navigation, route }) => {
  const { bucket } = route.params
  const theme = useTheme()
  const [imagesStuff, setImageStuff] = useState(
    bucket?.map(item => {
      return {
        url: item?.extraInfo?.secondIcon,
        loading: false
      }
    })
  )

  const onClose = () => {
    navigation.goBack()
  }
  const handlePress = card => {
    if (
      card.extraInfo.key === 'purchase_fix_discover' ||
      card.extraInfo.key === 'recharge_my'
    ) {
      navigation.goBack()
    }
    card.extraInfo?.onPress(card.extraInfo)
  }

  return (
    <VFScreen
      title={'dashboard_discovery_screen_title'}
      onClose={onClose}
      withHeaderSpace
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={[styles.scrollViewContentContainer]}
      >
        <SafeAreaView>
          <VFText
            i18nKey="dashboard_discovery_promotion"
            style={styles.screenTitle}
          />
          <View style={styles.container}>
            {bucket.map((card, index) => {
              if (card.extraInfo.key === 'CocaCola') {
                return null
              }
              return (
                <View style={styles.card}>
                  <TouchableOpacity
                    onPress={() => handlePress(card)}
                    accessible={false}
                    accessibilityLabel="DBeditorialCard"
                  >
                    <ImageBackground
                      source={{ uri: card.extraInfo.secondIcon }}
                      imageStyle={styles.imageBorderRadius}
                      height={220}
                      resizeMode="cover"
                      accessible
                      onLoadStart={() => {
                        const tempImages = imagesStuff?.map((el, ind) => {
                          if (ind === index) {
                            el.loading = true
                          }
                          return el
                        })
                        // setImageStuff(tempImages)
                      }}
                      onLoadEnd={() => {
                        const tempImages = imagesStuff?.map((el, ind) => {
                          if (ind === index) {
                            el.loading = false
                          }
                          return el
                        })
                        setTimeout(() => {
                          // setImageStuff(tempImages)
                        }, 500)
                      }}
                    >
                      {!imagesStuff[index]?.loading ? (
                        <View style={styles.textContainer}>
                          <View>
                            <VFText
                              style={styles.title}
                              i18nKey={card.extraInfo.title}
                            />
                            <VFText
                              style={styles.subTitle}
                              i18nKey={card.extraInfo.subTitle}
                            />
                          </View>
                          <View style={styles.btn}>
                            <VFText
                              style={styles.text}
                              i18nKey={card.extraInfo.buttonText}
                              accessibilityLabel="DBtopupNow"
                            />
                            <Icon
                              name={Images.icChevronRightWhite}
                              size={14}
                              type="image"
                            />
                          </View>
                        </View>
                      ) : <></>}
                    </ImageBackground>
                  </TouchableOpacity>
                </View>
              )
            })}
          </View>
        </SafeAreaView>
      </ScrollView>
    </VFScreen>
  )
}
DiscoverScreenSeeMore.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
}

export default DiscoverScreenSeeMore
