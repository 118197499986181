import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import { normalizeObject } from '../../Utils'

import ReduxCache from '../../Utils/ReduxCache'

import { API_URLS } from '../Config'

const statusObject = {
    true: 'ON',
    false: 'OFF'
}

const language = ReduxCache.getLanguage()

const headers = {
    'X-Source-CountryCode': 'al',
    'X-Source-Operator': 'myvodafone',
    'X-Source-System': 'selfcare',
    'x-application-version': '5.2.0',
    'Accept-Language': language,
}

const mapPreferences = (response) => {
    const parts = response?.parts
    const preferences = parts?.partyPreference?.partyPreference || []
    const permissions = parts?.partyPermission?.permission?.partyPermission || []

    const mappedPreferences = preferences?.map((preference) => ({
        key: preference?.id?.[0]?.value,
        initialValue: preference?.status === 'ON',
    }))

    const mappedPermissions = permissions?.map((permission) => ({
        key: permission?.id?.[0]?.value,
        initialValue: permission?.status === 'ON',
    }))

    const mappedItems = [...mappedPermissions, ...mappedPreferences].map((o) => Object.assign({ [o.key]: o.initialValue }, {}))
    const items = Object.assign(...mappedItems)

    return { response, items }
}

const mapUpdateResponse = (response) => {
    const parts = response?.parts
    const preferences = parts?.partyPreference?.partyPreference || []

    const mappedPreferences = preferences?.map((preference) => Object.assign({ [preference?.id?.[0]?.value]: preference?.status === 'ON' }, {}))

    const userPreferences = Object.assign(...mappedPreferences)

    return userPreferences
}

async function getPreferences(
    customHeaders = {},
    utilities = {}
) {
    const _utilities = retrieveUtilities(utilities, {
        responseMapper: response => mapPreferences(response)
    })

    const {
        errorMapper,
        onError,
        onStart,
        onSuccess,
        responseMapper
    } = _utilities


    onStart()

    const params = {
        count: 0,
        offset: 0,
        partyId: ReduxCache.getMsisdn(),
        channelId: 'selfcare'
    }
    try {
        const response = await axios.get(API_URLS.customerPrivacyProfile, {
            headers: {
                ...headers,
                ...customHeaders,
            },
            params,
        })
        const _response = responseMapper(response?.data?.[0])

        onSuccess(_response)
        return _response
    } catch (error) {
        const _error = errorMapper(error)
        if (_isFunction(onError)) {
            onError(_error)
        } else {
            throw _error
        }
    }
}

async function updatePreferences(
    data,
    items,
    customHeaders = {},
    utilities = {}
) {
    const _utilities = retrieveUtilities(utilities, {
        responseMapper: response => mapUpdateResponse(response)
    })

    const {
        errorMapper,
        onError,
        onStart,
        onSuccess,
        responseMapper,
    } = _utilities

    const parts = data?.parts
    const requestId = data?.id?.[0]?.value
    const preferences = parts?.partyPreference?.partyPreference || []
    const permissions = parts?.partyPermission?.permission?.partyPermission || []
    const newPermissions = permissions?.map((item) => ({
        ...item,
        status: statusObject[items?.[item?.id?.[0]?.value]],
    }))
    const newPreferences = preferences?.map((item) => ({
        ...item,
        status: statusObject[items?.[item?.id?.[0]?.value]],
        value: statusObject[items?.[item?.id?.[0]?.value]]
    }))

    let newData = {
        ...data,
        parts: {
            ...data?.parts,
            partyPreference: {
                partyPreference: [...newPreferences],
            },
            partyPermission: {
                permission: {
                    partyPermission: [...newPermissions],
                }
            }
        },
    }

    newData = normalizeObject(newData)

    onStart()

    try {
        const response = await axios.patch(`${API_URLS.customerPrivacyProfile}/${requestId}`, newData, {
            headers: {
                ...headers,
                ...customHeaders,
            },
        })
        const _response = responseMapper(response?.data)

        onSuccess(_response)
        return _response
    } catch (error) {
        const _error = errorMapper(error)
        if (_isFunction(onError)) {
            onError(_error)
        } else {
            throw _error
        }
    }
}

export { getPreferences, updatePreferences }
