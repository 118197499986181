import React, { Component } from 'react'
import { View, Animated, TouchableOpacity, Dimensions } from 'react-native'
import PropTypes from 'prop-types'
import { isEqual as _isEqual } from 'lodash'
import PagerView from 'react-native-pager-view'
import { Icon, Pagination, VFText } from '@vfgroup-oneplatform/foundation/Components'

import {
    withTheme,
    LightThemeColors
} from '@vfgroup-oneplatform/foundation/Components/Themes'

import DiscoverFixedCard from '../DiscoverFixedCard'

import { getThemeImages } from '../../Themes'

import styles from './DiscoverCarousel.Styles'
import NavigationService from '../../../Navigation/NavigationService'

class DiscoverCarousel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeDotCardIndex: 0,
            isPrevDisabled: true,
            isNextDisabled: true,
            isFirstChecked: false,
            width: Dimensions.get('screen').width,
            showMore: false,
            bottom: 0
        }
        const { theme } = props
        this.Images = getThemeImages(theme.name)
        this.ResponsiveAnimated = React.createRef()
        this.bucketItems = React.createRef([])
        this.didFirstRender = React.createRef()
        this.scrollRef = React.createRef(0)
        this.scrollComponent = React.createRef()

    }

    componentDidMount() {
        if (!this.didFirstRender.current) {
            this.ResponsiveAnimated.current = Animated.createAnimatedComponent(PagerView)
            this.didFirstRender.current = true
        }
        this.bucketItems.current = this.props.bucket

    }

    onPageScroll = (e) => {
        this.setState({ activeDotCardIndex: e })
    }

    componentDidUpdate(prevProps) {
        this.bucketItems.current = this.props.bucket
        const { theme } = this.props
        if (this.props.bucket?.length !== prevProps?.bucket?.length) {
            this.ResponsiveAnimated.current = Animated.createAnimatedComponent(PagerView)
        }
        if (!_isEqual(prevProps.theme, this.props.theme)) {
            this.Images = getThemeImages(theme.name)
        }
        if (Dimensions.get('window').width > 700) {
            if (this.scrollComponent.current.scrollWidth - this.scrollComponent.current.clientWidth > 0 && this.state.isNextDisabled && !this.state.isFirstChecked) {
                this.setState({
                    isNextDisabled: false,
                    isFirstChecked: true,
                    bottom: document.getElementById('basicContainerId')?.getBoundingClientRect().height - document.getElementById('carouselSwipeContaner')?.getBoundingClientRect().height < 0
                        ?
                        document.getElementById('basicContainerId')?.getBoundingClientRect().height - document.getElementById('carouselSwipeContaner')?.getBoundingClientRect().height
                        :
                        this.props.leftSideLength === undefined ? '64px' : 0
                })
            }
        }

    }

    renderDiscoverCard = ({ item }) => <DiscoverFixedCard theme={this.props.theme} {...item} />

    scrollLeft = () => {
        if (this.state.isPrevDisabled) return
        if (this.scrollComponent.current?.scrollLeft != 0) {
            this.scrollRef.current -= 455
            this.scrollComponent.current.scroll({
                left: this.scrollRef.current,
                behavior: 'smooth'
            })

            const pointToPercent = 1 / this.bucketItems.current?.length
            const currentPercent = Math.min(1, this.scrollRef.current / (this.scrollComponent.current.scrollWidth - this.scrollComponent.current.clientWidth))

            let activeDot = Math.floor(currentPercent / pointToPercent)

            this.setState({
                activeDotCardIndex: activeDot,
                isNextDisabled: false,
                isPrevDisabled: currentPercent == 0
            })

        }
    }

    scrollRight = () => {
        if (this.state.isNextDisabled) return
        if (this.scrollComponent.current.scrollLeft + this.scrollComponent.current.clientWidth != this.scrollComponent.current.scrollWidth) {
            this.scrollRef.current += 455
            this.scrollComponent.current.scroll({
                left: this.scrollRef.current,
                behavior: 'smooth'
            })

            const pointToPercent = 1 / this.bucketItems.current?.length
            const currentPercent = Math.min(1, this.scrollRef.current / (this.scrollComponent.current.scrollWidth - this.scrollComponent.current.clientWidth))


            let activeDot = Math.floor(currentPercent / pointToPercent)

            if (activeDot > this.bucketItems.current?.length - 1) {
                activeDot = this.bucketItems.current?.length - 1
            }



            this.setState({
                activeDotCardIndex: activeDot,
                isPrevDisabled: false,
                isNextDisabled: currentPercent == 1
            })
        }
    }

    onSeeMorePress = () => this.setState({ showMore: !this.state.showMore })

    seeDetails = () => NavigationService.navigate('DiscoverScreenSeeMore', { bucket: this.props.bucket })

    renderDiscoverCard = ({ item }) => <DiscoverFixedCard {...item} />



    render() {
        const {
            bucket = [],
            theme
        } = this.props

        if (Dimensions.get('window').width > 700) {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 29
                    }}
                >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <VFText
                            style={{ fontSize: 28, fontWeight: 700 }}
                            i18nKey='dashboard_discovery_label' />
                        <div>
                            <div
                                onClick={this.scrollLeft}
                                style={{
                                    width: 28,
                                    height: 28,
                                    borderRadius: 7,
                                    border: 'solid 1px',
                                    marginRight: 8,
                                    display: 'inline-block',
                                    borderColor: 'transparent',
                                    'box-shadow': 'rgba(0, 0, 0, 0.16) 0px 2px 8px',
                                    backgroundColor: this.state.isPrevDisabled ? '#e2e2e2' : theme.colors.cardBackgroundColor,
                                    cursor: 'pointer',

                                }}
                            >
                                <Icon
                                    name={this.Images.ic_chevron_left_black_white}
                                    size={24}
                                    style={{
                                        margin: 'auto',
                                        marginTop: 2
                                    }}
                                />
                            </div>
                            <div
                                onClick={this.scrollRight}
                                style={{
                                    width: 28,
                                    height: 28,
                                    borderRadius: 7,
                                    border: 'solid 1px',
                                    display: 'inline-block',
                                    borderColor: 'transparent',
                                    'box-shadow': 'rgba(0, 0, 0, 0.16) 0px 2px 8px',
                                    backgroundColor: this.state.isNextDisabled ? '#e2e2e2' : theme.colors.cardBackgroundColor,
                                    cursor: 'pointer'
                                }}
                            >
                                <Icon
                                    name={this.Images.ic_chevron_right_black_white}
                                    size={24}
                                    style={{
                                        margin: 'auto',
                                        marginTop: 2
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        ref={this.scrollComponent}
                        id='carouselSwipeContaner'
                        style={{
                            display: 'flex',
                            gap: 16,
                            flexDirection: 'row',
                            flex: 1,
                            overflow: 'hidden',
                            // marginTop: 64,
                            padding: 0,
                            minHeight: document.getElementById('tilesContainer')?.clientHeight ? document.getElementById('tilesContainer')?.clientHeight : '100%'
                        }}
                    >
                        {this.bucketItems.current?.map((page, i) => {
                            if (page.extraInfo.key === 'CocaCola') return null
                            return (
                                <View key={i} style={styles.cardCarouselStyle}>
                                    {this.renderDiscoverCard({ item: page })}
                                </View>
                            )
                        })}
                    </div>
                    {bucket?.length > 1 && <Pagination
                        activeColor={theme.colors.secondryColor}
                        inactiveColor={theme.colors.fillColor}
                        inactiveOpacity={1}
                        numberOfDots={bucket.length}
                        activeDotIndex={this.state.activeDotCardIndex}
                        containerStyle={[styles.paginationStyle, {
                            bottom: '-64px'

                        }]}
                    />}
                </div>
            )
        }

        return (
            <View style={styles.container}>
                <View style={styles.discoverContainer}>
                    {bucket.slice(0, this.state.showMore ? bucket.length : 4).map((page, index) => {
                        if (page.extraInfo.key === 'CocaCola') return null
                        return (
                            <View style={styles.discoverCard(this.state.width, bucket.length, index)}>
                                {this.renderDiscoverCard({ item: page })}
                            </View>
                        )
                    })}
                </View>
                {bucket.length >= 4 && (
                    <TouchableOpacity
                        activeOpacity={0.1}
                        onPress={this.onSeeMorePress}
                        style={styles.seeMoreButton}
                    >
                        <VFText
                            i18nKey={this.state.showMore ? 'dashboard_discovery_see_less' : 'dashboard_discovery_see_more'}
                            style={styles.seeMore(theme)}
                        />
                    </TouchableOpacity>
                )}

                {bucket.length > 4 && (
                    <TouchableOpacity
                        activeOpacity={0.1}
                        onPress={this.seeDetails}
                        style={styles.detailsButton}
                    >
                        <VFText
                            i18nKey={'dashboard_discovery_see_details'}
                            style={styles.seeMore(theme)}
                        />
                    </TouchableOpacity>
                )}
            </View>
        )

        // return (
        //     <>
        //         {this.ResponsiveAnimated.current && <this.ResponsiveAnimated.current
        //             style={styles.cardStyle}
        //             initialPage={0}
        //             onPageSelected={this.onPageScroll}
        //             orientation="horizontal"
        //             showPageIndicator={false}
        //             pageMargin={20}
        //         >
        //             {this.bucketItems.current.map((page, i) => {
        //                 return (
        //                     <View key={i} style={styles.cardCarouselStyle}>
        //                         {this.renderDiscoverCard({ item: page })}
        //                     </View>
        //                 )
        //             })}
        //         </this.ResponsiveAnimated.current>}
        //         {bucket?.length > 1 && <Pagination
        //             activeColor={theme.colors.secondryColor}
        //             inactiveColor={theme.colors.fillColor}
        //             inactiveOpacity={1}
        //             numberOfDots={bucket.length}
        //             activeDotIndex={this.state.activeDotCardIndex}
        //             containerStyle={styles.paginationStyle}
        //         />}
        //     </>
        // )
    }
}

DiscoverCarousel.defaultProps = {
    theme: LightThemeColors,
    extraInfo: {}
}

DiscoverCarousel.propTypes = {
    extraInfo: PropTypes.objectOf({
        bucket: PropTypes.array.isRequired
    }).isRequired,
    theme: PropTypes.objectOf({
        name: PropTypes.string,
        isDark: PropTypes.bool,
        colors: PropTypes.object
    })
}

export default withTheme(DiscoverCarousel)
