import React, { Component } from 'react'
import { View, TouchableOpacity } from 'react-native'
import Modal from 'react-native-modal'

import { styles } from './MyModal.styles'

class MyModal extends Component {
  render() {
    const { visible, header, close, children, avoidKeyboard = false } = this.props
    return (
      <Modal
        propagateSwipe={true}
        backdropOpacity={0.8}
        onSwipeComplete={() => close()}
        onBackdropPress={() => close()}
        swipeDirection="down"
        hideModalContentWhileAnimating={true}
        presentationStyle="overFullScreen"
        style={styles.modal}
        isVisible={visible}
        avoidKeyboard={avoidKeyboard}
      >
        <View style={styles.indicatorContainer}>
          <View style={styles.topIndicator} />
        </View>
        <View style={styles.main}>
          <View style={styles.header}>
            {header}
            <TouchableOpacity style={styles.close} onPress={() => close()}>
              <View style={[styles.diagonals, styles.primaryDiagonal]} />
              <View style={[styles.diagonals, styles.secondaryDiagonal]} />
            </TouchableOpacity>
          </View>
          <View style={styles.content}>{children}</View>
        </View>
      </Modal>
    )
  }
}

export default MyModal
