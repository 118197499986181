import {
  LightThemeColors,
  DarkThemeColors,
  Colors
} from '@vfgroup-oneplatform/foundation/Components/Themes'

import {
  DarkThemeImages,
  LightThemeImages
} from './Modes'

function getThemeColors(themeName) {
  switch (themeName) {
    case 'light':
      return LightThemeColors
    case 'dark':
      return {
        ...DarkThemeColors,
        colors: {
          ...DarkThemeColors.colors,
          cardBackgroundColor: Colors.thinDark,
          tintColor: Colors.thinDark,
          borderColor: Colors.charcoalGrey,
          borderColorFour: Colors.charcoalGrey,
          borderColorNine: Colors.charcoalGrey,
          quickActionGradientColors: {
            primary: [Colors.vfRed, Colors.vfRed, Colors.activeRed],
            secondary: [Colors.thinDark, Colors.thinDark],
          },
        }
      }
    default:
      return LightThemeColors
  }
}

function getThemeImages(themeName) {
  switch (themeName) {
    case 'light':
      return LightThemeImages
    case 'dark':
      return DarkThemeImages
    default:
      return LightThemeImages
  }
}

export { getThemeColors, getThemeImages }
