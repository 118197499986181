import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'
import moment from 'moment'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

// eslint-disable-next-line import/no-cycle
import ReduxCache from '../../Utils/ReduxCache'

import { API_URLS } from '../Config'

const mapError = error => {
  return error?.response?.data?.message || error?.message
}

const MSISDN = ReduxCache.getMsisdn()


const getTimeDiffFromNow = date => {
  const now = new Date()
  // If expiration date is greater than current time
  if (date > now) {
    const totalHours = moment(date).diff(moment(now), 'hours')
    // If expiration time is lower than 48H return format 'HH:mm'
    if (totalHours <= 48) {
      const toHoursAndMinutes = (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60)
        const minutes = totalMinutes % 60
        return { hours, minutes }
      }
      const totalMinutes = moment(date).diff(moment(now), 'minutes')
      const { hours, minutes } = toHoursAndMinutes(totalMinutes)
      return hours + 'h : ' + minutes + 'm'
    } else {
      // If expiration time is greater than 48H return format 'DD:HH'
      const toDaysAndHours = (totalHoursLeft) => {
        const days = Math.floor(totalHoursLeft / 24)
        const hours = totalHoursLeft % 24
        return { days, hours }
      }
      const totalHoursLeft = moment(date).diff(moment(now), 'hours')
      const { days, hours } = toDaysAndHours(totalHoursLeft)
      return days + 'd : ' + hours + 'h'
    }
  } else {
    return 'Expired'
  }
}
const storiesResponseMapper = response => {
  const { data } = response || { data: [] }

  // If there is no data then return data
  if (data.length === 0) {
    return data
  }

  const stories = []

  // Sort the data based on the sorting property found on description key. It returns as string so it should be converted to integer
  data.sort((a, b) =>
    parseInt(a.description, 10) > parseInt(b.description, 10)
      ? 1
      : parseInt(b.description, 10) > parseInt(a.description, 10)
        ? -1
        : 0
  )

  data.map((item, ind) => {
    let isPegaReccomended = false
    item.recommendationItem.map((story) => {
      if (story?.product?.productTerm[0].name.includes('pegaRecommmandation')) {
        isPegaReccomended = true
      }
    })

    const storyBubble = {
      id: item.id,
      isPegaReccomended: isPegaReccomended,
      bubbleIndex: ind,
      description: item?.name || '',
      image: item?.href || '',
      stories: [],
      seen: false,
      showCountdown: false,
      timeLeft: null,
    }

    item.recommendationItem.map((story, index) => {
      const prodChar = story?.product?.productCharacteristic || []

      const storyItem = {
        id: story.id,
        storyIndex: index,
        name: story?.product?.description || '',
        // type may be image or video
        type: prodChar[0]?.valueType,
        image: prodChar[0]?.value,
        seen: story?.state === 'done',
        showCountdown: prodChar[5]?.value || false,
        description: story?.product?.name || '',
        icon: prodChar[prodChar?.findIndex(element => element?.valueType?.includes('icon'))]?.value,
        // timeLeft: moment(story?.product?.productTerm[0]?.validFor?.endDateTime).format('yyyy dddd hh:mm'),
        timeLeft: getTimeDiffFromNow(
          new Date(story?.product?.productTerm[0]?.validFor?.endDateTime)
        ),
        CTAText: prodChar?.find(element => element?.valueType?.includes('CTAText'))?.value,
        CTAURL: prodChar?.find(element => element?.valueType?.includes('CTAUrl'))?.value,
        CTAType: prodChar?.find(element => element?.valueType?.includes('CTAType'))?.value,
        liked: false
      }

      storyBubble.stories.push(storyItem)
    })


    // When there is only one story and this story has a countdown show the story's countdown on the bubble as well
    if (storyBubble.stories.length === 1) {
      storyBubble.showCountdown = storyBubble.stories[0].showCountdown
      storyBubble.timeLeft = storyBubble.stories[0].timeLeft
    }

    // Check if all stories are seen and mark bubble as seen true
    if (
      storyBubble.stories.findIndex(story => {
        return story.seen === false
      }) === -1
    ) {
      storyBubble.seen = true
    }
    stories.push(storyBubble)
  })

  // return null
  return { stories }
}

const getStories = async (data, customHeaders = {}, utilities = {}) => {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError
  })


  const { errorMapper, onError, onStart, onSuccess } = _utilities

  const { name } = data

  const params = {
    'relatedParty.id': MSISDN,
    type: 'story',
    name: name,
    'relatedParty.role': 'subscriber',
  }

  onStart()

  try {
    const response = await axios.get(API_URLS.stories.storiesRecommandation, {
      headers: {
        'vf-project': 'digitalcontent'
      },
      params
    })
    const _response = storiesResponseMapper(response)

    onSuccess(_response)
    return _response

  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const postStoryEvents = async (_data, customHeaders = {}, utilities = {}) => {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError
  })
  const { errorMapper, onError, onStart } = _utilities

  const { storyId, event, diff, skippedTime } = _data
  onStart()
  const data = {
    correlationId: storyId,
    description: JSON.stringify(diff) || JSON.stringify(skippedTime) || 'string',
    eventId: 'string',
    eventTime: new Date().toISOString(),
    eventType: event,
    priority: 'string',
    timeOcurred: new Date().toISOString(),
    title: 'string',
    event: 'string',
    relatedParty: [
      {
        id: MSISDN,
      }
    ],
    source: {
      id: 'selfcare',
    }
  }


  try {
    await axios.post(
      API_URLS.stories.storiesEvents,
      data,
      {
        header: customHeaders,
        params: {},
      },
    )

  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

export { getStories, storiesResponseMapper, postStoryEvents }
