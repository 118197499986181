import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'
import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import { convertCity } from '../../Utils/Helpers'

import ReduxCache from '../../Utils/ReduxCache'

import { API_URLS } from '../Config'


const mapError = (error) => {
  const payload = error?.response?.data?.message || error?.message
  return payload
}


const cartResponseMapper = (res) => {

  const data = res?.data || []

  const { cartTotalPrice = [] } = data

  const totalPrice = {
    'name': cartTotalPrice[0]?.name || '',
    'value': cartTotalPrice[0]?.price?.taxIncludedAmount?.value || 0,
    'unit': cartTotalPrice[0]?.price?.taxIncludedAmount?.unit || '',
    'chargePeriod': cartTotalPrice[0]?.recurringChargePeriod?.recurringChargePeriod || ''
  }


  return totalPrice
}

const getRenewalOffers = async (
  customHeaders = {},
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

  onStart()

  const msisdn = ReduxCache.getMsisdn()

  try {
    const response = await axios.get(API_URLS.pega, {
      params: {
        'relatedParty.id': msisdn,
        'relatedParty.role': 'customer',
        'channel.id': 'selfcare',
        'type': 'renewal_fix'
      },
      headers: { ...customHeaders },
      data: null,
    })

    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}


const getOrderPrice = async (
  data,
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, errorMapper } = _utilities

  const msisdn = ReduxCache.getMsisdn()

  onStart()

  const { selectedOffer, isPurchase } = data

  let cartItem = []

  if (isPurchase) {
    const selectedOfferData = {
      'id': selectedOffer.id,
      'quantity': 1,
      'action': 'add',
      'product': {
        ...selectedOffer?.offerData?.recommendationItem?.[0].product,
        'id': selectedOffer.id,
        'description': 'product_catalogue',
        'name': selectedOffer?.title,
      },
      'productOffering': {
        'id': selectedOffer?.id,
        'name': selectedOffer?.title
      }
    }

    cartItem = [selectedOfferData]

    if (selectedOffer?.selectedTvOffer) {

      const { selectedTvOffer } = selectedOffer
      const selectedTvOfferData = {
        'id': selectedTvOffer.id,
        'quantity': 1,
        'action': 'add',
        'product': {
          ...selectedTvOffer?.offerData?.recommendationItem?.[0].product,
          'id': selectedTvOffer.id,
          'description': isPurchase ? 'product_catalogue' : 'pega_offer',
          'name': selectedTvOffer?.title,
        },
        'productOffering': {
          'id': selectedTvOffer?.id,
          'name': selectedTvOffer?.title
        }
      }

      cartItem.push(selectedTvOfferData)

      if (selectedTvOffer?.selectedAddOn) {
        const { selectedAddOn } = selectedTvOffer

        const selectedAddOnData = {
          'id': selectedAddOn.id,
          'quantity': 1,
          'action': 'add',
          'product': {
            ...selectedAddOn?.data?.product,
            'id': selectedAddOn.id,
            'description': isPurchase ? 'product_catalogue' : 'pega_offer',
            'name': selectedAddOn?.title,
          },
          'productOffering': {
            'id': selectedAddOn?.id,
            'name': selectedAddOn?.title
          }
        }
        cartItem.push(selectedAddOnData)
      }
    }

  } else {

    const offersArray = selectedOffer?.offerData?.recommendationItem.filter(item => item?.product?.productCharacteristic?.find(el => el?.name === 'SpecType')?.value === 'Service')

    offersArray?.map(item => {
      cartItem.push({
        'id': item?.id,
        'quantity': 1,
        'action': 'add',
        'product': {
          ...item?.product,
          'description': 'pega_offer',
        },
        'productOffering': {
          'id': item?.id,
          'name': item?.productOffering?.name
        }
      })
    })
  }

  const requestBody = {
    'cartItem': cartItem,
    'relatedParty': [
      {
        'id': msisdn,
        'role': 'Customer',
        '@referredType': 'Customer'
      }
    ]
  }


  try {
    const response = await axios.post(API_URLS.fix.shoppingCart, requestBody)

    const _response = cartResponseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const createOrder = async (
  data,
  customHeaders = {},
  utilities = {},
) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper } = _utilities

  onStart()

  const {
    selectedOffer,
    selectedModem,
    selectedDate,
    isPurchase,
    selectedStreet,
    selectedCity,
    selectedCountry,
  } = data

  const headers = {
    'vf-project': 'dnext',
    ...customHeaders
  }

  const city = convertCity(selectedCity).toUpperCase()

  const placeArray = [{
    'id': city,
    'name': `${selectedStreet}, ${city}, ${selectedCountry}`,
    'role': '',
  }]
  const productOrderItem = []

  if (isPurchase) {
    const offerData = {
      'id': selectedOffer.id,
      'quantity': 1,
      'action': 'add',
      'itemTotalPrice': [],
      'product': {
        ...selectedOffer?.offerData?.recommendationItem?.[0].product,
        'productRelationship': [
          selectedOffer?.modemData[selectedModem]?.modemData,
          selectedOffer?.installationFee?.installationData
        ],
        'productOffering': selectedOffer?.offerData?.recommendationItem?.[0]?.productOffering,
        'place': placeArray,
        'status': 'created'
      },
      'productOffering': {
        'id': selectedOffer.id,
        'name': selectedOffer.title,
      }
    }

    productOrderItem.push(offerData)

    if (selectedOffer?.selectedTvOffer) {

      const { selectedTvOffer } = selectedOffer

      const productRelationshipData = []

      selectedTvOffer?.tvResources?.map(item => {
        productRelationshipData.push(item?.data)
      })

      if (selectedTvOffer?.selectedAddOn) {
        const { selectedAddOn } = selectedTvOffer
        productRelationshipData.push(selectedAddOn?.data)
      }

      const tvOfferData = {
        'id': selectedTvOffer.id,
        'quantity': 1,
        'action': 'add',
        'itemTotalPrice': [],
        'product': {
          ...selectedTvOffer?.offerData?.recommendationItem?.[0].product,
          'productRelationship': productRelationshipData,
          'productOffering': selectedTvOffer?.offerData?.recommendationItem?.[0]?.productOffering,
          'status': 'created',
          'place': placeArray,
        },
        'productOffering': {
          'id': selectedTvOffer.id,
          'name': selectedTvOffer.title,
        }
      }

      productOrderItem.push(tvOfferData)

    }
  } else {
    const offersArray = selectedOffer?.offerData?.recommendationItem.filter(item => item?.product?.productCharacteristic?.find(el => el?.name === 'SpecType')?.value === 'Service')

    offersArray?.map(item => {
      productOrderItem.push({
        'id': item?.id,
        'quantity': 1,
        'action': 'add',
        'itemTotalPrice': [],
        'product': {
          ...item?.product,
          'status': 'created'
        },
        'productOffering': item?.productOffering
      })
    })
  }

  const requestData = {
    'category': selectedOffer?.recomandation_type,
    'channel': [
      {
        'id': 'selfcare'
      }
    ],
    'note': [
      {
        'id': 'installation_datetime',
        'text': `${selectedDate}T08:56:46.844Z`
      }
    ],
    'productOrderItem': productOrderItem,
    'relatedParty': [
      {
        'id': ReduxCache.getMsisdn(),
        'role': 'Customer',
        '@referredType': 'Customer'
      }
    ]
  }

  try {
    const response = await axios.post(API_URLS.fix.sendOrder, requestData, {
      headers: headers
    })
    const _response = responseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = mapError(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

export { getRenewalOffers, getOrderPrice, createOrder }
