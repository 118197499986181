import React, { useState, useRef } from 'react'
import { View, Animated, TouchableOpacity, Text, useWindowDimensions } from 'react-native'
import PropTypes from 'prop-types'
import PagerView from 'react-native-pager-view'

import { Pagination, VFText } from '@vfgroup-oneplatform/foundation/Components'

import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import { Colors, useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import CardHeader from '../Dashboard/SecondaryComponent/Components/CardHeader'

import { formatNumberNoDecimal, isAdminView } from '../../../Utils'

import styles from './LastBills.Styles'
import ReduxCache from '../../../Utils/ReduxCache'
import { useEffect } from 'react'
import moment from 'moment'


const LastBills = ({ data, onPress, billing, userType }) => {

  const [activeIndex, setActiveIndex] = useState(0)

  const activeCardPosition = new Animated.Value(0)
  const theme = useTheme()

  const onPageScroll = (e) => setActiveIndex(e)

  let formattedData = [...data]

  const prevBussinesChooser = useRef(ReduxCache.getBusinessAccount()?.showBusinessView)
  const Component = useRef()
  const ref = useRef()
  const screenWidth = useWindowDimensions().width


  if ((userType?.includes('postpaid') || isAdminView()) && parseInt(billing?.paymentInfo?.split(' ')?.[0], 10)) {
    formattedData = [
      {
        bill_amount: parseFloat(billing?.value),
        bill_currency: billing?.currency,
        billing_date: parseInt(billing?.paymentInfo?.split(' ')?.[0], 10),
        billing_month: billing?.paymentInfo?.split(' ')?.[1] || '',
      },
      ...data,
    ]
  }

  const mapStatusToColorAndText = (item) => {
    const { status, billing_full_date, billing_date, billing_month } = item
    if (status === 'settled') {
      return {
        color: Colors.true_green,
        i18nKey: 'billing_dashboard_status_paid'
      }
    } else if (status === 'sent') {
      const nowDate = moment()
      if (nowDate.diff(billing_full_date) > 0) {
        return {
          color: Colors.vfRed,
          i18nKey: 'billing_dashboard_status_overdue'
        }
      } else {
        return {
          color: Colors.tangerine,
          i18nKey: 'billing_dashboard_status_unpaid',
          placeHolders: [billing_date?.toString(), '', ContentManager.translate(billing_month)]
        }
      }
    }
  }



  const renderItem = ({ item }) => {
    return (
      <TouchableOpacity onPress={onPress} activeOpacity={1} style={styles.cardCarouselStyle}>
        <View style={styles.container}>
          <CardHeader icon="icBillReport" title="dashboard_last_bill_title" />
          <View style={styles.nextBillContainer}>
            {(item?.category === 'fix' || item?.category === 'mobile') ? <VFText
              i18nKey={`last_bill_${item?.category}_service`}
              style={styles.billSubTitle(screenWidth)}
              type="primary"
            /> : <VFText
              i18nKey={userType?.includes('postpaid') ? 'dashboard_billing_postpay_card_subtitle' : 'dashboard_billing_card_subtitle'}
              style={styles.billSubTitle(screenWidth)}
              type="primary"
            />}
            <View style={styles.priceAndCurrencyContainer(screenWidth)} allowFontScaling={false}>
              <View style={styles.priceAndCurrencyTextContainer}>
                <Text style={styles.billPrice(theme)}>
                  {item?.bill_amount ? formatNumberNoDecimal(item?.bill_amount).toString() : ' 0'}
                  <Text style={styles.billCurrency(theme, screenWidth)} allowFontScaling={false}>
                    {' ' + item?.bill_currency}
                  </Text>
                </Text>
              </View>
            </View>
            {item.status ?
              <View style={styles.statusContainer}>
                <View style={[styles.statusIndicator, { backgroundColor: mapStatusToColorAndText(item).color }]} />
                <VFText
                  {...mapStatusToColorAndText(item)}
                  style={styles.statusText(theme, screenWidth)}
                />
              </View> :
              <View style={styles.rowText}>
                <VFText
                  i18nKey="dashboard_billing_last_bills_pay_date"
                  placeHolders={[item?.billing_date?.toString()]}
                  style={styles.billDueDate(theme, screenWidth)}
                />
                <VFText
                  i18nKey={item?.billing_month}
                  style={styles.billDueDate(theme, screenWidth)}
                />
              </View>
            }
          </View>
        </View>
      </TouchableOpacity>
    )
  }


  useEffect(() => {
    Component.current = Animated.createAnimatedComponent(PagerView)
  }, [theme.name])

  return (
    <>
      {Component.current && <Component.current
        style={styles.cardStyle}
        initialPage={0}
        onPageSelected={onPageScroll}
        orientation="horizontal"
        showPageIndicator={false}
        pageMargin={0}
        onPageScroll={Animated.event(
          [
            {
              nativeEvent: {
                offset: activeCardPosition,
              },
            },
          ],
          {
            useNativeDriver: false,
          }
        )}
      >
        {formattedData.map((page) => <View style={styles.cardCarouselStyle}>
          {renderItem({ item: page })}
        </View>)}
      </Component.current>}
      {formattedData?.length > 1 && <Pagination
        activeColor={theme.colors.secondryColor}
        inactiveColor={theme.colors.fillColor}
        inactiveOpacity={1}
        numberOfDots={formattedData.length}
        activeDotIndex={activeIndex}
        containerStyle={styles.paginationStyle}
      />}
    </>
  )
}

LastBills.defaultProps = {
  data: [],
  onPress: () => { }
}

LastBills.propTypes = {
  data: PropTypes.array.isRequired,
  onPress: PropTypes.func,
  billing: PropTypes.object,
  userType: PropTypes.string
}

export default LastBills
