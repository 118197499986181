import {StyleSheet} from 'react-native'

import colors from '../../Themes/Colors'

const styles = StyleSheet.create({
  line: {
    position: 'absolute',
    top: '50%',
    width: 3,
    left: 47.5,
    height: '100%',
  },
  line_disabled_true: {
    tintColor: colors.flower_blue,
  },
  line_disabled_false: {
    tintColor: colors.flower_yellow,
  },
  noLine: {
    display: 'none',
  },
  scrollView: {
    flexGrow: 1,
    paddingBottom: 100,
  },
  rowContainer: {
    flexGrow: 1,
    paddingVertical: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rowContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heartContainer: {
    padding: 8,
    margin: 8,
    marginLeft: 24,
    width: 50,
    height: 50,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.flower_ocean,
  },
  heart_container_disabled_true: {
    backgroundColor: colors.flower_ocean,
  },
  heart_container_disabled_false: {
    shadowColor: '#390D0F',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.16,
    shadowRadius: 8,
    elevation: 8,
    backgroundColor: colors.flower_yellow,
  },
  heartImg: {
    width: 45,
    height: 45,
    resizeMode: 'contain',
    overflow: 'visible',
    shadowOffset: {width: 0, height: 6},
    shadowOpacity: 0.16,
    shadowRadius: 8,
  },
  inactiveFlower: {
    width: 85,
    height: 85,
    transform: [{translateX: 1}, {translateY: 3}],
  },
  heartImgAmount: {
    width: 70,
    height: 70,
    marginRight: 8,
    overflow: 'visible',
    justifyContent: 'center',
    alignContent: 'center',
  },
  heartImageStyles: {
    height: '170%',
    width: '170%',
    left: '-35%',
    top: '-30%',
  },
  touchable: {
    overflow: 'visible',
    padding: 16,
  },
  heartText: {
    color: colors.red,
    margin: 0,
  },

  activeDesc: {
    color: colors.spring_green,
    marginRight: 0,
  },
  activeBalance: {
    color: colors.red,
    marginBottom: 0,
  },

  inactiveDesc: {
    color: colors.anthracite,
    marginRight: 0,
  },
  inactiveBalance: {
    color: colors.anthracite,
    marginBottom: 0,
  },

  balanceDesc: {
    marginTop: 0,
    color: colors.anthracite,
  },
})

export {styles}
