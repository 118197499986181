import { Dimensions, Platform, StyleSheet } from 'react-native'
import { Colors, Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  containerStyle: theme => ({
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: theme.colors.cardBackgroundColor,
    borderRadius: 6,
    paddingHorizontal: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 20 : 12,
    paddingTop: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 20 : 15.5,
    paddingBottom: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 20 : 12,
    elevation: 2,
    shadowColor: Colors.shadow,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowRadius: 8,
    shadowOpacity: 1
  }),
  titleContainerStyle: {
    flexDirection: 'row',
    alignItems: Platform.OS == 'web' && Dimensions.get('window').width > 700 ? 'flex-start' : 'center',
    flex: Platform.OS == 'web' && Dimensions.get('window').width > 700 ? 0.4 : null,
    justifyContent: Platform.OS == 'web' && Dimensions.get('window').width > 700 ? 'flex-start' : null,
  },
  titleTextStyle: theme => ({
    marginHorizontal: 8,
    fontSize: 16.6,
    ...Fonts.type.bold,
    letterSpacing: 0,
    color: theme.colors.accentColor
  }),
  infoContainerStyle: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  remainingTextStyle: theme => ({
    fontSize: 43.7,
    fontFamily: Fonts.type.VodafoneLt,
    letterSpacing: 0,
    lineHeight: 40,
    color: theme.colors.accentColor
  }),
  remainingUnitTextStyle: theme => ({
    fontSize: 29.1,
    fontFamily: Fonts.type.VodafoneLt,
    letterSpacing: 0,
    lineHeight: 36,
    color: theme.colors.accentColor
  }),
  spacingViewStyle: {
    width: 3,
    height: 1
  },
  usageLeftTextStyle: theme => ({
    fontSize: 16.6,
    letterSpacing: 0,
    color: theme.colors.accentColor
  }),
  resetTextStyle: theme => ({
    fontSize: 14.6,
    letterSpacing: -0.24,
    color: theme.colors.textColorTwo
  }),
  footerContainer: {
    flexDirection: Platform.OS == 'web' && Dimensions.get('window').width > 700 ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: Platform.OS == 'web' && Dimensions.get('window').width > 700 ? 'flex-start' : 'center',
    flex: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 0.4 : null,
    justifyContent: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 'flex-end' : null,
  },
  offerName: theme => ({
    color: theme.colors.accentColor,
    fontSize: 26,
    fontFamily: Fonts.type.VodafoneLt,
    lineHeight: 36,
  }),
  fixTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  statusText: theme => ({
    color: theme.colors.accentColor,
    fontSize: 14,
    fontFamily: Fonts.type.VodafoneLt,
  }),
  statusIcon: color => ({
    width: 15,
    height: 15,
    backgroundColor: color,
    borderRadius: 15,
    marginRight: 10,
    marginTop: -2
  }),
  fixBottomContainer: theme => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopColor: theme.colors.borderColor,
    paddingTop: 15
  }),
  statusContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '30%',
  },
  addressText: theme => ({
    color: theme.colors.accentColor,
    fontSize: 12,
    fontFamily: Fonts.type.VodafoneLt,
    width: '70%',
    textAlign: 'right'
  }),
  container: {
    flex: 1
  },
  buttonStyle: {
    backgroundColor: Colors.red,
    padding: 8,
    borderRadius: 7,
    alignSelf: 'flex-end'
  },
  buttonText: {
    fontSize: 16,
    ...Fonts.type.bold
  },
  flex: {
    flex: 1
  }
})
