import { useEffect, useRef } from 'react'
import { SplashModes } from '@vfgroup-oneplatform/foundation/Splash'

import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'

import { getLocalKey } from '../../Services/Storage'
import launchLoginScreen from '../../Utils/LaunchLogin'

import NavigationService from '../../Navigation/NavigationService'

import { getMsisdnData } from '../../Utils/MsisdnLocalData'

import ReduxCache from '../../Utils/ReduxCache'

import { Actions as DashboardActions } from '../Dashboard/Dashboard.Actions'

import { Actions } from './LandingScreen.Actions'


const LandingScreen = ({ other, route, otherProps }) => {
    const configurationsRedux = useSelector(store => store.configurations)
    const userType = ReduxCache.getUserType()
    const dispatch = useDispatch()
    const language = useSelector(state => state.language)
    const fixTutorialsShown = useRef(false)
    let businessTutorialsShown
    const sohoUserType = ReduxCache.getBusinessAccount()?.sohoUserType || ''
    const isLoggedInUAT = window.localStorage.getItem('isLoggedInUAT') === 'true'



    if (route?.params?.isDeepLink) {
        const sideBar = useRef([
            {
                key: 'Dashboard',
                name: 'dashboard_title',
                screens: ['Dashboard']
            },
            {
                key: 'ProductsAndServices',
                name: 'sub_tray_my_products_title',
                screens: ['ProductsAndServices']
            },
            {
                key: 'Billing',
                name: 'dashboard_billing_card_title',
                screens: ['Billing', 'MonthlyBilling', 'FixBilling']
            },
            {
                key: 'Catalogue',
                name: 'catalogue_title_screen',
                screens: ['Catalogue', 'CatalogueDetails']
            },
            {
                key: 'My Products',
                name: 'product_section_title',
                items: [
                ]
            }
        ])

        const generateMenuData = (data) => {

            const offersAndRewards = {
                key: 'Offers & Rewards',
                name: 'dashboard_offers_rewards_group_component_name',
                items: []
            }

            const basicAndSos = {
                key: 'Basic Services',
                name: 'basic_section_title',
                items: []
            }

            const assistance = {
                key: 'Assistance',
                name: 'Assistance',
                items: []
            }

            let shake


            const myAccount = {
                key: 'My Account',
                name: 'accounts_section_title',
                items: [
                    {
                        key: 'PaymentMethods',
                        name: 'payment_method_screen_title',
                        screens: ['PaymentMethods', 'EditPayment', 'AddPayment']
                    },
                    {
                        key: 'FixContracts',
                        name: 'my_contracts',
                        screens: ['FixContracts']
                    },
                    {
                        key: 'ManageOrders',
                        name: 'my_orders',
                        screens: ['ManageOrders']
                    },
                    {
                        key: 'Settings',
                        name: 'subtray_app_settings_title',
                        screens: ['Settings', 'ManageProfile', 'EditProfile', 'ChangeMyPassword', 'DeactivateAccount', 'Recommendations', 'Preferences', 'DataManagment', 'TermsAndConditions', 'PrivacyPolicy']
                    },
                ]
            }

            data = data?.sort((a, b) => {
                if (!a.order) {
                    return -1
                }

                if (parseInt(a.order) < parseInt(b.order)) {
                    return -1
                }
                return 1
            })

            data?.map(item => {
                return item.subItems?.sort((a, b) => {
                    if (!a.order) {
                        return -1
                    }

                    if (parseInt(a.order) < parseInt(b.order)) {
                        return -1
                    }
                    return 1
                })
            })


            data?.map(item => {

                item?.subItems?.map(subItem => {

                    // OFFFERS AND REWARD

                    if (subItem.key === 'reward') {
                        offersAndRewards.items.push({
                            key: 'Demo',
                            name: 'Vodafone More',
                            screens: ['Demo', 'OpenedGift', 'Catalog', 'Profile', 'Info', 'Details', 'FavouritesDetails', 'VfCoinsTransfer', '']
                        })
                    }
                    if (subItem.key === 'redBoxWebview') {
                        offersAndRewards.items.push({
                            key: 'VfHearts',
                            name: 'Flower box',
                            screens: ['VfHearts', 'VfHeartsCollection']
                        })
                    }
                    if (subItem.key === 'ungame') {
                        offersAndRewards.items.push({
                            key: 'Quiz',
                            name: 'UN Quiz',
                            screens: ['Quiz', 'QuizGame']
                        })
                    }

                    if (subItem.key === 'shake') {
                        shake = {
                            key: 'Shake',
                            name: 'Shake',
                            screens: ['Shake']
                        }
                    }

                    //BASIC AND SOS

                    if (subItem.key === 'recharge_other') {
                        basicAndSos.items.push({
                            key: 'TopUPAnother',
                            name: 'top_up_someone_else_quick_action_title',
                            screens: ['TopUPAnother']
                        })
                    }

                    if (subItem.key === 'recharge_my') {
                        basicAndSos.items.push({
                            key: 'TopUP',
                            name: 'my_recharge_section_title',
                            screens: ['TopUP']
                        })
                    }

                    if (subItem.key === 'mParking') {
                        basicAndSos.items.push({
                            key: 'Parking',
                            name: 'Tirana Parking',
                            isWebView: true,
                            params: { 'url': `https://mytrp.altirana.com/?MSISDN=${ReduxCache.get('auth.hashedMsisdnParking')}&lang=${language}`, 'title': 'Tirana Parking', 'isReact': true }
                        })
                    }

                    if (subItem.key === 'VodafoneSharing') {
                        basicAndSos.items.push({
                            key: 'DataSharing',
                            name: 'Vodafone Share',
                            screens: ['DataSharing']
                        })
                    }

                    // ASSIST

                    if (subItem.key === 'Roaming') {
                        assistance.items.push({
                            key: 'Roaming',
                            name: 'Roaming',
                            isWebView: true,
                            params: { 'url': 'https://v1.vodafone.al/vodafone/Roaming_4510_1.php', 'title': 'Roaming', 'isReact': true }
                        })
                    }

                    if (subItem.key === 'call_me_back') {
                        assistance.items.push({
                            key: 'CallMeBack',
                            name: 'call_me_back_title',
                            screens: ['CallMeBack', 'CallMeBackHistory']
                        })
                    }

                })
            })
            dispatch(DashboardActions.updateUserSideBar([...sideBar.current, offersAndRewards, basicAndSos, assistance, myAccount, shake]))
        }


        useEffect(() => {
            if (configurationsRedux['menu_' + userType] && configurationsRedux['menu_' + userType].length > 0) {
                generateMenuData(configurationsRedux['menu_' + userType])
                startSplashEndAnimation()
                // onMenuReceived(configurationsRedux['menu_guest'])
            } else {
                generateMenuData(configurationsRedux.menu)
            }
        }, [configurationsRedux])
    }
    // const logoX =  -180
    // const logoY = -278

    useEffect(() => {
        getConfigurations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getConfigurations = async () => {
        try {
            Actions.getLP()
            getMsisdnData().then(res => {
                fixTutorialsShown.current = res?.fixTutorialsShown
                businessTutorialsShown = res?.businessTutorialsShown
            })

        } catch (error) {

        } finally {
            if (route?.params?.isDeepLink) {
                const isLogin = await getLocalKey('isLoggedIn')
                if (!isLogin) {
                    startSplashEndAnimation()
                }
            } else {
                startSplashEndAnimation()
            }
        }
    }

    const startSplashEndAnimation = async () => {
        // const { startSplashEndingAnimation, setSplashMode, setSplashLogoPosition, dismissSplash } = other
        const { startSplashEndingAnimation, setSplashMode, dismissSplash } = other


        const isLogin = await getLocalKey('isLoggedIn')
        if (isLoggedInUAT || route.params?.fromUAT) {
            NavigationService.navigate('Dashboard')
        }
        else if (isLogin && !userType?.includes('guest') && !route.params?.invalidToken) {

            if (fixTutorialsShown.current || !userType?.includes('fix')) {
                if (!businessTutorialsShown && sohoUserType === 'business_chooser') {
                    NavigationService.navigateWithResetAction('Tutorial')
                }
                if (route?.params?.isDeepLink) {
                    // NavigationService.navigateWithResetAction('Dashboard')
                    NavigationService.navigateWithResetAction(route?.params?.route.name, { ...route.params.route.params, isDeepLink: true })
                } else {
                    NavigationService.navigateWithResetAction('Dashboard')
                }
            } else {
                NavigationService.navigateWithResetAction('Tutorial')
            }
            // setSplashMode(SplashModes.TRANSITION)
            // setSplashLogoPosition({ logoX, logoY })
            setSplashMode(SplashModes.FADING)
        } else {
            NavigationService.navigateWithResetAction('PreLogin')
            setSplashMode(SplashModes.FADING)
            // launchLoginScreen()
        }

        try {
            if (!other.isLoading) {
                startSplashEndingAnimation({
                    startAfter: 10
                })
                    .then(response => {
                        if (response.done) {
                            response.dismissSplash()
                        }
                    })
            }
        } catch (error) {
            getConfigurations()
        }
    }

    return null
}

LandingScreen.propTypes = {
    other: PropTypes.object,
    isDeepLink: PropTypes.bool,
}

export default LandingScreen
