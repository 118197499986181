import React, {Component} from 'react'
import {View} from 'react-native'
import {Button, Text} from 'native-base'

class Error extends Component {
  render() {
    const message = this.props.message
    const retry = this.props.retry

    return (
      <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
        <Text
          style={{
            fontFamily: 'Vodafone Lt',
            color: '#000',
            marginBottom: 15,
            fontSize: 17,
          }}>
          {' '}
          {message}{' '}
        </Text>
        <Button
          style={{alignSelf: 'center', backgroundColor: 'red'}}
          onPress={() => {
            retry()
          }}>
          <Text style={{fontFamily: 'Vodafone Rg'}}>Try Again</Text>
        </Button>
      </View>
    )
  }
}

export default Error
