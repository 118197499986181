import { SendPin, ValidatePin } from '../../Services'
import { formatPhoneNumber } from '../../Utils'

const generatePin = async (phoneNumber) => {
    try {
        phoneNumber = formatPhoneNumber(phoneNumber)
        return SendPin(phoneNumber, { bypass_token: true }, {})
    } catch (error) {
        throw error
    }
}

const validatePin = async (phoneNumber, pin) => {
    return ValidatePin(phoneNumber, pin, {bypass_token: true}, {})
}

const Actions = { generatePin, validatePin }

export { Actions }
