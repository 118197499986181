import {StyleSheet, Platform} from 'react-native'

export default StyleSheet.create({
  content: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    flexDirection: 'column',
    flex: 1,
  },
  item: {
    color: 'black',
    fontSize: 13,
    width: '33%',
    textAlign: 'center',
  },
  item3: {
    color: 'black',
    fontSize: 13,
    width: '33%',
    textAlign: 'center',
  },
  item4: {
    color: 'black',
    fontSize: 13,
    width: '25%',
    textAlign: 'center',
  },
  listItem: {
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
  },
  activeTabStyles: {
    backgroundColor: '#fff',
    borderBottomWidth: 2,
  },
  tabStyles: {
    backgroundColor: 'red',
    borderBottomWidth: 2,
  },
  activeTextStyle: {
    color: 'black',
    fontSize: 16,
    fontWeight: 'normal',
    fontFamily: Platform.OS === 'ios' ? 'Vodafone Rg' : 'VodafoneRg',
  },
  tab: {
    backgroundColor: '#ddddde',
    flex: 1,
  },
  tableHeader: {
    paddingBottom: 8,
    paddingTop: 8,
    color: 'red',
    fontFamily: Platform.OS === 'ios' ? 'Vodafone Rg' : 'VodafoneRg',
    fontSize: 17,
    width: '33%',
    textAlign: 'center',
  },
  header3: {
    paddingBottom: 8,
    paddingTop: 8,
    color: 'red',
    fontFamily: Platform.OS === 'ios' ? 'Vodafone Rg' : 'VodafoneRg',
    fontSize: 15,
    textAlign: 'center',
    width: '33%',
  },
  header4: {
    paddingBottom: 8,
    paddingTop: 8,
    color: 'red',
    fontFamily: Platform.OS === 'ios' ? 'Vodafone Rg' : 'VodafoneRg',
    fontSize: 15,
    textAlign: 'center',
    width: '25%',
  },
  tabs: {
    backgroundColor: '#ddddde',
    flex: 1,
  },
  tabsUnderline: {
    backgroundColor: 'transparent',
    height: 2,
    marginBottom: -1
  },
  tabHeading: {
    backgroundColor: '#fff',
    borderBottomWidth: 2,
    overflow: 'hidden',
  },
  arrow: {
    width: 12,
    height: 12,
    flex: 1,
    position: 'absolute',
    transform: [{rotate: '45deg'}],
    bottom: -8,
    backgroundColor: 'red',
  },
  tabText: {
    color: '#000',
    fontSize: 14,
    fontWeight: 'normal',
  },
  tabContent: {
    backgroundColor: '#fff',
    borderRadius: 8,
    overflow: 'hidden',
    marginTop: 10,
    padding: 10,
    paddingHorizontal: 0,
    flex: 1,
  },
  contentView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  container: {
    backgroundColor: '#ddddde',
    flex: 1,
  },
})
