import React from 'react'
import { View, Dimensions, Platform } from 'react-native'
import ContentLoader, { Rect } from 'react-content-loader/native'

import { Card } from '@vfgroup-oneplatform/foundation/Components'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'


import styles from './Users.Styles'

const { width: SCREEN_WIDTH } = Dimensions.get('screen')

const UsersLoading = () => {

  const theme = useTheme()

  const loadingArray = [...Array(2)].map((_) => { })

  return (
    <View style={{ paddingHorizontal: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? '10%' : 0 }}>
      <ContentLoader
        speed={2}
        width={SCREEN_WIDTH - 32}
        height={135}
        backgroundColor={theme.colors.shimmerCenterColor}
        foregroundColor={theme.colors.shimmerEdgesColor}
      >
        {/* title */}
        <Rect x="0" y="15" rx="4" ry="4" width={120} height={15} />
        {/* subtitle */}
        <Rect x="0" y="50" rx="4" ry="4" width={160} height={15} />
        {/* search input */}
        <Rect x="0" y="80" rx="4" ry="4" width={SCREEN_WIDTH - 32} height={55} />
      </ContentLoader>

      <View style={styles.line(theme)} />

      {/* admin users cards */}
      <>
        <ContentLoader
          speed={2}
          width={SCREEN_WIDTH - 32}
          height={25}
          backgroundColor={theme.colors.shimmerCenterColor}
          foregroundColor={theme.colors.shimmerEdgesColor}
        >
          <Rect x="0" y="0" rx="4" ry="4" width={120} height={8} />
        </ContentLoader>
        {loadingArray.map(() => {
          return (
            <Card type="main" style={styles.cardStyle}>
              <ContentLoader
                speed={2}
                width={SCREEN_WIDTH - 32}
                height={30}
                backgroundColor={theme.colors.shimmerCenterColor}
                foregroundColor={theme.colors.shimmerEdgesColor}
              >
                <Rect x="0" y="0" rx="15" ry="15" width={30} height={30} />
                <Rect x="40" y="7" rx="4" ry="4" width={160} height={15} />
              </ContentLoader>
            </Card>
          )
        })}
      </>

      {/* normal users cards */}
      <>
        <ContentLoader
          speed={2}
          width={SCREEN_WIDTH - 32}
          height={50}
          backgroundColor={theme.colors.shimmerCenterColor}
          foregroundColor={theme.colors.shimmerEdgesColor}
        >
          <Rect x="0" y="20" rx="4" ry="4" width={120} height={8} />
        </ContentLoader>
        {loadingArray.map(() => {
          return (
            <Card type="main" style={styles.cardStyle}>
              <ContentLoader
                speed={2}
                width={SCREEN_WIDTH - 32}
                height={30}
                backgroundColor={theme.colors.shimmerCenterColor}
                foregroundColor={theme.colors.shimmerEdgesColor}
              >
                <Rect x="0" y="0" rx="15" ry="15" width={30} height={30} />
                <Rect x="40" y="7" rx="4" ry="4" width={160} height={15} />
              </ContentLoader>
            </Card>
          )
        })}
      </>
    </View>
  )
}


export default UsersLoading
