// import { actionTypes } from './CatalogueDetails.Actions'
const actionTypes = {
  REQUEST_ACTIATE_OFFER: 'REQUEST_ACTIATE_OFFER',
  SUCCESS_ACTIATE_OFFER: 'SUCCESS_ACTIATE_OFFER',
  ERROR_ACTIATE_OFFER: 'ERROR_ACTIATE_OFFER',
  RESET_ACTIATE_OFFER: 'RESET_ACTIATE_OFFER',
}

function catalogueOfferActivation(
  state = { loading: false },
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_ACTIATE_OFFER:
      return { loading: true }

    case actionTypes.SUCCESS_ACTIATE_OFFER:
      return {
        loading: false,
        ...action.payload,
      }

    case actionTypes.ERROR_ACTIATE_OFFER:
      return {
        loading: false,
        error: action.payload,
      }

    case actionTypes.RESET_ACTIATE_OFFER:
      return { loading: false }

    default:
      return state
  }
}


export {
  catalogueOfferActivation,
}
