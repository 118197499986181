import { actionTypes } from './FixBundles.Actions'

function renewalsOffers(state = { loading: false, data: null }, action = {}) {
    switch (action.type) {
        case actionTypes.REQUEST_OFFERS:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.SUCCESS_OFFERS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload,
            }
        case actionTypes.ERROR_OFFERS:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}

function selectedOffer(state = { data: null }, action = {}) {
    switch (action.type) {
        case actionTypes.CLEAR_SELECTED_BUNDLE:
            return {
                data: {},
            }
        case actionTypes.SELECT_BUNDLE:
            return {
                data: action.payload,
            }
        case actionTypes.SELECT_TV_OFFER:
            return {
                data: {
                    ...state.data,
                    selectedTvOffer: action.payload
                },
            }
        case actionTypes.SELECT_TV_ADD_ON:
            return {
                data: {
                    ...state.data,
                    selectedTvOffer: {
                        ...state.data.selectedTvOffer,
                        selectedAddOn: action.payload
                    }
                },
            }
        default:
            return state
    }
}

function internetOffers(state = { loading: false, data: null }, action = {}) {
    switch (action.type) {
        case actionTypes.REQUEST_INTERNET_OFFERS:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.SUCCESS_INTERNET_OFFERS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload,
            }
        case actionTypes.ERROR_INTERNET_OFFERS:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case actionTypes.CLEAR_INTERNET_OFFERS:
            return {
                ...state,
                loading: false,
                data: null,
            }
        default:
            return state
    }
}

function tvOffers(state = { loading: false, data: null }, action = {}) {
    switch (action.type) {
        case actionTypes.REQUEST_TV_OFFERS:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.SUCCESS_TV_OFFERS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload,
            }
        case actionTypes.ERROR_TV_OFFERS:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case actionTypes.CLEAR_TV_OFFERS:
            return {
                ...state,
                loading: false,
                data: null,
            }
        default:
            return state
    }
}

export { renewalsOffers, selectedOffer, internetOffers, tvOffers }
