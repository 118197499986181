import { StyleSheet } from 'react-native'

export default StyleSheet.create({
    subHeaderDescriptionContainerStyle: {
        marginTop: -3
    },
    buttonStyle: {
        marginBottom: 33.7,
    },
    refreshViewStyle: {
        marginTop: 0,
    },
    titleStyle: {
        fontSize: 16
    }
})
