import { useEffect, useContext } from 'react'
import { PositionsProvider, TooltipsIds } from '@vfgroup-oneplatform/framework/Services/PositionsProvider'


import TobiSupportContext from '../../../Utils/TobiSupportContext'

function useTooltip() {
  const tobiContext = useContext(TobiSupportContext)

  const { toolTipsModalRef } = tobiContext


  //tooltips
  useEffect(() => {
    if (toolTipsModalRef?.current?.updatePositions) {
      PositionsProvider.subscribe(toolTipsModalRef.current.updatePositions)

    }
    if (toolTipsModalRef?.current?.state?.positions) {
      PositionsProvider.setLoading(TooltipsIds.TOBI, false)
      PositionsProvider.setLoading(TooltipsIds.TRAY, false)
      PositionsProvider.setLoading(TooltipsIds.MAIN_CARDS, false)
    }

  }, [toolTipsModalRef])


  return {
    toolTipsModalRef,
  }
}

export default useTooltip
