export const actionTypes = {
    BALANCE_LOADING: 'BALANCE_LOADING',
    BALANCE_SUCCESS: 'BALANCE_SUCCESS',
    BALANCE_ERROR: 'BALANCE_ERROR',
    PEGA_LOADING: 'PEGA_LOADING',
    PEGA_SUCCESS: 'PEGA_SUCCESS',
    PEGA_ERROR: 'PEGA_ERROR',
    UPDATE_SIDEBAR: 'UPDATE_SIDEBAR',
    CLEAR_SIDEBAR: 'CLEAR_SIDEBAR',
}
