import {ResetPassword} from '../../Services'
import { formatPhoneNumber } from '../../Utils/Regex'

const resetPassword = async (phoneNumber, password, pin) => {
    try {

        phoneNumber = formatPhoneNumber(phoneNumber)

        const response = await ResetPassword(phoneNumber, password, pin, {bypass_token: true}, {})

        return response
    } catch (error) {
        throw error
    }
}

const Actions = {resetPassword}

export {Actions}
