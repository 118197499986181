import moment from 'moment'
import AsyncStorage from '@react-native-async-storage/async-storage'

import ReduxCache from './ReduxCache'

const getMsisdnData = async () => {
    const msisdn = ReduxCache.getMsisdn()
    try {
        let data = await AsyncStorage.getItem('msisdnData')
        data = JSON.parse(data)
        return data?.[msisdn]
    } catch (e) {
        console.log('Something went wrong when reading local storage' + e)
    }
}

const setMsisdnData = async (key, value) => {
    const msisdn = ReduxCache.getMsisdn()
    try {
        let data = await AsyncStorage.getItem('msisdnData')
        data = JSON.parse(data) || {}
        data[msisdn] = {
            ...data?.[msisdn],
            [key]: value,
        }

        await AsyncStorage.setItem('msisdnData', JSON.stringify(data))
        return
    } catch (e) {
        console.log('Something went wrong when writing to local storage' + e)
    }
}

const isShownToday = async (type) => {
    const localData = await getMsisdnData() || {}

    const lastDateShown = moment(localData[type] || null)

    if (!localData[type] || (lastDateShown.isValid() && !lastDateShown.isSame(moment(), 'day'))) {
        return false
    }

    return true
}

const shouldShowReview = async () => {
    const lastReviewDate = await AsyncStorage.getItem('lastReviewDate')

    if (!lastReviewDate) {
        return true
    }

    const lastDateShown = moment(lastReviewDate || null)
    const currentDate = moment()

    return currentDate.diff(lastDateShown, 'days') > 14
}

const updateLastReviewDate = () => {
    const currentDate = moment().format(moment.HTML5_FMT.DATE)
    AsyncStorage.setItem('lastReviewDate', currentDate)
}

const setShownToday = (type) => {
    setMsisdnData(type, moment())
}


export {
    getMsisdnData,
    setMsisdnData,
    isShownToday,
    setShownToday,
    shouldShowReview,
    updateLastReviewDate
}
