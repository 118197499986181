import { StyleSheet } from 'react-native'

import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  subHeaderTitleStyle: theme => ({
    fontSize: Fonts.size.h4,
    color: theme.colors.textColor,
  }),
  subHeaderSubTitleStyle: theme => ({
    fontSize: Fonts.size.regular,
    fontFamily: Fonts.type.VFFont,
    fontWeight: '100',
    color: theme.colors.textColorTwo,
  }),
  containerStyle: {
    // paddingHorizontal: 16,
    // paddingVertical: 15,
    flex: 1
  }
})

