export default {
    app_name: 'My Vodafone',
    general_error: 'Diçka nuk funksionoi!',
    quick_action_add_new_payment_card_title: 'Shto një kartë të re krediti/debiti',
    quick_action_add_new_payment_card_subtitle: 'Vendos të dhënat e kartës',
    quick_action_add_new_payment_card_field_card_number: 'Numri i kartës',
    quick_action_add_new_payment_card_field_name_on_card: 'Emri në kartë',
    quick_action_add_new_payment_card_field_expire_date: 'Data e skadencës',
    quick_action_add_new_payment_card_field_cvv: 'CVV',
    quick_action_add_new_payment_card_expiration_date_hint_message: 'MM/YY',
    quick_action_add_new_payment_card_field_card_name: 'Emërto kartën',
    quick_action_add_new_payment_card_save_this_card: 'Ruaj kartën',
    quick_action_add_new_payment_card_encryption_hint: 'Siguria e shërbimit garantohet nga sistemi i enkriptimit të të dhënave (SSL). Të dhënat e kartës sigurohen në llogarinë tënde Vodafone. ',
    quick_action_add_new_payment_card_done: 'Vazhdo',
    quick_action_add_new_payment_card_field_card_number_error_message: 'Numri i kartës nuk është i saktë',
    quick_action_add_new_payment_card_field_name_on_card_error_message: 'Emri nuk është i saktë',
    quick_action_add_new_payment_card_field_expire_date_error_message: 'Data nuk është e saktë',
    quick_action_add_new_payment_card_field_cvv_error_message: 'CVV nuk është e sakte',
    quick_action_add_new_payment_card_field_card_name_error_message: 'Vendos një emër tjetër',
    credit_card: 'Kartë Krediti',
    quick_action_add_new_payment_temp_card_name: 'Kartë e përkohshme',
    payment_quick_action_error: 'Diçka nuk funksionoi!',
    payment_quick_action_error_message: 'Provo përsëri!',
    top_up_quick_action_error: 'Diçka nuk funksionoi!',
    top_up_quick_action_error_message: 'Provo përsëri!',
    full_error_button_text: 'Provo përsëri',
    pay_bill_quick_action_failure_try_again: 'Provo përsëri',
    pay_bill_quick_action_failure_cancel: 'Kthehu',
    top_up_quick_action_title: 'Rimbush numrin tim',
    top_up_quick_action_main_title: 'Rimbush numrin tim',
    top_up_quick_action_subtitle: 'Zgjidh vlerën',
    top_up_quick_action_payment_method_title: 'Mënyra e pagesës',
    top_up_quick_action_currency: 'Lekë',
    top_up_quick_action_payment_method: '%1$s **** %2$s',
    top_up_quick_action_payment_method_subtitle: '%1$s **** %2$s',
    top_up_quick_action_offer_subtitle: 'Rimbush mbi 800 Lekë dhe përfito 3GB dhuratë',
    top_up_quick_action_offer_subtitle_third_party: 'Rimbush një numër tjetër dhe përfito 1GB bonus',
    top_up_quick_action_successful: 'Rimbushja u krye me sukses!',
    top_up_quick_action_balance: '%1$s %2$s janë shtuar në balancën tënde.',
    top_up_quick_action_confirm_button_text: 'Rimbush tani',
    dashboard_upgrade_component_button_topup: 'Rimbush tani',
    top_up_quick_action_close_button_text: 'Mbyll',
    top_up_quick_action_return_to_dashboard_button_text: 'Mbyll',
    top_up_quick_action_loading: 'Duke u proçesuar...',
    top_up_quick_action_loading_main: 'Duke u proçesuar...',
    top_up_quick_action_loading_purchase: 'Duke u proçesuar...',
    top_up_quick_action_ok_button_text: 'OK',
    top_up_quick_action_selected_payment_method_desc: '**** **** **** %1$s',
    top_up_quick_action_add_payment_method_item_text: 'Shto kartën',
    payment_add_form_card_number: 'Numri i kartës',
    payment_add_form_card_number_error_message: 'Numri i kartës nuk është i saktë',
    payment_add_form_name_on_card: 'Emri në kartë',
    payment_add_form_expiration_date: 'Data e skandecës',
    payment_add_form_expiration_date_error_message: 'Data nuk është e saktë',
    payment_add_form_expiration_date_hint_message: 'MM/YY',
    payment_add_form_cvv_number: 'CVV',
    payment_add_form_cvv_number_error_message: 'Kodi CVV nuk është i saktë',
    payment_add_form_card_name: 'Emri i kartës',
    payment_add_form_security_notice: 'Siguria e shërbimit garantohet nga sistemi i enkriptimit të të dhënave (SSL). Të dhënat e kartës sigurohen në llogarinë tënde Vodafone.',
    payment_add_form_toggle_text: 'Ruaj kartën',
    payment_add_form_save_button: 'Ruaj kartën',
    payment_add_form_title_toggle: 'Do të fshish %1$s?',
    payment_add_form_description_toggle: 'Kjo kartë nuk do mund të përdoret më ne My Vodafone.',
    payment_method_screen_title: 'Menaxho kartat',
    payment_method_add_card_title: 'Shto kartë krediti/debiti',
    payment_method_add_card: 'Shto kartë',
    payment_method_expiry: 'Exp %1$s',
    payment_add_card_loading_text: 'Duke u proçesuar...',
    payment_preferred_payment_methods: 'Karta e preferuar',
    payment_other_payment_methods: 'Karta të tjera',
    payment_add_new_payment_method: 'Shto një kartë të re',
    payment_add_new_card_screen_title: 'Shto një kartë të re',
    payment_add_scan_card_description: 'Shto kartën duke e skanuar me kamerën e telefonit',
    payment_edit_scan_card_description: 'Edito kartën duke e skanuar me kamerën e telefonit',
    payment_scan_card_button: 'Skano kartën',
    payment_make_payment_method_preferred: 'Bëje të preferuar',
    payment_expiry_date: 'Data e skadencës',
    payment_quick_action_title: 'Paguaj faturën',
    payment_quick_action_title_ok_button_text: 'OK',
    payment_quick_action_title_done_button_text: 'OK',
    payment_quick_action_confirm_button_text: 'Konfirmo',
    payment_quick_action_reject_button_text: 'Mbyll',
    payment_quick_action_loading: 'Duke u proçesuar…',
    payment_quick_action_success: 'Pagesa e fatures %1$s u krye me sukses!',
    payment_quick_action_success_message: 'Faleminderit! Fatura u pagua me sukses.',
    payment_delete_form_delete_button_confirm: 'Fshi kartën',
    payment_delete_form_delete_button: 'Do të fshish kartën?',
    payment_delete_form_keep_card_button: 'Mbaj kartën',
    payment_no_existing_methods_title: 'Nuk ka kartë te ruajtur.',
    payment_no_existing_methods_description: 'Mund të shtosh një kartë krediti/debiti duke shtypur butonin Shto.',
    topup_add_mobile_number: 'Vendos numrin e telefonit',
    topup_wrong_format: 'Numër i gabuar. Numri duhet të jetë: 3556xxxxxxxx',
    top_up_quick_action_manage_payment_method_item_text: 'Menaxho',
    ALL: 'Lekë',
    top_up_quick_action_subtitle_third_party: 'Zgjidh numrin dhe vlerën',
    top_up_quick_action_main_title_third_party: 'Rimbush një numër tjetër',
    top_up_quick_action_balance_thid_party: '%1$s %2$s janë shtuar në balancë.',
    top_up_someone_else_quick_action_title: 'Rimbush një numër tjetër',
    top_up_someone_else_new_recipient_title: 'Rimbush një numër tjetër dhe përfito 1GB bonus',
    top_up_someone_else_next_button_title: 'Vazhdo',

    //billing
    billing_client_code: 'Kodi i klientit',
    billing_bill_number: 'Numri i faturës',
    billing_dashboard_title: 'Fatura',
    billing_dashboard_description: 'Shpenzimi deri tani:',
    billing_dashboard_due_date_args: 'Data e pagesës %1$s%2$s %3$s',
    billing_dashboard_next_available_date: 'Fatura do të jetë gati më %1$s',
    billing_dashboard_next_available_date: '',
    billing_current_bill_extra_charges: 'Shpenzime shtesë',
    billing_current_bill_included_services: 'Shërbimet e tua',
    billing_current_bill_title: 'Fatura e fundit',
    billing_previous_bill_title: 'Faturat e mëparshme',
    billing_no_bill_title: 'Fatura jote nuk është gati.',
    billing_no_bill_subtitle: 'Fatura do të jetë gati më %1$s',
    billing_no_bill_subtitle: '',
    billing_payment_method: 'Mënyra e pagesës',
    billing_payment_breakdown_btn_more: 'Më shumë',
    billing_payment_breakdown_btn_less: 'Më pak',
    billing_details_title: 'Fatura e %1$s',
    billing_details_bundle: 'Paketë',
    billing_details_call_to: '',
    billing_details_more_info: 'Detaje ',
    billing_details_pdf_report: 'Fatura PDF',
    billing_landing_title: 'Faturat e mia',
    billing_credit_card_stars: '%1$s **** **** **** %2$s',
    billing_credit_card_paid_date: 'Paguaj më %1$s%2$s %3$s',
    billing_credit_card_pay_now: 'Paguaj tani %1$s',
    billing_credit_card_already_paid: 'Paguar më %1$s',
    billing_payment_breakdown_title: 'Faturë përmbledhëse',
    billing_payment_breakdown_total_title: 'Totali',
    billing_payment_breakdown_total_pay_title: 'Totali për t\'u paguar',
    billing_payment_breakdown_service_cost_title: 'Shpenzime jashtë planit',
    billing_payment_breakdown_total_before_vat_title: 'Totali pa TVSH',
    billing_payment_breakdown_vat_title: 'TVSH',
    billing_payment_breakdown_btn_show_details: 'Shiko detajet',
    billing_payment_breakdown_btn_hide_details: 'Mbyll',
    billing_error_something_went_wrong: 'Diçka nuk funksionoi',
    billing_show_more_title: 'Trego %1$s muajt e tjerë',
    billing_negative_value_note: 'Faturë negative',
    billing_negative_bill_quick_action_title: 'Faturë negative',
    billing_negative_bill_quick_action_question_text: 'Pse Fatura ime është negative?',
    billing_negative_bill_quick_action_description: 'Fatura negative tregon që ti ke paguar mjaftueshëm për të mbuluar faturën aktuale dhe ke akoma kredit të mbetur.',
    billing_negative_bill_quick_action_button_text: 'Mbyll',
    billing_negative_over_paid: 'Pagesë mbi Faturën ',
    billing_chart_see_bill: 'Shiko Faturën',
    billing_card_tag_paid: 'E paguar',
    billing_card_tag_processing_payment: 'Duke proçesuar pagesën',
    billing_card_tag_partially_paid: 'Paguar pjesërisht',
    billing_card_tag_unpaid: 'E pa paguar',
    billing_card_tag_overdue: 'Overdue',
    billing_details_pay_bill_now: 'Paguaj tani %1$s',
    billing_current_spend: '',
    billing_details_total_spend: '',
    billing_details_bill_paid: 'Paguar në %1$s',
    billing_payment_method_not_saved: 'Asnjë pagesë e ruajtur',
    billing_client_code: 'Kodi i klientit',
    billing_client_status: 'Statusi',
    billing_filter_show_all: 'Të gjitha',
    billing_bill_type_mobile: 'Celular',
    billing_bill_type_fix: 'Internet Fix',
    no_cards: 'Nuk keni asnjë kartë të ruajtur!',
    payment_delete_card_error: 'Ti nuk mund të fshish kartën e preferuar',
    payment_delete_card_error_message: 'Ndrysho preferencat dhe provo përsëri',
    ': ': '',
    billing_download_bill: 'Shkarko faturën',
    billing_storage_permission_denied: 'Duhet të jepni akses në media',


    // Dashboard
    everything_is_ok_greeting_text: 'Përshëndetje %1$s',
    everything_is_ok_description_text: 'Just checking everything is OK',
    everything_is_ok_text: 'Përshëndetje',
    everything_is_ok_error_text: 'We\'ve found an issue…',
    everything_is_ok_details_screen_success_text: 'Everything is OK, %1$s. We\'ll let you know if anything changes.',
    everything_is_ok_details_screen_in_progress_text: 'We\'re checking your services. This won\'t take long.',
    everything_is_ok_details_screen_error_text: 'We\'ve found something that you need to have a look at…',
    everything_is_ok_in_progress_text: 'Checking your services…',
    everything_is_ok_billing_title: 'Billing',
    everything_is_ok_last_bill_paid_title: 'Last bill paid',
    everything_is_ok_last_bill_paid_description: 'Okay',
    everything_is_ok_additional_charges_title: 'Additional charges',
    everything_is_ok_additional_charges_description: 'You have %1$s out of plan spend on your current bill. Please review these charges.',
    everything_is_ok_additional_charges_action_title: 'Review',
    everything_is_ok_data_remaining_title: 'Data Remaining',
    everything_is_ok_account_title: 'Llogaria',
    everything_is_ok_address_up_to_date_title: 'Address up to date',
    everything_is_ok_payment_details_title: 'Payment Details',
    everything_is_ok_payment_details_description: 'Your payment details are out of date.',
    everything_is_ok_payment_details_action_title: 'Update',
    everything_is_ok_my_phone_title: 'Telefoni im',
    dashboard_app_get_action_label: 'Get',
    everything_is_ok_my_fix_title: 'Shërbimet Fix',
    everything_is_ok_broadband_title: 'Broadband',
    everything_is_ok_speed_checker_action: 'Speed Checker',
    everything_is_ok_phone_title: '%1$s\'s phone',
    dashboard_title: 'Dashboard',
    dashboard_help_and_support_item_title: 'Help and support',
    dashboard_app_get_action_label: 'Shkarko',
    dashboard_app_open_action_label: 'Open',
    dashboard_basic_group_and_sos_component_name: 'Shërbimet SOS dhe bazike',
    dashboard_discovery_label: 'Zbulo',
    dashboard_upgrade_component_name: 'upgrade component',
    dashboard_upgrade_component_title: 'This is upgrade component',
    dashboard_upgrade_component_title_topup: '%1$s, top up by %2$s%3$s or more and get %4$s%5$s data for %6$s%7$s',
    dashboard_upgrade_component_subtitle_topup: 'Normal price %1$s%2$s.\nOffer ends on %3$s.',
    dashboard_basic_group_component_name: 'Basics',
    dashboard_group_component_show_more: 'Më shumë',
    dashboard_group_component_show_less: 'Më pak',
    dashboard_demo_component_name: 'Demo Component',
    dashboard_top_up_item_title: 'Top-up',
    dashboard_change_pin_item_title: 'Change PIN',
    dashboard_your_messages_item_title: 'Your messages',
    dashboard_purchase_add_on_item_title: 'Purchase add on',
    dashboard_assistance_group_component_name: 'Assistance',
    dashboard_travelling_abroad_item_title: 'Travelling abroad',
    dashboard_in_store_appointment_item_title: 'In-store appointments',
    dashboard_store_locator_item_title: 'Store locator',
    dashboard_wifi_finder_item_title: 'WiFi finder',
    dashboard_demo_component_title: 'This is our demo component',
    dashboard_developer_settings_item_title: 'Developer settings',
    dashboard_offers_rewards_group_component_name: 'Offers and rewards',
    dashboard_network_health_group_component_name: 'Network Health',
    dashboard_speed_checker_title: 'Speed Checker',
    dashboard_broadband_finder_item_title: 'Broadband finder',
    dashboard_apps_component_title: 'Other Vodafone apps',
    dashboard_facebook_item_title: 'Facebook',
    dashboard_twitter_item_title: 'Twitter',
    dashboard_slack_item_title: 'Slack',
    dashboard_loading_error_message: 'Diçka nuk funksionoi! Aplikacioni nuk po rifreskohet',
    dashboard_loading_error_try_again_button: 'Provo përsëri',
    dashboard_item_error_normal_message: 'Një gabim ka ndodhur!',
    dashboard_item_error_normal_try_again_button: 'Provo përsëri',
    dashboard_item_error_small_message: 'Një gabim ka ndodhur!',
    dashboard_item_error_small_try_again_button: 'Provo përsëri',
    dashboard_top_up_card_title: 'Rimbush',
    dashboard_top_up_card_subtitle: 'Balanca jote është:',
    dashboard_billing_card_title: 'Fatura',
    dashboard_billing_card_subtitle: 'Fatura e fundit:',
    dashboard_billing_postpay_card_subtitle: 'Shpenzimi aktual',
    dashboard_billing_card_pay_date: 'Paguaj deri më %1$s%2$s %3$s',
    dashboard_top_up_card_username: '%1$s\'s phone',
    dashboard_top_up_card_last_updated: 'Rifreskuar para %1$@ minutash',
    dashboard_top_up_card_date_paid_title: 'Load amount',
    dashboard_top_up_card_badge_unpaid_title: 'Load amount',
    dashboard_top_up_card_description_unpaid_title: 'Couldn\'t renew tariff',
    dashboard_top_up_card_due_in_days: 'Due in %1$s days',
    dashboard_my_messages_card_title: 'Mesazhet e mia',
    dashboard_view_all_spendings_button: 'Shiko të gjitha shpenzimet',
    dashboard_offline_text: 'Përshëndetje %1$s you are offline',
    //Discovery Screen
    dashboard_discovery_see_more: 'Më shumë',
    dashboard_discovery_see_less: 'Më pak',
    dashboard_discovery_screen_title: 'Zbulo',
    dashboard_discovery_promotion: 'Promocione',
    dashboard_discovery_see_details: 'Shiko detajet',
    app_error_title: 'An error has occurred',
    app_error_description: 'Your connection was interrupted or your network has changed, your device may be trying to connect to too many networks at once.',
    app_error_button: 'Retry',
    dashboard_card_topup_user_phone: '%1$s\'s phone',
    lastupdatedlabel_minute: 'Rifreskuar para %1$s minutash',
    lastupdatedlabel_minutes: 'Rifreskuar para %1$s minutash',
    lastupdatedlabel_timestamp: 'Rifreskuar më %1$s',
    lastupdatedlabel_updating: 'Duke u rifreskuar...',
    lastupdatedlabel_justupdated: 'Rifresko',
    'My Data': 'Internet',
    'Local Calls': 'Minuta',
    top_up_last_recharge: 'Rimbushja e fundit %1$s',
    unlimited_access_to_internet: 'Ti ke akses pa limit në internet',
    unlimited_sms: 'Ti mund të komunikosh pa limit',
    unlimited_voice: 'Ti mund të komunikosh pa limit',
    activate_bundle: 'Aktivizo pakete',
    no_active_bundle: 'Ti nuk ke asnjë paketë aktive:',
    dashboard_get_more_data: 'Merr më shumë internet',
    dashboard_bundle_amount_left: 'të mbetura nga %1$s',
    unlimited_bar_data: 'Pa limit',
    unlimited_bar_sms: 'Pa limit',
    unlimited_bar_voice: 'Pa limit',
    'Credit': 'Balanca',
    dashboard_collected: 'të mbledhura',
    dashboard_promo_label_hours: 'orë',
    dashboard_promo_label_days: 'ditë',
    dashboard_promo_label_min: 'min',
    dashboard_get_your_ticket: 'Hyr tani',
    dashboard_tickets: 'Bileta',
    dashboard_details: 'Detaje',
    dashboard_fix_lines: '%1$s Linja Fix',
    dashboard_fix_line: '1 Linjë Fix',

    //for fix user types
    dashboard_card_created_status: 'Ne pritje',
    dashboard_card_active_status: 'Aktive',
    dashboard_card_inactive_status: 'Jo aktive',
    dashboard_card_status: 'Off',
    dashboard_card_terminated_status: 'Anulluar',
    dashboard_card_cancelled_status: 'Anulluar',
    dashboard_my_mobile: "Shërbimi celular",

    tray_accounts_section_title: 'My account',
    tray_messages_section_title: 'Messages',
    tray_messages_no_new_messages: 'No new messages',

    sub_tray_payment_title: 'Payment',
    sub_tray_payment_subtitle: '%1$s methods available',
    sub_tray_payment_subtitle_one_method: '1 method available',
    sub_tray_payment_subtitle_zero_method: '0 payment methods',

    subtray_app_settings_title: 'Konfigurime',
    subtray_app_settings_sub_title: 'Ndryshoni konfigurimet tuaja',
    logout_title: 'Dil',
    logout_subtitle: 'Profili i %1$s',
    logout_description: 'Jeni i sigurt që dëshironi të dilni nga My Vodafone?',
    logout_button: 'Dil',
    logout_cancel_button: 'Anullo',
    sub_tray_my_products_title: 'Produkte & Shërbime',
    my_account: 'My account',
    sub_tray_select_one: 'Zgjidh një',

    netperform_why_this_network_wifi_connection: 'Lidhur me Wi-Fi',
    netperform_why_this_network_wifi_description: 'Momentalisht ju jeni të lidhur me Wi-Fi. Në qoftë se dëshironi të testoni shpejtesinë mobile, duhet të shkoni në konfigurimet e telefonit dhe të fikni Wi-Fi.',
    netperform_why_this_network_settings_button: 'Shko tek konfigurimet',
    netperform_why_this_network_no_button: 'Kthehu',
    netperform_why_this_network_ok_button: 'Konfirmo',
    netperform_why_this_network_data_connection: 'Lidhur me internet',
    netperform_why_this_network_data_description: 'Momentalisht ju jeni të lidhur me internetin e SIM-it tuaj. Në qoftë se dëshironi të testoni shpejtesinë Wi-Fi, lidhuni me një rrjet Wi-Fi dhe testoni përsëri.',
    netperform_why_this_network_title: 'Pse ky rrjet?',
    netperform_speed_checker: 'Testi i shpejtësisë',
    netperform_download: 'Shkarkim',
    netperform_upload: 'Ngarkim',
    netperform_connection: 'Ping',
    netperform_speed_result_slow: 'Ngadalë',
    netperform_speed_result_average: 'Mesatare',
    netperform_speed_result_great: 'Shkëlqyer',
    netperform_speed_checker_history_no_tests: 'Ti nuk ke bëre asnjë test',
    netperform_speed_checker_history_no_tests_description: '',
    netperform_check_again: 'Provo përsëri',
    netperform_speed_checker_poor_hint: 'Mund të hasni vështirësi në hapjen e videove online ose navigimin në internet.',
    netperform_speed_checker_average_hint: 'Mund të marrë më shumë kohë në ngarkimin e videove online ose shërbimeve të tjera.',
    netperform_speed_checker_great_hint: 'Ti nuk duhet të kesh asnjë vështirësi në ngarkimin  e videove, telefonatave apo shërbime të tjera online.',
    netperform_speed_checker_upgrade_my_plan: 'Upgrade my plan',
    netperform_speed_checker_report_result_to_vodafone: 'Raporto rezultatet në Vodafone',
    netperform_tips_and_tricks: 'Këshilla si të përmirësosh shpejtësinë',
    netperform_tips_and_tricks_get_better_connection: 'Përmirëso lidhjen',
    netperform_tips_and_tricks_location: 'Vendndodhja',
    netperform_tips_and_tricks_signal_weak: 'A është sinjali i telefonit i dobët? Disa zona rurale kanë sinjale më të dobëta dhe do të ndikojnë në lidhjen e internetit. Shpejtësia mund të përmirësohet nëse ndryshon vendndodhjen.',
    netperform_tips_and_tricks_your_data_plan: 'Plani yt',
    netperform_tips_and_tricks_your_data_plan_description: 'Në varësi të planit tënd, shpejtësia e internetit mund të kufizohet. Shiko planin tënd nëse do ta përmirësosh për shpejtësi më të mirë.',
    netperform_tips_and_tricks_still_slow: 'Akoma e ngadaltë?',
    netperform_tips_and_tricks_still_slow_description: 'Kontrollo pyetjet e shpeshta për më shumë informacion mbi lidhjen internetit ose kontakto shërbimin  e klientit nëse ke probleme.',
    netperform_tips_and_tricks_get_help: 'Merr ndihmë',
    netperform_speed_history_show_map: 'Shiko hartën',
    netperform_speed_history_hide_map: 'Mbyll hartën',
    netperform_grant_permission_header: 'Jep akses',
    netperform_grant_permission_description: 'Për të kontrolluar shpejtësinë e rrjetit, na duhen të dhëna për vendndodhjen tënde, cilësinë dhe përdorimin e shërbimeve dhe konsumin e të dhënave të aplikacioneve të instaluara. Lejo akses në mënyrë që ne të mund të mbledhim këto të dhëna dhe të ofrojmë informacionin përkatës.',
    netperform_grant_permission_proceed_button: 'Konfirmo',
    netperform_grant_permission_no_thanks_button: 'Kthehu',
    netperform_grant_permission_grant_permission: 'Jep akses',
    netperform_grant_permission_disabled: 'Aksesi i pezulluar',
    netperform_grant_permission_network_disabled_description: 'Për të kontrolluar shpejtësinë e rrjetit, jep akses për rrjetin.',
    netperform_grant_permission_app_location_disabled_description: 'Për të kontrolluar shpejtësinë e rrjetit, jep akses për vendndodhjen tënde.',
    netperform_network_not_connected: 'Mungon lidhja',
    netperform_network_wifi: 'Wi-Fi',
    netperform_network_network: 'Rrjetin',
    netperform_network_connected_to: 'Lidhur me:',
    netperform_network_wifi_checking: 'Duke kontrolluar Wi-Fi',
    netperform_network_network_checking: 'Duke kontrolluar rrjetin',
    netperform_start_speed_test: 'Testo shpejtësinë',
    netperform_grant_permission_app_location_description_ios: 'Aktivizo vendndodhjen për të përmirësuar cilësinë e testit të shpejtësisë dhe për të parë hartën. Ti mund ta ndryshosh këtë në çdo kohë.',
    netperform_grant_permission_app_location_description_android: 'Aktivizo vendndodhjen për të përmirësuar cilësinë e testit të shpejtësisë dhe për të parë hartën. Ti mund ta ndryshosh këtë në çdo kohë.',
    netperform_grant_permission_deveice_location_description_ios: 'Vendndodhja e pajisjes është çaktivizuar, aktivizoni aksesin për të përmirësuar cilësinë e testit të shpejtësisë dhe për të parë hartën. Shkoni te konfigurimet e pajisjes > Privatësia > Shërbimet e vendndodhjes. Ti mund ta ndryshosh këtë në çdo kohë.',
    netperform_grant_permission_deveice_location_description_android: 'Vendndodhja e pajisjes është çaktivizuar, aktivizoni aksesin për të përmirësuar cilësinë e testit të shpejtësisë dhe për të parë hartën. Ti mund ta ndryshosh këtë në çdo kohë.',
    netperform_grant_permission_usage_access_description1: 'Ne mund të të japim një përmbledhje të aplikacioneve ku shpenzoni shumicën e të dhënave.',
    netperform_grant_permission_usage_access_description2: 'Për të përfituar këtë funksionalitet, aktivizo aksesin e përdorimit në konfigurimet e paisjes Android. Ti mund ta ndryshosh këtë në çdo kohë.',
    netperform_grant_permission_settings_button: 'Shko tek konfigurimet',
    netperform_grant_permission_no_button: 'Kthehu',
    netperform_grant_permission_ok_button: 'Konfirmo',
    netperform_error_no_connection_button: 'Provo përsëri',
    netperform_error_no_connection_text: 'Nuk je i lidhur me internetin.',
    netperform_usage_social_networks: 'Rrjete sociale',
    netperform_usage_streaming: 'Streaming',
    netperform_usage_categories: 'Kategoritë',
    netperform_usage_by_categories: 'Sipas kategorive',
    netperform_usage_overtime: 'Over time',
    netperform_usage_how_you_are_using_your_data: 'Si po e konsumon internetin',
    netperform_usage_how_you_are_using_data_by_app_category: 'Si po e konsumon internetin sipas kategorive',
    netperform_usage_top_applications: 'Aplikacionet më të përdorura',
    netperform_usage_show_more: 'Më shumë',
    netperform_usage_show_less: 'Më pak',
    netperform_usage_data: 'Internet',
    netperform_usage_wifi: 'Wi-Fi',
    netperform_usage_daily_card_title: 'Dita',
    netperform_usage_weekly_card_title: 'Java',
    netperform_usage_monthly_card_title: 'Muaji',
    netperform_usage_request_enable_permission_title: 'Aksesi i pezulluar',
    netperform_usage_request_enable_permission_description: 'Duhet të aktivizoni aksesin e përdorimit.',
    netperform_speed_test_tab_title: 'Testi i shpejtësisë',
    netperform_speed_test_history_tab_title: 'Historiku',
    netperform_default_error_button: 'Provo përsëri',
    netperform_default_error_text: 'Diçka nuk funksionoi',

    //Settings
    settings_screen_title: 'Konfigurime',
    settings_general_settings_title: 'Konfigurime të përgjithshme',
    settings_general_settings_subtitle: 'Kontrollo dhe menaxho konfigurimet e aplikacionit dhe telefonit tënd.',
    settings_permissions_title: 'Lejo aksesin',
    settings_permissions_subtitle: 'Menaxho dhe lejo aksesin në pajisjen tënde',
    settings_device_permissions_title: 'Akseset në pajisje',
    settings_device_permissions_subtitle: 'Menaxho dhe edito akseset në pajisjen tënde.',
    settings_profile_manage_title: 'Menaxho profilin tënd',
    settings_profile_manage_subtitle: 'Menaxho dhe edito profilin tënd',
    improving_our_network_title: 'Rrejti',
    improving_our_network_description: 'Përmirësimi i rrjetit',
    display_options_display_mode_section_title: 'Mënyra e shfaqjes',
    display_options_display_mode_section_description: '',
    display_options_mode_auto: 'Auto',
    display_options_mode_light: 'Light',
    display_options_mode_dark: 'Dark',
    settings_language_title: 'Gjuha',
    settings_language_subtitle: 'Përzgjidh gjuhën',
    app_languages_english: 'Anglisht',
    app_languages_albanian: 'Shqip',
    biometric_toggle_subtitle: 'Përdor identifikimin biometrik për akses më të lehtë në My Vodafone.',
    biometric_toggle_title: 'Touch ID',
    permissions_app_personalised_description: 'Në varësi të akseseve që ke dhënë, ne mund të bëjmë rekomandime dhe të përshtatim shërbimin tonë bazuar në vendndodhjen tënde, cilësinë e shërbimit tënd të lidhjes dhe konsumin e të dhënave të aplikacioneve të instaluara.',
    permissions_app_personalised_title: 'Shërbime dhe rekomandime të personalizuara',
    permissions_contact_description: 'Sinkronizo detajet e kontakteve direkt nga telefoni yt',
    permissions_contact_title: 'Kontaktet e telefonit',
    permissions_contacts_card_button_title: 'Lejo aksesin',
    permissions_contacts_card_description: 'Për të shfaqur kontaktet, lejo aksesin',
    permissions_contacts_card_title: 'Lista e kontakteve',
    permissions_footer_text: 'Kliko për të manaxhuar akseset në My Vodafone',
    permissions_location_description: 'Për të shfaqur mesazhe dhe informacione të rëndësishme bazuar në vendndodhjen tënde.',
    permissions_location_title: 'Vendndodhja',
    permissions_network_description: 'Përdor të dhënat e mbledhura për të përmirësuar rrjetin e Vodafone. Këto të dhëna janë plotësisht anonime dhe nuk mund të lidhen me identitetin tënd',
    permissions_network_title: 'Rrjeti',
    permissions_personalised_description: 'Përdor të dhënat e përdorimit për të krijuar shërbime dhe rekomandime të personalizuara',
    permissions_personalised_title: 'Shërbime dhe rekomandime të personalizuara',
    permissions_push_notifications_description: 'Lejo aksesin në njoftime që të jesh i përditësuar rreth llogarisë tënde',
    permissions_push_notifications_title: 'Njoftimet e aplikacionit',
    permissions_request_hypertext: 'Aktivizo këtu',
    permissions_settings_hypertext: 'Konfigurime',
    picker_select_done_text: 'Konfirmo',
    privacy_permissions_happy_with_this: 'Konfirmo',
    privacy_permissions_learn_more: 'Mëso më shumë',
    privacy_permissions_set_settings: 'Vendos konfigurimet e privatësisë',
    privacy_permissions_show_less: 'Më pak',
    privacy_permissions_accept_all_settings: 'Prano kushtet dhe politikat e privatësisë',
    privacy_permissions_reject_all_settings: 'Refuzo të gjitha',
    privacy_permissions_read_privacy_policy: 'Lexo kushtet dhe politikat e privatësisë',

    privacy_permissions_overlay_reject_all_success_modal_description: 'Për të parë rekomandimet dhe ofertat e personalizuara',
    privacy_permissions_overlay_reject_all_success_modal_primary_button: 'Mbyll',
    privacy_permissions_overlay_reject_all_success_modal_secondary_button: 'Menaxho konfigurimet e privatësisë',
    privacy_permissions_overlay_reject_all_success_modal_title: 'Konfigurimet e privatësisë janë refuzuar',
    privacy_permissions_processing: 'Duke u ngarkuar…',
    privacy_settings_accept_all_error_screen_button: 'Provo përsëri',
    privacy_settings_accept_all_error_screen_message: 'Nuk mund të proçesonim kërkesën',
    privacy_settings_accept_all_error_screen_title: 'Diçka shkoi gabim!',
    rivacy_settings_quick_action_description: 'Konfigurimet tuaja të privatësisë janë përditësuar me sukses.',
    privacy_settings_quick_action_primary_button_text: 'Mbyll',
    privacy_settings_quick_action_secondary_button_text: 'Kthehu',
    privacy_settings_quick_action_title: 'Urime!',
    privacy_permissions_dashboard_button: 'Kthehu',
    privacy_permissions_manage_button: 'Menaxho konfigurimet e privatësisë',
    privacy_permissions_accept_all_success_description: 'Konfigurimet tuaja të privatësisë janë përditësuar me sukses.',
    phone_calls_permission_title: 'Telefonata',
    phone_calls_permission_description: 'Për të identifikuar pajisjen dhe për të përdorur testin e shpejtësisë.',
    call_logs_permission_title: 'Historiku i telefonatave',
    call_logs_permission_description: 'Kontrollo historikun e telefonatave',
    personalised_permission_title: 'Shërbime dhe rekomandime të personalizuara',
    personalised_permission_description: 'Përdor të dhënat e përdorimit për të krijuar shërbime dhe rekomandime të personalizuara.',
    permissions_usage_title: 'Përdorimi',
    permissions_usage_description: 'Për të kuptuar performancen e rrjetit ne përdorim të dhena nga pajisja jote',

    profile_manage_title: 'Menaxho profilin',
    profile_settings_title: 'Konfigurimet e profilit',
    profile_settings_subtitle: 'Menaxho detajet e profilit tënd',
    profile_settings_edit_title: 'Modifiko profilin',
    profile_settings_edit_subtitle: 'Modifiko profilin tënd',
    profile_settings_edit_password_title: 'Ndrysho fjalëkalimin',
    profile_settings_edit_password_subtitle: 'Ndrysho fjalëkalimin tënd',
    profile_settings_deactivate_title: 'Çaktivizo llogarinë',
    profile_settings_deactivate_subtitle: 'Fshi llogarinë dhe të gjitha të dhënat e tua në My Vodafone',
    profile_settings_name_label: 'Emri',
    profile_settings_name_helper: 'Keni 12 karaktere në dispozicion',
    profile_settings_name_helper_error: 'Keni vendosur më shumë se 12 karaktere',
    profile_settings_name_input_label: 'Shto emrin',
    profile_settings_edit_name_title: 'Modifiko emrin tënd',
    profile_settings_edit_name_subtitle: 'Si t\'ju drejtohemi? Shtoni si dëshironi të quheni që të jetë më e lehtë për ne të komunikojmë.',
    profile_settings_save_button: 'Ruaj',
    profile_settings_save_name_success_title: 'Emri u ruajt me sukses!',

    change_password_title: 'Fjalëkalimi im',
    change_password_subtitle: 'Ndrysho fjalëkalimin',
    change_password_description: 'Për arsye sigurie vendos fjalëkalimin ekzistues',
    change_password_current_password_placeholder: 'Vendos fjalëkalimin ekzistues',
    change_password_new_password_title: 'Fjalëkalimi i ri',
    change_password_new_password_placeholder: 'Fjalëkalimi',
    change_password_confirm_new_password_placeholder: 'Konfirmo fjalëkalimin',
    change_password_confirm_new_password_error_message: 'Karakteret e fjalëkalimit të ri nuk përkojnë',
    change_password_confirm_button_title: 'Konfirmo',
    change_password_modal_title: 'Ndrysho fjalëkalimin',
    change_password_modal_success_title: 'Urime!',
    change_password_modal_success_subtitle: 'Fjalëkalimi është ndryshuar me sukses.',
    change_password_modal_success_button_title: 'Faqja kryesore',
    change_password_modal_failure_title: 'Diçka shkoi gabim',
    change_password_modal_failure_subtitle: 'Fjalëklimi yt nuk u ndryshua. Provo përseri.',
    change_password_modal_failure_button_title: 'Provo përsëri',

    delete_account_screen_title: 'Fshi llogarine',
    delete_account_long_description: 'Duke fshirë profilin tënd ti do të fshish të gjitha të dhënat e tua në My Vodafone app.\n\n Mos u shqetëso pasi ti do të vazhdosh të jesh klient Vodafone si dhe mund të rihapësh nje llogari të re në aplikacion në çdo moment.\n',
    delete_account_less_button: 'Më pak',
    delete_account_more_button: 'Më shumë',
    delete_account_delete_button: 'Fshi llogarinë',
    delete_account_note_title: 'Fshi profilin tënd',
    delete_account_profile_note_title: 'Profili yt',
    delete_account_profile_note_description: 'Profili yt ka të dhëna që përmirësojnë eskperiencën tënde në Vodafone',
    delete_account_button: 'Fshi',
    delete_account_cancel_button: 'Anullo',
    delete_account_confirm_title: 'A jeni të sigurt që doni të fshini profilin tuaj?',
    delete_account_confirm_text: 'Ju do të humbisni të gjitha të dhënat tuaja në app',
    delete_account_feedback_text: 'Mund të na jepni disa komente pse vendosët të fshini profilin tuaj',
    delete_account_other_reason: 'Tjetër',
    delete_account_title: 'Fshi profilin',

    delete_account_first_reason: 'Nuk më pëlqeu aplikacioni',
    delete_account_second_reason: 'Është një aplikacion shumë i ngadaltë',
    delete_account_third_reason: 'Unë nuk i besoj Vodafone',
    delete_account_error_title: 'Diçka nuk funksionoi',
    delete_account_default_error: 'Ju lutemi provoni përsëri kërkesën tuaj',
    delete_account_back_button: 'Kthehu',

    //onboard
    onboarding_greeting_text: 'Welcome to My Vodafone',
    onboarding_welcome_text: 'Let\'s start setting up your account',
    onboarding_start_action_label: 'Let\'s go!',
    terms_and_conditions_quick_action_title: 'Terms & conditions and privacy policy',
    terms_and_conditions_hyperlink_text: 'Terms and conditions',
    privacy_policy_hyperlink_text: 'privacy policy',
    terms_and_conditions_agree_title: 'I agree',
    terms_and_conditions_continue_button: 'Continue',
    terms_and_conditions_description_text: 'By using the app you agree to our %1$s and confirm you have read and understood our %2$s.',
    onboarding_address_step_description: 'test',
    onboarding_address_step_boldDescription: 'test',
    onboarding_address_step_yes_label: 'Yes',
    onboarding_address_step_no_label: 'No',
    onboarding_address_step_title: 'Address',
    onboarding_id_verification_step_description: 'test1',
    onboarding_skip_action_label: 'Skip',
    onboarding_step_forward_action_label: 'Forward',
    onboarding_id_verification_step_title: 'ID verification',
    onboarding_payment_method_step_description: 'test2',
    onboarding_step_yes_action_label: 'Yes',
    onboarding_payment_method_step_title: 'Payment method for better billing',
    permissions_header_title: 'Set permissions here. Don\'t worry - you can change these from your settings screen anytime you want.',
    permissions_button_title: 'I am happy with this',
    permissions_step_title: 'Permissions',
    onboarding_second_permission_step_action_two_text2: 'Skip',
    onboarding_congratulations_title: 'Congratulations!',
    onboarding_congratulations_sub_title: 'It\'s all set and ready to go.',
    onboarding_continue_label: 'Get started',
    onboarding_step_not_now: 'Not now',

    //terms&conditions
    terms_and_conditions_title: 'Kushte & Afate',
    terms_and_conditions_show_more: 'Më shumë',
    terms_and_conditions_show_less: 'Më pak',

    //Privacy policy 
    privacy_policy_title: 'Njoftim mbi privatësinë',
    privacy_policy_subtitle: 'Mëso më shumë rreth politikës së privatësisë',
    privacy_policy_show_more: 'Më shumë',
    privacy_policy_show_less: 'Më pak',

    //Data Management
    data_management_title: 'Menaxhimi i të dhënave',
    data_management_subtitle: 'Kontrollo dhe modifiko të dhënat',

    //Setting privacy settings 
    settings_privacy_settings_title: 'Privatësia',
    settings_privacy_settings_subtittle: 'Kontrollo si Vodafone përdor të dhënat e tua për të ofruar shërbime të dedikuara për ty si dhe menaxho preferencat.',
    settings_recommandation_description: 'Informohu dhe menaxho aksesin e të dhënave të tua.',
    settings_preferences_description: 'Menaxho mënyrat me të cilat Vodafone mund të të kontaktojë.',
    settings_terms_conditions_description: 'Informohu mbi kushtet e përgjithëshme të aplikacionit dhe shërbimeve',

    //Recommendations
    recommendations_title: 'Rekomandime të personalizuara',

    //Preferences
    preferences_title: 'Preferencat e komunikimit',
    preferences_subtitle: 'Preferencat e komunikimit',
    preferences_first_description: 'Herë pas here dëshirojmë t\'ju kontaktojmë për ofertat dhe promovimet tona më të fundit.',
    preferences_second_description: 'Zgjidh mënyren e preferuar që dëshiron të të kontaktojmë:',

    //netperform
    settings_netperform_title: 'NetPerform',
    settings_netperform_subtitle: 'Menaxho konfigurimet NetPerform',
    setting_netperform_policy: 'Politikat e privacisë Net Perform',

    delete_account_success_title: 'Llogaria u fshi me sukses',

    //top-up
    top_up_data_added_successful: 'Interneti u shtua!',
    top_up_quick_action_accepting_offer_subtitle: 'Çmimi normal %1$s%2$s. Oferta përfundn më %3$s.',
    top_up_quick_action_add_data_button_text: 'Shto internet',
    top_up_quick_action_add_data_label_text: 'Shto %1$s%2$s për %3$s%4$s?',
    top_up_quick_action_add_data_normal_price: 'Çmimi normal %1$s%2$s',
    top_up_quick_action_back_button_text: 'Kthehu',
    top_up_quick_action_balance_and_data_added_remaining: 'Rimbushja u krye me sukses. Balanca jote është %1$s%2$s si dhe %3$s%4$s GB të mbetura',
    top_up_quick_action_balance_and_remaining: 'Balanca jote është %1$s%2$s si dhe %3$s%4$s GB të mbetura.',
    top_up_quick_action_balance_offer: 'Rimbushja u krye me sukses. Balanca jote është %1$s%2$s si dhe %3$s%4$s GB të mbetura',
    top_up_quick_action_data_added_remaining: 'Rimbushja u kryer me sukses. Ti tani ke %3$s%4$s GB të mbetura',
    top_up_quick_action_edit: 'Modifiko',
    top_up_quick_action_error_button: 'Provo përseri',
    top_up_quick_action_no_offer_button: 'Jo vazhdo me %1$s%2$s',
    top_up_quick_action_no_thanks_button_text: 'Jo, faleminderit',
    top_up_quick_action_offer: 'Në qoftë se rimbush %1$s%2$s përfiton %3$s%4$s %5$s për vetëm %6$s%7$s.',
    top_up_quick_action_offer_button: 'Rimbush %1$s%2$s',
    top_up_quick_action_offer_completed: 'Rimbushja u krye me sukses dhe oferta është zhbllokuar!',
    top_up_quick_action_offer_end: 'Oferta pëfundon më %1$.',
    top_up_quick_action_offer_summary_data_title: '%1$s%2$s Internet',
    top_up_quick_action_offer_summary_title: 'Përmbledhje',
    top_up_quick_action_offer_summary_top_up_title: 'Rimbush',
    top_up_quick_action_offer_summary_total_title: 'Totali',
    top_up_quick_action_offer_title: 'Shto %1$s%2$s internet për vetëm %3$s',
    top_up_quick_action_offer_value: 'Balanca jote është %1$s%2$s. Ti mund të shtosh 3$s%4$s për vetëm %5$s%6$.',
    top_up_quick_action_remove_data_button_text: 'Hiq',
    top_up_quick_action_set_auto_topup_button_text: 'Set Auto top-up',
    top_up_quick_action_single_offer_subtitle: 'Top up by %1$s%2$s and you can get %3$s%4$s of %5$s for only %6$s%7$s',
    top_up_quick_action_upgrade_top_up_subtitle: 'Normal price %1$s%2$s.\nOffer ends on %3$s.',
    top_up_someone_else_contact_list_search_bar_placholder: 'Kërko kontaktet',
    top_up_someone_else_contact_list_section_all_title: 'Të gjitha',
    top_up_someone_else_contact_list_section_recent_title: 'Më të fundit',
    top_up_someone_else_contact_list_section_title: 'Zgjidh një kontakt',
    top_up_someone_else_default_tab_title: 'Quick top-up',
    top_up_someone_else_new_recipient_error_title: 'Numër i pasaktë',
    top_up_someone_else_new_recipient_header_title: 'Numri i telefonit',
    top_up_someone_else_new_recipient_placeholder: 'Numri i telefonit',

    top_up_someone_else_quick_action_failure_description: 'Nuk mund të procesojme këtë kërkesë. Verifiko detajet e pagesës.',
    top_up_someone_else_quick_action_failure_primary_button_text: 'Provo përsëri',
    top_up_someone_else_quick_action_failure_secondary_button_text: 'Kthehu',
    top_up_someone_else_quick_action_failure_title: 'Dicka nuk funksionoi!',
    top_up_someone_else_quick_action_subtitle: 'Numrin tim',
    top_up_someone_else_quick_action_title_with_formatting: 'Rimbush %1$s',
    top_up_someone_else_section_title: 'Zgjidh një kontakt',
    top_up_someone_else_successful_description: 'Ti sapo rimbushe %1$s%2$s drejt %3$s të cilat iu shtuan në balancë.',
    top_up_someone_else_successful_primary_button_text: 'Faqja kryesore',
    top_up_someone_else_successful_title: 'Urime!',

    custom_auto_top_up_first_tab: 'Zgjidh vlerën',
    custom_auto_top_up_second_tab: 'Vendos vlerën',
    custom_auto_top_up_field_currency: 'Lekë',
    custom_auto_top_up_field_hint: 'Vendos vlerën e rimbushjes',
    custom_auto_top_up_field_lower_hint: 'Vlera e rimbushjes nuk duhet të jetë më e madhe se %1$s %2$s',
    custom_auto_top_up_field_lower_hint_error: 'Ti e ke kaluar limitin e rimbushjes %1$s %2$s',
    custom_auto_top_up_field_upper_hint: 'Vendos vlerën',
    custom_auto_top_up_success_button: 'Faqja kryesore',
    custom_auto_top_up_success_message: 'Rimbushja prej %1$s drejt %1$s %2$s u krye me sukses.',
    custom_auto_top_up_success_title: 'Urime!',

    my_plan_primary_card_consumption: '%1$s nga %2$s',
    my_plan_primary_card_contract_end_label: 'Kontrata përfundon më',
    my_plan_primary_card_data: 'Internet',
    my_plan_primary_card_data_add_button: 'Shto internet',
    my_plan_primary_card_header_renewal_date: 'Data e skadencës %1$s',
    my_plan_primary_card_local_calls: 'Telefonata lokale',
    my_plan_primary_card_local_calls_add_button: 'Shto minuta',
    my_plan_primary_card_minimum_term_end_label: 'Paketa skadon më',
    my_plan_primary_card_price_label: 'në muaj',
    my_plan_primary_card_puk_code_label: 'Kodi PUK',
    my_plan_primary_card_renewal_date_label: 'Data e rinovimit',
    my_plan_primary_card_show_less: 'Më pak',
    my_plan_primary_card_show_more: 'Më shumë',
    my_plan_primary_card_sim_number_label: 'Nr i kartës SIM',
    my_plan_primary_card_sms: 'SMS',
    my_plan_primary_card_sms_add_button: 'Shto SMS',
    my_plan_primary_card_subtitle: 'Shërbime kryesore',
    my_plan_primary_card_subtitle_basic_bundle: 'Paketa kryesore',
    my_plan_primary_card_title: 'Shërbime aktive',
    my_plan_primary_card_title_subscription: 'Abonimi yt',
    my_plan_primary_card_unlimited_consumption: 'Pa limit',
    my_plan_primary_card_upgrade_plan: 'Përmirëso planin',
    my_plan_primary_card_upgrade_start_label: 'Eligible to upgrade from',
    my_plan_screen_error_message: 'Diçka nuk funksionoi.',
    my_plan_screen_title: 'Plani im',
    my_plan_screen_try_again: 'Provo përsëri',
    my_plan_bucket_status_active: 'Aktive',
    my_plan_bucket_status_suspended: 'Jo aktive',

    balance_history_section_title_today: 'Sot',
    balance_history_section_title_yesterday: 'Dje',
    balance_history_see_all_categories: 'Të gjitha',
    balance_history_title: 'Historiku',
    balance_screen_error_message: 'Diçka nuk funksionoi.',
    balance_screen_no_history_subtitle: 'Sapo të përdorësh telefonin do të mundesh të shikosh aktivitetin tënd',
    balance_screen_no_history_title: 'Nuk u gjend asnjë aktivitet',
    balance_screen_title: 'Aktiviteti',
    balance_screen_try_again: 'Provo përsëri',

    addons_default_addon_period: 'Muaj',
    addons_inactive_status: 'Pause',
    addons_list_section_description: 'Paketa shtesë aktive',
    addons_list_section_title: 'Paketat shtesë',
    addons_not_available: 'Ti nuk ke asnjë paketë shtesë me rinovim automatik.',
    addons_screen_error_message: 'Diçka nuk funksionoi.',
    addons_screen_title: 'Paketa shtesë',
    addons_screen_try_again: 'Provo përsëri',
    addons_show_less: 'Më pak',
    addons_show_more: 'Më shumë',
    addons_sub_text: '%1$s€ per %2$s',
    manage_addon_action_cancel: 'Anullo',
    manage_addon_action_confirm: 'Konfirmo',
    manage_addon_addons_title: 'Paketat shtesë',
    manage_addon_buy_action_button_title: 'Bli',
    manage_addon_buy_action_title: 'Bli paketa shtesë',
    manage_addon_buy_confirm_message: 'Je i sigurt që do të blesh paketën %1$s?',
    manage_addon_buy_date_title: 'Fillon sot',
    manage_addon_buy_more_details: 'Ti mund të përdorësh këtë paketë duke filluar nga sot.',
    manage_addon_buy_quick_action_title: 'Bli paketë shtesë',
    manage_addon_duration: '',
    manage_addon_remove_confirm_message: 'Je i sigurt që do ta çaktivizosh paketën %1$s?',
    manage_addon_remove_date_title: 'Data e skadencës',
    manage_addon_remove_more_details: 'Ti mund ta përdorësh këtë paketë deri më %1$s',
    manage_addon_remove_quick_action_title: 'Çaktivizo paketën',
    manage_addon_remove_action_button_title: 'Çaktivizo paketën',
    addons_sub_text: '%1$s',
    manage_addon_active_active_addon_title: 'Paketat aktive',
    Shop_Addons_See_All_Categories: 'Të gjitha',
    remove_addon_success_title: 'Sukses!',
    remove_addon_default_success_message: 'Kjo paketë shtesë u çaktivizua me sukses.',
    remove_addons_success_confirm: 'Okay',
    Confirm: 'Konfirmo',
    Cancel: 'Anullo',
    manage_addon_active_addon_title: 'Paketat aktive',
    shop_addons_see_all_categories: 'Të gjitha',

    messages_all_inbox_no_messages: 'Nuk ke asnjë njoftim.',
    messages_all_inbox_no_messages_description: '',
    messages_dashboard_access_secure_content_subtitle: 'Tap to access this secure area',
    messages_inbox_all: 'Të gjitha',
    messages_inbox_loading: 'Duke u ngarkuar…',
    messages_inbox_my_messages_header_title: 'Llogaria ime',
    messages_inbox_my_messages_title: 'Njoftimet e mia',
    messages_inbox_see_more: 'Më shumë',
    messages_inbox_show_less: 'Më pak',
    messages_inbox_show_more: 'Më shumë',
    messages_inbox_this_month: 'Ketë muaj',
    messages_inbox_unread_tab: 'Të palexuara %1$s',
    messages_inbox_unread_text: 'pa lexuar',
    messages_item_subtitle_new_unread: '%1$s njoftime te palexuar',
    messages_item_subtitle_no_messages: 'Nuk ka njoftime të reja',
    messages_item_title: 'Njoftimet e mia',
    messages_list_unread_header: 'Njoftimet e palexuara',
    messages_unread_inbox_no_messages: 'Nuk ke asnjë njoftim.',
    messages_unread_inbox_no_messages_description: '',
    remove_addons_error_title: 'Një gabim ka ndodhur!',
    remove_addons_back_button: 'Kthehu',
    netperform_speed_checker_more_info_title: 'Info',
    netperform_speed_checker_more_info_url: 'https://api-web-ci-vfal.vodafone.com/file/speedtest/more_info/MoreInfoSpeedTestAL.html',

    login_change_language_label: 'Ndrysho gjuhën',
    change_language_modal_button: 'Konfirmo',
    change_language_modal_subtitle: 'Zgjidh gjuhën e preferuar',
    change_language_modal_title: 'Ndrysho gjuhën',
    choose_account_type_not_registered_with_my_vodafone_label: 'Nuk je regjistruar në My Vodafone?',
    confirm_new_password_button: 'Konfirmo fjalëkalimin e ri',
    confirm_new_password_confirm_password_hint: 'Konfirmo fjalëkalimin',
    confirm_new_password_error_enter_valid_confirmation_password: 'Konfirmimi i fjalëkalimit i pavlefshëm',
    confirm_new_password_error_enter_valid_password: 'Fjalëkalim i pavlefshëm',
    confirm_new_password_password_hint: 'Fjalëkalimi',

    login_title_text: 'Hyr në My Vodafone',
    register_already_exists_warning_subtitle: 'Kjo llogari ekziston!',
    register_already_exists_warning_text: 'Numri %1$s është i lidhur me një llogari. Vendos një numër tjetër ose ndrysho të dhënat e llogarisë.',
    register_already_exists_warning_title: 'Krijo një llogari',
    register_confirm_password_hint: 'Konfirmo fjalëkalimin',
    register_different_account_button: 'Hyr në një llogari tjeter',
    register_error_enter_valid_confirmation_password: 'Konfirmimi i fjalëkalimit i pavlefshëm',
    register_error_enter_valid_password: 'Fjalëkalim i pavlefshëm',
    register_error_enter_valid_phone: 'Numri i pasaktë',
    register_helper_information: 'Here are some friendly tips and helpful advice for the user',
    register_login_text: 'Regjistrohu',
    register_password_hint: 'Fjalëkalimi',
    register_phone_number_hint: 'Numri i telefonit',
    register_registered_with_my_vodafone_label: 'Je i regjistruar?',
    register_reset_password_button: 'Ndrysho fjalëkalimin',
    register_use_your_credentials: 'Vendos të dhënat',
    register_verifying_loading_text: 'Duke verifikuar…',
    registered_account_register_now_label: 'Regjistrohu tani',
    soft_login_add_valid_phone_number: 'Vendos një numër të saktë',
    soft_login_error_title: 'Diçka nuk funksionoi',
    soft_login_error_try_again: 'Provo përsëri.',
    soft_login_footer_first_label: 'Aksesi do të jetë i limituar në qoftë se',
    soft_login_footer_second_label: 'nuk vendos të dhënat e tua.',
    soft_login_hint: 'Numri i telefonit',
    soft_login_next_label: 'Vazhdo',
    upfront_forgot_your_password_label: 'Keni harruar fjalëkalimin?',
    upfront_email_or_phone_number_hint: 'Numri i telefonit',
    upfront_login_error_enter_valid_email: 'Vendos një numër të saktë',
    upfront_login_error_enter_valid_password: 'Vendos një fjalëkalim më të fortë',
    upfront_login_error_message: 'Numri ose fjalëkalimi nuk janë të saktë.',
    upfront_login_error_multiple_attempts_remaining: 'Ti ke dhe %1$s mundësi të mbetura. Provo përsëri.',
    upfront_login_error_single_attempt_remaining: 'Ti ke dhe %1$s mundësi të mbetur. Provo përsëri.',
    upfront_login_error_title: 'Numri ose fjalëkalimi nuk janë të saktë.',
    upfront_login_error_try_again: 'Provo përsëri.',
    upfront_login_error_try_again_single_attempt: 'Provo përsëri. 1 mundësi e mbetur',
    upfront_login_helper_information: 'Vendos numrin tënd të telefonit dhe fjalëkalimin për të krijuar llogarinë në My Vodafone',
    upfront_login_invalid_password: 'Fjalëkalimi nuk është të saktë.',
    upfront_login_or_text: 'ose',
    upfront_login_reset_account_login_button: 'Ndrysho të dhënat e llogarisë',
    upfront_login_text: 'Hyr',
    upfront_not_registered_with_my_vodafone_label: 'Nuk je i regjistruar në My Vodafone?',
    upfront_our_privacy_label: 'Politika e privatësisë',
    upfront_password_hint: 'Fjalëkalimi',
    upfront_register_now_label: 'Regjistrohu tani',
    upfront_remember_me_label: 'Më mbaj mënd',
    verification_change_number_text: 'Ndrysho numrin e telefonit',
    verification_description_receive_sms: 'Ti do të marrësh një SMS me një kod 5 shifror në 3 minutat në vazhdim',
    verification_enter_code_text: 'Vendos kodin 5 shifror',
    verification_error_code_is_not_correct: 'Kodi nuk është i saktë',
    verification_loading_login_text: 'Duke hyrë…',
    verification_resend_login_code_text: 'Ridërgo kodin',
    verification_resend_login_code_text_with_timer: 'Ridërgo kodin (%1$s)',
    verification_sent_code_text: 'Kodi i verfikimit është dërguar drejt:',
    verification_verifying_login_code_text: 'Duke verifikuar…',
    verification_wrong_phone_error_msg: 'Diçka nuk funksionoi',

    product_and_services_sub_header_title_prepaid: 'Balanca',
    product_and_services_sub_header_title_postpaid: 'Fatura',
    product_and_services_after_sub_header_title_prepaid: ' kredit i mbetur',
    product_and_services_after_sub_header_title_postpaid: ' për t\'u paguar',
    product_and_services_top_up_button: 'Rimbush',
    product_and_services_after_sub_header_title_fix: ' a month',
    addons_timeline_today: 'Sot',

    //privacy permission onboarding
    privacy_permissions_intro_title: 'Përditësime të rëndësishme në konfigurimet e privatësisë ',
    privacy_permissions_intro_content_p1: 'Në Vodafone, ne vlersojmë shumë përdorimin korrekt të të dhënave personale.',
    privacy_permissions_intro_content_p2: 'Për të përmirësuar ndjeshëm eksperiencën tënde, ne studiojmë infromacionet e përdorimit të rrjetit.',
    privacy_permissions_intro_more_content_p1: 'Ne, herë pas here, mund të të infromojmë rreth ofertave dhe shërbimeve nga partnerët tanë nëse mendojmë se të interesojnë.',
    privacy_permissions_intro_more_content_p2: 'Infromacione si përdorimi dhe cilësia e rrjetit, të dhënat nga trafiku i rrjetit si dhe vendndodhja na ndihmon për të personalizuar shërbime të krijuara posaçërisht për ty.',
    privacy_permissions_intro_more_content_p3: 'Ne sigurohemi të jemi plotësisht në zbatim të ligjit Shqiptar dhe Europian.',
    privacy_permissions_intro_more_content_p4: 'Ti mundesh, në çdo kohë të ushtrosh të gjitha të drejtat e mundësuara nga ligji për të proçesuar informacione duke dërguar një kërkesë në adresën alfunc.askprivacy@vodafone.com',
    privacy_permissions_intro_action_item1: 'Mesazhet dhe telefonatat e tua nuk do të aksesohen',
    privacy_permissions_intro_action_item2: 'Merr ofertat më të fundit në produktet dhe shërbimet e krijuara vetëm për ty',
    privacy_permissions_intro_action_item3: 'Përditësohu me informacione rreth llogarisë tënde',
    privacy_permissions_section_item1_title: 'Vendos konfigurimet e privatësisë',
    privacy_permissions_section_item1_content1: 'Kontrollo si Vodafone përdor të dhënat e tua për të krijuar oferta dhe menaxho preferencat e kontaktit',
    privacy_permissions_section_item1_content2: 'Mund ti ndryshosh të gjitha këto tek konfigurimet e privatësisë.',
    privacy_permissions_section_item2_title: 'Oferta të krijuara posaçërisht për ty',
    privacy_permissions_section_item2_content1: 'Për të përmirësuar eksperiencën tënde, ne mund të shohim se si i përdor shërbimet në rrjet.',
    privacy_permissions_section_item2_content2: 'Infromacione si përdorimi dhe cilësia e rrjetit, të dhënat nga trafiku i rrjetit si dhe vendndodhja na ndihmon për të personalizuar shërbime të krijuara posaçërisht për ty.',
    privacy_permissions_section_item2_more_content_para1: 'Ne, herë pas here, mund të të infromojmë rreth ofertave dhe shërbimeve nga partnerët tanë nëse mendojmë se të interesojnë.',
    privacy_permissions_section_item2_more_content_para2: 'Ti mund të kontrollosh informacionin të cilin mbledhim dhe përdorimin në tre mënyra dhe mund ti përditësosh në çdo moment:',
    privacy_permissions_section_item2_more_content_bullet1: 'Duke përdorur konfigurimet e privatësisë si më poshtë për të kontrolluar të dhënat për rrjetin',
    privacy_permissions_section_item2_more_content_bullet2: 'Çdo aplikacion i Vodafone ka sektorin e vet të konfigurimeve të cilat i kontrollon brenda në aplikacion.',
    privacy_permissions_section_item2_more_content_bullet3: 'Duke kontrolluar si përdoren cookies në web ',
    privacy_permissions_section_item2_more_content_info_what: 'Çfarë: ',
    privacy_permissions_section_item2_more_content_info_what_desc: 'nformacion rreth përdorimit të produkteve dhe shërbimeve dhe ku e përdoret rrjeti ',
    privacy_permissions_section_item2_more_content_info_why: 'Pse: ',
    privacy_permissions_section_item2_more_content_info_why_desc: 'Ne mund të të shfaqim ofertat më të fundit rreth produkteve dhe shërbimeve tona, ofrojmë shërbim klienti të personalizuar duke u siguruar që rrjeti jonë celular të jetë i disponueshëm kudo ke nevojë. ',
    privacy_permissions_section_item3_title: 'Preferencat e kontaktit ',
    privacy_permissions_section_item3_content: 'Herë pas here ne mund të të kontaktojmë rreth ofertave më të fundit.',
    privacy_permissions_section_item3_more_content_info_what: 'Çfarë: ',
    privacy_permissions_section_item3_more_content_info_what_desc: 'Informacion se si mund të të kontaktojmë ',
    privacy_permissions_section_item3_more_content_info_why: 'Pse: ',
    privacy_permissions_section_item3_more_content_info_why_desc: 'Që të të mbajmë të përditësuar me çdo informacion rreth llogarisë tënde ',
    product_section_title: "Produktet e mia",
    accounts_section_title: "Llogaria ime",
    support_section_title: "Suport",
    basic_section_title: "Shërbime bazë",
    my_recharge_section_title: "Rimbush numrin tim",
    flower_landing_title: 'Koleksioni i luleve',
    //Contracts
    contracts_header_title: 'Kontratat e mia',
    contracts_mobile_title: 'Shërbimi celular',
    contracts_fixed_title: 'Shërbimi Fix',
    contracts_download_button_label: 'Shkarkoni PDF',
    contracts_try_again: 'Provo përsëri',
    contracts_fixed_no_contracts: 'Ti nuk ke asnjë kontratë',
    contracts_price: 'Çmimi',
    contracts_contract_duration: 'Kohëzgjatja e kontratës',
    contracts_first_day: 'Dita e parë e kontratës',
    contracts_last_day: 'Dita e fundit e kontratës',
    contracts_months: '%1$s Muaj',

    //my orders
    my_orders_action_order_summary: 'Përmbledhja e kërkesës',
    my_orders_title: 'Porositë e mia',
    my_orders_manage_your_orders: 'Menaxho kërkesat',
    my_orders_number_of_orders_inprogress: ' kërkesa në progres',
    my_orders_number_of_order_inprogress: ' kërkesë në progres',
    my_orders_inprogress: 'Në progres',
    my_orders_pastorders: 'Kërkesa të mbyllura',
    my_orders_order_number: 'Numri i kërkesës:',
    my_orders_price: 'Çmimi: ',
    my_orders_start_date: 'Data e kërkesës: %1$s',
    my_orders_status: 'Statusi:',
    my_orders_summary_total_without_tax: 'Total pa TVSH',
    my_orders_summary_total_tax_rate: 'Tatimi',
    my_orders_summary_total_device_cost: 'Total:',
    my_orders_summary_total_to_pay: 'Totali për tu paguar',
    my_orders_summary_total: 'Pagesë totale',
    my_orders_no_inprogess_orders: 'Nuk keni asnjë kërkesë në progres',
    my_orders_no_past_orders: 'Nuk keni asnjë kërkesë të mbyllur',
    my_orders_try_again: 'Provo përsëri',
    my_orders_scan_button: 'Skano barkodin e kontratës',
    //orders statues
    my_orders_acknowledged_status: 'Pranuar',
    my_orders_device_returned_status: 'Pajisja u kthye',
    my_orders_pending_status: 'Në pritje',
    my_orders_held_status: 'Held',
    my_orders_inprogress_status: 'Në progres',
    my_orders_completed_status: 'E përfunduar',
    my_orders_canceled_status: 'Anuluar',
    general_screen_title: 'General',
    language_screen_title: 'Gjuha',
    privacy_screen_title: 'Privacy',
    order_progress_alert_button: 'Get progress alerts',
    order_progress_collection_status: 'Ready for collection',
    order_progress_confirmed_status: 'Confirmed',
    order_progress_delivered_status: 'Delivered',
    order_progress_dispatched_status: 'Dispatched',
    order_progress_estimated_delivery: '',
    order_progress_installation_status: 'Installation',
    order_progress_screen_title: 'Order no. %1$s',
    order_progress_status: 'Status: %1$s',
    order_progress_timeline_action: 'View order details',
    order_progress_timeline_collection_title: 'Ready for collection',
    order_progress_timeline_confirmed_title: 'Order confirmed',
    order_progress_timeline_delivered_title: 'Order delivered',
    order_progress_timeline_dispatched_title: 'Order dispatched',
    order_progress_timeline_installation_title: 'Installation',
    order_progress_timeline_step_update_time: 'Update time:',
    order_progress_timeline_subtitle: 'Order placed: %1$s',
    order_progress_timeline_title: 'Order updates',
    order_progress_timeline_transit_title: 'Order in transit',
    order_progress_transit_status: 'In transit',

    order_progress_title: 'Kërkesa nr. %1$s',
    order_progress_account: "Llogaria e %1$s",
    order_progress_alert_button: 'Merr njoftimes',
    order_progress_screen_title: 'Kërkesa nr. %1$s',
    order_progress_status: 'Status: Kërkesa %1$s',
    order_progress_delivery: 'Parashikimi i dorëzimit: %1$s',
    order_progress_timeline_step_update_time: 'Koha e përditësimit:',
    order_progress_timeline_subtitle: 'Kërkesa u ekzekutua %1$s',
    order_progress_timeline_title: 'Përditësime të kërkesës',
    order_progress_scan_button: 'Skano',
    order_progress_active_sim_title: "Ti ke aktivizuar",
    order_progress_active_sim_subtitle: 'Vodafone SIM',
    order_progress_active_sim_dashboard: 'Kthehu në faqen kryesore',
    order_progress_error: 'Ups!',
    order_progress_error_message: 'Dicka nuk funksionoi',
    order_progress_close: 'Mbyll',
    order_progress_order_confirmed: 'Kërkesa u konfirmua',
    order_progress_sim_delivery: 'SIM i dorëzuar',
    order_progress_order_completed: 'Kërkesa e përfunduar',
    order_progress_activate_service: 'Aktivizo shërbimin',
    active_sim_title: 'Shijo më të mirat e Vodafone',
    active_sim_first_item_title: 'Rrjeti më i mirë i internetit celular dhe fiks',
    active_sim_second_item_title: 'Eksperienca më e mirë dixhitale në Shqipëri',
    active_sim_third_item_title: 'Suporti më i mirë i klientit',
    active_sim_card_title: 'Vodafone SIM e re',
    active_sim_card_subtitle: 'Numër telefoni',
    active_sim_card_active: 'Aktive',
    scan_sim_scan_button: 'Skano kartën SIM',
    scan_sim_title: 'Karta jote SIM është aktive.',
    scan_sim_subtitle: 'Skano kartën SIM dhe shijo produktet dhe shërbimet Vodafone',
    summer_promo_title: 'Summer Bonus',
    summer_promo_activate_offer: 'Aktivizo',
    summer_promo_close: 'Mbyll',
    summer_promo_try_again: 'Provo përsëri',
    summer_promo_error_title: 'Ka ndodhur një problem!',
    summer_promo_congratulations: 'Urime!',
    summer_promo_details_unit: 'Ditë',
    summer_promo_details_label: 'Vlefshmëria',
    summer_promo_active_offer: 'Ju e keni aktivizuar një herë paketën Summer Bonus.',
    summer_promo_went_wrong: 'Diçka nuk funksionoi!',
    summer_promo_try_again: 'Provo përsëri',

    //Recommend Vodafone to a friend screen
    recommend_title: 'Sill nje mik',
    recommend_share_code: 'Ndaj kodin',
    recommend_read_terms: 'Lexo kushtet dhe afatet',
    recommend_terms_modal_title: 'Kushte dhe afate',
    recommend_agree_terms: 'Pranoj të gjitha kushtet dhe politikën e privatësisë',
    reccomend_terms_info: 'Duke shtypur ‘Pranoj’ ti pranon të gjitha kushtet.',
    recommend_continue: 'Vazhdo',
    recommend_info: 'Info',
    recommend_successful_referrals: 'Referime te sukseshme: %1$s',
    recommend_referral_item_joined_date: 'Regjistruar më %1$s',
    recommend_no_referrals: 'Ketu do te shfaqen miqte e tu, sapo te perfundoje me sukses verifikimi i kodit.',
    recommend_try_again: 'Provo përsëri',
    recommend_share_message: 'Ndaj kodin: %1$s me miqtë',
    recommend_more_info_url: 'https://api-web-ci-vfal.vodafone.com/file/affiliate/more_info/MoreInfoAffiliateAL.html',
    reccomend_terms_url: 'https://api-web-ci-vfal.vodafone.com/file/affiliate/terms_and_cond/TermsAndCondAL.html',

    recommend_successful_referral: 'Successful Referral',
    recommend_successful_referral_title: 'Great news! %1$s joined Vodafone',
    recommend_successful_referral_subtitle: 'Your referral was successful! We have added 15GB to your account for the next 3 months. Enjoy!',
    recommend_successful_referral_dashboard: 'Return to Dashboard',
    recommend_successful_referral_close: 'Refer more friends',

    offline_header_is_offline: 'You are offine',
    offline_header_is_online: 'You are online again',
    //hyperlink
    hyperlink_screen_title: 'Statusi i numrit',
    hyperlink_postpaid_title: 'Validiteti i kontratës',
    hyperlink_puk: 'PUK',
    hyperlink_copy_puk_button: 'Kopjo',
    hyperlink_starting_from: 'Duke filluar nga',
    hyperlink_until: 'Deri më',
    hyperlink_duration: 'Kohëzgjatja',
    hyperlink_last_recharge: 'Rimbushja e fundit',
    hyperlink_recharge_date: 'Data e rimbushjes',
    hyperlink_balance_duration: 'Kohëzgjatja e balancës',
    hyperlink_balance_validity: 'Validiteti i balancës',
    hyperlink_deactive: 'Jo aktive nga',
    hyperlink_info_text: 'Kontrollo dhe verifiko te dhënat e perditësuara rreth numrit tënd. Kopjo kodin PUK për të zhbllokuar numrin.',
    hyperlink_more_info_url: 'https://api-web-ci-vfal.vodafone.com/file/hyperlink/more_info/MoreInfoHyperlinkAL.html',
    hyperlink_copy_puk: 'Kopjuar',
    hyperlink_months: ' muaj',

    fix_manual_product_title: "Shto shërbimin fix",
    fix_manual_product_subtitle: "Produktet e mia",
    fix_manual_product_first_step_desc: " Nuk u gjend asnjë kontratë me të dhënat e tua. Në qoftë se ke një kontratë interneti me Vodafone mund ta shtosh atë manualisht.",
    fix_manual_product_add_contract: "Shto kontratë tjeter",
    fix_manual_product_cancel: "Anullo",
    fix_manual_product_second_step_desc: "Një nga të dhënat personale (Numri personal ose numri i telefonit) nuk përputhen me të dhënat në sistem. Përditëso të dhënat personale ose shto manualisht kontratën.",
    fix_manual_product_add_data_step_desc: "Shto kontratën fix në My Vodafone që të kesh akses në të gjitha shërbimet tona digjitale.",
    fix_manual_product_nipt_or_id: "Numër personal ose NIPT",
    fix_manual_product_id: "Numër personal",
    fix_manual_product_nipt: "Numër NIPT",
    fix_manual_product_msisdn: "Numri i telefonit",
    fix_manual_product_wrong_number_format: "Formati i numrit është gabim",
    fix_manual_product_wrong_nipt_or_id_format: "Formati i ID ose NIPT është gabim",
    fix_manual_product_verify_step_title: "Kodi i verifimit",
    fix_manual_product_verify_step_subtitle: "Kodi i verifikimit u dërgua drejt:",
    fix_manual_product_verify_step_desc: "Vendos kodin:",
    fix_manual_product_verify_code_resend_code_disabled: "Ri-dërgo kodin(%1$s)",
    fix_manual_product_verify_code_resend_code: "Ri-dërgo kodin",
    fix_manual_product_verify_code_error: "Kodi i vendosur është i pavlefshëm",
    fix_manual_product_default_error_message: "Një nga të dhënat personale (Numri personal ose numri i telefonit) nuk përputhen me të dhënat në sistem. Përditëso të dhënat personale ose shto manualisht kontratën.",
    fix_manual_product_try_again: 'Provo përsëri',
    fix_manual_product_close: 'Mbyll',
    fix_manual_product_final_step_subtitle: 'Kontratat e tua',
    fix_manual_product_final_step_desc: 'Kontrate e fix u shtua në profilin tënd të My Vodafone.',
    fix_manual_id_type: 'Zgjidh llojin e identifikimit',
    fix_manual_personal_id: 'Numër personal',
    fix_manual_nipt: 'NIPT',
    select_amount: 'Zgjidhni Shumën',
    tranfer_balance: 'Transfero Balancën',
    call_me_back: 'Më telefono',
    pns_fix_price_month: 'Çmimi në muaj',
    pns_fix_min_terms_ends: 'Kontrata përfundon më',
    pns_fix_start_date: 'Data e fillimit',
    pns_fix_upgrade_plan: 'Përmireso planin',
    pns_fix_card_active_status: 'Aktive',
    pns_fix_card_inactive_status: 'Jo aktive',
    pns_fix_card_in_process_status: 'Në pritje',
    pns_fix_no_tv_addons: "Nuk keni paketa shtesë TV",

    tutorial_next_step: 'Hapi tjetër',
    tutorial_last_step: 'Vazhdo',

    biometric_login_use_biometry_type: 'Përdor %1$s',

    prelogin_welcome: 'Mirësevjen në My Vodafone',
    prelogin_quick_links: 'Zbulo produktet Vodafone',
    prelogin_getStarted: 'Hyr',
    prelogin_continue_as_guest: 'Vazhdo si Guest',

    guest_pop_up_title: 'Për të zbuluar më shumë rreth produkteve, shërbimeve dhe ofertave të Vodafone, duhet të krijosh një llogari',
    guest_pop_up_description: 'Ti mund të krijosh një llogari në aplikacion edhe nëse nuk je klient Vodafone',
    guest_pop_up_login: "Hyr",
    guest_pop_up_signup: "Regjistrohu",

    story_offerExpires: 'Koha e vlefshme e ofertes: %1$s',
    support_dashboard_modal_title: "Hello I'm TOBi",
    support_dashboard_modal_subtitle: "How can I help you?",
    support_dashboard_help_button: 'Ask me a question',
    support_dashboard_modal_desc: "Quick help",
    tobi_interactive_help_message: 'Let me know if you need any help',
    tooltips_card_next_button_title: 'Next',

    tooltips_first_card_title_hybrid_fix: 'My products & account',
    tooltips_second_card_title_hybrid_fix: 'My phone',
    tooltips_third_card_title_hybrid_fix: 'My bills',
    tooltips_fourth_card_title_hybrid_fix: 'My offers',
    tooltips_first_card_subtitle_hybrid_fix: 'test',
    tooltips_second_card_subtitle_hybrid_fix: 'test',
    tooltips_third_card_subtitle_hybrid_fix: 'test',
    tooltips_fourth_card_subtitle_hybrid_fix: 'test',

    tooltips_first_card_title_hybrid: 'My products & account',
    tooltips_second_card_title_hybrid: 'My phone',
    tooltips_third_card_title_hybrid: 'My bills',
    tooltips_fourth_card_title_hybrid: 'My offers',
    tooltips_first_card_subtitle_hybrid: 'test',
    tooltips_second_card_subtitle_hybrid: 'test',
    tooltips_third_card_subtitle_hybrid: 'test',
    tooltips_fourth_card_subtitle_hybrid: 'test',


    tooltips_first_card_title_prepaid_fix: 'My products & account',
    tooltips_second_card_title_prepaid_fix: 'My phone',
    tooltips_third_card_title_prepaid_fix: 'My bills',
    tooltips_fourth_card_title_prepaid_fix: 'My offers',
    tooltips_first_card_subtitle_prepaid_fix: 'test',
    tooltips_second_card_subtitle_prepaid_fix: 'test',
    tooltips_third_card_subtitle_prepaid_fix: 'test',
    tooltips_fourth_card_subtitle_prepaid_fix: 'test',

    tooltips_first_card_title_prepaid: 'My products & account',
    tooltips_second_card_title_prepaid: 'My phone',
    tooltips_third_card_title_prepaid: 'My bills',
    tooltips_fourth_card_title_prepaid: 'My offers',
    tooltips_first_card_subtitle_prepaid: 'test',
    tooltips_second_card_subtitle_prepaid: 'test',
    tooltips_third_card_subtitle_prepaid: 'test',
    tooltips_fourth_card_subtitle_prepaid: 'test',


    tooltips_first_card_title_postpaid_fix: 'My products & account',
    tooltips_second_card_title_postpaid_fix: 'My phone',
    tooltips_third_card_title_postpaid_fix: 'My bills',
    tooltips_fourth_card_title_postpaid_fix: 'My offers',
    tooltips_first_card_subtitle_postpaid_fix: 'test',
    tooltips_second_card_subtitle_postpaid_fix: 'test',
    tooltips_third_card_subtitle_postpaid_fix: 'test',
    tooltips_fourth_card_subtitle_postpaid_fix: 'test',

    tooltips_first_card_title_postpaid: 'My products & account',
    tooltips_second_card_title_postpaid: 'My phone',
    tooltips_third_card_title_postpaid: 'My bills',
    tooltips_fourth_card_title_postpaid: 'My offers',
    tooltips_first_card_subtitle_postpaid: 'test',
    tooltips_second_card_subtitle_postpaid: 'test',
    tooltips_third_card_subtitle_postpaid: 'test',
    tooltips_fourth_card_subtitle_postpaid: 'test',

    tooltips_first_card_title_non_vf: 'My products & account',
    tooltips_second_card_title_non_vf: 'My phone',
    tooltips_third_card_title_non_vf: 'My bills',
    tooltips_fourth_card_title_non_vf: 'My offers',
    tooltips_first_card_subtitle_non_vf: 'test',
    tooltips_second_card_subtitle_non_vf: 'test',
    tooltips_third_card_subtitle_non_vf: 'test',
    tooltips_fourth_card_subtitle_non_vf: 'test',

    tooltips_first_card_title_guest: 'My products & account',
    tooltips_second_card_title_guest: 'My phone',
    tooltips_third_card_title_guest: 'My bills',
    tooltips_fourth_card_title_guest: 'My offers',
    tooltips_first_card_subtitle_guest: 'test',
    tooltips_second_card_subtitle_guest: 'test',
    tooltips_third_card_subtitle_guest: 'test',
    tooltips_fourth_card_subtitle_guest: 'test',


    tooltips_first_card_title_fix: 'My products & account',
    tooltips_second_card_title_fix: 'My phone',
    tooltips_third_card_title_fix: 'My bills',
    tooltips_fourth_card_title_fix: 'My offers',
    tooltips_first_card_subtitle_fix: 'test',
    tooltips_second_card_subtitle_fix: 'test',
    tooltips_third_card_subtitle_fix: 'test',
    tooltips_fourth_card_subtitle_fix: 'test',

    // BBV
    bigbrother_bbv_tittle: 'Big Brother VIP 2',
    bigbrother_no_balance: 'Ju nuk keni balancë të mjaftueshme për të votuar.',
    bigbrother_refill_now: 'Rimbushni tani për të votuar banorin e preferuar.',
    bigbrother_voting_closed: 'Votimi u mbyll.',
    bigbrother_confirm: 'Konfirmo',
    bigbrother_confirmation: 'Konfirmim',
    bigbrother_details: 'Detaje',

    bbadminpanel_bbv_administration: 'BBV Administration',
    bbadminpanel_voting_status_label: 'Hap / Mbyll votimin',
    bbadminpanel_voting_status_open: 'Hapur',
    bbadminpanel_voting_status_closed: 'Mbyllur',
    bbadminpanel_voting_type_label: 'Shpëto / Elemino',
    bbadminpanel_voting_type_save: 'Shpëto',
    bbadminpanel_voting_type_eliminate: 'Elemino',
    bbadminpanel_set_voting: 'Vendos votimin',
    bbadminpanel_add_title: 'Vendos titullin kryesor',
    bbadminpanel_select_housemates: 'Zgjidh banorët',
    bbadminpanel_post_voting: 'Posto',
    bbadminpanel_text_title: 'Titulli',

    SMS: 'SMS',
    MIN: 'MIN',
    recommended_bundles_screen_title: 'Ofertat e mia',
    recommended_bundles_title: 'Ekskluzivisht për ty',
    recommended_bundles_subtitle: 'Zgjidh ofertën e preferuar dhe shijo komunikim të pandërprerë.',
    recommended_bundles_button: 'Shiko të gjitha paketat',
    recommended_bundles_view_more: 'Shiko më shumë',
    recommended_bundles_activate: 'Aktivizo tani',

    reward_loyalty_non_vf_message: 'Cannot use this feature',
    reward_loyalty_show_more: 'Më shumë',
    reward_loyalty_show_less: 'Më pak',
    january: 'Janar',
    february: 'Shkurt',
    march: 'Mars',
    april: 'Prill',
    may: 'Maj',
    june: 'Qershor',
    july: 'Korrik',
    august: 'Gusht',
    september: 'Shtator',
    october: 'Tetor',
    november: 'Nentor',
    december: 'Dhjetor',
    January: 'Janar',
    February: 'Shkurt',
    March: 'Mars',
    April: 'Prill',
    May: 'Maj',
    June: 'Qershor',
    July: 'Korrik',
    August: 'Gusht',
    September: 'Shtator',
    October: 'Tetor',
    November: 'Nentor',
    December: 'Dhjetor',
    Jan: 'Jan',
    Feb: 'Shk',
    Mar: 'Mar',
    Apr: 'Pri',
    Jun: 'Qer',
    Jul: 'Korr',
    Aug: 'Gush',
    Sep: 'Shta',
    Oct: 'Tet',
    Nov: 'Nën',
    Dec: 'Dhj',
    morning: 'Paradite',
    midday: 'Dreke',
    afterlunch: 'Pasdite',
    my_products: 'Produktet e mia',
    my_contracts: 'Kontratat e mia',
    my_orders: 'Porositë e mia',
    your_appointments_service_assistance: 'Asistencë',

    // Roaming
    roaming_settings_screen_title: 'Konfigurime Roaming',
    roaming_settings_location_permission_title: 'Autorizimi i Vendndodhjes',
    roaming_settings_location_permission_description: 'Kliko për të zbuluar më shumë rreth vendndodhjes',
    roaming_settings_learn_more: 'Më shumë',
    roaming_settings_screen_description: 'Ne menyre qe te perdorni roaming ju duhet ta ndizni ate ne konfigurimet e pajisjes, si dhe sigurohuni qe qe konfigurimi i roaming te jete i ndezur ne My Vodafone.',
    roaming_settings_device_settings_title: 'Konfigurime të Celularit',
    roaming_settings_device_settings_description: 'Përpara se të udhëtosh, sigurohu që shërbimi "Data Roaming" në celularin tënd të jetë aktiv',
    roaming_settings_network_settings_title: 'Konfigurimet e Rrjetit',
    roaming_settings_network_settings_description: 'Ndiz roaming ne rrjetin e Vodafone ne menyre qe te perdoresh SIM jashte vendit',
    roaming_settings_device_settings_how_to_enable_roaming_title: 'Si të aktivizosh Roaming në celularin tënd:',
    roaming_settings_network_settings_toggle_title: 'Sherbimet Roaming',
    roaming_settings_network_settings_popup_title: 'Ju po hiqni sherbimin roaming nga celulari juaj.',
    roaming_settings_network_settings_popup_description: 'A jeni te sigurte qe deshironi te vazhdoni?',
    roaming_settings_network_settings_toggle_calls: 'Telefonata',
    roaming_settings_network_settings_toggle_SMS: 'SMS',
    roaming_settings_network_settings_toggle_data: 'Internet',
    roaming_settings_network_settings_administrator_contact: "!!! Kontakto administratorin per te pasur mundesine te ndryshoni kete filter!",
    roaming_settings_dataCap_title: 'Kontrolli i Internetit',
    roaming_settings_dataCap_description: 'Mbaj nën kontroll shpenzimet e tua të internetit në Roaming nëpërmjet shërbimit "Kontrolli i Internetit". Kur ky shërbim është aktiv, ti njoftohesh kur je duke përdorur internet me tarifa standarde Roaming si dhe në limitet e mëposhtme:',
    roaming_settings_locationPermission_steps_title: "Location Settings",
    roaming_settings_locationPermission_steps_description: "Si të japësh autorizimin për vendndodhjen në celularin tënd:",
    roaming_close: "Mbyll",
    roaming_settings_data_cap: 'Kontrolli i Internetit',
    roaming_settings_data_cap_default_error: 'Dicka nuk funksionoi! Provo përsëri',

    roaming_settings_location_step_one_android: "Shko te Settings",
    roaming_settings_location_step_two_android: "Location",
    roaming_settings_location_step_three_android: "Ndiz Location Services",
    roaming_settings_roaming_step_one_android: "Shko tek Settings",
    roaming_settings_roaming_step_two_android: "Connections",
    roaming_settings_roaming_step_three_android: "Mobile Network",
    roaming_settings_roaming_step_four_android: "Ndiz Data roaming",

    roaming_settings_location_step_one_ios: "Shko te Settings",
    roaming_settings_location_step_two_ios: "Privacy",
    roaming_settings_location_step_three_ios: "Location Services",
    roaming_settings_location_step_four_ios: "Ndiz Location Services",
    roaming_settings_roaming_step_one_ios: "Konfigurimet",
    roaming_settings_roaming_step_two_ios: "Mobile data",
    roaming_settings_roaming_step_three_ios: "Mobile Data Options",
    roaming_settings_roaming_step_four_ios: "Ndiz Data Roaming",

    billing_details_title: 'Fatura e muajit %1$s',
    billing_details_header_title: 'Fatura e %1$s',
    billing_credit_card_paid_date: 'Paguaj deri më %1$s%2$s %3$s',
    billing_current_bills_title: 'Faturat e fundit',
    billing_user_payment_modal_title: 'Pagesë fature',
    billing_user_payment_modal_subtitle: 'Nuk mund të paguash faturën ',
    billing_user_payment_modal_desc: 'Vetëm administratori i biznesit mund të kryejë pagesën për numrin tënd dhe pjestarët e tjerë të grupit. Ti mund të shikosh vetëm detajet e faturës.',
    billing_user_payment_modal_button_title: 'Mbyll',
    billing_user_payment_modal_error_title: 'Ups!',
    billing_user_payment_modal_error_desc: 'Diçka nuk funksionoi!',
    billing_user_payment_modal_error_button: 'Anullo',
    billing_chooser_payment_modal_button_title: 'Ndrysho në llogari biznesi',
    billing_chooser_payment_modal_subtitle: 'Informacion',
    billing_chooser_payment_modal_desc: 'Për të vijuar me pagesën e faturës, duhet të kesh të drejta administratori. Hyr në llogarinë e Biznesit duke zgjedhur Ndrysho Llogarinë.',
    billing_bills_type_fix: 'Shërbimi fiks',
    billing_bills_type_mobile: 'Shërbimi celular',
    billing_breakdown_filter_role_company: 'Biznesi im',
    billing_breakdown_filter_role_employee: 'Përdoruesit',
    billing_breakdown_filter_role_service: 'Produkte të tjera',
    billing_breakdown_filter_role_fix: 'Shërbimi fiks',
    billing_current_bill_products: 'Produkte',
    everything_is_ok_my_phone_title: 'Numri im',
    dashboard_app_open_action_label: 'Hyr',
    dashboard_billing_postpay_card_subtitle: 'Shpenzimi i muajit aktual',
    dashboard_billing_card_pay_date: "Paguaj deri më %1$s%2$s %3$s",
    dashboard_billing_last_bills_pay_date: "Paguaj deri më %1$s%2$s %3$s",
    dashboard_fix_lines: '%1$s Linja Fikse',
    dashboard_fix_line: '1 Linjë Fix',
    dashboard_fix_line: '1 Linjë Fikse',
    dashboard_business_usage: "Plani yt i Biznesit",
    dashboard_business_usage_users: '%1$s Përdorues',
    dashboard_business_usage_reset_days: 'Ripërtërihet për %1$s ditë',
    dashboard_business_usage_reset_day: 'Ripërtërihet për 1 ditë',
    dashboard_business_usage_reset_next_day: 'Ripërtërihet ditën e nesërme',
    subtray_business_section_title: 'Biznesi im',
    subtray_business_section_users: ' %1$s Përdorues',
    subtray_business_section_user: '- 1 Përdorues',
    subtray_business_section_button_title: 'Pasqyra e biznesit',
    sub_tray_select_one: 'Zgjidh',
    delete_account_profile_note_description: 'Profili yt ka të dhëna që përmirësojnë eksperiencën tënde në Vodafone',
    top_up_someone_else_quick_action_failure_description: 'Nuk mund të procesojme këtë kërkesë. Verifiko detajet e pagesës.',
    top_up_someone_else_quick_action_failure_primary_button_text: 'Provo përsëri',
    top_up_someone_else_quick_action_failure_secondary_button_text: 'Kthehu',
    top_up_someone_else_quick_action_failure_title: 'Dicka nuk funksionoi!',
    top_up_someone_else_quick_action_subtitle: 'Numrin tim',
    top_up_someone_else_quick_action_title_with_formatting: 'Rimbush %1$s',
    top_up_someone_else_section_title: 'Zgjidh një kontakt',
    top_up_someone_else_successful_description: 'Ti sapo rimbushe %1$s%2$s drejt %3$s të cilat iu shtuan në balancë.',
    top_up_someone_else_successful_primary_button_text: 'Faqja kryesore',
    top_up_someone_else_successful_title: 'Urime!',

    custom_auto_top_up_first_tab: 'Zgjidh vlerën',
    custom_auto_top_up_second_tab: 'Vendos vlerën',
    custom_auto_top_up_field_currency: 'Lekë',
    custom_auto_top_up_field_hint: 'Vendos vlerën e rimbushjes',
    custom_auto_top_up_field_lower_hint: 'Vlera e rimbushjes nuk duhet të jetë më e madhe se %1$s %2$s',
    custom_auto_top_up_field_lower_hint_error: 'Ti e ke kaluar limitin e rimbushjes %1$s %2$s',
    custom_auto_top_up_field_upper_hint: 'Vendos vlerën',
    custom_auto_top_up_success_button: 'Faqja kryesore',
    custom_auto_top_up_success_message: 'Rimbushja prej %1$s drejt %1$s %2$s u krye me sukses.',
    custom_auto_top_up_success_title: 'Urime!',


    mc_messages_list_unread_header: 'Të palexuara',
    analytics_message_center_component_name: 'messagecenter',
    messages_list_read_header: 'Lexo njoftimet',
    messages_all_inbox_no_messages: 'Nuk ke asnjë njoftim.',
    messages_all_inbox_no_messages_description: '',
    messages_dashboard_access_secure_content_subtitle: 'Tap to access this secure area',
    messages_inbox_all: 'Të gjitha',
    messages_inbox_loading: 'Duke u ngarkuar…',
    messages_inbox_my_messages_header_title: 'Llogaria ime',
    messages_inbox_my_messages_title: 'Njoftimet e mia',
    messages_inbox_read_tab: 'Të lexuara',
    messages_inbox_see_more: 'Më shumë',
    messages_inbox_show_less: 'Më pak',
    messages_inbox_show_more: 'Më shumë',
    messages_inbox_this_month: 'Ketë muaj',
    messages_inbox_unread_tab: 'Të palexuara %1$s',
    messages_inbox_unread_text: 'pa lexuar',
    messages_item_subtitle_new_unread: '%1$s njoftime te palexuar',
    messages_item_subtitle_no_messages: 'Nuk ka njoftime të reja',
    messages_item_title: 'Njoftimet e mia',
    messages_list_unread_header: 'Njoftimet e palexuara',
    messages_unread_inbox_no_messages: 'Nuk ke asnjë njoftim.',
    messages_unread_inbox_no_messages_description: '',
    messages_read_inbox_no_messages: 'Nuk ke asnjë njoftim të lexuar',
    messages_read_inbox_no_messages_description: '',
    mc_messages_list_unread_header: 'Njoftime të reja',
    messages_delete_all_read: 'Fshi të gjitha',
    messages_delete_all_read_modal_description: 'A je i sigurt që do ti fshishë te gjithë njoftimet e lexuara? Kjo nuk mund të zhbëhet.',
    messages_delete_all_read_modal_primary_button: 'Konfirmo',
    messages_delete_all_read_modal_secondary_button: 'Anullo',
    messages_delete_all_read_modal_title: 'Fshi të gjithë njoftimet e lexuara',
    messages_delete_modal_description: 'A je i sigurt që do të fshishë njoftimin? Kjo nuk mund të zhbëhet.',
    messages_delete_modal_primary_button: 'Konfirmo',
    messages_delete_modal_secondary_button: 'Anullo',
    messages_delete_modal_title: 'Fshi njoftimin',
    messages_delete_text: 'Fshi',
    messages_inbox_important: 'Njoftimet e rendësishme',
    messages_inbox_other: 'Njoftime të tjera',
    messages_list_read_header: 'Njoftimet e lexuara',
    messages_mark_all_read_modal_description: 'Are you sure you want to mark all as read? You can still read the messages under the ‘Read’ tab.',
    messages_mark_all_read_modal_primary_button: 'Konfirmo',
    messages_mark_all_read_modal_secondary_button: 'Anullo',
    messages_mark_all_read_modal_title: 'Mark all as read',
    messages_see_all_filter: 'Shiko të gjitha',
    messages_unread_mark_all_read: 'Mark all as read',

    remove_addons_error_title: 'Një gabim ka ndodhur!',
    remove_addons_back_button: 'Kthehu',
    netperform_speed_checker_more_info_title: 'Info',
    netperform_speed_checker_more_info_url: 'https://api-web-ci-vfal.vodafone.com/file/speedtest/more_info/MoreInfoSpeedTestAL.html',
    product_and_services_sub_header_title_prepaid: 'Balanca',
    product_and_services_sub_header_title_postpaid: 'Fatura',
    product_and_services_after_sub_header_title_prepaid: ' kredit i mbetur',
    product_and_services_after_sub_header_title_postpaid: ' për t\'u paguar',
    product_and_services_top_up_button: 'Rimbush',
    product_and_services_after_sub_header_title_fix: ' a month',
    product_and_services_ebill_button: 'Faturat',
    addons_timeline_today: 'Sot',
    //soho framework
    "soho_subtray_business_overview_section_subtitle": "- %1$s Përdorues",
    "account_switch_loading_screen_desc": "%1$s",
    "soho_addons_card_price": "%1$s /%2$s",
    "soho_my_profile_sub_header": "%1$s shtuar në faturë.",
    "soho_marketplace_recommended_for_you_header_subtitle": "%1$s aplikacione.",
    "textarea_message_remaining": "%1$s karaktere të mbetura.",
    "soho_addons_user_active_addons_empty_text": "%1$s nuk ka asnjë paketë shtesë aktive. Për të blerë, kliko Blej Paketë shtesë.",
    "soho_my_profile_addons_requested_addons_empty_text": "%1$s nuk ka asnjë kërkesë për paketë shtesë. Për të blerë, kliko Kërko Paketë shtesë.",
    "soho_profile_management_cvm_page_banner_add_ons_title_singular": "%1$s ka %2$s kërkesë aktive për paketë shtesë.",
    "soho_profile_management_cvm_page_banner_add_ons_title_plural": "%1$s ka %2$s kërkesa aktive për paketa shtesë.",
    "soho_profile_management_cvm_page_banner_licence_title_singular": "%1$s ka %2$s license aktive ne pritje.",
    "soho_profile_management_cvm_page_banner_licence_title_plural": "%1$s ka %2$s licensa aktive ne pritje.",
    "soho_addons_buy_success_modal_description": "%1$s është shtuar me sukses tek paketat e tua shtesë.",
    "soho_addons_request_success_modal_description": "%1$s është shtuar me sukses tek kërkesat e paketave shtesë.",
    "soho_addons_cancel_request_success_modal_description": "%1$s është hequr me sukses nga kërkesat e paketave shtesë.",
    "soho_addons_remove_success_modal_description": "%1$s është hequr me sukses.",
    "soho_plans_plan_card_usage_details_value": "%1$s/%2$s",
    "soho_dashboard_applications_licences_badge": "%1$s licensa",
    "soho_marketplace_pdp_summary_cost_breakdown_amount_value": "%1$s licensa",
    "soho_licences_used_count_format": "%1$s licensa të përdorura",
    "soho_marketplace_company_licences_badge": "%1$s licensa të përdorura",
    "soho_requests_licences_used_count_format": "%1$s licensa të përdorura",
    "soho_marketplace_company_licences_header_subtitle": "%1$s licensa në %2$s aplikacione",
    soho_plans_plan_card_title: "Shërbimi celular",
    "soho_manage_requests_screen_title": "%1$s kërkesë",
    "soho_plans_plan_consumption": "%1$s përdorur këtë muaj",
    "soho_addons_user_count_format": "%1$s përdorues",
    "soho_my_plan_tile_number_of_user_format": "%1$s përdorues",
    "soho_user_edit_add_ons_assigned_one_user": "%1$s përdorues në këtë paketë shtesë",
    "soho_plans_add_user_plan_user_current_count": "%1$s përdorues në këtë plan tarifor",
    "soho_plans_user_per_plan": "%1$s përdorues në këtë plan tarifor",
    "soho_plans_add_user_plan_unassigned_user_current_count": "%1$s përdorues pa plan",
    "soho_user_edit_add_ons_un_assigned_more_one_user": "%1$s përdorues pa këtë paketë shtesë",
    "soho_addons_addon_number_of_users_label": "%1$s Përdorues",
    "soho_addons_users_count_format": "%1$s Përdorues",
    "soho_business_overview_number_of_users": "%1$s Përdorues",
    "soho_my_plan_tile_number_of_users_format": "%1$s Përdorues",
    "soho_my_users_and_groups_your_groups_users_count_text": "%1$s Përdorues",
    "soho_user_edit_add_ons_assigned_more_one_user": "%1$s përdorues në këtë paketë shtesë",
    "soho_plans_add_user_plan_users_current_count": "%1$s përdorues në këtë plan tarifor",
    "soho_plans_users_per_plan": "%1$s përdorues në këtë plan tarifor",
    "soho_plans_add_user_plan_unassigned_users_current_count": "%1$s përdorues pa plan",
    "soho_user_edit_add_ons_unassigned_users_without_this_addon": "%1$s përdorues pa këtë paketë shtesë",
    "soho_business_overview_cvm_page_banner_plans_title_singular": "%1$s, aktulisht ke %2$s plan për përmirësim",
    "soho_business_overview_cvm_page_banner_plans_title_plural": "%1$s, aktulisht ke %2$s plane për përmirësime",
    "soho_business_overview_cvm_page_banner_licences_title_singular": "%1$s, ti ke %2$s licensë të re në pritje",
    "soho_business_overview_cvm_page_banner_licences_title_plural": "%1$s, ti ke %2$s licensa të re në pritje",
    "soho_business_overview_cvm_page_banner_devices_title_singular": "%1$s, ti ke %2$s kërkesë të re për pajisje",
    "soho_business_overview_cvm_page_banner_devices_title_plural": "%1$s, ti ke %2$s kërkesa të reja për pajisje",
    "soho_business_overview_cvm_page_banner_usage_title_singular": "%1$s, ti ke %2$s përdorues që po tejkalon përdorimin e caktuar të planit",
    "soho_business_overview_cvm_page_banner_usage_title_plural": "%1$s, ti ke %2$s përdorues që po tejkalojnë përdorimin e caktuar të planit",
    "soho_plans_plan_card_consumption": "%1$s/%2$s",
    "soho_usage_usage_card_consumption": "%1$s/%2$s",
    "soho_profile_addons_table_title": "%1$s\\'s Paketa shtesë",
    "soho_my_profile_title": "%1$s\\'s work phone",
    "soho_user_plan_tile_top_title": "%1$s’s phone",
    "soho_user_edit_add_ons_unit_measure": "/%1$s",
    "soho_my_users_and_groups_screen_title": "Llogaria ime",
    soho_profile_management_account_number: "Llogaria e %1$s",
    "account_switch_tray_subtitle": "Llogaria e %1$s",
    "soho_addons_remove_success_modal_title": "Veprimi i përfunduar!",
    "soho_plans_remove_user_success_modal_title": "Veprimi i përfunduar!",
    "soho_requests_cancel_addon_success_modal_title": "Veprimi i përfunduar!",
    "soho_requests_cancel_device_success_modal_title": "Veprimi i përfunduar!",
    "soho_requests_cancel_licence_success_modal_title": "Veprimi i përfunduar!",
    "soho_requests_reject_addon_success_modal_title": "Veprimi i përfunduar!",
    "soho_requests_reject_device_success_modal_title": "Veprimi i përfunduar!",
    "soho_requests_reject_licence_success_modal_title": "Veprimi i përfunduar!",
    "soho_main_tile_my_plan_active_status": "Aktive",
    "soho_plans_active_plan_card_label": "Aktive",
    "soho_addons_table_active_addons": "Paketat shtesë aktive",
    "soho_profile_management_active_current_licences": "Licencat aktuale aktive",
    "soho_devices_screen_sub_title": "Pajisjet aktive",
    "soho_requests_pending_title": "Kërkesat aktive",
    "soho_profile_plans_section_title": "Shërbime aktive",
    "soho_plans_plan_add_calls_button_label": "Shto minuta",
    "soho_plans_plan_add_data_button_label": "Shto internet",
    "soho_plans_add_usage_data_title": "Shtoji internet planit",
    "soho_my_users_and_groups_add_group_text": "Shto grup",
    "soho_marketplace_pdp_summary_addon_item_add_item": "Shto artikull",
    "soho_plans_add_usage_calls_title": "Shtoji minuta planit",
    "soho_addons_shop_addons_subtitle_add_more_addons": "Shto me shume paketa ",
    "soho_marketplace_pdp_purchase_summary_addons_subtitle": "Shto më shumë paketa shtesë në planin tënd",
    "soho_marketplace_pdp_summary_addon_breakdown_item_title": "Paketë shtesë",
    "soho_marketplace_pdp_summary_addon_item_title": "Paketë shtesë",
    "soho_business_overview_addons_tab_title": "Paketa shtesë",
    "soho_plans_plan_add_sms_button_label": "Shto SMS",
    "soho_plans_add_usage_sms_title": "Shtoji SMS planit",
    "soho_plans_plan_add_user_label": "Shto përdorues",
    "soho_plans_add_user_quick_action_title": "Shtoji përdorues planit",
    "soho_plans_add_user_section_title": "Shtoji përdorues planit",
    "soho_plans_add_users_quick_action_title": "Shtoji përdorues planit",
    "soho_manage_requests_addon_screen_title": "Kërkesë për paketë shtesë",
    "soho_my_profile_addons": "Paketa shtesë",
    "soho_profile_management_user_details_addons": "Paketa shtesë",
    "soho_addons_shop_addons_screen_title": "Paketa shtesë",
    "soho_marketplace_pdp_summary_addons_title": "Paketa shtesë",
    "soho_marketplace_pdp_summary_cost_breakdown_addons": "Paketa shtesë",
    "soho_requests_filter_addons": "Paketa shtesë",
    "soho_marketplace_pdp_summary_addons_breakdown_title": "Zbërthim i kostos së paketave shtesë",
    "soho_my_users_and_groups_users_administrators": "Administratorët",
    "soho_addons_edit_user_quick_action_hint": "Të gjithë përdoruesit do të mund të vazhdojnë ta përdorin këtë paketë shtesë rregullisht.",
    "soho_marketplace_pdp_summary_cost_breakdown_amount": "Shuma",
    "soho_extras_modal_error_screen_title": "Diçka nuk funksionoi",
    "soho_addons_failure_error_message": "Diçka nuk funksionoi! Aplikacioni nuk po rifreskohet",
    "soho_contracted_product_details_error_message": "Diçka nuk funksionoi! Aplikacioni nuk po rifreskohet",
    "soho_full_error_overlay_subtitle": "Diçka nuk funksionoi! Aplikacioni nuk po rifreskohet",
    "soho_licences_failure_error_message": "Diçka nuk funksionoi! Aplikacioni nuk po rifreskohet",
    "soho_marketplace_full_screen_error_text": "Diçka nuk funksionoi! Aplikacioni nuk po rifreskohet",
    "soho_my_profile_addon_failure_error_message": "Diçka nuk funksionoi! Aplikacioni nuk po rifreskohet",
    "soho_plans_failure_error_message": "Diçka nuk funksionoi! Aplikacioni nuk po rifreskohet",
    "soho_profile_management_account_failure_error_message": "Diçka nuk funksionoi! Aplikacioni nuk po rifreskohet",
    "soho_requests_full_failure_error_message": "Diçka nuk funksionoi! Aplikacioni nuk po rifreskohet",
    "soho_requests_past_error_message": "Diçka nuk funksionoi! Aplikacioni nuk po rifreskohet",
    "soho_user_permission_list_error_message": "Diçka nuk funksionoi! Aplikacioni nuk po rifreskohet",
    "soho_dashboard_applications_card_tile_title": "Aplikacione",
    "soho_marketplace_company_licences_page_title": "Aplikacione",
    "soho_marketplace_recommended_for_you_page_title": "Aplikacione",
    "soho_marketplace_recommended_for_you_header_title": "Përmbledhje e aplikacioneve",
    "soho_addons_edit_user_quick_action_title": "Apliko ndryshimet",
    "soho_addons_remove_addon_button_apply_changes_label": "Apliko ndryshimet",
    "soho_requests_pending_approve_button": "Aprovo",
    "soho_requests_pending_approve_upgrade_button": "Aprovo përmirësimin",
    "soho_requests_status_completed": "Aprovuar",
    "soho_requests_past_requests_approved_at": "Aprovuar %1$s",
    "soho_profile_addons_buy_quick_action_description": "A je i sigurtë se dëshiron te aktivizosh paketën shtesë %1$s",
    "soho_profile_addons_cancel_request_quick_action_description": "A je i sigurtë se dëshiron te anullosh kërkesën për paketën shtesë %1$s",
    "soho_profile_addons_remove_quick_action_description": "Je i sigurt që dëshiron të heqësh paketën shtesë për %1$s?",
    "soho_addons_remove_quick_action_description": "Je i sigurt që dëshiron të heqësh këtë paketë shtesë? Të gjithë përdoruesit që kanë këtë paketë shtesë do të afektohen.",
    "soho_profile_addons_request_quick_action_description": "Je i sigurt që dëshiron të kërkosh këtë paketë shtesë për %1$s?",
    "soho_marketplace_licence_details_request_quick_action_modal_description": "Je i sigurt që dëshiron të kërkosh këtë licensë për %1$s?",
    "soho_plans_add_users_quick_action_description": "Je i sigurt që dëshiron t'i shtosh këta përdorues në këtë plan?",
    "soho_plans_add_user_quick_action_description": "Je i sigurt që dëshiron të shtosh këtë përdorues në këtë plan?",
    "soho_plans_remove_user_quick_action_description": "Je i sigurt që dëshiron të heqësh këtë përdorues nga ky plan?",
    "soho_devices_assigned_to": "Caktuar për",
    "soho_requests_approve_addon_quick_action_title": "Duke caktuar paketën shtesë",
    "soho_requests_approve_device_quick_action_title": "Duke caktuar pajisje të disponueshme për përdoruesit",
    "soho_requests_approve_licence_quick_action_title": "Duke caktuar licensa të disponueshme për përdoruesit",
    "soho_addons_buy_addon_auto_renew_label": "Rinovim automatik",
    "soho_marketplace_licence_details_request_failure_modal_secondary_button_title": "Mbrapa",
    "soho_profile_requested_addon_details_back_to_addons_label": "Kthehu tek paketat shtesë",
    "soho_profile_addons_remove_addon_secondary_button_back_to_addons_label": "Kthehu tek paketat shtesë",
    "soho_full_error_overlay_back_to_dashboard_text": "Kthehu në faqen kryesore",
    "soho_application_pdp_purchase_success_modal_primary_button": "Kthehu tek aplikacionet",
    "soho_full_error_overlay_back_to_marketplace_text": "Kthehu tek aplikacionet",
    "soho_marketplace_pdp_purchase_successful_overlay_secondary_button_text": "Kthehu tek aplikacionet",
    "soho_multiple_requests_approve_secondary_button_title": "Kthehu tek kërkesat",
    "soho_multiple_requests_secondary_button_title": "Kthehu tek kërkesat",
    "soho_requests_approve_addon_success_modal_primary_button_title": "Kthehu tek kërkesat",
    "soho_requests_approve_device_success_modal_primary_button_title": "Kthehu tek kërkesat",
    "soho_requests_approve_licence_success_modal_primary_button_title": "Kthehu tek kërkesat",
    "soho_requests_cancel_addon_success_modal_primary_button_title": "Kthehu tek kërkesat",
    "soho_requests_cancel_device_success_modal_primary_button_title": "Kthehu tek kërkesat",
    "soho_requests_cancel_licence_success_modal_primary_button_title": "Kthehu tek kërkesat",
    "soho_requests_cancel_request_success_modal_primary_button_title": "Kthehu tek kërkesat",
    "soho_requests_reject_addon_success_modal_primary_button_title": "Kthehu tek kërkesat",
    "soho_requests_reject_device_success_modal_primary_button_title": "Kthehu tek kërkesat",
    "soho_requests_reject_licence_success_modal_primary_button_title": "Kthehu tek kërkesat",
    "account_switch_secondary_button": "Kthehu tek ndërrimi i llogarisë",
    "soho_addons_remove_addon_based_on_label": "Bazuar në %1$s përdorues",
    "soho_profile_management_cvm_page_banner_plans_title": "Bazuar në përdorimin aktual të %1$s, ne rekomandojmë një plan alternativ",
    "soho_plans_add_users_quick_action_price_based_on": "Bazuar ne planin e parë",
    "soho_business_overview_billing_button_text": "Faturat",
    "soho_marketplace_pdp_summary_cost_breakdown": "Zbërthimi",
    "soho_dashboard_applications_filter_bundles": "Paketa",
    "account_switch_business_account": "Llogaria e biznesit",
    "account_switch_business_account_loading": "Llogarinë e biznesit",
    "soho_business_overview_title": "Pasqyra e biznesit",
    "soho_subtray_business_overview_section_button_text": "Pasqyra e biznesit",
    "soho_addons_buy_quick_action_title": "Bli paketë shtesë",
    "soho_addons_buy_addons_button_title": "Bli paketa shtesë",
    "soho_addons_buy_addon_button_buy_this_addon_label": "Bli këtë paketë shtesë",
    "soho_profile_addons_buy_addon_button_buy_this_addon_label": "Bli këte paketë shtesë",
    "soho_marketplace_pdp_summary_cost_breakdown_terms_description": "Duke shtypur ‘Pranoj’ ti pranon të gjitha kushtet. Shiko %1$s, dhe %2$s.",
    "soho_marketplace_pdp_total_cost_on_confirm_instructions": "Duke shtypur ‘Pranoj’ ti pranon të gjitha kushtet. Shiko politikën e kthimit, termat dhe kushtet.",
    "soho_plans_plan_card_calls_label": "Minuta",
    "soho_usage_categories_filter_calls": "Minuta",
    "soho_usage_categories_graph_calls_subtitle": "Minuta",
    "account_switch_cancel": "Anullo",
    "soho_addons_buy_quick_action_cancel_button_title": "Anullo",
    "soho_addons_edit_user_quick_action_cancel_button_title": "Anullo",
    "soho_addons_failure_modal_cancel": "Anullo",
    "soho_addons_remove_quick_action_cancel_button_title": "Anullo",
    "soho_addons_request_quick_action_cancel_button_title": "Anullo",
    "soho_application_pdp_purchase_failure_modal_secondary_button": "Anullo",
    "soho_marketplace_licence_details_request_quick_action_modal_secondary_button_title": "Anullo",
    "soho_plans_add_or_remove_user_failure_modal_cancel": "Anullo",
    "soho_plans_add_usage_cancel_button_title": "Anullo",
    "soho_plans_add_user_cancel_button_title": "Anullo",
    "soho_plans_add_user_quick_action_cancel_button_title": "Anullo",
    "soho_plans_remove_user_quick_action_cancel_button_title": "Anullo",
    "soho_requests_approve_addon_quick_action_cancel_button_title": "Anullo",
    "soho_requests_approve_device_quick_action_cancel_button_title": "Anullo",
    "soho_requests_approve_licence_quick_action_cancel_button_title": "Anullo",
    "soho_requests_cancel_addon_quick_action_cancel_button_title": "Anullo",
    "soho_requests_cancel_device_quick_action_cancel_button_title": "Anullo",
    "soho_requests_cancel_licence_quick_action_cancel_button_title": "Anullo",
    "soho_requests_cancel_request_quick_action_cancel_button_title": "Anullo",
    "soho_requests_failure_modal_cancel_button_title": "Anullo",
    "soho_requests_reject_addon_quick_action_cancel_button_title": "Anullo",
    "soho_requests_reject_device_quick_action_cancel_button_title": "Anullo",
    "soho_requests_reject_licence_quick_action_cancel_button_title": "Anullo",
    "soho_user_edit_add_ons_secondary_button": "Anullo",
    "switch_account_quick_action_cancel_button_title": "Anullo",
    "soho_requests_cancel_addon_quick_action_title": "Anullo kërkesën ",
    "soho_requests_cancel_device_quick_action_title": "Anullo kërkesën për pajisjen",
    "soho_requests_cancel_licence_quick_action_title": "Anullo kërkesën për licensen",
    "soho_my_profile_addons_cancel_request_button_label": "Anullo kërkesën ",
    "soho_requests_pending_cancel_request_button": "Anullo kërkesën ",
    "soho_addons_cancel_request_quick_action_title": "Anullo kërkesën ",
    "soho_requests_status_cancelled": "Anuluar",
    "soho_requests_past_requests_cancelled_at": "Anuluar %1$s",
    "soho_usage_categories_title": "Kategori",
    "soho_plans_add_usage_data_one_off_payment_statement": "Ndryshimet nuk janë shtuar në faturën tënde mujore. Interneti i papërdorur mund të përdoren derisa plani jot të rifreskohet.",
    "soho_plans_add_usage_data_recurring_payment_statement": "Ndryshimet nuk janë shtuar në faturën tënde mujore. Interneti i papërdorur mund të përdoren derisa plani jot të rifreskohet.",
    "soho_plans_add_usage_calls_one_off_payment_statement": "Ndryshimet nuk janë shtuar në faturën tënde mujore. Minutat e papërdorura mund të përdoren derisa plani jot të rifreskohet.",
    "soho_plans_add_usage_calls_recurring_payment_statement": "Ndryshimet nuk janë shtuar në faturën tënde mujore. Minutat e papërdorura mund të përdoren derisa plani jot të rifreskohet.",
    "soho_plans_add_usage_sms_one_off_payment_statement": "Ndryshimet nuk janë shtuar në faturën tënde mujore. SMS-të e papërdorura mund të përdoren derisa plani jot të rifreskohet.",
    "soho_plans_add_usage_sms_recurring_payment_statement": "Ndryshimet nuk janë shtuar në faturën tënde mujore. SMS-të e papërdorura mund të përdoren derisa plani jot të rifreskohet.",
    "soho_plans_add_usage_data_choose_occurrence_title": "Zgjidhni shfaqjen e internetit shtesë",
    "soho_plans_add_usage_calls_choose_occurrence_title": "Zgjidhni shfaqjen e minutave shtesë",
    "soho_plans_add_usage_sms_choose_occurrence_title": "Zgjidhni shfaqjen e SMS-ve shtesë",
    "soho_plans_add_usage_calls_choose_amount_title": "Zgjidh shumën",
    "soho_plans_add_usage_data_choose_amount_title": "Zgjidh shumën",
    "soho_plans_add_usage_sms_choose_amount_title": "Zgjidh shumën",
    "soho_marketplace_pdp_your_plan_choose_this_plan": "Zgjidh këtë plan",
    "soho_marketplace_purchase_choose_this_plan_title": "Zgjidh këtë plan",
    "soho_marketplace_pdp_summary_addons_breakdown_close_button_title": "Mbyll",
    "soho_requests_close": "Mbyll",
    "soho_dashboard_applications_filter_collaboration": "Bashkëpunim",
    "soho_dashboard_applications_company_licences": "Licensat e biznesit",
    "soho_marketplace_company_licences_subtitle": "Licensat e biznesit",
    "soho_marketplace_purchase_compare_plans_title": "Krahaso planet",
    "soho_addons_buy_quick_action_confirm_button_title": "Konfirmo",
    "soho_addons_edit_user_quick_action_confirm_button_title": "Konfirmo",
    "soho_addons_remove_quick_action_confirm_button_title": "Konfirmo",
    "soho_addons_request_quick_action_confirm_button_title": "Konfirmo",
    "soho_marketplace_licence_details_request_quick_action_modal_primary_button_title": "Konfirmo",
    "soho_marketplace_pdp_summary_cost_breakdown_confirm_payment": "Konfirmo",
    "soho_plans_add_usage_confirm_button_title": "Konfirmo",
    "soho_plans_add_user_confirm_button_title": "Konfirmo",
    "soho_plans_add_user_quick_action_confirm_button_title": "Konfirmo",
    "soho_plans_remove_user_quick_action_confirm_button_title": "Konfirmo",
    "soho_requests_approve_addon_quick_action_confirm_button_title": "Konfirmo",
    "soho_requests_approve_device_quick_action_confirm_button_title": "Konfirmo",
    "soho_requests_approve_licence_quick_action_confirm_button_title": "Konfirmo",
    "soho_requests_cancel_addon_quick_action_confirm_button_title": "Konfirmo",
    "soho_requests_cancel_device_quick_action_confirm_button_title": "Konfirmo",
    "soho_requests_cancel_licence_quick_action_confirm_button_title": "Konfirmo",
    "soho_requests_cancel_request_quick_action_confirm_button_title": "Konfirmo",
    "soho_requests_reject_addon_quick_action_confirm_button_title": "Konfirmo",
    "soho_requests_reject_device_quick_action_confirm_button_title": "Konfirmo",
    "soho_requests_reject_licence_quick_action_confirm_button_title": "Konfirmo",
    "soho_user_edit_add_ons_confirm_button_title": "Konfirmo",
    "soho_addons_congratulations_overlay_title": "Urime!",
    "soho_addons_success_modal_title": "Urime!",
    "soho_application_pdp_purchase_request_success_modal_title": "Urime!",
    "soho_marketplace_licence_details_request_success_modal_title": "Urime!",
    "soho_marketplace_pdp_purchase_successful_overlay_title": "Urime!",
    "soho_plans_add_usage_result_success_title": "Urime!",
    "soho_plans_add_user_success_modal_title": "Urime!",
    "soho_requests_approve_addon_success_modal_title": "Urime!",
    "soho_requests_approve_device_success_modal_title": "Urime!",
    "soho_requests_approve_licence_success_modal_title": "Urime!",
    "soho_plans_plan_card_contract_data_contract_ends_label": "Kontrata përfundon më",
    "soho_marketplace_pdp_summary_cost": "Kosto",
    "soho_my_profile_header": "Kosto në muaj",
    "soho_my_users_and_groups_empty_groups_btn": "Krijo një grup",
    "account_switch_current_account": "Llogaria aktuale",
    "soho_business_overview_current_bill": "Fatura aktuale",
    "soho_plans_add_user_success_modal_return_to_dashboard": "Faqja kryesore",
    "soho_plans_plan_card_data_label": "Data",
    "soho_usage_categories_filter_data": "Data",
    "soho_usage_categories_graph_data_subtitle": "Data",
    "soho_requests_dates_format": "dd.MM.yyyy",
    "soho_plans_dates_format": "dd/MM/yyyy",
    "soho_manage_requests_device_screen_title": "Kerkesë për pajisje",
    "soho_business_overview_devices_tab_title": "Pajisje",
    "soho_my_profile_devices": "Pajisje",
    "soho_profile_management_user_details_devices": "Pajisje",
    "soho_requests_filter_devices": "Pajisje",
    "soho_addons_request_addon_duration_label": "Kohëzgjatja",
    "soho_marketplace_pdp_summary_cost_breakdown_duration": "Kohëzgjatja",
    "soho_plans_plan_card_contract_data_eligible_upgrade_from_label": "I zgjedhur për tu përmirësuar",
    "soho_requests_reject_quick_action_placeholder": "Shkruaj një mesazh (opsionale)",
    "soho_addons_buy_addons_expiration_date_label": "Data e përfundimit",
    "soho_addons_remove_my_addons_expiration_date_label": "Data e përfundimit",
    "soho_profile_management_remove_addons_expiration_date_label": "Data e përfundimit",
    "soho_addons_table_expired_addons": "Paketa shtesë të përfunduara",
    "soho_dashboard_applications_explore_applications": "Zbulo aplikacionet",
    "soho_dashboard_applications_explore_title": "Zbulo aplikacionet",
    "soho_dashboard_applications_filter_favourite": "Të preferuarat",
    "soho_dashboard_applications_filter_field_force": "Menaxhimi në terren",
    "soho_my_users_and_groups_subtitle": "Kërko profilin e përdoruesit",
    "soho_dashboard_applications_find_out_more": "Zbulo më shumë",
    "soho_marketplace_licence_details_product_information_find_out_more": "Zbulo më shumë",
    "soho_marketplace_pdp_total_cost_first_bill_title": "Fatura e parë",
    "soho_user_plan_tile_get_more_data_title": "Merr më shumë internet",
    "soho_user_plan_tile_get_more_mins_title": "Merr më shumë minuta",
    "soho_user_plan_tile_get_more_sms_title": "Merr më shumë SMS",
    "soho_plans_add_usage_result_failure_go_back_button_title": "Kthehu",
    "soho_my_users_and_groups_groups_tab_title": "Grupe",
    "soho_usage_categories_description": "Si po e përdor internetin tënd",
    "soho_usage_users_description": "Si po e përdor internetin tënd",
    "soho_main_tile_my_plan_in_active_status": "Jo aktive",
    "soho_plans_inactive_plan_card_label": "Jo aktive",
    "soho_addons_buy_addons_inc_vat_label": "me TVSH",
    "soho_marketplace_pdp_summary_inc_vat": "me TVSH",
    "soho_plans_add_users_quick_action_price_include_vat": "me TVSH",
    "soho_plans_plan_card_price_includes_vat": "me TVSH",
    "soho_profile_management_licences_include_vat": "me TVSH",
    "soho_marketplace_licence_details_licence_card_include_vat": "me TVSH",
    "soho_dashboard_applications_filter_iot": "IOT",
    "soho_requests_updated_label_just_updated": "Sapo e përditësuar",
    "soho_plans_plan_card_usage_details_separator": "të mbetura",
    "soho_user_plan_tile_left_of": "ka lënë paketën %1$s",
    "soho_usage_users_search_placeholder": "Kërko",
    "soho_marketplace_licence_details_licence_card_title": "License",
    "soho_marketplace_licence_details_stepper_licence_title": "Licensë",
    "soho_marketplace_pdp_total_cost_licence_title": "Licensë",
    "soho_licence_details_title": "Detaje të licensës",
    "soho_marketplace_licence_details_title": "Detaje të licensës",
    "soho_manage_requests_licence_screen_title": "Kërkesë për licensë",
    "soho_licences_usage": "Përdorimi i licensës",
    "soho_business_overview_licences_tab_title": "Aplikacione",
    "soho_licences_statistics_card_title": "Aplikacione",
    "soho_my_profile_licences": "Aplikacione",
    "soho_profile_management_user_details_licences": "Aplikacione",
    "soho_requests_filter_licences": "Aplikacione",
    "soho_marketplace_company_licences_header_title": "Pasqyrë e licensave",
    "soho_plans_add_usage_quick_action_loading": "Në ngarkim…",
    "soho_marketplace_licence_sub_header_manage_btn": "Administro",
    "soho_user_edit_add_ons_title": "Administro paketat shtesë",
    "soho_multiple_requests_approve_addon_primary_button_title": "Administro paketat shtesë",
    "soho_multiple_requests_reject_addon_primary_button_title": "Administro paketat shtesë",
    "soho_multiple_requests_approve_device_primary_button_title": "Menaxho pajisjet",
    "soho_multiple_requests_reject_device_primary_button_title": "Menaxho pajisjet",
    "soho_my_users_and_groups_groups_title": "Administro paketat shtesë",
    "soho_multiple_requests_approve_licence_primary_button_title": "Administro licensat",
    "soho_multiple_requests_reject_licence_primary_button_title": "Administro licensat",
    "soho_manage_requests_title": "Administro kërkesat",
    "soho_requests_pending_manage_requests_button": "Administro kërkesat",
    "soho_my_users_and_groups_users_title": "Administro përdoruesit",
    "soho_dashboard_applications_filter_managed_security": "Administro sigurinë",
    "soho_my_users_and_groups_users_subtitle": "Anëtarët e biznesit",
    "soho_plans_plan_card_contract_data_minimum_term_ends_label": "Afati minimal përfundon më",
    "soho_marketplace_pdp_summary_addon_breakdown_item_monthly_per_licence": "Mujore / licensë",
    "soho_marketplace_licence_details_more_information": "Më shumë informacion",
    "soho_addons_table_title": "Paketat e mia",
    "soho_my_profile_addons_my_addons_label": "Paketat e mia",
    "soho_subtray_business_overview_section_title": "Biznesi im",
    "soho_devices_screen_title": "Pajisjet e mia aktuale",
    "soho_marketplace_pdp_purchase_summary_my_licence": "Licensa ime",
    "soho_dashboard_applications_user_licences": "Licensat e mia",
    "soho_marketplace_pdp_purchase_summary_my_plan": "Plani im",
    "soho_marketplace_purchase_my_plan_title": "Plani im",
    "soho_my_plan_tile_title": "Plani im",
    "soho_my_profile_plans": "Plani im",
    "soho_my_requests_title": "Kërkesat e mia",
    "soho_my_users_and_groups_title": "Përdoruesit e mi",
    "soho_marketplace_pdp_summary_addons_subtitle": "Duhet te rimbushësh ",
    "soho_usage_users_next": "Tjetër",
    "soho_devices_is_empty": "Nuk ka pajisje aktive",
    "soho_licences_empty_description": "Nuk ka licensa të disponueshme",
    "soho_profile_management_empty_users_first_description": "Nuk ka përdorues të disponueshëm",
    "soho_business_overview_cvm_page_banner_licences_secondary_button_title": "Jo, faleminderit",
    "soho_business_overview_cvm_page_banner_secondary_button_title": "Jo, faleminderit",
    "soho_marketplace_pdp_summary_addon_breakdown_item_number_of_licence": "Numri i licensave",
    "soho_plans_add_usage_calls_occurrence_first_option_title": "Një herë",
    "soho_plans_add_usage_data_occurrence_first_option_title": "Një herë",
    "soho_plans_add_usage_sms_occurrence_first_option_title": "Një herë",
    "account_switch_default_error_title": "Ups!",
    "soho_full_error_overlay_title": "Ups, diçka nuk funksionoi",
    "soho_addons_shop_addons_other_filter": "Tjetër",
    "account_switch_other_accounts": "Llogari të tjera",
    "soho_marketplace_pdp_your_plan_other_plans": "Plane të tjera",
    "soho_marketplace_purchase_other_plans_sub_title": "Plane të tjera",
    "soho_overtime_users_title": "Jashtë orarit",
    "soho_requests_past_tab_title": "E kaluar",
    "soho_requests_past_title": "Kërkesa të kaluara",
    "soho_requests_pending_tab_title": "Në pritje",
    "soho_marketplace_pdp_summary_cost_per_month": "në muaj",
    "soho_marketplace_pdp_summary_cost_per_year": "në vit",
    "account_switch_personal_account": "Llogaria personale",
    "account_switch_personal_account_loading": "Llogarinë personale",
    "soho_marketplace_purchase_plan_step_title": "Plan",
    "soho_dashboard_plan_details_card_tile_title": "Detaje të planit",
    "soho_business_overview_plan_tab_title": "Planet tarifore",
    "soho_marketplace_pdp_your_plan_plans": "Plane",
    "soho_plans_add_user_title": "Plane",
    soho_plans_title: "Planet tarifore",
    "soho_profile_management_user_details_plans": "Plane",
    "soho_requests_approve_device_quick_action_description": "Të lutem, konfirmo që dëshiron të caktosh një pajisje për '%1$s' përdoruesit të zgjedhur",
    "soho_multiple_requests_approve_device_quick_action_description": "Të lutem, konfirmo që dëshiron të caktosh një pajisje për '%1$s' përdoruesve të zgjedhur",
    "soho_requests_approve_licence_quick_action_description": "Të lutem, konfirmo që dëshiron të caktosh një license për '%1$s' përdoruesit të zgjedhur",
    "soho_multiple_requests_approve_licence_quick_action_description": "Të lutem, konfirmo që dëshiron të caktosh një license për '%1$s' përdoruesve të zgjedhur",
    "soho_requests_approve_addon_quick_action_description": "Të lutem, konfirmo që dëshiron t'i caktosh paketën shtesë '%1$s' përdoruesit të zgjedhur",
    "soho_multiple_requests_approve_addon_quick_action_description": "Të lutem, konfirmo që dëshiron t'i caktosh një paketë shtesë '%1$s' përdoruesve të zgjedhur",
    "soho_requests_cancel_addon_quick_action_description": "Të lutem, konfirmo që dëshiron të anullosh paketën tënde shtesë '%1$s'",
    "soho_requests_cancel_device_quick_action_description": "Të lutem, konfirmo që dëshiron të të anullosh kërkesën për pajisjen për '%1$s'",
    "soho_requests_cancel_licence_quick_action_description": "Të lutem, konfirmo që dëshiron të të anullosh kërkesën për licensen për '%1$s'",
    "soho_usage_users_prev": "E mëparshme",
    "soho_plans_plan_card_price_label": "Çmimi në muaj",
    "soho_profile_plans_section_subtitle": "Shërbime kryesore",
    "soho_addons_modal_loading_screen_subtitle": "Duke u procesuar…",
    "soho_requests_modal_loading_screen_subtitle": "Duke u procesuar…",
    "soho_user_permission_list_loading_message": "Duke u procesuar…",
    "soho_manage_requests_header_description": "ID e produktit",
    "soho_marketplace_licence_details_licence_card_product_id": "ID e produktit",
    "soho_user_edit_add_ons_product_id": "ID e produktit",
    "soho_marketplace_licence_details_product_information": "Informacion i produktit",
    "soho_marketplace_pdp_purchase_successful_overlay_primary_button_text": "Detaje të produktit",
    "soho_profile_management_title": "Profili",
    "soho_marketplace_licence_details_licence_card_purchase": "Blej",
    "soho_marketplace_pdp_purchase_title": "Blej",
    "soho_application_pdp_purchase_modal_loading_screen_subtitle": "Duke e blere…",
    "soho_marketplace_licence_details_request_loading_modal_title": "Duke e blere…",
    "soho_marketplace_pdp_summary_quantity": "Sasi",
    "soho_addons_recommended_for_you": "Rekomanuar për ty",
    "soho_dashboard_applications_recommended_for_you": "Rekomanuar për ty",
    "soho_marketplace_pdp_your_plan_recommended_for_you": "Rekomanuar për ty",
    "soho_marketplace_purchase_recommend_for_you_title": "Rekomanuar për ty",
    "soho_marketplace_recommended_for_you_subtitle": "Rekomanuar për ty",
    "soho_plans_add_usage_calls_occurrence_second_option_title": "Përsëritëse",
    "soho_plans_add_usage_data_occurrence_second_option_title": "Përsëritëse",
    "soho_plans_add_usage_sms_occurrence_second_option_title": "Përsëritëse",
    "soho_requests_pending_reject_button": "Refuzo",
    "soho_requests_status_rejected": "E refuzuar",
    "soho_requests_past_requests_rejected_at": "E refuzuar %1$s",
    "soho_requests_reject_device_quick_action_title": "Duke refuzuar kërkesën për pajisje",
    "soho_requests_reject_licence_quick_action_title": "Duke refuzuar kërkesën për license",
    "soho_requests_reject_addon_quick_action_title": "Duke refuzuar kërkesën për paketë shtesë",
    "soho_marketplace_applications_list_related_products_section_title": "Produkte të ngjashme",
    "soho_marketplace_licence_details_related_products_section_title": "Produkte të ngjashme",
    "soho_plans_add_user_remove_button_title": "Fshij",
    "soho_plans_plan_user_card_remove_label": "Fshij",
    "soho_profile_addons_remove_addon_button_apply_changes_label": "Fshij",
    "soho_user_edit_add_ons_remove_user_title": "Fshij",
    "soho_addons_remove_quick_action_title": "Fshij paketën shtesë",
    "soho_marketplace_pdp_summary_addon_item_remove_item": "Fshij artikullin",
    "soho_addons_remove_addon_secondary_button_remove_this_addon_label": "Fshij këtë paketë shtesë",
    "soho_plans_remove_user_quick_action_title": "Fshij përdoruesin nga plani",
    "soho_addons_remove_addon_renewal_date_label": "Data e rinovimit",
    "soho_plans_plan_card_contract_data_renewal_date_label": "Data e rinovimit",
    "soho_marketplace_licence_details_licence_card_request": "Kërko",
    "soho_addons_request_quick_action_title": "Kërko paketë shtesë",
    "soho_addons_my_profile_request_addons_screen_title": "Kërko paketë shtesë",
    "soho_addons_primary_button": "Kërko paketë shtesë",
    "soho_my_plans_request_usage_calls_label": "Kërko minuta",
    "soho_addons_cancel_request_success_modal_title": "Kërkesa e anulluar",
    "soho_my_plans_request_usage_data_label": "Kërko internet",
    "soho_my_plans_request_usage_data_title": "Kërko internet",
    "soho_marketplace_licence_details_request_quick_action_modal_title": "Kërko licensa",
    "soho_my_plans_request_usage_calls_title": "Kërko minuta",
    "soho_my_plans_request_usage_sms_label": "Kërko SMS",
    "soho_my_plans_request_usage_sms_title": "Kërko SMS",
    "soho_addons_request_success_modal_title": "Kërkesë e dorëzuar",
    "soho_my_profile_addons_request_new_addon_button_label": "Kërko ketë paketë shtesë",
    "soho_requests_pending_requests_requested_at": "Kërkuar %1$s",
    "soho_my_profile_addons_requested_addons_title": "Kërkuar paketë shtesë",
    "soho_user_requests_number_of_requested_users": "Kërkuar nga %1$s përdoruesve",
    "soho_user_plan_tile_reset_in_day": "Rifreskohet në %1$s ditë",
    "soho_user_plan_tile_reset_in_days": "Ripërtërihet për %1$s ditë",
    "soho_marketplace_pdp_summary_cost_breakdown_return_policy_hyperlink": "politika e kthimit",
    "soho_addons_success_modal_return_to_addons": "Kthehu tek paketat shtesë",
    "soho_marketplace_licence_details_request_success_modal_primary_button_title": "Kthehu tek aplikacionet",
    "soho_plans_request_usage_result_success_button_title": "Kthehu tek plani im",
    "soho_plans_add_usage_result_success_return_button_title": "Kthehu tek planet",
    "soho_plans_add_user_success_modal_return_to_plan": "Kthehu tek planet",
    "soho_marketplace_pdp_summary_cost_breakdown_duration_monthly": "Abonim mujor i vazhdueshëm",
    "soho_marketplace_pdp_summary_cost_breakdown_duration_yearly": "Abonim vjetor i vazhdueshëm",
    "soho_marketplace_search_placeholder": "Kërko kontakt",
    "soho_licences_search_hint": "Kërko…",
    "soho_marketplace_lets_refine_your_search": "Kërko…",
    "soho_my_users_and_groups_users_search_placeholder": "Kërko…",
    "soho_requests_search_placeholder": "Kërko…",
    "soho_dashboard_applications_filter_security": "Siguria",
    "soho_addons_shop_addons_see_all_filter": "Shiko të gjitha",
    "soho_dashboard_applications_filter_see_all": "Shiko të gjitha",
    "soho_dashboard_applications_see_all": "Shiko të gjitha",
    "soho_requests_filter_see_all": "Shiko të gjitha",
    "soho_usage_categories_filter_see_all": "Shiko të gjitha",
    "soho_dashboard_applications_see_more": "Shiko më shumë",
    "soho_manage_requests_select_all_button_title": "Përzgjidhi të gjitha",
    "soho_plans_add_user_select_all_button_title": "Përzgjidhi të gjitha",
    "soho_user_edit_add_ons_select_all_button_title": "Përzgjidhi të gjitha",
    "soho_marketplace_purchase_my_plan_sub_title": "Plani i përzgjedhur",
    "soho_addons_shop_addons_title_shop_addons": "Bli paketa shtesë",
    "soho_marketplace_licence_details_product_information_show_features": "Shfaq karakteristikat e plota",
    "soho_addons_addon_description_show_less": "Më pak",
    "soho_plans_plan_card_show_less": "Më pak",
    "soho_addons_addon_description_show_more": "Më shumë",
    "soho_dashboard_applications_explore_show_more_title": "Më shumë",
    "soho_dashboard_applications_show_more": "Më shumë",
    "soho_marketplace_company_licences_show_more": "Më shumë",
    "soho_marketplace_recommended_for_you_show_more": "Më shumë",
    "soho_my_users_and_groups_users_show_more": "Më shumë",
    "soho_plans_plan_card_show_more": "Më shumë",
    "soho_user_edit_add_ons_show_more_title": "Më shumë",
    "soho_plans_plan_card_sms_label": "SMS",
    "soho_usage_categories_filter_sms": "SMS",
    "soho_usage_categories_graph_sms_subtitle": "SMS",
    "account_switch_default_error_desc": "Diçka nuk funksionoi",
    "soho_addons_failure_modal_title": "Diçka nuk funksionoi!",
    "soho_application_pdp_purchase_failure_modal_title": "Diçka nuk funksionoi!",
    "soho_marketplace_licence_details_request_failure_modal_title": "Diçka nuk funksionoi!",
    "soho_plans_add_or_remove_user_failure_modal_title": "Diçka nuk funksionoi!",
    "soho_requests_approve_addon_failure_modal_title": "Diçka nuk funksionoi!",
    "soho_requests_approve_device_failure_modal_title": "Diçka nuk funksionoi!",
    "soho_requests_approve_licence_failure_modal_title": "Diçka nuk funksionoi!",
    "soho_requests_cancel_addon_failure_modal_title": "Diçka nuk funksionoi!",
    "soho_requests_cancel_device_failure_modal_title": "Diçka nuk funksionoi!",
    "soho_requests_cancel_licence_failure_modal_title": "Diçka nuk funksionoi!",
    "soho_requests_failure_modal_title": "Diçka nuk funksionoi!",
    "soho_requests_reject_addon_failure_modal_title": "Diçka nuk funksionoi!",
    "soho_requests_reject_device_failure_modal_title": "Diçka nuk funksionoi!",
    "soho_requests_reject_licence_failure_modal_title": "Diçka nuk funksionoi!",
    "soho_devices_you_do_not_have_active_devices_here": "Më vjen keq, ti nuk ke asnjë pajisje ketu.",
    "soho_licences_empty_small_description": "Më vjen keq, ti nuk ke asnjë licensë në dispozicion.",
    "soho_profile_management_empty_users_second_description": "Më vjen keq, ti nuk ke asnjë përdorues aktual.",
    "soho_my_users_and_groups_users_standard": "Përdorues standard",
    "soho_marketplace_pdp_summary_cost_breakdown_start_date": "Data e fillimit",
    "soho_addons_buy_addon_starting_today_label": "Filluar nga sot",
    "soho_marketplace_licence_details_product_information_suggested_plan": "Plani i sugjeruar",
    "soho_marketplace_licence_details_stepper_summary_title": "Përmbledhje",
    "soho_marketplace_licence_details_summary_header_title": "Përmbledhje",
    "soho_marketplace_pdp_purchase_summary_title": "Përmbledhje",
    "soho_marketplace_purchase_summary_step_title": "Përmbledhje",
    "account_switch_title": "Ndrysho llogarinë",
    "account_switch_tray_button": "Ndrysho llogarinë",
    account_switch_loading_screen_title: "Duke kaluar në…",
    "soho_addons_users_hint": "Përzgjidh përdorues te shikosh/ndryshosh përdoruesin e përzgjedhur",
    "soho_marketplace_pdp_summary_cost_breakdown_terms_hyperlink": "Kushte dhe afate",
    "soho_addons_edit_user_quick_action_description": "Ndryshimi i kostos pas modifikimit të përdoruesve në një paketë shtesë ekzistuese do të reflektohet në ciklin tjetër të faturimit.",
    "soho_addons_edit_success_modal_description": "Lista e përdoruesve në këtë paketë shtesë është përditësuar me sukses",
    "soho_marketplace_pdp_summary_cost_note": "Çmimi do të shtohet në faturën tënde të ardhshme",
    "soho_plans_add_user_success_modal_description": "Përdoruesi u shtua me sukses në këtë plan",
    "soho_plans_remove_user_success_modal_plan_name_description": "Përdoruesi është hequr me sukses nga %1$s",
    "soho_plans_remove_user_success_modal_description": "Përdoruesi është hequr me sukses nga plani",
    "soho_requests_approve_addon_success_modal_subtitle": "Përdoruesi do të marrë një mesazh konfirmimi kur të hyjë përsëri",
    "soho_requests_approve_device_success_modal_subtitle": "Përdoruesi do të marrë një mesazh konfirmimi kur të hyjë përsëri",
    "soho_requests_approve_licence_success_modal_subtitle": "Përdoruesi do të marrë një mesazh konfirmimi kur të hyjë përsëri",
    "soho_requests_reject_addon_success_modal_subtitle": "Përdoruesi do të marrë një mesazh njoftimi kur të hyjë përsëri",
    "soho_requests_reject_device_success_modal_subtitle": "Përdoruesi do të marrë një mesazh njoftimi kur të hyjë përsëri",
    "soho_requests_reject_licence_success_modal_subtitle": "Përdoruesi do të marrë një mesazh njoftimi kur të hyjë përsëri",
    "soho_plans_add_user_success_modal_description_plural": "Përdoruesit janë shtuar me sukses në plan",
    "soho_multiple_requests_approve_addon_success_modal_subtitle": "Përdoruesit do të marrin një mesazh konfirmimi kur të hyjnë përsëri",
    "soho_multiple_requests_approve_device_success_modal_subtitle": "Përdoruesit do të marrin një mesazh konfirmimi kur të hyjnë përsëri",
    "soho_multiple_requests_approve_licence_success_modal_subtitle": "Përdoruesit do të marrin një mesazh konfirmimi kur të hyjnë përsëri",
    "soho_multiple_requests_reject_addon_success_modal_subtitle": "Përdoruesit do të marrin një mesazh njoftimi kur të hyjnë përsëri",
    "soho_multiple_requests_reject_device_success_modal_subtitle": "Përdoruesit do të marrin një mesazh njoftimi kur të hyjnë përsëri",
    "soho_multiple_requests_reject_licence_success_modal_subtitle": "Përdoruesit do të marrin një mesazh njoftimi kur të hyjnë përsëri",
    "soho_marketplace_pdp_summary_cost_breakdown_then_monthly": "Muojre",
    "soho_marketplace_pdp_summary_cost_breakdown_then_yearly": "Vjetore",
    "soho_dashboard_applications_available_applications": "Ka %1$s aplikacione të disponueshme",
    "soho_dashboard_applications_explore_sub_title": "Ka %1$s aplikacione të disponueshme",
    "soho_user_edit_add_ons_assigned_no_users": "Aktualisht nuk ka përdorues në këtë paketë shtesë",
    "soho_plans_add_user_plan_no_assigned_users": "Aktualisht nuk ka përdorues në këtë plan",
    "soho_plans_add_user_plan_no_unassigned_users": "Aktualisht nuk ka asnjë përdorues pa një plan",
    "soho_user_edit_add_ons_no_unassigned_users": "Aktualisht nuk ka përdorues pa këtë paketë shtesë",
    "soho_licences_empty_view_message": "Nuk ka rezultate për \"%1$s\". Provo një kërkim të ri",
    "soho_marketplace_explore_section_no_results_found": "Nuk ka rezultate për \"%1$s\". Provo një kërkim të ri",
    "soho_profile_management_no_results_found": "Nuk ka rezultate për \"%1$s\". Provo një kërkim të ri",
    "soho_requests_search_empty_view_message": "Nuk ka rezultate për \"%1$s\". Provo një kërkim të ri",
    "soho_marketplace_pdp_your_plan_this_includes": "Përfshirë",
    "soho_requests_application_components_image_title": "Përfshirë",
    "soho_marketplace_pdp_total_cost_subtitle": "Ky cmim do të shtohet në faturën e ardhshme",
    "soho_marketplace_pdp_summary_cost_breakdown_start_date_today": "Sot",
    "soho_marketplace_pdp_summary_addon_breakdown_item_total": "Total",
    "soho_addons_remove_addon_total_cost_label": "Kosto totale",
    "soho_marketplace_pdp_total_cost": "Kosto totale",
    "soho_plans_add_users_quick_action_price_cost": "Kosto totale",
    "soho_requests_approve_request_confirmation_dialog_price_title": "Kosto totale",
    "soho_marketplace_pdp_summary_addons_breakdown_total_to_pay": "Totali për tu paguar",
    "soho_dashboard_applications_filter_tradesperson": "Tregtar",
    "soho_addons_failure_modal_try_again": "Provo përsëri",
    "soho_addons_shop_addons_error_try_again": "Provo përsëri",
    "soho_application_pdp_purchase_failure_modal_primary_button": "Provo përsëri",
    "soho_contracted_product_details_error_try_again": "Provo përsëri",
    "soho_extras_modal_error_screen_primary_button_title": "Provo përsëri",
    "soho_licences_failure_error_try_again": "Provo përsëri",
    "soho_marketplace_licence_details_request_failure_modal_primary_button_title": "Provo përsëri",
    "soho_plans_add_or_remove_user_failure_modal_try_again": "Provo përsëri",
    "soho_plans_add_usage_result_failure_try_again_button_title": "Provo përsëri",
    "soho_plans_failure_try_again": "Provo përsëri",
    "soho_plans_plan_error_try_again": "Provo përsëri",
    "soho_profile_management_account_error_try_again": "Provo përsëri",
    "soho_requests_approve_addon_failure_modal_try_again": "Provo përsëri",
    "soho_requests_approve_device_failure_modal_try_again": "Provo përsëri",
    "soho_requests_approve_licence_failure_modal_try_again": "Provo përsëri",
    "soho_requests_cancel_addon_failure_modal_try_again": "Provo përsëri",
    "soho_requests_cancel_device_failure_modal_try_again": "Provo përsëri",
    "soho_requests_cancel_licence_failure_modal_try_again": "Provo përsëri",
    "soho_requests_failure_modal_try_again_button_title": "Provo përsëri",
    "soho_requests_full_failure_error_try_again": "Provo përsëri",
    "soho_requests_past_error_try_again_button_title": "Provo përsëri",
    "soho_requests_reject_addon_failure_modal_try_again": "Provo përsëri",
    "soho_requests_reject_device_failure_modal_try_again": "Provo përsëri",
    "soho_requests_reject_licence_failure_modal_try_again": "Provo përsëri",
    "soho_usage_categories_error_try_again": "Provo përsëri",
    "soho_usage_users_error_try_again": "Provo përsëri",
    "soho_user_permission_list_error_try_again": "Provo përsëri",
    "soho_addons_failure_modal_description": "Kjo kërkesë nuk mund të procesoet.",
    "soho_plans_add_or_remove_user_failure_modal_description": "Kjo kërkesë nuk mund të procesoet.",
    "soho_requests_approve_addon_failure_modal_description": "Kjo kërkesë nuk mund të procesoet.",
    "soho_requests_approve_device_failure_modal_description": "Kjo kërkesë nuk mund të procesoet.",
    "soho_requests_approve_licence_failure_modal_description": "Kjo kërkesë nuk mund të procesoet.",
    "soho_requests_reject_addon_failure_modal_description": "Kjo kërkesë nuk mund të procesoet.",
    "soho_requests_reject_device_failure_modal_description": "Kjo kërkesë nuk mund të procesoet.",
    "soho_requests_reject_licence_failure_modal_description": "Kjo kërkesë nuk mund të procesoet.",
    "soho_requests_cancel_addon_failure_modal_description": "Kjo kërkesë nuk mund të procesoet. Provo përsëri më vonë.",
    "soho_requests_cancel_device_failure_modal_description": "Kjo kërkesë nuk mund të procesoet. Provo përsëri më vonë.",
    "soho_requests_cancel_licence_failure_modal_description": "Kjo kërkesë nuk mund të procesoet. Provo përsëri më vonë.",
    "soho_plans_plan_card_unlimited_consumption": "Pa limit",
    "soho_plans_plan_unlimited_consumption": "Pa limit",
    "soho_usage_usage_card_unlimited_consumption": "Pa limit",
    "soho_user_plan_tile_unlimited_title": "Pa limit",
    "soho_user_plan_tile_unlimited_description": "Akses interneti pa limit",
    "soho_licences_filter_unused_licences": "Licensa të papërdorura",
    "soho_requests_updated_label_timestamp": "Përditësuar %1$s",
    "soho_requests_updated_label_minute": "Përditësuar përpara %1$s min",
    "soho_requests_updated_label_minutes": "Përditësuar përpara %1$s min",
    "soho_requests_updated_label_updating": "Duke u përditësuar…",
    "soho_marketplace_pdp_summary_cost_breakdown_upfront": "Përpara",
    "soho_marketplace_pdp_summary_cost_upfront": "përpara",
    "soho_requests_pending_requests_requested_device_upgrade_from": "Përmirëso nga",
    "soho_devices_upgrade_now": "Përmirëso tani",
    "soho_plans_plan_card_upgrade_plan": "Përmirëso planin",
    "soho_requests_pending_requests_upgrade_requested_at": "Përmirësim i kërkuar",
    "soho_devices_upgrade_today_for": "Përmirëso tani për",
    "soho_business_overview_usage_tab_title": "Përdorimi",
    "soho_my_profile_usage": "Përdorimi",
    "soho_profile_management_user_details_usage": "Përdorimi",
    "soho_user_requests_title": "Kërkesat e përdoruesve",
    "soho_profile_addons_buy_quick_action_hint": "Përdoruesi do të mund ta përdorë këtë paketë shtesë duke filluar nga sot.",
    "soho_profile_addons_remove_quick_action_hint": "Përdoruesi do të jetë ende në gjendje ta përdorë këtë paketë shtesë deri në %1$s",
    "soho_addons_add_users_button": "Përdorues",
    "soho_my_users_and_groups_users_tab_title": "Përdoruesit",
    "soho_usage_users_title": "Përdorues",
    "soho_user_edit_add_ons_list_header": "Përdorues",
    "soho_plans_plan_users_title": "Përdorues në këtë plan",
    "soho_user_edit_add_ons_assigned_no_users_title": "Përdorues me këtë paketë shtesë",
    "soho_manage_requests_card_title": "Përdorues kërkon produktin",
    "soho_addons_buy_quick_action_hint": "Përdoruesit do të mund ta përdorin këtë paketë shtesë duke filluar nga sot.",
    "soho_addons_remove_quick_action_hint": "Përdoruesit do të mund ta përdorin këtë shtesë deri në %1$s",
    "soho_requests_view_details_button_title": "Shiko planin",
    "soho_usage_users_view_graphs_label": "Shiko grafikët",
    "soho_dashboard_applications_view_licence": "Shiko licensën",
    "soho_business_overview_cvm_page_banner_primary_button_title": "Shiko planin",
    "soho_plans_plan_card_view_plan": "Shiko planin",
    "soho_plans_plan_user_card_view_profile_label": "Shiko profilin",
    "soho_my_profile_addons_view_requests_label": "Shiko kërkesën",
    "soho_marketplace_licence_details_summary_header_description": "Pothuajse kemi mbaruar, rishiko detajet dhe konfirmon blerjen tënde…",
    "soho_my_users_and_groups_empty_users_description_text": "Informacionet do të shfaqen këtu sapo të shtosh përdoruesit tuaj.",
    "soho_my_users_and_groups_empty_groups_sub_title": "Informacionet do të shfaqen këtu sapo të krijosh grupin e parë.",
    "soho_dashboard_applications_web_builders": "Ndërtuesit e feqeve të internetit",
    "soho_marketplace_licence_details_product_information_what_is_different": "Çfarë është e ndryshme?",
    "soho_marketplace_pdp_summary_cost_breakdown_addons_why_modal_question": "Pse %1$s në faturën tënde të parë?",
    "soho_marketplace_pdp_your_plan_why_package_is_perfect": "Pse kjo paketë është perfekte për mua?",
    "soho_marketplace_purchase_why_this_plan_title": "Pse ky pplan është perfekt për mua?",
    "soho_marketplace_pdp_summary_cost_breakdown_addons_why_text": "Pse?",
    "soho_addons_buy_quick_action_description": "Dëshiron të blesh këtë paketë shtesë dhe t'ja aktivizosh të gjithë përdoruesve të përzgjedhur? ",
    "soho_business_overview_cvm_page_banner_description_plural": "Dëshiron ti rishikosh?",
    "soho_profile_management_cvm_page_banner_add_ons_description_plural": "Dëshiron ti rishikosh?",
    "soho_profile_management_cvm_page_banner_licence_description_singular": "Dëshiron ta rishikosh këtë kërkesë?",
    "soho_business_overview_cvm_page_banner_description_singular": "Dëshiron ta rishikosh?",
    "soho_profile_management_cvm_page_banner_add_ons_description_singular": "Dëshiron ta rishikosh?",
    "soho_profile_management_cvm_page_banner_licence_description_plural": "Dëshiron ti rishikosh këto kërkesa?",
    "soho_profile_management_cvm_page_banner_plans_description": "Dëshiron të shikosh këtë plan të rekomanduar?",
    "soho_marketplace_pdp_summary_addon_breakdown_item_yearly_per_licence": "Vjetore / licensë",
    "soho_business_overview_cvm_page_banner_licences_primary_button_title": "Po",
    "soho_profile_management_cvm_page_banner_add_ons_primary_button_title": "Po",
    "soho_multiple_requests_approve_addon_success_modal_extra_text": "Mund të vazhdosh të menaxhosh paketat shtesë për këtë aplikacion ose të ktheheni dhe të menaxhoni kërkesat e tjera.",
    "soho_multiple_requests_approve_device_success_modal_extra_text": "Mund të vazhdosh të menaxhosh pajisjet për këtë aplikacion ose të ktheheni dhe të menaxhoni kërkesat e tjera.",
    "soho_multiple_requests_approve_license_success_modal_extra_text": "Mund të vazhdosh të menaxhosh licensat shtesë për këtë aplikacion ose të ktheheni dhe të menaxhoni kërkesat e tjera.",
    "soho_plans_add_user_success_modal_subtitle": "Mund të vazhdosh te përmbledhja ose të ktheheni te faqja kryesore.",
    "soho_marketplace_pdp_purchase_successful_overlay_purchase_description": "Ti mund t'i menaxhosh këto licensa dhe t'ua caktosh ato punonjësve të tu, ose të ktheheni tek aplikacionet.",
    "soho_devices_upgrade_free_on": "Mund të përmirësosh planin falas në",
    "soho_devices_upgrade_from": "Mund të përmirësosh nga",
    "soho_addons_active_addons_empty_text": "Ti nuk ke asnje pakete shtese aktive. Perzgjidh Bli nje pakete ",
    "soho_addons_my_profile_my_addons_empty_text": "Ti nuk ke asnjë paketë shtesë. Kliko Kërko Paketa Shtesa.",
    "soho_my_users_and_groups_empty_groups_title": "Nuk ke asnjë grup të ngritur",
    "soho_my_profile_requested_addon_empty_text": "Nuk ke asnjë kerkesë per paketë shtesë. Kliko Kërko Paketa Shtesa.",
    "soho_my_users_and_groups_empty_users_title_text": "Nuk ke përdorues të disponueshëm",
    "soho_plans_empty_plans": "Nuk ke ende plane aktive",
    "soho_requests_past_empty_request_title": "Ju nuk keni asnjë kërkesë të mëparshme",
    "soho_requests_pending_empty_request_title": "Nuk ke asnjë kërkesë në pritje për momentin",
    "soho_licences_and_applications_count": "Ti ke %1$s licensa brenda %2$s aplikacioneve ",
    "soho_requests_reject_addon_quick_action_description": "Ti ke zgjedhur të refuzosh paketën shtesë  '%1$s'  për përdoruesin e zgjedhur",
    "soho_multiple_requests_reject_addon_quick_action_description": "Ti ke zgjedhur të refuzosh paketën shtesë  '%1$s'  për përdoruesit e zgjedhur",
    "soho_requests_reject_device_quick_action_description": "Ti ke zgjedhur të refuzosh pajisjen sipas kërkesës për '%1$s' për përdoruesin e zgjedhur",
    "soho_multiple_requests_reject_device_quick_action_description": "Ti ke zgjedhur të refuzosh pajisjen sipas kërkesës për '%1$s' për përdoruesit e zgjedhur",
    "soho_requests_reject_licence_quick_action_description": "Ti ke zgjedhur të refuzosh licensen sipas kërkesës për '%1$s' për përdoruesin e zgjedhur",
    "soho_multiple_requests_reject_licence_quick_action_description": "Ti ke zgjedhur të refuzosh licensen sipas kërkesës për '%1$s' për përdoruesit e zgjedhur",
    "soho_application_pdp_purchase_success_modal_description": "Ke blerë me sukses licensën %1$s!",
    "soho_plans_add_usage_user_result_success_message": "Ti ke kërkuar %1$s%2$s shtesë në planin tënd dhe ai i është dërguar administratorit.",
    "soho_plans_request_usage_result_success_message": "Ti ke kërkuar %1$s%2$s shtesë në planin tënd dhe ai i është dërguar administratorit.",
    "soho_profile_addons_request_quick_action_hint": "Ti do të mund ta përdoësh këtë paketë shtesë sapo të aprovohet kërkesa",
    "soho_requests_cancel_addon_success_modal_subtitle": "Së shpejti do të merrësh një njoftim konfirmimi",
    "soho_requests_cancel_device_success_modal_subtitle": "Së shpejti do të merrësh një njoftim konfirmimi",
    "soho_requests_cancel_licence_success_modal_subtitle": "Së shpejti do të merrësh një njoftim konfirmimi",
    "soho_requests_cancel_request_success_modal_subtitle": "Së shpejti do të merrësh një njoftim konfirmimi",
    "soho_marketplace_licence_details_request_success_modal_extra_text": "Së shpejti do të merrësh një njoftim konfirmimi.",
    "soho_plans_add_usage_result_success_message": "Ti ke shtuar një %1$s%2$s shtesë në plan",
    "soho_requests_approve_addon_success_modal_description": "Ti i ke caktuar paketën shtesë për '%1$s' për përdoruesin të zgjedhur",
    "soho_multiple_requests_approve_addon_success_modal_description": "Ti i ke caktuar paketën shtesë për '%1$s' për përdoruesit e zgjedhur",
    "soho_requests_approve_device_success_modal_description": "Ti i ke caktuar pajisjen për '%1$s' për përdoruesin të zgjedhur",
    "soho_multiple_requests_approve_device_success_modal_description": "Ti i ke caktuar pajisjen për '%1$s' për përdoruesit e zgjedhur",
    "soho_requests_approve_licence_success_modal_description": "Ti i ke caktuar licensen për '%1$s' për përdoruesin të zgjedhur",
    "soho_multiple_requests_approve_licence_success_modal_description": "Ti i ke caktuar licensen për '%1$s' për përdoruesit e zgjedhur",
    "soho_requests_cancel_addon_success_modal_description": "Ti ke anulluar kërkesën për ‘%1$s’",
    "soho_requests_cancel_device_success_modal_description": "Ti ke anuluar kërkesën tënde për \"%1$s\"",
    "soho_requests_cancel_licence_success_modal_description": "Ti ke anuluar kërkesën tënde për \"%1$s\"",
    "soho_marketplace_pdp_purchase_successful_overlay_purchase_details": "Ke blerë %1$s %2$s licenca me planin %3$s të zgjedhur.",
    "soho_requests_reject_addon_success_modal_description": "Ti ke refuzuar kërkesën e përdoruesit për \"%1$s\"",
    "soho_multiple_requests_reject_addon_success_modal_description": "Ti ke refuzuar kërkesaa e përdoruesit për \"%1$s\"",
    "soho_multiple_requests_reject_device_success_modal_description": "Ti ke refuzuar kërkesaa e përdoruesit për \"%1$s\"",
    "soho_multiple_requests_reject_licence_success_modal_description": "Ti ke refuzuar kërkesaa e përdoruesit për \"%1$s\"",
    "soho_requests_reject_device_success_modal_description": "Ti ke refuzuar kërkesaa e përdoruesit për \"%1$s\"",
    "soho_requests_reject_licence_success_modal_description": "Ti ke refuzuar kërkesaa e përdoruesit për \"%1$s\"",
    "soho_marketplace_pdp_summary_cost_breakdown_addons_why_modal_description": "Shuma e parë e faturës mund të jetë më e lartë sepse përfshin paraprakisht kostot e licencës dhe paketave shtesave .",
    "soho_my_users_and_groups_your_groups_text": "Grupet e tua",
    "soho_marketplace_pdp_summary_your_licence": "Licensat e tua",
    "soho_licences_your_licences": "Licensat e tua",
    "soho_marketplace_licence_details_stepper_your_plan_title": "Plani yt",
    "soho_marketplace_pdp_summary_your_plan": "Palni yt",
    "soho_marketplace_licence_details_request_success_modal_description": "Kërkesa I është dërguar administratorit.",
    "soho_requests_pending_requests_count": '%1$s kërkesa në pritje',
    soho_requests_pending_requests_count_one: '%1$s kërkesë në pritje',
    soho_user_requests_number_of_requested_user: 'Kërkuar nga %1$s përdorues',
    soho_business_overview_plans_tab_title: 'Planet tarifore',
    textarea_message_remaining: '%1$s karaktere të mbetura.',
    soho_monthly_billling_search_placeholder: 'Kërko',
    soho_monthly_billing_no_users: 'Nuk u gjend asnjë përdorues',
    soho_plans_section_title: "Shërbimi celular",
    soho_my_users_and_groups_empty_search: 'Nuk ka asnjë rezultat për "%1$s".',


    select_contact_select_phone: 'Zgjidh një kontakt',
    select_contact_cancel: 'Anullo',

    last_bill_mobile_service: 'Shërbimi celular',
    last_bill_fix_service: 'Shërbimi fiks',
    dashboard_last_bill_title: 'Fatura',

    notification_overlay_close: 'Close',
    notification_overlay_description: 'Aktivizimi i njoftimeve në pajisjen tënde do të të ndihmojë të përfitosh sa më shumë nga aplikacioni My Vodafone.',
    notification_overlay_enable_permission: 'Aktivizo njoftimet',
    notification_overlay_second_title: 'Dëshiron të aktivizosh njoftimet në pajisjen tënde?',
    notification_overlay_title: 'Cilesimet e pajisjes',

    soho_requests_past_requests_status_completed: "E Përfunduar",
    soho_requests_past_requests_status_cancelled: "E Anulluar",
    soho_requests_past_requests_status_rejected: "E Refuzuar",

    contacts_permission_alert_cancel_title: 'Anullo',
    contacts_permission_alert_confirm_title: 'Konfigurime',
    contacts_permission_alert_message: "Për të parë kontaktet e tua, duhet t'i japësh My Vodafone lejen për të aksesuar kontaktet e tua tek cilësimet",
    contacts_permission_alert_title: 'Përditëso cilësimet e tua',

    soho_plans_empty_title: 'Nuk ka asnjë shërbim aktiv',
    soho_plan_single_plan_title: 'Shërbim celular',
    soho_plans_empty_title: 'Nuk ka asnjë shërbim aktiv',

    update_app_version_title: 'Duhet te merrni versionin e ri',
    update_app_version_subtitle: 'Duhet te merrni versionin e ri',
    update_app_version_button: 'Shkarko versionin e ri',
    billing_dashboard_status_paid: "E paguar",
    billing_dashboard_status_unpaid: "Paguaj deri më %1$s%2$s %3$s",
    billing_dashboard_status_overdue: "Paguaj tani",


    cocacola_header_title: 'Skano & Fito',
    cocacola_reward: 'Fito internet',
    cocacola_qrcode: 'Skano kodin QR',
    cocacola_scan_qr: 'Skano kodin QR',
    cocacola_card_description: 'Si të fitoni internet falas: \n \n 1. Porosit një Coca-Cola Zero në Spar Food Court. \n 2. Skano kodin QR të faturës tënde këtu. \n 3. Përfito menjëherë internet falas.',
    cocacola_loading: 'Duke kontrolluar kodin…',
    cocacola_success_title: 'Urime!',
    cocacola_success_description: 'Ti sapo fitove %1$s falas, të vlefshme për %2$s.',
    cocacola_return: 'Kthehu në faqen kryesore',
    cocacola_error_header_title: 'Skano & Fito',
    cocacola_error_title: 'Ndodhi një problem!',
    cocacola_error_description: 'Kodi QR që skanuat nuk mund të verifikohet. Ju lutem skanoni kodin përsëri.',
    cocacola_error_try_again: 'Provo përsëri',
    cocacola_error_close: 'Mbyll',
    cocacola_notfound_title: 'Nuk u gjet asnjë produkt.',
    cocacola_notfound_description: 'Bli një prej menuve Coca-Cola për të përfituar GB internet falas.',
    cocacola_notfound_close: 'Mbyll',
    cocacola_camera_description: 'Vendos kodin QR brenda katrorit për ta skanuar dhe përfituar internetin falas.',
    cocacola_info_url: 'https://api-web-ci-vfal.vodafone.com/file/cocacola/info_al.html',
}