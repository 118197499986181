import { StyleSheet } from 'react-native'
import { Colors, Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'


const styles = StyleSheet.create({
  title: {
    ...Fonts.type.bold,
    fontSize: Fonts.size.regularSmall,
    marginHorizontal: 8,
  },
  container: {
    flex: 1,
    paddingHorizontal: 12,
    paddingTop: 15.5,
    paddingBottom: 8,
    display: 'grid'
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    backgroundColor: Colors.red,
    padding: 7,
    borderRadius: 8,
  },
  textContainer: {
    marginTop: 15,
    flex: 1
  },
  text: {
    fontSize: Fonts.size.regularSmall,
  },
  textsView: {
    marginTop: 10
  },
  flex: {
    flex: 1,
    borderRadius: 8,
    overflow: 'hidden'
  }
})


export default styles