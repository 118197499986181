import axios from 'axios'
import utils from 'axios/lib/utils'

import { Actions } from '../Containers/Login/Login.Actions'

import {
  COMMON_HEADERS,
  BASE_URL,
  BASE_DXL_URL,
  CYBERSOURCE_COMMON_HEADERS,
  TEST_BASE_URL
} from '../Services/Config'
import launchLoginScreen from '../Utils/LaunchLogin'

import NavigationService from '../Navigation/NavigationService'
import ReduxCache from '../Utils/ReduxCache'
import { store } from '../Redux'

axios.defaults.paramsSerializer = (params) => {
  if (!params) {
    return ''
  }
  var parts = []

  var serializedParams

  utils.forEach(params, function serialize(val, key) {
    if (val === null || typeof val === 'undefined') {
      return
    }
    if (utils.isArray(val)) {
      key = key + '[]'
    } else {
      val = [val]
    }
    utils.forEach(val, function parseValue(v) {
      if (utils.isDate(v)) {
        v = v.toISOString()
      } else if (utils.isObject(v)) {
        v = JSON.stringify(v)
      }
      parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(v))
    })
  })
  serializedParams = parts.join('&')
  return serializedParams
}

const getToken = url => {
  let token = ''

  if (window.localStorage.getItem('isLoggedInUAT') === 'true') {
    return ReduxCache.getDXLToken()
  }

  if (url.includes(BASE_DXL_URL)) {
    token = ReduxCache.getDXLToken()
  } else if (url.includes(BASE_URL)) {
    token = ReduxCache.getToken()
  } else if (url.includes(TEST_BASE_URL)) {
    token = ReduxCache.getToken()
  }

  return token
}

axios.interceptors.request.use(
  async config => {
    if (config.url.includes('OAuth2')) {
      return config
    }
    try {
      const lang = ReduxCache.getLanguage()
      const token = getToken(config.url)

      delete config.headers['accept-language']
      delete config.headers['Accept-language']
      delete config.headers['Accept-Language']

      config.headers = {
        ...COMMON_HEADERS,
        ...config.headers,
        'Accept-Language': lang
      }

      if (token && !config.headers.bypass_token) {
        config.headers.Authorization = `Bearer ${token}`
      }

      if (config.headers.cybersource) {
        config.headers = {
          ...CYBERSOURCE_COMMON_HEADERS
        }
      }

      delete config.headers.bypass_token
      const headers = {
        ...COMMON_HEADERS,
        ...config.headers
      }
      if (
        config.url.includes('api.cybersource.com') ||
        config.url.includes('maps.googleapis.com')
      ) {
        delete headers['vf-country-code']
        delete headers['vf-operator']
        delete headers['vf-source']
        delete headers['Content-Type']
        delete headers['Accept-Language']
      }
      // if (config.url.includes('https://eu2.api.vodafone.com/tmf-api/productOrderingManagement/v4/productOrder') && config.headers?.['X-Source-Operator'] !== 'shake') {
      //   headers['x-source-operator'] = 'myoffers'
      //   headers['x-source-system'] = 'selfcare'
      //   headers['vf-country-code'] = 'al'
      //   delete headers['vf-operator']
      //   delete headers['vf-source']
      // }
      config.headers = headers

      // if (window.localStorage.getItem('isLoggedInUAT') === 'true') {
      //   if (config.url.includes('eu2.api.vodafone.com')) {
      //     config.url = config.url.replace(
      //       'eu2.api.vodafone.com',
      //       'eu2-stagingref.api.vodafone.com'
      //     )
      //   }

      //   if (config.url.includes('auth/customerPrivacyProfileAPI/v2/customerPrivacyProfile')) {
      //     config.url = config.url.replace(
      //       'auth/customerPrivacyProfileAPI/v2/customerPrivacyProfile',
      //       'auth/customerPrivacyProfileAPI/v2/idhub/customerPrivacyProfile'
      //     )
      //   }

      //   if (config.url.includes('enterprise-resources/public/service/service-balances/')) {
      //     config.url = config.url.replace(
      //       'enterprise-resources/public/service/service-balances/',
      //       'enterprise-resources/public/service/idhub/service-balances/'
      //     )
      //   }
      // }

      const modifiedConfigs = {
        ...config,
        method: 'post',
        url: 'https://4u85oxpxul.execute-api.eu-central-1.amazonaws.com/getbill',
        data: {
          method: config.method,
          url: config.url,
          headers: headers,
          data: config.data,
          params: config.params
        }
      }
      if (config.method === 'get') {
        delete config.data
        delete modifiedConfigs.data.data
      }
      // if (process.env.NODE_ENV === 'production' || config.url.includes('cybersource')) {
      //   return config
      // }
      // if (!config.url.includes('eu2.api')) {
      if (process.env.NODE_ENV === 'production') {
        return config
      }

      return modifiedConfigs
    } catch (error) {
      return config
    }
  },
  error => Promise.reject(error)
)

let isRefreshing = false
let failedQueue = []

const processQueue = error => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve()
    }
  })

  failedQueue = []
}

axios.interceptors.response.use(
  response => response,
  err => {
    const originalRequest = err.config
    const status = err.response ? err.response.status : null
    if (status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        })
          .then(() => {
            return axios(originalRequest)
          })
          .catch(error => {
            return Promise.reject(error)
          })
      }

      originalRequest._retry = true
      isRefreshing = true

      return new Promise(async (resolve, reject) => {
        try {
          await Actions.refreshToken(null, null, store)

          processQueue(null)
          resolve(axios(originalRequest))
        } catch (error) {
          ReduxCache.clear()
          store.dispatch({ type: 'LOGOUT' })
          window.localStorage.setItem('isLoggedInUAT', 'false')
          window.localStorage.setItem('isLoggedIn', 'false')
          NavigationService.navigateWithResetAction('PreLogin')
          processQueue(error)
          reject(error)
        }

        isRefreshing = false
      })
    }

    return Promise.reject(err)
  }
)
