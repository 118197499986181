import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import { API_URLS } from '../../Config'

const mapError = (error) => {
  const payload = error?.response?.data?.message || error?.message
  return {
    message: payload
  }
}

const USER_ROLES = ['granter', 'user', 'employee']

const usersResponseMapper = (res) => {

  const { individual } = res

  const mappedResponse = []

  if (!individual || individual.length === 0) {
    return []
  }

  individual.map(item => {
    const { id, fullName, relatedParty } = item

    let isAdmin = false
    const groups = []
    const roles = []

    if (relatedParty && relatedParty.length !== 0) {
      relatedParty.map(el => {
        const { id: groupId, name, role } = el

        if (role === 'granter') {
          isAdmin = true
        }

        if (USER_ROLES.includes(role)) {
          roles.push(role)
        }

        groups.push({ id: groupId, name })
      })
    }


    mappedResponse.push({
      id,
      name: fullName,
      isAdmin,
      groups,
      roles,
      icon: 'ic_indicators_business'
    })

  })


  return mappedResponse
}


const getUsers = async (id, utilities) => {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
    responseMapper: usersResponseMapper
  })

  const { onStart, onSuccess, onError, errorMapper, responseMapper } = _utilities

  onStart()

  const headers = {
    'vf-project': 'soho',
  }

  const params = {
    'individual.relatedParty.id': id,
  }

  try {
    const response = await axios.get(API_URLS.business.usersAndGroup.users, {
      headers: headers,
      params: params,
    })
    const _response = responseMapper(response?.data)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

export {
  getUsers
}
