import axios from 'axios'

import CONFIG from '../../Config'
import { getErrorMessage } from '../../Utils'
import ReduxCache from '../../Utils/ReduxCache'

import { action_types } from './profile.actionTypes'

const getHistory = (type, page, order = {}) => {
  return async dispatch => {
    dispatch({
      type: action_types.REQUEST_HISTORY,
      payload: type,
    })

    const msisdn = ReduxCache.getMsisdn()

    const limit = 20
    const url = await CONFIG.getUrl()
    const headers = await CONFIG.getProfileHeaders()

    let queryURl = `?loyaltyAccountId=${msisdn}&count=${page}&limit=${limit}&type=${type}`

    if (type == 'earned') {
      if (order.column) {
        queryURl += `&fields=${order.column}`
      }

      if (order.type) {
        queryURl += `&sort=${order.type}`
      }
    }

    axios
      .get(url.historyEndpoint + queryURl, {
        headers: {
          ...headers,
        },
        data: null,
      })
      .then(response => {
        const products = []

        for (var i in response.data) {
          const coupon = response.data[i]
          const id = coupon.productOffering?.id?.[0]?.value

          const product = {
            id,
            price: coupon.loyaltyAccountAmount.value,
            currency: coupon.loyaltyAccountAmount.currencyID,
            date: coupon.lastModified.value,
            name: coupon.name,
            desc: coupon.desc,
            validity: coupon.characteristic[0][0].validityPeriod.toDate.date,
            categoryId: coupon?.productOffering?.category?.[0].value,
          }

          products.push(product)
        }

        dispatch({
          type: action_types.SUCCESS_HISTORY,
          payload: {
            type: type,
            products: products,
            page: page,
          },
        })
      })
      .catch(error => {
        const message = getErrorMessage(error)
        dispatch({
          type: action_types.ERROR_HISTORY,
          payload: {
            type: type,
            error: message,
          },
        })
      })
  }
}

export const actions = { getHistory }
