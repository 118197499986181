import React, { Component } from 'react'
import { View, Text, TouchableOpacity, ScrollView} from 'react-native'
import {connect} from 'react-redux'


import { Footer, FooterTab, Button } from 'native-base'
import FastImage from 'react-native-fast-image'


import GridList from 'react-native-grid-list'

import LargeHeader from '../../Components/LargeHeader'


import Loader from '../../Components/Loader'
import { __translate, __image } from '../../Utils'

import styles from './survey.styles'
import { Images } from '../../Themes'
import { actions } from './survey.actions'

class Survey extends Component  {

  constructor(props) {
    super(props)
    this.state = {
      items: {},
      showThankYou: false
    }
  }

  componentDidMount() {
    this.props.getPreferences()
  }

  retry(){
    this.props.getPreferences()
  }

  handleSelectedRadionButton(id) {

    this.setState({
      items: {
        ...this.state.items,
        [id]: !this.state.items[id]
      }
    })
  }

  componentDidUpdate() {
    if (this.props.preferences.submited){
      setTimeout(() => {
        this.props.navigation.navigate('App')
      }, 2000)
    }
  }

  sendPreferences(){

    const items = this.state.items
    const preferences = { ...this.props.preferences.data }

    const selectedItems = []
    for (const i in items){
      if (items[i]){
        selectedItems.push(i)
      }
    }

    const selectedObjects = []
    for (var i in preferences.relatedPreference){
      const id = preferences.relatedPreference[i].id[0].value
      if (selectedItems.indexOf(id) > -1){
        preferences.relatedPreference[i].status = (preferences.relatedPreference[i].status == 'inactive') ? 'active' : 'inactive'
        selectedObjects.push(preferences.relatedPreference[i])
      }
    }

    preferences.relatedPreference = selectedObjects

    this.props.sendPreferences(preferences)

  }

  isSelected(id) {
    if (typeof this.state.items[id] !== 'undefined'){
        return this.state.items[id]
    }
    return false
  }

  renderItem = ({ item, index }) => (

    <View style={styles.containerStyle}>
      <TouchableOpacity onPress={() => this.handleSelectedRadionButton(item.id[0].value)}>
        <FastImage
          style={styles.itemImage}
          source={{ uri: item.desc }}
        />
        <View style={styles.textAndRadioContainer}>
            <View style={styles.radioIos}>
              { this.isSelected(item.id[0].value) && <View style={styles.innerRadio} /> }
            </View>

            <Text style={styles.catName}>{item.name}</Text>

        </View>
      </TouchableOpacity>
    </View>
  )

  render () {
    const { preferences } = this.props

    if (!preferences.loading && preferences.data){
      return (
        <View style={styles.background}>
          <FastImage
            style={styles.background}
            source={Images.survey_background}
          >
            <LargeHeader />
            <ScrollView >
              <GridList style={{ ...styles.grid }}
                showSeparator
                data={preferences.data.relatedPreference}
                numColumns={3}
                renderItem={this.renderItem}
              />
            </ScrollView>

            <Footer style={styles.footer}>
              <FooterTab>
                <Button onPress={() => this.sendPreferences()} vertical style={styles.submit}>
                  <Text style={styles.buttonText}> {__translate('reward_loyalty_survey_bt_submit')} </Text>
                </Button>

                <Button onPress={() => this.props.navigation.navigate('Catalog')} vertical style={styles.skip}>
                  <Text style={styles.buttonText}> {__translate('reward_loyalty_survey_bt_skip')} </Text>
                </Button>
              </FooterTab >
            </Footer>
          </FastImage>
          { preferences.submited &&
            <View style={styles.thankYou}>
              <FastImage
                style={styles.thankYouImage}
                source={{ uri: __image('reward_loyalty_survey_submission') }}
              />
              <Text style={styles.thankYouText}> {__translate('reward_loyalty_survey_submission')} </Text>
            </View>
          }
        </View>
      )
    }

    if (preferences.error){
      return (
        <View style={styles.background}>
          <FastImage
            style={styles.background}
            source={Images.survey_background}
          >
            <Error message={'Something went wrong!'} retry={() => this.retry()} />
          </FastImage>
        </View>
      )
    }

    return (
      <View style={styles.background}>
        <FastImage
            style={styles.background}
            source={Images.survey_background}
          >
          <Loader size={65} view={'full'} />
        </FastImage>
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  const { preferences, user, splash } = state
  return { preferences, user, splash }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPreferences: () => {
      dispatch(actions.getPreferences())
    },
    sendPreferences: (preferences) => {
      dispatch(actions.sendPreferences(preferences))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Survey)
