import React, {Component} from 'react'
import {Text} from 'react-native'

import styles from './vfText.styles'

class VfText extends Component {
  render() {
    return (
      <Text
        {...this.props}
        style={[
          styles.default,
          styles['align_' + this.props.align],
          styles[this.props.size],
          styles[this.props.weight],
          this.props.style,
        ]}>
        {this.props.children}
      </Text>
    )
  }
}

VfText.defaultProps = {
  size: 'body',
  weight: 'normal',
  align: 'left',
}

export default VfText
