import React, { useState, useEffect, useRef } from 'react'
import { StatusBar, Platform } from 'react-native'
import PropTypes from 'prop-types'

import { useSelector, useDispatch } from 'react-redux'

import { Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { Migration as MigrationScreen } from '@vfal-lab/rn-vfg-migration/Screens'
import { VFScreen } from '@vfgroup-oneplatform/foundation/Components'

import ReduxCache from '../../Utils/ReduxCache'

import { personalNumberRegex } from '../../Utils/Regex'

import { actions } from './Migration.Actions'
import styles from './Migration.Styles'

const MAX_NUMBER_OF_RETRIES = 3
const MAX_NUM_OF_RETRY_CONFIRMATION = 2

const rvalues = {
  true: 'ON',
  false: 'OFF',
}

const NOTIFY_SCREENS = [
  'OfferSeen',
  'TermsSeen',
  'VerificationSeen',
  'Accepted',
  'Raised',
  'VerificationFail',
]

const RAISED = 'Raised'



function Migration({ navigation, route }) {
  const msisdn = ReduxCache.getMsisdn()
  const { type = 'test' } = route.params || {}



  /** Get offer details */
  const getOfferDetails = async (utilities) => {
    return dispatch(actions.getOfferDetails(type, utilities))
  }

  const [selectedOffer, setSelectedOffer] = useState(-1)
  const offerDetails = useSelector(state => state.offerDetails)
  // const extraBenefits = offerDetails?.data?.extraBenefits?.map((item) => (Object.assign({}, extraDetailsStyles, item)))

  /** Selected activation period */
  const [selectedPeriod, setSelectedPeriod] = useState(-1)

  /** Terms and conditions */
  const [toggleAll, setToggleAll] = useState(false)

  const getPreferences = async (utilities) => {
    return dispatch(actions.getPreferences(utilities))
  }

  const preferences = useSelector(state => state.migrationPreferences)

  const termsAndConditions = preferences?.data

  const updatePreference = async (id, value) => {
    return dispatch(actions.updatePreference(id, value, termsAndConditions))
  }

  const updateAllPreferences = async (value) => {
    return dispatch(actions.updateAllPreferences(rvalues[value], termsAndConditions))
  }

  const indexOfDisabled = termsAndConditions?.findIndex(item => item.value === 'OFF')
  const acceptedRequired = termsAndConditions?.findIndex(item => item.necessity === 'required' && item.value === 'OFF') === -1

  useEffect(() => {
    setToggleAll(indexOfDisabled === -1)
  }, [indexOfDisabled])


  const updateRequest = useSelector(state => state.preferencePayload.data)

  const updatePreferences = async (utilities) => {
    return dispatch(actions.updatePreferences(updateRequest, termsAndConditions, utilities))
  }

  /** Account info */
  const [idNumber, setIdNumber] = useState('')
  const [email, setEmail] = useState('')
  const [vatNumber, setVatNumber] = useState('')
  const [selectedAccountType, setSelectedAccountType] = useState(0)

  const acountTypes = {
    0: 'Individual',
    1: 'Business',
  }

  const accountTypeOptions = [
    {
      label: 'migration_individual_account',
    },
    {
      label: 'migration_business_account',
    },
  ]

  const searchAccount = async (utilities) => {
    return dispatch(actions.searchAccount(idNumber, acountTypes[selectedAccountType], email, utilities))
  }

  /** Pin Generation and Validation */
  const [numberOfRetries, setNumberOfRetries] = useState(0)
  const pinGeneration = useSelector(state => state.pinGeneration)
  const pinValidation = useSelector(state => state.pinValidation)
  const dispatch = useDispatch()

  const GeneratePin = async (utilities) => {
    return dispatch(actions.generatePin(utilities))
  }
  const ValidatePin = async (pinCode, utilities) => {
    return dispatch(actions.validatePin(pinCode, utilities))
  }

  /** Last 3 rechages and 3 most called numbers input */
  const lastRechargeInput = useRef(null)
  const mostCalledNumberInput = useRef(null)

  const [lastRecharge, setLastRecharge] = useState('')
  const [lastRecharges, setLastRecharges] = useState([])
  const [mostCalledNumber, setMostCalledNumber] = useState('')
  const [mostCalledNumbers, setMostCalledNumbers] = useState([])

  const addToLastRecharge = () => {
    if (lastRecharges.length < 3 && lastRecharge && lastRecharge !== '') {
      setLastRecharges(previousValue => {
        previousValue.push(lastRecharge)
        return [...previousValue]
      })
      setLastRecharge('')
      lastRechargeInput.current.setValue('')
    }
  }
  const removeFromLastRecharge = (index) => {
    setLastRecharges(previousValue => {
      previousValue.splice(index, 1)
      return [...previousValue]
    })
  }
  const addToMostCalled = value => {
    const number = value || mostCalledNumber
    if (mostCalledNumbers.length < 3 && !!number) {
      const alRegex = /^(3556|003556|\+3556|06)/
      if (mostCalledNumbers.findIndex(el => {
        const entry = el.replace(/\s/g, ''), newEntry = number.replace(/\s/g, '')
        return entry === newEntry || entry.replace(alRegex, '') === newEntry.replace(alRegex, '')
      }) === -1) {
        setMostCalledNumbers(previousValue => {
          previousValue.push(number)
          return [...previousValue]
        })
        setMostCalledNumber('')
        mostCalledNumberInput.current.setValue('')
      } else {
        setMostCalledNumber(number)
        mostCalledNumberInput.current.setValue(number)
      }
    }
  }
  const removeFromMostCalled = (index) => {
    setMostCalledNumbers(previousValue => {
      previousValue.splice(index, 1)
      return [...previousValue]
    })
  }

  /** Personal details */
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [address, setAddress] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [contactNumber, setContactNumber] = useState('')

  /** Camera related states and functions */
  const [imageFile, setImageFile] = useState(null)

  const notify = async (screen, utilities = {}) => {
    const data = offerDetails.data?.[selectedOffer] || {}
    return dispatch(actions.notify(
      data.id,
      data.title,
      data.desc,
      data.category,
      data.status,
      data.details,
      data.campaign,
      data.activationPeriodPayload?.[selectedAccountType],
      NOTIFY_SCREENS?.[screen],
      utilities
    ))
  }

  const id = useSelector(state => state.accountSearch.data)

  const createOrder = async (pin, utilities = {}) => {

    const data = offerDetails.data?.[selectedOffer] || {}
    const personalInfo = {
      name,
      familyName: surname,
      idCard: id,
      accountType: acountTypes[selectedAccountType],
      vatNumber,
      address,
      emailAddress,
      contactNumber,
      email,
    }

    const campaign = data.campaign || {}
    const price = data.price || {}
    const lineItem = [
      {
        id: campaign.id,
        name: campaign.name,
        desc: campaign.desc,
        type: campaign.type,
        category: campaign.category,
        status: campaign.status,
        action: campaign.action,
        oneOffPrice: [
          {
            amount: {
              value: price.original_price?.priceDetail?.value,
              currencyID: price.original_price?.priceDetail?.currencyID,
            },
            discountAmount: {
              value: price.discounted_price?.priceDetail?.value,
              currencyID: price.discounted_price?.priceDetail?.currencyID,
            },
          },
        ],
      },
    ]

    return dispatch(actions.createOrder(
      data.id,
      data.type,
      data.category,
      data.status,
      data.activationPeriodPayload?.[selectedAccountType],
      personalInfo,
      lineItem,
      pin,
      lastRecharges,
      mostCalledNumbers,
      imageFile,
      utilities
    ))
  }

  // const resetStatusBar = () => {
  //   if (Platform.OS === 'android'){
  //     StatusBar.setBarStyle('light-content', true)
  //     StatusBar.setBackgroundColor(Colors.thinDark, true)
  //     StatusBar.setTranslucent(false)
  //   } else if (Platform.OS === 'ios'){
  //     StatusBar.setBarStyle('dark-content', true)
  //   }
  // }

  const onClose = async () => {
    // resetStatusBar()
    navigation.pop()
  }

  return (
    <VFScreen
      headerStyle={styles.headerStyle}
      hideMainHeader
    >
      <MigrationScreen
        isContentLoading={false}
        onClose={onClose}
        notify={notify}
        existingOrder={offerDetails?.[0]?.status === RAISED}
        type={type}
        offers={{
          ...offerDetails,
          getOfferDetails,
          selectedOffer,
          setSelectedOffer,
          selectedPeriod,
          setSelectedPeriod,
          isNextDisabled: selectedOffer === -1
            || selectedPeriod === -1
            || !acceptedRequired
            || preferences?.error,
          next: () => { },
        }}
        // offer={{
        //   type: type,
        //   getOfferDetails: getOfferDetails,
        //   loading: offerDetails?.loading,
        //   title: offerDetails?.data?.title,
        //   details: offerDetails?.data?.lineItem,
        //   extraBenefits: extraBenefits,
        //   price: offerDetails?.data?.price,
        // }}
        termsAndConditions={{
          getPreferences,
          loading: preferences?.loading,
          content: termsAndConditions,
          updatePreference,
          updateAllPreferences,
          updatePreferences,
          toggleAll,
          isNextDisabled: !acceptedRequired || preferences?.error,
          next: () => { },
          acceptedRequired
        }}
        accountInfo={{
          accountTypeOptions: accountTypeOptions,
          id: idNumber,
          setIdNumber: setIdNumber,
          idFormat: personalNumberRegex,
          email: email,
          setEmail: setEmail,
          emailFormat: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
          vatNumber,
          setVatNumber,
          vatNumberFormat: /^[A-Z]{1}\d{8}[A-Z]{1}$/,
          selectedAccountType: selectedAccountType,
          setSelectedAccountType: setSelectedAccountType,
          searchAccount: searchAccount,
          isNextDisabled: !idNumber
          // ||email
          ,
          next: () => { },
        }}
        pin={{
          pinLength: 5,
          MSISDN: msisdn,
          generatePin: GeneratePin,
          validatePin: ValidatePin,
          pinGeneration: pinGeneration,
          pinValidation: pinValidation,
          numberOfRetries: numberOfRetries,
          MAX_NUMBER_OF_RETRIES: MAX_NUMBER_OF_RETRIES,
          setNumberOfRetries: setNumberOfRetries,
          next: () => { },
        }}
        verifyAccountInfo={{
          lastRechargeInput: lastRechargeInput,
          setLastRechargeInput: ref => { lastRechargeInput.current = ref },
          mostCalledNumberInput: mostCalledNumberInput,
          setMostCalledNumberInput: ref => { mostCalledNumberInput.current = ref },
          lastRecharge: lastRecharge,
          setLastRecharge: setLastRecharge,
          lastRechargeFormat: /^\d+([\,\.]\d{1,6})?$/,
          minRecharge: 100,
          maxRecharge: 10000,
          lastRecharges: lastRecharges,
          setLastRecharges: setLastRecharges,
          mostCalledNumber: mostCalledNumber,
          setMostCalledNumber: setMostCalledNumber,
          mostCalledNumberFormat: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,

          mostCalledNumbers: mostCalledNumbers,
          setMostCalledNumbers: setMostCalledNumbers,

          addToLastRecharge: addToLastRecharge,
          removeFromLastRecharge: removeFromLastRecharge,
          addToMostCalled: addToMostCalled,
          removeFromMostCalled: removeFromMostCalled,

          name: name,
          setName: setName,
          nameFormat: /^[a-zA-Z]{3,}$/,
          surname: surname,
          setSurname: setSurname,
          surnameFormat: /^[a-zA-Z]{3,}$/,
          address: address,
          setAddress: setAddress,
          addressFormat: /^[\s\S]{4,}$/,
          emailAddress: emailAddress,
          setEmailAddress: setEmailAddress,
          contactNumber: contactNumber,
          setContactNumber: setContactNumber,
          numberFormat: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,

          imageFile: imageFile,
          setImageFile: setImageFile,
          isRemoveImageDisabled: !imageFile,

          isNextDisabled: lastRecharges.length < 3 ||
            mostCalledNumbers.length < 3 ||
            !name ||
            !surname ||
            !address ||
            // !emailAddress ||
            !contactNumber ||
            !imageFile,
          next: () => { },

        }}
        createOrder={createOrder}
        maxNumOfRetryConfirmation={MAX_NUM_OF_RETRY_CONFIRMATION}
      />
    </VFScreen>
  )
}

Migration.defaultProps = {
  route: {
    type: 'P2H'
  }
}

Migration.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object
}
export default Migration
