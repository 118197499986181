import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'
import moment from 'moment'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import ReduxCache from '../../Utils/ReduxCache'

import { API_URLS } from '../Config'



const mapError = (error) => {
  const payload = error?.response?.data?.message || error?.message
  return payload
}

const pukResponseMapper = (response) => {
  const data = response.data[0]?.resourceCharacteristic
  const pukData = {}

  data.map(item => {
    const name = item?.name
    const value = item?.id
    pukData[name] = value
  })
  return pukData
}

const formatDate = (date) => {
  if (date.format('DD/MM/YYYY') === 'Invalid date') {
    return '—'
  } else {
    return date.format('DD/MM/YYYY')
  }
}

const accountResponseMapper = (response) => {

  const data = response?.data[0]

  const accountType = data?.accountType.toLowerCase()

  const balanceData = []
  const billData = []
  const balanceExtraData = []

  if (accountType !== 'postpaid') {

    const startDate = moment(data?.accountBalance[0]?.validFor?.startDateTime)
    const endDate = moment(data?.accountBalance[0]?.validFor?.endDateTime)

    let formatedEndDate = formatDate(endDate)

    const lastRechargeDate = formatDate(moment(data?.lastModified))

    let lastRechargeValue = data?.accountBalance[0]?.amount.value + ' ' + data?.accountBalance[0]?.amount.unit

    if (lastRechargeDate === '01/01/1970') {
      formatedEndDate = '—'
      lastRechargeValue = '—'
    }

    const balanceValidity = endDate.diff(startDate, 'M')


    balanceData.push({ title: 'hyperlink_last_recharge', value: lastRechargeValue })

    balanceData.push({ title: 'hyperlink_recharge_date', value: lastRechargeDate === '01/01/1970' ? '—' : lastRechargeDate })

    if (accountType === 'hybrid') {
      balanceData.push({ title: 'hyperlink_balance_duration', value: formatedEndDate })
    }

    if (accountType === 'prepaid') {
      balanceExtraData.push({ title: 'hyperlink_balance_validity', value: balanceValidity.toString() + ContentManager.translate('hyperlink_months') })
      balanceExtraData.push({ title: 'hyperlink_deactive', value: formatedEndDate })
    }
  }

  if (accountType !== 'prepaid') {

    const startDate = moment(data?.billStructure?.cycleSpecification?.validFor?.startDateTime)
    const formatedStartDate = formatDate(moment(startDate))
    const endDate = moment(data?.billStructure?.cycleSpecification?.validFor?.endDateTime)
    const formatedEndDate = formatDate(endDate)

    const billDuration = isNaN(endDate.diff(startDate, 'M')) ? '—' : endDate.diff(startDate, 'M').toString() + ContentManager.translate('hyperlink_months')

    billData.push({ title: 'hyperlink_starting_from', value: formatedStartDate })
    billData.push({ title: 'hyperlink_until', value: formatedEndDate })
    billData.push({ title: 'hyperlink_duration', value: billDuration })
  }

  const details = (accountType !== 'prepaid') ? billData : balanceData
  const extraDetails = (accountType === 'hybrid') ? balanceData : (accountType === 'prepaid' ? balanceExtraData : [])

  return { accountType: accountType, details: details, extraDetails: extraDetails }
}

async function getPuk(
  customHeaders = {},
  utilities = {},
) {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
  })

  const {
    errorMapper,
    onError,
    onStart,
    onSuccess,
  } = _utilities

  onStart()

  const params = {
    'relatedParty.id': ReduxCache.getMsisdn()
  }

  try {
    const response = await axios.get(API_URLS.productsAndServices.getPuk, {
      headers: customHeaders,
      params,
    })
    const _response = pukResponseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

async function getAccountInfo(
  customHeaders = {},
  utilities = {},
) {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
  })

  const {
    errorMapper,
    onError,
    onStart,
    onSuccess,
  } = _utilities

  onStart()

  const params = {
    'relatedParty.id': ReduxCache.getMsisdn(),
    'relatedParty.role': 'subscriber',
  }

  try {
    const response = await axios.get(API_URLS.productsAndServices.accountManagement, {
      headers: customHeaders,
      params,
    })
    const _response = accountResponseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

export { getPuk, getAccountInfo }
