import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import { VFText, VFButton } from '@vfgroup-oneplatform/foundation/Components'

import ReduxCache from '../../../Utils/ReduxCache'

import { getMsisdnData, isBusinessChooser } from '../../../Utils'

import styles from './AccountSwitch.Styles'

const AccountSwitchButton = ({
  onPress
}) => {

  const [username, setUsername] = useState()

  const msisdn = ReduxCache.getMsisdn()
  const businessAccount = ReduxCache.getBusinessAccount()

  const businessName = businessAccount?.businessName


  useEffect(() => {
    getMsisdnData().then(res => {
      setUsername(res?.username)
    }).catch(() => { })
  }, [])

  if (!isBusinessChooser()) { return null }

  return (
    <View>
      <VFText
        i18nKey="account_switch_tray_subtitle"
        style={styles.username}
        placeHolders={[businessAccount.showBusinessView ? businessName : (username || msisdn)]}
      />
      <VFButton
        title="account_switch_tray_button"
        style={styles.switchContainer}
        type="secondary"
        onPress={onPress}
        textStyle={styles.buttonText}
      />
    </View>
  )
}
AccountSwitchButton.defaultProps = {
  onPress: () => { }
}
AccountSwitchButton.propTypes = {
  onPress: PropTypes.func,
}

export default AccountSwitchButton
