import { StyleSheet, Platform } from 'react-native'
import { Colors, Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'hidden',
    borderRadius: 6
  },
  boldWordStyle: {
    fontFamily: Fonts.type.bold.fontFamily,
    fontSize: 16.64,
    fontWeight: Fonts.type.bold.fontWeight
  },
  buttonStyle: {
    backgroundColor: Colors.white,
    borderRadius: 6,
    marginBottom: 10,
    fontFamily: Fonts.type.VFFont
  },
  buttonTitleStyle: {
    color: Colors.dark_grey,
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: 18.7,
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0,
    lineHeight: 24,
    textAlign: 'center'
  },
  paymentMethodTitleStyle: {
    color: Colors.white,
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: 25,
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: 38.5,
    letterSpacing: 0,
    lineHeight: 31.2,
    width: 291.2
  },
  subtitleStyle: {
    color: Colors.white,
    fontSize: 16.6,
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: -0.28,
    marginBottom: 30
  },
  titleStyle: {
    color: Colors.white,
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: 25,
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0,
    lineHeight: 31.2,
    marginTop: 10
  },
  paymentMethod: {
    flexDirection: 'row'
  },
  iconAndTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: hasBackground => ({
    marginRight: 8,
    marginTop: 10,
    marginLeft: 10,
    tintColor: hasBackground ? Colors.white : 'default'
  }),
  subtitleConatier: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginHorizontal: 12.5
  },
  title: {
    // marginTop: 10,
    marginLeft: 6,
    ...Fonts.type.bold,
    fontSize: Platform.OS === 'ios' ? 16.6 : 16
  },
  subtitle: {
    fontSize: Platform.OS === 'ios' ? 16.6 : 16,
    lineHeight: 22.9
  },
  rightSubtitle: theme => ({
    color: theme.colors.textColorTwo,
    fontSize: Platform.OS === 'ios' ? 14.6 : 14,
    lineHeight: 18.6,
    marginBottom: 1
  }),
  messagesSubtitle: (theme, hasBackground) => ({
    // color: hasBackground ? Colors.white : theme.colors.textColorTwo,
    fontSize: Platform.OS === 'ios' ? 14.5 : 15,
    lineHeight: 22,
    // marginBottom: 9,
    // marginLeft: 10,
  }),
  messagesContainer: {
    justifyContent: 'space-between',
    height: '95%'
  },
  topUpContiner: {
    height: '100%',
    justifyContent: 'space-between'
  },
  topUpSubtitleContainer: {
    marginBottom: 5
  },
  lottieStyle: {
    width: 25,
    height: 25,
    marginRight: 12,
    marginTop: 3,
    marginLeft: 3,
  }
})
