import { action_types } from './profile.actionTypes'

const history = (
  state = {
    loading: false,
    data: {
      earned: { data: null, loading: false },
      used: { data: null, loading: false },
      credit: { data: null, loading: false },
      debit: { data: null, loading: false },
      active: { data: null, loading: false },
    }, error: null
  }, action = {}) => {
  switch (action.type) {
    case action_types.REQUEST_HISTORY:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload]: {
            ...state.data[action.payload],
            loading: true,
          }
        },
        loading: true,
      }
    case action_types.SUCCESS_HISTORY:
      const oldData = state.data[action.payload.type].data || []
      if (action.payload.page === 0) {
        return {
          ...state,
          loading: false,
          error: null,
          data: {
            ...state.data,
            [action.payload.type]: {
              data: action.payload.products,
              loading: false,
            },
          },
        }
      }
      return {
        ...state,
        loading: false,
        error: null,
        data: {
          ...state.data,
          [action.payload.type]: {
            data: (oldData || []).concat(action.payload.products),
            loading: false
          }
        }
      }
    case action_types.ERROR_HISTORY:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.payload]: {
            ...state.data[action.payload.type],
            loading: false,
          },
        },
        error: action.payload.error
      }
    default:
      return state
  }
}

export { history }
