import { Platform, StyleSheet } from 'react-native'
import { Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  favoritesIcon: {
    width: 35,
    height: 35,
  },
  card: {
    borderRadius: 10,
    overflow: 'hidden',
  },
  content: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 20,
  },
  partner: {
    position: 'absolute',
    top: '160px',
    left: '5px',
    zIndex: 999,
    padding: '4px',
    borderRadius: '8px',
  },
  date: {
    backgroundColor: '#fff',
    position: 'absolute',
    top: '160px',
    right: 10,
    zIndex: 9
  },
  partnerText: {
    fontSize: 15,
  },
  dateText: {
    fontSize: 13,
    color: '#000',
    fontWeight: 'bold',
  },
  offer: {
    color: '#000',
    fontSize: 13,
  },
  offerValue: {
    color: '#000',
    fontSize: 13,
    fontWeight: 'bold',
  },
  favHeart: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 2,
  },
  cardItem: {
    padding: 0,
    height: Platform.OS === 'ios' ? 35 : 45,
  },
  left: {
    marginLeft: -15,
  },
  body: {
    alignItems: 'center',
    flex: 1
  },
  right: {

  },
  moreButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  moreIcon: {
    width: 35,
    height: 25,
    paddingLeft: 10,
    paddingRight: 10,
  },
  moreIconOpened: {
    width: 35,
    height: 25,
    paddingLeft: 10,
    paddingRight: 10,
    transform: [
      {
        rotate: '180deg',
      },
    ],
  },
  priceValue: {
    fontWeight: 'bold',
  },
  priceText: {
    fontWeight: 'normal',
  },
  main: {
    borderTopRightRadius: 10,
    zIndex: 1,
    position: 'relative'
  },
  moreText: {
    color: Colors.vfRed
  },
  contentWebView: (size = 80) => ({
    minHeight: size
  })
});