import axios from 'axios'

import ReduxCache from '../../Utils/ReduxCache'

import { API_URLS, CLIENT_ID, CLIENT_SECRET } from '../Config'

async function changePassword(
    oldPassword,
    newPassword,
    customHeaders = {}
) {

    const data = [
        {
            'op': 'replace',
            'path': '$.changeAuthenticationCredentialAPI',
            'value': {
                'details': {
                    'credentialType': 'password',
                    'oldPasswordText': oldPassword,
                    'passwordHintText': '',
                    'passwordText': newPassword,
                    'usernameText': ReduxCache.getMsisdn()
                },
                'category': [
                    {
                        'listHierarchyId': '1',
                        'value': 'password'
                    }
                ],
                'desc': 'CheckAuthenticationCredential',
                'name': 'CheckAuthenticationCredential',
                'parts': {
                    'specification': {
                        'category': [
                            {
                                'listHierarchyId': '1',
                                'value': 'token'
                            }
                        ],
                        'characteristicsValue': [
                            {
                                'characteristicName': 'client_id',
                                'creationTime': {
                                    'value': ''
                                },
                                'description': '',
                                'type': '',
                                'validityPeriod': {
                                    'fromDate': {
                                        'date': ''
                                    },
                                    'toDate': {
                                        'date': ''
                                    }
                                },
                                'value': CLIENT_ID
                            },
                            {
                                'characteristicName': 'client_secret',
                                'creationTime': {
                                    'value': ''
                                },
                                'description': '',
                                'type': '',
                                'validityPeriod': {
                                    'fromDate': {
                                        'date': ''
                                    },
                                    'toDate': {
                                        'date': ''
                                    }
                                },
                                'value': CLIENT_SECRET
                            }
                        ],
                        'desc': 'ConsumerChangeAuthenticationCredential',
                        'id': [
                            {
                                'schemeAgencyName': 'Vodafone',
                                'schemeName': 'SpecificationID',
                                'value': '123'
                            }
                        ],
                        'name': 'ConsumerChangeAuthenticationCredential',
                        'status': '',
                        'type': 'token'
                    }
                },
                'type': 'password'
            }
        }
    ]

    await axios.patch(`${API_URLS.changePassword}/${ReduxCache.getMsisdn()}`, data, {
        headers: customHeaders,
    })
}

export { changePassword }
