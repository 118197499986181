import React from 'react'
import { ViewPropTypes, View } from 'react-native'
import PropTypes from 'prop-types'

import RadioButton from './RadioButton'

import { styles } from './RadioGroup.Styles'

function RadioGroup({
    options,
    style,
    optionStyle,
    activeOptionStyle,
    labelStyle,
    activeLabelStyle,
    selected,
    setSelected
}) {

    return (
        <View style={[styles.container, style]}>
            {options.map((item, index) => {
                const active = index === selected
                const isLast = index === options.length - 1
                return (
                    <RadioButton
                        key={index}
                        id={index}
                        label={item.label}
                        isActive={active}
                        optionStyle={optionStyle}
                        activeOptionStyle={activeOptionStyle}
                        labelStyle={labelStyle}
                        activeLabelStyle={activeLabelStyle}
                        setSelected={setSelected}
                        disabled={!setSelected}
                        isLast={isLast}
                    />
                )
            })}
        </View>
    )
}

RadioGroup.defaultProps = {
    options: [],
    style: {},
    optionStyle: {},
    activeOptionStyle: {},
    labelStyle: {},
    activeLabelStyle: {},
    selected: -1,
    setSelected: () => { }
}

RadioGroup.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string
            ]),
            label: PropTypes.string
        })
    ),
    style: ViewPropTypes.style,
    optionStyle: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    activeOptionStyle: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    labelStyle: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    activeLabelStyle: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    selected: PropTypes.number,
    setSelected: PropTypes.func
}

export default RadioGroup
