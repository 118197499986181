import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'
import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import ReduxCache from '../../Utils/ReduxCache'

import { API_URLS, COMMON_HEADERS, GOOGLE_GEOCODING_KEY } from '../Config'

const mapError = error => {
  return error?.response?.data?.message || error?.message
}

const MSISDN = ReduxCache.getMsisdn()

// !!! DO NOT REMOVE !!!
// This will be used in the future to get the roaming service state

// const findIndexOfFeatureCharacteristic = (data, id) => {
//   if (data && id) {
//     return data?.feature[0].featureCharacteristic.findIndex(el =>
//       el.id.includes(id)
//     )
//   }
// }

const servicesAndDataCapMapper = response => {
  const data = response

  // This will be added in a later moment
  // !!! DO NOT REMOVE !!!
  // const services = {
  //   allServiceStatus: data?.isServiceEnabled,
  //   calls:
  //     data?.feature[0].featureCharacteristic[
  //       findIndexOfFeatureCharacteristic(data, 'voice') != -1
  //         ? findIndexOfFeatureCharacteristic(data, 'voice')
  //         : 0
  //     ].value === 'enabled',
  //   data:
  //     data?.feature[0].featureCharacteristic[
  //       findIndexOfFeatureCharacteristic(data, 'data') != -1
  //         ? findIndexOfFeatureCharacteristic(data, 'data')
  //         : 0
  //     ].value === 'enabled',
  //   sms:
  //     data?.feature[0].featureCharacteristic[
  //       findIndexOfFeatureCharacteristic(data, 'sms') != -1
  //         ? findIndexOfFeatureCharacteristic(data, 'sms')
  //         : 0
  //     ].value === 'enabled'
  // }

  const schemas = []
  data[0]?.feature[0]?.constraint?.map((schema, index) => {
    schemas.push({
      id: schema.id,
      label: schema.id,
      icon: 'ic_roamingSMS',
      selected: schema.name === 'Active',
      info: schema.href,
      index: index
    })
  })

  return { schemas }
}


const getRoamingServicesAndDataCap = async (
  customHeaders = {},
  utilities = {}
) => {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError
  })

  const { onStart, onSuccess, onError, errorMapper } =
    _utilities

  onStart()

  try {
    const response = await axios.get(API_URLS.roaming.servicesAndDataCap, {
      header: { ...COMMON_HEADERS, ...customHeaders }
    })

    const _response = servicesAndDataCapMapper(response.data)

    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const updateServicesAndDataCap = async (
  _data,
  utilities = {}
) => {

  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError
  })
  const headers = {
    'Content-Type': 'application/json-patch+json',
  }

  const { onStart, onSuccess, onError, errorMapper } =
    _utilities

  onStart()

  // const { serviceEnabled, specificServices, dataCapSchemas } = _data || {}
  const { type, dataCapSchemas, selectedSchema } = _data || {}

  const data = {
    id: 'roaming',
    serviceType: 'roaming/roamingFeature/roamingConstraint',
    isServiceEnabled: true,
    isStateful: true,
    feature: [
      {
        id: 'roaming',
        isBundle: true,
        isEnabled: true,
        name: 'Roaming Services',
        constraint:
          type?.includes('changer') ? [{
            id: selectedSchema?.id,
            href: selectedSchema?.info,
            name: 'active'
          }]
            :
            dataCapSchemas.map(item => {
              return {
                id: item.id,
                href: item.info,
                name: item.selected ? 'active' : 'inactive'
              }
            }),
        featureCharacteristic: [
          {
            id: 'voice',
            name: 'Calls',
            valueType: 'string',
            // value: specificServices.calls ? 'enabled' : 'disabled'
            value: 'enabled'
          },
          {
            id: 'data',
            name: 'Data',
            valueType: 'string',
            // value: specificServices.data ? 'enabled' : 'disabled'
            value: 'enabled'
          },
          {
            id: 'sms',
            name: 'SMS',
            valueType: 'string',
            // value: specificServices.sms ? 'enabled' : 'disabled'
            value: 'enabled'
          }
        ]
      }
    ],
    relatedParty: [
      {
        id: ReduxCache.getMsisdn(),
        role: 'subscriber',
        '@referredType': 'subscriber'
      }
    ],
    state: '',
    '@baseType': 'string',
    '@schemaLocation': 'string',
    '@type': type?.includes('deactivation') ? 'deactivation' : 'activation'
  }

  try {
    const response = await axios.patch(API_URLS.roaming.servicesAndDataCap, data, { headers },)
    onSuccess(response.data)
    return response.data[0].description
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}


const recommandationMapper = response => {
  const data = response[0]

  if (data) {
    const recommandation = {
      title: data.name,
      description: data.description,
      bundle: data.recommendationItem[0]?.product?.isBundle,
      myOfferId: data.id,
      longDesc:
        data.recommendationItem[0].product.productCharacteristic[5].value || null,
      CTAType: data.recommendationItem[0].product.productCharacteristic[1].value,
      CTATitle: data.recommendationItem[0].product.productCharacteristic[0].value,
      CTAUrl: data.recommendationItem[0].product.productCharacteristic[2].value
    }
    return recommandation
  }
  return null
}

const getRoamingRecommandation = async (
  place,
  customHeaders = {},
  utilities = {}
) => {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError
  })

  const { onStart, onSuccess, errorMapper } =
    _utilities

  onStart()

  const userType = ReduxCache.getUserType()

  try {
    const response = await axios.get(API_URLS.roaming.roamingRecommandation, {
      header: customHeaders,
      params: {
        'relatedParty.id': MSISDN,
        type: 'roaming',
        'place.id': place,
        'marketSegment.id': userType
      }
    })


    const _response = recommandationMapper(response.data)

    onSuccess(_response)

    return _response
  } catch (error) {
    const _error = errorMapper(error)
    throw _error
  }
}

const offersMapper = async response => {
  const data = response[0]?.productOffering
  const bundles = []
  data?.map(item => {
    const priceString = item.name.split('\n')[1].trim()
    const priceValue = parseInt(priceString?.split(' ')?.[0], 10)
    const priceUnit = priceString?.split(' ')?.[1]
    bundles.push({
      id: item.id,
      title: item.name.split('\n')[0].trim(),
      price: priceValue ? priceValue + ' ' + priceUnit : priceString
    })
  })

  return bundles
}

const getRoamingOffers = async (place, utilities, customHeaders = {}) => {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError
  })


  const { onStart, onSuccess, errorMapper } = _utilities

  onStart()

  try {
    const response = await axios.get(API_URLS.roaming.roamingOffers, {
      header: customHeaders,
      params: {
        'relatedParty.id': MSISDN,
        id: 'roaming',
        place: 'place'
      }
    })

    if (response) {
      const _response = offersMapper(response.data)
      onSuccess(_response)
      return _response
    }
  } catch (error) {
    const _error = errorMapper(error)
    throw _error
  }
}

const getCountryFromCoordinates = async (lat, lng, utilities) => {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError
  })

  const { onStart, onSuccess, onError, errorMapper } = _utilities

  onStart()

  try {
    const response = await axios.get(API_URLS.googleGeocoding, {
      params: {
        latlng: `${lat},${lng}`,
        key: GOOGLE_GEOCODING_KEY
      }
    })

    const _response = countryFromCoordinatesMapper(response)

    onSuccess(_response)

    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const countryFromCoordinatesMapper = data => {
  data?.results.filter(item => {
    item.types.includes('country')
  })

  let response = 'AL'

  if (data?.results) {
    response = data?.results[0].address_components[0].short_name
  }

  return response
}


// const servicesResponse = {
//   id: 'roaming',
//   isServiceEnabled: true,
//   isStateful: true,
//   serviceType: 'roaming',
//   feature: [
//     {
//       id: 'roaming',
//       isBundle: true,
//       isEnabled: true,
//       name: 'Roaming Services',
//       constraint: [
//         {
//           id: 'Schema 1',
//           href: 'Schema 1 more info',
//           name: 'enabled'
//         },
//         {
//           id: 'Schema 2',
//           href: 'Schema 2 more info',
//           name: 'disabled'
//         },
//         {
//           id: 'Schema 3',
//           href: 'Schema 3 more info',
//           name: 'disabled'
//         }
//       ],
//       featureCharacteristic: [
//         {
//           id: 'voice',
//           name: 'Calls',
//           valueType: 'string',
//           value: 'enabled'
//         },
//         {
//           id: 'data',
//           name: 'Data',
//           valueType: 'string',
//           value: 'disabled'
//         },
//         {
//           id: 'sms',
//           name: 'SMS',
//           valueType: 'string',
//           value: 'enabled'
//         }
//       ]
//     }
//   ],
//   relatedParty: [
//     {
//       id: '35569',
//       role: 'subscriber',
//       '@referredType': 'subscriber'
//     }
//   ],
//   state: '',
//   '@baseType': 'string',
//   '@schemaLocation': 'string',
//   '@type': 'string'
// }

// const OffersRESPONSE = [
//   {
//     id: 'roaming',
//     productOffering: [
//       {
//         id: 'RoamingBundleId1',
//         href: '',
//         name: 'Roaming Bundle 1 \n 100 ALL',
//         '@referredType': 'productOffering'
//       },
//       {
//         id: 'RoamingBundleId2',
//         href: '',
//         name: 'Roaming Bundle 2 \n 100 ALL',
//         '@referredType': 'productOffering'
//       },
//       {
//         id: 'RoamingBundleId3',
//         href: '',
//         name: 'Roaming Bundle 3 \n 100 ALL',
//         '@referredType': 'productOffering'
//       }
//     ]
//   }
// ]

// const recommandationRESPONSE = [
//   {
//     id: 'Infinity_PP2',
//     description: 'Me shume internet per ty ne Roaming, ne shtetet xxx...',
//     name: 'Internet pa limit 4G',
//     recommendationType: 'bundle',
//     channel: [
//       {
//         id: 'selfcare'
//       }
//     ],
//     recommendationItem: [
//       {
//         product: {
//           id: 'Infinity_PP2',
//           description: 'Internet pa limit',
//           isBundle: true,
//           isCustomerVisible: true,
//           name: 'Internet pa limit 4G',
//           product: [
//             {
//               id: 'internet',
//               name: 'Internet (MB)',
//               productCharacteristic: [
//                 {
//                   name: 'type',
//                   value: 'internet'
//                 },
//                 {
//                   name: 'amount',
//                   value: '204800'
//                 },
//                 {
//                   name: 'unit',
//                   value: 'MB'
//                 }
//               ]
//             }
//           ],
//           productCharacteristic: [
//             {
//               name: 'longDesc',
//               value:
//                 'E Re! 4G ska limit me Vodafone. Shtoji planit tënd internet pa limit në shpejtësinë maksimale 4G deri në fund të muajit për vetëm 600 lekë (me TVSH). Aktivizo para 27/02/2022.',
//               '@type': 'default'
//             },
//             {
//               name: 'CTA',
//               value: 'Aktivizo tani',
//               '@type': 'default'
//             },
//             {
//               name: 'CTAUrl',
//               value: 'details',
//               '@type': 'default'
//             },
//             {
//               name: 'Behavior',
//               value: 'NO_TEXT',
//               '@type': 'default'
//             },
//             {
//               name: 'icon_name',
//               value: 'pega_bundle',
//               '@type': 'attachment'
//             },
//             {
//               name: 'image_details',
//               value: 'third_image',
//               '@type': 'attachment'
//             },
//             {
//               name: 'image',
//               value:
//                 'https://api-web-ci-vfal.vodafone.com/file/mva10/myoffers/pegaimage.png',
//               '@type': 'attachment'
//             },
//             {
//               name: 'counter',
//               value: 'NO_TEXT',
//               '@type': 'default'
//             },
//             {
//               name: 'Section',
//               value: 'Top',
//               '@type': 'default'
//             },
//             {
//               name: 'payment_methods',
//               value: 'Bill',
//               '@type': 'default'
//             }
//           ],
//           productPrice: [
//             {
//               priceType: 'final',
//               price: {
//                 taxRate: 0.0,
//                 taxIncludedAmount: {
//                   unit: ' Lekë',
//                   value: 600.0
//                 }
//               }
//             },
//             {
//               priceType: 'loan',
//               price: {
//                 taxRate: 20.0,
//                 taxIncludedAmount: {
//                   unit: ' Lekë',
//                   value: 0.0
//                 }
//               }
//             }
//           ],
//           productTerm: [
//             {
//               duration: {
//                 amount: 0.0,
//                 units: 'ditë'
//               }
//             }
//           ]
//         }
//       }
//     ],
//     relatedParty: {
//       id: '355699609617',
//       '@referredType': 'subscriber'
//     }
//   }
// ]

// const geocodingRESPONSE = {
//   plus_code: {
//     compound_code: 'WP4M+RPR Karbunarë e Vogël, Albania',
//     global_code: '8FGXWP4M+RPR'
//   },
//   results: [
//     {
//       address_components: [
//         {
//           long_name: 'WP4M+RP',
//           short_name: 'WP4M+RP',
//           types: ['plus_code']
//         },
//         {
//           long_name: 'Karbunarë e Vogël',
//           short_name: 'Karbunarë e Vogël',
//           types: ['locality', 'political']
//         },
//         {
//           long_name: 'Lushnjë District',
//           short_name: 'Lushnjë District',
//           types: ['administrative_area_level_2', 'political']
//         },
//         {
//           long_name: 'Fier County',
//           short_name: 'Fier County',
//           types: ['administrative_area_level_1', 'political']
//         },
//         {
//           long_name: 'Albania',
//           short_name: 'AL',
//           types: ['country', 'political']
//         }
//       ],
//       formatted_address: 'WP4M+RP Karbunarë e Vogël, Albania',
//       geometry: {
//         bounds: {
//           northeast: {
//             lat: 40.907125,
//             lng: 19.734375
//           },
//           southwest: {
//             lat: 40.907,
//             lng: 19.73425
//           }
//         },
//         location: {
//           lat: 40.9071207,
//           lng: 19.734271
//         },
//         location_type: 'GEOMETRIC_CENTER',
//         viewport: {
//           northeast: {
//             lat: 40.9084114802915,
//             lng: 19.7356614802915
//           },
//           southwest: {
//             lat: 40.9057135197085,
//             lng: 19.73296351970849
//           }
//         }
//       },
//       place_id: 'GhIJJwP2hxx0REARtmYrL_m7M0A',
//       plus_code: {
//         compound_code: 'WP4M+RP Karbunarë e Vogël, Albania',
//         global_code: '8FGXWP4M+RP'
//       },
//       types: ['plus_code']
//     },
//     {
//       address_components: [
//         {
//           long_name: 'WP5P+88G',
//           short_name: 'WP5P+88G',
//           types: ['plus_code']
//         },
//         {
//           long_name: 'Karbunarë e Vogël',
//           short_name: 'Karbunarë e Vogël',
//           types: ['locality', 'political']
//         },
//         {
//           long_name: 'Lushnjë District',
//           short_name: 'Lushnjë District',
//           types: ['administrative_area_level_2', 'political']
//         },
//         {
//           long_name: 'Fier County',
//           short_name: 'Fier County',
//           types: ['administrative_area_level_1', 'political']
//         },
//         {
//           long_name: 'Albania',
//           short_name: 'AL',
//           types: ['country', 'political']
//         }
//       ],
//       formatted_address: 'WP5P+88G, Karbunarë e Vogël, Albania',
//       geometry: {
//         location: {
//           lat: 40.9083003,
//           lng: 19.7358381
//         },
//         location_type: 'GEOMETRIC_CENTER',
//         viewport: {
//           northeast: {
//             lat: 40.9096492802915,
//             lng: 19.7371870802915
//           },
//           southwest: {
//             lat: 40.9069513197085,
//             lng: 19.7344891197085
//           }
//         }
//       },
//       place_id: 'ChIJWyqWVW0FUBMRxdYRYJa8CHA',
//       types: ['establishment', 'point_of_interest', 'school']
//     },
//     {
//       address_components: [
//         {
//           long_name: 'SH72',
//           short_name: 'SH72',
//           types: ['route']
//         },
//         {
//           long_name: 'Rrethi i Lushnjës',
//           short_name: 'Rrethi i Lushnjës',
//           types: ['administrative_area_level_2', 'political']
//         },
//         {
//           long_name: 'Qarku i Fierit',
//           short_name: 'Qarku i Fierit',
//           types: ['administrative_area_level_1', 'political']
//         },
//         {
//           long_name: 'Albania',
//           short_name: 'AL',
//           types: ['country', 'political']
//         }
//       ],
//       formatted_address: 'SH72, Albania',
//       geometry: {
//         bounds: {
//           northeast: {
//             lat: 40.9069166,
//             lng: 19.7376696
//           },
//           southwest: {
//             lat: 40.9020823,
//             lng: 19.7302919
//           }
//         },
//         location: {
//           lat: 40.9045678,
//           lng: 19.7340564
//         },
//         location_type: 'GEOMETRIC_CENTER',
//         viewport: {
//           northeast: {
//             lat: 40.9069166,
//             lng: 19.7376696
//           },
//           southwest: {
//             lat: 40.9020823,
//             lng: 19.7302919
//           }
//         }
//       },
//       place_id: 'ChIJO5aaIXUGUBMRcgxMXAA8VZ8',
//       types: ['route']
//     },
//     {
//       address_components: [
//         {
//           long_name: 'Karbunarë e Vogël',
//           short_name: 'Karbunarë e Vogël',
//           types: ['locality', 'political']
//         },
//         {
//           long_name: 'Lushnjë District',
//           short_name: 'Lushnjë District',
//           types: ['administrative_area_level_2', 'political']
//         },
//         {
//           long_name: 'Fier County',
//           short_name: 'Fier County',
//           types: ['administrative_area_level_1', 'political']
//         },
//         {
//           long_name: 'Albania',
//           short_name: 'AL',
//           types: ['country', 'political']
//         }
//       ],
//       formatted_address: 'Karbunarë e Vogël, Albania',
//       geometry: {
//         bounds: {
//           northeast: {
//             lat: 40.9171772,
//             lng: 19.7369385
//           },
//           southwest: {
//             lat: 40.9067341,
//             lng: 19.7295571
//           }
//         },
//         location: {
//           lat: 40.9111327,
//           lng: 19.7331247
//         },
//         location_type: 'APPROXIMATE',
//         viewport: {
//           northeast: {
//             lat: 40.9171772,
//             lng: 19.7369385
//           },
//           southwest: {
//             lat: 40.9067341,
//             lng: 19.7295571
//           }
//         }
//       },
//       place_id: 'ChIJbV-W2d0FUBMRNj2A3nVM5kE',
//       types: ['locality', 'political']
//     },
//     {
//       address_components: [
//         {
//           long_name: 'Lushnjë District',
//           short_name: 'Lushnjë District',
//           types: ['administrative_area_level_2', 'political']
//         },
//         {
//           long_name: 'Fier County',
//           short_name: 'Fier County',
//           types: ['administrative_area_level_1', 'political']
//         },
//         {
//           long_name: 'Albania',
//           short_name: 'AL',
//           types: ['country', 'political']
//         }
//       ],
//       formatted_address: 'Lushnjë District, Albania',
//       geometry: {
//         bounds: {
//           northeast: {
//             lat: 41.06486599999999,
//             lng: 19.817515
//           },
//           southwest: {
//             lat: 40.759831,
//             lng: 19.4351119
//           }
//         },
//         location: {
//           lat: 40.9664367,
//           lng: 19.6163185
//         },
//         location_type: 'APPROXIMATE',
//         viewport: {
//           northeast: {
//             lat: 41.06486599999999,
//             lng: 19.817515
//           },
//           southwest: {
//             lat: 40.759831,
//             lng: 19.4351119
//           }
//         }
//       },
//       place_id: 'ChIJsx4A8-ADUBMRhimKICJGF7M',
//       types: ['administrative_area_level_2', 'political']
//     },
//     {
//       address_components: [
//         {
//           long_name: 'Fier County',
//           short_name: 'Fier County',
//           types: ['administrative_area_level_1', 'political']
//         },
//         {
//           long_name: 'Albania',
//           short_name: 'AL',
//           types: ['country', 'political']
//         }
//       ],
//       formatted_address: 'Fier County, Albania',
//       geometry: {
//         bounds: {
//           northeast: {
//             lat: 41.0711799,
//             lng: 19.9268561
//           },
//           southwest: {
//             lat: 40.4201419,
//             lng: 19.3069471
//           }
//         },
//         location: {
//           lat: 40.9191392,
//           lng: 19.6639309
//         },
//         location_type: 'APPROXIMATE',
//         viewport: {
//           northeast: {
//             lat: 41.0711799,
//             lng: 19.9268561
//           },
//           southwest: {
//             lat: 40.4201419,
//             lng: 19.3069471
//           }
//         }
//       },
//       place_id: 'ChIJmfqXjzz8TxMRtcOcOmoEGGE',
//       types: ['administrative_area_level_1', 'political']
//     },
//     {
//       address_components: [
//         {
//           long_name: 'Albania',
//           short_name: 'AL',
//           types: ['country', 'political']
//         }
//       ],
//       formatted_address: 'Albania',
//       geometry: {
//         bounds: {
//           northeast: {
//             lat: 42.6611669,
//             lng: 21.0572394
//           },
//           southwest: {
//             lat: 39.6447296,
//             lng: 19.1217
//           }
//         },
//         location: {
//           lat: 41.153332,
//           lng: 20.168331
//         },
//         location_type: 'APPROXIMATE',
//         viewport: {
//           northeast: {
//             lat: 42.6611669,
//             lng: 21.0572394
//           },
//           southwest: {
//             lat: 39.6447296,
//             lng: 19.1217
//           }
//         }
//       },
//       place_id: 'ChIJLUwnvfM7RRMR7juY1onlfAc',
//       types: ['country', 'political']
//     }
//   ],
//   status: 'OK'
// }



export { getRoamingRecommandation, getRoamingOffers, getCountryFromCoordinates, getRoamingServicesAndDataCap, servicesAndDataCapMapper, updateServicesAndDataCap }
