import React, { useLayoutEffect, useRef } from 'react'
import { Animated, Easing, View } from 'react-native'
import PropTypes from 'prop-types'

import styles from './ProgressBar.Styles'

const Progress = ({
    // Index is used to recreate another progress bar with each story increment
    index,
    // Progress bar's filling color
    color,
    // Progress bar's background color
    backgroundColor,
    style,
    // If story's self index is less than that of the order of the stories passed it should appear as seen and the progress bar should appear full
    seen,
    height,
    pause = false,
    width,
    // This conditional tells if the the story is the current one so the animation should start
    startAnimation = true,
    seconds,
    // animDuration,
    progress,
    // indexationSetter,
    testID = 'progress-bar',
}) => {

    const barWidth = useRef(new Animated.Value(1)).current

    useLayoutEffect(() => {

        startAnimation && Animated.timing(barWidth, {
            value: 0,
            toValue: progress || width * 2,//progress,
            // duration: animDuration > seconds? (animDuration-seconds)*1000 + 500 : seconds*1000 + 500,//animDuration,
            duration: seconds * 1000 + 500,
            easing: Easing.linear,
            useNativeDriver: true,
        }).start()
        if (pause){
            barWidth.stopAnimation((e) => {
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pause, seconds])


    return (
        <View
            testID={testID}
            key={index}
            style={[
                styles.container(width),
                height ? { height } : 6,
                backgroundColor ? { backgroundColor } : 'gray',
                style,
            ]}>
            {seen ?
                <View style={styles.seen(width)} />
                :
                <Animated.View style={styles.bar(color, startAnimation, barWidth)} />
            }
        </View>
    )
}


Progress.propTypes = {
    index: PropTypes.number,
    color: PropTypes.string,
    seen: PropTypes.bool,
    backgroundColor: PropTypes.string,
    style: PropTypes.object,
    height: PropTypes.number,
    width: PropTypes.number,
    startAnimation: PropTypes.bool,
    animDuration: PropTypes.number,
    progress: PropTypes.number,
    testID: PropTypes.string,
    seconds: PropTypes.number,
    pause: PropTypes.bool,
}

Progress.defaultProps = {
    startAnimation: true,
    testID: 'progress-bar',
}

export default Progress
