import React from 'react'
import { View, ImageBackground, Platform, Dimensions } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import PropTypes from 'prop-types'

import { isAdminView } from '../../../../Utils'

import Images from '../../../Themes/Images'

import styles from './DashboardBackground.Styles'


const DashboardBackground = ({ children, statusBarBackground }) => {

  if (Dimensions.get('window').width > 700) {
    return (children)
  }

  if (Platform.OS === 'web') {
    return (
      <div
        style={{
          'background-image': `url(${isAdminView() ? Images.background_soho_waves_red.uri : Images.background_waves_red.uri})`,
          height: '100vh',
          width: '100vw',
          'background-size': 'cover',
          overflow: 'scroll',
          padding: 0,
        }}
      >
        {children}
      </div>
    )
  }


}
DashboardBackground.propTypes = {
  statusBarBackground: PropTypes.string,
  children: PropTypes.element
}

export default DashboardBackground
