import {AppRegistry, LogBox} from 'react-native'

import App from './Containers/App'
import app from './app.json'

LogBox.ignoreLogs([
  'Warning: Failed prop type',
  'Warning: Each child in a list',
  'Warning: Invalid argument supplied to oneOf',
  'EventEmitter.removeListener',
  'Non-serializable values were found',
  'Invalid `viewBox` prop'
])

AppRegistry.registerComponent(app.appName, () => App)
AppRegistry.runApplication(app.appName, {
  rootTag: document.getElementById('root')
})