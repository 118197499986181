import { StyleSheet, Dimensions } from 'react-native'

import { Colors, Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

const { width: SCREEN_WIDTH } = Dimensions.get('screen')

export default StyleSheet.create({
  searchButtonContainer: (disabled, theme) => ({
    backgroundColor: disabled ? Colors.lightGrey : Colors.vfRed,
    width: '15%',
    height: 55,
    marginLeft: -6,
    marginTop: 6,
    borderWidth: 1,
    borderColor: theme.colors.textInput.default.baseColor,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    justifyContent: 'center',
    alignItems: 'center'
  }),
  searchContentContainer: theme => ({
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.borderColorThree,
    marginBottom: 30,
  }),
  textInputContainerStyle: theme => ({
    backgroundColor: theme.colors.cardBackgroundColor,
    borderRadius: 6,
  }),
  inputContainerStyle: {
    marginHorizontal: 0,
    width: '100%',
  },
  rightIconStyle: {
    marginTop: -5,
    resizeMode: 'contain'
  },
  modalContainer: insets => ({
    width: SCREEN_WIDTH - 32,
    position: 'absolute',
    bottom: insets.bottom,
  }),
  contactsContainer: theme => ({
    borderRadius: 12,
    backgroundColor: theme.colors.cardBackgroundColor,
    overflow: 'hidden'
  }),
  selectPhoneText: theme => ({
    paddingVertical: 16,
    textAlign: 'center',
    color: theme.colors.textColorTwo,
    fontSize: Fonts.size.regularSmall
  }),
  contactsItem: theme => ({
    backgroundColor: theme.colors.cardBackgroundColor,
    borderTopWidth: 1,
    borderTopColor: theme.colors.borderColorThree,
    paddingVertical: 16,
    alignItems: 'center'
  }),
  text: {
    fontSize: Fonts.size.regular
  },
  cancelButton: theme => ({
    backgroundColor: theme.colors.cardBackgroundColor,
    marginTop: 10,
    paddingVertical: 16,
    alignItems: 'center',
    borderRadius: 12
  }),
  title: {
    fontSize: Fonts.size.h4,
    fontFamily: Fonts.type.VodafoneLt,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  modalContentContainer: theme => ({
    backgroundColor: theme.colors.cardBackgroundColor,
    margin: 0,
    paddingHorizontal: 16,
    marginTop: 50,
    borderRadius: 6
  })
})
