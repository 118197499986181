import React from 'react'
import PropTypes from 'prop-types'
import AppSettings from '@vfgroup-oneplatform/framework/Settings/AppSettings'
import OptionItem from '@vfgroup-oneplatform/framework/Settings/components/OptionItem'
import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { getThemeImages } from '../../Group/Themes'

import NavigationService from '../../Navigation/NavigationService'


import ReduxCache from '../../Utils/ReduxCache'

function ManageProfile({ navigation: { pop }, ...props }) {

    const userType = ReduxCache.getUserType()

    const theme = useTheme()
    const images = getThemeImages(theme.name)

    const profileSettings = {
        title: 'profile_settings_title',
        description: 'profile_settings_subtitle',
        withCard: true,
        items: [
            {
                component: OptionItem,
                props: {
                    icon: 'ic_Users_Members_Customers',
                    title: 'profile_settings_edit_title',
                    description: 'profile_settings_edit_subtitle',
                    onPress: () => NavigationService.navigate('EditProfile')
                }
            },
            {
                component: OptionItem,
                props: {
                    icon: 'ic_show_password_insights',
                    title: 'profile_settings_edit_password_title',
                    description: 'profile_settings_edit_password_subtitle',
                    onPress: () => userType === 'guest' ? NavigationService.navigate('TransparentPopup') : NavigationService.navigate('ChangeMyPassword')
                }
            },
            {
                component: OptionItem,
                props: {
                    icon: 'ic_admin',
                    title: 'profile_settings_deactivate_title',
                    description: 'profile_settings_deactivate_subtitle',
                    onPress: () => userType === 'guest' ? NavigationService.navigate('TransparentPopup') : NavigationService.navigate('DeactivateAccount')
                }
            }
        ]
    }

    const onClose = () => pop()

    return (
        <AppSettings
            title="profile_manage_title"
            settings={[profileSettings]}
            onClose={onClose}
            images={images}
            withTray
            {...props}
        />
    )
}
ManageProfile.propTypes = {
    navigation: PropTypes.object,
    theme: PropTypes.object
}

export default ManageProfile
