export const actionTypes = {
  REQUEST_PROFILE: 'REQUEST_PROFILE',
  SUCCESS_PROFILE: 'SUCCESS_PROFILE',
  ERROR_PROFILE: 'ERROR_PROFILE',
  INITIAL_PROFILE: 'INITIAL_PROFILE',
  REQUEST_ALL_ITEMS: 'REQUEST_ALL_ITEMS',
  SUCCESS_ALL_ITEMS: 'SUCCESS_ALL_ITEMS',
  ERROR_ALL_ITEMS: 'ERROR_ALL_ITEMS',
  SUCCESS_PLAIN_PRODUCTS: 'SUCCESS_PLAIN_PRODUCTS',
  REQUEST_LB_REDEEM_HANDLE: 'REQUEST_LB_REDEEM_HANDLE',
  SUCCESS_LB_REDEEM_HANDLE: 'SUCCESS_LB_REDEEM_HANDLE',
  ERROR_LB_REDEEM_HANDLE: 'ERROR_LB_REDEEM_HANDLE',
  RESET_LB_REDEEM: 'RESET_LB_REDEEM',
  ACTIVATE_INFO: 'ACTIVATE_INFO',
  DEACTIVATE_INFO: 'DEACTIVATE_INFO',
}