import React from 'react'
import { Dimensions, Platform } from 'react-native'
import { useSelector } from 'react-redux'

import ChangePassword from '@vfgroup-oneplatform/framework/ChangePassword'

import NavigationService from '../../Navigation/NavigationService'
import { changePassword } from '../../Services/Settings'

function ChangeMyPassword({ ...props }) {

    const passwordRule = useSelector(state => state.configurations.config?.regex?.passwordRule)
    const passwordStrengthError = useSelector(state => state.configurations.pages.find(el => el.page === 'forgotPassword').items.find(el => el.key === 'forgotpswd_password_strength_lessthan3_error').value)

    const onChangePasswordPress = async (currentPassword, newPassword) => {
        await changePassword(currentPassword, newPassword, {
            'Content-Type': 'application/merge-patch+json',
        })
    }

    const onSuccess = () => NavigationService.navigate('Dashboard')

    const validateNewPassword = (pass) => {
        const passwordRegex = new RegExp(passwordRule)
        if (!passwordRegex?.test(pass)) {
            return false
        } else {
            return true
        }
    }

    const onClosePress = () => NavigationService.pop()

    return (
        <ChangePassword
            onClosePress={onClosePress}
            validateNewPassword={validateNewPassword}
            onChangePasswordPress={onChangePasswordPress}
            onSuccess={onSuccess}
            invalidNewPasswordHelperText={passwordStrengthError}
            {...props}
        />
    )
}

export default ChangeMyPassword
