import React from 'react'
import { Dimensions, Platform, View, Pressable } from 'react-native'
import PropTypes from 'prop-types'
import { VFText, Icon } from '@vfgroup-oneplatform/foundation/Components'
import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { Images } from '../../../../Themes'

import styles from './PastOrdersSubCard.Styles'

const PastOrdersSubCard = ({ item }) => {
  const theme = useTheme()

  let image

  if (item.orderIcon === 'Internet' || item.orderIcon === 'Modem') {
    image = Images.modem
  } else if (item.orderIcon === 'CableTv' || item.orderIcon === 'SmartCard') {
    image = Images.tv
  } else {
    image = Images.tv_and_modem
  }

  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
    return (
      <View style={[styles.card(theme), { padding: 20, borderRadius: 6 }]}>
        <Pressable>
          {/* <View style={styles.itemNameContainer(theme)}> */}
          <VFText style={styles.itemNameText(theme)} i18nKey={item.title} />
          {/* </View> */}
          <View style={{ width: '100%', height: 1, backgroundColor: '#EBEBEB', marginVertical: 20 }} />

          <View style={styles.itemDetailsContainer}>
            <Icon
              size={80}
              type="image"
              style={styles.image}
              name={image}
            />
            <View style={styles.itemDetailsSubContainer}>
              <VFText
                style={styles.statusText(theme)}
                i18nKey="my_orders_status"
              />
              <VFText
                style={styles.orderStatusText(theme)}
                i18nKey="my_orders_completed_status"
              />
            </View>
          </View>
        </Pressable>
      </View>
    )
  }

  return (
    <View style={styles.card(theme)}>
      <View style={styles.itemNameContainer(theme)}>
        <VFText style={styles.itemNameText(theme)} i18nKey={item.title} />
      </View>

      <View style={styles.itemDetailsContainer}>
        <Icon
          size={80}
          type="image"
          style={styles.image}
          name={image}
        />
        <View style={styles.itemDetailsSubContainer}>
          <VFText
            style={styles.statusText(theme)}
            i18nKey="my_orders_status"
          />
          <VFText
            style={styles.orderStatusText(theme)}
            i18nKey="my_orders_completed_status"
          />
        </View>
      </View>
    </View>
  )
}

PastOrdersSubCard.defaultProps = {
  isInstallation: false,
}

PastOrdersSubCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    title: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    orderIcon: PropTypes.string,
  }),
}

export default PastOrdersSubCard
