import React from 'react'
import { Dimensions, Platform, ScrollView } from 'react-native'
import { VFScreen } from '@vfgroup-oneplatform/foundation/Components'
import { useSelector } from 'react-redux'

import Card from '../../Components/Card'
import WebView from '../../Components/WebView/WebView'

import NavigationService from '../../Navigation/NavigationService'

import { styles } from './PrivacyPolicy.Styles'

const PrivacyPolicy = ({ ...rest }) => {

    const onClose = () => NavigationService.pop()
    const privacyData = useSelector(state => state.configurations.pages.find(el => el.page === 'gdpr').items.find(el => el.key === 'gdpr_settings_privacy_privacy_notice_item_description'))


    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
        return (
            <VFScreen
                title="privacy_policy_title"
                onClose={onClose}
                closeButtonTestID={'appSettingsCloseBtn'}
                containerStyle={styles.wrapper}
                onBack={onClose}
                showBack={true}
                showClose={false}
                {...rest}
            >
                <ScrollView contentContainerStyle={[styles.container, { paddingHorizontal: '10%' }]} >
                    <Card>
                        <WebView content={privacyData?.value} />
                    </Card>
                </ScrollView>
            </VFScreen>
        )
    }

    return (
        <VFScreen
            title="privacy_policy_title"
            onClose={onClose}
            closeButtonTestID={'appSettingsCloseBtn'}
            containerStyle={styles.wrapper}
        >
            <ScrollView contentContainerStyle={styles.container} >
                <Card>
                    <WebView content={privacyData?.value} />
                </Card>
            </ScrollView>
        </VFScreen>
    )
}

export default PrivacyPolicy
