/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useLayoutEffect } from 'react'
import { Dimensions, Platform, StatusBar, View } from 'react-native'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { CallMeBack as CallMeBackScreen } from '@vfal-lab/rn-vfg-call-me-back/Screens'

import { Colors, useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import ReactNativeModal from 'react-native-modal'

import { Icon, VFButton, VFText } from '@vfgroup-oneplatform/foundation/Components'

import { Images } from '@vfal-lab/rn-vfg-catalogue/Themes'

import ReduxCache from '../../Utils/ReduxCache'
import { validatePhoneNumber, formatPhoneNumber } from '../../Utils/Regex'

import { getCallMeBackHistory, sendCallMeBack } from './CallMeBack.Actions'
import styles from './CallMeBack.Styles'

function CallMeBack({ navigation, route }) {
  const dispatch = useDispatch()
  const theme = useTheme()

  const msisdn = ReduxCache.getMsisdn()

  const [isContentLoading, setContentLoading] = useState(true)
  const [isError, setIsErroModal] = useState(false)

  const { validityObject } = useSelector(state => state.callMeBackHistory.data || {})
  const historyLoading = useSelector(state => state.callMeBackHistory.loading || false)
  const historyError = useSelector(state => state.callMeBackHistory.error || false)
  const callMeBackError = useSelector(state => state.callMeBack.error || '')
  const callMeBackSuccess = useSelector(state => state.callMeBack.data || {})
  const callMeBackLoading = useSelector(state => state.callMeBack.loading || false)

  const getHistory = async (utilities) => {
    setIsErroModal(true)
    return dispatch(getCallMeBackHistory(utilities))
  }

  const callMeBack = async (receiver, utilities) => {
    return dispatch(sendCallMeBack(formatPhoneNumber(receiver), utilities))
  }

  useLayoutEffect(() => {
    getHistory({
      onSuccess: () => setContentLoading(false),
      onError: () => {
        setIsErroModal(true)
      },
    })
  }, [])


  const resetStatusBar = () => {
    if (Platform.OS === 'android') {
      StatusBar.setBarStyle('light-content', true)
      StatusBar.setBackgroundColor(Colors.thinDark, true)
      StatusBar.setTranslucent(false)
    } else if (Platform.OS === 'ios') {
      StatusBar.setBarStyle('dark-content', true)
    }
  }

  const onClose = () => {
    resetStatusBar()
    if (route?.params?.isDeepLink) {
      navigation.navigate('Dashboard')
    } else {
      navigation.pop()
    }
  }

  const navigateToHistory = () => {
    navigation.navigate('CallMeBackHistory')
  }

  return (
    <>
      <CallMeBackScreen
        onClose={onClose}
        navigateToHistory={navigateToHistory}
        loading={isContentLoading || historyLoading || historyError}
        callMeBack={callMeBack}
        validityObject={validityObject}
        userPhoneNumber={msisdn}
        callMeBackError={callMeBackError}
        callMeBackSuccess={callMeBackSuccess}
        callMeBackLoading={callMeBackLoading}
        getHistory={getHistory}
        validatePhoneNumber={validatePhoneNumber}
        formatPhoneNumber={formatPhoneNumber}
      />
      <ReactNativeModal
        isVisible={isError}
        backdropOpacity={0.5}
        onSwipeComplete={() => setIsErroModal(false)}
        onBackdropPress={() => setIsErroModal(false)}
        propagateSwipe
        hideModalContentWhileAnimating
        hardwareAccelerated
        backdropTransitionOutTiming={0}
        style={{
          justifyContent: 'flex-end',
          margin: '0 !important',
          minWidth: 1920,
          maxWidth: 1920,
        }}
      >
        <View
          style={{
            height: 'auto',
            minWidth: '730px',
            maxWidth: '730px',
            backgroundColor: theme.colors.quickActionGradientColors.secondary,
            margin: 'auto',
            padding: '20px',
            borderRadius: '7px',
            alignItems: 'center'
          }}
          onStartShouldSetResponder={() => true} >
          <Icon name={Images.error} type="image" size={64} resizeMode="contain" style={{ marginBottom: 16 }} />
          <VFText color={theme.colors.textColor} style={{ marginBottom: 16, fontSize: 24 }} i18nKey="catalogue_label_something_went_wrong" size="h1" weight="bold" align="center" />
          <VFButton
            title={'catalogue_action_retry'}
            onPress={getHistory}
            style={{ width: '100%' }}
          />
        </View>
      </ReactNativeModal>
    </>
  )
}
CallMeBack.propTypes = {
  navigation: PropTypes.object,
}

export default CallMeBack
