import { StyleSheet, Dimensions } from 'react-native'
import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

const { width } = Dimensions.get('screen')

export default StyleSheet.create({
  buttonText: {
    fontWeight: Fonts.type.bold.fontWeight
  },
  switchContainer: {
    width: '50%',
  },
  username: {
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: 20,
    marginVertical: 16
  },
  modal: {
    margin: 0,
    height: 'auto',
    zIndex: 1000
  },
  title: {
    fontSize: Fonts.size.h4,
    ...Fonts.type.bold,
    textAlign: 'center',
    marginTop: 30,
    marginBottom: 20
  },
  contentContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center'
  },
  subtitle: {
    fontSize: Fonts.size.h6,
    fontFamily: Fonts.type.VFFont,
    textAlign: 'center',
    marginBottom: 20
  },
  buttonsContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
    width: width,
    paddingHorizontal: 16,
  },
  button: {
    marginTop: 15,
  },
  switchTitle: {
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Fonts.size.h4,
    marginBottom: 10,
  },
  loadingView: {
    width: '100%'
  },
  loadingTitle: theme => ({
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: 29.1,
    marginTop: 22,
    color: theme.colors.headerTitleColor,
  }),
  loadingSubtitle: theme => ({
    fontSize: 16.6,
    marginTop: 7,
    color: theme.colors.headerTitleColor,
  }),
  loadingContainer: theme => ({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.cardBackgroundColor,
    height: '100%',
    width: '100%'
  }),
  currentAccountTitleStyle: theme => ({
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: 21.9,
    color: theme.colors.headerTitleColor,
    marginBottom: 33,
  }),
  otherAccountsTitleStyle: theme => ({
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: 21.9,
    color: theme.colors.headerTitleColor,
    marginVertical: 33,
  }),
  accountItemContainerStyle: theme => ({
    borderTopWidth: 1,
    borderTopColor: theme.colors.divider,
  }),
  cancelButtonStyle: theme => ({
    marginTop: 32,
    borderColor: theme.colors.borderColorFive,
  }),
  headerTitle: {
    ...Fonts.type.bold,
    fontSize: Fonts.size.regular
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16
  },
  switchAccountContainer: theme => ({
    width: '100%',
    bottom: 0,
    position: 'absolute',
    left: 0,
    right: 0,
    backgroundColor: theme.colors.cardBackgroundColor,
    paddingHorizontal: 16,
    paddingTop: 16,
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
    paddingBottom: 50
  }),
  errorModal: theme => ({
    backgroundColor: theme.colors.cardBackgroundColor,
    flex: 1,
    paddingBottom: 40
  })
})
