import React from 'react'
import { View, Animated } from 'react-native'

import {
    Icon,
    VFText,
} from '@vfgroup-oneplatform/foundation/Components'
import PropTypes from 'prop-types'
import { withTheme } from '@vfgroup-oneplatform/foundation/Components/Themes/Provider'
import { LightThemeColors, Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

import LinearGradient from 'react-native-linear-gradient'

import { Images as themeImages } from '../../../Themes'

import styles from './GuestInfoCard.Styles'


function GuestInfoCard({
    background,
    image,
    title,
    subTitle,
    theme
}) {

    return (
        <LinearGradient
            colors={background === 'bg_red' ? [Colors.darkRedTwo, Colors.vfRed] : [Colors.white, Colors.white]}
            angleCenter={{ x: 0.5, y: 0.5 }}
            angle={30}
            useAngle={true}
            style={styles.rounded}
        >
            <Animated.View style={styles.containerStyle(theme)}>

                <Animated.View style={styles.titleContainerStyle}>
                    <VFText
                        i18nKey={title}
                        style={[styles.titleTextStyle(theme), background === 'bg_red' ? styles.whiteText : styles.blackText]}
                    />
                </Animated.View>

                <View style={styles.footerContainer}>
                    <>
                        <VFText
                            i18nKey={subTitle}
                            style={[styles.resetTextStyle(theme), background === 'bg_red' ? styles.whiteText : styles.blackText]}
                        />

                        <Icon
                            source={background === 'bg_red' ? themeImages.chevron_right_white : themeImages.chevron_right_grey}
                            size={16}
                            resizeMode="contain"
                        />
                    </>
                </View>

                <Icon
                    source={{ uri: image }}
                    type="image"
                    size={120}
                    resizeMode="contain"
                    style={styles.cartImageStyles}
                />
            </Animated.View>
        </LinearGradient>
    )
}

GuestInfoCard.defaultProps = {
    theme: LightThemeColors
}

GuestInfoCard.propTypes = {
    theme: PropTypes.shape({
        name: PropTypes.string,
        isDark: PropTypes.bool,
        colors: PropTypes.object
    }),
    background: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
}

export default withTheme(GuestInfoCard)