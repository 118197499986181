import { ActivateOffer } from '@vfal-lab/rn-vfg-catalogue'
// import { ActivateOffer } from '../../Services/MyOffers'

import { isArray, removeNullValues } from '../../Utils/JSON'
import { toLowerCase } from '../../Utils/Helpers'
import ReduxCache from '../../Utils/ReduxCache'


const actionTypes = {
  REQUEST_ACTIATE_OFFER: 'REQUEST_ACTIATE_OFFER',
  SUCCESS_ACTIATE_OFFER: 'SUCCESS_ACTIATE_OFFER',
  ERROR_ACTIATE_OFFER: 'ERROR_ACTIATE_OFFER',
  RESET_ACTIATE_OFFER: 'RESET_ACTIATE_OFFER',
}


const responseMapper = response => {
  const res = removeNullValues(response?.data || {})
  const {
    state,
    productOfferingQualification = [],
  } = res

  const productDeleteItems = []
  const productOrderItem = []

  if (isArray(productOfferingQualification)) {
    productOfferingQualification.forEach(({ id, name }) => {
      productDeleteItems.push({ id, name })
      productOrderItem.push({
        id,
        action: 'delete',
        product: {
          name,
        },
      })
    })
  }

  const result = {
    pending: toLowerCase(state) === 'pending',
    completed: toLowerCase(state) === 'completed',
    productDeleteItems,
    productOrderItem,
  }

  return result
}

const errorMapper = error => error?.response?.data?.message || error?.message

const customHeaders = {
  // 'x-source-system': 'selfcare',
  // 'x-source-operator': 'myoffers',
  // 'x-country-code': 'al',
}

const activateOffer = (channel, productOrderItem, utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_ACTIATE_OFFER,
    })

    const msisdn = ReduxCache.getMsisdn()

    ActivateOffer(
      channel,
      productOrderItem,
      msisdn,
      customHeaders,
      {
        ...utilities,
        responseMapper: responseMapper,
        errorMapper: errorMapper,
      }
    ).then(response => dispatch({
      type: actionTypes.SUCCESS_ACTIATE_OFFER,
      payload: response,
    })).catch(error => dispatch({
      type: actionTypes.ERROR_ACTIATE_OFFER,
      payload: error,
    }))
  }
}

const resetOfferActivation = () => {
  return async dispatch => {

    dispatch({
      type: actionTypes.RESET_ACTIATE_OFFER,
    })
  }
}


const actions = {
  activateOffer,
  resetOfferActivation,
}

export {
  actionTypes,
  actions,
}
