import {
  GetTransferBalanceOptions,
  GetTransferDataOptions,
  GetTransferVcoinsOptions,
  TransferBalance,
  TransferData,
  TransferVcoins,
} from '@vfal-lab/rn-vfg-data-sharing/Services'

import ReduxCache from '../../Utils/ReduxCache'

import { actionTypes } from './DataSharing.ActionTypes'

const errorMapper = (error) => {
  const payload = error?.response?.data?.message || error?.message
  return payload
}

const getBalanceResponseMapper = (res) => {
  const response = res?.data

  const options = response?.['related-service-account-adjustment']
  const details = response?.details?.['adjustment-amount']
  const balance = details?.$
  const currency = details?.['@currency-id']
  const characteristic = response?.parts?.['adjustment-specification']?.characteristics
  const limits = characteristic?.filter(item => item?.name !== 'transfer_validity') || []
  const { value: { $ = '' } = {} } = characteristic?.find(item => item?.name === 'transfer_validity') || {}

  const monthlyAndDailyLimits = limits.map(({ name, value }) => {
    const usedAndLimit = value?.$?.split('/')

    const limitAndType = usedAndLimit?.[1]?.split(' ')
    return ({
      description: name,
      used: parseFloat(usedAndLimit?.[0]) || 0,
      limit: parseFloat(limitAndType?.[0]) || 0,
      type: currency,
      reachedDescription: `share_reached_${name}`,
    })
  })

  const items = []
  options.map(el => {
    const title = `${el.name.$}${currency}`
    const subtitle = `${el.name.$} ${currency}`
    // ${el.desc.$}
    items.push({
      title,
      value: el.name.$,
      currency,
      subtitle,
    })
  })
  return { items, balance, currency, monthlyAndDailyLimits, description: $ }
}

const getDataResponseMapper = (res) => {
  const options = res?.data

  const limitsObject = options?.find((option) => Array.isArray(option?.parts?.['adjustment-specification']?.characteristics))
  const characteristic = limitsObject?.parts?.['adjustment-specification']?.characteristics
  const limits = characteristic?.filter(item => item?.name !== 'transfer_validity')
  const { value: { $ = '' } = {} } = characteristic?.find(item => item?.name === 'transfer_validity') || {}

  const monthlyAndDailyLimits = limits.map(({ name, value }) => {
    const usedAndLimit = value?.$?.split('/')
    const limitAndType = usedAndLimit?.[1]?.split(' ')
    return ({
      description: name,
      used: parseFloat(usedAndLimit?.[0]),
      limit: parseFloat(limitAndType?.[0]),
      type: limitAndType?.[1] || 'GB',
      reachedDescription: `share_reached_${name}`,
    })
  })

  const items = []
  options.map(el => {
    const title = el['related-service-account-adjustment'][0].name.$
    const extraData = el['related-service-account-adjustment'][1].name.$
    const currency = el?.parts?.['transfer-from']?.amount['@currency-id']
    const description = el?.parts?.['transfer-from']?.amount.$ + ' ' + currency
    const details = { ...el?.details?.['adjustment-quantity'], '@unit-code': currency }
    items.push({ title, extraData, description, details, subtitle: title })
  })
  return { items, monthlyAndDailyLimits, description: $ }
}

const getVcoinsResponseMapper = (result) => {
  const items = result.data
  const transferVcoinsItems = []

  const limitsObject = items?.find((option) => Array.isArray(option?.parts?.adjustmentSpecification?.characteristicsValue))
  const characteristicArray = limitsObject?.parts?.adjustmentSpecification?.characteristicsValue
  const limits = characteristicArray?.filter((limit) => limit?.type)
  const { value = '' } = characteristicArray?.find((limit) => limit?.characteristicName === 'transfer_validity')

  const prev = {}
  const mappedLimits = limits.map((limit) => {
    if (prev[limit.type]) {
      prev[limit.type] = {
        ...prev[limit.type],
        limit: parseFloat(limit?.value) || 0,
      }
    } else {
      prev[limit.type] = {
        description: limit.type,
        type: '',
        reachedDescription: `share_reached_${limit.type}`,
        used: parseFloat(limit?.value) || 0,
      }
    }
    return { ...prev }
  })

  const newLimits = Object.assign(...mappedLimits)

  items.map((item) => {
    const vcoinsAmount = item?.name.split(' ')
    const transferVCoinsObj = {
      id: item.id[0].value,
      title: vcoinsAmount?.[0],
      description: item.desc,
      type: item.type,
      category: item.category[0].value,
      status: item.status,
      icon: true,
      subtitle: vcoinsAmount?.[0] + ' ' + 'Vcoins',
    }

    const characteristics = item.parts.adjustmentSpecification.characteristicsValue
    for (const j in characteristics) {
      const characteristic = characteristics[j]
      transferVCoinsObj[characteristic.characteristicName] = characteristic.value
    }

    transferVcoinsItems.push(transferVCoinsObj)
  })

  return { items: transferVcoinsItems, monthlyAndDailyLimits: Object.values(newLimits), description: value }
}

const getBalanceTransferResponseMapper = (res) => {
  const response = res?.data
  const message = response?.desc?.$
  return { message }
}

const getDataTransferResponseMapper = (res) => {
  const response = res?.data
  const message = response?.desc?.$
  return { message }
}

const getVcoinsTransferResponseMapper = (res) => {
  const response = res?.data
  const message = response?.desc?.$
  return { message }
}

const customBalanceHeaders = {
  'x-application-version': '5.2.0',
  'x-application-os': 'Android',
  'x-source-operator': 'myvodafone',
  'x-source-system': 'selfcare',
  'content-type': 'application/json',
  'accept-language': 'al',
  'x-source-countrycode': 'al',
}

const customDataHeaders = {
  'x-application-version': '5.2.0',
  'x-application-os': 'Android',
  'x-source-operator': 'DataShare',
  'x-source-system': 'selfcare',
  'Content-Type': 'application/json',
  '&accept-language': 'al',
  'x-source-countrycode': 'al',
}

const customVcoinsHeaders = {
  'Content-Type': 'application/json',
  'vf-operator': 'reward',
  'vf-source': 'selfcare',
  'Accept-Language': 'al',
  'vf-country-code': 'al',
}

const customBalanceTransferHeaders = {
  'x-application-version': '5.2.0',
  'x-application-os': 'Android',
  'x-source-operator': 'SosCredit',
  'x-source-system': 'selfcare',
  'Content-Type': 'application/json',
  'accept-language': 'al',
  'x-source-countrycode': 'al',
}

const customDataTransferHeaders = {
  'x-application-version': '5.2.0',
  'x-application-os': 'Android',
  'x-source-operator': 'DataShare',
  'x-source-system': 'selfcare',
  'Content-Type': 'application/json',
  'accept-language': 'al',
  'x-source-countrycode': 'al',
}

const getTransferOptionsBalance = (utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_BALANCE_OPTIONS,
    })

    const msisdn = ReduxCache.getMsisdn()

    GetTransferBalanceOptions(msisdn, customBalanceHeaders, {
      ...utilities,
      responseMapper: getBalanceResponseMapper,
      errorMapper: errorMapper,
    })
      .then(response => dispatch({
        type: actionTypes.SUCCESS_BALANCE_OPTIONS,
        payload: response,
      }))
      .catch(error => dispatch({
        type: actionTypes.ERROR_BALANCE_OPTIONS,
        payload: error,
      }))
  }
}

const getTransferOptionsData = (utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_DATA_OPTIONS,
    })

    GetTransferDataOptions(customDataHeaders, {
      ...utilities,
      responseMapper: getDataResponseMapper,
      errorMapper: errorMapper,
    })
      .then(response => dispatch({
        type: actionTypes.SUCCESS_DATA_OPTIONS,
        payload: response,
      }))
      .catch(error => dispatch({
        type: actionTypes.ERROR_DATA_OPTIONS,
        payload: error,
      }))
  }
}

const getTransferOptionsVcoins = (utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_VCOINS_OPTIONS,
    })

    const msisdn = ReduxCache.getMsisdn()

    GetTransferVcoinsOptions(msisdn, customVcoinsHeaders, {
      ...utilities,
      responseMapper: getVcoinsResponseMapper,
      errorMapper: errorMapper,
    })
      .then(response => dispatch({
        type: actionTypes.SUCCESS_VCOINS_OPTIONS,
        payload: response,
      }))
      .catch(error => dispatch({
        type: actionTypes.ERROR_VCOINS_OPTIONS,
        payload: error,
      }))
  }
}

const transferBalance = (transferDetails, utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_BALANCE_TRANSFER,
    })

    const msisdn = ReduxCache.getMsisdn()

    TransferBalance(transferDetails, msisdn, customBalanceTransferHeaders, {
      ...utilities,
      responseMapper: getBalanceTransferResponseMapper,
      errorMapper: errorMapper,
    })
      .then(response => dispatch({
        type: actionTypes.SUCCESS_BALANCE_TRANSFER,
        payload: response,
      }))
      .catch(error => dispatch({
        type: actionTypes.ERROR_BALANCE_TRANSFER,
        payload: error,
      }))
  }
}

const transferData = (transferDetails, utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_DATA_TRANSFER,
    })

    const msisdn = ReduxCache.getMsisdn()

    TransferData(transferDetails, msisdn, customDataTransferHeaders, {
      ...utilities,
      responseMapper: getDataTransferResponseMapper,
      errorMapper: errorMapper,
    })
      .then(response => dispatch({
        type: actionTypes.SUCCESS_DATA_TRANSFER,
        payload: response,
      }))
      .catch(error => dispatch({
        type: actionTypes.ERROR_DATA_TRANSFER,
        payload: error,
      }))
  }
}

const transferVcoins = (transferDetails, utilities = {}) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.REQUEST_VCOINS_TRANSFER,
    })

    const msisdn = ReduxCache.getMsisdn()

    TransferVcoins(transferDetails, msisdn, customVcoinsHeaders, {
      ...utilities,
      responseMapper: getVcoinsTransferResponseMapper,
      errorMapper: errorMapper,
    })
      .then(response => dispatch({
        type: actionTypes.SUCCESS_VCOINS_TRANSFER,
        payload: response,
      }))
      .catch(error => dispatch({
        type: actionTypes.ERROR_VCOINS_TRANSFER,
        payload: error,
      }))
  }
}

export {
  actionTypes,
  getTransferOptionsBalance,
  getTransferOptionsData,
  getTransferOptionsVcoins,
  transferBalance,
  transferData,
  transferVcoins,
}
