import { Dimensions, Platform, StyleSheet } from 'react-native'
import { Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  container: (user) => ({
    flex: 1,
    position: 'relative',
    // padding: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? user !== 'guest' && 20 : null
    borderRadius: 7,
    overflow: 'hidden'
  }),
  usageInfoCard: {
    elevation: 0,
    shadowColor: Colors.transparent,
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0,
  },
  paginationStyle: {
    position: 'absolute',
    right: 15,
    top: 24,
    height: 10,
    alignItems: 'center',
    flexDirection: 'row',
  },
})
