const colors = {
  background: 'rgb(255,255,255)',
  cardBackground: '#FFFFFF',
  clear: 'rgba(0,0,0,0)',
  transparent: 'rgba(0,0,0,0)',
  silver: '#F7F7F7',
  darkRed: 'rgb(169,0,0)',
  steel: '#CCCCCC',
  error: 'rgba(200, 0, 0, 0.8)',
  ricePaper: 'rgba(255,255,255, 0.75)',
  vfRed: 'rgb(230, 0, 0)',
  vfRedRGBA: (alpha = 1) => `rgba(230, 0, 0, ${alpha})`,
  red: '#e60000',
  border: '#dddddd',
  white: 'rgb(255, 255, 255)',
  whiteRGBA: (alpha = 1) => `rgba(255, 255, 255, ${alpha})`,
  brownGrey: 'rgb(153, 153, 153)',
  ocean: 'rgb(0, 124, 146)',
  oceanRGBA: (alpha = 1) => `rgba(0, 124, 146, ${alpha})`,
  veryLightPink: 'rgb(204, 204, 204)',
  mustardGreen: 'rgb(168, 182, 0)',
  mustardGreenRGBA: (alpha = 1) => `rgba(168, 182, 0, ${alpha})`,
  babyBlueRGBA: (alpha = 1) => `rgba(0, 22, 230, ${alpha})`,
  babyBlue2: 'rgb(0, 22, 255)',
  babyBlue2RGBA: (alpha = 1) => `rgba(0, 22, 255, ${alpha})`,
  whiteSmoke: '#f4f4f4',
  shadow: 'rgba(0, 0, 0, 0.2)',
  veryLightGrey: '#d3d3d3',
  linegrey: 'rgba(0, 0, 0, 0.16)',
  activeRed: 'rgb(153, 0, 0)',
  activeGrey: 'rgb(244, 244, 244)',
  icGreyStep: 'rgba(0, 0, 0, 0.5)',
  black: '#000000',
  warm_grey_two: '#999999',
  whisper: '#eeeeee',
  white_two: '#ebebeb',
  lightGrayTwo: 'rgb(229, 229, 229)',
  success: 'rgb(0, 153, 0)',
  blackRGBA: (alpha = 1) => `rgba(0, 0, 0, ${alpha})`,
  fresh_orange: '#EB9700',
  maroon: '#990000',
  light_grey: '#666666',
  dark_grey: '#333333',
  frenchBlue: 'rgb(66,103,178)',
  lightFrenchBlue: '#1877F2',
  turquoise: 'rgb(0, 150, 174)',
  darkCyan: 'rgb(0, 124, 146)',
  aluminium: '#ebebeb',
  flower_blue: '#3d7fa8',
  flower_yellow: '#ffe58c',
  flower_ocean: '#8dd9e8',
  bunker: '#25282B',
  bondiBlue: '#0096AD',
  gray65: '#A6A6A6',
}

export default colors
