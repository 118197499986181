import { ActionTypes } from './OnBoarding.ActionTypes'

const initialState = {
  isStarted: false,
  isDone: false,
  checkedSteps: [],
  onBoardingSteps: []
}
function onBoarding(store = initialState, action = {}) {
  switch (action.type) {
    case ActionTypes.START_ONBOARDING:
      return startOnBoarding(store)
    case ActionTypes.FETCH_ONBOARDING:
      return fetchOnBoarding(store)
    case ActionTypes.FETCH_ONBOARDING_SUCCESS:
      return fetchOnBoardingSuccess(store, action)
    case ActionTypes.UPDATE_STEP:
      return updateStep(store, action)
    case ActionTypes.FINISH_ONBOARDING:
      return finishOnBoarding(store)
    case ActionTypes.CLEAR_ONBOARDING_CACHE:
      return initialState
    default:
      return store
  }
}

function startOnBoarding(store) {
  return {
    ...store,
    isStarted: true
  }
}

function fetchOnBoarding(store) {
  return {
    ...store
  }
}

function fetchOnBoardingSuccess(store, action) {
  return {
    ...store,
    onBoardingSteps: action.data
  }
}

function updateStep(store, action) {
  return {
    ...store,
    checkedSteps: [...store.checkedSteps, action.data]
  }
}

function finishOnBoarding(store) {
  return {
    ...store,
    isDone: true
  }
}

export default onBoarding
