import React, { Component } from 'react'
import { View, Text, FlatList, Image, Platform, Dimensions } from 'react-native'
import { connect } from 'react-redux'
import { ListItem, TabHeading, Tab, Tabs, ScrollableTab } from 'native-base'

import { VFScreen } from '@vfgroup-oneplatform/foundation/Components'

import Header from '../../Components/Header'


import Loader from '../../Components/Loader'
import Error from '../../Components/Error'

import { __translate, __image } from '../../Utils'
import { DynamicCoupon } from '../../Utils/DynamicCoupon'

import { actions as categoryActions } from '../Catalog/catalog.actions'
import { actions as profileActions } from '../Demo/demo.actions'

import { actions } from './profile.actions'
import styles from './profile.styles'
import { Images } from '../../Themes'
import AsyncStorage from '@react-native-async-storage/async-storage'


class Profile extends Component {
  static contextType = DynamicCoupon
  constructor(props) {
    super(props)
    this.state = {
      items: {
        vcoins: ['earned', 'used'],
        balance: ['credit', 'debit', 'active'],
      },
      itemsColor: ['red', 'black'],
      earned: {
        name: __translate('reward_loyalty_history_earned'),
        columns: [
          __translate('reward_loyalty_history_date'),
          __translate('reward_loyalty_history_activity'),
          __translate('reward_loyalty_currency_name'),
          __translate('reward_loyalty_history_expiration') || 'Skadenca',
        ],
        color: 'brown',
      },
      used: {
        name: __translate('reward_loyalty_history_redeemed'),
        columns: [
          __translate('reward_loyalty_history_date'),
          __translate('reward_loyalty_history_partner'),
          __translate('reward_loyalty_currency_name'),
        ],
        color: 'black',
      },
      credit: {
        name: __translate('reward_loyalty_history_coupons'),
        columns: [
          __translate('reward_loyalty_history_date'),
          __translate('reward_loyalty_history_activity'),
          __translate('reward_loyalty_history_value_filter'),
        ],
        color: 'brown',
      },
      debit: {
        name: __translate('reward_loyalty_history_used'),
        columns: [
          __translate('reward_loyalty_history_date'),
          __translate('reward_loyalty_history_partner'),
          __translate('reward_loyalty_history_value_filter'),
        ],
        color: 'black',
      },
      active: {
        name: __translate('reward_loyalty_history_unused'),
        columns: [
          __translate('reward_loyalty_history_date'),
          __translate('reward_loyalty_history_partner'),
          __translate('reward_loyalty_history_value_filter'),
        ],
        color: '#007C92',
      },
      page: {
        earned: 0,
        user: 0,
        used: 0,
        credit: 0,
        debit: 0,
        active: 0,
      },
      orderColumn: 'profit',
      orderType: 'DESC',
      order: {
        column: 'profit',
        type: 'DESC',
      },
      activeTab: this.props.route.params.param === 'vcoins' ? 'earned' : 'credit',
    }
  }

  componentDidMount() {
    const order = this.getOrder()
    this.props.getHistory(this.state.items[this.props.route.params.param][0], 0, order)
    this.props.getHistory(this.state.items[this.props.route.params.param][1], 0, order)
    if (this.props.route.params.param === 'balance') {
      this.props.getHistory(this.state.items[this.props.route.params.param][2], 0)
    }
  }

  _retry() {
    const order = this.getOrder()
    this.props.getHistory(this.state.items[this.props.route.params.param][0], 0, order)
  }

  getOrder() {
    return this.state.order
  }

  onRefresh() {
    const order = this.getOrder()
    this.props.getHistory(this.state.active, 0, order)
  }

  changeOrderBy(column) {
    const order = this.getOrder()
    let orderType = 'DESC'

    if (order.column == column) {
      if (order.type == 'DESC') {
        orderType = 'ASC'
      } else {
        orderType = 'DESC'
      }
    }

    this.setState({ order: { type: orderType, column: column } }, () => {
      const order = this.getOrder()
      this.props.getHistory(this.state.items[this.props.route.params.param][0], 0, order)
    })
  }

  handleTabChange(item) {
    const type = item.ref.props.type
    const { history } = this.props
    const order = this.getOrder()
    if (!history.data[type].data) {
      this.props.getHistory(type, 0, order)
    }

    this.setState({ active: type })
  }

  _handleLoadMore(type) {
    const items = this.props.history.data[type].data
    const order = this.getOrder()

    if (!items || items.length < 20) {
      return
    }

    const page = Math.floor(items.length / 20)
    const prevPage = this.state.page[type]

    if (page <= prevPage) {
      return
    }

    this.setState({ page: { [type]: page } })
    this.props.getHistory(type, page, order)
  }

  onClose = () => {
    this.props.navigation.pop(3)
  }

  onBack = () => {
    this.props.navigation.pop()
  }



  render() {
    const theme = localStorage.getItem('@display_option')

    const getList = type => {
      const { history } = this.props


      if (
        this.props.history.data?.[type]?.loading &&
        !this.props.history.data?.[type]?.data
      ) {
        return <Loader size={45} />
      }

      if (history.data?.[type]?.data) {
        const listStyle =
          this.state[type].columns.length == 3 ? styles.item3 : styles.item4
        return (
          <FlatList
            data={this.props.history.data[type].data || []}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item }) => {
              return (
                <ListItem
                  noIndent
                  style={styles.listItem}
                  button={type === 'active'}
                  onPress={() => {
                    if (type === 'active') {
                      this.context.openCoupon(
                        () => {
                          //getActiveCoupon
                          this.context.setActiveScreen('loading')
                          this.context.setIsGet(true)
                          this.props.getActiveCoupon(
                            item?.id,
                            () => this.context.setActiveScreen('qrCode'),
                            () => {
                              this.context.setActiveScreen('input')
                            },
                          )
                        },
                        () => {
                          //onGenerateCoupon
                          this.context.setActiveScreen('loading')
                          this.context.setIsGet(false)
                          this.props.generateCoupon(
                            { ...item, value: this.context.couponValue },
                            () => {
                              //onSuccess
                              this.context.setActiveScreen('qrCode')
                              this.onRefresh()
                              this.props.getUserProfile()
                              this.props.getCategoryItems(item?.categoryId, null, true)
                            },
                            () => {
                              //onError
                              this.context.setModal(prev => ({
                                ...prev,
                                error: {
                                  error: this.props.dynamicCoupons.error,
                                  type: 'generate',
                                },
                              }))
                              this.context.setActiveScreen('error')
                            },
                          )
                        },
                        () => {
                          //onCancelCoupon
                          this.context.setActiveScreen('loading')
                          this.props.cancelCoupon(
                            item?.id,
                            this.props.activeCoupon.data,
                            () => {
                              //onSuccess
                              this.context.closeModal()
                              this.onRefresh()
                              this.props.getUserProfile()
                              this.props.getCategoryItems(item?.categoryId, null, true)
                            },
                            () => {
                              //onError
                              this.context.setModal(prev => ({
                                ...prev,
                                error: {
                                  error: this.props.cancelledCoupon.error,
                                  type: 'cancel',
                                },
                              }))
                              this.context.setActiveScreen('error')
                            }
                          )
                        }
                      )
                    }
                  }}
                >
                  <Text style={listStyle}> {item.date} </Text>
                  <Text style={listStyle}> {item.desc} </Text>
                  {(this.props.user.data.coins || item.currency) && (
                    <Text style={listStyle}> {`${item.price} ${this.props.route.params.param === 'vcoins' ? '' : item.currency}`} </Text>
                  )}
                  <Text style={listStyle}> {item.validity} </Text>
                </ListItem>
              )
            }}
            initialNumToRender={15}
            onRefresh={() => this.onRefresh()}
            refreshing={this.props.history.data[type].loading}
            onEndReached={() => this._handleLoadMore(type)}
            onEndReachedThreshold={0.5}
            ListFooterComponent={
              this.props.history.data[type].loading ? (
                <Loader size={45} />
              ) : null
            }
          />
        )
      }

      return null
    }

    const getTabs = () => {
      return this.state.items[this.props.route.params.param]?.map((item, index) => {
        const headerStyle =
          this.state[item].columns.length == 3
            ? styles.header3
            : styles.header4

        if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
          return (
            <Tab
              activeTabStyle={styles.activeTabStyles}
              activeTextStyle={styles.activeTextStyle}
              textStyle={styles.activeTextStyle}
              tabStyle={styles.tabStyles}
              style={{ flex: 1 }}
              key={item}
              type={item}
              heading={
                <TabHeading
                  style={[
                    styles.tabHeading,
                    {
                      borderBottomColor:
                        this.state.activeTab !== item ? this.state[item].color : 'red',
                    }
                  ]}>
                  <Text style={styles.tabText}>{this.state[item].name}</Text>
                  {this.state.activeTab === item && <View style={styles.arrow} />}
                </TabHeading>
              }>
              <View style={{
                flexDirection: 'column',
                flex: 1,
              }}>
                <View style={{
                  backgroundColor: '#fff',
                  overflow: 'hidden',
                  paddingHorizontal: 0,
                  flex: 1,
                }}>
                  <View style={styles.contentView}>
                    {item == 'earned' ? (
                      <>
                        <Text
                          onPress={() => this.changeOrderBy('profit')}
                          style={headerStyle}>
                          {this.state[item].columns[0]}
                        </Text>
                        <Image
                          style={[
                            {
                              width: 25,
                              height: 15,
                              marginLeft: -30,
                              marginTop: 8,
                            },
                            this.state.order.column == 'profit' &&
                              this.state.order.type == 'DESC'
                              ? { transform: [{ rotate: '180deg' }] }
                              : {},
                          ]}
                          source={{
                            uri: __image('reward_loyalty_hybrid_schema_header'),
                          }}
                        />
                      </>
                    ) : (
                      <Text style={headerStyle}>
                        {this.state[item].columns[0]}
                      </Text>
                    )}
                    <Text style={headerStyle}>{this.state[item].columns[1]}</Text>
                    {(this.props.user.data.coins || this.state[item].columns[2]) && (
                      <Text style={headerStyle}>
                        {this.state[item].columns[2]}
                      </Text>
                    )}
                    {this.state[item].columns[3] && (
                      <>
                        <Text
                          onPress={() => this.changeOrderBy('expiry')}
                          style={[headerStyle, { paddingRight: 20 }]}>
                          {this.state[item].columns[3]}
                        </Text>
                        <Image
                          style={[
                            {
                              width: 25,
                              height: 15,
                              marginLeft: -20,
                              marginTop: 8,
                            },
                            this.state.order.column == 'expiry' &&
                              this.state.order.type == 'DESC'
                              ? { transform: [{ rotate: '180deg' }] }
                              : {},
                          ]}
                          source={{
                            uri: __image('reward_loyalty_hybrid_schema_header'),
                          }}
                        />
                      </>
                    )}
                  </View>
                  <View style={{ flex: 1 }}>{getList(item)}</View>
                </View>
              </View>
            </Tab>
          )
        }
        return (
          <Tab
            activeTabStyle={styles.activeTabStyles}
            activeTextStyle={styles.activeTextStyle}
            textStyle={styles.activeTextStyle}
            tabStyle={styles.tabStyles}
            style={styles.tab}
            key={item}
            type={item}
            heading={
              <TabHeading
                style={[
                  styles.tabHeading,
                  {
                    borderBottomColor:
                      this.state.active !== item ? '#333' : 'red'
                  }
                ]}>
                <Text style={styles.tabText}>{this.state[item].name}</Text>
                {this.state.active == item && <View style={styles.arrow} />}
              </TabHeading>
            }>
            <View style={styles.content}>
              <View style={styles.tabContent}>
                <View style={styles.contentView}>
                  {item == 'earned' ? (
                    <>
                      <Text
                        onPress={() => this.changeOrderBy('profit')}
                        style={headerStyle}>
                        {this.state[item].columns[0]}
                      </Text>
                      <Image
                        style={[
                          {
                            width: 25,
                            height: 15,
                            marginLeft: -30,
                            marginTop: 8,
                          },
                          this.state.order.column == 'profit' &&
                            this.state.order.type == 'DESC'
                            ? { transform: [{ rotate: '180deg' }] }
                            : {},
                        ]}
                        source={{
                          uri: __image('reward_loyalty_hybrid_schema_header'),
                        }}
                      />
                    </>
                  ) : (
                    <Text style={headerStyle}>
                      {this.state[item].columns[0]}
                    </Text>
                  )}
                  <Text style={headerStyle}>{this.state[item].columns[1]}</Text>
                  {this.props.user.data.coins && (
                    <Text style={headerStyle}>
                      {this.state[item].columns[2]}
                    </Text>
                  )}
                  {this.state[item].columns[3] && (
                    <>
                      <Text
                        onPress={() => this.changeOrderBy('expiry')}
                        style={[headerStyle, { paddingRight: 20 }]}>
                        {this.state[item].columns[3]}
                      </Text>
                      <Image
                        style={[
                          {
                            width: 25,
                            height: 15,
                            marginLeft: -20,
                            marginTop: 8,
                          },
                          this.state.order.column == 'expiry' &&
                            this.state.order.type == 'DESC'
                            ? { transform: [{ rotate: '180deg' }] }
                            : {},
                        ]}
                        source={{
                          uri: __image('reward_loyalty_hybrid_schema_header'),
                        }}
                      />
                    </>
                  )}
                </View>
                <View style={{ flex: 1 }}>{getList(item)}</View>
              </View>
            </View>
          </Tab>
        )
      })
    }

    if (this.props.history.error) {
      return (
        <Error message={this.props.history.error} retry={() => this._retry()} />
      )
    }

    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
      return (
        <VFScreen
          headerStyle={{ backgroundColor: theme === 'dark' ? '#25282B' : '#FAFAFA', }}
          containerStyle={{ backgroundColor: theme === 'dark' ? '#25282B' : '#FAFAFA', }}
          contentContainerStyle={{ height: '94%' }}
          title={__translate('reward_landing_title')}
          onClose={this.onClose}
          onBack={this.onBack}
          showBack
          showClose={false}
          titleStyle={{ color: '#333' }}
          rightIcon={Images.close}
        >
          <View style={[styles.container, { backgroundColor: null, paddingHorizontal: '10%' }]}>
            <Header
              showDate={this.props.route.params.param === 'vcoins'}
              activeRoute={this.props.route.params.param}
              user={this.props.user}
              navigation={this.props.navigation}
              previous={'Catalog'}
            />
            <Tabs
              onChangeTab={item => this.handleTabChange(item)}
              tabBarUnderlineStyle={styles.tabsUnderline}
              style={[styles.tabs, { backgroundColor: null }]}
              tabContainerStyle={{ backgroundColor: '#fff' }}
              locked={Platform.OS === 'web' ? true : false}
              contentProps={{
                style: {
                  "overflow-x": 'hidden'
                }
              }}
            >
              {getTabs()}
            </Tabs>
          </View>
        </VFScreen>
      )
    }

    return (
      <VFScreen
        headerStyle={{ backgroundColor: theme === 'dark' ? '#25282B' : '#FAFAFA', }}
        containerStyle={{ backgroundColor: theme === 'dark' ? '#25282B' : '#FAFAFA', }}
        contentContainerStyle={{ height: '94%' }}
        title={__translate('reward_landing_title')}
        onClose={this.onClose}
        onBack={this.onBack}
        showBack
      >
        <View style={styles.container}>
          <Header
            showDate={true}
            user={this.props.user}
            navigation={this.props.navigation}
            previous={'Catalog'}
          />
          <Tabs
            onChangeTab={item => this.handleTabChange(item)}
            tabBarUnderlineStyle={styles.tabsUnderline}
            style={styles.tabs}
            tabContainerStyle={{ backgroundColor: '#fff' }}
            locked={Platform.OS === 'web' ? true : false}
            contentProps={{
              style: {
                "overflow-x": 'hidden'
              }
            }}
          >
            {getTabs()}
          </Tabs>
        </View>
      </VFScreen>
    )
  }
}

const mapStateToProps = state => {
  const {
    history,
    user,
    splash,
    dynamicCoupons,
    activeCoupon,
    cancelledCoupon,
  } = state
  return {
    history,
    user,
    splash,
    dynamicCoupons,
    activeCoupon,
    cancelledCoupon,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getHistory: (type, page, order) => {
      dispatch(actions.getHistory(type, page, order))
    },
    cancelCoupon: (couponId, data, onSuccess, onError) => {
      dispatch(categoryActions.cancelCoupon(couponId, data, onSuccess, onError))
    },
    generateCoupon: (product, onSuccess, onError) => {
      dispatch(categoryActions.generateCoupon(product, onSuccess, onError))
    },
    getActiveCoupon: (id, onSuccess, onError) => {
      dispatch(categoryActions.getActiveCoupon(id, onSuccess, onError))
    },
    getUserProfile: () => {
      dispatch(profileActions.getUserProfile())
    },
    getCategoryItems: (categoryId, page, isFirstPage = false) => {
      dispatch(categoryActions.getCategoryItems(categoryId, page, isFirstPage))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile)
