import { StyleSheet } from 'react-native'

import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
    stepTitleText: {
        fontFamily: Fonts.type.VodafoneLt,
        fontSize: 29.1,
        lineHeight: 37.4,
        textAlign: 'center',
    },
    stepDescriptionText: {
        textAlign: 'center',
        fontSize: 18.7,
        marginTop: 8.6,
        lineHeight: 25,
    },
})
