import { StyleSheet } from 'react-native'
import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'


const styles = StyleSheet.create({
    container: {
        paddingTop: 25,
    },
    card: (theme) => ({
        backgroundColor: theme.colors.cardBackgroundColor,
        marginVertical: 4,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    }),
    wrapper: {
        paddingBottom: 24
    },
    title: {
        fontSize: Fonts.size.h5,
        fontFamily: Fonts.type.VFFont,
    },
})

export { styles }
