import { Image } from 'react-native'

import { Images as foundationImages } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { __image } from '../Utils'


// leave off @2x/@3x ic-clock-red
const images = {
  //More Images
  survey_background: Image.resolveAssetSource(
    require('../Assets/Images/backgrounds/survey-background.jpg'),
  ),
  wallet_white: Image.resolveAssetSource(
    require('../Assets/Images/icons/wallet-white.png'),
  ),
  info_white: Image.resolveAssetSource(
    require('../Assets/Images/icons/info-white.png'),
  ),
  wallet: Image.resolveAssetSource(require('../Assets/Images/icons/wallet.png')),
  info_full_bg: Image.resolveAssetSource(
    require('../Assets/Images/backgrounds/info.jpg'),
  ),
  arrow_left_red: Image.resolveAssetSource(
    require('../Assets/Images/icons/arrow-left-red.png'),
  ),
  go: Image.resolveAssetSource(require('../Assets/Images/icons/go.png')),
  go_white: Image.resolveAssetSource(require('../Assets/Images/icons/go-white.png')),
  close_red: Image.resolveAssetSource(
    require('../Assets/Images/icons/close-red.png'),
  ),
  box_opened: { uri: __image('reward_loyalty_coupon') },
  arrow_down: Image.resolveAssetSource(
    require('../Assets/Images/icons/arrow-down.png'),
  ),
  search: Image.resolveAssetSource(require('../Assets/Images/icons/search.png')),
  close_white: Image.resolveAssetSource(
    require('../Assets/Images/icons/close-white.png'),
  ),
  clock_white: Image.resolveAssetSource(
    require('../Assets/Images/icons/clock_white.png')
  ),
  data_white: Image.resolveAssetSource(
    require('../Assets/Images/icons/data_white.png')
  ),
  clock_black: Image.resolveAssetSource(
    require('../Assets/Images/icons/clock.png')
  ),
  data_black: Image.resolveAssetSource(
    require('../Assets/Images/icons/data.png')
  ),
  info_circle: Image.resolveAssetSource(
    require('../Assets/Images/icons/info_circle.png')
  ),
  celebrate: Image.resolveAssetSource(
    require('../Assets/Images/icons/celebrate.png')
  ),
  circle_animation: Image.resolveAssetSource(
    require('../Assets/Images/Animation.gif'),
  ),
  circle: Image.resolveAssetSource(
    require('../Assets/Images/backgrounds/circle.png'),
  ),
  //Refill images
  info: Image.resolveAssetSource(require('../Assets/Images/icons/info.png')),
  icon: Image.resolveAssetSource(require('../Assets/Images/icons/icon.png')),
  attention: Image.resolveAssetSource(
    require('../Assets/Images/icons/attention.png'),
  ),
  success: Image.resolveAssetSource(require('../Assets/Images/icons/success.png')),
  //Coins Transfer
  chevron_left_grey: Image.resolveAssetSource(
    require('../Assets/Images/vf_icons/system_icons/chevron_left_grey.png'),
  ),
  chevron_right_white: Image.resolveAssetSource(
    require('../Assets/Images/vf_icons/system_icons/chevron_right_white.png'),
  ),
  chevron_right_grey: Image.resolveAssetSource(
    require('../Assets/Images/vf_icons/system_icons/chevron_right_grey.png'),
  ),
  chevron_left_red: Image.resolveAssetSource(
    require('../Assets/Images/vf_icons/system_icons/chevron_left_red.png'),
  ),
  chevron_down_red: Image.resolveAssetSource(
    require('../Assets/Images/vf_icons/system_icons/chevron_down_red.png'),
  ),
  contacts: Image.resolveAssetSource(
    require('../Assets/Images/vf_icons/system_icons/contacts.png'),
  ),
  vcoins: Image.resolveAssetSource(
    require('../Assets/Images/vf_icons/system_icons/vcoins.png'),
  ),
  share_vcoins: Image.resolveAssetSource(
    require('../Assets/Images/vf_icons/system_icons/share_vcoins.png'),
  ),
  ok_sign: Image.resolveAssetSource(
    require('../Assets/Images/vf_icons/system_icons/ok_sign.png'),
  ),
  warning: Image.resolveAssetSource(
    require('../Assets/Images/vf_icons/HIFI/warning.png'),
  ),
  tick_success: Image.resolveAssetSource(
    require('../Assets/Images/vf_icons/HIFI/tick.png'),
  ),

  //love-box images
  lb_main_hearts_bg: Image.resolveAssetSource(
    require('../Assets/Images/love-box/mainHeartsBg.png'),
  ),
  lb_bottom_hearts_bg: Image.resolveAssetSource(
    require('../Assets/Images/love-box/bottomBg.png'),
  ),
  lb_hearts: Image.resolveAssetSource(
    require('../Assets/Images/love-box/hearts.png'),
  ),
  lb_hearts_collection_bg: Image.resolveAssetSource(
    require('../Assets/Images/love-box/mainHeartsCollectionBg.png'),
  ),
  lb_success_error: Image.resolveAssetSource(
    require('../Assets/Images/love-box/success_error.png'),
  ),
  lb_success_hearts: Image.resolveAssetSource(
    require('../Assets/Images/love-box/successHearts.png'),
  ),
  lb_error_hearts: Image.resolveAssetSource(
    require('../Assets/Images/love-box/errorHearts.png'),
  ),
  lb_inactive_heart_amount: Image.resolveAssetSource(
    require('../Assets/Images/love-box/inactiveHeartAmount.png'),
  ),
  lb_inactive_heart: Image.resolveAssetSource(
    require('../Assets/Images/love-box/inactiveHeart.png'),
  ),
  lb_active_heart_amount: Image.resolveAssetSource(
    require('../Assets/Images/love-box/activeHeartAmount.png'),
  ),
  lb_active_heart: Image.resolveAssetSource(
    require('../Assets/Images/love-box/activeHeart.png'),
  ),
  lb_inactive_dash: Image.resolveAssetSource(
    require('../Assets/Images/love-box/inactiveDash.png'),
  ),
  lb_active_dash: Image.resolveAssetSource(
    require('../Assets/Images/love-box/activeDash.png'),
  ),
  lb_close: Image.resolveAssetSource(require('../Assets/Images/love-box/close.png')),

  //flower-box images
  fl_main_hearts_bg: Image.resolveAssetSource(
    require('../Assets/Images/flower-box/mainFlowersBG.png'),
  ),
  fl_bottom_hearts_bg: Image.resolveAssetSource(
    require('../Assets/Images/flower-box/flowersBottomBG.png'),
  ),
  fl_hearts: Image.resolveAssetSource(
    require('../Assets/Images/flower-box/flowers.png'),
  ),
  fl_hearts_collection_bg: Image.resolveAssetSource(
    require('../Assets/Images/flower-box/mainFlowersCollectionBG.png'),
  ),
  fl_redeem_flowers_bg: Image.resolveAssetSource(
    require('../Assets/Images/flower-box/mainRedeemFlowers.png'),
  ),
  fl_activate_flower: Image.resolveAssetSource(
    require('../Assets/Images/flower-box/activateFlower.png'),
  ),
  fl_error: Image.resolveAssetSource(
    require('../Assets/Images/flower-box/errorFlowersBG.png'),
  ),
  fl_success_hearts: Image.resolveAssetSource(
    require('../Assets/Images/flower-box/successFlowers.png'),
  ),
  fl_error_hearts: Image.resolveAssetSource(
    require('../Assets/Images/flower-box/errorFlowers.png'),
  ),
  fl_inactive_heart_amount: Image.resolveAssetSource(
    require('../Assets/Images/flower-box/inactiveFlowerAmount.png'),
  ),
  fl_inactive_heart: Image.resolveAssetSource(
    require('../Assets/Images/flower-box/inactiveFlower.png'),
  ),
  fl_active_heart_amount: Image.resolveAssetSource(
    require('../Assets/Images/flower-box/activeFlowerAmount.png'),
  ),
  fl_active_heart: Image.resolveAssetSource(
    require('../Assets/Images/flower-box/activeFlower.png'),
  ),


  visa_icon: Image.resolveAssetSource(
    require('../Assets/Images/icons/visa.png'),
  ),
  mastercard_icon: Image.resolveAssetSource(
    require('../Assets/Images/icons/mastercard.png'),
  ),
  americanExpress_icon: Image.resolveAssetSource(
    require('../Assets/Images/icons/americanExpress.jpg'),
  ),


  ic_outgoing_call: Image.resolveAssetSource(
    require('./../Assets/Images/Addons/icOutgoingCall.png')
  ),
  ic_data_sharing: Image.resolveAssetSource(
    require('./../Assets/Images/Addons/dataSharing.png')
  ),
  ic_sms_text: Image.resolveAssetSource(
    require('./../Assets/Images/Addons/smsText.png')
  ),
  ic_loan: Image.resolveAssetSource(
    require('./../Assets/Images/Addons/loan.png')
  ),
  ic_loan_payback: Image.resolveAssetSource(
    require('./../Assets/Images/Addons/loan-payback.png')
  ),
  addOnHeader: Image.resolveAssetSource(
    require('./../Assets/Images/Addons/addon_image.png')
  ),
  cart: Image.resolveAssetSource(
    require('./../Assets/Images/icons/cart.png')
  ),
  order: Image.resolveAssetSource(require('./../Assets/Images/icons/order.png')),
  recommend_header: Image.resolveAssetSource(
    require('./../Assets/Images/Recommend/Affiliate_banner.png')
  ),
  recommend_info: Image.resolveAssetSource(
    require('./../Assets/Images/Recommend/recommend_info.png')
  ),
  referal_success: Image.resolveAssetSource(
    require('./../Assets/Images/Recommend/referal_success.png')
  ),
  tick_solution: Image.resolveAssetSource(
    require('./../Assets/Images/icons/tick_solution.png')
  ),
  faceId: Image.resolveAssetSource(require('./../Assets/Images/icons/faceId.png')),
  fingerprint: Image.resolveAssetSource(require('./../Assets/Images/icons/fingerprint.png')),
  barcode: Image.resolveAssetSource(require('./../Assets/Images/icons/Barcode.png')),

  ic_tv: Image.resolveAssetSource(
    require('./../Assets/Images/icons/ic_tv.png')
  ),
  ic_tv_white: Image.resolveAssetSource(
    require('./../Assets/Images/icons/ic_tv_white.png')
  ),
  ic_wifi: Image.resolveAssetSource(
    require('./../Assets/Images/icons/ic_wifi.png')
  ),
  speed: Image.resolveAssetSource(
    require('./../Assets/Images/icons/speed.png')
  ),
  unlimited: Image.resolveAssetSource(
    require('./../Assets/Images/icons/unlimited.png')
  ),

  modem: Image.resolveAssetSource(
    require('./../Assets/Images/icons/modem.png')
  ),
  tv: Image.resolveAssetSource(
    require('./../Assets/Images/icons/tv.png')
  ),
  tv_and_modem: Image.resolveAssetSource(
    require('./../Assets/Images/icons/tv-and-modem.png')
  ),
  ic_tv_and_internet: Image.resolveAssetSource(
    require('./../Assets/Images/icons/ic_tv_internet.png')
  ),
  ic_tv_and_internet_white: Image.resolveAssetSource(
    require('./../Assets/Images/icons/ic_tv_internet_white.png')
  ),
  back: Image.resolveAssetSource(
    require('./../Assets/Images/icons/back.png'),
  ),
  // Roaming Settings
  locationRoaming: Image.resolveAssetSource(require('./../Assets/Images/icons/locationRoaming.png')),
  ic_roaming: Image.resolveAssetSource(require('./../Assets/Images/icons/roaming.png')),
  ic_roamingCall: Image.resolveAssetSource(require('./../Assets/Images/icons/call-log.png')),
  ic_roamingSMS: Image.resolveAssetSource(require('./../Assets/Images/icons/sms_text.png')),
  ic_roamingData: Image.resolveAssetSource(require('./../Assets/Images/icons/data_sharing.png')),
  ic_roamingInternational: Image.resolveAssetSource(require('./../Assets/Images/icons/international.png')),
  // ic_roamingWarning: Image.resolveAssetSource(require('./../Assets/Images/icons/ic_warning.png')),
  flash_filled: Image.resolveAssetSource(require('./../Assets/Images/icons/flash_filled.png')),
  flash: Image.resolveAssetSource(require('./../Assets/Images/icons/flash.png')),

  sim_card: Image.resolveAssetSource(require('./../Assets/Images/icons/sim_card.png')),
  vf_logo_gray: Image.resolveAssetSource(require('./../Assets/Images/icons/vf_logo_gray.png')),
  tobi: Image.resolveAssetSource(
    require('./../Assets/Images/backgrounds/Tobi.png')
  ),
  // PreLogin
  preLoginBackground: Image.resolveAssetSource(
    require('./../Assets/Images/PreLogin/GetStarted.png')
  ),
  topUp: Image.resolveAssetSource(
    require('./../Assets/Images/vf_icons/system_icons/top_up_grey.png')
  ),
  like_button: Image.resolveAssetSource(
    require('./../Assets/Images/guest/heart.png')
  ),
  like_button_coloured: Image.resolveAssetSource(
    require('./../Assets/Images/guest/heart_coloured.png')
  ),
  share_button: Image.resolveAssetSource(
    require('./../Assets/Images/guest/forward.png')
  ),
  clock_orange: Image.resolveAssetSource(
    require('./../Assets/Images/guest/clock.png')
  ),
  mobile: Image.resolveAssetSource(
    require('./../Assets/Images/guest/mobile.png')
  ),
  vodafoneUnlimited: Image.resolveAssetSource(
    require('./../Assets/Images/guest/vodafoneUnlimited.png')
  ),
  gigaFibra: Image.resolveAssetSource(
    require('./../Assets/Images/guest/gigaFibra.png')
  ),
  gigaTV: Image.resolveAssetSource(
    require('./../Assets/Images/guest/gigaTV.png')
  ),
  unlimitedHome: Image.resolveAssetSource(
    require('./../Assets/Images/guest/unlimitedHome.png')
  ),
  iot: Image.resolveAssetSource(
    require('./../Assets/Images/guest/iot.png')
  ),
  touristPack: Image.resolveAssetSource(
    require('./../Assets/Images/guest/touristPack.png')
  ),
  cloudSecurity: Image.resolveAssetSource(
    require('./../Assets/Images/guest/cloudSecurity.png')
  ),
  eShop: Image.resolveAssetSource(
    require('./../Assets/Images/guest/eShop.png')
  ),
  heartRed: Image.resolveAssetSource(
    require('./../Assets/Images/guest/heart_red.png')
  ),
  blackClock: Image.resolveAssetSource(
    require('./../Assets/Images/guest/clock_black.png')
  ),
  icAlbanianFlag: Image.resolveAssetSource(
    require(
      './../Assets/Images/guest/icAlbanianFlag.png'
    )
  ),
  icBritishFlag: Image.resolveAssetSource(
    require(
      './../Assets/Images/guest/icBritishFlag.png'
    )
  ),
  icRefreshWhite: Image.resolveAssetSource(
    require(
      './../Assets/Images/guest/icRefreshWhite.png'
    )
  ),
  // Big Brother
  bigBrotherBackground: Image.resolveAssetSource(
    require(
      './../Assets/Images/big-brother/bigBrotherBackground.png'
    )
  ),
  competitorCircleBlue: Image.resolveAssetSource(
    require(
      './../Assets/Images/big-brother/competitorCircleBlue.png'
    )
  ),
  competitorCircleRed: Image.resolveAssetSource(
    require(
      './../Assets/Images/big-brother/competitorCircleRed.png'
    )
  ),
  competitorCircleGreen: Image.resolveAssetSource(
    require(
      './../Assets/Images/big-brother/competitorCircleGreen.png'
    )
  ),
  ic_mobile: foundationImages.ic_mobile,
  ic_roamingRedTail: Image.resolveAssetSource(
    require('./../Assets/Images/icons/ic_roaming_redTail.png')
  ),
  ic_roaming_location: Image.resolveAssetSource(
    require('./../Assets/Images/icons/ic_roaming_location.png')
  ),

  upgrade: Image.resolveAssetSource(require('../Assets/Images/backgrounds/upgrade.png')),
  info_circle_black: Image.resolveAssetSource(
    require('../Assets/Images/vf_icons/system_icons/info_circle_black_new.png')
  ),
  coca_cola_card: Image.resolveAssetSource(
    require('../Assets/Images/coca-cola/card.png')
  ),
  coca_cola_can: Image.resolveAssetSource(
    require('../Assets/Images/coca-cola/can.png')
  ),
  coca_cola_scan: Image.resolveAssetSource(
    require('../Assets/Images/coca-cola/scan.png')
  ),
  coca_cola_success: Image.resolveAssetSource(
    require('../Assets/Images/coca-cola/success.png')
  ),
  coca_cola_error: Image.resolveAssetSource(
    require('../Assets/Images/coca-cola/error.png')
  ),
  coca_cola_not_found: Image.resolveAssetSource(
    require('../Assets/Images/coca-cola/basket.png')
  ),
  info_light: Image.resolveAssetSource(require('../Assets/Images/icons/info_light.png')),
  info_dark: Image.resolveAssetSource(require('../Assets/Images/icons/info_dark.png')),
  notification_error_light: Image.resolveAssetSource(require('../Assets/Images/icons/notification_error_light.png')),
  notification_error_dark: Image.resolveAssetSource(require('../Assets/Images/icons/notification_error_dark.png')),
  activated_sim: Image.resolveAssetSource(require('../Assets/Images/activated_sim.png')),
  need_help_light: Image.resolveAssetSource(require('../Assets/Images/icons/need_help_light.png')),
  need_help_dark: Image.resolveAssetSource(require('../Assets/Images/icons/need_help_dark.png')),
  connectivity_light: Image.resolveAssetSource(require('../Assets/Images/icons/connectivity_light.png')),
  connectivity_dark: Image.resolveAssetSource(require('../Assets/Images/icons/connectivity_dark.png')),
  international_light: Image.resolveAssetSource(require('../Assets/Images/icons/international_light.png')),
  international_dark: Image.resolveAssetSource(require('../Assets/Images/icons/international_dark.png')),
  new_sim: Image.resolveAssetSource(require('../Assets/Images/icons/new_sim.png')),
  shopping_trolley: Image.resolveAssetSource(require('../Assets/Images/icons/shopping_trolley.png')),
  delivery_box: Image.resolveAssetSource(require('../Assets/Images/icons/delivery_box.png')),
}

export default images
