import ReduxCache from './ReduxCache'

const toLowerCase = value => typeof value === 'string' ? value.toLowerCase() : value
const toUpperCase = value => typeof value === 'string' ? value.toUpperCase() : value
const isURL = value => /^https?:\/\/.*/.test(value)


const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const diacritics = /\p{Diacritic}/gu
const normalizeString = str => str.normalize('NFD').replace(diacritics, '')
const normalizeObject = (obj) => {
    const newObj = JSON.stringify(obj)
    return JSON.parse(normalizeString(newObj))
}
const isFixUser = (userType = false) => {
    if (!userType) {
        userType = ReduxCache.getUserType()
    }

    const FIX_USER_TYPES = ['prepaid_fix', 'hybrid_fix', 'postpaid_fix']

    if (FIX_USER_TYPES.includes(userType)) {
        return true
    }

    return false
}

const isBusinessChooser = () => {
    const businessAccount = ReduxCache.getBusinessAccount()

    if (businessAccount?.sohoUserType === 'business_chooser') {
        return true
    } else {
        return false
    }
}

const convertCity = (city) => {
    if (!city) {
        return ''
    }
    if (city == 'Tirana') {
        return 'Tirane'
    } else {
        return city.replace('ë', 'e').trim()
    }
}

const convertMB = (units, amount) => {
    let convertedAmount = amount
    if (units === 'MB') {
        const amountBeforeDot = amount.toString().split('.')?.[0]
        if (amountBeforeDot.length <= 3) {
            convertedAmount = parseFloat(convertedAmount, 10)
        } else if (amountBeforeDot.length >= 4 && amountBeforeDot.length <= 6) {
            return convertMbTo('GB', convertedAmount)
        } else {
            return convertMbTo('TB', convertedAmount)
        }
    }
    return {
        amount: convertedAmount,
        units: units,
    }

}

const convertMbTo = (targetUnit, amount, currentUnit = 'MB') => {
    if (currentUnit === 'MB' && targetUnit === 'GB') {
        return {
            amount: parseFloat((parseFloat(amount) / 1024).toFixed(1)),
            units: 'GB',
        }
    } else if (currentUnit === 'MB' && targetUnit === 'TB') {
        return {
            amount: parseFloat((parseFloat(amount) / 1048576).toFixed(1)),
            units: 'TB',
        }
    }
    return { amount, units: currentUnit }
}



const isAdminView = () => {
    const businessAccount = ReduxCache.getBusinessAccount()

    if (businessAccount?.sohoUserType === 'business_chooser' && businessAccount?.showBusinessView) {
        return true
    } else {
        return false
    }
}

export {
    toLowerCase,
    toUpperCase,
    isURL,
    normalizeString,
    normalizeObject,
    capitalizeFirstLetter,
    isFixUser,
    convertCity,
    isBusinessChooser,
    isAdminView,
    convertMbTo,
    convertMB
}
