import { Dimensions, Platform } from 'react-native'

const { width, height } = Dimensions.get('window')

// Used via Metrics.baseMargin
const metrics = {
  mainContentPadding: 15,
  marginHorizontal: 10,
  marginVertical: 10,
  section: 25,
  baseMargin: 10,
  doubleBaseMargin: 18,
  smallMargin: 5,
  doubleSection: 50,
  horizontalLineHeight: 1,
  galleryCardWidth: 142,
  galleryCardMargin: 10,
  screenWidth: width < height ? width : height,
  screenHeight: width < height ? height : width,
  navBarHeight: Platform.OS === 'ios' ? 64 : 54,
  buttonRadius: 4,
  icons: {
    tiny: 15,
    small: 20,
    medium: 30,
    large: 45,
    xl: 50,
    xxl: 70
  },
  images: {
    small: 20,
    medium: 40,
    large: 60,
    logo: 200
  },
  card: {
    large: { width: 100, hight: 100 }, // main card
    medium: { width: 100, hight: 100 }, // discovery card
    small: { width: 100, hight: 100 }, // secondary card
    tiny: { width: 100, hight: 100 } // seconday half card
  }
}

export default metrics
