import axios from 'axios'

import CONFIG from '../../Config'
import {getErrorMessage, setLocalToken} from '../../Utils'
import ReduxCache from '../../Utils/ReduxCache'

import {action_types} from './demo.actionTypes'

const getUserProfile = () => {
  return async dispatch => {
    dispatch({
      type: action_types.REQUEST_PROFILE,
    })



    const msisdn = ReduxCache.getMsisdn()

    const url = await CONFIG.getUrl()
    const headers = await CONFIG.getProfileHeaders()

    axios
      .get(url.profileEndpoint + msisdn, {
        headers: {
          ...headers,
        }
      })
      .then(response => {


        const data = response.data

        const characteristics = {}

        for (const i in data.parts.specification.characteristicsValue) {
          const characteristic = data.parts.specification.characteristicsValue[i]
          characteristics[characteristic.characteristicName] =
            characteristic.value
        }

        const balances = data?.details?.balance

        const lekeBalanceObj = balances?.find(item => item?.type === 'leke') || {}
        const ticketsBalanceObj = balances?.find(item => item?.type === 'tickets') || {}
        const lekeBalance = lekeBalanceObj?.amount?.value ? `${lekeBalanceObj?.amount?.value} ${lekeBalanceObj?.amount?.currencyID}` : null
        const ticketsBalance = ticketsBalanceObj?.amount?.value || 0

        let coins = data.details.loyaltyAccountBalance.value
        if (coins == '') {
          coins = null
        }

        const user = {
          id: data.id[0].value,
          name: data.name,
          status: data.status,
          created: data.created.value,
          coins: coins,
          lekeBalance: lekeBalance,
          ticketsBalance: ticketsBalance,
          characteristics: characteristics,
        }

        dispatch({
          type: action_types.SUCCESS_PROFILE,
          payload: user,
        })

      })
      .catch(error => {
        

        const message = getErrorMessage(error)
        dispatch({
          type: action_types.ERROR_PROFILE,
          payload: message,
        })
      })
  }
}

const setInitialUser = user => {
  return dispatch => {
    dispatch({
      type: action_types.INITIAL_PROFILE,
      payload: user,
    })
  }
}

const setActiveComponent = component => {
  return dispatch => {
    dispatch({
      type: action_types.ACTIVE_COMPONENT,
      payload: component,
    })
  }
}

export const actions = {
  getUserProfile,
  setInitialUser,
  setActiveComponent,
}
