import { StyleSheet, Dimensions, Platform } from 'react-native'

const { width, height } = Dimensions.get('window')

export default StyleSheet.create({
  gestureStyle: {
    flex: 1,
    backgroundColor: '#000000'
  },
  safeArea: {
    flex: 1,
    backgroundColor: '#000000',
  },
  storyContainer: {
    backgroundColor: '#000000'
  },
  background: {
    flex: 1,
    paddingHorizontal: 10,
    zIndex: 5
  },
  progresses: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
    zIndex: 5,
    marginBottom: 15
  },
  singleProgress: {
    maringTop: 10
  },
  pressable: {
    height: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? height - 100 : height,
    width: Platform.OS === 'web' ? '100%' : width,
    marginHorizontal: 10,
  },
  loaderContainer: {
    height: '100%',
    width: '100%',
    marginTop: -30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loader: {
    height: 80,
    alignSelf: 'center',
    paddingRight: 20,
  },
  videoPlayer: {
    height: height + 20,
    width: width,
    zIndex: 1,
    marginLeft: -10,
    marginVertical: -20,
  },
  storyImage: {
    width: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? '100%' : width,
    height: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? '90%' : height + 20,
    zIndex: 1,
    marginLeft: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 0 : -10,
    marginVertical: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 0 : -20,
    marginTop: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 20 : -20,

  },
  bottomButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '22%'
  },
  storyDataContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 5,
    position: 'absolute',
    top: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 30 : 16,
    left: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 30 : null,
    width: '100%',
    flex: 1,
    flexWrap: 'wrap',
    flexShrink: 1,

  },
  customIconContainer: {
    borderRadius: 100,
    borderColor: '#fff',
    backgroundColor: 'white',
    borderWidth: 1,
    height: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 50 : 40,
    width: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 50 : 40,
    justifyContent: 'center',
    alignItems: 'center',
    resizeMode: 'cover',
    zIndex: 10
  },
  customIcon: {
    zIndex: 2,
    borderRadius: 50,
    height: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 45 : 35,
    width: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 45 : 35,
  },
  infoLeft: {
    flexDirection: 'row',
    paddingVertical: 16,
    flex: 1,
    width: '80%',
    flexGrow: 1,
  },
  iconContainer: {
    borderRadius: 50,
    backgroundColor: 'white',
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleContainer: {
    justifyContent: 'space-between',
    marginLeft: 8,
    paddingVertical: 3,
    flex: 1,
    flexGrow: 1,
  },
  titleText: {
    color: 'white',
    fontWeight: '700',
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 20 : null
  },
  subTitleText: {
    flex: 1,
    color: 'white',
    flexWrap: 'wrap',
    lineHeight: 16,
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 16 : null
  },
  navigationContainer: {
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 99,
    position: 'absolute',
    top: '15%'
  },
  leftBtnNav: { width: '30%', height: '100%', marginLeft: -10 },
  navGradient: {
    width: '40%',
    height: '70%',
    zIndex: 99,
    position: 'relative'
  },
  rightBtnNav: {
    width: '30%',
    height: '100%',
    marginRight: -10,
    alignItems: 'flex-end'
  },
  bottomBtnContainer: bottom => ({
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: bottom,
    marginBottom: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? -30 : 30,
    alignItems: 'center',
    paddingLeft: 5,
    paddingBottom: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 0 : 15,
    zIndex: 999,
  }),
  storyBtn: (theme) => ({
    width: '70%',
    backgroundColor: theme.colors.buttons.primaryWhite.default,
    color: 'black'
  }),
  emptyStoryButton: {
    width: '70%',
  },
  buttonTitle: (theme) => ({
    color: '#000'
  }),
  reloading: {
    position: 'absolute',
    top: '50%',
    left: '45%'
  },
})
