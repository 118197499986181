/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { StatusBar } from 'react-native'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import Browser from '@vfal-lab/rn-vfg-catalogue/Components/Browser'
import { CatalogueScreen } from '@vfal-lab/rn-vfg-catalogue/Screens'

import Refill from '../Refill'
import Payments from '../Payments'
import { setLocal } from '../../Utils'


import NavigationService from '../../Navigation/NavigationService'

import { actions } from './Catalogue.Actions'


const LANGUAGES = {
  al: {
    'payment_quick_action_title': 'Blerje produkti',
    'payment_quick_action_subtitle': 'Aktivizo tani',
    'payment_quick_action_description': 'Validiteti:',
    'payment_quick_action_success': 'Paketa %1$s u aktivizua me sukses.',
    'payment_quick_action_success_message': '',
  },
  en: {
    'payment_quick_action_title': 'Activate bundle',
    'payment_quick_action_subtitle': 'Activate',
    'payment_quick_action_description': 'Validity:',
    'payment_quick_action_success': 'Bundle %1$s activated successfully!',
    'payment_quick_action_success_message': '',
  },
}

function Catalogue({ navigation, route }) {
  const dispatch = useDispatch()

  const [showRefill, setShowRefill] = useState(false)
  const [showPayments, setShowPayments] = useState(false)
  const [bundleId, setBundleId] = useState(route.params?.bundleId)
  const [bundleCategory, setBundleCategory] = useState(route.params?.bundleCategory)
  const [activeCategory, setActiveCategory] = useState(false)
  const [browserState, setBrowserState] = useState({
    isVisible: false,
    url: undefined,
  })

  const catalogue = useSelector(state => state.catalogueDetails)
  const [shouldSHowModal, setShouldShowModal] = useState(true)



  useEffect(() => {
    if (catalogue?.data?.length > 0 && bundleId) {
      catalogue.data.map(category => {
        category.subcategories.map(subcategory => {
          subcategory.items.map(item => {
            if (item.id == bundleId) {
              setBundleId(false)
              navigation.navigate('CatalogueDetails', { item, prevScreen: 'Roaming' })
            }
          })

        })
      })
    }

    if (catalogue?.data?.length > 0 && bundleCategory) {
      catalogue.data.map((category, i) => {
        if (category.id == `category_${bundleCategory}`) {
          setActiveCategory(i)
          setBundleCategory(false)
        }
      })
    }

  }, [catalogue])

  const getCatalogue = () => {
    dispatch(actions.getCatalogue('myoffers'))
  }

  /** Content manager initialization */
  const [isContentLoading, setContentLoading] = useState(true)

  useEffect(() => {
    ContentManager.configureI18nTranslations({ en: LANGUAGES.en, al: LANGUAGES.al })
    setContentLoading(false)
    getCatalogue()
  }, [])

  const onClose = () => {
    if (route?.params?.isDeepLink) {
      navigation.navigate('Dashboard')
    } else {
      navigation.pop()
    }
  }

  useEffect(() => {
    if (route?.params?.type) {
      // getCatalogue()
      let categoryId = ''
      if (route?.params?.type === 'paketa') {
        setActiveCategory(0)
        categoryId = 'category_bundle'
      } else if (route?.params?.type === 'paketa-shtese') {
        setActiveCategory(1)
        categoryId = 'category_add_on'
      } else if (route?.params?.type === 'roaming') {
        setActiveCategory(2)
        categoryId = 'category_roaming'
      } else {
        NavigationService.navigateWithResetAction('Home', { isDeepLinking: false })
      }


      if (catalogue?.data?.length > 0 && route?.params?.bundleId) {
        setTimeout(() => {
          catalogue.data.find(category => category.id === categoryId).subcategories.map(subcategory => {
            subcategory.items.map(item => {
              if (item.id === route?.params?.bundleId) {
                setBundleId(false)
                navigation.navigate('CatalogueDetails', { item })
              }
            })
          })
        }, 3000)
      }
    }
  }, [route])

  const openBrowser = (url) => {
    setBrowserState({ isVisible: true, url })
  }

  const closeBrowser = () => {
    setBrowserState({ isVisible: false })
  }

  const onOfferPress = async (item) => {
    const { ctaurl } = item
    switch (ctaurl) {
      case undefined:
      case 'details':
        console.log(item, 'item')
        navigation.navigate('CatalogueDetails', { item })
        break
      case 'p2h':
        // @todo change this to navigation params
        await setLocal('type', 'P2H')
        navigation.navigate('Migration')
        break
      case 'renewal':
        await setLocal('type', 'Renewal')
        navigation.navigate('Migration')
        break
      case 'refill':
        setShowRefill(true)
        break
      case 'shake':
        navigation.navigate('Shake')
        break
      case 'flowerbox':
        navigation.navigate('VfHearts')
        break
      case 'topup':
        setShowPayments(true)
        break
      case 'ebill':
        navigation.navigate('Billing')
        break
      case 'gigafamily':
        navigation.navigate('GigaFamily')
        break
      case 'tidal':
        openBrowser(ContentManager.translate('catalogue_payment_url_tidal'))
        break
      default:
        navigation.navigate('CatalogueDetails', { item })
        break
    }
  }

  useEffect(() => {
    setShouldShowModal(true)
  }, [])

  return (
    <>
      <StatusBar />
      <CatalogueScreen
        isContentLoading={isContentLoading}
        onClose={onClose}
        onOfferPress={onOfferPress}
        activeTab={activeCategory}
        {...catalogue}
        retry={getCatalogue}
        maxNumOfRetries={3}
        shouldSHowModal={shouldSHowModal}
        setShouldShowModal={setShouldShowModal}
      />
      {showRefill && <Refill setShowRefill={setShowRefill} isVisible={showRefill} />}
      {showPayments && (
        <Payments
          setShowPayment={setShowPayments}
          isVisible={showPayments}
        />
      )}
      {browserState.isVisible && <Browser {...browserState} closeBrowser={closeBrowser} />}
    </>
  )
}

Catalogue.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object
}
export default Catalogue
