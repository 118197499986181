import axios from 'axios'

import moment from 'moment'

import { isFunction as _isFunction } from 'lodash'

import Images from '@vfal-lab/rn-vfg-fix-billing/Themes/Images'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import ReduxCache from '../../Utils/ReduxCache'

import { formatNumber, isAdminView } from '../../Utils'


import { API_URLS, COMMON_HEADERS } from '../Config'


const monthTranslations = {
  al: {
    'January': 'Janar',
    'February': 'Shkurt',
    'March': 'Mars',
    'April': 'Prill',
    'May': 'Maj',
    'June': 'Qershor',
    'July': 'Korrik',
    'August': 'Gusht',
    'September': 'Shtator',
    'October': 'Tetor',
    'November': 'Nëntor',
    'December': 'Dhjetor',
    'Jan': 'Janar',
    'Feb': 'Shkurt',
    'Mar': 'Mars',
    'Apr': 'Prill',
    'Jun': 'Qershor',
    'Jul': 'Korrik',
    'Aug': 'Gusht',
    'Sep': 'Shtator',
    'Oct': 'Tetor',
    'Nov': 'Nëntor',
    'Dec': 'Dhjetor',
    'Janar': 'Janarit',
    'Shkurt': 'Shkurtit',
    'Mars': 'Marsit',
    'Prill': 'Prillit',
    'Maj': 'Majit',
    'Qershor': 'Qershorit',
    'Korrik': 'Korrikut',
    'Gusht': 'Gushtit',
    'Shtator': 'Shtatorit',
    'Tetor': 'Tetorit',
    'Nëntor': 'Nëntorit',
    'Dhjetor': 'Dhjetorit'
  },
  en: {
    'January': 'January',
    'February': 'February',
    'March': 'March',
    'April': 'April',
    'May': 'May',
    'June': 'June',
    'July': 'July',
    'August': 'August',
    'September': 'September',
    'October': 'October',
    'November': 'November',
    'December': 'December',
    'Jan': 'Jan',
    'Feb': 'Feb',
    'Mar': 'Mar',
    'Apr': 'Apr',
    'Jun': 'Jun',
    'Jul': 'Jul',
    'Aug': 'Aug',
    'Sep': 'Sep',
    'Oct': 'Oct',
    'Nov': 'Nov',
    'Dec': 'Dec'
  }
}

const mapError = (error) => {
  const payload = error?.response?.data?.message || error?.message
  return payload
}


const mapCategoryToService = (category) => {
  if (category === 'mobile') {
    return 'DATA'
  } else if (category === 'fix') {
    return 'BROADBAND'
  } else {
    return ''
  }
}

const SERVICE_TYPES = ['TV', 'DATA', 'ROAMING', 'BROADBAND', 'HOMEPHONE']


const mapBill = (bill) => {

  const language = ReduxCache.getLanguage()

  const {
    amountDue,
    billingPeriod,
    id,
    state,
    category,
    relatedParty,
    paymentDueDate,
    appliedCustomerBillingRate,
    billDate
  } = bill

  moment.locale('en')
  const services = []
  const chargesList = []

  const billType = category ? category : ''

  const adminName = relatedParty?.[0]?.name || ''
  const address = category === 'fix' ? relatedParty?.[0]?.href : null

  services.push(mapCategoryToService(category))

  const startDate = moment(billingPeriod.startDateTime)
  const endDate = moment(billingPeriod.endDateTime)

  const shortStartDate = startDate.date() + ' ' + monthTranslations[language][startDate.format('MMM')]
  const shortEndDate = endDate.date() + ' ' + monthTranslations[language][endDate.format('MMM')]

  const date = moment(paymentDueDate)

  if (appliedCustomerBillingRate && appliedCustomerBillingRate?.length > 0) {
    appliedCustomerBillingRate.filter(el => (el.type !== 'NON_USAGE' && el.type !== 'tax'))?.map(item => {
      const { name, taxIncludedAmount } = item
      if (name && taxIncludedAmount?.value) {
        chargesList.push({
          title: name,
          price: formatNumber(taxIncludedAmount?.value?.toFixed(2)).toString() + ' ' + taxIncludedAmount?.unit
        })
      }
    })

    appliedCustomerBillingRate.forEach(el => {
      if (SERVICE_TYPES.includes(el?.description?.toUpperCase()) && !services.find(service => service === el?.description?.toUpperCase())) {
        services.push(el?.description?.toUpperCase())
      }
    })
  }

  return {
    id,
    extraChargesText: isAdminView() ? adminName : chargesList.length !== 0 ? (category === 'fix' ? 'billing_current_bill_products' : 'billing_current_bill_extra_charges') : null,
    chargesList: isAdminView() ? null : chargesList,
    startDate: billingPeriod.startDateTime,
    endDate: billingPeriod.endDateTime,
    month: monthTranslations[language][startDate.format('MMMM')],
    shortMonth: monthTranslations[language][startDate.format('MMM')],
    fullmonth: monthTranslations[language][date.format('MMMM')],
    formattedMonth: monthTranslations[language][date.format('MMMM')],
    monthNumber: startDate.format('M'),
    year: endDate.year(),
    shortStartDate,
    shortEndDate,
    date: startDate.format('DD'),
    timePeriod: `${shortStartDate} - ${shortEndDate}`,
    value: +amountDue.value,
    currency: ' ' + amountDue.unit,
    status: state !== 'sent' ? state : 'new',
    isCurrencyFirst: false,
    price: `${formatNumber(amountDue)} ${amountDue.unit}`,
    priceNumber: amountDue.value,
    paymentInfo: [date.format('DD'), '', monthTranslations[language][date.format('MMM')]],
    imageListProp: {
      services: services.length === 0 ? [mapCategoryToService(category)] : services,
      arrow: Images.chevronRightRed
    },
    address,
    billType: billType ? billType : ' ',
    //to order bills, fix bill are postpaid in advance and mobile bills are postpaid
    //for fix bill the current month will be a month in advance and for mobile bill is the current month
    billDate,
    billDateMonthNumber: moment(billDate).format('M'),
    withBadge: true
  }
}


const mapAllBillsData = (res) => {

  let formattedBills = []

  res.map(bill => formattedBills.push(mapBill(bill)))

  formattedBills = formattedBills.length > 0 ? formattedBills?.sort((a, b) => moment(a.billDate).diff(moment(b.billDate))) : []

  return formattedBills
}

const downloadBillResponseMapper = (res) => {
  const binaryData = res?.[0]?.binaryAttachment[0]

  const id = res?.[0].id

  return {
    content: binaryData.content,
    attachmentType: binaryData.attachmentType,
    id,
  }

}

const mapHeadlineIcon = (role) => {
  switch (role) {
    case 'Company':
      return 'ic_indicators_business'
    case 'Individual':
      return 'ic_admin'
    case 'Employee':
      return 'ic_admin'
    case 'fix':
      return 'icWifiBroadband'
    default:
      return 'ic_mobile'
  }
}

const billSubscriptionMapper = (data = []) => {

  const formattedData = []

  if (data.length > 0) {
    data.map(item => {
      const {
        appliedCustomerBillingRate,
        relatedParty,
        id,
        state
      } = item


      let totalValue = ''
      let noVatValue = ''
      let vatValue = ''
      let unit = ''

      const detailsItems = {}

      if (appliedCustomerBillingRate && appliedCustomerBillingRate?.length > 0) {
        const totalObj = appliedCustomerBillingRate.find(el => el.type === 'tax') || {}
        if (Object.keys(totalObj).length > 0) {
          totalValue = totalObj?.taxIncludedAmount?.value?.toFixed(2)
          noVatValue = totalObj?.taxExcludedAmount?.value?.toFixed(2)
          vatValue = totalObj?.appliedTax?.[0]?.taxAmount?.value?.toFixed(2)
          unit = totalObj?.taxIncludedAmount?.unit
        }

        const filteredDetailsArray = appliedCustomerBillingRate.filter(el => el.type !== 'tax')

        if (filteredDetailsArray && filteredDetailsArray?.length > 0) {
          filteredDetailsArray.map(detailsItem => {
            if (detailsItem?.type) {
              detailsItems[detailsItem?.type] = [
                ...(detailsItems?.[detailsItem?.type] || []),
                {
                  title: detailsItem?.name,
                  price: formatNumber(detailsItem?.taxIncludedAmount?.value).toString() + ' ' + detailsItem?.taxIncludedAmount?.unit
                }
              ]
            }
          })
        }
      }

      const formattedObject = {
        headlineProps: {
          title: relatedParty?.[0]?.name,
          logo: mapHeadlineIcon(relatedParty?.[0]?.role),
          arrow: false,
          id: relatedParty?.[0]?.id,
        },
        total: {
          totalValue: totalValue ? formatNumber(totalValue).toString() + ' ' + unit : '',
          noVatValue: noVatValue ? formatNumber(noVatValue).toString() + ' ' + unit : '',
          vatValue: vatValue ? formatNumber(vatValue).toString() + ' ' + unit : '',
        },
        type: relatedParty?.[0]?.['@type'],
        role: relatedParty?.[0]?.role,
        detailsItems: detailsItems,
        id,
        status: state !== 'sent' ? state : 'new',
      }
      formattedData.push(formattedObject)
    })
  }

  return formattedData
}

const billBreakdownResponseMapper = (res = []) => {

  const language = ReduxCache.getLanguage()
  moment.locale('en')

  let formattedData = {}

  if (res.length > 0) {

    const bill = isAdminView() ? res.find(el => el?.relatedParty?.[0]?.['@type'] === 'organization') : res[0]

    const categories = []
    res.forEach(el => {
      if (!categories.find(item => item.id === el?.relatedParty?.[0]?.['@type'])) {
        if (!categories?.find(item => item.id === el?.relatedParty?.[0]?.role)) {
          categories.push({
            name: ContentManager.translate(`billing_breakdown_filter_role_${el?.relatedParty?.[0]?.role?.toLowerCase()}`),
            id: el?.relatedParty?.[0]?.role
          })
        }
      }
    })

    const {
      amountDue,
      billDate,
      billingPeriod,
      id,
      paymentMethod: paymentObj,
      relatedParty,
      state,
      paymentDueDate
    } = bill || {}


    const startDate = moment(billingPeriod?.startDateTime)
    const endDate = moment(billingPeriod?.endDateTime)

    const shortStartDate = startDate.date() + ' ' + monthTranslations[language][startDate.format('MMM')]
    const shortEndDate = endDate.date() + ' ' + monthTranslations[language][endDate.format('MMM')]

    const date = moment(billDate)
    const billStartDate = moment(billingPeriod?.startDateTime)

    const accountId = relatedParty?.[0]?.id

    let paymentDate = moment(paymentDueDate)

    let payment = {}

    if (paymentObj && paymentObj?.[0]) {
      payment = {
        paymentMethod: {
          details: {
            brand: paymentObj?.[0]?.details?.brand,
            lastFourDigit: paymentObj?.[0]?.details?.lastFourDigits
          }
        },
        id: paymentObj?.[0]?.details?.id
      }
      paymentDate = moment(paymentObj?.[0]?.details?.paymentDate)
    }

    formattedData = {
      id,
      startDate: billingPeriod?.startDateTime,
      endDate: billingPeriod?.endDateTime,
      month: monthTranslations[language][startDate.format('MMMM')],
      shortMonth: monthTranslations[language][startDate.format('MMM')],
      fullmonth: monthTranslations[language][date.format('MMMM')],
      formattedMonth: monthTranslations[language][startDate.format('MMMM')],
      year: endDate.year(),
      shortStartDate,
      shortEndDate,
      date: billStartDate.format('DD'),
      timePeriod: `${shortStartDate} - ${shortEndDate}`,
      paymentDate,
      formatedPaymentDate: !paymentDate ? '' : monthTranslations[language][paymentDate?.format('MMMM')],
      paymentMethod: payment?.paymentMethod,
      payment,
      partyAccount: accountId,
      accountId,
      value: +amountDue?.value,
      currency: ' ' + amountDue?.unit,
      status: state !== 'sent' ? state : undefined,
      isCurrencyFirst: false,
      price: `${formatNumber(amountDue?.value)} ${amountDue?.unit}`,
      priceNumber: amountDue?.value,
      paymentInfo: [date.format('DD'), '', monthTranslations[language][date.format('MMM')]],
      subscription: billSubscriptionMapper(res),
      categories,
    }
  }
  return formattedData
}


const getAllBills = async (utilities = {}) => {

  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
    responseMapper: mapAllBillsData
  })

  const {
    onStart,
    onSuccess,
    onError,
    responseMapper,
    errorMapper
  } = _utilities

  const msisdn = ReduxCache.getMsisdn()

  const params = isAdminView() ? {
    "relatedParty[(@referredType=='organization')].id": msisdn,
  } : {
    "relatedParty[(@referredType=='individual')].id": msisdn,
    'category': 'all'
  }

  const headers = {
    ...COMMON_HEADERS,
    'vf-operator': 'selfcare',
    'vf-source': 'customerAgent',
    'vf-project': 'soho'
  }

  onStart()

  try {
    const response = await axios.get(API_URLS.billHistory, {
      params,
      headers,
    })

    const _response = responseMapper(response?.data)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}


const GetBillPDF = async (billId, utilities = {}) => {
  const _utilities = retrieveUtilities(utilities, {
    responseMapper: downloadBillResponseMapper,
    errorMapper: mapError
  })

  const {
    onStart,
    onSuccess,
    onError,
    responseMapper,
    errorMapper
  } = _utilities


  const msisdn = ReduxCache.getMsisdn()

  const headers = {
    'vf-source': 'selfcare',
    'vf-operator': 'ebill',
    'vf-country-code': 'al',
  }

  onStart()

  try {
    const response = await axios.get(API_URLS.getBillPDF +
      `?documentType=${isAdminView() ? 'ebill_business' : 'ebill'}&id=${billId}&relatedParty.id=${msisdn}`, {
      headers,
    })

    const _response = responseMapper(response?.data)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}


const getBillBreakdown = async (billId, isAdmin, msisdn, utilities = {}) => {

  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
    responseMapper: billBreakdownResponseMapper
  })

  const {
    onStart,
    onSuccess,
    onError,
    responseMapper,
    errorMapper
  } = _utilities


  const headers = {
    'vf-project': 'soho',
  }

  const params = isAdmin ? {
    "relatedParty[(@referredType=='organization')].id": msisdn,
    'bill.id': billId
  } : {
    "relatedParty[(@referredType=='individual')].id": msisdn,
    'bill.id': billId
  }

  onStart()

  try {
    const response = await axios.get(API_URLS.billBreakdown, {
      headers,
      params
    })

    const _response = responseMapper(response.data)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

export { getAllBills, GetBillPDF, getBillBreakdown }
