import React, { useState, useEffect } from 'react'
import { View, KeyboardAvoidingView, Platform, Dimensions } from 'react-native'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'


import Modal from 'react-native-modal'

import AppSettings from '@vfgroup-oneplatform/framework/Settings/AppSettings'

import { Icon, QuickActionMenu, VFButton, VFInput, VFText } from '@vfgroup-oneplatform/foundation/Components'

import { Colors, useTheme, Images } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { getThemeImages } from '../../Group/Themes'
import RadioGroup from '../../Group/Components/RadioGroup'

import NavigationService from '../../Navigation/NavigationService'
import { terminateAccount } from '../../Services/Settings'

import launchLoginScreen from '../../Utils/LaunchLogin'

import Card from './Card'
import DescriptionCard from './DescriptionCard'

import styles from './DeactivateAccount.Styles'


function ErrorStep({
    closeModal,
    deleteError,
}) {
    const images = getThemeImages('light')
    return (
        <View style={styles.requestResultContainer}>
            <Icon
                name={images.ic_WarningHiLight_Theme}
                size={80}
                resizeMode="contain"
                type="image"
                style={styles.image}
            />
            <View style={styles.textContainer}>
                <VFText
                    i18nKey="delete_account_error_title"
                    size="h1"
                    weight="bold"
                    style={styles.errorTitle}
                />
                <VFText
                    i18nKey={deleteError || 'delete_account_default_error'}
                    size="h5"
                    color={Colors.dark_grey}
                    style={styles.errorDescription}
                />
            </View>
            <VFButton
                type="primary"
                title="delete_account_back_button"
                onPress={closeModal}
                style={styles.stepButton}
            />
        </View>
    )
}
ErrorStep.propTypes = {
    closeModal: PropTypes.func,
    deleteError: PropTypes.string,
}

function DeleteProfile({ closeModal, confirmDeletion }) {
    return (
        <View
            style={styles.modal}
            onStartShouldSetResponder={() => true}>
            <VFText
                i18nKey="delete_account_confirm_title"
                style={styles.modalTitle(24)}
            />

            <VFText
                i18nKey="delete_account_confirm_text"
                style={styles.modalSubtitle}
            />

            <VFButton
                type="primary"
                title="delete_account_button"
                onPress={confirmDeletion}
                style={styles.confirmButton}
            />
            <VFButton
                type="secondary"
                title="delete_account_cancel_button"
                onPress={closeModal}
            />
        </View>
    )
}
DeleteProfile.propTypes = {
    confirmDeletion: PropTypes.func,
    closeModal: PropTypes.func,
}
function Feedback({
    closeModal,
    deleteAccount,
    selected,
    setSelected,
    otherReason,
    setOtherReason,
    loading,
    options,
}) {

    return (
        <KeyboardAvoidingView
            style={styles.modalContainer}
            behavior={Platform.OS === 'ios' ? 'position' : undefined}
            keyboardVerticalOffset={200}
        >
            <View onStartShouldSetResponder={() => true} style={styles.modalContainer}>
                <VFText
                    i18nKey="delete_account_feedback_text"
                    style={styles.modalTitle(10)}
                />

                <RadioGroup
                    options={options}
                    setSelected={setSelected}
                    selected={selected}
                />

                {selected === 3 &&
                    <VFInput
                        label="delete_account_other_reason"
                        value={otherReason}
                        onChangeText={setOtherReason}
                        containerStyle={styles.inputContainer}
                        labelTextStyle={styles.inputText}
                        style={styles.inputText}
                        tintColor={Colors.ocean}
                    />
                }

                <VFButton
                    type="primary"
                    title="delete_account_button"
                    onPress={deleteAccount}
                    style={styles.confirmButton}
                    disabled={selected === -1 || (selected === 3 && !otherReason) || loading}
                />
                <VFButton
                    type="secondary"
                    title="delete_account_cancel_button"
                    onPress={closeModal}
                />
            </View>
        </KeyboardAvoidingView>
    )
}
Feedback.propTypes = {
    deleteAccount: PropTypes.func,
    closeModal: PropTypes.func,
    selected: PropTypes.number,
    setSelected: PropTypes.func,
    otherReason: PropTypes.string,
    setOtherReason: PropTypes.func,
    loading: PropTypes.bool,
    options: PropTypes.object,
}

function Success({
    goToLogout,
    successMsg
}) {

    return (
        <View onStartShouldSetResponder={() => true}>
            <VFText
                i18nKey={successMsg || 'delete_account_success_title'}
                style={styles.modalTitle(25)}
            />
            <VFButton
                type="primary"
                title="Okay"
                onPress={goToLogout}
            />
        </View>
    )
}
Success.propTypes = {
    goToLogout: PropTypes.func,
    successMsg: PropTypes.string,
}

const ActiveScreen = {
    delete: DeleteProfile,
    feedback: Feedback,
    error: ErrorStep,
    success: Success,
}

const activeScreens = {
    delete: 'delete',
    feedback: 'feedback',
    error: 'error',
    success: 'success',
}

function DeactivateAccount({ ...rest }) {
    const theme = useTheme()
    const images = getThemeImages(theme.name)

    const [isVisible, setVisible] = useState(false)
    const [activeModal, setActiveModal] = useState(activeScreens.delete)
    const DeleteProfileStep = ActiveScreen[activeModal]
    const [selected, setSelected] = useState(-1)
    const [otherReason, setOtherReason] = useState()
    const [error, setError] = useState('')
    const [successMsg, setSuccessMsg] = useState('')

    const reasons = useSelector(state => state.configurations.pages.find(el => el.page === 'Account').items)
    const options = []
    reasons.map((item, index) => {
        options.push({ id: index, label: item.value, reason: item.value })
    })

    useEffect(() => {
        if (activeModal === activeScreens.success) {
            setTimeout(() => {
                goToLogout()
            }, 5000)
        }
    }, [activeModal])

    const [loading, setLoading] = useState(false)

    const closeModal = () => {
        setVisible(false)
        if (activeModal !== activeScreens.success) {
            setActiveModal(activeScreens.delete)
            setSelected(-1)
            setOtherReason()
        } else {
            goToLogout()
        }
    }

    const description = {
        title: 'delete_account_note_title',
        description: ' ',
        withCard: true,
        items: [
            {
                component: DescriptionCard,
                props: {
                    description: 'delete_account_long_description',
                }
            },
        ]
    }

    const profileSettings = {
        description: ' ',
        withCard: true,
        items: [
            {
                component: Card,
                props: {
                    icon: 'ic_admin',
                    title: 'delete_account_profile_note_title',
                    description: 'delete_account_profile_note_description',
                    onPress: () => setVisible(true)
                }
            },
        ]
    }

    const onFailedTermination = (er) => {
        setActiveModal(activeScreens.error)
        setError(er)
    }

    const onClose = () => NavigationService.pop()

    const deleteAccount = () => {
        setLoading(true)
        let reason = ''
        if (selected === 3) {
            reason = otherReason
        } else {
            reason = options?.[selected]?.reason
        }

        terminateAccount(reason, { 'vf-operator': 'account' }, {
            onSuccess: (response) => {
                setSuccessMsg(response?.description)
                setLoading(false)
                setActiveModal(activeScreens.success)
            },
            onError: (er) => {
                setLoading(false)
                onFailedTermination(er)
            }
        })
    }

    const goToLogout = () => launchLoginScreen()

    const confirmDeletion = () => setActiveModal(activeScreens.feedback)

    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
        return (
            <>
                <AppSettings
                    title="delete_account_screen_title"
                    settings={[description, profileSettings]}
                    onClose={onClose}
                    images={images}
                    withTray
                    {...rest}
                />
                <Modal
                    isVisible={isVisible}
                    backdropOpacity={0.5}
                    onSwipeComplete={() => setVisible(false)}
                    onBackdropPress={() => setVisible(false)}
                    propagateSwipe
                    hideModalContentWhileAnimating
                    hardwareAccelerated
                    backdropTransitionOutTiming={0}
                    style={{
                        justifyContent: 'flex-end',
                        margin: '0 !important',
                        minWidth: 1920,
                        maxWidth: 1920,
                    }}
                >
                    <View
                        style={{
                            maxHeight: '300px',
                            minWidth: '600px',
                            maxWidth: '600px',
                            backgroundColor: theme.colors.background.primary[0],
                            margin: 'auto',
                            padding: '20px',
                            borderRadius: '7px',
                        }}
                        onStartShouldSetResponder={() => true}>
                        <DeleteProfileStep
                            setActiveModal={setActiveModal}
                            closeModal={closeModal}
                            deleteAccount={deleteAccount}
                            confirmDeletion={confirmDeletion}
                            selected={selected}
                            setSelected={setSelected}
                            otherReason={otherReason}
                            setOtherReason={setOtherReason}
                            deleteError={error}
                            loading={loading}
                            options={options}
                            goToLogout={goToLogout}
                            successMsg={successMsg}
                        />
                    </View>
                </Modal>
            </>
        )
    }

    return (
        <>
            <AppSettings
                title="delete_account_screen_title"
                settings={[description, profileSettings]}
                onClose={onClose}
                images={images}
                withTray
            />
            <QuickActionMenu
                isVisible={isVisible}
                toggleModal={closeModal}
                type="secondary"
                title="delete_account_title"
            >
                <DeleteProfileStep
                    setActiveModal={setActiveModal}
                    closeModal={closeModal}
                    deleteAccount={deleteAccount}
                    confirmDeletion={confirmDeletion}
                    selected={selected}
                    setSelected={setSelected}
                    otherReason={otherReason}
                    setOtherReason={setOtherReason}
                    deleteError={error}
                    loading={loading}
                    options={options}
                    goToLogout={goToLogout}
                    successMsg={successMsg}
                />
            </QuickActionMenu>
        </>
    )
}

export default DeactivateAccount
