import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'
import { Platform } from 'react-native'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import ReduxCache from '../Utils/ReduxCache'

import { APP_VERSION } from '../Config'

import { API_URLS } from './Config'

const mapBalanceResponse = (data) => {
  const balance = {}

  data.map((item, index) => {
    if (balance[item.type.$.toLowerCase()]) {
      balance[item.type.$.toLowerCase() + '_' + index] = item
    } else {
      balance[item.type.$.toLowerCase()] = item
    }
  })

  return balance
}

const GetBalance = async (isSohoUser = false, customHeaders = {}, utilities = { responseMapper: mapBalanceResponse }) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

  const businessAccount = ReduxCache.getBusinessAccount()
  const userType = ReduxCache.getUserType()

  onStart()

  try {
    const response = await axios.get(API_URLS.balance, {
      headers: {
        'X-Source-System': 'selfcare',
        'X-Source-Operator': 'myvodafone',
        'X-Source-CountryCode': 'al|3.6.0',
        'X-Application-Version': APP_VERSION,
        'X-Application-OS': Platform.OS == 'ios' ? 'IOS' : 'Android',
        ...customHeaders
      },
      params: {
        'categories.$': isSohoUser ? businessAccount?.sohoUserType : userType
      }
    })

    const _response = responseMapper(response.data)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

export { GetBalance }
