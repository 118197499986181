import { StyleSheet, Platform, Dimensions } from 'react-native'

import { Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { PlatformUtils } from '@vfgroup-oneplatform/foundation/Utils'

export default StyleSheet.create({
  backgroundImage: {
    flex: 1,
    height: '100%',
    width: '100%',
    paddingHorizontal: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? '10%' : null,
    paddingBottom: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 32 : null,
  },
  singleStory: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 8
  },
  welcomeContainer: {
    diplay: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: Platform.OS === 'ios' ? 32 : 22,
    marginHorizontal: 16,
    alignItems: 'center'
  },
  welcomeMessage: {
    fontSize: 20,
    color: Colors.white,
  },
  flagsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  flagSeparator: {
    fontSize: 22,
    color: Colors.white,
    textAlign: 'center'
  },
  storyContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 4,
  },
  storyContentStyle: {
    gap: 20,
    justifyContent: 'space-between'
  },
  storyButton: seen => ({
    borderRadius: 100,
    borderWidth: seen ? 3 : 3,
    borderColor: seen ? Colors.gray65 : Colors.bondiBlue,
    overflow: 'hidden',
    backgroundColor: Colors.black,
    padding: 1
  }),
  radius: {
    borderRadius: 100
  },
  empty: {
    height: 13
  },
  offerCountdown: {
    backgroundcolor: Colors.white,
    borderRadius: 100,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 5,
    paddingVertical: 5,
    alignItems: 'center',
    width: 70,
    marginTop: -20,
    marginBottom: 10
  },
  offerCountdownIcon: {
    marginRight: 5,
    resizeMode: 'contain'
  },
  offerCountdownDescription: width => ({
    color: Colors.white,
    fontSize: 14,
    lineHeight: 16.07,
    width: width / 4,
    textAlign: 'center'
  }),
  offerCountdownTime: {
    fontSize: 12
  },
  cardContainer: (theme) => ({
    marginHorizontal: 0,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 20 : 0,
    borderBottomRightRadius: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 20 : 0,
    zIndex: 5,
    elevation: 5,
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.backgroundColorTwo,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 0,
    flex: 8,
  }),
  cardTitle: {
    fontSize: 24,
    fontWeight: '700',
    marginVertical: 10,
    textAlign: 'left',
    alignSelf: 'flex-start',
  },
  quickLinksContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: 35,
    marginBottom: 50,
    flex: 3,
    flexWrap: 'wrap',
  },
  quickLinksIconContainer: {
    backgroundColor: Colors.whiteSmoke,
    borderRadius: 50,
    height: 70,
    width: 70,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    resizeMode: 'contain',
    overflow: 'scroll'
  },
  quickLinksDescription: {
    textAlign: 'center',
    lineHeight: 18,
    paddingTop: 10,
    fontSize: 15
  },
  buttonsContainer: theme => ({
    width: '100%',
    backgroundColor: theme.colors.backgroundColorTwo,
    // paddingBottom: 16,
    paddingHorizontal: 16,
    marginTop: 16
  }),
  guestButtonContainer: {
    width: '100%',
    alignItems: 'center',
    marginVertical: 16
  },
  guestButtonText: theme => ({
    color: theme.colors.textColor,
    fontSize: 16,
    lineHeight: 20
  }),
  singleLinkContainer: width => ({
    width: width > 700 ? ((width * 0.8) / 5) - 50 : 50,
    alignItems: 'center'
  }),
  singleLink: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  lottieContainer: theme => ({
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  lottie: width => ({
    width: width / 2,
    height: 'auto',
    marginLeft: 0,
    alignSelf: 'center',
  }),
  storiesContainer: {
    paddingTop: 10
  },
})
