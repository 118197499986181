export const actionTypes = {
  REQUEST_GENERATE_PIN: 'REQUEST_GENERATE_PIN',
  SUCCESS_GENERATE_PIN: 'SUCCESS_GENERATE_PIN',
  ERROR_GENERATE_PIN: 'ERROR_GENERATE_PIN',

  REQUEST_VALIDATE_PIN: 'REQUEST_VALIDATE_PIN',
  SUCCESS_VALIDATE_PIN: 'SUCCESS_VALIDATE_PIN',
  ERROR_VALIDATE_PIN: 'ERROR_VALIDATE_PIN',

  REQUEST_OFFER_DETAILS: 'REQUEST_OFFER_DETAILS',
  SUCCESS_OFFER_DETAILS: 'SUCCESS_OFFER_DETAILS',
  ERROR_OFFER_DETAILS: 'ERROR_OFFER_DETAILS',

  REQUEST_PREFERENCES: 'REQUEST_PREFERENCES',
  SUCCESS_PREFERENCES: 'SUCCESS_PREFERENCES',
  ERROR_PREFERENCES: 'ERROR_PREFERENCES',

  SUCCESS_PREFERENCES_PAYLOAD: 'SUCCESS_PREFERENCES_PAYLOAD',

  UPDATE_PREFERENCE: 'UPDATE_PREFERENCE',
  UPDATE_ALL_PREFERENCES: 'UPDATE_ALL_PREFERENCES',

  REQUEST_UPDATE_PREFERENCES: 'REQUEST_UPDATE_PREFERENCES',
  SUCCESS_UPDATE_PREFERENCES: 'SUCCESS_UPDATE_PREFERENCES',
  ERROR_UPDATE_PREFERENCES: 'ERROR_UPDATE_PREFERENCES',

  REQUEST_SEARCH_ACCOUNT: 'REQUEST_SEARCH_ACCOUNT',
  SUCCESS_SEARCH_ACCOUNT: 'SUCCESS_SEARCH_ACCOUNT',
  ERROR_SEARCH_ACCOUNT: 'ERROR_SEARCH_ACCOUNT',

  REQUEST_NOTIFY: 'REQUEST_NOTIFY',
  SUCCESS_NOTIFY: 'SUCCESS_NOTIFY',
  ERROR_NOTIFY: 'ERROR_NOTIFY',

  REQUEST_CREATE_ORDER: 'REQUEST_CREATE_ORDER',
  SUCCESS_CREATE_ORDER: 'SUCCESS_CREATE_ORDER',
  ERROR_CREATE_ORDER: 'ERROR_CREATE_ORDER'
}