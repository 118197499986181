import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'
import moment from 'moment'
import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import ReduxCache from '../../Utils/ReduxCache'

import { API_URLS } from '../Config'

const mapError = (error) => {
  const payload = error?.response?.data?.message || error?.message
  return payload
}


const orderResponseMapper = (response) => {
  const data = response?.data || []

  if (data.length === 0) {
    return data
  }

  const ordersArray = []

  data.map(order => {

    const { id } = order

    const items = []

    let total = 0
    let totalWithoutTax = 0
    let orderUnit

    let orderState = 'completed'


    order?.productOrderItem.map(orderItem => {
      const { state, itemTotalPrice, product, itemPrice } = orderItem
      const title = product?.name || ''

      if (state !== 'completed') {
        orderState = state
      }

      const orderIcon = product?.productCharacteristic?.find(el => el.name === 'SpecSubType')?.value


      const dutyFreePrice = itemTotalPrice?.[0]?.price?.dutyFreeAmount?.value || 0

      const taxIncludedPrice = itemTotalPrice?.[0].price?.taxIncludedAmount?.value || 0
      const unit = itemTotalPrice?.[0]?.price?.dutyFreeAmount?.unit
      orderUnit = itemTotalPrice?.[0]?.price?.dutyFreeAmount?.unit
      const taxRate = itemPrice?.[0]?.price?.taxRate

      total = total + parseInt(taxIncludedPrice, 10)
      totalWithoutTax = totalWithoutTax + parseInt(dutyFreePrice, 10)


      const date = order?.orderDate

      const startDate = moment(date).format('DD/MM/YYYY')

      const pricePlan = {
        dutyFreePrice: dutyFreePrice?.toString() || '0',
        taxRate: taxRate?.toString() || '0',
        totalAmount: taxIncludedPrice?.toString() || '0',
        unit: ContentManager.translate(unit)
      }

      const price = taxIncludedPrice.toString() + ' ' + ContentManager.translate(unit)

      items.push({ id: orderItem?.id, title, status: state, pricePlan, price, startDate, orderIcon })

    })

    const pricePlan = {
      'dutyFreePrice': totalWithoutTax?.toString() || '0',
      'total': total?.toString() || '0',
      'unit': ContentManager.translate(orderUnit) || ' Lekë'
    }

    ordersArray.push({
      id: id.substring(id.length - 7, id.length),
      pricePlan,
      items,
      state: orderState !== 'completed' ? 'inProgress' : orderState
    })

  })

  return ordersArray
}

async function getOrders(
  utilities = {},
  customHeaders = {}
) {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
  })

  const {
    errorMapper,
    onError,
    onStart,
    onSuccess,
  } = _utilities

  onStart()

  const params = {
    'relatedParty.id': ReduxCache.getMsisdn()
  }

  const headers = {
    ...customHeaders,
    'vf-project': 'dnext'
  }

  try {
    const response = await axios.get(API_URLS.fix.getOrders, {
      params,
      headers
    })
    const _response = orderResponseMapper(response)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}


export { getOrders }
