/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { View, Keyboard, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import QRCode from 'react-native-qrcode-svg'
import { VFText, Icon } from '@vfgroup-oneplatform/foundation/Components'

import VFButton from '../VFButton'
import MigrationLottie from '../MigrationLottie'
import VfText from '../VfText'
import Modal from '../Modal'

import { __translate } from '../../Utils'

import { Images, Colors } from '../../Themes'
import styles from './DynamicCoupon.Styles'

const STEP_OPTIONS = {
  input: {
    title: __translate('reward_loyalty_header_input'),
  },
  error: {
    title: __translate('reward_loyalty_header_error'),
  },
  loading: {
    title: __translate('reward_loyalty_header_loading'),
  },
  qrCode: {
    title: __translate('reward_loyalty_header_data'),
  },
}

function Input({
  value,
  setValue,
  onGenerateCoupon,
}) {

  const [isAmountError, setAmountError] = useState(false)

  useEffect(() => {
    if ((parseFloat(value, 10) % 10 !== 0) && !isNaN(parseFloat(value, 10))) {
      setAmountError(true)
    } else {
      setAmountError(false)
    }
  }, [value])

  return (
    <View
      style={styles.modal}
      onStartShouldSetResponder={() => true}
    >

      <TouchableOpacity
        style={styles.modalContentWraper}
        onPress={() => Keyboard.dismiss()}
        activeOpacity={1}
      >
        <>
          <VfText
            size="h4"
            align="left">{__translate('reward_loyalty_coupon_value_title')}</VfText>
          <View style={{ position: 'relative' }}>
            <VFInput
              label={__translate('reward_loyalty_coupon_value_label')}
              value={value}
              onChangeText={setValue}
              tintColor={Colors.turquoise}
              onFocus={() => setAmountError(false)}
              showHelperText={isAmountError}
              helperText={__translate('reward_loyalty_coupon_value_error')}
              helperTextColor={Colors.red}
              keyboardType="numeric"
            />
            <VfText
              style={{ position: 'absolute', right: 15, bottom: isAmountError ? 38 : 23 }}
            >{__translate('reward_loyalty_vcash')}</VfText>
          </View>
          <VfText
            align="left"
            size="h5"
            style={styles.medVerticalSpacing}>{__translate('reward_loyalty_coupon_helper_text')}</VfText>
        </>
      </TouchableOpacity>

      <VFButton
        type="primary"
        title={__translate('reward_loyalty_coupon_generate_button')}
        onPress={onGenerateCoupon}
        disabled={Boolean(parseFloat(value, 10) % 10 !== 0)}
      />
    </View>
  )
}
Input.propTypes = {
  value: PropTypes.number,
  setValue: PropTypes.func,
  onGenerateCoupon: PropTypes.func,
}

function QRCodeGenerated({
  isGet,
  onCancelCoupon,
}) {
  const couponData = useSelector(state => (isGet ? state.activeCoupon.data : state.dynamicCoupons.data))
  const parts = couponData?.parts?.productOffering?.[0]

  const price = parts?.priceFinalPrice?.value
  const currency = parts?.priceFinalPrice?.currencyID
  const partner = parts?.name
  const code = parts?.id?.[0]?.value
  const title = parts?.desc

  return (
    <View
      style={styles.modal}
      onStartShouldSetResponder={() => true}>
      <View style={styles.modalContentWraper}>
        <VfText
          size="h3"
          align="center"
        >{title}</VfText>
        <VfText
          align="center"
          size="h2"
          weight="bold"
        >{`${price} ${currency}`}</VfText>
        <VfText
          align="center"
          size="h6"
        >{partner}</VfText>
        <View style={styles.qrWrapper}>
          <QRCode size={150} value={code} />
        </View>
        <View
          style={styles.codeWrapper}
        >
          <VfText
            align="center"
            size="h3"
            weight="bold"
            selectable={true}
          >{code}</VfText>
        </View>
      </View>
      <VFButton
        type="primary"
        title={__translate('reward_loyalty_coupon_cancel_button')}
        onPress={() => onCancelCoupon()}
      />
    </View>
  )
}
QRCodeGenerated.propTypes = {
  onCancelCoupon: PropTypes.func,
  isGet: PropTypes.bool,
}

function Loading() {
  return (
    <View
      style={styles.modal}
      onStartShouldSetResponder={() => true}
    >
      <View style={styles.loaderWraper}>
        <MigrationLottie
          source={require('./loaderE60000.json')}
          style={styles.lottie}
        />
      </View>
    </View>
  )
}

function CouponError({
  setActiveScreen,
  error,
  closeModal,
  setValue,
}) {

  const couponError = error?.error.charAt(0) === '{' ? JSON.parse(error?.error) : {
    message1: __translate('reward_loyalty_general_error'),
    message2: error?.error,
  }
  const errorType = error?.type

  return (
    <View
      style={styles.modal}
      onStartShouldSetResponder={() => true}>
      <View style={styles.errorWrapper}>
        <Icon
          name={Images.warning}
          size={95}
          type="image"
          resizeMode="contain"
        />
        <VfText
          weight="bold"
          size="h3"
          align="center"
          style={styles.verticalSpacing}
        >{couponError?.message1}</VfText>
        <VfText
          align="center"
          style={styles.xlargeSpace}
        >{couponError?.message2}</VfText>
      </View>
      <VFButton
        type="primary"
        title={__translate('reward_loyalty_coupon_ok_button')}
        onPress={() => {
          if (errorType === 'generate') {
            setValue(null)
            setActiveScreen('input')
          } else {
            closeModal()
          }
        }}
      />
    </View>
  )
}
CouponError.propTypes = {
  setActiveScreen: PropTypes.func,
  closeModal: PropTypes.func,
  error: PropTypes.string,
  setValue: PropTypes.func,
}

const STEPS_COMPONENTS = {
  input: Input,
  qrCode: QRCodeGenerated,
  loading: Loading,
  error: CouponError,
}

function DynamicCoupon({
  activeScreen,
  setActiveScreen,
  isVisible,
  closeModal,
  value,
  setValue,
  onGenerateCoupon,
  onCancelCoupon,
  isGet,
  error,
}) {
  const GenerateStep = STEPS_COMPONENTS[activeScreen]

  return (
    <Modal
      visible={isVisible}
      close={reload => closeModal(reload)}
      avoidKeyboard
      header={<VfText weight="bold" color={Colors.font}>{STEP_OPTIONS[activeScreen].title}</VfText>}
    >
      <GenerateStep
        activeScreen={activeScreen}
        setActiveScreen={setActiveScreen}
        value={value}
        setValue={setValue}
        onGenerateCoupon={onGenerateCoupon}
        onCancelCoupon={onCancelCoupon}
        isGet={isGet}
        error={error}
        closeModal={closeModal}
      />
    </Modal>
  )
}
DynamicCoupon.propTypes = {
  activeScreen: PropTypes.string,
  setActiveScreen: PropTypes.func,
  isVisible: PropTypes.bool,
  closeModal: PropTypes.func,
  item: PropTypes.object,
  value: PropTypes.number,
  setValue: PropTypes.func,
  onGenerateCoupon: PropTypes.func,
  onCancelCoupon: PropTypes.func,
  isGet: PropTypes.bool,
  error: PropTypes.string,
}

export default DynamicCoupon
