import { StyleSheet } from 'react-native'

import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  emptyTitle: theme => ({
    ...Fonts.type.bold,
    fontSize: Fonts.size.h5,
    color: theme.colors.textColorThree,
    textAlign: 'center'
  }),
  emptySubtitle: theme => ({
    fontSize: Fonts.size.h6,
    color: theme.colors.textColorThree,
    textAlign: 'center',
    marginVertical: 15,
    fontFsmily: Fonts.type.VodafoneLt
  }),
  line: theme => ({
    backgroundColor: theme.colors.borderColorThree,
    height: 2,
    width: '100%',
    marginVertical: 25
  }),
  cardStyle: {
    height: 'auto',
    width: '100%',
    marginVertical: 10,
    paddingHorizontal: 16,
    paddingVertical: 20,
    cursor: 'pointer'
  },
  title: {
    ...Fonts.type.bold,
    fontSize: Fonts.size.h5,
  },
  subtitle: theme => ({
    fontSize: Fonts.size.regular,
    color: theme.colors.textColorThree,
    marginVertical: 15,
    fontFsmily: Fonts.type.VodafoneLt
  }),
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    marginLeft: 15,
    fontSize: Fonts.size.h6
  },
  rightIcon: {
    right: 16,
    position: 'absolute',
  },
  userTypeText: theme => ({
    fontSize: Fonts.size.regular,
    color: theme.colors.textColorThree,
    marginVertical: 5,
    fontFsmily: Fonts.type.VodafoneLt
  }),
  pageContainer: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    marginBottom: 25,
    marginTop: 15
  },
  pageText: theme => ({
    fontSize: 18,
    color: theme.colors.seeBillColor,
  }),
  pageButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 10,
  },
  scrollContainer: {
    marginBottom: 60,
  },
  contentContainer: {
    flexGrow: 1,
    marginBottom: 100
  }
})
