import React from 'react'
import { View } from 'react-native'

import { styles, shadowStyles } from './card.styles'
import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

const card = ({ style, withShadow = true, children, theme }) => {
  const _theme = useTheme()
  return <View style={[styles.card(theme || _theme), style, withShadow ? shadowStyles : '']}>{children}</View>
}

export default card
