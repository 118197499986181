import { StyleSheet, Platform } from 'react-native'

export default StyleSheet.create({
  containerStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: 0,
    paddingRight: 30,
    paddingLeft: 20,
    paddingTop: 20
  },
  textAndRadioContainer: {
    flexDirection: 'row',
    paddingBottom: 8,
    paddingRight: 2,
    paddingTop: 8
  },
  catName: {
    color: '#fff',
    fontSize: 13,
    marginTop: 3
  },
  radio: {
    paddingBottom: 10,
    paddingRight: 5
  },
  itemImage: {
    height: 60,
    width: 60,
    justifyContent: 'center'
  },
  background: {
    width: '100%',
    height: '100%'
  },
  grid: {
    paddingBottom: 50,
    marginBottom: 50
  },
  footer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 50,
    zIndex: 0,
    backgroundColor: 'transparent',
    borderTopColor: 'transparent'
  },
  submit: {
    backgroundColor: '#da0404',
    height: 50
  },
  skip: {
    backgroundColor: '#012333',
    height: 50
  },
  buttonText: {
    color: 'white',
    fontSize: 14,
    fontFamily: Platform.OS === 'ios' ? 'Vodafone Rg' : 'VodafoneRg'
  },
  thankYou: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(4,4,6,0.85)',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2
  },
  thankYouImage: {
    width: 60,
    height: 60
  },
  thankYouText: {
    color: '#fff',
    fontSize: 22
  },
  radioIos: {
    width: 16,
    height: 16,
    borderRadius: 16,
    borderWidth: 2,
    borderColor: '#fff',
    marginTop: 5,
    marginRight: 5
  },
  innerRadio: {
    backgroundColor: 'red',
    borderRadius: 10,
    flex: 1,
    margin: 2
  }
})
