import {actionTypes} from './CallMeBack.ActionTypes'

function callMeBackHistory(
    state = { loading: false, data: null },
    action = {},
) {
    switch (action.type) {
        case actionTypes.REQUEST_HISTORY:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.SUCCESS_HISTORY:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload,
            }
        case actionTypes.ERROR_HISTORY:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}

function callMeBack(
    state = { loading: false, data: null },
    action = {},
) {
    switch (action.type) {
        case actionTypes.REQUEST_CALLMEBACK:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.SUCCESS_CALLMEBACK:
            return {
                loading: false,
                error: null,
                data: action.payload,
            }
        case actionTypes.ERROR_CALLMEBACK:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}

export { callMeBackHistory, callMeBack }
