import { GetCatalogue } from '../../Services/MyOffers'

import { removeNullValues, isArray, isNonEmptyArray } from '../../Utils/JSON'
import { isURL, toLowerCase } from '../../Utils/Helpers'
import ReduxCache from '../../Utils/ReduxCache'


const actionTypes = {
  REQUEST_GET_CATALOGUE: 'REQUEST_GET_CATALOGUE',
  SUCCESS_GET_CATALOGUE: 'SUCCESS_GET_CATALOGUE',
  ERROR_GET_CATALOGUE: 'ERROR_GET_CATALOGUE',
}

const mapDigitalProductOffering = (categories, digitalProductOffering) => {
  if (isArray(digitalProductOffering)) {
    digitalProductOffering.forEach(({
      id,
      name,
      description: title,
      lifecycleStatus: status,
      digitalProductOfferingSpecification: {
        digitalCharacteristic = [],
      } = {},
      channel = [],
      attachment = [],
      category: categoryDetails = [],
      digitalTerm = [],
      digitalProductOfferingPrice = [],
      digitalProductOfferingRelationship = [],
    } = {}) => {
      // Product details
      const details = {
        id,
        name,
        title,
        status,
        prices: {},
        images: {},
        showMoreData: [],
        channel,
      }

      // Payment methods & long desc
      if (isArray(digitalCharacteristic)) {
        const characteristicNames = {
          payment_methods: 'paymentMethods',
          longdesc: 'description',
        }
        const characteristicsToReduce = ['longdesc']
        digitalCharacteristic.forEach(({
          name: digitalCharacteristicType,
          digitalCharacteristicValue = [],
        } = {}) => {
          digitalCharacteristicType = toLowerCase(digitalCharacteristicType)
          // Reduce the array of objects with value key to arrays of values (string)
          const digitalCharacteristicName = characteristicNames[digitalCharacteristicType] || digitalCharacteristicType
          let digitalCharacteristicValues = (details[digitalCharacteristicName] || [])
          if (isArray(digitalCharacteristicValue)) {
            digitalCharacteristicValue.forEach(({ value } = {}) => {
              digitalCharacteristicValues.push(value)
            })
          }
          if (characteristicsToReduce.includes(digitalCharacteristicType)) {
            digitalCharacteristicValues = digitalCharacteristicValues?.[0]
          }
          details[digitalCharacteristicName] = digitalCharacteristicValues
        })
      }

      // Convert payment methods to lowercase
      if (isArray(details.paymentMethods)) {
        details.paymentMethods = details.paymentMethods.map(method => toLowerCase(method))
      }

      // Images & Icons
      if (isArray(attachment)) {
        const attachmentNames = { icon_name: 'icon', image_details: 'detailsImage' }
        attachment.forEach(({ attachmentType, url } = {}) => {
          const attachmentName = attachmentNames[attachmentType] || attachmentType
          details[attachmentName] = url
        })
      }

      // Prices & period (discounted proce has priority over standard price)
      if (isArray(digitalProductOfferingPrice)) {
        digitalProductOfferingPrice.forEach(({
          name: duration,
          price: { value, unit } = {},
          priceType,
          tax = [],
        } = {}) => {
          priceType = toLowerCase(priceType)
          const taxDetails = {}
          if (isNonEmptyArray(tax)) {
            const {
              taxAmount: {
                value: taxAmount,
                unit: taxUnit,
              } = {},
              taxRate,
            } = (tax[0] || {})
            const totalPrice = Number(value) + Number(taxAmount)
            Object.assign(taxDetails, { taxAmount: String(taxAmount), taxUnit, taxRate: String(taxRate), totalPrice })
          }
          details.prices[priceType] = {
            priceType,
            duration,
            price: String(value),
            unit,
            ...taxDetails,
          }
        })
      }

      // Add final price to item details
      Object.assign(details, details.prices.final || {})

      // Buckets
      if (isArray(digitalProductOfferingRelationship)) {
        digitalProductOfferingRelationship.forEach(({
          digitalProductOffering: {
            id: digitalProductOfferingId,
            name: digitalProductOfferingName,
            description: digitalProductOfferingDescription,
            digitalProductOfferingSpecification: {
              digitalCharacteristic: digitalCharacteristics = [],
            } = {},
          } = {},
        } = {}) => {
          const characteristic = {}
          if (isArray(digitalCharacteristics)) {
            digitalCharacteristics.forEach(({ name: type, digitalCharacteristicValue = [] } = {}) => {
              if (isArray(digitalCharacteristicValue)) {
                digitalCharacteristicValue.forEach(({ value } = {}) => {
                  characteristic[type] = String(value)
                })
              }
            })
          }
          details.showMoreData.push({
            id: digitalProductOfferingId,
            icon: digitalProductOfferingId,
            label: digitalProductOfferingDescription,
            unit: '',
            ...characteristic,
          })
        })
      }


      // Validity
      if (isArray(digitalTerm)) {
        digitalTerm.forEach(({ duration: { amount, unit } = {} } = {}) => {
          details.showMoreData.push({
            id: `validity_${id}`,
            icon: 'validity',
            label: 'catalogue_details_label_validity',
            amount: String(amount),
            unit,
          })
        })
      }

      // Category & subcategory & filters (consider subcategory & filters the same)
      const filterCategories = ({ categoryType } = {}) => categoryType === 'category'
      const filterSubcategories = ({ categoryType } = {}) => categoryType === 'subcategory' || categoryType === 'filter'
      if (isArray(categoryDetails)) {

        // Add Specifications to item
        categoryDetails.forEach(({
          name: sepcifName,
          categoryType: specifType,
        }) => {
          specifType = toLowerCase(specifType)
          details[specifType] = sepcifName
        })

        // Add Categories if missing
        const productCategories = categoryDetails.filter(filterCategories)
        productCategories.forEach(({
          id: categoryId,
          name: categoryName,
        } = {}) => {
          if (!categories[categoryId]) {
            categories[categoryId] = {
              id: `category_${categoryId}`,
              name: categoryName,
              subcategories: {
                'catalogue_label_filters_all': {
                  id: 'subcategory_all',
                  name: 'catalogue_label_filters_all',
                  items: [],
                },
              },
              recommendations: {},
            }
          }
        })

        // Add subcategory in each category if missing & Add product in each subcategory
        const findItem = ({ id: itemId } = {}) => itemId === id
        categoryDetails.filter(filterSubcategories).forEach(({
          id: subcategoryId,
          name: subcategoryName,
        } = {}) => {
          productCategories.forEach(({ id: categoryId } = {}) => {
            if (!categories[categoryId].subcategories[subcategoryName]) {
              categories[categoryId].subcategories[subcategoryName] = {
                id: `subcategory_${subcategoryId}`,
                name: subcategoryName,
                items: [details],
              }
            } else {
              categories[categoryId].subcategories[subcategoryName].items.push(details)
            }
            if (!categories[categoryId].subcategories.catalogue_label_filters_all.items.find(findItem)) {
              categories[categoryId].subcategories.catalogue_label_filters_all.items.push(details)
            }
          })
        })
      }
    })
  }
}

const mapRecommendation = (categories, recommendations) => {
  if (isArray(recommendations)) {
    recommendations.forEach(({
      id,
      name,
      item: items = [],
    } = {}) => {

      const {
        category = [],
        channel = [],
        product = [],
        offering: {
          priority,
          productPrice = [],
          attachment = [],
          productTerm = [],
          productSpecification: {
            description: title = '',
            lifecycleStatus: status,
            productSpecCharacteristic = [],
          },
        } = {},
      } = (items?.[0] || {})

      // Product details
      const details = {
        id,
        name,
        title,
        priority,
        status,
        prices: {},
        images: {},
        showMoreData: [],
        channel,
        duration: '',
      }

      // Prices & period (discounted proce has priority over standard price)
      if (isArray(productPrice)) {
        productPrice.forEach(({
          price: { taxRate, taxIncludedAmount: { value, unit } = {} } = {},
          priceType,
        } = {}) => {
          priceType = toLowerCase(priceType)
          const totalPrice = Number(value) + Number(taxRate)
          taxRate = String(taxRate)
          details.prices[priceType] = {
            priceType,
            duration: '',
            price: String(value),
            unit,
            taxAmount: taxRate,
            taxUnit: unit,
            taxRate,
            totalPrice,
          }
        })
      }

      // Add final price to item details
      Object.assign(details, details.prices.final || {})

      // Payment methods & long desc, CTA, sections & other characteristics
      if (isArray(productSpecCharacteristic)) {
        const characteristicNames = { longdesc: 'description', payment_methods: 'paymentMethods', icon_name: 'icon', image_details: 'detailsImage' }
        productSpecCharacteristic.forEach(({
          name: productSpecCharacteristicType,
          productSpecCharacteristicValue = [],
        } = {}) => {
          productSpecCharacteristicType = toLowerCase(productSpecCharacteristicType)
          // Reduce the array of objects with value key to arrays of values (string)
          const productSpecCharacteristicName = characteristicNames[productSpecCharacteristicType] || productSpecCharacteristicType
          const productSpecCharacteristicValues = details[productSpecCharacteristicName] || []
          if (isArray(productSpecCharacteristicValue)) {
            productSpecCharacteristicValue.forEach(({ value } = {}) => {
              productSpecCharacteristicValues.push(value)
            })
          }
          details[productSpecCharacteristicName] = productSpecCharacteristicValues
        })
      }

      // Reduce characteristic arrays to strings
      const characteristicsToReduce = ['description', 'behavior', 'cta', 'ctaurl', 'icon', 'detailsImage', 'image']
      characteristicsToReduce.forEach(characteristicType => {
        if (isNonEmptyArray(details[characteristicType])) {
          details[characteristicType] = details[characteristicType][0]
        } else {
          delete details[characteristicType]
        }
      })

      // Image url
      if (isArray(attachment)) {
        const attachmentNames = { icon_name: 'icon', image_details: 'detailsImage' }
        attachment.forEach(({ id: attachmentType, url } = {}) => {
          attachmentType = toLowerCase(attachmentType)
          const attachmentName = attachmentNames[attachmentType] || attachmentType
          if (isURL(url)) {
            details[attachmentName] = attachmentName
            details.images[attachmentName] = { uri: url }
          } else {
            details[attachmentName] = url
          }
        })
      }

      // Convert payment methods to lowercase
      if (isArray(details.paymentMethods)) {
        details.paymentMethods = details.paymentMethods.map(method => toLowerCase(method))
      }
      // Convert sections to lowercase
      if (isArray(details.section)) {
        details.section = details.section.map(section => toLowerCase(section))
      }

      // Buckets
      if (Array.isArray(product)) {
        product.forEach(({ name: label, productCharacteristic = [] } = {}) => {
          const characteristic = {}
          if (isArray(productCharacteristic)) {
            productCharacteristic.forEach(({ name: type, value } = {}) => {
              characteristic[type] = String(value)
            })
          }

          details.showMoreData.push({
            id: characteristic.type,
            icon: characteristic.type,
            label,
            unit: '',
            ...characteristic,
          })
        })
      }

      // Validity
      if (isArray(productTerm)) {
        productTerm.forEach(({ duration: { amount, units } = {} } = {}) => {
          details.showMoreData.push({
            id: `validity_${id}`,
            icon: 'validity',
            label: 'catalogue_details_label_validity',
            amount: String(amount),
            unit: units,
          })
        })
      }

      // Category & subcategory & filters (consider subcategory & filters the same)
      const filterCategories = ({ '@referredType': categoryType } = {}) => categoryType === 'category'
      const filterSubcategories = ({ '@referredType': categoryType } = {}) => categoryType === 'subcategory' || categoryType === 'filter'
      if (isArray(category)) {

        // Add Specifications to item
        category.forEach(({
          name: sepcifName,
          '@referredType': specifType,
        }) => {
          specifType = toLowerCase(specifType)
          details[specifType] = sepcifName
        })

        // Add Categories if missing
        const productCategories = category.filter(filterCategories)
        productCategories.forEach(({
          id: categoryId,
          name: categoryName,
        } = {}) => {
          if (!categories[categoryId]) {
            categories[categoryId] = {
              id: `category_${categoryId}`,
              name: categoryName,
              subcategories: {
                'catalogue_label_filters_all': {
                  id: 'subcategory_all',
                  name: 'catalogue_label_filters_all',
                  items: [],
                },
              },
              recommendations: {},
            }
          }
        })

        // Add subcategory in each category if missing & Add product in each subcategory
        const findItem = ({ id: itemId } = {}) => itemId === id
        category.filter(filterSubcategories).forEach(({
          id: subcategoryId,
          name: subcategoryName,
        } = {}) => {
          if (isArray(details.section)) {
            if (details.section.includes('recommended')) {
              const recommendedItem = {
                ...details,
                label: 'catalogue_label_recommended',
              }
              productCategories.forEach(({ id: categoryId } = {}) => {
                if (!categories[categoryId].subcategories[subcategoryName]) {
                  categories[categoryId].subcategories[subcategoryName] = {
                    id: `subcategory_${subcategoryId}`,
                    name: subcategoryName,
                    items: [recommendedItem],
                  }
                } else {
                  categories[categoryId].subcategories[subcategoryName].items.unshift(recommendedItem)
                }
                if (!categories[categoryId].subcategories.catalogue_label_filters_all.items.find(findItem)) {
                  categories[categoryId].subcategories.catalogue_label_filters_all.items.unshift(recommendedItem)
                }
              })
            }

            if (details.section.includes('top')) {
              productCategories.forEach(({ id: categoryId } = {}) => {
                if (!categories[categoryId].recommendations[id]) {
                  categories[categoryId].recommendations[id] = {
                    id: `recommendation_${id}`,
                    ...details,
                  }
                }
              })
            }
          }

        })
      }
    })
  }
}

const mapObjectsToArray = ({ subcategories, recommendations, ...rest }) => ({
  ...rest,
  subcategories: Object.values(subcategories),
  recommendations: Object.values(recommendations),
})

const getCatalogueResponseMapper = response => {
  const res = removeNullValues(response?.data || {})
  const {
    digitalProductOffering = [],
    recommendation,
  } = res

  const categories = {}

  mapDigitalProductOffering(categories, digitalProductOffering)
  mapRecommendation(categories, recommendation)

  return Object.values(categories).map(mapObjectsToArray)
}

const errorMapper = error => error?.response?.data?.message || error?.message

const customHeaders = {
  'vf-source': 'selfcare',
  'vf-operator': 'myoffers',
  'vf-country-code': 'al',
}

const getCatalogue = (recommendationType, utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_GET_CATALOGUE,
    })

    const msisdn = ReduxCache.getMsisdn()

    GetCatalogue(
      recommendationType,
      msisdn,
      'subscriber',
      customHeaders,
      {
        ...utilities,
        responseMapper: getCatalogueResponseMapper,
        errorMapper: errorMapper,
      }
    ).then(response => dispatch({
      type: actionTypes.SUCCESS_GET_CATALOGUE,
      payload: response,
    })).catch(error => dispatch({
      type: actionTypes.ERROR_GET_CATALOGUE,
      payload: error,
    }))
  }
}


const actions = {
  getCatalogue,
}

export {
  actionTypes,
  actions,
}
