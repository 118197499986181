import AsyncStorage from '@react-native-async-storage/async-storage'

import { REHYDRATE } from 'redux-persist'

import { action_types } from './splash.actionTypes'

function splash(state = { loading: false, data: null, isCache: false }, action = {}) {
  switch (action.type) {
    case action_types.SUCCESS_SPLASH:
      const data = state.data
      const existing = data ? data[action.payload.language] : []
      return {
        ...state,
        loading: false,
        error: null,
        isCache: false,
        data: {
          ...state.data,
          active: action.payload.component,
          locale: action.payload.language,
          [action.payload.language]: {
            ...existing,
            ...action.payload.translations,
          },
        },
      }

    case REHYDRATE:
      if (
        typeof action.payload === 'undefined' ||
        typeof action.payload.splash === 'undefined' ||
        action.payload.splash.error
      ) {
        return state
      }

      return {
        ...state,
        loading: false,
        error: null,
        isCache: true,
        data: {
          ...action.payload.splash.data,
        },
      }

    case action_types.ERROR_SPLASH:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case action_types.REQUEST_SPLASH:
      return {
        ...state,
        loading: true,
      }

    case 'ACTIVE_COMPONENT':
      return {
        ...state,
        data: {
          ...state.data,
          active: action.payload,
        },
      }
    default:
      if (!state.data) {
        const splashContent = getData('reward_splash_content')
        if (splashContent) {
          const locale = splashContent.language

          if (locale && splashContent[locale]) {
            return {
              loading: false,
              data: {
                locale: locale,
                [locale]: splashContent[locale] || [],
              },
            }
          }
        }
      }
      return state
  }
}

function images(state = { loading: false, data: null }, action = {}) {
  switch (action.type) {
    case action_types.SUCCESS_SPLASH_IMAGES:
      return {
        ...state,
        locale: action.payload.language,
        data: {
          ...state.data,
          [action.payload.language]: action.payload.images,
        },
      }
    case action_types.ERROR_SPLASH:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

const getData = async key => {
  try {
    let data = await AsyncStorage.getItem(key)

    if (data == null) {
      return null
    }

    data = JSON.parse(data)
    return data
  } catch (e) {
    return null
  }
}

export { splash, images }
