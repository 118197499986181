import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { PaymentMethods } from '@vfgroup-oneplatform/framework/Payment/Containers'
import { VFScreen, VFText } from '@vfgroup-oneplatform/foundation/Components'
import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import crypto from 'isomorphic-webcrypto'
import { TextEncoder } from 'text-encoding'


import NavigationService from '../../Navigation/NavigationService'
import { CyberSource, VFPayments } from '../../Services'

import ReduxCache from '../../Utils/ReduxCache'

import { Images } from '../../Themes'


import styles from './PaymentMethods.Styles'

const getBrandById = (id) => {
  const types = {
    '001': 'Visa',
    '002': 'Mastercard',
    '003': 'American Express'
  }

  return types[id]
}

const getImageByCardId = (id) => {
  const types = {
    '001': Images.visa_icon,
    '002': Images.mastercard_icon,
    '003': Images.americanExpress_icon
  }

  return types[id]
}

const PaymentMethodsContainer = ({ route, navigation, ...props }) => {
  const [isLoading, setLoading] = useState(true)
  const [cards, setCards] = useState([])
  const [msisdn, setMsisdn] = useState()
  const [clientId, setClientId] = useState()
  const [defaultPayment, setDefaultPayment] = useState({})


  const theme = useTheme()

  useEffect(() => {
    setMsisdn(ReduxCache.getMsisdn())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (msisdn) {
      getCustomerId()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msisdn])

  useEffect(() => {
    if (clientId) {
      getPaymentInstruments()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  async function sha256(message) {
    const msgUint8 = new TextEncoder().encode(message)
    const hashBuffer = await crypto.subtle.digest({ name: 'SHA-256' }, msgUint8)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
    return hashHex.substring(0, 50)
  }

  const getPaymentInstruments = () => {
    setLoading(true)

    VFPayments.generateToken(msisdn, null, 'GET', { 'vf-operator': 'CustomerPaymentInstrument.getList' })
      .then(data => {
        var tokenized = data.id[0].value

        CyberSource.getPaymentInstruments(tokenized, clientId)
          .then(res => {

            const allCards = []

            if (res.count > 0) {
              res._embedded.paymentInstruments.map(card => {
                const cardDetail = {
                  id: card.id,
                  instrumentIdentifier: card.instrumentIdentifier.id,
                  token: card.id,
                  cardName: card.billTo?.firstName || 'credit_card',
                  nameOnCard: getBrandById(card.card.type),
                  lastFourDigits: card._embedded.instrumentIdentifier.card.number.slice(-4),
                  brand: getBrandById(card.card.type),
                  expiry: card.card.expirationMonth + '/' + card.card.expirationYear,
                  icon: getImageByCardId(card.card.type)
                }

                if (!card.default) {
                  allCards.push(cardDetail)
                }


                if (card.default) {
                  setDefaultPayment(cardDetail)
                }
              })
            }

            setCards(allCards)

            if (res.count === 0) {
              setDefaultPayment()
            }

            setLoading(false)

          })
          .catch(error => {
            console.error(error)
          })



      })
      .catch(error => {
        console.error(error)
      })
  }

  const getCustomerId = () => {
    VFPayments.getCustomerId(msisdn, { 'vf-operator': 'payments' })
      .then(data => {
        const externalId = data.details.externalId.value
        setClientId(externalId)
      })
      .catch(error => {
        console.error(error)
      })
  }

  const getPaymentMethods = (param) => {
  }

  const makePreferredPaymentCard = (cardId) => {
    updateCustomerDefaultCard(cardId)
  }

  const updateCustomerDefaultCard = async (cardId) => {

    const hasshedMsisdn = await sha256(msisdn)

    const body = {
      'buyerInformation': {
        'merchantCustomerID': clientId
      },
      'clientReferenceInformation': {
        'code': hasshedMsisdn
      },
      'defaultPaymentInstrument': {
        'id': cardId
      }
    }

    VFPayments.generateToken(msisdn, body, 'POST', { 'vf-operator': 'Customer.update' })
      .then(data => {
        var tokenized = data.id[0].value

        CyberSource.setDefaultCustomerCard(tokenized, clientId, body)
          .then(res => {
            getPaymentInstruments()
          })
          .catch(error => {
            console.error(error.message)
          })



      })
      .catch(error => {
        console.error(error.message)
      })
  }

  const onEditPaymentSuccess = () => {
    NavigationService.navigate('PaymentMethods')
    getPaymentInstruments()
  }

  const handleCardPress = (cardId) => {
    const card = cards.filter(item => item.id === cardId)

    let cardDetails = defaultPayment
    if (card.length > 0) {
      cardDetails = card[0]
    }

    NavigationService.navigate('EditPayment', {
      card: cardDetails,
      clientId,
      msisdn,
      onEditPaymentSuccess: onEditPaymentSuccess
    })
  }

  const onAddNewPaymentMethodPress = (cardId) => {
    const card = cards.filter(item => item.id == cardId)

    let cardDetails = defaultPayment
    if (card.length > 0) {
      cardDetails = card[0]
    }

    NavigationService.navigate('AddPayment', {
      card: cardDetails,
      clientId,
      msisdn,
      onEditPaymentSuccess: onEditPaymentSuccess
    })
  }


  const onClose = () => {
    const screen = route?.params?.screen
    if (screen === 'Billing') {
      NavigationService.navigate('MonthlyBilling')
    } else {
      NavigationService.navigate('Dashboard')
    }
  }

  const onBack = () => {
    const screen = route?.params?.screen
    if (screen === 'Billing') {
      NavigationService.navigate('MonthlyBilling')
    } else {
      NavigationService.navigate('Dashboard')
    }
  }

  const renderEmptyCardsContent = () => {
    return (
      <VFText
        i18nKey="payment_no_existing_methods_title"
        style={styles.noCardText(theme)}
      />
    )
  }

  return (
    <VFScreen title="payment_method_screen_title" onClose={onClose} onBack={onBack}>
      <PaymentMethods
        isLoading={isLoading}
        cards={cards}
        preferredCard={defaultPayment}
        onPressMakePreferred={makePreferredPaymentCard}
        onEditPaymentSuccess={getPaymentMethods}
        handleCardPress={handleCardPress}
        renderEmptyCardsContent={renderEmptyCardsContent}
        onAddNewPaymentMethodPress={onAddNewPaymentMethodPress}
      />
    </VFScreen>
  )

  // return (
  //   <PaymentMethodsScreen
  //     isLoading={isLoading}
  //     cards={cards}
  //     onClose={onClose}
  //     onBack={onBack}
  //     preferredCard={defaultPayment}
  //     onPressMakePreferred={makePreferredPaymentCard}
  //     onEditPaymentSuccess={getPaymentMethods}
  //     handleCardPress={handleCardPress}
  //     renderEmptyCardsContent={renderEmptyCardsContent}
  //     onAddNewPaymentMethodPress={onAddNewPaymentMethodPress}
  //   />
  // )
}

PaymentMethodsContainer.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
  Payment: PropTypes.object,
  makePreferredPaymentCard: PropTypes.func
}

export default PaymentMethodsContainer
