import axios from 'axios'

import CONFIG from '../../Config'
import {getErrorMessage} from '../../Utils'
import ReduxCache from '../../Utils/ReduxCache'

import {actionTypes} from './VfHearts.actionsTypes'

function getServiceBalance(isBackground = false) {
  return async dispatch => {
    if (!isBackground) {
      dispatch({
        type: actionTypes.REQUEST_GET_SERVICE_BALANCE,
      })
    }
    const config = {}
    const headers = await CONFIG.getServiceBalanceHeaders()
    config.headers = headers
    const url = await CONFIG.getUrl()
    axios
      .get(url.loveBoxServiceBalance, config)
      .then(result => {
        const items = result.data
        let item, data
        for (const i in items) {
          if (items[i].type.$ === 'lovebox') {
            item = items[i]
            break
          }
        }
        if (item === undefined) {
          const errorMessage = getErrorMessage()
          dispatch({
            type: actionTypes.ERROR_GET_SERVICE_BALANCE,
            payload: errorMessage,
          })
          return
        } else if (item.status.$ === 'inactive') {
          data = {
            isEligible: false,
            date: item['validity-period']['to-date'].date,
            dateFormat: item['validity-period']['to-date']['@format'],
            desc: item.desc,
          }
        } else {
          data = {
            key: item.categories[0]['@list-name'],
            id: item.categories[0]['@list-name'],
            isEligible: true,
            date: item['validity-period']['to-date'].date,
            dateFormat: item['validity-period']['to-date']['@format'],
            name: item.name,
            desc: item.desc,
            type: item.type.$,
            category: item.categories[0].$,
            status: item.status.$,
            amount: item.details.amount.$,
            currency: item.details.amount['@currency-id'],
            allowenceDesc:
              item.details.allowance['available-allowance']['@currency-id'],
            usedAllowenceValue: item.details.allowance['used-allowance'].$,
          }
        }

        if (data.status == 'presented'){
          dispatch(activateLoveBox(data))
        }

        dispatch({
          type: actionTypes.SUCCESS_GET_SERVICE_BALANCE,
          payload: data,
        })


      })
      .catch(event => {
        const errorMessage = getErrorMessage(event)
        dispatch({
          type: actionTypes.ERROR_GET_SERVICE_BALANCE,
          payload: errorMessage,
        })
      })
  }
}
function activateLoveBox(serviceBalanceDetails) {
  return async dispatch => {
    dispatch({
      type: actionTypes.REQUEST_LOVE_BOX_REDEEM,
    })
    const msisdn = ReduxCache.getMsisdn()
    const headers = await CONFIG.getLoveBoxRedeemHeaders()
    const data = {
      id: [
        {
          value: '',
          schemeID: null,
          schemeName: 'Payment Voucher ID',
          schemeAgencyName: 'Vodafone',
        },
      ],
      name: '',
      desc: '',
      type: 'mysterybox',
      category: [
        {
          value: 'token',
          listHierarchyId: 'bundle',
        },
      ],
      status: serviceBalanceDetails.status,
      version: null,
      created: {
        value: '2015-04-01T00:00:00',
        format: null,
      },
      createdBy: {
        value: 'vodafone',
        schemeID: null,
        schemeName: 'UserID',
        schemeAgencyName: 'Vodafone',
      },
      lastModified: {
        value: '2015-04-01T00:00:00',
        format: null,
      },
      lastModifiedBy: {
        value: 's.rogers',
        schemeID: null,
        schemeName: 'UserID',
        schemeAgencyName: 'Vodafone',
      },
      validityPeriod: {
        fromDate: {
          dateString: null,
          date: '2015-04-01',
        },
        toDate: {
          dateString: null,
          date: '2015-04-01',
        },
      },
      lastActivityDate: {
        value: '2015-04-01T00:00:00',
        format: null,
      },
      details: {
        serialNumberId: {
          value: 'Voucher Serial Number',
          schemeID: null,
          schemeName: '',
          schemeAgencyName: 'Vodafone',
        },
        voucherValue: null,
        reasonCode: null,
      },
      relatedPaymentVoucher: null,
      roles: {
        redeemer: {
          id: [
            {
              value: msisdn,
              schemeID: null,
              schemeName: 'Party Role id',
              schemeAgencyName: 'Vodafone',
            },
          ],
          name: null,
          desc: null,
          type: null,
          category: null,
          status: null,
          _links: null,
        },
        manager: null,
        agent: null,
      },
      relatedParty: null,
      parts: {
        channel: [
          {
            id: [
              {
                value: 'selfcare',
                schemeID: null,
                schemeName: 'Channel ID',
                schemeAgencyName: 'Vodafone',
              },
            ],
            name: 'MyVodafone',
            desc: 'MyVodafone',
            type: 'digital',
            category: [
              {
                value: 'token',
                listHierarchyId: 'mobile',
              },
            ],
            status: null,
            _links: null,
            version: null,
          },
        ],
        pin: {
          typeCode: 'token',
          value: '',
          status: 'Active',
          creationTime: {
            value: '2015-04-01T00:00:00',
            format: null,
          },
          validityPeriod: {
            fromDate: {
              dateString: null,
              date: '2015-04-01',
            },
            toDate: {
              dateString: null,
              date: '2015-04-01',
            },
          },
        },
        productOffering: [
          {
            id: [
              {
                value: serviceBalanceDetails.id,
                schemeID: null,
                schemeName: 'Product Offering ID',
                schemeAgencyName: 'Vodafone',
              },
            ],
            name: 'Bundle Name',
            desc: 'Bundle description',
            type: 'activate',
            category: [
              {
                value: '',
                listHierarchyId: '',
              },
            ],
            status: 'Active',
            _links: null,
            version: '13.0',
            createdBy: null,
            fromDate: {
              dateString: null,
              date: '2015-04-01',
            },
            toDate: {
              dateString: null,
              date: '',
            },
            priceChargeFrecuency: {
              value: '',
              format: null,
            },
            priceFinalPrice: {
              value: '',
              currencyID: null,
              currencyCodeListVersionID: null,
            },
            priceOriginalPrice: {
              value: '',
              currencyID: null,
              currencyCodeListVersionID: null,
            },
            priceText: '',
            priceType: '',
          },
        ],
        service: null,
        serviceSpecification: null,
        specification: null,
        marketingCampaign: null,
      },
    }
    const url = await CONFIG.getUrl()
    axios
      .post(url.redeemMagicEndpoint, data, {
        headers: headers,
      })
      .then(result => {
        const data = result.data
        dispatch({
          type: actionTypes.SUCCESS_LOVE_BOX_REDEEM,
          payload: data,
        })
      })
      .catch(event => {
        const errorMessage = getErrorMessage(event)
        dispatch({
          type: actionTypes.ERROR_LOVE_BOX_REDEEM,
          payload: errorMessage,
        })
      })
  }
}

function resetServiceBalance() {
  return dispatch => {
    dispatch({
      type: actionTypes.RESET_GET_SERVICE_BALANCE,
      payload: null,
    })
  }
}

function resetActivateLoveBox(refresh = false) {
  return dispatch => {
    if (refresh) {
      dispatch(getServiceBalance())
    }
    dispatch({
      type: actionTypes.RESET_LOVE_BOX_REDEEM,
      payload: null,
    })
  }
}

function activateInfo() {
  return dispatch => {
    dispatch({
      type: actionTypes.ACTIVATE_INFO,
      payload: null,
    })
  }
}

export const actions = {
  getServiceBalance,
  activateLoveBox,
  resetServiceBalance,
  resetActivateLoveBox,
  activateInfo,
}
