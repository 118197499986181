import { StyleSheet, Dimensions } from 'react-native'

import { Colors, Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

import colors from '../../Themes/Colors'

const { height: SCREEN_HEIGHT } = Dimensions.get('window')

const styles = StyleSheet.create({
  screenContainer: (theme) => ({
    backgroundColor: theme.colors.backgroundColor
  }),
  header: {
    borderBottomWidth: 0
  },
  backgroundContainer: {
    flex: 1,
  },
  flexContainer: (theme) => ({
    flex: 1,
    backgroundColor: theme.colors.backgroundColor
  }),
  mainImage: {
    width: '100%',
    height: '100%'
  },
  imageBackground: {
    marginTop: -70,
    margin: 20,
    padding: 30,
    flex: 1
  },
  qrHeader: {
    borderBottomWidth: 1,
    paddingBottom: 10,
    borderBottomColor: colors.aluminium
  },
  qrWrapper: {
    flex: 0,
    flexDirection: 'row'
  },
  scanIcon: (theme) => ({
    width: 32,
    height: 32,
    marginRight: 10,
    alignSelf: 'center',
    tintColor: theme.colors.sectionHeaderTextColor
  }),
  scanSubtitle: (theme) => ({
    color: theme.colors.textColor,
    marginBottom: 2
  }),
  scanTitle: (theme) => ({
    color: theme.colors.sectionHeaderTextColor,
    fontSize: 18,
    fontWeight: 'bold'
  }),
  scanDesc: (theme) => ({
    flexGrow: 1,
    marginTop: 10,
    color: theme.colors.sectionHeaderTextColor,
    fontSize: 17,
    lineHeight: 20,
  }),
  scanButton: {
    alignSelf: 'center',
    width: '100%'
  },
  errorContainer:{
    flex: 1,
    padding: 20
  },
  errorContentWraper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cameraContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  overlayMask: {
    position: 'absolute',
    backgroundColor: Colors.blackRGBA(0.75),
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  modal: (theme, insets) => ({
    marginBottom: 0,
    backgroundColor: theme.colors.backgroundColor,
    paddingBottom: insets.bottom
  }),
  borderRadius: (width, imageHeight, topVerticalOffset) => ({
    backgroundColor: 'transparent',
    width: width + 4,
    height: imageHeight + 4,
    position: 'absolute',
    top: topVerticalOffset - 2,
    borderWidth: 2,
    borderRadius: 10,
    borderColor: Colors.ocean,
    left: 78,
    overflow: 'hidden',
  }),
  headerContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 40,
    paddingHorizontal: 16,
    height: 60,
  },
  textContainer: {
    position: 'absolute',
    bottom: 120,
    padding: 20,
    width: '100%',
    alignItems: 'center',
    textAlign: 'center',
  },
  text: {
    color: Colors.white,
    textAlign: 'center',
    fontFamily: Fonts.type.VFFont,
    fontSize: Fonts.size.h6,
    marginTop: 10,
    lineHeight: 20,
  },
  emptyIcon: {
    width: 25,
    height: 25,
  },
  image: (width) => ({
    width: width,
    height: '100%',
  }),
  buttonsContainer: {
    paddingHorizontal: 16,
    marginBottom: 40,
  },
  button: {
    marginTop: 15,
  },
  errorTitle: {
    fontSize: 25,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 10
  },
  errorDescription: {
    fontSize: 17,
    textAlign: 'center',
    lineHeight: 20
  },
  tryButton: {
    marginTop: 20
  },
  webviewContainer: {
    height: SCREEN_HEIGHT * 0.65,
    padding: 10
  },
  webviewLoader: {
    width: '100%',
    height: '100%'
  }
})

export default styles
