import {
  GetCallMeBackHistory,
  SendCallMeBack,
} from '@vfal-lab/rn-vfg-call-me-back/Services'

import ReduxCache from '../../Utils/ReduxCache'

import {actionTypes} from './CallMeBack.ActionTypes'

const errorMapper = (error) => {
  const payload = error?.response?.data?.message || error?.message
  return payload
}

const getHistoryResponseMapper = (res) => {
  const response = res?.data
  const {
    parts = {},
  } = response?.find(item => item?.type === 'metadata')
  const history = response.filter((item) => item?.type === 'sms')
  const historyData = history?.map(({ roles: { receiver } = {}, created, validityPeriod }) => ({
    phoneNumber: receiver?.id?.[0]?.value,
    created,
    validityPeriod,
  }))

  const validityObject = parts?.specification?.characteristicValue
  const mapped = validityObject?.map((item) => ({
    [item.type]: {
      value: item?.value,
      description: item?.description,
    },
  }))

  const validityCallMeBack = Object.assign({}, ...mapped)

  return { validityObject: validityCallMeBack, historyData }
}

const sendCallMeBackResponseMapper = (res) => {
  const { desc = '' } = res?.data
  return { description: desc }
}

const callMeBackHeaders = {
  'x-source-operator': 'myvodafone',
  'x-source-system': 'selfcare',
  'content-type': 'application/json',
  'Content-Type': 'application/json',
  'accept-language': 'al',
  'x-application-version': '5.2.0',
  'x-application-os': 'Android',
  'x-source-countrycode': 'al',
}

const getCallMeBackHistory = (utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_HISTORY,
    })

    const msisdn = ReduxCache.getMsisdn()

    GetCallMeBackHistory(msisdn, callMeBackHeaders, {
      ...utilities,
      responseMapper: getHistoryResponseMapper,
      errorMapper: errorMapper,
    })
      .then(response => dispatch({
        type: actionTypes.SUCCESS_HISTORY,
        payload: response,
      }))
      .catch(error => dispatch({
        type: actionTypes.ERROR_HISTORY,
        payload: error,
      }))
  }
}

const sendCallMeBack = (receiver, utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_CALLMEBACK,
    })

    const msisdn = ReduxCache.getMsisdn()

    SendCallMeBack(receiver, msisdn, callMeBackHeaders, {
      ...utilities,
      responseMapper: sendCallMeBackResponseMapper,
      errorMapper: errorMapper,
    })
      .then(response => dispatch({
        type: actionTypes.SUCCESS_CALLMEBACK,
        payload: response,
      }))
      .catch(error => dispatch({
        type: actionTypes.ERROR_CALLMEBACK,
        payload: error,
      }))
  }
}


export {
  actionTypes,
  getCallMeBackHistory,
  sendCallMeBack,
}
