import { StyleSheet } from 'react-native'


const styles = StyleSheet.create({
    bar: (color, start, barWidth) => ({
        backgroundColor: color,
        width: start ? 1 : 0,
        height: '100%',
        transform: [
            {
                scaleX: barWidth
            }
        ]
    }),
    container: width => ({
        flexDirection: 'row',
        overflow: 'hidden',
        width: width,
    }),
    seen: width => ({
        backgroundColor: '#fff',
        width: width,
        height: '100%',
    })
})

export default styles
