import React from 'react'
import PropTypes from 'prop-types'
import AppSettings from '@vfgroup-oneplatform/framework/Settings/AppSettings'
import OptionItem from '@vfgroup-oneplatform/framework/Settings/components/OptionItem'
import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { Dimensions, Platform } from 'react-native'

import { getThemeImages } from '../../Group/Themes'

import NavigationService from '../../Navigation/NavigationService'


function Recommendations({ navigation: { pop }, ...rest }) {
    const theme = useTheme()
    const images = getThemeImages(theme.name)

    const recommendations = {
        title: 'settings_privacy_settings_title',
        description: 'settings_privacy_settings_subtittle',
        withCard: true,
        items: [
            {
                component: OptionItem,
                props: {
                    icon: 'ic_Users_Members_Customers',
                    title: 'privacy_policy_title',
                    description: 'privacy_policy_subtitle',
                    onPress: () => NavigationService.navigate('PrivacyPolicy')
                }
            },
            {
                component: OptionItem,
                props: {
                    icon: 'ic_data_sharing',
                    title: 'data_management_title',
                    description: 'data_management_subtitle',
                    onPress: () => NavigationService.navigate('DataManagment')
                }
            },
        ]
    }

    const onClose = () => pop()


    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
        return (
            <AppSettings
                title="recommendations_title"
                settings={[recommendations]}
                onClose={onClose}
                images={images}
                withTray
                {...rest}
            />
        )
    }

    return (
        <AppSettings
            title="recommendations_title"
            settings={[recommendations]}
            onClose={onClose}
            images={images}
            withTray
        />
    )
}
Recommendations.propTypes = {
    navigation: PropTypes.object,
    theme: PropTypes.object
}

export default Recommendations
