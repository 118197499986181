import { StyleSheet, Platform, Dimensions } from 'react-native'
import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

const screenWidth = Dimensions.get('window').width
let cardsStyles = {}
if (screenWidth >= 600) {
    cardsStyles = {
        sec: {
            maxHeight: Platform.OS === 'web' ? null : 350,
            marginRight: 16
        },
        third: {
            maxHeight: 160
        },
        fourth: {
            maxHeight: 160
        }
    }
}

const styles = StyleSheet.create({
    storiesContainer: {
        marginBottom: 12
    },
    discoverImageStyle: {
        width: 200,
        height: 200
    },
    dashboardFeatureWrapper: (theme) => ({
        backgroundColor: Dimensions.get('window').width > 700 ? theme.colors.backgroundColor : null,
        flex: 1,
        ...Platform.select({
            web: {
                // overflow: 'scroll',
                paddingHorizontal: screenWidth >= 600 ? 20 : 0,
            },
            default: {
            }
        }),
    }),
    logoutModalContainer: {
        flex: 1,
    },
    logoutConfirmTitle: {
        marginVertical: 25,
        fontFamily: Fonts.type.VodafoneLt,
        fontSize: Fonts.size.h4,
    },
    logoutConfirmButton: {
        marginTop: 15,
    },
    ...cardsStyles,
    container: {
        flex: 1,
    },
    subTrayImageProps: {
        width: '55%',
        resizeMode: 'contain',
        marginTop: -78,
    },
    trayTabStylesTitle: theme => ({
        minWidth: 100,
        color: theme.colors.textColorTwo,
    }),
    containerStyle: insets => ({
        top: insets.top,
        marginBottom: insets.top,
    }),
    descriptionContainerStyle: { marginBottom: -10 }
})

export { styles }
