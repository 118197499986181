/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react'
import { Dimensions, Platform, StatusBar } from 'react-native'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import { Colors, useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'
import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import { Renewals } from '@vfal-lab/rn-vfg-renewals-fix/Screens'

import { Images as images, getThemeImages } from '@vfal-lab/rn-vfg-renewals-fix/Themes'

import {
  getInternetOffers,
  selectBundle,
  getTVOffers,
  clearBundle,
  clearInternetOfferData,
  clearTVOfferData,
  selectTVOffer,
  selectTVAddOn,
} from '../FixBundles/FixBundles.Actions'

import {
  validateFixUser,
  getCustomer,
  postIndividualData,
  getOrderPrice,
  createOrder,
} from '../../Services'

import { personalNumberRegex, nameFormat } from '../../Utils/Regex'
import { Actions as LoginActions } from '../Login/Login.Actions'

import { isFixUser } from '../../Utils/Helpers'

import {
  yearsOptions,
  issuingCountryOptions,
  monthOptions,
} from './utils'


function RenewalsScreen({ navigation, route: { params: { isPurchase = true } } }) {

  const LOADING_OFFERS = [{}, {}, {}, {}]

  const theme = useTheme()
  const themeImages = getThemeImages(theme.name)

  const dispatch = useDispatch()
  const _getInternetOffers = async (id, utilities) => dispatch(getInternetOffers(id, utilities))
  const _getTVOffers = async (id, utilities) => dispatch(getTVOffers(id, utilities))

  const selectRenewalOffer = useCallback(async (bundle) => dispatch(selectBundle(bundle)), [])
  const selectTvOffer = useCallback(async (bundle) => dispatch(selectTVOffer(bundle)), [])
  const selectTvAddOn = useCallback(async (bundle) => dispatch(selectTVAddOn(bundle)), [])

  const clearRenewalOffer = useCallback(async () => dispatch(clearBundle()), [])
  const clearInternetOffersData = useCallback(async () => dispatch(clearInternetOfferData()), [])
  const clearTVOffersData = useCallback(async () => dispatch(clearTVOfferData()), [])

  const [isPurchaseID, setIsPurchaseID] = useState(isPurchase || true)

  //offers error
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const internetOffers = useSelector(state => state.internetOffers.data || [])
  const TVOffers = useSelector(state => state.tvOffers.data || [])

  const internetOffersLoading = useSelector(state => state.internetOffers.loading)
  const tvOffersLoading = useSelector(state => state.tvOffers.loading)

  const selectedOffer = useSelector(state => state.selectedOffer.data || {})

  const selectedCityId = useSelector(state => state?.geographicAddressValidation?.data?.submittedGeographicAddress.id || '')
  const selectedGeographicAddress = useSelector(state => state?.geographicAddressValidation?.data?.submittedGeographicAddress || {})
  const selectedStreet = useSelector(state => state?.geographicAddressValidation?.data?.street || '')

  const modemData = useSelector(state => state.selectedOffer?.data?.modemData || [])
  const installationFee = useSelector(state => state.selectedOffer?.data?.installationFee || {})
  const tvResourcesData = useSelector(state => state.selectedOffer?.data?.selectedTvOffer?.tvResources || [])


  /** Account info */
  const [idNumber, setIdNumber] = useState('')
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [issuingCountry, setIssuingCountry] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [imageFile, setImageFile] = useState(null)
  const [idType, setIdType] = useState()

  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null)
  const [selectedModem, setSelectedModem] = useState(null)
  const [selectedDate, setSelectedDate] = useState(moment().add(1, 'day').format('YYYY-MM-DD'))

  const [totalPrice, setTotalPrice] = useState({})
  const [totalPriceError, setTotalPriceError] = useState(true)
  const [totalPriceLoading, setTotalPriceLoading] = useState(false)

  //Camera
  const [frontIdPhoto, setFrontIdPhoto] = useState()
  const [frontIdPhotoBase64, setFrontIdPhotoBase64] = useState()
  const [backIdPhoto, setBackIdPhoto] = useState()
  const [backIdPhotoBase64, setBackIdPhotoBase64] = useState()
  const [selfie, setSelfie] = useState()
  const [selfieBase64, setSelfieBase64] = useState()

  /** Content manager initialization */
  const [isContentLoading, setContentLoading] = useState(false)
  const [isTvLoading, setIsTvLoading] = useState(false)
  const [tvOffersError, setTvOffersError] = useState(false)
  const [isVerifyLoading, setIsVerfiyLoading] = useState(false)
  const [postIndividualLoading, setPostIndividualLoading] = useState(false)

  const [isOrderLoading, setIsOrderLoading] = useState(false)
  const [orderError, setOrderError] = useState()

  const GetInternetOffers = () => {
    setContentLoading(true)
    _getInternetOffers(selectedCityId, {
      onSuccess: (res) => {
        const filteredData = res?.filter(el => el?.modemData?.length !== 0)
        if ((filteredData === null || filteredData?.length === 0)) {
          setError(true)
          setErrorMessage('renewals_no_offers_error')
        } else {
          //preselect the first offer
          selectRenewalOffer(res[0])
        }
        setContentLoading(false)
      },
      onError: (err) => {
        setError(true)
        setErrorMessage(err)
        setContentLoading(false)
      },
    })
  }

  const GetTVOffers = () => {
    setIsTvLoading(true)
    setTvOffersError(false)
    _getTVOffers(selectedCityId, {
      onSuccess: (res) => {
        setIsTvLoading(false)
        setTvOffersError(false)
      },
      onError: () => {
        setTvOffersError(true)
        setIsTvLoading(false)
      },
    })
  }

  const onOrderCreate = (callback) => {
    setIsOrderLoading(true)
    const data = {
      selectedOffer,
      selectedModem,
      selectedDate,
      isPurchase,
      selectedStreet,
      selectedCity: selectedGeographicAddress.city,
      selectedCountry: selectedGeographicAddress.country
    }

    createOrder(data, {}, {
      onSuccess: async () => {
        setIsOrderLoading(false)
        setOrderError(false)
        callback({ type: 'success' })
        if (isPurchase && !isFixUser()) {
          await LoginActions.refreshToken()
        }
      },
      onError: (err) => {
        setOrderError(true)
        setIsOrderLoading(false)
        callback({ type: 'error', message: err })
      }
    })
  }

  const changeStatusBar = (barStyle = 'dark-content') => {
    if (Platform.OS === 'android') {
      StatusBar.setBarStyle('light-content', true)
      StatusBar.setBackgroundColor(Colors.thinDark, true)
      StatusBar.setTranslucent(false)
    } else if (Platform.OS === 'ios') {
      StatusBar.setBarStyle(barStyle, true)
    }
  }

  const onClose = () => {
    changeStatusBar('light-content')
    clearInternetOffersData()
    clearTVOffersData()
    clearRenewalOffer()
    isPurchase ? navigation.pop() : navigation.pop(2)
  }

  const timeSlots = [
    {
      title: ContentManager.translate('morning'),
      desc: '9am-12pm',
    },
    {
      title: ContentManager.translate('midday'),
      desc: '12pm-3pm',
    },
    {
      title: ContentManager.translate('afterlunch'),
      desc: '3pm-8pm',
    },
  ]

  const modems = []

  const tvResources = []

  if (modemData.length !== 0) {
    modemData.map(item => {
      modems.push({
        image: images.modem,
        title: item.title,
        desc: item?.price === 0 ? 'renewals_installation_free_modem' : item?.price?.value?.toString() + ' ' + item?.price?.unit,
        capacity: 'renewals_installation_modem_capacity',
        note: 'renewals_installation_modem_note',
        price: item?.price?.value,
      })
    })
  }

  if (tvResourcesData.length !== 0) {
    tvResourcesData.map(item => {
      tvResources.push({
        ...item,
        title: item.title,
        desc: '',
      })
    })
  }

  const deliveryAddresses = [
    {
      title: isPurchase ? `${selectedStreet}, ${selectedGeographicAddress.city} ${selectedGeographicAddress.country}` : selectedOffer?.location || ' ',
      desc: isPurchase ? ContentManager.translate('renewals_summary_step_delivery_at', [timeSlots[selectedTimeSlot]?.title, selectedDate]) : '',
      icon: themeImages.deliveryAddress,
    },
  ]

  const verify = (callback, callBackError) => {
    if (isPurchase) {
      setIsVerfiyLoading(true)
      validateFixUser({}, {
        onSuccess: (res) => {
          setIsVerfiyLoading(false)
          setIsPurchaseID(res)
          callback()
        },
        onError: (err) => {
          setIsVerfiyLoading(false)
          callBackError(err?.message)
        },
      })
    }
  }

  const getCustomerValidation = (callback, callBackError) => {
    setIsVerfiyLoading(true)
    getCustomer(idNumber, {}, {
      onSuccess: () => {
        setIsVerfiyLoading(false)
        callback?.()
      },
      onError: (er) => {
        setIsVerfiyLoading(false)
        callBackError?.(er)
      }
    })
  }

  const patchId = async (callback, callBackError) => {
    setPostIndividualLoading(true)
    try {
      const endDateTime = `${year}-${month}-01T11:54:03.045Z`
      const data = {
        givenName: name,
        familyName: surname,
        identificationId: idNumber,
        issuingAuthority: issuingCountry,
        endDateTime,
        frontIdPhotoBase64,
        backIdPhotoBase64,
        selfieBase64,
        street: selectedStreet,
        city: selectedGeographicAddress.city,
        country: selectedGeographicAddress.country
      }
      postIndividualData(data, {}, {
        onSuccess: () => {
          callback()
          setPostIndividualLoading(false)
        },
        onError: (e) => {
          setPostIndividualLoading(false)
          callBackError(e)
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  const getTotalPrice = () => {
    const data = { selectedOffer, isPurchase }
    setTotalPriceLoading(true)
    getOrderPrice(data, {
      onSuccess: res => {
        setTotalPrice(res)
        setTotalPriceError(false)
        setTotalPriceLoading(false)
      },
      onError: () => {
        setTotalPriceError(true)
        setTotalPriceLoading(false)
      }
    })
  }

  // if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
  return (
    <Renewals
      error={error}
      errorMessage={errorMessage}
      onClose={onClose}
      isContentLoading={internetOffersLoading || isContentLoading}
      hasInstallation={isPurchase}
      GetInternetOffers={GetInternetOffers}
      GetTVOffers={GetTVOffers}
      isTvLoading={tvOffersLoading || isTvLoading}
      tvOffersError={tvOffersError}
      offers={internetOffersLoading ? LOADING_OFFERS : internetOffers}
      offersTV={tvOffersLoading ? LOADING_OFFERS : TVOffers}
      selectedOffer={selectedOffer}
      setSelectedOffer={selectRenewalOffer}
      selectTvOffer={selectTvOffer}
      selectTvAddOn={selectTvAddOn}
      //identification
      isVerifyLoading={isVerifyLoading}
      isPurchase={isPurchase}
      accountInfo={{
        id: idNumber,
        setIdNumber,
        idFormat: personalNumberRegex,
        name,
        setName,
        nameFormat: nameFormat,
        surname,
        setSurname,
        surnameFormat: nameFormat,
        issuingCountryOptions,
        issuingCountry,
        setIssuingCountry,
        issuingCountryFormat: nameFormat,
        monthOptions,
        month,
        setMonth,
        yearOptions: yearsOptions,
        year,
        setYear,
        imageFile,
        setImageFile,
        frontIdPhoto,
        setFrontIdPhoto,
        frontIdPhotoBase64,
        setFrontIdPhotoBase64,
        backIdPhoto,
        setBackIdPhoto,
        backIdPhotoBase64,
        setBackIdPhotoBase64,
        selfie,
        setSelfie,
        selfieBase64,
        setSelfieBase64,
        searchAccount: () => { },
        isNextDisabled: (isPurchase && isPurchaseID) || !isPurchase
          ? !idNumber
          : !idNumber
          || !name
          || !surname
          || !issuingCountry
          || !month
          || !year
          || postIndividualLoading,
        next: () => { },
        idType,
        setIdType,
      }}
      postIndividualLoading={postIndividualLoading}
      isPurchaseID={isPurchaseID}
      verify={verify}
      patchId={patchId}
      getCustomerValidation={getCustomerValidation}
      //installation
      timeSlots={timeSlots}
      modems={modems}
      tvResources={tvResources}
      installationFee={installationFee}
      selectedTimeSlot={selectedTimeSlot}
      setSelectedTimeSlot={setSelectedTimeSlot}
      selectedModem={selectedModem}
      setSelectedModem={setSelectedModem}
      deliveryAddresses={deliveryAddresses}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      //summary
      getTotalPrice={getTotalPrice}
      totalPrice={totalPrice}
      totalPriceError={totalPriceError}
      totalPriceLoading={totalPriceLoading}
      //confirm button
      onConfirm={onOrderCreate}
      orderError={orderError}
      isOrderLoading={isOrderLoading}
    />
  )
  // }

  // return (<Renewals
  //   error={error}
  //   errorMessage={errorMessage}
  //   onClose={onClose}
  //   isContentLoading={internetOffersLoading || isContentLoading}
  //   hasInstallation={isPurchase}
  //   GetInternetOffers={GetInternetOffers}
  //   GetTVOffers={GetTVOffers}
  //   isTvLoading={tvOffersLoading || isTvLoading}
  //   tvOffersError={tvOffersError}
  //   offers={internetOffersLoading ? LOADING_OFFERS : internetOffers}
  //   offersTV={tvOffersLoading ? LOADING_OFFERS : TVOffers}
  //   selectedOffer={selectedOffer}
  //   setSelectedOffer={selectRenewalOffer}
  //   selectTvOffer={selectTvOffer}
  //   selectTvAddOn={selectTvAddOn}
  //   //identification
  //   isVerifyLoading={isVerifyLoading}
  //   isPurchase={isPurchase}
  //   accountInfo={{
  //     id: idNumber,
  //     setIdNumber,
  //     idFormat: personalNumberRegex,
  //     name,
  //     setName,
  //     nameFormat: nameFormat,
  //     surname,
  //     setSurname,
  //     surnameFormat: nameFormat,
  //     issuingCountryOptions,
  //     issuingCountry,
  //     setIssuingCountry,
  //     issuingCountryFormat: nameFormat,
  //     monthOptions,
  //     month,
  //     setMonth,
  //     yearOptions: yearsOptions,
  //     year,
  //     setYear,
  //     imageFile,
  //     setImageFile,
  //     frontIdPhoto,
  //     setFrontIdPhoto,
  //     frontIdPhotoBase64,
  //     setFrontIdPhotoBase64,
  //     backIdPhoto,
  //     setBackIdPhoto,
  //     backIdPhotoBase64,
  //     setBackIdPhotoBase64,
  //     selfie,
  //     setSelfie,
  //     selfieBase64,
  //     setSelfieBase64,
  //     searchAccount: () => { },
  //     isNextDisabled: (isPurchase && isPurchaseID) || !isPurchase
  //       ? !idNumber
  //       : !idNumber
  //       || !name
  //       || !surname
  //       || !issuingCountry
  //       || !month
  //       || !year
  //       || postIndividualLoading,
  //     next: () => { },
  //     idType,
  //     setIdType,
  //   }}
  //   postIndividualLoading={postIndividualLoading}
  //   isPurchaseID={isPurchaseID}
  //   verify={verify}
  //   patchId={patchId}
  //   getCustomerValidation={getCustomerValidation}
  //   //installation
  //   timeSlots={timeSlots}
  //   modems={modems}
  //   tvResources={tvResources}
  //   installationFee={installationFee}
  //   selectedTimeSlot={selectedTimeSlot}
  //   setSelectedTimeSlot={setSelectedTimeSlot}
  //   selectedModem={selectedModem}
  //   setSelectedModem={setSelectedModem}
  //   deliveryAddresses={deliveryAddresses}
  //   selectedDate={selectedDate}
  //   setSelectedDate={setSelectedDate}
  //   //summary
  //   getTotalPrice={getTotalPrice}
  //   totalPrice={totalPrice}
  //   totalPriceError={totalPriceError}
  //   totalPriceLoading={totalPriceLoading}
  //   //confirm button
  //   onConfirm={onOrderCreate}
  //   orderError={orderError}
  //   isOrderLoading={isOrderLoading}
  // />)
}
RenewalsScreen.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
}

export default RenewalsScreen
