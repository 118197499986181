import React, { Component } from 'react'
import { View, TouchableOpacity } from 'react-native'
import { Text } from 'native-base'

import FastImage from 'react-native-fast-image'

import Error from '../Error'
import Loader from '../Loader'

import { __image, __translate } from '../../Utils'

import { Images } from '../../Themes'
import styles from './header.styles'

class Header extends Component {

  handleNavigation(param) {
    const { navigation } = this.props
    if (this.props.previous) {
      navigation.navigate(this.props.previous)
    } else {
      navigation.navigate('Profile', { param })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.user !== nextProps.user
  }

  render() {
    const { user, navigation } = this.props

    if (user.loading) {
      return (
        <Loader size={40} />
      )
    }

    if (user.error) {
      return (
        <View style={styles.profileContainer}>
          <Error message={user.error} retry={() => this.props.retry()} />
        </View>
      )
    }

    return (
      <View style={styles.profileContainer}>
        <View style={styles.headerItems}>
          <TouchableOpacity onPress={() => this.handleNavigation('vcoins')}>
            <View style={styles.profileItems}>
              {this.props.previous ?
                <>
                  <FastImage
                    source={Images.go}
                    style={{ ...styles.goIcon, transform: [{ rotate: '180deg' }] }}
                  />
                  <FastImage
                    source={{ uri: __image('reward_loyalty_available_coins_bt') }}
                    style={styles.walletImage}
                  />
                  <Text style={styles.points}> {user.data.coins} </Text>
                </>
                :
                <>
                  <FastImage
                    source={{ uri: __image('reward_loyalty_available_coins_bt') }}
                    style={styles.walletImage}
                  />
                  <Text style={styles.points}> {user.data.coins} </Text>
                  <FastImage
                    source={Images.go}
                    style={styles.goIcon}
                  />
                </>
              }
            </View>
          </TouchableOpacity>
          {user.data.lekeBalance && <TouchableOpacity onPress={() => this.handleNavigation('balance')} >
            <View style={styles.profileItems}>
              {this.props.previous ?
                <>
                  {/* <FastImage
                    source={Images.go}
                    style={{ ...styles.goIcon, transform: [{ rotate: '180deg' }] }}
                  />
                  <Text style={styles.points}> {user.data.coins} </Text> */}
                </>
                :
                <>
                  <Text style={styles.points}> {user.data.lekeBalance} </Text>
                  <FastImage
                    source={Images.go}
                    style={styles.goIcon}
                  />
                </>
              }
            </View>
          </TouchableOpacity>}
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {this.props.showDate ?
            <View style={{ alignItems: 'flex-end' }}>
              <View style={{ flexDirection: 'row', marginBottom: 5 }}>
                {
                  user.data.coins &&
                  <TouchableOpacity style={{ marginRight: 10 }} onPress={() => navigation.navigate('VfCoinsTransfer')}>
                    <FastImage
                      source={{ uri: __image('reward_share_confirmation_desc') }}
                      style={styles.infoIcon}
                      resizeMode={'contain'}
                    />
                  </TouchableOpacity>
                }
                <TouchableOpacity onPress={() => navigation.navigate('Info')}>
                  <FastImage
                    source={{ uri: __image('reward_loyalty_more_info_bt') }}
                    style={styles.infoIcon}
                  />
                </TouchableOpacity>
              </View>
              <Text style={{ fontSize: 14 }}>{__translate('reward_loyalty_registration_date')}</Text>
              <Text style={{ fontSize: 14 }}>{user.data.created}</Text>
            </View>
            : this.props.activeRoute === 'balance' ? <TouchableOpacity onPress={() => this.handleNavigation()} style={{ flexDirection: 'row', alignItems: 'center' }} >
              <FastImage
                source={Images.go}
                style={{ ...styles.goIcon, transform: [{ rotate: '180deg' }] }}
              />
              <Text style={styles.points}> {user.data.lekeBalance} </Text>
            </TouchableOpacity> : null
          }
        </View>
      </View>
    )
  }
}

export default Header
