import { store } from '../Redux/CreateStore'

const i18n = key => {
  try {
    const state = store.getState()
    const locale = state.splash.data.locale
    const active = state.splash.data.active
    const translations = state.splash.data[locale]

    return translations[active][key] || ''
  } catch (error) {
    return ''
  }
}

export default i18n
