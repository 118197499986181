import React from 'react'
import {View} from 'react-native'

import {styles} from './horizontalLine.styles'

const horizontalLine = () => {
  return <View style={styles.horizontalLine} />
}

export default horizontalLine
