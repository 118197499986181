import React, { Component } from 'react'
import { ImagePropTypes } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  find as _find,
  get as _get,
  isFunction as _isFunction
} from 'lodash'
import PropTypes from 'prop-types'
import { User_Types } from '@vfgroup-oneplatform/foundation/Utils/UserTypes.Enum'
// import { Usage } from 'rn-vfg-netperform'
import { TABS_LABELS } from '@vfgroup-oneplatform/framework/ProductsAndServices/Utils/Enums'

import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import { AddOnsDurationEnum } from '@vfgroup-oneplatform/framework/AddOns/Containers/Timeline/helpers'

import ProductAndServicesStyles from '@vfgroup-oneplatform/framework/ProductsAndServices/ProductsAndServices.Styles'

import { VFButton } from '@vfgroup-oneplatform/foundation/Components'

import { withTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { GetPEGA, ProductsAndServices as ProductsAndServicesService } from '../../Services'

import { getFixPlansData } from '../../Services/Fix'

import ReduxCache from '../../Utils/ReduxCache'

import Payments from '../Payments'
import GroupImages from '../../Group/Themes/Images'
import Refill from '../Refill'

import { formatNumber } from '../../Utils'

import { getThemeImages } from '../../Group/Themes'

import ProductsAndServices from './ProductsAndServicesNew'

import { Images } from '../../Themes'


const addOns = [
  {
    id: 1,
    name: '10GB Roaming',
    price: 12.11,
    icon: 'roaming',
    duration: 'week',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
    unit: '€',
    title: 'Keep in touch with your family even more easily'
  },
  {
    id: 2,
    name: '200GB Roaming',
    price: 12.11,
    icon: 'roaming',
    duration: 'month',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
    unit: '€',
    title: 'Keep in touch with your family even more easily'
  }
]

class ProductsAndServicesContainer extends Component {
  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
    this.usageRef = React.createRef()
    this.categories = [
      {
        category: 'netperform_usage_social_networks',
        appsPackageName: [
          'com.twitter.android',
          'com.snapchat.android',
          'com.whatsapp',
          'com.instagram.android',
          'com.facebook.katana'
        ]
      },
      {
        category: 'netperform_usage_streaming',
        appsPackageName: [
          'com.google.android.youtube',
          'com.netflix.mediaclient',
          'com.spotify.music',
          'com.anghami'
        ]
      }
    ]

    this.state = {
      forceOpenModal: false,
      isShowBannerEnabled: true,
      withPageBanner: false,
      isInlineContentEnabled: false,
      activeTab: { index: 0, key: '' },
      isNoBalanceHistory: false,
      isBalanceFailed: false,
      isBalanceLoading: true,
      isTopUpModalVisible: false,
      lastUpdateTime: null,
      isTimeLineRefreshed: true,
      isPageBannerVisible: true,
      isAutoTopUPBannerVisible: true,
      withAutoTopUpBanner: false,
      isAlbanianUser: false,
      isAutoTopUpModalVisible: false,
      isAutoTopUpSetUp: false,
      planData: [],
      balanceTopics: [],
      addons: [],
      isMyPlanLoading: true,
      isMyPlanError: false,
      customerBill: '',
      showPayments: false,
      showRefillButton: false,
      pegaButtons: false,
      pageBannerData: false,
      upgradePlanCTA: false,
      showRefill: false,
      showHyperLink: false,
      productsWithOffer: [],
      unit: '',
      errorMessage: '',
    }

    this.msisdn = ReduxCache.getMsisdn()
    this.userType = ReduxCache.getUserType()

    this.inlineContentData = _get(
      this.props,
      'productsAndServices.inlineContentData',
      {}
    )
    this.getInlineContentDataImages()

    this.setIsInLineContentEnabled()
  }

  componentDidMount() {
    const {
      topUp
    } = this.props

    this.setState({ isAutoTopUpSetUp: topUp?.isAutoTopUpSetUp, isAlbanianUser: true })

    ContentManager.configureI18nTranslations({ en: { my_plan_primary_card_consumption: '%1$s / %2$s' }, al: { my_plan_primary_card_consumption: '%1$s / %2$s' } })

    this.getMyPlanData()
    this.getPegaData()

    this.refreshBalanceTab()

    this.handleDeepLinking()

  }

  // onPrimaryButtonPress for Banner
  onPrimaryButtonPress = () => {
    this.setState({
      forceOpenModal: true
    })
  }

  // onSecondaryButtonPress for Banner
  onSecondaryButtonPress = hideBanner => {
    hideBanner()
  }

  onUpgradePress = () => {
    const { navigation } = this.props

    navigation.navigate('Purchase')
  }

  // set forceOpenModal model with isVisible from myPlan state
  onModalVisibilityChange = isVisible => {
    this.setState({
      forceOpenModal: isVisible
    })
  }

  onActiveTabChange = async (index, activeTab) => {
    this.setState({
      activeTab: { index, key: activeTab.key }
    })
  }

  onRefresh = () => {
    return this.refreshActiveTabData()
  }

  onBalanceTryAgainPress = () => {
    this.refreshBalanceTab()
  }

  getMyPlanState() {
    const planData = this.state.planData
    let found = false

    if (!this.state.showRefillButton) {
      return this.state.planData
    }

    const newData = planData.map((item, index) => {
      if (index == 0) {
        item.withUpgradePlanCTA = this.state.upgradePlanCTA ? true : false
      }
      if (found) {
        return item
      }

      const newUsage = item.usageData && item.usageData.map(usage => {
        if ((usage.key == 'data' || usage.key == 'internet') && !found) {
          found = true
          usage.shouldShowAddUsageButton = true
          usage.addUsageButtonTitle = this.state.showRefillButton.cta
          usage.onPress = () => this.setState({ showRefill: true })
        }

        return usage
      })

      item.usageData = newUsage
      return item

    })

    return newData
  }

  async getPegaData() {
    const response = await GetPEGA('renewal_fix')

    if (response.categories && response.categories.includes('DM')) {
      this.setState({ refillButton: true })
    }

    const buttons = {}
    for (const key in response.characteristics) {
      const data = response.characteristics[key]
      const details = response.characteristics[key].characteristics
      const agreements = response.characteristics[key]?.agreementIds || []

      this.setState({ productsWithOffer: agreements })

      if (details.Section?.includes('Top')) {
        // show red card
        this.setState({ withPageBanner: true })
      }

      if (details.Section?.includes('Upgrade')) {
        // show upgrade button at the first element
        this.setState({
          upgradePlanCTA: {
            cta: details.CTA[0],
            url: details.CTAUrl[0],
          }
        })
      }

      if (details.CTA && details.CTAUrl) {
        buttons[details.CTAUrl[0]] = {
          cta: details.CTA[0],
          url: details.CTAUrl[0],
        }

        if (details.CTAUrl[0] == 'refill') {
          this.setState({
            showRefillButton: {
              cta: details.CTA[0],
              url: details.CTAUrl[0],
            }
          })
        } else {
          this.setState({
            pageBannerData: {
              title: data.name,
              description: details.longDesc[0] != 'NO_TEXT' ? details.longDesc[0] : '',
              primaryButtonTitle: details.CTA[0],
              onPrimaryButtonPress: () => this.manageCTACardPress(details.CTAUrl[0]),
            }
          })
        }
      }
    }
  }

  async getMyPlanData() {

    this.setState({ isMyPlanLoading: true })

    getFixPlansData({}, {
      onSuccess: (response) => {
        this.setState({
          planData: response?.planData || [],
          isMyPlanLoading: false,
          customerBill: response?.customerBill,
          isMyPlanError: false,
          unit: response?.unit
        })
      },
      onError: (error) => {
        this.setState({
          isMyPlanLoading: false,
          isMyPlanError: true,
          errorMessage: error
        })
      }
    })
  }

  getParsedAsyncStorageValue = async key => {
    const asyncStorageValue = await AsyncStorage.getItem(key)
    return JSON.parse(asyncStorageValue)
  }

  getInlineContentDataImages = () => {
    this.addOnsImages = Images
    let { leftIcon, rightIcon } = this.inlineContentData
    leftIcon = this.addOnsImages[leftIcon]
    rightIcon = this.addOnsImages[rightIcon]

    this.inlineContentData = {
      ...this.inlineContentData,
      leftIcon,
      rightIcon
    }
  }

  manageUpgradeCTAPress = () => {
    //this.manageCTACardPress(this.state.upgradePlanCTA.url)
    this.manageCTACardPress('FixBundles')
  }

  manageCTACardPress(url) {
    const { navigation } = this.props

    switch (url) {
      case 'p2h':
        navigation.navigate('Migration', { type: 'p2h' })
        break
      case 'renewal':
        navigation.navigate('Migration', { type: 'renewal' })
        break
      case 'details':
      case 'myoffers':
        navigation.navigate('Catalogue')
        break
      case 'shake':
        navigation.navigate('Shake')
        break
      case 'flowerbox':
        navigation.navigate('VfHearts')
        break
      case 'ebill':
        navigation.navigate('Billing')
        break
      case 'gigafamily':
        navigation.navigate('GigaFamily')
        break
      case 'FixBundles':
        navigation.navigate('FixBundles')
        break
    }
  }

  refreshMyPlanTab = async () => {
    this.getMyPlanData()
  }
  refreshActiveTabData = async () => {
    const { getDeviceData, getMyAddonsData } = this.props
    const { activeTab } = this.state

    switch (activeTab.key) {
      case TABS_LABELS.MY_PLAN:
        return this.refreshMyPlanTab()
      case TABS_LABELS.BALANCE:
        return this.refreshBalanceTab()
      case TABS_LABELS.DEVICES:
        return getDeviceData()
      case TABS_LABELS.ADDONS:
        return getMyAddonsData()
      default:
        break
    }
  }

  setIsInLineContentEnabled = async () => {
    const isInlineContentEnabledParsed = await this.getParsedAsyncStorageValue(
      'isInlineContentEnabled'
    )
    this.setState({
      isInlineContentEnabled: isInlineContentEnabledParsed
    })
  }

  refreshBalanceTab = async () => {
    this.setState({ isBalanceLoading: true })

    const data = await ProductsAndServicesService.getTopics()

    this.setState({ isBalanceLoading: false, isTimeLineRefreshed: true, balanceTopics: data, lastUpdateTime: new Date() })
  }

  setIsPageBannerVisible = value => {
    this.setState({ isPageBannerVisible: value })
  }

  setIsAutoTopUpBannerVisible = value => {
    this.setState({ isAutoTopUPBannerVisible: value })
  }

  handleCloseButtonPress = () => {
    if (this.props.route?.params?.isDeepLink) {
      this.props.navigation.navigate('Dashboard')
    } else {
      this.props.navigation.pop()
    }
  }

  handleOnScroll = scrollY => {
    const handelScroll = _get(this.usageRef, 'current.handelScroll')
    if (_isFunction(handelScroll)) {
      handelScroll(scrollY)
    }
  }

  handleNavigateToAddonsShop = () => {
    this.props.navigation.navigate('Catalogue', { bundleCategory: 'add_on' })
  }

  handleUsagePress = (data) => {
    if (data.onPress) {
      data.onPress()
    } else {
      this.handleNavigateToAddonsShop()
    }
  }

  handleNavigateToCatalogue = (item) => {
    if (item.onPress) {
      item.onPress()
    } else {
      this.props.navigation.navigate('Catalogue')
    }

  }

  handlePressAddOnItem = item => {
    this.props.navigation.navigate('RemoveAddOn', { ...item })
  }

  handleInlineContentPress = addOnData => {
    this.props.navigation.navigate('BuyAddOn', {
      ...addOnData,
      isOpenedThrowInLineContent: true
    })
  }

  foundDailyAddon = _find(
    addOns,
    element => {
      return element.duration == AddOnsDurationEnum.DAY
    }
  )

  toggleModal = () => {
    this.setState({
      isTopUpModalVisible: !this.state.isTopUpModalVisible
    })
  }

  closeModal = () => {
    this.setState({
      isTopUpModalVisible: false
    })
  }

  toggleAutoTopUpQuickAction = isVisible => {
    const { isAutoTopUpSetUp } = this.props.topUp
    this.setState({
      isAutoTopUpModalVisible: isVisible || !this.state.isAutoTopUpModalVisible,
      isAutoTopUpSetUp
    })
  }

  closeAutoTopUpModalQuickAction = () => {
    this.setState({ isAutoTopUpModalVisible: false })
  }

  togglePayments = (value) => {
    this.setState({ showPayments: value })
  }

  toggleRefill = (value) => {
    this.setState({ showRefill: value })
  }

  setDefaultActiveTab = (value) => {
    this.setState({ defaultActiveTab: value })
  }

  handleDeepLinking = () => {
    const { route } = this.props
    if (route?.params?.isDeepLink) {
      if (route?.params?.type) {
        setTimeout(() => {
          if (route?.params?.type === 'paketa-shtese') {
            this.setDefaultActiveTab(2)
          } else if (route?.params?.type === 'aktiviteti') {
            this.setDefaultActiveTab(1)
          }
        }, 2000)
      }
    }
  }

  toggleHyperLinkModal = () => {
    this.setState({ showHyperLink: !this.state.showHyperLink })
  }

  renderUsageTabBody = () => {
    return (
      <></>
      // <Usage
      //   isActiveTab
      //   ref={this.usageRef}
      //   categories={this.categories}
      //   headerRef={this.headerRef}
      //   withTray
      // />
    )
  }

  renderSubHeaderRightComponent = () => {
    if (this.userType == 'prepaid') {
      return (
        <VFButton
          title={'product_and_services_top_up_button'}
          onPress={() => this.togglePayments(true)}
          style={ProductAndServicesStyles.subHeaderRightComponent}
          textStyle={ProductAndServicesStyles.subHeaderRightComponentStyle}
          type={'primary'}
        />
      )
    }

    return (
      <VFButton
        title={'product_and_services_ebill_button'}
        onPress={() => this.props.navigation.navigate('Billing')}
        style={ProductAndServicesStyles.subHeaderRightComponent}
        textStyle={ProductAndServicesStyles.subHeaderRightComponentStyle}
        type={'primary'}
      />
    )
  }

  render() {
    const {
      isInlineContentEnabled,
      isNoBalanceHistory,
      isBalanceFailed,
      isBalanceLoading,
      lastUpdateTime,
      customerBill,
      defaultActiveTab
    } = this.state
    const {
      theme,
      getMyAddonsData,
    } = this.props

    const userType = User_Types.PAY_M
    const {
      myAddonsError,
    } = _get(this.props, 'productsAndServices', {})
    const images = getThemeImages(theme.name)


    return (
      <>
        <ProductsAndServices
          onRefresh={this.onRefresh}
          lastUpdateTime={lastUpdateTime || new Date()}
          renderTopUp={() => null}
          myPlanProps={{
            isError: this.state.isMyPlanError,
            errorText: this.state.errorMessage || 'my_plan_screen_error_message',
            onTryAgainPress: () => this.getMyPlanData(),
            planData: this.getMyPlanState(),
            isMyPlanLoading: this.state.isMyPlanLoading,
            title: 'my_plan_primary_card_title',
            subtitle: 'my_plan_primary_card_subtitle',
            onPressUpgradePlan: this.manageUpgradeCTAPress,
            productsWithOffer: this.state.productsWithOffer
          }}
          defaultActiveTab={defaultActiveTab}
          onActiveTabChange={this.onActiveTabChange}
          addOnsProps={{
            // currentPlan: this.addOnsCurrentPlan,
            isError: myAddonsError?.isError,
            errorText: myAddonsError?.text,
            onTryAgainPress: getMyAddonsData,
            handleNavigateToAddonsShop: this.handleNavigateToAddonsShop,
            isLoading: this.state.isMyPlanLoading,
            data: this.state.addons,
            inlineContentData: this.inlineContentData,
            isInlineContentEnabled: isInlineContentEnabled,
            images: this.addOnsImages,
            addOnsListIcon: this.addOnsImages.icMenu,
            addOnsTimelineIcon: this.addOnsImages.icActivity,
            onItemPress: this.handlePressAddOnItem,
            onInlineContentPress: this.handleInlineContentPress,
            renewableAddonIconName: 'icRefresh',
            withTimeline: false
          }}
          balanceProps={{
            withCategories: true,
            images: images,
            isNoBalanceHistory: isNoBalanceHistory,
            isError: isBalanceFailed,
            isLoading: isBalanceLoading,
            onTryAgainPress: this.onBalanceTryAgainPress,
            balanceData: this.state.balanceTopics,
            // balanceData: balanceData,
            getBalanceData: () => { },
          }}
          vfScreenProps={{
            title: 'everything_is_ok_my_fix_title',
            withSubHeader: true,
            subHeaderTitle:
              this.userType == 'prepaid' ? 'product_and_services_sub_header_title_prepaid' : 'product_and_services_sub_header_title_postpaid',
            subHeaderSubTitle: customerBill ? (formatNumber(customerBill) + (this.state.unit || '')) : ' ',
            subHeaderAfterSubTitle: 'product_and_services_after_sub_header_title_postpaid',
            subHeaderDescription: ' ',
            subHeaderDescriptionStyle: null,
            onClose: this.handleCloseButtonPress,
            handleOnScroll: this.handleOnScroll,
            ref: this.headerRef,
            closeButtonTestID: 'UserDataClose',
            renderSubHeaderRightComponent: this.renderSubHeaderRightComponent,
            onSubHeaderDescriptionPress: this.toggleHyperLinkModal,
          }}
          headerDescription="Updated 2min ago"
          images={images}
          customTabs={[]}
          userType={userType}
          theme={theme}
          withPageBanner={this.state.withPageBanner}
          pageBannerProps={{
            ...this.state.pageBannerData,
            withSecondaryButton: false
          }}
        />

        {this.state.showPayments && <Payments setShowPayments={this.togglePayments} isVisible={this.state.showPayments} />}
        {this.state.showRefill && <Refill
          showPayments={() => {
            this.toggleRefill(false)
            this.togglePayments(true)
          }}
          setShowRefill={this.toggleRefill}
          balance={ReduxCache.getBalance()}
          isVisible={this.state.showRefill}
        />}

      </>
    )
  }
}

ProductsAndServicesContainer.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    pop: PropTypes.func
  }),
  Balance: PropTypes.object,
  Login: PropTypes.object,
  Payment: PropTypes.object,
  topUp: PropTypes.shape({
    isAutoTopUpSetUp: PropTypes.bool,
    autoTopUpData: PropTypes.object,
    isAutoTopUpActivated: PropTypes.bool
  }),
  actions: PropTypes.object,
  theme: PropTypes.shape({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  }),
  inlineContentData: PropTypes.object,
  userType: PropTypes.Stubs,
  screenProps: PropTypes.object,
  getBalanceData: PropTypes.func,
  balanceData: PropTypes.array,
  currentBalance: PropTypes.string,
  username: PropTypes.string,
  selectedPaymentCard: PropTypes.shape({
    brand: PropTypes.string,
    lastFourDigits: PropTypes.number,
    cardName: PropTypes.string,
    expiry: PropTypes.string,
    icon: ImagePropTypes
  }),
  paymentCards: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string,
      lastFourDigits: PropTypes.number,
      cardName: PropTypes.string,
      expiry: PropTypes.string,
      icon: ImagePropTypes
    })
  ),
  wheelValues: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      gift: PropTypes.bool
    })
  ),
  offerValues: PropTypes.shape({
    giftUnit: PropTypes.string,
    giftValue: PropTypes.number,
    giftNormalPrice: PropTypes.number,
    giftOfferPrice: PropTypes.number,
    currency: PropTypes.string
  }),
  defaultWheelValue: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      gift: PropTypes.bool
    })
  ),
  handlePressConfirmSelection: PropTypes.func,
  handlePressAddExtraOffer: PropTypes.func,
  handleSelectPaymentCard: PropTypes.func,
  handlePressSaveCard: PropTypes.func,
  onCloseModalIconPress: PropTypes.func,
  getPaymentMethodData: PropTypes.func,
  getDeviceData: PropTypes.func,
  productsAndServices: PropTypes.object,
  clearDeviceData: PropTypes.func,
  getMyAddonsData: PropTypes.func,
  clearAddonsData: PropTypes.func,

  route: PropTypes.object,
}

export default withTheme(ProductsAndServicesContainer)
