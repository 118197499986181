import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

const data = () => ({
  introSections: [
    {
      title: ContentManager.translate('privacy_permissions_intro_title'),
      content: {
        paragraphs: [
          ContentManager.translate('privacy_permissions_intro_content_p1'),
          ContentManager.translate('privacy_permissions_intro_content_p2')
        ]
      },
      moreContent: {
        paragraphs: [
          ContentManager.translate('privacy_permissions_intro_more_content_p1'),
          ContentManager.translate('privacy_permissions_intro_more_content_p2'),
          ContentManager.translate('privacy_permissions_intro_more_content_p3'),
          ContentManager.translate('privacy_permissions_intro_more_content_p4'),
        ]
      },
      actions: {
        items: [
          {
            title: ContentManager.translate('privacy_permissions_intro_action_item1')
          },
          {
            title: ContentManager.translate('privacy_permissions_intro_action_item2')
          },
          {
            title: ContentManager.translate('privacy_permissions_intro_action_item3')
          }
        ]
      }
    }
  ],
  privacySettingsSections: [
    {
      title: ContentManager.translate('privacy_permissions_section_item1_title'),
      content: {
        paragraphs: [
          ContentManager.translate('privacy_permissions_section_item1_content1'),
          ContentManager.translate('privacy_permissions_section_item1_content2')
        ]
      }
    },
    {
      title: ContentManager.translate('privacy_permissions_section_item2_title'),
      content: {
        paragraphs: [
          ContentManager.translate('privacy_permissions_section_item2_content1'),
          ContentManager.translate('privacy_permissions_section_item2_content2'),
        ]
      },
      moreContent: {
        paragraphs: [
          ContentManager.translate('privacy_permissions_section_item2_more_content_para1'),
          ContentManager.translate('privacy_permissions_section_item2_more_content_para2'),
        ],
        bullets: [
          ContentManager.translate('privacy_permissions_section_item2_more_content_bullet1'),
          ContentManager.translate('privacy_permissions_section_item2_more_content_bullet2'),
          ContentManager.translate('privacy_permissions_section_item2_more_content_bullet3'),
        ],
        info: [
          [
            ContentManager.translate('privacy_permissions_section_item2_more_content_info_what'),
            ContentManager.translate('privacy_permissions_section_item2_more_content_info_what_desc')
          ],
          [
            ContentManager.translate('privacy_permissions_section_item2_more_content_info_why'),
            ContentManager.translate('privacy_permissions_section_item2_more_content_info_why_desc')
          ]
        ]
      },
      actions: {
        items: []
      }
    },
    {
      title: ContentManager.translate('privacy_permissions_section_item3_title'),
      content: {
        paragraphs: [
          ContentManager.translate('privacy_permissions_section_item3_content')
        ]
      },
      moreContent: {
        info: [
          [
            ContentManager.translate('privacy_permissions_section_item3_more_content_info_what'),
            ContentManager.translate('privacy_permissions_section_item3_more_content_info_what_desc')
          ],
          [
            ContentManager.translate('privacy_permissions_section_item3_more_content_info_why'),
            ContentManager.translate('privacy_permissions_section_item3_more_content_info_why_desc')
          ]
        ]
      },
      actions: {
        items: []
      }
    }
  ]
})

export default data
