
import { WebView } from 'react-native-webview'

// import RNFetchBlob from 'rn-fetch-blob'

import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import { MonthlyBilling } from '@vfal-lab/rn-vfg-fix-billing/Screens'

import { FilterBar, VFText } from '@vfgroup-oneplatform/foundation/Components'

import Images from '@vfal-lab/rn-vfg-fix-billing/Themes/Images'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import PaymentWrapper from '@vfgroup-oneplatform/framework/Payment/PaymentWrapper'
import { PaymentTypes } from '@vfgroup-oneplatform/framework/Payment'

import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import { View, Platform, Dimensions } from 'react-native'

import { Billing as BillingService, CyberSource, VFPayments } from '../../Services'

import ReduxCache from '../../Utils/ReduxCache'

import { isAdminView, isBusinessChooser } from '../../Utils'

import { Styles } from '../Payments/Payments.Styles'

import SubscriptionList from './SubscriptionList'
// import SohoBillPaymentModal from './SohoBillPaymentModal'

import styles from './MonthlyBilling.Styles'

import {
  PaymentScreenTypes,
  sha256,
  tokenTypes,
  tokenizeCard,
  getBrandById,
  getIdByBrand,
  getImageByCardId,
  getCardType,
} from './Helpers'

function MonthlyBillingHocExample({ navigation, route }) {

  const scrollRef = useRef()

  const [paymentLoader, setPaymentLoader] = useState(true)
  const [dowloadLoader, setDowloadLoader] = useState(false)
  const [subscriptionsLoader, setSubscriptionsLoader] = useState(true)
  // eslint-disable-next-line no-unused-vars
  const [headlineLoader, setHeadlineLoader] = useState(true)
  const [bill, setBill] = useState({})

  const [showPayment, setShowPayment] = useState(false)
  const [cards, setCards] = useState([])
  const [defaultPayment, setDefaultPayment] = useState({})
  const [clientId, setClientId] = useState()

  const msisdn = ReduxCache.getMsisdn()

  const businessAccount = ReduxCache.getBusinessAccount()
  const isBusinessUser = businessAccount?.sohoUserType === 'business_user'

  const theme = useTheme()

  const [categories, setCategories] = useState()
  const [selectedCategories, setSelectedCategories] = useState('')

  const [inputOffset, setInputOffset] = useState(0)

  const [showUnableToPayModal, setShowUnableToPayModal] = useState(false)

  const billParam = route?.params?.bill || {}
  const showPaymentModal = route.params.showPaymentModal

  const defaultError = 'payment_quick_action_error'
  const [errorMessage, setErrorMessage] = useState(defaultError)
  const [loading, setLoading] = useState(false)

  const [payerAuthenticationDetails, setPayerAuthenticationDetails] = useState({})
  // const [payerAuthenticationResponse, setPayerAuthenticationResponse] = useState(false)
  const [payment3DSData, setPayment3DSData] = useState(false)
  const [tokenizedCard, setTokenizedCard] = useState(false)


  const [paymentOptions, setPaymentOptions] = useState({ isCardListOpend: true })
  const [onErrorPress, setOnErrorPress] = useState()
  const [isStorageGranted, setisStorageGranted] = useState(true)
  const currency = 'ALL'
  const isThirdParty = false

  useEffect(() => {
    if (cards.length > 0) {
      getPayerAuthenticationToken()
    }
  }, [cards])

  useEffect(() => {

    if (!msisdn) {
      return
    }

    getBillData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msisdn])

  useEffect(() => {
    if (showPaymentModal) {
      setShowPayment(true)
      getPaymentInstruments(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPaymentModal])

  const getBillData = () => {
    const billId = billParam.id
    setPaymentLoader(true)
    setSubscriptionsLoader(true)
    setHeadlineLoader(true)
    setBill({})
    BillingService.getBillBreakdown(billId, isAdminView(), msisdn, {
      onSuccess: res => {
        setBill(res)
        setPaymentLoader(false)
        setSubscriptionsLoader(false)
        setHeadlineLoader(false)
        setCategories(res?.categories || [])
        setSelectedCategories(res?.categories?.[0]?.id)
      }
    })
  }

  const toggleModal = () => {
    setShowPayment(false)
  }

  const onBack = () => navigation.goBack()

  const navigateToBilling = () => navigation.navigate('Billing', { reset: true })


  const getCustomerId = () => {
    setLoading(true)

    VFPayments.getCustomerId(msisdn, { 'vf-operator': 'payments' })
      .then(data => {
        const externalId = data.details.externalId.value
        setClientId(externalId)
        if (clientId) {
          setPaymentOptions({
            screen: PaymentScreenTypes.DEFAULT
          })
        }
      })
      .catch(error => {

        setErrorMessage(defaultError)
        if (error?.data?.reasonCode === 400) {
          createCustomerCyberSource()
        } else {
          setPaymentOptions({
            screen: PaymentScreenTypes.ERROR
          })
          setOnErrorPress(() => () => getCustomerId())
        }
      })
  }

  const createCustomerCyberSource = async () => {
    const hashedMsisdn = await sha256(msisdn)
    const body = {
      'buyerInformation': {
        'merchantCustomerID': hashedMsisdn
      },
      'clientReferenceInformation': {
        'code': hashedMsisdn
      }
    }

    try {
      const data = await VFPayments.generateToken(msisdn, body, 'POST', { 'vf-operator': 'Customer.create' })
      var tokenized = data.id[0].value

      const cyberSourceCustomer = await CyberSource.createCustomer(tokenized, body)
      setClientId(cyberSourceCustomer.id)

      saveCustomerId(cyberSourceCustomer.id)

    } catch (error) {
      setPaymentOptions({
        screen: PaymentScreenTypes.ERROR
      })

      setErrorMessage(defaultError)

      setOnErrorPress(() => () => createCustomerCyberSource())
    }

  }

  const saveCustomerId = async (id) => {
    try {
      await VFPayments.saveCustomerIdToVF(msisdn, id, { 'vf-operator': 'payments' })

    } catch (error) {
      setPaymentOptions({
        screen: PaymentScreenTypes.ERROR
      })

      setErrorMessage(defaultError)
      setOnErrorPress(() => () => saveCustomerId(id))
    }
  }


  const createCustomerPaymentInstrument = async (card, instrumentIdentifier) => {

    const body = {
      'card': {
        'expirationMonth': card.expirationMonth,
        'expirationYear': card.expirationYear,
        'type': card.type
      },
      'instrumentIdentifier': {
        'id': instrumentIdentifier
      },
      'billTo': {
        'firstName': card.cardName
      }
    }

    try {
      const data = await VFPayments.generateToken(msisdn, body, 'POST', { 'vf-operator': 'CustomerPaymentInstrument.create' })
      var tokenized = data.id[0].value

      const customerPaymentInstrument = await CyberSource.createCustomerPaymentInstrument(tokenized, clientId, body)
      return customerPaymentInstrument

    } catch (error) {
      console.log(error)
      return false
    }
  }

  // param here when in billing is returned from manage it should update cards and set the default one
  const getPaymentInstruments = (shouldSetDefault = false) => {
    setPaymentOptions({
      screen: PaymentScreenTypes.PAYMENTREQUEST
    })
    VFPayments.generateToken(msisdn, null, 'GET', { 'vf-operator': 'CustomerPaymentInstrument.getList' })
      .then(data => {
        var tokenized = data.id[0].value

        CyberSource.getPaymentInstruments(tokenized, clientId)
          .then(res => {

            const allCards = []

            if (res.count > 0) {
              res._embedded.paymentInstruments.map(card => {
                const cardDetail = {
                  id: card.id,
                  instrumentIdentifier: card.instrumentIdentifier.id,
                  token: card.id,
                  cardName: card.billTo?.firstName || 'credit_card',
                  nameOnCard: 'credit_card',
                  lastFourDigits: card._embedded.instrumentIdentifier.card.number.slice(-4),
                  brand: getBrandById(card.card.type),
                  expiry: card.card.expirationMonth + '/' + card.card.expirationYear,
                  icon: getImageByCardId(card.card.type),
                  tokenType: tokenTypes.CARDID
                }

                allCards.push(cardDetail)

                if (card.default && (!defaultPayment.id || shouldSetDefault)) {
                  cardDetail.tokenType = tokenTypes.DEFAULT
                  setDefaultPayment(cardDetail)
                }
              })

            }

            setCards(allCards)

            if (allCards.length > 0) {
              setPaymentOptions({
                ...paymentOptions,
                isCardListOpend: false
              })
            } else {
              setDefaultPayment({})
            }

            setPaymentOptions({
              screen: PaymentScreenTypes.DEFAULT
            })

            setLoading(false)


          })
          .catch(error => {
            setLoading(false)
            setErrorMessage(defaultError)
            setOnErrorPress(() => () => getPaymentInstruments())
          })

      })
      .catch(error => {
        setLoading(false)
        setErrorMessage(defaultError)
        setOnErrorPress(() => () => getPaymentInstruments())
      })
  }

  const getPayerAuthenticationToken = async () => {
    const hashedMsisdn = await sha256(msisdn)
    const body = {
      'clientReferenceInformation': {
        'code': hashedMsisdn
      },
      'paymentInformation': {
        'customer': {
          'customerId': tokenizedCard ? tokenizedCard : clientId
        },
        'card': {
          'expirationMonth': defaultPayment.expiry.split('/')[0],
          'expirationYear': defaultPayment.expiry.split('/')[1]
        }
      }
    }

    VFPayments.generateToken(msisdn, body, 'POST', { 'vf-operator': 'PA.DeviceData' })
      .then(data => {
        const payerDetails = {}

        data.parts.specification.characteristicsValue.map(item => {
          payerDetails[item.characteristicName] = item.value
        })

        setPayerAuthenticationDetails(payerDetails)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleSelectPaymentCard = (card) => {
    setDefaultPayment(card)
  }

  const onPressConfirmSelection = async (selectedItem, prevId = false) => {
    const amount = selectedItem.selectedValue.value

    setPaymentOptions({
      screen: PaymentScreenTypes.PAYMENTREQUEST
    })

    const paymentDetails = {
      instrumentIdentifier: defaultPayment.instrumentIdentifier,
      token: defaultPayment.token || '',
      authorizedAmount: amount,
      currency: currency,
      customer: clientId,
      tokenType: defaultPayment.tokenType || tokenTypes.DEFAULT,
      isThirdParty: isThirdParty,
      expiry: defaultPayment.expiry,
      billId: bill.id,
      sessionIdentifier: payerAuthenticationDetails.referenceId,
      id: prevId,
    }

    try {
      const response = await VFPayments.createBillingPayment(msisdn, bill.accountId, paymentDetails, { 'vf-operator': 'payments', 'vf-source': 'myvodafone' })

      if (response.status === 'AUTHORIZED') {
        setPaymentOptions({
          screen: PaymentScreenTypes.PAYMENTSUCCESSFULLY
        })

        getBillData()

        setErrorMessage(defaultError)

        return {
          balance: amount
        }
      }

      return await new Promise((resolve, reject) => {
        const onSuccess = async (id) => {
          try {
            await onPressConfirmSelection(selectedItem, id)
            setPayment3DSData(null)
            resolve({
              balance: amount
            })
          } catch (error) {
            reject(error)
          }
        }

        setPayment3DSData({
          paymentSessionRequest: response.paymentMethod.paymentSessionRequest,
          terminationURL: response.paymentMethod.terminationURL,
          id: response.id,
          onSuccess: onSuccess,
          onError: reject
        })
      })

    } catch (error) {
      setOnErrorPress(() => async () => {
        await onPressConfirmSelection(selectedItem)
      })

      throw new Error(error?.message || error?.data?.message)
    }

  }

  const onPressSaveCard = async (card) => {

    try {

      setPaymentOptions({ screen: PaymentScreenTypes.ADD_PAYMENT_CARD_LOADING })

      const tokenBody = {
        'encryptionType': 'RsaOaep',
        'targetOrigin': 'https://www.vodafone.al'
      }

      const data = await VFPayments.generateToken(msisdn, tokenBody, 'POST', { 'vf-operator': 'Flex.GenerateKey' })

      const specifications = data?.parts?.specification?.characteristicsValue

      const specificationsData = {}
      specifications.forEach(item => {
        specificationsData[item.characteristicName] = item.value
      })

      const jwt = {
        'kid': specificationsData.jwkKeyId,
        'n': specificationsData.jwkMod,
        'e': specificationsData.jwkExp,
        'use': 'enc',
        'kty': 'RSA'
      }


      const tokenized = await tokenizeCard(card.cardNo, jwt)
      const cardBrand = getCardType(card.cardNo)
      const cardTypeID = getIdByBrand(cardBrand)

      const body = {
        'cardInfo': {
          'cardNumber': tokenized,
          'expirationMonth': card.expirationData.split('/')[0],
          'expirationYear': '20' + card.expirationData.split('/')[1],
          'cardType': cardTypeID
        },
        'keyId': specificationsData.jwkKeyId
      }

      const instrument = await CyberSource.createTokenizesCard(body)


      const instrumentIdentifier = instrument?._embedded?.icsReply?.instrumentIdentifier
      const instrumentId = instrumentIdentifier.id

      const cardDetail = {
        id: instrumentId,
        instrumentIdentifier: instrumentId,
        token: instrumentId,
        cardName: card.cardName,
        nameOnCard: '',
        lastFourDigits: instrument.maskedPan.split('XXXXXX')[1],
        brand: cardBrand,
        expiry: body.cardInfo.expirationMonth + '/' + body.cardInfo.expirationYear,
        icon: getImageByCardId(cardTypeID)
      }

      let cyberSourceCardObject = {}
      if (card.saveForLater && instrumentId) {
        cyberSourceCardObject = {
          number: card.cardNo,
          expirationMonth: card.expirationData.split('/')[0],
          expirationYear: '20' + card.expirationData.split('/')[1],
          securityCode: card.ccvNo,
          nameOnCard: card.nameOnCard,
          cardName: card.cardName,
          type: cardTypeID
        }

        cardDetail.tokenType = tokenTypes.CARDID
      } else {
        cardDetail.tokenType = tokenTypes.TOKENID
        setTokenizedCard(instrumentId)
      }


      if (card.saveForLater && instrumentId) {
        const customerPaymentInstrument = await createCustomerPaymentInstrument(cyberSourceCardObject, instrumentId, clientId)
        cardDetail.id = customerPaymentInstrument.id
        cardDetail.token = customerPaymentInstrument.id
        getPaymentInstruments()
      } else {
        setPaymentOptions({ screen: PaymentScreenTypes.DEFAULT })
      }

      setDefaultPayment(cardDetail)
      setCards([...cards, cardDetail])

    } catch (error) {
      setPaymentOptions({
        screen: PaymentScreenTypes.ERROR
      })

      setErrorMessage(defaultError)

      setOnErrorPress(() => () => setPaymentOptions({ screen: PaymentScreenTypes.ADD_PAYMENT_CARD }))
    }

  }

  useEffect(() => {
    setPaymentOptions({
      screen: PaymentScreenTypes.ADD_PAYMENT_CARD_LOADING
    })
  }, [])


  useEffect(() => {
    if (!clientId) {
      return
    }
    getPaymentInstruments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])


  const retry = () => {
    setPaymentOptions({
      screen: PaymentScreenTypes.ADD_PAYMENT_CARD_LOADING
    })
    onErrorPress()
  }

  const onPressManageCard = () => {
    setShowPayment(false)
    navigation.navigate('PaymentMethods', { screen: 'Billing' })
  }

  const onClose = () => {
    navigation.pop(2)
  }



  const downloadBill = async () => {
    setDowloadLoader(true)
    const billId = bill.id
    BillingService.GetBillPDF(billId, {
      onSuccess: (response) => {
        const { content, attachmentType, id } = response

        new Blob([content], { type: attachmentType }).text().then(base64Text => {
          const linkSource = `data:application/pdf;base64,${base64Text}`
          const downloadLink = document.createElement('a')
          const fileName = `${id}.pdf`
          downloadLink.href = linkSource
          downloadLink.download = fileName
          if (downloadLink.download !== '') {
            downloadLink.click()
          }
        })
        setDowloadLoader(false)
      },
      onError: () => setDowloadLoader(false)
    })
  }

  const onFilterItemPress = (cat) => {
    setSelectedCategories(cat)
  }

  // const currentDate = new Date()
  // const isCurrentMonth = bill.monthNumber == (currentDate.getMonth() + 1) && bill.year == currentDate.getFullYear()

  const iframeContent = `
      <form id="collectionForm" name="devicedata" method="POST" action="${payerAuthenticationDetails.deviceDataCollectionUrl}">
            <input type="text" name="JWT" value="${payerAuthenticationDetails.accessToken}"/>
        </form>
        <script>
        window.onload = function() {
          document.getElementById("collectionForm").submit();
        };
      </script>
  `

  const javascript = `
    window.addEventListener("message", (event) => {
      window.ReactNativeWebView.postMessage(JSON.stringify(event));
      if(event.origin === 'https://centinelapistag.cardinalcommerce.com'){
        let data = JSON.parse(event.data);
      }

      if(data !== undefined && data.Status){
        console.log('Songbird ran DF successfully');
      }
    }, false);

    true;
  `

  const popUpFrameContent = `
    <form height="250" width="400" id="stepUpForm" name="stepUp" method="POST" action="${payment3DSData?.terminationURL}">
          <input type="hidden" name="JWT" value="${payment3DSData?.paymentSessionRequest}"/>
          <input type="hidden" name="MD" value=""/>
      </form>
      <script>
      window.onload = function() {
        document.getElementById("stepUpForm").submit();
      };
    </script>
  `

  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {

    return (
      <>
        <PaymentWrapper
          // username={'Syrja'}
          isLoading={loading}
          defaultPayment={defaultPayment}
          paymentCards={cards}
          toggleModal={toggleModal}
          closeModal={toggleModal}
          isVisible={showPayment}
          onPressManageCard={onPressManageCard}
          // onPressAddData={onPressAddData}
          // onPressRejectData={onPressRejectData}
          singleValue
          value={{ value: bill.priceNumber, currency: currency, paidValue: 0 }}
          onSelectDefaultCard={handleSelectPaymentCard}
          // wheelValues={wheelValues}
          // offerValues={offerValues}
          //   defaultWheelValue={defaultWheelValue}
          onClose={toggleModal}
          type={PaymentTypes.BILLING_PAYMENT}
          onPressConfirmSelection={onPressConfirmSelection}
          QATitle={'payment_quick_action_title'}
          QASubtitle={'payment_quick_action_subtitle'}
          billPaymentSuccessPlaceholders={[bill.formattedMonth]}
          onErrorPress={retry}
          paymentButtonError="full_error_button_text"
          billButtonError="full_error_button_text"
          topUpTitleError="top_up_quick_action_error"
          topUpErrorMessage="top_up_quick_action_error_message"
          paymentTitleError={errorMessage}
          paymentErrorMessage={errorMessage !== 'payment_quick_action_error' ? ' ' : 'payment_quick_action_error_message'}
          currency={ContentManager.translate(currency)}
          onPressSaveCard={onPressSaveCard}
          billInfoText={bill?.timePeriod}
          hiddenFields={['nameOnCard']}
          isBtnSuccessDisabled={!defaultPayment.id}
          isCenterModal
        >
          {payment3DSData &&
            <View style={Styles.iframePopup}>
              <WebView
                url={payment3DSData.terminationURL}
                // statusBarColor={statusBarColor}
                source={{ html: popUpFrameContent }}
                injectedJavaScript={javascript}
                onMessage={(event) => {
                  setTimeout(() => {
                    payment3DSData.onSuccess(payment3DSData.id)
                    setPayment3DSData(false)
                  }, 1000)
                  // setPayerAuthenticationResponse(JSON.parse(event.nativeEvent.data))
                }}
                javaScriptEnabled={true}
                onClose={() => setPayment3DSData(false)}
              />
            </View>
          }
        </PaymentWrapper>

        <MonthlyBilling
          scrollRef={scrollRef}
          vfScreen={{
            title: 'billing_details_title',
            titlePlaceholders: [bill.formattedMonth],
            onClose: onClose,
            onBack: onBack,
            showBack: true,
          }}
          header={{
            title: bill.timePeriod ? bill?.timePeriod : ' ',
            subTitle: bill.price ? bill.price : ' ',
            customSubHeaderTitle: 'billing_details_header_title'
          }}
          payment={{
            isLoading: paymentLoader,
            logo: Images.greenTick,
            codeValue: bill.partyAccount,
            billNumberValue: bill.id,
            paymentMethod: bill.payment?.id ? bill.paymentMethod.details.brand + ' ' + bill.paymentMethod.details.lastFourDigit : null,
            status: bill?.status ? bill?.status : 'new',
            onPaymentPress: () => {
              if (isBusinessUser || (isBusinessChooser() && !businessAccount?.showBusinessView)) {
                setShowUnableToPayModal(true)
              } else {
                setShowPayment(true)
                getCustomerId()
              }
            },
            paymentDatePlaceholder: [bill?.paymentDate?.format('DD') + ' ' + bill?.formatedPaymentDate],
            toBePaidPlaceholders: [bill.date, '', bill.month],
            price: bill?.price,
            withSecondaryButton: true,
            secondaryButtonTitle: isStorageGranted ? 'billing_download_bill' : 'billing_storage_permission_denied',
            onSecondaryButtonPress: downloadBill,
            isSecondaryButtonDisabled: dowloadLoader,
            isSecondaryButtonLoading: dowloadLoader
          }}
          subscriptions={{
            data: bill.subscription || [],
            isLoading: subscriptionsLoader
          }}
          renderSectionAfterPaymentInfo={() => {
            if (bill?.categories?.length <= 1 || !isAdminView() || subscriptionsLoader) {
              return null
            } else {
              return (
                <View style={styles.filterContainer}>
                  <FilterBar
                    filterData={categories}
                    setSelectedCategories={(cat) => onFilterItemPress(cat)}
                    selectedCategories={selectedCategories}
                    isLoading={false}
                    theme={theme}
                    withSeeAll={false}
                  />
                </View>
              )
            }
          }}
          renderSubscriptions={(subscriptions) => {
            const data = isAdminView() ? (
              subscriptions?.isLoading ? [{}, {}] : subscriptions?.data?.filter(el => el?.role === selectedCategories)
            ) : (subscriptions?.isLoading ? [{}] : subscriptions?.data)
            return (
              <View onLayout={ev => setInputOffset(ev?.nativeEvent?.layout?.y)}>
                <VFText i18nKey="billing_payment_breakdown_title" style={styles.breakdownTitle} />
                {data?.length !== 0 && <SubscriptionList
                  data={data}
                  isLoading={subscriptions?.isLoading}
                  scrollRef={scrollRef}
                  offset={inputOffset}
                  unfilteredData={data || []}
                  isAdminView={isAdminView()}
                  selectedCategories={selectedCategories}
                />}
              </View>
            )
          }}
        />
        {
          payerAuthenticationDetails.accessToken &&
          <View style={Styles.iframeDataCollection}>
            <WebView
              javaScriptEnabled={true}
              onMessage={(event) => {
                console.log('event.nativeEvent payerAuthentication', event.nativeEvent)
                // setPayerAuthenticationResponse(JSON.parse(event.nativeEvent.data))
              }}
              injectedJavaScript={javascript}
              // injectedJavaScriptBeforeContentLoaded={runBeforeFirst}
              source={{ html: iframeContent }} />
          </View>
        }
        {/* <SohoBillPaymentModal
          isVisible={showUnableToPayModal}
          toggleModal={() => setShowUnableToPayModal(prev => !prev)}
          isAdmin={isBusinessChooser()}
          navigateToBilling={navigateToBilling}
        /> */}
      </>
    )
  }

  return (
    <>
      <PaymentWrapper
        // username={'Syrja'}
        isLoading={loading}
        defaultPayment={defaultPayment}
        paymentCards={cards}
        toggleModal={toggleModal}
        closeModal={toggleModal}
        isVisible={showPayment}
        onPressManageCard={onPressManageCard}
        // onPressAddData={onPressAddData}
        // onPressRejectData={onPressRejectData}
        singleValue
        value={{ value: bill.priceNumber, currency: currency, paidValue: 0 }}
        onSelectDefaultCard={handleSelectPaymentCard}
        // wheelValues={wheelValues}
        // offerValues={offerValues}
        //   defaultWheelValue={defaultWheelValue}
        onClose={toggleModal}
        type={PaymentTypes.BILLING_PAYMENT}
        onPressConfirmSelection={onPressConfirmSelection}
        QATitle={'payment_quick_action_title'}
        QASubtitle={'payment_quick_action_subtitle'}
        billPaymentSuccessPlaceholders={[bill.formattedMonth]}
        onErrorPress={retry}
        paymentButtonError="full_error_button_text"
        billButtonError="full_error_button_text"
        topUpTitleError="top_up_quick_action_error"
        topUpErrorMessage="top_up_quick_action_error_message"
        paymentTitleError={errorMessage}
        paymentErrorMessage={errorMessage !== 'payment_quick_action_error' ? ' ' : 'payment_quick_action_error_message'}
        currency={ContentManager.translate(currency)}
        onPressSaveCard={onPressSaveCard}
        billInfoText={bill?.timePeriod}
        hiddenFields={['nameOnCard']}
        isBtnSuccessDisabled={!defaultPayment.id}
        btnRejectStyle={{ marginTop: 6 }}
      >
        {payment3DSData &&
          <View style={Styles.iframePopup}>
            <WebView
              url={payment3DSData.terminationURL}
              // statusBarColor={statusBarColor}
              source={{ html: popUpFrameContent }}
              injectedJavaScript={javascript}
              onMessage={(event) => {
                setTimeout(() => {
                  payment3DSData.onSuccess(payment3DSData.id)
                  setPayment3DSData(false)
                }, 1000)
                // setPayerAuthenticationResponse(JSON.parse(event.nativeEvent.data))
              }}
              javaScriptEnabled={true}
              onClose={() => setPayment3DSData(false)}
            />
          </View>
        }
      </PaymentWrapper>

      <MonthlyBilling
        scrollRef={scrollRef}
        vfScreen={{
          title: 'billing_details_title',
          titlePlaceholders: [bill.formattedMonth],
          onClose: onClose,
          onBack: onBack,
          showBack: true,
        }}
        header={{
          title: bill.timePeriod ? bill?.timePeriod : ' ',
          subTitle: bill.price ? bill.price : ' ',
          customSubHeaderTitle: 'billing_details_header_title'
        }}
        payment={{
          isLoading: paymentLoader,
          logo: Images.greenTick,
          codeValue: bill.partyAccount,
          billNumberValue: bill.id,
          paymentMethod: bill.payment?.id ? bill.paymentMethod.details.brand + ' ' + bill.paymentMethod.details.lastFourDigit : null,
          status: bill?.status ? bill?.status : 'new',
          onPaymentPress: () => {
            if (isBusinessUser || (isBusinessChooser() && !businessAccount?.showBusinessView)) {
              setShowUnableToPayModal(true)
            } else {
              setShowPayment(true)
              getCustomerId()
            }
          },
          paymentDatePlaceholder: [bill?.paymentDate?.format('DD') + ' ' + bill?.formatedPaymentDate],
          toBePaidPlaceholders: [bill.date, '', bill.month],
          price: bill?.price,
          withSecondaryButton: true,
          secondaryButtonTitle: isStorageGranted ? 'billing_download_bill' : 'billing_storage_permission_denied',
          onSecondaryButtonPress: downloadBill,
          isSecondaryButtonDisabled: dowloadLoader,
          isSecondaryButtonLoading: dowloadLoader
        }}
        subscriptions={{
          data: bill.subscription || [],
          isLoading: subscriptionsLoader
        }}
        renderSectionAfterPaymentInfo={() => {
          if (bill?.categories?.length <= 1 || !isAdminView() || subscriptionsLoader) {
            return null
          } else {
            return (
              <View style={styles.filterContainer}>
                <FilterBar
                  filterData={categories}
                  setSelectedCategories={(cat) => onFilterItemPress(cat)}
                  selectedCategories={selectedCategories}
                  isLoading={false}
                  theme={theme}
                  withSeeAll={false}
                />
              </View>
            )
          }
        }}
        renderSubscriptions={(subscriptions) => {
          const data = isAdminView() ? (
            subscriptions?.isLoading ? [{}, {}] : subscriptions?.data?.filter(el => el?.role === selectedCategories)
          ) : (subscriptions?.isLoading ? [{}] : subscriptions?.data)
          return (
            <View onLayout={ev => setInputOffset(ev?.nativeEvent?.layout?.y)}>
              <VFText i18nKey="billing_payment_breakdown_title" style={styles.breakdownTitle} />
              {data?.length !== 0 && <SubscriptionList
                data={data}
                isLoading={subscriptions?.isLoading}
                scrollRef={scrollRef}
                offset={inputOffset}
                unfilteredData={data || []}
                isAdminView={isAdminView()}
                selectedCategories={selectedCategories}
              />}
            </View>
          )
        }}
      />
      {
        payerAuthenticationDetails.accessToken &&
        <View style={Styles.iframeDataCollection}>
          <WebView
            javaScriptEnabled={true}
            onMessage={(event) => {
              console.log('event.nativeEvent payerAuthentication', event.nativeEvent)
              // setPayerAuthenticationResponse(JSON.parse(event.nativeEvent.data))
            }}
            injectedJavaScript={javascript}
            // injectedJavaScriptBeforeContentLoaded={runBeforeFirst}
            source={{ html: iframeContent }} />
        </View>
      }
      {/* <SohoBillPaymentModal
        isVisible={showUnableToPayModal}
        toggleModal={() => setShowUnableToPayModal(prev => !prev)}
        isAdmin={isBusinessChooser()}
        navigateToBilling={navigateToBilling}
      /> */}
    </>
  )
}
MonthlyBillingHocExample.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object
}

export default MonthlyBillingHocExample
