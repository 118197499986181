import { StyleSheet, Dimensions, Platform } from 'react-native'

var deviceWidth = Dimensions.get('window').width

export default StyleSheet.create({
  containerStyle: {
    flex: 1
  },
  congratulation: {
    color: '#fff',
    fontSize: 35,
    textAlign: 'center',
    marginTop: 60
  },
  gift: {
    width: deviceWidth * 74 / 100,
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '13%',
    borderRadius: 500,
    height: deviceWidth * 74 / 100,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 95
  },
  animation: {
    width: (deviceWidth * 74 / 100 + 4),
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '13%',
    height: deviceWidth * 74 / 100,
    position: 'absolute',
    bottom: 95
  },
  circle: {
    width: (deviceWidth * 74 / 100 + 4),
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '13%',
    height: deviceWidth * 74 / 100 + 8,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 95
  },
  normalText: {
    color: '#fff',
    fontSize: 21
  },
  normalBoldText: {
    color: '#fff',
    fontSize: 21,
    fontWeight: 'bold'
  },
  giftName: {
    color: '#fff',
    fontSize: 25,
    fontWeight: 'bold',
    marginBottom: 35
  },
  slide1: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  slide2: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  gift_opened: {
    width: '100%',
    height: '100%'
  },
  dot: {
    width: 13,
    height: 13,
    borderRadius: 7
  },
  activeDot: {
    width: 15,
    height: 15,
    borderRadius: 8
  },
  pagination: {
    bottom: Platform.OS === 'ios' ? 70 : 55
  }
})
