import { StyleSheet, Platform } from 'react-native'

import Colors from '../../Themes/Colors'

export default StyleSheet.create({
  backgroundImage: {
    flex: 1,
    height: '100%',
    width: '100%',
  },
  singleStory: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 8
  },
  welcomeContainer: {
    diplay: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: Platform.OS === 'ios' ? 32 : 22,
    marginHorizontal: 16,
    alignItems: 'center'
  },
  welcomeMessage: {
    fontSize: 20,
    color: Colors.white,
  },
  flagsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  flagSeparator: {
    fontSize: 22,
    color: Colors.white,
    textAlign: 'center',
    marginHorizontal: 5,
  },
  storyContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 4,
  },
  storyContentStyle: {
    gap: 20,
    justifyContent: 'space-between'
  },
  storyButton: seen => ({
    borderRadius: 100,
    borderWidth: seen ? 3 : 3,
    borderColor: seen ? Colors.gray65 : Colors.bondiBlue,
    overflow: 'hidden',
    backgroundColor: Colors.black,
    padding: 1
  }),
  radius: {
    borderRadius: 100
  },
  empty: {
    height: 13
  },
  offerCountdown: {
    backgroundcolor: Colors.white,
    borderRadius: 100,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 5,
    paddingVertical: 5,
    alignItems: 'center',
    width: 70,
    marginTop: -20,
    marginBottom: 10
  },
  offerCountdownIcon: {
    marginRight: 5,
    resizeMode: 'contain'
  },
  offerCountdownDescription: width => ({
    color: Colors.white,
    fontSize: 14,
    lineHeight: 16.07,
    width: width / 4,
    textAlign: 'center'
  }),
  offerCountdownTime: {
    fontSize: 12
  },
  cardContainer: {
    marginHorizontal: 0,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    zIndex: 5,
    elevation: 5,
    width: '100%',
    height: '100%',
    backgroundcolor: Colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 0,
    flex: 8,
  },
  cardTitle: {
    fontSize: 26,
    fontWeight: '700',
    marginVertical: 10,
    textAlign: 'left',
    alignSelf: 'flex-start',
    flex: 1,
  },
  quickLinksContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 35,
    marginBottom: 50,
    flex: 3,
    flexWrap: 'wrap'
  },
  quickLinksIconContainer: {
    backgroundColor: Colors.whiteSmoke,
    borderRadius: 50,
    height: 70,
    width: 70,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    resizeMode: 'contain',
    overflow: 'scroll'
  },
  quickLinksDescription: {
    textAlign: 'center',
    lineHeight: 18,
    paddingTop: 10,
    fontSize: 15
  },
  buttonsContainer: {
    width: '100%',
    flex: 2
  },
  guestButtonContainer: {
    width: '100%',
    alignItems: 'center',
    marginVertical: 16
  },
  guestButtonText: {
    color: Colors.bunker,
    fontSize: 16,
    lineHeight: 20
  },
  singleLinkContainer: width => ({
    width: width / 5,
    alignItems: 'center'
  }),
  singleLink: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  lottieContainer: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  lottie: width => ({
    width: width / 2,
    height: 'auto',
    marginLeft: 0,
    alignSelf: 'center',
  }),
  circleFlag: {
    borderColor: 'white',
    borderWidth: 1,
    borderRadius: 50,
    overflow: 'hidden',
    height: 20,
    width: 20,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
