import { StyleSheet } from 'react-native'

import { Fonts, Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    marginTop: 30,
    flex: 1,
  },
  title: {
    fontSize: Fonts.size.h4,
    ...Fonts.type.bold,
    marginVertical: 10
  },
  inputContainerStyle: {
    marginHorizontal: 0,
    width: '85%',
  },
  searchButtonContainer: (disabled, theme) => ({
    backgroundColor: disabled ? Colors.lightGrey : Colors.vfRed,
    width: '15%',
    height: 55,
    marginLeft: -6,
    marginTop: 6,
    borderWidth: 1,
    borderColor: theme.colors.textInput.default.baseColor,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    justifyContent: 'center',
    alignItems: 'center'
  }),
  searchContentContainer: theme => ({
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.borderColorThree,
    marginBottom: 15,
  }),
  textInputContainerStyle: theme => ({
    backgroundColor: theme.colors.cardBackgroundColor,
    borderRadius: 6,
  }),
  line: theme => ({
    backgroundColor: theme.colors.cardBackgroundColor,
    width: '100%',
    height: 2,
    top: 2,
    zIndex: 100,
  }),
  cardStyle: {
    height: 'auto',
    marginVertical: 10,
    paddingHorizontal: 16,
    width: '100%',
    cursor: 'pointer'
  },
  usageContainer: {
    flexDirection: 'row',
    marginVertical: 5
  },
  usageIconContainer: theme => ({
    backgroundColor: theme.colors.backgroundColorFive,
    padding: 5,
  }),
  usageText: {
    fontSize: Fonts.size.regular,
    flex: 1
  },
  usageTextValue: {
    fontSize: Fonts.size.regular,
    flex: 1,
    textAlign: 'right'
  },
  usageTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'baseline'
  },
  usagePercentageContainer: {
    flex: 1,
    paddingLeft: 16,
  },
  pageContainer: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    marginBottom: 25,
    marginTop: 15
  },
  pageText: theme => ({
    fontSize: 18,
    color: theme.colors.seeBillColor,
  }),
  pageButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 10,
  },
  contentContainer: {
    flex: 1
  }
})
