import React from 'react'
import { View, Animated, TouchableOpacity, ImageBackground } from 'react-native'
import PropTypes from 'prop-types'
import { isNumber as _isNumber } from 'lodash'

import { VFText, Icon, PercentageIndicator } from '@vfgroup-oneplatform/foundation/Components'
import { withTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { getThemeImages } from '../../Themes'

import styles from './MobileUsage.Styles'

const getIcon = (type) => {
  switch (type) {
    case 'data':
      return 'ic_data_sharing'
    case 'sms':
      return 'ic_sms_text'
    case 'voice':
      return 'ic_call_log'
    default:
      return null
  }
}


const MobileUsage = ({
  data,
  theme,
  animate,
  ...props
}) => {

  const Images = getThemeImages(theme.name)

  const { extraData = [] } = data

  return (
    <Animated.View style={styles.flex1}>
      <ImageBackground
        source={Images.usage_tile_bg}
        resizeMode="cover"
        style={styles.container(theme)}
      >
        <Animated.View style={styles.headerContainer}>
          <Icon
            name={Images.icMobile}
            size={30}
          />
          <VFText
            i18nKey="dashboard_my_mobile"
            style={styles.title}
          />
        </Animated.View>
        <View style={styles.rowStyle} >
          {extraData.map((item, index) => {
            const { remainingValue = {}, value = {} } = item

            const valueRemainingPercentage = !_isNumber(remainingValue.amount) ? 100 : (remainingValue?.amount / value?.amount) * 100

            return (
              <View key={index} style={[styles.itemContainer, index !== (extraData.length - 1) && styles.rightBorder(theme)]}>
                <View style={styles.itemHeader}>
                  <Icon
                    name={Images[getIcon(item?.usageType)]}
                    size={30}
                    resizeMode="contain"
                    style={styles.itemIcon}
                  />
                  <VFText
                    i18nKey={item?.valueName}
                    style={styles.title}
                  />
                </View>
                <PercentageIndicator
                  value={valueRemainingPercentage || 0}
                  animate={animate}
                  fillColor={theme.colors.fillColor}
                  color={theme.colors.secondryColor}
                />
              </View>
            )
          })}
        </View>

        {/* <View style={styles.footerContainer}> */}
        {/* {(props.showRefill || extraData[0]?.showGetMoreData) ?
            <TouchableOpacity onPress={props.onRefillPress} style={styles.button}>
              <VFText
                style={styles.title}
                i18nKey="Refill"
                type="secondary"
              />
            </TouchableOpacity> : <>
              {extraData[0]?.showGetMoreData ?
                <TouchableOpacity onPress={props.onGetMoreDataPress} style={styles.button}>
                  <VFText
                    style={styles.title}
                    i18nKey="dashboard_get_more_data"
                    type="secondary"
                  />
                </TouchableOpacity> : null
              }
            </>
          } */}
        {/* </View> */}
      </ImageBackground>
    </Animated.View>
  )
}
MobileUsage.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.shape({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  }),
  animate: PropTypes.bool.isRequired,
  showRefill: PropTypes.bool,
  onRefillPress: PropTypes.func,
  onGetMoreDataPress: PropTypes.func,
}

export default withTheme(MobileUsage)