import { StyleSheet } from 'react-native'

import { Fonts, Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'


export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'baseline',
    paddingHorizontal: 16,
    // width: 200,
    alignSelf: 'center',
    marginTop: 23,
    marginLeft: 10,
  },
  smallLabel: {
    fontFamily: Fonts.type.VFFont,
    color: Colors.white,
    marginTop: 7,
    fontSize: Fonts.size.small
  },
  sectionCentered: {
    alignItems: 'center',
    paddingRight: 10,
  },
  label: {
    ...Fonts.type.bold,
    color: Colors.white,
    fontSize: Fonts.size.h3
  },
  colon: {
    ...Fonts.type.bold,
    color: Colors.white,
    fontSize: Fonts.size.h3,
    marginTop: -2,
    paddingRight: 10,
  }
})
