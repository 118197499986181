import { StyleSheet } from 'react-native'
import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  contentContainer: {
    paddingHorizontal: 16.6,
    paddingTop: 32.4,
    paddingBottom: 30
  },
  subHeaderTitle: {
    fontSize: 25.1,
    lineHeight: 31.4
  },
  subHeaderSubTitle: {
    fontSize: 20.8,
    lineHeight: 25.1,
    fontWeight: 'bold',
    fontFamily: Fonts.type.VFFont,
    marginBottom: 2,
  },
  manualRefresh: {
    marginBottom: 0,
    marginLeft: 0,
    marginTop: -10
  },
  emptyListTitle: (theme) => ({
    fontSize: 19.3,
    lineHeight: 22.9,
    color: theme.colors.textColor,
    fontWeight: Fonts.type.bold.fontWeight,
    textAlign: 'center',
    marginVertical: 15,
  }),
  emptyListSubtitle: (theme) => ({
    fontSize: 15.7,
    lineHeight: 22.9,
    color: theme.colors.textColorTwo,
    textAlign: 'center',
  }),
  cardContentLoader: theme => ({
    padding: 16,
    borderRadius: 6,
    marginVertical: 8,
    backgroundColor: theme.colors.cardBackgroundColor
  }),
  textContentLoader: {
    borderRadius: 6,
  },
  errorContainer: {
    paddingVertical: 30,
    paddingHorizontal: 10,
  },
  errorText: (theme) => ({
    fontFamily: Fonts.type.VFFont,
    fontSize: Fonts.size.h6,
    color: theme.colors.textColorTwo,
    marginVertical: 20,
    textAlign: 'center',
  }),
  tryAgainContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tryAgainText: (theme) => ({
    fontFamily: Fonts.type.VFFont,
    fontSize: Fonts.size.h6,
    marginRight: 5,
    color: theme.colors.textColorTwo,
  }),
  scanBtn: {
    marginBottom: 25,
  },
  modalContainer: theme => ({
    margin: 0,
    backgroundColor: theme.colors.backgroundColor,
    flex: 1,
  }),
  modalStyle: {
    margin: 0,
    justifyContent: 'flex-end',
  }
})

