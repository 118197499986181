import React, {} from 'react'
import PropTypes from 'prop-types'

import { GigaProductInfo as GigaProductInfoScreen} from '@vfal-lab/rn-vfg-giga-family/Screens'

function GigaProductInfo({navigation}) {

  return (
    <GigaProductInfoScreen
      navigation={navigation}
    />
  )
}

GigaProductInfo.defaultProps = {}

GigaProductInfo.propTypes = {
  navigation: PropTypes.object
}

export default GigaProductInfo
