import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Dimensions, Platform, Pressable } from 'react-native'





import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import AsyncStorage from '@react-native-async-storage/async-storage'
import {
    SectionEnums,
} from '@vfgroup-oneplatform/framework/CommonUI/PrivacyPermissionsOverlay'
import { View } from 'native-base'
import { Icon, VFText } from '@vfgroup-oneplatform/foundation/Components'
import Collapse from 'react-native-collapsible'
import {
    PrivacyPermissionsSection,
} from '@vfgroup-oneplatform/framework/CommonUI/PrivacyPermissionsOverlay/Components'
import SpinerOverlay from '@vfgroup-oneplatform/foundation/Components/SpinnerOverlay/SpinnerOverlay'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'


import { useNavigation } from '@react-navigation/native'

import AppSettings from '@vfgroup-oneplatform/framework/Settings/AppSettings'


import OptionItem from '@vfgroup-oneplatform/framework/Settings/components/OptionItem'

import DisplayOptions from '@vfgroup-oneplatform/framework/Settings/DisplayOptions'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import ReduxCache from '../../Utils/ReduxCache'
import { getPreferences, updatePreferences } from '../../Services/Settings'
import PrivacyPermissions from '../PrivacyPermissions'
import NavigationService from '../../Navigation/NavigationService'
import { getThemeImages } from '../../Group/Themes'

import { isAdminView } from '../../Utils'

import SelectLanguage from './SelectLanguage'

function Settings({ navigation: { pop }, route, ...rest }) {
    const [isPermissionsVisible, setPermissionsVisible] = useState(false)
    const [data, setData] = useState({})
    const [items, setItems] = useState({})
    const [saving, setSaving] = useState(false)
    const [didSectionRender, setDidSectionRender] = useState(false)
    const navigate = useNavigation()


    const theme = useTheme()
    const userType = ReduxCache.getUserType()
    const businessAccount = ReduxCache.getBusinessAccount()

    const images = getThemeImages(theme.name)

    const language = ReduxCache.getLanguage()

    const [isContactPreferencesOpened, setIsContactPreferencesOpened] = useState(true)

    const handleImage = (key) => {
        switch (key) {
            case 'settings_netperform_privacy_html_android':
                return 'networkSignal'
            case 'settings_tems_html':
                return 'ic_doc3'
            case 'settings_netperform_terms_and_conditions_html':
                return 'networkSignal'
            case 'app_privacy_regulation_supplement_html':
                return 'privacy'
            case 'settings_terms_and_conditions_topup_html':
                return 'topUpAnother'
            default:
                return 'icon'
        }
    }


    // CONTACT PREFERENCES

    const styles = {
        titleContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        iconTextContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            ...Platform.select({
                web: {
                    flexShrink: 1
                },
                default: {}
            })
        },
        icon: {
            tintColor: theme.colors.textColor,
            marginRight: 11
        },
        title: {
            fontSize: 18.7,
            lineHeight: 25,
            paddingTop: Platform.OS === 'ios' ? 5 : null,
            fontWeight: 700
        },
        subTitle: {
            marginLeft: 42,
            fontSize: 14.6,
            marginTop: 6,
            lineHeight: 18.7
        },
        subTitleContainer: {
            paddingRight: 16
        }
    }

    const renderContactPreferencesItem = () => {
        return (
            <View style={{
                backgroundColor: theme.colors.cardBackgroundColor,
                borderRadius: 6, padding: 20, justifyContent: 'center', borderRadius: 6,
                elevation: 5,
                shadowColor: 'rgba(0, 0, 0, 0.16)',
                shadowOffset: {
                    width: 0,
                    height: 2
                },
                shadowRadius: 4,
                shadowOpacity: 1,
            }} >
                <Pressable onPress={() => { setIsContactPreferencesOpened(!isContactPreferencesOpened) }}>
                    <View style={styles.titleContainer}>
                        <View style={styles.iconTextContainer}>
                            <Icon
                                type="image"
                                source={images.icLandlineOrCallMinutes3}
                                size={34}
                                style={styles.icon}
                            />
                            <VFText type="primary" i18nKey={'preferences_subtitle'} style={styles.title} />
                        </View>

                        <Icon type="image" size={32} source={isContactPreferencesOpened ? images.ic_chevron_down_red : images.ic_chevron_up_red} />
                    </View>
                    <View style={styles.subTitleContainer}>
                        <VFText
                            type="primary"
                            i18nKey={'preferences_first_description'}
                            style={styles.subTitle}
                        />
                    </View>
                </Pressable>
                <Collapse collapsed={isContactPreferencesOpened} style={{ marginTop: 16 }}>
                    {sections.map((section, index) => (
                        <PrivacyPermissionsSection theme={theme} index={index} key={index} section={section} />
                    ))}
                </Collapse>
            </View>
        )
    }




    const contactPreferences = {
        title: ContentManager.translate('preferences_subtitle'),
        description: ContentManager.translate('preferences_first_description'),
        items: [
            {
                component: renderContactPreferencesItem
            }
        ],
        privacySettingsSections: [
            {
                actions: {
                    items: []
                }
            }
        ]
    }

    useEffect(() => {
        if (ReduxCache.getUserType() !== 'guest') {
            getPreferences({
                'Accept-Language': language,
            }, {
                onSuccess: ({ response, items: newItems }) => {
                    AsyncStorage.setItem('shouldRenderPreferences', `${response?.status !== 'Active'}`)
                    if (response?.status === 'Active') {
                        // askForNotifications(newItems)
                    }
                    setItems(newItems)
                    setData(response)
                },
                onError: () => { }
            })
        }
    }, [])

    const onOkButton = () => {
        // setSaving(true)

        updatePreferences(data, items, {}, {
            onSuccess: (res) => {
                // setSaving(false)
                // setIsContactPreferencesOpened(!isContactPreferencesOpened)
                // onDismiss()
                // askForNotifications(res)
            },
            onError: () => { }
        })
    }

    const updateDataManagment = () => {
        updatePreferences(personalisedData, personalisedItems, {}, {
            onSuccess: (res) => {
                // setSaving(false)
                // setIsContactPreferencesOpened(!isContactPreferencesOpened)
                // onDismiss()
                // askForNotifications(res)
            },
            onError: () => { }
        })
    }

    const onToggle = key => {
        setItems(oldItems => {
            return {
                ...oldItems,
                [key]: !oldItems[key]
            }
        })
    }

    const sections = contactPreferences.privacySettingsSections.map((sec, i) => {
        const parts = data?.parts
        const preferencesArr = parts?.partyPreference?.partyPreference

        const mappedPreferences = preferencesArr?.map((preference) => ({
            title: preference?.name,
            description: preference?.desc,
            key: preference?.id?.[0]?.value,
            initialValue: items?.[preference?.id?.[0]?.value],
            onToggle: onToggle
        })) || []


        return {
            ...sec,
            actions: {
                type: SectionEnums.PERMISSION,
                items: mappedPreferences
            }
        }
    })

    // END OF CONTACT PREFERENCES


    // TERMS AND CONDITIONS
    const termsAndConditionsArray = []


    const termsData = useSelector(state => state.configurations.pages.find(el => el.page === 'settingsotherpage').items)

    const termsItems = useSelector(state => state.configurations.pages.find(el => el.page === 'settings').items)


    termsData.map(item => {
        const content = item.value
        let title = ''
        if (item.key === 'settings_tems_html') {
            title = termsItems.find(el => el.key === 'settings_terms_and_conditions_general_title').value
        }
        if (item.key === 'settings_netperform_privacy_html_android') {
            title = termsItems.find(el => el.key === 'settings_terms_and_conditions_netperform_privacy').value
        }
        if (item.key === 'settings_netperform_terms_and_conditions_html') {
            title = termsItems.find(el => el.key === 'settings_terms_and_conditions_netperform_terms_and_conditions').value
        }
        if (item.key === 'settings_terms_and_conditions_topup_html') {
            title = termsItems.find(el => el.key === 'settings_terms_and_conditions_topup_title').value
        }
        if (item.key === 'app_privacy_regulation_supplement_html') {
            title = termsItems.find(el => el.key === 'settings_terms_and_conditions_privacy_app').value
        }
        if (title !== '') {
            termsAndConditionsArray.push({ content: content, title: title, key: item?.key })
        }
    })

    const termsAndConditions = {
        title: 'terms_and_conditions_title',
        description: 'settings_terms_conditions_description',
        withCard: true,
        items: termsAndConditionsArray.map((item, i) => {
            return ({
                component: OptionItem,
                props: {
                    icon: handleImage(item?.key),
                    title: item?.title,
                    onPress: () => NavigationService.navigate('TermsAndConditions', { content: item?.content, title: item?.title })
                }
            })
        }
        )
    }


    // END OF TERM AND CONDITIONS


    // Personalised Recomandation

    const [isPersonalised, setIsPersonalised] = useState(true)
    const [personalisedData, setPersonalisedData] = useState({})
    const [personalisedItems, setPersonalisedItems] = useState({})




    useEffect(() => {
        if (ReduxCache.getUserType() !== 'guest') {
            getPreferences({
                'Accept-Language': language,
            }, {
                onSuccess: ({ response, items: newItems }) => {
                    AsyncStorage.setItem('shouldRenderPreferences', `${response?.status !== 'Active'}`)
                    setPersonalisedItems(newItems)
                    setPersonalisedData(response)
                    // setIsLoading(false)
                },
                onError: () => { }
            })
        }
    }, [])

    const personalisedPreferences = {
        'privacySettingsSections': [
            {
                'actions': {
                    'items': []
                }
            }
        ]
    }

    const onToggleDataManagment = key => {
        setPersonalisedItems(oldItems => {
            return {
                ...oldItems,
                [key]: !oldItems[key]
            }
        })
    }

    const personalisedSection = personalisedPreferences.privacySettingsSections.map((sec, i) => {
        const parts = data?.parts
        const permissions = parts?.partyPermission?.permission?.partyPermission

        const mappedPermissions = permissions?.map((permission) => ({
            title: permission?.name,
            description: permission?.desc,
            key: permission?.id?.[0]?.value,
            initialValue: personalisedItems?.[permission?.id?.[0]?.value],
            onToggle: onToggleDataManagment
        })) || []

        return {
            ...sec,
            actions: {
                type: SectionEnums.PERMISSION,
                items: mappedPermissions
            }
        }
    })



    const dataManagmentComponent = () => {
        return (
            <>
                <Pressable onPress={() => { setIsPersonalised(!isPersonalised) }}>
                    <View style={styles.titleContainer}>
                        <View style={styles.iconTextContainer}>
                            <Icon
                                type="image"
                                source={images.ic_data_sharing}
                                size={34}
                                style={styles.icon}
                            />
                            <VFText type="primary" i18nKey={'data_management_title'} style={styles.title} />
                        </View>

                        <Icon type="image" size={32} source={images.ic_chevron_right} />
                    </View>
                    <View style={styles.subTitleContainer}>
                        <VFText
                            type="primary"
                            i18nKey={'privacy_policy_subtitle'}
                            style={styles.subTitle}
                        />
                    </View>
                </Pressable>
                <Collapse collapsed={isPersonalised} style={{ marginTop: 16 }}>
                    {personalisedSection.map((section, index) => (
                        <PrivacyPermissionsSection index={index} key={index} section={section} />
                    ))}
                </Collapse>
            </>
        )
    }

    const personalisedRecomandation = {
        title: 'settings_privacy_settings_title',
        description: 'settings_privacy_settings_subtittle',
        withCard: true,
        items: [
            {
                component: OptionItem,
                props: {
                    icon: 'ic_Users_Members_Customers',
                    title: 'privacy_policy_title',
                    description: 'privacy_policy_subtitle',
                    onPress: () => NavigationService.navigate('PrivacyPolicy')
                }
            },
            {
                component: dataManagmentComponent,
                props: {
                    icon: 'ic_data_sharing',
                    title: 'data_management_title',
                    description: 'data_management_subtitle',
                    onPress: () => NavigationService.navigate('DataManagment')
                }
            },
        ]
    }


    // End of  Personalised Recomandation




    const permissions = {
        title: 'settings_permissions_title',
        description: 'settings_permissions_subtitle',
        withCard: true,
        items: [
            {
                component: OptionItem,
                props: {
                    icon: 'icPadlockClose',
                    title: 'settings_device_permissions_title',
                    description: 'settings_device_permissions_subtitle',
                    onPress: () => NavigationService.navigate('AppPermissions')
                }
            },
            {
                component: OptionItem,
                props: {
                    icon: 'icNetworkMap',
                    title: 'settings_netperform_title',
                    description: 'settings_netperform_subtitle',
                    onPress: () => userType === 'guest' ? navigate('TransparentPopup') : navigate('NetPerfom')
                }
            },
        ]
    }

    const generalSettings = {
        title: 'settings_general_settings_title',
        description: 'settings_general_settings_subtitle',
        withCard: true,
        items: [
            // { component: withBiometricStorage(SettingsToggle) },
            { component: DisplayOptions },
            {
                component: OptionItem,
                props: {
                    icon: 'ic_admin',
                    title: 'settings_profile_manage_title',
                    description: 'settings_profile_manage_subtitle',
                    onPress: () => NavigationService.navigate('ManageProfile')
                }
            },
            // { component: DisplayOptions },
        ]
    }

    const languageSettings = {
        title: 'settings_language_title',
        description: 'settings_language_subtitle',
        withCard: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? false : true,
        isRadio: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? true : false,
        items: [
            {
                component: SelectLanguage
            },
        ]
    }

    const preferences = {
        title: 'settings_privacy_settings_title',
        description: 'settings_privacy_settings_subtittle',
        withCard: true,
        items: [
            {
                component: OptionItem,
                props: {
                    title: 'recommendations_title',
                    description: 'settings_recommandation_description',
                    icon: 'ic_admin',
                    onPress: () => userType === 'guest' ? NavigationService.navigate('TransparentPopup') : NavigationService.navigate('Recommendations')
                }
            },
            {
                component: OptionItem,
                props: {
                    title: 'preferences_title',
                    description: 'settings_preferences_description',
                    icon: 'ic_phone',
                    onPress: () => userType === 'guest' ? NavigationService.navigate('TransparentPopup') : NavigationService.navigate('Preferences')
                }
            },
            {
                component: OptionItem,
                props: {
                    title: 'terms_and_conditions_title',
                    description: 'settings_terms_conditions_description',
                    icon: 'ic_doc',
                    onPress: () => NavigationService.navigate('TermsAndConditions')
                }
            }
        ]
    }

    const recommendations = {
        title: 'settings_privacy_settings_title',
        description: 'settings_privacy_settings_subtittle',
        withCard: true,
        items: [
            {
                component: OptionItem,
                props: {
                    icon: 'ic_Users_Members_Customers',
                    title: 'privacy_policy_title',
                    description: 'privacy_policy_subtitle',
                    onPress: () => NavigationService.navigate('PrivacyPolicy')
                }
            },
            {
                component: OptionItem,
                props: {
                    icon: 'ic_data_sharing',
                    title: 'data_management_title',
                    description: 'data_management_subtitle',
                    onPress: () => NavigationService.navigate('DataManagment')
                }
            },
        ]
    }



    const onClose = () => {
        if (route?.params?.isDeepLink) {
            NavigationService.navigate('Dashboard')
        } else {
            pop()

        }
    }

    const SETTINGS = [
        [
            { ...generalSettings }
        ],
        [
            { ...languageSettings }
        ],
        [
            { ...personalisedRecomandation }, { ...contactPreferences }, { ...termsAndConditions }
        ]
    ]

    const getAppSettings = () => {
        // if (businessAccount?.sohoUserType === 'business_user' || isAdminView()) {
        //     return [generalSettings, languageSettings, permissions, preferences]
        // } else {
        //     return [...SETTINGS]
        // }
        return SETTINGS
    }

    useEffect(() => {
        if (!isEmpty(items)) {
            onOkButton()
        }
    }, [items])

    useEffect(() => {
        if (!isEmpty(personalisedItems)) {
            updateDataManagment()
        }
    }, [personalisedItems])



    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
        return (
            <>
                <AppSettings
                    title="settings_screen_title"
                    settings={getAppSettings()}
                    onClose={onClose}
                    images={images}
                    withTray
                    SETTINGS={SETTINGS}
                    {...rest}
                />
                {isPermissionsVisible && <PrivacyPermissions
                    isVisible={isPermissionsVisible}
                    setIsVisible={setPermissionsVisible}
                />}
                {saving && <SpinerOverlay isVisible autoPlay lottieStyle={{
                    width: 200,
                    height: 200,
                }} />}
            </>
        )
    }

    return (
        <>
            <AppSettings
                title="settings_screen_title"
                settings={[generalSettings, languageSettings, permissions, preferences]}
                onClose={onClose}
                images={images}
                withTray
            />
            {isPermissionsVisible && <PrivacyPermissions
                isVisible={isPermissionsVisible}
                setIsVisible={setPermissionsVisible}
            />}
        </>
    )
}
Settings.propTypes = {
    navigation: PropTypes.object,
    theme: PropTypes.object
}

export default Settings
