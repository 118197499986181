import { Dimensions, Platform, StyleSheet } from 'react-native'
import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  card: theme => ({
    backgroundColor: theme.colors.cardBackgroundColorThree,
    justifyContent: 'space-between',
    borderBottomColor: theme.colors.VFDivider.tertiary,
    borderBottomWidth: 1,
    shadowColor: theme.colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 8,
    elevation: 8,
  }),
  itemNameContainer: theme => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomColor: theme.colors.VFDivider.tertiary,
    borderBottomWidth: 1,
    // borderBottomEndRadius: 44 / 2,
    // borderBottomStartRadius: 44 / 2,
    padding: 16.6,
    marginHorizontal: 16.6
  }),
  itemNameText: theme => ({
    fontSize: 18,
    lineHeight: 26,
    ...Fonts.type.bold,
    color: theme.colors.textColor
  }),
  itemDetailsContainer: {
    flexDirection: 'row',
    marginTop: 17,
    marginHorizontal: 17,
    alignItems: 'center'
  },
  image: {
    width: 84,
    height: 84,
    marginRight: 16,
    resizeMode: 'contain'
  },
  itemDetailsSubContainer: {
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 'flex-end' : 'space-between',
  },
  statusText: (theme) => ({
    fontSize: 17.7,
    lineHeight: 22.9,
    color: theme.colors.textColorTwo,
    fontWeight: 400
  }),
  orderStatusText: (theme) => ({
    fontSize: 17.7,
    lineHeight: 22.9,
    color: theme.colors.textColor,
    fontWeight: 700,
    marginLeft: 24
  }),
})
