import React, { Component } from 'react'
import {
  View,
  Text,
  Animated,
  ImageBackground,
  TouchableOpacity,
  Image,
  Platform,
  Dimensions,
} from 'react-native'
import { connect } from 'react-redux'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { actions as catalogActions } from '../Catalog/catalog.actions'
import { VFScreen } from '@vfgroup-oneplatform/foundation/Components'


import Error from '../../Components/Error'
import Loader from '../../Components/Loader'
import Splash from '../Splash'
import { __translate, __image } from '../../Utils'
import OpenedGift from '../OpenedGift'

import { actions as giftActions } from '../OpenedGift/openedgift.actions'

import ReduxCache from '../../Utils/ReduxCache'

import styles from './demo.styles'
import { Images } from '../../Themes'
import { actions } from './demo.actions'

class Demo extends Component {
  state = {
    giftRequested: false,
    userType: ReduxCache.getUserType(),
  };

  requestGift() {
    // this.runAnimation()
    this.setState({ giftRequested: true })
    this.props.getGift()
  }

  runAnimation() {
    Animated.loop(
      Animated.timing(this.animatedValue, {
        toValue: 1,
        duration: 1200,
      }),
    ).start()
  }

  componentWillUnmount() {
    Animated.timing(this.animatedValue).stop()
  }

  componentDidMount() {
    this.props.setActiveComponent('More')
    this.props.getUserProfile()
    this.animatedValue = new Animated.Value(0)
    setTimeout(() => {
      this.props.getCategories(true)
    }, 500)
    this.checkUserStatus()
  }

  checkUserStatus() {
    AsyncStorage.getItem('user').then(user => {
      user = JSON.parse(user)

      if ((user.status && user.status.toLowerCase() == 'active') || this.state.userType === 'non_vf') {
        this.props.setInitialUser(user)
        this.props.navigation.replace('Catalog', this.props.route.params)
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this?.props?.user?.data && !this?.props?.splash?.loading && this?.props?.images?.data &&
        this?.props?.user?.data?.status?.toLowerCase() == 'active') || this?.state?.userType === 'non_vf'
    ) {
      this.props.navigation.replace('Catalog', this.props.route.params)
    }
  }

  _retry() {
    this.props.getUserProfile()
  }

  renderItem() {
    const { user, splash, images, gift } = this.props || {}

    if (
      splash.isCache ||
      !splash.data ||
      !splash.data?.[splash.data.locale] ||
      !splash.data?.[splash.data.locale].More
    ) {
      return <Splash component={'More'} />
    }

    if ((gift?.data || gift.error) && this.state.userType !== 'non_vf') {
      return (
        <>
          <ImageBackground
            source={{ uri: __image('reward_loyalty_welcome_page_bg') }}
            style={{ width: '100%', height: '100%' }}>
            <OpenedGift navigation={this.props.navigation} />
          </ImageBackground>
        </>
      )
    }
    if (!user?.loading && user.data && user.data?.status?.toLowerCase() != 'active' && this.state.userType !== 'non_vf') {
      return (
        <View style={{ flex: 1 }}>
          <ImageBackground
            source={{ uri: __image('reward_loyalty_welcome_page_bg') }}
            style={{ width: '100%', height: '100%' }}>
            <Text style={styles.welcomeMessage}>
              {' '}
              {__translate('reward_loyalty_welcome')}{' '}
            </Text>
            {user.data.coins && (
              <Text style={styles.coins}>
                {' '}
                {__translate('reward_loyalty_initial_coins')} {user.data.coins}{' '}
                {__translate('reward_loyalty_currency_name')}{' '}
              </Text>
            )}

            <Image style={styles.circle} source={Images.circle} />

            <View style={styles.gift}>
              <Text style={styles.unbox}>
                {' '}
                {__translate('reward_loyalty_unbox_gift')}{' '}
              </Text>

              <TouchableOpacity onPress={() => this.requestGift()}>
                <Image
                  source={{ uri: __image('reward_loyalty_unbox_gift') }}
                  style={styles.gift_coin}
                />
              </TouchableOpacity>
            </View>
          </ImageBackground>
        </View>
      )
    }

    if (user.loading || user.data?.status?.toLowerCase() == 'active') {
      return (
        <View>
          <Loader size={65} view={'full'} />
        </View>
      )
    } else {
      return (
        <View>
          <ImageBackground
            source={{ uri: __image('reward_loyalty_welcome_page_bg') }}
            style={styles.gift_closed}>
            <Error message={user.error} retry={() => this._retry()} />
          </ImageBackground>
        </View>
      )
    }
  }

  render() {
    return (
      <VFScreen
        headerStyle={styles.headerStyle}
        title={'Vodafone More'}
      >
        {this.renderItem(this.props)}
      </VFScreen>
    )
  }
}

const mapStateToProps = state => {
  const { user, splash, images, gift } = state || {}
  return { user, splash, images, gift }
}

const mapDispatchToProps = dispatch => {
  return {
    getUserProfile: () => {
      dispatch(actions.getUserProfile())
    },
    getCategories: () => {
      dispatch(catalogActions.getCategories())
    },
    getGift: () => {
      dispatch(giftActions.getGift())
    },
    setInitialUser: user => {
      dispatch(actions.setInitialUser(user))
    },
    setActiveComponent: component => {
      dispatch(actions.setActiveComponent(component))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Demo)
