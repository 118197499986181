import { StyleSheet, Dimensions, Platform } from 'react-native'

import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

const { width: screenWidth } = Dimensions.get('window')

export default StyleSheet.create({
  paginationStyle: {
    zIndex: 3,
    position: 'absolute',
    alignSelf: 'center',
    top: 21,
    right: 8
  },
  cardStyle: {
    justifyContent: 'center',
    height: '100%'
  },
  cardCarouselStyle: {
    flex: 1,
  },
  container: {
    padding: 12,
    flex: 1,
    justifyContent: 'space-between',
    paddingRight: 0
  },
  billSubTitle: screenWidth => ({
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 18 : screenWidth * 0.04,
    marginTop: 6,
    fontFamily: Fonts.type.VFFont,
  }),
  billPrice: (theme) => ({
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 64 : 22,
    alignSelf: 'baseline',
    color: theme.colors.textColor
  }),
  billCurrency: (theme) => ({
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 64 : 22,
    alignSelf: 'baseline',
    paddingBottom: Platform.OS === 'android' ? 4 : 0,
    color: theme.colors.textColor
  }),
  priceAndCurrencyContainer: screenWidth => ({
    justifyContent: Dimensions.get('window').width > 700 ? 'flex-start' : 'flex-end',
    marginBottom: 5,
    flex: 1,
    paddingBottom: 12,
    marginTop: Dimensions.get('window').width > 700 && 12
  }),
  priceAndCurrencyTextContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  billDueDate: (theme) => ({
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 26 : 22,
    color: theme.colors.textColorTwo,
  }),
  nextBillContainer: {
    flex: 1
  },
  rowText: {
    flexDirection: 'row'
  },
  statusIndicator: {
    width: 10,
    height: 10,
    borderRadius: 10
  },
  statusText: (theme) => ({
    fontSize: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 36 : 18,
    color: theme.colors.textColorTwo,
    marginLeft: 5,
    lineHeight: 14,
    textAlignVertical: 'bottom',
  }),
  statusContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  }
})
