/* eslint-disable react-native/no-inline-styles */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import { check, PERMISSIONS } from 'react-native-permissions'
import moment from 'moment'

import { View, ScrollView, TouchableOpacity, Platform, Dimensions } from 'react-native'

import {
  VFScreen,
  VFBanner,
  Icon,
  VFText,
  VFButton,
  VFShowMoreText,
  QuickActionMenu,
  RadioButton,
} from '@vfgroup-oneplatform/foundation/Components'
import VFLottieView from '@vfgroup-oneplatform/foundation/Components/VFLottieView'

import { SettingsToggle } from '@vfgroup-oneplatform/framework/Settings/components'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { isArray } from 'lodash'

import _Modal from 'react-native-modal'

import Card from '../../Components/Card'
import InfoOverlay from '../../Components/InfoOverlay/InfoOverlay'

import {
  getRoamingServicesAndDataCap,
  updateServicesAndDataCap
} from '../../Services/Roaming'

import ReduxCache from '../../Utils/ReduxCache'

import { setMsisdnData, getMsisdnData } from '../../Utils'

import { getThemeImages } from '../../Group/Themes'

import ErrorHandlerComponent from '../../Components/ErrorHandler'

import styles from './RoamingSettings.Styles'



const RoamingSettings = ({ navigation }) => {
  const businessAccount = ReduxCache.getBusinessAccount()
  const isBusinessUser = businessAccount?.sohoUserType === 'business_user'

  const theme = useTheme()
  const Images = getThemeImages(theme.name)
  const userType = ReduxCache.getUserType()

  let locationSteps = []
  let roamingSteps = []

  if (Platform.OS === 'android') {
    locationSteps = [
      'roaming_settings_location_step_one_android',
      'roaming_settings_location_step_two_android',
      'roaming_settings_location_step_three_android'
    ]

    roamingSteps = [
      'roaming_settings_roaming_step_one_android',
      'roaming_settings_roaming_step_two_android',
      'roaming_settings_roaming_step_three_android',
      'roaming_settings_roaming_step_four_android'
    ]
  } else {
    locationSteps = [
      'roaming_settings_location_step_one_ios',
      'roaming_settings_location_step_two_ios',
      'roaming_settings_location_step_three_ios',
      'roaming_settings_location_step_four_ios'
    ]
    roamingSteps = [
      'roaming_settings_roaming_step_one_ios',
      'roaming_settings_roaming_step_two_ios',
      'roaming_settings_roaming_step_three_ios',
      'roaming_settings_roaming_step_four_ios'
    ]
  }

  const SETTINGS = 'SETTINGS'
  const LOCATION_SETTINGS = 'LOCATION_SETTINGS'
  const DEVICE_SETTINGS = 'DEVICE_SETTINGS'
  const SCHEMA_ACTION_TYPES = {
    activation: 'activation',
    deactivation: 'deactivation',
    changer: 'changer'
  }

  const [renderScreen, setRenderScreen] = useState(SETTINGS)

  // Location permission
  const [showLocationPermission, setShowLocationPermission] = useState(true)

  // Network States
  const isUserBusiness = false

  const [services, setServices] = useState(null)

  const [isDataCapDisabled, setIsDataCapDisabled] = useState(false)
  const [isChanged, setIsChanged] = useState(false)

  // const [isRoamingServicesSelected, setIsRoamingServicesSelected] = useState()
  // const [isCallsServiceSelected, setisCallsServiceSelected] = useState()
  // const [isSMSServiceSelected, setIsSMSServiceSelected] = useState(services?.sms)
  // const [isDataServiceSelected, setIsDataServiceSelected] = useState()


  // const [isWarningVisible, setIsWarningVisible] = useState({
  //   state: false,
  //   allServicesOff: false
  // })

  // RED Roaming State
  // const [isRedRoaming, setIsRedRoaming] = useState(true)

  // Data CAP States
  const [loadingSchemas, setLoadingSchemas] = useState(false)
  const [errorSchemas, setErrorSchemas] = useState(false)
  const [dataCapSchemas, setDataCapSchemas] = useState([])
  const [isDataCAPSelected, setIsDataCAPSelected] = useState(false)

  const [showDataCapModal, setShowDataCapModal] = useState({
    boolean: false,
    description: ''
  })


  const onClose = () => navigation.pop()
  const getDataCap = useCallback(async () => {
    if (!userType?.includes('postpaid')) {
      return
    }
    if (!isDataCapDisabled) {
      try {
        const { schemas } = await getRoamingServicesAndDataCap()
        if (schemas && isArray(schemas)) {
          // Check if all schemas are on or off and set the toggle
          setIsDataCAPSelected(
            schemas.some(item => item.selected)
          )
          setDataCapSchemas(schemas)
          setErrorSchemas(false)
          setLoadingSchemas(false)
          return
        }
        setErrorSchemas(true)
        setLoadingSchemas(false)
      } catch (error) {
        setErrorSchemas(true)
        setLoadingSchemas(false)
      }
    }
  }, [isDataCapDisabled])



  const getLastDataCapChange = useCallback(async (changed) => {
    const result = await getMsisdnData()
    const timeStamp = result?.RoamingDataCapTimeStap

    if (timeStamp) {
      const lastUpdateTime = parseInt(timeStamp, 10)
      const currentTime = moment().unix()
      const differenceTime = currentTime - lastUpdateTime
      if (differenceTime > 15 && !changed) {
        setIsDataCapDisabled(false)
      } else if (differenceTime === 15) {
        getDataCap()
        setIsChanged(false)
      } else {
        setIsDataCapDisabled(true)
      }
    }
  }, [getDataCap, setIsChanged])

  const getDataCapAsync = async () => {
    if (!userType?.includes('postpaid') || isBusinessUser) {
      return
    }
    setLoadingSchemas(true)
    try {
      const { schemas } = await getRoamingServicesAndDataCap()
      if (schemas && isArray(schemas)) {
        // Check if all schemas are on or off and set the toggle
        setIsDataCAPSelected(
          schemas.some(item => item.selected)
        )
        setDataCapSchemas(schemas)
        setErrorSchemas(false)
        return
      }
      setErrorSchemas(true)

    } catch (error) {
      setErrorSchemas(true)
    } finally {
      setLoadingSchemas(false)
    }

  }

  const schemaChanger = async (type, selectedSchema) => {
    setIsDataCapDisabled(true)
    setIsChanged(true)

    const prevSchemas = dataCapSchemas

    const newSchema = type === 'deactivation' ? [] : dataCapSchemas?.map(el => ({ ...el, selected: type === 'activation' ? (el.id === 'Schema 2' ? true : false) : (el.id === selectedSchema?.id ? true : false) }))

    setDataCapSchemas(newSchema)

    const prevSelection = isDataCAPSelected

    if (type === 'deactivation') {
      setIsDataCAPSelected(false)
    } else if (type === 'activation') {
      setIsDataCAPSelected(true)
    }

    try {
      const res = await updateServicesAndDataCap({
        type,
        dataCapSchemas,
        selectedSchema
      })
      setShowDataCapModal({
        boolean: true,
        description: res,
      })
      await setMsisdnData('RoamingDataCapTimeStap', moment().unix().toString())
    } catch (error) {
      setDataCapSchemas(prevSchemas)
      setIsDataCAPSelected(prevSelection)
      setShowDataCapModal({
        boolean: true,
        description: error
      })
      setIsDataCapDisabled(false)
      setIsChanged(false)
      console.log('heree', error)
    }
  }



  useEffect(() => {
    const changed = isChanged
    const interval = setInterval(() => {
      getLastDataCapChange(changed)
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [getLastDataCapChange, isChanged])

  useEffect(() => {
    getDataCapAsync()
    navigator.geolocation.getCurrentPosition((res) => {
      setShowLocationPermission(false)
    })
  }, [])

  const paddings = {
    'true': {
      paddingRight: '10%',
      paddingLeft: '10%',
      paddingBottom: '10%'
    },
    'false': {
      paddingBottom: '10%'
    }
  }



  return (
    <VFScreen
      title={'roaming_settings_screen_title'}
      showBack={true}
      onBack={onClose}
      onClose={() => navigation.navigate('Dashboard')}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'scroll',
          ...paddings[Dimensions.get('window').width > 700]
        }}
      >

        {renderScreen === SETTINGS && (
          <View>
            {/* Location Banner */}
            {!showLocationPermission && (
              <View style={styles.bannerContainer}>
                <VFBanner
                  title={'roaming_settings_location_permission_title'}
                  backgroundColors={[
                    'rgba(0, 124, 146, 1)',
                    'rgba(0, 124, 146, 1)'
                  ]}
                  primaryButtonViewStyle={styles.primaryButton(theme)}
                  textPrimaryButtonStyle={styles.primaryButtonText(theme)}
                  withCloseButton={true}
                  withLeftIcon={true}
                  leftIcon={Images.locationRoaming}
                  leftIconSize={20}
                  leftIconType={'image'}
                  leftIconStyle={styles.bannerIcon}
                  description={
                    'roaming_settings_location_permission_description'
                  }
                  descriptionStyle={styles.bannerDescription}
                  type="gradient"
                  titleStyle={styles.bannerText}
                  primaryButtonTitle={'roaming_settings_learn_more'}
                  onPrimaryButtonPress={() => {
                    setRenderScreen(LOCATION_SETTINGS)
                  }}

                />
              </View>
            )}

            {/* First Section */}
            <View
              style={[
                styles.infoContainer,
                showLocationPermission && { marginTop: 5 },
                !showLocationPermission && { marginTop: 10 }
              ]}
            >
              {/* <VFText
                i18nKey={'roaming_settings_screen_title'}
                style={styles.boldTitle}
              />
              <VFText
                i18nKey={'roaming_settings_screen_description'}
                style={styles.graySubtitle}
              /> */}
            </View>

            {/* Device Settings Section */}
            <View style={styles.infoContainer}>
              <View style={styles.row}>
                <VFText
                  i18nKey={'roaming_settings_device_settings_title'}
                  style={styles.boldTitle}
                />
                {/* <VFText i18nKey="OFF" style={styles.offText} /> */}
              </View>
              <VFText
                i18nKey={'roaming_settings_device_settings_description'}
                style={styles.graySubtitle}
              />
              <View style={styles.learnMoreContainer}>
                <TouchableOpacity
                  onPress={() => {
                    setRenderScreen(DEVICE_SETTINGS)
                  }}
                  style={styles.absolutePosition}
                >
                  <VFText
                    i18nKey={'roaming_settings_learn_more'}
                    style={styles.link(theme)}
                  />
                </TouchableOpacity>
              </View>
            </View>

            {/* Network Settings - Network Toggle */}

            <View style={styles.infoContainer}>
              {isUserBusiness && (
                <VFText
                  i18nKey="roaming_settings_network_settings_administrator_contact"
                  style={styles.businessText}
                />
              )}
            </View>

            {/* Data CAP Defined Below */}
            {userType?.includes('postpaid') && !isBusinessUser && (
              <View>
                <VFText
                  i18nKey="roaming_settings_dataCap_title"
                  style={styles.boldTitle}
                />
                <VFText
                  i18nKey="roaming_settings_dataCap_description"
                  style={styles.graySubtitle}
                />
                {(
                  loadingSchemas ? <View style={styles.loaderContainer}>
                    <VFLottieView
                      style={styles.loader}
                      autoPlay
                      loop
                    />
                  </View> :
                    errorSchemas ?
                      <ErrorHandlerComponent
                        style={{ paddingBottom: 20 }}
                        textButton={'roaming_settings_data_cap_default_error'}
                        onRetry={() => getDataCapAsync()}
                      /> : <>
                        {/* Toggle Button */}
                        <Card withShadow={false} theme={theme}>
                          <View style={styles.generalToggle}>
                            <SettingsToggle
                              description={''}
                              title="roaming_settings_dataCap_title"
                              isSelected={isDataCAPSelected}
                              images={Images}
                              icon={'ic_globe'}
                              style={styles.marginTop30}
                              onChange={() => {
                                isDataCAPSelected ? schemaChanger(SCHEMA_ACTION_TYPES.deactivation) : schemaChanger(SCHEMA_ACTION_TYPES.activation)
                              }}
                            />
                          </View>
                          {isDataCapDisabled && dataCapSchemas?.length === 0 && <View style={styles.overlayContainer(theme)}>
                            <VFLottieView
                              style={styles.overlayLoader}
                              autoPlay
                              loop
                            />
                          </View>}
                        </Card>
                        {/* Radio buttons */}
                        {isDataCAPSelected &&
                          <Card style={styles.moreOptions} withShadow={false} theme={theme}>
                            {dataCapSchemas && dataCapSchemas.length > 0 && dataCapSchemas?.map((schema, index) => {
                              return (
                                <View style={index === 0 ? { paddingBottom: 20 } : [styles.borderTop2nd, { paddingBottom: 30 }]}>
                                  <View style={[styles.capItem, index === 0 && styles.capWithBorderTop(theme)]}>
                                    <View style={styles.capInfoContainer}>
                                      <Icon
                                        name={Images.ic_sms_text}
                                        style={styles.capIcon}
                                      />
                                      <VFText
                                        i18nKey={schema.label}
                                        style={styles.capText}
                                      />
                                    </View>
                                    <RadioButton
                                      onPress={() => schemaChanger(SCHEMA_ACTION_TYPES.changer, schema)}
                                      selected={dataCapSchemas[index].selected}
                                      accessible={true}
                                      accessibilityLabel={schema.label}
                                    />
                                  </View>
                                  <VFShowMoreText
                                    text={schema.info}
                                    numberOfLines={0}
                                    showMoreText="dashboard_group_component_show_more"
                                    showLessText="dashboard_group_component_show_less"
                                    style={styles.capMoreText}
                                  />
                                </View>
                              )
                            })}
                            {isDataCapDisabled && <View style={styles.overlayContainer(theme)}>
                              <VFLottieView
                                style={styles.overlayLoader}
                                autoPlay
                                loop
                              />
                            </View>}
                          </Card>}
                      </>
                )}
                {Dimensions.get('window').width > 700 ?
                  <_Modal
                    isVisible={showDataCapModal.boolean}
                    backdropOpacity={0.5}
                    onSwipeComplete={() => {
                      setShowDataCapModal({
                        boolean: false,
                        description: ''
                      })
                      navigation.goBack()
                    }}
                    onBackdropPress={() => {
                      setShowDataCapModal({
                        boolean: false,
                        description: ''
                      })
                      navigation.goBack()
                    }}
                    propagateSwipe
                    hideModalContentWhileAnimating
                    hardwareAccelerated
                    backdropTransitionOutTiming={0}
                    style={{
                      justifyContent: 'flex-end',
                      margin: '0 !important',
                      minWidth: 1920,
                      maxWidth: 1920,
                    }}
                  >
                    <View
                      style={{
                        minWidth: '600px',
                        maxWidth: '600px',
                        height: 'auto',
                        backgroundColor: theme.colors.quickActionGradientColors.secondary,
                        margin: 'auto',
                        padding: '20px',
                        paddingTop: null,
                        borderRadius: '7px',
                      }}
                      onStartShouldSetResponder={() => true}>
                      <View style={styles.modalIconContainer}>
                        <Icon
                          name={Images.ic_WarningHiLight_Theme}
                          size={120}
                          style={styles.modalIcon}
                        />
                      </View>
                      <View style={styles.modalInfoContainer}>
                        <VFText
                          i18nKey={
                            showDataCapModal.description
                          }
                          style={styles.modalText}
                        />
                      </View>
                      <VFButton
                        title={'roaming_close'}
                        style={styles.modalButton}
                        onPress={() => {
                          setShowDataCapModal({
                            boolean: false,
                            description: ''
                          })
                          navigation.goBack()
                        }}
                      />
                    </View>
                  </_Modal> :
                  <QuickActionMenu
                    isVisible={showDataCapModal.boolean}
                    closeModal={() => {
                      setShowDataCapModal({
                        boolean: false,
                        description: ''
                      })
                      navigation.goBack()
                    }}
                    title={'roaming_settings_data_cap'}
                    type="secondary"
                    withCloseButton={true}
                    headerStyle={styles.headerStyle}
                  >
                    <View style={styles.modalIconContainer}>
                      <Icon
                        name={Images.ic_WarningHiLight_Theme}
                        size={120}
                        style={styles.modalIcon}
                      />
                    </View>
                    <View style={styles.modalInfoContainer}>
                      <VFText
                        i18nKey={
                          showDataCapModal.description
                        }
                        style={styles.modalText}
                      />
                    </View>
                    <VFButton
                      title={'roaming_close'}
                      style={styles.modalButton}
                      onPress={() => {
                        setShowDataCapModal({
                          boolean: false,
                          description: ''
                        })
                        navigation.goBack()
                      }}
                    />
                  </QuickActionMenu>}
              </View>
            )}
          </View>
        )}

        {renderScreen === LOCATION_SETTINGS && (
          <InfoOverlay
            title={'roaming_settings_locationPermission_steps_title'}
            icon={Images.ic_roamingLocation}
            description={
              'roaming_settings_locationPermission_steps_description'
            }
            steps={locationSteps}
            onPress={() => {
              setRenderScreen(SETTINGS)
            }}
            withButton={true}
          />
        )}

        {renderScreen === DEVICE_SETTINGS && (
          <InfoOverlay
            title={'roaming_settings_device_settings_title'}
            icon={Images.ic_roamingRedTail}
            description={
              'roaming_settings_device_settings_how_to_enable_roaming_title'
            }
            steps={roamingSteps}
            onPress={() => {
              setRenderScreen(SETTINGS)
            }}
            withButton
            iconSize={120}
          />
        )}
      </div>
    </VFScreen>
  )
}

RoamingSettings.propTypes = {
  navigation: PropTypes.object
}

export default RoamingSettings
