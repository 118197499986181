import {actionTypes} from './VfHeartsCollection.actionTypes'

function userState(state = {loading: false, data: null}, action = {}) {
  switch (action.type) {
    case actionTypes.SUCCESS_PROFILE:
      return {
        ...state,
        loading: false,
        error: null,
        data: {
          ...state.data,
          ...action.payload,
        },
      }

    case actionTypes.ERROR_PROFILE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case actionTypes.REQUEST_PROFILE:
      return {
        ...state,
        loading: true,
      }

    case actionTypes.INITIAL_PROFILE:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          coins: action.payload.coins,
          status: action.payload.status,
          created: '',
        },
      }

    default:
      return state
  }
}

function allLoveBoxItems(
  state = {loading: false, data: null, error: null},
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_ALL_ITEMS:
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_ALL_ITEMS:
      return {
        ...state,
        loading: false,
        data: action.payload.products,
        error: null,
      }

    case actionTypes.ERROR_ALL_ITEMS:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload.error,
      }

    default:
      return state
  }
}

function plainLoveBoxProducts(state = {}, action = {}) {
  if(action.type == actionTypes.SUCCESS_PLAIN_PRODUCTS){
    return {
      ...state,
      ...action.payload,
    }
  }
  
  return state
}

function redeemLbItem(
  state = {loading: false, isSuccess: false, data: null, error: null},
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_LB_REDEEM_HANDLE:
      return {
        ...state,
        loading: true,
        isSuccess: false,
        data: null,
        error: null,
      }
    case actionTypes.SUCCESS_LB_REDEEM_HANDLE:
      return {
        ...state,
        loading: false,
        isSuccess: true,
        data: action.payload,
        error: null,
      }
    case actionTypes.ERROR_LB_REDEEM_HANDLE:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        data: null,
        error: action.payload,
      }
    case actionTypes.RESET_LB_REDEEM:
      return {
        loading: false,
        isSuccess: false,
        data: null,
        error: null,
      }
    default:
      return state
  }
}

function infoState(
  state = {
    isActive: false,
    goBack: false,
  },
  action = {},
) {
  switch (action.type) {
    case actionTypes.ACTIVATE_INFO:
      return {
        ...state,
        isActive: true,
        goBack: action.payload,
      }

    case actionTypes.DEACTIVATE_INFO:
      return {
        ...state,
        isActive: false,
        goBack: false,
      }
    default:
      return state
  }
}

export {userState, allLoveBoxItems, plainLoveBoxProducts, redeemLbItem, infoState}
