import { StyleSheet, Platform } from 'react-native'
import { PlatformUtils } from '@vfgroup-oneplatform/foundation/Utils'
import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

const isIPhoneWithNotch = PlatformUtils.isIPhoneDeviceWithNotch()

export default StyleSheet.create({
  scrollViewContentContainer: {
    marginHorizontal: 12,
    marginTop: Platform.OS === 'ios' ? (isIPhoneWithNotch ? 39 : 20) : 39,
    paddingBottom: 150
  },
  scrollViewContentContainerWithTray: {
    paddingBottom: Platform.OS === 'ios' ? (isIPhoneWithNotch ? 145 : 110) : 145
  },
  noCardText: theme => ({
    textAlign: 'center',
    color: theme.colors.textColorTwo,
    fontSize: Fonts.size.h6,
    marginTop: 10,
    fontFamily: Fonts.type.VFFont
  }),
})
