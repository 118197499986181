import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  flex1: {
    flex: 1,
    borderRadius: 6,
    overflow: 'hidden'
  },
  containerStyle: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  footerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 8,
  },
  resetTextStyle: {
    fontSize: 16,
    fontFamily: 'Vodafone Rg',
    letterSpacing: 0,
    color: 'white',
    paddingRight: 10
  },
})


export default styles
