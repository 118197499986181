import { Dimensions, Platform, StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 6,
    overflow: 'hidden',
    cursor: 'pointer',
  },
  image: {
    flex: 1,
    padding: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 20 : 10,
  }
})
