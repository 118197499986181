import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Platform, View, BackHandler, Dimensions, ScrollView, Pressable, Image, useWindowDimensions } from 'react-native'
import PropTypes from 'prop-types'
import { TrayHoc } from '@vfgroup-oneplatform/framework/Dashboard/Tray'
import DashboardFeatureComponent from '@vfgroup-oneplatform/framework/Dashboard'
import { setLoadingConfigObject, shimmerPlaceholderConfig } from '@vfgroup-oneplatform/foundation/Components/Shimmer/Shimmer.utils'
import { VFText, VFButton, VFBackground, VFOverlay, QuickActionMenu, Icon } from '@vfgroup-oneplatform/foundation/Components'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
// import { UrbanAirship } from 'urbanairship-react-native'


import { TRAY_TYPES } from '@vfgroup-oneplatform/framework/Dashboard/Tray'

import { useTheme, Colors, Images as foundationImages, } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { useFocusEffect, useNavigation } from '@react-navigation/native'

import { cloneDeep as _cloneDeep, first } from 'lodash'

import { useDispatch, useSelector } from 'react-redux'

import MainComponent from '../../Group/Components/Dashboard/MainComponent'
import SecondaryComponent from '../../Group/Components/Dashboard/SecondaryComponent/SecondaryComponent'
import ThirdComponent from '../../Group/Components/Dashboard/ThirdComponent/ThirdComponent'
import FourthComponent from '../../Group/Components/Dashboard/FourthComponent/FourthComponent'

import DiscoverCarousel from '../../Group/Components/DiscoverCarousel'
import VodafoneTV from '../../Group/Components/VideoPlayer/VideoPlayer'
import EditorialCard from '../../Group/Components/EditorialCard'

import { getMsisdnData, isShownToday, setMsisdnData, setShownToday } from '../../Utils'

import { Images as groupImages } from '../../Group/Themes'

import Payments from '../Payments'
import Refill from '../Refill'
import DataSharing from '../DataSharing'

import PrivacyPermissionsOverlay from '../PrivacyPermissions'


import { Actions, actionTypes } from './Dashboard.Actions'
import launchLoginScreen from '../../Utils/LaunchLogin'
import { Actions as LandingScreenActions } from '../LandingScreen/LandingScreen.Actions'
import ReduxCache from '../../Utils/ReduxCache'
import { isFixUser } from '../../Utils'

import DashboardDummyData from './Dashboard.dummy'

import { styles } from './Dashboard.Styles'

import { getThemeImages } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { Images, Images as frameWorkImages } from '@vfgroup-oneplatform/framework/Themes'


import Modal from '../../../node_modules/react-native-modal'

import { Billing as BillingService, GetBalance, RefreshToken } from '../../Services'

import NavigationService from '../../Navigation/NavigationService'
import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import useNetwork from './useNetwork'

import FixManualProduct from '../FixManualProduct'

import FixPurchaseCoverage from '../FixPurchaseCoverage'

import useDashboard from './hooks/useDashboard'
import useDashboardMenu from './hooks/useDashboardMenu'
import useDeepLink from './hooks/useDeepLink'
import useTooltip from './hooks/useTooltips'
import images from '@vfgroup-oneplatform/framework/Themes/Images'


import Stories from '../Story/Stories'
import ThemeImages from '../../Themes/Images'
import Story from '../Story/Story'
import { setStoriesInStore } from '../Story/Story.Actions'
import TransparentPopup from '../TransparentPopup/TransparentPopup'

import BusinessOverviewButton from '../../Group/Components/BusinessOverviewButton'
import AccountSwitch, { AccountSwitchButton } from '../../Group/Components/AccountSwitch'
import DashboardBackground from '../../Group/Components/Dashboard/DashboardBackground'
import { store } from '../../Redux'


const GuestToLogin = ({ isVisible, toggleModal, onLogin, onRegister }) => {

  return (
    <Modal
      isVisible={isVisible}
      backdropOpacity={0.9}
      onBackdropPress={toggleModal}
      propagateSwipe
      hideModalContentWhileAnimating
      hardwareAccelerated
      backdropTransitionOutTiming={0}
      backdropColor={'#171616'}
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 !important',
        minWidth: 1920,
        maxWidth: 1920,
        flexDirection: 'column',
        flexBasis: 'flex-start',
      }}
    >

      <TransparentPopup />
    </Modal>
  )

}


const ConfirmLogout = ({ isVisible, toggleModal, onConfirm }) => {
  const theme = useTheme()
  const logout = async () => {
    ReduxCache.clear()
    window.localStorage.setItem('isLoggedInUAT', 'false')
    window.localStorage.setItem('isLoggedIn', 'false')
    NavigationService.navigateWithResetAction('PreLogin')
  }

  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
    return (
      <Modal
        isVisible={isVisible}
        backdropOpacity={0.5}
        onSwipeComplete={() => close()}
        onBackdropPress={() => close()}
        propagateSwipe
        hideModalContentWhileAnimating
        hardwareAccelerated
        backdropTransitionOutTiming={0}
        style={{
          justifyContent: 'flex-end',
          margin: '0 !important',
          minWidth: 1920,
          maxWidth: 1920,
        }}
      >
        <View style={{
          width: 'auto',
          height: 'auto',
          backgroundColor: theme.colors.quickActionGradientColors.secondary,
          margin: 'auto',
          padding: '20px',
          borderRadius: "7px",
        }}
        >
          <View style={styles.logoutModalContainer}>
            <VFText
              i18nKey='logout_description'
              style={[styles.logoutConfirmTitle, { marginTop: 0 }]}
            />
            <VFButton
              title="logout_button"
              style={styles.logoutConfirmButton}
              onPress={logout}
            />
            <VFButton
              title="logout_cancel_button"
              type="secondary"
              style={styles.logoutConfirmButton}
              onPress={toggleModal}
            />
          </View>
        </View>
      </Modal>
    )
  }

  return (
    <QuickActionMenu
      isVisible={isVisible}
      toggleModal={toggleModal}
      type="secondary"
      title="logout_title"
    >
      <View style={styles.logoutModalContainer}>
        <VFText
          i18nKey='logout_description'
          style={styles.logoutConfirmTitle}
        />
        <VFButton
          title="logout_button"
          style={styles.logoutConfirmButton}
          onPress={logout}
        />
        <VFButton
          title="logout_cancel_button"
          type="secondary"
          style={styles.logoutConfirmButton}
          onPress={toggleModal}
        />
      </View>
    </QuickActionMenu>
  )
}
ConfirmLogout.propTypes = {
  isVisible: PropTypes.bool,
  toggleModal: PropTypes.func,
  onConfirm: PropTypes.func,
}

export const StoriesModal = ({
  isOpen,
  close,
  updateBubble,
  ...props
}) => {
  const { width } = Dimensions.get('window')

  return (
    <Modal
      isVisible={isOpen}
      backdropOpacity={0.9}
      propagateSwipe
      hideModalContentWhileAnimating
      hardwareAccelerated
      backdropTransitionOutTiming={0}
      backdropColor={'#171616'}
      style={{
        justifyContent: 'flex-end',
        margin: '0 !important',
        minWidth: width,
        maxWidth: width,
        flexDirection: 'column',
        flexBasis: 'flex-start'
      }}
    >
      <Pressable onPress={close} style={{ top: 30, right: 30, position: 'fixed' }}>
        <Icon name={ThemeImages.close_white} color='red' size={32} />
      </Pressable>
      <View style={{ width: '50%', margin: 'auto' }}>
        <Story
          {...props}
          updateBubble={updateBubble}
          close={close}
        />
      </View>
    </Modal>
  )
}

const components = {
  MainCardComponent: MainComponent,
  SecondaryCardComponent: SecondaryComponent,
  ThirdCardComponent: ThirdComponent,
  FourthCardComponent: FourthComponent,
  DiscoverCarousel: DiscoverCarousel,
  VodafoneTV: VodafoneTV,
  EditorialCard: EditorialCard,
}

const dashboardLoadingConfig = {
  mainCardsLoadingConfig: [
    {
      left: setLoadingConfigObject('circle', 1, 10, { marginTop: 10 }),
      main: setLoadingConfigObject('line', 1, 10, { marginTop: 10 }),
      right: setLoadingConfigObject('line', 1, 10, { marginTop: 10 }),
      style: { marginTop: 10 }
    }
  ],
  secCardsLoadingConfig: [
    {
      left: setLoadingConfigObject('circle', 1, 10, { marginTop: 10 }),
      main: null,
      right: setLoadingConfigObject('line', 1, 10, { marginTop: 10 }),
      style: { marginTop: 10 }
    }
  ],
  adCardsLoadingConfig: [
    {
      left: setLoadingConfigObject('circle', 1, 10, { marginTop: 10 }),
      main: null,
      right: setLoadingConfigObject('line', 1, 10, { marginTop: 10 }),
      style: { marginTop: 10 }
    }
  ]
}

const discoverCardLoadingConfig = [{
  left: null,
  main: null,
  right: null
}]

const lastUpdatedLoadingConfig = [{
  main: null,
  left: setLoadingConfigObject('line', 1, 50, { marginTop: 10 }),
  right: null
}]


const menuMapping = {
  'myoffers': 'Catalogue',
  'shake': 'Shake',
  'redBoxWebview': 'VfHearts',
  //  'netPerformSpeedtest': 'SpeedChecker',
  'reward': 'Demo',
  'billing': 'Billing',
  'recharge_my': 'Payments',
  'recharge_other': 'Payments',
  'GigaFamily': 'GigaFamily',
  'payment': 'PaymentMethods',
  'migration': 'Migration',
  'refill': 'Refill',
  'promo_live': 'Promo',
  'promo': 'Promo',
  'callMeBack': 'CallMeBack',
  // 'netPerformSpeedtest': 'SpeedChecker',
  'myWeekend': 'VDayOffers',
  'fix_renewal': 'FixBundles',
  'fix_bill': 'FixBilling',
  'fixProductsAndServices': 'FixProductsAndServices',
  'affiliate': 'Recommend',
}

const monthTranslations = {
  al: {
    'January': 'Janar',
    'February': 'Shkurt',
    'March': 'Mars',
    'April': 'Prill',
    'May': 'Maj',
    'June': 'Qershor',
    'July': 'Korrik',
    'August': 'Gusht',
    'September': 'Shtator',
    'October': 'Tetor',
    'November': 'Nëntor',
    'December': 'Dhjetor',
    'Jan': 'Jan',
    'Feb': 'Shku',
    'Mar': 'Mar',
    'Apr': 'Pri',
    'Jun': 'Qer',
    'Jul': 'Korr',
    'Aug': 'Gush',
    'Sep': 'Shta',
    'Oct': 'Tet',
    'Nov': 'Nën',
    'Dec': 'Dhje',
    'Janar': 'Janarit',
    'Shkurt': 'Shkurtit',
    'Mars': 'Marsit',
    'Prill': 'Prillit',
    'Maj': 'Majit',
    'Qershor': 'Qershorit',
    'Korrik': 'Korrikut',
    'Gusht': 'Gushtit',
    'Shtator': 'Shtatorit',
    'Tetor': 'Tetorit',
    'Nëntor': 'Nëntorit',
    'Dhjetor': 'Dhjetorit'
  },
  en: {
    'January': 'January',
    'February': 'February',
    'March': 'March',
    'April': 'April',
    'May': 'May',
    'June': 'June',
    'July': 'July',
    'August': 'August',
    'September': 'September',
    'October': 'October',
    'November': 'November',
    'December': 'December',
    'Jan': 'Jan',
    'Feb': 'Feb',
    'Mar': 'Mar',
    'Apr': 'Apr',
    'Jun': 'Jun',
    'Jul': 'Jul',
    'Aug': 'Aug',
    'Sep': 'Sep',
    'Oct': 'Oct',
    'Nov': 'Nov',
    'Dec': 'Dec'
  }
}

const launchScreenAnimationTimeout = 5000

const OnlineStatus = ({ isOnline }) => {
  if (!isOnline) {
    return (
      <View style={{ alignSelf: 'flex-end', flexDirection: 'row', marginVertical: 12, alignItems: 'center' }}>
        <View
          style={{ backgroundColor: 'red', width: 24, height: 24, borderRadius: '50%', marginRight: 12 }}
        />
        <VFText i18nKey='Offline' style={{ fontSize: 18, fontWeight: 700 }} />
      </View>
    )
  }
  return (
    <View style={{ alignSelf: 'flex-end', flexDirection: 'row', marginVertical: 12, alignItems: 'center' }}>
      <View
        style={{ backgroundColor: 'green', width: 24, height: 24, borderRadius: '50%', marginRight: 12 }}
      />
      <VFText i18nKey='Online' style={{ fontSize: 18, fontWeight: 700 }} />
    </View>
  )
}

function Dashboard({ isConnected }) {


  const { navigate } = useNavigation()

  const { toolTipsModalRef } = useTooltip()
  const theme = useTheme()
  const themeImages = getThemeImages(theme.name)
  const { width } = useWindowDimensions()
  const insets = useSafeAreaInsets()
  const flatlistRef = useRef()
  // const [isVideoVisible, setIsVideoVisible] = useState(false)
  // const videoPosition = useRef({
  //   bottom: 50,
  //   right: 50
  // })



  const {
    refreshDashboard,
    toggleDataShare,
    onFinishPreferences,
    showPayments,
    showRefill,
    showDataShare,
    isThirdParty,
    showPreferences,
    dataShareActiveTab,
    userName,
    userBalance,
    setUserBalance,
    shouldRenderPreferences,
    balanceReduxData,
    language,
    setShowPayments,
    setShowRefill,
    setShowDataShare,
    setIsThirdParty,
    setShowPreferences,
    unreadMessage,
    setDataShareActiveTab,
    setShowTrayModal,
    showTrayModal,
    isStoryVisible,
    setIsStoryVisible,
    statusBarColor,
    setStatusBarColor,
    statusBarBackground,
    setStatusBarBackground,
    getStatusBarColor,
    getStatusBarBackground,
    lastUpdateTime,
    setHeaderHeight,
    headerHeight
  } = useDashboard(theme)

  const {
    components,
    dashboardLoadingConfig,
    discoverCardLoadingConfig,
    lastUpdatedLoadingConfig,
    getMyAccountTray,
    getProductsTray,
    getDashboardData,
    handlePressSubTrayItems,
    handleBottomMenuNavigation,
    error,
    showLogoutConfirm,
    showDiscoverModal,
    discoverModalData,
    shouldShowFix,
    showFixManualProduct,
    navigateToNative,
    isLoading,
    setShowLogoutModal,
    toggleDicoveryModal,
    toggleFix,
    toggleManualLine,
    openTobiUrl,
    tobiURL,
    tobiIcon,
    toggleRefill,
    sideBar,
    setStoriesProps,
    storiesProps,
    accountSwitchVisible,
    toggleAccountSwitch,
    businessAccount,
    groupImages,
    trayOptionalItems,
    getNotificationCount,
  } = useDashboardMenu({ userName, setShowPayments, setShowRefill, setShowDataShare, setIsThirdParty, userBalance, setUserBalance, })


  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(Actions?.updateUserSideBar(sideBar.current))
  }, [sideBar.current.length])

  const updateRef = (item, index = null) => {
    if (index !== null) {
      sideBar.current.splice(index, 1, item)
    } else {
      sideBar.current = [...sideBar.current, item]
    }
  }

  const onPressTobi = () => {
    navigate('WebView', { url: tobiURL, isReact: true, incognito: true })
  }

  const calculateTotalUsers = () => {
    if (balanceReduxData?.data) {
      let totalUsers = 0

      Object.keys(balanceReduxData?.data).filter(key => {
        if (key.includes('businessplan')) {
          totalUsers = totalUsers + balanceReduxData?.data[key]?.details?.amount?.$
        }
      })

      return totalUsers
    } else {
      return '-'
    }
  }

  const isFocused = useRef()

  const handleScroll = (dir) => {
    if (dir === '-') {
      document.getElementById('scrollId').scrollBy({ top: 60, behavior: 'smooth' })
    }

    if (dir === '+') {
      document.getElementById('scrollId').scrollBy({ top: -60, behavior: 'smooth' })
    }

  }

  window.addEventListener('keydown', event => {
    if (event.key == 'ArrowUp') {
      handleScroll('+')
    } else if (event.key == 'ArrowDown') {
      handleScroll('-')
    }
  })


  return (
    <DashboardBackground statusBarBackground={statusBarBackground}>
      <View style={styles.dashboardFeatureWrapper(theme)}>
        {Platform.OS === 'web' && Dimensions.get('window').width > 700 ?
          <View style={{
            width: '100vw',
            height: 81,
            backgroundColor: theme.colors.backgroundColorSix,
            paddingLeft: 32,
            paddingTop: 16,
            justifyContent: 'space-between',
            shadowColor: '#171717',
            shadowOffset: { width: -2, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 3,
            marginBottom: 10,
            marginLeft: '-20px',
            flexDirection: 'row'
          }}>
            <Pressable onPress={() => { }}>
              <Icon
                type="image"
                size={48}
                name={images.logo_vf_red}
              />
            </Pressable>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              {/* {!isOnline &&
                <View style={{ marginRight: 20 }}>
                  <Icon size={28} type='image' name={Images.icNetworkMap} />
                  <View style={{ height: 8, width: 8, borderRadius: '50%', marginLeft: '20px', marginTop: '-20px', backgroundColor: '#E60000', position: 'absolute', top: 20 }} />
                </View>
              } */}
              {ReduxCache.getUserType() !== 'guest' && <Pressable
                onPress={() => setShowLogoutModal(true)}
                style={{
                  width: 48,
                  height: 48,
                  borderRadius: '50%',
                  backgroundColor: '#007C92',
                  alignItems: 'center',
                  marginRight: 16,
                  justifyContent: 'center'
                }}>
                <VFText i18nKey={userName === '' ? '' : userName} style={{ color: 'white', fontSize: 18 }} />
              </Pressable>}
            </View>
          </View> : null}

        {Platform.OS === 'web' && Dimensions.get('window').width > 700 && !isLoading &&
          <Pressable onPress={openTobiUrl} style={{
            width: 100,
            height: 100,
            borderRadius: '50%',
            backgroundColor: 'white',
            alignSelf: 'flex-end',
            marginTop: 30,
            marginRight: 20,
            alignItems: 'center',
            position: 'fixed',
            bottom: 50,
            right: 20,
            zIndex: 9,
            elevation: 5,
            shadowColor: 'rgba(0, 0, 0, 0.3)',
            shadowOffset: {
              width: 0,
              height: 1
            },
            shadowRadius: 4,
            shadowOpacity: 1,
          }}>
            <Image
              source={frameWorkImages.toBi}
              style={{ width: 100, height: 100, alignSelf: 'center' }}
              accessibilityLabel={'trayTobiIcon'}
              testID={'trayTobiIcon'}
            />
          </Pressable>}

        {Platform.OS === 'web' && Dimensions.get('window').width > 700 && <Stories
          textColor={theme.colors.textColorTwo}
          bubbleSize={Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 65 : 45}
          navigationLocation={'Dashboard'}
          containerStyle={styles.storiesContainer}
          onPress={setStoriesProps}
          openStories={setIsStoryVisible}
        />}

        {Platform.OS === 'web' && Dimensions.get('window').width > 700 ?
          <ScrollView
            scrollEnabled={!isLoading || !balanceReduxData.loading}
            scrollEventThrottle={32}
            nativeID='scrollId'
          >
            {/* <VFText i18nKey={gretting} style={{ fontSize: 18, lineHeight: 24, fontWeight: 400, color: '#333333', marginVertical: 10, alignSelf: 'flex-end' }} /> */}
            {/* <OnlineStatus isOnline={isOnline} /> */}
            <DashboardFeatureComponent
              dashboardConfig={{
                isLoading: isLoading || balanceReduxData.loading,
                dashboardData: isLoading || balanceReduxData.loading ? DashboardDummyData : getDashboardData(),
                componentList: components,
                // handleOnPressCard: (key) => {
                //   if (key !== 'VodafoneTV') {
                //     navigate('ProductsAndServices')
                //   }
                // },
                handleOnPressDiscoverItem: navigateToNative,
                dashboardAnimationStyles: { marginTop: 10 }
              }}
              // isFullError={true}
              onDashboardFailedTryAgainPress={refreshDashboard}
              isDashboardFailed={(!isLoading && !balanceReduxData.loading) && (error || balanceReduxData.error)}
              EIOConfig={{
                data: [{ status: 0 }],
                onChange: () => { },
                userName: userName,
                icons: { ...foundationImages },
                type: 'primary',
                // title: 'Good morning %s',
                expandable: false
              }}
              images={{ ...foundationImages, ...groupImages }}
              onRefresh={refreshDashboard}
              lastUpdateTime={new Date()}
              // refreshInterval={150000}
              dashboardLoadingConfig={dashboardLoadingConfig}
              discoverCardLoadingConfig={discoverCardLoadingConfig}
              lastUpdatedLoadingConfig={lastUpdatedLoadingConfig}
              shimmerPlaceholderConfig={shimmerPlaceholderConfig}
              navigate={navigate}
              products={getProductsTray()}
              account={getMyAccountTray()}
              tobiURL={tobiURL}
              sideBar={sideBar}
              updateRef={updateRef}
              theme={theme}
              totalUsers={calculateTotalUsers()}
              accountSubtitle={userName || ReduxCache.getMsisdn()}
              toggleAccountSwitch={toggleAccountSwitch}
            // setIsVideoVisible={setIsVideoVisible}
            />
          </ScrollView>
          :
          <DashboardFeatureComponent
            dashboardConfig={{
              isLoading: isLoading || balanceReduxData.loading,
              dashboardData: isLoading || balanceReduxData.loading ? DashboardDummyData : getDashboardData(),
              componentList: components,
              handleOnPressCard: (key) => {
                if (key !== 'VodafoneTV') {
                  navigate('ProductsAndServices')
                }
              },
              handleOnPressDiscoverItem: navigateToNative,
              dashboardAnimationStyles: { marginTop: 10, }
            }}
            // isFullError={true}
            onDashboardFailedTryAgainPress={refreshDashboard}
            isDashboardFailed={(!isLoading && !balanceReduxData.loading) && (error || balanceReduxData.error)}
            EIOConfig={{
              data: [{ status: 0 }],
              onChange: () => { },
              userName: userName,
              icons: { ...foundationImages },
              type: 'primary',
              // title: 'Good morning %s',
              expandable: false,
              RenderComponent: () => {
                return (
                  <Stories
                    textColor={Colors.white}
                    bubbleSize={Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 65 : 70}
                    navigationLocation={'Dashboard'}
                    containerStyle={styles.storiesContainer}
                    onPress={setStoriesProps}
                    openStories={setIsStoryVisible}
                  />
                )
              }
            }}
            withDiscoverSnapProps={{
              setFlatListRef: flatlistRef,
              // onScroll: ev => {
              //   const { contentOffset } = ev
              //   if (contentOffset?.y >= (headerHeight + insets.top)) {
              //     setStatusBarColor(theme.name === 'light' ? 'dark-content' : 'light-content')
              //     setStatusBarBackground(theme.colors.background.primary[0])
              //   } else {
              //     setStatusBarColor(getStatusBarColor())
              //     setStatusBarBackground(getStatusBarBackground())
              //   }
              // }
            }}
            images={{ ...foundationImages, ...groupImages }}
            onRefresh={refreshDashboard}
            lastUpdateTime={new Date()}
            // refreshInterval={150000}
            dashboardLoadingConfig={dashboardLoadingConfig}
            discoverCardLoadingConfig={discoverCardLoadingConfig}
            lastUpdatedLoadingConfig={lastUpdatedLoadingConfig}
            shimmerPlaceholderConfig={shimmerPlaceholderConfig}
            navigate={navigate}
            products={getProductsTray()}
            account={getMyAccountTray()}

            tobiURL={tobiURL}
          />
        }

      </View>


      {showPayments && <Payments isThirdParty={isThirdParty} setShowPayments={setShowPayments} isVisible={showPayments} />}
      {
        showRefill && <Refill
          showPayments={() => {
            toggleRefill(false)
            togglePayments(true)
          }}
          setShowRefill={toggleRefill}
          balance={userBalance.balance_amount?.toString()}
          isVisible={showRefill} />
      }

      {
        shouldRenderPreferences && <PrivacyPermissionsOverlay
          isVisible={showPreferences}
          setIsVisible={setShowPreferences}
          onFinish={onFinishPreferences}
        />
      }

      {showDataShare && <DataSharing isVisible={showDataShare} toggleModal={toggleDataShare} />}

      <VFOverlay
        isVisible={discoverModalData.isModalVisible}
        title={discoverModalData.title}
        description={discoverModalData.description}
        buttonTitle={discoverModalData.buttonText}
        image={{ uri: discoverModalData.modalIcon }}
        onClosePress={toggleDicoveryModal}
        onButtonPress={() => {
          toggleDicoveryModal()
          discoverModalData.onPress(discoverModalData)
        }
        }
        imageProps={{
          style: {
            width: 200,
            height: 200
          }
        }}
      />

      <ConfirmLogout
        isVisible={showLogoutConfirm}
        toggleModal={() => setShowLogoutModal(false)}
        onConfirm={() => {
          dispatch(Actions.clearSidebar())
          launchLoginScreen()
        }}
      />
      {Platform.OS === 'web' && Dimensions.get('window').width > 700 && <StoriesModal
        isOpen={isStoryVisible}
        close={() => setIsStoryVisible(false)}
        {...storiesProps}
        updateBubble={setStoriesProps}
      />}

      {showFixManualProduct && <FixManualProduct
        isVisible={showFixManualProduct}
        toggleModal={toggleManualLine}
        refreshDashboard={refreshDashboard}
      />}

      {
        shouldShowFix && <FixPurchaseCoverage
          isVisible={shouldShowFix}
          toggleModal={toggleFix}
        />
      }

      {accountSwitchVisible && <AccountSwitch
        isVisible={accountSwitchVisible}
        onClose={() => {
          toggleAccountSwitch()
          flatlistRef?.current?.scrollToOffset(0, true)
        }}
      />}

      {/* {isVideoVisible && <div
        onDragEnd={(e) => {
          console.log(e);
        }}
        style={{
          width: 600,
          height: 600,
          borderRadius: 7,
          'box-shadow': 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
          position: 'absolute',
          right: videoPosition.current.right,
          bottom: videoPosition.current.bottom

        }}
      >

      </div>} */}


      {
        Platform.OS === 'web' && Dimensions.get('window').width < 700 &&
        <TrayHoc
          toggleModal={() => setShowTrayModal(!showTrayModal)}
          isModalVisible={showTrayModal}
          trayType={TRAY_TYPES.MAIN}
          onPressSubTrayItems={handlePressSubTrayItems}
          data={{
            'products': getProductsTray(),
            'account': getMyAccountTray(),
            'optionalItems': trayOptionalItems
          }}
          notificationsCount={
            language == 'al' ? { 'Llogaria ime': { 'Njoftime': unreadMessage } } : { 'My Account': { 'My Messages': unreadMessage } }
          }
          images={{ ...foundationImages, ...groupImages }}
          onPressTrayItem={handleBottomMenuNavigation}
          trayTabStyles={{ title: { minWidth: 100 } }}
          // call toggle Modal or any other desired action
          // onPressSubTrayItems={this.handlePressSubTrayItems}
          // any action handling can be implemented
          // notificationsCount={this.state.notificationsCount}
          onPressTobi={onPressTobi}
          // trayIcon={this.renderTrayTobiLessIcon()}

          renderSubTrayHeaderBeforeDescription={({ subTrayTitle }, _, toggleModal) => {
            if (isLoading || balanceReduxData.loading) { return null }

            if ((!isLoading && !balanceReduxData.loading) && (error || balanceReduxData.error)) { return null }

            if (subTrayTitle === 'My Products' || subTrayTitle === 'Produktet e mia') {
              return <BusinessOverviewButton
                onPress={() => {
                  navigate('BusinessOverview')
                  toggleModal()
                }}
                businessName={businessAccount?.businessName}
              />
            }

            if (subTrayTitle === 'My Account' || subTrayTitle === 'Llogaria ime') {
              return <AccountSwitchButton
                onPress={() => {
                  toggleModal()
                  toggleAccountSwitch()
                }}
              />
            }
          }}
        />
      }
    </DashboardBackground>
  )
}

export default Dashboard
