import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  details: {
    width: '100%',
    height: 160
  },
  go_white: {
    width: 25,
    height: 25,
    marginTop: 10,
    marginLeft: 10,
    transform: [{ rotate: '180deg' }]
  },
  content: {
    paddingVertical: 20,
    paddingHorizontal: 30
  },
  go: {
    width: 25,
    height: 25,
    marginTop: 10,
    marginLeft: 10,
    transform: [{ rotate: '90deg' }],
    alignSelf: 'center'
  }
})
