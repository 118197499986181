import React, { useState, useEffect } from 'react'

import { View, ImageBackground, useWindowDimensions, TouchableOpacity, StatusBar, Platform, Dimensions } from 'react-native'
import { VFButton, VFScreen, Icon, VFText } from '@vfgroup-oneplatform/foundation/Components'

import VFLottieLoadingScreen from '@vfgroup-oneplatform/foundation/Components/VFLottieLoadingScreen'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import PropTypes from 'prop-types'

import { v4 } from 'uuid'

import axios from 'axios'

import { useSelector } from 'react-redux'

import Contentloader, { Rect, Circle } from 'react-content-loader/native'

import CustomLanguageComponent from '../../Components/CustomLanguageChanger'
import Card from '../../Components/Card'

import launchLoginScreen from '../../Utils/LaunchLogin'
import NavigationService from '../../Navigation/NavigationService'
import Stories from '../Story/Stories'

import { Actions as LoginActions, actionTypes } from '../Login/Login.Actions'

import ReduxCache from '../../Utils/ReduxCache'
import { removeLocalKey } from '../../Services/Storage'

import { actionTypes as DashboardActionTypes } from '../Dashboard/Dashboard.Actions'
import { store } from '../../Redux'

import { StoriesModal } from '../Dashboard/Dashboard'

import Images from '../../Themes/Images'

import styles from './PreLogin.Styles.js'





const PreLogin = ({ route }) => {

  const width = Dimensions.get('window').width

  const theme = useTheme()

  const [loginState] = useState('logging')
  const [loading, setLoading] = useState(false)

  const { withoutGuestLogin } = route?.params ?? false

  const [, setLanguageChanger] = useState()

  const [storiesProps, setStoriesProps] = useState()
  const [isStoryVisible, setIsStoryVisible] = useState(false)
  const configurationsRedux = useSelector(state => state?.configurations)

  const preLoginItems = configurationsRedux?.pages?.find(
    item => item?.page === 'preLogin'
  )?.items

  const openLinks = link => {
    NavigationService.navigate('WebView', {
      url: link.text,
      title: link.value,
      isReact: true
    })
  }


  const loginGuest = async () => {
    await removeLocalKey('isLoggedIn')
    const guestIdentifier = 'guest_12345'
    const guestSpecific = 'guest_12345'
    const useGuestClientCredentials = true
    await LoginActions.loginWithDXL(guestIdentifier, guestSpecific, useGuestClientCredentials, store)
  }

  const handlePreloginGuestLogin = async () => {
    setLoading(true)
    try {
      await loginGuest()
      await store.dispatch({ type: DashboardActionTypes.BALANCE_SUCCESS, data: null })
    } catch (error) {
      launchLoginScreen()
    }
    setLoading(false)
  }

  const quickLinks = [
    { icon: 'vodafoneUnlimited', description: 'Vodafone Unlimited', url: 'https://www.vodafone.al/eshop/-vodafone-unlimited/' },
    { icon: 'mobile', description: 'Vodafone Next', url: 'https://www.vodafone.al/eshop/vodafone-next-plus/' },
    { icon: 'gigaFibra', description: 'GigaFibra', url: 'https://www.vodafone.al/gigafibra/' },
    { icon: 'gigaTV', description: 'GigaTv', url: 'https://www.vodafone.al/gigafibra/' },
  ]

  const onGuestLogin = () => {
    store.dispatch({ type: actionTypes.LOGIN_SUCCESS, data: { userType: 'guest' } })
    ReduxCache.set('auth.userType', 'guest')
    NavigationService.navigate('Dashboard')
  }

  useEffect(() => {
    if (!withoutGuestLogin || window.localStorage.getItem('isLoggedInUAT') !== 'true') {
      handlePreloginGuestLogin()
      return
    }
  }, [])


  if (loading && !withoutGuestLogin) {
    return <View style={styles.lottieContainer}>
      <VFLottieLoadingScreen />
    </View>
  }

  const loginWithVodafone = async () => {
    let url = ''
    let clientID = ''
    if (process.env.NODE_ENV === 'development') {
      url = 'http://localhost:3000'
      clientID = 'al_dev'
    } else {
      url = 'https://my.vodafone.al'
      clientID = 'AL_MVA_WEB_preprod'
    }
    // const tempTimeStamp = Date.now()
    const tempUUID = v4()
    const language = ReduxCache.getLanguage() == 'en' ? 'en' : 'al'
    const { data } = await axios.get('https://api-mobile-ci-vfal.vodafone.com/OAuth2/v2/preAuthorize', {
      headers: {
        'vf-source': 'mva',
        'Accept-Language': language
      }
    })

    // `https://hub.stagingref.external.nonprod.id-euc1.aws.cps.vodafone.com/oidc/authorize?client_id=${clientID}&scope=openid%20email%20phone%20profile%20offline_access&response_type=code&redirect_uri=${url}&acr_values=urn:vodafone:loa:gold&state=${tempTimeStamp}&nonce=${tempUUID}`

    // const URL = `https://hub.dev.external.nonprod.id-euc1.aws.cps.vodafone.com/oidc/authorize?client_id=${clientID}&scope=openid%20email%20phone%20profile%20offline_access&response_type=code&redirect_uri=${url}&acr_values=urn:vodafone:loa:gold&state=${tempTimeStamp}&nonce=${tempUUID}`
    // const URL = `https://hub.stagingref.external.nonprod.id-euc1.aws.cps.vodafone.com/oidc/authorize?client_id=${clientID}&scope=openid%20email%20phone%20profile%20offline_access&response_type=code&redirect_uri=${url}&acr_values=urn:vodafone:loa:silver&state=${data?.state}&nonce=${tempUUID}`
    // const URL = `https://hub.stagingref.external.nonprod.id-euc1.aws.cps.vodafone.com/oidc/authorize?client_id=${clientID}&scope=openid%20email%20phone%20profile%20offline_access&response_type=code&ui_locales=${language}&redirect_uri=${url}&acr_values=urn:vodafone:loa:gold&state=${data?.state}&nonce=${tempUUID}`

    if (data?.redirect_url) {
      window.location.href = data?.redirect_url
    }
  }

  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
    return (
      // isFocused &&
      <ImageBackground source={{ uri: Images.preLoginBackground.uri }} resizeMode="cover" style={styles.backgroundImage} >
        <View style={styles.welcomeContainer}>
          <VFText onPress={loginWithVodafone} i18nKey="prelogin_welcome" style={styles.welcomeMessage} />
          {/*eslint-disable-next-line react-native/no-inline-styles */}
          <CustomLanguageComponent containerStyle={{ marginTop: 0 }} languageChanger={setLanguageChanger} />
        </View>

        {/* Story section */}
        <View style={styles.storyContainer}>
          {loginState === 'error' ?
            <VFText i18nKey="Something went wrong" />
            :
            <Stories
              textColor={'white'}
              bubbleSize={Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 65 : 38}
              navigationLocation={'PreLogin'}
              containerStyle={styles.storiesContainer}
              onPress={setStoriesProps}
              openStories={() => { setIsStoryVisible(true) }}
            />
          }
        </View>

        {/* Card Section */}
        <Card style={styles.cardContainer(theme)}>
          <VFText i18nKey="prelogin_quick_links" style={styles.cardTitle} />
          <View style={[styles.quickLinksContainer, { flexWrap: 'wrap', gap: 24 }]}>
            {preLoginItems?.map((link, index) => {
              return (
                <View style={styles.singleLinkContainer(width)}>
                  <TouchableOpacity
                    onPress={() => openLinks(link)}
                    style={styles.singleLink}
                    activeOpacity={0.8}
                  >
                    {preLoginItems ? (
                      <>
                        <View style={styles.quickLinksIconContainer}>
                          <Icon
                            name={Images[link.key]}
                            size={30}
                            resizeMode={'contain'}
                          />
                        </View>
                        <View>
                          <VFText
                            i18nKey={link.value}
                            style={styles.quickLinksDescription}
                          />
                        </View>
                      </>
                    ) : (
                      <ContentLoader
                        speed={2}
                        width={60}
                        height={100}
                        backgroundColor={theme.colors.shimmerCenterColor}
                        foregroundColor={theme.colors.shimmerEdgesColor}
                      >
                        <Rect
                          x="0"
                          y="0"
                          rx="40"
                          ry="40"
                          width={60}
                          height={60}
                        />
                        <Rect
                          x="0"
                          y="80"
                          rx="4"
                          ry="4"
                          width={60}
                          height={15}
                        />
                      </ContentLoader>
                    )}
                  </TouchableOpacity>
                </View>
              )
            })}
          </View>

          <View style={styles.buttonsContainer(theme)}>
            <VFButton
              title={'prelogin_getStarted'}
              type={'primary'}
              // onPress={() => launchLoginScreen(false, false, false)}
              onPress={loginWithVodafone}
              allowFontScaling={true}
            />

            <TouchableOpacity
              style={styles.guestButtonContainer}
              onPress={onGuestLogin}
            >
              <VFText i18nKey={'prelogin_continue_as_guest'} style={styles.guestButtonText(theme)} />
            </TouchableOpacity>
          </View>
        </Card>


        <StoriesModal
          isOpen={isStoryVisible}
          close={() => setIsStoryVisible(false)}
          {...storiesProps}
          updateBubble={setStoriesProps}
        />

      </ImageBackground>
    )
  }

  return (
    // isFocused &&
    <VFScreen withStatusBar={false} withHeader={false}>
      <ImageBackground source={{ uri: Images.preLoginBackground.uri }} resizeMode="cover" style={styles.backgroundImage} >
        <View style={styles.welcomeContainer}>
          <VFText onPress={loginWithVodafone} i18nKey="prelogin_welcome" style={styles.welcomeMessage} />
          {/*eslint-disable-next-line react-native/no-inline-styles */}
          <CustomLanguageComponent containerStyle={{ marginTop: 0 }} languageChanger={setLanguageChanger} />
        </View>

        {/* Story section */}
        <View style={styles.storyContainer}>
          {loginState === 'error' ?
            <VFText i18nKey="Something went wrong" />
            :
            <Stories textColor={'white'} navigationLocation={'PreLogin'} />
          }
        </View>

        {/* Card Section */}
        <Card style={styles.cardContainer(theme)}>
          <VFText i18nKey="prelogin_quick_links" style={styles.cardTitle} />
          <View style={[styles.quickLinksContainer, { gap: 30, marginTop: 0 }]}>
            {preLoginItems?.map((link, index) => {
              return (
                <View style={styles.singleLinkContainer(width)}>
                  <TouchableOpacity
                    onPress={() => openLinks(link)}
                    style={styles.singleLink}
                    activeOpacity={0.8}
                  >
                    {preLoginItems ? (
                      <>
                        <View style={styles.quickLinksIconContainer}>
                          <Icon
                            name={Images[link.key]}
                            size={30}
                            resizeMode={'contain'}
                          />
                        </View>
                        <View>
                          <VFText
                            i18nKey={link.value}
                            style={styles.quickLinksDescription}
                          />
                        </View>
                      </>
                    ) : (
                      <ContentLoader
                        speed={2}
                        width={60}
                        height={100}
                        backgroundColor={theme.colors.shimmerCenterColor}
                        foregroundColor={theme.colors.shimmerEdgesColor}
                      >
                        <Rect
                          x="0"
                          y="0"
                          rx="40"
                          ry="40"
                          width={60}
                          height={60}
                        />
                        <Rect
                          x="0"
                          y="80"
                          rx="4"
                          ry="4"
                          width={60}
                          height={15}
                        />
                      </ContentLoader>
                    )}
                  </TouchableOpacity>
                </View>
              )
            })}
          </View>

          <View style={[styles.buttonsContainer(theme), { position: 'absolute', bottom: '-15px' }]}>
            <VFButton
              title={'prelogin_getStarted'}
              type={'primary'}
              // onPress={() => launchLoginScreen(false, false, false)}
              onPress={loginWithVodafone}
              allowFontScaling={true}
            />

            <TouchableOpacity
              style={styles.guestButtonContainer}
              onPress={onGuestLogin}
            >
              <VFText i18nKey={'prelogin_continue_as_guest'} style={styles.guestButtonText(theme)} />
            </TouchableOpacity>
          </View>
        </Card>

      </ImageBackground>
    </VFScreen>
  )
}

PreLogin.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.object
  })
}

export default PreLogin
