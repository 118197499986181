import {actionTypes} from './DataSharing.ActionTypes'

function balanceOptions(state = { loading: false, data: null }, action = {}) {
  switch (action.type) {
    case actionTypes.REQUEST_BALANCE_OPTIONS:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SUCCESS_BALANCE_OPTIONS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      }
    case actionTypes.ERROR_BALANCE_OPTIONS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

function dataOptions(state = { loading: false, data: null }, action = {}) {
  switch (action.type) {
    case actionTypes.REQUEST_DATA_OPTIONS:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SUCCESS_DATA_OPTIONS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      }
    case actionTypes.ERROR_DATA_OPTIONS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

function vcoinsOptions(state = { loading: false, data: null }, action = {}) {
  switch (action.type) {
    case actionTypes.REQUEST_VCOINS_OPTIONS:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SUCCESS_VCOINS_OPTIONS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      }
    case actionTypes.ERROR_VCOINS_OPTIONS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}


function balanceTransfer(state = { loading: false, data: null }, action = {}) {
  switch (action.type) {
    case actionTypes.REQUEST_BALANCE_TRANSFER:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SUCCESS_BALANCE_TRANSFER:
      return {
        ...state,
        loading: false,
        error: null,
      }
    case actionTypes.ERROR_BALANCE_TRANSFER:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

function dataTransfer(state = { loading: false, data: null }, action = {}) {
  switch (action.type) {
    case actionTypes.REQUEST_DATA_TRANSFER:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SUCCESS_DATA_TRANSFER:
      return {
        ...state,
        loading: false,
        error: null,
      }
    case actionTypes.ERROR_DATA_TRANSFER:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

function vcoinsTransfer(state = { loading: false, data: null }, action = {}) {
  switch (action.type) {
    case actionTypes.REQUEST_VCOINS_TRANSFER:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SUCCESS_VCOINS_TRANSFER:
      return {
        ...state,
        loading: false,
        error: null,
      }
    case actionTypes.ERROR_VCOINS_TRANSFER:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}


export {
  balanceOptions,
  dataOptions,
  vcoinsOptions,
  balanceTransfer,
  dataTransfer,
  vcoinsTransfer
}
