import { actionTypes } from './GigaBonus.ActionTypes'

function gigaBonus(state = { loading: false, data: null, error: null }, action = {}) {
  switch (action.type) {
    case actionTypes.REQUEST_ORDER_BONUS:
      return {
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_ORDER_BONUS:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }

    case actionTypes.ERROR_ORDER_BONUS:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}

export default gigaBonus
