import React, { useState, useLayoutEffect, useCallback } from 'react'
import { Dimensions, Platform, StatusBar } from 'react-native'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

import Bundles from '@vfal-lab/rn-vfg-renewals-fix/Screens/Bundles/Bundles'

import { clearInternetOfferData, clearTVOfferData, getOffers, selectBundle } from './FixBundles.Actions'

function BundlesScreen({ navigation }) {

    const dispatch = useDispatch()

    const LOADING_OFFERS = [{}, {}, {}, {}]

    const getRenewalOffers = async (utilities) => dispatch(getOffers(utilities))
    const selectRenewalOffer = async (bundle) => dispatch(selectBundle(bundle))
    const clearInternetOffersData = useCallback(async () => dispatch(clearInternetOfferData()), [])
    const clearTVOffersData = useCallback(async () => dispatch(clearTVOfferData()), [])

    /** Content manager initialization */
    const [isContentLoading, setContentLoading] = useState(true)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useLayoutEffect(() => {
        getRenewalOffers({
            onSuccess: (res) => setContentLoading(false),
            onError: (er) => {
                setError(true)
                setErrorMessage(er)
                setContentLoading(false)
            },
        })
    }, [])


    const renewalOffers = useSelector(state => state.renewalsOffers.data || [])
    const renewalOffersLoading = useSelector(state => state.renewalsOffers.loading || false)

    const selectedOffer = useSelector(state => state.selectedOffer.data || {})

    // const resetStatusBar = (iosContent = 'light-content') => {
    //     if (Platform.OS === 'android') {
    //         StatusBar.setBarStyle('light-content', true)
    //         StatusBar.setBackgroundColor(Colors.darkRed, true)
    //         StatusBar.setTranslucent(false)
    //     } else if (Platform.OS === 'ios') {
    //         StatusBar.setBarStyle(iosContent, true)
    //     }
    // }

    const onClose = () => {
        resetStatusBar('dark-content')
        clearInternetOffersData()
        clearTVOffersData()
        navigation.pop()
    }


    const navigateToRenewal = () => navigation.navigate('FixRenewals', { isPurchase: false })

    const onSelectOffer = (offer) => {
        selectRenewalOffer(offer)
        navigateToRenewal()
    }

    if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
        return (
            <></>
        )
    }

    return <Bundles
        onClose={onClose}
        isContentLoading={isContentLoading || renewalOffersLoading}
        offers={(isContentLoading || renewalOffersLoading) ? LOADING_OFFERS : renewalOffers}
        navigateToRenewal={navigateToRenewal}
        showSubHeader={true}
        selectedOffer={selectedOffer}
        onSelectOffer={onSelectOffer}
        showSummaryBreakdownBtn={true}
        resetStatusBar={resetStatusBar}
        isPurchase={false}
        error={error}
        errorMessage={errorMessage}
    />
}
BundlesScreen.propTypes = {
    navigation: PropTypes.object,
}

export default BundlesScreen
