

export const getStatusTitle = (status) => {
  switch (status) {
    case 'acknowledged':
      return 'my_orders_acknowledged_status'
    case 'rejected':
      return 'my_orders_device_returned_status'
    case 'pending':
      return 'my_orders_pending_status'
    case 'held':
      return 'my_orders_held_status'
    case 'inProgress':
      return 'my_orders_inprogress_status'
    case 'completed':
      return 'my_orders_completed_status'
    case 'assessingCancellation':
      return 'my_orders_canceled_status'
    default:
      return status
  }
}

export const mapStatusToTag = (status) => {
  switch (status) {
    case 'acknowledged':
      return 'processing'
    case 'rejected':
      return 'processing'
    case 'pending':
      return 'neutral'
    case 'held':
      return 'processing'
    case 'inProgress':
      return 'neutral'
    case 'completed':
      return 'positive'
    case 'assessingCancellation':
      return 'processing'
    default:
      return 'neutral'
  }
}

