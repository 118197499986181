import React, { useRef, useState } from 'react'
import { View, TouchableOpacity, Dimensions, Image } from 'react-native'
import PropTypes from 'prop-types'
import { RNCamera } from 'react-native-camera'
import { RNHoleView } from 'react-native-hole-view'
// import ImageEditor from '@react-native-community/image-editor'


import { VFText, Icon } from '@vfgroup-oneplatform/foundation/Components'

import VFButton from '../../../../Components/VFButton'

import { Images } from '../../../../Themes'

import styles from './Scan.Styles'


const { width: SCREEN_WIDTH } = Dimensions.get('screen')


const Scan = ({
  onClose,
  onButtonPress,
  image,
  setImage,
  barCode,
  setBarCode,
  loading,
}) => {

  const cameraRef = useRef()

  const [flashState, setFlashState] = useState(RNCamera.Constants.FlashMode.off)


  const width = SCREEN_WIDTH - 64
  const cameraHeight = SCREEN_WIDTH * 16 / 9

  const imageHeight = width / 1.582

  const topVerticalOffset = (cameraHeight - 120 - imageHeight) / 2

  const toggleFlash = () => {
    if (flashState === RNCamera.Constants.FlashMode.off) {
      setFlashState(RNCamera.Constants.FlashMode.on)
    } else {
      setFlashState(RNCamera.Constants.FlashMode.off)
    }
  }

  const takePicture = async () => {
    try {
      if (cameraRef.current) {
        const options = {
          width: SCREEN_WIDTH,
          quality: 1,
          base64: true,
          skipProcessing: true,
          fixOrientation: true,
          forceUpOrientation: true,
          pauseAfterCapture: true,
          orientation: 'portrait',
        }

        const data = await cameraRef.current.takePictureAsync(options)

        const cropData = {
          offset: { x: 32, y: topVerticalOffset },
          size: { width: width, height: imageHeight },
          resizeMode: 'contain',
        }

        // const cropedImage = await ImageEditor.cropImage(data.uri, cropData)
        const cropedImage = null

        setImage(cropedImage)

      }

    } catch (error) {
      console.log(error)
    }

  }

  const retakePhoto = () => {
    setImage()
  }

  return (
    <>
      {image ? <View style={styles.showImageContainer}>

        <View style={styles.headerContainer}>
          <View style={styles.emptyIcon} />

          <TouchableOpacity onPress={onClose}>
            <Icon
              name={Images.close_white}
              size={25}
              resizeMode="contain"
              type="image"
            />
          </TouchableOpacity>
        </View>

        <View style={styles.borderRadius(width, imageHeight, topVerticalOffset)}>
          <Image
            source={{ uri: image }}
            style={styles.image(width)}
            resizeMode="contain"
          />
        </View>

        <View>
          <VFText
            i18nKey={barCode ? barCode : ''}
            style={[styles.text, styles.marginTop]}
          />
        </View>

        <View style={styles.buttonsContainer}>
          <VFButton
            title="renewals_camera_continue"
            style={styles.button}
            onPress={onButtonPress}
            isLoading={loading}
            loadingTitle={'Loading...'}
            disabled={!barCode}
          />
          <VFButton
            title="renewals_camera_retake_photo"
            style={styles.button}
            type="secondary"
            onPress={retakePhoto}
          />
        </View>
      </View> :
        <View style={styles.container}>
          <RNCamera
            ref={cameraRef}
            type={RNCamera.Constants.Type.back}
            flashMode={flashState}
            focusable
            useNativeZoom={false}
            zoom={0}
            ratio="16:9"
            style={{ height: cameraHeight, width: SCREEN_WIDTH }}
            onBarCodeRead={({ data }) => setBarCode(data)}
          >
            <View style={styles.cameraContainer}>

              <RNHoleView
                style={styles.overlayMask}
                holes={[
                  {
                    x: 32,
                    y: topVerticalOffset,
                    width: width,
                    height: imageHeight,
                    borderRadius: 8,
                  },
                ]}
              />
              <View style={styles.borderRadius(width, imageHeight, topVerticalOffset)} />


              <View style={styles.headerContainer}>

                <TouchableOpacity onPress={toggleFlash}>
                  <Icon
                    name={RNCamera.Constants.FlashMode.on === flashState ? Images.flash_filled : Images.flash}
                    size={25}
                    resizeMode="contain"
                    type="image"
                  />
                </TouchableOpacity>

                <TouchableOpacity onPress={onClose}>
                  <Icon
                    name={Images.close_white}
                    size={25}
                    resizeMode="contain"
                    type="image"
                  />
                </TouchableOpacity>

              </View>


              <View style={styles.textContainer}>
                <VFText
                  i18nKey={barCode ? barCode : 'Scanning...'}
                  style={styles.text}
                />
              </View>
            </View>
          </RNCamera>

          <View style={styles.buttonContainer}>
            <TouchableOpacity
              onPress={takePicture}
              style={styles.cameraButtonOutter}>
              <View style={styles.cameraButtonInner} />
            </TouchableOpacity>
          </View>
        </View>}
    </>
  )
}
Scan.propTypes = {
  onClose: PropTypes.func,
  onButtonPress: PropTypes.func,
  image: PropTypes.string,
  setImage: PropTypes.func,
  barCode: PropTypes.string,
  setBarCode: PropTypes.func,
  loading: PropTypes.bool,
}

export default Scan
