import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import { SafeAreaView } from 'react-native-safe-area-context'

import { VFText, VFButton, Icon } from '@vfgroup-oneplatform/foundation/Components'

import { withTheme, getThemeImages } from '@vfgroup-oneplatform/foundation/Components/Themes'


import { Images } from '../../../../Themes'

import styles from './Error.Styles'


const Error = ({
  onClose,
  error,
  theme,
  addManually
}) => {

  const themedImages = getThemeImages(theme.name)

  return (
    <SafeAreaView style={styles.container(theme)}>
      <TouchableOpacity
        style={styles.closeButton}
        onPress={onClose}
        activeOpacity={1}
      >
        <Icon
          name={themedImages.ic_close}
          size={25}
          type="image"
        />
      </TouchableOpacity>
      <View style={styles.orderScreenContainer}>
        <Icon
          name={Images.warning}
          type="image"
          size={120}
          resizeMode="contain"
        />
        <VFText
          i18nKey="order_progress_error"
          style={styles.title}
        />
        <VFText
          i18nKey={error || 'order_progress_error_message'}
          style={styles.subtitle(theme)}
        />
      </View>
      <VFButton
        type="primary"
        title="acquisition_orders_add_manually_id"
        style={styles.btnStyle}
        onPress={addManually}
      />
      <VFButton
        type="secondary"
        title="order_progress_close"
        style={styles.btnStyle}
        onPress={onClose}
      />
    </SafeAreaView>
  )
}

Error.propTypes = {
  onClose: PropTypes.func,
  error: PropTypes.string,
  theme: PropTypes.object,
  addManually: PropTypes.func
}

export default withTheme(Error)
