import React, { useState } from 'react'
import { Dimensions, Platform, View } from 'react-native'
import PropTypes from 'prop-types'
import {
  SectionHeader,
  QuickActionMenu
} from '@vfgroup-oneplatform/foundation/Components'
import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { getThemeImages } from '@vfgroup-oneplatform/framework/Themes'

import Modal from 'react-native-modal'
import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'


import InProgressOrdersSubCard from '../InProgressOrdersSubCard/InProgressOrdersSubCard'

import OrderSummaryDataModal from '../OrderSummaryDataModal'

import styles from './InProgressOrdersCard.styles'



const InProgressOrdersCard = ({ order, showEdit }) => {
  const theme = useTheme()
  const images = getThemeImages(theme.name)

  const [isOrderSummaryModal, setOrderSummaryModal] = useState(false)

  const onOpenOrderSummaryModal = () => {
    setOrderSummaryModal(true)
  }
  const onCloseOrderSummaryModal = () => {
    setOrderSummaryModal(false)
  }

  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
    const titleNumber = ContentManager.translate('my_orders_order_number') + ' ' + order.id.toString()
    const orderPlaced = ContentManager.translate('order_progress_timeline_subtitle') + ' ' + order.items[0].startDate
    return (
      <View style={styles.container}>
        <SectionHeader
          theme={theme}
          title={titleNumber}
          titleStyle={styles.titleStyle(theme)}
          rightIcon={images.icon_bill_report_generic}
          rightText="my_orders_action_order_summary"
          rightTextStyle={styles.rightText(theme)}
          subtitle={orderPlaced}
          subtitleStyle={styles.subtitle(theme)}
          onRightSectionPress={onOpenOrderSummaryModal}
        />

        {order.items.map((item, index) => (
          <InProgressOrdersSubCard key={index} item={item} showEdit={showEdit} />
        ))}

        {isOrderSummaryModal && <Modal
          isVisible={isOrderSummaryModal}
          backdropOpacity={0.5}
          onSwipeComplete={() => setOrderSummaryModal(false)}
          onBackdropPress={() => setOrderSummaryModal(false)}
          propagateSwipe
          hideModalContentWhileAnimating
          hardwareAccelerated
          backdropTransitionOutTiming={0}
          style={{
            justifyContent: 'flex-end',
            margin: '0 !important',
            minWidth: 1920,
            maxWidth: 1920,
          }}
        >
          <View
            style={{
              height: 'auto',
              minWidth: '600px',
              maxWidth: '600px',
              backgroundColor: theme.colors.quickActionGradientColors.secondary,
              margin: 'auto',
              padding: '20px',
              borderRadius: '7px',
            }}
            onStartShouldSetResponder={() => true}>
            <OrderSummaryDataModal order={order} />
          </View>
        </Modal>}
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <SectionHeader
        theme={theme}
        title="my_orders_order_number"
        titleStyle={styles.titleStyle(theme)}
        rightIcon={images.icon_bill_report_generic}
        rightText="my_orders_action_order_summary"
        rightTextStyle={styles.rightText(theme)}
        subtitle={order.id.toString()}
        subtitleStyle={styles.subtitle(theme)}
        onRightSectionPress={onOpenOrderSummaryModal}
      />

      {order.items.map((item, index) => (
        <InProgressOrdersSubCard key={index} orderId={order.id} item={item} showEdit={showEdit} />
      ))}

      {isOrderSummaryModal ? (
        <QuickActionMenu
          isVisible={isOrderSummaryModal}
          closeModal={onCloseOrderSummaryModal}
          title={'my_orders_action_order_summary'}
          type="secondary"
          titleStyle={styles.QAMtitle}
          headerStyle={styles.header}
          containerStyle={styles.QAMContainerStyle}
          notScrollable
        >
          <OrderSummaryDataModal order={order} />
        </QuickActionMenu>
      ) : null}
    </View>
  )
}

InProgressOrdersCard.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    items: PropTypes.arrayOf(PropTypes.object)
  }),
  showEdit: PropTypes.bool,
  onItemPress: PropTypes.func,
}

export default InProgressOrdersCard
