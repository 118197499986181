import React from 'react'
import { View, Platform, Dimensions } from 'react-native'
import { VFText, VFButton, VFTouchableInScroll } from '@vfgroup-oneplatform/foundation/Components'
import { withTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import PropTypes from 'prop-types'

import styles from '../SecondaryComponent.Styles'

const OneOrMoreBills = ({ bill, isCurrencyFirst, theme, onPress, userType, spendings }) => {
  const { value = '0', currency = ' Lekë', paymentInfo } = bill || {}

  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
    return (
      <VFTouchableInScroll onPress={onPress}>
        <View style={styles.nextBillContainer(theme)}>
          <VFText
            i18nKey={userType?.includes('postpaid') ? 'dashboard_billing_postpay_card_subtitle' : 'dashboard_billing_card_subtitle'}
            style={styles.billSubTitle}
            type="primary"
          />
          <View style={styles.priceAndCurrencyContainer}>
            <View style={styles.priceAndCurrencyTextContainer}>
              <VFText
                i18nKey={isCurrencyFirst ? currency : value}
                style={isCurrencyFirst ? styles.billCurrency : styles.billPrice}
              />
              <VFText
                i18nKey={isCurrencyFirst ? value : currency}
                style={isCurrencyFirst ? styles.billPrice : styles.billCurrency}
              />
            </View>
          </View>
          {spendings?.chargesList?.map((item) => {
            return (
              <View style={styles.smallMarginTop}>
                <View style={styles.expensesDetails}>
                  <VFText i18nKey={item.title} style={[styles.smallMarginBottom, styles.section]} />
                  <View style={styles.flexRow}>
                    <VFText i18nKey={item?.price} style={styles.section} />
                    <VFText i18nKey={spendings?.currency} style={styles.section} />
                  </View>
                </View>
                <View style={styles.horizontalLine}></View>
              </View>
            )
          })}
          <View style={{ flex: 1, justifyContent: 'flex-end', }}>
            <VFButton title='dashboard_view_all_spendings_button' type='secondary' onPress={onPress} style={{ marginBottom: 20 }} />
            {paymentInfo && <VFText
              i18nKey="dashboard_billing_card_pay_date"
              placeHolders={[paymentInfo]}
              style={styles.billDueDate(theme)}
            />}
          </View>
        </View>
      </VFTouchableInScroll>
    )
  }
  return (
    <VFTouchableInScroll onPress={onPress}>
      <View style={styles.nextBillContainer}>
        <VFText
          i18nKey={userType == 'postpaid' ? 'dashboard_billing_postpay_card_subtitle' : 'dashboard_billing_card_subtitle'}
          style={styles.billSubTitle}
          type="primary"
        />
        <View style={styles.priceAndCurrencyContainer}>
          <View style={styles.priceAndCurrencyTextContainer}>
            <VFText
              i18nKey={isCurrencyFirst ? currency : value}
              style={isCurrencyFirst ? styles.billCurrency : styles.billPrice}
            />
            <VFText
              i18nKey={isCurrencyFirst ? value : currency}
              style={isCurrencyFirst ? styles.billPrice : styles.billCurrency}
            />
          </View>
        </View>
        {paymentInfo && <VFText
          i18nKey="dashboard_billing_card_pay_date"
          placeHolders={[paymentInfo]}
          style={styles.billDueDate(theme)}
        />}
      </View>
    </VFTouchableInScroll>
  )
}

OneOrMoreBills.propTypes = {
  bill: PropTypes.object,
  isCurrencyFirst: PropTypes.bool,
  theme: PropTypes.objectOf({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  })
}

export default withTheme(OneOrMoreBills)
