import {
  GetOfferDetails,
  ActivateOffer
} from '@vfal-lab/rn-vfg-refill/Services'

import ReduxCache from '../../Utils/ReduxCache'
import { actionTypes } from './Refill.ActionsTypes'

const errorMapper = (error) => {
  const payload = error?.response?.data?.message || error?.message
  return payload
}

const customHeaders = {
  'vf-source': 'selfcare',
  'vf-operator': 'refill',
  'vf-country-code': 'al'
}

const getOfferDetailsResponseMapper = (response) => {
  const data = response.data?.[0] || {}

  const {
    id,
    name: title,
    type,
    category,
    validityPeriod: {
      fromDate: { date: fromDate } = {},
      toDate: { date: toDate } = {}
    } = {},
    parts = {}
  } = data

  const detailsLink = parts.specification?.characteristics?.find(function (e) {
    return e.type === 'more_info_link'
  })?.value

  const lineItem = []
  parts.lineItem?.map(item => {

    const { name, type: itemType } = item.productOffer || {}
    const { value, currencyID: currency } = item.price?.[0]?.priceDetail || {}
    let disabled, desc

    if (!value) {
      disabled = true
    } else {
      desc = value + ' ' + currency
    }

    lineItem.push({
      title: name,
      type: itemType,
      value,
      desc,
      disabled
    })
  })

  return {
    id,
    title,
    type,
    category,
    fromDate,
    toDate,
    detailsLink,
    lineItem
  }
}

const activateOfferResponseMapper = (response) => {
  const data = response.data
  const payload = {}

  payload.message = data.name
  payload.status = data.status
  payload.overwriteItems = data.lineItem?.[0]?.productOffering || []

  return payload
}

const getOfferDetails = (utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_GET_OFFER
    })

    const msisdn = ReduxCache.getMsisdn()

    const TYPE = 'DM'
    const STATUS = 'active'
    const CHANNEL_ID = 'selfcare'
    GetOfferDetails(TYPE, STATUS, CHANNEL_ID, msisdn, customHeaders, {
      ...utilities,
      responseMapper: getOfferDetailsResponseMapper,
      errorMapper: errorMapper
    }).then(response => dispatch({
      type: actionTypes.SUCCESS_GET_OFFER,
      payload: response
    })).catch(error => dispatch({
      type: actionTypes.ERROR_GET_OFFER,
      payload: error
    }))
  }
}

const activateOffer = (
  id,
  name,
  type,
  category,
  status,
  overwriteItems,
  bundleAction,
  utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.REQUEST_ACTIVATE_OFFER
    })

    const msisdn = ReduxCache.getMsisdn()

    ActivateOffer(
      id,
      msisdn,
      name,
      type,
      category,
      status,
      overwriteItems,
      bundleAction,
      customHeaders, {
      ...utilities,
      responseMapper: activateOfferResponseMapper,
      errorMapper: errorMapper
    }).then(response => dispatch({
      type: actionTypes.SUCCESS_ACTIVATE_OFFER,
      payload: response
    })).catch(error => dispatch({
      type: actionTypes.ERROR_ACTIVATE_OFFER,
      payload: error
    }))
  }
}


const actions = {
  getOfferDetails,
  activateOffer
}

export {
  actionTypes,
  actions
}
