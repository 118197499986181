import { StyleSheet } from 'react-native'
import { Colors, Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'


const styles = StyleSheet.create({
  flex1: {
    flex: 1
  },
  container: theme => ({
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: theme.colors.cardBackgroundColor,
    borderRadius: 6,
    paddingHorizontal: 12,
    paddingTop: 15.5,
    paddingBottom: 12,
    overflow: 'hidden'
  }),
  title: {
    ...Fonts.type.bold,
    fontSize: Fonts.size.regular,
    marginHorizontal: 8,
  },
  button: {
    backgroundColor: Colors.red,
    padding: 8,
    borderRadius: 7,
    alignSelf: 'flex-end'
  },
  footerContainer: {
    justifyContent: 'center',
    marginTop: 6
  },
  rowStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemContainer: {
    width: '33%',
    paddingHorizontal: 10,
    justifyContent: 'center',
  },
  rightBorder: theme => ({
    borderRightWidth: 1,
    borderRightColor: theme.colors.divider,
  }),
  itemIcon: {
    marginBottom: 10,
  },
  itemHeader: {
    alignItems: 'center',
  },
})


export default styles
