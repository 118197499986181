export default `<head><meta name="viewport" content="width=device-width, initial-scale=1"></head>
  <body style="width: 100%;height: 100%;overflow: hidden;padding:0;margin:0;"> <style> /* Connecting Fonts      :-
  --------------------------- */
  .bottle-container {
    background-color: #ca3942;
    text-align: center;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
  }
  .bottle-container svg {
    overflow: visible !important;
    position: relative;
    display: block;
    height: 300px;
    margin-top: -100px;
  }

  .bottle_cap {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-animation: bottle-cap 0.8s linear;
    -moz-animation: bottle-cap 0.8s linear;
    -o-animation: bottle-cap 0.8s linear;
    -ms-animation: bottle-cap 0.8s linear;
    animation: bottle-cap 0.8s linear;
    -webkit-animation-delay: 7.1s;
    -moz-animation-delay: 7.1s;
    -o-animation-delay: 7.1s;
    -ms-animation-delay: 7.1s;
    animation-delay: 7.1s;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .bottle_logo {
    fill: #ca3942;
  }
  .bottle_loader {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    -webkit-animation: bottle-loader 7.1s linear;
    -moz-animation: bottle-loader 7.1s linear;
    -o-animation: bottle-loader 7.1s linear;
    -ms-animation: bottle-loader 7.1s linear;
    animation: bottle-loader 7.1s linear;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .bottle_open {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-animation: fade-in 0.1s linear;
    -moz-animation: fade-in 0.1s linear;
    -o-animation: fade-in 0.1s linear;
    -ms-animation: fade-in 0.1s linear;
    animation: fade-in 0.1s linear;
    -webkit-animation-delay: 6.899999999999999s;
    -moz-animation-delay: 6.899999999999999s;
    -o-animation-delay: 6.899999999999999s;
    -ms-animation-delay: 6.899999999999999s;
    animation-delay: 6.899999999999999s;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .bottle_outline {
    stroke: rgba(255,255,255,0.15);
    -webkit-animation: switch-outline 0.1s linear;
    -moz-animation: switch-outline 0.1s linear;
    -o-animation: switch-outline 0.1s linear;
    -ms-animation: switch-outline 0.1s linear;
    animation: switch-outline 0.1s linear;
    -webkit-animation-delay: 7.1s;
    -moz-animation-delay: 7.1s;
    -o-animation-delay: 7.1s;
    -ms-animation-delay: 7.1s;
    animation-delay: 7.1s;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .fill_waves {
    fill: #752024;
    -webkit-animation: bottle-waves 0.3s linear infinite;
    -moz-animation: bottle-waves 0.3s linear infinite;
    -o-animation: bottle-waves 0.3s linear infinite;
    -ms-animation: bottle-waves 0.3s linear infinite;
    animation: bottle-waves 0.3s linear infinite;
    -webkit-animation-delay: 6.8s;
    -moz-animation-delay: 6.8s;
    -o-animation-delay: 6.8s;
    -ms-animation-delay: 6.8s;
    animation-delay: 6.8s;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  .circle-1,
  .circle-2,
  .circle-3,
  .circle-4,
  .circle-5,
  .circle-6,
  .circle-7,
  .circle-8,
  .circle-9 {
    -webkit-animation: bubbles 1.5s linear infinite;
    -moz-animation: bubbles 1.5s linear infinite;
    -o-animation: bubbles 1.5s linear infinite;
    -ms-animation: bubbles 1.5s linear infinite;
    animation: bubbles 1.5s linear infinite;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  .circle-1 {
    -webkit-animation-delay: 0.2s;
    -moz-animation-delay: 0.2s;
    -o-animation-delay: 0.2s;
    -ms-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  .circle-2 {
    -webkit-animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
    -o-animation-delay: 0.4s;
    -ms-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  .circle-3 {
    -webkit-animation-delay: 0.6s;
    -moz-animation-delay: 0.6s;
    -o-animation-delay: 0.6s;
    -ms-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  .circle-4 {
    -webkit-animation-delay: 0.8s;
    -moz-animation-delay: 0.8s;
    -o-animation-delay: 0.8s;
    -ms-animation-delay: 0.8s;
    animation-delay: 0.8s;
  }
  .circle-5 {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-delay: 1s;
    -ms-animation-delay: 1s;
    animation-delay: 1s;
  }
  .circle-6 {
    -webkit-animation-delay: 1.2s;
    -moz-animation-delay: 1.2s;
    -o-animation-delay: 1.2s;
    -ms-animation-delay: 1.2s;
    animation-delay: 1.2s;
  }
  .circle-7 {
    -webkit-animation-delay: 1.4s;
    -moz-animation-delay: 1.4s;
    -o-animation-delay: 1.4s;
    -ms-animation-delay: 1.4s;
    animation-delay: 1.4s;
  }
  .circle-8 {
    -webkit-animation-delay: 1.6s;
    -moz-animation-delay: 1.6s;
    -o-animation-delay: 1.6s;
    -ms-animation-delay: 1.6s;
    animation-delay: 1.6s;
  }
  .circle-9 {
    -webkit-animation-delay: 1.8s;
    -moz-animation-delay: 1.8s;
    -o-animation-delay: 1.8s;
    -ms-animation-delay: 1.8s;
    animation-delay: 1.8s;
  }
  .bubbles_group {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-animation: fade-in 0.1s linear;
    -moz-animation: fade-in 0.1s linear;
    -o-animation: fade-in 0.1s linear;
    -ms-animation: fade-in 0.1s linear;
    animation: fade-in 0.1s linear;
    -webkit-animation-delay: 7.6s;
    -moz-animation-delay: 7.6s;
    -o-animation-delay: 7.6s;
    -ms-animation-delay: 7.6s;
    animation-delay: 7.6s;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .loading-done {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    display: inline-block;
    width: 100%;
    -webkit-animation: fade-up-stay 0.3s linear;
    -moz-animation: fade-up-stay 0.3s linear;
    -o-animation: fade-up-stay 0.3s linear;
    -ms-animation: fade-up-stay 0.3s linear;
    animation: fade-up-stay 0.3s linear;
    -webkit-animation-delay: 6.899999999999999s;
    -moz-animation-delay: 6.899999999999999s;
    -o-animation-delay: 6.899999999999999s;
    -ms-animation-delay: 6.899999999999999s;
    animation-delay: 6.899999999999999s;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .counter {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  .counter:after {
    content: '%';
  }
  .counter.active {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  .loading-counter {
    position: relative;
    display: inline-block;
    height: 12px;
    width: 100%;
    -webkit-animation: fade-up 0.3s linear;
    -moz-animation: fade-up 0.3s linear;
    -o-animation: fade-up 0.3s linear;
    -ms-animation: fade-up 0.3s linear;
    animation: fade-up 0.3s linear;
    -webkit-animation-delay: 6.6s;
    -moz-animation-delay: 6.6s;
    -o-animation-delay: 6.6s;
    -ms-animation-delay: 6.6s;
    animation-delay: 6.6s;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .loading-counter span {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  .loading-container.active {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  .reload-demo {
    border: 0.2em solid #fff;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    text-decoration: none;
    letter-spacing: 0.15em;
    font-weight: 500;
    padding: 0.4em 1em;
    -webkit-border-radius: 1.5em;
    border-radius: 1.5em;
    position: absolute;
    bottom: 2em;
    right: 2em;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-animation: fade-in 0.1s linear;
    -moz-animation: fade-in 0.1s linear;
    -o-animation: fade-in 0.1s linear;
    -ms-animation: fade-in 0.1s linear;
    animation: fade-in 0.1s linear;
    -webkit-animation-delay: 6.6s;
    -moz-animation-delay: 6.6s;
    -o-animation-delay: 6.6s;
    -ms-animation-delay: 6.6s;
    animation-delay: 6.6s;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .reload-demo:hover {
    background-color: #fff;
    color: #ca3942;
  }
  /* Browser Fallbacks     :-
  --------------------------- */
  .browser-support {
    display: none;
    width: 100%;
    text-align: center;
  }
  .browser-support img {
    display: block;
    margin: 4em auto;
  }
  .browser-support p {
    margin-bottom: 1em;
    color: #777;
  }
  .browser-ie .bottle-container {
    display: none;
  }
  .browser-ie .browser-support {
    display: block;
  }
  .browser-logos {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .browser-logos a {
    display: inline-block;
    width: 4em;
    margin: 0 0.75em;
    text-decoration: none;
    color: #777;
  }
  .browser-logos a:hover {
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
  }
  .browser-logos img {
    display: inline-block;
    width: 100%;
    height: auto;
    margin: 0;
  }
  .browser-logos .text {
    display: inline-block;
    margin-top: 0.5em;
  }
  .loading-message {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    display: inline-block;
    -webkit-animation: hide-loading-message 0.3s linear;
    -moz-animation: hide-loading-message 0.3s linear;
    -o-animation: hide-loading-message 0.3s linear;
    -ms-animation: hide-loading-message 0.3s linear;
    animation: hide-loading-message 0.3s linear;
    -webkit-animation-delay: 3s;
    -moz-animation-delay: 3s;
    -o-animation-delay: 3s;
    -ms-animation-delay: 3s;
    animation-delay: 3s;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  @-moz-keyframes bottle-cap {
    0% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: rotate(0) translateY(0);
      -moz-transform: rotate(0) translateY(0);
      -o-transform: rotate(0) translateY(0);
      -ms-transform: rotate(0) translateY(0);
      transform: rotate(0) translateY(0);
    }
    15% {
      -webkit-transform: translateX(40px) translateY(-40px) rotate(60deg);
      -moz-transform: translateX(40px) translateY(-40px) rotate(60deg);
      -o-transform: translateX(40px) translateY(-40px) rotate(60deg);
      -ms-transform: translateX(40px) translateY(-40px) rotate(60deg);
      transform: translateX(40px) translateY(-40px) rotate(60deg);
    }
    45% {
      -webkit-transform: translateX(140px) translateY(-70px) rotate(120deg);
      -moz-transform: translateX(140px) translateY(-70px) rotate(120deg);
      -o-transform: translateX(140px) translateY(-70px) rotate(120deg);
      -ms-transform: translateX(140px) translateY(-70px) rotate(120deg);
      transform: translateX(140px) translateY(-70px) rotate(120deg);
    }
    65% {
      -webkit-transform: translateX(160px) translateY(-50px) rotate(135deg);
      -moz-transform: translateX(160px) translateY(-50px) rotate(135deg);
      -o-transform: translateX(160px) translateY(-50px) rotate(135deg);
      -ms-transform: translateX(160px) translateY(-50px) rotate(135deg);
      transform: translateX(160px) translateY(-50px) rotate(135deg);
    }
    75% {
      -webkit-transform: translateX(175px) translateY(330px) rotate(180deg);
      -moz-transform: translateX(175px) translateY(330px) rotate(180deg);
      -o-transform: translateX(175px) translateY(330px) rotate(180deg);
      -ms-transform: translateX(175px) translateY(330px) rotate(180deg);
      transform: translateX(175px) translateY(330px) rotate(180deg);
    }
    90% {
      -webkit-transform: translateX(170px) translateY(335px) rotate(135deg);
      -moz-transform: translateX(170px) translateY(335px) rotate(135deg);
      -o-transform: translateX(170px) translateY(335px) rotate(135deg);
      -ms-transform: translateX(170px) translateY(335px) rotate(135deg);
      transform: translateX(170px) translateY(335px) rotate(135deg);
    }
    100% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateX(175px) translateY(360px) rotate(180deg);
      -moz-transform: translateX(175px) translateY(360px) rotate(180deg);
      -o-transform: translateX(175px) translateY(360px) rotate(180deg);
      -ms-transform: translateX(175px) translateY(360px) rotate(180deg);
      transform: translateX(175px) translateY(360px) rotate(180deg);
    }
  }
  @-webkit-keyframes bottle-cap {
    0% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: rotate(0) translateY(0);
      -moz-transform: rotate(0) translateY(0);
      -o-transform: rotate(0) translateY(0);
      -ms-transform: rotate(0) translateY(0);
      transform: rotate(0) translateY(0);
    }
    15% {
      -webkit-transform: translateX(40px) translateY(-40px) rotate(60deg);
      -moz-transform: translateX(40px) translateY(-40px) rotate(60deg);
      -o-transform: translateX(40px) translateY(-40px) rotate(60deg);
      -ms-transform: translateX(40px) translateY(-40px) rotate(60deg);
      transform: translateX(40px) translateY(-40px) rotate(60deg);
    }
    45% {
      -webkit-transform: translateX(140px) translateY(-70px) rotate(120deg);
      -moz-transform: translateX(140px) translateY(-70px) rotate(120deg);
      -o-transform: translateX(140px) translateY(-70px) rotate(120deg);
      -ms-transform: translateX(140px) translateY(-70px) rotate(120deg);
      transform: translateX(140px) translateY(-70px) rotate(120deg);
    }
    65% {
      -webkit-transform: translateX(160px) translateY(-50px) rotate(135deg);
      -moz-transform: translateX(160px) translateY(-50px) rotate(135deg);
      -o-transform: translateX(160px) translateY(-50px) rotate(135deg);
      -ms-transform: translateX(160px) translateY(-50px) rotate(135deg);
      transform: translateX(160px) translateY(-50px) rotate(135deg);
    }
    75% {
      -webkit-transform: translateX(175px) translateY(330px) rotate(180deg);
      -moz-transform: translateX(175px) translateY(330px) rotate(180deg);
      -o-transform: translateX(175px) translateY(330px) rotate(180deg);
      -ms-transform: translateX(175px) translateY(330px) rotate(180deg);
      transform: translateX(175px) translateY(330px) rotate(180deg);
    }
    90% {
      -webkit-transform: translateX(170px) translateY(335px) rotate(135deg);
      -moz-transform: translateX(170px) translateY(335px) rotate(135deg);
      -o-transform: translateX(170px) translateY(335px) rotate(135deg);
      -ms-transform: translateX(170px) translateY(335px) rotate(135deg);
      transform: translateX(170px) translateY(335px) rotate(135deg);
    }
    100% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateX(175px) translateY(360px) rotate(180deg);
      -moz-transform: translateX(175px) translateY(360px) rotate(180deg);
      -o-transform: translateX(175px) translateY(360px) rotate(180deg);
      -ms-transform: translateX(175px) translateY(360px) rotate(180deg);
      transform: translateX(175px) translateY(360px) rotate(180deg);
    }
  }
  @-o-keyframes bottle-cap {
    0% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: rotate(0) translateY(0);
      -moz-transform: rotate(0) translateY(0);
      -o-transform: rotate(0) translateY(0);
      -ms-transform: rotate(0) translateY(0);
      transform: rotate(0) translateY(0);
    }
    15% {
      -webkit-transform: translateX(40px) translateY(-40px) rotate(60deg);
      -moz-transform: translateX(40px) translateY(-40px) rotate(60deg);
      -o-transform: translateX(40px) translateY(-40px) rotate(60deg);
      -ms-transform: translateX(40px) translateY(-40px) rotate(60deg);
      transform: translateX(40px) translateY(-40px) rotate(60deg);
    }
    45% {
      -webkit-transform: translateX(140px) translateY(-70px) rotate(120deg);
      -moz-transform: translateX(140px) translateY(-70px) rotate(120deg);
      -o-transform: translateX(140px) translateY(-70px) rotate(120deg);
      -ms-transform: translateX(140px) translateY(-70px) rotate(120deg);
      transform: translateX(140px) translateY(-70px) rotate(120deg);
    }
    65% {
      -webkit-transform: translateX(160px) translateY(-50px) rotate(135deg);
      -moz-transform: translateX(160px) translateY(-50px) rotate(135deg);
      -o-transform: translateX(160px) translateY(-50px) rotate(135deg);
      -ms-transform: translateX(160px) translateY(-50px) rotate(135deg);
      transform: translateX(160px) translateY(-50px) rotate(135deg);
    }
    75% {
      -webkit-transform: translateX(175px) translateY(330px) rotate(180deg);
      -moz-transform: translateX(175px) translateY(330px) rotate(180deg);
      -o-transform: translateX(175px) translateY(330px) rotate(180deg);
      -ms-transform: translateX(175px) translateY(330px) rotate(180deg);
      transform: translateX(175px) translateY(330px) rotate(180deg);
    }
    90% {
      -webkit-transform: translateX(170px) translateY(335px) rotate(135deg);
      -moz-transform: translateX(170px) translateY(335px) rotate(135deg);
      -o-transform: translateX(170px) translateY(335px) rotate(135deg);
      -ms-transform: translateX(170px) translateY(335px) rotate(135deg);
      transform: translateX(170px) translateY(335px) rotate(135deg);
    }
    100% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateX(175px) translateY(360px) rotate(180deg);
      -moz-transform: translateX(175px) translateY(360px) rotate(180deg);
      -o-transform: translateX(175px) translateY(360px) rotate(180deg);
      -ms-transform: translateX(175px) translateY(360px) rotate(180deg);
      transform: translateX(175px) translateY(360px) rotate(180deg);
    }
  }
  @keyframes bottle-cap {
    0% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: rotate(0) translateY(0);
      -moz-transform: rotate(0) translateY(0);
      -o-transform: rotate(0) translateY(0);
      -ms-transform: rotate(0) translateY(0);
      transform: rotate(0) translateY(0);
    }
    15% {
      -webkit-transform: translateX(40px) translateY(-40px) rotate(60deg);
      -moz-transform: translateX(40px) translateY(-40px) rotate(60deg);
      -o-transform: translateX(40px) translateY(-40px) rotate(60deg);
      -ms-transform: translateX(40px) translateY(-40px) rotate(60deg);
      transform: translateX(40px) translateY(-40px) rotate(60deg);
    }
    45% {
      -webkit-transform: translateX(140px) translateY(-70px) rotate(120deg);
      -moz-transform: translateX(140px) translateY(-70px) rotate(120deg);
      -o-transform: translateX(140px) translateY(-70px) rotate(120deg);
      -ms-transform: translateX(140px) translateY(-70px) rotate(120deg);
      transform: translateX(140px) translateY(-70px) rotate(120deg);
    }
    65% {
      -webkit-transform: translateX(160px) translateY(-50px) rotate(135deg);
      -moz-transform: translateX(160px) translateY(-50px) rotate(135deg);
      -o-transform: translateX(160px) translateY(-50px) rotate(135deg);
      -ms-transform: translateX(160px) translateY(-50px) rotate(135deg);
      transform: translateX(160px) translateY(-50px) rotate(135deg);
    }
    75% {
      -webkit-transform: translateX(175px) translateY(330px) rotate(180deg);
      -moz-transform: translateX(175px) translateY(330px) rotate(180deg);
      -o-transform: translateX(175px) translateY(330px) rotate(180deg);
      -ms-transform: translateX(175px) translateY(330px) rotate(180deg);
      transform: translateX(175px) translateY(330px) rotate(180deg);
    }
    90% {
      -webkit-transform: translateX(170px) translateY(335px) rotate(135deg);
      -moz-transform: translateX(170px) translateY(335px) rotate(135deg);
      -o-transform: translateX(170px) translateY(335px) rotate(135deg);
      -ms-transform: translateX(170px) translateY(335px) rotate(135deg);
      transform: translateX(170px) translateY(335px) rotate(135deg);
    }
    100% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateX(175px) translateY(360px) rotate(180deg);
      -moz-transform: translateX(175px) translateY(360px) rotate(180deg);
      -o-transform: translateX(175px) translateY(360px) rotate(180deg);
      -ms-transform: translateX(175px) translateY(360px) rotate(180deg);
      transform: translateX(175px) translateY(360px) rotate(180deg);
    }
  }
  @-moz-keyframes bottle-waves {
    0%, 75% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    100% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
  }
  @-webkit-keyframes bottle-waves {
    0%, 75% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    100% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
  }
  @-o-keyframes bottle-waves {
    0%, 75% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    100% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
  }
  @keyframes bottle-waves {
    0%, 75% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    100% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
  }
  @-moz-keyframes bottle-loader {
    0% {
      stroke-dashoffset: 1000;
    }
    52%, 99.5% {
      stroke-dashoffset: 0;
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
  }
  @-webkit-keyframes bottle-loader {
    0% {
      stroke-dashoffset: 1000;
    }
    52%, 99.5% {
      stroke-dashoffset: 0;
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
  }
  @-o-keyframes bottle-loader {
    0% {
      stroke-dashoffset: 1000;
    }
    52%, 99.5% {
      stroke-dashoffset: 0;
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
  }
  @keyframes bottle-loader {
    0% {
      stroke-dashoffset: 1000;
    }
    52%, 99.5% {
      stroke-dashoffset: 0;
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
  }
  @-moz-keyframes switch-outline {
    0% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
  }
  @-webkit-keyframes switch-outline {
    0% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
  }
  @-o-keyframes switch-outline {
    0% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
  }
  @keyframes switch-outline {
    0% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
  }
  @-moz-keyframes fade-in {
    0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    100% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
  }
  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    100% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
  }
  @-o-keyframes fade-in {
    0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    100% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    100% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
  }
  @-moz-keyframes bubbles {
    0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-2px) scale(0.8);
      -moz-transform: translateY(-2px) scale(0.8);
      -o-transform: translateY(-2px) scale(0.8);
      -ms-transform: translateY(-2px) scale(0.8);
      transform: translateY(-2px) scale(0.8);
    }
    100% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(-12px) scale(1);
      -moz-transform: translateY(-12px) scale(1);
      -o-transform: translateY(-12px) scale(1);
      -ms-transform: translateY(-12px) scale(1);
      transform: translateY(-12px) scale(1);
    }
  }
  @-webkit-keyframes bubbles {
    0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-2px) scale(0.8);
      -moz-transform: translateY(-2px) scale(0.8);
      -o-transform: translateY(-2px) scale(0.8);
      -ms-transform: translateY(-2px) scale(0.8);
      transform: translateY(-2px) scale(0.8);
    }
    100% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(-12px) scale(1);
      -moz-transform: translateY(-12px) scale(1);
      -o-transform: translateY(-12px) scale(1);
      -ms-transform: translateY(-12px) scale(1);
      transform: translateY(-12px) scale(1);
    }
  }
  @-o-keyframes bubbles {
    0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-2px) scale(0.8);
      -moz-transform: translateY(-2px) scale(0.8);
      -o-transform: translateY(-2px) scale(0.8);
      -ms-transform: translateY(-2px) scale(0.8);
      transform: translateY(-2px) scale(0.8);
    }
    100% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(-12px) scale(1);
      -moz-transform: translateY(-12px) scale(1);
      -o-transform: translateY(-12px) scale(1);
      -ms-transform: translateY(-12px) scale(1);
      transform: translateY(-12px) scale(1);
    }
  }
  @keyframes bubbles {
    0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-2px) scale(0.8);
      -moz-transform: translateY(-2px) scale(0.8);
      -o-transform: translateY(-2px) scale(0.8);
      -ms-transform: translateY(-2px) scale(0.8);
      transform: translateY(-2px) scale(0.8);
    }
    100% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(-12px) scale(1);
      -moz-transform: translateY(-12px) scale(1);
      -o-transform: translateY(-12px) scale(1);
      -ms-transform: translateY(-12px) scale(1);
      transform: translateY(-12px) scale(1);
    }
  }
  @-moz-keyframes fade-up {
    0% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-15px);
      -moz-transform: translateY(-15px);
      -o-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
  @-webkit-keyframes fade-up {
    0% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-15px);
      -moz-transform: translateY(-15px);
      -o-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
  @-o-keyframes fade-up {
    0% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-15px);
      -moz-transform: translateY(-15px);
      -o-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
  @keyframes fade-up {
    0% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-15px);
      -moz-transform: translateY(-15px);
      -o-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
  @-moz-keyframes fade-up-stay {
    0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    100% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(-15px);
      -moz-transform: translateY(-15px);
      -o-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
  @-webkit-keyframes fade-up-stay {
    0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    100% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(-15px);
      -moz-transform: translateY(-15px);
      -o-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
  @-o-keyframes fade-up-stay {
    0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    100% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(-15px);
      -moz-transform: translateY(-15px);
      -o-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
  @keyframes fade-up-stay {
    0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    100% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(-15px);
      -moz-transform: translateY(-15px);
      -o-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
  @-moz-keyframes hide-loading-message {
    0% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-15%);
      -moz-transform: translateY(-15%);
      -o-transform: translateY(-15%);
      -ms-transform: translateY(-15%);
      transform: translateY(-15%);
    }
  }
  @-webkit-keyframes hide-loading-message {
    0% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-15%);
      -moz-transform: translateY(-15%);
      -o-transform: translateY(-15%);
      -ms-transform: translateY(-15%);
      transform: translateY(-15%);
    }
  }
  @-o-keyframes hide-loading-message {
    0% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-15%);
      -moz-transform: translateY(-15%);
      -o-transform: translateY(-15%);
      -ms-transform: translateY(-15%);
      transform: translateY(-15%);
    }
  }
  @keyframes hide-loading-message {
    0% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-15%);
      -moz-transform: translateY(-15%);
      -o-transform: translateY(-15%);
      -ms-transform: translateY(-15%);
      transform: translateY(-15%);
    }
  } </style>	<div class="browser-support">
  <img src="assets/graphics/fallbacks/ie-fallback.gif" width="598" height="699" alt="Animated GIF for Internet Explorer">

  
</div>

<div class="browser-support">
  <p>This is a gif fallback for Internet explorer 7/8/9, <br /> Please consider downloading one of the following browsers or, <br /> upgrading to a newer Internet Explorer.</p>

  <div class="browser-logos">
    <a href="https://www.google.com/chrome/browser/desktop/" target="_blank">
      <img src="assets/graphics/logos/chrome.svg" alt="Chrome Browser">
      <span class="text">Chrome</span>
    </a>
    <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">
      <img src="assets/graphics/logos/firefox.svg" alt="Firefox Browser">
      <span class="text">Firefox</span>
    </a>
    <a href="https://support.apple.com/en-us/HT204416" target="_blank">
      <img src="assets/graphics/logos/safari.svg" alt="Safari Browser">
      <span class="text">Safari</span>
    </a>

  </div>
</div>

<div id="inner-refresh">
  <div class="bottle-container">
    <svg  version="1.1"
          class="bottle"
          xmlns="http://www.w3.org/2000/svg"
          width="100px"
          height="200px"
          viewBox="0 0 100 200"
          xml:space="preserve" >

      <!-- Defs -->
      <defs>
        <!-- Linear Gradient - With SMIL Animation of Filling the bottle -->
        <linearGradient id="fillGradient"
                  x1="0%"
                  y1="101%"
                  x2="0%"
                  y2="0%">

          <stop offset="0" stop-color="#752024">
          </stop>

          <stop offset="1%" stop-color="#ca3942">
          </stop>

          <animate attributeName="y1"
               from="101%"
               to="6%"
               begin="3s"
               dur="4s"
               fill="freeze"> 
          </animate>

        </linearGradient>

        <!-- Waves Mask -->
        <path id="waves_mask_path" fill="none" d="M92.3,272.5c0,0.6,0,5.2-0.5,12l0.2,0.1v19.5c0.4,1.8,0,3.8,1,5.7v0.6c0,4.9,1.8,9.9,3.3,14.7
        c1.4,4.4,2.6,9,3.2,13.2l0.5,6.8v4.7c0,4-1.7,12.3-5.8,14.2c-5.4,2.8-11.3,3.6-16.9,4.3c-1,0.1-2,0.2-3,0.4
        c-1.1,0.2-10.7,0.3-22.8,0.3s-21.7-0.1-22.8-0.3c-1-0.1-2-0.3-2.9-0.4c-5.7-0.7-11.5-1.5-17-4.3C4.7,362.1,3,353.8,3,349.8v-4.7
        l0.7-6.8c0.5-4.3,2-8.8,3.3-13.2c1.5-4.8,3-9.8,3.5-14.7l0.1-0.6c0.2-1.9,0.2-3.9,0.6-5.7l-0.2-0.3v-19.2l0.2-0.1
        c-0.5-6.8-0.6-11.4-0.6-12c0-0.2,0-0.6-2-17.8c-1.1-8.7-3.3-22.6-4.6-31c-0.5-3.1-1-5.7-1-6.2v-4.2c0-0.1,0.3-0.2,0.4-0.3
        c0.5-0.6,1.2-1.2,1.2-2.1c0-0.6-0.2-1.2-0.5-1.9c-0.1-0.3-0.4-0.9-0.4-1.1v-0.2c-0.3-1.5-0.3-3.1-0.3-4.7c0-1.1,0-2.2-0.1-3.3
        l0.4-17.5c0.1-0.3,0.2-0.6,0.2-0.9c0-0.3,0-0.5,0.1-0.7c0.1-0.1,0-0.3,0-0.5v-4.5l1.7-13.8c0.3-1.2,0.5-2.3,0.6-3.4
        c0.3-2.7,0.7-5.2,2.6-7.6c0.5-0.6,0.4-1.4-0.1-2c-1.2-1.2-1.2-1.8-1.2-3.3c0-0.6,0.4-5.2,0.9-7.9c2-7.8,4.6-15.8,7.1-23.6
        c1.8-5.5,3.6-11.2,5.2-16.8c2.7-8.8,6-20.3,8.5-30.4c0.8-2.1,1-4.2,1.3-6.2c0.1-1.2,0.3-2.3,0.5-3.5v-0.2c0.2-2.2,0.4-4.6,0.7-7
        v-0.4L32,31.9c0-0.2,0-0.4-0.1-0.6c-0.5-1-1-1.8-1.4-2.4c-0.7-1-1-1.6-1-2.7c0-1.6,1.3-4.9,2.3-7.3c1-2.5,1.6-3.9,1.6-4.8
        c0-2.2-0.9-3.3-1.6-4.2c-0.6-0.7-0.9-1.1-0.9-2c0-3.6,1.9-4.2,7.2-4.7c0.7,0,1.4-0.1,2.1-0.2h22.6c0.7,0.1,1.5,0.2,2.2,0.2
        c5.3,0.5,7.2,1,7.2,4.7c0,0.9-0.3,1.3-0.9,2c-0.7,0.8-1.6,2-1.6,4.2c0,0.9,0.5,2.3,1.6,4.8c1,2.4,2.3,5.7,2.3,7.3
        c0,1.2-0.4,1.7-1,2.7c-0.4,0.6-0.9,1.4-1.4,2.4C71,31.5,71,31.7,71,31.9l0.2,17.6v0.4c0.2,2.4,0.4,4.8,0.7,7v0.2
        c0.2,1.1,0.4,2.3,0.5,3.5c0.3,2,0.5,4.1,1.3,6.2c2.4,10.1,5.8,21.6,8.5,30.4c1.6,5.6,3.5,11.3,5.2,16.8c2.5,7.7,5.1,15.7,7.1,23.6
        c0.4,2.7,0.9,7.4,0.9,7.9c0,1.5,0,2.1-1.2,3.3c-0.5,0.5-0.6,1.4-0.1,2c1.9,2.5,2.2,5,2.6,7.6c0.1,1.1,0.4,2.2,0.6,3.4l1.6,13.8v5
        c0.1,0.2,0.1,0.5,0.1,0.7c0,0.3,0.1,0.6,0.2,0.9l0.5,17.5c-0.1,1.1-0.1,2.2-0.1,3.3c0,1.6-0.1,3.2-0.3,4.7v0.2
        c0,0.2-0.2,0.8-0.4,1.1c-0.3,0.6-0.5,1.2-0.5,1.9c0,0.9,0.7,1.5,1.2,2.1c0.1,0.1,0.4,0.2,0.4,0.3v4.2c0,0.5-0.6,3.1-1,6.2
        c-1.3,8.3-3.5,22.3-4.6,31C92.2,271.9,92.2,272.3,92.3,272.5L92.3,272.5z">
        </path>
      </defs>

      <!-- Clip Paths -->
      <clipPath id="waves_mask">
        <use xlink:href="#waves_mask_path"  overflow="visible"></use>
      </clipPath>

      <!-- Bottle Outline -->
      <path class="bottle_outline" fill="none" stroke="#30A600" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M40.2,1.5c-5.5,0.7-10.7,0.2-10.7,6.4c0,3,2.5,3,2.5,6.2c0,1.6-3.9,8.9-3.9,12.1c0,2.5,1.4,3.2,2.5,5.7l-0.2,17.6
      c-0.2,2.5-0.5,5-0.7,7.3c-0.7,3.2-0.7,6.4-1.8,9.6c-2.3,9.7-5.5,20.6-8.5,30.5c-3.9,13.5-8.9,27-12.3,40.5c-0.5,2.7-0.9,7.5-0.9,8.2
      c0,1.6,0,2.7,1.6,4.3c-3,3.9-2.5,7.8-3.4,11.7l-1.6,13.9v4.6c-0.2,0.7-0.2,1.4-0.5,1.8l-0.5,17.8c0.2,2.7,0,5.5,0.5,8.2
      c0,0.9,0.9,2.3,0.9,3c0,0.5-0.9,1.2-1.4,1.8v4.8c0,0.5,3.9,24.7,5.5,37.3c0.7,5.7,2.1,17.2,2.1,17.6c0,0.7,0.2,5.5,0.7,12.1v19.2
      c-0.5,2.1-0.7,4.3-0.9,6.4c-0.9,9.2-5.7,18.8-6.8,27.9l-0.8,7v4.8c0,4.4,1.8,13.3,6.6,15.6c6.6,3.4,13.7,3.9,20.3,4.8
      c2.7,0.4,43.3,0.4,46.1,0c6.6-0.9,13.7-1.4,20.3-4.8c4.8-2.3,6.6-11.2,6.6-15.6V345l-0.7-6.9c-1.1-9.2-5.9-18.8-6.8-27.9
      c-0.2-2.1-0.5-4.3-0.9-6.4v-19.2c0.5-6.6,0.7-11.4,0.7-12.1c0-0.5,1.4-11.9,2.1-17.6c1.6-12.6,5.5-36.8,5.5-37.3v-4.8
      c-0.5-0.7-1.4-1.4-1.4-1.8c0-0.7,0.9-2.1,0.9-3c0.5-2.7,0.2-5.5,0.5-8.2l-0.5-17.8c-0.2-0.5-0.2-1.1-0.4-1.8v-4.6l-1.6-13.9
      c-0.9-3.9-0.5-7.8-3.4-11.7c1.6-1.6,1.6-2.7,1.6-4.3c0-0.7-0.5-5.5-0.9-8.2c-3.4-13.5-8.5-27-12.3-40.5c-3-9.8-6.2-20.8-8.5-30.4
      c-1.1-3.2-1.1-6.4-1.8-9.6c-0.2-2.3-0.5-4.8-0.7-7.3l-0.3-17.8c1.1-2.5,2.5-3.2,2.5-5.7c0-3.2-3.9-10.5-3.9-12.1
      c0-3.2,2.5-3.2,2.5-6.2c0-6.2-5.2-5.7-10.7-6.4L40.2,1.5L40.2,1.5z">
      </path>

      <!-- Bottle Fill -->	
      <path class="bottle_fill" fill="url(#fillGradient)" d="M92.3,272.5c0,0.6,0,5.2-0.5,12l0.2,0.1v19.5c0.4,1.8,0,3.8,1,5.7v0.6
      c0,4.9,1.8,9.9,3.3,14.7c1.4,4.4,2.6,9,3.2,13.2l0.5,6.8v4.7c0,4-1.7,12.3-5.8,14.2c-5.4,2.8-11.3,3.6-16.9,4.3c-1,0.1-2,0.2-3,0.4
      c-1.1,0.2-10.7,0.3-22.8,0.3s-21.7-0.1-22.8-0.3c-1-0.1-2-0.3-2.9-0.4c-5.7-0.7-11.5-1.5-17-4.3C4.7,362.1,3,353.8,3,349.8v-4.7
      l0.7-6.8c0.5-4.3,2-8.8,3.3-13.2c1.5-4.8,3-9.8,3.5-14.7l0.1-0.6c0.2-1.9,0.2-3.9,0.6-5.7l-0.2-0.3v-19.2l0.2-0.1
      c-0.5-6.8-0.6-11.4-0.6-12c0-0.2,0-0.6-2-17.8c-1.1-8.7-3.3-22.6-4.6-31c-0.5-3.1-1-5.7-1-6.2v-4.2c0-0.1,0.3-0.2,0.4-0.3
      c0.5-0.6,1.2-1.2,1.2-2.1c0-0.6-0.2-1.2-0.5-1.9c-0.1-0.3-0.4-0.9-0.4-1.1v-0.2c-0.3-1.5-0.3-3.1-0.3-4.7c0-1.1,0-2.2-0.1-3.3
      l0.4-17.5c0.1-0.3,0.2-0.6,0.2-0.9c0-0.3,0-0.5,0.1-0.7c0.1-0.1,0-0.3,0-0.5v-4.5l1.7-13.8c0.3-1.2,0.5-2.3,0.6-3.4
      c0.3-2.7,0.7-5.2,2.6-7.6c0.5-0.6,0.4-1.4-0.1-2c-1.2-1.2-1.2-1.8-1.2-3.3c0-0.6,0.4-5.2,0.9-7.9c2-7.8,4.6-15.8,7.1-23.6
      c1.8-5.5,3.6-11.2,5.2-16.8c2.7-8.8,6-20.3,8.5-30.4c0.8-2.1,1-4.2,1.3-6.2c0.1-1.2,0.3-2.3,0.5-3.5v-0.2c0.2-2.2,0.4-4.6,0.7-7
      v-0.4L32,31.9c0-0.2,0-0.4-0.1-0.6c-0.5-1-1-1.8-1.4-2.4c-0.7-1-1-1.6-1-2.7c0-1.6,1.3-4.9,2.3-7.3c1-2.5,1.6-3.9,1.6-4.8
      c0-2.2-0.9-3.3-1.6-4.2c-0.6-0.7-0.9-1.1-0.9-2c0-3.6,1.9-4.2,7.2-4.7c0.7,0,1.4-0.1,2.1-0.2h22.6c0.7,0.1,1.5,0.2,2.2,0.2
      c5.3,0.5,7.2,1,7.2,4.7c0,0.9-0.3,1.3-0.9,2c-0.7,0.8-1.6,2-1.6,4.2c0,0.9,0.5,2.3,1.6,4.8c1,2.4,2.3,5.7,2.3,7.3
      c0,1.2-0.4,1.7-1,2.7c-0.4,0.6-0.9,1.4-1.4,2.4C71,31.5,71,31.7,71,31.9l0.2,17.6v0.4c0.2,2.4,0.4,4.8,0.7,7v0.2
      c0.2,1.1,0.4,2.3,0.5,3.5c0.3,2,0.5,4.1,1.3,6.2c2.4,10.1,5.8,21.6,8.5,30.4c1.6,5.6,3.5,11.3,5.2,16.8c2.5,7.7,5.1,15.7,7.1,23.6
      c0.4,2.7,0.9,7.4,0.9,7.9c0,1.5,0,2.1-1.2,3.3c-0.5,0.5-0.6,1.4-0.1,2c1.9,2.5,2.2,5,2.6,7.6c0.1,1.1,0.4,2.2,0.6,3.4l1.6,13.8v5
      c0.1,0.2,0.1,0.5,0.1,0.7c0,0.3,0.1,0.6,0.2,0.9l0.5,17.5c-0.1,1.1-0.1,2.2-0.1,3.3c0,1.6-0.1,3.2-0.3,4.7v0.2
      c0,0.2-0.2,0.8-0.4,1.1c-0.3,0.6-0.5,1.2-0.5,1.9c0,0.9,0.7,1.5,1.2,2.1c0.1,0.1,0.4,0.2,0.4,0.3v4.2c0,0.5-0.6,3.1-1,6.2
      c-1.3,8.3-3.5,22.3-4.6,31C92.2,271.9,92.2,272.3,92.3,272.5L92.3,272.5z">
      </path>

      <!-- Bottle Loader -->
      <path id="bottle_loader" class="bottle_loader" fill="none" stroke="#FFFFFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M40.1,1.5c-5.5,0.7-10.7,0.2-10.7,6.4c0,3,2.5,3,2.5,6.2c0,1.6-3.9,8.9-3.9,12.1c0,2.5,1.4,3.2,2.5,5.7l-0.2,17.6
      c-0.2,2.5-0.5,5-0.7,7.3c-0.7,3.2-0.7,6.4-1.8,9.6c-2.3,9.7-5.5,20.6-8.5,30.5c-3.9,13.5-8.9,27-12.3,40.5c-0.5,2.7-0.9,7.5-0.9,8.2
      c0,1.6,0,2.7,1.6,4.3c-3,3.9-2.5,7.8-3.4,11.7l-1.6,13.9v4.6c-0.2,0.7-0.2,1.4-0.5,1.8l-0.5,17.8c0.2,2.7,0,5.5,0.5,8.2
      c0,0.9,0.9,2.3,0.9,3c0,0.5-0.9,1.2-1.4,1.8v4.8c0,0.5,3.9,24.7,5.5,37.3c0.7,5.7,2.1,17.2,2.1,17.6c0,0.7,0.2,5.5,0.7,12.1v19.2
      c-0.5,2.1-0.7,4.3-0.9,6.4c-0.9,9.2-5.7,18.8-6.8,27.9l-0.8,7v4.8c0,4.4,1.8,13.3,6.6,15.6c6.6,3.4,13.7,3.9,20.3,4.8
      c2.7,0.4,43.3,0.4,46.1,0c6.6-0.9,13.7-1.4,20.3-4.8c4.8-2.3,6.6-11.2,6.6-15.6V345l-0.7-6.9c-1.1-9.2-5.9-18.8-6.8-27.9
      c-0.2-2.1-0.5-4.3-0.9-6.4v-19.2c0.5-6.6,0.7-11.4,0.7-12.1c0-0.5,1.4-11.9,2.1-17.6c1.6-12.6,5.5-36.8,5.5-37.3v-4.8
      c-0.5-0.7-1.4-1.4-1.4-1.8c0-0.7,0.9-2.1,0.9-3c0.5-2.7,0.2-5.5,0.5-8.2l-0.5-17.8c-0.2-0.5-0.2-1.1-0.4-1.8v-4.6l-1.6-13.9
      c-0.9-3.9-0.5-7.8-3.4-11.7c1.6-1.6,1.6-2.7,1.6-4.3c0-0.7-0.5-5.5-0.9-8.2c-3.4-13.5-8.5-27-12.3-40.5c-3-9.8-6.2-20.8-8.5-30.4
      c-1.1-3.2-1.1-6.4-1.8-9.6c-0.2-2.3-0.5-4.8-0.7-7.3l-0.3-17.8c1.1-2.5,2.5-3.2,2.5-5.7c0-3.2-3.9-10.5-3.9-12.1
      c0-3.2,2.5-3.2,2.5-6.2c0-6.2-5.2-5.7-10.7-6.4L40.1,1.5L40.1,1.5z">
      </path>

      <!-- Bottle Open -->
      <path class="bottle_open" fill="#FFFFFF" d="M51.5,372c-10.9,0-21.9-0.1-23.3-0.3c-0.9-0.1-1.8-0.2-2.7-0.3
      c-6-0.8-12.2-1.5-18.1-4.6c-6-2.9-7.4-13.5-7.4-16.9V345c0-0.1,0-0.1,0-0.2l0.8-7c0.5-4.6,2-9.2,3.4-13.7c1.5-4.7,3-9.5,3.4-14.1
      l0-0.2c0.2-2,0.4-4.2,0.9-6.3v-19c-0.5-6.4-0.7-11.4-0.7-12.2c0-0.6-1.1-9.4-2.1-17.4c-1.1-8.5-3.2-22.4-4.5-30.6
      c-1-6.4-1-6.6-1-6.9v-4.8c0-0.4,0.1-0.7,0.3-1c0.2-0.2,0.4-0.4,0.6-0.7c0.1-0.1,0.3-0.3,0.4-0.4c-0.1-0.2-0.2-0.4-0.2-0.5
      c-0.3-0.6-0.6-1.4-0.6-2.1c-0.3-1.8-0.4-3.6-0.4-5.3c0-1,0-2-0.1-2.9c0-0.1,0-0.1,0-0.2l0.5-17.8c0-0.3,0.1-0.6,0.3-0.9
      c0,0,0-0.2,0.1-0.4c0-0.2,0.1-0.4,0.1-0.7v-4.4c0-0.1,0-0.1,0-0.2l1.6-13.9c0-0.1,0-0.1,0-0.2c0.2-1,0.4-2.1,0.5-3.2
      c0.3-2.6,0.7-5.3,2.4-8.1c-1.2-1.6-1.2-3-1.2-4.4c0-0.9,0.4-5.7,0.9-8.5c2-8.1,4.7-16.3,7.2-24.2c1.7-5.4,3.5-11,5.1-16.5
      C20.8,87,24,75.9,26.4,66c0,0,0-0.1,0-0.1c0.6-1.8,0.9-3.6,1.1-5.6c0.2-1.2,0.3-2.5,0.6-3.8c0.1-1,0.2-2,0.3-3.1
      c0.1-1.3,0.3-2.7,0.4-4.1L29,32.2c-0.3-0.7-0.7-1.2-1-1.7c-0.7-1.1-1.5-2.3-1.5-4.3c0-2.2,1.4-5.5,2.6-8.4c0.6-1.3,1.3-3.2,1.3-3.7
      c0-1.1-0.4-1.5-0.9-2.2c-0.7-0.8-1.6-1.9-1.6-3.9c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5c0,0.9,0.3,1.4,0.9,2c0.7,0.8,1.6,2,1.6,4.2
      c0,0.9-0.5,2.3-1.6,4.8c-1,2.4-2.3,5.7-2.3,7.3c0,1.1,0.4,1.7,1,2.7c0.4,0.6,0.9,1.4,1.4,2.4c0.1,0.2,0.1,0.4,0.1,0.6l-0.2,17.6
      c0,0,0,0.1,0,0.1c-0.1,1.4-0.3,2.8-0.4,4.2c-0.1,1.1-0.2,2.1-0.3,3.1c0,0.1,0,0.1,0,0.2c-0.3,1.2-0.4,2.4-0.6,3.6
      c-0.3,2-0.5,4-1.2,6.1c-2.4,9.9-5.6,21-8.5,30.5c-1.6,5.5-3.4,11.1-5.1,16.5c-2.5,7.8-5.1,16-7.1,23.9c-0.5,2.5-0.9,7.2-0.9,7.8
      c0,1.5,0,2.1,1.2,3.2c0.5,0.5,0.6,1.4,0.1,2c-1.9,2.5-2.2,5-2.6,7.6c-0.1,1.1-0.3,2.2-0.6,3.4l-1.6,13.7v4.5c0,0.1,0,0.3-0.1,0.4
      c-0.1,0.2-0.1,0.4-0.1,0.6c-0.1,0.4-0.1,0.8-0.3,1.2l-0.5,17.4c0.1,1,0.1,2,0.1,3c0,1.7,0.1,3.4,0.4,5c0,0.1,0,0.2,0,0.3
      c0,0.2,0.2,0.8,0.4,1.1c0.3,0.7,0.5,1.3,0.5,1.9c0,1-0.7,1.7-1.3,2.3c0,0-0.1,0.1-0.1,0.1v4.1c0.1,0.5,0.5,3.3,1,6.5
      c1.3,8.3,3.4,22.1,4.5,30.7c0.8,6.4,2.1,17.3,2.1,17.8c0,0.6,0.2,5.2,0.7,12c0,0,0,0.1,0,0.1v19.2c0,0.1,0,0.2,0,0.3
      c-0.5,1.9-0.7,4-0.8,6l0,0.2c-0.5,4.9-2,9.9-3.5,14.7c-1.4,4.4-2.8,8.9-3.3,13.2l-0.8,6.9v4.7c0,4,1.7,12.3,5.7,14.2
      c5.5,2.8,11.4,3.6,17.1,4.3c0.9,0.1,1.8,0.2,2.7,0.4c2.5,0.4,43.1,0.4,45.7,0c0.9-0.1,1.8-0.2,2.7-0.4c5.7-0.7,11.6-1.5,17.1-4.3
      c4.1-2,5.8-10.3,5.8-14.3v-4.7l-0.7-6.8c-0.5-4.3-1.9-8.9-3.3-13.4c-1.5-4.8-3-9.7-3.5-14.5c-0.2-2.2-0.5-4.3-0.9-6.3
      c0-0.1,0-0.2,0-0.3v-19.2c0,0,0-0.1,0-0.1c0.5-6.8,0.7-11.4,0.7-12c0-0.4,0.3-3.3,2.1-17.8c1.1-8.6,3.2-22.4,4.5-30.7
      c0.5-3.2,0.9-6,1-6.5v-4.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5-0.6-1.1-1.2-1.1-2c0-0.6,0.3-1.2,0.5-1.9c0.1-0.3,0.4-0.9,0.4-1.1
      c0-0.1,0-0.2,0-0.3c0.3-1.4,0.3-2.9,0.3-4.4c0-1.2,0-2.4,0.2-3.6l-0.5-17.5c-0.1-0.3-0.1-0.6-0.2-0.9c0-0.2-0.1-0.5-0.2-0.7
      c0-0.1-0.1-0.3-0.1-0.4v-4.5l-1.6-13.7c-0.3-1.1-0.4-2.3-0.6-3.4c-0.4-2.7-0.7-5.2-2.6-7.7c-0.4-0.6-0.4-1.4,0.1-2
      c1.2-1.2,1.2-1.7,1.2-3.2c0-0.6-0.5-5.2-0.9-7.9c-1.9-7.6-4.4-15.3-6.8-22.8c-1.9-5.8-3.8-11.7-5.4-17.6
      c-3.8-12.3-6.5-22.2-8.5-30.4c-0.7-2.1-1-4.1-1.2-6.1c-0.2-1.2-0.3-2.4-0.6-3.6c0-0.1,0-0.1,0-0.2c-0.1-1-0.2-2.1-0.3-3.1
      c-0.1-1.4-0.3-2.8-0.4-4.2L71,31.9c0-0.2,0-0.4,0.1-0.6c0.5-1,1-1.8,1.4-2.4c0.7-1,1-1.5,1-2.7c0-1.6-1.3-4.9-2.3-7.3
      c-1-2.5-1.6-3.9-1.6-4.8c0-2.2,0.9-3.3,1.6-4.2c0.6-0.7,0.9-1.1,0.9-2c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5c0,2-0.9,3.1-1.6,3.9
      c-0.6,0.7-0.9,1.2-0.9,2.3c0,0.5,0.8,2.3,1.3,3.7c1.2,2.9,2.6,6.3,2.6,8.4c0,2-0.8,3.2-1.5,4.3c-0.3,0.5-0.7,1-1,1.7l0.3,17.5
      c0.1,1.3,0.2,2.7,0.4,4c0.1,1.1,0.2,2.1,0.3,3.1c0.3,1.3,0.4,2.5,0.6,3.8c0.3,1.9,0.5,3.8,1.1,5.6c0,0,0,0.1,0,0.1
      c1.9,8.1,4.7,18,8.5,30.3c1.6,5.9,3.6,11.8,5.4,17.5c2.4,7.5,5,15.3,6.9,23c0,0,0,0.1,0,0.1c0.4,2.7,0.9,7.6,0.9,8.4
      c0,1.4,0,2.8-1.2,4.4c1.7,2.8,2.1,5.5,2.4,8.1c0.2,1.1,0.3,2.1,0.5,3.2c0,0.1,0,0.1,0,0.2l1.6,13.9c0,0.1,0,0.1,0,0.2v4.4
      c0.1,0.3,0.1,0.6,0.2,0.9c0,0.2,0.1,0.5,0.1,0.6c0.1,0.2,0.1,0.3,0.1,0.5l0.5,17.8c0,0.1,0,0.1,0,0.2c-0.1,1.1-0.1,2.2-0.2,3.4
      c0,1.6,0,3.2-0.3,4.8c0,0.7-0.3,1.4-0.6,2.1c-0.1,0.1-0.1,0.3-0.2,0.5c0.1,0.1,0.1,0.2,0.2,0.2c0.3,0.3,0.6,0.6,0.8,1
      c0.2,0.3,0.3,0.6,0.3,0.9v4.8c0,0.2,0,0.5-1,6.9c-1.3,8.3-3.4,22.1-4.5,30.6c-0.6,5-2,16.6-2.1,17.5c0,0.8-0.2,5.7-0.7,12.1v19
      c0.4,2,0.7,4.2,0.9,6.4c0.4,4.5,1.9,9.3,3.4,13.9c1.4,4.6,2.9,9.3,3.4,13.9l0.7,6.9c0,0,0,0.1,0,0.2v4.8c0,3.5-1.4,14-7.5,17
      c-5.9,3-12.1,3.8-18.1,4.6c-0.9,0.1-1.8,0.2-2.7,0.3C73.3,371.9,62.4,372,51.5,372z">
      </path>

      <!-- Fill Wave -->
      <path class="fill_waves" clip-path="url(#waves_mask)" fill="#F9FF58" d="M117.2,35.1c8.7,0-0.7-11.8-0.7-11.8
      c-1.6-2.5-3.8-4.7-6-4.7c-3.1,0-4.3,4.5-7.3,4.5c-3.1,0-4.2-4.5-7.4-4.5c-3.1,0-4.3,4.5-7.3,4.5c-3.1,0-4.2-4.5-7.4-4.5
      c-3.1,0-4.3,4.5-7.3,4.5c-3.1,0-4.2-4.5-7.4-4.5c-3.1,0-4.3,4.5-7.3,4.5c-3.1,0-3.7-4.5-7.4-4.5c-3.1,0-4.3,4.5-7.3,4.5
      c-3.1,0-4.2-4.5-7.4-4.5c-3.1,0-4.3,4.5-7.3,4.5c-3.1,0-4.2-4.5-7.4-4.5c-3.1,0-4.3,4.5-7.3,4.5c-3.1,0-4.2-4.5-7.4-4.5
      C4.1,18.5,3,23-0.1,23c-3.1,0-3.7-4.5-7.4-4.5h-0.1c-2.2,0-4.4,2.2-6,4.7c0,0-9.4,11.8-0.7,11.8H117.2z">
      </path>

      <!-- Bottle Cap -->
      <path class="bottle_cap" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#FFFFFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
      M40.6,1.5C34.8,2.2,29,1.7,29.5,8.1c0.2,3,44.1,2.2,44-0.6c-0.1-5.8-5-5.3-10.1-6L40.6,1.5z">
      </path>


      <!-- Bottle Logo -->
      <path id="bottle_x5F_logo_1_" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M67.7,186.1c-0.2,0.5-2.4,4.5-4,4.5
      c-0.6,0-0.8-0.6-0.8-1c0-1.5,1.7-4.4,2.6-5.5C65.9,184.9,66.9,185.8,67.7,186.1z M83.6,181.6c0,0.1-0.6,1.2-1,1.8
      c-0.5,0.8-0.9,1.7-1.4,2.5c-0.5,0.7-2.5,4.7-3.8,4.7c-0.4,0-0.4-0.3-0.4-0.5c0-2.1,4.4-8.8,6.1-8.8
      C83.3,181.2,83.6,181.3,83.6,181.6z M69.2,182.1c0,1-0.6,2.3-1.1,3.2c-0.6,0-1.7-1.1-1.7-1.7v-0.7c0.2-1,1.2-1.9,1.9-1.9
      C69,181,69.2,181.8,69.2,182.1z M25.5,177.5c0.4,0.9,1.5,1.6,2.3,1.8c-0.3,0.5-2.6,4.8-4.2,4.8c-0.6,0-0.7-0.7-0.7-1
      C22.9,181.4,24.6,178.9,25.5,177.5L25.5,177.5z M45.7,175c0,0.1-0.6,1.1-0.9,1.7c-0.7,1.1-1.4,2.4-2.1,3.6c-0.1,0.1-2,3.9-3.2,3.9
      c-0.5,0-0.5-0.3-0.5-0.5c0-2,3.9-7.2,5.1-8.4c0.2-0.2,0.8-0.5,1.1-0.5C45.4,174.6,45.7,174.7,45.7,175z M29.2,175.7
      c0,0.8-0.6,1.9-0.8,2.7c-1.1,0-1.9-0.8-1.9-1.7c0-0.9,0.9-2.2,1.9-2.2C29,174.6,29.2,175.3,29.2,175.7z M53.2,173.7l-1.3,2.2h-2.3
      l1.3-2.2H53.2z M30.1,175.3c0,0.9-0.5,2.1-0.8,3.1h0.2c0.3-0.1,0.6-0.2,0.8-0.4c1-0.5,2.1-1.8,3.2-2.8c1.2-1.1,2.2-1.8,3.5-1.8
      c1,0,1.8,0.6,1.8,1.7c0,0.6-0.8,3.1-2,3.1c-0.5,0-0.6-0.4-0.6-0.8c0-0.6,0.4-1.4,1.2-1.7c0.2-0.1,0.4-0.1,0.6-0.1
      c0.1-0.1,0.2-0.3,0.2-0.4c0-0.5-0.4-0.6-0.7-0.6c-1.3,0-3.3,2.7-4,3.8c-0.4,0.6-0.8,1.3-1.1,2.1c-0.5,1-0.9,1.9-0.9,2.9
      c0,0.6,0.2,1.5,1.1,1.5c1.5,0,3.4-2.4,4.5-3.8c1.7-2.1,3.1-4.6,5.5-6.6c0.6-0.5,1.6-1,2.3-1c0.4,0,1,0.2,1.4,0.8
      c0.1-0.1,0.2-0.3,0.3-0.5H49c-1.8,3.2-3.6,6.3-5.4,9.5c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.4,0.3,0.4c0.2,0,0.8-0.5,0.9-0.5
      c1.1-1,2.1-2.4,2.9-3.4h0.8c-0.8,1.3-4.6,6.5-6.7,6.5c-0.6,0-1.1-0.2-1.1-1c-0.1-0.9,0-1,0-1c-1,1.1-2.2,2-3.2,2
      c-1.1,0-1.5-0.8-1.5-1.6c0-0.1,0.2-1.2,0.3-1.8c-2,2-3.5,3.4-5.4,3.4c-1.9,0-2.3-1.8-2.3-3c0-1.3,1-3.4,1.7-4.5c0,0,0,0-0.1,0
      c-0.1,0-0.8,0.1-1.2,0.3c-0.1,0-0.2,0.2-0.2,0.3c-1.1,2.1-3,5-5.4,6.4c-0.7,0.4-1.1,0.6-1.7,0.6c-1.5,0-1.7-1.4-1.7-2.4
      c0-4,5.2-10.9,8.6-10.9C29.7,173.4,30.1,174.3,30.1,175.3z M67.8,169.6c-0.1,0.8-0.3,1.7-0.6,2.5c-0.2,0.6-0.7,1.4-1.1,2
      c-0.3,0.5-2.3,2.7-3.1,2.7c-0.6,0-0.7-0.4-0.7-0.8c0-1.8,3.1-5.8,4.8-6.6c0.2-0.1,0.5-0.3,0.8-0.4V169.6z M86.3,168.3
      c0,0.6-0.1,0.9-0.4,1.6c-0.1,0.2-0.2,0.5-0.4,0.7c-0.2,0.2-0.4,0.2-0.7,0.2h-0.1c0.3-0.7,0.9-1.7,0.9-2.4c0-0.2-0.2-0.3-0.3-0.3
      c-1.2,0-4.9,4.5-5.1,4.8c-2.5,3.3-4.6,6.9-6.2,10.2c0,0,0,0,0,0.1c0.6-0.4,1.2-0.9,1.8-1.4c2.2-2,4.6-4.5,6.2-6.7
      c0.2-0.2,0.4-0.5,0.5-0.7c0.2,0,0.5,0,0.8-0.1c-0.4,0.6-0.9,1.3-1.4,1.9c-1.9,2.4-4.7,5.5-7.1,7.4c-0.6,0.5-1.3,0.8-1.7,1.4
      c-0.2,0.2-0.3,0.7-0.4,1c-0.3,0.7-1.1,3-1.1,4c0,0.4,0,0.9,0.6,0.9c0.5,0,1.3-1,1.5-1.3c2-2.8,3.3-5.8,6.4-8.4
      c1-0.8,1.7-1.2,2.6-1.2c0.6,0,0.9,0.1,1.4,1c0.2-0.2,0.3-0.4,0.4-0.7H87l-2.7,4.9c-0.3,0.6-0.7,1.3-1,1.9c-0.1,0.1-1.7,2.9-1.7,3.4
      c0,0.2,0.2,0.3,0.3,0.3c0.1,0,0.5-0.2,0.7-0.4c1.3-1.1,2.3-2.6,3.2-3.9h0.8c-0.6,1.1-4.4,6.8-6.8,6.8c-1,0-1.2-0.7-1.2-1.4
      c0-0.1,0-0.5,0-0.7c-1,0.9-2.3,2.1-3.3,2.1c-1.2,0-1.5-1-1.5-1.9c0-0.1,0-0.5,0-0.8c-1.1,1.2-2.5,2.7-3.6,2.7
      c-0.8,0-1.5-0.9-1.5-1.6v-1c0.2-1.5,0.7-2.9,1.2-4.4l0-0.1c-0.4,0.1-0.9,0.1-1.3,0.2c-0.2,0-0.2,0.2-0.3,0.3
      c-0.8,1.2-1.5,2.6-2.6,3.8c-1.1,1.3-2.9,2.9-4.2,2.9c-0.3,0-0.8-0.1-1.1-0.5c-0.2-0.3-0.5-0.9-0.5-1.2v-0.9
      c0.3-3.8,5.1-10.7,8.5-10.7c1.2,0,1.6,0.8,1.6,1.8c0,1.1-0.6,2.6-1.1,3.7c0.1,0,0.1,0.1,0.2,0.1c0.3,0,1.1-0.1,1.2-0.4
      c0.5-0.9,0.9-1.9,1.4-2.8c1.5-2.9,3.3-5.9,5.8-8.9c1.6-2,5.6-6.3,7.8-6.3C86.1,167.2,86.3,167.7,86.3,168.3z M81.9,173.9
      c-0.8,0-0.6,0-1.6-0.2l1.8-2.2c0.3,0,0.8,0,0.8,0c2.8,0,6.1-1.6,8.8-4.5C89.4,171.4,85.3,173.9,81.9,173.9z M67.5,167.2
      c0.2,0.1,0.5,0.5,0.6,0.5c0.1,0,0.2-0.1,0.3-0.1c1-0.4,2-0.7,3-0.7c1.4,0,3,0.5,4.4,1.4c1.2,0.8,2.4,1.4,3.6,2.2l-1.9,2.1
      c-2.5-1.4-5.6-4.2-8.1-4.2c-0.2,0-0.6,0-0.8,0.2v1.2l-0.1,0.6c-0.1,0.4-0.2,0.9-0.4,1.3c-0.8,2.4-3.3,5.9-5.3,5.9
      c-1,0-1.3-0.9-1.3-1.6c0-0.5,0.2-1.1,0.4-1.5c0.6-1.9,2.7-4.5,4.8-5.9l0.6-0.4c-0.1-0.1-0.7-0.2-0.7-0.2c-1.2,0-3.1,1.4-4.1,2.2
      c-4.7,3.9-8.7,10.2-9.9,13.9c-0.3,1-0.9,3-0.9,4.3c0,1.2,0.5,2.8,2.2,2.8c0.7,0,1.4-0.4,2.2-0.9c2.4-1.9,4.6-5.6,4.6-7.9
      c0-0.5-0.1-1.1-0.8-1.1c-1.1,0-2,1.6-2.4,2.5c-0.3,0.5-0.4,1.2-0.6,1.8c-1,0.5-2.3,1.5-3.2,2.6c0.2-3.7,2.9-8.1,6.1-8.1
      c1.4,0,1.7,1.3,1.7,2.2c0,3.3-3.4,8.8-7.2,10.5c-0.8,0.3-1.4,0.5-2.1,0.5c-3,0-3.8-3-3.8-4.9c0-1.6,0.4-2.8,0.9-4.4
      c1.9-5.5,8.3-14.2,14.5-16.4c0.8-0.3,1.7-0.5,2.4-0.5C66.5,166.9,67.1,167,67.5,167.2z M29.7,168.9c0,0.7-0.8,3.2-2.1,3.2
      c-0.6,0-0.7-0.4-0.7-0.8c0-0.6,0.3-1.4,1.1-1.8c0.2-0.1,0.5-0.1,0.8-0.2c0.2-0.1,0.2-0.3,0.2-0.5c0-0.7-0.6-0.9-1-0.9
      c-0.8,0-1.9,0.6-2.6,1c-4.6,3.3-8.4,9.4-9.5,12.8c-0.6,1.7-1.1,3.3-1.1,5c0,3.6,2.3,5,5.1,5c3.8,0,10.8-3,16.1-3
      c3.2,0,5.6,0.7,9.2,4c0.3,0.2,0.6,0.6,0.8,0.9c-1.9-1.2-3.9-1.9-5.7-1.9c-1.3,0-2.5,0-3.6,1.8c-1.6-2.6-4-2.8-5.9-2.8
      c-4.1,0-9.8,2.4-13.2,2.4c-4.2,0-6.3-2.9-6.3-6.7c0-5.1,3.6-11.9,9-16.3c0.5-0.4,1.1-0.9,1.6-1.2c1.9-1.2,3.9-2.1,5.8-2.1
      C29,166.8,29.7,167.8,29.7,168.9z">
      </path>

      <!-- Bubbles Group -->
      <g class="bubbles_group">
        <path class="circle-1" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#EEEEEE" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M42.5-18c0-3.9,3.1-7,7-7s7,3.1,7,7s-3.1,7-7,7S42.5-14.1,42.5-18z">
        </path>

        <circle class="circle-2" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#FFFFFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="68.5" cy="-27" r="5">
        </circle>

        <circle class="circle-3" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#FFFFFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="44.3" cy="-64.6" r="5">
        </circle>

        <path class="circle-4" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#FE7C7C" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M31.8-30c0-2.7,2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9c0,2.7-2.2,4.9-4.9,4.9C34-25.1,31.8-27.3,31.8-30z">
        </path>

        <circle class="circle-5" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#A92E34" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="56.3" cy="-46" r="12">
        </circle>

        <circle class="circle-6" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#EEEEEE" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="65.7" cy="-98.5" r="5">
        </circle>

        <path class="circle-7" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#F16C6E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M36.7-86c0-5.8,4.7-10.6,10.6-10.6S57.8-91.9,57.8-86s-4.7,10.6-10.6,10.6S36.7-80.2,36.7-86z">
        </path>

        <circle class="circle-8" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#A92E34" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="26.5" cy="-73.6" r="5">
        </circle>

        <circle class="circle-9" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#EEEEEE" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="26.5" cy="-49" r="12">
        </circle>
      </g>
    </svg>
  </div>
</div>

</body>`