import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'
import moment from 'moment'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import { USAGE_TYPES } from '@vfgroup-oneplatform/soho-framework/BusinessOverview/Utils/Enums'
import { Plans as PlansService } from '@vfgroup-oneplatform/soho-framework/Services/BusinessOverview'

import { PRODUCT_STATUS } from '@vfgroup-oneplatform/soho-framework/Services/Enums'

import { USER_ROLES } from '@vfgroup-oneplatform/soho-framework/MyUsersAndGroups/Utils/Enums'

import { getFormattedDate } from '@vfgroup-oneplatform/soho-framework/Services/BusinessOverview/Plans/Utils'

import { convertMB, convertMbTo } from '../../../Utils/Helpers'

import { API_URLS } from '../../Config'
import ReduxCache from '../../../Utils/ReduxCache'


const mapError = (error) => {
  const payload = error?.response?.data?.message || error?.message
  return {
    message: payload
  }
}


const iconMapper = (usageType) => {
  if (usageType.includes(USAGE_TYPES.DATA)) {
    return 'ic_data_sharing'
  } else if (usageType.includes(USAGE_TYPES.CALLS)) {
    return 'ic_call_log'
  } else if (usageType.includes(USAGE_TYPES.SMS)) {
    return 'ic_sms_text'
  } else {
    return ''
  }
}

const getContractDataLabel = (name) => {
  switch (name) {
    case 'renewal-date':
      return 'soho_plans_plan_card_contract_data_renewal_date_label'
    case 'contract-ends':
      return 'soho_plans_plan_card_contract_data_contract_ends_label'
    case 'minimum-term-ends':
      return 'soho_plans_plan_card_contract_data_minimum_term_ends_label'
    case 'eligible-upgrade-from':
      return 'soho_plans_plan_card_contract_data_eligible_upgrade_from_label'
  }
  return name
}

const month_translations = {
  al: {
    '1': 'Jan',
    '2': 'Shk',
    '3': 'Mar',
    '4': 'Pri',
    '5': 'Maj',
    '6': 'Qer',
    '7': 'Korr',
    '8': 'Gush',
    '9': 'Shta',
    '10': 'Tet',
    '11': 'Nën',
    '12': 'Dhj',
  },
  en: {
    '1': 'Jan',
    '2': 'Feb',
    '3': 'Mar',
    '4': 'Apr',
    '5': 'May',
    '6': 'Jun',
    '7': 'Jul',
    '8': 'Aug',
    '9': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
  }
}

const mapUnassignedUsers = (plan) => {

  const unassignedUsers = []

  if (!plan?.unassignedUser || plan?.unassignedUser <= 0) {
    return []
  }

  plan?.unassignedUser?.map(user => {
    const { id, fullName, relatedParty } = user

    const relatedPartyObject = relatedParty.find(u => u.role?.toLowerCase() !== (USER_ROLES.USER || USER_ROLES.GRANTER))

    unassignedUsers.push({
      id,
      name: fullName || id,
      role: relatedPartyObject?.role,
      '@type': user['@type'],
      icon: 'ic_admin'
    })
  })
  return unassignedUsers
}

const mapUsers = (plan) => {

  const mappedUsers = []

  plan?.relatedParty?.forEach(user => {
    const { id, name, role } = user
    if (user['@type'] === 'individual') {
      mappedUsers.push({
        id,
        name,
        role,
        '@type': user['@type'],
        '@referredType': user['@referredType'],
        icon: 'ic_admin'
      })
    }
  })
  return mappedUsers
}

const mapUsageData = (plan, usageConsumptionReport) => {

  const formattedData = []

  const data = usageConsumptionReport?.[0]?.bucket?.filter(el => el?.product?.[0]?.id === plan?.id)

  if (!data || data?.length === 0) {
    return []
  }


  data.map(usageItem => {

    const { name, usageType, bucketCounter } = usageItem

    const totalUsageObj = bucketCounter?.find(el => el.counterType === 'global') || {}
    const usedUsageObj = bucketCounter?.find(el => el.counterType === 'used') || {} //is actually remainingg amount

    const isUnlimited = totalUsageObj?.valueName === 'Unlimited'

    const { amount: totalAmount, units: totalUnits } = convertMB(totalUsageObj?.value?.units, totalUsageObj?.value?.amount)

    const { amount: usedAmount, units: usedUnits } = convertMbTo(totalUnits, usedUsageObj?.value?.amount, usedUsageObj?.value?.units)

    const leftAmount = usedAmount
    const valuePercentage = parseInt(leftAmount / totalAmount * 100, 10)

    formattedData.push({
      name,
      usageType: name,
      icon: iconMapper(usageType),
      totalAmount: parseFloat(Math.round(totalAmount * 100) / 100, 10)?.toString(),
      remainingAmount: parseFloat(Math.round(leftAmount * 100) / 100, 10)?.toString(),
      usedUnits,
      totalUnits,
      isUnlimited,
      valuePercentage
    })
  })

  return formattedData
}


const mapPlansResponse = (response) => {

  const language = ReduxCache.getLanguage()

  if (!response || response.length === 0 || Object.keys(response).length === 0) {
    return []
  }

  const { product, usageConsumptionReport } = response

  const filteredPlans = product && product?.length > 0 && product?.filter(el => [PRODUCT_STATUS.ACTIVE, PRODUCT_STATUS.TERMINATED].includes(el?.status))

  const mappedResponse = []

  if (!filteredPlans || filteredPlans.length <= 0) {
    return []
  }

  filteredPlans.map(item => {
    const { id, productCharacteristic, status, name, productTerm, productPrice, terminationDate } = item

    const type = productCharacteristic?.find(el => el.name === 'plan-type')?.value || ''
    const serviceType = productCharacteristic?.find(el => el.name === 'service-plan-type')?.value || ''

    const mappedProductTerm = []

    if (productTerm && productTerm.length > 0) {
      productTerm.map(term => {
        const { name: termName, validFor } = term

        if (Object.keys(validFor).length > 0 && termName) {
          mappedProductTerm.push({
            key: getContractDataLabel(termName),
            value: getFormattedDate(validFor),
            type: name,
          })
        }
      })
    }

    const price = productPrice?.[0]?.price?.taxIncludedAmount?.value
    const currency = productPrice?.[0]?.price?.taxIncludedAmount?.unit

    let usageData = []


    const usagePercentageContainerStyle = {}

    if (usageConsumptionReport?.length === 0 || !usageConsumptionReport) {
      usagePercentageContainerStyle.borderBottomWidth = 0
      usagePercentageContainerStyle.paddingVertical = 0
      usagePercentageContainerStyle.marginTop = 0
    } else {
      usageData = mapUsageData(item, usageConsumptionReport)
    }

    const users = mapUsers(item)
    const unassignedUsers = mapUnassignedUsers(item)

    let terminationMonth = ''
    let terminationFormatedDate = ''
    let renewalDate = ''

    if (terminationDate && moment(terminationDate).isValid()) {
      terminationMonth = moment(terminationDate).format('M')
      terminationFormatedDate = moment(terminationDate).format('DD')
      renewalDate = ContentManager.translate('my_plan_primary_card_header_renewal_date', [`${terminationFormatedDate?.toString()} ${month_translations[language]?.[terminationMonth]}`])
    }


    mappedResponse.push({
      id,
      status,
      name,
      type,
      serviceType,
      contractData: mappedProductTerm,
      price: price ? parseFloat(price).toFixed(2) : null,
      currency,
      usageData,
      usagePercentageContainerStyle,
      users,
      unassignedUsers,
      withPrice: false,
      renewalDate,
    })
  })

  return mappedResponse
}


const getPlans = async (id, type, utilities) => {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
    responseMapper: mapPlansResponse
  })

  const { onStart, onSuccess, onError, errorMapper, responseMapper } = _utilities

  onStart()

  const headers = {
    'vf-project': 'soho',
  }

  const params = type === 'organization' ? {
    "relatedParty[(@referredType=='organization')].id": id,
    '@type': 'Plan',
  } : {
    "relatedParty[role=='employee'].id": id,
    '@type': 'Plan',
  }

  try {
    const response = await axios.get(API_URLS.business.busninessOverview.plan, {
      headers: headers,
      params: params
    })
    const _response = responseMapper(response?.data, iconMapper)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const getPlansExtrasData = async (planId, utilities) => {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
    responseMapper: PlansService.mapPlansExtrasResponse
  })

  const { onStart, onSuccess, onError, errorMapper, responseMapper } = _utilities

  onStart()

  const headers = {
    'vf-project': 'soho',
  }

  const params = {
    'productOfferingRelationship.relationshipType': 'Addon',
    'id': planId,
  }

  try {
    const response = await axios.get(API_URLS.business.busninessOverview.ProductOffering, {
      headers: headers,
      params: params,
    })
    const _response = responseMapper(response?.data, iconMapper)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const removeUserFromPlan = async (planId, userId, utilities) => {

  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
  })

  const { onStart, onSuccess, onError, errorMapper } = _utilities

  onStart()

  const headers = {
    'vf-project': 'soho',
  }

  const data = [
    {
      'op': 'remove',
      'path': `/relatedParty.id=${userId}`
    }
  ]

  try {
    const response = await axios.patch(`${API_URLS.business.busninessOverview.product}/${planId}`, data, {
      headers: headers,
    })
    const _response = response?.data
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }

}

const addUsersToPlan = async (planId, users, utilities) => {

  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
  })

  const { onStart, onSuccess, onError, errorMapper } = _utilities

  onStart()


  const headers = {
    'vf-project': 'soho',
  }

  const data = []

  users.map(user => {
    data.push({
      'op': 'add',
      'path': '/relatedParty',
      'value': user
    })
  })

  try {
    const response = await axios.patch(`${API_URLS.business.busninessOverview.product}/${planId}`, data, {
      headers: headers,
    })
    const _response = response?.data

    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}


export {
  getPlans,
  removeUserFromPlan,
  addUsersToPlan,
  getPlansExtrasData,
}
