import { StyleSheet, Platform, Dimensions } from 'react-native'
import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  card: theme => ({
    backgroundColor: theme.colors.cardBackgroundColorThree,
    justifyContent: 'space-between',
    borderBottomColor: theme.colors.VFDivider.tertiary,
    borderBottomWidth: 1,
    shadowColor: theme.colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 8,
    elevation: 8,
    marginBottom: 20,
    borderRadius: 6,
    padding: 20
  }),
  itemNameContainer: theme => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomColor: theme.colors.VFDivider.tertiary,
    borderBottomWidth: 1,
    // borderBottomEndRadius: 44 / 2,
    // borderBottomStartRadius: 44 / 2,
    padding: 16.6,
    marginHorizontal: 16.6
  }),
  itemNameText: theme => ({
    fontSize: 18,
    lineHeight: 26,
    ...Fonts.type.bold,
    color: theme.colors.textColor
  }),
  editItemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  itemEdit: theme => ({
    color: theme.colors.text.tertiary,
    fontSize: 16.7,
    letterSpacing: 0,
    marginRight: 8.5
  }),
  itemDetailsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 17,
    marginHorizontal: 17,
    alignItems: 'center'
  },
  image: {
    width: 84,
    height: 84,
    marginRight: 16,
    resizeMode: 'contain'
  },
  itemDetailsSubContainer: {
    flexGrow: 0.1,
  },
  itemDetailsSubContainerChild: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  statusText: {
    fontSize: 18,
    lineHeight: 22.9,
    fontWeight: 400,
  },
  statusMultilineContainer: { alignItems: 'flex-end' },
  itemUpdatedAtContainer: {
    padding: 16.6
  },
  itemUpdatedAt: theme => ({
    fontSize: 18,
    color: theme.colors.textColorTwo,
    fontWeight: 400,
    lineHeight: 21,
    marginTop: 20
  })
})
