import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { StyleSheet, Dimensions } from 'react-native'

const { width } = Dimensions.get('screen')

export default StyleSheet.create({
  filterContainer: {
    width: width,
    marginLeft: -16,
    marginTop: 30,
    marginBottom: 0
  },
  expansionPanelContainer: {
    marginBottom: 15,
  },
  breakdownTitle: {
    ...Fonts.type.bold,
    fontSize: Fonts.size.h4,
    marginBottom: 20,
    marginTop: 15
  }
})
