import React from 'react'
import { View, TouchableOpacity, TouchableWithoutFeedback, ImageBackground, Dimensions } from 'react-native'
import PropTypes from 'prop-types'

import {
  withTheme,
  LightThemeColors
} from '@vfgroup-oneplatform/foundation/Components/Themes'
import { VFText } from '@vfgroup-oneplatform/foundation/Components'

import { getThemeImages } from '../../../Themes'

import LastBills from '../../LastBills'

import styles from './SecondaryComponent.Styles'

import { OneOrMoreBills, TopUp, CardHeader } from './Components'


const SecondaryComponent = ({
  extraInfo,
  userType,
  theme,
  userTypeInitial,
  nonVf,
  guest
}) => {

  const { billing = {}, topUp = {} } = extraInfo

  const { isCurrencyFirst } = billing

  const currentBill = {
    'value': billing?.value,
    'currency': billing?.currency,
    'paymentInfo': billing?.paymentInfo,
    'lastBill': billing?.lastBill || []
  }


  const Images = getThemeImages(theme.name)

  if (userTypeInitial === '' || userType === '' || !userType) {
    return null
  }

  if (userType === 'non_vf' || userType === 'guest') {

    const element = userType?.includes('non_vf') ? nonVf : guest

    return (
      <TouchableWithoutFeedback style={{ flex: 1 }} onPressIn={element?.onPress}>
        <View style={{ flex: 1 }}>
          <ImageBackground source={{ uri: element?.background }} style={[styles?.imageBackgroundContainer, { flex: 1 }]}>
            <View style={styles.nonVfContainer}>
              <CardHeader icon={element?.icon} title={element?.title} type={'secondary'} />
              <VFText
                i18nKey={element?.subTitle}
                style={styles?.nonVfSubTitle}
              />
            </View>
          </ImageBackground>
        </View>
      </TouchableWithoutFeedback>
    )
  }

  if (userType === 'prepaid_fix') {
    return (
      <View style={styles.twoCardContainer(theme)}>
        <TouchableOpacity style={[styles.halfContainer(theme), { minHeight: Dimensions.get('window').width > 700 ? 250 : null }]} onPress={billing.onPress} activeOpacity={1}>
          <CardHeader icon="icBillReport" title="dashboard_billing_card_title" />
          <View>
            {isNaN(currentBill.lastBill?.[0]?.bill_amount) ?
              <VFText
                i18nKey="billing_no_bill_title"
                style={styles.billFixSubTitle}
                type="primary"
                numberOfLines={1}
              />
              :
              <>
                <VFText
                  i18nKey="dashboard_billing_card_subtitle"
                  style={styles.billFixSubTitle}
                  type="primary"
                  numberOfLines={1}
                />
                <VFText
                  i18nKey={isCurrencyFirst ? currentBill.lastBill?.[0]?.bill_currency + ' ' + currentBill.lastBill?.[0]?.bill_amount : currentBill?.lastBill?.[0]?.bill_amount + ' ' + currentBill?.lastBill?.[0]?.bill_currency}
                  style={styles.billFixValue}
                  type="primary"
                />
              </>
            }
          </View>
        </TouchableOpacity>

        <TouchableOpacity style={styles.halfContainer(theme)} onPress={topUp.onPress} activeOpacity={1}>
          <CardHeader icon="icTopUp" title="dashboard_top_up_card_title" />
          <View style={{ flexDirection: 'row', gap: 8 }}>
            <VFText
              i18nKey="dashboard_top_up_card_subtitle"
              style={styles.billFixSubTitle}
              type="primary"
              numberOfLines={1}
            />
            <VFText
              i18nKey={topUp.value + ' ' + topUp.currency}
              style={styles.billFixValue}
              type="primary"
            />
          </View>
        </TouchableOpacity>
      </View>
    )
  }

  if (currentBill?.lastBill && currentBill?.lastBill.length > 0) {
    return <LastBills
      data={currentBill.lastBill}
      onPress={billing.onPress}
      billing={billing}
      userType={userType}
    />
  }

  return (
    <>
      {userType !== 'prepaid' ? (
        <View style={styles.container}>
          <CardHeader icon="icBillReport" title="dashboard_billing_card_title" />
          <OneOrMoreBills
            bill={currentBill}
            userType={userType}
            isCurrencyFirst={isCurrencyFirst}
            onPress={billing.onPress}
          />
        </View>
      ) : (
        <>
          <TopUp
            paymentInfo={topUp.paymentInfo}
            currency={topUp.currency}
            value={topUp.value}
            image={Images.icTopUp}
            onPress={topUp.onPress}
          />
        </>
      )}
    </>
  )

}

SecondaryComponent.defaultProps = {
  theme: LightThemeColors
}
SecondaryComponent.propTypes = {
  theme: PropTypes.objectOf({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  }),
  extraInfo: PropTypes.objectOf({
    currentBill: PropTypes.object,
    isCurrencyFirst: PropTypes.bool
  }),
  userType: PropTypes.string,
  userTypeInitial: PropTypes.string,
  nonVf: PropTypes.any,
  guest: PropTypes.any
}

export default withTheme(SecondaryComponent)