import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
    option: theme => ({
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingVertical: 16,
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.borderColorThree
    }),
    isLast_true: {
        borderBottomWidth: 0,
    },
    label: {
        fontSize: 16,
    }
})

export { styles }
