
const getUsageAmount = (item) => {
  if (item?.isUnlimited) {
    return 'soho_user_plan_tile_unlimited_title'
  } else {
    const text = item?.usedAmount.toString() + '/' + item?.totalAmount.toString() + ' ' + item?.totalUnits
    return text
  }
}

const sortByName = (array) => {
  return array.sort((a, b) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  })
}

export {
  getUsageAmount,
  sortByName,
}
