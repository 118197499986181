import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { CatalogueDetailsScreen } from '@vfal-lab/rn-vfg-catalogue/Screens'

import { Dimensions, Platform } from 'react-native'

import Payments from '../PaymentsProvider'
import { actions as catalogueActions } from '../Catalogue/Catalogue.Actions'

import { actions } from './CatalogueDetails.Actions'



function CatalogueDetails({ navigation, route }) {

  const dispatch = useDispatch()

  const item = route.params?.item || {}

  const prevScreen = route.params?.prevScreen

  const [showPayments, setShowPayments] = useState(false)
  const [paymentObj, setPaymentObj] = useState({})

  const offerActivation = useSelector(state => state.catalogueOfferActivation)

  useEffect(() => {
    if (offerActivation.completed) {
      dispatch(catalogueActions.getCatalogue('myoffers'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerActivation.completed])

  const openPayments = () => {
    const { channel } = item

    const {
      id,
      name,
      category,
      price,
      unit,
      section,
      recommendationissue,
      recommendationgroup,
    } = item

    const finalPrice = { value: parseInt(price), unit }

    const characteristics = section ? [
      {
        name: 'type',
        value: 'recommendation',
      },
      {
        name: 'RecommendationIssue',
        value: recommendationissue,
      },
      {
        name: 'RecommendationGroup',
        value: recommendationgroup,
      },
    ] : [
      {
        name: 'type',
        value: 'standard',
      },
    ]

    // if(paymentMethod == 'creditcard'){
    //   characteristics.push({
    //     "name": "paymentToken",
    //     "value": "tokenized card"
    //   })

    //   characteristics.push({
    //     "name": "paymentTokenType",
    //     "value": "Default/TokenId/CardId"
    //   })
    // }

    characteristics.push({
      name: 'payment_methods',
      value: 'creditcard',
    })

    characteristics.push({
      name: 'Category',
      value: category,
    })

    let validity = {}

    item.showMoreData.map(data => {
      if (data.icon == 'validity') {
        validity = data
      }
    })

    setPaymentObj({
      channel: channel,
      characteristics: characteristics,
      price: finalPrice,
      id: id,
      name: name,
      title: 'activate_bundle',
      validity: validity
    })

    setShowPayments(true)
  }

  const orderItem = async (paymentMethod, utilities) => {

    const { pending, productOrderItem } = (offerActivation || {})
    const { channel } = item

    const {
      id,
      name,
      category,
      prices,
      price,
      unit,
      section,
      recommendationissue,
      recommendationgroup,
    } = item

    const finalPrice = { value: price, unit }

    if (paymentMethod === 'loan') {
      finalPrice.value = prices?.loan?.price
      finalPrice.unit = prices?.loan?.unit
    }

    const characteristics = section ? [
      {
        name: 'type',
        value: 'recommendation',
      },
      {
        name: 'RecommendationIssue',
        value: recommendationissue,
      },
      {
        name: 'RecommendationGroup',
        value: recommendationgroup,
      },
    ] : [
      {
        name: 'type',
        value: 'standard',
      },
    ]

    let orderItems = [
      {
        id,
        quantity: 1,
        action: 'add',
        product: {
          name,
          productCharacteristic: [
            {
              name: 'payment_methods',
              value: paymentMethod,
            },
            {
              name: 'Category',
              value: category,
            },
            ...characteristics,
          ],
          productPrice: [
            {
              price: {
                taxIncludedAmount: { ...finalPrice },
              },
            },
          ],
        },
      },
    ]

    if (pending) {
      orderItems = orderItems.concat(productOrderItem)
    }

    return dispatch(actions.activateOffer(channel, orderItems, utilities))
  }

  const resetOrderActivation = async () => {
    return dispatch(actions.resetOfferActivation())
  }

  const onBack = () => {
    if (prevScreen) {
      navigation.pop(2)
    } else {
      navigation.goBack()
    }
  }

  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
    return (
      <>
        <CatalogueDetailsScreen
          item={item}
          offerActivation={offerActivation}
          activate={orderItem}
          resetOfferActivation={resetOrderActivation}
          onBack={onBack}
          openPayments={openPayments}
        />
        {showPayments && (
          <Payments
            setShowPayment={setShowPayments}
            isVisible={showPayments}
            paymentObj={paymentObj}
            isOfferActivating={true}
          />
        )}
      </>
    )
  }

  return (
    <>
      <CatalogueDetailsScreen
        item={item}
        offerActivation={offerActivation}
        activate={orderItem}
        resetOfferActivation={resetOrderActivation}
        onBack={onBack}
        openPayments={openPayments}
      />
      {showPayments && (
        <Payments
          setShowPayment={setShowPayments}
          isVisible={showPayments}
          paymentObj={paymentObj}
        />
      )}
    </>
  )
}

CatalogueDetails.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
}

export default CatalogueDetails
