import React, { Component } from 'react'
import { Platform } from 'react-native'
import PropTypes from 'prop-types'
import {
  has as _has,
  isFunction as _isFunction,
  cloneDeep as _cloneDeep,
  isNil as _isNil
} from 'lodash'

import { Devices, AddOns, Balance } from '@vfgroup-oneplatform/framework'

import {
  HeaderTabs,
  TabView,
  VFButton
  , VFScreen
} from '@vfgroup-oneplatform/foundation/Components'

import { withTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { User_Types } from '@vfgroup-oneplatform/foundation/Utils/UserTypes.Enum'


import ManualRefresh from '@vfgroup-oneplatform/foundation/Components/ManualRefresh'


import { ProductsAndServicesTrackingManager } from '@vfgroup-oneplatform/framework/Utils/TrackingManagers'

import { ProductsAndServicesTab } from '@vfgroup-oneplatform/framework/ProductsAndServices/Components'

import styles from './ProductsAndServices.Styles'

import MyFixPlan from './MyFixPlan'

import { FIX_TABS, TABS_LABELS } from './Utils/Enums'

class ProductsAndServices extends Component {
  constructor(props) {
    super(props)
    this.HeaderTabsProps = this.getHeaderTabsProps()
    this.state = {
      activeTab: 0
    }
  }

  componentDidMount() {
    const { activeTab } = this.state
    ProductsAndServicesTrackingManager.trackView()
    this.onActiveTabChange(activeTab)
  }

  // Handle tab change for deep link
  componentDidUpdate(prevProps) {
    if (prevProps.defaultActiveTab !== this.props.defaultActiveTab) {
      this.onActiveTabChange(this.props.defaultActiveTab)
    }
  }

  onActiveTabChange = activeTab => {
    const { onActiveTabChange } = this.props
    this.setState({
      activeTab
    })
    if (_isFunction(onActiveTabChange)) {
      onActiveTabChange(activeTab, this.HeaderTabsProps[activeTab])
    }
  }
  getHeaderTabsProps = () => {
    const { customTabs } = this.props

    const originalTabs = FIX_TABS

    const cutomTabs = this.filterTabsPerPlatform(customTabs)

    const allTabs = this.mergeCustomTabsWithOriginal(originalTabs, cutomTabs)

    return allTabs.map(({ key, tab_key }, index) => ({ key, tab_key, index }))
  }

  filterTabsPerPlatform = tabs => {
    return tabs.filter(tab => {
      return _isNil(tab.platform) || tab.platform === Platform.OS
    })
  }

  mergeCustomTabsWithOriginal = (originalTabs, cutomTabs) => {
    const mergedTabs = _cloneDeep(originalTabs)
    for (let i = 0; i < cutomTabs.length; i++) {
      if (_has(cutomTabs[i], 'index')) {
        mergedTabs.splice(cutomTabs[i].index, 0, cutomTabs[i])
      } else {
        mergedTabs.push(cutomTabs[i])
      }
    }
    return mergedTabs
  }
  shouldScrollBeEnabled = () => {
    const { activeTab: activeTabIndex } = this.state
    const { myPlanProps, addOnsProps, devicesProps, balanceProps } = this.props
    const activeTab = this.HeaderTabsProps[activeTabIndex].key
    if (
      (activeTab == TABS_LABELS.MY_PLAN && myPlanProps.isMyPlanLoading) ||
      (activeTab == TABS_LABELS.ADDONS && addOnsProps.isLoading) ||
      (activeTab == TABS_LABELS.DEVICES && devicesProps.isLoading) ||
      (activeTab == TABS_LABELS.BALANCE && balanceProps.isLoading)
    ) {
      return false
    }
    return true
  }
  renderTabContent = (TabComponent, tabProps) => {
    const { pageBannerProps, withPageBanner, autoTopUpBannerProps } = this.props

    return (
      <ProductsAndServicesTab
        TabComponent={TabComponent}
        pageBannerProps={pageBannerProps}
        withPageBanner={withPageBanner}
        autoTopUpBannerProps={autoTopUpBannerProps}
        {...tabProps}
      />
    )
  }
  renderDescription = () => {
    const { images, onRefresh, lastUpdateTime } = this.props
    return (
      <ManualRefresh
        onRefresh={onRefresh}
        images={images}
        lastUpdateTime={lastUpdateTime}
        style={styles.refreshViewStyle}
      />
    )
  }

  renderTabsContent = () => {
    const {
      myPlanProps,
      devicesProps,
      theme,
      withTray,
      addOnsProps,
      images,
      customTabs,
      balanceProps
    } = this.props
    return this.HeaderTabsProps.map(({ key }, index) => (
      <TabView index={index}>
        {this.renderTab({
          key,
          myPlanProps,
          devicesProps,
          theme,
          withTray,
          addOnsProps,
          images,
          customTabs,
          balanceProps
        })}
      </TabView>
    ))
  }

  renderTab = ({
    key,
    myPlanProps,
    devicesProps,
    theme,
    withTray,
    addOnsProps,
    images,
    customTabs,
    balanceProps
  }) => {
    switch (key) {
      case TABS_LABELS.MY_PLAN:
        return this.renderTabContent(MyFixPlan, {
          theme,
          images,
          withTray,
          ...myPlanProps
        })
      case TABS_LABELS.DEVICES:
        return this.renderTabContent(Devices, {
          withTray,
          ...devicesProps
        })
      case TABS_LABELS.ADDONS:
        return this.renderTabContent(AddOns, {
          withTray,
          buttonStyle: styles.buttonStyle,
          ...addOnsProps
        })

      case TABS_LABELS.BALANCE:
        return this.renderTabContent(Balance, {
          withTray,
          ...balanceProps
        })
      default:
        return (customTabs || [])
          .find(tab => tab.key === key)
          ?.renderTabContent()
    }
  }

  renderSubHeaderRightComponent = () => {
    const { vfScreenProps, topUpProps } = this.props
    const { renderSubHeaderRightComponent } = vfScreenProps
    if (_isFunction(renderSubHeaderRightComponent)) {
      return renderSubHeaderRightComponent()
    }
    const { onTopUpButtonPress, toggleModal } = topUpProps
    return (
      <VFButton
        title={'my_products_header_payg_topup_button_title'}
        onPress={
          _isFunction(onTopUpButtonPress) ? onTopUpButtonPress : toggleModal
        }
        style={styles.subHeaderRightComponent}
        textStyle={styles.subHeaderRightComponentStyle}
        textAccessibilityLabel={
          'my_products_header_payg_topup_button_title_label'
        }
        type={'primary'}
      />
    )
  }

  render() {
    const { vfScreenProps, userType, renderTopUp } = this.props
    const { activeTab } = this.state

    return (
      <VFScreen
        renderDescriptionInfo={this.renderDescription}
        renderSubHeaderRightComponent={
          userType === User_Types.PAY_G
            ? this.renderSubHeaderRightComponent
            : null
        }
        {...vfScreenProps}
        configureHeaderTabs={{
          activeTab,
          setActiveTab: this.onActiveTabChange,
          tabs: this.HeaderTabsProps,
          scrollEnabled: this.shouldScrollBeEnabled()
        }}
        subHeaderDescriptionContainerStyle={styles.subHeaderDescriptionContainerStyle}
      >
        <HeaderTabs activeTab={activeTab}>
          {this.renderTabsContent()}
        </HeaderTabs>
        {renderTopUp()}
      </VFScreen>
    )
  }
}

ProductsAndServices.defaultProps = {
  userType: User_Types.PAY_M,
  refreshIcon: 'ic_refresh'
}

ProductsAndServices.propTypes = {
  userType: PropTypes.oneOf(Object.values(User_Types)),
  myPlanProps: PropTypes.objectOf({
    addExtraPlanData: PropTypes.object,
    onPressAddConfirmation: PropTypes.func,
    withUpgradePlanCTA: PropTypes.bool,
    withUsagePercentage: PropTypes.bool,
    planData: PropTypes.array,
    forceOpenModal: PropTypes.objectOf({
      force: PropTypes.bool,
      key: PropTypes.string,
      onModalVisibilityChange: PropTypes.func
    }),
    onPressUpgradePlan: PropTypes.func
  }),
  devicesProps: PropTypes.objectOf({
    CurrentDeviceData: PropTypes.object,
    onPressUpgradenow: PropTypes.func
  }),
  withPageBanner: PropTypes.bool,
  pageBannerProps: PropTypes.objectOf({
    backgroundColors: PropTypes.array,
    title: PropTypes.string,
    description: PropTypes.string,
    primaryButtonTitle: PropTypes.string,
    secondaryButtonTitle: PropTypes.string,
    onPrimaryButtonPress: PropTypes.func,
    onSecondaryButtonPress: PropTypes.func,
    withSecondaryButton: PropTypes.bool
  }),
  customTabs: [],
  theme: PropTypes.objectOf({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  }),
  withTray: PropTypes.bool,
  addOnsProps: PropTypes.objectOf({
    isMonthView: PropTypes.bool,
    currentPlan: PropTypes.object,
    handleNavigateToAddonsShop: PropTypes.func,
    data: PropTypes.object,
    inlineContentData: PropTypes.object,
    isInlineContentEnabled: PropTypes.bool,
    images: PropTypes.array,
    addOnsListIcon: PropTypes.object,
    addOnsTimelineIcon: PropTypes.object,
    onItemPress: PropTypes.func,
    onInlineContentPress: PropTypes.func
  }),
  images: PropTypes.array,
  headerDescription: PropTypes.string,
  defaultActiveTab: PropTypes.oneOf(0, 1, 2),
  onActiveTabChange: PropTypes.func,
  refreshIcon: PropTypes.string,
  vfScreenProps: PropTypes.objectOf({
    title: PropTypes.string,
    withSubHeader: PropTypes.bool,
    subHeaderTitle: PropTypes.string,
    subHeaderSubTitle: PropTypes.string,
    subHeaderAfterSubTitle: PropTypes.string,
    subHeaderDescription: PropTypes.string,
    onClose: PropTypes.func,
    onScroll: PropTypes.func
  }),
  balanceProps: PropTypes.objectOf({
    withCategoories: PropTypes.bool,
    images: PropTypes.arrayOf(PropTypes.Object),
    balanceData: PropTypes.object
  }),
  topUpProps: PropTypes.objectOf({
    toggleModal: PropTypes.func
  }),
  renderTopUp: PropTypes.func,
  onRefresh: PropTypes.func,
  lastUpdateTime: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number,
    PropTypes.string
  ]),
  autoTopUpBannerProps: PropTypes.shape({
    backgroundColors: PropTypes.array,
    title: PropTypes.string,
    description: PropTypes.string,
    primaryButtonTitle: PropTypes.string,
    onPrimaryButtonPress: PropTypes.func
  })
}

export default withTheme(ProductsAndServices)
