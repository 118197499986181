import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'
import moment from 'moment'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'
import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import ReduxCache from '../../Utils/ReduxCache'

import { isAdminView } from '../../Utils'

import { API_URLS } from '../Config'


const mapError = (error) => {
  const payload = error?.response?.data?.message || error?.message
  return payload
}

const appointmentsResponseMapper = (res) => {
  const formattedResponse = []

  if (!res || res?.length === 0) {
    return []
  }

  res.map(item => {
    const { category, id, validFor, place, note } = item || {}

    const momentStartDateTime = moment.utc(validFor?.startDateTime)
    const momentEndDateTime = moment.utc(validFor?.endDateTime)

    const startHour = momentStartDateTime.format('HH:mm')
    const endHour = momentEndDateTime.format('HH:mm')

    formattedResponse.push({
      service: {
        id,
        title: category,
        image: category,
        description: note?.[0]?.text
      },
      place: place,
      date: {
        selectedDate: validFor?.startDateTime,
        selectedTime: `${startHour} - ${endHour}`
      }
    })
  })

  return formattedResponse
}

const servicesResponseMapper = (res) => {
  if (!res || res?.length === 0 || !res?.[0]?.digitalProductOffering || res?.digitalProductOffering?.[0]?.length === 0) {
    return []
  }
  const formattedResponse = []

  res?.[0]?.digitalProductOffering.map(productOfferingItem => {

    const { id, name, description, digitalTerm, category } = productOfferingItem

    const durationItem = digitalTerm?.[0]?.duration || {}


    formattedResponse.push({
      id,
      title: name,
      subtitle: ContentManager.translate('book_appointment_service_duration', [durationItem?.amount || '', durationItem?.unit || '']),
      description,
      serviceIcon: category?.[0]?.id,
      data: productOfferingItem
    })
  })

  return formattedResponse
}

const storesResponseMapper = (res) => {
  if (!res || res?.length === 0) {
    return []
  }

  const formattedResponse = []

  res.map(item => {
    const { id, name, streetName, streetNr, city, country, geographicLocation: { geometry } } = item

    const addressObject = {
      name: streetName + ' ' + streetNr + `${streetNr ? ', ' : ''}` + city + ', ' + country,
      latitude: geometry?.[0]?.y,
      longitude: geometry?.[0]?.x,
    }

    formattedResponse.push({
      id,
      name,
      address: addressObject,
      data: item
    })
  })

  return formattedResponse
}

const openHoursResponseMapper = (res) => {
  let formattedResponse = {}
  const formattedCalendar = {}

  const { calendar, code } = res?.[0] || {}

  if (calendar && calendar?.length > 0) {
    calendar.map(item => {
      const { day, hourPeriod } = item

      if (hourPeriod && hourPeriod?.length > 0) {
        formattedCalendar[day] = []
        hourPeriod.map(hourPeriodItem => {
          // moment.locale('en')
          formattedCalendar[day] = [
            ...formattedCalendar[day],
            `${moment(hourPeriodItem.startHour, 'hh:mm A', 'en', true).format('HH:mm')} - ${moment(hourPeriodItem.endHour, 'hh:mm A', 'en', true).format('HH:mm')}`
          ]
        })
      }
    })
  }

  formattedResponse = {
    calendar: formattedCalendar,
    email: code
  }

  return formattedResponse
}

const timeSlotsResponseMapper = (res) => {
  const formattedResponse = []
  if (!res || res.length === 0) {
    return []
  }
  res.map(item => {
    const { id, availableTimeSlot } = item || {}
    const timeSlots = []
    if (availableTimeSlot?.length > 0 && availableTimeSlot) {
      availableTimeSlot.map(el => {
        timeSlots.push({
          startTime: moment(el?.validFor?.startDateTime).utc().format('HH:mm'),
          endTime: moment(el?.validFor?.endDateTime).utc().format('HH:mm'),
        })
      })
    }
    formattedResponse.push({ id, timeSlots })
  })
  return formattedResponse
}



const getAppointments = async (params, utilities) => {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
    responseMapper: appointmentsResponseMapper
  })

  const { onStart, onSuccess, onError, errorMapper, responseMapper } = _utilities

  onStart()


  const headers = {
    'vf-operator': 'bookappointment'
  }

  try {
    const response = await axios.get(API_URLS.appointments.appointment, {
      params: params,
      headers: headers,
    })
    const _response = responseMapper(response?.data)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const getServices = async (utilities) => {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
    responseMapper: servicesResponseMapper
  })

  const { onStart, onSuccess, onError, errorMapper, responseMapper } = _utilities

  onStart()

  const msisdn = ReduxCache.getMsisdn()

  const params = {
    'relatedParty.id': msisdn,
    'relatedParty.referredType': isAdminView() ? 'organization' : 'individual'
  }

  const headers = {
    'vf-operator': 'bookappointment',
    'vf-project': 'selfcare'
  }

  try {
    const response = await axios.get(API_URLS.appointments.services, {
      params: params,
      headers: headers
    })
    const _response = responseMapper(response?.data)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const getStoresData = async (utilities) => {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
    responseMapper: storesResponseMapper
  })

  const { onStart, onSuccess, onError, errorMapper, responseMapper } = _utilities

  onStart()

  const name = isAdminView() ? 'vfoffice' : 'vfshop'

  const params = {
    'geographicLocation.name': name
  }

  const headers = {
    'vf-operator': 'bookappointment',
  }

  try {
    const response = await axios.get(API_URLS.appointments.geographicAddress, {
      params: params,
      headers: headers
    })
    const _response = responseMapper(response?.data)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }

}

const getStoresOpenHours = async (id, utilities) => {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
    responseMapper: openHoursResponseMapper
  })

  const { onStart, onSuccess, onError, errorMapper, responseMapper } = _utilities

  onStart()

  const params = {
    'place.id': id,
    'relatedParty.role': isAdminView() ? 'organization' : 'individual',
    'relatedParty.id': ReduxCache.getMsisdn()
  }

  try {
    const response = await axios.get(API_URLS.appointments.openHours, {
      params: params,
      headers: {
        'vf-operator': 'bookappointment'
      }
    })
    const _response = responseMapper(response?.data)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const getAvailableTimeSlots = async (date, placeId, utilities) => {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
    responseMapper: timeSlotsResponseMapper
  })

  const { onStart, onSuccess, onError, errorMapper, responseMapper } = _utilities

  onStart()

  const year = date.format('YYYY')
  const month = date.format('MM')
  const day = date.format('DD')

  const params = {
    'place.id': placeId,
    'validFor.startDateTime': `${year}-${month}-${day}T16:00:00.000Z`
  }

  try {
    const response = await axios.get(API_URLS.appointments.timeSlots, {
      params: params,
      headers: {
        'vf-operator': 'bookappointment',
      }
    })
    const _response = responseMapper(response?.data)
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

const postAppointment = async (data, utilities) => {
  const _utilities = retrieveUtilities(utilities, {
    errorMapper: mapError,
  })

  const { onStart, onSuccess, onError, errorMapper } = _utilities

  onStart()

  const msisdn = ReduxCache.getMsisdn()


  const headers = {
    'vf-operator': 'bookappointment'
  }

  const { service, store, date } = data

  const selectedDate = moment(date.selectedDate).format('YYYY-MM-DD')
  const selectedTime = date.selectedTime?.split(' - ')
  const startTime = selectedTime?.[0]
  const endTime = selectedTime?.[1]

  const businessAccount = ReduxCache?.getBusinessAccount()

  const requestBody = {
    'category': service.data?.category?.[0]?.id,
    'validFor': {
      'startDateTime': `${selectedDate}T${startTime}:00.000Z`,
      'endDateTime': `${selectedDate}T${endTime}:00.000Z`,
    },
    'relatedParty': [{
      'id': msisdn,
      'role': isAdminView() ? 'organization' : 'individual',
      'name': isAdminView() ? businessAccount?.businessName : ''
    }],
    'place': {
      'role': 'service_store',
      'id': store.id,
      'href': '',
      'name': store?.id === 'custom' ? store?.address?.name : store?.name || ''
    },
    'note': [{
      'text': service?.description || ''
    }]
  }


  try {
    const response = await axios.post(API_URLS.appointments.appointment, requestBody, {
      headers: headers,
    })
    const _response = response?.data
    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)
    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}


export {
  getAppointments,
  getStoresOpenHours,
  getServices,
  getStoresData,
  postAppointment,
  getAvailableTimeSlots
}
