import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  backgroundContainer: insets => ({
    // top: -insets.top,
    bottom: 0,
    position: 'absolute',
    flex: 1,
  }),
  statusBarContainer: (insets, color) => ({
    width: '100%',
    height: 2 * insets.top,
    backgroundColor: color,
    position: 'absolute',
    zIndex: 2,
  }),
})
