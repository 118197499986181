import React from 'react'
import { View, Text, ViewPropTypes, Animated, ImageBackground, Dimensions } from 'react-native'
import { isNil as _isNil } from 'lodash'

import {
  Icon,
  VFText,
  IconTypes,
  PercentageIndicator
} from '@vfgroup-oneplatform/foundation/Components'
import PropTypes from 'prop-types'
import { withTheme } from '@vfgroup-oneplatform/foundation/Components/Themes/Provider'
import { LightThemeColors, Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { getThemeImages } from '../../Themes'

import styles from './UsageInfoCard.Styles'

function UsageInfoCard({
  icon,
  iconType,
  iconSize,
  footerRightIcon,
  footerRightIconType,
  footerRightIconSize,
  title,
  remaining,
  remainingUnit,
  remainingText,
  remainingTextPlaceholder,
  resetTextValue,
  footerText,
  value,
  animate,
  style,
  titleContainerStyle,
  titleStyle,
  infoContainerStyle,
  remainingStyle,
  remainingUnitStyle,
  remainingTextStyle,
  resetTextStyle,
  theme,
  type,
  offerName,
  fixImage,
  status,
  address,
  usageType,
  ...props
}) {

  const Images = getThemeImages(theme.name)

  const color = status === 'inactive' || status === 'terminated' || status === 'suspended' ? Colors.vfRed
    : status === 'active' ? Colors.true_green
      : status === 'created' ? Colors.fresh_orange : undefined


  return (
    <Animated.View style={styles.flex}>
      <ImageBackground
        source={Images.usage_tile_bg}
        resizeMode="cover"
        style={[styles.containerStyle(theme), style]}
      >
        <Icon
          type={iconType}
          name={icon}
          size={Dimensions.get('window').width > 700 ? 32 : iconSize}
        />
        <VFText
          i18nKey={title}
          style={[styles.titleTextStyle(theme), titleStyle]}
        />
        {type === 'fix' ?
          <>
            <View style={styles.fixTitleContainer}>
              <VFText
                i18nKey={offerName}
                style={{ ...styles.offerName(theme), fontSize: offerName.length < 25 ? 26 : 22 }}
              />
              <Icon
                type='image'
                name={fixImage}
                size={50}
              />
            </View>
            <View style={styles.fixBottomContainer}>
              <View style={styles.statusContainer}>
                <View style={styles.statusIcon(color)} />
                <VFText
                  i18nKey={`dashboard_card_${status}_status`}
                  style={styles.statusText(theme)}
                />
              </View>
              <VFText
                i18nKey={address.length < 38
                  ? `${address}`
                  : `${address.substring(0, 35)}...`}
                style={styles.addressText(theme)}
                numberOfLines={1}
              />
            </View>
          </>
          :
          <View>
            <View style={[styles.infoContainerStyle, infoContainerStyle]}>
              <VFText numberOfLines={1}>
                <VFText
                  i18nKey={remaining}
                  style={[styles.remainingTextStyle(theme), remainingStyle]}
                />
                {remainingUnit && (
                  <VFText
                    i18nKey={remainingUnit}
                    style={[
                      styles.remainingUnitTextStyle(theme),
                      remainingUnitStyle
                    ]}
                  />
                )}
                <View style={styles.spacingViewStyle} />
                <VFText
                  i18nKey={remainingText}
                  placeHolders={remainingTextPlaceholder}
                  style={[styles.usageLeftTextStyle(theme), remainingTextStyle]}
                />
              </VFText>
            </View>
            <PercentageIndicator
              value={value}
              animate={animate}
              fillColor={theme.colors.fillColor}
              color={theme.colors.secondryColor}
              {...props}
            />
          </View>
        }
        <View style={styles.footerContainer}>
          {!_isNil(resetTextValue) || !_isNil(footerText) ? (
            <VFText
              i18nKey={footerText}
              style={[styles.resetTextStyle(theme), resetTextStyle]}
              accessibilityLabel={resetTextAccessibilityLabel}
            />
          ) : null}

        </View>
      </ImageBackground>
    </Animated.View>
  )
}

UsageInfoCard.defaultProps = {
  iconType: IconTypes.image,
  footerRightIconType: IconTypes.image,
  iconSize: 25,
  footerRightIconSize: 25,
  theme: LightThemeColors
}

UsageInfoCard.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      uri: PropTypes.string.isRequired
    })
  ]).isRequired,
  iconType: PropTypes.oneOf(Object.values(IconTypes)).isRequired,
  iconSize: PropTypes.number,
  footerRightIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      uri: PropTypes.string.isRequired
    })
  ]),
  footerRightIconType: PropTypes.oneOf(Object.values(IconTypes)),
  footerRightIconSize: PropTypes.number,
  title: PropTypes.string.isRequired,
  remaining: PropTypes.string.isRequired,
  remainingUnit: PropTypes.string,
  remainingText: PropTypes.string.isRequired,
  footerText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  animate: PropTypes.bool.isRequired,
  style: ViewPropTypes.style,
  titleContainerStyle: ViewPropTypes.style,
  titleStyle: Text.propTypes.style,
  infoContainerStyle: ViewPropTypes.style,
  remainingStyle: Text.propTypes.style,
  remainingUnitStyle: Text.propTypes.style,
  remainingTextStyle: Text.propTypes.style,
  resetTextStyle: Text.propTypes.style,
  iconAccessibilityLabel: ViewPropTypes.accessibilityLabel,
  titleAccessibilityLabel: ViewPropTypes.accessibilityLabel,
  remainingAccessibilityLabel: ViewPropTypes.accessibilityLabel,
  remainingUnitAccessibilityLabel: ViewPropTypes.accessibilityLabel,
  remainingTextAccessibilityLabel: ViewPropTypes.accessibilityLabel,
  resetTextAccessibilityLabel: ViewPropTypes.accessibilityLabel,
  theme: PropTypes.shape({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  }),
  resetTextValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default withTheme(UsageInfoCard)
