import React, { useState, useEffect } from 'react'
import { Billing } from '@vfal-lab/rn-vfg-fix-billing/Screens'

import { Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'

// eslint-disable-next-line no-unused-vars
import { PaymentTypes } from '@vfgroup-oneplatform/framework/Payment/Utils/PaymentTypes.js'

import PropTypes from 'prop-types'
// import { withPageTracker } from '@vf-org/react-native-smapi'

import { Billing as BillingService } from '../../Services'

// eslint-disable-next-line no-unused-vars
import Payments from '../Payments/Payments'

import ReduxCache from '../../Utils/ReduxCache'


const BillingHocExample = ({ navigation, route }) => {
  const language = ReduxCache.getLanguage()
  const [isLoading, setIsLoading] = useState(false)

  const [currentBills, setCurrentBills] = useState([])
  const [previousBills, setPreviousBills] = useState([])
  const [historyChartData, setHistoryChartData] = useState([])
  const [billTypes, setBillTypes] = useState([])//indicators below chart (all bills)
  const [billsFilters, setBillsFilters] = useState([])//filters for previous bills
  // eslint-disable-next-line no-unused-vars
  const [showPayments, setShowPayments] = useState(false)

  const { navigate } = navigation

  const { reset } = route?.params || {}

  const getBills = () => {
    setIsLoading(true)
    BillingService.getAllBills({
      onSuccess: res => {
        setIsLoading(false)

        const currentMonth = res?.[0].billDateMonthNumber
        const currentBillsArray = res?.filter(el => el.billDateMonthNumber === currentMonth)
        const previousBillsArray = res?.filter(el => el.billDateMonthNumber !== currentMonth)

        setPreviousBills(previousBillsArray)
        setCurrentBills(currentBillsArray)
        setHistoryChartData(res.reverse())

        let billsTypesArray = []
        let billsFiltersArray = []
        const billTypesFormatted = []
        const billFiltersFormatted = []

        if (res.length > 0) {
          res.forEach(item => billsTypesArray.push(item?.billType))
        }
        if (previousBillsArray.length > 0) {
          previousBillsArray.forEach(item => billsFiltersArray.push(item?.billType))
        }

        if (billsTypesArray.length > 0) {
          billsTypesArray = [...new Set(billsTypesArray)]
          billsTypesArray.forEach(item => item && billTypesFormatted?.push({ id: `${item}`, name: 'billing_bills_type_' + item, color: item === 'fix' ? Colors.ocean : Colors.vfRed }))
        }
        setBillTypes(billTypesFormatted)
        if (billsFiltersArray.length > 0) {
          billsFiltersArray = [...new Set(billsFiltersArray)]
          billsFiltersArray.forEach(item => item && billFiltersFormatted?.push({ id: `${item}`, name: 'billing_bills_type_' + item }))
        }
        setBillsFilters(billFiltersFormatted)
      },
      onError: () => {
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    getBills()
  }, [])

  const navigateToMonthlyBillingScreen = billData => {
    navigate('MonthlyBilling', {
      bill: billData
    })
  }

  const onClose = () => {
    if (route?.params?.isDeepLink) {
      navigation.navigate('Dashboard')
    } else {
      navigation.pop()
    }
  }


  // Deep Linking
  React.useEffect(() => {
    if (route?.params?.id && previousBills) {
      const { params } = route
      let billId = params.id
      if (params?.section) {
        billId = billId + '/' + params?.section
      }
      let billObject = null
      if (billId === 'current') {
        billObject = previousBills[previousBills.length - 1]
      } else {
        billObject = previousBills.find(item => item.id === billId)
      }
      if (billObject) {
        navigateToMonthlyBillingScreen(billObject)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, historyChartData])


  return (
    <>
      <Billing
        withAutoBillStatus={false}
        billsDisplayedPerPage={30}
        internalPagination={false}
        withAutoPaymentCard={false}
        autoPaymentCardProps={{
          title: 'billing_auto_bill_banner_title_on',
          description: 'billing_auto_bill_banner_description_on',
          buttonTitle: 'billing_auto_bill_banner_edit_button',
          onPrimaryButtonPress: () => { setShowPayments(true) },
          autoBillPayDate: {
            day: '10',
            month: 'Oct'
          },
          isAutoBillActivated: true,
        }}
        withAutoPaymentBanner={false}
        billingBannerProps={{
          title: 'Auto bill payment',
          description: 'Stop worrying about your bill payment. Set up auto bill payment now.',
          primaryButtonTitle: 'Set up auto bill payment',
          onPrimaryButtonPress: () => { setShowPayments(true) }
        }}
        vfScreen={{
          title: 'billing_landing_title',
          onClose: onClose,
        }}
        chart={{
          data: historyChartData,
          isLoading: isLoading,
          onBarPress: (billObj) => { navigateToMonthlyBillingScreen(billObj) },
          isLastOutLined: true
        }}
        currentBillsCards={{
          isLoading: isLoading,
          data: isLoading ? [{}] : currentBills,
          onPress: (billObj) => navigateToMonthlyBillingScreen(billObj),
          withBadge: true,
          paymentInfo: currentBills?.length > 1 ? null : currentBills?.[0]?.paymentInfo,
        }}
        billsCards={{
          data: previousBills,
          onBillPress: (billObj) => navigateToMonthlyBillingScreen(billObj),
          isLoading: isLoading,
          filterData: billsFilters
        }}
        billTypes={{
          data: billTypes
        }}
      />
      {/* commented because in android with a specific number it's showing as half of the screen 0693169879 */}
      {/* <Payments type={PaymentTypes.SETUP_AUTO_BILL} setShowPayments={setShowPayments} isVisible={showPayments}/> */}
    </>
  )
}

BillingHocExample.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object
}

export default BillingHocExample
