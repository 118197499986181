import React, { Component } from 'react'
import { View } from 'react-native'
import { Text, Button } from 'native-base'
import { connect } from 'react-redux'

import QRCode from 'react-native-qrcode-svg'

import { VFText } from '@vfgroup-oneplatform/foundation/Components'
import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

import PopUp from '../../Components/PopUp'
import Loader from '../../Components/Loader'
import Error from '../../Components/Error'

import { __translate, __image } from '../../Utils'

import { actions } from './redeem.actions'


class Redeem extends Component {

  constructor(props) {
    super(props)

    this.state = {
      popUp: this.props.popUpModal,
      userType: this.props.userType
    }
  }

  componentDidMount() {
    if (this.state.userType !== 'non_vf') {
      this.props.getQrCode(this.props.popUpModal.item)
    }
  }

  closeRedeem(reload) {
    this.props.closePopUp(reload)
    this.props.resetRedeem()
  }

  _retryQr(item) {
    this.props.getQrCode(item)
  }

  handleRedeem(id) {
    let product = this.props.plainProducts[id]

    if (this.props.popUpModal.item.type !== 'telco') {
      product = this.props.popUp.data.product
    }

    this.props.redeemProduct(product)
  }

  render() {
    const { user, redeem, popUpModal, popUp } = this.props
    const popUpData = this.props.popUpModal

    const getQr = (popUp, code) => {
      if (popUp.loading) {
        return <Loader size={40} />
      }

      if (popUp.error) {
        return (
          <View style={{ width: 150, height: 150, alignSelf: 'center' }}>
            <Error message={popUp.error} retry={() => this._retryQr(popUpData.item)} />
          </View>
        )
      }

      return (
        <>
          <View style={{ width: 150, height: 150, alignSelf: 'center' }}>
            {code && <QRCode size={150} value={code} />}
          </View>
          <View
            style={{
              alignSelf: 'center',
              padding: 10,
              borderStyle: 'dashed',
              borderRadius: 1,
              borderWidth: 2,
              borderColor: '#979797',
              marginVertical: 16,
            }}
          >
            <Text style={{ textAlign: 'center', color: '#333', fontSize: 17 }} selectable={true} > {code} </Text>
          </View>
        </>
      )
    }

    if (!popUpModal.display) {
      return null
    }

    if (this.state.userType === 'non_vf') {
      return (
        <PopUp
          visibility={popUpModal.display}
          closePopUp={() => this.props.closePopUp(false)}
          showHeader={false}
        >
          <View style={{ paddingTop: 30, paddingBottom: 20 }}>
            <VFText
              i18nKey='reward_loyalty_non_vf_message'
              style={{ fontSize: 18, fontFamily: Fonts.type.VFFont }}
            />
          </View>

        </PopUp>
      )
    }

    if (user.data.coins && user.data.coins != '' && popUpData.item.price) {
      const difference = user.data.coins - popUpData.item.price

      if (difference < 0) {
        return (
          <PopUp visibility={popUpModal.display} image={{ uri: __image('reward_loyalty_not_enough_coins') }} closePopUp={() => this.props.closePopUp(false)}>
            <View>
              <Text style={{ textAlign: 'center', color: '#333', fontSize: 17, marginBottom: 10 }}> {__translate('reward_loyalty_not_enough_coins')} {Math.abs(difference)} {__translate('reward_loyalty_not_enough_coins_tx')} </Text>
            </View>
          </PopUp>
        )
      }
    }


    if (redeem.error) {
      return (
        <PopUp visibility={popUpModal.display} image={{ uri: __image('reward_loyalty_qrcode_page_icon') }} closePopUp={() => this.closeRedeem(false)}>
          <View>
            <Text style={{ textAlign: 'center', color: '#333', fontSize: 17, marginBottom: 10 }}> {redeem.error} </Text>
          </View>
        </PopUp>
      )
    }

    if (redeem.data && redeem.data.success) {
      let code = null

      if (popUp.data) {
        code = popUp.data.code
      }

      return (
        <PopUp visibility={popUpModal.display} image={{ uri: __image('reward_loyalty_telco_confirmation') }} closePopUp={() => this.closeRedeem(true)}>
          <View>
            {redeem.loading ?
              <Loader size={65} />
              :
              <>
                <Text style={{ textAlign: 'center', color: '#333', fontSize: 17, marginBottom: 10 }}> {__translate('reward_loyalty_coupon')} </Text>
                <Text style={{ textAlign: 'center', color: '#333', fontSize: 17 }}> {popUpData.item.name}, {__translate('reward_loyalty_coupon_validity')} {redeem.data.product.validityPeriod}</Text>
                {popUpModal.item.type == 'promotion' &&
                  <View style={{ marginTop: 25 }}>
                    {getQr(popUp, code)}
                  </View>
                }
              </>
            }
          </View>
        </PopUp>
      )
    }

    if (popUpModal.item.type == 'nontelco') {
      let code = null

      if (popUp.data) {
        code = popUp.data.code
      }

      return (
        <PopUp visibility={popUpModal.display} image={{ uri: __image('reward_loyalty_qrcode_page_icon') }} closePopUp={() => this.props.closePopUp(true)}>
          <View>
            <Text style={{ textAlign: 'left', color: '#333', fontSize: 17, marginBottom: 10 }}> <Text style={{ fontWeight: 'bold' }}>{popUpData.item.name}</Text>  {__translate('reward_loyalty_qrcode_location')} {popUpData.item.partner}</Text>
            {
              popUpData.item.currency &&
              <Text style={{ textAlign: 'left', color: '#333', fontSize: 17 }}>{popUpData.item.currency}: <Text style={{ fontWeight: 'bold' }}>{popUpData.item.price} </Text></Text>
            }

            <Text style={{ textAlign: 'left', color: '#333', fontSize: 17 }}>{__translate('reward_loyalty_telco_expiration')}: <Text style={{ fontWeight: 'bold' }}>{popUpData.item.validityPeriod}</Text></Text>
            <View style={{ marginTop: 25 }}>
              {getQr(popUp, code)}
            </View>
          </View>
        </PopUp>
      )
    }

    if (popUpModal.item.type == 'promotion') {
      let code = null

      if (popUp.data) {
        code = popUp.data.code
      }

      return (
        <PopUp visibility={popUpModal.display} image={{ uri: __image('reward_loyalty_qrcode_page_icon') }} closePopUp={() => this.props.closePopUp(true)}>
          {redeem.loading
            ?
            <Loader size={40} />
            :
            <View>
              <Text style={{ textAlign: 'left', color: '#333', fontSize: 17, marginBottom: 10 }}> <Text style={{ fontWeight: 'bold' }}>{popUpData.item.name}</Text>  {__translate('reward_loyalty_qrcode_location')} {popUpData.item.partner}</Text>
              {
                popUpData.item.currency &&
                <Text style={{ textAlign: 'left', color: '#333', fontSize: 17 }}>{popUpData.item.currency}: <Text style={{ fontWeight: 'bold' }}>{popUpData.item.price} </Text></Text>
              }

              <Text style={{ textAlign: 'left', color: '#333', fontSize: 17 }}>{__translate('reward_loyalty_telco_expiration')}: <Text style={{ fontWeight: 'bold' }}>{popUpData.item.validityPeriod}</Text></Text>

              {
                popUp.data && !popUp.loading && !popUp.data.isRedeem ?
                  <>
                    <Text style={{ textAlign: 'center', marginTop: 30, color: '#333', fontSize: 17 }}> {__translate('reward_loyalty_telco_confirmation')} </Text>

                    <View style={{ marginTop: 15 }}>
                      <Button onPress={() => this.handleRedeem(popUpModal.item.id)} style={{ backgroundColor: 'red', height: 35, justifyContent: 'center', alignSelf: 'center', marginTop: 5, marginBottom: 5 }}><Text> {__translate('reward_loyalty_telco_bt_yes')} </Text></Button>
                      <Button onPress={() => this.props.closePopUp(false)} style={{ backgroundColor: '#333', height: 35, justifyContent: 'center', alignSelf: 'center', marginTop: 5, marginBottom: 5 }}><Text> {__translate('reward_loyalty_telco_bt_cancel')} </Text></Button>
                    </View>
                  </>
                  :
                  <View style={{ marginTop: 25 }}>
                    {getQr(popUp, code)}
                  </View>
              }




            </View>
          }
        </PopUp>
      )
    }

    return (
      <PopUp visibility={popUpModal.display} image={{ uri: __image('reward_loyalty_qrcode_page_icon') }} closePopUp={() => this.props.closePopUp(false)}>
        {redeem.loading
          ?
          <Loader size={40} />
          :
          <View>
            <Text style={{ textAlign: 'center', color: '#333', fontSize: 18, marginBottom: 10 }}> {popUpData.item.name} </Text>
            {
              popUpData.item.currency &&
              <Text style={{ textAlign: 'left', color: '#333', fontSize: 17 }}>{popUpData.item.currency}: {popUpData.item.price}</Text>
            }
            <Text style={{ textAlign: 'left', color: '#333', fontSize: 17 }}>{__translate('reward_loyalty_telco_expiration')}: {popUpData.item.validityPeriod} </Text>
            <Text style={{ textAlign: 'center', marginTop: 30, color: '#333', fontSize: 17 }}> {__translate('reward_loyalty_telco_confirmation')} </Text>

            <View style={{ marginTop: 15 }}>
              <Button onPress={() => this.handleRedeem(popUpModal.item.id)} style={{ backgroundColor: 'red', height: 35, justifyContent: 'center', alignSelf: 'center', marginTop: 5, marginBottom: 5 }}><Text> {__translate('reward_loyalty_telco_bt_yes')} </Text></Button>
              <Button onPress={() => this.props.closePopUp(false)} style={{ backgroundColor: '#333', height: 35, justifyContent: 'center', alignSelf: 'center', marginTop: 5, marginBottom: 5 }}><Text> {__translate('reward_loyalty_telco_bt_cancel')} </Text></Button>
            </View>
          </View>
        }
      </PopUp>
    )
  }
}

const mapStateToProps = (state) => {
  const { user, popUp, plainProducts, redeem, preferences } = state
  return { user, popUp, plainProducts, redeem, preferences }
}

const mapDispatchToProps = dispatch => {
  return {
    getQrCode: (product) => {
      dispatch(actions.getQrCode(product))
    },
    redeemProduct: (product) => {
      dispatch(actions.redeemProduct(product))
    },
    resetRedeem: () => {
      dispatch(actions.resetRedeem())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Redeem)