import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { View, TouchableOpacity, ScrollView, Platform, Dimensions } from 'react-native'

import { VFText, ErrorScreen, Icon, Card } from '@vfgroup-oneplatform/foundation/Components'
import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { getThemeImages } from '../../../../Group/Themes'

import { SearchInputContact } from '../../../../Group/Components/SearchInput'

import UsersLoading from './UsersLoading'

import styles from './Users.Styles'

const NO_OF_PAGES = 10

const Users = ({
  users,
  isLoading,
  error,
  onUserPress,
  tryAgain,
  scrollRef,
}) => {

  const theme = useTheme()

  const inputRef = useRef()
  const [searchedText, setSearchedText] = useState('')

  const [adminUsers, setAdminUsers] = useState([])
  const [standardUsers, setStandardUsers] = useState([])

  const [filteredUsersArray, setFilteredUsersArray] = useState([])

  const themedImages = getThemeImages(theme.name)

  const [page, setPage] = useState(0)

  useEffect(() => {
    if (!isLoading) {
      setAdminUsers(prev => users?.filter(user => user.isAdmin) || [])
      setStandardUsers(prev => users?.filter(user => !user.isAdmin) || [])
      setFilteredUsersArray([...users?.filter(user => user.isAdmin), ...users?.filter(user => !user.isAdmin)])
    }
  }, [isLoading, users])


  useEffect(() => {
    if (!searchedText) {
      setFilteredUsersArray([...adminUsers, ...standardUsers])
    } else {
      onSearch()
    }
  }, [searchedText, adminUsers, standardUsers])


  const onSearch = () => {
    setFilteredUsersArray([...adminUsers, ...standardUsers].filter(el => el?.name?.includes(searchedText)))
  }


  const renderLoading = () => {
    return <UsersLoading />
  }

  const renderError = () => {
    return <ErrorScreen
      theme={theme}
      text={error?.message}
      onPress={tryAgain}
    />
  }

  const renderCard = ({ id, icon, name }) => {
    return (
      <Card
        type="main"
        style={[styles.cardStyle, styles.row]}
        key={id}
        onPressCard={() => onUserPress({ id, name })}
      >
        {icon && <Icon name={themedImages[icon]} size={30} resizeMode="contain" />}
        <VFText i18nKey={name} style={styles.text} />
        <Icon name={themedImages.ic_chevron_right_red} size={30} resizeMode="contain" style={styles.rightIcon} />
      </Card>
    )
  }


  const renderPages = () => {
    if (filteredUsersArray.length <= NO_OF_PAGES) {
      return null
    }
    return (
      <View style={styles.pageContainer}>
        {page !== 0 && <TouchableOpacity
          onPress={() => {
            setPage(prev => prev - 1)
            scrollRef?.current?._listRef?._scrollRef?.scrollTo({ x: 0, y: 0, animated: true })
          }}
          style={styles.pageButton}
        >
          <Icon name={themedImages.ic_chevron_right_red} style={{ transform: [{ rotate: '180deg' }] }} size={22} />
          <VFText i18nKey="soho_usage_users_prev" style={styles.pageText(theme)} />
        </TouchableOpacity>}
        {
          (page + 1) * NO_OF_PAGES < filteredUsersArray.length && <TouchableOpacity
            onPress={() => {
              setPage(prev => prev + 1)
              scrollRef?.current?._listRef?._scrollRef?.scrollTo({ x: 0, y: 0, animated: true })
            }}
            style={styles.pageButton}
          >
            <VFText i18nKey="soho_usage_users_next" style={styles.pageText(theme)} />
            <Icon name={themedImages.ic_chevron_right_red} size={22} />
          </TouchableOpacity >
        }
      </View >
    )
  }

  const renderUsers = () => {

    const data = filteredUsersArray.slice(page * NO_OF_PAGES, (page + 1) * NO_OF_PAGES)

    const standardUserIndex = data.findIndex(el => !el?.isAdmin)

    if (data.length === 0) {
      return <VFText
        i18nKey={'soho_my_users_and_groups_empty_search'}
        style={styles.emptyTitle(theme)}
        placeHolders={[searchedText]}
      />
    }

    return (
      <div style={{ height: '50vh', overflow: 'scroll' }}>
        {data[0]?.isAdmin && <VFText
          i18nKey="soho_my_users_and_groups_users_administrators"
          style={styles.userTypeText(theme)}
        />}
        {data.map((item, index) => {
          return <>
            {(index === standardUserIndex) && <VFText
              i18nKey="soho_my_users_and_groups_users_standard"
              style={styles.userTypeText(theme)}
            />}
            {renderCard({ ...item })}
          </>
        })}
        {renderPages()}
      </div>
    )
  }


  const renderContent = () => {
    return (
      <View style={{ paddingHorizontal: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? '10%' : 0 }}>
        {users.length === 0 ?
          <>
            <VFText
              i18nKey={'soho_my_users_and_groups_empty_users_title_text'}
              style={styles.emptyTitle(theme)}
            />
            <VFText
              i18nKey={'soho_my_users_and_groups_empty_users_description_text'}
              style={styles.emptySubtitle(theme)}
            />
          </> :
          <ScrollView contentContainerStyle={styles.scrollContainer} showsVerticalScrollIndicator={false} >
            <VFText
              i18nKey={'soho_my_users_and_groups_users_title'}
              style={styles.title}
            />
            <VFText
              i18nKey={'soho_my_users_and_groups_users_subtitle'}
              style={styles.subtitle(theme)}
            />

            <SearchInputContact
              inputRef={inputRef}
              value={searchedText}
              onChangeText={setSearchedText}
              label="soho_my_users_and_groups_users_search_placeholder"
              onSelectContact={setSearchedText}
            />
            {renderUsers()}
          </ScrollView>}
      </View>
    )
  }

  return (
    <>
      {isLoading ? renderLoading() : (error ? renderError() : renderContent())}
    </>
  )
}
Users.defaultProps = {
  onUserPress: () => { },
  tryAgain: () => { }
}
Users.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      isAdmin: PropTypes.bool,
      groups: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })),
      roles: PropTypes.arrayOf(PropTypes.string),
      icon: PropTypes.string
    })
  ),
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  onUserPress: PropTypes.func,
  tryAgain: PropTypes.func,
  scrollRef: PropTypes.object,
}

export default Users
