/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import DataSharingScreen from '@vfal-lab/rn-vfg-data-sharing/Screens'

import { Dimensions, Platform } from 'react-native'
import { VFScreen } from '@vfgroup-oneplatform/foundation/Components'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { validatePhoneNumber, formatPhoneNumber } from '../../Utils/Regex'

import {
  getTransferOptionsBalance,
  getTransferOptionsData,
  getTransferOptionsVcoins,
  transferBalance,
  transferData,
  transferVcoins,
} from './DataSharing.Actions'

import styles from './DataSharing.Styles'



function DataSharing({ isVisible, toggleModal, navigation, route = {} }) {
  const theme = useTheme()

  const { params = 'internet' } = route

  const [preselectedOption, setPreselectedOption] = useState(params?.isBalance ? 'balance' : 'internet')

  const dispatch = useDispatch()

  const getBalanceOptions = async (utilities) => dispatch(getTransferOptionsBalance(utilities))

  const getDataOptions = async (utilities) => dispatch(getTransferOptionsData(utilities))

  const getVcoinsOptions = async (utilities) => dispatch(getTransferOptionsVcoins(utilities))

  const shareBalance = async (transferDetails, utilities) => dispatch(transferBalance(transferDetails, utilities))

  const shareData = async (transferDetails, utilities) => dispatch(transferData(transferDetails, utilities))

  const shareVcoins = async (transferDetails, utilities) => dispatch(transferVcoins(transferDetails, utilities))

  /** Content manager initialization */
  const [isContentLoading, setContentLoading] = useState(true)
  useLayoutEffect(() => {
    try {
      getDataOptions({
        onSuccess: (res) => {
          if (res?.items?.length <= 0) {
            setPreselectedOption('balance')
          }
        },
        onError: () => { },
      })
      getVcoinsOptions({
        onSuccess: () => setContentLoading(false),
        onError: () => { },
      })
      getBalanceOptions({
        onSuccess: (res) => {
          if (res?.items?.length <= 0) {
            setPreselectedOption('vcoins')
          }

        },
        onError: () => { },
      })
      setContentLoading(false)
    } catch (error) {
    }
  }, [])


  const dataOptions = useSelector(state => state.dataOptions.data || [])
  const balanceOptions = useSelector(state => state.balanceOptions.data || [])
  const vcoinsOptions = useSelector(state => state.vcoinsOptions.data || [])

  const dataOptionsLoading = useSelector(state => state.dataOptions.loading || false)
  const balanceOptionsLoading = useSelector(state => state.balanceOptions.loading || false)
  const vcoinsOptionsLoading = useSelector(state => state.vcoinsOptions.loading || false)

  const loading = isContentLoading || dataOptionsLoading || balanceOptionsLoading || vcoinsOptionsLoading

  // IF user is in web and desltop env than show view
  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {

    return (
      <VFScreen
        headerStyle={styles.headerStyle(theme)}
        title={'Vodafone Share'}
        isFullHeight
        onClose={() => { navigation.pop(1) }}
      >
        <DataSharingScreen
          loading={loading}
          isVisible={isVisible}
          toggleModal={toggleModal}
          transferBalance={shareBalance}
          transferData={shareData}
          transferVcoins={shareVcoins}
          dataOptions={dataOptions}
          balanceOptions={balanceOptions}
          vcoinsOptions={vcoinsOptions}
          preselectedOption={preselectedOption}
          entryData={loading ? null : {
            internet: dataOptions?.items || [],
            vcoins: vcoinsOptions?.items || [],
            balance: balanceOptions?.items || [],
            minutes: [],
          }}
          monthlyLimit={{
            internet: dataOptions?.monthlyAndDailyLimits,
            vcoins: vcoinsOptions?.monthlyAndDailyLimits,
            balance: balanceOptions?.monthlyAndDailyLimits,
            minutes: [],
          }}
          confirmationText={{
            internet: dataOptions?.description,
            vcoins: vcoinsOptions?.description,
            balance: balanceOptions?.description,
            minutes: '',
          }}
          validatePhoneNumber={validatePhoneNumber}
          formatPhoneNumber={formatPhoneNumber}
        />
      </VFScreen>
    )
  }

  return (
    <DataSharingScreen
      loading={loading}
      isVisible={isVisible}
      toggleModal={toggleModal}
      transferBalance={shareBalance}
      transferData={shareData}
      transferVcoins={shareVcoins}
      dataOptions={dataOptions}
      balanceOptions={balanceOptions}
      vcoinsOptions={vcoinsOptions}
      preselectedOption={preselectedOption}
      entryData={loading ? null : {
        internet: dataOptions?.items || [],
        vcoins: vcoinsOptions?.items || [],
        balance: balanceOptions?.items || [],
        minutes: [],
      }}
      monthlyLimit={{
        internet: dataOptions?.monthlyAndDailyLimits,
        vcoins: vcoinsOptions?.monthlyAndDailyLimits,
        balance: balanceOptions?.monthlyAndDailyLimits,
        minutes: [],
      }}
      confirmationText={{
        internet: dataOptions?.description,
        vcoins: vcoinsOptions?.description,
        balance: balanceOptions?.description,
        minutes: '',
      }}
      validatePhoneNumber={validatePhoneNumber}
      formatPhoneNumber={formatPhoneNumber}
    />
  )
}
DataSharing.propTypes = {
  isVisible: PropTypes.bool,
  toggleModal: PropTypes.func,
  navigation: PropTypes.object,
}

export default DataSharing
