import axios from 'axios'
import { isFunction as _isFunction } from 'lodash'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import ReduxCache from '../../Utils/ReduxCache'

import { API_URLS } from '../Config'

const GetLP = async (version, customHeaders = {}, utilities = {}) => {
  const _utilities = retrieveUtilities(utilities)

  const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

  onStart()

  const params = {
    version: version,
    msisdn: ReduxCache.getMsisdn() || '',
    hash: '',
  }

  try {
    const response = await axios.get(API_URLS.LP, {
      params: params,
      headers: customHeaders
    })

    const _response = responseMapper(response)

    onSuccess(_response)
    return _response
  } catch (error) {
    const _error = errorMapper(error)

    if (_isFunction(onError)) {
      onError(_error)
    } else {
      throw _error
    }
  }
}

export { GetLP }
