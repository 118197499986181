import { StyleSheet } from 'react-native'
import { Colors, Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

const styles = StyleSheet.create({
  headerTitle: {
    color: Colors.white
  },
  container: {
    flex: 1,
    overflow: 'scroll',
  },
  referContainer: (theme) => ({
    backgroundColor: theme.colors.backgroundColor,
    paddingHorizontal: 16,
    paddingVertical: 20,
    alignItems: 'center',
  }),
  subtitle: {
    ...Fonts.type.bold,
    textAlign: 'center',
    fontSize: 22,
    marginHorizontal: 20,
  },
  description: {
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Fonts.size.h6,
    marginTop: 25,
    textAlign: 'center',
    lineHeight: 22,
  },
  codeContainer: (theme) => ({
    borderColor: theme.colors.borderColor,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderRadius: 6,
    padding: 15,
    marginTop: 20,
    width: '70%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.backgroundColorThree
  }),
  codeText: {
    ...Fonts.type.bold,
    fontSize: Fonts.size.h6,
  },
  shareButton: {
    width: '100%',
    marginVertical: 20,
  },
  readTermsText: {
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Fonts.size.regular,
    textDecorationLine: 'underline',
    marginBottom: 5,
  },
  acceptTermsContainer: {
    paddingVertical: 25,
    borderBottomColor: Colors.lightGrey,
    borderBottomWidth: 1,
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  checkboxText: {
    marginLeft: 10,
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Fonts.size.regular,
  },
  termsInfoText: {
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Fonts.size.regularSmall,
    textAlign: 'center',
  },
  modalContainer: {
    flex: 1,
    paddingTop: 30,
  },
  termsButton: {
    marginTop: 0,
    marginBottom: 10,
  },
  referalsContainer: {
    paddingHorizontal: 16,
    paddingTop: 20,
    marginBottom: 20,
  },
  referalsHeader: theme => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    backgroundColor: theme.colors.white
  }),
  successfulReferalsText: {
    ...Fonts.type.bold,
    fontSize: Fonts.size.h5,
  },
  numberOfReferalsText: {
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Fonts.size.regular,
  },
  referalTopContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  referalItemContainer: (showBottomBorder, theme) => ({
    borderBottomColor: theme.colors.borderColor,
    borderBottomWidth: showBottomBorder ? 1 : 0,
    paddingVertical: 15,
  }),
  referalItemNumber: theme => ({
    fontFamily: Fonts.type.VFFont,
    fontSize: Fonts.size.regular,
    color: theme.colors.textColor
  }),
  referalItemGift: theme => ({
    fontFamily: Fonts.type.VFFont,
    fontSize: Fonts.size.regular,
    color: theme.colors.ocean,
  }),
  referalItemJoinedDate: theme => ({
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Fonts.size.regularSmall,
    color: theme.colors.textColor,
    marginTop: 5,
  }),
  noReferalText: {
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Fonts.size.h6,
  },
  cardContainer: theme => ({
    paddingVertical: 0,
    marginTop: 10,
    marginHorizontal: 0,
    backgroundColor: theme.colors.cardBackgroudColor,
  }),
  errorContainer: {
    paddingVertical: 30,
    paddingHorizontal: 10,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: theme => ({
    fontFamily: Fonts.type.VFFont,
    fontSize: Fonts.size.h6,
    color: theme.colors.textColorTwo,
    marginVertical: 20,
    textAlign: 'center',
  }),
  tryAgainContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tryAgainText: {
    fontFamily: Fonts.type.VFFont,
    fontSize: Fonts.size.h6,
    color: Colors.vfRed,
    marginRight: 5,
  },
  modalTitleStyle: {
    marginLeft: -16,
  },
  modalCloseStyle: {
    marginRight: -16,
  },
})

export const successfullReferalStyles = StyleSheet.create({
  container: theme => ({
    backgroundColor: theme.colors.white,
    flex: 1,
    paddingHorizontal: 16,
    justifyContent: 'space-between',
    paddingVertical: 50,
  }),
  title: {
    ...Fonts.type.bold,
    textAlign: 'center',
    fontSize: Fonts.size.h4,
    marginHorizontal: 30,
    marginTop: 50,
  },
  topContainer: {
    alignItems: 'center',
    marginTop: 40,
  },
  subtitle: {
    fontFamily: Fonts.type.VodafoneLt,
    fontSize: Fonts.size.h6,
    marginTop: 25,
    textAlign: 'center',
  },
  button: {
    marginVertical: 10,
  },
  headerStyle: {
    borderBottomWidth: 0,
  },
})

export default styles
