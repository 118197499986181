import React, { Component } from 'react'
import { Dimensions, ImagePropTypes, Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  find as _find,
  get as _get,
  isFunction as _isFunction
} from 'lodash'
import PropTypes from 'prop-types'

import { User_Types } from '@vfgroup-oneplatform/foundation/Utils/UserTypes.Enum'
// import { Usage } from '@vfgroup-oneplatform/netperform'

import { TABS_LABELS } from '@vfgroup-oneplatform/framework/ProductsAndServices/Utils/Enums'

import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'
import { withTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { AddOnsDurationEnum } from '@vfgroup-oneplatform/framework/AddOns/Containers/Timeline/helpers'

import ProductAndServicesStyles from '@vfgroup-oneplatform/framework/ProductsAndServices/ProductsAndServices.Styles'

import { VFButton } from '@vfgroup-oneplatform/foundation/Components'

import { GetPEGA, ProductsAndServices as ProductsAndServicesService } from '../../Services'


import ReduxCache from '../../Utils/ReduxCache'

import Payments from '../Payments'
import GroupImages from '../../Group/Themes/Images'
import Refill from '../Refill'

import { isFixUser, formatNumber, isAdminView } from '../../Utils'

import ProductsAndServices from './ProductsAndServicesNew'

import { Images } from '../../Themes'

import styles from './ProductsAndServices.Styles'



import Hyperlink from './Hyperlink'


const addOns = [
  {
    id: 1,
    name: '10GB Roaming',
    price: 12.11,
    icon: 'roaming',
    duration: 'week',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
    unit: '€',
    title: 'Keep in touch with your family even more easily'
  },
  {
    id: 2,
    name: '200GB Roaming',
    price: 12.11,
    icon: 'roaming',
    duration: 'month',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
    unit: '€',
    title: 'Keep in touch with your family even more easily'
  }
]


// const balanceData = [
//     {
//       id: 1,
//       name: 'International calls',
//       items: [
//         {
//           id: 10,
//           icon: 'ic_outgoing_call',
//           title: '+33 (0) 580979275',
//           date: moment().set({ hour: 13, minute: 0 }),
//           duration: '12m 34s',
//           price: '2.83€',
//           type: 'International calls'
//         },
//         {
//           id: 11,
//           icon: 'ic_outgoing_call',
//           title: '+39 (0) 430456666',
//           date: moment().subtract(1, 'days').set({ hour: 16, minute: 8 }),
//           duration: '1 hr 12min',
//           price: '5.30€',
//           type: 'International calls'
//         }
//       ]
//     },
//     {
//       id: 2,
//       name: 'Local calls',
//       items: [
//         {
//           id: 12,
//           icon: 'ic_outgoing_call',
//           title: '+44 (0) 780998927',
//           date: moment().set({ hour: 13, minute: 30 }),
//           duration: '12m 34s',
//           price: '2.83€',
//           type: 'Local calls'
//         },
//         {
//           id: 13,
//           icon: 'ic_outgoing_call',
//           title: '+44 (0) 780998927',
//           date: moment().subtract(1, 'days').set({ hour: 13, minute: 30 }),
//           duration: '1 hr 40min',
//           price: '2.83€',
//           type: 'Local calls'
//         },
//         {
//           id: 14,
//           icon: 'ic_outgoing_call',
//           title: '+44 (0) 780998927',
//           date: moment().set({ hour: 13, minute: 30, date: 1 }),
//           duration: '12m 34s',
//           price: '1.00€',
//           type: 'Local calls'
//         }
//       ]
//     }
// ]

// const myPlanData = {
//     'status': 'Active',
//     'date':'my_plan_primary_card_renewal_date',
//     'datePlaceholders':['28th Feb'],
//     'title': 'Vodafone Special 50GB',
//     'price': '16,00',
//     'priceUnit': '€',
//     'usageData': [
//       {
//         'usageItemLabel': 'my_plan_primary_card_data',
//         'usageValuesText': 'my_plan_primary_card_consumption',
//         'usageValuesPlaceholders': ['15.2GB', '30GB'],
//         'isUnlimitedUsage': false,
//         'icon': 'my_plan_dataSharing',
//         'shouldShowAddUsageButton': true,
//         'usagePercentageValue': 43,
//         'addUsageButtonTitle': 'Add Data'
//       },
//       {
//         'usageItemLabel': 'my_plan_primary_card_local_calls',
//         'isUnlimitedUsage': true,
//         'icon': 'my_plan_calls',
//         'shouldShowAddUsageButton': false
//       },
//       {
//         'usageItemLabel': 'my_plan_primary_card_sms',
//         'usageValuesText': 'my_plan_primary_card_consumption',
//         'usageValuesPlaceholders': ['934', '1000'],
//         'isUnlimitedUsage': false,
//         'icon': 'my_plan_smsText',
//         'shouldShowAddUsageButton': true,
//         'usagePercentageValue': 93,
//         'addUsageButtonTitle': 'Add SMS'
//       }
//     ],
//     'contractData': [
//       {
//         'key': 'Renewal date',
//         'value': '28/11/2019'
//       },
//       {
//         'key': 'Contract ends on',
//         'value': '28/05/2020'
//       },
//       {
//         'key': 'Minimun term ends on',
//         'value': '28/12/2019'
//       },
//       {
//         'key': 'Eligible to upgrade from',
//         'value': '28/01/2020'
//       },
//       {
//         'key': 'SIM card no',
//         'value': '09821390841'
//       },
//       {
//         'key': 'PUK code',
//         'value': 'NF9023NX92021'
//       }
//     ]
// }

class ProductsAndServicesContainer extends Component {
  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
    this.usageRef = React.createRef()
    this.categories = [
      {
        category: 'netperform_usage_social_networks',
        appsPackageName: [
          'com.twitter.android',
          'com.snapchat.android',
          'com.whatsapp',
          'com.instagram.android',
          'com.facebook.katana'
        ]
      },
      {
        category: 'netperform_usage_streaming',
        appsPackageName: [
          'com.google.android.youtube',
          'com.netflix.mediaclient',
          'com.spotify.music',
          'com.anghami'
        ]
      }
    ]

    this.state = {
      forceOpenModal: false,
      isShowBannerEnabled: true,
      withPageBanner: false,
      isInlineContentEnabled: false,
      activeTab: { index: 0, key: '' },
      isNoBalanceHistory: false,
      isBalanceFailed: false,
      isBalanceLoading: true,
      isTopUpModalVisible: false,
      lastUpdateTime: null,
      isTimeLineRefreshed: true,
      isPageBannerVisible: true,
      isAutoTopUPBannerVisible: true,
      withAutoTopUpBanner: false,
      isAlbanianUser: false,
      isAutoTopUpModalVisible: false,
      isAutoTopUpSetUp: false,
      planData: [],
      balanceTopics: [],
      addons: [],
      isMyPlanLoading: true,
      isMyPlanError: false,
      customerBill: {},
      showPayments: false,
      showRefillButton: false,
      pegaButtons: false,
      pageBannerData: false,
      upgradePlanCTA: false,
      showRefill: false,
    }

    this.msisdn = ReduxCache.getMsisdn()
    this.userType = ReduxCache.getUserType()

    this.inlineContentData = _get(
      this.props,
      'productsAndServices.inlineContentData',
      {}
    )
    this.getInlineContentDataImages()

    this.setIsInLineContentEnabled()
  }

  componentDidMount() {
    const {
      topUp
    } = this.props

    this.setState({ isAutoTopUpSetUp: topUp?.isAutoTopUpSetUp, isAlbanianUser: true })

    ContentManager.configureI18nTranslations({ en: { my_plan_primary_card_consumption: '%1$s / %2$s' }, al: { my_plan_primary_card_consumption: '%1$s / %2$s' } })

    this.getMyPlanData()
    this.getPegaData()

    this.refreshBalanceTab()
    this.handleDeepLinking()
  }

  // onPrimaryButtonPress for Banner
  onPrimaryButtonPress = () => {
    this.setState({
      forceOpenModal: true
    })
  }

  // onSecondaryButtonPress for Banner
  onSecondaryButtonPress = hideBanner => {
    hideBanner()
  }

  onUpgradePress = () => {
    const { navigation } = this.props

    navigation.navigate('Purchase')
  }

  // set forceOpenModal model with isVisible from myPlan state
  onModalVisibilityChange = isVisible => {
    this.setState({
      forceOpenModal: isVisible
    })
  }

  onActiveTabChange = async (index, activeTab) => {
    this.setState({
      activeTab: { index, key: activeTab.key }
    })
  }

  onRefresh = () => {
    return this.refreshActiveTabData()
  }

  onBalanceTryAgainPress = () => {
    this.refreshBalanceTab()
  }

  getMyPlanState() {
    const planData = this.state.planData
    let found = false

    if (!this.state.showRefillButton) {
      return this.state.planData
    }

    const newData = planData.map((item, index) => {
      if (index == 0) {
        item.withUpgradePlanCTA = this.state.upgradePlanCTA ? true : false
      }
      if (found) {
        return item
      }
      const newUsage = item.usageData.map(usage => {
        if ((usage.key == 'data' || usage.key == 'internet') && !found) {
          found = true
          usage.shouldShowAddUsageButton = true
          usage.addUsageButtonTitle = this.state.showRefillButton.cta
          usage.onPress = () => this.setState({ showRefill: true })
        }

        return usage
      })

      item.usageData = newUsage
      return item

    })

    return newData
  }

  async getPegaData() {
    const response = await GetPEGA('myplan')

    if (response.categories && response.categories.includes('DM')) {
      this.setState({ refillButton: true })
    }

    const buttons = {}

    for (const key in response.characteristics) {
      const data = response.characteristics[key]
      const details = response.characteristics[key].characteristics

      if (details.Section?.includes('Top') && details.CTA?.length > 0 && details.CTAUrl?.length > 0) {
        // show red card
        this.setState({ withPageBanner: true })
      }

      if (details.Section?.includes('Upgrade')) {
        // show upgrade button at the first element
        this.setState({
          upgradePlanCTA: {
            cta: details.CTA[0],
            url: details.CTAUrl[0],
          }
        })
      }

      if (details.CTA && details.CTAUrl) {
        buttons[details.CTAUrl[0]] = {
          cta: details.CTA[0],
          url: details.CTAUrl[0],
        }

        if (details.CTAUrl[0] == 'refill') {
          this.setState({
            showRefillButton: {
              cta: details.CTA[0],
              url: details.CTAUrl[0],
            }
          })
        } else {
          this.setState({
            pageBannerData: {
              title: data.name,
              description: details.longDesc[0] != 'NO_TEXT' ? details.longDesc[0] : '',
              primaryButtonTitle: details.CTA[0],
              onPrimaryButtonPress: () => this.manageCTACardPress(details.CTAUrl[0]),
            }
          })
        }
      }
    }
  }

  async getMyPlanData() {
    this.setState({ isMyPlanLoading: true })

    try {
      const data = await ProductsAndServicesService.getMyPlanData()

      const planData = []
      const addonsData = []
      data.data.map(item => {
        if (item.characteristics?.productType === 'addon') {
          addonsData.push({
            id: 1,
            name: item.title,
            title: item.title,
            expirationDate: item.datePlaceholders[0],
            price: item.priceObj.value + item.priceObj.unit,
            icon: 'roaming',
            duration: '',
            description: item.characteristics?.longDesc,
            unit: '',
            addOnId: item.id,
            addonPrice: item.priceObj.value,
            addonUnit: item.priceObj.unit
          })
        } else {
          planData.push(item)
        }
      })

      this.setState({ planData: planData, addons: addonsData, isMyPlanLoading: false, customerBill: data.customerBill, isMyPlanError: false })
    } catch (error) {

      // const myPlanData = [{
      //       'status': 'Active',
      //       'date':'my_plan_primary_card_renewal_date',
      //       'datePlaceholders':['28th Feb'],
      //       'title': 'Vodafone Special 50GB',
      //       'price': '16,00',
      //       'priceUnit': '€',
      //       'usageData': [
      //         {
      //           'usageItemLabel': 'my_plan_primary_card_data',
      //           'usageValuesText': 'my_plan_primary_card_consumption',
      //           'usageValuesPlaceholders': ['15.2GB', '30GB'],
      //           'isUnlimitedUsage': false,
      //           'icon': 'my_plan_dataSharing',
      //           'usagePercentageValue': 43,
      //           'key': 'data'
      //         },
      //         {
      //           'usageItemLabel': 'my_plan_primary_card_local_calls',
      //           'isUnlimitedUsage': true,
      //           'icon': 'my_plan_calls',
      //           'shouldShowAddUsageButton': false
      //         },
      //         {
      //           'usageItemLabel': 'my_plan_primary_card_sms',
      //           'usageValuesText': 'my_plan_primary_card_consumption',
      //           'usageValuesPlaceholders': ['934', '1000'],
      //           'isUnlimitedUsage': false,
      //           'icon': 'my_plan_smsText',
      //           'shouldShowAddUsageButton': true,
      //           'usagePercentageValue': 93,
      //           'addUsageButtonTitle': 'Add SMS'
      //         }
      //       ],
      // }]

      // this.setState({planData: myPlanData, isMyPlanLoading: false})

      this.setState({ isMyPlanLoading: false, isMyPlanError: true })
    }
  }

  getParsedAsyncStorageValue = async key => {
    const asyncStorageValue = await AsyncStorage.getItem(key)
    return JSON.parse(asyncStorageValue)
  }

  getInlineContentDataImages = () => {
    this.addOnsImages = Images
    let { leftIcon, rightIcon } = this.inlineContentData
    leftIcon = this.addOnsImages[leftIcon]
    rightIcon = this.addOnsImages[rightIcon]

    this.inlineContentData = {
      ...this.inlineContentData,
      leftIcon,
      rightIcon
    }
  }

  manageUpgradeCTAPress = () => {
    this.manageCTACardPress(this.state.upgradePlanCTA.url)
  }

  manageCTACardPress(url) {
    const { navigation } = this.props

    switch (url) {
      case 'p2h':
        navigation.navigate('Migration', { type: 'p2h' })
        break
      case 'renewal':
        navigation.navigate('Migration', { type: 'renewal' })
        break
      case 'details':
      case 'myoffers':
        navigation.navigate('Catalogue')
        break
      case 'shake':
        navigation.navigate('Shake')
        break
      case 'flowerbox':
        navigation.navigate('VfHearts')
        break
      case 'ebill':
        navigation.navigate('Billing')
        break
      case 'gigafamily':
        navigation.navigate('GigaFamily')
        break
    }
  }

  refreshMyPlanTab = async () => {
    this.getMyPlanData()
  }
  refreshActiveTabData = async () => {
    const { getDeviceData, getMyAddonsData } = this.props
    const { activeTab } = this.state

    switch (activeTab.key) {
      case TABS_LABELS.MY_PLAN:
        return this.refreshMyPlanTab()
      case TABS_LABELS.BALANCE:
        return this.refreshBalanceTab()
      case TABS_LABELS.DEVICES:
        return getDeviceData()
      case TABS_LABELS.ADDONS:
        return getMyAddonsData()
      default:
        break
    }
  }

  setIsInLineContentEnabled = async () => {
    const isInlineContentEnabledParsed = await this.getParsedAsyncStorageValue(
      'isInlineContentEnabled'
    )
    this.setState({
      isInlineContentEnabled: isInlineContentEnabledParsed
    })
  }

  refreshBalanceTab = async () => {
    this.setState({ isBalanceLoading: true })

    const data = await ProductsAndServicesService.getTopics()

    this.setState({ isBalanceLoading: false, isTimeLineRefreshed: true, balanceTopics: data, lastUpdateTime: new Date() })
  }

  setIsPageBannerVisible = value => {
    this.setState({ isPageBannerVisible: value })
  }

  setIsAutoTopUpBannerVisible = value => {
    this.setState({ isAutoTopUPBannerVisible: value })
  }

  handlePressAddConfirmation = async (
    paymentTypeOptionSelected,
    paymentAmountOptionSelected
  ) => {

  }

  handleCloseButtonPress = () => {
    if (this.props.route?.params?.isDeepLink) {
      this.props.navigation.navigate('Dashboard')
    } else {
      this.props.navigation.pop()
    }
  }

  handleOnScroll = scrollY => {
    const handelScroll = _get(this.usageRef, 'current.handelScroll')
    if (_isFunction(handelScroll)) {
      handelScroll(scrollY)
    }
  }

  handleNavigateToAddonsShop = () => {
    this.props.navigation.navigate('Catalogue', { bundleCategory: 'add_on' })
  }

  handleUsagePress = (data) => {
    if (data.onPress) {
      data.onPress()
    } else {
      this.handleNavigateToAddonsShop()
    }
  }

  handleNavigateToCatalogue = (item) => {
    if (item.onPress) {
      item.onPress()
    } else {
      this.props.navigation.navigate('Catalogue')
    }

  }

  handlePressAddOnItem = item => {
    this.props.navigation.navigate('RemoveAddOn', { ...item })
  }

  handleInlineContentPress = addOnData => {
    this.props.navigation.navigate('BuyAddOn', {
      ...addOnData,
      isOpenedThrowInLineContent: true
    })
  }

  foundDailyAddon = _find(
    addOns,
    element => {
      return element.duration == AddOnsDurationEnum.DAY
    }
  )

  toggleModal = () => {
    this.setState({
      isTopUpModalVisible: !this.state.isTopUpModalVisible
    })
  }

  closeModal = () => {
    this.setState({
      isTopUpModalVisible: false
    })
  }

  toggleAutoTopUpQuickAction = isVisible => {
    const { isAutoTopUpSetUp } = this.props.topUp
    this.setState({
      isAutoTopUpModalVisible: isVisible || !this.state.isAutoTopUpModalVisible,
      isAutoTopUpSetUp
    })
  }

  closeAutoTopUpModalQuickAction = () => {
    this.setState({ isAutoTopUpModalVisible: false })
  }

  togglePayments = (value) => {
    this.setState({ showPayments: value })
  }

  toggleRefill = (value) => {
    this.setState({ showRefill: value })
  }

  renderUsageTabBody = () => {
    return (
      <Usage
        isActiveTab
        ref={this.usageRef}
        categories={this.categories}
        headerRef={this.headerRef}
        withTray
      />
    )
  }

  renderSubHeaderRightComponent = () => {
    if (isAdminView()) { return null }
    if (this.userType.includes('prepaid')) {
      return (
        <VFButton
          title={'product_and_services_top_up_button'}
          onPress={() => {
            if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
              this.props.navigation.navigate('TopUP')
            } else {
              this.togglePayments(true)
            }
          }}
          style={ProductAndServicesStyles.subHeaderRightComponent}
          textStyle={ProductAndServicesStyles.subHeaderRightComponentStyle}
          type={'primary'}
        />
      )
    }

    return (
      <VFButton
        title={'product_and_services_ebill_button'}
        onPress={() => this.props.navigation.navigate('Billing')}
        style={ProductAndServicesStyles.subHeaderRightComponent}
        textStyle={ProductAndServicesStyles.subHeaderRightComponentStyle}
        type={'primary'}
      />
    )
  }

  setDefaultActiveTab = (value) => {
    this.setState({ defaultActiveTab: value })
  }

  handleDeepLinking = () => {
    const { route } = this.props
    if (route?.params?.isDeepLink) {
      console.log(1)
      if (route?.params?.type) {
        console.log(2)
        setTimeout(() => {
          if (route?.params?.type === 'paketa-shtese') {
            console.log(3)
            this.setDefaultActiveTab(2)
          } else if (route?.params?.type === 'aktiviteti') {
            console.log(4)
            this.setDefaultActiveTab(1)
          }
        }, 2000)
      }
    }
  }

  toggleHyperLinkModal = () => {
    this.setState({ showHyperLink: !this.state.showHyperLink })
  }

  render() {
    const {
      isInlineContentEnabled,
      isNoBalanceHistory,
      isBalanceFailed,
      isBalanceLoading,
      lastUpdateTime,
      customerBill,
      defaultActiveTab
    } = this.state
    const {
      theme,
      onCloseModalIconPress,
      getMyAddonsData,
    } = this.props

    const currentBalance = ReduxCache.getBalance()
    const userType = User_Types.PAY_M
    const {
      myAddonsError,
    } = _get(this.props, 'productsAndServices', {})
    const images = GroupImages

    const addPlanExtraModalJson = {
      'data': {
        'options': [
          {
            'title': 'add_data_occurrence_first_option_title',
            'items': [
              {
                'title': '10GB',
                'subTitle': '6€'
              },
              {
                'title': '20GB',
                'subTitle': '10€'
              },
              {
                'title': '50GB',
                'subTitle': '20€'
              },
              {
                'title': '100GB',
                'subTitle': '40€'
              },
              {
                'title': '150GB',
                'subTitle': '70€'
              }
            ],
            'description': 'add_data_one_off_payment_statement'
          },
          {
            'title': 'add_data_occurrence_second_option_title',
            'items': [
              {
                'title': '10GB',
                'subTitle': '6€/month'
              },
              {
                'title': '20GB',
                'subTitle': '10€/month'
              },
              {
                'title': '50GB',
                'subTitle': '20€/month'
              },
              {
                'title': '100GB',
                'subTitle': '40€/month'
              },
              {
                'title': '150GB',
                'subTitle': '70€/month'
              }
            ],
            'description': 'add_data_recurring_payment_statement'
          }
        ]
      },
      'sms': {
        'options': [
          {
            'title': 'add_sms_occurrence_first_option_title',
            'items': [
              {
                'title': '10 SMS',
                'subTitle': '6€'
              },
              {
                'title': '20 SMS',
                'subTitle': '10€'
              },
              {
                'title': '50 SMS',
                'subTitle': '20€'
              },
              {
                'title': '100 SMS',
                'subTitle': '40€'
              },
              {
                'title': '150 SMS',
                'subTitle': '70€'
              }
            ],
            'description': 'add_sms_one_off_payment_statement'
          },
          {
            'title': 'add_sms_occurrence_second_option_title',
            'items': [
              {
                'title': '10 SMS',
                'subTitle': '6€/month'
              },
              {
                'title': '20 SMS',
                'subTitle': '10€/month'
              },
              {
                'title': '50 SMS',
                'subTitle': '20€/month'
              },
              {
                'title': '100 SMS',
                'subTitle': '40€/month'
              },
              {
                'title': '150 SMS',
                'subTitle': '70€/month'
              }
            ],
            'description': 'add_sms_recurring_payment_statement'
          }
        ]
      }
    }



    return (
      <>
        <ProductsAndServices
          onRefresh={this.onRefresh}
          lastUpdateTime={lastUpdateTime || new Date()}
          renderTopUp={() => null}
          myPlanProps={{
            isError: this.state.isMyPlanError,
            errorText: 'my_plan_screen_error_message',
            onTryAgainPress: () => this.getMyPlanData(),
            planData: this.getMyPlanState(),
            isMyPlanLoading: this.state.isMyPlanLoading,
            onAddUsagePress: this.handleUsagePress,
            addExtraPlanData: addPlanExtraModalJson,
            onClose: onCloseModalIconPress,
            onPressAddConfirmation: this.handlePressAddConfirmation,
            withUsagePercentage: false,
            onPressUpgradePlan: this.manageUpgradeCTAPress,
            title: 'my_plan_primary_card_title',
            subtitle: 'my_plan_primary_card_subtitle',
            forceOpenModal: {
              force: this.state.forceOpenModal,
              key: 'data',
              onModalVisibilityChange: this.onModalVisibilityChange
            },
            withUpgradePlanCTA: this.state.upgradePlanCTA ? true : false
          }}
          onActiveTabChange={this.onActiveTabChange}
          addOnsProps={{
            // currentPlan: this.addOnsCurrentPlan,
            isError: myAddonsError?.isError,
            errorText: myAddonsError?.text,
            onTryAgainPress: getMyAddonsData,
            handleNavigateToAddonsShop: this.handleNavigateToAddonsShop,
            isLoading: this.state.isMyPlanLoading,
            data: this.state.addons,
            inlineContentData: this.inlineContentData,
            isInlineContentEnabled: isInlineContentEnabled,
            images: this.addOnsImages,
            addOnsListIcon: this.addOnsImages.icMenu,
            addOnsTimelineIcon: this.addOnsImages.icActivity,
            onItemPress: this.handlePressAddOnItem,
            onInlineContentPress: this.handleInlineContentPress,
            renewableAddonIconName: 'icRefresh',
            withTimeline: false
          }}
          balanceProps={{
            withCategories: true,
            images: images,
            isNoBalanceHistory: isNoBalanceHistory,
            isError: isBalanceFailed,
            isLoading: isBalanceLoading,
            onTryAgainPress: this.onBalanceTryAgainPress,
            balanceData: this.state.balanceTopics,
            // balanceData: balanceData,
            getBalanceData: () => { },
          }}
          vfScreenProps={{
            title: 'everything_is_ok_my_phone_title',
            withSubHeader: true,
            subHeaderTitle:
              this.userType === 'prepaid' ? 'product_and_services_sub_header_title_prepaid' : 'product_and_services_sub_header_title_postpaid',
            subHeaderSubTitle:
              this.userType.includes('prepaid') ? currentBalance : customerBill?.amountDue?.value?.toString() ? formatNumber(customerBill?.amountDue?.value) + ' Lekë' : ' ',
            subHeaderAfterSubTitle: this.userType === 'prepaid' ? 'product_and_services_after_sub_header_title_prepaid' : 'product_and_services_after_sub_header_title_postpaid',
            subHeaderDescription: this.msisdn,
            subHeaderDescriptionStyle: styles.subHeaderDescriptionStyle,
            onClose: this.handleCloseButtonPress,
            handleOnScroll: this.handleOnScroll,
            ref: this.headerRef,
            closeButtonTestID: 'UserDataClose',
            renderSubHeaderRightComponent: this.renderSubHeaderRightComponent,
            onSubHeaderDescriptionPress: this.toggleHyperLinkModal,
          }}
          headerDescription="Updated 2min ago"
          images={images}
          customTabs={[]}
          userType={userType}
          theme={theme}
          withPageBanner={this.state.withPageBanner}
          pageBannerProps={{
            ...this.state.pageBannerData,
            withSecondaryButton: false
          }}
          defaultActiveTab={defaultActiveTab}
        />

        {this.state.showHyperLink && <Hyperlink
          isVisible={this.state.showHyperLink}
          toggleModal={this.toggleHyperLinkModal}
        />}

        {this.state.showPayments && <Payments setShowPayments={this.togglePayments} isVisible={this.state.showPayments} />}
        {this.state.showRefill && <Refill
          showPayments={() => {
            this.toggleRefill(false)
            this.togglePayments(true)
          }}
          setShowRefill={this.toggleRefill}
          balance={ReduxCache.getBalance()}
          isVisible={this.state.showRefill} />}

      </>
    )
  }
}

ProductsAndServicesContainer.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    pop: PropTypes.func
  }),
  Balance: PropTypes.object,
  Login: PropTypes.object,
  Payment: PropTypes.object,
  topUp: PropTypes.shape({
    isAutoTopUpSetUp: PropTypes.bool,
    autoTopUpData: PropTypes.object,
    isAutoTopUpActivated: PropTypes.bool
  }),
  actions: PropTypes.object,
  theme: PropTypes.shape({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  }),
  inlineContentData: PropTypes.object,
  userType: PropTypes.Stubs,
  screenProps: PropTypes.object,
  getBalanceData: PropTypes.func,
  balanceData: PropTypes.array,
  currentBalance: PropTypes.string,
  username: PropTypes.string,
  selectedPaymentCard: PropTypes.shape({
    brand: PropTypes.string,
    lastFourDigits: PropTypes.number,
    cardName: PropTypes.string,
    expiry: PropTypes.string,
    icon: ImagePropTypes
  }),
  paymentCards: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string,
      lastFourDigits: PropTypes.number,
      cardName: PropTypes.string,
      expiry: PropTypes.string,
      icon: ImagePropTypes
    })
  ),
  wheelValues: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      gift: PropTypes.bool
    })
  ),
  offerValues: PropTypes.shape({
    giftUnit: PropTypes.string,
    giftValue: PropTypes.number,
    giftNormalPrice: PropTypes.number,
    giftOfferPrice: PropTypes.number,
    currency: PropTypes.string
  }),
  defaultWheelValue: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      gift: PropTypes.bool
    })
  ),
  handlePressConfirmSelection: PropTypes.func,
  handlePressAddExtraOffer: PropTypes.func,
  handleSelectPaymentCard: PropTypes.func,
  handlePressSaveCard: PropTypes.func,
  onCloseModalIconPress: PropTypes.func,
  getPaymentMethodData: PropTypes.func,
  getDeviceData: PropTypes.func,
  productsAndServices: PropTypes.object,
  clearDeviceData: PropTypes.func,
  getMyAddonsData: PropTypes.func,
  clearAddonsData: PropTypes.func
}

export default ProductsAndServicesContainer
