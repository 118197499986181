import { action_types } from './survey.actionTypes'

function preferences (state = { loading: false, data: null, error: false, submiting: false, submited: false, submit_error: false }, action = {}) {
  switch (action.type) {
    case action_types.REQUEST_PREFERENCES:
      return {
        ...state,
        loading: true
      }
    case action_types.SUCCESS_PREFERENCES:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      }
    case action_types.ERROR_PREFERENCES:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case action_types.REQUEST_SUBMIT_PREFERENCES:
      return {
        ...state,
        submiting: true
      }
    case action_types.SUCCESS_SUBMIT_PREFERENCES:
      return {
        ...state,
        submiting: false,
        submit_error: null,
        submited: true
      }
    case action_types.ERROR_SUBMIT_PREFERENCES:
      return {
        ...state,
        submiting: false,
        submited: false,
        submit_error: action.payload
      }
    default:
      return state
  }
}

export { preferences }
