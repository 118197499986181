import { Dimensions, Platform, StyleSheet } from 'react-native'
import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
    cardContainerStyle: theme => ({
        marginTop: 16,
        marginBottom: 20,
        padding: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 20 : 16,
        paddingBottom: 20,
        marginHorizontal: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? null : 2,
        marginRight: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 20 : 2,
        backgroundColor: theme.colors.cardBackgroundColor,
        borderRadius: 6,
        elevation: 2,
        shadowColor: 'rgba(0, 0, 0, 0.16)',
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowRadius: 4,
        shadowOpacity: 1,
        width: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? 400 : null,
    }),
    headerTitleViewStyle: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        overflow: 'hidden',
        flex: 1
    },
    statusStyle: colors => {
        if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
            return ({
                fontWeight: 500,
                fontFamily: Fonts.type.VFFont,
                fontSize: 16.6,
                lineHeight: 23,
                backgroundColor: colors.myPlanPrimaryCardStatus,
                color: 'white',
                borderRadius: 13,
                paddingHorizontal: 16,
                paddinVertical: 4
            })
        }
        return ({
            fontWeight: 'bold',
            fontFamily: Fonts.type.VFFont,
            fontSize: 16.6,
            lineHeight: 23,
            color: colors.myPlanPrimaryCardStatus,
            marginTop: -2
        })
    },
    renewalDateStyle: theme => ({
        fontSize: 16.7,
        lineHeight: 23,
        color: theme.colors.textColorTwo
    }),
    titleStyle: theme => ({
        fontFamily: Fonts.type.VodafoneLt,
        fontSize: 25,
        lineHeight: 31.3,
        color: theme.colors.headerTitleColor,
        marginTop: 16.7
    }),
    divider: theme => ({
        marginVertical: 16,
        borderBottomColor: theme.colors.divider,
        borderBottomWidth: 1
    })
})
