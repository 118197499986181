import { StyleSheet } from 'react-native'

import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
  cardStyle: {
    height: 'auto',
    marginBottom: 15,
    paddingHorizontal: 16,
    width: '100%',
    paddingVertical: 15
  },
  cardHeader: theme => ({
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 15,
    borderBottomColor: theme.colors.borderColorFour,
    borderBottomWidth: 1
  }),
  headerTitle: {
    ...Fonts.type.bold,
    fontSize: 18,
    marginLeft: 16,
  },
  headerSubtitle: theme => ({
    marginLeft: 16,
    color: theme.colors.textColorTwo,
    fontSize: 16,
    marginTop: 5,
    lineHeight: 22,
  }),
  button: {
    borderWidth: 0,
    height: 22,
    marginTop: 12,
    alignSelf: 'center'
  },
  buttonText: theme => ({
    color: theme.colors.showMoreColor
  }),
  itemContainer: theme => ({
    paddingBottom: 15,
    borderBottomColor: theme.colors.borderColorFour,
    borderBottomWidth: 1,
    marginVertical: 7.5,
  }),
  row: {
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    flex: 1,

  },
  itemText: {
    fontSize: 16,
    marginVertical: 7.5,
  },
  itemTitle: {
    fontSize: 16,
    marginVertical: 7.5,
    flex: 1,
    paddingRight: 5
  },
  dataContainer: {
    paddingLeft: 50,
  },
  line: theme => ({
    backgroundColor: theme.colors.borderColorFour,
    width: '100%',
    height: 1,
    marginTop: 20
  }),
  bold: {
    ...Fonts.type.bold
  }
})
