import { StyleSheet, Platform, Dimensions } from 'react-native'

import { Colors } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { PlatformUtils } from '@vfgroup-oneplatform/foundation/Utils'

const isIPhoneWithNotch = PlatformUtils.isIPhoneDeviceWithNotch()

export default StyleSheet.create({
  scrollViewContentContainer: theme => ({
    paddingHorizontal: 16.6,
    paddingTop: Platform.OS === 'ios' ? (isIPhoneWithNotch ? 39 : 20) : 39,
    paddingBottom: Platform.OS === 'web' && Dimensions.get('window').width > 700 ? null : 20,
    backgroundColor: theme.colors.backgroundColor,
    flex: 1
  }),
  scrollViewContentContainerWithTray: {
    paddingBottom: Platform.OS === 'ios' ? (isIPhoneWithNotch ? 125 : 115) : 165
  },
  sectionContainer: {
    marginTop: 32.5
  },
  containerStyle: theme => ({
    backgroundColor: theme.colors.cardBackgroundColor,
    paddingVertical: 20,
    borderRadius: 6,
    paddingHorizontal: 20,
    shadowColor: Colors.linegrey,
    elevation: 2,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowRadius: 4,
    shadowOpacity: 1
  }),
  divider: theme => ({
    width: '100%',
    height: 1,
    backgroundColor: theme.colors.horizontalLineColor,
    marginVertical: 16
  }),
  lottieStyle: {
    width: 200,
    height: 200,
  },
  button: {
    marginHorizontal: 16.6,
    marginBottom: 70,
    marginTop: 15,
  }
})
