import { actionTypes } from './Promo.Actions'

function dailyCatalogue(
  state = { loading: false, data: null, error: null },
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_DAILY_CATALOGUE:
      return {
        loading: true,
        data: null,
        error: null,
      }
    case actionTypes.SUCCESS_DAILY_CATALOGUE:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }
    case actionTypes.ERROR_DAILY_CATALOGUE:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }
    default:
      return state
  }
}

function rewardDetails(
  state = { loading: false, data: null, error: null },
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_ORDER_PRODUCT:
      return {
        loading: true,
        data: null,
        error: null,
      }
    case actionTypes.SUCCESS_ORDER_PRODUCT:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }
    case actionTypes.ERROR_ORDER_PRODUCT:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }
    default:
      return state
  }
}

export {
  dailyCatalogue,
  rewardDetails,
}
