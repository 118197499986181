import { actionTypes } from './GigaFamily.ActionTypes'

function customerPlan(state = { loading: false, data: null, error: null }, action = {}) {
  switch (action.type) {
    case actionTypes.REQUEST_CUSTOMER_PLAN:
      return {
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_CUSTOMER_PLAN:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }

    case actionTypes.ERROR_CUSTOMER_PLAN:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}

function products(
  state = { loading: false, data: null, error: null },
  action = {},
) {
  switch (action.type) {
    case actionTypes.REQUEST_GET_PRODUCTS:
      return {
        loading: true,
        data: null,
        error: null,
      }

    case actionTypes.SUCCESS_GET_PRODUCTS:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }

    case actionTypes.ERROR_GET_PRODUCTS:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}


export {
  customerPlan,
  products,
}
