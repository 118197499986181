
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-native-modal'

import { Platform, View, TouchableOpacity } from 'react-native'

// import {
//   check,
//   request,
//   openSettings,
//   PERMISSIONS,
//   RESULTS,
// } from 'react-native-permissions'


import { Icon, VFText } from '@vfgroup-oneplatform/foundation/Components'

import ContactList from '@vfgroup-oneplatform/framework/CommonUI/ContactList/ContactList'

import { getThemeImages, useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'

import { formatPhoneNumber } from '../../../Utils/Regex'

import SearchInput from './SearchInput'

import styles from './SearchInput.Styles'


// const CONTACTS_PERMISSION = Platform.OS === 'ios' ? PERMISSIONS.IOS.CONTACTS : PERMISSIONS.ANDROID.READ_CONTACTS


const SearchInputContact = ({
  inputRef,
  onSelectContact,
  ...props
}) => {

  const [showModal, setShowModal] = useState(false)

  const theme = useTheme()

  const themedImages = getThemeImages(theme.name)

  const onSelectNumber = (number) => {
    let selectedNumber = number.toString()

    selectedNumber = selectedNumber.replace(/[^\d.]/g, '')

    if (selectedNumber.includes(' ')) {
      selectedNumber = selectedNumber.replace(/ /g, '')
    }

    onSelectContact(formatPhoneNumber(selectedNumber?.toString()))
    inputRef.current?.setValue(formatPhoneNumber(selectedNumber?.toString()))

    setShowModal(false)
  }

  // const openContacts = () => {
  //   check(CONTACTS_PERMISSION).then(status => {
  //     switch (status) {
  //       case RESULTS.DENIED:
  //         request(CONTACTS_PERMISSION).then(result => {
  //           if (result === RESULTS.GRANTED) {
  //             setShowModal(true)
  //           }
  //           if ((result === RESULTS.BLOCKED || result === RESULTS.DENIED) && Platform.OS !== 'ios') {
  //             openSettings()
  //           }
  //         })
  //         break
  //       case RESULTS.GRANTED:
  //         setShowModal(true)
  //         break
  //       case RESULTS.BLOCKED:
  //         openSettings()
  //         break
  //       default:
  //         break
  //     }
  //   })
  // }


  return (
    <>
      <SearchInput
        inputRef={inputRef}
        rightButtonIcon="icCallsContactsWhite"
        rightButtonDisabled={false}
        // onRightButtonPress={openContacts}
        formatMsisdn={true}
        {...props}
      />
      <Modal
        isVisible={showModal}
        onSwipeComplete={() => setShowModal(false)}
        onBackdropPress={() => setShowModal(false)}
        backdropOpacity={0.5}
        animationIn="slideInUp"
        animationInTiming={500}
        animationOut="slideOutDown"
        animationOutTiming={500}
        style={styles.modalContentContainer(theme)}
      >
        <View style={styles.row}>
          <VFText
            i18nKey="select_contact_select_phone"
            style={styles.title}
          />
          <TouchableOpacity
            style={styles.contactsItem(theme)}
            onPress={() => setShowModal(false)}
          >
            <Icon
              name={themedImages.ic_close}
              size={30}
              resizeMode="contain"
            />
          </TouchableOpacity>
        </View>
        <ContactList
          onPressContact={selectedItem => onSelectNumber(selectedItem)}
        />
      </Modal>
    </>
  )
}
SearchInputContact.propTypes = {
  inputRef: PropTypes.object,
  onSelectContact: PropTypes.func
}

export default SearchInputContact
