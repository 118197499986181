import React, { Component } from 'react'

import { View, Text} from 'react-native'

import { __translate } from '../../Utils'

import styles from './largeheader.styles'

export default class LargeHeader extends Component {
  render() {
    return (
      <View style={styles.headerstyle}>
        <Text style={styles.titleText}> { __translate('reward_loyalty_survey_header')} </Text>
        <Text style={styles.subtitleText}>
          {__translate('reward_loyalty_survey_desc')}
        </Text>
      </View>
    )
  }
}
