import React from 'react'
import PropTypes from 'prop-types'

import { WebView as VFWebView } from '@vfgroup-oneplatform/framework/CommonUI'
import { VFScreen } from '@vfgroup-oneplatform/foundation/Components'

import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { navigationRef } from '../../Navigation/NavigationService'

import styles from './WebView.Styles.js'

function WebView({ navigation, route }) {
    const { url, title, postData, statusBarColor, incognito = true } = route.params
    const insets = useSafeAreaInsets()
    const onClose = () => {
        if (route?.params?.isDeepLink) {
            navigationRef.navigate('Dashboard')
        } else {
            navigation.pop()
        }
    }
    return (
        <VFScreen title={title || url} hideMainHeader>
            <VFWebView
                url={title || url}
                statusBarColor={statusBarColor}
                source={postData ? { uri: url, body: postData, method: 'POST', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } } : { uri: url }}
                onClose={onClose}
                incognito={incognito}
                navigation
                headerStyle={styles.headerStyle(insets)}
            />
        </VFScreen>
    )
}

WebView.propTypes = {
    url: PropTypes.string,
    onClose: PropTypes.func,
    onMessage: PropTypes.func,
    navigation: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired
}

WebView.defaultProps = {

}

export default WebView
