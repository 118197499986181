/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useLayoutEffect } from 'react'

import moment from 'moment'
import { map as _map, capitalize as _capitalize } from 'lodash'

import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'

import PropTypes from 'prop-types'

import { Billing } from '@vfal-lab/rn-vfg-fix-billing/Screens'

import { getCurrentBills, getPreviousBills } from '../../Services/Fix'

import ReduxCache from '../../Utils/ReduxCache'


const monthTranslations = {
  al: {
    'January': 'Janar',
    'February': 'Shkurt',
    'March': 'Mars',
    'April': 'Prill',
    'May': 'Maj',
    'June': 'Qershor',
    'July': 'Korrik',
    'August': 'Gusht',
    'September': 'Shtator',
    'October': 'Tetor',
    'November': 'Nëntor',
    'December': 'Dhjetor',
    'Jan': 'Janar',
    'Feb': 'Shkurt',
    'Mar': 'Mars',
    'Apr': 'Prill',
    'Jun': 'Qershor',
    'Jul': 'Korrik',
    'Aug': 'Gusht',
    'Sep': 'Shtator',
    'Oct': 'Tetor',
    'Nov': 'Nëntor',
    'Dec': 'Dhjetor',
    'Janar': 'Janarit',
    'Shkurt': 'Shkurtit',
    'Mars': 'Marsit',
    'Prill': 'Prillit',
    'Maj': 'Majit',
    'Qershor': 'Qershorit',
    'Korrik': 'Korrikut',
    'Gusht': 'Gushtit',
    'Shtator': 'Shtatorit',
    'Tetor': 'Tetorit',
    'Nëntor': 'Nëntorit',
    'Dhjetor': 'Dhjetorit'
  },
  en: {
    'January': 'January',
    'February': 'February',
    'March': 'March',
    'April': 'April',
    'May': 'May',
    'June': 'June',
    'July': 'July',
    'August': 'August',
    'September': 'September',
    'October': 'October',
    'November': 'November',
    'December': 'December',
    'Jan': 'Jan',
    'Feb': 'Feb',
    'Mar': 'Mar',
    'Apr': 'Apr',
    'Jun': 'Jun',
    'Jul': 'Jul',
    'Aug': 'Aug',
    'Sep': 'Sep',
    'Oct': 'Oct',
    'Nov': 'Nov',
    'Dec': 'Dec'
  }
}

const mapBillsData = (response = {}, language = 'al') => {

  const data = response


  const currentBills = []

  _map(data, currentBill => {
    const { id, billDate, amountDue, billingPeriod, state, outOfBundleAmount } = currentBill

    const services = []
    const outOfBundle = []
    let billType = 'mobile'
    let address = ''

    outOfBundleAmount.map(item => {
      const title = item.characteristic.find(el => el.name === 'Description')?.value || item.characteristic.find(el => el.name === 'ServiceType')?.value
      const service = item.characteristic.find(el => el.name === 'ServiceType')?.value?.toUpperCase()
      billType = item.characteristic.find(el => el.name === 'BillType')?.value
      address = item.characteristic.find(el => el.name === 'Address')?.value
      outOfBundle.push({ title, price: item?.value?.toFixed(2) + ' ' + item?.unit })
      services.push(service)
    })

    const date = moment(billDate)
    const billStartDate = moment(billingPeriod.startDateTime)

    currentBills.push({
      id,
      chargesList: outOfBundle.length > 0 ? outOfBundle : null,
      startDate: billingPeriod.startDateTime,
      endDate: billingPeriod.endDateTime,
      year: billStartDate.year(),
      month: monthTranslations[language][billStartDate.format('MMM')],
      date: billStartDate.format('DD'),
      paymentInfo: [date.format('DD'), '', monthTranslations[language][date.format('MMM')]],
      value: +amountDue.value,
      currency: amountDue.unit,
      price: amountDue.value + ' ' + amountDue.unit,
      imageListProp: {
        services: new Set(services),
        title: 'Your services included',
      },
      status: state !== 'sent' ? state : undefined,
      billType: billType,
      address: address ?? ''
    })

  })

  return currentBills
}


const override = {
  billing_details_call_to: '',
  ': ': '',
  billing_payment_breakdown_title: 'Summarized Bill'
}

function FixBillingScreen({ navigation, route }) {

  const [currentBillLoader, setCurrentBillLoader] = useState(true)
  const [BillsLoader, setBillsLoader] = useState(true)

  const [currentBills, setCurrentBills] = useState([])
  const [previousBills, setPreviousBills] = useState([])

  // eslint-disable-next-line no-unused-vars
  const [shouldRender, setShouldRender] = useState(false)

  const language = ReduxCache.getLanguage()
  const msisdn = ReduxCache.getMsisdn()
  //const userType = ReduxCache.getUserType()

  const { navigate } = navigation

  /** Content manager initialization */
  useLayoutEffect(() => {
    setShouldRender(true)
    ContentManager.configureI18nTranslations({ en: override })
    setShouldRender(false)
  }, [])

  useEffect(() => {
    if (!msisdn) {
      return
    }

    const headers = {
      'Accept-Language': language,
      'vf-country-code': 'al',
      // 'operator': 'selfcare',
      // 'source': 'customerAgent',
      'Content-Type': 'application/json;charset=utf-8'
    }

    getCurrentBills(msisdn, headers, {
      responseMapper: (res) => mapBillsData(res.data),
      onSuccess: (response) => {
        setCurrentBills(response)
        setCurrentBillLoader(false)
      },
      onError: (error) => {
        setCurrentBillLoader(false)
      }
    })

    getPreviousBills(msisdn, headers, {
      responseMapper: (res) => mapBillsData(res.data),
      onSuccess: (response) => {
        setPreviousBills(response)
        setBillsLoader(false)
      },
      onError: (error) => {
        setBillsLoader(false)
      }
    })

  }, [msisdn, navigation])

  const navigateToMonthlyBillingScreen = billData => {
    navigate('MonthlyBilling', {
      bill: billData
    })
  }

  const onClose = () => {
    if (route?.params?.isDeepLink) {
      navigation.navigate('Dashboard')
    } else {
      navigation.pop()
    }
  }

  const historyChartData = [...previousBills]
  const historyData = [...previousBills]

  let billTypesArray = []
  const billTypes = []

  currentBills.forEach(() => historyData.shift())

  historyData.map(item => billTypesArray.push(item.billType))

  billTypesArray = [...new Set(billTypesArray)]

  billTypesArray.map(item => billTypes.push({ value: `${item}`, label: _capitalize(item) }))



  // Deep Linking
  // React.useEffect(() => {
  //   if (route?.params?.id && historyChartData) {
  //     const { params } = route
  //     let billId = params.id
  //     if (params?.section) {
  //       billId = billId + '/' + params?.section
  //     }
  //     let billObject = null
  //     if (billId === 'current') {
  //       billObject = historyChartData[historyChartData.length - 1]
  //     } else {
  //       billObject = historyChartData.find(item => item.id === billId)
  //     }
  //     if (billObject) {
  //       navigateToMonthlyBillingScreen(billObject)
  //     }
  //   }
  // }, [route, historyChartData, navigateToMonthlyBillingScreen])



  return (
    <Billing
      vfScreen={{
        title: 'eBill',
        onClose: onClose
      }}
      chart={{
        data: historyChartData.reverse(),
        isLoading: BillsLoader,
        onBarPress: (billObj) => { navigateToMonthlyBillingScreen(billObj) },
        isLastOutLined: true
      }}
      currentBillsCard={{
        isLoading: currentBillLoader,
        bills: currentBills,
        onPress: (billObj) => { navigateToMonthlyBillingScreen(billObj) }
      }}
      billsCards={{
        data: historyData,
        onBillPress: (billObj) => { navigateToMonthlyBillingScreen(billObj) },
        isLoading: BillsLoader,
        onShowMorePress: () => { },
        showMoreButton: false,
        monthsRemaining: 0,
      }}
      isCurrencyFirst={false}
      billTypes={billTypes.length > 1 ? billTypes : []}
    />
  )
}

FixBillingScreen.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object
}

export default FixBillingScreen
