import { StyleSheet } from 'react-native'
import { Colors, Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

import images from '../../Themes/Images'

const styles = StyleSheet.create({
    headerStyle: {
        backgroundColor: Colors.white,
        borderBottomWidth: 0
    },
    container: {
        flex: 1,
        backgroundColor: Colors.whiteSmoke,
    },
    headerOfferTitle: {
        marginHorizontal: 16,
    },
    headerContentContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginVertical: 8,
    },
    fillRemainingSpace: {
        flexGrow: 1,
    },
    headerIcon: {
        tintColor: Colors.white,
        marginRight: 16,
        marginLeft: 8,
    },
    scrollContainer: {
        flexGrow: 1,
        paddingBottom: 32,
    },
    timelineContainer: {
        padding: 16,
        backgroundColor: Colors.white,
    },
    timelineIconContainer: {
        marginBottom: 4,
    },
    timelineItemTitle: {
        padding: 0,
        fontSize: 16,
    },
    timelineActiveItemTitle: {
        color: Colors.thinDark,
    },
    viewPager: {
        flexGrow: 1,
    },
    sectionTitle: {
        margin: 16,
        marginTop: 32,
    },
    cardStyle: {
        margin: 16,
        marginBottom: 0,
        padding: 0,
    },
    cardContentContainer: {
        paddingVertical: 0,
        overflow: 'hidden',
    },
    collapsedContent: {
        backgroundColor: Colors.whiteSmoke,
    },
    extraListContainer: {
        margin: 16,
        marginRight: 0,
    },
    options: {
        marginHorizontal: 12,
        marginBottom: 16,
    },
    firstButton: {
        margin: 16,
        marginTop: 32,
        marginBottom: 0,
    },
    button: {
        margin: 16,
        marginBottom: 0,
    },
    collapsedContent2: {
        paddingHorizontal: 16,
        backgroundColor: Colors.white,
    },
    titleStyle: {
        ...Fonts.type.bold,
    },
    titleStyle2: {
        fontSize: 14,
    },
    descStyles2: {
        fontSize: 18,
        ...Fonts.type.bold,
    },
    toggleAllContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 16,
        paddingTop: 32,
        paddingBottom: 0,
    },
    inputContainer: {
        minHeight: 50,
    },
    extraTitleStyle: {
        marginTop: 2,
        fontSize: 14,
    },
    checkBoxStyle: {
        marginTop: 12,
        marginRight: 12,
        marginLeft: 4,
    },
    extraIconStyle: {
        margin: 4,
        marginVertical: 8,
        tintColor: Colors.mustardGreen,
        width: 16,
        height: 16,
    },
    termsRowStyle: {
        borderTopColor: Colors.veryLightPinkTwo,
        borderTopWidth: 1,
        borderStyle: 'solid',
        paddingVertical: 16,
    },
    bottomText: {
        margin: 16,
    },
    textSpacing: {
        marginHorizontal: 16,
        marginVertical: 8,
    },
    horizontalSpacing: {
        marginHorizontal: 16,
    },
    cardContentContaoner3: {
        padding: 8,
        marginBottom: 18,
        overflow: 'hidden',
    },
    radioGroup: {
        marginTop: 16,
    },
    scrollContainer4: {
        flexGrow: 1,
    },
    summary: {
        marginTop: 32,
        paddingBottom: 32,
        backgroundColor: Colors.white,
    },
    separator: {
        margin: 16,
        backgroundColor: Colors.veryLightPinkTwo,
    },
    sumaryDetailsrowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    iconHIFI: {
        width: 42,
        height: 42,
    },
    verificationCodeSentTitle: {
        fontSize: 14,
        marginTop: 12,
    },
    verificationCodeSentContainer: {
        margin: 16,
        marginBottom: 24,
        borderRadius: 6,
        borderWidth: 1,
        borderColor: Colors.veryLightPinkTwo,
        borderStyle: 'solid',
    },
    touchableText: {
        textDecorationLine: 'underline',
    },
    icon: {
        resizeMode: 'contain',
    },
    verificationRowIcon: {
        width: 32,
        height: 32,
        resizeMode: 'contain',
        tintColor: Colors.thinDark,
    },
    deleteIcon: {
        tintColor: Colors.red,
    },


})

const extraIcon = images.tickConfirm

const checkBoxTintcolors = {
    true: Colors.ocean,
    false: Colors.veryLightPinkTwo,
}

const timelineTitleProps = {
    numberOfLines: 1,
    ellipsizeMode: 'clip',
}

export default styles
export {
    timelineTitleProps,
    checkBoxTintcolors,
    extraIcon,
}
