import { StackActions, CommonActions, createNavigationContainerRef } from '@react-navigation/native'

import Navigation from '@vfgroup-oneplatform/foundation/Utils/Navigation'

const navigationRef = createNavigationContainerRef()
function navigate(name, params) {
  if (navigationRef.isReady()) {
    navigationRef.navigate(name, params)
  }
}

function navigateWithResetAction(name, params) {
  if (navigationRef.isReady()) {
    const resetAction = CommonActions.reset({
      index: 1,
      routes: [
        { name: name, params: params }
      ],
    })

    navigationRef.dispatch(resetAction)
  }
}

function push(...args) {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(StackActions.push(...args))
  }
}

function getParam(classInstance, paramName, defaultValue) {
  try {
    if (navigationRef.isReady()) {
      // added because sometimes the value of params is null and we want to return null instead of defaultData
      if (paramName in navigationRef.getCurrentRoute().params) {
        return navigationRef.getCurrentRoute().params[paramName]
      }
    }
  } catch (error) {

  }

  return defaultValue
}

function pop(...args) {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(StackActions.pop(...args))
  }
}

function goBack() {
  navigationRef.dispatch(CommonActions.goBack())
}

function popToTop() {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(StackActions.popToTop())
  }
}

Navigation.instance = {
  navigate,
  goBack,
  pop,
  push,
  getParam,
  navigateWithResetAction,
  popToTop,
}

export { navigationRef }

export default {
  navigate,
  goBack,
  pop,
  push,
  getParam,
  navigateWithResetAction,
  popToTop
}
