import { StyleSheet, Platform, Dimensions } from 'react-native'

const width = Dimensions.get('window').width / 4

export default StyleSheet.create({
  containerStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: 0,
    paddingRight: 30,
    paddingLeft: 20,
    paddingTop: 20
  },
  topLabel: {
    fontWeight: 'bold',
    color: '#fff',
    fontSize: 19,
    marginTop: 3,
    textAlign: 'center',
    fontFamily: Platform.OS === 'ios' ? 'Vodafone Rg' : 'VodafoneRgBd'

  },
  lineStyle: {
    width: 1,
    height: 60,
    backgroundColor: '#fff',
    marginLeft: 27
  },
  closeIcon: {
    width: 25,
    height: 25,
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 20,
    top: 20
  },
  years: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 45,
    height: 45,
    borderRadius: 50,
    backgroundColor: 'white',
    borderWidth: 4,
    borderColor: 'white',
    shadowColor: 'transparent'
  },
  years_red: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 55,
    height: 55,
    borderRadius: 60,
    backgroundColor: 'red',
    borderWidth: 4,
    borderColor: 'red',
    shadowColor: 'transparent'
  },
  text: {
    paddingBottom: 8,
    paddingRight: 8,
    paddingLeft: 8,
    paddingTop: 17,
    color: 'white',
    fontFamily: Platform.OS === 'ios' ? 'Vodafone Rg' : 'VodafoneRg',
    fontSize: 16
  },
  info_bg: {
    width: '100%',
    height: '100%'
  },
  topView: {
    justifyContent: 'center',
    flexDirection: 'row',
    padding: 15,
    paddingBottom: 5,
    paddingTop: 25,
    marginTop: Platform.OS === 'ios' ? 25 : 0,
  },
  headerText: {
    flex: 1,
    alignContent: 'center',
    alignSelf: 'center'
  },
  scrollView: {
    flexDirection: 'column',
    flex: 1
  },
  tableContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: 'rgba(30,30,30,0.5)',
    margin: 12,
    padding: 15,
    borderTopStartRadius: 15,
    borderTopEndRadius: 15
  },
  tableColumn: {
    color: 'white',
    fontFamily: Platform.OS === 'ios' ? 'Vodafone Rg' : 'VodafoneRg',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: 'bold',
    width: width
  },
  tableBody: {
    justifyContent: 'space-around',
    marginLeft: 20,
    marginRight: 20,
    padding: 10
  },
  tableRow: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  circle: {
    backgroundColor: 'transparent',
    padding: 4,
    borderColor: '#fff',
    borderRadius: 50,
    borderWidth: 1
  },
  circleBig: {
    backgroundColor: 'transparent',
    padding: 4,
    borderColor: '#fff',
    borderRadius: 50,
    marginLeft: -5,
    borderWidth: 1
  },
  white_circle: {
    color: 'black',
    fontFamily: Platform.OS === 'ios' ? 'Vodafone Rg' : 'VodafoneRg',
    fontSize: 15
  },
  red_circle: {
    color: 'white',
    fontFamily: Platform.OS === 'ios' ? 'Vodafone Rg' : 'VodafoneRg',
    fontSize: 17
  },
  content: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(30,30,30,0.7)',
    margin: 12,
    padding: 12,
    borderBottomStartRadius: 15
  },
  contentText: {
    color: 'white',
    fontFamily: Platform.OS === 'ios' ? 'Vodafone Rg' : 'VodafoneRg',
    fontSize: 15
  },
  contentButton: {
    marginTop: 10,
    flex: 1,
    flexDirection: 'row',
    borderTopColor: '#fff',
    borderTopWidth: 1,
    paddingTop: 5
  },
  go: {
    width: 15,
    height: 15,
    marginTop: 5,
    marginLeft: 5
  },
  dot: {
    width: 13,
    height: 13,
    borderRadius: 7
  },
  activeDot: {
    width: 15,
    height: 15,
    borderRadius: 8
  },
  pagination: {
    bottom: 10,
  }

})
