import { Image } from 'react-native'
import LightFrameworkImages from '@vfgroup-oneplatform/framework/Themes/Modes/Light/Images'

import Images from '../../Images'

const images = {
  ...LightFrameworkImages,
  ...Images,

  // Dashboard
  ic_store: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/icVstore@3x.png')
  ),
  icAdd: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/icAdd@3x.png')
  ),
  icShoppingTrolley: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/icShoppingTrolley@3x.png')
  ),
  icEngineer: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/icEngineer@3x.png')
  ),
  ic_settings: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/icSettings@3x.png')
  ),
  ic_globe: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/icInternetGlobeCountry@3x.png')
  ),
  ic_acceleration: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/icAcceleration@3x.png')
  ),
  secondary_card2: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/secondarycard2@3x.png')
  ),
  ic_data_sharing: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/dataSharing@3x.png')
  ),
  ic_sms_text: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/smsText@3x.png')
  ),
  ic_call_log: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/callLog@3x.png')
  ),
  ic_ebill_web: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/ebill_web.png')
  ),

  // Addons
  icMenu: Image.resolveAssetSource(
    require('./../../../Assets/Images/Addons/icMenuRed@3x.png')
  ),
  icActivity: Image.resolveAssetSource(
    require('./../../../Assets/Images/Addons/icActivityRed@3x.png')
  ),
  videoPass: Image.resolveAssetSource(
    require('./../../../Assets/Images/Addons/videoPass@3x.png')
  ),
  socialPass: Image.resolveAssetSource(
    require('./../../../Assets/Images/Addons/socialPass@3x.png')
  ),
  maps: Image.resolveAssetSource(
    require('./../../../Assets/Images/Addons/maps@3x.png')
  ),
  roaming: Image.resolveAssetSource(
    require('./../../../Assets/Images/Addons/roaming@3x.png')
  ),
  gamingPass: Image.resolveAssetSource(
    require('./../../../Assets/Images/Addons/gamingPass@3x.png')
  ),
  addOnHeader: Image.resolveAssetSource(
    require('./../../../Assets/Images/Addons/header@3x.png')
  ),
  visa: Image.resolveAssetSource(require('./../../../Assets/Images/visa@3x.png')),
  roamingPassIcon: Image.resolveAssetSource(
    require('./../../../Assets/Images/Addons/roamingPassIcon@3x.png')
  ),
  roamingPassImage: Image.resolveAssetSource(
    require('./../../../Assets/Images/Addons/roamingPassImage@3x.png')
  ),
  icBillReport: Image.resolveAssetSource(
    require('@vfgroup-oneplatform/foundation/Components/Assets/Images/Icons/icBillReportGenericTariffPlan@3x.png')
  ),
  icClock: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/icClockMid@3x.png')
  ),
  icMobile: Image.resolveAssetSource(
    require('@vfgroup-oneplatform/foundation/Components/Assets/Images/Icons/ic_mobile@3x.png')
  ),
  icRefresh: Image.resolveAssetSource(
    require('./../../../Assets/Images/Addons/icSync@3x.png')
  ),
  ic_outgoing_call: Image.resolveAssetSource(
    require('../../../Assets/Images/Balance/icOutgoingCall@3x.png')
  ),
  ic_social_pass: Image.resolveAssetSource(
    require('../../../Assets/Images/Balance/icSocialPass@3x.png')
  ),
  icMail: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/icMail@3x.png')
  ),
  icTopUp: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/icTopUp@3x.png')
  ),
  my_plan_calls: Image.resolveAssetSource(
    require('../../../Assets/Images/Addons/calls@3x.png')
  ),
  my_plan_dataSharing: Image.resolveAssetSource(
    require('../../../Assets/Images/Addons/dataSharing@3x.png')
  ),
  my_plan_smsText: Image.resolveAssetSource(
    require('../../../Assets/Images/Addons/smsText@3x.png')
  ),

  // Billing
  ic_parentalControl: Image.resolveAssetSource(
    require('../../../Assets/Images/Billing/icParentalControl@3x.png')
  ),
  ic_router: Image.resolveAssetSource(
    require('../../../Assets/Images/Billing/icRouter@3x.png')
  ),
  ic_mobile: Image.resolveAssetSource(
    require('../../../Assets/Images/Billing/mobileDark@3x.png')
  ),
  icLogoWithCircle: Image.resolveAssetSource(
    require('../../../Assets/Images/Logo/redWithCircle@3x.png')
  ),
  ic_indicators_admin: Image.resolveAssetSource(
    require('../../../Assets/Images/SwitchAccount/iconsIndicatorsSystemIconsAdmin333333@3x.png')
  ),
  ic_indicators_business: Image.resolveAssetSource(
    require('../../../Assets/Images/SwitchAccount/iconsIndicatorsSystemIconsBusiness333333@3x.png')
  ),
  ic_indicators_home: Image.resolveAssetSource(
    require('../../../Assets/Images/SwitchAccount/iconsIndicatorsSystemIconsHome333333@3x.png')
  ),
  ic_hardware_repaire: Image.resolveAssetSource(
    require('../../../Assets/Images/BookAnAppointment/hardware_repair@3x.png')
  ),
  ic_health_check: Image.resolveAssetSource(
    require('../../../Assets/Images/BookAnAppointment/health_check@3x.png')
  ),
  ic_product_showcase: Image.resolveAssetSource(
    require('../../../Assets/Images/BookAnAppointment/product_showcase@3x.png')
  ),

  // DeveloperSettings
  ic_car: Image.resolveAssetSource(
    require('../../../Assets/Images/DeveloperSettings/icCar@3x.png')
  ),
  ic_dashboard: Image.resolveAssetSource(
    require('../../../Assets/Images/DeveloperSettings/icDashboard@3x.png')
  ),
  id_document: Image.resolveAssetSource(
    require('../../../Assets/Images/DeveloperSettings/icDocument@3x.png')
  ),
  ic_family: Image.resolveAssetSource(
    require('../../../Assets/Images/DeveloperSettings/icFamily@3x.png')
  ),
  ic_chevron_down: Image.resolveAssetSource(
    require('../../../Assets/Images/DeveloperSettings/icChevronDown@3x.png')
  ),
  community_or_foundation: Image.resolveAssetSource(
    require('../../../Assets/Images/DeveloperSettings/communityOrFoundation@3x.png')
  ),
  ic_password_key: Image.resolveAssetSource(
    require('../../../Assets/Images/DeveloperSettings/icPasswordKey@3x.png')
  ),

  // tobi
  ic_clock: Image.resolveAssetSource(
    require('../../../Assets/Images/tobi/ic-clock-red@3x.png')
  ),

  // settings
  settings_icDashboard: Image.resolveAssetSource(
    require('../../../Assets/Images/DeveloperSettings/icDashboard@3x.png')
  ),
  settings_usefulInfo: Image.resolveAssetSource(
    require('../../../Assets/Images/Settings/usefulInfo@3x.png')
  ),
  ic_phone: Image.resolveAssetSource(
    require('../../../Assets/Images/Settings/ic_phone@3x.png')
  ),
  ic_doc: Image.resolveAssetSource(
    require('../../../Assets/Images/Settings/ic_doc@3x.png')
  ),

  // Roaming Settings
  locationRoaming: Image.resolveAssetSource(require('../../../Assets/Images/Settings/locationRoaming.png')),
  ic_roamingLocation: Image.resolveAssetSource(require('../../../Assets/Images/Settings/ic_roaming_location.png')),
  ic_roamingRedTail: Image.resolveAssetSource(require('../../../Assets/Images/Settings/ic_roaming_redTail.png')),

  // Soho Business overview
  icLandlineOrCallMinutes: Image.resolveAssetSource(
    require('../../../Assets/Images/Soho/BusinessOverview/Plans/icLandlineOrCallMinutes@3x.png')
  ),

  // Languages
  icBritishFlag: Image.resolveAssetSource(
    require('../../../Assets/Images/icons_indicators_flags_britain@3x.png')
  ),
  icAlbanianFlag: Image.resolveAssetSource(
    require('../../../Assets/Images/icons_indicators_flags_albanian.png')
  ),
  icHungaryFlag: Image.resolveAssetSource(
    require('../../../Assets/Images/iconsIndicatorsFlagsHungary@3x.png')
  ),

  //edit tertiary cards
  icAllRewards: Image.resolveAssetSource(
    require('../../../Assets/Images/icAllRewards@3x.png')
  ),
  icWifiBroadband: Image.resolveAssetSource(
    require('../../../Assets/Images/icWifiBroadband.png')
  ),
  networkSignal: Image.resolveAssetSource(
    require('../../../Assets/Images/Network-signal.png')
  ),
  icon: Image.resolveAssetSource(
    require('../../../../../App/Assets/Images/icons/icon.png')
  ),
  ic_doc3: Image.resolveAssetSource(
    require('../../../Assets/Images/Settings/ic_doc.png')
  ),
  topUpAnother: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/top-up-another@3x.png')
  ),
  privacy: Image.resolveAssetSource(
    require('../../../../Group/Assets/Images/privacy.png')
  ),
  icLandlineOrCallMinutes3: Image.resolveAssetSource(
    require('../../../Assets/Images/Soho/BusinessOverview/Plans/icLandlineOrCallMinutes@3x.png')
  ),

  icShoppingTrolley: Image.resolveAssetSource(require('../../../Assets/Images/Dashboard/icShoppingTrolley@3x.png')),
  ic_acceleration: Image.resolveAssetSource(require('../../../Assets/Images/Dashboard/icAcceleration@3x.png')),
  ic_stars: Image.resolveAssetSource(require('../../../Assets/Images/Dashboard/ic_stars.png')),
  ic_rewards: Image.resolveAssetSource(require('../../../Assets/Images/Dashboard/reward@3x.png')),
  ic_red_box: Image.resolveAssetSource(require('../../../Assets/Images/Dashboard/flower@3x.png')),
  ic_recharge: Image.resolveAssetSource(require('../../../Assets/Images/Dashboard/top_up@3x.png')),
  ic_recharge_other: Image.resolveAssetSource(require('../../../Assets/Images/Dashboard/top-up-another@3x.png')),
  ic_parking: Image.resolveAssetSource(require('../../../Assets/Images/Dashboard/parking@3x.png')),
  ic_sharing: Image.resolveAssetSource(require('../../../Assets/Images/Dashboard/sharing@2x.png')),
  ic_fixed_line: Image.resolveAssetSource(require('../../../Assets/Images/Dashboard/fixLine.png')),
  ic_call_me_back: Image.resolveAssetSource(require('../../../Assets/Images/Dashboard/call-me-back@3x.png')),
  ic_credit: Image.resolveAssetSource(require('../../../Assets/Images/Billing/iconsIndicatorsSystemIconsAutoTopUpBlack.png')),

  ic_entertainment: Image.resolveAssetSource(require('../../../Assets/Images/Dashboard/ic_entertainment.png')),

  icRoaming: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/iconsIndicatorsSystemIconsRoaming333333@3x.png')
  ),

  speed: Images.speed,
  busniess_usage: Images.busniess_usage,
  ic_soho_offers: LightFrameworkImages.ic_rewards,
  call_log: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/call-log.png')
  ),
  ic_store: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/ic_store.png')
  ),
  cloud: Images.cloud,
  digital: Images.digital,
  fixed: Images.fixed,
  security: Images.security,
  vhub: Images.vhub,
  bundles: Images.bundles,
  'bookcalendar-month': Images['bookcalendar-month'],
}

export default images
