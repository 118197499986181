import React, { useState, useEffect } from 'react'
import { View, TouchableOpacity, ScrollView, Dimensions, Platform, Pressable } from 'react-native'
import PropTypes from 'prop-types'
import Clipboard from '@react-native-clipboard/clipboard'
import WebView from 'react-native-webview'

import {
  QuickActionMenu,
  VFText,
  Icon,
  VFButton,
  Shimmer,
} from '@vfgroup-oneplatform/foundation/Components'
import ContentManager from '@vfgroup-oneplatform/foundation/ContentManager'
import { getThemeImages, useTheme, Images } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { setLoadingConfigObject } from '@vfgroup-oneplatform/foundation/Components/Shimmer/Shimmer.utils'

import Modal from 'react-native-modal'

import ReduxCache from '../../../Utils/ReduxCache'
import Loader from '../../../Components/Loader'
import { Images as images } from '../../../Themes'


import { getPuk, getAccountInfo } from '../../../Services/ProductsAndServices/Hyperlink'

import Styles from './Hyperlink.Styles'



const { height: SCREEN_HEIGHT } = Dimensions.get('window')

const shimmerConfig = [
  {
    left: setLoadingConfigObject(
      'line',
      1,
      120,
      { marginVertical: 10 },
    ),
    right: setLoadingConfigObject(
      'line',
      1,
      120,
      { marginVertical: 10 },
    )
  },
]

const shimmerConfig1 = [
  {
    left: setLoadingConfigObject(
      'line',
      1,
      120,
      { marginVertical: 10 },
    ),
  },
]

const Loading = () => {
  return (
    <>
      <Shimmer config={shimmerConfig} />
      <Shimmer config={shimmerConfig} />
    </>
  )
}

const Row = ({ item }) => {
  return (
    <View style={Styles.rowContainer}>
      <VFText
        i18nKey={item.title}
        style={Styles.rowText}
      />
      <VFText
        i18nKey={item.value}
        style={Styles.rowText}
      />
    </View>
  )
}

Row.propTypes = {
  item: PropTypes.object,
  loading: PropTypes.bool,
}

const BorderedRow = ({ item, theme }) => {
  return (
    <View style={Styles.borderedRowContainer(theme)}>
      <VFText
        i18nKey={item.title}
        style={Styles.rowText}
      />
      <VFText
        i18nKey={item.value}
        style={Styles.rowText}
      />
    </View>
  )
}

BorderedRow.propTypes = {
  item: PropTypes.object,
  loading: PropTypes.bool,
  theme: PropTypes.object,
}

const Error = ({ error, getData, theme }) => {
  return (
    <View style={Styles.errorContainer}>
      <VFText
        i18nKey={error}
        style={Styles.errorText(theme)}
      />
      <TouchableOpacity
        style={Styles.tryAgainContainer}
        onPress={getData}
      >
        <VFText
          i18nKey="dashboard_loading_error_try_again_button"
          style={Styles.tryAgainText}
        />
        <Icon
          name={Images.ic_RefreshRed}
          size={30}
          resizeMode="contain"
        />
      </TouchableOpacity>
    </View>
  )
}

Error.propTypes = {
  error: PropTypes.string,
  getData: PropTypes.func,
  theme: PropTypes.object,
}


function Hyperlink({
  isVisible,
  toggleModal,
}) {

  const userType = ReduxCache.getUserType()

  const theme = useTheme()

  const themeImages = getThemeImages(theme.name)

  const [showInfo, setShowInfo] = useState(false)
  const [puk, setPuk] = useState()
  const [isPukLoading, setIsPukLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState()
  const [data, setData] = useState()
  const [copied, setCopied] = useState(false)

  const height = SCREEN_HEIGHT * 0.8 - 32

  const getData = () => {
    setIsPukLoading(true)
    setIsLoading(true)
    setError()
    getAccountInfo({ 'vf-operator': 'myplan' }, {
      onSuccess: (response) => {
        setData(response)
        setIsLoading(false)
      },
      onError: (er) => {
        setError(er)
        setIsLoading(false)
      }
    })
    getPuk({ 'vf-operator': 'myplan' }, {
      onSuccess: (response) => {
        setPuk(response.PUK1)
        setIsPukLoading(false)
      },
      onError: (er) => {
        setError(er)
        setIsPukLoading(false)
      }
    })
  }


  useEffect(() => {
    getData()
  }, [])

  const toggleInfoModal = () => setShowInfo(!showInfo)

  const copyToClipboard = () => {
    setCopied(true)
    Clipboard.setString(puk)
    setTimeout(() => setCopied(false), 2000)
  }

  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
    console.log(theme, 'theme')
    return (
      <Modal
        isVisible={isVisible}
        backdropOpacity={0.5}
        onSwipeComplete={() => toggleModal()}
        onBackdropPress={() => toggleModal()}
        propagateSwipe
        hideModalContentWhileAnimating
        hardwareAccelerated
        backdropTransitionOutTiming={0}
        style={{
          justifyContent: 'flex-end',
          margin: '0 !important',
          minWidth: 1920,
          maxWidth: 1920,
        }}
      >
        <View
          style={{
            height: '600px',
            minWidth: '600px',
            maxWidth: '600px',
            backgroundColor: theme.colors.quickActionGradientColors.secondary,
            margin: 'auto',
            padding: '20px',
            borderRadius: '7px',
          }}
          onStartShouldSetResponder={() => true}>
          {error ? <Error error={error} getData={getData} theme={theme} /> : <ScrollView>
            {showInfo ?
              <View style={{ height: height - (height / 3) }}>
                <Pressable onPress={() => setShowInfo(false)}>
                  <Icon
                    name={images.back}
                    type="image"
                    size={24}
                    style={{ tintColor: theme.colors.textColor }}
                  />
                </Pressable>
                <WebView
                  // EN is on albanian because the website servers it on albanian even when EN is required
                  source={{ uri: ContentManager.translate('hyperlink_more_info_url') }}
                  startInLoadingState={true}
                  renderLoading={() => <Loader size={50} view="full" />}
                />
              </View> :
              <>
                <View style={Styles.titleContainer}>
                  <VFText
                    i18nKey={userType.includes('prepaid') ? 'hyperlink_balance_validity' : 'hyperlink_postpaid_title'}
                    style={Styles.title}
                  />
                  <TouchableOpacity onPress={toggleInfoModal}>
                    <Icon
                      name={images.info}
                      type="image"
                      size={30}
                    />
                  </TouchableOpacity>
                </View>

                {userType.includes('prepaid') && <View style={Styles.prepaidConatiner}>
                  {isLoading ? <Loading /> :
                    data?.extraDetails.map(item => <Row item={item} loading={isLoading} />)
                  }
                </View>}

                {isLoading ? <Loading /> : data?.details.map(item => <BorderedRow item={item} loading={isLoading} theme={theme} />)}

                {userType.includes('hybrid') && <View style={Styles.hybridContainer(theme)}>
                  <VFText
                    i18nKey="hyperlink_balance_validity"
                    style={Styles.hybridText}
                  />
                  {isLoading ? <Loading /> : data?.extraDetails.map(item => <Row item={item} loading={isLoading} />)}
                </View>}

                <VFText
                  i18nKey="hyperlink_info_text"
                  style={Styles.infoText}
                />

                <View style={Styles.pukContainer(theme)}>
                  <View style={Styles.pukContent}>
                    <Icon
                      name={themeImages.ic_sim}
                      size={45}
                      style={Styles.pukIcon}
                    />
                    <View style={Styles.pukTextContainer}>
                      <VFText
                        i18nKey="hyperlink_puk"
                        style={Styles.pukText}
                      />
                      {isPukLoading ? <Shimmer config={shimmerConfig1} /> : <VFText
                        i18nKey={puk}
                        style={Styles.pukValueText}
                      />}

                    </View>
                  </View>
                  <VFButton
                    title="hyperlink_copy_puk_button"
                    style={Styles.button}
                    textStyle={Styles.pukValueText}
                    onPress={copyToClipboard}
                  />
                  {!isPukLoading && copied && <>
                    <View style={Styles.copyContainer(theme)}>
                      <VFText
                        i18nKey="hyperlink_copy_puk"
                        style={Styles.copyText(theme)}
                      />
                    </View>
                    <View style={Styles.arrow(theme)} />
                  </>}
                </View>

              </>}
          </ScrollView>}
        </View>
      </Modal>
    )
  }

  return (
    <QuickActionMenu
      isVisible={isVisible}
      toggleModal={showInfo ? toggleInfoModal : toggleModal}
      type="secondary"
      title="hyperlink_screen_title"
      closeBtnStyle={Styles.closeButtonStyle}
      titleStyle={Styles.titleStyle}
    >
      {error ? <Error error={error} getData={getData} theme={theme} /> : <ScrollView>
        {showInfo ? <View style={{ height: height - (height / 3) }}>
          <WebView
            source={{ uri: ContentManager.translate('hyperlink_more_info_url') }}
            startInLoadingState={true}
            renderLoading={() => <Loader size={50} view="full" />}
          />
        </View> : <>
          <View style={Styles.titleContainer}>
            <VFText
              i18nKey={userType.includes('prepaid') ? 'hyperlink_balance_validity' : 'hyperlink_postpaid_title'}
              style={Styles.title}
            />
            <TouchableOpacity onPress={toggleInfoModal}>
              <Icon
                name={images.info}
                type="image"
                size={30}
              />
            </TouchableOpacity>
          </View>

          {userType.includes('prepaid') && <View style={Styles.prepaidConatiner}>
            {isLoading ? <Loading /> :
              data?.extraDetails.map(item => <Row item={item} loading={isLoading} />)
            }
          </View>}

          {isLoading ? <Loading /> : data?.details.map(item => <BorderedRow item={item} loading={isLoading} theme={theme} />)}

          {userType.includes('hybrid') && <View style={Styles.hybridContainer(theme)}>
            <VFText
              i18nKey="hyperlink_balance_validity"
              style={Styles.hybridText}
            />
            {isLoading ? <Loading /> : data?.extraDetails.map(item => <Row item={item} loading={isLoading} />)}
          </View>}

          <VFText
            i18nKey="hyperlink_info_text"
            style={Styles.infoText}
          />

          <View style={Styles.pukContainer(theme)}>
            <View style={Styles.pukContent}>
              <Icon
                name={themeImages.ic_sim}
                size={45}
                style={Styles.pukIcon}
              />
              <View style={Styles.pukTextContainer}>
                <VFText
                  i18nKey="hyperlink_puk"
                  style={Styles.pukText}
                />
                {isPukLoading ? <Shimmer config={shimmerConfig1} /> : <VFText
                  i18nKey={puk}
                  style={Styles.pukValueText}
                />}

              </View>
            </View>
            <VFButton
              title="hyperlink_copy_puk_button"
              style={Styles.button}
              textStyle={Styles.pukValueText}
              onPress={copyToClipboard}
            />
            {!isPukLoading && copied && <>
              <View style={Styles.copyContainer(theme)}>
                <VFText
                  i18nKey="hyperlink_copy_puk"
                  style={Styles.copyText(theme)}
                />
              </View>
              <View style={Styles.arrow(theme)} />
            </>}
          </View>

        </>}
      </ScrollView>}
    </QuickActionMenu >
  )
}

Hyperlink.propTypes = {
  isVisible: PropTypes.bool,
  toggleModal: PropTypes.func,
}

export default Hyperlink
