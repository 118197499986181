import { StyleSheet } from 'react-native'
import { Colors, Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

export default StyleSheet.create({
    containerStyle: theme => ({
        flex: 1,
        justifyContent: 'space-between',
        //backgroundColor: '#000',
        borderRadius: 6,
        paddingHorizontal: 12,
        paddingTop: 15.5,
        paddingBottom: 12,

        elevation: 2,
        shadowColor: Colors.shadow,
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowRadius: 8,
        shadowOpacity: 1,
        position: 'relative'
    }),
    titleContainerStyle: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    titleTextStyle: theme => ({
        marginHorizontal: 8,
        marginTop: 10,
        fontSize: 24,
        fontFamily: 'Vodafone Lt',
        letterSpacing: 0
    }),
    infoContainerStyle: {
        flexDirection: 'row',
        alignItems: 'flex-end'
    },
    remainingTextStyle: theme => ({
        fontSize: 43.7,
        fontFamily: Fonts.type.VodafoneLt,
        letterSpacing: 0,
        lineHeight: 40,
        color: theme.colors.accentColor
    }),
    remainingUnitTextStyle: theme => ({
        fontSize: 29.1,
        fontFamily: Fonts.type.VodafoneLt,
        letterSpacing: 0,
        lineHeight: 36,
        color: theme.colors.accentColor
    }),
    spacingViewStyle: {
        width: 3,
        height: 1
    },
    usageLeftTextStyle: theme => ({
        fontSize: 16.6,
        letterSpacing: 0,
        color: theme.colors.accentColor
    }),
    resetTextStyle: theme => ({
        fontSize: 16,
        fontFamily: 'Vodafone Rg',
        letterSpacing: 0,
        color: theme.colors.dark_grey,
        paddingRight: 10
    }),
    footerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 8,
        marginBottom: 10,
    },
    cartImageStyles: {
        position: 'absolute',
        right: 0,
        bottom: 0,
    },
    bgStyle: {
        flex: 1,
        borderRadius: 20
    },
    whiteText: {
        color: '#FFF'
    },
    blackText: {
        color: '#000'
    },
    rounded: {
        borderRadius: 5,
        flex: 1
    }
})