import React from 'react'
import { View, Animated, TouchableOpacity, ImageBackground } from 'react-native'
import PropTypes from 'prop-types'
import moment from 'moment'

import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { VFText, Icon } from '@vfgroup-oneplatform/foundation/Components'

import { getThemeImages } from '../../Themes'

import styles from './BusinessUsage.Styles'


const BusinessUsage = ({ planName, onPress, planDetails }) => {

  const theme = useTheme()

  const themedImages = getThemeImages(theme.name)

  const getRemainingDays = () => {

    const { validityPeriod } = planDetails
    const endDate = moment(validityPeriod?.['to-date']?.date, validityPeriod?.['from-date']?.['@format']?.toUpperCase())
    const currentTime = moment()

    const diff = endDate.diff(currentTime, 'days')

    return diff
  }

  const getFooterText = (days) => {
    if (days === 1) {
      return 'dashboard_business_usage_reset_day'
    } else if (days === 0) {
      return 'dashboard_business_usage_reset_next_day'
    } else {
      return 'dashboard_business_usage_reset_days'
    }
  }


  return (
    <TouchableOpacity activeOpacity={0.6} onPress={onPress} style={styles.contentContainer(theme)}>
      <ImageBackground
        source={themedImages.soho_usage_tile_bg}
        resizeMode="cover"
        style={styles.container(theme)}
      >
        <Animated.View style={styles.headerContainer}>
          <Icon
            name={themedImages.busniess_usage}
            size={30}
          />
          <VFText
            i18nKey="dashboard_business_usage"
            style={styles.title}
          />
        </Animated.View>
        <View style={styles.textView}>
          <VFText
            i18nKey={planName ? planName : ''}
            style={styles.planTitle}
          />
        </View>
        <View>
          {planDetails?.users && <VFText
            i18nKey={'dashboard_business_usage_users'}
            style={styles.usersText}
            placeHolders={[planDetails?.users]}
          />}
          {getRemainingDays() >= 0 && <VFText
            i18nKey={getFooterText(getRemainingDays())}
            style={styles.footerText(theme)}
            placeHolders={[getRemainingDays().toString()]}
          />}
        </View>
      </ImageBackground>
    </TouchableOpacity>
  )
}
BusinessUsage.defaultProps = {
  planName: '',
  onPress: () => { },
}
BusinessUsage.propTypes = {
  planName: PropTypes.string,
  planDetails: PropTypes.shape({
    validityPeriod: PropTypes.object,
    users: PropTypes.number
  }),
  onPress: PropTypes.func
}

export default BusinessUsage
