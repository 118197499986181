import React from 'react'
import { View, ViewPropTypes } from 'react-native'
import { useTheme } from '@vfgroup-oneplatform/foundation/Components/Themes'
import { Icon, VFText } from '@vfgroup-oneplatform/foundation/Components'
import PropTypes from 'prop-types'
import { map as _map } from 'lodash'

import styles from './OrderSummaryData.Styles'

const ICON_SIZE = 32
const renderDeviceIcon = (icon, style) => (
  <Icon style={[styles.iconStyle, style]} size={ICON_SIZE} name={icon} />
)
const OrderSummaryData = ({
  icon,
  subTitle,
  details,
  containerStyle
}) => {
  const theme = useTheme()
  return (
    <View style={[styles.container(theme), containerStyle]}>
      <View style={styles.headerContainer}>
        {/* {renderDeviceIcon(icon)} */}
        <View style={styles.titleSubTitleContainer}>
          <VFText style={styles.subTitle} i18nKey={subTitle} />
        </View>
      </View>

      <View style={styles.detailsContainer}>
        <View style={styles.detailsWrapper}>
          {_map(details, ({ key, value, keyStyle, valueStyle }) => (
            <View style={styles.detailItem} key={key}>
              <VFText
                type={'primary'}
                style={[styles.detailKey, keyStyle]}
                i18nKey={`${key}`}
              />
              <VFText
                type={'primary'}
                style={[styles.detailValue, valueStyle]}
                i18nKey={`${value}`}
              />
            </View>
          ))}
        </View>
      </View>
    </View>
  )
}
OrderSummaryData.propTypes = {
  icon: PropTypes.object,
  subTitle: PropTypes.string,
  details: PropTypes.array,
  containerStyle: ViewPropTypes.style
}
export default OrderSummaryData
