import React from 'react'
import { View, Text, ViewPropTypes, Animated, TouchableOpacity, Dimensions } from 'react-native'
import { isNil as _isNil } from 'lodash'

import {
  Icon,
  VFText,
  IconTypes,
  PercentageIndicator
} from '@vfgroup-oneplatform/foundation/Components'
import PropTypes from 'prop-types'
import { withTheme } from '@vfgroup-oneplatform/foundation/Components/Themes/Provider'
import { LightThemeColors } from '@vfgroup-oneplatform/foundation/Components/Themes'

import styles from './UsageInfoCard.Styles'

function UsageInfoCardWithButton({
  icon,
  iconType,
  iconSize,
  footerRightIcon,
  footerRightIconType,
  footerRightIconSize,
  title,
  remaining,
  remainingUnit,
  remainingText,
  remainingTextPlaceholder,
  resetTextValue,
  footerText,
  value,
  animate,
  style,
  titleContainerStyle,
  titleStyle,
  infoContainerStyle,
  remainingStyle,
  remainingUnitStyle,
  remainingTextStyle,
  resetTextStyle,
  iconAccessibilityLabel,
  titleAccessibilityLabel,
  remainingAccessibilityLabel,
  remainingUnitAccessibilityLabel,
  remainingTextAccessibilityLabel,
  resetTextAccessibilityLabel,
  theme,
  onPress,
  ...props
}) {
  return (
    <TouchableOpacity style={{ flex: 1 }} onPress={onPress}>
      <Animated.View style={[styles.containerStyle(theme), style]}>
        <Animated.View style={[styles.titleContainerStyle, titleContainerStyle]}>
          <Icon
            type={iconType}
            name={icon}
            size={Dimensions.get('window').width > 700 ? 32 : iconSize}
            accessibilityLabel={iconAccessibilityLabel}
          />
          <VFText
            i18nKey={title}
            style={[styles.titleTextStyle(theme), titleStyle]}
            accessibilityLabel={titleAccessibilityLabel}
          />
        </Animated.View>
        <View>
          <View style={[styles.infoContainerStyle, infoContainerStyle]}>
            <VFText numberOfLines={1}>
              <VFText
                i18nKey={remaining}
                style={[styles.remainingTextStyle(theme), remainingStyle]}
                accessibilityLabel={remainingAccessibilityLabel}
              />
              {remainingUnit && (
                <VFText
                  i18nKey={remainingUnit}
                  style={[
                    styles.remainingUnitTextStyle(theme),
                    remainingUnitStyle
                  ]}
                  accessibilityLabel={remainingUnitAccessibilityLabel}
                />
              )}
              <View style={styles.spacingViewStyle} />
              <VFText
                i18nKey={remainingText}
                placeHolders={remainingTextPlaceholder}
                style={[styles.usageLeftTextStyle(theme), remainingTextStyle]}
                accessibilityLabel={remainingTextAccessibilityLabel}
              />
            </VFText>
          </View>
          <PercentageIndicator
            value={value}
            animate={animate}
            fillColor={theme.colors.fillColor}
            color={theme.colors.secondryColor}
            {...props}
          />
        </View>
        <View style={styles.footerContainer}>
          {!_isNil(resetTextValue) || !_isNil(footerText) ? (
            <VFText
              i18nKey={
                footerText ||
                (resetTextValue === 0
                  ? 'unlimited_access_to_the_internet'
                  : resetTextValue === 1
                    ? 'usage_card_resets_in_day'
                    : 'usage_card_resets_in_days')
              }
              placeHolders={[resetTextValue]}
              style={[styles.resetTextStyle(theme), resetTextStyle]}
              accessibilityLabel={resetTextAccessibilityLabel}
            />
          ) : null}

          {props.showRefill && (
            <View style={{ flex: 1 }}>
              <TouchableOpacity onPress={props.onRefillPress} style={{ backgroundColor: Colors.red, padding: 8, borderRadius: 7, alignSelf: 'flex-end' }}>
                <VFText style={{ fontSize: 16, ...Fonts.type.bold }} i18nKey={'Refill'} type={'secondary'} />
              </TouchableOpacity>
            </View>
          )
          }

          {!props.showRefill && props.showGetMoreData && (
            <View style={{ flex: 1 }}>
              <TouchableOpacity onPress={props.onGetMoreDataPress} style={{ backgroundColor: Colors.red, padding: 8, borderRadius: 7, alignSelf: 'flex-end' }}>
                <VFText style={{ fontSize: 15, ...Fonts.type.bold }} i18nKey={'dashboard_get_more_data'} type={'secondary'} />
              </TouchableOpacity>
            </View>
          )
          }
          {footerRightIcon ? (
            <Icon
              type={footerRightIconType}
              name={footerRightIcon}
              size={footerRightIconSize}
              accessibilityLabel={iconAccessibilityLabel}
            />
          ) : null}
        </View>
      </Animated.View>
    </TouchableOpacity>
  )
}

UsageInfoCardWithButton.defaultProps = {
  iconType: IconTypes.image,
  footerRightIconType: IconTypes.image,
  iconSize: 25,
  footerRightIconSize: 25,
  theme: LightThemeColors
}

UsageInfoCardWithButton.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      uri: PropTypes.string.isRequired
    })
  ]).isRequired,
  iconType: PropTypes.oneOf(Object.values(IconTypes)).isRequired,
  iconSize: PropTypes.number,
  footerRightIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      uri: PropTypes.string.isRequired
    })
  ]),
  footerRightIconType: PropTypes.oneOf(Object.values(IconTypes)),
  footerRightIconSize: PropTypes.number,
  title: PropTypes.string.isRequired,
  remaining: PropTypes.string.isRequired,
  remainingUnit: PropTypes.string,
  remainingText: PropTypes.string.isRequired,
  footerText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  animate: PropTypes.bool.isRequired,
  style: ViewPropTypes.style,
  titleContainerStyle: ViewPropTypes.style,
  titleStyle: Text.propTypes.style,
  infoContainerStyle: ViewPropTypes.style,
  remainingStyle: Text.propTypes.style,
  remainingUnitStyle: Text.propTypes.style,
  remainingTextStyle: Text.propTypes.style,
  resetTextStyle: Text.propTypes.style,
  iconAccessibilityLabel: ViewPropTypes.accessibilityLabel,
  titleAccessibilityLabel: ViewPropTypes.accessibilityLabel,
  remainingAccessibilityLabel: ViewPropTypes.accessibilityLabel,
  remainingUnitAccessibilityLabel: ViewPropTypes.accessibilityLabel,
  remainingTextAccessibilityLabel: ViewPropTypes.accessibilityLabel,
  resetTextAccessibilityLabel: ViewPropTypes.accessibilityLabel,
  theme: PropTypes.shape({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  }),
  resetTextValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default withTheme(UsageInfoCardWithButton)
