import { StyleSheet } from 'react-native'
import { Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

const style = StyleSheet.create({
  totalPayText: {
    marginTop: 20,
    marginVertical: 15,
    ...Fonts.type.bold,
    fontSize: 16,
    paddingHorizontal: 16
  },
  totalPayContent: {
    paddingBottom: 10,
    paddingHorizontal: 16
  },
  separator: theme => ({
    height: 1,
    backgroundColor: theme.colors.borderColorThree,
    marginBottom: 8
  }),
  totalPayContentRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 5
  },
  contentText: {
    ...Fonts.type.bold,
    fontSize: 16
  },
  sectionTitle: {
    fontSize: 16.7,
    marginTop: 9,
    fontFamily: Fonts.type.VodafoneRg,
    paddingHorizontal: 16,
    lineHeight: 23
  },
  orderNumber: {
    fontSize: 25.1,
    ...Fonts.type.bold,
    marginTop: 8.4,
    lineHeight: 31.4,
    marginBottom: 24.4,
    paddingHorizontal: 16
  },
  orderNumContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  badgeStyle: {
    width: '28%',
    height: '37%',
    marginTop: 14
  },
  badgeTextStyle: {
    textAlign: 'center',
    fontSize: 14.6,
    lineHeight: 16.7,
    paddingVertical: -1
  },
  container: {
    marginRight: 17
  },
  deviceTotalCost: {
    ...Fonts.type.bold
  },
  scrollView: {
    maxHeight: 340
  }
})
export default style
