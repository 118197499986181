import React, { PureComponent } from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import { VFText, Icon } from '@vfgroup-oneplatform/foundation/Components'
import {
  withTheme,
  LightThemeColors,
  Colors
} from '@vfgroup-oneplatform/foundation/Components/Themes'

import { getThemeImages } from '../../../../../../Group/Themes'

import styles from './FixPlanCard.Styles'

class FixPlanCard extends PureComponent {

  constructor(props) {
    super(props)
  }

  render() {
    const {
      item,
      theme = LightThemeColors,
      upgradePlanBtn,
    } = this.props

    const {
      status,
      title,
      address,
      price,
      priceUnit,
      characteristics,
      startDate,
      terminationDate
    } = item

    const color = status === 'inactive' || status === 'terminated' ? Colors.vfRed
      : status === 'active' ? Colors.true_green
        : status === 'in process' ? Colors.fresh_orange : theme.colors.textColor

    const themedImages = getThemeImages(theme.name)

    return (
      <View style={styles.cardContainerStyle(theme)}>

        <View style={styles.cardHeaderStyle}>
          <View style={styles.headerTitleViewStyle}>
            <VFText
              style={styles.statusStyle(color)}
              i18nKey={`pns_fix_card_${status?.replace(' ', '_')}_status`}
            />
          </View>
          <View style={styles.titleViewStyle}>
            <VFText
              style={styles.titleStyle(theme)}
              i18nKey={title}
            />
            <VFText
              style={styles.addressStyle(theme)}
              i18nKey={address}
            />
          </View>
        </View>

        <View style={styles.characteristicsContainer(theme)}>

          {characteristics.length === 0 && <View style={styles.noAddonsContainer}>
            <VFText
              style={styles.text(theme)}
              i18nKey="pns_fix_no_tv_addons"
            />
          </View>}

          {characteristics.length > 0 && characteristics.map(characteristic => {
            return (
              <View style={styles.row}>
                <Icon
                  name={item?.name === 'TV package' ? themedImages.ic_tv_icon : themedImages.speed}
                  size={30}
                  style={styles.icon}
                />
                <VFText
                  style={styles.text(theme)}
                  i18nKey={characteristic?.value || ' '}
                />
              </View>
            )
          })}
        </View>

        <View>

          {price >= 0 && <View style={styles.dataRow(theme)}>
            <VFText
              style={styles.renewalDateStyle(theme)}
              i18nKey="pns_fix_price_month"
            />
            <View style={styles.priceContainer}>
              <VFText
                style={styles.priceText(theme)}
                i18nKey={price?.toString() + ' '}
              />
              <VFText
                style={styles.priceUnit(theme)}
                i18nKey={priceUnit || ' '}
              />
            </View>
          </View>}

          {startDate && <View style={styles.dataRow(theme)}>
            <VFText
              style={styles.renewalDateStyle(theme)}
              i18nKey="pns_fix_start_date"
            />
            <VFText
              style={[styles.renewalDateStyle(theme)]}
              i18nKey={startDate}
            />
          </View>}

          {terminationDate && <View style={[styles.dataRow(theme), { borderBottomWidth: 0 }]}>
            <VFText
              style={styles.renewalDateStyle(theme)}
              i18nKey="pns_fix_min_terms_ends"
            />
            <VFText
              style={styles.renewalDateStyle(theme)}
              i18nKey={terminationDate}
            />
          </View>}

        </View>

        {upgradePlanBtn()}

      </View>
    )
  }
}

FixPlanCard.propTypes = {
  theme: PropTypes.shape({
    name: PropTypes.string,
    isDark: PropTypes.bool,
    colors: PropTypes.object
  }),
  item: PropTypes.objectOf({
    id: PropTypes.string,
    status: PropTypes.string,
    title: PropTypes.string,
    address: PropTypes.string,
    price: PropTypes.string,
    priceUnit: PropTypes.string,
    characteristics: PropTypes.array,
    startDate: PropTypes.string,
    terminationDate: PropTypes.string
  }),
  upgradePlanBtn: PropTypes.func
}

export default withTheme(FixPlanCard)
