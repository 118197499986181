import { StyleSheet, Platform } from 'react-native'

export default StyleSheet.create({
  background: {
    backgroundColor: '#ba0404',
    zIndex: 11
  },
  backgroundOpened: {
    backgroundColor: '#ba0404',
    zIndex: 11,
    paddingBottom: Platform.OS === 'ios' ? 30 : 0
  },
  icon: {
    width: 40,
    height: 40
  },
  modalContainer: {
    height: '100%',
    width: '100%',
    bottom: 0
  },
  modalContent: {
    backgroundColor: '#fff',
    height: '50%',
    bottom: 0,
    position: 'absolute',
    width: '80%',
    alignSelf: 'center',
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    overflow: 'hidden'
  },
  modalHeader: {
    backgroundColor: '#fff',
    marginTop: Platform.OS === 'android' ? 10 : -15,
    ...Platform.select({
      web: {
        marginTop: 10
      }
    })
  },
  search: {
    width: 25,
    height: 25,
    marginRight: 10,
    marginLeft: 5
  },
  input: {
    fontSize: 17,
    paddingRight: 40,
    width: '100%'
  },
  sort: {
    fontSize: 18,
    fontWeight: 'bold',
    paddingRight: 40,
    textAlign: 'center',
    color: 'red',
    width: '100%',
    paddingLeft: 40
  },
  arrow_down: {
    width: 28,
    height: 28
  },
  selectedItem: {
    textAlign: 'center',
    fontSize: 17,
    padding: 7,
    color: 'red',
    backgroundColor: '#eee'
  },
  item: {
    textAlign: 'center',
    fontSize: 17,
    color: '#555',
    padding: 7
  },
  listView: {
    backgroundColor: 'white'
  },
  hasFilter: {
    borderRightColor: 'rgba(51, 51, 51, 0.25)',
    backgroundColor: '#950101',
    borderRightWidth: 1,
    borderBottomColor: '#fff',
    borderBottomWidth: 2
  },
  noFilter: {
    borderRightColor: 'rgba(51, 51, 51, 0.25)',
    borderRightWidth: 1
  },
  clear: {
    textAlign: 'center',
    paddingBottom: 10,
    color: 'red',
    paddingTop: Platform.OS === 'ios' ? 10 : 0
  }
})
