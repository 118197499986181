
import {
  GetDailyCatalogue,
  ActivateReward,
} from '../../Services/Promo'

import ReduxCache from '../../Utils/ReduxCache'

const actionTypes = {
  REQUEST_DAILY_CATALOGUE: 'REQUEST_DAILY_CATALOGUE',
  SUCCESS_DAILY_CATALOGUE: 'SUCCESS_DAILY_CATALOGUE',
  ERROR_DAILY_CATALOGUE: 'ERROR_DAILY_CATALOGUE',

  REQUEST_ORDER_PRODUCT: 'REQUEST_ORDER_PRODUCT',
  SUCCESS_ORDER_PRODUCT: 'SUCCESS_ORDER_PRODUCT',
  ERROR_ORDER_PRODUCT: 'ERROR_ORDER_PRODUCT',
}

const errorMapperMessage = (error) => {
  const payload = error?.response?.data || {
    message: 'Diçka shkoi gabim',
  }
  return payload
}


const rowMapper = (row) => {
  return row.map((element) => {
    const additionalData = { onClick: '' }
    element?.productCharacteristic?.map((prod) => {
      if (prod.id === 'uplift') {
        const description = prod?.valueType
        const name = prod?.name
        const currency = prod['@baseType']
        const value = parseFloat(prod['@type'], 10)
        additionalData[prod?.id] = { description, name, currency, value }
      } else {
        additionalData[prod?.id] = prod?.name
      }
    })
    return {
      ...element,
      productCharacteristic: additionalData,
    }
  })
}

const getProductsResponseMapper = (response) => {
  const { relatedParty, description, category, place, recommendationItem = [] } = (response.data?.[0] || {})

  const cards = []
  let len = 0
  recommendationItem.sort((a, b) => parseFloat(a.priority) - parseFloat(b.priority))
  recommendationItem.forEach((level) => {
    const product = level?.product?.product || []
    len = Math.max(len, product.length)
  })
  if (len % 3 > 0) {
    len = (Math.floor(len / 3) + 1) * 3
  }
  recommendationItem.forEach((level) => {
    const { priority, product } = level || {}
    const { product: prods, ...rest } = product || {}
    for (let i = 0; i < len; i += 3) {
      let el = prods.slice(i, i + 3)
      const empty = []
      for (let j = 0; j < 3 - el.length; j++) {
        empty.push({
          status: 'empty',
        })
      }
      el = el.concat(empty)
      const info = {
        priority,
        product: {
          product: rowMapper(el),
          ...rest,
        },
      }
      const idx = i / 3
      if (Array.isArray(cards[idx])) {
        cards[idx].push(info)
      } else {
        const page = [info]
        cards.push(page)
      }
    }
  })


  return { relatedParty, description, category, place, cards }
}

const orderResponseMapper = (response) => {
  const data = response.data || {}
  const { productOffering = {}, itemPrice = [] } = data.productOrderItem?.[0] || {}
  const { description: desc } = data
  const name = productOffering?.name
  const productId = productOffering?.id
  const pricing = itemPrice?.[0]?.price?.dutyFreeAmount
  const payload = { productId, name, desc, pricing }
  return payload
}

const getProducts = (type, utilities = {}) => {
  return async (dispatch, getState) => {

    dispatch({
      type: actionTypes.REQUEST_DAILY_CATALOGUE,
    })

    const { configurations } = getState()

    const showPromoHeader = configurations?.config?.promo_project_header

    let headers = {
      'vf-operator': 'promo',
    }

    if (showPromoHeader === 'true') {
      headers = {
        'vf-operator': 'promo',
        'vf-project': 'promo',
      }
    }

    GetDailyCatalogue(type, headers, {
      ...utilities,
      responseMapper: getProductsResponseMapper,
      errorMapper: errorMapperMessage,
    }).then(response => dispatch({
      type: actionTypes.SUCCESS_DAILY_CATALOGUE,
      payload: response,
    })
    ).catch(error => dispatch({
      type: actionTypes.ERROR_DAILY_CATALOGUE,
      payload: error,
    }))
  }
}

const orderProduct = (orderId, productId, type, status, pendingTeminateData, utilities = {}) => {
  return async (dispatch, getState) => {

    dispatch({
      type: actionTypes.REQUEST_ORDER_PRODUCT,
    })

    const { configurations } = getState()

    const showPromoHeader = configurations?.config?.promo_project_header

    let headers = {
      'X-Source-Operator': 'promo2020',
      'X-Source-System': 'selfcare',
      'X-Source-CountryCode': 'al',
    }

    if (showPromoHeader === 'true') {
      headers = {
        'X-Source-Operator': 'promo2020',
        'X-Source-System': 'selfcare',
        'X-Source-CountryCode': 'al',
        'vf-project': 'promo',
      }
    }

    const msisdn = ReduxCache.getMsisdn()

    const requestBodyForPendingTerminate = {
      'description': pendingTeminateData?.description,
      'channel': [
        {
          'id': 'selfcare',
        },
      ],
      'productOrderItem': [
        {
          'id': orderId,
          'quantity': 1,
          'action': 'add',
          'itemPrice': [
            {
              'price': {
                'dutyFreeAmount': {
                  'unit': pendingTeminateData?.currency,
                  'value': pendingTeminateData?.value,
                },
              },
            },
          ],
          'productOffering': {
            'id': pendingTeminateData?.name,
            'name': pendingTeminateData?.description,
          },
        },
      ],
      'relatedParty': [
        {
          'id': msisdn,
          'role': 'Customer',
          '@referredType': 'individual',
        },
      ],
      '@type': type,
    }

    const requestBodyForActiveStatus = {
      'channel': [
        {
          'id': 'selfcare',
        },
      ],
      'productOrderItem': [
        {
          'id': orderId,
          'quantity': 1,
          'action': 'add',
          'productOffering': {
            'id': productId,
          },
        },
      ],
      'relatedParty': [
        {
          'id': msisdn,
          'role': 'Customer',
          '@referredType': 'individual',
        },
      ],
      '@type': type,
    }

    const requestBody = status === 'pendingTerminate' ? requestBodyForPendingTerminate : requestBodyForActiveStatus

    ActivateReward(requestBody, headers, {
      ...utilities,
      responseMapper: orderResponseMapper,
      errorMapper: errorMapperMessage,
    }).then(response => dispatch({
      type: actionTypes.SUCCESS_ORDER_PRODUCT,
      payload: response,
    })).catch(error => dispatch({
      type: actionTypes.ERROR_ORDER_PRODUCT,
      payload: error,
    }))
  }
}

const actions = {
  getProducts,
  orderProduct,
}

export {
  actionTypes,
  actions,
}

