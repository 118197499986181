
import {
  OpenQuizEvent,
  getQuizLevels,
  startQuizLevel,
} from '@vfal-lab/rn-vfg-quiz/Services'

import ReduxCache from '../../Utils/ReduxCache'

const actionTypes = {
  OPEN_QUIZ_EVENT: 'OPEN_QUIZ_EVENT',
  SUCCESS_QUIZ_EVENT: 'SUCCESS_QUIZ_EVENT',
  ERROR_QUIZ_EVENT: 'ERROR_QUIZ_EVENT',

  GET_QUIZ_LEVELS: 'GET_QUIZ_LEVELS',
  SUCCESS_QUIZ_LEVELS: 'SUCCESS_QUIZ_LEVELS',
  ERROR_QUIZ_LEVELS: 'ERROR_QUIZ_LEVELS',

  START_QUIZ_LEVEL: 'START_QUIZ_LEVEL',
  SUCCESS_START_QUIZ_LEVEL: 'SUCCESS_START_QUIZ_LEVEL',
  ERROR_START_QUIZ_LEVEL: 'ERROR_START_QUIZ_LEVEL',
}

const errorMapper = (error) => {
  const payload = error?.response?.data?.message || error?.message
  return payload
}

const quizLevelsMapper = (res) => {
  return res?.data.map(item => ({
    id: item?.id[0]?.value,
    level: item?.name,
    description: item?.desc,
    info: item?.shortDescription,
    optionsNr: item?.parts?.specification?.characteristicsValue[0]?.value,
  }))

}

const customHeaders = {
  'X-Source-Operator': 'quiz',
  'X-Source-System': 'selfcare',
  'Content-Type': 'application/json;charset=UTF-8',
}

const listQuizLevels = (utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.GET_QUIZ_LEVELS,
    })

    const msisdn = ReduxCache.getMsisdn()

    getQuizLevels(msisdn, customHeaders, {
      ...utilities,
      responseMapper: quizLevelsMapper,
      errorMapper: errorMapper,
    })
      .then(response => dispatch({
        type: actionTypes.SUCCESS_QUIZ_LEVELS,
        payload: response,
      }))
      .catch(error => dispatch({
        type: actionTypes.ERROR_QUIZ_LEVELS,
        payload: error,
      }))
  }
}

const openQuizEvent = (utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.OPEN_QUIZ_EVENT,
    })

    const msisdn = ReduxCache.getMsisdn()

    OpenQuizEvent(msisdn, customHeaders, {
      ...utilities,
      responseMapper: (res) => res,
      errorMapper: errorMapper,
    })
      .then(response => dispatch({
        type: actionTypes.SUCCESS_QUIZ_EVENT,
        payload: response,
      }))
      .catch(error => dispatch({
        type: actionTypes.ERROR_QUIZ_EVENT,
        payload: error,
      }))
  }
}

const openQuizLevelMapper = (res) => (res?.data?.id[0]?.value)

const openQuizLevel = (quizLevelId, utilities = {}) => {
  return async dispatch => {

    dispatch({
      type: actionTypes.START_QUIZ_LEVEL,
    })

    const msisdn = ReduxCache.getMsisdn()

    startQuizLevel(msisdn, quizLevelId, customHeaders, {
      ...utilities,
      responseMapper: openQuizLevelMapper,
      errorMapper: errorMapper,
    })
      .then(response => dispatch({
        type: actionTypes.SUCCESS_START_QUIZ_LEVEL,
        payload: response,
      }))
      .catch(error => dispatch({
        type: actionTypes.ERROR_START_QUIZ_LEVEL,
        payload: error,
      }))
  }
}

export {
  actionTypes,
  openQuizEvent,
  listQuizLevels,
  openQuizLevel,
}
