import axios from 'axios'

import { isFunction } from 'lodash'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import ReduxCache from '../../Utils/ReduxCache'

import { API_URLS } from '../Config'

import { errorMapper, validateNumberMapper } from './Mappers'

const validateUnlimitedNumber = async ({ body, ...utilities }) => {
  const _utilities = retrieveUtilities({
    ...utilities,
    errorMapper,
    responseMapper: validateNumberMapper
  })
  _utilities.onStart()
  try {
    const reqBody = {
      status: 'initialized',
      familyName: 'string',
      givenName: 'string',
      partyCharacteristic: [
        {
          name: 'tariffPlan',
          value: body.tariffPlan
        }
      ],
      relatedParty: [
        {
          id: body.number,
          '@referredType': 'member'
        },
        {
          id: ReduxCache.getMsisdn(),
          '@referredType': 'admin'
        }
      ]
    }
    const response = await axios.post(
      API_URLS.unlimited.validateNumber,
      reqBody,
      {
        headers: {
          'vf-project': 'unlimited'
        }
      }
    )
    const _response = _utilities.responseMapper(response?.data ?? {})
    _utilities.onSuccess(_response)
  } catch (error) {
    const _error = _utilities.errorMapper(error)
    if (isFunction(_utilities.onError)) {
      _utilities.onError(_error)
    } else {
      throw _error
    }
  }
}

export { validateUnlimitedNumber }
