import React, { Component } from 'react'
import { Linking, Platform } from 'react-native'
import { WebView as DefaultWebView } from 'react-native-webview'

const script = `
  <script>
    var viewport = document.querySelector('meta[name=viewport]');
    var viewportContent = 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0';
    
    if (viewport === null) {
      viewport = document.createElement('meta');
      viewport.setAttribute('name', 'viewport');
      viewport.setAttribute('content', viewportContent);

      if(!document.head){
        document.body.parentElement.append = document.createElement('head');
      }
      document.head.appendChild(viewport);
    }

    window.location.hash = 1;
    var calculator = document.createElement("div");
    calculator.id = "height-calculator";
    while (document.body.firstChild) {
        calculator.appendChild(document.body.firstChild);
    }
    document.body.appendChild(calculator);
    document.title = calculator.scrollHeight;

    

  </script>
`

const style = `
  <style>
    body, html, #height-calculator {
        margin: 0;
        padding: 0;
    }
    #height-calculator {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
    }
  </style>
`

class WebView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: Platform.OS == 'android' ? 40 : 80,
    }
  }

  onNavigationChange(event) {
    if (
      event.title != 'undefined' &&
      event.title != '' &&
      event.title != 'about:blank' &&
      event.title != '<<NaN>>' &&
      Number.isFinite(Number(event.title))
    ) {
      const htmlHeight = Number(event.title) //convert to number

      if (htmlHeight > this.state.height) {
        this.setState({ height: htmlHeight })
      }
    }

    if (event.url.indexOf('http') >= 0) {
      this.webview.stopLoading()
      Linking.openURL(event.url)
    }
  }

  render() {
    const { content } = this.props
    return (
      <DefaultWebView
        ref={ref => {
          this.webview = ref
        }}
        scrollEnabled={false}
        style={[this.props.style, { height: this.state.height },]}
        onNavigationStateChange={event => this.onNavigationChange(event)}
        javaScriptEnabled={true}
        source={{ html: content + style + script }}
      />
    )
  }
}

export default WebView
