import { StyleSheet, Dimensions, Platform } from 'react-native'
import { Colors, Fonts } from '@vfgroup-oneplatform/foundation/Components/Themes'

const screenWidth = Dimensions.get('window').width
const tobiWidht = screenWidth / 5.294
export default StyleSheet.create({
  outerContainer: {
    justifyContent: 'center',
    width: screenWidth,
    flexDirection: 'row'
  },
  container: theme => ({
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: theme.colors.cardBackgroundColor,
    borderRadius: screenWidth / 11,
    elevation: 2,
    height: tobiWidht,
    shadowColor: Colors.shadow,
    shadowOffset: {
      width: 0,
      height: -5
    },
    shadowOpacity: 1,
    shadowRadius: 5,
    width: tobiWidht
  }),
  customTrayIcon: {
    marginHorizontal: 9
  },
  textView: {
    flex: 1,
    flexDirection: 'row',
    marginRight: 30
  },
  text: {
    fontSize: 18,
    flex: 1,
    letterSpacing: -0.27,
    lineHeight: 20,
    fontFamily: Fonts.type.VodafoneLt
  },
  tobiStyle: {
    width: tobiWidht,
    height: tobiWidht,
    justifyContent: 'center'
  },
  badgeStyle: {
    width: 20,
    left: 50
  },
  tobiContainerStyle: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  wrapper: {
    position: 'absolute',
    bottom: 35
  }
})
