import {StyleSheet} from 'react-native'

import colors from '../../Themes/Colors'

export default StyleSheet.create({
  default: {
    fontFamily: 'Vodafone Rg',
    fontWeight: 'normal',
    color: colors.dark_grey,
    fontSize: 16,
    textAlign: 'auto',
    margin: 8,
  },
  h1: {
    fontSize: 32,
  },
  h2: {
    fontSize: 28,
  },
  h3: {
    fontSize: 24,
  },
  h4: {
    fontSize: 20,
  },
  h5: {
    fontSize: 18,
  },
  h6: {
    fontSize: 16,
  },
  body: {
    fontSize: 16,
  },
  footnote: {
    fontSize: 14,
  },
  align_left: {
    textAlign: 'left',
  },
  align_right: {
    textAlign: 'right',
  },
  align_center: {
    textAlign: 'center',
  },
  align_justify: {
    textAlign: 'justify',
  },
  normal: {
    fontFamily: 'Vodafone Rg',
    fontWeight: 'normal',
  },
  bold: {
    fontFamily: 'Vodafone Rg',
    fontWeight: 'bold',
  },
  light: {
    fontFamily: 'Vodafone Lt',
  },
})
